import {
    StrategyActionsTypes,
    StrategyBoardSidePanelCollapseStatus,
} from "../../../../types/typesStrategy";


const initialState: StrategyBoardSidePanelCollapseStatus = {
    collapseStatus_value: true
}

const reducerPanelCollapseStatus = (state = initialState, action: StrategyActionsTypes): StrategyBoardSidePanelCollapseStatus => {

    switch (action.type) {

        case "STRATEGY_BOARD_SIDE_PANEL_COLLAPSE_STATUS":
            const { collapseStatus_value } = action.payload
            return {
                collapseStatus_value
            };

        default:
            return state
    }
}

export default reducerPanelCollapseStatus