import { StrategyActionsTypes, StrategyFunctionGroups } from "../../../../types/typesStrategy";

const initialState = {
    function_groups: []
}

const reducerFunctionGroups = (state = initialState, actions: StrategyActionsTypes): StrategyFunctionGroups => {
    switch (actions.type) {
        case "STRATEGY_FUNCTION_GROUPS":
            const {function_groups} = actions.payload
            return {
                function_groups
            }
    
        default:
            return state;
    }
}

export default reducerFunctionGroups