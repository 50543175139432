export const createShadow = (type:string)=>{
    let xmlns = "http://www.w3.org/2000/svg";

    let _type = type === 'figure' ? 'figure_shadow':'box_shadow'
    let defs = document.createElementNS(xmlns,'defs') as SVGDefsElement;
    let filter = document.createElementNS(xmlns,'filter') as SVGFilterElement;
    let filter_data={
        id:_type,
        x:"-40%",
        y:"-40%",
        width:"180%",
        height:"1800%",
        filterUnits:"userSpaceOnUse",
    }
    for(const [key,value] of Object.entries(filter_data)){
        filter.setAttributeNS(null,key,value);
    }


    let feDropShadow = document.createElementNS(xmlns,'feDropShadow') as SVGFEDropShadowElement;
    let feDropShadow_data = {
        x:"0",
        y:"0",
        stdDeviation:"5",
        "flood-color":"#000",
        "flood-opacity":"0.08"
    }
    for(const [key,value] of Object.entries(feDropShadow_data)){
        feDropShadow.setAttributeNS(null,key,value);
    }

    filter.appendChild(feDropShadow);
    defs.appendChild(filter);
    
    return defs
}