import React, { FC, DragEvent, MouseEvent, ChangeEvent } from "react";
import classes from "../Item/Item.module.scss";
import { icons, OutputMessages } from "../../../../../../../settings/settings";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../store";
import {
  Active,
  Cancel,
  Delete,
  GroupIcon,
  ItemIcon,
  Pen,
  Sort,
} from "../../../../../../../components/UI/Svg/Svg";
import Tooltip from "../../../../../../../components/UI/Tooltip/Message/Tooltip";

type ItemType = {
  type: string;
  uuid: string;
  id: string;
  key: string;
  value: string | ItemType[];
};

type Data = {
  no?: number;
  index?: any;
  data?: any;
  dropItemHandler?: (e: DragEvent<HTMLDivElement>) => void;
  clickHandler?: (e: MouseEvent<HTMLDivElement>) => void;
  dragOverItemHandler?: (e: DragEvent<HTMLDivElement>) => void;
  dragLeaveItemHandler?: (e: DragEvent<HTMLDivElement>) => void;
  dragStartHandler: (e: DragEvent<HTMLDivElement>, item: ItemType) => void;
  editItemHandler: (e: MouseEvent<SVGSVGElement>, data: ItemType) => void;
  deleteItemHandler: (e: MouseEvent<SVGSVGElement>, data: ItemType) => void;
  saveItemHandler: (e: MouseEvent<SVGSVGElement>, data: ItemType) => void;
  cancelItemHandler: (e: MouseEvent<SVGSVGElement>, data: ItemType) => void;
  changeInputHandler: (
    e: ChangeEvent<HTMLInputElement>,
    data: ItemType,
    type: string
  ) => void;
  sortHandler: (e: DragEvent<HTMLDivElement>, item: ItemType) => void;
};

const Container: FC<Data> = ({
  data,
  dropItemHandler,
  clickHandler,
  dragOverItemHandler,
  dragLeaveItemHandler,
  dragStartHandler,
  editItemHandler,
  deleteItemHandler,
  saveItemHandler,
  cancelItemHandler,
  changeInputHandler,
  sortHandler,
}) => {
  return (
    <TreeList
      data={data}
      changeInputHandler={changeInputHandler}
      dragStartHandler={dragStartHandler}
      saveItemHandler={saveItemHandler}
      cancelItemHandler={cancelItemHandler}
      editItemHandler={editItemHandler}
      deleteItemHandler={deleteItemHandler}
      dragOverItemHandler={dragOverItemHandler}
      dragLeaveItemHandler={dragLeaveItemHandler}
      dropItemHandler={dropItemHandler}
      clickHandler={clickHandler}
      sortHandler={sortHandler}
    />
  );
};

export default Container;

const TreeList: FC<Data> = ({
  no,
  index,
  data,
  dropItemHandler,
  clickHandler,
  dragOverItemHandler,
  dragLeaveItemHandler,
  dragStartHandler,
  editItemHandler,
  deleteItemHandler,
  saveItemHandler,
  cancelItemHandler,
  changeInputHandler,
  sortHandler,
}) => {
  return data?.map((item: any, i: number) => {
    return (
      <Item
        key={i}
        data={item}
        no={no ? no : 1}
        index={index ? `${index}-${i}` : i}
        changeInputHandler={changeInputHandler}
        dragStartHandler={dragStartHandler}
        saveItemHandler={saveItemHandler}
        cancelItemHandler={cancelItemHandler}
        editItemHandler={editItemHandler}
        deleteItemHandler={deleteItemHandler}
        dragOverItemHandler={dragOverItemHandler}
        dragLeaveItemHandler={dragLeaveItemHandler}
        dropItemHandler={dropItemHandler}
        clickHandler={clickHandler}
        sortHandler={sortHandler}
      />
    );
  });
};

const Item: FC<Data> = ({
  index,
  no,
  data,
  dropItemHandler,
  clickHandler,
  dragOverItemHandler,
  dragLeaveItemHandler,
  dragStartHandler,
  editItemHandler,
  deleteItemHandler,
  saveItemHandler,
  cancelItemHandler,
  changeInputHandler,
  sortHandler,
}) => {
  return (
    <div className={classes.Item}>
      <LI
        no={no}
        index={index}
        data={data}
        changeInputHandler={changeInputHandler}
        dragStartHandler={dragStartHandler}
        saveItemHandler={saveItemHandler}
        cancelItemHandler={cancelItemHandler}
        editItemHandler={editItemHandler}
        deleteItemHandler={deleteItemHandler}
        dragOverItemHandler={dragOverItemHandler}
        dragLeaveItemHandler={dragLeaveItemHandler}
        dropItemHandler={dropItemHandler}
        clickHandler={clickHandler}
        sortHandler={sortHandler}
      >
        {Array.isArray(data?.value) && (
          <UL data={data}>
            <TreeList
              data={data.value}
              no={no ? no + 1 : 1}
              index={`${index}`}
              changeInputHandler={changeInputHandler}
              dragStartHandler={dragStartHandler}
              saveItemHandler={saveItemHandler}
              cancelItemHandler={cancelItemHandler}
              editItemHandler={editItemHandler}
              deleteItemHandler={deleteItemHandler}
              dragOverItemHandler={dragOverItemHandler}
              dragLeaveItemHandler={dragLeaveItemHandler}
              dropItemHandler={dropItemHandler}
              clickHandler={clickHandler}
              sortHandler={sortHandler}
            />
          </UL>
        )}
      </LI>
    </div>
  );
};

const UL: FC<Partial<Data>> = ({ data, children }) => {
  return (
    <div
      id="child"
      data-show={data?.value?.length === 0 ? false : true}
      data-uuid={data?.uuid}
      className={
        data?.value?.length === 0
          ? [classes.Child, classes.ChildHide].join(" ")
          : classes.Child
      }
    >
      {children}
    </div>
  );
};

const LI: FC<Data> = ({
  no,
  index,
  data,
  dropItemHandler,
  clickHandler,
  dragOverItemHandler,
  dragLeaveItemHandler,
  dragStartHandler,
  editItemHandler,
  deleteItemHandler,
  saveItemHandler,
  cancelItemHandler,
  changeInputHandler,
  sortHandler,
  children,
}) => {
  const {
    reducerStrategy: {
      StrategyBoardElements: {
        StrategyOutputFormatItemStatus: { status, item },
        StrategyOutputFormatMessage: { message },
      },
    },
  } = useSelector((state: RootState) => state);

  const getKey = () => {
    let result: any = null;
    if (
      (status === "edit" || status === "writing") &&
      data.uuid === item?.uuid
    ) {
      result = (
        <span
          id="key"
          style={
            data?.type === "item" || data?.type === "group"
              ? { left: "3vh" }
              : { left: 0 }
          }
          className={classes.Key}
        >
          <input
            data-type="key"
            onChange={(e) => changeInputHandler(e, data, "key")}
            className={classes.KeyInput}
            defaultValue={data?.key}
          />
        </span>
      );
    } else {
      result = (
        <span
          id="key"
          style={
            data?.type === "item" || data?.type === "group"
              ? { left: "3vh" }
              : { left: 0 }
          }
          className={classes.Key}
        >
          {data.key}
        </span>
      );
    }

    return result;
  };
  const getType = () => {
    const { type, value } = data;
    let result: any = null;
    if (type === "item" || type === "input" || type === "variable") {
      result = (
        <span
          style={
            data?.type === "item" || data?.type === "group"
              ? { left: "3vh" }
              : { left: 0 }
          }
          className={classes.Dot}
        >
          {":"}
        </span>
      );
    } else if (type === "group" && Array.isArray(value) && value?.length > 0) {
      result = (
        <span
          style={
            data?.type === "item" || data?.type === "group"
              ? { left: "3vh" }
              : { left: 0 }
          }
          className={classes.Dot}
        >
          {":"}
        </span>
      );
    }
    return result;
  };
  const getValue = () => {
    let result: any = null;
    const { id: dataId, type, uuid: dataUuid, value, key } = data;
    if (type === "item" || type === "input" || type === "variable") {
      let id: any = null;
      let className: any = null;
      let resultValue: any = null;
      if (
        typeof value === "string" ||
        (Array.isArray(value) && value?.length === 0)
      ) {
        id = "value";
      } else {
        id = "";
      }

      if (
        typeof value === "string" ||
        (Array.isArray(value) && value?.length === 0)
      ) {
        className = classes.Value;
      } else {
        className = [classes.Value, classes.ValueNum].join(" ");
      }
      if (typeof value === "string" || typeof value === "number") {
        const val =
          typeof value === "string" ? value?.replace(/["']/g, "") : value;

        if (
          (status === "edit" || status === "writing") &&
          dataUuid === item?.uuid &&
          dataId?.startsWith("item")
        ) {
          resultValue = (
            <input
              data-type="value"
              onChange={(e) => changeInputHandler(e, data, "value")}
              className={classes.ValueInput}
              defaultValue={val}
            />
          );
        } else {
          resultValue =
            key.length + value.toString().length >= 28
              ? val.toString()?.substring(0, 15) + "..."
              : val;
        }
      } else if (typeof value === "object") {
        if (value?.length > 0) {
          resultValue = `{${value?.length}}`;
        } else if (value?.length === 0) {
          if (
            (status === "edit" || status === "writing") &&
            dataUuid === item?.uuid
          ) {
            resultValue = (
              <input
                data-type="value"
                onChange={(e) => changeInputHandler(e, data, "value")}
                className={classes.ValueInput}
                defaultValue={"value"}
              />
            );
          } else {
            resultValue = "value";
          }
        } else {
          resultValue = value;
        }
      }

      result = (
        <span
          id={id}
          title={`${key}:${value}`}
          style={
            data?.type === "item" || data?.type === "group"
              ? { left: "3vh" }
              : { left: 0 }
          }
          className={className}
        >
          {resultValue}
        </span>
      );
    } else if (
      data?.type === "group" &&
      Array.isArray(data?.value) &&
      data?.value?.length > 0
    ) {
      result = (
        <span
          style={
            data?.type === "item" || data?.type === "group"
              ? { left: "3vh" }
              : { left: 0 }
          }
          className={
            typeof data?.value === "string" ||
            (Array.isArray(data?.value) && data?.value?.length === 0)
              ? classes.Value
              : [classes.Value, classes.ValueNum].join(" ")
          }
        >
          {`{${data?.value?.length}}`}
        </span>
      );
    }

    return result;
  };
  return (
    <>
      <div
        id="parent"
        title={data?.type}
        className={classes.Parent}
        data-no={no}
        data-id={data?.id}
        data-uuid={data?.uuid}
        data-type={data?.type}
        data-index={index}
        // draggable={
        //   (data.type === "group" ||
        //     typeof data?.value === "string" ||
        //     (Array.isArray(data?.value) && data?.value?.length === 0)) &&
        //   message === OutputMessages.EMPTY
        //     ? true
        //     : false
        // }
        draggable={true
        }
        onDragStart={(e) => dragStartHandler(e, data)}
        onDragEnter={dragOverItemHandler}
        onDragLeave={dragLeaveItemHandler}
        onDrop={dropItemHandler}
        onClick={clickHandler}
      >
        {(data?.type === "item" || data?.type === "group") && (
          <span className={classes.TypeIcon}>
            {data?.type === "item" ? <ItemIcon /> : <GroupIcon />}
          </span>
        )}

        <span className={classes.Icon}>
          <img
            id="icon"
            className={data?.value?.length === 0 ? classes.CloseListIcon : ""}
            src={icons.triangle}
            alt="icon"
          />
        </span>
        {getKey()}
        {getType()}
        {getValue()}
        <div
          id="detail"
          style={
            (status === "edit" || status === "writing") &&
            data.uuid === item?.uuid
              ? { display: "flex" }
              : { display: "none" }
          }
          className={classes.Detail}
        >
          <span>
            {status === "writing" &&
            data.uuid === item?.uuid &&
            message !== OutputMessages.NULL ? (
              <Active handler={(e) => saveItemHandler(e, data)} />
            ) : message !== OutputMessages.NULL ? (
              <Pen handler={(e) => editItemHandler(e, data)} />
            ) : null}
          </span>
          <span>
            {(status === "edit" || status === "writing") &&
            data.uuid === item?.uuid ? (
              <Cancel handler={(e) => cancelItemHandler(e, data)} />
            ) : message !== OutputMessages.NULL ? (
              <Delete handler={(e) => deleteItemHandler(e, data)} />
            ) : null}
          </span>
          <span>
            <Sort down={(e) => sortHandler(e, data)} />
          </span>
        </div>
        {message === OutputMessages.UNFINISHED_SAVE &&
        item?.uuid === data.uuid ? (
          <Tooltip />
        ) : message === OutputMessages.NULL && item?.uuid === data.uuid ? (
          <Tooltip />
        ) : null}
      </div>

      {children}
    </>
  );
};
