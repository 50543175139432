import React from "react";
import classes from "./Checkbox.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/combineReducer";
import { addCheckbox } from "../../../store/actions/action";
import { icons } from "../../../settings/settings";
// import { addCheckboxDecision } from '../../../store/actions/actionsDecision';

interface CheckboxProps {
  id?: number;
  section?: string;
  isIterate: boolean
}

const Checkbox: React.FC<CheckboxProps> = ({ id, section, isIterate }, props: any) => {
  const dispatch = useDispatch();

  const store = useSelector((state: RootState) => {
    return state;
  });
  const checkboxRef = React.useRef(null);

  const checkedHandler = (e: any) => {
    if (store?.reducer.checkboxList.length > 0) {
      let mass = [...store.reducer.checkboxList];
      mass.forEach((elem: any, i: number) => {
        if (elem.id === id) {
          elem.checked = e.target.checked;
          let index = mass.indexOf(elem);
          mass.splice(index, 1);
          dispatch(
            addCheckbox({
              id: mass[index]?.id,
              checked: mass[index]?.checked,
            })
          );
        } else {
          dispatch(
            addCheckbox({
              id: id,
              checked: e.target.checked,
            })
          );
        }
      });
    } else {
      dispatch(
        addCheckbox({
          id: id,
          checked: e.target.checked,
        })
      );
    }
  };

  return (
    <label className={classes.Label}>
      <input
        data-name="checkbox"
        onChange={checkedHandler}
        id={`${id}`}
        type="checkbox"
        ref={checkboxRef}
        checked={isIterate}
      />

      <img src={icons.checkbox} alt="checked icon" />
      {section && section}
    </label>
  );
};

export default Checkbox;
