import { StrategyApproveFields, StrategyActionsTypes } from './../../../types/typesStrategy';
export const initialState: StrategyApproveFields = {
  strategy_name: "",
  app_name: "",
  approved_by: null,
  auth_key: "",
  auth_token: "",
  build: "",
  destination_port: "",
  id: 0,
  strategy: 0,
  version: "",
}

const reducerApprove = (state = initialState, action: StrategyActionsTypes): StrategyApproveFields => {
  switch (action.type) {
    case "STRATEGY_APPROVE_FIELDS":
      const {strategy_name, app_name, approved_by, auth_key, auth_token, build, destination_port, id,
      strategy, version} = action.payload;

      return {
        strategy_name,
        app_name,
        approved_by,
        auth_key,
        auth_token,
        build,
        destination_port,
        id,
        strategy,
        version,
      };
    default:
      return state;
  }
}

export default reducerApprove