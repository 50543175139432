import { StrategyActionsTypes, StrategyFunctionData } from "../../../../types/typesStrategy";

const initialState = {
    function_data: []
}

const reducerFunctionData = (state = initialState, actions: StrategyActionsTypes): StrategyFunctionData => {
    switch (actions.type) {
        case "STRATEGY_FUNCTIONS":
            const {function_data} = actions.payload
            return {
                function_data
            }
    
        default:
            return state;
    }
}

export default reducerFunctionData