  import DB from "../layout/DB/Storage";
import { request } from "./request";
import { endpoints } from "../api/endpoints";
import {
  ApiDebugModes,
  ElementTypes,
  messages,
  storageKeys,
} from "../settings/settings";
import { checkElementConnections } from "./checkElementConnections";
import scssVariables from "../assets/scss/variables.module.scss";
import { manipulateExecutionData } from "./manipulateExecutionData";

const {
  strategy_designer: { strategy },
} = endpoints;

interface IExecuteStrategy {
  state: object;
  setState: Function;
  activeElementId: string;
  debugMode: ApiDebugModes;
  elementPresentData?: Record<string, string | object>;
  elementPreviousData?: Record<string, any>;
  elementType?: ElementTypes;
}

export const executeStrategy = ({
  state,
  setState,
  activeElementId,
  debugMode,
  elementPresentData,
  elementPreviousData,
  elementType,
 }: IExecuteStrategy): any => { 
  const db = new DB(storageKeys.boardElements);
  setState({
    ...state,
    visible: true,
    topMessage: messages.messageWaitForResponse,
    bgColor: scssVariables.colorButtonBlue,
    txtColor: scssVariables.colorTextWhite,
  });

  function manipulateData(strategyData: Array<object>, elementId: string) {
    elementId = elementId === "output_format" ? "output" : elementId;

    function filterElements(element: any) {
      return element.sourceId !== elementId;
    }

    const copyOfDataWithoutSpecElement = [...strategyData]
      ?.filter(filterElements)
      ?.map((element: any) => {
        if (element.breakPoint) {
          element.breakPoint = "False";
          return element;
        } else {
          return element;
        }
      });

    let specificElement: any = [...strategyData]?.find((element: any) => {
      return element.sourceId === elementId;
    });

    if (elementPresentData && elementPreviousData && elementType) {
      specificElement.data = manipulateExecutionData({
        elementState: elementPresentData,
        elementType,
        elementPreviousData,
      });
    }

    specificElement.breakPoint = "True";

    copyOfDataWithoutSpecElement.push({ ...specificElement });

    return [...copyOfDataWithoutSpecElement];
  }

  function generateStrategyEngineData(strategyData: Array<object>) {
    const strategyDetails = sessionStorage.getItem(storageKeys.activeStrategy);
    const userDetails = sessionStorage.getItem(storageKeys.userDetails);
    let strategy: any = {};
    let user: any = {};
    if (strategyDetails && userDetails) {
      strategy = JSON.parse(strategyDetails);
      user = JSON.parse(userDetails);
    }

    return {
      user: user?.user,
      name: strategy?.name,
      version: strategy?.version,
      status: 1,
      strategy_data: manipulateData(strategyData, activeElementId),
    };
  }

  function runEngine(data: object) {
    const strategyDetails = sessionStorage.getItem(storageKeys.activeStrategy);
    if (strategyDetails) {
      const { id } = JSON.parse(strategyDetails);
      let { strategy_data } = data as any;
      let filterData = strategy_data?.filter(
        (f: any) => f.type === "product"
      ) as Array<any>;
      let filterCodeMessageData = strategy_data?.filter(
        (f: any) => f.type === "code_message_list"
      ) as Array<any>;
      let fData = filterData?.map((m: any) => {
        const {
          data: { mappData },
        } = m;
        let newMappData = mappData?.map(         
          ({ decision_label, product_group, status_id, type }: any) => {
            return {
              decision_label: decision_label,
              product_group: product_group,
              status_id: status_id,
              type: type
            };
          }
        );
        return { ...m, data: { ...m.data, mappData: newMappData } };
      });
      let fCodeMessageData = filterCodeMessageData?.map((m: any) => {
        const {
          data: { code_message_list },
        } = m;
        let newMappData = code_message_list?.map(({ code, message }: any) => {
          return { code: code, message: message };
        });
        return { ...m, data: { ...m.data, code_message_list: newMappData } };
      });
      let newData = strategy_data?.filter(
        (f: any) => f.type !== "product"
      ) as Array<any>;
      for (const item of fData) {
        newData.push(item);
      }
      let newData2 = newData?.filter(
        (f: any) => f.type !== "code_message_list"
      ) as Array<any>;
      for (const item of fCodeMessageData) {
        newData2.push(item);
      }    
      const sendData = { ...data, strategy_data: newData2 };
      request
        .patch(strategy.patch(id), sendData, null, debugMode)
        .then((res) => {
          const { success, response } = res;

          if (success) {
            if ("debug_breakpoint" in response) {
              setState({
                ...state,
                visible: true,
                topMessage:
                  messages.messageApiResponseFieldMissing("Breakpoint"),
                bgColor: scssVariables.colorButtonRed,
                txtColor: scssVariables.colorTextWhite,
              });
              return;
            }

            const { debug_breakpoints } = response;

            if (!debug_breakpoints) {
              if (response) {
                setState({
                  ...state,
                  visible: true,
                  topMessage: messages.messageEmptyResponse,
                  data: null,
                  bgColor: scssVariables.colorButtonRed,
                  txtColor: scssVariables.colorTextWhite,
                });
              } else {
                setState({
                  ...state,
                  visible: true,
                  topMessage: messages.messageEmptyResponse,
                  data: null,
                  bgColor: scssVariables.colorButtonRed,
                  txtColor: scssVariables.colorTextWhite,
                });
                return;
              }
            }
            switch (debugMode) {
              case ApiDebugModes.BREAKPOINT: {
                const entries = debug_breakpoints && Object.entries(debug_breakpoints);
                if (entries?.length > 0) {
                  const [key, value] = entries[0];
                  setState({
                    ...state,
                    visible: true,
                    topMessage:
                      messages.messageStrategyKeyExecuteSuccessful(key),
                    data: value,
                    bgColor: scssVariables.colorButtonGreen,
                    txtColor: scssVariables.colorTextWhite,
                  });
                } else {
                  setState({
                    ...state,
                    visible: true,
                    topMessage: messages.messageEmptyResponse,
                    data: null,
                    bgColor: scssVariables.colorButtonRed,
                    txtColor: scssVariables.colorTextWhite,
                  });
                }
                break;
              }
              case ApiDebugModes.OUTPUT: {
                setState({
                  ...state,
                  visible: true,
                  topMessage: messages.messageStrategyExecuteSuccessful,
                  data: response,
                  bgColor: scssVariables.colorButtonGreen,
                  txtColor: scssVariables.colorTextWhite,
                });
              }
            }
          } else {
            const { code, message } = response;
            console.warn(messages.messageApiPatchStatusFalse, response);

            setState({
              ...state,
              visible: true,
              topMessage: messages.messageCodeMessage(`\n${code} ${message}`),
              bgColor: scssVariables.colorButtonRed,
              txtColor: scssVariables.colorTextWhite,
            });
          }
        })
        .catch((error) => {
          
          console.error(messages.messageApiPatchError, error);
          setState({
            ...state,
            visible: true,
            topMessage: messages.messageApiInternalError,
            bgColor: scssVariables.colorButtonRed,
            txtColor: scssVariables.colorTextWhite,
          });
        });
    }
  }

  db.fetchAll()
    .then((res: any) => {
      const { status, data } = res;

      if (status) {
        checkElementConnections(data)
          .then(() => {
            runEngine(generateStrategyEngineData([...data]));
          })
          .catch(() => {
            setState({
              ...state,
              visible: true,
              topMessage: messages.messageUncompletedElementConnection,
              bgColor: scssVariables.colorButtonRed,
              txtColor: scssVariables.colorTextWhite,
            });
          });
      } else {
        console.warn(messages.messageStorageFetchStatusFalse, res);
      }
    })
    .catch((error) => {
      console.error(messages.messageStorageFetchError, error);
    });
};
