import { StrategyActionsTypes, StrategyBoardSidePanelInputCreateObject } from "../../../../../types/typesStrategy";

const initialState: StrategyBoardSidePanelInputCreateObject = {
    data: {}
}

const reducerInputCreateObject = (state = initialState, action: StrategyActionsTypes):StrategyBoardSidePanelInputCreateObject => {
    switch (action.type) {
        case "STRATEGY_BOARD_SIDE_PANEL_INPUT_CREATE_OBJECT":
            const {data} = action.payload
            return {
                data
            }
    
        default:
            return state;
    }
}

export default reducerInputCreateObject