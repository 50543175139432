import { StrategyActionsTypes, StrategyBoardType, STRATEGY_BOARD_TYPE } from "../../../../types/typesStrategy";


const initialState: StrategyBoardType = {
    element_type:[],
}

const reducer = (state = initialState, action: StrategyActionsTypes): StrategyBoardType => {

    switch (action.type) {

        case STRATEGY_BOARD_TYPE:
            const {element_type} = action.payload;
            
            return {
                element_type
            }

        default:
            return state

    }
}

export default reducer