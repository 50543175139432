import { elementSize } from "../../../../../../settings/settings";
import { change_destination } from "../change_destination_arrow/main";
import { draw_arrow } from "../draw_arrow.handler";
import { create_text, move_text } from "../element_text.handler";
import { listener } from "./listener";
import { createOutput } from "./output_formats";
import {createErrors } from "./system_errors";

const arrow_type ={
    top:'l-4 0 ,l4 -8 ,l4 8 ,l-4 0',
    right:'l0 -5 ,l8 5 ,l-8 5 ,l0 -5',
    bottom:'l4 0 ,l-4 8 ,l-4 -8 ,l4 0',
    left:'l0 -5,l-8 5 ,l8 5,l0 -5',
}
export const createEndpoint = (data:object)=>{
  
    let xmlns = "http://www.w3.org/2000/svg";
    const group = document.createElementNS(xmlns,'g') as SVGGElement;

    group.setAttributeNS(null,'data-id','endpoint');
    group.setAttributeNS(null,'id','endpoint');
    group.setAttributeNS(null,'data-type','figure');

    const newElement = document.createElementNS(xmlns,'rect') as SVGRectElement;
    newElement.setAttributeNS(null,'data-id','endpoint');
    newElement.setAttributeNS(null,'data-type','endpoint');

    for(const [key,value] of Object.entries(data)){
        newElement.setAttributeNS(null,key,value);
    }

 

    
    const endpoint = listener(newElement);
    const output = createOutput(data);
    const errors = createErrors(data)

    group.appendChild(endpoint);
    let text_end = create_text(endpoint);
    group.appendChild(text_end)

    group.appendChild(output);
   

    group.appendChild(errors);





    return group;

}

export const dragEndpoint = (coord:any,target:any)=>{
    const svg = document.querySelector(`#svg_board`) as SVGElement;
    const elem = svg.querySelector('rect[data-id = "endpoint"]') as SVGRectElement;
    
    if(target.dataset.type ==='output_format'){
        const arrow = svg.querySelector('path[data-contact = "endpoint-output_format"]') as SVGPathElement;

        let drawn:string = `M${+coord.x- (58)} ${+coord.y+35},h20,v0,h20,${arrow_type.right}`;
        arrow.setAttributeNS(null,'d',drawn);
        elem.setAttributeNS(null,'x',`${+coord.x - (70+58+12)}`);
        elem.setAttributeNS(null,'y',`${+coord.y}`);
        
        move_text(elem)
        draw_arrow(elem,{x:+coord.x - (70+58+12),y:+coord.y})

    }
    if(target.dataset.type ==='system_errors'){
        const arrow = svg.querySelector('path[data-contact = "endpoint-system_errors"]') as SVGPathElement;

        let drawn:string = `M${+coord.x+ elementSize.system_error.width/2 } ${+coord.y - (58)},v20,h0,v20,${arrow_type.bottom}`;
        arrow.setAttributeNS(null,'d',drawn);
        elem.setAttributeNS(null,'x',`${+coord.x + Math.abs(70 - elementSize.system_error.width)/2}`);
        elem.setAttributeNS(null,'y',`${+coord.y - (70+58+12)}`);
        move_text(elem)
        draw_arrow(elem,{x:+coord.x + Math.abs(70 - elementSize.system_error.width)/2,y:+coord.y - (70+58+12)})


    }
    change_destination<SVGRectElement>(elem)

}