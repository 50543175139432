import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import classes from './Table.module.scss'
import { RootState } from '../../../store/combineReducer'
import ScoreCardLevel from './ScoreCardLevel'
import CategoryLevel from './CategoryLevel'
import CriteriaLevel from './CriteriaLevel'
import AttributeLevel from './AttributeLevel'



const ScoreCardTable: FC = () => {


    const state = useSelector((state: RootState) => state)
    const {
        reducerScoreCard: {
            scoreCardLevel: { level }
        }
    } = state

    const levels = {
        scoreCard: 1,
        category: 2,
        criteria: 3,
        attribute: 4
    }

    return (
        <section className={classes.ScoreCardTable}>

            {
                level === levels.scoreCard && <ScoreCardLevel />
            }
            {
                level === levels.category && <CategoryLevel />
            }
            {
                level === levels.criteria && <CriteriaLevel />
            }
            {
                level === levels.attribute && <AttributeLevel />
            }

        </section >
    )
}

export default ScoreCardTable;