import DB from "../../../../../layout/DB/Storage";
import React, {
  FC,
  useState,
  MouseEvent,
  DragEvent,
  useEffect,
  ChangeEvent,
  useRef,
  useLayoutEffect,
  useCallback,
} from "react";
import objectPath from "object-path";
import {
  ApiDebugModes,
  ButtonTypes,
  ElementTypes,
  icons,
  messages,
  ModalNames,
  OutputMessages,
  output_format_data,
  storage_key,
} from "../../../../../settings/settings";
import classes from "./Output.module.scss";
import { OUTPUT_ELEMENTS } from "../../../../../settings/settings";
import { v4 as uuidv4 } from "uuid";
import Tree from "./RightTree/Tree";
import { useDispatch, useSelector } from "react-redux";
import {
  handleStrategyBoardSidePanelContent,
  handleStrategyOutputFormatData,
  handleStrategyOutputFormatItemStatus,
  handleStrategyOutputFormatMessage,
} from "../../../../../store/actions/actionsStrategy";
import { RootState } from "../../../../../store";
import TooltipV2 from "../../../../../components/UI/Tooltip/TooltipV2";
import { executeStrategy } from "../../../../../helpers/executeStrategy";
import OutputDataViewer from "./LeftTree/LeftTree";
import { GroupIcon, ItemIcon } from "../../../../../components/UI/Svg/Svg";
import Modal from "../../../../../components/UI/Modal/Modal";
import {
  closeModal,
  openModal,
} from "../../../../../store/actions/actionsModal";

type ItemType = {
  type: string;
  uuid: string;
  parent?: string;
  staticKey?: string;
  id: string;
  key: string;
  value: string | object[];
};
const Output: FC = () => {
  const [elements, setElements] = useState<object[]>([]);
  const [fullScreenStatus, setFullScreenStatus] = useState<boolean>(false);
  const [changeStatus, setChangeStatus] = useState<boolean>(false);
  const [tooltipState, setTooltipState] = useState<any>({
    visible: false,
    data: undefined,
    bgColor: undefined,
    txtColor: undefined,
    topMessage: undefined,
    bottomMessage:
      "This notification may show reduced result. For full result please choose Debug mode.",
  });
  const [outputData, setOutputData] = useState<any>({
    id: "",
    label: "",
    outputValue: "",
    resultVar: "",
  });
  const [outputChangedData, setOutputChangedData] = useState<any>({
    id: "",
    label: "",
    outputValue: "",
    resultVar: "",
  });
  const [treeItem, setTreeItem] = useState<any>(null);
  const [sortableStatus,setSortableStatus] = useState<boolean>(false);
  const refOutputPanel = useRef<HTMLElement>(null);

  const dispatch = useDispatch();

  const {
    reducerStrategy: {
      StrategyBoardElements: {
        ElementInput: { element_input },
        StrategyOutputFormatData: { data },
        StrategyOutputFormatItemStatus: { item: store_item, status },
        StrategyOutputFormatMessage: { message },
      },
    },
    reducerModal: { modalState },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    const db = new DB(storage_key);
    db.fetchAll().then((res: any) => {
      const { status } = res;
      if (status) {
        const { data } = res;
        if (data?.length > 0) {
          const filtered_data = data?.filter((f: any) =>
            OUTPUT_ELEMENTS.includes(f.type)
          ) as object[];
          setElements([...filtered_data]);
        }
      }
    });
  }, []);

  useLayoutEffect(() => {
    const db = new DB(storage_key);
    db.fetch({ type: "output" }).then((res: any) => {
      const { status, data } = res;
      if (status && data) {
        const { overlays, data: output_data } = data;
        dispatch(handleStrategyOutputFormatData({ data: [...overlays] }));
        setOutputData({ ...output_data });
        setOutputChangedData({ ...output_data });
      }
    });
  }, [dispatch]);
  const createObjectHandler = (
    id: string,
    type: string = "",
    index: string
  ) => {
    const unique_id = uuidv4();
    let new_item = {} as ItemType;
    let ref_data = [...data] as ItemType[] | any[];

    if (id) {
      const [find_item] = elements?.filter((f: any) => f.sourceId === id);
      if (find_item) {
        const {
          data: { label },
        } = find_item as any;

        if (!index) {
          new_item = {
            sourceId:id,
            type: "variable",
            id: id,
            uuid: unique_id,
            key: label,
            value: label,
          } as ItemType;
        } else {
          const select_index = index?.split("-") as string[];
          if (select_index) {
            const { chaining_mass } = getSelectIndexHandle(index, "create");
            const _concat_chaining2 = chaining_mass.join("") as any;
            let c = _concat_chaining2.split(".");
            c.shift();
            let join_c = c.join(".");
            const model = objectPath(ref_data);
            const { id, key, value, uuid } = model.get(join_c) as ItemType;

            new_item = {
              sourceId:id,
              type: "variable",
              id: id,
              uuid: uuid,
              key: key,
              value: value,
            } as ItemType;
          }
        }
      }
    } else {
      if (!index) {
        new_item = {
          type: type === "item" ? type : "group",
          id: type + "-" + unique_id,
          uuid: type + "-" + unique_id,
          key: type === "item" ? "key" : "group",
          value: "value",
        } as ItemType;
      } else {
        const select_index = index?.split("-") as string[];
        if (select_index) {
          const { chaining_mass } = getSelectIndexHandle(index, "create");
          const _concat_chaining = chaining_mass.join("") as any;
          let c = _concat_chaining.split(".");
          c.shift();
          let join_c = c.join(".");
          const model = objectPath(ref_data);
          const { id, key, value, uuid, type, parent, staticKey } = model.get(
            join_c
          ) as ItemType;
          new_item = {
            type: type,
            id: id,
            uuid: uuid,
            key: key,
            value: value,
            parent: parent,
            staticKey: staticKey,
          } as ItemType;
        }
      }
    }

    return new_item;
  };

  const selectItemHandler = (
    event: MouseEvent<HTMLDivElement>,
    item: ItemType | null = null
  ): void => {
    event.preventDefault();
    event.stopPropagation();

    const element = event.currentTarget as HTMLDivElement;

    const uuid = element?.dataset.uuid as string;
    const child_parent = element?.parentNode as HTMLDivElement;
    const child = child_parent?.querySelector(
      `[id = "child"][data-uuid = "${uuid}"]`
    ) as HTMLDivElement;

    if (child) {
      const check_data_show = child.dataset.show as string;

      if (check_data_show === "true") {
        child?.classList.toggle(classes.ActiveListClick);
        let icon = element?.querySelector("#icon") as HTMLImageElement;
        icon?.classList.toggle(classes.ActiveListIcon);
      }
    }
  };

  const dragTreeHandler = (
    event: DragEvent<HTMLSpanElement>,
    item: any,
    fieldObjects: any
  ) => {
    const { id, type } = fieldObjects;
    const _type =
      id.includes("decision") || id.includes("scorecard")
        ? "variable"
        : "input";
    const flatten = (_data: any) => {
      const array = _data;

      return array.reduce((acc: any, curr: any) => {
        const uuid = uuidv4();
        acc.push({
          sourceId:id,
          id: `${_type}-` + uuid,
          uuid: `${_type}-` + uuid,
          key:
            curr?.key !== curr?.parent && _type === "variable"
              ? `${curr?.parent}_${curr?.key}`
              : curr?.key,
          value:
            _type === "variable"
              ? type === "object"
                ? curr?.key
                : `${curr?.parent}.${curr?.key}`
              : Array.isArray(curr?.value)
              ? curr?.type === "array"
                ? `${curr?.parent}.${curr?.key}`
                : flatten(curr?.value)
              : curr?.value,
          type: _type,
          parent: curr?.parent,
          staticKey:
            curr?.key !== curr?.parent && _type === "variable"
              ? `${curr?.parent}_${curr?.key}`
              : curr?.key,
          staticValue: Array.isArray(curr?.value)
            ? curr?.type === "array"
              ? `${curr?.parent}.${curr?.key}`
              : flatten(curr?.value)
            : curr?.value,
          dataType: curr?.type,
        });

        return acc;
      }, []);
    };

    const ref_item = { ...flatten([fieldObjects]) } as any;
    setTreeItem(ref_item[0]);
    event.dataTransfer.setData("type", "tree");
  };

  const dragStartHandler = (
    event: DragEvent<HTMLDivElement>,
    item: string
  ): void => {
    const [variable_name] = elements.filter((f: any) => f.data.label === item);
    if (variable_name) {
      if (status !== "writing") {
        const {
          sourceId,
          data: { label },
        } = variable_name as any;
        event.dataTransfer.setData("id", sourceId);
        event.dataTransfer.setData("label", label);

        event.dataTransfer.effectAllowed = "copyMove";
      } else {
        event.preventDefault();

        if (message !== OutputMessages.NULL) {
          dispatch(
            handleStrategyOutputFormatMessage({
              message: OutputMessages.UNFINISHED_SAVE,
            })
          );
        }
      }
    }
  };

  const dragOverHandler = (event: DragEvent) => {
    event.preventDefault();
  };
  const dropHandler = (event: DragEvent) => {
    event.preventDefault();
    if (treeItem) {
      dispatch(handleStrategyOutputFormatData({ data: [...data, treeItem] }));
      getOutputData([...data, treeItem]);
      setTreeItem(null);
      setChangeStatus(true);
    } else {
      const id = event.dataTransfer.getData("id") as string;
      const type = event.dataTransfer.getData("type") as string;
      const label = event.dataTransfer.getData("label") as string;

      const select_value_name = event.dataTransfer.getData("value") as string;
      const productData = event.dataTransfer.getData(
        "dragging_product"
      ) as string;
      const objData = productData ? JSON.parse(productData) : null;
      const elementId = id ? id : objData?.key;
      if (elementId && !type) {
        const new_item = createObjectHandler(elementId, "", "") as ItemType;
        if (new_item) {
          let check_dublicate = data?.filter(
            (f: any) =>
              typeof f.value === "string" &&
              (f.value === label || f.value === select_value_name)
          ) as ItemType[];
          if (check_dublicate.length === 0) {
            dispatch(
              handleStrategyOutputFormatData({ data: [...data, new_item] })
            );
            getOutputData([...data, new_item]);
            setChangeStatus(true);
          }
        }
      }
    }
  };

  const getItemHandle = (ref_data: ItemType[], index: any[]) => {
    if (!index) return;
    let get_item = { ...ref_data?.[index?.[0]] } as any;
    let mass_chaining = [`ref_data.${index?.[0]}`] as string[];
    index?.splice(0, 1);
    for (let i of index) {
      get_item =
        Array.isArray(get_item.value) && get_item.value.length > 0
          ? get_item.value[i]
          : { ...get_item };
      mass_chaining.push(`.value.${i}`);
    }

    return { get_item, mass_chaining };
  };
  const getSelectIndexHandle = (index: string, type: string = "") => {
    let ref_index_list = index.split("-") as string[];
    let sliced_index =
      ref_index_list.length === 1
        ? +ref_index_list[0]
        : +ref_index_list[ref_index_list.length - 1];
    let chaining_mass = [] as any;
    if (!type) {
      chaining_mass =
        ref_index_list.length > 1
          ? [`ref_data.${ref_index_list[0]}`]
          : ([`ref_data`] as any);
      if (ref_index_list.length === 2) {
        chaining_mass.push(`.value`);
      } else if (ref_index_list.length > 2) {
        ref_index_list.splice(0, 1);
        ref_index_list.pop();
        for (const i in ref_index_list) {
          chaining_mass.push(`.value.${ref_index_list[i]}`);
        }
        chaining_mass.push(`.value`);
      }
    } else if (type === "create") {
      chaining_mass = [`ref_data.${ref_index_list[0]}`];
      ref_index_list.splice(0, 1);

      for (const i in ref_index_list) {
        chaining_mass.push(`.value.${ref_index_list[i]}`);
      }
    }

    return { chaining_mass, sliced_index };
  };
  const dropItemHandler = (event: DragEvent<HTMLDivElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    const element = event.currentTarget as HTMLDivElement;

    if (element) {
      element.classList.remove(classes.ActiveListOver);
    }
    const drop_id = event.currentTarget.getAttribute("data-id") as string;
    const index = event.currentTarget.getAttribute("data-index") as string;

    if (!index) return;

    const id = event.dataTransfer.getData("id") as string;
    const drop_uuid = event.currentTarget.getAttribute("data-uuid") as string;
    const data_no = event.currentTarget.getAttribute("data-no") as string;
    const select_index = event.dataTransfer.getData("index") as string;
    const select_uuid = event.dataTransfer.getData("uuid") as string;
    const data_type = event.dataTransfer.getData("type") as string;
    if (data_type === "tree") {
      let new_item = {};
      let ref_data = [...data] as any[];
      const _split = index?.split("-") as string[];

      const { get_item, mass_chaining } = getItemHandle(
        ref_data,
        _split
      ) as any;
      const _concat = mass_chaining.join("") as any;
      if (get_item) {
        if (get_item.dataType === "object" || get_item.type === "group") {
          new_item = {
            ...get_item,
            value:
              Array.isArray(get_item?.value) && get_item.value.length > 0
                ? [...get_item?.value, treeItem]
                : [treeItem],
          } as any;
          // if(sortableStatus){
          //   callSortable(new_item,get_item);
          // }
          // setSortableStatus(false);
          if (new_item) {
            const check_dublicate =
              typeof get_item?.value === "string"
                ? []
                : typeof get_item?.value === "object"
                ? get_item?.value?.filter(
                    (f: any) => f.value === treeItem.value
                  )
                : [];

            if (check_dublicate?.length === 0) {
              let c = _concat.split(".");
              c.shift();
              let join_c = c.join(".");
              objectPath.set(ref_data, join_c, new_item);
              dispatch(handleStrategyOutputFormatData({ data: [...ref_data] }));
              getOutputData(ref_data);

              setChangeStatus(true);
              const model = objectPath(ref_data);
              let c3 = _concat.split(".");
              c3.shift();
              let join_c3 = c3.join(".");
              if (model.get(join_c3)?.length === 0) {
                c3.pop();
                let parent = c3.join(".");
                model.del(parent);
              }
            }
          }
        }
      }
    }
    if (id && drop_id && select_uuid !== drop_uuid) {
      if (data_no && index) {
        const create_item = (
          id.startsWith("item") ||
          id.startsWith("group") ||
          id.startsWith("input")
            ? createObjectHandler(
                "",
                id.startsWith("item")
                  ? "item"
                  : id.startsWith("group")
                  ? "group"
                  : "input",
                select_index
              )
            : createObjectHandler(id, "", select_index)
        ) as ItemType;
        if (create_item) {
          let new_item = {};
          let ref_data = [...data] as any[];
          const _split = index?.split("-") as string[];

          const { get_item, mass_chaining } = getItemHandle(
            ref_data,
            _split
          ) as any;
          if(sortableStatus){
            let create_sortable_item = {} as any;
            if(Object.keys(create_item).length>0){
              create_sortable_item = create_item
            }else{
               create_sortable_item = createObjectHandler("", "item", select_index);
            }
            callSortable(create_sortable_item,get_item);
            setSortableStatus(false);
            return;
          }
          
          const _concat2 = mass_chaining.join("") as any;
          if (get_item) {
            if (get_item.dataType === "object" || get_item.type === "group") {
              new_item = {
                ...get_item,
                value:
                  Array.isArray(get_item?.value) && get_item.value.length > 0
                    ? [...get_item?.value, create_item]
                    : create_item
                    ? [create_item]
                    : "null",
              } as any;
              
              if (new_item) {
                const check_dublicate =
                  typeof get_item?.value === "string"
                    ? []
                    : get_item.value.filter(
                        (f: any) => f.value === create_item.value
                      );

                if (check_dublicate?.length === 0) {
                  let c = _concat2.split(".");
                  c.shift();
                  let join_c = c.join(".");
                  objectPath.set(ref_data, join_c, new_item);

                  if (select_index) {
                    let { chaining_mass, sliced_index } = getSelectIndexHandle(
                      select_index
                    ) as any;
                    const _concat_chaining2 = chaining_mass.join("") as any;
                    const model = objectPath(ref_data);
                    let c2 = _concat_chaining2.split(".");
                    c2.shift();
                    c2.push(`${sliced_index}`);
                    let join_c2 = c2.join(".");
                    model.del(join_c2);

                    let c3 = _concat_chaining2.split(".");
                    c3.shift();
                    let join_c3 = c3.join(".");
                    if (model.get(join_c3)?.length === 0) {
                      c3.pop();
                      let parent = c3.join(".");
                      model.del(parent);
                    }
                  }

                  dispatch(
                    handleStrategyOutputFormatData({ data: [...ref_data] })
                  );
                  getOutputData(ref_data);

                  setChangeStatus(true);
                }
              }
            }
          }
        }
      }
    }
  };
  const dragLeaveItemHandler = (event: DragEvent): void => {
    event.preventDefault();

    const element = event.currentTarget as HTMLDivElement;
    if (element) {
      element.classList.remove(classes.ActiveListOver);
    }
  };

  const dragOverItemHandler = (event: DragEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    const element = event.currentTarget as HTMLDivElement;
    if (element) {
      element.classList.add(classes.ActiveListOver);
    }
  };
  const addItemHandler = (event: MouseEvent<HTMLButtonElement>): void => {
    if (status !== "writing") {
      const item = createObjectHandler("", "item", "") as ItemType;
      dispatch(handleStrategyOutputFormatData({ data: [...data, item] }));
      if (status === "edit") {
        dispatch(
          handleStrategyOutputFormatItemStatus({ status: "", item: undefined })
        );
      }
    } else {
      if (message !== OutputMessages.NULL) {
        dispatch(
          handleStrategyOutputFormatMessage({
            message: OutputMessages.UNFINISHED_SAVE,
          })
        );
      }
    }
  };
  const addGroupHandler = (event: MouseEvent<HTMLButtonElement>): void => {
    if (status !== "writing") {
      const group = createObjectHandler("", "group", "") as ItemType;
      dispatch(handleStrategyOutputFormatData({ data: [...data, group] }));
    } else {
      if (message !== OutputMessages.NULL) {
        dispatch(
          handleStrategyOutputFormatMessage({
            message: OutputMessages.UNFINISHED_SAVE,
          })
        );
      }
    }
  };

  const sortHandler = (
    event: DragEvent<HTMLDivElement>,
    item: ItemType
  ): void => {
    event.stopPropagation();
    setSortableStatus(true);
  };
  const callSortable = (start:any,end:any)=>{
      const copyOfData = [...data] as any[];
      const {id:idStart} = start;
      const {id:idEnd} = end;
      const indexStart = [...data].findIndex(item=>item.id === idStart);
      const indexEnd = [...data].findIndex(item=>item.id === idEnd);
      copyOfData[indexEnd] = start
      copyOfData[indexStart] = end

      dispatch(
        handleStrategyOutputFormatData({ data: [...copyOfData] })
      );
      // // setD
  }
  const dragStartItemHandler = (
    event: DragEvent<HTMLDivElement>,
    item: ItemType
  ): void => {
    event.stopPropagation();

    if (status !== "writing") {
      const { id, uuid: select_uuid } = item as any;
      event.dataTransfer.setData("id", id);
      const index = event.currentTarget.getAttribute("data-index") as string;
      event.dataTransfer.setData("index", index);
      event.dataTransfer.setData("uuid", select_uuid);
      event.dataTransfer.setData("type", "item");
      const value = event.currentTarget.querySelector(
        `span[id = "value"]`
      )! as HTMLSpanElement;
      const value_name = value?.textContent as string;
      event.dataTransfer.setData("value", value_name);
      event.dataTransfer.effectAllowed = "copyMove";
    } else {
      if (message !== OutputMessages.NULL) {
        dispatch(
          handleStrategyOutputFormatMessage({
            message: OutputMessages.UNFINISHED_SAVE,
          })
        );
      }
    }
  };

  const editItemHandler = (
    e: MouseEvent<SVGSVGElement>,
    item: ItemType
  ): void => {
    e.stopPropagation();
    if (status !== "writing") {
      dispatch(
        handleStrategyOutputFormatItemStatus({ status: "edit", item: item })
      );
    } else {
      dispatch(
        handleStrategyOutputFormatMessage({
          message: OutputMessages.UNFINISHED_SAVE,
        })
      );
    }
  };
  const deleteItemHandler = (
    e: MouseEvent<SVGSVGElement>,
    item: ItemType
  ): void => {
    e.stopPropagation();
    if (status !== "writing") {
      dispatch(
        handleStrategyOutputFormatItemStatus({ status: "delete", item: item })
      );
      const element = e.currentTarget.closest("#parent")! as HTMLDivElement;
      if (element) {
        const ref_data = [...data] as any[];
        const index = element.dataset.index as string;
        if (index) {
          let { chaining_mass, sliced_index } = getSelectIndexHandle(
            index
          ) as any;
          const _concat_chaining2 = chaining_mass.join("") as any;
          const model = objectPath(ref_data);
          let c2 = _concat_chaining2.split(".");
          let c3 = _concat_chaining2.split(".");
          c2.shift();
          c3.shift();
          c2.push(`${sliced_index}`);
          let join_c2 = c2.join(".");
          let join_c3 = c3.join(".");
          model.del(join_c2);
          if (model.get(join_c3)?.length === 0) {
            c3.pop();
            let parent = c3.join(".");
            model.del(parent);
          }
          dispatch(handleStrategyOutputFormatData({ data: ref_data }));
          getOutputData(ref_data);
        }
      }
    } else {
      dispatch(
        handleStrategyOutputFormatMessage({
          message: OutputMessages.UNFINISHED_SAVE,
        })
      );
    }
  };
  const saveItemHandler = (
    e: MouseEvent<SVGSVGElement>,
    item: ItemType
  ): void => {
    const parent = e.currentTarget.closest("#parent") as HTMLDivElement;
    let ref_data = [...data] as any[];
    if (parent) {
      const index = parent.dataset.index as string;
      const index_mass = index?.split("-") as string[];
      const val =
        typeof store_item?.value === "string"
          ? store_item.value
              .replace(/["']/g, "")
              .replace(/[`~!@#$%^&*(){}_|+\-=?\s;:'",.<>]/gi, "_")
              .replace(/\\/g, "/")
          : store_item?.value;

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let new_item = {
        ...store_item,
        key: store_item?.key
          .replace(/[`~!@#$%^&*(){}_|+\-=?\s;:'",.<>]/gi, "_")
          .replace(/^[0-9].*$/gi, "_")
          .replace(/\\/g, "/"),
        value: item?.type === "item" ? `'${val}'` : val,
      };
      const { mass_chaining } = getItemHandle(ref_data, index_mass) as any;
      const _concat = mass_chaining.join("") as any;

      let c = _concat.split(".");
      c.shift();
      let join_c = c.join(".");
      objectPath.set(ref_data, join_c, new_item);

      dispatch(handleStrategyOutputFormatItemStatus({ status: "" }));
      dispatch(handleStrategyOutputFormatData({ data: ref_data }));
      dispatch(
        handleStrategyOutputFormatMessage({ message: OutputMessages.EMPTY })
      );
      getOutputData(ref_data)
    }
  };
  const cancelItemHandler = (
    e: MouseEvent<SVGSVGElement>,
    item: ItemType
  ): void => {
    e.stopPropagation();

    dispatch(handleStrategyOutputFormatItemStatus({ status: "", item: item }));

    dispatch(
      handleStrategyOutputFormatMessage({ message: OutputMessages.EMPTY })
    );
  };
  const changeInputHandler = (
    e: ChangeEvent<HTMLInputElement>,
    item: ItemType,
    type: string
  ): void => {
    e.stopPropagation();
    if (e.currentTarget.dataset.type === "key") {
      e.currentTarget.value = e.currentTarget.value
        .replace(/[`~!@#$%^&*(){}+_|+\-=?\s;:'",.<>/]/gi, "_")
        .replace(/^[0-9].*$/gi, "_")
        .replace(/\\/g, "/");
    } else {
      e.currentTarget.value = e.currentTarget.value
        .replace(/[`~!@#$%^&*(){}+_|+\-=?\s;:'",.<>/]/gi, "_")
        .replace(/\\/g, "/");
    }

    const new_value = e.currentTarget.value as string;

    if (type === "key") {
      if (e.currentTarget.value.trim() === "") {
        dispatch(
          handleStrategyOutputFormatMessage({ message: OutputMessages.NULL })
        );
      } else {
        let new_item = {
          ...store_item,
          key: new_value,
        } as ItemType;
        dispatch(
          handleStrategyOutputFormatItemStatus({
            status: "writing",
            item: new_item,
          })
        );
        // dispatch(
        //   handleStrategyOutputFormatMessage({
        //     message: OutputMessages.UNFINISHED_SAVE,
        //   })
        // );
      }
    }
    if (type === "value") {
      if (e.currentTarget.value.trim() === "") {
        dispatch(
          handleStrategyOutputFormatMessage({ message: OutputMessages.NULL })
        );
      } else {
        let new_item = {
          ...store_item,
          value: new_value,
        } as ItemType;
        dispatch(
          handleStrategyOutputFormatItemStatus({
            status: "writing",
            item: new_item,
          })
        );
        dispatch(
          handleStrategyOutputFormatMessage({
            message: OutputMessages.UNFINISHED_SAVE,
          })
        );
      }
    }
  };
  const closePanel = () => {
    if (status !== "writing") {
      if (!refOutputPanel.current?.classList.contains(classes.ClosePanel)) {
        refOutputPanel.current?.classList.add(classes.ClosePanel);
      }
      setTimeout(() => {
        dispatch(handleStrategyBoardSidePanelContent({ content_value: null }));
      }, 1000);
    } else {
      if (message !== OutputMessages.NULL) {
        dispatch(
          handleStrategyOutputFormatMessage({
            message: OutputMessages.UNFINISHED_SAVE,
          })
        );
      }
    }
  };

  const getUpdatedData = useCallback(
    (output_data: ItemType[]) => {
      let svg = document.querySelector("#svg_board") as SVGSVGElement;
      let element = svg.querySelector(
        `#figure[data-id = ${element_input}]`
      ) as SVGAElement;
      let { x: left, y: top } = element.getBoundingClientRect();
      const ref_data = [...output_data] as ItemType[];
      const groupBy = (key: any, array: any) => {
        return (
          Array.isArray(array) &&
          array?.reduce((acc: any, obj: any) => {
            const isNumber = typeof obj[key] === "number";

            const property = !isNumber ? obj[key] : obj["parent"];
            acc[property] = acc[property] || [];
            acc[property] =
              typeof obj.value === "string" || typeof obj.value === "number"
                ? obj.type === "input"
                  ? obj.parent && obj.staticKey
                    ? `root.${obj.parent}.${obj.staticKey}`
                    : `${obj.value}`
                  : `${obj.value}`
                : groupBy("key", obj.value);
            return acc;
          }, {})
        );
      };
      const convert = (massive: any) => {
        let _obj: Array<any> = [];
        for (const [key, value] of massive) {
          if (typeof value === "string") {
            if (value !== "value") {
              _obj.push(`${key}:${value}`);
            }
          } else {
            if (convert(Object.entries(value)).length !== 0) {
              _obj.push(`${key}:{${convert(Object.entries(value))}}`);
            }
          }
        }
        return _obj;
      };
      let mass = convert(Object.entries(groupBy("key", ref_data)));
      let mass_to_string: string = "{" + mass.join(",") + "}";

      let output_obj: any = {
        ...output_format_data,
        sourceId: "output",
        style: `left:${left}px,top:${top - 2}px`,
        data: {
          ...output_format_data.data,
          id: "output",
          resultVar: element_input,
          outputValue: mass_to_string,
        },
        overlays: [...ref_data],
      };
      return { output_obj };
    },
    [element_input]
  );

  const getOutputData = useCallback(
    (new_data: any[] = []) => {
      if (new_data) {
        const {
          output_obj: { data: output_data },
        } = getUpdatedData([...new_data]);
        setOutputChangedData(output_data);
      }
    },
    [getUpdatedData]
  );

  const submitHandler = () => {
    if (status !== "writing") {
      const { output_obj } = getUpdatedData(data);
      const db = new DB(storage_key);
      if (output_obj) {
        db.add([output_obj]);
      }
      closePanel();
    } else {
      dispatch(
        handleStrategyOutputFormatMessage({
          message: OutputMessages.UNFINISHED_SAVE,
        })
      );
    }
  };
  const handleFullScreen = () => {
    const aside = refOutputPanel.current as HTMLElement;
    aside.classList.toggle(classes.FullScreen);
    setFullScreenStatus(!fullScreenStatus);
  };
  const checkChanges = () => {
    if (changeStatus) {
      dispatch(
        openModal({
          modalState: {
            visible: true,
            title: messages.titleClosePanel,
            question: messages.titleSureClosePanel,
            message: messages.messagePanelClosing,
            buttonMessage: messages.titleBtnClose,
            buttonType: ButtonTypes.PRIMARY,
            name: ModalNames.OUTPUT_FORMAT_PANEL,
          },
        })
      );
    } else {
      closePanel();
    }
  };

  const handleModalAction = (action: boolean) => {
    const { buttonType } = modalState;
    if (buttonType === ButtonTypes.PRIMARY && action) {
      closePanel();
    } else if (buttonType === ButtonTypes.WARNING) {
    }

    dispatch(closeModal());
  };

  const handleExecution = () => {
    executeStrategy({
      state: tooltipState,
      setState: setTooltipState,
      activeElementId: element_input,
      debugMode: ApiDebugModes.OUTPUT,
      elementType: ElementTypes.OUTPUT,
      elementPresentData: outputChangedData,
      elementPreviousData: outputData,
    });
  };
  return (
    <>
      <aside ref={refOutputPanel} className={classes.Aside}>
        <header className={classes.Header}>
          <div className={classes.CloseBtn} onClick={checkChanges}>
            <img src={icons.closeBtn} alt="icon" />
          </div>
          <div className={classes.Fls} onClick={handleFullScreen}>
            {!fullScreenStatus ? (
              <img
                src={icons.fullScreen}
                title="Full screen"
                alt="Full screen"
              />
            ) : (
              <img
                src={icons.exitFullScreen}
                title="Exit full screen"
                alt="Exit full screen"
              />
            )}
          </div>
          <div className={classes.Title}>output format</div>
          <div className={classes.Actions}>
            <TooltipV2
              visible={tooltipState?.visible}
              onVisible={(value: boolean) =>
                setTooltipState({
                  ...tooltipState,
                  visible: value,
                  data: undefined,
                })
              }
              data={tooltipState?.data}
              topMessage={tooltipState?.topMessage}
              bottomMessage={tooltipState?.bottomMessage}
              backgroundColor={tooltipState?.bgColor}
              textColor={tooltipState?.txtColor}
              margin={"4vh"}
              child={
                <img
                  className={classes.PlayIcon}
                  src={icons.strategyPlay}
                  alt="icon"
                  onClick={handleExecution}
                />
              }
            />
          </div>
        </header>
        <section className={classes.Content}>
          <aside className={classes.ContentLeft}>
            <header className={classes.Header}>All elements</header>
            {/* <nav className={classes.Nav}>
            <span title="Add Item" className={classes.Item}><ItemIcon handler={addItemHandler}/></span>
            <span title="Add Group" className={classes.Group}><GroupIcon handler={addGroupHandler}/></span>
          </nav> */}
            <section className={classes.Section}>
              <OutputDataViewer
                fullHeight={true}
                dragTreeHandler={dragTreeHandler}
                dragVariable={dragStartHandler}
                dragMaps={dragStartHandler}
              />
            </section>
          </aside>
          <aside className={classes.ContentRight}>
            <header className={classes.Header}>
              <span>Output</span>
              <nav className={classes.Nav}>
                <span
                  title="Add Item"
                  className={classes.Item}
                  onClick={addItemHandler}
                >
                  <ItemIcon /> <small>Add item</small>
                </span>
                <span
                  title="Add Group"
                  className={classes.Group}
                  onClick={addGroupHandler}
                >
                  <GroupIcon />
                  <small>Add group</small>
                </span>
              </nav>
            </header>
            <section
              onDrop={dropHandler}
              onDragOver={dragOverHandler}
              className={classes.Section}
            >
              <Tree
                changeInputHandler={changeInputHandler}
                saveItemHandler={saveItemHandler}
                cancelItemHandler={cancelItemHandler}
                editItemHandler={editItemHandler}
                deleteItemHandler={deleteItemHandler}
                dragStartItemHandler={dragStartItemHandler}
                dragOverItemHandler={dragOverItemHandler}
                dragLeaveItemHandler={dragLeaveItemHandler}
                dropItemHandler={dropItemHandler}
                clickHandler={selectItemHandler}
                dropHandler={dropHandler}
                sortHandler={sortHandler}
              />
              {/* <Item/> */}
            </section>
          </aside>
        </section>
        <footer className={classes.Footer}>
          <button onClick={submitHandler}>submit</button>
        </footer>
      </aside>
      <Modal
        title={modalState?.title}
        question={modalState?.question}
        message={modalState?.message}
        buttonMessage={modalState?.buttonMessage}
        buttonType={modalState?.buttonType}
        onAction={handleModalAction}
        visible={modalState?.visible}
        name={ModalNames.OUTPUT_FORMAT_PANEL}
      />
    </>
  );
};

export default Output;
