import { add_storage } from "./add_storage.handler";
import { remove_arrow_box } from "./remove_arrow_box.handler";
import { condition_label } from "./condition_arrow.handler";

export const drag_contact = (arrow: SVGPathElement) => {
    const svg = document.querySelector(`#svg_board`) as SVGElement;

    let arrow_over: any = null;
    const parent = svg.querySelector(`g[data-id = ${arrow.dataset.contact?.split('-')?.[0]}]`) as SVGGElement;
    const side = arrow.dataset.side;
    const box_group = parent?.querySelector('#arrow_box_group') as SVGGElement;
    const box = box_group?.querySelector(`g[id = ${side}_arrow_box_group]`) as SVGGElement;
    const group = box?.querySelector('#circle') as SVGRectElement;

    const arrow_type = {
        top: 'l-4 0 ,l4 -8 ,l4 8 ,l-4 0',
        right: 'l0 -5 ,l8 5 ,l-8 5 ,l0 -5',
        bottom: 'l4 0 ,l-4 8 ,l-4 -8 ,l4 0',
        left: 'l0 -5,l-8 5 ,l8 5,l0 -5',
    }
    function mousePosition(e: any) {
        let svg: any = document.querySelector('#svg_board') as SVGElement;

        let CTM = svg.getScreenCTM()



        return {
            x: (e.clientX - CTM.e) / CTM.a,
            y: (e.clientY - CTM.f) / CTM.d
        };
    }
    const id = arrow.dataset.contact?.split('-')?.[0]

    const arrowDownHandler = (e_down: any) => {
        let { x: a_x, y: a_y, width: a_width, height: a_height } = e_down.target.getBoundingClientRect();

        let __x: any = group?.getAttribute('x');
        let __y: any = group?.getAttribute('y');
        let __width: any = group?.getAttribute('width');
        let __height: any = group?.getAttribute('height');
        let _x = +__x;
        let _y = +__y;
        let _width = +__width;
        let _height = +__height
        let arrow_side: any = arrow.dataset.side;
        if (arrow_side === 'top') {
            if (Math.abs(e_down.y - a_y) < 10) {

                const arrowMoveHandler = (e_move: MouseEvent) => {
                    e_down.target.style.cursor = 'grab'
                    // let {x,y} = e_move;
                    let coord = mousePosition(e_move);
                    let { x, y } = coord;
                    let m: string = '';
                    let d: string = '';
                    if (arrow_side === 'top') {
                        m = `M${_x + _width / 2} ${_y + _height - 3}`;
                        d = `${m},v${(y - (_y + _height)) / 2},h${(x - (_x + _width / 2))},v${(y - (_y + _height)) / 2},${arrow_type.top}`
                    }

                    arrow.setAttributeNS(null, 'd', d);
                    condition_label(arrow);

                }
                const arrowOverHandler = (e_over: any) => {
                    if (e_over.target.tagName === 'rect' && e_over.target.dataset.type !== 'startpoint') {
                        arrow_over = e_over.target;


                    }
                    if (e_over.target.tagName === 'use') {
                        let id = e_over.target.getAttribute('xlink:href')?.split('#')[1];
                        if (id !== 'startpoint') {
                            let find = svg.querySelector(`rect[data-id = ${id}]:not(use)`)
                            arrow_over = find;

                        } else {
                            console.log('alert')
                        }

                    }

                }
                const arrowUpHandler = (e_up: MouseEvent) => {
                    document.removeEventListener('mousemove', arrowMoveHandler, false);
                    document.removeEventListener('mouseover', arrowOverHandler, false);

                    let {
                        x: {
                            baseVal: {
                                value: _x
                            }
                        },
                        y: {
                            baseVal: {
                                value: _y
                            }
                        },
                        width: {
                            baseVal: {
                                value: _width
                            }
                        }
                        , height: {
                            baseVal: {
                                value: _height
                            }
                        }

                    } = group

                    if (arrow_over) {
                        let t_id: string = '';
                        if (arrow_over.id === 'circle') {
                            t_id = arrow_over.parentNode?.parentNode?.parentNode?.dataset?.id;
                        } else if (arrow_over.id === 'figure') {
                            t_id = arrow_over.dataset.id;
                        }
                        arrow.setAttributeNS(null, 'data-contact', `${id}-${t_id}`);
                        arrow.setAttributeNS(null, 'data-side', arrow_side);
                        let d: any = arrow.getAttribute('d');
                        let d_mass = d?.split(',');


                        if (side === 'top') {

                            if (arrow_over.id === 'circle') {

                                d_mass[0] = `M${_x + _width / 2} ${_y + _height - 3}`
                                let h = _x < arrow_over.x?.baseVal?.value ? `h${Math.abs((arrow_over.x?.baseVal?.value + arrow_over.width?.baseVal?.value / 2) - (_x + _width / 2))}` : `h-${Math.abs((arrow_over.x?.baseVal?.value + arrow_over.width?.baseVal?.value / 2) - (_x + _width / 2))}`;
                                d_mass[1] = `v-${Math.abs((arrow_over.y?.baseVal?.value - (_y + _height)) / 2 + 7)}`;
                                d_mass[2] = h
                                d_mass[3] = `v-${Math.abs((arrow_over.y?.baseVal?.value - (_y + _height)) / 2 + 7)}`;
                                remove_arrow_box(id, t_id, side, arrow_over.parentNode?.parentNode?.parentNode?.dataset?.type)
                            } else if (arrow_over.id === 'figure') {
                                d_mass[0] = `M${_x + _width / 2} ${_y + _height - 3}`
                                let h = _x < arrow_over.x?.baseVal?.value ? `h${Math.abs((arrow_over.x?.baseVal?.value + arrow_over.width?.baseVal?.value / 2) - (_x + _width / 2))}` : `h-${Math.abs((arrow_over.x?.baseVal?.value + arrow_over.width?.baseVal?.value / 2) - (_x + _width / 2))}`
                                d_mass[1] = `v-${Math.abs(((arrow_over.y?.baseVal?.value + arrow_over.height?.baseVal?.value) - (_y + _height)) / 2 + 11.5)}`;
                                d_mass[2] = h;
                                d_mass[3] = `v-${Math.abs(((arrow_over.y?.baseVal?.value + arrow_over.height?.baseVal?.value) - (_y + _height)) / 2 + 11.5)}`;

                                remove_arrow_box(id, t_id, side, arrow_over.parentNode?.parentNode?.parentNode?.dataset?.type)

                            }
                        }


                        arrow.setAttributeNS(null, 'd', d_mass.join(','));
                        if (arrow_over) {
                            if (arrow_over.id === 'circle') {
                                add_storage(arrow_over?.parentNode?.parentNode?.parentNode)

                            } else {
                                add_storage(arrow_over?.closest('g'))
                            }
                        }

                        arrow_over = null;
                    }
                    condition_label(arrow);

                }
                const arrowLeaveHandler = (e_leave: MouseEvent) => {
                    document.removeEventListener('mousemove', arrowMoveHandler, false);
                    document.removeEventListener('mouseover', arrowOverHandler, false);


                }
                document.addEventListener('mouseover', arrowOverHandler);
                document.addEventListener('mousemove', arrowMoveHandler)
                document.addEventListener('mouseup', arrowUpHandler)
                document.addEventListener('mouseleave', arrowLeaveHandler);

            }
        }
        if (arrow_side === 'right') {
            if (Math.abs(a_x + a_width - e_down.x) < 11) {

                const arrowMoveHandler = (e_move: MouseEvent) => {
                    e_down.target.style.cursor = 'grab'
                    // let {x,y} = e_move;
                    let coord = mousePosition(e_move);
                    let { x, y } = coord;
                    let m: string = '';
                    let d: string = '';

                    if (arrow_side === 'right') {
                        m = `M${_x + 3} ${_y + _height / 2}`;

                        d = `${m},h${(x - _x) / 2 - 6},v${y - (_y + _height / 2)},h${(x - _x) / 2 - 6},${arrow_type.right}`
                    }

                    arrow.setAttributeNS(null, 'd', d);
                    condition_label(arrow);
                }
                const arrowOverHandler = (e_over: any) => {
                    if (e_over.target.tagName === 'rect' && e_over.target.dataset.type !== 'startpoint') {
                        arrow_over = e_over.target;


                    }
                    if (e_over.target.tagName === 'use') {
                        let id = e_over.target.getAttribute('xlink:href')?.split('#')[1];
                        if (id !== 'startpoint') {
                            let find = svg.querySelector(`rect[data-id = ${id}]:not(use)`)
                            arrow_over = find;

                        } else {
                            console.log('alert')
                        }

                    }

                }
                const arrowUpHandler = (e_up: MouseEvent) => {
                    document.removeEventListener('mousemove', arrowMoveHandler, false);
                    document.removeEventListener('mouseover', arrowOverHandler, false);

                    let __x: any = group?.getAttribute('x');
                    let __y: any = group?.getAttribute('y');
                    let __width: any = group?.getAttribute('width');
                    let __height: any = group?.getAttribute('height');
                    let _x = +__x;
                    let _y = +__y;
                    let _width = +__width;
                    let _height = +__height
                    if (arrow_over) {
                        let t_id: string = '';
                        if (arrow_over.id === 'circle') {
                            t_id = arrow_over.parentNode?.parentNode?.parentNode?.dataset?.id;
                        } else if (arrow_over.id === 'figure') {
                            t_id = arrow_over.dataset.id;
                        }
                        arrow.setAttributeNS(null, 'data-contact', `${id}-${t_id}`);
                        arrow.setAttributeNS(null, 'data-side', arrow_side);
                        let d: any = arrow.getAttribute('d');
                        let d_mass = d?.split(',');



                        if (side === 'right') {

                            if (arrow_over.id === 'circle') {

                                d_mass[1] = `h${((arrow_over.x?.baseVal?.value + _width) - _x) / 2 - 7}`;
                                d_mass[2] = `v${((arrow_over.y?.baseVal?.value + _height / 2) - (_y + _height / 2))}`;
                                d_mass[3] = `h${((arrow_over.x?.baseVal?.value + _width) - _x) / 2 - 7}`;

                                remove_arrow_box(id, t_id, side, arrow_over.parentNode?.parentNode?.parentNode?.dataset?.type)

                            } else if (arrow_over.id === 'figure') {
                                d_mass[1] = `h${(arrow_over.x?.baseVal?.value - _x) / 2 - 11.5}`;
                                d_mass[2] = `v${((arrow_over.y?.baseVal?.value + arrow_over.height?.baseVal?.value / 2) - (_y + _height / 2))}`;
                                d_mass[3] = `h${(arrow_over.x?.baseVal?.value - _x) / 2 - 11.5}`;
                                remove_arrow_box(id, t_id, side, arrow_over.dataset?.type)

                            }
                        }


                        arrow.setAttributeNS(null, 'd', d_mass.join(','));
                        if (arrow_over) {
                            if (arrow_over.id === 'circle') {
                                add_storage(arrow_over?.parentNode?.parentNode?.parentNode)

                            } else {
                                add_storage(arrow_over?.closest('g'))
                            }
                        }

                        arrow_over = null;
                    }
                    condition_label(arrow);

                }
                const arrowLeaveHandler = (e_leave: MouseEvent) => {
                    document.removeEventListener('mousemove', arrowMoveHandler, false);
                    document.removeEventListener('mouseover', arrowOverHandler, false);


                }
                document.addEventListener('mouseover', arrowOverHandler);
                document.addEventListener('mousemove', arrowMoveHandler)
                document.addEventListener('mouseup', arrowUpHandler)
                document.addEventListener('mouseleave', arrowLeaveHandler);

            }
        }

        if (arrow_side === 'bottom') {
            if (Math.abs(a_y + a_height - e_down.y) < 10) {
                const arrowMoveHandler = (e_move: MouseEvent) => {
                    e_down.target.style.cursor = 'grab'
                    // let {x,y} = e_move;
                    let coord = mousePosition(e_move);
                    let { x, y } = coord;
                    let m: string = '';
                    let d: string = '';

                    if (arrow_side === 'bottom') {
                        m = `M${_x + _width / 2} ${_y + 3}`;

                        d = `${m},v${(y - _y) / 2},h${x - (_x + _width / 2)},v${(y - _y) / 2},${arrow_type.bottom}`

                    }

                    arrow.setAttributeNS(null, 'd', d);
                    condition_label(arrow);

                }
                const arrowOverHandler = (e_over: any) => {
                    if (e_over.target.tagName === 'rect' && e_over.target.dataset.type !== 'startpoint') {
                        arrow_over = e_over.target;


                    }
                    if (e_over.target.tagName === 'use') {
                        let id = e_over.target.getAttribute('xlink:href')?.split('#')[1];
                        if (id !== 'startpoint') {
                            let find = svg.querySelector(`rect[data-id = ${id}]:not(use)`)
                            arrow_over = find;

                        }

                    }

                }
                const arrowUpHandler = (e_up: MouseEvent) => {
                    document.removeEventListener('mousemove', arrowMoveHandler, false);
                    document.removeEventListener('mouseover', arrowOverHandler, false);

                    let {
                        x: {
                            baseVal: {
                                value: _x
                            }
                        },
                        y: {
                            baseVal: {
                                value: _y
                            }
                        },
                        width: {
                            baseVal: {
                                value: _width
                            }
                        }


                    } = group

                    if (arrow_over) {
                        let t_id: string = '';
                        if (arrow_over.id === 'circle') {
                            t_id = arrow_over.parentNode?.parentNode?.parentNode?.dataset?.id;
                        } else if (arrow_over.id === 'figure') {
                            t_id = arrow_over.dataset.id;
                        }
                        arrow.setAttributeNS(null, 'data-contact', `${id}-${t_id}`);
                        arrow.setAttributeNS(null, 'data-side', arrow_side);
                        let d: any = arrow.getAttribute('d');
                        let d_mass = d?.split(',');


                        if (side === 'bottom') {
                            if (arrow_over.id === 'circle') {
                                d_mass[0] = `M${_x + _width / 2} ${_y + 3}`

                                d_mass[1] = `v${((arrow_over.y?.baseVal.value + arrow_over.height?.baseVal.value - 13.5) - _y) / 2}`;
                                d_mass[2] = `h${(arrow_over.x?.baseVal.value + (arrow_over.width?.baseVal.value / 2)) - (_x + _width / 2)}`;
                                d_mass[3] = `v${((arrow_over.y?.baseVal.value + arrow_over.height?.baseVal.value - 13.5) - _y) / 2}`;

                                remove_arrow_box(id, t_id, side, arrow_over.parentNode?.parentNode?.parentNode?.dataset?.type)

                            } else if (arrow_over.id === 'figure') {
                                d_mass[0] = `M${_x + _width / 2} ${_y + 3}`

                                d_mass[1] = `v${((arrow_over.y?.baseVal.value - 23) - _y) / 2}`;
                                d_mass[2] = `h${(arrow_over.x?.baseVal.value + (arrow_over.width?.baseVal.value / 2)) - (_x + _width / 2)}`;
                                d_mass[3] = `v${((arrow_over.y?.baseVal.value - 23) - _y) / 2}`;

                                remove_arrow_box(id, t_id, side, arrow_over.parentNode?.parentNode?.parentNode?.dataset?.type)

                            }
                        }


                        arrow.setAttributeNS(null, 'd', d_mass.join(','));
                        if (arrow_over) {
                            if (arrow_over.id === 'circle') {
                                add_storage(arrow_over?.parentNode?.parentNode?.parentNode)

                            } else {
                                add_storage(arrow_over?.closest('g'))
                            }
                        }
                        arrow_over = null;
                    }
                    condition_label(arrow);

                }
                const arrowLeaveHandler = (e_leave: MouseEvent) => {
                    document.removeEventListener('mousemove', arrowMoveHandler, false);
                    document.removeEventListener('mouseover', arrowOverHandler, false);


                }
                document.addEventListener('mouseover', arrowOverHandler);
                document.addEventListener('mousemove', arrowMoveHandler)
                document.addEventListener('mouseup', arrowUpHandler)
                document.addEventListener('mouseleave', arrowLeaveHandler);

            }

        }
        if (arrow_side === 'left') {
            if (Math.abs(a_x - e_down.x) < 10) {
                const arrowMoveHandler = (e_move: MouseEvent) => {
                    e_down.target.style.cursor = 'grab'
                    // let {x,y} = e_move;
                    let coord = mousePosition(e_move);
                    let { x, y } = coord;
                    let m: string = '';
                    let d: string = '';

                    if (arrow_side === 'left') {
                        m = `M${_x + _width - 3} ${_y + _height / 2}`;

                        d = `${m},h-${Math.abs((x - _x) / 2 - 6)},v${y - (_y + _height / 2)},h-${Math.abs((x - _x) / 2 - 6)},${arrow_type.left}`
                    }
                    arrow.setAttributeNS(null, 'd', d);
                    condition_label(arrow);

                }
                const arrowOverHandler = (e_over: any) => {
                    if (e_over.target.tagName === 'rect' && e_over.target.dataset.type !== 'startpoint') {
                        arrow_over = e_over.target;


                    }
                    if (e_over.target.tagName === 'use') {
                        let id = e_over.target.getAttribute('xlink:href')?.split('#')[1];
                        if (id !== 'startpoint') {
                            let find = svg.querySelector(`rect[data-id = ${id}]:not(use)`)
                            arrow_over = find;

                        } else {
                            console.log('alert')
                        }

                    }

                }
                const arrowUpHandler = (e_up: MouseEvent) => {
                    document.removeEventListener('mousemove', arrowMoveHandler, false);
                    document.removeEventListener('mouseover', arrowOverHandler, false);

                    let {
                        x: {
                            baseVal: {
                                value: _x
                            }
                        },
                        y: {
                            baseVal: {
                                value: _y
                            }
                        },
                        width: {
                            baseVal: {
                                value: _width
                            }
                        }
                        , height: {
                            baseVal: {
                                value: _height
                            }
                        }

                    } = group

                    if (arrow_over) {
                        let t_id: string = '';
                        if (arrow_over.id === 'circle') {
                            t_id = arrow_over.parentNode?.parentNode?.parentNode?.dataset?.id;
                        } else if (arrow_over.id === 'figure') {
                            t_id = arrow_over.dataset.id;
                        }
                        arrow.setAttributeNS(null, 'data-contact', `${id}-${t_id}`);
                        arrow.setAttributeNS(null, 'data-side', arrow_side);
                        let d: any = arrow.getAttribute('d');
                        let d_mass = d?.split(',');



                        if (side === 'left') {

                            if (arrow_over.id === 'circle') {

                                d_mass[0] = `M${_x + _width - 3} ${_y + _height / 2}`;

                                let v = (arrow_over.y?.baseVal?.value + (arrow_over.height?.baseVal?.value / 2)) < (_y + _height / 2) ? `v-${Math.abs((arrow_over.y?.baseVal?.value + arrow_over.height?.baseVal?.value / 2) - (_y + _height / 2))}` : `v${Math.abs((arrow_over.y?.baseVal?.value + arrow_over.height?.baseVal?.value / 2) - (_y + _height / 2))}`
                                d_mass[1] = `h-${Math.abs((arrow_over.x?.baseVal?.value - (_x + _width)) / 2 + 6.5)}`;
                                d_mass[2] = v
                                d_mass[3] = `h-${Math.abs((arrow_over.x?.baseVal?.value - (_x + _width)) / 2 + 6.5)}`;

                                remove_arrow_box(id, t_id, side, arrow_over.parentNode?.parentNode?.parentNode?.dataset?.type)
                            } else if (arrow_over.id === 'figure') {
                                d_mass[0] = `M${_x + _width - 3} ${_y + _height / 2}`;
                                let v = (arrow_over.y?.baseVal?.value + (arrow_over.height?.baseVal?.value / 2)) < (_y + _height / 2) ? `v-${Math.abs((arrow_over.y?.baseVal?.value + (arrow_over.height?.baseVal?.value / 2)) - (_y + _height / 2))}` : `v${((arrow_over.y?.baseVal?.value + (arrow_over.height?.baseVal?.value / 2)) - (_y + _height / 2))}`
                                d_mass[1] = `h-${Math.abs(((arrow_over.x?.baseVal?.value + arrow_over.width?.baseVal?.value + 21) - (_x + _width)) / 2)}`;
                                d_mass[2] = v
                                d_mass[3] = `h-${Math.abs(((arrow_over.x?.baseVal?.value + arrow_over.width?.baseVal?.value + 21) - (_x + _width)) / 2)}`;
                                remove_arrow_box(id, t_id, side, arrow_over.dataset?.type)

                            }
                        }
                        arrow.setAttributeNS(null, 'd', d_mass.join(','));
                        if (arrow_over) {
                            if (arrow_over.id === 'circle') {
                                add_storage(arrow_over?.parentNode?.parentNode?.parentNode)

                            } else {
                                add_storage(arrow_over?.closest('g'))
                            }
                        }

                        arrow_over = null;
                    }
                    condition_label(arrow);

                }
                const arrowLeaveHandler = (e_leave: MouseEvent) => {
                    document.removeEventListener('mousemove', arrowMoveHandler, false);
                    document.removeEventListener('mouseover', arrowOverHandler, false);


                }
                document.addEventListener('mouseover', arrowOverHandler);
                document.addEventListener('mousemove', arrowMoveHandler)
                document.addEventListener('mouseup', arrowUpHandler)
                document.addEventListener('mouseleave', arrowLeaveHandler);

            }

        }


        document.removeEventListener('mousedown', arrowDownHandler, false)
    }
    arrow.addEventListener('mousedown', arrowDownHandler);
}