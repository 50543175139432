import { getAttr } from "../methods/attributes"
import { nearby_side } from "./nearby_side/nearby_side";
import { Coord, Massive } from "./types";



export const generate_arrow = (element:SVGRectElement,first:Massive,last:Massive)=>{
       const {x:first_x,y:first_y,width:first_width,height:first_height,id:first_id} =  getAttr(first?.element);
       const {x:last_x,y:last_y,width:last_width,height:last_height,id:last_id} =  getAttr(last?.element);
      
       if(first_x && first_y && first_width && first_height && first_id  && last_x && last_y && last_width && last_height && last_id){
          const coord:Coord={
               first_x:first_x,
               first_y:first_y,
               first_width:first_width,
               first_height:first_height,
               last_x:last_x,
               last_y:last_y,
               last_width:last_width,
               last_height:last_height
          }
   
          if(first && last){
               const {side:first_side,bool} = first;
               const {side:last_side} = last;
   
               let xmlns:string = "http://www.w3.org/2000/svg";    
               let arrow = document.createElementNS(xmlns,'path') as SVGPathElement;
               arrow.setAttributeNS(null,'stroke','#788896');
               arrow.setAttributeNS(null,'stroke-width','5.65');
               arrow.setAttributeNS(null,'fill','none');
               arrow.setAttributeNS(null,'stroke-linejoin','round');
               arrow.setAttributeNS(null,'stroke-linecap','round');
               arrow.setAttributeNS(null,'id','arrow');
               if(bool){
                    arrow.setAttributeNS(null,'data-bool',bool);

               }
               arrow.setAttributeNS(null,'data-contact',`${first_id}-${last_id}`);
               const side:any={first_side:first_side,last_side:last_side}
          
               nearby_side(arrow,side,coord,first,last)
   
               
            
          }
      }
      
       
}