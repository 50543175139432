import {
    StrategyActionsTypes,
    StrategyBoardSidePanelInputStage
} from "../../../../../types/typesStrategy";


const initialState: StrategyBoardSidePanelInputStage = {
    stage_value: null

}

const reducerInputStage = (state = initialState, action: StrategyActionsTypes): StrategyBoardSidePanelInputStage => {

    switch (action.type) {

        case "STRATEGY_BOARD_SIDE_PANEL_INPUT_STAGE":
            const { stage_value } = action.payload
            return {
                stage_value
            };

        default:
            return state
    }
}

export default reducerInputStage