import { add_storage } from "./add_storage.handler";
// import { condition_arrow } from "./condition_arrow.handler";
import { call_arrow_box_source, call_arrow_box_target, remove_arrow_box } from "./remove_arrow_box.handler";
import {condition_label} from "./condition_arrow.handler";
import { arrowLoop } from "./findArrowLoop";
import recycleSvg from "../../../../../assets/svg/deletewhite.svg";
import { remove_arrow_db } from "./remove_element.handler";
export const drag_arrow = (id:any,group:SVGGElement,side:string) =>{
    const svg = document.querySelector(`#svg_board`) as SVGElement;
    let xmlns:string = "http://www.w3.org/2000/svg";
    const arrow_type:any ={
        top:'l-4 0 ,l4 -8 ,l4 8 ,l-4 0',
        right:'l0 -5 ,l8 5 ,l-8 5 ,l0 -5',
        bottom:'l4 0 ,l-4 8 ,l-4 -8 ,l4 0',
        left:'l0 -5,l-8 5 ,l8 5,l0 -5',
    }
    function mousePosition(e:any){
        let svg:any = document.querySelector('#svg_board') as SVGElement;
    
        let CTM = svg.getScreenCTM()
       
      
    
        return {
            x: (e.clientX - CTM.e) / CTM.a ,
            y: (e.clientY - CTM.f) / CTM.d 
        };
    }
//arrow
            let arrow = document.createElementNS(xmlns,'path') as SVGPathElement;
            arrow.setAttributeNS(null,'stroke','#788896');
            arrow.setAttributeNS(null,'stroke-width','5.65');
            arrow.setAttributeNS(null,'fill','none');
            arrow.setAttributeNS(null,'stroke-linejoin','round');
            arrow.setAttributeNS(null,'stroke-linecap','round');
            arrow.setAttributeNS(null,'id','arrow');

            let arrow_over:any = null;

            const arrowDownHandler = (e_down:any)=>{

                let {x:a_x,y:a_y,width:a_width,height:a_height} = e_down.target.getBoundingClientRect();
                let {
                    x:{
                        baseVal:{
                            value:_x
                        }
                    },
                    y:{
                        baseVal:{
                            value:_y
                        }
                    },
                    width:{
                        baseVal:{
                            value:_width
                        }
                    },
                    height:{
                        baseVal:{
                            value:_height
                        }
                    }
                
                } = group?.querySelector('#circle') as SVGRectElement;
                let arrow_side:any = arrow.dataset.side;
                if(arrow_side === 'top'){
                    if(Math.abs(e_down.y-a_y) < 10){
                        
                        const arrowMoveHandler = (e_move:MouseEvent)=>{
                            e_down.target.style.cursor = 'grab'
                            let {x,y} = e_move;
                            let m:string ='';
                            let d:string = '';
                            if(arrow_side === 'top'){
                                m =  `M${_x + _width/2} ${_y + _height - 3}`;
                               d = `${m},v${(y - (_y+_height))/2},h${(x - (_x +_width/2))},v${(y - (_y+_height))/2},${arrow_type[arrow_side]}`
                            }
                           
                            arrow.setAttributeNS(null,'d',d);
                        }
                        const arrowOverHandler = (e_over:any)=>{
                            if(e_over.target.tagName === 'rect' && e_over.target.dataset.type !=='startpoint'){
                                arrow_over = e_over.target;
                                    
    
                            }
                            if(e_over.target.tagName === 'use'){
                                let id =e_over.target.getAttribute('xlink:href')?.split('#')[1];
                                if(id !== 'startpoint'){
                                    let find = svg.querySelector(`rect[data-id = ${id}]:not(use)`)
                                    arrow_over =find;
    
                                }else{
                                    console.log('alert')
                                }
    
                            }
    
                        }
                        const arrowUpHandler = (e_up:MouseEvent)=>{
                            document.removeEventListener('mousemove',arrowMoveHandler,false);
                            document.removeEventListener('mouseover',arrowOverHandler,false);
    
                            let {
                                x:{
                                    baseVal:{
                                        value:_x
                                    }
                                },
                                y:{
                                    baseVal:{
                                        value:_y
                                    }
                                },
                                width:{
                                    baseVal:{
                                        value:_width
                                    }
                                }
                                ,height:{
                                    baseVal:{
                                        value:_height
                                    }
                                }
                            
                            } = group?.querySelector('#circle') as SVGRectElement;
    
                            if(arrow_over){
                                let t_id:string = '';
                                if(arrow_over?.id === 'circle'){
                                    t_id = arrow_over.parentNode?.parentNode?.parentNode?.dataset?.id;
                                }else if(arrow_over?.id === 'figure'){
                                    t_id = arrow_over.dataset.id;
                                }
                                arrow.setAttributeNS(null,'data-contact',`${id}-${t_id}`);
                                arrow.setAttributeNS(null,'data-side',side);
                                let d:any =  arrow.getAttribute('d');
                                let d_mass = d?.split(',');
    
                                
                                if(side === 'top'){
    
                                    if(arrow_over?.id === 'circle'){
    
                                        d_mass[0] = `M${_x + _width/2} ${_y + _height - 3}`
                                        let h = _x < arrow_over.x?.baseVal?.value ? `h${Math.abs((arrow_over.x?.baseVal?.value + arrow_over.width?.baseVal?.value/2 ) - (_x+_width/2))}` : `h-${Math.abs((arrow_over.x?.baseVal?.value + arrow_over.width?.baseVal?.value/2 ) - (_x+_width/2))}`;
                                        d_mass[1] = `v-${Math.abs((arrow_over.y?.baseVal?.value - (_y+ _height))/2 + 7)}`;
                                        d_mass[2] = h
                                        d_mass[3] = `v-${Math.abs((arrow_over.y?.baseVal?.value - (_y+ _height))/2 + 7)}`;
                                        remove_arrow_box(id,t_id,side,arrow_over.parentNode?.parentNode?.parentNode?.dataset?.type)
                                    }else if(arrow_over?.id === 'figure'){
                                        d_mass[0] = `M${_x + _width/2} ${_y + _height - 3}`
                                        let h = _x < arrow_over.x?.baseVal?.value ? `h${Math.abs((arrow_over.x?.baseVal?.value + arrow_over.width?.baseVal?.value/2 ) - (_x+_width/2))}` :`h-${Math.abs((arrow_over.x?.baseVal?.value + arrow_over.width?.baseVal?.value/2 ) - (_x+_width/2))}`
                                        d_mass[1] = `v-${Math.abs(((arrow_over.y?.baseVal?.value + arrow_over.height?.baseVal?.value) - (_y+ _height))/2 + 11.5)}`;
                                        d_mass[2] = h;
                                        d_mass[3] = `v-${Math.abs(((arrow_over.y?.baseVal?.value + arrow_over.height?.baseVal?.value) - (_y+ _height))/2 + 11.5)}`;
                    
                                        remove_arrow_box(id,t_id,side,arrow_over.parentNode?.parentNode?.parentNode?.dataset?.type)
                    
                                    }
                                }
                                
                            
                                arrow.setAttributeNS(null,'d',d_mass.join(','));   
           
                                over = null;
                            }
                        }
                        const arrowLeaveHandler = (e_leave:MouseEvent)=>{
                            document.removeEventListener('mousemove',arrowMoveHandler,false);
                            document.removeEventListener('mouseover',arrowOverHandler,false);
    
        
                        }
                        document.addEventListener('mouseover',arrowOverHandler);
                        document.addEventListener('mousemove',arrowMoveHandler)
                        document.addEventListener('mouseup',arrowUpHandler)
                        document.addEventListener('mouseleave',arrowLeaveHandler);
    
                    }
                }
                if(arrow_side === 'right'){
                    if(Math.abs(a_x +a_width - e_down.x) < 11){
                        
                        const arrowMoveHandler = (e_move:MouseEvent)=>{
                            e_down.target.style.cursor = 'grab'
                            let {x,y} = e_move;
                            let m:string ='';
                            let d:string = '';
                            if(arrow_side === 'right'){
                                m =  `M${_x + 3} ${_y+_height/2}`;
    
                                d = `${m},h${(x-_x)/2 - 6},v${y- (_y + _height/2)},h${(x-_x)/2 - 6},${arrow_type[arrow_side]}`
                             }
                         
                            arrow.setAttributeNS(null,'d',d);
                        }
                        const arrowOverHandler = (e_over:any)=>{
                            if(e_over.target.tagName === 'rect' && e_over.target.dataset.type !=='startpoint'){
                                arrow_over = e_over.target;
                                    
    
                            }
                            if(e_over.target.tagName === 'use'){
                                let id =e_over.target.getAttribute('xlink:href')?.split('#')[1];
                                if(id !== 'startpoint'){
                                    let find = svg.querySelector(`rect[data-id = ${id}]:not(use)`)
                                    arrow_over =find;
    
                                }else{
                                    console.log('alert')
                                }
    
                            }
    
                        }
                        const arrowUpHandler = (e_up:MouseEvent)=>{
                            document.removeEventListener('mousemove',arrowMoveHandler,false);
                            document.removeEventListener('mouseover',arrowOverHandler,false);
    
                            let {
                                x:{
                                    baseVal:{
                                        value:_x
                                    }
                                },
                                y:{
                                    baseVal:{
                                        value:_y
                                    }
                                },
                                width:{
                                    baseVal:{
                                        value:_width
                                    }
                                }
                                ,height:{
                                    baseVal:{
                                        value:_height
                                    }
                                }
                            
                            } = group?.querySelector('#circle') as SVGRectElement;
    
                            if(arrow_over){
                                let t_id:string = '';
                                if(arrow_over?.id === 'circle'){
                                    t_id = arrow_over.parentNode?.parentNode?.parentNode?.dataset?.id;
                                }else if(arrow_over?.id === 'figure'){
                                    t_id = arrow_over.dataset.id;
                                }
                                arrow.setAttributeNS(null,'data-contact',`${id}-${t_id}`);
                                arrow.setAttributeNS(null,'data-side',side);
                                let d:any =  arrow.getAttribute('d');
                                let d_mass = d?.split(',');
    
                                
                           
                                if(side === 'right'){
    
                                    if(arrow_over?.id === 'circle'){
    
                                        d_mass[1] = `h${((arrow_over.x?.baseVal?.value + _width) - _x )/2 - 7}`;
                                        d_mass[2] = `v${((arrow_over.y?.baseVal?.value + _height/2) - (_y + _height/2) )}`;
                                        d_mass[3] = `h${((arrow_over.x?.baseVal?.value + _width) - _x )/2 - 7}`;
                    
                                        remove_arrow_box(id,t_id,side,arrow_over.parentNode?.parentNode?.parentNode?.dataset?.type)
                    
                                    }else if(arrow_over?.id === 'figure'){
                                        d_mass[1] = `h${(arrow_over.x?.baseVal?.value - _x )/2 - 11.5}`;
                                        d_mass[2] = `v${((arrow_over.y?.baseVal?.value + arrow_over.height?.baseVal?.value/2) - (_y + _height/2) )}`;
                                        d_mass[3] = `h${(arrow_over.x?.baseVal?.value - _x )/2 - 11.5}`;
                                        remove_arrow_box(id,t_id,side,arrow_over.dataset?.type)
                    
                                    }
                                }
                                
                          
                                arrow.setAttributeNS(null,'d',d_mass.join(',')); 
                                over = null;
                            }
                        }
                        const arrowLeaveHandler = (e_leave:MouseEvent)=>{
                            document.removeEventListener('mousemove',arrowMoveHandler,false);
                            document.removeEventListener('mouseover',arrowOverHandler,false);
    
        
                        }
                        document.addEventListener('mouseover',arrowOverHandler);
                        document.addEventListener('mousemove',arrowMoveHandler)
                        document.addEventListener('mouseup',arrowUpHandler)
                        document.addEventListener('mouseleave',arrowLeaveHandler);
    
                    }
                }

                if(arrow_side === 'bottom'){
                    if(Math.abs(a_y + a_height - e_down.y) < 10){
                        const arrowMoveHandler = (e_move:MouseEvent)=>{

                            e_down.target.style.cursor = 'grab'
                            let {x,y} = e_move;
                            let m:string ='';
                            let d:string = '';
                     
                             if(arrow_side === 'bottom'){
                                m =  `M${_x+_width/2 } ${_y + 3}`;
    
                                d = `${m},v${(y - _y)/2 },h${x - (_x+_width/2)},v${(y - _y)/2},${arrow_type[arrow_side]}`
                                
                             }
                           
                            arrow.setAttributeNS(null,'d',d);
                        }
                        const arrowOverHandler = (e_over:any)=>{
                            if(e_over.target.tagName === 'rect' && e_over.target.dataset.type !=='startpoint'){
                                arrow_over = e_over.target;
                                    
    
                            }
                            if(e_over.target.tagName === 'use'){
                                let id =e_over.target.getAttribute('xlink:href')?.split('#')[1];
                                if(id !== 'startpoint'){
                                    let find = svg.querySelector(`rect[data-id = ${id}]:not(use)`)
                                    arrow_over =find;
    
                                }else{
                                    console.log('alert')
                                }
    
                            }
    
                        }
                        const arrowUpHandler = (e_up:MouseEvent)=>{
                            document.removeEventListener('mousemove',arrowMoveHandler,false);
                            document.removeEventListener('mouseover',arrowOverHandler,false);
    
                            let {
                                x:{
                                    baseVal:{
                                        value:_x
                                    }
                                },
                                y:{
                                    baseVal:{
                                        value:_y
                                    }
                                },
                                width:{
                                    baseVal:{
                                        value:_width
                                    }
                                }

                            
                            } = group?.querySelector('#circle') as SVGRectElement;
    
                            if(arrow_over){
                                let t_id:string = '';
                                if(arrow_over?.id === 'circle'){
                                    t_id = arrow_over.parentNode?.parentNode?.parentNode?.dataset?.id;
                                }else if(arrow_over?.id === 'figure'){
                                    t_id = arrow_over.dataset.id;
                                }
                                arrow.setAttributeNS(null,'data-contact',`${id}-${t_id}`);
                                arrow.setAttributeNS(null,'data-side',side);
                                let d:any =  arrow.getAttribute('d');
                                let d_mass = d?.split(',');
    
                               
                                if(side === 'bottom'){
                                    if(arrow_over?.id === 'circle'){
                                        d_mass[0] = `M${_x + _width/2} ${_y + 3}`
    
                                        d_mass[1] = `v${((arrow_over.y?.baseVal.value + arrow_over.height?.baseVal.value - 13.5) - _y)/2}`;
                                        d_mass[2] = `h${(arrow_over.x?.baseVal.value + (arrow_over.width?.baseVal.value/2)) - (_x+_width/2)}`;
                                        d_mass[3] = `v${((arrow_over.y?.baseVal.value + arrow_over.height?.baseVal.value - 13.5) - _y)/2}`;
                    
                                        remove_arrow_box(id,t_id,side,arrow_over.parentNode?.parentNode?.parentNode?.dataset?.type)
                    
                                    }else if(arrow_over?.id === 'figure'){
                                        d_mass[0] = `M${_x + _width/2} ${_y  + 3}`
    
                                        d_mass[1] = `v${((arrow_over.y?.baseVal.value - 23) - _y)/2}`;
                                        d_mass[2] = `h${(arrow_over.x?.baseVal.value + (arrow_over.width?.baseVal.value/2)) - (_x+_width/2)}`;
                                        d_mass[3] = `v${((arrow_over.y?.baseVal.value - 23) - _y)/2}`;
                    
                                        remove_arrow_box(id,t_id,side,arrow_over.parentNode?.parentNode?.parentNode?.dataset?.type)
                    
                                    }
                                }
                          
                          
                                arrow.setAttributeNS(null,'d',d_mass.join(','));   
           
                                over = null;
                            }
                        }
                        const arrowLeaveHandler = (e_leave:MouseEvent)=>{
                            document.removeEventListener('mousemove',arrowMoveHandler,false);
                            document.removeEventListener('mouseover',arrowOverHandler,false);
    
        
                        }
                        document.addEventListener('mouseover',arrowOverHandler);
                        document.addEventListener('mousemove',arrowMoveHandler)
                        document.addEventListener('mouseup',arrowUpHandler)
                        document.addEventListener('mouseleave',arrowLeaveHandler);
    
                    }
                        
                }
                if(arrow_side === 'left'){
                    if(Math.abs(a_x - e_down.x)<10){
                        const arrowMoveHandler = (e_move:MouseEvent)=>{
                            e_down.target.style.cursor = 'grab'
                            let {x,y} = e_move;
                            let m:string ='';
                            let d:string = '';
                          
                             if(arrow_side === 'left'){
                                m =  `M${_x + _width - 3} ${_y+_height/2}`;
    
                                d = `${m},h-${Math.abs((x-_x)/2 - 6)},v${y- (_y + _height/2)},h-${Math.abs((x-_x)/2 - 6)},${arrow_type[arrow_side]}`
                             }
                            arrow.setAttributeNS(null,'d',d);
                        }
                        const arrowOverHandler = (e_over:any)=>{
                            if(e_over.target.tagName === 'rect' && e_over.target.dataset.type !=='startpoint'){
                                arrow_over = e_over.target;
                                    
    
                            }
                            if(e_over.target.tagName === 'use'){
                                let id =e_over.target.getAttribute('xlink:href')?.split('#')[1];
                                if(id !== 'startpoint'){
                                    let find = svg.querySelector(`rect[data-id = ${id}]:not(use)`)
                                    arrow_over =find;
    
                                }else{
                                    console.log('alert')
                                }
    
                            }
    
                        }
                        const arrowUpHandler = (e_up:MouseEvent)=>{
                            document.removeEventListener('mousemove',arrowMoveHandler,false);
                            document.removeEventListener('mouseover',arrowOverHandler,false);
    
                            let {
                                x:{
                                    baseVal:{
                                        value:_x
                                    }
                                },
                                y:{
                                    baseVal:{
                                        value:_y
                                    }
                                },
                                width:{
                                    baseVal:{
                                        value:_width
                                    }
                                }
                                ,height:{
                                    baseVal:{
                                        value:_height
                                    }
                                }
                            
                            } = group?.querySelector('#circle') as SVGRectElement;
    
                            if(arrow_over){
                                let t_id:string = '';
                                if(arrow_over?.id === 'circle'){
                                    t_id = arrow_over.parentNode?.parentNode?.parentNode?.dataset?.id;
                                }else if(arrow_over?.id === 'figure'){
                                    t_id = arrow_over.dataset.id;
                                }
                                arrow.setAttributeNS(null,'data-contact',`${id}-${t_id}`);
                                arrow.setAttributeNS(null,'data-side',side);
                                let d:any =  arrow.getAttribute('d');
                                let d_mass = d?.split(',');
    
                                
                             
                                if(side === 'left'){
    
                                    if(arrow_over?.id === 'circle'){
    
                                        d_mass[0] =   `M${_x + _width - 3} ${_y+_height/2}`;

                                        let v = (arrow_over.y?.baseVal?.value + (arrow_over.height?.baseVal?.value/2)) < (_y + _height/2) ? `v-${Math.abs((arrow_over.y?.baseVal?.value + arrow_over.height?.baseVal?.value/2) - (_y+_height/2))}`:`v${Math.abs((arrow_over.y?.baseVal?.value + arrow_over.height?.baseVal?.value/2) - (_y+_height/2)) }`
                                        d_mass[1] = `h-${Math.abs((arrow_over.x?.baseVal?.value - (_x + _width))/2 + 6.5)}`;
                                        d_mass[2] = v
                                        d_mass[3] = `h-${Math.abs((arrow_over.x?.baseVal?.value - (_x + _width))/2 +6.5)}`;
                    
                                        remove_arrow_box(id,t_id,side,arrow_over.parentNode?.parentNode?.parentNode?.dataset?.type)
                                    }else if(arrow_over?.id === 'figure'){
                                        d_mass[0] =   `M${_x + _width - 3} ${_y+_height/2}`;
                                        let v = (arrow_over.y?.baseVal?.value + (arrow_over.height?.baseVal?.value/2)) < (_y + _height/2) ? `v-${Math.abs((arrow_over.y?.baseVal?.value + (arrow_over.height?.baseVal?.value/2)) - (_y+_height/2))}`:`v${((arrow_over.y?.baseVal?.value + (arrow_over.height?.baseVal?.value/2)) - (_y+_height/2))}`
                                        d_mass[1] = `h-${Math.abs(((arrow_over.x?.baseVal?.value + arrow_over.width?.baseVal?.value + 21) - (_x + _width))/2)}`;
                                        d_mass[2] = v
                                        d_mass[3] = `h-${Math.abs(((arrow_over.x?.baseVal?.value + arrow_over.width?.baseVal?.value + 21) - (_x + _width))/2)}`;
                                        remove_arrow_box(id,t_id,side,arrow_over.dataset?.type)
                    
                                    }
                                }
                                arrow.setAttributeNS(null,'d',d_mass.join(','));   
           
                                over = null;
                            }
                        }
                        const arrowLeaveHandler = (e_leave:MouseEvent)=>{
                            document.removeEventListener('mousemove',arrowMoveHandler,false);
                            document.removeEventListener('mouseover',arrowOverHandler,false);

        
                        }
                        document.addEventListener('mouseover',arrowOverHandler);
                        document.addEventListener('mousemove',arrowMoveHandler)
                        document.addEventListener('mouseup',arrowUpHandler)
                        document.addEventListener('mouseleave',arrowLeaveHandler);
    
                    }
                     
                }

                

            }
            arrow.addEventListener('mousedown',arrowDownHandler);
    
//arrow end

    let over:any = null;
    let upStatus:boolean = false;
    const downHandler = (e_down:any)=>{

        let {
            x:{
                baseVal:{
                    value:_x
                }
            },
            y:{
                baseVal:{
                    value:_y
                }
            },
            width:{
                baseVal:{
                    value:_width
                }
            }
            ,height:{
                baseVal:{
                    value:_height
                }
            }
        
        } = group?.querySelector('#circle') as SVGRectElement;

        const moveHandler = (e_move:any)=>{
            // e_move.preventDefault();
            // let {x,y} = e_move;
            let coord = mousePosition(e_move);
            let {x,y} = coord;

            switch(side){
                case 'top':

                let h = x > _x + _width/2 ? `h${Math.abs(x - (_x + _width/2))}` :`h-${Math.abs(x - (_x + _width/2))}`
                    let m_top:string = `M${_x + _width/2} ${Math.abs(_y + _height - 3)},v-${Math.abs((y - (_y+ _height))/2 + 3)},${h},v-${Math.abs((y - (_y+ _height))/2 + 3)},${arrow_type.top}`;

                    arrow.setAttributeNS(null,'d',m_top);
                    let source_element_t = group.parentNode?.parentNode?.querySelector('#figure') as SVGRectElement;
                        if(source_element_t.dataset.type === 'condt'){
                            let arrows = svg.querySelectorAll(`path[data-contact ^= ${source_element_t.dataset.id}]`) as NodeList;
                                    if(arrows.length>0){
                                        arrows.forEach((_arrow:any)=>{
                                            if(_arrow.getAttribute('data-bool') === 'yes'){
                                                arrow.setAttributeNS(null,'data-bool','no');
                                            }else{
                                                arrow.setAttributeNS(null,'data-bool','yes');
                                            }
                                        })


                                    }else{
                                        arrow.setAttributeNS(null,'data-bool','yes');

                                    }
                        }
                    svg.insertBefore(arrow,svg.firstChild);
                    
                    break;
                case 'right':
                        
                                let m:string = `M${_x + 3} ${_y+_height/2}`;
                                let d:string = `${m},h${(x-_x)/2 - 6},v${y- (_y + _height/2)},h${(x-_x)/2 - 6},${arrow_type.right}`
                                arrow.setAttributeNS(null,'d',d);
                                let source_element_r = group.parentNode?.parentNode?.querySelector('#figure') as SVGRectElement;
                                if(source_element_r.dataset.type === 'condt'){
                                    let arrows = svg.querySelectorAll(`path[data-contact ^= ${source_element_r.dataset.id}]`) as NodeList;
                                    if(arrows.length>0){
                                        arrows.forEach((_arrow:any)=>{
                                            if(_arrow.getAttribute('data-bool') === 'yes'){
                                                arrow.setAttributeNS(null,'data-bool','no');
                                            }else{
                                                arrow.setAttributeNS(null,'data-bool','yes');
                                            }
                                        })
                                        


                                    }else{
                                        arrow.setAttributeNS(null,'data-bool','yes');

                                    }
                                }
                                svg.insertBefore(arrow,svg.firstChild);
                                
                    break;
                case 'bottom':

                        let m_bottom:string = `M${_x + _width/2} ${_y+3}`;
                        let d_bottom:string = `${m_bottom},v${(y- _y)/2},h${(x-(_x + _width/2))},v${(y- _y)/2},${arrow_type.bottom}`
                        arrow.setAttributeNS(null,'d',d_bottom);
                        let source_element_b = group.parentNode?.parentNode?.querySelector('#figure') as SVGRectElement;
                        if(source_element_b.dataset.type === 'condt'){

                            let arrows = svg.querySelectorAll(`path[data-contact ^= ${source_element_b.dataset.id}]`) as NodeList;
                                    if(arrows.length>0){
                                        arrows.forEach((_arrow:any)=>{
                                            if(_arrow.getAttribute('data-bool') === 'yes'){
                                                arrow.setAttributeNS(null,'data-bool','no');
                                            }else{
                                                arrow.setAttributeNS(null,'data-bool','yes');
                                            }
                                        })


                                    }else{
                                        arrow.setAttributeNS(null,'data-bool','yes');

                                    }
                        }
                        svg.insertBefore(arrow,svg.firstChild);
            
                    break;
                case 'left':
                        
                        let m_left:string = `M${_x+_width - 3} ${_y + _height/2}`;
                        let v = y < (_y+_height/2) ? `v-${Math.abs(y - (_y+_height/2))}` :`v${Math.abs(y - (_y+_height/2))}`
                        let d_left:string = `${m_left},h-${Math.abs((x - (_x + _width))/2 + 7.5)},${v},h-${Math.abs((x - (_x + _width))/2 + 7.5)},${arrow_type.left}`
                        arrow.setAttributeNS(null,'d',d_left);
                        let source_element_l = group.parentNode?.parentNode?.querySelector('#figure') as SVGRectElement;
                        if(source_element_l.dataset.type === 'condt'){
                            let arrows = svg.querySelectorAll(`path[data-contact ^= ${source_element_l.dataset.id}]`) as NodeList;
                                    if(arrows.length>0){
                                        arrows.forEach((_arrow:any)=>{
                                            if(_arrow.getAttribute('data-bool') === 'yes'){
                                                arrow.setAttributeNS(null,'data-bool','no');
                                            }else{
                                                arrow.setAttributeNS(null,'data-bool','yes');
                                            }
                                        })


                                    }else{
                                        arrow.setAttributeNS(null,'data-bool','yes');

                                    }
                        }
                        svg.insertBefore(arrow,svg.firstChild);
            
                    break;
                
                default:
                    break;
            }
            

        }
        const overHandler = (e_over:any)=>{

            if(e_over.target.tagName === 'rect'){
                over = e_over.target;
                arrow.setAttributeNS(null,'data-contact',`${id}-`)
                const {status} = arrowLoop(over,arrow);
                upStatus = status
                // if(over.dataset.type === 'validate' || over.dataset.type === 'datawizard'){
                //     over.style.cursor = "no-drop"
                // }
            }else{
                    arrow.setAttribute('stroke',"#788896");
    
            }
         

            if(e_over.target.tagName === 'use'){

                    let _id =e_over.target.getAttribute('xlink:href')?.split('#')[1];
                    let find = svg.querySelector(`rect[data-id = ${_id}]:not(use)`)
                    over =find;
                
          
            }
        }

        const upHandler = (e_up:MouseEvent)=>{
            if(!over){
                let has_contact = arrow.hasAttribute('data-contact');
                if(!has_contact && arrow){
                    if(svg.contains(arrow)) svg.removeChild(arrow)
                }


            }else if(upStatus){
                if(svg.contains(arrow)) svg.removeChild(arrow)

            }else if(over){

                        let t_id:string = '';
                        if(over?.id === 'circle'){
                            t_id = over.parentNode?.parentNode?.parentNode?.dataset?.id;
                            if(over.parentNode?.parentNode?.parentNode?.querySelector('#figure')?.dataset.type === 'validate' || over.parentNode?.parentNode?.parentNode?.querySelector('#figure')?.dataset.type === 'datawizard'){
                                if(svg.contains(arrow)) svg.removeChild(arrow);

                            }
                        }else if(over?.id === 'figure'){
                            t_id = over.dataset.id;
                            if(over.dataset.type === 'validate' || over.dataset.type === 'datawizard'){
                                if(svg.contains(arrow)) svg.removeChild(arrow);

                            }
                        }
                       if(id === t_id){
                        if(svg.contains(arrow)) svg.removeChild(arrow);
                       }else{
                           if(over.dataset.type === 'validate' || over.dataset.type === 'datawizard'){

                               if(svg.contains(arrow)) svg.removeChild(arrow);


                           }else if(over.parentNode?.parentNode?.parentNode?.querySelector('#figure')?.dataset.type === 'validate' || over.parentNode?.parentNode?.parentNode?.querySelector('#figure')?.dataset.type === 'datawizard'){
                               if(svg.contains(arrow)) svg.removeChild(arrow);

                           }else{
                               arrow.setAttributeNS(null,'data-contact',`${id}-${t_id}`);
                               arrow.setAttributeNS(null,'data-side',side);
                               let d:any =  arrow.getAttribute('d');
                               let d_mass = d?.split(',');


                               if(side === 'top'){
                                   if(over?.id === 'circle' && d_mass?.length>0){
                                       d_mass[0] = `M${_x + _width/2} ${_y + _height - 3}`
                                       let h = _x < over.x?.baseVal?.value ? `h${Math.abs((over.x?.baseVal?.value + over.width?.baseVal?.value/2 ) - (_x+_width/2))}` : `h-${Math.abs((over.x?.baseVal?.value + over.width?.baseVal?.value/2 ) - (_x+_width/2))}`;
                                       d_mass[1] = `v-${Math.abs((over.y?.baseVal?.value - (_y+ _height))/2 + 7)}`;
                                       d_mass[2] = h
                                       d_mass[3] = `v-${Math.abs((over.y?.baseVal?.value - (_y+ _height))/2 + 7)}`;
                                       remove_arrow_box(id,t_id,side,over.parentNode?.parentNode?.parentNode?.dataset?.type)
                                   }else if(over?.id === 'figure' && d_mass?.length>0){
                                       d_mass[0] = `M${_x+_width/2} ${_y+29}`
                                       let h = _x < over.x?.baseVal?.value ? `h${Math.abs((over.x?.baseVal?.value + over.width?.baseVal?.value/2 ) - (_x+_width/2))}` :`h-${Math.abs((over.x?.baseVal?.value + over.width?.baseVal?.value/2 ) - (_x+_width/2))}`
                                       d_mass[1] = `v-${Math.abs(((over.y?.baseVal?.value + over.height?.baseVal?.value) - (_y+ _height))/2 + 11.5)}`;
                                       d_mass[2] = h;
                                       d_mass[3] = `v-${Math.abs(((over.y?.baseVal?.value + over.height?.baseVal?.value) - (_y+ _height))/2 + 11.5)}`;
                                       remove_arrow_box(id,t_id,side,over.parentNode?.parentNode?.parentNode?.dataset?.type)

                                   }
                               }
                               if(side === 'right'){

                                   if(over?.id === 'circle' && d_mass?.length>0){

                                       d_mass[1] = `h${((over.x?.baseVal?.value + _width) - _x )/2 - 7}`;
                                       d_mass[2] = `v${((over.y?.baseVal?.value + _height/2) - (_y + _height/2) )}`;
                                       d_mass[3] = `h${((over.x?.baseVal?.value + _width) - _x )/2 - 7}`;

                                       remove_arrow_box(id,t_id,side,over.parentNode?.parentNode?.parentNode?.dataset?.type)

                                   }else if(over?.id === 'figure' && d_mass?.length>0){
                                       d_mass[1] = `h${(over.x?.baseVal?.value - _x )/2 - 11.5}`;
                                       d_mass[2] = `v${((over.y?.baseVal?.value + over.height?.baseVal?.value/2) - (_y + _height/2) )}`;
                                       d_mass[3] = `h${(over.x?.baseVal?.value - _x )/2 - 11.5}`;
                                       remove_arrow_box(id,t_id,side,over.dataset?.type)

                                   }
                               }
                               if(side === 'bottom'){
                                   if(over?.id === 'circle' && d_mass?.length>0){
                                       d_mass[1] = `v${((over.y?.baseVal.value + over.height?.baseVal.value - 13.5) - _y)/2}`;
                                       d_mass[2] = `h${(over.x?.baseVal.value + (over.width?.baseVal.value/2)) - (_x+_width/2)}`;
                                       d_mass[3] = `v${((over.y?.baseVal.value + over.height?.baseVal.value - 13.5) - _y)/2}`;

                                       remove_arrow_box(id,t_id,side,over.parentNode?.parentNode?.parentNode?.dataset?.type)

                                   }else if(over?.id === 'figure' && d_mass?.length>0){
                                       d_mass[1] = `v${((over.y?.baseVal.value - 23) - _y)/2}`;
                                       d_mass[2] = `h${(over.x?.baseVal.value + (over.width?.baseVal.value/2)) - (_x+_width/2)}`;
                                       d_mass[3] = `v${((over.y?.baseVal.value - 23) - _y)/2}`;

                                       remove_arrow_box(id,t_id,side,over.parentNode?.parentNode?.parentNode?.dataset?.type)

                                   }
                               }
                               if(side === 'left'){

                                   if(over?.id === 'circle' && d_mass?.length>0){

                                       d_mass[0] = `M${_x + _width - 3} ${_y + _height/2}`
                                       let v = (over.y?.baseVal?.value + (over.height?.baseVal?.value/2)) < (_y + _height/2) ? `v-${Math.abs((over.y?.baseVal?.value + over.height?.baseVal?.value/2) - (_y+_height/2))}`:`v${Math.abs((over.y?.baseVal?.value + over.height?.baseVal?.value/2) - (_y+_height/2)) }`
                                       d_mass[1] = `h-${Math.abs((over.x?.baseVal?.value - (_x + _width))/2 + 8)}`;
                                       d_mass[2] = v
                                       d_mass[3] = `h-${Math.abs((over.x?.baseVal?.value - (_x + _width))/2 + 8)}`;

                                       remove_arrow_box(id,t_id,side,over.parentNode?.parentNode?.parentNode?.dataset?.type)
                                   }else if(over?.id === 'figure' && d_mass?.length>0){
                                       d_mass[0] = `M${_x + _width - 3} ${_y + _height/2}`

                                       // d_mass[0] = `M${over.x?.baseVal?.value + over.width?.baseVal?.value + 21} ${over.y?.baseVal?.value + over.height?.baseVal?.value/2}`
                                       let v = (over.y?.baseVal?.value + (over.height?.baseVal?.value/2)) < (_y + _height/2) ? `v-${Math.abs((over.y?.baseVal?.value + (over.height?.baseVal?.value/2)) - (_y+_height/2))}`:`v${((over.y?.baseVal?.value + (over.height?.baseVal?.value/2)) - (_y+_height/2))}`
                                       d_mass[1] = `h-${Math.abs(((over.x?.baseVal?.value + over.width?.baseVal?.value + 24) - (_x + _width))/2)}`;
                                       d_mass[2] = v
                                       d_mass[3] = `h-${Math.abs(((over.x?.baseVal?.value + over.width?.baseVal?.value + 24) - (_x + _width))/2)}`;
                                       remove_arrow_box(id,t_id,side,over.dataset?.type)

                                   }
                               }
                               if(d_mass && d_mass.length>0){
                                arrow?.setAttributeNS(null,'d',d_mass.join(','));
                               }

                               // svg.insertBefore(arrow,svg.firstChild);

                               if(over){
                                   if(over?.id === 'circle'){
                                       add_storage(over?.parentNode?.parentNode?.parentNode)

                                   }else{
                                       add_storage(over?.closest('g'))
                                   }
                               }
                               arrow.addEventListener("dblclick",(e)=>{
                                const activeArrow = e.target as SVGPathElement;
                                showRemoveElement(activeArrow);
                               })
                               over = null;
                           }

                       }
                condition_label(arrow);

            }
            document.removeEventListener('mousemove',moveHandler,false);
            document.removeEventListener('mouseover',overHandler,false);

            
        }
        const leaveHandler = (e_up:MouseEvent)=>{
            document.removeEventListener('mousemove',moveHandler,false);
            document.removeEventListener('mouseover',overHandler,false);
        }

        document.addEventListener('mouseover',overHandler);

       if(e_down){

           const parent_element = e_down.currentTarget?.parentNode.parentNode as SVGGElement;
           const id = parent_element.dataset.id;
           const element = parent_element.querySelector('#figure') as SVGRectElement;
           const type = element.dataset.type
           const find_contact = svg.querySelector(`path[data-contact ^= "${id}-"]`) as SVGPathElement;

           const circle = e_down.currentTarget.querySelector('#circle') as SVGRectElement;
           const side = circle.dataset.side;
           const find_side = svg.querySelector(`[data-side = "${side}"][data-contact ^= "${id}-"]`) as SVGPathElement;

           if(type !== 'condt' && !find_contact){
               document.addEventListener('mousemove',moveHandler);
               document.addEventListener('mouseup',upHandler);

           }
           if(type === 'condt'  && !find_side){
               document.addEventListener('mousemove',moveHandler);
               document.addEventListener('mouseup',upHandler);

           }
       }
        document.addEventListener('mouseleave',leaveHandler);


    }
    group.addEventListener('mousedown',downHandler);
    group.addEventListener('click',(e)=>{
        e.preventDefault();
        e.stopPropagation()
    })

}
const showRemoveElement = (arrow:SVGPathElement) => {
    const board = document.querySelector("#StrategyBoard") as HTMLDivElement;

    const findRecycleBin = board.querySelector("#recycle_bin") as HTMLDivElement;
    
        let recycleBinCoord = {
            x:0,y:0
        } as any;
        if (arrow.dataset.side === "right" || arrow.dataset.side === "left" || arrow.dataset.side === "right-bottom-left" || arrow.dataset.side === "left-bottom-right" || arrow.dataset.side === "right-top-left" || arrow.dataset.side === "right-top-left") {
            let d = arrow.getAttribute("d") as string;
            let [m, h1, v] = d?.split(",");
            let mx = +m?.split(" ")?.[0]?.split("M")?.[1];
            let my = +m?.split(" ")?.[1];
            let h = +h1?.split("h")?.[1];
  
            let _split = v?.split("v");
            if (_split?.[1]?.split("-")?.length === 2) {
              let _v = Math.abs(+_split?.[1]?.split("-")?.[1]);
              let coord = { x: mx + h - 20.35, y: my - _v / 2 };
              recycleBinCoord ={
                x:coord.x,
                y:coord.y
              }
            } else {
              let _v = Math.abs(+_split?.[1]?.split("-")?.[0]);
              let coord = { x: 0, y: 0 };
              if (_v === 0) {
                coord.y = my + _v / 2 + 11;
                coord.x = mx + h - 20.35;
              } else {
                coord.y = my + _v / 2;
                coord.x = mx + h - 20.35;
              }
              recycleBinCoord ={
                x:coord.x,
                y:coord.y
              }
            }
          }
          if (arrow.dataset.side === "top" || arrow.dataset.side === "bottom" || arrow.dataset.side === "top-left-bottom" || arrow.dataset.side === "top-right-bottom" || arrow.dataset.side === "bottom-left-top" || arrow.dataset.side === "bottom-right-top" || arrow.dataset.side === "top-left" || arrow.dataset.side === "top-right" || arrow.dataset.side === "bottom-left"  || arrow.dataset.side === "bottom-right"  ) {
            let d = arrow.getAttribute("d") as string;
            let [m, v1, h] = d?.split(",");
            let mx = +m?.split(" ")?.[0]?.split("M")?.[1];
            let my = +m?.split(" ")?.[1];
            let v = +v1?.split("v")?.[1];
  
            let _split = h?.split("h");
            if (_split?.[1]?.split("-")?.length === 2) {
              let _h = Math.abs(+_split?.[1]?.split("-")?.[1]);
              let coord = { x: mx - _h / 2 - 20.35, y: my + v + 11 };
              recycleBinCoord ={
                x:coord.x,
                y:coord.y
              }
            } else {
              let _h = Math.abs(+_split?.[1]);
  
              let coord = { x: 0, y: 0 };
              if (_h === 0) {
                coord.y = my + v;
                coord.x = mx + _h / 2 - 20.35;
              } else {
                coord.y = my + v + 11;
                coord.x = mx + _h / 2 - 20.35;
              }
              recycleBinCoord ={
                x:coord.x,
                y:coord.y
              }
            }
          }
          if(findRecycleBin){
            board.removeChild(findRecycleBin);
          }
          const selected = arrow.dataset.contact as string;
          const recycleBin = `<div  id="recycle_bin" style="left:${recycleBinCoord.x}px;top:${recycleBinCoord.y}px;display:${recycleBinCoord.x !== 0 || recycleBinCoord.y !== 0 ? 'block' :'none'}" ><img src="${recycleSvg}"/></div>`
      
          board.insertAdjacentHTML('afterbegin', recycleBin)
          const deletedBtn = board.querySelector("#recycle_bin") as HTMLDivElement;
          if(!deletedBtn) return;
          deletedBtn.addEventListener("click",()=>{
            removeArrowHandler(selected ?? "");
            board.removeChild(deletedBtn)
          })
}


const removeArrowHandler = (selected:string) => {
    const svg = document.querySelector("#svg_board") as SVGElement;
    if (selected) {
      let find_arrows = svg.querySelectorAll(
        `path[data-contact $= "${selected}"]`
      ) as NodeList;
      let find_arrows_target = svg.querySelectorAll(
        `path[data-contact ^= "${selected}"]`
      ) as NodeList;

      remove_arrow_db(selected);

      if (find_arrows?.length > 0) {
        svg.removeChild(find_arrows[0]);
        let find_arrow = find_arrows[0] as SVGPathElement;

        const id = find_arrow?.dataset.contact;
        if (id) {
          let find_text = svg.querySelectorAll(
            `text[id = "${id}"]`
          ) as NodeList;
          find_text?.forEach((text: any) => {
            svg.removeChild(text);
          });
        }

        call_arrow_box_source(find_arrows);
        if (find_arrows_target) {
          call_arrow_box_target(find_arrows_target);
        }
        
      }
    }
  };