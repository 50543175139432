import React, { FC, useState, useEffect } from "react";
import classes from "./CheckboxV2.module.scss";
import { icons } from "../../../settings/settings";

interface ICheckbox {
  id?: string;
  label?: string | number;
  labelColor?: string;
  unCheckedPrefix?: string | number;
  value?: boolean;
  disabled?: boolean;
  size?: string;
  onChange?: (event: any) => void;
}

const Checkbox: FC<ICheckbox> = ({
  id,
  label,
  labelColor,
  unCheckedPrefix,
  value,
  disabled,
  size,
  onChange = (valueState) => valueState,
}) => {
  const [valueState, setValueState] = useState<boolean>(false);

  useEffect(() => {
    if (typeof value !== "boolean") return

    setValueState(value);
  }, [value]);

  const checkHandler = () => {
    setValueState(!valueState);
    onChange(!valueState);
  };

  return (
    <div className={classes.CheckboxContainer} id={id}>
      <input
        type="checkbox"
        onChange={() => checkHandler()}
        checked={valueState}
        disabled={disabled}
        style={size === "small" ? { width: "1.44vh", height: "1.44vh" } : {}}
      />
      {label && (
        <label style={{ color: labelColor }}>
          {`${unCheckedPrefix && !valueState ? unCheckedPrefix : label}`}
        </label>
      )}

      <img
        src={icons.checkbox}
        alt="checked icon"
        style={
          size === "small"
            ? { width: "1vh", height: "1vh", left: "0.75vh" }
            : {}
        }
      />
    </div>
  );
};

export default Checkbox;
