import React, { useEffect } from "react";
import classes from "./Dashboard.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useGetAllData } from "../../hooks/useGetAllData";
import { handleStrategyData } from "../../store/actions/actionsStrategy";
import { handleScoreCardData } from "../../store/actions/actionsScoreCard";
import { handleProductData } from "../../store/actions/actionsProduct";
import { handleDecisionData } from "../../store/actions/actionsDecision";
import DashboardTable from "components/UI/DashboardTable/DashboardTable";

interface SectionProps {
  sections?: any[];
}

type DashboardAdditions = [string];

const dashboardDesignerAdditions: DashboardAdditions[] = [
  ["strategy"],
  ["scorecard"],
  ["products"],
  ["decision"],
];

const get5elem = (arr: any[]): typeof arr => {
  const emtyArr = Array.from({ length: 5 }, () => null);

  return [...arr, ...emtyArr].slice(0, 5);
};

const Dashboard: React.FC<SectionProps> = ({ sections }) => {
  const {
    reducerStrategy: { StrategyData },
    reducerScoreCard: { scoreCardData },
    reducerProduct: { products },
    reducerDecision: { decisionState },
  } = useSelector((state: RootState) => state);

  const last5Map: Record<string, any[]> = {
    strategy: get5elem(StrategyData.data || []),
    scorecard: get5elem(scoreCardData.scoreCardData || []),
    products: get5elem(products),
    decision: get5elem(decisionState),
  };

  const {
    strategy: { getAllStrategies },
    scorecard: { getAllScorecards },
    product: { getAllProducts },
    decision: { getAllDecisions },
  } = useGetAllData();

  useEffect(() => {
    getAllStrategies.get({
      reduxData: StrategyData?.data?.length,
      action: (data) => handleStrategyData({ data }),
    });
  }, [StrategyData, getAllStrategies]);

  useEffect(() => {
    if (getAllScorecards.isLoading) return;

    getAllScorecards.get({
      reduxData: scoreCardData.scoreCardData,
      action: (scoreCardData) => handleScoreCardData({ scoreCardData }),
    });
  }, [scoreCardData, getAllScorecards]);

  useEffect(() => {
    getAllProducts.get({
      reduxData: products.length,
      action: handleProductData,
    });
  }, [products, getAllProducts]);

  useEffect(() => {
    getAllDecisions.get({
      reduxData: decisionState.length,
      action: handleDecisionData,
    });
  }, [decisionState, getAllDecisions]);

  return (
    <div className={classes.Dashboard}>
      <div className={classes.TopSection}>
        <div className={classes.Title}>Dashboard</div>
        <div className={classes.SectionList}>
          {sections?.map((section, idx) => {
            const [type] = dashboardDesignerAdditions[idx];

            return (
              <DashboardTable
                key={idx}
                section={section}
                index={idx}
                data={last5Map[type]}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
