import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import classes from "./StrategyEditor.module.scss";
import { v1 as uuidv1 } from "uuid";
import { completeParentheses } from "../../../helpers/completeParentheses";
import {
  ISearchReturn,
  searchLocalPossibilities,
} from "../../../helpers/searchLocalPossibilities";
import { useDebounce } from "use-debounce/lib";
import PossibilitiesList from "../PossibilitiesList/PossibilitiesList";
import { getTextCursorCoordinates } from "../../../helpers/getTextCursorCoordinates";
import {
  ButtonTypes,
  EditorKeySources,
  editorOperatorList,
  ElementTypes,
  messages,
  ModalNames,
  storageKeys,
} from "../../../settings/settings";
import Modal from "../../../components/UI/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/combineReducer";
import { openModal, resetModal } from "../../../store/actions/actionsModal";

interface IStrategyEditor {
  label?: string;
  value: string;
  valueIndex?: string;
  onChange: (value: { valueIndex: string; value: string } | string) => void;
  onPaste?: any;
  getValueIndex?: (index: string) => void;
  iterateKey?: string;
  height?: string;
  objectData?: object;
  functionData?: IIFunctionData;
  tabIndex?: number;
  validate?: boolean;
  elementType?: ElementTypes;
  editorModalName: ModalNames;
  getManualKeyStatus?: (status: { [key: string]: boolean }) => void;
  getIndexedValue?: (value: string) => void;
  onSingleClick?: (description: string, syntax: string) => void;
  getDataType?: (dataType: string) => void;
  replaceOnDrop?: boolean;
}

interface ICursorCoordinates {
  top: string;
  left: string;
}

export interface IIndexedKeys {
  source: EditorKeySources;
  key: string;
}

export interface IIFunctionData {
  editorIndex: string;
  data: IIndexedKeys;
}

let prevValue: Array<{ id: string; _value: string | undefined }> = [];const inputRegExp = /(root|UserData).([.\w[\]]+)/g;
export const iterateRegExp = /ITERATE.([.\w[\]]+)/g;
export const clearExtensionRegExp = /(root|ITERATE|UserData)\./g;
export const valueRegExp = /(?!\[)([\w.[\]]+)(?<!\\])/g;
export const operatorRegExp = /(&&|\*\*|\|\||!=|=>|<=|==|[~+\-*,/=<>()[\]])/;
export const stringRegExp = /("|')[\w\W]+("|')/;
export const numberRegExp = /(?<![\w\\[])[\d.\\?]+(?![\w\]])/;
export const whiteSpaceRegExp = /(\s+)/;
export const nullRegExp = /^null$/;
const combinedRegExp = new RegExp(
  `${valueRegExp.source}|${operatorRegExp.source}|${whiteSpaceRegExp.source}|${stringRegExp.source}|${nullRegExp.source}`,
  "g"
);

const StrategyEditor: FC<IStrategyEditor> = ({
  label,
  value,
  valueIndex,
  onChange,
  getValueIndex,
  iterateKey,
  height,
  functionData,
  tabIndex,
  validate = false,
  elementType,
  editorModalName,
  getManualKeyStatus,
  getIndexedValue,
  onPaste,
  onSingleClick,
  getDataType,
  replaceOnDrop
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const labelRef = useRef<HTMLSpanElement | null>(null);
  const clearRef = useRef<HTMLButtonElement | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const backdropRef = useRef<HTMLDivElement | null>(null);
  const highlightsRef = useRef<HTMLDivElement | null>(null);
  const textDropLayerRef = useRef<HTMLDivElement | null>(null);

  const dispatch = useDispatch();

  const {
    reducerModal: { modalState },
  } = useSelector((state: RootState) => state);

  const [uniqueId, setUniqueId] = useState<string | null>(null);
  const [hasFocus, setHasFocus] = useState<boolean>(false);
  const [cursorPosition, setCursorPosition] = useState<number>(0);
  const [cursorCoordinates, setCursorCoordinates] =
    useState<ICursorCoordinates | null>(null);
  const [searchStr, setSearchStr] = useState<string | null>(null);
  const [searchResult, setSearchResult] = useState<ISearchReturn | null>(null);
  const [importedKeys, setImportedKeys] = useState<Array<IIndexedKeys>>([]);
  const [indexedKeys, setIndexedKeys] = useState<Array<IIndexedKeys>>([]);
  const [searchKey] = useDebounce(searchStr, 1e2);

  const generateUniqueId = useCallback(() => {
    if (uniqueId) return;

    setUniqueId(uuidv1());
  }, [uniqueId]);

  const setPreviousValue = useCallback(() => {
    if (!uniqueId) return;

    const hasPrevValue = prevValue.find((prevObj) => {
      const { id } = prevObj;
      return id === uniqueId;
    });

    if (!hasPrevValue) {
      prevValue.push({ id: uniqueId, _value: value });
    }

    prevValue = prevValue.map((prevObj) => {
      const { id } = prevObj;

      return id === uniqueId ? { ...prevObj, _value: value } : prevObj;
    });
  }, [uniqueId, value]);

  const checkPrevEquality = useCallback(() => {
    const specificPrevObj = prevValue.find((prevObj) => {
      const { id } = prevObj;

      return id === uniqueId;
    });

    if (!specificPrevObj) return false;

    const { _value } = specificPrevObj;

    return _value === value;
  }, [uniqueId, value]);

  const parseValue = useCallback(() => {
    if (!elementType || checkPrevEquality()) return;
    function reformatSearchResult(result: any) {

      return result
        .map((searchResObj: any) => {
          const { source, keys } = searchResObj;
          return keys.map((key: string) => {
            return {
              source,
              key,
            };
          });
        })
        .flat();
    }
    if (!importedKeys.length) {
      const inputKeys = value?.match(inputRegExp) ?? [];
      const iterateKeys = value?.match(iterateRegExp) ?? [];
      const allKeys = value?.match(valueRegExp) ?? [];
      inputKeys.forEach((key) => {
        const cleanedKey = key.replace(inputRegExp, "$2");
        setImportedKeys((state) => {
          return [
            ...state,
            {
              source: EditorKeySources.INPUT,
              key: cleanedKey,
            },
          ];
        });
      });

      iterateKeys.forEach((key) => {
        const cleanedKey = key.replace(iterateRegExp, "$1");
        setImportedKeys((state) => {
          return [
            ...state,
            {
              source: EditorKeySources.ITERATE,
              key: cleanedKey,
            },
          ];
        });
      });

      allKeys.forEach((key) => {
        const isNumber = Number(key);

        if (isNumber) return;

        searchLocalPossibilities({ searchValue: key, iterateKey })
          .then((result) => {
            if (!result) return;

            setImportedKeys((state) => {
              return [...state, reformatSearchResult(result).flat()].flat();
            });
          })
          .catch(() => {});
      });
    }

    setPreviousValue();
  }, [
    value,
    elementType,
    iterateKey,
    importedKeys,
    setPreviousValue,
    checkPrevEquality,
  ]);

  const generateIndexedKeys = useCallback(() => {
    if (!elementType) return;

    if (!value) {
      setIndexedKeys([]);
      return;
    }

    const matchedArray: Array<string> | null = value.match(combinedRegExp);
    const splittedMatchedArray = [] as any[];
    const regExQuotes = /(["'])(?:(?=(\\?))\2.)*?\1/g;

    matchedArray?.forEach((matched:string)=>{
      if(regExQuotes.test(matched)){
        const splitted = matched.split(",")

        splitted.forEach((item:string,i:number)=>{
          splittedMatchedArray.push(item)
          if(splitted.length>1 && i<splitted.length-1){
            splittedMatchedArray.push(",")
          }

        })
      }else{
        splittedMatchedArray.push(matched)
      }
    })
    if (!splittedMatchedArray) return;
    let splitObjArray: Array<IIndexedKeys> = splittedMatchedArray.map(
      (matched: string) => {
        const isOperator = operatorRegExp.test(matched);
        const isString = stringRegExp.test(matched);
        const isNumber = numberRegExp.test(matched);
        const isWhiteSpace = whiteSpaceRegExp.test(matched);
        const isNull = nullRegExp.test(matched);
        const copyOfImportedKeys = [...importedKeys];
        const rootKeys = [] as any;
        copyOfImportedKeys.forEach(({source,key}:any)=>{
          if(source === "Input"){
            if(key.includes('.')){
              rootKeys.push({source:"Input",key:key.split('.')[0]})
            }
          }
        })
        const ids = rootKeys.map(({key}:any) => key)
        const uniqueKeys = rootKeys.filter(({source,key}:any, index:any) => !ids.includes(key, index + 1));


        const isImportedKey =valueIndex ==="parentKey" ? [...uniqueKeys,...copyOfImportedKeys].find((importedKey: IIndexedKeys) => {
          const { key } = importedKey;
          return key === matched;
        })
        :
        copyOfImportedKeys.find((importedKey: IIndexedKeys) => {
          const { key } = importedKey;
          return key === matched;
        });
       
        if (isOperator) {
          return {
            source: EditorKeySources.OPERATOR,
            key: matched,
          };
        }

        if (isString) {
          return {
            source: EditorKeySources.STRING,
            key: matched,
          };
        }

        if (isNumber) {
          return {
            source: EditorKeySources.NUMBER,
            key: matched,
          };
        }

        if (isWhiteSpace) {
          return {
            source: EditorKeySources.SPACE,
            key: matched,
          };
        }

        if (isNull) {
          return {
            source: EditorKeySources.NULL,
            key: matched,
          };
        }

        if (!isImportedKey) {
          return {
            source: EditorKeySources.MANUAL,
            key: matched,
          };
        }
        return isImportedKey;
      }
    );
    setIndexedKeys(splitObjArray.flat());
  }, [elementType, value, importedKeys, valueIndex]);

  const passValueIndex = useCallback(() => {
    if (!valueIndex || !getValueIndex) return;

    getValueIndex(valueIndex);
  }, [valueIndex, getValueIndex]);

  const focusLabel = useCallback(() => {
    const { current } = labelRef;
    const hasClass = current?.classList.contains(classes.ActiveLabel);

    if (!value || hasClass) return;

    current?.classList.add(classes.ActiveLabel);
  }, [value]);

  const showClearButton = useCallback(() => {
    const { current } = clearRef;

    if (!value) {
      current?.classList.remove(classes.ActiveClearButton);
      return;
    }

    current?.classList.add(classes.ActiveClearButton);
  }, [value]);

  const hidePossibilities = useCallback(() => {
    setSearchResult(null);
  }, []);

  const focus = useCallback(() => {
    const wrapperElement = wrapperRef.current;
    const labelElement = labelRef.current;
    const hasClass = labelElement?.classList.contains(classes.ActiveWrapper);

    setHasFocus(true);
    passValueIndex();

    if (hasClass) return;

    wrapperElement?.classList.add(classes.NotHover);
    labelElement?.classList.add(classes.ActiveLabel);
  }, [passValueIndex]);

  const blur = useCallback(() => {
    const wrapperElement = wrapperRef.current;
    const labelElement = labelRef.current;

    setHasFocus(false);
    wrapperElement?.classList.remove(classes.NotHover);

    if (value) return;

    labelElement?.classList.remove(classes.ActiveLabel);
  }, [value]);

  const checkSourceAndAdd = useCallback(
    (indexedKey: IIndexedKeys): string => {
      const { source, key } = indexedKey;

      switch (source) {
        case EditorKeySources.INPUT: {
          return `${
            elementType === ElementTypes.CONDITION ? "$UserData" : "root"
          }.${key}`;
        }
        case EditorKeySources.ITERATE: {
          return `${source.toUpperCase()}.${key}`;
        }
        case EditorKeySources.SELF: {
          return `ITERATE.${key.replace('SELF.','')}`;
        }
        default: {
          return key;
        }
      }
    },
    [elementType]
  );

  const passIndexedValue = useCallback(() => {
    let finalValue: string = "";

    if (ElementTypes && indexedKeys.length) {
      indexedKeys.forEach((indexedKey: IIndexedKeys) => {
        finalValue += checkSourceAndAdd(indexedKey);
      });
    }

    if (!getIndexedValue) return;

    getIndexedValue(finalValue);
  }, [getIndexedValue, indexedKeys, checkSourceAndAdd]);

  const passValue = useCallback(
    (value: string) => {
      onChange(valueIndex ? { valueIndex, value } : value);
    },
    [onChange, valueIndex]
  );

  const insertToCursorPosition = useCallback(
    (source: EditorKeySources, key: string) => {
      const cleanedValue = replaceOnDrop 
        ? "" 
        : elementType
          ? value?.replace(clearExtensionRegExp, "")
          : value;
      const insertion =
        source === EditorKeySources.FUNCTION ? `${key}( )` : key;

      if (!cursorPosition) {
        passValue(`${insertion}${cleanedValue}`);
        return;
      }

      const beforePosition = cleanedValue.substring(0, cursorPosition);
      const afterPosition = cleanedValue.substring(cursorPosition);
      const combineResult = `${beforePosition}${insertion}${afterPosition}`;
      let copyOfResult = combineResult;
      if(iterateKey && copyOfResult.includes(iterateKey.replace('root.',''))){
        copyOfResult = copyOfResult.replaceAll(iterateKey.replace('root.',''),'SELF')
      }
      passValue(copyOfResult);
      // setCursorPosition(`${beforePosition}${insertion}`.length);
      setCursorPosition(copyOfResult.length);

    },
    [elementType, value, cursorPosition, iterateKey, passValue, replaceOnDrop]
  );

  const insertSymbol = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const { currentTarget } = event;
      let symbol = currentTarget.getAttribute("data-symbol");

      if (!symbol) return;

      if (symbol === "(") symbol = completeParentheses(value);

      insertToCursorPosition(EditorKeySources.OPERATOR, symbol);
    },
    [insertToCursorPosition, value]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const {
        target: { value },
      } = event;
      let copyOfValue = value;
      if(iterateKey && value.includes(iterateKey.replace('root.',''))){
        copyOfValue = copyOfValue.replaceAll(iterateKey.replace('root.',''),'SELF')
      }

      if(copyOfValue.includes("!=")){
        copyOfValue = copyOfValue.replaceAll("!=","<>")
      }

      
      
      hidePossibilities();
      passValue(copyOfValue);
    },
    [iterateKey, hidePossibilities, passValue]
  );

  const changeCursorPosition = useCallback((event: any) => {
    const {
      target: { selectionStart },
    } = event;

    setCursorPosition(selectionStart);
  }, []);

  const pushToImportedKeys = useCallback((data: IIndexedKeys) => {
    setImportedKeys((importedKeys) => [...importedKeys, data]);
  }, []);

  const showDropSymbol = useCallback(() => {
    const textDropLayer = textDropLayerRef.current;
    const dropSymbol = document.createElement("span");
    dropSymbol.setAttribute("id", "dropSymbol");
    dropSymbol.setAttribute("class", classes.DropSymbol);
    const dropSymbolNode = document.getElementById("dropSymbol");

    if (!textDropLayer) return;

    if (!dropSymbolNode) {
      textDropLayer.insertBefore(
        dropSymbol,
        textDropLayer.childNodes[cursorPosition]
      );
      return;
    }

    textDropLayer.removeChild(dropSymbolNode);
  }, [cursorPosition]);

  const dragEnter = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      const { currentTarget } = event;

      if (!currentTarget) return;

      currentTarget.classList.add(classes.Drop);
      showDropSymbol();
      hidePossibilities();
    },
    [showDropSymbol, hidePossibilities]
  );

  const dragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const { currentTarget } = event;
    if (!currentTarget) return;

    const hasClass = currentTarget.classList.contains(classes.Drop);

    if (!hasClass) {
      currentTarget?.classList.add(classes.Drop);
    }
  }, []);

  const dragLeave = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      const { currentTarget } = event;

      if (!currentTarget) return;

      currentTarget.classList.remove(classes.Drop);

      showDropSymbol();
    },
    [showDropSymbol]
  );

  const drop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      const { currentTarget, dataTransfer } = event;
      const textarea = textareaRef.current;
      const draggingSource = dataTransfer.getData("dragging_source");
      const draggingVariable = dataTransfer.getData("dragging_variable");
      const draggingMap = dataTransfer.getData("dragging_map");
      const draggingDecision = dataTransfer.getData("dragging_decision");
      const draggingFunction = dataTransfer.getData("dragging_function");
      const functionInfo = dataTransfer.getData("function_info");
      

      const droppedDataJSON: string | undefined =
        draggingSource ||
        draggingVariable ||
        draggingMap ||
        draggingFunction ||
        draggingDecision;

      currentTarget?.classList.remove(classes.Drop);

      if (!droppedDataJSON || !textarea) return;
      if (functionInfo) {
        const parsedFunctionInf = JSON.parse(functionInfo) as {
          description: string;
          syntax: string;
        };
        if (!onSingleClick) return;
        const { description, syntax } = parsedFunctionInf;
        onSingleClick(description as string, syntax as string);
      }
      const droppedDataObj: IIndexedKeys = JSON.parse(droppedDataJSON);
      pushToImportedKeys(droppedDataObj);
      insertToCursorPosition(droppedDataObj.source, droppedDataObj.key);
      textarea.focus();
    },
    [insertToCursorPosition, onSingleClick, pushToImportedKeys]
  );


  const generateHighlights = useCallback(() => {
    if (!elementType) return;

    const textareaElement = textareaRef.current;
    const backdropElement = backdropRef.current;
    const highlightsElement = highlightsRef.current;
    const textDropLayer = textDropLayerRef.current;
    let textContent: string='';
    if(value?.includes('ITERATE')){
      textContent = value.replaceAll('ITERATE','SELF').replaceAll('root.','')

    }else if(value?.startsWith('root')){
      textContent = value.replaceAll('ITERATE','SELF').replaceAll('root.','')

    }else{
      textContent = value
      ?.replace(clearExtensionRegExp, "")
      ?.replace(/\n$/g, "\n\n");
    }
   
    let highlightsText: string = textContent;
    let dropLayerText: string = textContent;
    if (
      !textareaElement ||
      !backdropElement ||
      !highlightsElement ||
      !textDropLayer
    )
      return;
    indexedKeys.forEach((indexedKey: IIndexedKeys) => {
      const { source, key } = indexedKey;
      if (source === EditorKeySources.MANUAL) {

        highlightsText = highlightsText.replace(
          new RegExp(`\\b(?<!<mark>)(${key})\\b`, "g"),
          "<mark>$&</mark>"
        );
        if(key.startsWith('SELF') && iterateKey){
          highlightsText = highlightsText.replace(/(<mark[^>]*>|<\/mark>)/g, '')
        }
        
      }
    });

    dropLayerText = dropLayerText?.replace(/\w|\W/g, "<span>$&</span>");

    if (getManualKeyStatus) {
      getManualKeyStatus({
        [valueIndex ?? "status"]: highlightsText?.includes("<mark>"),
      });
    }
    highlightsElement.innerHTML = highlightsText;
    textDropLayer.innerHTML = dropLayerText;
  }, [elementType, value, indexedKeys, getManualKeyStatus, iterateKey, valueIndex]);

  const trimSearchString = useCallback(() => {
    if (!value || !hasFocus) {
      return;
    }

    const cutString = value.substring(0, cursorPosition);

    if (cutString.endsWith(" ")) {
      return;
    }

    const searchables = cutString.match(valueRegExp);

    if (!searchables) {
      return;
    }

    const lastSearchableIndex = searchables.length - 1;
    let searchable = searchables[lastSearchableIndex];

    setSearchStr(searchable);
  }, [value, hasFocus, cursorPosition]);

  const searchPossibilities = useCallback(() => {
    if (!searchKey || searchKey.length === 1) {
      hidePossibilities();
      return;
    }

    if (
      importedKeys.length &&
      searchKey === importedKeys[importedKeys.length - 1].key
    ) {
      hidePossibilities();
      setSearchStr(null);
      return;
    }

    searchLocalPossibilities({
      searchValue: searchKey,
      iterateKey: iterateKey?.replace(clearExtensionRegExp, ""),
      label:label
    })
      .then((result: any) => {
        if (!result) return;
        setSearchResult(result);
      })
      .catch(hidePossibilities);
  }, [searchKey, importedKeys, iterateKey, label, hidePossibilities]);

  const getCursorCoordinates = useCallback(() => {
    const textarea = textareaRef.current;
    if (!textarea || !searchStr) return;

    setCursorCoordinates(
      getTextCursorCoordinates({ element: textarea, cursorPosition })
    );
  }, [cursorPosition, searchStr]);

  const getStrategyFunctions = () => {
    const strategyFunctionsJSON = sessionStorage.getItem(
      storageKeys.reformattedStrategyFunctions
    );

    if (!strategyFunctionsJSON) return;

    const strategyFunctionsArray = JSON.parse(strategyFunctionsJSON);

    return strategyFunctionsArray;
  };
  const replaceWithSelectedPossibility = useCallback(
    (selection: IIndexedKeys) => {
      if (!searchKey) return;

      const { source, key } = selection;
        const copyOfData = [...getStrategyFunctions()] as any[];
        const getInfo = copyOfData
        ?.map((list: any) => {
          return list?.functions?.filter((f: any) => f.name === key);
        })
        ?.filter((f: any) => f.length > 0)?.[0];
        if (getInfo?.length>0) {
          const [item] = getInfo
          if (!onSingleClick) return;
          const { description, syntax } = item;
          onSingleClick(description as string, syntax as string);
        }
      

      const textarea = textareaRef.current;
      const selectedPossibility =
        source === EditorKeySources.FUNCTION ? `${key}( )` : key;
      const selectionStart: number = cursorPosition - searchKey.length;
      const selectionEnd: number = cursorPosition;

      const firstCutPart: string = value.substring(0, selectionStart);
      const lastCutPart: string = value !== "ITERATE" ? value.substring(selectionEnd):"";
      const combinedValue: string = `${firstCutPart}${selectedPossibility}${lastCutPart}`;
      // const newCursorPosition = `${firstCutPart}${selectedPossibility}`.length;
      hidePossibilities();
      pushToImportedKeys(selection);
      let copyOfResult = combinedValue;

      if(iterateKey && copyOfResult.includes(iterateKey.replace('root.',''))){
        copyOfResult = copyOfResult.replaceAll(iterateKey.replace('root.',''),'SELF')

      }
      // setCursorPosition(newCursorPosition);
      setCursorPosition(copyOfResult.length);

      passValue(copyOfResult);

      textarea && textarea.focus();
    },
    [searchKey, cursorPosition, value, hidePossibilities, pushToImportedKeys, iterateKey, passValue, onSingleClick]
  );

  const onScroll = useCallback((event: React.UIEvent<HTMLTextAreaElement>) => {
    const { currentTarget } = event;
    const backdropElement = backdropRef.current;

    if (!backdropElement) return;

    backdropElement.scrollTop = currentTarget.scrollTop;
    backdropElement.scrollLeft = currentTarget.scrollLeft;
  }, []);

  const handleKeyPress = (event:any)=>{
    if(valueIndex === "parentKey" && event.key === "Enter") event.preventDefault()
  }
  const openClearModal = useCallback(() => {
    dispatch(
      openModal({
        modalState: {
          visible: true,
          title: messages.titleClearEditor,
          question: messages.titleSureClearEditor,
          message: "",
          buttonMessage: messages.titleBtnClear,
          buttonType: ButtonTypes.DELETE,
          name: editorModalName,
        },
      })
    );
  }, [dispatch, editorModalName]);

  const onModalAction = useCallback(
    (action: boolean) => {
      if (editorModalName !== modalState?.name) return;

      if (action) {
        passValue("");
        const { current } = labelRef;

        if (!current) return;

        current.classList.remove(classes.ActiveLabel);
      }

      dispatch(resetModal());
    },
    [editorModalName, passValue, modalState, dispatch]
  );

  const includeFunctionBody = useCallback(() => {
    if (!functionData) return;

    const { editorIndex, data } = functionData;
    
    if (editorIndex !== valueIndex) return;
    pushToImportedKeys(data);
    insertToCursorPosition(data.source, data.key);
  }, [functionData, valueIndex, pushToImportedKeys, insertToCursorPosition]);

  const checkValidateStatus = useCallback(() => {
    const { current } = wrapperRef;

    if (!current) return;

    if (!validate) {
      current.classList.remove(classes.Validate);
      return;
    }

    current.classList.add(classes.Validate);
  }, [validate]);

  const manipulateClicks = useCallback(
    (event: MouseEvent) => {
      const target = event?.target as HTMLElement;

      if (target?.dataset?.name !== "possibility-row") {
        hidePossibilities();
      }
    },
    [hidePossibilities]
  );

  const manipulateKeyboard = useCallback(
    (event: KeyboardEvent) => {
      const { keyCode } = event;
      const escKeyCode = 27;

      if (keyCode === escKeyCode) {
        hidePossibilities();
      }
    },
    [hidePossibilities]
  );

  const generateOperatorButtons = useCallback(() => {
    return editorOperatorList.map(({ key, title, symbol, dataSymbol }) => {
      return (
        <button
          key={key}
          title={title}
          data-symbol={dataSymbol}
          onClick={insertSymbol}
        >
          {symbol}
        </button>
      );
    });
  }, [insertSymbol]);

  useLayoutEffect(generateUniqueId, [generateUniqueId]);

  useLayoutEffect(parseValue, [parseValue]);
  useEffect(()=>{
    function reformatSearchResult(result: any) {

      return result
        .map((searchResObj: any) => {
          const { source, keys } = searchResObj;

          return keys.map((key: string) => {
            return {
              source,
              key,
            };
          });
        })
        .flat();
    }
    if(elementType === ElementTypes.DATA_WIZARD){
      
        const allKeys = value?.match(valueRegExp) ?? [];
        allKeys.forEach((key) => {
          const isNumber = Number(key);
  
          if (isNumber) return;
  
          searchLocalPossibilities({ searchValue: key, iterateKey })
            .then((result) => {
              if (!result) return;
  
              setImportedKeys((state) => {
                return [...state, reformatSearchResult(result).flat()].flat();
              });
            })
            .catch(() => {});
        });
    }
  }, [iterateKey, value,elementType]);
  useLayoutEffect(() => {
    document.addEventListener("click", manipulateClicks);
    document.addEventListener("keydown", manipulateKeyboard);

    return () => {
      document.removeEventListener("click", manipulateClicks);
      document.removeEventListener("keydown", manipulateKeyboard);
    };
  }, [manipulateClicks, manipulateKeyboard]);

  useEffect(passIndexedValue, [passIndexedValue]);

  useEffect(focusLabel, [focusLabel]);

  useEffect(showClearButton, [showClearButton]);

  useEffect(trimSearchString, [trimSearchString]);

  useEffect(getCursorCoordinates, [getCursorCoordinates]);

  useEffect(generateHighlights, [generateHighlights]);

  useEffect(searchPossibilities, [searchPossibilities]);

  useEffect(hidePossibilities, [hidePossibilities, cursorPosition]);

  useEffect(includeFunctionBody, [includeFunctionBody]);

  useEffect(checkValidateStatus, [checkValidateStatus]);

  useEffect(generateIndexedKeys, [generateIndexedKeys]);

  const decisionValue = (type:ElementTypes):string=>{
   
    let textContent: string='';
    const splittedValue = value?.split(operatorRegExp) as string[];
    splittedValue?.forEach((val:string)=>{
      if(val && !operatorRegExp.test(val)){
    
          const index = splittedValue.indexOf(val);
          
          if(val.includes("ITERATE")){
            val = val.replaceAll("ITERATE","SELF")
          }
          if(val.includes("root")){
            val = val.replaceAll("root.","")
          }
          if(val.includes("$UserData")){
            val = val.replaceAll("$UserData.","")
          }
          
          splittedValue[index] = val;
          textContent = splittedValue.join('');
      }
    }) 
    return textContent
  }

  const handleDrop = (event:any)=>{
    event.preventDefault();
    const { currentTarget, dataTransfer } = event;
    const draggingData = dataTransfer.getData("dragging_source");
    currentTarget?.classList.remove(classes.Drop);

    if (!draggingData) return;

    const { dataType } = JSON.parse(draggingData);
    getDataType && getDataType(dataType)
  }
  return (
    <div
      ref={wrapperRef}
      id={`wrapper-${uniqueId}`}
      className={valueIndex === "parentKey" ? [classes.StrategyEditorWrapper,classes.ForInputWrapper].join(" ") :[classes.StrategyEditorWrapper,classes.ForTextareaWrapper].join(" ")}
      style={{ height }}      
    >    
      {valueIndex !== "parentKey" && 
          <div className={classes.StrategyEditorHeader}>
            {generateOperatorButtons()}
          </div>
      }
      <div className={classes.StrategyEditorBody} >
        <div className={classes.BodyActions}>
          <span ref={labelRef} className={classes.Label}>
            {label}
          </span>
          {valueIndex !== "parentKey" && <button
            ref={clearRef}
            className={classes.ClearButton}
            onClick={openClearModal}
          >
            Clear
          </button> }
        </div>
        <div
          className={classes.TextareaWrapper}
          onDragEnter={dragEnter}
          onDragOver={dragOver}
          onDragLeave={dragLeave}
          onDrop={drop}
          
        >
          <div ref={backdropRef} className={classes.Backdrop}>
            <div ref={textDropLayerRef} className={classes.TextDropLayer} />
            <div ref={highlightsRef} className={classes.Highlights} />
          </div>
          <textarea
            ref={textareaRef}
            name="editor"
            id={`${uniqueId}`}
            tabIndex={tabIndex}
            value={
              elementType ? decisionValue(elementType):''
            }
            onChange={handleChange}
            onKeyUp={changeCursorPosition}
            onMouseUp={changeCursorPosition}
            onFocus={focus}
            onBlur={blur}
            onScroll={onScroll}
            onKeyPress={handleKeyPress}
            spellCheck="false"
            onPaste={onPaste}
            onDrop={handleDrop}
          />
          {searchResult && (
            <PossibilitiesList
              showOnly={valueIndex}
              possibilities={searchResult}
              cursorCoordinates={cursorCoordinates}
              maxLeftCoordinate={wrapperRef.current?.offsetWidth}
              onSelect={replaceWithSelectedPossibility}
            />
          )}
        </div>
      </div>
      <Modal
        title={modalState?.title}
        question={modalState?.question}
        message={modalState?.message}
        buttonMessage={modalState?.buttonMessage}
        buttonType={modalState?.buttonType}
        onAction={onModalAction}
        visible={modalState?.visible}
        name={editorModalName}
      />
    </div>
  );
};

export default React.memo(StrategyEditor);
