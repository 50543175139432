import {  SourceDataTypes, StrategyActionsTypes } from "../../../types/typesStrategy";

const initialState: SourceDataTypes = {
    data: null
}

const SourceDataTypesReducer = (state = initialState, action: StrategyActionsTypes): SourceDataTypes => {
    switch (action.type) {
        case "STRATEGY_SOURCE_DATA_TYPES":
            const {data} = action.payload

            return {
                data
            }
    
        default:
            return state
    }
}

export default SourceDataTypesReducer;