import { ElementTypes } from "../settings/settings";

interface IManipulateExecutionData {
  elementState: any;
  elementType: ElementTypes;
  elementPreviousData?: any;
  isEditMode?: boolean
}

export const manipulateExecutionData = ({
  elementState,
  elementType,
  elementPreviousData,
  isEditMode
}: IManipulateExecutionData): Record<string, any> => {  

  switch (elementType) {
    case ElementTypes.FUNCTION: {
      return {
        ...elementState,
        label: elementState?.resultVar,
        func: `${elementState?.resultVar}=${elementState?.funcTxt}`,
      };
    }
    case ElementTypes.DATA_WIZARD: {
      const parentKey = elementState?.parentKey;
      delete elementState.parentKey;    

      if (!elementPreviousData) {
        return {
          [parentKey]: { ...elementState },
        };
      }
      if (parentKey in elementPreviousData.data) { 
        const { validation } = elementPreviousData.data[parentKey];
        const validationProperties = Object.keys(validation);
        const conditionCurrent =  elementState.validation.validation_0.role_condition.replace(/\s+/g, '');
        const mappingConditions = validationProperties.map(property=>validation[property].role_condition.replace(/\s+/g, ''));
        let result = {} as any;
          if(isEditMode === true){  
          const editProperty = elementState.validationKey;
          elementPreviousData.data[parentKey].validation[editProperty] = Object.values(elementState.validation)[0];
            result = {
              ...elementPreviousData.data
            }  
        }else{
          const nextProperty = validationProperties[0].replace(
            `_0`,
            `_${validationProperties.length}`
          );
  
          elementPreviousData.data[parentKey].object_type = elementState.object_type
          validation[nextProperty] = Object.values(elementState.validation)[0];
          result = {
              ...elementPreviousData.data
          }
        }        
        return {
          ...result
        };
      } else {      
        return {       
          ...elementPreviousData.data,
          [parentKey]: { ...elementState },
        };
      }
    }
    default: {
      return { ...elementState };
    }
  }
};

