import { getAttr } from "../methods/attributes"
import { replace_side } from "../remove_arrow_box.handler";

export const decision_direction = (source: SVGRectElement, target: SVGRectElement, side: string) => {
    const svg = document.querySelector(`#svg_board`) as SVGSVGElement;
    const svg_border_right = svg?.x?.baseVal?.value + svg?.width?.baseVal?.value as number;
    const svg_border_bottom = svg?.y?.baseVal?.value + svg?.height?.baseVal?.value as number;
    // const svg_border_top = svg?.y?.baseVal?.value;

    const { x: source_x, width: source_width, y: source_y, height: source_height } = getAttr(source) as any;
    const { width: target_width, height: target_height } = getAttr(target) as any;
    const distance = 132 as number;
    const border_margin = 35 as number;
    const element_x_right = source_x + source_width + distance + target_width as number;
    const element_x_left = source_x - distance - target_width as number;
    const element_y_bottom = source_y + source_height + distance + target_height as number;
    const element_y_top = source_y - distance - target_height as number;

    let new_side = '' as string;

    switch (side) {
        case 'top':
            if (element_y_top >= border_margin) {
                new_side = side;
            } else {
                if (svg_border_right - element_x_right > border_margin) {
                    if (!exists_side(source)?.includes('bottom')) {
                        new_side = 'bottom';
                    } else if (!exists_side(source)?.includes('right')) {
                        new_side = 'right';
                    }
                } else {
                    if (!exists_side(source)?.includes('bottom')) {
                        new_side = 'bottom';
                    } else if (!exists_side(source)?.includes('left')) {
                        new_side = 'left';
                    }
                }

            }
            break;
        case 'right':
            if (svg_border_right - element_x_right > border_margin) {
                new_side = side;
            } else {
                if (source_y + source_height < (svg_border_bottom - border_margin - target_height - distance)) {
                    if (!exists_side(source)?.includes('left')) {
                        new_side = 'left';
                    } else if (!exists_side(source)?.includes('bottom')) {
                        new_side = 'bottom';
                    }else if (!exists_side(source)?.includes('top')) {
                        new_side = 'top';
                    }
                } else {
                    if (!exists_side(source)?.includes('left')) {
                        new_side = 'left';
                    } else if (!exists_side(source)?.includes('top')) {
                        new_side = 'top';
                    }else if (!exists_side(source)?.includes('bottom')) {
                        new_side = 'bottom';
                    }
                }
            }
            break;
        case 'bottom':
            if ((svg_border_bottom - element_y_bottom) >= border_margin) {
                new_side = side;
            } else {
                if (svg_border_right - element_x_right > border_margin) {
                    if (!exists_side(source)?.includes('top')) {
                        new_side = 'top';
                    } else if (!exists_side(source)?.includes('right')) {
                        new_side = 'right';
                    }
                } else {
                    if (!exists_side(source)?.includes('top')) {
                        new_side = 'top';
                    } else if (!exists_side(source)?.includes('left')) {
                        new_side = 'left';
                    }
                }

            }
            break;
        case 'left':
            if (element_x_left > border_margin) {
                new_side = side;
            } else {
                if (source_y + source_height < (svg_border_bottom - border_margin - target_height - distance)) {
                    if (!exists_side(source)?.includes('right')) {
                        new_side = 'right';
                    } else if (!exists_side(source)?.includes('bottom')) {
                        new_side = 'bottom';
                    }else if (!exists_side(source)?.includes('top')) {
                        new_side = 'top';
                    }
                } else {
                    if (!exists_side(source)?.includes('right')) {
                        new_side = 'right';
                    } else if (!exists_side(source)?.includes('top')) {
                        new_side = 'top';
                    } else if (!exists_side(source)?.includes('bottom')) {
                        new_side = 'bottom';
                    }
                }
            }
            break;
        default:
            new_side = side;
            break;
    }
    return new_side


}

export const exists_side = (element: SVGRectElement) => {
    const svg = document.querySelector(`#svg_board`) as SVGSVGElement;
    const id = element?.dataset?.id;
    let exists = [] as string[];
    const inputs = svg.querySelectorAll(`path[data-contact *= "${id}"]`) as NodeListOf<SVGPathElement>;
    if (inputs?.length > 0) {
        inputs.forEach((input: SVGPathElement) => {
            let side = input?.dataset?.side as string;
            exists.push(replace_side(side))
        })
    }
    return exists;
}