import React, { Fragment, useEffect } from "react";
import classes from "./Tree.module.scss";
import { Triangle, Pen, Delete } from "../../../components/UI/Svg/Svg";
import Button from "../../../components/UI/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/combineReducer";
import {
  activeModal,
  handleActiveTooltip,
  touchBlocked,
} from "../../../store/actions/action";
import {
  activeScoreCardData,
  handleScoreCardCategoryData,
  handleScoreCardLevel,
  handleScoreCardLevelDataId,
  handleScoreCardCriteriaData,
  handleScoreCardData,
  scoreCardActiveDelete,
  handleActiveTree,
  handleScoreCardAttributeData,
} from "../../../store/actions/actionsScoreCard";
import { request } from "../../../helpers/request";
import { endpoints } from "../../../api/endpoints";
import { useGetAllData } from "../../../hooks/useGetAllData";

const Tree: React.FC = () => {
  const [scoreList, setScoreList] = React.useState(true);

  const store = useSelector((state: RootState) => state);
  const {
    scorecard: {
      getAllScorecards,
      getAllScorecardCategories,
      getAllScorecardCriteria,
    },
  } = useGetAllData();

  const {
    reducer: {
      mainTooltip: { type },
    },
    reducerScoreCard: {
      scoreCardAttributeData: { scoreCardAttributeData },
      scoreCardLevelDataId: { scoreCardId, categoryId },
      scoreCardActiveElement,
    },
  } = store;
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const promises: Promise<any>[] = [];
      await getAllScorecards.get({
        reduxData: store.reducerScoreCard.scoreCardData.scoreCardData,
        action: (scoreCardData) => handleScoreCardData({ scoreCardData }),
        additionalHandler(response = []) {
          for (const item of response || []) {
            const promise = getAllScorecardCategories.get(
              {
                action: (scoreCardCategoryData) =>
                  handleScoreCardCategoryData({ scoreCardCategoryData }),

                additionalHandler(response = []) {
                  for (const item of response || []) {
                    const promise = getAllScorecardCriteria.get(
                      {
                        action: (scoreCardCriteriaData) =>
                          handleScoreCardCriteriaData({
                            scoreCardCriteriaData,
                          }),
                      },
                      +item?.id
                    );

                    promises.push(promise);
                  }
                },
              },
              +item?.id
            );

            promises.push(promise);
          }
        },
      });

      await Promise.all(promises);
    })();
  }, [
    getAllScorecardCategories,
    getAllScorecardCriteria,
    getAllScorecards,
    store,
  ]);

  React.useEffect(() => {
    if (store.reducerScoreCard.scoreCardActiveDelete.scoreCardActiveDelete) {
      let { id } =
        store.reducerScoreCard.activeScoreCardData.activeScoreCardData;
      let scoreCardDataRef = [
        ...store.reducerScoreCard.scoreCardData.scoreCardData,
      ];
      let filteredData = scoreCardDataRef?.filter((el: any) => {
        return +el.id === +id;
      });
      let index = scoreCardDataRef.indexOf(filteredData[0]);
      scoreCardDataRef.splice(index, 1);
      request
        .delete(endpoints.score_card.score_card.delete(+id))
        .then((res) => {
          if (res.ok) {
            dispatch(handleScoreCardData({ scoreCardData: scoreCardDataRef }));
            dispatch(scoreCardActiveDelete({ scoreCardActiveDelete: false }));
          }
        })
        .catch((err) => {
          console.log("err:" + err);
        });
    }
  }, [
    dispatch,
    store.reducerScoreCard.activeScoreCardData.activeScoreCardData,
    store.reducerScoreCard.scoreCardActiveDelete.scoreCardActiveDelete,
    store.reducerScoreCard.scoreCardData.scoreCardData,
  ]);

  const nextBtnHandler = (id: number | null, criteriaType: number | null) => {
    const {
      score_card: { attribute },
    } = endpoints;

    if (scoreCardActiveElement.name === null && type === null) {
      dispatch(
        handleScoreCardLevelDataId({
          scoreCardId: scoreCardId,
          categoryId: categoryId,
          criteriaId: id,
          criteriaType: criteriaType,
        })
      );

      if (scoreCardAttributeData === null) {
        request
          .get(attribute.getAll(id))
          .then((result) => {
            const { response } = result;
            dispatch(
              handleScoreCardAttributeData({ scoreCardAttributeData: response })
            );
            dispatch(handleScoreCardLevel({ level: 4 }));
          })
          .catch((error) => {
            console.log("error " + error);
          });
      }

      if (scoreCardAttributeData !== null) {
        let childData = scoreCardAttributeData?.filter(
          (element: any) => element.score_card_criteria_id === id
        );

        if (childData.length === 0) {
          request
            .get(attribute.getAll(id))
            .then((result) => {
              const { response } = result;
              let combinedData = scoreCardAttributeData?.concat(response);
              dispatch(
                handleScoreCardAttributeData({
                  scoreCardAttributeData: combinedData,
                })
              );
              dispatch(handleScoreCardLevel({ level: 4 }));
            })
            .catch((error) => {
              console.log("error " + error);
            });
        }

        if (childData.length !== 0) {
          dispatch(handleScoreCardLevel({ level: 4 }));
        }
      }
    }

    if (scoreCardActiveElement.name !== null && type !== "validation") {
      if (type === null)
        dispatch(
          handleActiveTooltip({
            childId: scoreCardActiveElement.name,
            childDataId: null,
            type: "saveChanges",
          })
        );
      if (type === "saveChanges") {
        dispatch(
          handleActiveTooltip({ childId: null, childDataId: null, type: null })
        );
        setTimeout(() => {
          dispatch(
            handleActiveTooltip({
              childId: scoreCardActiveElement.name,
              childDataId: null,
              type: "saveChanges",
            })
          );
        }, 50);
      }
    }
  };

  const treeItemToggleHandler = (e: any, item: any) => {
    let activeTree =
      store.reducerScoreCard.activeTree.activeTree?.length > 0
        ? store.reducerScoreCard.activeTree.activeTree
        : [];
    let activeTreeMassive = [...activeTree];
    if (store.reducerScoreCard.scoreCardActiveElement.name === null) {
      if (item === "score") {
        setScoreList(!scoreList);
        dispatch(handleScoreCardLevel({ level: 1 }));
        dispatch(
          handleScoreCardLevelDataId({
            scoreCardId: null,
            categoryId: null,
            criteriaId: null,
            criteriaType: null,
          })
        );
      } else {
        if (!item?.score_card_id && !item?.score_card_category_id) {
          let checkItem = activeTreeMassive?.filter((el: any) => {
            return el?.id === item?.id && el?.name === item?.name;
          });
          if (
            checkItem.length === 1 &&
            !checkItem[0].hasOwnProperty("score_card_id")
          ) {
            let index = activeTreeMassive.indexOf(checkItem[0]);
            activeTreeMassive.splice(index, 1);
            dispatch(handleActiveTree({ activeTree: activeTreeMassive }));
            dispatch(handleScoreCardLevel({ level: 1 }));
            dispatch(
              handleScoreCardLevelDataId({
                scoreCardId: null,
                categoryId: null,
                criteriaId: null,
                criteriaType: null,
              })
            );
          } else if (checkItem.length === 0) {
            let mass = [item];
            dispatch(handleActiveTree({ activeTree: mass }));
            dispatch(handleScoreCardLevel({ level: 2 }));
            dispatch(
              handleScoreCardLevelDataId({
                scoreCardId: item?.id,
                categoryId: null,
                criteriaId: null,
                criteriaType: null,
              })
            );
          }
        } else if (item?.score_card_id && !item?.score_card_category_id) {
          let checkItem = activeTreeMassive?.filter((el: any) => {
            return (
              el?.score_card_id === item?.score_card_id && el?.id === item?.id
            );
          });
          if (
            checkItem.length === 1 &&
            checkItem[0]?.hasOwnProperty("score_card_id")
          ) {
            let index = activeTreeMassive.indexOf(checkItem[0]);
            activeTreeMassive.splice(index, 1);
            dispatch(handleActiveTree({ activeTree: activeTreeMassive }));
            dispatch(handleScoreCardLevel({ level: 2 }));
            dispatch(
              handleScoreCardLevelDataId({
                scoreCardId: item?.score_card_id,
                categoryId: null,
                criteriaId: null,
                criteriaType: null,
              })
            );
          } else if (checkItem.length === 0) {
            let mass = [...activeTree, item];
            let score_card = mass?.filter((el: any) => !el?.score_card_id);
            let category = mass?.filter((el: any) => el?.score_card_id)?.pop();
            let new_mass = [...score_card, category];
            dispatch(handleActiveTree({ activeTree: new_mass }));
            dispatch(handleScoreCardLevel({ level: 3 }));
            dispatch(
              handleScoreCardLevelDataId({
                scoreCardId: item?.score_card_id,
                categoryId: item?.id,
                criteriaId: null,
                criteriaType: null,
              })
            );
          }
        } else {
          let { scoreCardId } = store.reducerScoreCard.scoreCardLevelDataId;

          let checkItem = activeTreeMassive?.filter((el: any) => {
            return (
              el?.score_card_category_id === item?.score_card_category_id &&
              el?.id === item?.id
            );
          });
          if (
            checkItem.length === 1 &&
            checkItem[0]?.hasOwnProperty("score_card_category_id")
          ) {
            let index = activeTreeMassive.indexOf(checkItem[0]);
            activeTreeMassive.splice(index, 1);
            dispatch(handleActiveTree({ activeTree: activeTreeMassive }));
            dispatch(handleScoreCardLevel({ level: 3 }));
            dispatch(
              handleScoreCardLevelDataId({
                scoreCardId: scoreCardId,
                categoryId: item?.score_card_category_id,
                criteriaId: null,
                criteriaType: null,
              })
            );
          } else if (checkItem.length === 0) {
            let mass = [...activeTree, item];
            dispatch(handleActiveTree({ activeTree: mass }));
            dispatch(handleScoreCardLevel({ level: 4 }));
            dispatch(
              handleScoreCardLevelDataId({
                scoreCardId: scoreCardId,
                categoryId: item?.score_card_category_id,
                criteriaId: item?.id,
                criteriaType: null,
              })
            );
          }
        }
      }
    } else {
      dispatch(activeModal({ activeModal: "score_blocked_modal" }));
      dispatch(touchBlocked({ status: true }));
    }
  };

  const addScoreHandler = () => {
    if (store.reducerScoreCard.scoreCardActiveElement.name === null) {
      dispatch(activeModal({ activeModal: "add_score_modal" }));
    } else {
      dispatch(activeModal({ activeModal: "score_blocked_modal" }));
      dispatch(touchBlocked({ status: true }));
    }
  };

  const treeEditHandler = (e: any, item: any) => {
    e.stopPropagation();
    if (store.reducerScoreCard.scoreCardActiveElement.name === null) {
      dispatch(activeModal({ activeModal: "edit_score_modal" }));
      dispatch(activeScoreCardData({ activeScoreCardData: item }));
    } else {
      dispatch(activeModal({ activeModal: "score_blocked_modal" }));
      dispatch(touchBlocked({ status: true }));
    }
  };
  const treeDeleteHandler = (e: any, item: any) => {
    e.stopPropagation();
    if (store.reducerScoreCard.scoreCardActiveElement.name === null) {
      dispatch(activeModal({ activeModal: "delete_score_modal" }));
      dispatch(activeScoreCardData({ activeScoreCardData: item }));
    } else {
      dispatch(activeModal({ activeModal: "score_blocked_modal" }));
      dispatch(touchBlocked({ status: true }));
    }
  };

  return (
    <div className={classes.TreeHiddenBackground}>
      <div className={classes.Tree}>
        <div className={classes.AccordionArea}>
          <div className={classes.LinkScore}>
            <span
              onClick={(e) => treeItemToggleHandler(e, "score")}
              className={classes.Title}
            >
              <Triangle
                fill={scoreList ? "#2C7CF6" : "#B2BDCD"}
                active={scoreList ? true : false}
              />
              <span className={classes.Text}>Score</span>
            </span>
            <div
              id={`level_1`}
              className={
                !scoreList
                  ? classes.TitleChild
                  : [classes.TitleChild, classes.TitleChildActive].join(" ")
              }
            >
              {store.reducerScoreCard.scoreCardData.scoreCardData
                ?.filter((el: any) => !el.newRow)
                ?.map((item: any, i: number) => {
                  return (
                    <div key={i} className={classes.Link}>
                      <span
                        data-object={JSON.stringify(item)}
                        onClick={(e) => treeItemToggleHandler(e, item)}
                        className={classes.Title}
                      >
                        <Triangle
                          fill={
                            store.reducerScoreCard.activeTree.activeTree &&
                            store.reducerScoreCard.activeTree.activeTree.filter(
                              (activeItem: any) => {
                                return (
                                  activeItem?.id === item.id &&
                                  !activeItem?.hasOwnProperty("score_card_id")
                                );
                              }
                            ).length > 0
                              ? "#2C7CF6"
                              : "#B2BDCD"
                          }
                          active={
                            store.reducerScoreCard.activeTree.activeTree &&
                            store.reducerScoreCard.activeTree.activeTree.filter(
                              (activeItem: any) => {
                                return (
                                  activeItem?.id === item.id &&
                                  !activeItem?.hasOwnProperty("score_card_id")
                                );
                              }
                            ).length > 0
                              ? true
                              : false
                          }
                        />
                        <span className={classes.Text}>{item.name}</span>
                        <span className={classes.Tools}>
                          <span>
                            <Pen handler={(e) => treeEditHandler(e, item)} />
                          </span>
                          <span>
                            <Delete
                              handler={(e) => treeDeleteHandler(e, item)}
                            />
                          </span>
                        </span>
                      </span>
                      <div
                        id={`level_2`}
                        className={
                          store.reducerScoreCard.activeTree.activeTree?.filter(
                            (elem: any) => elem === item
                          )[0]
                            ? [
                                classes.TitleChild,
                                classes.TitleChildActive,
                              ].join(" ")
                            : classes.TitleChild
                        }
                      >
                        {store.reducerScoreCard.scoreCardCategoryData.scoreCardCategoryData
                          ?.filter((card_category: any, k: number) => {
                            return (
                              +card_category.score_card_id === +item.id &&
                              !card_category?.newRow
                            );
                          })
                          .map((category: any, j: number) => {
                            return (
                              <Fragment key={j}>
                                <span
                                  data-object={JSON.stringify(category)}
                                  onClick={(e) =>
                                    treeItemToggleHandler(e, category)
                                  }
                                  className={classes.Title}
                                >
                                  <Triangle
                                    fill={
                                      store.reducerScoreCard.activeTree
                                        .activeTree &&
                                      store.reducerScoreCard.activeTree.activeTree.filter(
                                        (activeItem: any) => {
                                          return (
                                            activeItem?.score_card_id ===
                                              category.score_card_id &&
                                            activeItem?.id === category?.id
                                          );
                                        }
                                      ).length > 0
                                        ? "#2C7CF6"
                                        : "#B2BDCD"
                                    }
                                    active={
                                      store.reducerScoreCard.activeTree
                                        .activeTree &&
                                      store.reducerScoreCard.activeTree.activeTree.filter(
                                        (activeItem: any) => {
                                          return (
                                            activeItem?.score_card_id ===
                                              category.score_card_id &&
                                            activeItem?.id === category?.id
                                          );
                                        }
                                      ).length > 0
                                        ? true
                                        : false
                                    }
                                  />
                                  <span className={classes.Text}>
                                    {category.name}
                                  </span>
                                </span>
                                <div
                                  id={`level_3`}
                                  className={
                                    store.reducerScoreCard.activeTree.activeTree?.filter(
                                      (elem: any) => elem === category
                                    )[0]
                                      ? [
                                          classes.TitleChild,
                                          classes.TitleChildActive,
                                        ].join(" ")
                                      : classes.TitleChild
                                  }
                                >
                                  {store.reducerScoreCard.scoreCardCriteriaData.scoreCardCriteriaData
                                    ?.filter(
                                      (card_criteria: any, p: number) => {
                                        return (
                                          +card_criteria.score_card_category_id ===
                                          +category.id
                                        );
                                      }
                                    )
                                    .map((criteria: any, j: number) => {
                                      return (
                                        <Fragment key={j}>
                                          <span
                                            data-object={JSON.stringify(
                                              criteria
                                            )}
                                            onClick={() =>
                                              nextBtnHandler(
                                                criteria.id,
                                                criteria.criteria_type_id
                                              )
                                            }
                                            className={[
                                              classes.Title,
                                              classes.TitleCriteria,
                                            ].join(" ")}
                                          >
                                            <Triangle
                                              fill={
                                                store.reducerScoreCard
                                                  .activeTree.activeTree &&
                                                store.reducerScoreCard.activeTree.activeTree.filter(
                                                  (activeItem: any) => {
                                                    return (
                                                      +activeItem?.score_card_category_id ===
                                                        +criteria.score_card_category_id &&
                                                      +activeItem?.id ===
                                                        +criteria?.id
                                                    );
                                                  }
                                                ).length > 0
                                                  ? "#2C7CF6"
                                                  : "#B2BDCD"
                                              }
                                              active={
                                                store.reducerScoreCard
                                                  .activeTree.activeTree &&
                                                store.reducerScoreCard.activeTree.activeTree.filter(
                                                  (activeItem: any) => {
                                                    return (
                                                      +activeItem?.score_card_category_id ===
                                                        +criteria.score_card_category_id &&
                                                      +activeItem?.id ===
                                                        +criteria?.id
                                                    );
                                                  }
                                                ).length > 0
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <span className={classes.Text}>
                                              {criteria.name}
                                            </span>
                                          </span>
                                        </Fragment>
                                      );
                                    })}
                                </div>
                              </Fragment>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className={classes.AddScore}>
          <Button
            onClick={addScoreHandler}
            type="button"
            width="8vw"
            height="5vh"
            bgColor="#2C7CF6"
            color="#FFFFFF"
            borderRadius="1vh"
            position="absolute"
            left="5vw"
            top="1vh"
          >
            Add Score
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Tree;
