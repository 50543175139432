import strategyDesignerIcon from "../../../assets/svg/strategyDesigner.svg";
import scoreCardDesignerIcon from "../../../assets/svg/scoreCardDesigner.svg";
import productDesignerIcon from "../../../assets/svg/productDesigner.svg";
import decisionDesignerIcon from "../../../assets/svg/designDesigner.svg";

import classes from "./Dashboard.module.scss";
import { dateFormatter } from "helpers/dateFormatter";
import { handleActiveTree, handleScoreCardCategoryData, handleScoreCardLevel, handleScoreCardLevelDataId } from "store/actions/actionsScoreCard";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "store";
import { handleActiveTooltip } from "store/actions/action";
import { endpoints } from "api/endpoints";
import { request } from "../../../helpers/request";

interface DashboardTableProps {
  section: any;
  index: number;
  data: any;
}

interface DashboardAdditions {
  icon: string;
  color: string;
  getUrl: (item: any) => string;
  target: string;
}

const dashboardDesignerAdditions: DashboardAdditions[] = [
  {
    icon: strategyDesignerIcon,
    color:
      // "linear-gradient(266.39deg, rgba(75, 121, 189, 0.78) 0%, #615FD8 103.07%)",
      "linear-gradient(0deg, rgba(41, 70, 95, 0) 0%, #0470D1 0%)",

    getUrl: (item) =>
      `/strategy?name=${item?.name}&id=${item?.id}&status=${item?.status}&version=${item?.version}`,
    target: "_blank",
  },
  {
    icon: scoreCardDesignerIcon,
    color:
      // "linear-gradient(266.39deg, rgba(89, 165, 186, 0.78) 0%, #59A5BA 103.07%)",
      "linear-gradient(0deg, rgba(211, 70, 92, 0) 0%, #D3465C 0%)",

    getUrl: (item) => "",
    target: "_self",
  },
  {
    icon: productDesignerIcon,
    color:
      // "linear-gradient(266.39deg, rgba(190, 134, 248, 0.78) 0%, #8F86F8 103.07%)",
      "linear-gradient(0deg, rgba(25, 174, 159,0) 0%, #19AE9F 0%)",

    getUrl: (item) => `/${item?.name.toLowerCase()}/${item?.id}`,
    target: "_self",
  },
  {
    icon: decisionDesignerIcon,
    color:
      // "linear-gradient(266.39deg, rgba(82, 185, 99, 0.78) 0%, #52B963 103.07%)",
      "linear-gradient(0deg, rgba(133, 124, 233, 0) 0%, #857CE9 0%)",

    getUrl: (item) =>
      `/${item?.type_id === 1 ? "decision_matrix" : "decision"}?id=${item?.id
      }&type=${item?.type_id}`,
    target: "_self",
  },
];

const DashboardTable = ({ section, index, data }: DashboardTableProps) => {
  const { icon, color, getUrl, target } = dashboardDesignerAdditions[index];
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    reducer: {
      mainTooltip: { childId, type },
    },
    reducerScoreCard: {
      scoreCardData: { scoreCardData },
      scoreCardCategoryData: { scoreCardCategoryData },
      scoreCardLevelDataId: {
        criteriaType,
      },
      scoreCardActiveElement,
    },
  } = useSelector((state: RootState) => state);
  const handleScoreCardItemClick = (item: any, url: string) => {
    const { id } = item;

      const {
        score_card: { category },
      } = endpoints;

    
      if (scoreCardCategoryData === null && id !== null) {
        request
          .get(category.getAll(id))
          .then((result: any) => {
            const { response } = result;

            dispatch(
              handleScoreCardCategoryData({ scoreCardCategoryData: response })
            );
            dispatch(handleScoreCardLevel({ level: 2 }));
          })
          .catch((error: any) => {
            console.log("error " + error);
          });
      }
        if (scoreCardActiveElement.name === null && type === null) {
          dispatch(
            handleScoreCardLevelDataId({
              scoreCardId: id,
              categoryId: null,
              criteriaId: null,
              criteriaType,
            })
          );
          if (scoreCardCategoryData === null && id !== null) {
            request
              .get(category.getAll(id))
              .then((result) => {
                const { response } = result;    
    
                dispatch(
                  handleScoreCardCategoryData({ scoreCardCategoryData: response })
                );
    
                dispatch(handleScoreCardLevel({ level: 2 }));
              })
              .catch((error) => {
                console.log("error " + error);
              });
          }
    
          if (scoreCardCategoryData !== null && id !== null) {
            let childData = scoreCardCategoryData.filter(
              (element: any) => element.score_card_id === id
            );
            if (childData.length === 0) {
              request
                .get(category.getAll(id))
                .then((result) => {
                  const { response } = result;
                  let combinedData = scoreCardCategoryData.concat(response);
                  dispatch(
                    handleScoreCardCategoryData({
                      scoreCardCategoryData: combinedData,
                    })
                  );
                  console.log("combinedData",combinedData)
                  dispatch(handleScoreCardLevel({ level: 2 }));
                })
                .catch((error) => {
                  console.log("error " + error);
                });
            }  
            if (childData.length !== 0) {
              dispatch(handleScoreCardLevel({ level: 2 }));
            }
          }
    
          let score_card_data = scoreCardData?.filter((elem: any) => {
            return +elem?.id === id;
          });
    
          dispatch(handleActiveTree({ activeTree: score_card_data }));
        }
    
        if (scoreCardActiveElement.name !== null && type !== "validation") {
          if (type === null)
            dispatch(
              handleActiveTooltip({
                childId: scoreCardActiveElement.name,
                childDataId: null,
                type: "saveChanges",
              })
            );
          if (type === "saveChanges") {
            dispatch(
              handleActiveTooltip({ childId: null, childDataId: null, type: null })
            );
            setTimeout(() => {
              dispatch(
                handleActiveTooltip({
                  childId: scoreCardActiveElement.name,
                  childDataId: null,
                  type: "saveChanges",
                })
              );
            }, 50);
          }
        }
        
    dispatch(
      handleScoreCardLevelDataId({
        scoreCardId: item.id,
        categoryId: null,
        criteriaId: null,
        criteriaType: null,
      }));   
     dispatch(handleActiveTree({ activeTree: [item]}));
    dispatch(handleScoreCardLevel({ level: 2 }));
    history.push(`${url}`)
  }

  return (
    <div className={classes.TableContainer}>
      <div
        className={classes.TableTitle}
        style={{
          backgroundImage: color,
        }}
      >
        <img src={icon} alt="Title icon" />
        <p>{section?.title}</p>
      </div>
      <table className={classes.Table}>
        <thead>
          <tr>
            <td>name</td>
            <td>description</td>
            <td>created date</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {data?.map((item: any, index: number) => {
          const formattedDate =
          item && dateFormatter(item?.add_date || item?.created_on || item?.update_time);  
            const url = `${section?.to}${getUrl(item)}`;
            if (url === '/score-card') {
              return (
                <tr
                  key={index}
                  onClick={() => handleScoreCardItemClick(item, url)}
                >
                  <td title={item?.name}>{item?.name}</td>
                  <td title={item?.description}>{item?.description}</td>
                  <td title={formattedDate}>{formattedDate}</td>
                  <td>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http:www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.42467 17.225C7.26634 17.225 7.10801 17.1667 6.98301 17.0417C6.74134 16.8 6.74134 16.4 6.98301 16.1583L12.4163 10.725C12.8163 10.325 12.8163 9.67502 12.4163 9.27502L6.98301 3.84168C6.74134 3.60002 6.74134 3.20002 6.98301 2.95835C7.22467 2.71668 7.62467 2.71668 7.86634 2.95835L13.2997 8.39168C13.7247 8.81668 13.9663 9.39168 13.9663 10C13.9663 10.6083 13.733 11.1833 13.2997 11.6083L7.86634 17.0417C7.74134 17.1583 7.58301 17.225 7.42467 17.225Z"
                        fill="#001237"
                        opacity="0.3"
                      />
                    </svg>
                  </td>
                </tr>
              )
            }
            else {
              return (
                <tr
                  key={index}
                  onClick={() => {
                    window.open(url, target);
                    dispatch(
                      handleScoreCardLevelDataId({
                        scoreCardId: item.id,
                        categoryId: null,
                        criteriaId: null,
                        criteriaType: null,
                      }))
                  }}
                >
                  <td title={item?.name}>{item?.name}</td>
                  <td title={item?.description}>{item?.description}</td>
                  <td title={formattedDate}>{formattedDate}</td>
                  <td>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.42467 17.225C7.26634 17.225 7.10801 17.1667 6.98301 17.0417C6.74134 16.8 6.74134 16.4 6.98301 16.1583L12.4163 10.725C12.8163 10.325 12.8163 9.67502 12.4163 9.27502L6.98301 3.84168C6.74134 3.60002 6.74134 3.20002 6.98301 2.95835C7.22467 2.71668 7.62467 2.71668 7.86634 2.95835L13.2997 8.39168C13.7247 8.81668 13.9663 9.39168 13.9663 10C13.9663 10.6083 13.733 11.1833 13.2997 11.6083L7.86634 17.0417C7.74134 17.1583 7.58301 17.225 7.42467 17.225Z"
                        fill="#001237"
                        opacity="0.3"
                      />
                    </svg>
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
      <div className={classes.TableFooter}>
        <a href={section?.to}>View all</a>
      </div>
    </div>
  );
};

export default DashboardTable;
