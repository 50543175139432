import { endpoints } from "../../../../api/endpoints";
import { request } from "../../../../helpers/request";
import React, {
  FC,
  useCallback,
  useRef,
  useState,
  DragEvent,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleChangeableStatus,
  handleStrategyMatrixActiveData,
  handleStrategyMatrixCoordData,
} from "../../../../store/actions/actionsStrategy";
import { setRequiredDecisionMatrix } from "../../../../store/actions/required.action";
import classes from "./DecisionMatrixMapper.module.scss";
import { RootState } from "store/combineReducer";
import { MatrixHtml } from "components/UI/Matrix/MatrixHtml/MatrixHtml";
import Spinner from "components/UI/Spinner/Spinner";
import { icons } from "settings/settings";

type TMatrixValue = {
  x: string;
  y: string;
};

interface IDecisionMatrixMapperProps {
  data?: any;
}

const DecisionMatrixMapper: FC<IDecisionMatrixMapperProps> = ({ data }) => {
  const [matrixValue, setMatrixValue] = useState<TMatrixValue>({
    x: "",
    y: "",
  });
  const [confModalStatus, setConfModalStatus] = useState<boolean>(false);
  const [freezedData, setFreezedData] = useState<TMatrixValue>({
    x: "",
    y: "",
  });
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  // const bodyRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const {
    reducerStrategy: {
      StrategyBoardElements: {
        StrategyMatrixActiveData: { matrix_data },
        ElementInput: { element_input },
        NewCreatedElement: { new_created_element },
      },
    },
  } = useSelector((state: RootState) => state);

  const {
    decision_designer: { decision_matrix },
  } = endpoints;
  useEffect(() => {
    const { x, y } = matrixValue;
    if (x) {
      dispatch(setRequiredDecisionMatrix({ field: { x: true } }));
    }
    if (y) {
      dispatch(setRequiredDecisionMatrix({ field: { y: true } }));
    }
  }, [dispatch, matrixValue]);

  const getMatrix = useCallback(
    (decisionId: number) => {
      request
        .get(decision_matrix.get(decisionId))
        .then((res) => {
          const { success, response } = res;

          if (success) {
            dispatch(handleStrategyMatrixActiveData({ matrix_data: response }));
            dispatch(setRequiredDecisionMatrix({ field: { matrix: true } }));
          } else {
            console.warn("Fetching ranges success is false ", res);
            dispatch(setRequiredDecisionMatrix({ field: { matrix: false } }));
          }
        })
        .catch((error) => {
          console.error("Fetching ranges error ", error);
        });
    },
    [decision_matrix, dispatch]
  );
  useEffect(() => {
    if (data?.decision_id) {
      const {
        decision_id,
        map_obj: { x, y },
      } = data;
      if (x && y) {
        setMatrixValue({ x: x, y: y });
        dispatch(handleStrategyMatrixCoordData({ x: x }));
        dispatch(handleStrategyMatrixCoordData({ y: y }));
      }
      setTimeout(() => getMatrix(decision_id), 500);
    }
  }, [data, dispatch, getMatrix]);
  // const initDragEnter = (event: any) => {
  //   event.preventDefault();
  // };

  // const initDragOver = (event: any) => {
  //   event.preventDefault();
  //   const { target } = event;

  //   if (!target.classList.contains(classes.WrapperDrop)) {
  //     target.classList.add(classes.WrapperDrop);
  //   }
  // };

  // const initDragLeave = (event: any) => {
  //   event.preventDefault();
  //   const { target } = event;
  //   if (target.classList.contains(classes.WrapperDrop)) {
  //     target.classList.remove(classes.WrapperDrop);
  //   }
  // };

  // const initDrop = (event: any) => {
  //   event.preventDefault();
  //   const { target } = event;

  //   // const variableName = dataTransfer.getData("dragging_variable");
  //   // const sourceName = dataTransfer.getData("dragging_source");

  //   if (target.classList.contains(classes.WrapperDrop)) {
  //     target.classList.remove(classes.WrapperDrop);
  //   }
  // };

  const generateRow = useCallback(() => {
    const rowDragEnter = (event: any) => {
      event.preventDefault();
    };

    const rowDragOver = (event: any) => {
      event.preventDefault();
      const { target } = event;

      if (!target.classList.contains(classes.RowDrop)) {
        target.classList.add(classes.RowDrop);
      }
    };

    const rowDragLeave = (event: any) => {
      event.preventDefault();
      const { target } = event;
      if (target.classList.contains(classes.RowDrop)) {
        target.classList.remove(classes.RowDrop);
      }
    };

    const rowDrop = (event: DragEvent<HTMLSpanElement>) => {
      event.preventDefault();
      const { currentTarget, dataTransfer } = event;

      const variableName = dataTransfer.getData("dragging_variable") as string;
      const sourceName = dataTransfer.getData("dragging_source") as string;
      const mappName = dataTransfer.getData("dragging_map") as string;
      const dataType = currentTarget.dataset.type as string;
      switch (dataType) {
        case "x":
          if (variableName) {
            const { key } = JSON.parse(variableName);
            const { x } = matrixValue;
            if (x && key !== x) {
              setConfModalStatus(true);
              setFreezedData((state: TMatrixValue) => {
                return {
                  x: key,
                  y: state.y,
                };
              });
            } else {
              setMatrixValue((state: TMatrixValue) => {
                return {
                  x: key,
                  y: state.y,
                };
              });
              dispatch(handleStrategyMatrixCoordData({ x: key }));
              dispatch(handleChangeableStatus({ status: true }));
            }
          } else if (sourceName) {
            const { key } = JSON.parse(sourceName);
            const { x } = matrixValue;
            if (x && key !== x) {
              setConfModalStatus(true);
              setFreezedData((state: TMatrixValue) => {
                return {
                  x: key,
                  y: state.y,
                };
              });
            } else {
              setMatrixValue((state: TMatrixValue) => {
                return {
                  x: key,
                  y: state.y,
                };
              });
              dispatch(handleStrategyMatrixCoordData({ x: key }));
              dispatch(handleChangeableStatus({ status: true }));
            }
          } else if (mappName) {
            const { key } = JSON.parse(mappName);
            const { x } = matrixValue;
            if (x && key !== x) {
              setConfModalStatus(true);
              setFreezedData((state: TMatrixValue) => {
                return {
                  x: key,
                  y: state.y,
                };
              });
            } else {
              setMatrixValue((state: TMatrixValue) => {
                return {
                  x: key,
                  y: state.y,
                };
              });
              dispatch(handleStrategyMatrixCoordData({ x: key }));
              dispatch(handleChangeableStatus({ status: true }));
            }
          }

          break;
        case "y":
          if (variableName) {
            const { key } = JSON.parse(variableName);
            const { y } = matrixValue;
            if (y && key !== y) {
              setConfModalStatus(true);
              setFreezedData((state: TMatrixValue) => {
                return {
                  x: state.x,
                  y: key,
                };
              });
            } else {
              setMatrixValue((state: TMatrixValue) => {
                return {
                  x: state.x,
                  y: key,
                };
              });
              dispatch(handleStrategyMatrixCoordData({ y: key }));
              dispatch(handleChangeableStatus({ status: true }));
            }
          } else if (sourceName) {
            const { key } = JSON.parse(sourceName);
            const { y } = matrixValue;
            if (y && key !== y) {
              setConfModalStatus(true);
              setFreezedData((state: TMatrixValue) => {
                return {
                  x: state.x,
                  y: key,
                };
              });
            } else {
              setMatrixValue((state: TMatrixValue) => {
                return {
                  x: state.x,
                  y: key,
                };
              });
              dispatch(handleStrategyMatrixCoordData({ y: key }));
              dispatch(handleChangeableStatus({ status: true }));
            }
          } else if (mappName) {
            const { key } = JSON.parse(mappName);
            const { y } = matrixValue;
            if (y && key !== y) {
              setConfModalStatus(true);
              setFreezedData((state: TMatrixValue) => {
                return {
                  x: state.x,
                  y: key,
                };
              });
            } else {
              setMatrixValue((state: TMatrixValue) => {
                return {
                  x: state.x,
                  y: key,
                };
              });
              dispatch(handleStrategyMatrixCoordData({ y: key }));
              dispatch(handleChangeableStatus({ status: true }));
            }
          }

          break;
      }

      if (currentTarget.children[0].classList.contains(classes.RowDrop)) {
        currentTarget.children[0].classList.remove(classes.RowDrop);
        currentTarget.children[0].setAttribute("style", "");
      }
    };
    const handleSubstrValue = (value: string) => {
      if (value?.length <= 30) {
        return value;
      } else {
        return value?.substring(0, 30) + "...";
      }
    };
    return (
      <>
        <div
          className={classes.YPoint}
          id="matrix_y"
          data-type="y"
          title={matrixValue.y}
          onDragEnter={rowDragEnter}
          onDragOver={rowDragOver}
          onDragLeave={rowDragLeave}
          onDrop={rowDrop}
        >
          <div className={classes.YPointInput}>
            <span className={classes.InputLabel}>Score First</span>
            <span
              className={
                matrixValue.y
                  ? [classes.InputPlaceholder, classes.Active].join(" ")
                  : classes.InputPlaceholder
              }
            >
              {matrixValue.y ? matrixValue.y : "Y"}
            </span>
          </div>
        </div>
        <div className={classes.MatrixArea}>
          <div className={classes.YArrow}>
            <span className={classes.HRisk}>High Risk</span>
            <span className={classes.Line}>
              <span className={classes.Arrow}></span>
            </span>
          </div>
          <div className={classes.Matrix}>
            {/* <MatrixViewer/> */}
            <MatrixHtml />
          </div>

          <div className={classes.XArrow}>
            <span className={classes.Line}>
              <span className={classes.Arrow}></span>
            </span>
            <span className={classes.LRisk}>Low Risk</span>
            <span className={classes.HRisk}>High Risk</span>
          </div>
        </div>
        <div
          className={classes.XPoint}
          id="matrix_x"
          data-type="x"
          title={matrixValue.x}
          onDragEnter={rowDragEnter}
          onDragOver={rowDragOver}
          onDragLeave={rowDragLeave}
          onDrop={rowDrop}
        >
          <div className={classes.XPointInput}>
            <span className={classes.InputLabel}>Score Second</span>
            <span
              className={
                matrixValue.x
                  ? [classes.InputPlaceholder, classes.Active].join(" ")
                  : classes.InputPlaceholder
              }
            >
              {matrixValue.x ? handleSubstrValue(matrixValue.x) : "X"}
            </span>
          </div>
        </div>
      </>
      // <div className={classes.Row}>
      //   <span
      //     id="matrix_x"
      //     data-type='x'
      //     title={matrixValue.x}
      //     onDragEnter={rowDragEnter}
      //     onDragOver={rowDragOver}
      //     onDragLeave={rowDragLeave}
      //     onDrop={rowDrop}
      //     className={classes.KeyCol}>
      //       <span className={matrixValue.x ? [classes.Label,classes.ActiveLabel].join(' '):classes.Label}>x</span>
      //       {handleSubstrValue(matrixValue.x)}

      //   </span>
      //   <span
      //     id="matrix_y"
      //     data-type='y'
      //     title={matrixValue.y}
      //     onDragEnter={rowDragEnter}
      //     onDragOver={rowDragOver}
      //     onDragLeave={rowDragLeave}
      //     onDrop={rowDrop}
      //     className={classes.ValueCol}>
      //       <span className={matrixValue.y ? [classes.Label,classes.ActiveLabel].join(' '):classes.Label}>y</span>

      //       {matrixValue.y}
      //   </span>
      // </div>
    );
  }, [dispatch, matrixValue]);

  const handleChangedData = () => {
    const { x, y } = freezedData;
    if (x) {
      setMatrixValue((state: TMatrixValue) => {
        return {
          x: x,
          y: state.y,
        };
      });
      setFreezedData({ x: "", y: "" });
      dispatch(handleStrategyMatrixCoordData({ x: x }));
    }
    if (y) {
      setMatrixValue((state: TMatrixValue) => {
        return {
          x: state.x,
          y: y,
        };
      });
      setFreezedData({ x: "", y: "" });
      dispatch(handleStrategyMatrixCoordData({ y: y }));
    }
    setConfModalStatus(false);
    dispatch(handleChangeableStatus({ status: true }));
  };
  const handleCancelChanged = () => {
    setConfModalStatus(false);
    setFreezedData({ x: "", y: "" });
  };

  return (
    <div ref={wrapperRef} className={classes.Wrapper}>
      {/* <div
        ref={bodyRef}
        className={classes.Body}
        onDragEnter={initDragEnter}
        onDragOver={initDragOver}
        onDragLeave={initDragLeave}
        onDrop={initDrop}
      >


    {generateRow()}
      </div> */}
      <div className={classes.Header}>
        {matrix_data && (
          <>
          <span>Yes</span>
          <span>Maybe</span>
          <span>No</span>
          </>
        )}
      </div>
      <div className={classes.Matrix}>
        {matrix_data ? (
          generateRow()
        ) : element_input ? (
          <div>
            <Spinner />
          </div>
        ) : new_created_element ? (
          <div className={classes.PlaceHolder}>
            <img src={icons.sidePanelDecisionMatrixDisabled} alt="matrix" />
          </div>
        ) : null}
      </div>
      {confModalStatus && (
        <div className={classes.ConfirmationModalBacker}>
          <div className={classes.ConfirmationModal}>
            <span className={classes.ConfirmationModalTitle}>
              Are you sure to change mapped item?
            </span>
            <div className={classes.ConfirmationModalActions}>
              <button onClick={handleChangedData}>Change</button>
              <button onClick={handleCancelChanged}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DecisionMatrixMapper;
