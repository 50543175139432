import React, { FC, useCallback, useLayoutEffect } from "react";
import Container from "./Container/Container";
import Tree from "./Tree/Tree";
import Section from "./Section/Section";

const Scorecard: FC = () => {
  const beforeLoadCallback = useCallback(() => {
    const localCredentials = localStorage.getItem("credentials");
    const localUserDetails = localStorage.getItem("user_details");

    if (localCredentials && localUserDetails) {
      sessionStorage.setItem("credentials", localCredentials);
      sessionStorage.setItem("user_details", localUserDetails);

      localStorage.removeItem("credentials");
      localStorage.removeItem("user_details");
    }
  }, []);

  useLayoutEffect(() => {
    beforeLoadCallback();
  }, [beforeLoadCallback]);

  return (
    <Container>
      <Tree />
      <Section />
    </Container>
  );
};

export default Scorecard;
