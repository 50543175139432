import React, { FC, useEffect, useLayoutEffect, useState } from "react";
import classes from "./Select.module.scss";

interface SelectProps {
  style?: object;
  placeHolder?: string;
  options?: Array<any>;
  defaultValue?: number | string | boolean;
  onChange?: Function;
  validate?: boolean;
  disabled?: boolean;
}

const CustomSelect: FC<SelectProps> = ({
  style,
  placeHolder,
  options,
  defaultValue,
  onChange = (value: string | number | boolean) => {},
  validate,
  disabled,
}) => {
  const [selectMenuVisibleStatus, setSelectMenuVisibleStatus] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [selectedOptionColor, setSelectedOptionColor] = useState<string>();

  useLayoutEffect(() => {
    if (defaultValue || defaultValue === 0) {
      options?.forEach((element: any) => {
        if (element.value === defaultValue) {
          setSelectedOption(element.option);
        }
      });
    } else if (selectedOption && !defaultValue) {
      setSelectedOption("");
    }
  }, [defaultValue, options, selectedOption]);

  useEffect(() => {
    window.addEventListener("click", () => setSelectMenuVisibleStatus(false));

    return window.removeEventListener("click", () =>
      setSelectMenuVisibleStatus(false)
    );
  }, []);

  const handleMenuVisibleStatus = (event: any) => {
    if (disabled) {
      return;
    }

    event.stopPropagation();

    setSelectMenuVisibleStatus(!selectMenuVisibleStatus);
  };

  const handleSelection = (option: string, value: number, color: string) => {
    setSelectedOption(option);
    setSelectedOptionColor(color);
    setSelectMenuVisibleStatus(false);
    onChange(value);
  };

  return (
    <div
      className={`${classes.CustomSelectWrapper} ${
        selectMenuVisibleStatus ? classes.Active : ""
      } ${validate ? classes.Validate : ""}`}
      style={
        selectedOptionColor ? { backgroundColor: selectedOptionColor + 31 } : {}
      }
      onClick={(event) => handleMenuVisibleStatus(event)}
    >
      <div className={classes.CustomSelect}>
        {selectedOptionColor && (
          <span className={classes.SelectedOptionColor}>
            <svg>
              <circle cx="50%" cy="50%" r="50%" fill={selectedOptionColor} />
            </svg>
          </span>
        )}
        {placeHolder && !selectedOption && (
          <span className={classes.CustomSelectPlaceHolder}>{placeHolder}</span>
        )}
        <span
          className={classes.SelectedOptionName}
          style={selectedOptionColor ? { color: selectedOptionColor } : {}}
        >
          {selectedOption}
        </span>
        <span className={classes.SelectArrow}>
          {selectMenuVisibleStatus ? (
            <svg
              viewBox="0 0 7 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.72318 0.108606L6.75305 3.13852C6.82318 3.2086 6.86182 3.30215 6.86182 3.4019C6.86182 3.50165 6.82318 3.5952 6.75305 3.66527L6.52997 3.88841C6.38461 4.0336 6.14836 4.0336 6.00322 3.88841L3.45892 1.34411L0.911803 3.89123C0.841669 3.96131 0.748176 4 0.648483 4C0.54868 4 0.455186 3.96131 0.384997 3.89123L0.161976 3.6681C0.0918422 3.59796 0.053205 3.50447 0.053205 3.40472C0.053205 3.30497 0.0918422 3.21143 0.161976 3.14135L3.19461 0.108606C3.26496 0.0383613 3.3589 -0.000220299 3.45876 9.53674e-07C3.559 -0.000220299 3.65288 0.0383613 3.72318 0.108606Z"
                fill="#B2BDCD"
              />
            </svg>
          ) : (
            <svg
              viewBox="0 0 7 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.66996 3.94462L6.69982 0.914706C6.76996 0.844628 6.80859 0.75108 6.80859 0.651331C6.80859 0.551583 6.76996 0.458035 6.69982 0.387957L6.47675 0.164824C6.33139 0.0196303 6.09513 0.0196303 5.95 0.164824L3.4057 2.70912L0.85858 0.162001C0.788446 0.0919228 0.694953 0.0532303 0.595261 0.0532303C0.495457 0.0532303 0.401964 0.0919228 0.331775 0.162001L0.108753 0.385134C0.0386195 0.455267 -1.7643e-05 0.54876 -1.7643e-05 0.648508C-1.7643e-05 0.748257 0.0386195 0.841805 0.108753 0.911883L3.14138 3.94462C3.21174 4.01487 3.30567 4.05345 3.40553 4.05323C3.50578 4.05345 3.59966 4.01487 3.66996 3.94462Z"
                fill="#B2BDCD"
              />
            </svg>
          )}
        </span>
      </div>
      <div
        className={
          selectMenuVisibleStatus ? classes.CustomSelectMenu : classes.Invisible
        }
      >
        {options?.map((element: any, index: number) => {
          const { option, value, color } = element;

          return (
            <span
              key={index}
              onClick={() => handleSelection(option, value, color)}
            >
              {option}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default CustomSelect;
