import { StrategyActionsTypes, StrategyBoardElementInput, STRATEGY_BOARD_ELEMENT_INPUT } from "../../../../types/typesStrategy";


const initialState: StrategyBoardElementInput = {
    element_input:null
}

const reducer = (state = initialState, action: StrategyActionsTypes): StrategyBoardElementInput => {

    switch (action.type) {

        case STRATEGY_BOARD_ELEMENT_INPUT:
            const {element_input} = action.payload;
            
            return {
                element_input
            }

        default:
            return state

    }
}

export default reducer