import { change_destination } from "../change_destination_arrow/main"
import { getArrowPropery, getContactId } from "../get_arrow_properties.handler";
import { getAttr } from "../methods/attributes";

export const decision_correctly_contact = (active_element:SVGRectElement,arrow:SVGPathElement) =>{
    const {id} = getAttr(active_element);
    const {contact} = getArrowPropery(arrow);
    const {prev} = getContactId(contact);
    if(prev !== "endpoint"){
        const new_contact = `${prev}-${id}`;
        arrow.setAttribute('data-contact',new_contact);
        change_destination(active_element);
    }
}