import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import classes from "./SidePanelMapping.module.scss";
import {
  ApiDebugModes,
  ButtonTypes,
  EditorKeySources,
  ElementTypes,
  icons,
  messages,
  ModalNames,
  storage_key,
} from "../../../../../settings/settings";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store/combineReducer";
import {
  handlerStrategyElementInput,
  handleStrategyBoardSidePanelContent,
  handleStrategyNewCreatedElementId,
} from "../../../../../store/actions/actionsStrategy";
import DB from "../../../../../layout/DB/Storage";
import DataViewer from "../../../../../components/UI/DataViewer/DataViewer";
import TooltipV2 from "../../../../../components/UI/Tooltip/TooltipV2";
import ScoreCard from "../../../../../components/UI/ScoreCard/ScoreCard";
import MappingList from "../../../../../components/UI/MappingList/MappingList";
import { executeStrategy } from "../../../../../helpers/executeStrategy";
import { remove_db } from "../../FlowChart/handlers/remove_element.handler";
import { hasChange } from "../../../../../helpers/hasChange";
import Modal from "../../../../../components/UI/Modal/Modal";
import {
  call_arrow_box_source,
  call_arrow_box_target,
} from "../../FlowChart/handlers/remove_arrow_box.handler";
import {
  closeModal,
  openModal,
} from "../../../../../store/actions/actionsModal";
import { checkField } from "../../../../../helpers/checkField";
import { submitElement } from "../../../../../helpers/submitElement";
import { create_arrow } from "../../FlowChart/handlers/create_arrow";
import { checkLabel } from "helpers/checkLabel";
import { Message } from "components/UI/Messages/Messages";
import { handleScoreCardCriteriaData } from "store/actions/actionsScoreCard";
import { TooltipInfo } from "components/UI/Tooltip/InfoTooltip/InfoTooltip";

interface IValidating {
  label: boolean;
  mappData: boolean;
}

let timeOutInstance: any;
let initialState: any;

const SidePanelMapping: FC = () => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch();
  const {
    reducerStrategy: {
      StrategyBoardElements: {
        NewCreatedElement: { new_created_element },
        ElementInput: { element_input },
      },
    },
    reducerModal: { modalState },
  } = useSelector((state: RootState) => state);

  const [mappingState, setMappingState] = useState<any>({
    id: "",
    label: "",
    map_type: "scorecard",
    scorecard_id: "",
    mappData: [],
  });
  const [prevMappingState, setPrevMappingState] = useState<any>();
  const [emptyFieldIndices, setEmptyFieldIndices] = useState<Array<number>>();
  const [headerInputStatus, setHeaderInputStatus] = useState<boolean>(false);
  const [labelState, setLabelState] = useState<any>({
    previous: "",
    present: "",
  });
  const [tooltipState, setTooltipState] = useState<any>({
    visible: false,
    data: undefined,
    bgColor: undefined,
    txtColor: undefined,
    topMessage: undefined,
    bottomMessage:
      "This notification may show reduced result. For full result please choose Debug mode",
  });
  const [validating, setValidating] = useState<IValidating>({
    label: false,
    mappData: false,
  });
  const [nameTooltip, setNameTooltip] = useState<boolean>(false);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [statusReload, setStatusReload] = useState<boolean>(false);
  const [scoreCardName, setScoreCardName] = useState("")
  const [scoreCardDescription, setScoreCardDescription] = useState("")

  const refReload = useRef<HTMLImageElement>(null);

  const [isOpenTooltipInfo, setIsOpenTooltipInfo] = useState<boolean>(false);

  const showInfo = (e: any) => {
   setIsOpenTooltipInfo(!isOpenTooltipInfo);
 };
  const setFullScreenState = useCallback(() => {
    const { current } = wrapperRef;

    if (current && fullScreen) {
      current.classList.remove(classes.FullScreen);
      setFullScreen(false);
    } else if (current && !fullScreen) {
      current.classList.add(classes.FullScreen);
      setFullScreen(true);
    }
  }, [fullScreen]);

  const handleMappingState = useCallback(
    (keys: Array<string>, values: Array<string | number | Array<object>>) => {
      let newState = { ...mappingState };

      keys?.forEach((key: string, index: number) => {
        newState[key] = values[index];
      });

      setMappingState({ ...newState });
    },
    [mappingState]
  );

  const getMappingId = useCallback(
    (elementId: string) => {
      const { id } = mappingState;

      if (!id) {
        handleMappingState(["id", "label"], [elementId, elementId]);
        setLabelState({ ...labelState, present: elementId });
        initialState = Object.freeze({
          id: elementId,
          label: elementId,
          map_type: "scorecard",
          scorecard_id: "",
          mappData: [],
        });
      }
    },
    [mappingState, handleMappingState, labelState]
  );

  const getPrevMappingData = useCallback(
    (elementId: string) => {
      const db = new DB(storage_key);
      const { id } = mappingState;

      if (id === "") {
        db.fetch({ sourceId: elementId })
          .then((result: any) => {
            const {
              status,
              data: { data },
            } = result;

            if (status) {
              initialState = Object.freeze({ ...data });
              setLabelState({ ...labelState, present: data?.label });
              setPrevMappingState({ ...data });
              setMappingState({ ...data });
            }

            if (!status) {
              console.warn(
                "Previous mapping data fetch from local storage not found"
              );
            }
          })
          .catch((error) => {
            console.error(
              "Previous mapping data fetch from local storage error :",
              error
            );
          });
      }
    },
    [mappingState, labelState]
  );

  const changeInputStatus = useCallback(
    (status: boolean, labelState: any | object) => {
      const { current } = inputRef;
      setHeaderInputStatus(status);
      if (status) {
        setLabelState({ ...labelState, previous: labelState.present });
        current?.focus();
      } else {
        current?.blur();
      }
    },
    []
  );

  const manipulateInputKeyUp = useCallback(
    (event: any) => {
      const { keyCode } = event;
      const enterCode = 13;
      const escCode = 27;

      if (keyCode === enterCode) {
        changeInputStatus(false, labelState);
        handleMappingState(["label"], [labelState.present]);
        labelState.present && setValidating({ ...validating, label: false });
      } else if (keyCode === escCode) {
        changeInputStatus(false, labelState);
        setLabelState({ previous: undefined, present: labelState.previous });
      }
    },
    [labelState, changeInputStatus, handleMappingState, validating]
  );

  const changeLabel = useCallback(
    (event: any) => {
      const { target } = event;
      const value = target?.value.replace(" ", "_");
      setLabelState({ ...labelState, present: value });
      if (validating.label) {
        if (value) {
          target?.parentElement?.classList.remove(classes.Validate);
        } else {
          target.parentElement?.classList.add(classes.Validate);
        }
      }
      setNameTooltip(false);
    },
    [labelState, validating]
  );

  useLayoutEffect(() => {
    if (new_created_element && !element_input) {
      getMappingId(new_created_element);
    } else if (element_input) {
      getPrevMappingData(element_input);
    }
  }, [element_input, new_created_element, getMappingId, getPrevMappingData]);

  useEffect(() => {
    return () => clearTimeout(timeOutInstance);
  }, []);

  const checkMapDataLength = () => {
    return mappingState?.mappData?.length > 0;
  };

  const handleInputClick = () => {
    if (!headerInputStatus) {
      changeInputStatus(true, labelState);
    }
  };

  const handleInputActions = (status: boolean) => {
    const data = {
      ...mappingState,
      label: labelState?.present,
    } as any;

    if (!checkLabel(data, ElementTypes.MAP)) {
      if (status) {
        changeInputStatus(false, labelState);

        handleMappingState(["label"], [labelState.present]);
        labelState.present && setValidating({ ...validating, label: false });
      } else {
        setLabelState({ previous: undefined, present: labelState.previous });
      }
      setNameTooltip(false);
    } else {
      setNameTooltip(true);
    }
  };

  const closeWrapper = () => {
    const { current } = wrapperRef;

    if (current) {
      current.classList.add(classes.CloseAnimation);
      timeOutInstance = setTimeout(() => {
        dispatch(handleStrategyBoardSidePanelContent({ content_value: null }));
        dispatch(
          handleStrategyNewCreatedElementId({ new_created_element: null })
        );
        dispatch(handlerStrategyElementInput({ element_input: null }));
        dispatch(handleScoreCardCriteriaData({ scoreCardCriteriaData: null }));
      }, 1000);
    }
  };

  const removeElement = () => {
    if (new_created_element) {
      const svg = document.querySelector("#svg_board") as SVGSVGElement;
      const find_element: any = svg?.querySelector(
        `rect[data-id = ${new_created_element}]`
      ) as SVGRectElement;

      const find_arrows = svg.querySelectorAll(
        `path[data-contact $= ${new_created_element}]`
      ) as NodeList;
      const find_arrows_target = svg.querySelectorAll(
        `path[data-contact ^= ${new_created_element}]`
      ) as NodeList;

      let source: any = null;
      let target: any = null;
      let prev_arrow: any = null;
      if (find_element) {
        remove_db(find_element);
        //

        if (find_arrows?.length !== 0) {
          find_arrows.forEach((arrow: any) => {
            const id = arrow.dataset.contact;
            source = svg.querySelector(
              `rect[data-id = ${id?.split("-")?.[0]}]`
            );

            let find_text = svg.querySelectorAll(
              `text[id = "${id}"]`
            ) as NodeList;
            find_text?.forEach((text: any) => {
              svg.removeChild(text);
            });
            svg.removeChild(arrow);
          });
        }
        if (find_arrows_target?.length !== 0) {
          find_arrows_target.forEach((arrow: any) => {
            const id = arrow.dataset.contact;
            target = svg.querySelector(
              `rect[data-id = ${id?.split("-")?.[1]}]`
            );
            prev_arrow = arrow;
            let find_text = svg.querySelectorAll(
              `text[id = "${id}"]`
            ) as NodeList;
            find_text?.forEach((text: any) => {
              svg.removeChild(text);
            });

            svg.removeChild(arrow);
          });
        }

        svg.removeChild(find_element.parentNode);
        if (source && target) {
          create_arrow(source, target, prev_arrow, svg);
        } else {
          call_arrow_box_source(find_arrows);
          call_arrow_box_target(find_arrows_target);
        }
      }
    }
  };

  const checkChanges = () => {
    setIsOpenTooltipInfo(false);
    hasChange({
      initial: initialState,
      changeable: mappingState,
    })
      .then(() => {
        dispatch(
          openModal({
            modalState: {
              visible: true,
              title: messages.titleClosePanel,
              question: messages.titleSureClosePanel,
              message: messages.messagePanelClosing,
              buttonMessage: messages.titleBtnClose,
              buttonType: ButtonTypes.PRIMARY,
              name: ModalNames.DATA_WIZARD_PANEL,
            },
          })
        );
      })
      .catch((error) => {
        error && console.error(error);
        closeWrapper();
        removeElement();
      });
  };

  const handleModalAction = (action: boolean) => {
    const { buttonType } = modalState;
    if (buttonType === ButtonTypes.PRIMARY && action) {
      closeWrapper();
      removeElement();
    } else if (buttonType === ButtonTypes.WARNING) {
      setValidating({
        ...validating,
        [modalState.validatingField as string]: true,
      });
    }

    dispatch(closeModal());
  };

  const validateData = () =>
    new Promise((resolve, reject) => {
      checkField({ state: mappingState, types: ElementTypes.MAP })
        .then(resolve)
        .catch(({ reason, indices }) => {
          dispatch(
            openModal({
              modalState: {
                visible: true,
                title: messages.titleAttention,
                question: messages.titleMustBeFilled,
                message: messages.messagePanelEmptyAttention,
                buttonMessage: messages.titleUnderstand,
                buttonType: ButtonTypes.WARNING,
                validatingField: reason,
                name: ModalNames.DATA_WIZARD_PANEL,
              },
            })
          );
          indices && setEmptyFieldIndices([...indices]);
          reject();
        });
    });

  const handleExecution = () => {
    validateData().then(() => {
      executeStrategy({
        state: tooltipState,
        setState: setTooltipState,
        activeElementId: element_input,
        debugMode: ApiDebugModes.BREAKPOINT,
        elementPresentData: mappingState,
        elementPreviousData: prevMappingState,
        elementType: ElementTypes.MAP,
      });
    });
  };

  const handleSubmit = () => {
    validateData().then(() => {
      submitElement(
        new_created_element
          ? {
              id: new_created_element,
              state: mappingState,
              type: ElementTypes.MAP,
              isNew: true,
            }
          : {
              id: element_input,
              state: mappingState,
              previousData: prevMappingState,
              type: ElementTypes.MAP,
              isNew: false,
            }
      )
        .then(closeWrapper)
        .catch(() => {
          dispatch(
            openModal({
              modalState: {
                visible: true,
                title: messages.titleOops,
                question: messages.titleWentWrong,
                message: messages.messagePanelSubmittingError,
                buttonMessage: messages.titleAgain,
                buttonType: ButtonTypes.DELETE,
                name: ModalNames.DATA_WIZARD_PANEL,
              },
            })
          );
        });
    });
  };

  const handleReload = () => {
    refReload.current?.classList.add(classes.ReloadActive);
    setTimeout(
      () => refReload.current?.classList.remove(classes.ReloadActive),
      700
    );
    dispatch(handleScoreCardCriteriaData({ scoreCardCriteriaData: null }));
    setStatusReload(true);
    setTimeout(() => setStatusReload(false), 1000);
  };
  
  return (
    <div ref={wrapperRef} className={classes.MappingWrapper}>
      <section className={classes.MappingHeader}>
        <div className={classes.HeaderLeft}>
          <img
            src={fullScreen ? icons.exitFullScreen : icons.fullScreen}
            alt="screen icon"
            title={fullScreen ? "Exit full screen" : "Full screen"}
            onClick={setFullScreenState}
          />
          {nameTooltip && <Message style={{ left: 1.15, top: 5.6 }} />}

          <div
            className={`${classes.HeaderTitleWrapper} ${
              headerInputStatus ? classes.ActiveTitle : ""
            } ${validating.label ? classes.Validate : ""}`}
          >
            <input
              ref={inputRef}
              type="text"
              className={classes.HeaderTitleInput}
              title={labelState.present}
              value={labelState.present}
              onChange={changeLabel}
              onKeyUp={manipulateInputKeyUp}
              onClick={handleInputClick}
            />
            {headerInputStatus && (
              <>
                <svg
                  viewBox="0 0 10 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => handleInputActions(true)}
                >
                  <path d="M.115 4.23A.415.415 0 010 3.963c0-.077.038-.193.115-.27l.539-.538a.372.372 0 01.538 0l.039.038 2.115 2.27a.186.186 0 00.27 0L8.768.115h.039a.372.372 0 01.538 0l.539.539a.372.372 0 010 .538L3.73 7.577a.35.35 0 01-.27.115.35.35 0 01-.269-.115l-3-3.23-.077-.116z" />
                </svg>
                <svg
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => handleInputActions(false)}
                >
                  <path d="M5.88 4.997l3.93-3.93a.625.625 0 10-.883-.884l-3.93 3.93-3.93-3.93a.625.625 0 10-.884.884l3.93 3.93-3.93 3.93a.625.625 0 10.884.884l3.93-3.93 3.93 3.93a.623.623 0 00.884 0 .625.625 0 000-.884l-3.93-3.93z" />
                </svg>
              </>
            )}
          </div>
        </div>

        <div className={classes.HeaderActions}>
          {element_input && (
            <>
              <img
                ref={refReload}
                className={classes.HeaderReload}
                src={icons.reload}
                alt="Reload icon"
                title="Reload"
                onClick={handleReload}
              />
              <TooltipV2
                visible={tooltipState?.visible}
                onVisible={(value: boolean) =>
                  setTooltipState({
                    ...tooltipState,
                    visible: value,
                    data: undefined,
                  })
                }
                data={tooltipState?.data}
                topMessage={tooltipState?.topMessage}
                bottomMessage={tooltipState?.bottomMessage}
                backgroundColor={tooltipState?.bgColor}
                textColor={tooltipState?.txtColor}
                child={
                  <img
                    className={classes.HeaderPlayAction}
                    src={icons.strategyPlay}
                    alt="Play icon"
                    onClick={handleExecution}
                  />
                }
              />
            </>
          )}
        </div>
      </section>
      <section className={classes.MappingBody}>
        <aside className={classes.MappingLeftSide}>
          <DataViewer
            fullScreen={checkMapDataLength() ? true : false}
            dataViewerType={EditorKeySources.MAP}
            presentVariableId={
              new_created_element ? new_created_element : mappingState?.id
            }
            fullHeight={checkMapDataLength()}
          />
          {!checkMapDataLength() && (
            <>
              <span className={classes.SpacerNormal} />
              <ScoreCard
                onSelect={(id: number, name: string, description: string) => {
                  handleMappingState(["scorecard_id"], [id]);
                  setScoreCardName(name)
                  setScoreCardDescription(description)
                }}
                fullScreen={fullScreen}
              />
            </>
          )}
        </aside>
        <div className={classes.MappingRightSide}>
          <MappingList
            reload={statusReload}
            scoreCardId={mappingState?.scorecard_id}
            scoreCardName={scoreCardName}
            scoreCardDescription={scoreCardDescription}
            prevMappedData={
              element_input !== null ? mappingState?.mappData : undefined
            }
            emptyRowsIndices={emptyFieldIndices}
            onMap={(mappedData: Array<object>) => {
              handleMappingState(["mappData"], [mappedData]);
            }}
            validate={validating.mappData}
            fullScreen={fullScreen}
          />
          <span className={classes.SpacerNormal} />
          <button className={classes.SubmitButton} onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </section>
      {isOpenTooltipInfo && <TooltipInfo sidePanelUp />}
      {/* <button className={classes.MappingCloseButton} onClick={checkChanges}>
        <img src={icons.closeBtn} alt="Close icon" />
      </button> */}
      {!fullScreen && (
        <>
        <button className={classes.MappingCloseButton} onClick={checkChanges}>
          <img src={icons.closeBtn} alt="Close icon" />
        </button>
        <svg
            className={classes.HelpIcon}
            viewBox='0 0 8 8'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            onClick={(e) => showInfo(e)}
          >
            <path
              d='M4 6.30469C4.21574 6.30469 4.39062 6.1298 4.39062 5.91406C4.39062 5.69833 4.21574 5.52344 4 5.52344C3.78426 5.52344 3.60938 5.69833 3.60938 5.91406C3.60938 6.1298 3.78426 6.30469 4 6.30469Z'
              fill='white'
              fillOpacity='0.6'
            />      
            <path
              d='M4 2.00781C3.31075 2.00781 2.75 2.56856 2.75 3.25781C2.75 3.43041 2.88991 3.57031 3.0625 3.57031C3.23509 3.57031 3.375 3.43041 3.375 3.25781C3.375 2.91319 3.65538 2.63281 4 2.63281C4.34462 2.63281 4.625 2.91319 4.625 3.25781C4.625 3.60244 4.34462 3.88281 4 3.88281C3.82741 3.88281 3.6875 4.02272 3.6875 4.19531V4.97656C3.6875 5.14916 3.82741 5.28906 4 5.28906C4.17259 5.28906 4.3125 5.14916 4.3125 4.97656V4.46827C4.85103 4.32916 5.25 3.8392 5.25 3.25781C5.25 2.56856 4.68925 2.00781 4 2.00781Z'
              fill='white'
              fillOpacity='0.6'
            />
          </svg> 
        </>
       
      )}
      <Modal
        title={modalState?.title}
        question={modalState?.question}
        message={modalState?.message}
        buttonMessage={modalState?.buttonMessage}
        buttonType={modalState?.buttonType}
        onAction={handleModalAction}
        visible={modalState?.visible}
        name={ModalNames.DATA_WIZARD_PANEL}
      />
    </div>
  );
};

export default SidePanelMapping;
