import { code_message_data, condition_data, EActionTypes, end_point_data, function_data, mapping_data, output_format_data, start_point_data, storage_key, validate_data } from "../../../../../settings/settings";
import DB from "../../../../DB/Storage";
import { BoardStorage } from "./storage.helper";

export const add_storage = (parent: SVGElement) => {
    const element = parent?.querySelector('#figure') as SVGRectElement;
    const type = element?.dataset?.type;
    const id = element?.dataset?.id;

    if (type === 'startpoint') {
        self(id, type);
        // prev(id);

    } if (type === 'validate') {
        self(id, type);
        prev(id);

    } if (type === 'datawizard') {
        self(id, type);
        prev(id);

    } else if (type === 'endpoint') {
        self(id, type);
        prev(id);
    } else if (type === 'func') {
        self(id, type);
        prev(id);
    } else if (type === 'scorecard') {
        self(id, type);
        prev(id);

    } else if (type === 'decision_mapp') {
        self(id, type);
        prev(id);

    } else if (type === 'condt') {
        self(id, type);
        prev(id);

    } else if (type === 'code_message') {
        self(id, type);
        prev(id);

    } else if (type === 'output_format') {
        let obj = {
            ...output_format_data
        }
        new DB(storage_key).add([obj])
    }

}

const prev = (id: any) => {
    let svg = document.querySelector('#svg_board') as SVGElement;
    let find_arrows = svg.querySelectorAll(`path[data-contact $= ${id}]`) as NodeList;
    find_arrows?.forEach((arrow: any) => {

        if (arrow?.dataset?.contact?.endsWith(id)) {
            let side: string = arrow.dataset.side;
            let contact: string = arrow.dataset.contact;
            let coord: string = arrow.getAttribute('d');
            let prev_id: string = contact?.split('-')?.[0];
            let self_id: string = contact?.split('-')?.[1];
            const data_bool = arrow?.dataset.bool;

            let overlays = [
                {
                    side: side,
                    contact: contact,
                    coord: coord
                }
            ]

            new DB(storage_key).fetch({ sourceId: prev_id }).then((res_prev: any) => {
                if (res_prev.status && res_prev.data) {
                    let obj: Array<any> = [];
                    if (Array.isArray(res_prev.data)) {

                        if (data_bool?.toLowerCase() === 'yes') {
                            let _data_yes = res_prev.data?.filter((f: any) => f.branchLabel === 'YES');
                            let _data_no = res_prev.data?.filter((f: any) => f.branchLabel === 'NO');

                            obj.push(
                                {
                                    ..._data_yes[0],
                                    targetId: self_id,
                                    overlays: overlays,
                                    anchors: [side]
                                },
                                {
                                    ..._data_no[0]
                                }
                            )
                        }
                        if (data_bool?.toLowerCase() === 'no') {
                            let _data_yes = res_prev.data?.filter((f: any) => f.branchLabel === 'YES');
                            let _data_no = res_prev.data?.filter((f: any) => f.branchLabel === 'NO');

                            obj.push(
                                {
                                    ..._data_yes[0],

                                },
                                {
                                    ..._data_no[0],
                                    targetId: self_id,
                                    overlays: overlays,
                                    anchors: [side]
                                }
                            )

                        }

                    } else {

                        if (id === 'validate') {

                            obj.push({
                                ...res_prev.data,
                                overlays: overlays,
                                anchors: [side]
                            })
                            new DB(storage_key).fetch({ sourceId: id }).then((res_self: any) => {
                                let { status, data } = res_self
                                if (status && data) {
                                    if (data.targetId !== 'datawizard') {
                                        obj[0].targetId = data.targetId;
                                    } else {
                                        new DB(storage_key).fetch({ type: 'datawizard' }).then((_res: any) => {
                                            if (_res.status && _res.data) {
                                                obj[0].targetId = _res.data.targetId;

                                            }
                                        })
                                    }

                                }
                            })

                        } else if (id === 'datawizard') {
                            obj.push({
                                ...res_prev.data,
                                overlays: overlays,
                                anchors: [side]
                            })
                            new DB(storage_key).fetch({ sourceId: id }).then((res_self: any) => {
                                let { status, data } = res_self
                                if (status && data) {
                                    obj[0].targetId = data.targetId;

                                }
                            })
                        } else {

                            obj.push({
                                ...res_prev.data,
                                targetId: self_id,
                                overlays: overlays,
                                anchors: [side]
                            })
                            if (prev_id === 'validate') {
                                new DB(storage_key).fetch({ sourceId: 'startpoint' }).then((start_res: any) => {
                                    if (start_res.status && start_res.data) {
                                        let _obj = {
                                            ...start_res.data,
                                            targetId: self_id
                                        }
                                        new DB(storage_key).update({ sourceId: "startpoint" }, [_obj]);


                                    }
                                })


                            }
                            if (prev_id === 'datawizard') {
                                new DB(storage_key).fetch({ sourceId: 'startpoint' }).then((start_res: any) => {
                                    if (start_res.status && start_res.data) {
                                        let _obj = {
                                            ...start_res.data,
                                            targetId: self_id
                                        }
                                        new DB(storage_key).update({ sourceId: "startpoint" }, [_obj]);


                                    }
                                })


                            }


                        }

                    }

                    new DB(storage_key).update({ sourceId: prev_id }, obj);
                }
            })

        }
    })
}

const self = (id: any, type: any) => {
    let svg = document.querySelector('#svg_board') as SVGElement;

    if (type === 'startpoint') {
        let find_arrow = svg.querySelector(`path[data-contact ^= "${id}-"]`) as SVGPathElement;
        let side = find_arrow?.dataset.side;
        let contact = find_arrow?.dataset.contact;
        let coord = find_arrow?.getAttribute('d');

        let overlays = contact ? [
            {
                side: side,
                contact: contact,
                coord: coord
            }
        ] : [];
        let anchors = side ? [side] : [];
        let element = svg.querySelector(`rect[data-id = ${id}]`) as SVGRectElement;
        let x = element.getAttribute('x');
        let y = element.getAttribute('y');
        new DB(storage_key).fetch({ sourceId: id }).then((_res: any) => {
            let obj: Array<any> = []
            if (_res.status && _res.data) {


                obj.push({
                    ..._res.data,
                    sourceId: id,
                    overlays: overlays,
                    anchors: anchors,
                    targetId: contact ? contact.split('-')?.[1] : "",
                    style: `left:${x}px,top:${y}px`
                })

                new DB(storage_key).fetch({ sourceId: 'validate' }).then((res_self: any) => {
                    let { status, data } = res_self;
                    if (status && data) {
                        if (data.targetId !== 'datawizard') {
                            obj[0].targetId = data.targetId;
                        } else {
                            new DB(storage_key).fetch({ type: 'datawizard' }).then((__res: any) => {
                                if (__res.status && __res.data) {
                                    obj[0].targetId = __res.data.targetId;

                                }
                            })
                        }

                    } else {
                        obj = [];
                        obj.push({
                            ..._res.data,
                            sourceId: id,
                            overlays: overlays,
                            anchors: anchors,
                            targetId: contact ? contact.split('-')?.[1] : "",
                            style: `left:${x}px,top:${y}px`
                        })
                    }
                })

            } else {
                obj=[];
                obj.push({
                    ...start_point_data,
                    sourceId: id,
                    overlays: overlays,
                    anchors: anchors,
                    targetId: contact ? contact.split('-')?.[1] : "",
                    style: `left:${x}px,top:${y}px`
                })

            }
            new DB(storage_key).fetch({ sourceId: id }).then((res: any) => {
                if (res.status && res.data) {
                    new DB(storage_key).update({ sourceId: id }, obj)
                } else {

                    new DB(storage_key).add(obj)
                }
            })
        })
    }
    if (type === 'validate') {
        let find_arrow = svg.querySelector(`path[data-contact ^= "${id}-"]`) as SVGPathElement;

        let side = find_arrow?.dataset.side;
        let contact = find_arrow?.dataset.contact;
        let coord = find_arrow?.getAttribute('d');
        let overlays = contact ? [
            {
                side: side,
                contact: contact,
                coord: coord
            }
        ] : [];
        let anchors = side ? [side] : [];
        let element = svg.querySelector(`rect[data-id = ${id}]`) as SVGRectElement;
        // let {x,y} = element.getBoundingClientRect();
        let x = element.getAttribute('x');
        let y = element.getAttribute('y');
        new DB(storage_key).fetch({ sourceId: id }).then((_res: any) => {
            let obj: Array<any> = [];
            let _targetId = (contact && (contact !== undefined || contact !== null)) ? contact.split('-')?.[1] : 'endpoint'

            if (_res.status && _res.data) {
                obj.push({
                    ..._res.data,
                    sourceId: id,
                    overlays: overlays,
                    anchors: anchors,
                    targetId: _targetId,
                    style: `left:${x}px,top:${y}px`
                })

            } else {
                obj.push({
                    ...validate_data,
                    sourceId: id,
                    overlays: overlays,
                    anchors: anchors,
                    targetId: _targetId,
                    style: `left:${x}px,top:${y}px`
                })

            }
            new DB(storage_key).fetch({ sourceId: id }).then((res: any) => {
                if (res.status && res.data) {
                    new DB(storage_key).update({ sourceId: id }, obj)
                } else {

                    new DB(storage_key).add(obj)
                }
            })

        })

    }
    if (type === 'datawizard') {
        let find_arrow = svg.querySelector(`path[data-contact ^= "${id}-"]`) as SVGPathElement;

        let side = find_arrow?.dataset.side;
        let contact = find_arrow?.dataset.contact;
        let coord = find_arrow?.getAttribute('d');
        let overlays = contact ? [
            {
                side: side,
                contact: contact,
                coord: coord
            }
        ] : [];
        let anchors = side ? [side] : [];
        let element = svg.querySelector(`rect[data-id = ${id}]`) as SVGRectElement;
        let x = element.getAttribute('x');
        let y = element.getAttribute('y');
        new DB(storage_key).fetch({ type: id }).then((_res: any) => {
            let obj: Array<any> = [];
            let _targetId = (contact && (contact !== undefined || contact !== null)) ? contact.split('-')?.[1] : 'endpoint'

            if (_res.status && _res.data) {
                obj.push({
                    ..._res.data,
                    sourceId: id,
                    overlays: overlays,
                    anchors: anchors,
                    targetId: _targetId,
                    style: `left:${x}px,top:${y}px`
                })

            } else {
                // obj.push({
                //     ...validate_data,
                //     sourceId:id,
                //     overlays:overlays,
                //     anchors:anchors,
                //     targetId:_targetId,
                //     style:`left:${x}px,top:${y}px`
                // })

            }
            new DB(storage_key).fetch({ type: id }).then((res: any) => {
                if (res.status && res.data) {

                    new DB(storage_key).update({ type: id }, obj)

                } else {

                    new DB(storage_key).add(obj)
                }
            })

        })

    }
    if (type === 'endpoint') {


        let element = svg.querySelector(`rect[data-id = ${id}]`) as SVGRectElement;
        // let {x,y} = element.getBoundingClientRect();
        let x = element.getAttribute('x');
        let y = element.getAttribute('y');

        new DB(storage_key).fetch({ sourceId: id }).then((_res: any) => {
            let obj: Array<any> = []
            if (_res.status && _res.data) {
                obj.push({
                    ..._res.data,
                    sourceId: id,
                    style: `left:${x}px,top:${y}px`,
                    targetId:'endpoint'
                })


            } else {
                obj.push({
                    ...end_point_data,
                    sourceId: id,
                    style: `left:${x}px,top:${y}px`,
                    targetId:'endpoint'

                })
            }
            new DB(storage_key).fetch({ sourceId: id }).then((res: any) => {
                if (res.status && res.data) {
                    new DB(storage_key).update({ sourceId: id }, obj)
                } else {

                    new DB(storage_key).add(obj)
                }
            })
            check_prev(id, type)
        })


    }

    if (type === 'code_message') {


        let element = svg.querySelector(`rect[data-id = ${id}]`) as SVGRectElement;
        // let {x,y} = element.getBoundingClientRect();
        let x = element.getAttribute('x');
        let y = element.getAttribute('y');
        let obj: Array<any> = [];
        new DB(storage_key).fetch({ sourceId: id }).then((_res: any) => {
            if (_res.status && _res.data) {
                obj.push({
                    ..._res.data,
                    sourceId: id,
                    style: `left:${x}px,top:${y}px`
                })
            } else {
                obj.push({
                    ...code_message_data,
                    sourceId: id,
                    style: `left:${x}px,top:${y}px`
                })
            }
            new DB(storage_key).fetch({ sourceId: id }).then((res: any) => {
                if (res.status && res.data) {
                    new DB(storage_key).update({ sourceId: id }, obj)
                } else {

                    new DB(storage_key).add(obj)
                }
            })
            check_prev(id, type)
        })



    }
    if (type === 'func') {
        let find_arrow = svg.querySelector(`path[data-contact ^= "${id}-"]`) as SVGPathElement;

        let side = find_arrow?.dataset.side;
        let contact = find_arrow?.dataset.contact;
        let coord = find_arrow?.getAttribute('d');
        let overlays = contact ? [
            {
                side: side,
                contact: contact,
                coord: coord
            }
        ] : [];
        let anchors = side ? [side] : [];
        let element = svg.querySelector(`rect[data-id = ${id}]`) as SVGRectElement;
        // let {x,y} = element.getBoundingClientRect();
        let x = element.getAttribute('x');
        let y = element.getAttribute('y');
        new DB(storage_key).fetch({ sourceId: id }).then((_res: any) => {
            let obj: Array<any> = [];
            let _targetId = (contact && (contact !== undefined || contact !== null)) ? contact.split('-')?.[1] : 'endpoint'

            if (_res.status && _res.data) {
                obj.push({
                    ..._res.data,
                    sourceId: id,
                    overlays: overlays,
                    anchors: anchors,
                    targetId: _targetId,
                    style: `left:${x}px,top:${y}px`
                })

            } else {
                obj.push({
                    ...function_data,
                    sourceId: id,
                    overlays: overlays,
                    anchors: anchors,
                    targetId: _targetId,
                    style: `left:${x}px,top:${y}px`
                })

            }
            new DB(storage_key).fetch({ sourceId: id }).then((res: any) => {
                if (res.status && res.data) {
                    new DB(storage_key).update({ sourceId: id }, obj)
                } else {
                    new DB(storage_key).add(obj)
                }
            })

        })

    }
    if (type === 'scorecard') {
        let find_arrow = svg.querySelector(`path[data-contact ^="${id}-"]`) as SVGPathElement;
        let side = find_arrow?.dataset.side;
        let contact = find_arrow?.dataset.contact;
        let coord = find_arrow?.getAttribute('d');
        let overlays = contact ? [
            {
                side: side,
                contact: contact,
                coord: coord
            }
        ] : [];
        let anchors = side ? [side] : [];
        let element = svg.querySelector(`rect[data-id = ${id}]`) as SVGRectElement;
        // let {x,y} = element.getBoundingClientRect();
        let x = element.getAttribute('x');
        let y = element.getAttribute('y');
        new DB(storage_key).fetch({ sourceId: id }).then((_res: any) => {
            let obj: Array<any> = []
            if (_res.status && _res.data) {
                obj.push({
                    ..._res.data,
                    sourceId: id,
                    overlays: overlays,
                    anchors: anchors,
                    targetId: contact ? contact.split('-')?.[1] : "",
                    style: `left:${x}px,top:${y}px`
                })
            } else {
                obj.push({
                    ...mapping_data,
                    sourceId: id,
                    overlays: overlays,
                    anchors: anchors,
                    targetId: contact ? contact.split('-')?.[1] : "",
                    style: `left:${x}px,top:${y}px`
                })

            }
            new DB(storage_key).fetch({ sourceId: id }).then((res: any) => {
                if (res.status && res.data) {
                    new DB(storage_key).update({ sourceId: id }, obj)
                } else {

                    new DB(storage_key).add(obj)
                }
            })
        })

    }
    if (type === 'decision_mapp') {
        let find_arrow = svg.querySelector(`path[data-contact ^="${id}-"]`) as SVGPathElement;
        let side = find_arrow?.dataset.side;
        let contact = find_arrow?.dataset.contact;
        let coord = find_arrow?.getAttribute('d');
        let overlays = contact ? [
            {
                side: side,
                contact: contact,
                coord: coord
            }
        ] : [];
        let anchors = side ? [side] : [];
        let element = svg.querySelector(`rect[data-id = ${id}]`) as SVGRectElement;
        // let {x,y} = element.getBoundingClientRect();
        let x = element.getAttribute('x');
        let y = element.getAttribute('y');
        new DB(storage_key).fetch({ sourceId: id }).then((_res: any) => {
            let obj: Array<any> = []
            if (_res.status && _res.data) {
                obj.push({
                    ..._res.data,
                    sourceId: id,
                    overlays: overlays,
                    anchors: anchors,
                    targetId: contact ? contact.split('-')?.[1] : "",
                    style: `left:${x}px,top:${y}px`
                })
            } else {
                obj.push({
                    ...mapping_data,
                    sourceId: id,
                    overlays: overlays,
                    anchors: anchors,
                    targetId: contact ? contact.split('-')?.[1] : "",
                    style: `left:${x}px,top:${y}px`
                })

            }
            new DB(storage_key).fetch({ sourceId: id }).then((res: any) => {
                if (res.status && res.data) {
                    new DB(storage_key).update({ sourceId: id }, obj)
                } else {

                    new DB(storage_key).add(obj)
                }
            })
        })

    }
    if (type === 'condt') {

        let find_arrows = svg.querySelectorAll(`path[data-contact ^= "${id}-"]`) as NodeList;
        let obj: Array<any> = [];
        if (find_arrows.length > 0) {
            obj = [];

            find_arrows?.forEach((arrow: any) => {

                const data_bool = arrow?.dataset.bool;

                let side = arrow?.dataset.side;
                let contact = arrow?.dataset.contact;
                let coord = arrow?.getAttribute('d');

                let overlays: Array<any> = contact ? [
                    {
                        side: side,
                        contact: contact,
                        coord: coord
                    }
                ] : [];
                let anchors: Array<any> = side ? [side] : [];

                let element = svg.querySelector(`rect[data-id = ${id}]`) as SVGRectElement;

                // let {x,y} = element.getBoundingClientRect();
                let x = element.getAttribute('x');
                let y = element.getAttribute('y');

                new DB(storage_key).fetch({ sourceId: id }).then((_res: any) => {

                    if (_res.status && Array.isArray(_res.data)) {

                        if (data_bool?.toLowerCase() === "yes") {
                            let _data = _res.data?.filter((_r: any) => _r.branchLabel === 'YES');
                            obj.push(
                                {
                                    ..._data[0],
                                    sourceId: id,
                                    overlays: overlays,
                                    anchors: anchors,
                                    targetId: contact && contact.split('-')?.[1] && (contact.split('-')?.[1] !== id) ? contact.split('-')?.[1] : "",
                                    style: `left:${x}px,top:${y}px`
                                }
                            )
                        }
                        if (data_bool?.toLowerCase() === "no") {
                            let _data = _res.data?.filter((_r: any) => _r.branchLabel === 'NO');

                            obj.push(
                                {
                                    ..._data[0],
                                    sourceId: id,
                                    overlays: overlays,
                                    anchors: anchors,
                                    targetId: contact && contact.split('-')?.[1] && (contact.split('-')?.[1] !== id) ? contact.split('-')?.[1] : "",
                                    style: `left:${x}px,top:${y}px`
                                }
                            )

                        }
                        if (!data_bool) {
                            arrow.setAttributeNS(null, 'data-bool', 'yes');
                            obj.push(
                                {
                                    ...condition_data.yes,
                                    sourceId: id,
                                    overlays: overlays,
                                    anchors: anchors,
                                    targetId: contact && contact.split('-')?.[1] && (contact.split('-')?.[1] !== id) ? contact.split('-')?.[1] : "",
                                    style: `left:${x}px,top:${y}px`
                                },
                                {
                                    ...condition_data.no,
                                    sourceId: id,
                                    overlays: [],
                                    anchors: [],
                                    targetId: "",
                                    style: `left:${x}px,top:${y}px`
                                }
                            )
                        }
                    } else {

                        if (data_bool?.toLowerCase() === "yes") {

                            obj.push(
                                {
                                    ...condition_data.yes,
                                    sourceId: id,
                                    overlays: overlays,
                                    anchors: anchors,
                                    targetId: contact && contact.split('-')?.[1] && (contact.split('-')?.[1] !== id) ? contact.split('-')?.[1] : "",
                                    style: `left:${x}px,top:${y}px`
                                }
                            )
                        }
                        if (data_bool?.toLowerCase() === "no") {

                            obj.push(
                                {
                                    ...condition_data.no,
                                    sourceId: id,
                                    overlays: overlays,
                                    anchors: anchors,
                                    targetId: contact && contact.split('-')?.[1] && (contact.split('-')?.[1] !== id) ? contact.split('-')?.[1] : "",
                                    style: `left:${x}px,top:${y}px`
                                }
                            )

                        }
                        if (!data_bool) {
                            arrow.setAttributeNS(null, 'data-bool', 'yes');
                            obj.push(
                                {
                                    ...condition_data.yes,
                                    sourceId: id,
                                    overlays: overlays,
                                    anchors: anchors,
                                    targetId: contact && contact.split('-')?.[1] && (contact.split('-')?.[1] !== id) ? contact.split('-')?.[1] : "",
                                    style: `left:${x}px,top:${y}px`
                                },
                                {
                                    ...condition_data.no,
                                    sourceId: id,
                                    overlays: [],
                                    anchors: [],
                                    targetId: "",
                                    style: `left:${x}px,top:${y}px`
                                }
                            )
                        }
                    }
                })



            })
        } else {


            obj = []
            let element = svg.querySelector(`rect[data-id = ${id}]`) as SVGRectElement;

            let x = element.getAttribute('x');
            let y = element.getAttribute('y');
            new DB(storage_key).fetch({ sourceId: id }).then((res: any) => {
                if (res.status && Array.isArray(res.data)) {

                    obj.push(
                        {
                            ...res.data?.filter((f: any) => f.branchLabel === "YES")?.[0],
                            sourceId: id,
                            overlays: [],
                            anchors: [],
                            targetId: "",
                            style: `left:${x}px,top:${y}px`
                        },
                        {
                            ...res.data?.filter((f: any) => f.branchLabel === "NO")?.[0],
                            sourceId: id,
                            overlays: [],
                            anchors: [],
                            targetId: "",
                            style: `left:${x}px,top:${y}px`
                        }
                    )
                } else {
                    obj.push(
                        {
                            ...condition_data.yes,
                            sourceId: id,
                            overlays: [],
                            anchors: [],
                            targetId: "",
                            style: `left:${x}px,top:${y}px`
                        },
                        {
                            ...condition_data.no,
                            sourceId: id,
                            overlays: [],
                            anchors: [],
                            targetId: "",
                            style: `left:${x}px,top:${y}px`
                        }
                    )
                }
            })


        }

        new DB(storage_key).fetch({ sourceId: id }).then((res: any) => {
            if (res.status && res.data) {

                new DB(storage_key).update({ sourceId: id }, obj);

            } else {
                new DB(storage_key).add(obj);
            }
        })
        check_prev(id, type)



    }
}

const check_prev = (id: any, type: any) => {
    let svg = document.querySelector('#svg_board') as SVGElement;
    let find_arrows = svg.querySelectorAll(`path[data-contact $= ${id}]`) as NodeList;
    find_arrows?.forEach((arrow: any) => {
        let contact: string = arrow?.dataset.contact;
        let prev_id: string = contact?.split('-')?.[0];

        new DB(storage_key).fetch({ sourceId: prev_id }).then((res_prev: any) => {
            if (res_prev.status && res_prev.data) {

                new DB(storage_key).fetch({ sourceId: id }).then((res_self: any) => {
                    if (res_self.status && res_self.data) {
                        let arrow2 = svg.querySelector(`path[data-contact ^= "${id}-"]`) as SVGPathElement;
                        let overlays = arrow2 ? [{
                            side: arrow2.dataset.side,
                            contact: arrow2.dataset.contact,
                            coord: arrow2.getAttribute('d')
                        }] : []
                        let element = svg.querySelector(`rect[data-id = ${id}]`) as SVGRectElement;
                        let left = element?.getAttribute('x');
                        let top = element?.getAttribute('y');
                        let obj: Array<any> = [];
                        if (Array.isArray(res_self.data)) {
                            obj.push(
                                {
                                    ...res_self.data[0],
                                    targetId: res_prev.data.targetId,
                                    overlays: overlays,
                                    style: `left:${left}px,top:${top}px`
                                },
                                {
                                    ...res_self.data[1],
                                    style: `left:${left}px,top:${top}px`

                                }
                            )

                        } else {



                            obj.push({
                                ...res_self.data,
                                targetId: res_prev.data.targetId ? res_prev.data.targetId :(res_self.data.type === "endpoint" ? "endpoint" :""),
                                overlays: overlays,
                                style: `left:${left}px,top:${top}px`

                            })
                            
                        }

                        new DB(storage_key).update({ sourceId: id }, obj)


                    }
                })

            }
        })
    })
}

export const add_move = (parent: SVGGElement, _x: any = null) => {
    let svg = document.querySelector('#svg_board') as SVGElement;

    const element = parent.querySelector('#figure') as SVGRectElement;
    const type = element.dataset.type;
    const id = element.dataset.id;
    // let {x,y} = element.getBoundingClientRect();
    let x = element.getAttribute('x');
    let y = element.getAttribute('y');
    if (type === 'condt') {
        new DB(storage_key).fetch({ sourceId: id }).then((res: any) => {
            if (res.status && res.data) {
                let arrows = svg.querySelectorAll(`path[data-contact ^= "${res.data?.[0]?.sourceId}-"]`) as NodeList;
                let obj: Array<any> = [];
                if (arrows.length > 0) {

                    arrows?.forEach((arrow: any) => {

                        let d = arrow?.getAttribute('d');
                        let data_bool = arrow.dataset.bool;
                        if (data_bool?.toLowerCase() === 'yes') {
                            obj.push({
                                ...res.data[0],
                                style: `left:${x}px,top:${y}px`,
                                overlays: d ? [{ side: arrow.dataset.side, contact: arrow.dataset.contact, coord: d }] : []
                            })
                        }
                        if (data_bool?.toLowerCase() === 'no') {
                            obj.push({
                                ...res.data[1],
                                style: `left:${x}px,top:${y}px`,
                                overlays: d ? [{ side: arrow.dataset.side, contact: arrow.dataset.contact, coord: d }] : []
                            })
                        }
                    })
                } else {

                    obj.push(
                        {
                            ...res.data[0],
                            style: `left:${x}px,top:${y}px`,
                        },
                        {
                            ...res.data[1],
                            style: `left:${x}px,top:${y}px`,
                        }
                    )

                }

                new DB(storage_key).update({ sourceId: id }, obj)
            }
        })
    } else {

        new DB(storage_key).fetch({ sourceId: id }).then((res: any) => {
            if (res.status && res.data) {
                let arrow: any = svg.querySelector(`path[data-contact ^= "${res.data.sourceId}-"]`) as SVGPathElement;
                let d = arrow?.getAttribute('d');

                let obj = {
                    ...res.data,
                    style: `left:${x}px,top:${y}px`,
                    overlays: d ? [{ ...res.data.overlays?.[0],side: arrow.dataset.side, contact: arrow.dataset.contact, coord: d }] : []
                }
                if (id !== 'endpoint') {
                    new DB(storage_key).update({ sourceId: id }, [obj])

                }
                if (id === 'endpoint') {
                    let { data, sourceId, type } = res.data;
                    let _obj = {
                        data: data,
                        sourceId: sourceId,
                        type: type,
                        style: `left:${x}px,top:${y}px`,
                    }

                    new DB(storage_key).update({ sourceId: id }, [_obj])

                }
            }
        })
    }
}


export const chaining_storage = (first: SVGRectElement) => {

    const g = first.parentNode as SVGGElement;
    BoardStorage(g,EActionTypes.CHAINING)

}