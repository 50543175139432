import React, {
  FC,
  UIEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import classes from "./MappingList.module.scss";
import { endpoints } from "../../../api/endpoints";
import { request } from "../../../helpers/request";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/combineReducer";
import { handleScoreCardCriteriaData } from "../../../store/actions/actionsScoreCard";
import Modal from "../Modal/Modal";
import {
  ButtonTypes,
  EditorKeySources,
  messages,
  ModalNames,
  storageKeys,
} from "../../../settings/settings";
import { openModal, resetModal } from "../../../store/actions/actionsModal";

interface IMappingList {
  reload: boolean;
  scoreCardId?: number;
  scoreCardName?: string;
  scoreCardDescription?: string;
  prevMappedData?: Array<any>;
  emptyRowsIndices?: Array<number>;
  onMap?: Function;
  validate?: boolean;
  fullScreen: boolean;
}

const MappingList: FC<IMappingList> = ({
  reload,
  scoreCardId,
  scoreCardName,
  scoreCardDescription,
  prevMappedData,
  emptyRowsIndices,
  onMap = () => {},
  validate = false,
  fullScreen,
}) => {
  const dispatch = useDispatch();
  const {
    score_card: { score_card, criteria },
  } = endpoints;
  const {
    reducerScoreCard: {
      scoreCardCriteriaData: { scoreCardCriteriaData },
    },
    reducerModal: { modalState },
    reducerStrategy: {
      StrategyBoardElements: {
        ElementInput: { element_input },
      },
    },
  } = useSelector((state: RootState) => state);

  const criteriaSideRef = useRef<HTMLDivElement | null>(null);
  const criteriaBodyRef = useRef<HTMLDivElement | null>(null);
  const itemSideRef = useRef<HTMLDivElement | null>(null);
  const itemsBodyRef = useRef<HTMLDivElement | null>(null);

  const [prevScoreId, setPrevScoreId] = useState<number | undefined>();
  const [mapped, setMapped] = useState<Array<any>>([]);
  const [droppedItem, setDroppedItem] = useState<any | undefined>();
  const [confirmationIsShow, setConfirmationIsShow] = useState<boolean>(false);
  const [scroll, setScroll] = useState<boolean>(false);
  const [itemDetails, setItemDetails] = useState<any>();
  const [scoreCardObj, setScoreCardObj] = useState({
    name: "",
    description: "",
  });  

  const mapCriteria = useCallback(
    (criteria: Array<any>) => {
      if (criteria.length > 0) {
        let result: any = [];
        let isNull: boolean = false;

        criteria.forEach((criteria: any) => {
          const { name } = criteria;

          if (name === null) {
            result.push({ id: null, paramLabel: null, scLabel: null });
            isNull = true;
          } else {
            result.push({ id: "", paramLabel: "", scLabel: name });
          }
        });

        if (!isNull) {
          setMapped([...result]);
          onMap([...result]);
        }
      }
    },
    [onMap]
  );

  // const mapItems = useCallback(
  //   (mapIndex: number, item: string) => {
  //     const newState = [...mapped];
  //     newState[mapIndex]["paramLabel"] = item;
  //     setMapped([...newState]);
  //     onMap([...newState]);
  //   },
  //   [mapped, onMap]
  // );
  const mapItems = useCallback(
    (mapIndex: number, item: string, dataObj: any = null) => {
      const newState = [...mapped];
      newState[mapIndex]["paramLabel"] = item;
      if (dataObj) {
        const id = dataObj.id ?? "";
        if (id) newState[mapIndex]["id"] = id;
      }
      setMapped([...newState]);
      onMap([...newState]);
    },
    [mapped, onMap]
  );
  const checkItemAvailabilityReload = useCallback(
    (newMapping: any, response: any) => {
      const newState = [...mapped];

      newMapping?.forEach(({ index, obj }: any) => {
        newState[index]["id"] = obj.id;
        newState[index]["scLabel"] = response[index]["name"];
        newState[index]["paramLabel"] = obj.key;
      });
      if (response.length > newState.length) {
        for (let i = 0; i < response.length; i++) {
          if (!newState[i]) {
            newState.push({
              id: "",
              scLabel: response[i]["name"],
              paramLabel: "",
            });
          }
        }
      }
      setMapped([...newState]);
      onMap([...newState]);
    },
    [mapped, onMap]
  );
  const getMappedItemsFromStorage = useCallback(
    (prevData: any, response: any) => {
      if (!element_input) return;
      const boardElementsJSON = localStorage.getItem(storageKeys.boardElements);
      if (!boardElementsJSON) return;
      const boardElementsArray = [...JSON.parse(boardElementsJSON)];
      const currentElement = boardElementsArray.find(
        (item) => item.sourceId === element_input
      );
      if (!currentElement) return;
      const {
        data: { mappData },
      } = currentElement;
      const newMapping = [] as any;
      if (mappData.length > 0) {
        mappData.forEach((item: any, i: number) => {
          const { id, paramLabel } = item;
          if (!id) return;
          const getMappedElement = boardElementsArray.find(
            (item) => item.sourceId === id
          );
          if (!getMappedElement) return;
          const {
            data: { label },
          } = getMappedElement;

          if (paramLabel.includes(".")) {
            const [, subItem] = paramLabel.split(".");
            if (!subItem) return;
            const newLabel = `${label}.${subItem}`;
            newMapping.push({
              index: i,
              obj: { id: id, key: newLabel, source: EditorKeySources.VARIABLE },
            });
            // copyOFMapped.push({
            //   ...item,
            //   paramLabel:newLabel,
            //   scLabel:prevData[i].scLabel
            // })
          } else {
            newMapping.push({ index: i, obj: { id: id, key: label } });
            // copyOFMapped.push({
            //   ...item,
            //   paramLabel:label,
            //   scLabel:prevData[i].scLabel
            // })
          }
        });
      }
      const copyOFMapped = [] as any;

      if (prevData.length > 0) {
        prevData.forEach((item: any, i: number) => {
          copyOFMapped.push({
            ...item,
            id: item.id ?? "",
            paramLabel: item.paramLabel ?? "",
          });
        });
      }
      if (newMapping.length > 0 && scoreCardCriteriaData === null) {
        checkItemAvailabilityReload(newMapping, response);
      }
    },
    [checkItemAvailabilityReload, element_input, scoreCardCriteriaData]
  );

  const reloadCriteria = useCallback(
    (scoreId: number | undefined) => {
      function modifyFetchedCriteriaData(
        rawCriteria: Array<any>,
        scoreCardId: number
      ) {
        if (rawCriteria.length > 0) {
          let modifiedData: Array<any> = [];

          rawCriteria.forEach((rawCriteria: any) => {
            const modifiedCriteria = { ...rawCriteria };
            modifiedCriteria["scoreCard_id"] = scoreCardId;
            modifiedData.push(modifiedCriteria);
          });

          return modifiedData;
        } else {
          return rawCriteria;
        }
      }

      function fetchCriteria(scoreId: number) {
        request
          .get(criteria.getAllWithScoreId(scoreId))
          .then((res: any) => {
            const { success, response } = res;

            if (success) {
              if (response.length > 0) {
                mapCriteria([...response]);
                if (prevMappedData && prevMappedData.length === 0) return;
                const [first] = prevMappedData as any[];
                if (!first.id) return;
                getMappedItemsFromStorage(prevMappedData, response);
              } else {
                mapCriteria([{ name: null }]);
              }

              dispatch(
                handleScoreCardCriteriaData({
                  scoreCardCriteriaData:
                    scoreCardCriteriaData?.length > 0
                      ? [
                          ...scoreCardCriteriaData,
                          ...modifyFetchedCriteriaData(response, scoreId),
                        ]
                      : [...modifyFetchedCriteriaData(response, scoreId)],
                })
              );
            } else {
              console.warn("Get criteria with score id success false ", res);
            }
          })
          .catch((err: any) => {
            console.error("Get criteria with score id error ", err);
          });
      }

      if (reload && scoreId && scoreCardCriteriaData === null) {
        fetchCriteria(scoreId);
      }
      setPrevScoreId(scoreId);
    },
    [
      criteria,
      dispatch,
      getMappedItemsFromStorage,
      mapCriteria,
      prevMappedData,
      reload,
      scoreCardCriteriaData,
    ]
  );

  const getCriteria = useCallback(
    (scoreId: number | undefined) => {
      function modifyFetchedCriteriaData(
        rawCriteria: Array<any>,
        scoreCardId: number
      ) {
        if (rawCriteria.length > 0) {
          let modifiedData: Array<any> = [];

          rawCriteria.forEach((rawCriteria: any) => {
            const modifiedCriteria = { ...rawCriteria };
            modifiedCriteria["scoreCard_id"] = scoreCardId;
            modifiedData.push(modifiedCriteria);
          });

          return modifiedData;
        } else {
          return rawCriteria;
        }
      }

      function filterCriteriaByScoreId(
        criteriaData: Array<any>,
        scoreId: number
      ) {
        return criteriaData.filter((criteria: any) => {
          const { scoreCard_id } = criteria;
          return scoreCard_id === scoreId;
        });
      }

      function fetchCriteria(scoreId: number) {
        request
          .get(criteria.getAllWithScoreId(scoreId))
          .then((res: any) => {
            const { success, response } = res;

            if (success) {
              response.length > 0
                ? mapCriteria([...response])
                : mapCriteria([{ name: null }]);
              dispatch(
                handleScoreCardCriteriaData({
                  scoreCardCriteriaData:
                    scoreCardCriteriaData?.length > 0
                      ? [
                          ...scoreCardCriteriaData,
                          ...modifyFetchedCriteriaData(response, scoreId),
                        ]
                      : [...modifyFetchedCriteriaData(response, scoreId)],
                })
              );
            } else {
              console.warn("Get criteria with score id success false ", res);
            }
          })
          .catch((err: any) => {
            console.error("Get criteria with score id error ", err);
          });
      }

      if (scoreId && scoreCardCriteriaData === null) {
        fetchCriteria(scoreId);
      } else if (scoreId && scoreCardCriteriaData !== null) {
        const filterResult = filterCriteriaByScoreId(
          scoreCardCriteriaData,
          scoreId
        );

        if (filterResult.length > 0) {
          mapCriteria([...filterResult]);
        } else {
          fetchCriteria(scoreId);
        }
      }
      setPrevScoreId(scoreId);
    },
    [dispatch, criteria, scoreCardCriteriaData, mapCriteria]
  );

  const openWarningModal = useCallback(() => {
    dispatch(
      openModal({
        modalState: {
          name: ModalNames.MAPPING_LIST,
          title: messages.titleAttention,
          question: messages.titleDuplicate("mapping variable"),
          message: messages.messageDuplicatedMappingVariable,
          buttonType: ButtonTypes.WARNING,
          buttonMessage: messages.titleUnderstand,
          visible: true,
        },
      })
    );
  }, [dispatch]);

  const onModalAction = useCallback(() => {
    dispatch(resetModal());
  }, [dispatch]);

  const checkItemAvailability = useCallback(
    (rowId: number, draggingObj: any) => {
      let item =
        draggingObj.source === EditorKeySources.INPUT
          ? `$UserData.${draggingObj.key}`
          : draggingObj.key;

      const hasSameVariable = mapped.find((mapItem: any) => {
        return mapItem.paramLabel === item;
      });

      if (hasSameVariable) {
        openWarningModal();
        return;
      }

      if (!mapped[rowId]["paramLabel"]) {
        mapItems(rowId, item, draggingObj);
        return;
      }
      setItemDetails(draggingObj);
      setDroppedItem({ rowId, item });
      setConfirmationIsShow(true);
    },
    [openWarningModal, mapped, mapItems]
  );

  const generateCriteriaRow = useCallback(
    (mapped: Array<any>) => {
      function handleDragEnter(event: any) {
        event.preventDefault();
        const { target } = event;
        const itemsParentElement = itemsBodyRef.current;

        if (target && itemsParentElement) {
          target.classList.add(classes.Drop);
          const itemRowElement = itemsParentElement.children[
            target.id
          ] as HTMLDivElement;

          itemRowElement?.classList.add(classes.Drop);
        }
      }

      function handleDragOver(event: any) {
        event.preventDefault();
        const { target } = event;
        const itemsParentElement = itemsBodyRef.current;

        if (
          target &&
          itemsParentElement &&
          !target.classList.contains(classes.Drop)
        ) {
          target.classList.add(classes.Drop);
          const itemRowElement = itemsParentElement.children[
            target.id
          ] as HTMLDivElement;

          itemRowElement?.classList.add(classes.Drop);
        }
      }

      function handleDragLeave(event: any) {
        const { target } = event;
        const itemsParentElement = itemsBodyRef.current;

        if (target && itemsParentElement) {
          target.classList.remove(classes.Drop);
          const itemRowElement = itemsParentElement.children[
            target.id
          ] as HTMLDivElement;

          itemRowElement?.classList.remove(classes.Drop);
        }
      }

      function handleDrop(event: any) {
        event.preventDefault();
        const { target } = event;
        const itemsParentElement = itemsBodyRef.current;
        const draggingData = event.dataTransfer.getData("dragging_source");
        const draggingVariable =
          event.dataTransfer.getData("dragging_variable");
        const draggingMap = event.dataTransfer.getData("dragging_map");

        if (!draggingData && !draggingVariable && !draggingMap) return;

        const draggingObj = JSON.parse(
          draggingData || draggingVariable || draggingMap
        );

        // let key =
        //   draggingObj.source === EditorKeySources.INPUT
        //     ? `$UserData.${draggingObj.key}`
        //     : draggingObj.key;

        if (target && itemsParentElement) {
          checkItemAvailability(target.id, draggingObj);
          target.classList.remove(classes.Drop);
          const itemRowElement = itemsParentElement.children[
            target.id
          ] as HTMLDivElement;

          itemRowElement?.classList.remove(classes.Drop, classes.EmptyWarn);
        }
      }

      if (mapped.length > 0) {
        return mapped.map((mapElement: any, index: number) => {
          const { scLabel } = mapElement;

          return scLabel !== null ? (
            <div
              key={index}
              id={`${index}`}
              className={classes.Row}
              title={scLabel}
              onDragEnter={handleDragEnter}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              {scLabel}
            </div>
          ) : (
            <div key={index} className={classes.Row}>
              No data to display
            </div>
          );
        });
      } else {
        return null;
      }
    },
    [checkItemAvailability]
  );

  const generateItemsRow = useCallback(
    (mapped: Array<any>) => {
      function handleDragEnter(event: any) {
        const { target } = event;
        const criteriaParenElement = criteriaBodyRef.current;

        if (target && criteriaParenElement) {
          target.classList.add(classes.Drop);
          const itemRowElement = criteriaParenElement.children[
            target.id
          ] as HTMLDivElement;

          itemRowElement?.classList.add(classes.Drop);
        }
      }

      function handleDragOver(event: any) {
        event.preventDefault();
        const { target } = event;
        const criteriaParenElement = criteriaBodyRef.current;

        if (
          target &&
          criteriaParenElement &&
          !target.classList.contains(classes.Drop)
        ) {
          target.classList.add(classes.Drop);
          const itemRowElement = criteriaParenElement.children[
            target.id
          ] as HTMLDivElement;

          itemRowElement?.classList.add(classes.Drop);
        }
      }

      function handleDragLeave(event: any) {
        const { target } = event;
        const criteriaParenElement = criteriaBodyRef.current;

        if (target && criteriaParenElement) {
          target.classList.remove(classes.Drop);
          const itemRowElement = criteriaParenElement.children[
            target.id
          ] as HTMLDivElement;

          itemRowElement?.classList.remove(classes.Drop);
        }
      }
      function handleDrop(event: any) {
        event.preventDefault();
        const { target } = event;
        const criteriaParenElement = criteriaBodyRef.current;
        const draggingData = event.dataTransfer.getData("dragging_source");
        const draggingVariable =
          event.dataTransfer.getData("dragging_variable");

        const draggingMap = event.dataTransfer.getData("dragging_map");

        if (!draggingData && !draggingVariable && !draggingMap) return;

        const draggingObj = JSON.parse(
          draggingData || draggingVariable || draggingMap
        );

        // let key =
        //   draggingObj.source === EditorKeySources.INPUT
        //     ? `$UserData.${draggingObj.key}`
        //     : draggingObj.key;

        if (target && criteriaParenElement) {
          checkItemAvailability(target.id, draggingObj);
          target.classList.remove(classes.Drop, classes.EmptyWarn);
          const itemRowElement = criteriaParenElement.children[
            target.id
          ] as HTMLDivElement;

          itemRowElement?.classList.remove(classes.Drop);
        }
      }

      if (mapped.length > 0) {
        return mapped.map((mapElement: any, index: number) => {
          const { paramLabel } = mapElement;

          return (
            paramLabel !== null &&
            paramLabel !== undefined && (
              <div
                key={index}
                id={`${index}`}
                className={classes.Row}
                title={
                  paramLabel === "" ? "Must be filled with item" : paramLabel
                }
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                {paramLabel.replace("$UserData.", "")}
              </div>
            )
          );
        });
      } else {
        return null;
      }
    },
    [checkItemAvailability]
  );

  const showEmptyRows = useCallback((indices: Array<number>) => {
    const { current } = itemsBodyRef;

    if (current) {
      indices.forEach((index: number) => {
        const emptyField = current.children[index] as HTMLDivElement;
        emptyField.classList.add(classes.EmptyWarn);
      });
    }
  }, []);

  const validateComponent = useCallback(() => {
    const criteriaSideElement = criteriaSideRef?.current;
    const itemSideElement = itemSideRef?.current;
    if (!validate) return;

    if (mapped.length) {
      criteriaSideElement?.classList.remove(classes.Validate);
      itemSideElement?.classList.remove(classes.Validate);
    } else {
      criteriaSideElement?.classList.add(classes.Validate);
      itemSideElement?.classList.add(classes.Validate);
    }

    emptyRowsIndices?.length && showEmptyRows(emptyRowsIndices);
  }, [validate, mapped, emptyRowsIndices, showEmptyRows]);

  const fetchScoreCard = useCallback(() => {
    if (scoreCardName && prevMappedData) {
      return;
    }

    request.get(score_card.get()).then((res) => {
      const { response } = res;

      const currentScoreCard = response.filter(({ id }: { id: number }) => {
        return id === scoreCardId;
      });

      if (currentScoreCard.length) {
        setScoreCardObj({
          name: currentScoreCard[0].name,
          description: currentScoreCard[0].description,
        });
      }
    });
  }, [score_card, scoreCardId, scoreCardName, prevMappedData]);

  useLayoutEffect(() => {
    if (
      scoreCardId !== prevScoreId &&
      (!prevMappedData || prevMappedData?.length === 0)
    ) {
      getCriteria(scoreCardId);
    } else if (prevMappedData && prevMappedData.length > 0) {
      setMapped([...prevMappedData]);
    }
  }, [scoreCardId, prevScoreId, prevMappedData, getCriteria]);

  useEffect(() => {
    if (reload && scoreCardId) {
      reloadCriteria(scoreCardId);
    }
  }, [reloadCriteria, reload, scoreCardId]);
  useEffect(validateComponent, [validateComponent, mapped, emptyRowsIndices]);

  useEffect(fetchScoreCard, [fetchScoreCard]);

  const handleConfirmationModalResponse = (response: boolean) => {
    if (response) {
      const { rowId, item } = droppedItem;
      if (itemDetails) mapItems(rowId, item, itemDetails);
      mapItems(rowId, item);
      setConfirmationIsShow(false);
    } else {
      setDroppedItem(undefined);
      setConfirmationIsShow(false);
    }
  };
  const scrollLeftHandler = (e: UIEvent) => {
    if (scroll) {
      setScroll(false);
      return;
    }
    const rightPanel = itemsBodyRef.current as HTMLElement;
    const current = e.target as HTMLElement;
    if (rightPanel) {
      setScroll(true);
      rightPanel.scrollTop = current.scrollTop;
    }
  };
  const scrollRightHandler = (e: UIEvent) => {
    if (scroll) {
      setScroll(false);
      return;
    }
    const leftPanel = criteriaBodyRef.current as HTMLElement;
    const current = e.target as HTMLElement;
    if (leftPanel) {
      setScroll(true);
      leftPanel.scrollTop = current.scrollTop;
    }
  };

  return (
    <div
      className={
        fullScreen
          ? [classes.MappingListWrapper, classes.FullScreen].join(" ")
          : classes.MappingListWrapper
      }
    >
      <div ref={criteriaSideRef} className={classes.CriteriaSide}>
        <div className={classes.ListHeader}>
          <span
            className={classes.ListTitle}
            title={scoreCardDescription || scoreCardObj.description}
          >
            {(scoreCardName || scoreCardObj.name) || "score card criterias"}
          </span>
        </div>
        <div
          ref={criteriaBodyRef}
          className={classes.ListBody}
          onScroll={scrollLeftHandler}
        >
          {generateCriteriaRow(mapped)}
        </div>
      </div>
      <div ref={itemSideRef} className={classes.ItemsSide}>
        <div className={classes.ListHeader}>
          <span className={classes.ListTitle}>mapped items</span>
        </div>
        <div
          ref={itemsBodyRef}
          className={classes.ListBody}
          onScroll={scrollRightHandler}
        >
          {generateItemsRow(mapped)}
        </div>
      </div>
      {confirmationIsShow && (
        <div className={classes.ConfirmationModalBacker}>
          <div className={classes.ConfirmationModal}>
            <span className={classes.ConfirmationModalTitle}>
              Are you sure to change mapped item?
            </span>
            <div className={classes.ConfirmationModalActions}>
              <button onClick={() => handleConfirmationModalResponse(true)}>
                Change
              </button>
              <button onClick={() => handleConfirmationModalResponse(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      <Modal
        title={modalState?.title}
        question={modalState?.question}
        message={modalState?.message}
        buttonMessage={modalState?.buttonMessage}
        buttonType={modalState?.buttonType}
        onAction={onModalAction}
        visible={modalState?.visible}
        name={ModalNames.MAPPING_LIST}
      />
    </div>
  );
};

export default MappingList;
