import {
  ActionsTypes,
  User,
  Path,
  LOGIN,
  PATHNAME,
  Product,
  Modal,
  PRODUCTS,
  MODAL,
  Submit,
  SUBMITDATA,
  Checkbox,
  CHECKBOX,
  Refresh,
  REFRESH,
  SelectAll,
  SELECTALL,
  TOOLTIP,
  
  Tooltip,
  TouchBlocked,
  TOUCHBLOCKED,
  ActiveEvent,
  ACTIVEEVENT,
  CancelHandler,
  CANCELHANDLER,
  SaveHandler,
  SAVEHANDLER,
  ActiveAttrEvent,
  ACTIVEATTREVENT,
  ActiveAttribute,
  ACTIVEATTRIBUTE,
  AttributeBlocked,
  ATTRIBUTEBLOCKED,
  ActiveProduct,
  ACTIVEPRODUCT,
  ActiveRowArchive,
  ACTIVEROWARCHIVE,
  CheckedStatus,
  CHECKEDSTATUS,
  ConfirmTooltip,
  CONFIRMTOOLTIP,
  EmptyValueBlocked,
  EMPTYVALUEBLOCKED,
  ShowEmptyField,
  SHOWEMPTYFIELD,
  EmptyFieldStatus,
  EMPTYFIELDSTATUS,
  ActiveSection,
  ACTIVESECTION,
  MainTooltip,
  MAINTOOLTIP

} from "../types/types";

// export function addLogin(data: User): ActionsTypes {
//   return {
//     type: CREATE_LOGIN_REQUEST,
//     payload: {data}
//   };
// }
export function addLogin(user: User): ActionsTypes {
  return {
    type: LOGIN,
    payload: {
      user
    }
  };
}

export function setPath(pathname: Path): ActionsTypes {
  return {
    type: PATHNAME,
    payload: {
      pathname
    }
  };
}

export function activeModal(activeModal: Modal): ActionsTypes {
  return {
    type: MODAL,
    payload: {
      activeModal
    }
  };
}

export function addProduct(products: Product): ActionsTypes {
  return {
    type: PRODUCTS,
    payload: {
      products
    }
  };
}

export function submitData(submitData: Submit): ActionsTypes {
  return {
    type: SUBMITDATA,
    payload: {
      submitData
    }
  };
}

export function addCheckbox(checkboxList: Checkbox): ActionsTypes {
  return {
    type: CHECKBOX,
    payload: {
      checkboxList
    }
  };
}
export function refreshFetch(refresh: Refresh): ActionsTypes {
  return {
    type: REFRESH,
    payload: {
      refresh
    }
  };
}

export function selectAll(selectAll: SelectAll): ActionsTypes {
  return {
    type: SELECTALL,
    payload: {
      selectAll
    }
  };
}

export function tooltipActive(value: Tooltip): ActionsTypes {
  return {
    type: TOOLTIP,
    payload: {
      value
    }
  };
}



export function touchBlocked(value: TouchBlocked): ActionsTypes {
  return {
    type: TOUCHBLOCKED,
    payload: {touchBlocked:value}
  };
}
export function activeEvent(activeEvent: ActiveEvent): ActionsTypes {
  return {
    type: ACTIVEEVENT,
    payload: {activeEvent:activeEvent}
  };
}
export function cancelHandler(cancelHandler: CancelHandler): ActionsTypes {
  return {
    type: CANCELHANDLER,
    payload: {cancelHandler:cancelHandler}
  };
}
export function saveHandler(saveHandler: SaveHandler): ActionsTypes {
  return {
    type: SAVEHANDLER,
    payload: {saveHandler:saveHandler}
  };
}

export function activeAttrEvent(activeEvent: ActiveAttrEvent): ActionsTypes {
  return {
    type: ACTIVEATTREVENT,
    payload: {activeEvent:activeEvent}
  };
}


export function activeAttribute(activeAttribute: ActiveAttribute): ActionsTypes {
  return {
    type: ACTIVEATTRIBUTE,
    payload: {activeAttribute:activeAttribute}
  };
}
export function attributeBlocked(value: AttributeBlocked): ActionsTypes {
  return {
    type: ATTRIBUTEBLOCKED,
    payload: {attributeBlocked:value}
  };
}

export function activeProduct(activeProduct: ActiveProduct): ActionsTypes {
  return {
    type: ACTIVEPRODUCT,
    payload: {activeProduct:activeProduct}
  };
}
export function activeRowArchive(activeRowArchive: ActiveRowArchive): ActionsTypes {
  return {
    type: ACTIVEROWARCHIVE,
    payload: {activeRowArchive:activeRowArchive}
  };
}
export function checkedStatus(checkedStatus: CheckedStatus): ActionsTypes {
  return {
    type: CHECKEDSTATUS,
    payload: {checkedStatus:checkedStatus}
  };
}
export function confirmTooltip(confirmTooltip: ConfirmTooltip): ActionsTypes {
  return {
    type: CONFIRMTOOLTIP,
    payload: {confirmTooltip:confirmTooltip}
  };
}
//E
export function handleActiveTooltip(tooltipProps: MainTooltip): ActionsTypes {
  return {
    type: MAINTOOLTIP,
    payload: { tooltipProps }
  };
}
//END E

export function emptyValueBlocked(emptyValueBlocked: EmptyValueBlocked): ActionsTypes {
  return {
    type: EMPTYVALUEBLOCKED,
    payload: {emptyValueBlocked:emptyValueBlocked}
  };
}
export function showEmptyField(showEmptyField: ShowEmptyField): ActionsTypes {
  return {
    type: SHOWEMPTYFIELD,
    payload: {showEmptyField:showEmptyField}
  };
}
export function emptyFieldStatus(emptyFieldStatus: EmptyFieldStatus): ActionsTypes {
  return {
    type: EMPTYFIELDSTATUS,
    payload: {emptyFieldStatus:emptyFieldStatus}
  };
}
export function activeSection(activeSection:ActiveSection):ActionsTypes {
  return {
    type: ACTIVESECTION,
    payload: {
      activeSection
    }
  };
}