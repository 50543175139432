import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  WheelEvent,
} from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import classes from "./StrategyBoard.module.scss";
import { useSelector, useDispatch } from "react-redux";
import StrategyBoardNavigation from "./Navigation/StrategyBoardNaviagtion";
import StrategyBoardSidePanel from "./SidePanel/StrategyBoardSidePanel";
// import StrategyBoardMinimap from "./Minimap/StrategyBoardMinimap";
import StrategyBoardFooter from "./Footer/StrategyBoardFooter";
import { RootState } from "../../../store/combineReducer";
import {
  handleSourceDataAvailability,
  handleSourceDataTypes,
  handleStrategyApprovedByStatus,
  handleStrategyApproveFields,
  handleStrategyBoardData,
  handleStrategyBoardZoom,
  handleStrategyFetchStatus,
  handleStrategySourceDataStatus,
} from "../../../store/actions/actionsStrategy";
import Board from "./FlowChart/Board";
import Panel from "./FlowChart/SidePanel/Panel";
import { request } from "../../../helpers/request";
import { endpoints } from "../../../api/endpoints";
import panZoom from "./handler/panZoom";
import DB from "../../../layout/DB/Storage";
// import Backdrop from "../../../components/UI/Backdrop/Backdrop";
// import Spinner from "../../../components/UI/Spinner/Spinner";
import { storageKeys, messages } from "../../../settings/settings";
import { reformatSourceData } from "../../../helpers/reformatSourceData";
import {
  IFetchedGroups,
  reformatStrategyFunctions,
} from "../../../helpers/reformatStrategyFunctions";
import TestPanel from "./Mode/Test/TestPanel";
import { ModalLoader } from "components/UI/Modal/ModalLoader";
import { activeModal } from "store/actions/action";
let firstRender = true;
let instance: any;

const StrategyBoard: FC = () => {
  const [mode,setMode] = useState<string>('strategy');
  const dispatch = useDispatch();
  const boardRef = useRef<HTMLDivElement | null>(null);
  const history = useHistory();
 

  const {
    reducer,
    reducerStrategy: {
      StrategyBoardZoom: { minZoom, maxZoom, zoom },
      StrategyApproveReducer,
      StrategyApproveReducer: { strategy_name, build, id, strategy, version },
      StrategyBoardElements: {
        ElementPanel: { element_panel },
        ElementInput: { element_input },
        CheckOutputStatus:{status:output_status,showNotification}
      },
      StrategyBoardSidePanel: {
        Content: { content_value },
      },
      SourceDataAvailability,
      SourceDataTypes,
      // StrategyFetchStatus,
    },
  } = useSelector((state: RootState) => state);
  const {
    strategy_designer: {
      // strategy,
      strategy_source_data,
      strategy_source_data_type,
    },
    function: { function_groups, function_data },
  } = endpoints;

  const preventWindowMouseWheel = useCallback(() => {
    document.addEventListener(
      "wheel",
      (event: any) => {
        const { ctrlKey, metaKey } = event;
        if (ctrlKey || metaKey) {
          event.preventDefault();
        }
      },
      { passive: false }
    );
  }, []);

  const beforeLoadCallback = useCallback(() => {
    const localCredentials = localStorage.getItem(storageKeys.credentials);
    const localUserDetails = localStorage.getItem(storageKeys.userDetails);
    const localActiveStrategy = localStorage.getItem(
      storageKeys.activeStrategy
      );
      
      if (localCredentials && localUserDetails && localActiveStrategy) {

      sessionStorage.setItem(storageKeys.credentials, localCredentials);
      sessionStorage.setItem(storageKeys.userDetails, localUserDetails);
      sessionStorage.setItem(storageKeys.activeStrategy, localActiveStrategy);

      localStorage.removeItem(storageKeys.credentials);
      localStorage.removeItem(storageKeys.userDetails);
      localStorage.removeItem(storageKeys.activeStrategy);
    }
  }, []);

  const getStrategySourceData = useCallback(() => {
    const activeStrategy = sessionStorage.getItem(storageKeys.activeStrategy);
    const { status } = SourceDataAvailability;

    function addToSession(response: any) {
      const { id, strategy_source_data_type, source_data } = response[
        response.length - 1
      ];

      sessionStorage.setItem(
        storageKeys.activeStrategySourceData,
        JSON.stringify({
          id,
          type: strategy_source_data_type,
          data: source_data,
        })
      );

      reformatSourceData({ sourceData: source_data });
      dispatch(handleSourceDataAvailability({ status: true }));
    }

    if (activeStrategy && !status) {
      const { id } = JSON.parse(activeStrategy);
      dispatch(handleStrategySourceDataStatus({ status: true }));

      request
        .get(strategy_source_data.get(id))
        .then((res) => {
          const { success, response } = res;
          if (success && response.length) {
            addToSession(response);
          } else {
            console.warn(response.message);
          }
        })
        .catch((err) => {
          console.error(messages.messageApiGetError, err);
        });
    }
  }, [strategy_source_data, dispatch, SourceDataAvailability]);

  const getStrategySourceDataTypes = useCallback(() => {
    const { data } = SourceDataTypes;

    if (data === null) {
      request
        .get(strategy_source_data_type.getAll)
        .then((res) => {
          const { success, response } = res;
          if (success) {
            dispatch(handleSourceDataTypes({ data: response }));
          } else {
            console.error(
              "Get strategy source data types success false :",
              res
            );
          }
        })
        .catch((error) => {
          console.error("Get strategy source data types error :", error);
        });
    }
  }, [dispatch, strategy_source_data_type.getAll, SourceDataTypes]);

  const getUserStrategyData = useCallback(() => {

    const activeStrategyJSON = sessionStorage.getItem(
      storageKeys.activeStrategy
    
    );
    const boardElementsJSON = localStorage.getItem(storageKeys.boardElements);
    dispatch(handleStrategyFetchStatus({ status: true }));

    if (activeStrategyJSON && !boardElementsJSON) {
      const { id } = JSON.parse(activeStrategyJSON);
   
      request
        .get(endpoints.strategy_designer.strategy.get(id))
        .then((res) => {
          const { success, response } = res;
          if (success) {
            const { strategy_data } = response;

            if (strategy_data.length) {
             
              localStorage.setItem(
                storageKeys.boardElements,
                JSON.stringify(strategy_data)
              );
              dispatch(handleStrategyFetchStatus({ status: false }));
              dispatch(
                handleStrategyBoardData({
                  data: [JSON.stringify(strategy_data)],
                })
              );
            }
          } else {
            console.error(response?.message);
          }
        })
        .catch((error) => {
          console.error(messages.messageApiGetError, error);
        });
    } else if (activeStrategyJSON && boardElementsJSON) {
    
      dispatch(handleStrategyFetchStatus({ status: false }));
    }
  }, [dispatch]);

  const getStrategyFunctions = useCallback(
    (functionGroups: Array<IFetchedGroups>) => {
      request
        .get(function_data.getAll)
        .then((result) => {
          const { success, response } = result;
          if (!success) {
            console.warn(messages.messageApiGetStatusFalse);
            return;
          }

          reformatStrategyFunctions({ functionGroups, functions: response });
        })
        .catch((error) => {
          console.error(messages.messageApiGetError, error);
        });
    },
    [function_data]
  );

  const getStrategyFunctionGroups = useCallback(() => {
    request
      .get(function_groups.getAll)
      .then((res) => {
        const { success, response } = res;

        if (!success) {
          console.warn(messages.messageApiGetStatusFalse);
          return;
        }

        getStrategyFunctions(response);
      })
      .catch((err) => {
        console.error(messages.messageApiGetError, err);
      });
  }, [function_groups, getStrategyFunctions]);

  const getPrevCSS = useCallback(() => {
    const boardTransformationJSON = sessionStorage.getItem(
      storageKeys.boardTransformation
    );
    const boardElement = boardRef.current;
    if (firstRender && boardElement && boardTransformationJSON) {
      const transformation = JSON.parse(boardTransformationJSON);
      const {
        scale,
        translateX,
        translateY,
        originX,
        originY,
      } = transformation;
      instance = panZoom({
        minScale: minZoom,
        maxScale: maxZoom,
        element: boardElement,
        scaleSensitivity: 50,
        transformation,
      });
      boardElement.style.transform = `matrix(${scale}, 0, 0, ${scale}, ${translateX}, ${translateY})`;
      boardElement.style.transformOrigin = `${originX}px ${originY}px`;
      dispatch(
        handleStrategyBoardZoom({
          minZoom,
          maxZoom,
          zoom: scale,
        })
      );
    } else if (firstRender && boardElement && !boardTransformationJSON) {
      // const { offsetWidth, offsetHeight } = boardElement;
      // const newTranslateX = -(offsetWidth / 100) * 30;
      // const newTranslateY = -(offsetHeight / 100) * 33;
      const newTranslateX = 0;
      const newTranslateY = 0;
      instance = panZoom({
        minScale: minZoom,
        maxScale: maxZoom,
        element: boardElement,
        scaleSensitivity: 50,
        transformation: {
          scale: zoom,
          originX: 0,
          originY: 0,
          translateX: newTranslateX,
          translateY: newTranslateY,
        },
      });
      boardElement.style.transform = `matrix(${zoom}, 0, 0, ${zoom}, ${newTranslateX}, ${newTranslateY})`;

      firstRender = false;
    } else if (!firstRender && boardElement && boardTransformationJSON) {
      const { scale, translateX, translateY, originX, originY } = JSON.parse(
        boardTransformationJSON
      );

      instance = panZoom({
        minScale: minZoom,
        maxScale: maxZoom,
        element: boardElement,
        scaleSensitivity: 50,
        transformation: {
          scale,
          originX,
          originY,
          translateX,
          translateY,
        },
      });
    }
  }, [dispatch, minZoom, maxZoom, zoom]);


  useLayoutEffect(()=>{
    const {search} = history.location;
    const SearchParams = new URLSearchParams(search);
    const modeValue = SearchParams.get('mode');
    if(modeValue === 'strategy'){
      
    }
    if(modeValue){
      setMode(modeValue)
    }
  },[history.location])

  const handleMouseWheel = useCallback((event: WheelEvent) => {
    const { ctrlKey, metaKey, deltaY, pageX, pageY } = event;
    if (!ctrlKey && !metaKey) return;
    const newZoom = instance.zoom({
      deltaScale: Math.sign(deltaY) > 0 ? -1 : 1,
      x: pageX,
      y: pageY,
    });

    sessionStorage.setItem(
      storageKeys.boardTransformation,
      JSON.stringify(instance.getTransformation())
    );
    dispatch(handleStrategyBoardZoom({ minZoom, maxZoom, zoom: newZoom }));
    new DB(storageKeys.boardElements)
      .fetch({ sourceId: "endpoint" })
      .then((res: any) => {         

        if (res.status && res.data) {
          let obj = [
            {
              ...res.data,
              data: { ...res.data.overlays?.[0], zoom: newZoom },
            },
          ];
          new DB(storageKeys.boardElements)
            .update({ sourceId: "endpoint" }, obj)
            .then((result: any) => {
              const { status } = result;          
              if (!status)
                console.warn(messages.messageStorageUpdateStatusFalse);
            })
            .catch((error: any) => {
              console.error(messages.messageStorageUpdateError, error);
            });
        }
      });
  },[dispatch, maxZoom, minZoom]);
  useEffect(()=>{
    const event = { ctrlKey:true, metaKey:false, deltaY:125, pageX:655, pageY:209 } as any;
    handleMouseWheel(event)
  },[handleMouseWheel, history.location])
  useLayoutEffect(preventWindowMouseWheel, [preventWindowMouseWheel]);

  useLayoutEffect(beforeLoadCallback, [beforeLoadCallback]);

  useLayoutEffect(getUserStrategyData, [getUserStrategyData]);

  useLayoutEffect(getStrategyFunctionGroups, [getStrategyFunctionGroups]);

  useLayoutEffect(getPrevCSS, [getPrevCSS]);

  useEffect(() => {
    getStrategySourceData();
    getStrategySourceDataTypes();
  }, [getStrategySourceData, getStrategySourceDataTypes]);

  const handleMouseMove = (event: any) => {

    event.preventDefault();
    const { ctrlKey, metaKey, movementX, movementY } = event;
    if (!ctrlKey && !metaKey) return;

    instance.panBy({
      originX: movementX,
      originY: movementY,
    });

    sessionStorage.setItem(
      storageKeys.boardTransformation,
      JSON.stringify(instance.getTransformation())
    );
    
  };

  const handleMouseUp = (event: any) => {  
    
    event.preventDefault();
    const { target } = event;
    if (!target) return;
  
   const activeStrategyJSON = sessionStorage.getItem(
    storageKeys.activeStrategy
  );
  const boardElementsJSON = localStorage.getItem(storageKeys.boardElements);
  dispatch(handleStrategyFetchStatus({ status: true }));

  if (activeStrategyJSON && !boardElementsJSON) {
    const { id } = JSON.parse(activeStrategyJSON);

    request
      .get(endpoints.strategy_designer.strategy.get(id))
      .then((res) => {
        const { success, response } = res;
        if (success) {
          const { strategy_data } = response;
          if (strategy_data.length) {
            localStorage.setItem(
              storageKeys.boardElements,
              JSON.stringify(strategy_data)
            );
            dispatch(handleStrategyFetchStatus({ status: false }));
            dispatch(
              handleStrategyBoardData({
                data: [JSON.stringify(strategy_data)],
              })
            );
          }
        } else {
          console.error(response?.message);
        }
      })
      .catch((error) => {
        console.error(messages.messageApiGetError, error);
      });
  } else if (activeStrategyJSON && boardElementsJSON) {
    dispatch(handleStrategyFetchStatus({ status: false }));}

    target.style.cursor = "default";
    target.onmousemove = null;
    target.onmouseup = null;
    target.onmouseleave = null;
  };

  const handleMouseLeave = (event: any) => {
    event.preventDefault();
    const { target } = event;
    if (!target) return;

    target.style.cursor = "default";
    target.onmousemove = null;
    target.onmouseup = null;
    target.onmouseleave = null;
  };

  const handleMouseDown = (event: any) => {
    event.preventDefault();
    const { ctrlKey, metaKey, target } = event;

    if (!ctrlKey && !metaKey && !target) return;

    target.style.cursor = "move";
    target.onmousemove = handleMouseMove;
    target.onmouseup = handleMouseUp;
    target.onmouseleave = handleMouseLeave;
  };

 const checkOutput  = () => {
   if(output_status && !showNotification){
     return <ModalLoader spinner 
                messageTitle = "Checking Output..."
                messageBody = "Please wait"

            />
             

   }else if(showNotification){
    
    return <ModalLoader  
                messageTitle="OUTPUT is empty!"
                messageBody = "Please add elements"
           />
                
   }

 }

 const closeApproveModalHandler = () => {
  dispatch(activeModal({ activeModal: null }));
 }

 const confirmApproveModalHandler = () => {
  // console.log(StrategyApproveReducer);

  const { app_name, approved_by, auth_key, auth_token, destination_port } = StrategyApproveReducer;

  request
    .patch(endpoints.strategy_designer.strategy_approve.patch(strategy), {app_name, approved_by, auth_key, auth_token, destination_port})
    .then(res => {
   

      dispatch(handleStrategyApproveFields({
        strategy_name,
        app_name: res.response.app_name,
        approved_by: res.response.approved_by,
        auth_key: res.response.auth_key,
        auth_token,
        build,
        destination_port: res.response.destination_port,
        id,
        strategy,
        version,
      }));

      dispatch(handleStrategyApprovedByStatus({ approvedStatus: res.response.approved_by }));
      dispatch(activeModal({ activeModal: null }));
    })
    .catch(err => console.log("error: ", err));
 }

  return (
    <Switch>
      <Route path="/strategy-designer/:string"> 
        <section className={classes.StrategyBoardWrapper}>
        {reducer.activeModal.activeModal === "accept_strategy_approve" && (
          <section className={classes.ApproveModal}>
            <div className={classes.CloseIconDiv}>
              <div className={classes.CloseIconItself} onClick={closeApproveModalHandler}></div>
            </div>
            <div className={classes.MainSection}>
              <h1 className={classes.ModalTitle}>Are you sure?</h1>
              <p className={classes.ModalText}>Now you are engaged in serious activities. Be careful before checking this field. You won't be able to make changes again. Do you approve?</p>
              <div className={classes.ModalBtnGroup}>
                <button className={classes.NoButton} onClick={closeApproveModalHandler}>No</button>
                <button className={classes.YesButton} onClick={confirmApproveModalHandler}>Yes</button>
              </div>
            </div>
          </section>
        )}
          <StrategyBoardNavigation />
          {
            mode === 'strategy' 
            ?
            <>  
                {checkOutput()}
                <StrategyBoardSidePanel />
                {/* <StrategyBoardMinimap id={"StrategyBoard"} zoom={zoom} /> */}
                <StrategyBoardFooter />
                {element_panel && element_input && !content_value &&<Panel />}
                <div
                  className={classes.BoardViewport}
                  onWheel={handleMouseWheel}
                  onMouseDown={handleMouseDown}
                >
                  <div
                    ref={boardRef}
                    id="StrategyBoard"
                    className={classes.StrategyBoard}
                  >
                    <Board/>
                  </div>
                </div>
            </>
            
            : mode === 'test' 
            ? <TestPanel/>
            :null
          }
          
        </section>
      </Route>
    </Switch>
  );
};

export default StrategyBoard;
