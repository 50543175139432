import React, { Fragment, useCallback } from "react";
import { matrix_status } from "../../../../../settings/settings";

interface matrixProps {
  xyMatrix?: any;
  xySize?: any;
  handler: (
    e: any,
    x: any,
    y: any,
    label: string,
    coordX: number,
    coordY: number,
    cellID: number
  ) => void;
  data?: any;
  step?: any;
  export_status?: boolean;
  steps?: {
    vertical_steps: number;
    horizontal_steps: number;
  };
}
const MatrixSvg = (
  { xyMatrix, xySize, handler, data, step, export_status, steps }: matrixProps,
  props: any
) => {
  const matrix = xyMatrix;
  const matrixSize = xySize;

  const cellHandler = (
    e: any,
    x: any,
    y: any,
    label: string,
    coordX: number,
    coordY: number,
    cellID: number
  ) => {
    handler(e, x, y, label, coordX, coordY, cellID);
  };
  const reversePointHandler = useCallback((index, step, massive) => {
    const massive_length = [...massive]?.length;
    let new_index = massive_length - index;
    return (Number(step) * new_index).toFixed(0);
  }, []);
  return (
    <svg
      id="MatrixCoords"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: 263 + matrix.xMatrix(matrixSize.coords.x).length * 65 + "px",

        height:
          450 + (matrix.yMatrix(matrixSize.coords.y).length - 5) * 65 + "px",
      }}
      viewBox={
        export_status
          ? `-90 0 
                    ${263 + matrix.xMatrix(matrixSize.coords.x).length * 65}
                    ${
                      450 +
                      (matrix.yMatrix(matrixSize.coords.y).length - 5) * 65
                    }
                    `
          : `0 0 
                    ${
                      matrix.xMatrix(matrixSize.coords.x).length > 5
                        ? 450 +
                          (matrix.xMatrix(matrixSize.coords.x).length - 5) * 70
                        : 450
                    }
                    ${
                      450 +
                      (matrix.yMatrix(matrixSize.coords.y).length - 5) * 65
                    }
                    `
      }
    >
      {!export_status && (
        <>
          <g
            id="CoordX"
            transform={`translate(0 ${
              (matrix.yMatrix(matrixSize.coords.y).length - 5) * 65
            }) rotate(0)`}
          >
            <rect
              x="21.64"
              y="402"
              width={matrix.xMatrix(matrixSize.coords.x).length * 90}
              height="2"
              fill="#efefef"
            />
            <text
              x="21.64"
              y="425"
              fontFamily="ginger"
              fontSize="13px"
              fill="#B2BDCD"
            >
              Low Risk
            </text>
            <text
              x={
                matrix.xMatrix(matrixSize.coords.x).length >= 3
                  ? 425 +
                    (matrix.xMatrix(matrixSize.coords.x).length - 5) * 65 -
                    (21.64 + 21.64 / 2)
                  : 425 - (21.64 + 21.64 / 2)
              }
              y="425"
              fontFamily="ginger"
              fontSize="13px"
              fill="#B2BDCD"
            >
              High Risk
            </text>
          </g>
          <g
            id="xLine"
            transform={`translate(0 ${
              (matrix.yMatrix(matrixSize.coords.y).length - 5) * 65
            }) rotate(0)`}
          >
            {matrix
              .xMatrix(matrixSize.coords.x + 1)
              .map((xName: any, i: number) => {
                return (
                  <Fragment key={i}>
                    <rect
                      id={`x${i + 1}`}
                      x={72 + i * 64.5}
                      y="398"
                      width="1"
                      height="9"
                      fill="#efefef"
                    />
                    {export_status && (
                      <g>
                        <rect
                          id={`x${i + 1}`}
                          x={72 + i * 64.5}
                          y="398"
                          width="1"
                          height="9"
                          fill="#efefef"
                        />
                        <rect
                          id={`x${i + 1}`}
                          x={72 + i * 64.5 - 30}
                          y="430"
                          rx="8"
                          width="60"
                          height="40"
                          fill="#efefef"
                        />
                        <text
                          x={30 + (72 + i * 64.5 - 30)}
                          y={450}
                          fill="#102946"
                          fontSize="16px"
                          textAnchor="middle"
                          alignmentBaseline="middle"
                          pointerEvents="none"
                          fontFamily="ginger"
                        >
                          {reversePointHandler(
                            i,
                            steps?.horizontal_steps,
                            matrix.xMatrix(matrixSize.coords.x)
                          )}
                        </text>
                      </g>
                    )}
                  </Fragment>
                );
              })}
          </g>
          <g id="CoordY">
            <rect
              x="21.14"
              y="0"
              width="2"
              height={
                matrix.yMatrix(matrixSize.coords.y).length >= 3
                  ? 401 + (matrix.yMatrix(matrixSize.coords.y).length - 5) * 65
                  : 401
              }
              fill="#efefef"
            />
            <text
              x="21.14"
              y="30"
              transform="rotate(-90 30,48)"
              fontFamily="ginger"
              fontSize="13px"
              fill="#B2BDCD"
            >
              High Risk
            </text>
          </g>

          <g id="yLine">
            {matrix
              .yMatrix(matrixSize.coords.y + 1)
              .map((yName: any, i: number) => {
                return (
                  <Fragment key={i}>
                    <rect
                      id={`y${i + 1}`}
                      x="17"
                      y={30 + i * 64.5}
                      width="9"
                      height="1"
                      fill="#efefef"
                    />
                    {export_status && (
                      <g>
                        <rect
                          id={`y${i + 1}`}
                          rx="8"
                          x="-90"
                          y={30 + i * 64.5 - 20}
                          width="80"
                          height="40"
                          fill="#efefef"
                        />
                        <text
                          x="-50"
                          y={20 + (30 + i * 64.5 - 20)}
                          fill="#102946"
                          fontSize="16px"
                          textAnchor="middle"
                          alignmentBaseline="middle"
                          pointerEvents="none"
                          fontFamily="ginger"
                        >
                          {(Number(steps?.vertical_steps) * i).toFixed(0)}
                        </text>
                      </g>
                    )}
                  </Fragment>
                );
              })}
          </g>
        </>
      )}

      <g
        id="cells"
        transform={
          matrix.yMatrix(matrixSize.coords.y).length > 8
            ? `translate(-188 ${
                -85 + (matrix.yMatrix(matrixSize.coords.y).length - 5) * 65
              }) rotate(0)`
            : `translate(-188 ${
                -85 + (matrix.yMatrix(matrixSize.coords.y).length - 5) * 65
              }) rotate(0)`
        }
      >
        {/* ===CELL NAME Y=== */}

        {matrix.yMatrix(matrixSize.coords.y).map((yName: any, i: number) => {
          return (
            <g key={i} x="240" y={377 - i * 65}>
              <rect
                id="cellAA"
                x="240"
                y={377 - i * 65}
                width="20"
                height="60"
                rx="2.99"
                fill="transparent"
              />
              <text
                x="240"
                y={377 - i * 65 + 36}
                fontFamily="ginger"
                fontSize="15px"
                fill="#B2BDCD"
              >
                {yName.letter}
              </text>
            </g>
          );
        })}

        {/* ===END CELL NAME Y=== */}
        {/* ===CELL NAME X=== */}
        {matrix.xMatrix(matrixSize.coords.x).map((xName: any, i: number) => {
          return (
            <g key={i} x={263 + i * 65} y={442}>
              <rect
                id="cellAA"
                x={263 + i * 65}
                y={442}
                width="20"
                height="60"
                rx="2.99"
                fill="transparent"
              />
              <text
                x={263 + i * 65 + 25}
                y={462}
                fontFamily="ginger"
                fontSize="15px"
                fill="#B2BDCD"
              >
                {xName.letter}
              </text>
            </g>
          );
        })}

        {/* ===END CELL NAME X=== */}

        {matrix.yMatrix(matrixSize.coords.y).map((yName: any, y: number) => {
          return matrix
            .xMatrix(matrixSize.coords.x)
            .map((xName: any, x: number) => {
              let cell = data?.filter(
                (cell: any) => cell.label === yName.letter + xName.letter
              )[0];
              let cell_id = cell?.id;
              let cellColor = matrix_status.filter(
                (color: any) => color.id === cell?.status_id
              )[0];

              let fillAlgo =
                step === "final"
                  ? cellColor?.color
                  : x < matrix.xMatrix(matrixSize.coords.x).length - 1 &&
                    y === 0
                  ? "#04D439"
                  : x === 0 &&
                    y < matrix.yMatrix(matrixSize.coords.y).length - 1
                  ? "#04D439"
                  : x > 0 && y >= matrix.yMatrix(matrixSize.coords.y).length - 1
                  ? "#FF4037"
                  : x >= matrix.xMatrix(matrixSize.coords.x).length - 1 && y > 1
                  ? "#FF4037"
                  : x <=
                      matrix.xMatrix(matrixSize.coords.x).length -
                        (matrix.xMatrix(matrixSize.coords.x).length - 1) &&
                    y < matrix.yMatrix(matrixSize.coords.y).length - 3
                  ? "#04D439"
                  : matrix.xMatrix(matrixSize.coords.x).length >= 7 &&
                    x <= matrix.xMatrix(matrixSize.coords.x).length - 5 &&
                    y < matrix.yMatrix(matrixSize.coords.y).length - 3
                  ? "#04D439"
                  : x >= matrix.xMatrix(matrixSize.coords.x).length - 3 &&
                    y >= matrix.yMatrix(matrixSize.coords.y).length - 2
                  ? "#FF4037"
                  : matrix.yMatrix(matrixSize.coords.y).length >= 7 &&
                    x >= matrix.xMatrix(matrixSize.coords.x).length - 2 &&
                    y >= matrix.yMatrix(matrixSize.coords.y).length - 5
                  ? "#FF4037"
                  : "#FFDD2D";
              return (
                <g
                  key={x}
                  id="cell"
                  data-name={`label:${yName.letter + xName.letter}|coordx:${
                    matrix.xMatrix(matrixSize.coords.x).length - (x + 1)
                  }|coordY:${
                    matrix.yMatrix(matrixSize.coords.y).length - (y + 1)
                  }`}
                  onClick={(e) =>
                    cellHandler(
                      e,
                      263 + x * 65,
                      106 +
                        (matrix.yMatrix(matrixSize.coords.y).length - (y + 1)) *
                          65,
                      yName.letter + xName.letter,
                      matrix.xMatrix(matrixSize.coords.x).length - (x + 1),
                      matrix.yMatrix(matrixSize.coords.y).length - (y + 1),
                      cell_id
                    )
                  }
                  x={263 + x * 65}
                  y={377 - y * 65}
                >
                  <rect
                    id={`cell${yName.letter + xName.letter}`}
                    x={263 + x * 65}
                    y={377 - y * 65}
                    width="60"
                    height="60"
                    rx="2.99"
                    fill={fillAlgo}
                  />
                  <text
                    style={{ pointerEvents: "none" }}
                    x={263 + x * 65 + 20}
                    y={377 - y * 65 + 36}
                    fontFamily="ginger"
                    fontSize="15px"
                    fill="#FFFFFF"
                  >
                    {yName.letter + xName.letter}
                  </text>
                </g>
              );
            });
        })}
      </g>
    </svg>
  );
};

export default MatrixSvg;
