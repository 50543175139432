import TooltipV2 from "components/UI/Tooltip/TooltipV2";
import { executeStrategy } from "helpers/executeStrategy";
import React, {
  ChangeEvent,
  FC,
  MouseEvent,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ApiDebugModes,
  code_message_list_data,
  EActionTypes,
  icons,
  storage_key,
} from "../../../../../settings/settings";
import {
  handlerStrategyElementInput,
  handleStrategyBoardSidePanelContent,
  handleStrategyNewCreatedElementId,
} from "../../../../../store/actions/actionsStrategy";
import { RootState } from "../../../../../store/combineReducer";
import DB from "../../../../DB/Storage";
import {
  call_arrow_box_source,
  call_arrow_box_target,
} from "../handlers/remove_arrow_box.handler";
import { remove_db } from "../handlers/remove_element.handler";
import { BoardStorage } from "../handlers/storage.helper";
import classes from "./CodeMessage.module.scss";
const CodeMessage: FC = () => {
  const [panelStatus, setPanelStatus] = useState<boolean>(true);
  const [code, setCode] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [messageList, setMessageList] = useState<any[]>([]);
  const [activeMessage, setActiveMessage] = useState<any>({
    code: null,
    message: null,
  });
  const [existsCode, setExistsCode] = useState<boolean>(false);

  const [tooltipState, setTooltipState] = useState<any>({
    visible: false,
    data: undefined,
    bgColor: undefined,
    txtColor: undefined,
    topMessage: undefined,
    bottomMessage:
      "This notification may show reduced result. For full result please choose Debug mode.",
  });
  const dispatch = useDispatch();
  const {
    reducerStrategy: {
      StrategyBoardSidePanel: {
        Content: { content_value },
      },
      StrategyBoardElements: {
        NewCreatedElement: { new_created_element },
        ElementInput: { element_input },
      },
    },
  } = useSelector((state: RootState) => state);

  useLayoutEffect(() => {
    const db = new DB(storage_key);

    db.fetch({ type: content_value }).then((res: any) => {
      if (res.status) {
        if (res.data && Array.isArray(res.data) === false) {
          setMessageList([res.data]);
        }
        if (
          res.data &&
          Array.isArray(res.data) === true &&
          res.data.length > 1
        ) {
          const { data } = res.data.reduce(
            (acc: any, curr: any) => {
              if (!acc.keys.has(curr.data.code_message.code)) {
                acc.data.push(curr);
                acc.keys.add(curr.data.code_message.code);
              }
              return acc;
            },
            { data: [], keys: new Set<string>() }
          );
          setMessageList(data);
        }
      }
    });
  }, [content_value]);
  useLayoutEffect(() => {
    const db = new DB(storage_key);

    db.fetch({ sourceId: element_input }).then((res: any) => {
      if (res.status) {
        const { code, message } = res.data.data.code_message;
        setActiveMessage({ code: code?.trim(), message: message?.trim() });
      }
    });
  }, [element_input]);

  useEffect(() => {
    let _code = messageList?.find(
      (f: any) => f?.data?.code_message?.code === code
    );
    if (_code) {
      setExistsCode(true);
    }
    return () => setExistsCode(false);
  }, [code, messageList]);

  const setMessageHandler = (msg: any) => {
    const { code, message } = msg?.data?.code_message;
    setActiveMessage({ code: code?.trim(), message: message?.trim() });
    setCode(code?.trim());
    setMessage(message?.trim());
  };

  const closePanel = () => {
    setPanelStatus(false);
    if (new_created_element) {
      const svg = document.querySelector("#svg_board") as SVGSVGElement;
      const find_element: any = svg?.querySelector(
        `rect[data-id = ${new_created_element}]`
      ) as SVGRectElement;
      const find_arrows = svg.querySelectorAll(
        `path[data-contact $= ${new_created_element}]`
      ) as NodeList;
      const find_arrows_target = svg.querySelectorAll(
        `path[data-contact ^= ${new_created_element}]`
      ) as NodeList;

      if (find_arrows?.length !== 0) {
        find_arrows.forEach((arrow: any) => {
          const id = arrow.dataset.contact;
          let find_text = svg.querySelectorAll(
            `text[id = "${id}"]`
          ) as NodeList;
          find_text?.forEach((text: any) => {
            svg.removeChild(text);
          });
          svg.removeChild(arrow);
        });
      }
      if (find_arrows_target?.length !== 0) {
        find_arrows_target.forEach((arrow: any) => {
          const id = arrow.dataset.contact;

          let find_text = svg.querySelectorAll(
            `text[id = "${id}"]`
          ) as NodeList;
          find_text?.forEach((text: any) => {
            svg.removeChild(text);
          });

          svg.removeChild(arrow);
        });
      }

      call_arrow_box_source(find_arrows);
      call_arrow_box_target(find_arrows_target);

      if (find_element) {
        remove_db(find_element);
        svg.removeChild(find_element.parentNode);
      }
    }

    setTimeout(() => {
      dispatch(handlerStrategyElementInput({ element_input: null }));
      dispatch(handleStrategyBoardSidePanelContent({ content_value: null }));
      dispatch(
        handleStrategyNewCreatedElementId({ new_created_element: null })
      );
    }, 200);
  };

  const handleCode = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setCode(value);
  };
  const handleMessage = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setMessage(value);
  };
  const submitHandler = (e: MouseEvent<HTMLButtonElement>) => {
    const codeElement = document.querySelector<HTMLInputElement>("#code")!;
    codeElement.style.border = "";

    const messageCodes = messageList?.map(
      (message) => message.data.code_message.code
    );
    if (messageCodes.includes(code) && !activeMessage.code) {
      codeElement.style.border = "2px solid #f00";
      return;
    }

    const svg = document.querySelector("#svg_board") as SVGSVGElement;
    const id = element_input ? element_input : new_created_element;
    const codeMessage = svg.querySelector(
      `g[data-id = "${id}"]`
    ) as SVGGElement;

    BoardStorage(codeMessage, EActionTypes.ADD);

    new DB(storage_key).fetch({ sourceId: id }).then((res: any) => {
      if (res.status && res.data) {
        const { data } = res;
        const _code = code ? code : data.data.code_message.code;
        const _message = message ? message : data.data.code_message.message;
        let new_data = {
          ...data,
          targetId: "endpoint",
          data: {
            label: "code_message",
            code_message: {
              code: _code,
              message: _message,
            },
          },
        };

        new DB(storage_key).update({ sourceId: data.sourceId }, [new_data]);
        new DB(storage_key)
          .fetch({ type: "code_message_list" })
          .then((res_list: any) => {
            if (res_list.status && res_list.data) {
              const {
                data: { code_message_list },
              } = res_list.data;
              // const ref_list = [...code_message_list].filter((f:any)=>f.key !== _code && f.key !== _message);
              const ref_list = [...code_message_list].filter(
                (f: any) => f.id !== id
              );
              ref_list.push({
                id: id,
                code: code ? code : data.data.code_message.code,
                message: message ? message : data.data.code_message.message,
              });
              let new_data = {
                ...res_list.data,
                data: {
                  label: "code_message_list",
                  code_message_list: [...ref_list],
                },
              };
              new DB(storage_key).update({ type: "code_message_list" }, [
                new_data,
              ]);
            } else {
              let new_data = {
                ...code_message_list_data,
                data: {
                  label: "code_message_list",
                  code_message_list: [
                    {
                      id: id,
                      code: code ? code : data.data.code_message.code,
                      message: message
                        ? message
                        : data.data.code_message.message,
                    },
                  ],
                },
              };

              new DB(storage_key).add([new_data]);
            }
          });
      }
    });

    setTimeout(() => {
      dispatch(handlerStrategyElementInput({ element_input: null }));
      dispatch(handleStrategyBoardSidePanelContent({ content_value: null }));
      dispatch(
        handleStrategyNewCreatedElementId({ new_created_element: null })
      );
    }, 200);
  };

  return (
    <div
      className={
        panelStatus
          ? classes.CodeMessage
          : [classes.CodeMessage, classes.CloseAnimation].join(" ")
      }
    >
      <button onClick={closePanel} className={classes.CloseBtn}>
        <img src={icons.closeBtn} alt="icon" />
      </button>

      <div className={classes.Content}>
        <div className={classes.Header}>
          <div className={classes.Title}>code and message</div>
          <div className={classes.Run}>
            <div className={classes.Ico}>
            <TooltipV2
            visible={tooltipState?.visible}
            onVisible={(value: boolean) =>
              setTooltipState({
                ...tooltipState,
                visible: value,
                data: undefined,
              })
            }
            data={tooltipState?.data}
            topMessage={tooltipState?.topMessage}
            bottomMessage={tooltipState?.bottomMessage}
            backgroundColor={tooltipState?.bgColor}
            textColor={tooltipState?.txtColor}
            child={
              <img
                className={classes.PlayIcon}
                src={icons.strategyPlay}
                alt="icon"
                onClick={() =>
                  executeStrategy({
                    state: tooltipState,
                    setState: setTooltipState,
                    activeElementId: element_input,
                    debugMode: ApiDebugModes.BREAKPOINT
                  })
                }
              />
            }
          />
            </div>
          </div>
        </div>
        <div className={classes.Inputs}>
          <div className={classes.Code}>
            <label htmlFor="code">code</label>
            <input
              onChange={handleCode}
              id="code"
              defaultValue={activeMessage?.code}
            />
            {existsCode && (
              <div className={classes.Tooltip}>
                <div className={classes.Msg}>This code already exists</div>
              </div>
            )}
          </div>
          <div className={classes.Message}>
            <label htmlFor="message">message</label>

            <input
              onChange={handleMessage}
              id="message"
              defaultValue={activeMessage?.message}
            />
          </div>
        </div>
        <div className={classes.CodeList}>
          <div className={classes.ListTitle}>Use exists code and message</div>
          <div
            className={
              messageList?.length > 0
                ? classes.ItemTitle
                : [classes.ItemTitle, classes.ItemTitleNo].join(" ")
            }
          >
            <div className={classes.CodeFilter}>
              <div className={classes.sp}>code filter</div>
            </div>
            <div className={classes.MessageFilter}>
              <div className={classes.sp}>message filter</div>
            </div>
          </div>
          <div className={classes.Table}>
            {messageList?.length > 0 ? (
              messageList
                .filter((f) => f.data.code_message.code)
                .map((list: any, index: number) => (
                  <div
                    onClick={() => setMessageHandler(list)}
                    key={index}
                    className={classes.Item}
                  >
                    <div className={classes.CodeFilter}>
                      <div className={classes.CodeText}>
                        {list.data.code_message.code}
                      </div>
                    </div>
                    <div className={classes.MessageFilter}>
                      <div className={classes.MessageText}>
                        {list.data.code_message.message}
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <div className={classes.NoData}>No message to display</div>
            )}
          </div>
          <div className={classes.Submit}>
            <button
              //   disabled={existsCode || !code || !message ? true : false}
              onClick={submitHandler}
              className={
                // existsCode
                //   ? [classes.BtnSubmit, classes.BtnSubmitDisabled].join(" ")
                //   :
                classes.BtnSubmit
              }
            >
              submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodeMessage;
