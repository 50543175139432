import { check_arrow_box } from "./check_arrow_box";
import { ArrowSideType, Coord, Side,DecisionBox } from "./types";

export const decision_side = (coord:Coord,arrow_side:ArrowSideType) =>{
    const {first_side,last_side} = arrow_side;
    let side_status:Side={
            top_top:false,
            right_right:false,
            bottom_bottom:false,
            left_left:false,

            right_bottom:false,
            right_bottom_left:false,
            right_bottom_right:false,
            right_bottom_bottom:false,

            right_top:false,
            right_top_right:false,
            right_top_left:false,
            right_top_top:false,

            left_bottom:false,
            left_bottom_right:false,
            left_bottom_left:false,
            left_bottom_bottom:false,

            left_top:false,
            left_top_right:false,
            left_top_left:false,
            left_top_top:false,

            top_right:false,
            top_right_top:false,
            top_right_bottom:false,
            top_right_right:false,

            top_left:false,
            top_left_bottom:false,
            top_left_top:false,
            top_left_left:false,

            bottom_left:false,
            bottom_left_top:false,
            bottom_left_bottom:false,
            bottom_left_left:false,

            bottom_right:false,
            bottom_right_top:false,
            bottom_right_bottom:false,
            bottom_right_right:false
    };
    const side_split = last_side.split('-')
    const first_side_split = first_side.split('-')
    const first_element_side = first_side_split?.[0];
    if(first_element_side === 'right'){
                let side:string = `${first_side_split.length>1 ? first_side_split?.[0]+'_'+first_side_split?.[1]:first_side}_${side_split.length>1 ? side_split?.[1]:last_side}`;
                switch(side){
                    case 'right_top':
                        side_status[side] = true;
                        break;
                    case 'right_right':
                        side_status[side] = true;
                        break;
                    case 'right_bottom':
                        side_status[side] = true;
                        break;
                    case 'right_bottom_right':
                        side_status[side] = true;
                        break;
                    case 'right_bottom_left':
                        side_status[side] = true;
                        break;
                    case 'right_bottom_bottom':
                        side_status[side] = true;
                        break;
        
                    case 'right_top_right':
                        side_status[side] = true;
                        break;
                    case 'right_top_left':
                        side_status[side] = true;
                        break;
                    case 'right_top_top':
                        side_status[side] = true;
                        break;
                    default:
                        break;
                }
            
    }
 
   
    if(first_element_side === 'left'){

            let side:string = `${first_side_split.length>1 ? first_side_split?.[0]+'_'+first_side_split?.[1]:first_side}_${side_split.length>1 ? side_split?.[1]:last_side}`;
            switch(side){
                case 'left_top':
                    side_status[side] = true;
                    break;
                case 'left_left':
                    side_status[side] = true;
                    break;
                case 'left_bottom':
                    side_status[side] = true;
                    break;
                case 'left_bottom_right':
                    side_status[side] = true;
                    break;
                case 'left_bottom_left':
                    side_status[side] = true;
                    break;
                    
                case 'left_bottom_bottom':
                    side_status[side] = true;
                    break;
                case 'left_top_right':
                    side_status[side] = true;
                    break;
                case 'left_top_left':
                    side_status[side] = true;
                    break;
                case 'left_top_top':
                    side_status[side] = true;
                    break;
                default:
                    break;
            }
    }

    if(first_element_side === 'bottom'){


            let side:string = `${first_side_split.length>1 ? first_side_split?.[0]+'_'+first_side_split?.[1]:first_side}_${side_split.length>1 ? side_split?.[1]:last_side}`;
    
            switch(side){
                case 'bottom_bottom':
                    side_status[side] = true;
                    break;
                case 'bottom_left':
                    side_status[side] = true;
                    break;
                  
                case 'bottom_right':
                        side_status[side] = true;
                    break; 
                case 'bottom_right_bottom':
                    side_status[side] = true;
                    break;
                case 'bottom_right_top':
                    side_status[side] = true;
                    break;
                case 'bottom_right_right':
                    side_status[side] = true;
                    break;
                case 'bottom_left_bottom':
                    side_status[side] = true;
                    break;
                case 'bottom_left_top':
                    side_status[side] = true;
                    break;
                case 'bottom_left_left':
                    side_status[side] = true;
                    break;
                //  
                case 'right_bottom_left':
                    side_status[side] = true;
                    break;
                default:
                    break;
            }
    
    }
   
    if(first_element_side === 'top'){

            let side:string = `${first_side_split.length>1 ? first_side_split?.[0]+'_'+first_side_split?.[1]:first_side}_${side_split.length>1 ? side_split?.[1]:last_side}`;
    
            switch(side){
                case 'top_top':
                    side_status[side] = true;
                    break;
                case 'top_left':
                    side_status[side] = true;
                    break;
                case 'top_right':
                        side_status[side] = true;
                    break; 
                case 'top_right_bottom':
                    side_status[side] = true;
                    break;
                case 'top_right_top':
                    side_status[side] = true;
                    break;
                case 'top_right_right':
                    side_status[side] = true;
                    break;
                case 'top_left_bottom':
                    side_status[side] = true;
                    break;
                case 'top_left_top':
                    side_status[side] = true;
                    break;
                case 'top_left_left':
                    side_status[side] = true;
                    break;
                default:
                    break;
            }
    }
    
    return side_status;

}

export const check_side = (side:string,element:SVGRectElement)=>{
    const box_list = check_arrow_box(element);
    let f =  box_list?.filter((box:any) => box.side === side);
    if(f.length===1){
         return true                 
    }else{
        return false;
    }
 }



 
export const decision_box = (first:SVGRectElement,last:SVGRectElement)=>{
        const svg = document.querySelector(`#svg_board`) as SVGElement;

   
        const first_data_id = first.dataset.id;
        const last_data_id = last.dataset.id;

        const inputs_first = svg.querySelectorAll(`path[data-contact $= "-${first_data_id}"]`) as NodeListOf<SVGPathElement>
        const outputs_first = svg.querySelectorAll(`path[data-contact ^= "${first_data_id}-"]`) as NodeListOf<SVGPathElement>

        const inputs_last = svg.querySelectorAll(`path[data-contact $= "-${last_data_id}"]`) as NodeListOf<SVGPathElement>
        const outputs_last = svg.querySelectorAll(`path[data-contact ^= "${last_data_id}-"]`) as NodeListOf<SVGPathElement>

        let result:DecisionBox = {
            first:{
                inputs:[],
                outputs:[],
            },
            last:{
                inputs:[],
                outputs:[]
            }
        }
      
            inputs_first?.forEach((input:SVGPathElement)=>{
                let side = input.dataset.side;
                let new_side:any = side === 'right' ?
                 'left' :(side === 'left' ? 'right'
                 :(side === 'top' ? 'bottom'
                 :(side==='bottom' ? 'top'
                 :(side==='right-top' ? 'bottom'
                 :(side==='right-bottom' ? 'top'
                 :(side==='left-bottom' ? 'top'
                 :(side==='left-top' ? 'bottom'
                 :(side==='top-left' ? 'right'
                 :(side==='top-right' ? 'left'
                 :(side==='bottom-left' ? 'right'
                 :(side==='bottom-right' ? 'left'
                 :(side==='right-top-left' ? 'right'
                 :(side==='right-bottom-left' ? 'right'
                 :(side==='left-bottom-right' ? 'left'
                 :(side==='top-right-bottom' ? 'top'
                 :(side==='bottom-right-top' ? 'bottom'
                 :(side==='bottom-left-top' ? 'bottom':side)))))))))))))))));
                
                result.first.inputs.push(new_side)

               
            });
            outputs_first?.forEach((input:SVGPathElement)=>{
                let side:any = input.dataset.side;
                
                result.first.outputs.push(side)

               
            });
            inputs_last?.forEach((input:SVGPathElement)=>{
                let side = input.dataset.side;
                let new_side:any = side === 'right' ?
                 'left' :(side === 'left' ? 'right'
                 :(side === 'top' ? 'bottom'
                 :(side==='bottom' ? 'top'
                 :(side==='right-top' ? 'bottom'
                 :(side==='right-bottom' ? 'top'
                 :(side==='left-bottom' ? 'top'
                 :(side==='left-top' ? 'bottom'
                 :(side==='top-left' ? 'right'
                 :(side==='top-right' ? 'left'
                 :(side==='bottom-left' ? 'right'
                 :(side==='bottom-right' ? 'left'
                 :(side==='right-top-left' ? 'right'
                 :(side==='right-bottom-left' ? 'right'
                 :(side==='left-bottom-right' ? 'left'
                 :(side==='top-right-bottom' ? 'top'
                 :(side==='bottom-right-top' ? 'bottom'
                 :(side==='bottom-left-top' ? 'bottom':side)))))))))))))))));
                
                result.last.inputs.push(new_side)

               
            });
            outputs_last?.forEach((input:SVGPathElement)=>{
                let side:any = input.dataset.side;
                let new_side:any = 
                 (side==='right-top' ? 'right'
                 :(side==='right-bottom' ? 'right'
                 :(side==='left-bottom' ? 'left'
                 :(side==='left-top' ? 'left'
                 :(side==='top-left' ? 'top'
                 :(side==='top-right' ? 'right'
                 :(side==='bottom-left' ? 'bottom'
                 :(side==='bottom-right' ? 'bottom'
                 :(side==='right-top-left' ? 'right'
                 :(side==='right-bottom-left' ? 'right'
                 :(side==='left-bottom-right' ? 'left'
                 :(side==='top-right-bottom' ? 'top'
                 :(side==='bottom-right-top' ? 'bottom'
                 :(side==='bottom-left-top' ? 'bottom':side))))))))))))));
                result.last.outputs.push(new_side)

               
            });

        let filter_first = result.first.inputs.filter((value:string,index:number)=>result.first.inputs.indexOf(value) === index);
        let filter_last = result.last.inputs.filter((value:string,index:number)=>result.last.inputs.indexOf(value) === index);
        result.first.inputs = filter_first;
        result.last.inputs = filter_last    

        return result
        
}
