import React, { FC, useEffect, useState } from 'react';
import classes from './Tooltip.module.scss';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store/combineReducer";
import { handleRangeActiveElement, handleRangeReload, handleRangeActiveRow } from '../../../store/actions/actionsDecision';
import { request } from '../../../helpers/request';
import { endpoints } from '../../../api/endpoints';
import { handleActiveTooltip } from '../../../store/actions/action';

interface TooltipProps {
    children: any,
    fields?: any
}

const Tooltip: FC<TooltipProps> = ({ children, fields }) => {
    const [state, setstate] = useState<string | null>()
    const { decision_designer: { decision_range } } = endpoints
    const {
        reducer: {
            mainTooltip: { childId, type, childDataId }
        }
    } = useSelector((state: RootState) => state);
    const dispatch = useDispatch();

    const toolTipChildren = document.getElementById(`${state}`)

    useEffect(() => {
        if (type === "validation" && childId !== null) {

            let childElement = document.getElementById(`${childId}`) as HTMLElement
            let childElementWidth = childElement?.getBoundingClientRect()?.width
            let tooltipElement = document.getElementById(`${childId}`)?.parentNode?.lastChild as HTMLElement

            childElement.style.border = ".5px solid #FF554D"
            tooltipElement.style.display = "flex"
            tooltipElement.style.width = `${childElementWidth}px`

            setstate(childId)
        }

        if (type === null && toolTipChildren) {

            toolTipChildren.style.border = "none"
        }

    }, [type, childId, toolTipChildren])

    useEffect(() => {
        if (type === "deleteConfirm" && childId !== null) {

            let tooltipElement = document.getElementById(`${childId}`)?.parentNode?.lastChild as HTMLElement
            tooltipElement && (tooltipElement.style.display = "flex")
        }
    }, [type, childId])

    useEffect(() => {

        if (type === "saveChanges" && childId !== null) {

            let childElement = document.getElementById(`${childId}`) as HTMLElement
            let tooltipElement = document.getElementById(`${childId}`)?.parentNode?.lastChild as HTMLElement
            let childElementWidth = childElement?.getBoundingClientRect()?.width

            if(childElementWidth < 200) childElementWidth = 200
            

            if (tooltipElement) {
                tooltipElement.style.display = "flex"
                tooltipElement.style.width = `${childElementWidth}px`
            }

        }

    }, [type, childId])

    useEffect(() => {
      if (type === "moreMenu" && childId !== null) {
        let tooltipElement = document.getElementById(`${childId}`)?.parentNode
          ?.lastChild as HTMLElement;
        tooltipElement && (tooltipElement.style.display = "block");
      }
    }, [type, childId]);

    const confirmHandler = () => {
      request
        .delete(decision_range.delete(childDataId as number))
        .then((result) => {
          const { status } = result;
          if (status === 204) {
            dispatch(handleRangeReload(true));
            dispatch(
              handleActiveTooltip({
                childId: null,
                childDataId: null,
                type: null,
              })
            );
            dispatch(
              handleRangeActiveElement({ name: null, elementIndex: null })
            );
          }
        })
        .catch((error) => console.log("error " + error));
    };

    const cancelHandler = () => {
      dispatch(handleRangeActiveElement({ name: null }));
      dispatch(
        handleActiveTooltip({ childId: null, childDataId: null, type: null })
      );
      dispatch(handleRangeActiveRow({ rowIndex: null, newRow: false }));
    };

    return (
      <div id="tooltip" className={classes.Tooltip}>
        {children}
        {type === "validation" && (
          <span className={classes.Message}>Can not be empty</span>
        )}
        {type === "deleteConfirm" && (
          <div className={classes.TooltipConfirmBody}>
            <span className={classes.MessageConfirm}>Are you sure?</span>
            <div className={classes.ActionConfirm}>
              <button
                onClick={() => cancelHandler()}
                className={classes.Cancel}
              >
                Cancel
              </button>
              <button
                onClick={() => confirmHandler()}
                className={classes.Confirm}
              >
                Yes
              </button>
            </div>
          </div>
        )}
        {type === "saveChanges" && (
          <span className={classes.SaveChangesMessage}>
            Please save changes
          </span>
        )}
        {type === "moreMenu" && <div className={classes.Menu}>{fields}</div>}
      </div>
    );
}

export default Tooltip;