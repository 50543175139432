import React, { useEffect } from "react";
import classes from "./DecisionDesigner.module.scss";
import Table from "../../components/UI/Table/Table";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import { icons } from "../../settings/settings";
import {
  activeModal,
  activeSection,
  addCheckbox,
  checkedStatus,
  refreshFetch,
  selectAll,
} from "../../store/actions/action";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/combineReducer";
import { request } from "../../helpers/request";
import { endpoints } from "../../api/endpoints";
import Matrix from "./DecisionName/Matrix/Matrix";
import {
  activeDecision,
  handleDecisionData,
  matrixData,
  matrixDataStatus,
} from "../../store/actions/actionsDecision";
import DecisionNameRange from "./DecisionName/Range/Range";
import { ModalLoader } from "components/UI/Modal/ModalLoader";
import DataTree from "components/UI/Tree/DataTree";
import { useGetAllData } from "../../hooks/useGetAllData";

const DecisionDesigner: React.FC = () => {
  const [loader, setLoader] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    decision: { getAllDecisions },
  } = useGetAllData();

  const store = useSelector((state: RootState) => state);

  useEffect(() => {
    if (history.location.pathname === "/decision-designer") {
      dispatch(activeDecision({ activeDecision: null }));
      dispatch(matrixData({ matrixData: null }));
    }
  }, [dispatch, history.location]);

  const counter = store?.reducer.checkboxList.filter((list, index) => {
    return list.checked === true;
  });
  useEffect(() => {
    dispatch(matrixDataStatus({ status: false }));
  }, [dispatch, store.reducerDecision.activeDecision.activeDecision]);

  useEffect(() => {
    dispatch(activeSection({ activeSection: window.location.pathname }));
    dispatch(checkedStatus({ status: true }));
  }, [dispatch]);

  useEffect(() => {
    getAllDecisions.get({
      reduxData: store.reducerDecision.decisionState.length,   
      action: handleDecisionData,
    });
  }, [ store?.reducerDecision.decisionState.length, getAllDecisions]);

  const archiveHandler = async () => {
    setLoader(true);
    let ids = store.reducer.checkboxList.filter(
      (list) => list.checked === true
    );

    let decisionMassiveRef = [...store.reducerDecision.decisionState];

    let checkbox: any = document.querySelectorAll('[data-name="checkbox"]');

    ids.length > 0 &&
      ids.forEach((el: any, i: number) => {
        let checkedMassive = decisionMassiveRef.filter(
          (pm: any) => pm.id === el.id
        );
        let index = decisionMassiveRef.indexOf(checkedMassive[0]);
        decisionMassiveRef.splice(index, 1);
        request
          .delete(endpoints.decision_designer.decision.delete(el.id))
          .then((res) => {
            if (res.ok) {
              dispatch(checkedStatus({ status: true }));
              checkbox.forEach((elem: any) => {
                elem.checked = false;
              });
              if (store.reducer.checkboxList.length > 0) {
                let mass = [...store.reducer.checkboxList];
                mass.forEach((elem: any, i: number) => {
                  if (elem.id === el.id) {
                    let index = mass.indexOf(elem);
                    mass.splice(index, 1);
                    dispatch(
                      addCheckbox({
                        id: mass[index]?.id,
                        checked: mass[index]?.checked,
                      })
                    );
                    setLoader(false);
                  }
                });
              }
            }
          });
      });
    // const archiveProduct =await request(api.products+'','delete');
  };

  const cloneHandler = () => {
    setLoader(true);
    counter?.forEach(({ id }: any) => {
      request
        .post(endpoints.decision_designer.decision.clone(id), {})
        .then((res) => {
          const { success } = res;
          if (!success) return;
          dispatch(refreshFetch({ refresh: true }));
          setLoader(false);
        });
    });
  };
  const selectAllHandler = () => {
    dispatch(selectAll({ selectAll: true }));
    // setUnSelectStatus(true);

    let checkbox: any = document.querySelectorAll('[data-name="checkbox"]');
    // const ids:any = [];
    checkbox.forEach((el: any) => {
      el.checked = true;
      dispatch(
        addCheckbox({
          id: +el.id,
          checked: el.checked,
        })
      );
      // ids.push(el.id.split('checkbox_')[1])
      // request(api.products+el.id.split('checkbox_')[1]+'/','delete');
    });
  };

  const unSelectAllHandler = () => {
    dispatch(selectAll({ selectAll: false }));

    // setUnSelectStatus(false);

    let checkbox: any = document.querySelectorAll('[data-name="checkbox"]');
    dispatch(checkedStatus({ status: true }));

    checkbox.forEach((el: any) => {
      el.checked = false;
    });
  };
  const backPopupHandler = (e: any) => {
    if (store.reducer.touchBlocked.status) {
      dispatch(activeModal({ activeModal: "matrix_blocked_modal" }));
    }
  };

  return (
    <div className={classes.DecisionDesigner}>
      {loader && <ModalLoader>Please wait...</ModalLoader>}
      <div className={classes.Main}>
        <Switch>
          <Route
            path="/decision-designer/:string"
            render={({ location, history }) => {
              const url = location.search.split("&");
              const decision_id = url[0].split("=")[1];
              const type_id = +url[1].split("=")[1];
              const step = url[2]
                ? url[2].split("=")[1] === "final"
                  ? url[2].split("=")[1]
                  : +url[2].split("=")[1]
                : null;

              const matrixProps = {
                decision_id: decision_id,
                type_id: type_id,
                step: step,
              };
              return (
                <>
                  {type_id === 1 && (
                    <>
                      <div className={classes.HeaderDecision}>
                        <div className={classes.Title}>
                          {step === 1 || step === 2
                            ? "Matrix Generate Wizard:"
                            : store.reducerDecision.decisionState.find(
                                (each: any) => each.id === Number(decision_id)
                              )?.name}
                          {step === 1 ? (
                            <span className={classes.Step}>
                              {step} step - Matrix Size
                            </span>
                          ) : step === 2 ? (
                            <span className={classes.Step}>
                              {step} step - Set Matrix Points
                            </span>
                          ) : null}
                          <div className={classes.Type}>Matrix</div>
                        </div>
                      </div>
                      <div
                        onClick={backPopupHandler}
                        className={
                          store.reducer.touchBlocked?.status === true
                            ? [classes.BackBtn, classes.Dis].join(" ")
                            : classes.BackBtn
                        }
                      >
                        <Link to="/decision-designer">
                          <img src={icons.backBtn} alt="back" /> Back
                        </Link>
                      </div>
                      <Matrix {...matrixProps} />
                    </>
                  )}

                  {type_id === 2 && (
                    <>
                      {" "}
                      <DecisionNameRange
                        data={store.reducerDecision.decisionState}
                      />
                    </>
                  )}

                  {type_id === 3 && (
                    <>
                      <DataTree />
                    </>
                  )}
                </>
              );
            }}
          />
          <Route
            path="/decision-designer"
            render={() => (
              <>
                <div className={classes.Header}>
                  <div className={classes.Title}>Decision Designer</div>
                  <div className={classes.SearchDecision}>
                    {/* <div className={classes.Search}>
                                            <button>
                                            <img src={icons.search} alt='search'/>
                                            </button>
                                        </div> */}
                    <div className={classes.AddDecision}>
                      <button
                        onClick={() =>
                          dispatch(activeModal({ activeModal: "add_decision" }))
                        }
                      >
                        Add Decision
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className={classes.Actions}
                  style={{ display: counter.length > 0 ? "flex" : "none" }}
                >
                  <div className={classes.Selected}>
                    <div className={classes.SelectedItems}>
                      {counter.length === 1 ? (
                        <div>{counter.length} item selected</div>
                      ) : counter.length > 1 ? (
                        <div>{counter.length} items selected</div>
                      ) : null}
                    </div>
                    {/* <div className={classes.VLine}></div> */}
                    <div
                      className={
                        counter.length ===
                        store.reducerDecision.decisionState.length
                          ? [classes.SelectAll, classes.Sa].join(" ")
                          : classes.SelectAll
                      }
                    >
                      <button onClick={selectAllHandler}>Select All</button>
                    </div>
                    {/* <div
                        style={{
                          visibility: counter.length > 0 ? "visible" : "hidden",
                        }}
                        className={classes.VLine}
                      ></div> */}

                    {/* <div
                        style={{
                          visibility: counter.length > 0 ? "visible" : "hidden",
                        }}
                        className={classes.UnSelectAll}
                      >
                        <button onClick={unSelectAllHandler}>
                          Unselect All
                        </button>
                      </div> */}
                  </div>
                  <div className={classes.Action}>
                    <button className={classes.Clone} onClick={cloneHandler}>
                      Clone items
                    </button>
                    <button
                      onClick={archiveHandler}
                      className={classes.Archive}
                    >
                      Archive items
                    </button>
                    <button
                      className={classes.Cancel}
                      onClick={unSelectAllHandler}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <Table
                  saveStatusHandler={() => void 0}
                  statusTopHandler={() => void 0}
                  statusBottomHandler={() => void 0}
                  cancelStatusHandler={() => void 0}
                  editStatusHandler={() => void 0}
                  editAliasHandler={() => void 0}
                  saveAliasHandler={() => void 0}
                  cancelAliasHandler={() => void 0}
                  section="decision-designer"
                  data={store.reducerDecision.decisionState}
                  changeLoader={(status: boolean) => setLoader(status)}
                />
              </>
            )}
          />
        </Switch>
      </div>
    </div>
  );
};
export default DecisionDesigner;
