import React, { FC, useCallback, useEffect, useState } from "react";
import classes from "../DecisionManagerRange.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { endpoints } from "../../../../api/endpoints";
import { decision_type, icons } from "../../../../settings/settings";
import { RootState } from "../../../../store";
import Portal from "../../Portal/Portal";
import { request } from "../../../../helpers/request";
import { handleDecisionData } from "../../../../store/actions/actionsDecision";
import {
  handleChangeableStatus,
  handleStrategyMatrixActiveData,
} from "../../../../store/actions/actionsStrategy";

interface IDecisionManagerMatrix {
  setId: Function;
}

let timeoutInstance: any;

const DecisionManagerMatrix: FC<IDecisionManagerMatrix> = ({ setId }) => {
  const dispatch = useDispatch();
  const {
    decision_designer: { decision, decision_matrix },
  } = endpoints;

  const [portalShow, setPortalShow] = useState<boolean>(false);
  const [selectedDecisionId, setSelectedDecisionId] = useState<
    number | undefined
  >();
  // const [decisionId, setDecisionId] = useState<number | undefined>();

  const {
    reducerDecision: { decisionState },
  } = useSelector((state: RootState) => state);

  const getMatrix = useCallback(
    (decisionId: number) => {
      request
        .get(decision_matrix.get(decisionId))
        .then((res) => {
          const { success, response } = res;

          if (success) {
            dispatch(handleStrategyMatrixActiveData({ matrix_data: response }));
            // if (response.length === 0) {
            //   timeoutInstance = setTimeout(() => {
            //     setDecisionId(undefined);
            //   }, 1000);
            // }
          } else {
            console.warn("Fetching ranges success is false ", res);
          }
        })
        .catch((error) => {
          console.error("Fetching ranges error ", error);
        });
    },
    [decision_matrix, dispatch]
  );

  const closePortal = useCallback(() => {
    setPortalShow(false);
  }, []);

  const generateDecisionRows = useCallback(
    (decisions: Array<any>) => {
      function handleActivation(event: any, id: number) {
        const { target } = event;

        if (target.classList.contains(classes.ActiveRow)) {
          target.classList.remove(classes.ActiveRow);
          setSelectedDecisionId(undefined);
        } else {
          target.classList.add(classes.ActiveRow);
          setSelectedDecisionId(id);
        }
      }

      function selectId(id: number) {
        // setDecisionId(id);
        setId(String(id));
        getMatrix(id);
        closePortal();
        dispatch(handleChangeableStatus({ status: true }));
      }

      function filterTypes(decision: any) {
        const { type_id } = decision;
        const rangeType = decision_type.find((type: any) => {
          const { name } = type;
          return name === "Matrix";
        });
        return type_id === rangeType?.id;
      }

      if (decisions?.length) {
        return decisions
          .filter(filterTypes)
          .map((decision: any, index: number) => {
            const { id, name, description } = decision;

            return (
              <div
                key={index}
                className={classes.PortalRow}
                onClick={(event: any) => handleActivation(event, id)}
                onDoubleClick={() => selectId(id)}
              >
                <span>{name}</span>
                <span title={description}>{description}</span>
              </div>
            );
          });
      } else {
        return <div className={classes.NoData}>no data to display</div>;
      }
    },
    [closePortal, dispatch, getMatrix, setId]
  );

  useEffect(() => {
    return () => clearTimeout(timeoutInstance);
  }, []);

  const getDecisions = () => {
    if (decisionState?.length === 0) {
      request
        .get(decision.get)
        .then((res) => {
          const { success, response } = res;

          if (success) {
            dispatch(handleDecisionData([...response]));
          } else {
            console.warn("Fetching decisions success is false ", res);
          }
        })
        .catch((error) => {
          console.error("Fetching decisions error ", error);
        });
    }
  };

  const openPortal = () => {
    setPortalShow(true);
    getDecisions();
  };

  const handlePortalSubmit = () => {
    if (selectedDecisionId) {
      // setDecisionId(selectedDecisionId);
      setId(String(selectedDecisionId));
      getMatrix(selectedDecisionId);
      dispatch(handleChangeableStatus({ status: true }));
      closePortal();
    }
  };

  return (
    <div className={classes.DecisionManagerWrapper}>
      <div className={classes.ViewerHeader}>
        <span className={classes.Title}>Decisions</span>     
      </div>

      <div className={classes.ViewerBody}>
        <div className={classes.Actions}>
          <button className={classes.ActionButton} onClick={openPortal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
            >
              <g clipPath="url(#folderSVG)">
                <path
                  fill="#FCEBA6"
                  d="M11.98 15H.405a.405.405 0 01-.39-.51l2.628-7.208a.404.404 0 01.39-.299H14.61c.266 0 .39.255.39.51l-2.63 7.209a.404.404 0 01-.39.299z"
                />
                <path
                  fill="#F0D97A"
                  d="M13 4.655h-.586v2.328h1.034v-1.88c0-.247-.2-.448-.448-.448zM1.034 2.845H.448c-.247 0-.448.2-.448.448v11.05c.018 0 .036.002.053.003l.981-2.677V2.845z"
                />
                <path
                  fill="#F4EFDC"
                  d="M2.643 7.282a.404.404 0 01.39-.3h9.381V0H2.586v7.438l.057-.156z"
                />
                <path
                  fill="#CEC9AE"
                  d="M4.397 4.138h6.207a.259.259 0 100-.517H4.397a.259.259 0 100 .517zM4.397 2.328h2.586a.259.259 0 100-.517H4.397a.259.259 0 100 .517zM10.604 5.432H4.397a.259.259 0 100 .517h6.207a.259.259 0 100-.517zM2.586 7.438V.517H1.81v9.048l.776-2.127z"
                />
                <path
                  fill="#D1BF86"
                  d="M1.81 9.565v-8.53h-.775v10.657l.775-2.127z"
                />
              </g>
              <defs>
                <clipPath id="folderSVG">
                  <path fill="#fff" d="M0 0h15v15H0z" />
                </clipPath>
              </defs>
            </svg>
            <span>open</span>
          </button>
        </div>
      </div>
      {portalShow && (
        <Portal>
          <div className={classes.PortalWrapper}>
            <span className={classes.PortalTitle}>decision</span>
            <div className={classes.PortalHeader}>
              <span>decision name</span>
              <span>description</span>
            </div>
            <div className={classes.PortalBody}>
              {generateDecisionRows(decisionState)}
            </div>
            <button
              className={classes.SubmitButton}
              onClick={handlePortalSubmit}
            >
              Submit
            </button>
            <button
              className={classes.PortalCloseButton}
              onClick={() => closePortal()}
            >
              <img src={icons.closeBtn} alt="Close icon" />
            </button>
          </div>
        </Portal>
      )}
    </div>
  );
};

export default DecisionManagerMatrix;
