import { checkType } from "./checkType";
import { messages } from "../settings/settings";

interface IHasChange {
  initial: any;
  changeable: any;
}

function isObject(object: any) {
  return object != null && checkType({ value: object }) === "object";
}

function isArray(array: any) {
  return array != null && checkType({ value: array }) === "array";
}

function objectDeepEqual(object1: object, object2: object) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    // @ts-ignore
    const value1 = object1[key];
    // @ts-ignore
    const value2 = object2[key];
    const areObjects = isObject(value1) && isObject(value2);
    const areArrays = isArray(value1) && isArray(value2);
    if (
      (areArrays && !arrayDeepEqual(value1, value2)) ||
      (areObjects && !objectDeepEqual(value1, value2)) ||
      (!areArrays && !areObjects && value1 !== value2)
    ) {
      return false;
    }
  }

  return true;
}

function arrayDeepEqual(array1: Array<any>, array2: Array<any>) {
  if (array1.length !== array2.length) {
    return false;
  }

  for (const index in array1) {
    let value1: any;
    let value2: any;
    if (array1.hasOwnProperty(index)) {
      value1 = array1[index];
      value2 = array2[index];
    }
    const areArrays = isArray(value1) && isArray(value2);
    const areObjects = isObject(value1) && isObject(value2);

    if (
      (areArrays && !arrayDeepEqual(value1, value2)) ||
      (areObjects && !objectDeepEqual(value1, value2)) ||
      (!areArrays && !areObjects && value1 !== value2)
    ) {
      return false;
    }
  }

  return true;
}

export const hasChange = ({ initial, changeable }: IHasChange) =>
  new Promise<void>((resolve, reject) => {
    const initialValueType = checkType({ value: initial });
    const changeableValueType = checkType({ value: changeable });
    let result: boolean;

    if (initialValueType !== changeableValueType)
      return reject(messages.messageDifferentType);

    switch (initialValueType) {
      case "object": {
        result = !objectDeepEqual(initial, changeable);
        break;
      }
      case "array": {
        result = !arrayDeepEqual(initial, changeable);
        break;
      }
      default: {
        result = initialValueType !== changeableValueType;
        break;
      }
    }

    result ? resolve() : reject();
  });
