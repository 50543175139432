import React from 'react';
import classes from './Backdrop.module.scss';

const Backdrop = (props:any)=>{
    let position = props.position && props.position === 'absolute' ? [classes.Backdrop,classes.Absolute].join(' '):[classes.Backdrop,classes.Fixed].join(' ');
    let color = props.color && props.color ==='light' ? classes.Light:classes.Dark;
    return(
        <div  className={[position,color].join(' ')}>
            {props.children}
        </div>
    )
}

export default Backdrop;