import React, { FC, useCallback, useEffect, useState } from "react";
import classes from "./DecisionManagerRange.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { endpoints } from "../../../api/endpoints";
import { ButtonTypes, messages, ModalNames } from "../../../settings/settings";
import { RootState } from "../../../store";
import { request } from "../../../helpers/request";
import ModalList from "../../../components/UI/Modal/ModalList";
import { closeModal, openModal } from "../../../store/actions/actionsModal";

export interface IRangeObj {
  id: number;
  label: string;
  alias: string;
  FROM: string;
  TO: string;
}

interface IDecisionManagerRange {
  getId: (id: string) => void;
  getRangesData: (data: Array<IRangeObj>) => void;
}

interface IGetDecisionTypes {
  endpoint: string;
}

interface IGetDecisions {
  endpoint: string;
}

export interface IGetRanges {
  endpoint: string;
}

let timeoutInstance: any;

const getDecisionTypes = ({ endpoint }: IGetDecisionTypes) =>
  new Promise((resolve) => {
    request
      .get(endpoint)
      .then((res) => {
        const { success, response } = res;

        if (success) {
          resolve(response);
        } else {
          console.warn(messages.messageApiGetStatusFalse, res);
        }
      })
      .catch((error) => {
        console.error(messages.messageApiGetError, error);
      });
  });

const getDecisions = ({ endpoint }: IGetDecisions) =>
  new Promise((resolve) => {
    request
      .get(endpoint)
      .then((res) => {
        const { success, response } = res;

        if (success) {
          resolve(response);
        } else {
          console.warn(messages.messageApiGetStatusFalse, res);
        }
      })
      .catch((error) => {
        console.error(messages.messageApiGetError, error);
      });
  });

const getRanges = ({ endpoint }: IGetRanges) =>
  new Promise((resolve) => {
    request
      .get(endpoint)
      .then((res) => {
        const { success, response } = res;

        if (success) {
          resolve(response);
        } else {
          console.warn(messages.messageApiGetStatusFalse, res);
        }
      })
      .catch((error) => {
        console.error(messages.messageApiGetError, error);
      });
  });

const DecisionManagerRange: FC<IDecisionManagerRange> = ({
  getId,
  getRangesData,
}) => {
  const dispatch = useDispatch();
  const {
    decision_designer: { decision, decision_range, decision_type },
  } = endpoints;

  const [decisions, setDecisions] = useState<Array<Record<string, any>>>([]);
  const [loading, setLoading] = useState<boolean>(false);
 
  const {
    reducerModal: { modalState },
  } = useSelector((state: RootState) => state);

  const getAndFilterDecisions = useCallback(() => {
    const filterDecisions = (typesData: any, decisionsData: any) => {
      if (
        !typesData ||
        !typesData.length ||
        !decisionsData ||
        !decisionsData.length
      ) {
        return null;
      }

      const rangeTypeObj = typesData.find((typeObj: any) => {
        return typeObj?.name.toLowerCase() === "range";
      });

      return decisionsData
        .filter((decisionObj: any) => {
          return decisionObj?.type_id === rangeTypeObj?.id;
        })
        .map((decisionObj: any) => {
          return {
            id: decisionObj?.id,
            name: decisionObj?.name,
            description: decisionObj?.description,
          };
        });
    };

    setLoading(true);

    getDecisionTypes({ endpoint: decision_type.api }).then((typesData: any) => {
      getDecisions({ endpoint: decision.get }).then((decisionsData: any) => {
        setLoading(false);
        setDecisions(filterDecisions(typesData, decisionsData));
      });
    });
  }, [decision, decision_type]);

  const openListModal = useCallback(() => {
    dispatch(
      openModal({
        modalState: {
          visible: true,
          title: messages.titleSelectPanel("decision"),
          question: "",
          message: "",
          buttonMessage: messages.titleBtnApply,
          buttonType: ButtonTypes.PRIMARY,
          name: ModalNames.DECISION_MANAGER_RANGE_LIST,
        },
      })
    );

    getAndFilterDecisions();
  }, [dispatch, getAndFilterDecisions]);

  const closeListModal = useCallback(() => {
    dispatch(closeModal());
    setDecisions([]);
  }, [dispatch]);

  const getSelectedDecisionId = useCallback(
    (id: number) => {
      getId(id.toString());
      getRanges({ endpoint: decision_range.get(id) }).then((data: any) => {
        getRangesData(
          data.map((rangeObj: any) => {
            return {
              id: rangeObj?.id,
              label: rangeObj?.label,
              alias: rangeObj?.alias,
              FROM: rangeObj?.FROM,
              TO: rangeObj?.TO,
            };
          })
        );
      });
    },
    [decision_range, getId, getRangesData]
  );

  useEffect(() => {
    return () => clearTimeout(timeoutInstance);
  }, []);

  return (
    <div className={classes.DecisionManagerWrapper}>
      <div className={classes.ViewerHeader}>
        <span className={classes.Title}>Decisions</span>       
      </div>
      <div className={classes.ViewerBody}>
        <div className={classes.Actions}>
          <button className={classes.ActionButton} onClick={openListModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
            >
              <g clipPath="url(#folderSVG)">
                <path
                  fill="#FCEBA6"
                  d="M11.98 15H.405a.405.405 0 01-.39-.51l2.628-7.208a.404.404 0 01.39-.299H14.61c.266 0 .39.255.39.51l-2.63 7.209a.404.404 0 01-.39.299z"
                />
                <path
                  fill="#F0D97A"
                  d="M13 4.655h-.586v2.328h1.034v-1.88c0-.247-.2-.448-.448-.448zM1.034 2.845H.448c-.247 0-.448.2-.448.448v11.05c.018 0 .036.002.053.003l.981-2.677V2.845z"
                />
                <path
                  fill="#F4EFDC"
                  d="M2.643 7.282a.404.404 0 01.39-.3h9.381V0H2.586v7.438l.057-.156z"
                />
                <path
                  fill="#CEC9AE"
                  d="M4.397 4.138h6.207a.259.259 0 100-.517H4.397a.259.259 0 100 .517zM4.397 2.328h2.586a.259.259 0 100-.517H4.397a.259.259 0 100 .517zM10.604 5.432H4.397a.259.259 0 100 .517h6.207a.259.259 0 100-.517zM2.586 7.438V.517H1.81v9.048l.776-2.127z"
                />
                <path
                  fill="#D1BF86"
                  d="M1.81 9.565v-8.53h-.775v10.657l.775-2.127z"
                />
              </g>
              <defs>
                <clipPath id="folderSVG">
                  <path fill="#fff" d="M0 0h15v15H0z" />
                </clipPath>
              </defs>
            </svg>
            <span>open</span>
          </button>
        </div>
      </div>
      <ModalList
        name={ModalNames.DECISION_MANAGER_RANGE_LIST}
        title={modalState.title}
        loading={loading}
        buttonMessage={modalState.buttonMessage}
        buttonType={modalState.buttonType}
        onCancel={closeListModal}
        onSubmit={getSelectedDecisionId}
        visible={modalState.visible}
        headers={["name", "description"]}
        data={decisions}
      />
    </div>
  );
};

export default DecisionManagerRange;
