import { getAttr } from "../../../../../layout/StrategyDesigner/StrategyBoard/FlowChart/handlers/methods/attributes";
import {
  code_message_data,
  decision_data,
  EActionTypes,
  ElementTypes,
  function_data,
  mapping_data,
  product_data,
  storage_key,
  validate_data,
} from "../../../../../settings/settings";
import {
  getArrowPropery,
  getContactId,
  getType,
  idToType,
} from "./get_arrow_properties.handler";

export const BoardStorage = (
  element: SVGGElement,
  ActionType: EActionTypes,
  data: any = null
) => {
  const board = document.querySelector("#svg_board") as SVGSVGElement;
  const ElementDataId = element?.dataset.id as string;
  const FindRect = board?.querySelector(
    `rect[data-id = "${ElementDataId}"]`
  )! as SVGRectElement;
  const DataType = FindRect.dataset.type as ElementTypes;
  const { x, y } = getAttr(FindRect);
  const { item, arrows } = selectElement(ElementDataId, DataType);
  if (x && y) {
    switch (DataType) {
      case ElementTypes.STARTPOINT:
        switch (ActionType) {
          case EActionTypes.UP:
            if (ElementDataId) {
              const {
                prev,
                next: { contact, coord, side },
              } = arrows;
              const overlays = [
                { ...item.overlays?.[0],coord: coord, side: side, contact: contact },
              ] as any[];
              let new_item = {
                ...item,
                anchors: [side],
                overlays: overlays,
                style: `left:${x},top:${y}`,
              };
              updateElement(new_item, DataType);
              updatePrevElement(prev, DataType);
            }
            break;
          case EActionTypes.CHAINING:
            if (ElementDataId) {
              contactChaining(ElementDataId);
            }
            break;
        }
        break;
      case ElementTypes.FUNCTION:
        switch (ActionType) {
          case EActionTypes.UP:
            const {
              prev,
              next: { contact, coord, side },
            } = arrows;
            const overlays = [
              { ...item.overlays?.[0],coord: coord, side: side, contact: contact },
            ] as any[];
            let new_item = {
              ...item,
              anchors: [side],
              overlays: overlays,
              style: `left:${x},top:${y}`,
            };
            updateElement(new_item, DataType);
            updatePrevElement(prev, DataType);
            break;
          case EActionTypes.CHAINING:
            if (ElementDataId) {
              contactChaining(ElementDataId);
            }
            break;
          case EActionTypes.ADD:
            if (ElementDataId) {
              addElement(element, DataType, data);
            }
            break;
        }
        break;
      case ElementTypes.PRODUCT:
          switch (ActionType) {
            case EActionTypes.UP:
              const {
                prev,
                next: { contact, coord, side },
              } = arrows;
              const overlays = [
                { ...item.overlays?.[0],coord: coord, side: side, contact: contact },
              ] as any[];
              let new_item = {
                ...item,
                anchors: [side],
                overlays: overlays,
                style: `left:${x},top:${y}`,
              };
              updateElement(new_item, DataType);
              updatePrevElement(prev, DataType);
              break;
            case EActionTypes.CHAINING:
              if (ElementDataId) {
                contactChaining(ElementDataId);
              }
              break;
            case EActionTypes.ADD:
              if (ElementDataId) {
                addElement(element, DataType, data);
              }
              break;
          }
          break;
      case ElementTypes.CONDITION:
        switch (ActionType) {
          case EActionTypes.UP:
            const { prev, next } = arrows;
            const [item1, item2] = next;
            const overlays1 =
              item1?.bool === "yes"
                ? [
                    {
                      coord: item1?.coord,
                      side: item1?.side,
                      contact: item1?.contact,
                    },
                  ]
                : [
                    {
                      coord: item2?.coord,
                      side: item2?.side,
                      contact: item2?.contact,
                    },
                  ];
            const overlays2 =
              item2?.bool === "no"
                ? [
                    {
                      coord: item2?.coord,
                      side: item2?.side,
                      contact: item2?.contact,
                    },
                  ]
                : [
                    {
                      coord: item1?.coord,
                      side: item1?.side,
                      contact: item1?.contact,
                    },
                  ];
            const anchors1 = item1?.bool === "yes" ? item1?.side : item2?.side;
            const anchors2 = item2?.bool === "no" ? item2?.side : item1?.side;
            const targetId1 =
              item1?.bool === "yes"
                ? item1?.contact?.split("-")?.[1]
                : item2?.contact?.split("-")?.[1];
            const targetId2 =
              item2?.bool === "no"
                ? item2?.contact?.split("-")?.[1]
                : item1?.contact?.split("-")?.[1];
            let new_item = [
              {
                ...item[0],
                anchors: [anchors1],
                overlays: overlays1,
                style: `left:${x},top:${y}`,
                targetId: targetId1,
              },
              {
                ...item[1],
                anchors: [anchors2],
                overlays: overlays2,
                style: `left:${x},top:${y}`,
                targetId: targetId2 !== targetId1 ? targetId2 : "",
              },
            ];
            updateElement(new_item, DataType);
            updatePrevElement(prev, DataType);
            break;
          case EActionTypes.CHAINING:
            if (ElementDataId) {
              contactChaining(ElementDataId);
            }
            break;
          case EActionTypes.ADD:
            if (ElementDataId) {
              addElement(element, DataType);
            }
            break;
        }
        break;

      case ElementTypes.MAP:
        switch (ActionType) {
          case EActionTypes.UP:
            const {
              prev,
              next: { contact, coord, side },
            } = arrows;
            const overlays = [
              { ...item.overlays?.[0],coord: coord, side: side, contact: contact },
            ] as any[];
            let new_item = {
              ...item,
              anchors: [side],
              overlays: overlays,
              style: `left:${x},top:${y}`,
            };
            updateElement(new_item, DataType);
            updatePrevElement(prev, DataType);
            break;
          case EActionTypes.CHAINING:
            if (ElementDataId) {
              contactChaining(ElementDataId);
            }
            break;
          case EActionTypes.ADD:
            if (ElementDataId) {
              addElement(element, DataType,data);
            }
            break;
        }
        break;
      case ElementTypes.DECISION:
        switch (ActionType) {
          case EActionTypes.UP:
            const {
              prev,
              next: { contact, coord, side },
            } = arrows;
            const overlays = [
              { ...item.overlays?.[0],coord: coord, side: side, contact: contact },
            ] as any[];
            let new_item = {
              ...item,
              anchors: [side],
              overlays: overlays,
              style: `left:${x},top:${y}`,
            };
            updateElement(new_item, DataType);
            updatePrevElement(prev, DataType);
            break;
          case EActionTypes.CHAINING:
            if (ElementDataId) {
              contactChaining(ElementDataId);
            }
            break;
          case EActionTypes.ADD:
            if (ElementDataId) {
              addElement(element, DataType, data);
            }
            break;
        }
        break;
      case ElementTypes.VALIDATE:
        switch (ActionType) {
          case EActionTypes.UP:
            const {
              prev,
              next: { contact, coord, side },
            } = arrows;
            const overlays = [
              {...item.overlays?.[0], coord: coord, side: side, contact: contact },
            ] as any[];
            let new_item = {
              ...item,
              anchors: [side],
              overlays: overlays,
              style: `left:${x},top:${y}`,
            };
            updateElement(new_item, DataType);
            updatePrevElement(prev, DataType);
            break;
          case EActionTypes.CHAINING:
            if (ElementDataId) {
              contactChaining(ElementDataId);
            }
            break;
          case EActionTypes.ADD:
            if (ElementDataId) {
              addElement(element, DataType);
            }
            break;
        }
        break;
      case ElementTypes.DATA_WIZARD:
        switch (ActionType) {
          case EActionTypes.UP:
            const {
              prev,
              next: { contact, coord, side },
            } = arrows;
            const overlays = [
              {...item.overlays?.[0], coord: coord, side: side, contact: contact },
            ] as any[];
            let new_item = {
              ...item,
              anchors: [side],
              overlays: overlays,
              style: `left:${x},top:${y}`,
            };
            updateElement(new_item, DataType);
            updatePrevElement(prev, DataType);
            break;
          case EActionTypes.CHAINING:
            if (ElementDataId) {
              contactChaining(ElementDataId);
            }
            break;
          case EActionTypes.ADD:
            if (ElementDataId) {
              addElement(element, DataType);
            }
            break;
        }
        break;
      case ElementTypes.MESSAGE:
        switch (ActionType) {
          case EActionTypes.UP:
            const {
              prev,
              next: { contact, coord, side },
            } = arrows;
            const overlays = [
              {...item.overlays?.[0], coord: coord, side: side, contact: contact },
            ] as any[];
            let new_item = {
              ...item,
              anchors: [side],
              overlays: overlays,
              style: `left:${x},top:${y}`,
            };
            updateElement(new_item, DataType);
            updatePrevElement(prev, DataType);
            break;
          case EActionTypes.CHAINING:
            if (ElementDataId) {
              contactChaining(ElementDataId);
            }
            break;
          case EActionTypes.ADD:
            if (ElementDataId) {
              addElement(element, DataType);
            }
            break;
        }
        break;
    }
  }
};

const selectElement = (id: string, type: ElementTypes | null) => {
  const board = document.querySelector("#svg_board") as SVGSVGElement;

  const getItems = localStorage.getItem(storage_key) as string;
  const refItems = [...JSON.parse(getItems)] as any[];

  if (type === ElementTypes.CONDITION) {
    const [findItem] = refItems?.filter(
      (f) => f.sourceId === id && f.branchLabel === "YES"
    ) as any[];
    const [findItem2] = refItems?.filter(
      (f) => f.sourceId === id && f.branchLabel === "NO"
    ) as any[];
    const nextArrows = board?.querySelectorAll(
      `path[data-contact ^= "${id}"]`
    )! as NodeListOf<SVGPathElement>;
    const prevArrows = board?.querySelectorAll(
      `path[data-contact $= "${id}"]`
    )! as NodeListOf<SVGPathElement>;
    const next_mass = [] as any;
    nextArrows?.forEach((nextArrow: SVGPathElement) => {
      const DataContactNext = nextArrow?.dataset?.contact as string;
      const DataCoordNext = nextArrow?.getAttribute("d") as string;
      const DataSideNext = nextArrow?.dataset?.side as string;
      const DataBoolNext = nextArrow?.dataset?.bool as string;
      next_mass.push({
        bool: DataBoolNext,
        side: DataSideNext,
        coord: DataCoordNext,
        contact: DataContactNext,
      });
    });
    return {
      list: refItems,
      item: [findItem, findItem2],
      arrows: {
        prev: prevArrows,
        next: next_mass,
      },
    };
  } else {
    const [findItem] = refItems?.filter((f) => f.sourceId === id) as any[];
    const nextArrow = board?.querySelector(
      `path[data-contact ^= "${id}"]`
    )! as SVGPathElement;
    const prevArrows = board?.querySelectorAll(
      `path[data-contact $= "${id}"]`
    )! as NodeListOf<SVGPathElement>;
    const DataContactNext = nextArrow?.dataset?.contact as string;
    const DataCoordNext = nextArrow?.getAttribute("d") as string;
    const DataSideNext = nextArrow?.dataset?.side as string;

    return {
      list: refItems,
      item: findItem,
      arrows: {
        prev: prevArrows,
        next: {
          contact: DataContactNext,
          coord: DataCoordNext,
          side: DataSideNext,
        },
      },
    };
  }
};
const updateElement = (data: any, type: ElementTypes) => {
  const getItems = localStorage.getItem(storage_key) as string;
  const refItems = [...JSON.parse(getItems)] as any[];
  if (type === ElementTypes.CONDITION) {
    const [item1, item2] = data;
    const [findItem, findItem2] = refItems?.filter(
      (f) => f.sourceId === item1.sourceId
    ) as any[];
    const index1 = refItems.indexOf(findItem) as number;
    const index2 = refItems.indexOf(findItem2) as number;
    refItems[index1] = {
      ...item1,
    };
    refItems[index2] = {
      ...item2,
      style: item1.style,
    };
    localStorage.removeItem(storage_key);
    localStorage.setItem(storage_key, JSON.stringify(refItems));
    return { response: refItems };
  } else if (type === ElementTypes.STARTPOINT) {
    const { sourceId } = data;

    const [findItem] = refItems?.filter(
      (f) => f.sourceId === sourceId
    ) as any[];
    const index = refItems.indexOf(findItem) as number;
    const [checkValidate] = refItems?.filter(
      (f) => f.type === ElementTypes.VALIDATE
    ) as any[];
    if (checkValidate) {
      const { targetId } = checkValidate;
      if (targetId !== ElementTypes.DATA_WIZARD) {
        refItems[index] = {
          ...data,
          targetId: targetId,
        };
      } else {
        const [checkWizard] = refItems?.filter(
          (f) => f.type === ElementTypes.DATA_WIZARD
        ) as any[];
        if (checkWizard) {
          const { targetId: wizardTargetId } = checkWizard;
          refItems[index] = {
            ...data,
            targetId: wizardTargetId,
          };
        }
      }
    } else {
      refItems[index] = {
        ...data,
      };
    }

    localStorage.removeItem(storage_key);
    localStorage.setItem(storage_key, JSON.stringify(refItems));
    return { response: refItems };
  } else if (type === ElementTypes.VALIDATE) {
    const { sourceId } = data;

    const [findItem] = refItems?.filter(
      (f) => f.sourceId === sourceId
    ) as any[];
    const index = refItems.indexOf(findItem) as number;
    const [checkWizard] = refItems?.filter(
      (f) => f.type === ElementTypes.DATA_WIZARD
    ) as any[];
    if (checkWizard) {
      const { sourceId } = checkWizard;
      refItems[index] = {
        ...data,
        targetId: sourceId,
      };
    } else {
      refItems[index] = {
        ...data,
      };
    }

    localStorage.removeItem(storage_key);
    localStorage.setItem(storage_key, JSON.stringify(refItems));
    return { response: refItems };
  } else {
    const { sourceId } = data;

    const [findItem] = refItems?.filter(
      (f) => f.sourceId === sourceId
    ) as any[];
    const index = refItems.indexOf(findItem) as number;
    refItems[index] = {
      ...data,
    };
    localStorage.removeItem(storage_key);
    localStorage.setItem(storage_key, JSON.stringify(refItems));
    return { response: refItems };
  }
};
const updatePrevElement = (
  arrows: NodeListOf<SVGPathElement>,
  type: ElementTypes | null
) => {
  const board = document.querySelector("#svg_board") as SVGSVGElement;

  if (type === ElementTypes.VALIDATE) {
    const getItems = localStorage.getItem(storage_key) as string;
    const refItems = [...JSON.parse(getItems)] as any[];
    const [startPoint] = refItems?.filter(
      (f) => f.type === ElementTypes.STARTPOINT
    ) as any[];
    const index = refItems?.indexOf(startPoint) as number;
    const [checkValidate] = refItems?.filter(
      (f) => f.type === ElementTypes.VALIDATE
    ) as any[];
    if (checkValidate) {
      const { targetId } = checkValidate;
      if (targetId !== ElementTypes.DATA_WIZARD) {
        refItems[index] = {
          ...startPoint,
          targetId: targetId,
        };
      } else {
        const [checkWizard] = refItems?.filter(
          (f) => f.type === ElementTypes.DATA_WIZARD
        ) as any[];
        if (checkWizard) {
          const { targetId: wizardTargetId } = checkWizard;
          refItems[index] = {
            ...startPoint,
            targetId: wizardTargetId,
          };
        }
      }
    }
    localStorage.removeItem(storage_key);
    localStorage.setItem(storage_key, JSON.stringify(refItems));
  } else {
    arrows?.forEach((arrow: SVGPathElement) => {
      const {
        contact: arrowContactPrev,
        coord: arrowCoordPrev,
        side: arrowSidePrev,
        bool,
      } = getArrowPropery(arrow);
      const { prev: elementDataId } = getContactId(arrowContactPrev);
      const getElement = board?.querySelector(
        `rect[data-id = "${elementDataId}"]`
      ) as SVGRectElement;
      const { type } = getAttr(getElement);
      if (type) {
        const { typeResult } = getType(type);

        const { list, item } = selectElement(elementDataId, typeResult);
        if (typeResult === ElementTypes.CONDITION) {
          const [itemYes, itemNo] = item;
          const _item = bool === "yes" ? itemYes : itemNo;
          const overlays = [
            {
              coord: arrowCoordPrev,
              side: arrowSidePrev,
              contact: arrowContactPrev,
            },
          ] as any[];
          const new_item = {
            ..._item,
            anchors: [arrowSidePrev],
            overlays: overlays,
          };

          const [findItem] = list?.filter(
            (f) =>
              f.sourceId === elementDataId &&
              f.branchLabel === bool.toUpperCase()
          ) as any[];
          const index = list.indexOf(findItem) as number;
          list[index] = {
            ...new_item,
          };
        } else {
          const overlays = [
            {
              ...item.overlays?.[0],
              coord: arrowCoordPrev,
              side: arrowSidePrev,
              contact: arrowContactPrev,
            },
          ] as any[];
          const new_item = {
            ...item,
            anchors: [arrowSidePrev],
            overlays: overlays,
          };

          const [findItem] = list?.filter(
            (f) => f.sourceId === elementDataId
          ) as any[];
          const index = list.indexOf(findItem) as number;
          list[index] = {
            ...new_item,
          };
        }

        localStorage.removeItem(storage_key);
        localStorage.setItem(storage_key, JSON.stringify(list));
      }
    });
  }
};

const contactChaining = (id: string) => {
  const board = document.querySelector("#svg_board") as SVGSVGElement;
  const getItems = localStorage.getItem(storage_key) as string;
  const refItems = [...JSON.parse(getItems)] as any[];

  const findElement = board?.querySelector(
    `rect[data-id = "${id}"]`
  )! as SVGRectElement;
  if (findElement) {
    const dataType = findElement.dataset.type as string;

    if (dataType === ElementTypes.VALIDATE) {
      const arrow = board?.querySelector(
        `path[data-contact ^= "${id}"]`
      )! as SVGPathElement;
      const [findItem] = refItems?.filter((f) => f.sourceId === id) as any[];
      const index = refItems.indexOf(findItem) as number;
      if (arrow) {
        const contact = arrow.dataset.contact as string;
        const side = arrow.dataset.side as string;
        const coord = arrow.getAttribute("d") as string;
        refItems[index] = {
          ...findItem,
          anchors: [side],
          targetId: contact?.split("-")?.[1],
          overlays: [{...findItem.overlays?.[0], coord: coord, side: side, contact: contact }],
        };
        const [startPoint] = refItems?.filter(
          (f) => f.type === ElementTypes.STARTPOINT
        ) as any[];
        if (startPoint) {
          const index_start_point = refItems?.indexOf(startPoint) as number;
          refItems[index_start_point] = {
            ...startPoint,
            targetId: contact?.split("-")?.[1],
          };
        }

        localStorage.removeItem(storage_key);
        localStorage.setItem(storage_key, JSON.stringify(refItems));
      }
    } else if (dataType === ElementTypes.DATA_WIZARD) {
      const arrow = board?.querySelector(
        `path[data-contact ^= "${id}"]`
      )! as SVGPathElement;
      const [findItem] = refItems?.filter((f) => f.sourceId === id) as any[];
      const index = refItems.indexOf(findItem) as number;
      if (arrow) {
        const contact = arrow.dataset.contact as string;
        const side = arrow.dataset.side as string;
        const coord = arrow.getAttribute("d") as string;
        refItems[index] = {
          ...findItem,
          anchors: [side],
          targetId: contact?.split("-")?.[1],
          overlays: [{...findItem.overlays?.[0],  coord: coord, side: side, contact: contact }],
        };
        const [startPoint] = refItems?.filter(
          (f) => f.type === ElementTypes.STARTPOINT
        ) as any[];
        if (startPoint) {
          const index_start_point = refItems?.indexOf(startPoint) as number;
          refItems[index_start_point] = {
            ...startPoint,
            targetId: contact?.split("-")?.[1],
          };
        }

        localStorage.removeItem(storage_key);
        localStorage.setItem(storage_key, JSON.stringify(refItems));
      }
    } else {
      const arrow = board?.querySelector(
        `path[data-contact ^= "${id}"]`
      )! as SVGPathElement;
      const { type } = idToType(id);

      if (type === ElementTypes.CONDITION) {
        if (arrow) {
          const { contact, coord, side, bool } = getArrowPropery(arrow);
          const [findItem] = refItems?.filter(
            (f) => f.sourceId === id && f.branchLabel === bool?.toUpperCase()
          ) as any[];
          const index = refItems.indexOf(findItem) as number;

          refItems[index] = {
            ...findItem,
            anchors: [side],
            targetId: contact?.split("-")?.[1],
            overlays: [{...findItem.overlays?.[0],  coord: coord, side: side, contact: contact }],
          };
        }
      } else {
        const [findItem] = refItems?.filter((f) => f.sourceId === id) as any[];
        const index = refItems.indexOf(findItem) as number;
        if (arrow) {
          const contact = arrow.dataset.contact as string;
          const side = arrow.dataset.side as string;
          const coord = arrow.getAttribute("d") as string;
          refItems[index] = {
            ...findItem,
            anchors: [side],
            targetId: contact?.split("-")?.[1],
            overlays: [{ ...findItem?.overlays?.[0],coord: coord, side: side, contact: contact }],
          };
        }
      }
      localStorage.removeItem(storage_key);
      localStorage.setItem(storage_key, JSON.stringify(refItems));
    }
  }
};

const addElement = (
  element: SVGGElement,
  type: ElementTypes,
  input_data: any = null
) => {
  const id = element?.dataset?.id;
  const board = document.querySelector("#svg_board") as SVGSVGElement;
  const getItems = localStorage.getItem(storage_key) as string;
  const refItems = [...JSON.parse(getItems)] as any[];
  if (type === ElementTypes.VALIDATE) {
    const validate = element?.querySelector(
      `rect[data-id = "${id}"]`
    )! as SVGRectElement;
    const { x, y } = getAttr(validate);
    if (validate && x && y) {
      const prev_arrow = board?.querySelector(
        `path[data-contact $= "${id}"]`
      )! as SVGPathElement;
      const next_arrow = board?.querySelector(
        `path[data-contact ^= "${id}"]`
      )! as SVGPathElement;

      if (prev_arrow) {
        const contact = prev_arrow.dataset.contact as string;
        const side = prev_arrow.dataset.side as string;
        const coord = prev_arrow.getAttribute("d") as string;

        const next_id = next_arrow?.dataset?.contact?.split("-")?.[1] as string;
        const contact_next = next_arrow?.dataset?.contact as string;
        const side_next = next_arrow?.dataset?.side as string;
        const coord_next = next_arrow?.getAttribute("d") as string;

        const [startPoint] = refItems?.filter(
          (f) => f.type === ElementTypes.STARTPOINT
        ) as any[];
        if (startPoint) {
          const index_start_point = refItems?.indexOf(startPoint) as number;
          refItems[index_start_point] = {
            ...startPoint,
            anchors: [side],
            overlays: [
              {
                side: side,
                coord: coord,
                contact: contact,
              },
            ],
            targetId: next_id,
          };

          refItems.push({
            ...validate_data,
            sourceId: id,
            overlays: [
              {
                side: side_next,
                coord: coord_next,
                contact: contact_next,
              },
            ],
            anchors: [side_next],
            targetId: contact_next?.split("-")?.[1],
            style: `left:${x}px,top:${y}px`,
          });
        }
      }
    }
  } else if (type === ElementTypes.DATA_WIZARD) {
    const wizard = element?.querySelector(
      `rect[data-id = "${id}"]`
    )! as SVGRectElement;
    const { x, y } = getAttr(wizard);
    if (wizard && x && y) {
      const prev_arrow = board?.querySelector(
        `path[data-contact $= "${id}"]`
      )! as SVGPathElement;
      const next_arrow = board?.querySelector(
        `path[data-contact ^= "${id}"]`
      )! as SVGPathElement;

      if (prev_arrow) {
        const contact = prev_arrow?.dataset.contact as string;
        const side = prev_arrow?.dataset.side as string;
        const coord = prev_arrow?.getAttribute("d") as string;

        // const next_id = next_arrow?.dataset?.contact?.split('-')?.[1] as string;
        const contact_next = next_arrow?.dataset.contact as string;
        const side_next = next_arrow?.dataset.side as string;
        const coord_next = next_arrow?.getAttribute("d") as string;

        const [startPoint] = refItems?.filter(
          (f) => f.type === ElementTypes.STARTPOINT
        ) as any[];
        const [getWizard] = refItems?.filter(
          (f) => f.type === ElementTypes.DATA_WIZARD
        ) as any[];
        const [getValidate] = refItems?.filter(
          (f) => f.type === ElementTypes.VALIDATE
        ) as any[];

        if (startPoint && getValidate && getWizard) {
          const index_start_point = refItems?.indexOf(startPoint) as number;
          refItems[index_start_point] = {
            ...startPoint,

            targetId: contact_next?.split("-")?.[1],
          };
          const index_wizard = refItems?.indexOf(getWizard) as number;
          refItems[index_wizard] = {
            ...getWizard,
            sourceId: id,
            overlays: [
              {
                side: side_next,
                coord: coord_next,
                contact: contact_next,
              },
            ],
            anchors: [side_next],
            targetId: contact_next?.split("-")?.[1],
            style: `left:${x}px,top:${y}px`,
          };

          const index_validate = refItems?.indexOf(getValidate) as number;
          refItems[index_validate] = {
            ...getValidate,
            overlays: [
              {
                side: side,
                coord: coord,
                contact: contact,
              },
            ],
            anchors: [side],
            targetId: id,
          };
        }
      }
    }
  } else if (type === ElementTypes.CONDITION) {
    const conditionElement = element?.querySelector(
      `rect[data-id = "${id}"]`
    )! as SVGRectElement;
    const { x, y, type } = getAttr(conditionElement);
    if (conditionElement && x && y) {
      const prev_arrow = board?.querySelector(
        `path[data-contact $= "${id}"]`
      )! as SVGPathElement;
      if (prev_arrow) {
        const {
          contact: contact_prev,
          coord: coord_prev,
          side: side_prev,
        } = getArrowPropery(prev_arrow);
        const { prev: prev_first_id } = getContactId(contact_prev);
        if (prev_first_id) {
          const prev_element = board?.querySelector(
            `rect[data-id = "${prev_first_id}"]`
          )! as SVGRectElement;
          const { type: prev_type } = getAttr(prev_element);
          if (prev_type) {
            if (prev_type === ElementTypes.DATA_WIZARD) {
              const [getWizard] = refItems?.filter(
                (f) => f.type === ElementTypes.DATA_WIZARD
              ) as any[];
              const [startPoint] = refItems?.filter(
                (f) => f.type === ElementTypes.STARTPOINT
              ) as any[];

              const index_wizard = refItems?.indexOf(getWizard) as number;
              refItems[index_wizard] = {
                ...getWizard,
                overlays: [
                  {
                    side: side_prev,
                    coord: coord_prev,
                    contact: contact_prev,
                  },
                ],
                anchors: [side_prev],
                targetId: id,
              };
              const index_start_point = refItems?.indexOf(startPoint) as number;
              refItems[index_start_point] = {
                ...startPoint,
                targetId: id,
              };
            } else if (prev_type === ElementTypes.VALIDATE) {
              const [getValidate] = refItems?.filter(
                (f) => f.type === ElementTypes.VALIDATE
              ) as any[];
              const [startPoint] = refItems?.filter(
                (f) => f.type === ElementTypes.STARTPOINT
              ) as any[];

              const index_validate = refItems?.indexOf(getValidate) as number;
              refItems[index_validate] = {
                ...getValidate,
                overlays: [
                  {
                    side: side_prev,
                    coord: coord_prev,
                    contact: contact_prev,
                  },
                ],
                anchors: [side_prev],
                targetId: id,
              };
              const index_start_point = refItems?.indexOf(startPoint) as number;
              refItems[index_start_point] = {
                ...startPoint,
                targetId: id,
              };
            } else {
              const [prevElement] = refItems?.filter(
                (f) => f.sourceId === prev_first_id
              ) as any[];
              const index_prev_element = refItems?.indexOf(
                prevElement
              ) as number;
              refItems[index_prev_element] = {
                ...prevElement,
                anchors: [side_prev],
                overlays: [
                  { 
                    ...prevElement.overlays?.[0],
                    side: side_prev,
                    contact: contact_prev,
                    coord: coord_prev,
                  },
                ],
                targetId: id,
              };
            }
          }
        }
      } else {
        const data =
          type === ElementTypes.FUNCTION
            ? function_data
            : type === ElementTypes.MAP
            ? mapping_data
            : type === ElementTypes.DECISION
            ? decision_data
            : type === ElementTypes.PRODUCT 
            ? product_data
            : null;
        refItems.push({
          ...data,
          overlays: [],
          targetId: "",
          sourceId: id,
          style: `left:${x}px,top:${y}px`,
        });
      }
    }
  } else {
    const otherElement = element?.querySelector(
      `rect[data-id = "${id}"]`
    )! as SVGRectElement;
    const { x, y } = getAttr(otherElement);
    if (otherElement && x && y) {
      const prev_arrow = board?.querySelector(
        `path[data-contact $= "${id}"]`
      )! as SVGPathElement;
      const next_arrow = board?.querySelector(
        `path[data-contact ^= "${id}"]`
      )! as SVGPathElement;
      if (prev_arrow) {
        const {
          contact: contact_prev,
          coord: coord_prev,
          side: side_prev,
          bool,
        } = getArrowPropery(prev_arrow);
        const { prev: prev_first_id } = getContactId(contact_prev);
        if (prev_first_id) {
          const prev_element = board?.querySelector(
            `rect[data-id = "${prev_first_id}"]`
          )! as SVGRectElement;
          const { type: prev_type } = getAttr(prev_element);
          if (prev_type) {
            if (prev_type === ElementTypes.DATA_WIZARD) {
              const [getWizard] = refItems?.filter(
                (f) => f.type === ElementTypes.DATA_WIZARD
              ) as any[];
              const [startPoint] = refItems?.filter(
                (f) => f.type === ElementTypes.STARTPOINT
              ) as any[];

              const index_wizard = refItems?.indexOf(getWizard) as number;
              refItems[index_wizard] = {
                ...getWizard,
                overlays: [
                  {
                    side: side_prev,
                    coord: coord_prev,
                    contact: contact_prev,
                  },
                ],
                anchors: [side_prev],
                targetId: id,
              };
              const index_start_point = refItems?.indexOf(startPoint) as number;
              refItems[index_start_point] = {
                ...startPoint,
                targetId: id,
              };
            } else if (prev_type === ElementTypes.VALIDATE) {
              const [getValidate] = refItems?.filter(
                (f) => f.type === ElementTypes.VALIDATE
              ) as any[];
              const [startPoint] = refItems?.filter(
                (f) => f.type === ElementTypes.STARTPOINT
              ) as any[];

              const index_validate = refItems?.indexOf(getValidate) as number;
              refItems[index_validate] = {
                ...getValidate,
                overlays: [
                  {
                    side: side_prev,
                    coord: coord_prev,
                    contact: contact_prev,
                  },
                ],
                anchors: [side_prev],
                targetId: id,
              };
              const index_start_point = refItems?.indexOf(startPoint) as number;
              refItems[index_start_point] = {
                ...startPoint,
                targetId: id,
              };
            } else if (prev_type === ElementTypes.CONDITION) {
              const [conditionElement] = refItems?.filter(
                (f) =>
                  f.type === ElementTypes.CONDITION &&
                  f.sourceId === prev_first_id &&
                  f.branchLabel === bool.toUpperCase()
              ) as any[];
              const index_condition = refItems?.indexOf(
                conditionElement
              ) as number;
              refItems[index_condition] = {
                ...conditionElement,
                overlays: [
                  {
                    side: side_prev,
                    coord: coord_prev,
                    contact: contact_prev,
                  },
                ],
                anchors: [side_prev],
                targetId: id,
              };
            } else {
              const [prevElement] = refItems?.filter(
                (f) => f.sourceId === prev_first_id
              ) as any[];
              const index_prev_element = refItems?.indexOf(
                prevElement
              ) as number;
              refItems[index_prev_element] = {
                ...prevElement,
                anchors: [side_prev],
                overlays: [
                  {
                    ...prevElement.overlays?.[0],
                    side: side_prev,
                    contact: contact_prev,
                    coord: coord_prev,
                  },
                ],
                targetId: id,
              };
            }
            const data =
              type === ElementTypes.FUNCTION
                ? function_data
                : type === ElementTypes.MAP
                ? mapping_data
                : type === ElementTypes.DECISION
                ? decision_data
                : type === ElementTypes.MESSAGE
                ? code_message_data
                : type === ElementTypes.PRODUCT 
                ? product_data
                : null as any;

            if (next_arrow) {
              const {
                contact: contact_next,
                coord: coord_next,
                side: side_next,
              } = getArrowPropery(next_arrow);
              const { next: next_id } = getContactId(contact_next);
              refItems.push({
                ...data,
                data: input_data ? input_data : data?.data,

                overlays: [
                  {
                    ...data.overlays?.[0],
                    side: side_next,
                    coord: coord_next,
                    contact: contact_next,
                  },
                ],
                anchors: [side_next],
                sourceId: id,
                targetId: next_id,
                style: `left:${x}px,top:${y}px`,
              });
            } else {
              const { bool } = getArrowPropery(prev_arrow);
              refItems.push({
                ...data,
                data: input_data ? input_data : data?.data,

                overlays: [],
                targetId: bool?.toUpperCase() === "NO" ? "endpoint" : "",
                sourceId: id,
                style: `left:${x}px,top:${y}px`,
              });
            }
          }
        }
      } else {
        const data =
          type === ElementTypes.FUNCTION
            ? function_data
            : type === ElementTypes.MAP
            ? mapping_data
            : type === ElementTypes.DECISION
            ? decision_data
            : type === ElementTypes.PRODUCT 
            ? product_data
            : null;
        refItems.push({
          ...data,
          data: input_data ? input_data : data?.data,
          overlays: [],
          targetId: "",
          sourceId: id,
          style: `left:${x}px,top:${y}px`,
        });
      }
    }
  }
  localStorage.removeItem(storage_key);
  localStorage.setItem(storage_key, JSON.stringify(refItems));
};
