import DB from "../../../../../layout/DB/Storage";
import { EActionTypes, storage_key } from "../../../../../settings/settings";
import { create_arrow } from "./create_arrow/create_arrow.handler";
import { BoardStorage } from "./storage.helper";

export const find_contact = ()=>{
    const svg = document.querySelector(`#svg_board`) as SVGElement;
    let search  = svg.querySelector(`rect[data-target]`) as SVGRectElement;
    if(search){
        let target_element_parent = search.parentNode?.parentNode?.parentNode as SVGGElement;
        let target_element = target_element_parent.querySelector(`rect[id = "figure"]`) as SVGRectElement;
        let source_element_name:any = search.getAttribute('data-target');

        if(source_element_name){
            let source_element = svg.querySelector(`rect[data-id = ${source_element_name}]`) as SVGRectElement;
            if(source_element && target_element){
                const parent = source_element?.parentNode as SVGGElement;
                create_arrow(source_element,target_element,search.dataset.side,true);

                new DB(storage_key).fetch({sourceId:source_element_name})
                .then((res:any)=>{
                    const {status,data} = res;
                    if(status && data){
                        if(Array.isArray(data)){

                        
                            BoardStorage(parent,EActionTypes.ADD)
                           
                        }else{
                            const {data:obj_data} = data;
                            new DB(storage_key).remove({sourceId:source_element_name})
                            BoardStorage(parent,EActionTypes.ADD,obj_data)
                        }
                        

                    }
                })

                search.removeAttributeNS(null,'data-target')
            }
           
        }
    }
}