import { ChangeableStatus, CHANGEABLE_STATUS, StrategyActionsTypes } from "../../../../store/types/typesStrategy";

const initialState:ChangeableStatus = {
    status: false
}

const reducer = (state = initialState, action:StrategyActionsTypes):ChangeableStatus => {
    switch (action.type) {
        case CHANGEABLE_STATUS:
            const {status} = action.payload
            
            return {
                status:status
            }
    
        default:
            return state;
    }
}

export default reducer