import { splitBoard } from "helpers/split_board";
import { add_move } from "../../add_storage.handler";
import { moveArrowBox } from "../../arrow_box.handler";
import { change_destination } from "../../change_destination_arrow/main";
import { condition_label } from "../../condition_arrow.handler";
import { move_text } from "../../element_text.handler";
import { getArrowPropery } from "../../get_arrow_properties.handler";
import { getAttr } from "../../methods/attributes";

export const move_right = (next_arrow: SVGPathElement, next_element: SVGRectElement) => {
    const svg = document.querySelector(`#svg_board`) as SVGSVGElement;
    const contact = next_arrow?.dataset.contact;
    const source_id: string | undefined = contact?.split('-')?.[0];
    
    const svg_border_right = svg?.x?.baseVal?.value + svg?.width?.baseVal?.value as number;
    const svg_border_bottom = svg?.y?.baseVal?.value + svg?.height?.baseVal?.value as number;

    const output = svg.querySelector('rect[data-id = "output_format"]') as SVGRectElement;
    const { x: output_x, width: output_width } = getAttr(output) as any;

    if (source_id) {
        const source_element = svg.querySelector(`rect[data-id = "${source_id}"]`) as SVGRectElement;
        if (source_element) {
            const { x: s_x, y: s_y, height: s_height, width: s_width } = getAttr(source_element);
            const {
                height: t_height, width: t_width, type,id:t_id } = getAttr(next_element);

                if (s_x && s_width && s_y && s_height && t_height && t_width) {

                    const draw = next_arrow?.getAttribute('d');
                    const split_draw: string[] | undefined = draw?.split(',');
                    if (split_draw) {
                        const h1 = split_draw?.[1];
                        const v = +split_draw?.[2]?.split('v')?.[1];

                        if (type !== 'endpoint') {
                               
                                    next_element.setAttributeNS(null, 'x', `${s_x + s_width + (+h1.split('h')[h1.split('h').length - 1] * 2) + 32}`);
                                    next_element.setAttributeNS(null, 'y', `${s_y + s_height / 2 + v - t_height / 2}`);

                                    move_text(next_element);
                                    const data = {x:s_x + s_width + (+h1.split('h')[h1.split('h').length - 1] * 2) + 32,y:s_y + s_height / 2 + v - t_height / 2,id:t_id}
                                    splitBoard("move",data)
                                    let element = next_element.parentNode as SVGGElement;
                                    add_move(element, s_x + s_width + (+h1.split('h')[h1.split('h').length - 1] * 2) + 32);

                                    if (next_element.dataset.type === 'condt') {
                                        const condt_arrows = svg.querySelectorAll(`path[data-contact ^= "${next_element.dataset.id}-"]`) as NodeList;
                                        condt_arrows?.forEach((condt_arrow: any) => {
                                            condition_label(condt_arrow)
                                        })

                                        let figure2 = next_element.parentNode?.children[1] as SVGRectElement;
                                        let cond_x: any = next_element.getAttribute('x');
                                        let cond_y: any = next_element.getAttribute('y');
                                        let figure2_x: any = +(Math.sqrt((70 * 70) / 2) - 70 / 2).toFixed(2);
                                        figure2.setAttributeNS(null, 'x', +cond_x - 4 + figure2_x);
                                        figure2.setAttributeNS(null, 'y', +cond_y - 4 + figure2_x);
                                        figure2.setAttributeNS(null, 'transform', `rotate(45 ${s_x + s_width + (+h1.split('h')[h1.split('h').length - 1] * 2) + 32 + t_width / 2} ${s_y + s_height / 2 + v})`)

                                    }
                        }else{
                            if (svg_border_right - (output_x+output_width) > 157) {
                                next_element.setAttributeNS(null, 'x', `${s_x + s_width + (+h1.split('h')[h1.split('h').length - 1] * 2) + 32}`);
                                next_element.setAttributeNS(null, 'y', `${s_y + s_height / 2 + v - t_height / 2}`);

                                move_text(next_element);
                                let element = next_element.parentNode as SVGGElement;
                                add_move(element, s_x + s_width + (+h1.split('h')[h1.split('h').length - 1] * 2) + 32);

                              
                            }else{
                                next_element.setAttributeNS(null, 'x', `${svg_border_right-335}`);
                                next_element.setAttributeNS(null, 'y', `${svg_border_bottom-265}`);

                                move_text(next_element);
                                let element = next_element.parentNode as SVGGElement;
                                add_move(element,svg_border_right-335);
                            }
                               
                        }
                    }





                
            }

            const next_arrow_first_id = contact?.split('-')?.[0];

            if (source_id === next_arrow_first_id) {
                const { y: t_y } = getAttr(next_element) as any;
                if (s_y && s_height && s_x && s_width && t_y) {
                    const draw = next_arrow?.getAttribute('d');
                    const split_draw: string[] | undefined = draw?.split(',');

                    if (split_draw) {

                        
                        if (type !== 'endpoint') {

                            
                                const [, h1, v, h2, ...coord] = split_draw;
                                const mx: number = s_x + s_width + 12
                                const my: number = s_y + s_height / 2
                                const new_m = `M${mx} ${my}`;
                                const new_d = [new_m, h1, v, h2, coord].join(',');
                                next_arrow.setAttributeNS(null, 'd', new_d);
                                moveArrowBox(next_element, { x: s_x + s_width + (+h1.split('h')[h1.split('h').length - 1] * 2) + 32, y: t_y })

                            

                            
                        } else {
                            if (svg_border_right - (output_x+output_width) > 157) {
                                const [, h1, v, h2, ...coord] = split_draw;
                                const mx: number = s_x + s_width + 12
                                const my: number = s_y + s_height / 2
                                const new_m = `M${mx} ${my}`;
                                const new_d = [new_m, h1, v, h2, coord].join(',');
                                next_arrow.setAttributeNS(null, 'd', new_d)
                                moveArrowBox(next_element, { x: s_x + s_width + (+h1.split('h')[h1.split('h').length - 1] * 2) + 32, y: t_y })
                            }else{
                               
                                change_destination(source_element)
                            }
                            
                        }
                        if(source_id.includes('condition')){
                            const {bool} = getArrowPropery(next_arrow);
                            if(!bool){
                                next_arrow.setAttributeNS(null,'data-bool','yes')
                            }
                        }
                    }


                }
            }
        }
    }



}

