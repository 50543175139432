import { addArrowBox } from "./arrow_box.handler";
import { create_text } from "./element_text.handler";
import { listener, text_listener } from "./event_listener.handler";
import { generateUid } from "./generate_uid.handler";
import { createShadow } from "./shadow.handler";
import {  icons } from "../../../../../settings/settings";

export const createElement = (
  element: string,
  data: any,
  status: any = null,
  type: any = null
) => {
  
  let xmlns = "http://www.w3.org/2000/svg";
  let newElement: any = null;
  let groupElement = document.createElementNS(xmlns, "g") as SVGGElement;
  if (!status) {
    let uid_group: any = generateUid("data-id", element);

    groupElement.setAttributeNS(null, "data-id", uid_group);
    groupElement.setAttributeNS(null, "id", uid_group);
  } else {
    groupElement.setAttributeNS(null, "data-id", data["data-id"]);
    groupElement.setAttributeNS(null, "id", data["data-id"]);
  }

  groupElement.setAttributeNS(null, "data-type", "figure");

  switch (element) {
    case "startpoint":
      newElement = document.createElementNS(xmlns, "rect") as SVGRectElement;
      newElement.setAttributeNS(
        null,
        "data-id",
        generateUid("data-id", element)
      );

      newElement.setAttributeNS(null, "data-type", "startpoint");

      for (const [key, value] of Object.entries(data)) {
        newElement.setAttributeNS(null, key, value);
      }

      break;
    case "validate":
      newElement = document.createElementNS(xmlns, "rect") as SVGRectElement;
      newElement.setAttributeNS(
        null,
        "data-id",
        generateUid("data-id", element)
      );

      newElement.setAttributeNS(null, "data-type", "validate");

      for (const [key, value] of Object.entries(data)) {
        newElement.setAttributeNS(null, key, value);
      }
      break;
    case "datawizard":
      newElement = document.createElementNS(xmlns, "rect") as SVGRectElement;
      newElement.setAttributeNS(
        null,
        "data-id",
        generateUid("data-id", element)
      );

      newElement.setAttributeNS(null, "data-type", "datawizard");

      for (const [key, value] of Object.entries(data)) {
        newElement.setAttributeNS(null, key, value);
      }
      break;
    case "function":
      newElement = document.createElementNS(xmlns, "rect") as SVGRectElement;
      if (!status) {
        newElement.setAttributeNS(
          null,
          "data-id",
          generateUid("data-id", element)
        );
      } else {
        newElement.setAttributeNS(null, "data-id", data["data-id"]);
      }
      newElement.setAttributeNS(null, "data-type", "func");

      for (const [key, value] of Object.entries(data)) {
        newElement.setAttributeNS(null, key, value);
      }

      break;
    case "scorecard":
      newElement = document.createElementNS(xmlns, "rect") as SVGRectElement;
      if (!status) {
        newElement.setAttributeNS(
          null,
          "data-id",
          generateUid("data-id", element)
        );
      } else {
        newElement.setAttributeNS(null, "data-id", data["data-id"]);
      }
      newElement.setAttributeNS(null, "data-type", 'scorecard');

      for (const [key, value] of Object.entries(data)) {
        newElement.setAttributeNS(null, key, value);
      }

      break;
    case "condition":
      newElement = document.createElementNS(xmlns, "rect") as SVGRectElement;
      if (!status) {
        newElement.setAttributeNS(
          null,
          "data-id",
          generateUid("data-id", element)
        );
      } else {
        newElement.setAttributeNS(null, "data-id", data["data-id"]);
      }
      newElement.setAttributeNS(null, "data-type", "condt");

      for (const [key, value] of Object.entries(data)) {
        newElement.setAttributeNS(null, key, value);
      }

      break;
    case "codemessage":
      newElement = document.createElementNS(xmlns, "rect") as SVGRectElement;
      if (!status) {
        newElement.setAttributeNS(
          null,
          "data-id",
          generateUid("data-id", element)
        );
      } else {
        newElement.setAttributeNS(null, "data-id", data["data-id"]);
      }
      newElement.setAttributeNS(null, "data-type", "code_message");

      for (const [key, value] of Object.entries(data)) {
        newElement.setAttributeNS(null, key, value);
      }
      break;
    case "decision":
      newElement = document.createElementNS(xmlns, "rect") as SVGRectElement;
      if (!status) {
        newElement.setAttributeNS(
          null,
          "data-id",
          generateUid("data-id", element)
        );
      } else {
        newElement.setAttributeNS(null, "data-id", data["data-id"]);
      }
      if (type) {
        newElement.setAttributeNS(null, "data-decision", type);
      }
      newElement.setAttributeNS(null, "data-type", "decision_mapp");

      for (const [key, value] of Object.entries(data)) {
        newElement.setAttributeNS(null, key, value);
      }

      break;
    case "product":
      newElement = document.createElementNS(xmlns, "rect") as SVGRectElement;
      if (!status) {
        newElement.setAttributeNS(
          null,
          "data-id",
          generateUid("data-id", element)
        );
      } else {
        newElement.setAttributeNS(null, "data-id", data["data-id"]);
      }
        newElement.setAttributeNS(null, "data-type", "product");

      for (const [key, value] of Object.entries(data)) {
        newElement.setAttributeNS(null, key, value);
      }

      break;
    case "endpoint":
      newElement = document.createElementNS(xmlns, "rect") as SVGRectElement;
      newElement.setAttributeNS(
        null,
        "data-id",
        generateUid("data-id", element)
      );

      newElement.setAttributeNS(null, "data-type", "endpoint");

      for (const [key, value] of Object.entries(data)) {
        newElement.setAttributeNS(null, key, value);
      }

      break;
    default:
      newElement = document.createElementNS(xmlns, element) as SVGElement;
      break;
  }

  const { element: new_element } = listener(newElement);


  let result: any = null;
  if (element === "codemessage") {
    groupElement.appendChild(newElement);
    groupElement.appendChild(createShadow("figure"));
    result = groupElement;
  } else {
    result = addArrowBox(groupElement, new_element, createShadow("figure"), []);
  }
  let text = create_text(new_element);

  result.appendChild(text);
  text_listener(text);

  if (element) {
    const decision_type = newElement?.dataset?.decision as string;
    let elem: any =
      element === "function"
        ? icons.function
        : element === "scorecard"
        ? icons.mapping
        : element === "decision"
        ?(decision_type === 'matrix' ? icons.decision_matrix:icons.range)
        : element === "datawizard"
        ? icons.dataWizard
        : element === "product"
        ? icons.product
        :null;

    if (elem) {
      fetch(elem)
        .then((r) => r.text())
        .then((res: string) => {
          let parser: any = new DOMParser();
          let doc: any = parser.parseFromString(res, "text/html");
          let icon = doc.body.firstChild;

          let x: any = new_element.getAttribute("x");
          let y: any = new_element.getAttribute("y");
          let _x =
            element === "function"
              ? +x - 8
              : element === "scorecard"
              ? +x - 18
              : element === "decision"
              ? +x - 10
              : element === "datawizard"
              ? +x - 12
              : element === "product"
              ? +x
              : 0;
          let _y =
            element === "function"
              ? +y - 15
              : element === "scorecard"
              ? +y - 11
              : element === "decision"
              ? +y - 12
              : element === "datawizard"
              ? +y - 15
              : element === "product"
              ? +y - 11
              : 0;
          if (icon) {
            icon.setAttributeNS(null, "x", `${_x}`);
            icon.setAttributeNS(null, "y", `${_y}`);
            icon.setAttributeNS(null, "id", `title`);
          }

          result.firstChild.before(icon);
        });
    }
  }

  return result;
};

export const zIndex = (link: string = "") => {
  let svg: any = document.querySelector("#svg_board") as SVGElement;
  let xmlns = "http://www.w3.org/2000/svg";

  let find_use = svg?.querySelector("use") as SVGUseElement;
  if (find_use) {
    find_use.setAttributeNS(
      "http://www.w3.org/1999/xlink",
      "xlink:href",
      `#${link}`
    );
  } else {
    let use = document.createElementNS(xmlns, "use") as SVGUseElement;
    use.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", "");
    svg.appendChild(use);
  }
};
