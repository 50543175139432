import { move_bottom_left_top } from "./bottom/move_bottom_left_top";
import { move_bottom_right_top } from "./bottom/move_bottom_right_top";
import { move_bottom } from "./bottom/move_bottom";
import { move_bottom_left } from "./bottom/move_bottom_left";
import { move_bottom_right } from "./bottom/move_bottom_right";
import { move_left } from "./left/move_left";
import { move_left_bottom } from "./left/move_left_bottom";
import { move_left_bottom_right } from "./left/move_left_bottom_right";
import { move_left_top } from "./left/move_left_top";
import { move_left_top_right } from "./left/move_left_top_right";
import { move_right } from "./right/move_right";
import { move_right_bottom } from "./right/move_right_bottom";
import { move_right_bottom_left } from "./right/move_right_bottom_left";
import { move_right_top } from "./right/move_right_top";
import { move_right_top_left } from "./right/move_right_top_left";
import { move_top } from "./top/move_top";
import { move_top_left } from "./top/move_top_left";
import { move_top_left_bottom } from "./top/move_top_left_bottom";
import { move_top_right } from "./top/move_top_right";
import { move_top_right_bottom } from "./top/move_top_right_bottom";

export const move_auto = (target_element: SVGRectElement, first_element: SVGRectElement | any = null, drop: boolean = false) => {
    const svg = document.querySelector(`#svg_board`) as SVGElement;
    const new_id = target_element?.dataset.id;
    const find_next_arrows = svg.querySelectorAll(`path[data-contact ^= "${new_id}-"]`) as NodeListOf<SVGPathElement>;
    if (find_next_arrows) {
        find_next_arrows?.forEach((find_next_arrow: any) => {
            const contact: string | undefined = find_next_arrow.dataset.contact;
            const contact_split: string[] | undefined = contact?.split('-');
            const next_id: string | undefined = contact_split?.[1];
            const next_element = svg.querySelector(`rect[data-id = "${next_id}"]`) as SVGRectElement;

            const next_arrow = svg.querySelector(`path[data-contact $= "-${next_id}"]`) as SVGPathElement;

            if (next_arrow) {

                const side = next_arrow.dataset.side;
                switch (side) {
                    case 'top':
                        move_top(next_arrow, next_element);
                        break;
                    case 'top-right':
                        move_top_right(next_arrow, next_element)
                        break;
                    case 'top-left':
                        move_top_left(next_arrow, next_element)
                        break;
                    case 'top-left-bottom':
                        move_top_left_bottom(next_arrow, next_element)
                        break
                    case 'top-right-bottom':
                        move_top_right_bottom(next_arrow, next_element)
                        break
                    case 'right':
                        move_right(next_arrow, next_element);
                        break;
                    case 'right-bottom':
                        move_right_bottom(next_arrow, next_element)
                        break;
                    case 'right-top':
                        move_right_top(next_arrow, next_element)
                        break;
                    case 'right-bottom-left':
                        move_right_bottom_left(next_arrow, next_element);
                        break;
                    case 'right-top-left':
                        move_right_top_left(next_arrow, next_element);
                        break;
                    case 'bottom':
                        move_bottom(next_arrow, next_element);
                        break;
                    case 'bottom-right':
                        move_bottom_right(next_arrow, next_element)
                        break;
                    case 'bottom-right-top':
                        move_bottom_right_top(next_arrow, next_element)
                        break;
                    case 'bottom-left':
                        move_bottom_left(next_arrow, next_element)
                        break;
                    case 'bottom-left-top':
                        move_bottom_left_top(next_arrow, next_element)
                        break;
                    case 'left':
                        move_left(next_arrow, next_element);
                        break;
                    case 'left-top':
                        move_left_top(next_arrow, next_element);
                        break;
                    case 'left-bottom':
                        move_left_bottom(next_arrow, next_element);
                        break;

                    case 'left-top-right':
                        move_left_top_right(next_arrow, next_element);
                        break;
                    case 'left-bottom-right':
                        move_left_bottom_right(next_arrow, next_element);
                        break;
                    default:
                        break
                }
            }
            move_auto(next_element);
        })

    }

}


