import { createStore, applyMiddleware, compose } from "redux";
import thunk, { ThunkMiddleware } from "redux-thunk";

import { rootReducer } from "./combineReducer";

import { ActionsTypes } from "./types/types";
import { DecisionActionsTypes } from "./types/typesDecision";
import { ScoreCardActionsTypes } from "./types/typesScoreCard";
import { StrategyActionsTypes } from "./types/typesStrategy";
import { ModalActions } from "./types/typesModal";

export type RootState = ReturnType<typeof rootReducer>;

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(
      thunk as ThunkMiddleware<
        RootState,
        | ActionsTypes
        | DecisionActionsTypes
        | ScoreCardActionsTypes
        | StrategyActionsTypes
        | ModalActions
      >
    )
  )
);
