import { DataTypes } from "../settings/settings";

interface ICheckType {
  value: any;
  checkColor?: boolean;
}

export const checkType = ({ value, checkColor }: ICheckType) => {
  const valueType = typeof value;

  switch (valueType) {
    case "string":
      return checkColor
        ? value.includes("#", 0)
          ? "color"
          : DataTypes.STRING
        : DataTypes.STRING;
    case "object":
      return value
        ? Array.isArray(value)
          ? DataTypes.ARRAY
          : DataTypes.OBJECT
        : DataTypes.NULL;
    default:
      return valueType;
  }
};
