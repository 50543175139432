import React, { FC, useCallback, useEffect } from "react";
import classes from "./TableV2.module.scss";
import Checkbox from "../Checkbox/CheckboxV2";
import { Link } from "react-router-dom";
import { dateFormatter } from "../../../helpers/dateFormatter";
import TooltipMenu, { IMenu } from "../Tooltip/TooltipMenu";
import Button from "../Button/ButtonV2";
import { ButtonShape, ButtonTypes } from "../../../settings/settings";
import Spinner from "../../../components/UI/Spinner/Spinner"

interface IColumn {
  key: string;
  title: string;
  width: string;
  data?: Array<any>;
  link?: {
    target: string;
    to: (dataObj: IData) => string;
    onClick?: (dataObj: IData) => void;
  };
  isTime?: boolean;
}

interface IData {
  [key: string]: string | number;
}

interface ITable {
  columns: Array<IColumn>;
  data: Array<IData>;
  actionsMenu: Array<IMenu>;
  checkAction?: (action: boolean, id: number) => void;
  selectedIdList?: Array<any>;
  onClone:()=>void;
  onArchive: () => void;
  onDelete: () => void;
  onCancel: () => void;
  onSelectAll: () => void;
  selectedListLength: number;
  loading?: boolean;
}

const Table: FC<ITable> = ({
  columns,
  data,
  actionsMenu,
  checkAction,
  selectedIdList,
  onClone,
  onArchive,
  onDelete,
  onCancel,
  onSelectAll,
  selectedListLength,
  loading,
}) => {
  const passSelectedId = useCallback(
    (event: boolean, id: number) => {
      if (!checkAction) return;

      checkAction(event, id);
    },
    [checkAction]
  );

  const changeBodyHeight = useCallback(() => {
    const tableBody = document.getElementById("table-body");

    if (!tableBody) return;

    selectedListLength
      ? tableBody.classList.add(classes.CollapsedTableBody)
      : tableBody.classList.remove(classes.CollapsedTableBody);
  }, [selectedListLength]);

  const highlightRow = useCallback((event: boolean, id: number) => {
    const row = document.getElementById(`table-row-${id}`);

    if (!row) return;

    event
      ? row.classList.add(classes.Selected)
      : row.classList.remove(classes.Selected);
  }, []);

  const generateHeaderColumns = useCallback(() => {
    if (!columns?.length) return null;

    return columns.map(({ key, title, width }, index: number) => {
      return (
        <div key={index} style={{ width }} className={classes.HeaderColumn}>
          {title}
        </div>
      );
    });
  }, [columns]);

  const checkColumnKeyEquality = useCallback(() => {
    if (!columns?.length || !data?.length) return false;

    let columnKeys: Array<string> = [];
    let dataKeys: Array<string> = [];
    let result: Array<boolean> = [];

    columns.forEach(({ key }) => {
      columnKeys.push(key);
    });

    data.forEach((dataObj) => {
      dataKeys.push(...Object.keys(dataObj));
    });

    if (!columnKeys.length || !dataKeys.length) return false;

    for (let i = 0; i < columnKeys.length; i++) {
      for (let j = 0; j < dataKeys.length; j++) {
        result.push(columnKeys[i] === dataKeys[j]);
      }
    }

    return result.some((res: boolean) => res);
  }, [columns, data]);

  const checkRowSelectedStatus = useCallback(
    (rowId: number) => {
      if (!selectedIdList || !selectedIdList.length) return false;

      for (const obj of selectedIdList) {
        if (!("id" in obj)) return false;

        if (obj.id === rowId) return true;
      }
    },
    [selectedIdList]
  );

  const generateBodyColumns = useCallback(
    (dataObj: IData) => {
      return columns.map(
        ({ key, width, data, link, isTime }, index: number) => {
          return (
            <div
              key={index}
              className={classes.BodyColumn}
              style={{ width }}
              data-description={key === "description"}
            >
              {data?.length ? (
                data.find((dataElement) => dataElement?.id === dataObj[key])
                  ?.name
              ) : link ? (
                <Link
                  // target={link.target}
                  target='_blank'
                  to={link.to(dataObj)}
                  onClick={() => {
                    link?.onClick && link.onClick(dataObj);
                  }}
                >
                  {dataObj[key]}
                </Link>
              ) : isTime ? (
                dateFormatter(dataObj[key] as string)
              ) : (
                dataObj[key]
              )}
            </div>
          );
        }
      );
    },
    [columns]
  );

  const generateBodyRows = useCallback(() => {
    if (!checkColumnKeyEquality()) return null;

    return data.map((dataObj: IData, index: number) => {
      highlightRow(
        checkRowSelectedStatus(dataObj.id as number) as boolean,
        dataObj.id as number
      );

      return (
        <div
          key={index}
          id={`table-row-${dataObj.id}`}
          className={classes.TableRow}
        >
          <div className={classes.RowBody}>
            <div className={classes.BodyColumn}>
              <Checkbox
                value={checkRowSelectedStatus(dataObj.id as number)}
                onChange={(event: boolean) => {
                  passSelectedId(event, dataObj.id as number);
                  !selectedIdList && highlightRow(event, dataObj.id as number);
                }}
              />
            </div>
            {generateBodyColumns(dataObj)}
            <div className={classes.BodyColumn}>
              <TooltipMenu menu={actionsMenu} dataId={dataObj.id as number} />
            </div>
          </div>
        </div>
      );
    });
  }, [
    data,
    checkColumnKeyEquality,
    generateBodyColumns,
    passSelectedId,
    actionsMenu,
    highlightRow,
    checkRowSelectedStatus,
    selectedIdList,
  ]);

  useEffect(changeBodyHeight, [changeBodyHeight]);

  return (
    <div className={classes.Table}>
      {selectedListLength ? (
        <section id="table-header-actions" className={classes.ActionsSection}>
          <div className={classes.SelectedItems}>
            <p className={classes.ItemsCount}>
              {selectedListLength === 1 ? (
                `${selectedListLength} item selected`
              ) : selectedListLength > 1 ? (
                `${selectedListLength} items selected`

              ) : null}
            </p>
            <Button
              shape={ButtonShape.NONE}
              type={ButtonTypes.LINK_SECONDARY}
              onClick={onSelectAll}
            >
              Select All
            </Button>
          </div>
          <div className={classes.BtnGroup}>
            <Button disabled={selectedListLength > 1 ?? false } type={ButtonTypes.PRIMARY} onClick={onClone}>
              Clone items
            </Button>
            <Button type={ButtonTypes.WARNING} onClick={onArchive}>
              Archive items
            </Button>
            <Button type={ButtonTypes.DELETE} onClick={onDelete}>
              Delete items
            </Button>
            <Button type={ButtonTypes.SECONDARY} onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </section>
      ) : null}
      <section className={classes.TableHeader}>
        <div className={classes.HeaderColumn} />
        {generateHeaderColumns()}
        <div className={classes.HeaderColumn} />
      </section>
      <section id="table-body" className={classes.TableBody}>
        {loading ? <Spinner /> : generateBodyRows()}
      </section>
    </div>
  );
};

export default Table;
