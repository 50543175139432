
import { splitBoard } from 'helpers/split_board';
import { moveArrowBox } from '../arrow_box.handler';
import { condition_arrow } from '../condition_arrow.handler';
import { drag_contact } from '../drag_contact.handler';
import { move_text } from '../element_text.handler';
import { getAttr } from '../methods/attributes';
import { move_auto } from '../move_auto/move_auto.handler';
import { remove_arrow_box } from '../remove_arrow_box.handler';
export const create_arrow_bottom = (source_element:SVGRectElement,target_element:SVGRectElement,arrow_type:string,arrow:SVGPathElement,side:string)=>{
                        const svg = document.querySelector(`#svg_board`) as SVGElement;
   
                        const {width:t_width,height:t_height,id:t_id} = getAttr(target_element)
                        const {x:s_x,y:s_y,width:s_width,height:s_height,id:s_id} = getAttr(source_element)

                        if(t_width && t_height && s_width && s_height && s_x && s_y && t_id && s_id){

                            let m_b:string = `M${s_x+s_width/2} ${s_y+s_height +12},v50,h0,v50,${arrow_type}`;
                            arrow.setAttributeNS(null,'d',m_b);
                            arrow.setAttributeNS(null,'data-side',side);
                            drag_contact(arrow);
            
                            if(source_element.dataset.type === 'condt'){
                                
                                condition_arrow(source_element,arrow);
                                // condition_label(arrow)
            
                            }
                            let find_b = svg.querySelector(`path[data-contact = ${s_id}-${t_id}]`)
                            let find_b2 = svg.querySelector(`path[data-contact = ${t_id}-${s_id}]`)
            
                            if(!find_b && !find_b2){
                                target_element.setAttributeNS(null,'x',`${s_x+s_width/2 - t_width/2}`);
                                target_element.setAttributeNS(null,'y',`${s_y+108 + 15+9 + t_height}`);
                                move_text(target_element)
                                const data = {x:s_x+s_width/2 - t_width/2,y:s_y+108 + 15+9 + t_height,id:t_id}
                                splitBoard("move",data)
                                let check_contacts_bottom = svg.querySelectorAll(`path[data-contact ^= "${s_id}-"]`) as NodeList;
                                
                                check_contacts_bottom.forEach((check_contact_bottom:any)=>{
                                    if(source_element?.dataset?.type === 'condt'){
                                        if(check_contact_bottom.dataset.side === side){
                     
                                            let next_id:any = check_contact_bottom.getAttribute('data-contact')?.split('-')?.[1];
                                            check_contact_bottom.setAttributeNS(null,'data-contact',`${t_id}-${next_id}`);
                                            remove_arrow_box(t_id,next_id,side,target_element.dataset.type)
                                        }
                                    }else{
                     
                                            let next_id:any = check_contact_bottom.getAttribute('data-contact')?.split('-')?.[1];
                                            check_contact_bottom.setAttributeNS(null,'data-contact',`${t_id}-${next_id}`);
                                            remove_arrow_box(t_id,next_id,side,target_element.dataset.type)
                                
                                    }
                                    
                                })
            
                                let target_inputs_arrow = svg.querySelectorAll(`path[data-contact $= "-${t_id}"]:not(path[data-contact = "${s_id}-${t_id}"])`) as NodeList;
                                if(target_inputs_arrow.length>0){
                                    target_inputs_arrow.forEach((_input_arrow:any)=>{
                                        let _input_element = svg.querySelector(`rect[data-id = "${_input_arrow.dataset.contact?.split('-')?.[0]}"]`) as SVGRectElement
                                        let {
                                            x:{baseVal:{value:input_x}},
                                            y:{baseVal:{value:input_y}},
                                            width:{baseVal:{value:input_width}},
                                            height:{baseVal:{value:input_height}},
            
                                        } = _input_element;
                                        let {
                                            x:{baseVal:{value:target_x}},
                                            y:{baseVal:{value:target_y}},
                                            width:{baseVal:{value:target_width}},
                                            height:{baseVal:{value:target_height}},
            
                                        } = target_element;
                                        if(_input_arrow.dataset.side === 'top'){
                                            let drawn_massive:any = _input_arrow.getAttribute('d')?.split(',');
                                            let [m,,,,,v1,h,v2] = drawn_massive;
            
                                            m = `M${target_x+target_width/2} ${target_y + target_height + 20}`;
            
                                            let vertival:number = Math.abs((target_y + target_height +32) - (input_y)) 
            
                                            let horizontal:number = ((input_x + input_width/2) - (target_x + target_width/2));
                                            v1 = `v${vertival/2}`
                                            v2 = `v${vertival/2}`
                                            h = `h${horizontal}`
            
                                            _input_arrow.setAttributeNS(null,'d',[m,arrow_type,v1,h,v2].join(','));
                                            
                                        }
                                        if(_input_arrow.dataset.side === 'right'){
                                            let drawn_massive:any = _input_arrow.getAttribute('d')?.split(',');
                                            let [m,h1,v,h2,...other] = drawn_massive;
                                            let h:number = Math.abs(((input_x + input_width + 32) - target_x));
            
                                            v = `v${(target_y + target_height/2) - (input_y + input_height/2)}`
                                            h1 = `h${h/2}`
                                            h2 = `h${h/2}`
                                            _input_arrow.setAttributeNS(null,'d',[m,h1,v,h2,...other].join(','));
                                            
                                        }
                                        if(_input_arrow.dataset.side === 'bottom'){
                                            let drawn_massive:any = _input_arrow.getAttribute('d')?.split(',');
                                            let [m,v1,h,v2,...other] = drawn_massive;
            
                                            let v:number = (input_y + input_height+32) - target_y
                                                v1 = `v${v/2}`
                                                v2 = `v${v/2}`
            
                                             h = ((input_x + input_width/2) - (target_x + target_width/2));
                                            _input_arrow.setAttributeNS(null,'d',[m,v1,h,v2,...other].join(','));
                                            
                                        }
                                        if(_input_arrow.dataset.side === 'left'){
                                            let drawn_massive:any = _input_arrow.getAttribute('d')?.split(',');
                                            let [m,,,,,h1,v,h2] = drawn_massive;
                                            v = target_y > input_y ? `v-${Math.abs((target_y + target_height/2) - (input_y + input_height/2))}`:`v${Math.abs((target_y + target_height/2) - (input_y + input_height/2))}`
            
                                            let [mx,my] = m?.split(' ');
                                            let new_mx = target_x + target_width + 21;
                                            mx = `M${new_mx}`;
                                            let new_my = +target_y.toFixed(0) + +target_height.toFixed(0)/2
                                            my = `${new_my}`
                                            m = [mx,my].join(' ');
                                            let h:number = Math.abs((+input_x - 32) - (target_x + +target_width +1));
                                            h1 = `h${h/2}`
                                            h2 = `h${h/2}`
            
                                            _input_arrow.setAttributeNS(null,'d',[m,arrow_type.split(','),h1,v,h2].join(','));
                                            
                                        }
                                    })
                                }
            
                                if(target_element.dataset.type === 'condt'){
                                    let figure2 = target_element.parentNode?.children[1] as SVGRectElement;
                                    let cond_x:any = target_element.getAttribute('x');
                                    let cond_y:any = target_element.getAttribute('y');
                                    let figure2_x:any = +(Math.sqrt((70*70)/2) - 70/2).toFixed(2);
                                    figure2.setAttributeNS(null,'x',+cond_x -4 +figure2_x);
                                    figure2.setAttributeNS(null,'y',+cond_y - 4 +figure2_x);
                                    figure2.setAttributeNS(null,'transform',`rotate(45 ${s_x+s_width/2 - t_width/2 + 35} ${s_y+108+15+9 + t_height + 35})`)
                                }
                                move_auto(target_element);
            
                                moveArrowBox(target_element,{x:s_x+s_width/2 - t_width/2,y:s_y+108+15+9 + t_height});
                                svg.insertBefore(arrow,svg.firstChild);
            
                                remove_arrow_box(s_id,t_id,side,source_element.dataset.type)
                            }
                        
            
                        }
}