import { v4 as uuidv4 } from "uuid";
import { storageKeys } from "../settings/settings";
import { checkType } from "../helpers/checkType";

interface IReformatSourceData {
  sourceData: object;
}

export const reformatSourceData = ({
  sourceData,
}: IReformatSourceData): void => {
  function reformatValue(parent: string, value: any, valueType: string): any {
    switch (valueType) {
      case "string":
        return `"${value}"`;

      case "boolean":
        return String(value);

      case "null":
        return String(value);

      case "array":
        return reformatArrayData(value, parent);

      case "object":
        return reformatObjectData(value, parent);

      default:
        return value;
    }
  }

  function checkExtendableAvailability(valueType: string) {
    switch (valueType) {
      case "array":
        return true;

      case "object":
        return true;

      default:
        return false;
    }
  }

  function getChildrenKeys(value: any) {
    const valueType = checkType({ value });

    if (valueType !== "object" && valueType !== "array") return null;

    return Object.keys(value);
  }

  function reformatObjectData(data: any, parent: string): Array<object> {
    let formattedObjectData: Array<object> = [];

    Object.entries(data).forEach((element: Array<any>) => {
      formattedObjectData.push({
        id: uuidv4(),
        parent,
        key: element[0],
        childrenKeys: getChildrenKeys(element[1]),
        value: reformatValue(
          parent ? `${parent}.${element[0]}` : element[0],
          element[1],
          checkType({ value: element[1] })
        ),
        type: checkType({ value: element[1] }),
        extendable: checkExtendableAvailability(
          checkType({ value: element[1] })
        ),
        extended: false,
      });
    });

    return formattedObjectData;
  }

  function reformatArrayData(data: Array<any>, parent: string) {
    let formattedArrayData: Array<object> = [];

    data?.forEach((element: any, index: number) => {
      formattedArrayData.push({
        id: uuidv4(),
        parent,
        key: index,
        childrenKeys: getChildrenKeys(element),
        value: reformatValue(
          `${parent}[${index}]`,
          element,
          checkType({ value: element })
        ),
        type: checkType({ value: element }),
        extendable: checkExtendableAvailability(checkType({ value: element })),
        extended: false,
      });
    });

    return formattedArrayData;
  }

  if (!Object.keys(sourceData).length) return;

  sessionStorage.setItem(
    storageKeys.reformattedSourceData,
    JSON.stringify(reformatObjectData(sourceData, ""))
  );
};
