import React, {
  FC,
  MouseEvent,
  UIEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import classes from "./ProductList.module.scss";
import { endpoints } from "../../../api/endpoints";
import { request } from "../../../helpers/request";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/combineReducer";
import { handleScoreCardCriteriaData } from "../../../store/actions/actionsScoreCard";
import Modal from "../Modal/Modal";
import {
  color,
  // ButtonTypes,
  EditorKeySources,
  matrix_status,
  // messages,
  ModalNames,
  storage_key,
} from "../../../settings/settings";
import {
  // openModal,
  resetModal,
} from "../../../store/actions/actionsModal";
import DB from "../../../layout/DB/Storage";

interface IMappingList {
  decisionID?: number;
  prevMappedData?: Array<any>;
  emptyRowsIndices?: Array<number>;
  onMap?: Function;
  validate?: boolean;
  onSelect?: Function;
  decisionELementID: Function;
}

const ProductList: FC<IMappingList> = ({
  decisionID,
  decisionELementID,
  prevMappedData,
  emptyRowsIndices,
  onMap = () => {},
  validate = false,
  onSelect = (scoreId: number) => {},
}) => {
  const dispatch = useDispatch();
  const {
    decision_designer: { decision_matrix, decision_range },
  } = endpoints;
  const {
    reducerScoreCard: {
      scoreCardCriteriaData: { scoreCardCriteriaData },
    },
    reducerModal: { modalState },
  } = useSelector((state: RootState) => state);

  const criteriaSideRef = useRef<HTMLDivElement | null>(null);
  const criteriaBodyRef = useRef<HTMLDivElement | null>(null);
  const itemSideRef = useRef<HTMLDivElement | null>(null);
  const itemsBodyRef = useRef<HTMLDivElement | null>(null);

  const [prevScoreId, setPrevScoreId] = useState<number | undefined>();
  const [mapped, setMapped] = useState<Array<any>>([]);
  const [droppedItem, setDroppedItem] = useState<any | undefined>();
  const [confirmationIsShow, setConfirmationIsShow] = useState<boolean>(false);
  const [decisionType, setDecisionType] = useState<string>("");
  const [scroll, setScroll] = useState<boolean>(false);
  const [multiDecision, setMultiDecision] = useState<any[]>([]);
  let decisionRefs = useRef<any>([]);
  const mapCriteria = useCallback(
    (decisions: Array<any>) => {
      if (decisions.length > 0) {
        let result: any = [];
        let isNull: boolean = false;
        decisions.forEach((decision: any) => {
          const { matrix_cell, label } = decision;
          if (matrix_cell) {
            matrix_cell?.forEach((cell: any) => {
              const { label, status_id } = cell;
              if (label === null) {
                result.push({
                  product_group: null,
                  decision_label: null,
                  status_id: 0,
                  type: "matrix",
                });
                isNull = true;
              } else {
                result.push({
                  product_group: "",
                  decision_label: label,
                  status_id: status_id,
                  type: "matrix",
                });
              }
            });
          } else if (label) {
            const { label: _label, status_id } = decision;
            if (_label === null) {
              result.push({
                product_group: null,
                decision_label: null,
                status_id: 0,
                type: "range",
              });
              isNull = true;
            } else {
              result.push({
                product_group: "",
                decision_label: _label,
                status_id: status_id,
                type: "range",
              });
            }
          }
        });

        if (!isNull) {
          setMapped([...result]);
          onMap([...result]);
        }
      }
    },
    [onMap]
  );
  
  const mapItems = useCallback(
    (mapIndex: number, item: string) => {
      const newState = [...mapped];
      newState[mapIndex]["product_group"] = item;
      setMapped([...newState]);

      onMap([...newState]);
    },
    [mapped, onMap]
  );

  const getCriteria = useCallback(
    (decisionId: number | undefined) => {
      function modifyFetchedCriteriaData(
        rawCriteria: Array<any>,
        decisionID: number
      ) {
        if (rawCriteria.length > 0) {
          let modifiedData: Array<any> = [];

          rawCriteria.forEach((rawCriteria: any) => {
            const modifiedCriteria = { ...rawCriteria };
            modifiedCriteria["decision_id"] = decisionID;
            modifiedData.push(modifiedCriteria);
          });
          return modifiedData;
        } else {
          return rawCriteria;
        }
      }

      function filterCriteriaByScoreId(
        criteriaData: Array<any>,
        decisionId: number
      ) {
        return criteriaData.filter((criteria: any) => {
          const { decision_id } = criteria;
          return decision_id === decisionId;
        });
      }

      function fetchCriteria(decisionId: number) {
        const decision_api =
          decisionType === "range" ? decision_range : decision_matrix;
        request
          .get(decision_api.get(decisionId))
          .then((res: any) => {
            const { success, response } = res;
            if (success) {
              response.length > 0
                ? mapCriteria([...response])
                : mapCriteria([{ name: null }]);
              dispatch(
                handleScoreCardCriteriaData({
                  scoreCardCriteriaData:
                    scoreCardCriteriaData?.length > 0
                      ? [
                          ...scoreCardCriteriaData,
                          ...modifyFetchedCriteriaData(response, decisionId),
                        ]
                      : [...modifyFetchedCriteriaData(response, decisionId)],
                })
              );
            } else {
              console.warn("Get criteria with score id success false ", res);
            }
          })
          .catch((err: any) => {
            console.error("Get criteria with score id error ", err);
          });
      }

      if (decisionId && scoreCardCriteriaData === null) {
        fetchCriteria(decisionId);
      } else if (decisionId && scoreCardCriteriaData !== null) {
        const filterResult = filterCriteriaByScoreId(
          scoreCardCriteriaData,
          decisionId
        );

        if (filterResult.length > 0) {
          mapCriteria([...filterResult]);
        } else {
          fetchCriteria(decisionId);
        }
      }
      setPrevScoreId(decisionId);
    },
    [
      scoreCardCriteriaData,
      decisionType,
      decision_range,
      decision_matrix,
      mapCriteria,
      dispatch,
    ]
  );

  // const openWarningModal = useCallback(() => {
  //   dispatch(
  //     openModal({
  //       modalState: {
  //         name: ModalNames.MAPPING_LIST,
  //         title: messages.titleAttention,
  //         question: messages.titleDuplicate("mapping variable"),
  //         message: messages.messageDuplicatedMappingVariable,
  //         buttonType: ButtonTypes.WARNING,
  //         buttonMessage: messages.titleUnderstand,
  //         visible: true,
  //       },
  //     })
  //   );
  // }, [dispatch]);

  const onModalAction = useCallback(() => {
    dispatch(resetModal());
  }, [dispatch]);

  const checkItemAvailability = useCallback(
    (rowId: number, item: string) => {
      if (!mapped[rowId]?.["product_group"]) {
        if (multiDecision.length === 0) {
          mapItems(rowId, item);
        } else {
          for (let i = 0; i < multiDecision.length; i++) {
            const { index } = multiDecision[i];
            mapItems(index, item);
          }
          setMultiDecision([]);
        }
        return;
      }

      setDroppedItem({ rowId, item });
      setConfirmationIsShow(true);
    },
    [mapped, multiDecision, mapItems]
  );

  const clickDecision = useCallback(
    (e: MouseEvent<HTMLDivElement>, mapElement: any, index: number) => {
      const { decision_label } = mapElement;
      const element = e.currentTarget as HTMLDivElement;
      const ref_multiDecision = [...multiDecision] as any[];
      const selectedList = [] as any[];

      if (e.ctrlKey) {
        const checkList = ref_multiDecision?.filter(
          (f) => f.decision_label === decision_label
        );
        if (checkList?.length === 0) {
          setMultiDecision([
            ...ref_multiDecision,
            { index: index, decision_label: decision_label, element: element },
          ]);
          selectedList.push({
            index: index,
            decision_label: decision_label,
            element: element,
          });
          element.classList.add(classes.Selected);
        } else {
          const removeSelected = ref_multiDecision.filter(
            (f) => f.index !== index
          );

          setMultiDecision([...removeSelected]);
          element.classList.remove(classes.Selected);
        }
      }
      if (e.shiftKey) {
        const checkList = ref_multiDecision?.filter(
          (f) => f.decision_label === decision_label
        );
        if (checkList?.length === 0) {
          if (ref_multiDecision.length > 0) {
            let maxObj = ref_multiDecision?.reduce((max, obj) =>
              max.index > obj.index ? max : obj
            );
            let minObj = ref_multiDecision?.reduce((min, obj) =>
              min.index < obj.index ? min : obj
            );

            const { index: maxIndex } = maxObj;
            const { index: minIndex } = minObj;
            if (maxIndex < index) {
              for (let i = maxIndex + 1; i <= index; i++) {
                selectedList.push({
                  index: i,
                  decision_label: mapped[i].decision_label,
                  element: decisionRefs.current[i],
                });
                decisionRefs.current[i].classList.add(classes.Selected);
              }
            } else {
              for (let i = minIndex - 1; i >= index; i--) {
                selectedList.push({
                  index: i,
                  decision_label: mapped[i].decision_label,
                  element: decisionRefs.current[i],
                });
                decisionRefs.current[i].classList.add(classes.Selected);
              }
            }
          } else {
            selectedList.push({
              index: index,
              decision_label: decision_label,
              element: element,
            });
          }
          setMultiDecision([...multiDecision, ...selectedList]);
          element.classList.add(classes.Selected);
        } else {
          const removeSelected = ref_multiDecision.filter(
            (f) => f.index !== index
          );

          setMultiDecision([...removeSelected]);
          element.classList.remove(classes.Selected);
        }
      }
    },
    [mapped, multiDecision]
  );
  const clearMultiSelection = () => {
    multiDecision?.forEach(({ element }: any, i: number) => {
      element?.classList.remove(
        classes.Drop,
        classes.Selected,
        classes.EmptyWarn
      );
    });
    setMultiDecision([]);
  };

  const generateCriteriaRow = useCallback(
    (mapped: Array<any>) => {
      function handleDragEnter(event: any) {
        event.preventDefault();
        const { target } = event;
        const itemsParentElement = itemsBodyRef.current;

        if (target && itemsParentElement) {
          target.classList.add(classes.Drop);
          const itemRowElement = itemsParentElement.children[
            target.id
          ] as HTMLDivElement;

          itemRowElement?.classList.add(classes.Drop);
        }
      }

      function handleDragOver(event: any) {
        event.preventDefault();
        const { target } = event;
        const itemsParentElement = itemsBodyRef.current;

        if (
          target &&
          itemsParentElement &&
          !target.classList.contains(classes.Drop)
        ) {
          target.classList.add(classes.Drop);
          const itemRowElement = itemsParentElement.children[
            target.id
          ] as HTMLDivElement;

          itemRowElement?.classList.add(classes.Drop);
        }
      }

      function handleDragLeave(event: any) {
        const { target } = event;
        const itemsParentElement = itemsBodyRef.current;

        if (target && itemsParentElement) {
          if (multiDecision.length === 0) {
            target.classList.remove(classes.Drop);
          } else {
            multiDecision?.forEach(({ element }: any) => {
              if (element !== target) {
                if (target.classList.contains(classes.Drop)) {
                  target.classList.remove(classes.Drop);
                }
              }
            });
          }
          const itemRowElement = itemsParentElement.children[
            target.id
          ] as HTMLDivElement;

          itemRowElement?.classList.remove(classes.Drop);
        }
      }

      function handleDrop(event: any) {
        event.preventDefault();
        const { target } = event;
        const itemsParentElement = itemsBodyRef.current;

        const draggingProduct = event.dataTransfer.getData("draggingProduct");

        if (!draggingProduct) return;

        const draggingObj = JSON.parse(draggingProduct);
        let key =
          draggingObj.source === EditorKeySources.INPUT
            ? `UserData.${draggingObj.key}`
            : draggingObj.key;

        if (target && itemsParentElement) {
          checkItemAvailability(target.id, key);
          target.classList.remove(classes.Drop);
          const itemRowElement = itemsParentElement.children[
            target.id
          ] as HTMLDivElement;
          if (multiDecision.length === 0) {
            itemRowElement?.classList.remove(classes.Drop, classes.EmptyWarn);
          } else {
            itemRowElement?.classList.remove(classes.Drop, classes.EmptyWarn);

            multiDecision?.forEach(({ element }: any, i: number) => {
              element?.classList.remove(
                classes.Drop,
                classes.Selected,
                classes.EmptyWarn
              );
            });
          }
        }
      }
      if (mapped.length > 0) {
        return mapped.map((mapElement: any, index: number) => {
          const { decision_label, status_id, type } = mapElement;
          return decision_label !== null ? (
            <div
              ref={(element) => {
                decisionRefs.current[index] = element;
              }}
              key={index}
              id={`${index}`}
              className={classes.Row}
              title={decision_label}
              onDragEnter={handleDragEnter}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              onClick={(e) => clickDecision(e, mapElement, index)}
            >
              {type === "range" &&
                color.map((element: any, index: number) => {
                  const { id, color } = element;
                  if (decision_label.charAt(0) === id)
                    return (
                      <span
                        key={index}
                        className={classes.Cell}
                        style={{ backgroundColor: `${color}` }}
                      >
                        {decision_label}
                      </span>
                    );
                  else return null;
                })}
              {type === "matrix" &&
                matrix_status.map((element: any, index: number) => {
                  const { id, color } = element;

                  if (status_id === id)
                    return (
                      <span
                        key={index}
                        className={classes.Cell}
                        style={{ backgroundColor: `${color}` }}
                      >
                        {decision_label}
                      </span>
                    );
                  else return null;
                })}
              {
                // eslint-disable-next-line array-callback-return
                matrix_status?.map((element: any, index: number) => {
                  const { id, name, color, backgroundColor } = element;

                  if (id === status_id)
                    return (
                      <span
                        key={index}
                        className={classes.Status}
                        style={{
                          backgroundColor: backgroundColor,
                          color: color,
                        }}
                      >
                        {name}
                      </span>
                    );
                })
              }
            </div>
          ) : (
            <div key={index} className={classes.Row}>
              No data to display
            </div>
          );
        });
      } else {
        return null;
      }
    },
    [checkItemAvailability, clickDecision, multiDecision]
  );
  const generateItemsRow = useCallback(
    (mapped: Array<any>) => {
      function handleDragEnter(event: any) {
        const { target } = event;

        const criteriaParenElement = criteriaBodyRef.current;

        if (target && criteriaParenElement) {
          target.classList.add(classes.Drop);
          const itemRowElement = criteriaParenElement.children[
            target.id
          ] as HTMLDivElement;

          itemRowElement?.classList.add(classes.Drop);
        }
      }

      function handleDragOver(event: any) {
        event.preventDefault();
        const { target } = event;
        const criteriaParenElement = criteriaBodyRef.current;
        if (
          target &&
          criteriaParenElement &&
          !target.classList.contains(classes.Drop)
        ) {
          target.classList.add(classes.Drop);
          const itemRowElement = criteriaParenElement.children[
            target.id
          ] as HTMLDivElement;

          itemRowElement?.classList.add(classes.Drop);
        }
      }

      function handleDragLeave(event: any) {
        const { target } = event;
        const criteriaParenElement = criteriaBodyRef.current;

        if (target && criteriaParenElement) {
          target.classList.remove(classes.Drop);
          const itemRowElement = criteriaParenElement.children[
            target.id
          ] as HTMLDivElement;

          itemRowElement?.classList.remove(classes.Drop);
        }
      }

      function handleDrop(event: any) {
        event.preventDefault();
        const { target } = event;

        const criteriaParenElement = criteriaBodyRef.current;
        const draggingProduct = event.dataTransfer.getData("draggingProduct");
        if (!draggingProduct) return;
        const draggingObj = JSON.parse(draggingProduct);

        let key =
          draggingObj.source === EditorKeySources.PRODUCTS
            ? `${draggingObj.key}`
            : draggingObj.key;
        if (target && criteriaParenElement) {
          checkItemAvailability(target.id, key);
          target.classList.remove(classes.Drop, classes.EmptyWarn);
          const itemRowElement = criteriaParenElement.children[
            target.id
          ] as HTMLDivElement;

          itemRowElement?.classList.remove(classes.Drop);
        }
      }

      if (mapped.length > 0) {
        return mapped.map((mapElement: any, index: number) => {
          const { product_group } = mapElement;
          return (            
            product_group !== null && (
              <div
                key={index}
                id={`${index}`}
                className={classes.Row}
                title={
                  product_group === ""
                    ? "Must be filled with item"
                    : product_group
                }
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                {product_group}
              </div>
            )
          );
        });
      } else {
        return null;
      }
    },
    [checkItemAvailability]
  );

  const showEmptyRows = useCallback((indices: Array<number>) => {
    const { current } = itemsBodyRef;

    if (current) {
      indices.forEach((index: number) => {
        const emptyField = current.children[index] as HTMLDivElement;
        emptyField?.classList?.add(classes.EmptyWarn);
      });
    }
  }, []);

  const validateComponent = useCallback(() => {
    const criteriaSideElement = criteriaSideRef?.current;
    const itemSideElement = itemSideRef?.current;
    if (!validate) return;

    if (mapped.length) {
      criteriaSideElement?.classList.remove(classes.Validate);
      itemSideElement?.classList.remove(classes.Validate);
    } else {
      criteriaSideElement?.classList.add(classes.Validate);
      itemSideElement?.classList.add(classes.Validate);
    }

    emptyRowsIndices?.length && showEmptyRows(emptyRowsIndices);
  }, [validate, mapped, emptyRowsIndices, showEmptyRows]);

  useLayoutEffect(() => {
    if (
      decisionID !== prevScoreId &&
      (!prevMappedData || prevMappedData?.length === 0)
    ) {
      getCriteria(decisionID);
    } else if (prevMappedData && prevMappedData.length > 0) {
      setMapped([...prevMappedData]);
    }
  }, [decisionID, prevScoreId, prevMappedData, getCriteria]);

  useEffect(validateComponent, [validateComponent, mapped, emptyRowsIndices]);

  const handleConfirmationModalResponse = (response: boolean) => {
    if (response) {
      const { rowId, item } = droppedItem;
      mapItems(rowId, item);
      setConfirmationIsShow(false);
    } else {
      setDroppedItem(undefined);
      setConfirmationIsShow(false);
    }
  };
  const dragDecisionEnter = (e: any) => {
    e.preventDefault();
  };
  const dragDecisionOver = (e: any) => {
    e.preventDefault();
    const { target } = e;
    if (target && !target.classList.contains(classes.DropDecision)) {
      target.classList.add(classes.DropDecision);
    }
  };
  const dragDecisionLeave = (e: any) => {
    const { target } = e;
    if (target && target.classList.contains(classes.DropDecision)) {
      target.classList.remove(classes.DropDecision);
    }
  };
  const dropDecisionHandler = (e: any) => {
    e.preventDefault();
    const db = new DB(storage_key);
    const { target } = e;
    if (target && target.classList.contains(classes.DropDecision)) {
      target.classList.remove(classes.DropDecision);
    }
    const draggingDecision = e.dataTransfer.getData("dragging_decision");
    if (!draggingDecision) return;
    const { key } = JSON.parse(draggingDecision);
    db.fetch({ type: "decision" }).then((res: any) => {
      const { status, data } = res;
      if (status) {
        if (Array.isArray(data)) {
          const findDecision = data?.filter((f: any) => f.data.label === key);
          if (findDecision.length === 1) {
            const [decision] = findDecision;
            const {
              data: { decision_id, data_type },
            } = decision;
            decisionELementID(key, data_type);
            switch (data_type) {
              case "matrix":
                setDecisionType(data_type);
                onSelect(+decision_id);
                break;
              case "range":
                setDecisionType(data_type);
                onSelect(+decision_id);
                break;
            }
          }
        } else {
          const {
            data: { decision_id, data_type },
          } = data;
          decisionELementID(key, data_type);
          switch (data_type) {
            case "matrix":
              setDecisionType(data_type);
              onSelect(+decision_id);
              break;
            case "range":
              setDecisionType(data_type);
              onSelect(+decision_id);
              break;
          }
        }
      }
    });
  };

  const scrollLeftHandler = (e: UIEvent) => {
    if (scroll) {
      setScroll(false);
      return;
    }
    const rightPanel = itemsBodyRef.current as HTMLElement;
    const current = e.target as HTMLElement;
    if (rightPanel) {
      setScroll(true);
      rightPanel.scrollTop = current.scrollTop;
    }
  };
  const scrollRightHandler = (e: UIEvent) => {
    if (scroll) {
      setScroll(false);
      return;
    }
    const leftPanel = criteriaBodyRef.current as HTMLElement;
    const current = e.target as HTMLElement;
    if (leftPanel) {
      setScroll(true);
      leftPanel.scrollTop = current.scrollTop;
    }
  };

  return (
    <div className={classes.MappingListWrapper}>
      <div ref={criteriaSideRef} className={classes.CriteriaSide}>
        <div className={classes.ListHeader}>
          <span className={classes.ListTitle}>Decisions</span>
          {multiDecision.length !== 0 && (
            <span className={classes.ListClear} onClick={clearMultiSelection}>
              Clear
            </span>
          )}
        </div>
        {/* --------------- DECISIONS ------------ */}
        <div
          ref={criteriaBodyRef}
          onDrop={dropDecisionHandler}
          onDragEnter={dragDecisionEnter}
          onDragOver={dragDecisionOver}
          onDragLeave={dragDecisionLeave}
          className={classes.ListBody}
          onScroll={scrollLeftHandler}
        >
          {generateCriteriaRow(mapped)}
        </div>
      </div>
      <div ref={itemSideRef} className={classes.ItemsSide}>
        <div className={classes.ListHeader}>
          <span className={classes.ListTitle}>Product Groups</span>
        </div>
          {/* --------------- PRODUCTS ------------ */}
        <div
          ref={itemsBodyRef}
          className={classes.ListBody}
          onScroll={scrollRightHandler}
        >
          {generateItemsRow(mapped)}
        </div>
      </div>
      {confirmationIsShow && (
        <div className={classes.ConfirmationModalBacker}>
          <div className={classes.ConfirmationModal}>
            <span className={classes.ConfirmationModalTitle}>
              Are you sure to change mapped item?
            </span>
            <div className={classes.ConfirmationModalActions}>
              <button onClick={() => handleConfirmationModalResponse(true)}>
                Change
              </button>
              <button onClick={() => handleConfirmationModalResponse(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      <Modal
        title={modalState?.title}
        question={modalState?.question}
        message={modalState?.message}
        buttonMessage={modalState?.buttonMessage}
        buttonType={modalState?.buttonType}
        onAction={onModalAction}
        visible={modalState?.visible}
        name={ModalNames.MAPPING_LIST}
      />
    </div>
  );
};

export default ProductList;
