type Coord = {
    x: number,
    y: number
}
type ElementProperty = {
    s_x: number,
    s_y: number,
    s_width: number,
    s_height: number,
    t_x: number,
    t_y: number,
    t_width: number,
    t_height: number
}
type Size = {
    width: string,
    height: string
}
export const draw_right_bottom_left_first = (arrow: SVGPathElement, coord: Coord, element_property: ElementProperty, size: Size, drawn_split: Array<string>) => {
    const svg = document.querySelector(`#svg_board`) as SVGElement;
    const { t_x, t_y, t_height,t_width } = element_property;
    const { width, height } = size;
    const contact = arrow?.dataset.contact;
    let m: string = `M${+coord.x + +width + 12} ${+coord.y + +height / 2}`;
    let vertival: number = (coord.y + +height / 2) - (t_y + t_height / 2);

    let horizontal: number = ((coord.x + +width + 32) - (t_x));

    let h1: number =coord.x >= t_x ? (12+25):((t_x+t_width+12+12+25) - (coord.x + +width+12))
    let h2: number =coord.x >= t_x ? Math.abs((t_x+t_width+9) - (coord.x+ +width+25+12)):(24+4)

    drawn_split[1] = `h${h1}`
    drawn_split[3] = `h-${h2}`
    let v = +coord.y <= t_y ? `v${Math.abs(vertival)}` : `v-${Math.abs(vertival)}`
    drawn_split[0] = m;
   

    drawn_split[2] = v;
    drawn_split.join(',');
    const text = svg.querySelector(`text[id = "${contact}"]`) as SVGTextElement;
    if (vertival <= 0) {
        let _v = Math.abs(vertival);
        if (_v < 40) {
            text?.setAttributeNS(null, 'x', `${+coord.x + +width + 12 + (Math.abs(horizontal / 2) - 10)}`)
            text?.setAttributeNS(null, 'y', `${+coord.y + +height / 2 - 10}`)
            text?.setAttributeNS(null, 'transform', `rotate(0 0 0)`)

        } else {
            text?.setAttributeNS(null, 'x', `${+coord.x + +width + 12 + Math.abs(horizontal / 2) + 10}`)
            text?.setAttributeNS(null, 'y', `${+coord.y + +height / 2 + (Math.abs(vertival) / 2 - 15)}`)
            text?.setAttributeNS(null, 'transform', `rotate(90 ${+coord.x + +width + 12 + (Math.abs(horizontal / 2) + 10)} ${+coord.y + +height / 2 + (Math.abs(vertival) / 2 - 15)})`)

        }
    } else {
        let _v = Math.abs(vertival);
        if (_v < 40) {
            text?.setAttributeNS(null, 'x', `${+coord.x + +width + 12 + (Math.abs(horizontal / 2) - 10)}`)
            text?.setAttributeNS(null, 'y', `${+coord.y + +height / 2 + 20}`);
            text?.setAttributeNS(null, 'transform', `rotate(0 0 0)`)

        } else {
            text?.setAttributeNS(null, 'x', `${+coord.x + +width + 12 + Math.abs(horizontal / 2) + 10}`)
            text?.setAttributeNS(null, 'y', `${+coord.y + +height / 2 - (Math.abs(vertival) / 2 + 15)}`)
            text?.setAttributeNS(null, 'transform', `rotate(90 ${+coord.x + +width + 12 + (Math.abs(horizontal / 2) + 10)} ${+coord.y + +height / 2 - (Math.abs(vertival) / 2 + 15)})`)

        }
    }


    arrow.setAttributeNS(null, 'd', `${drawn_split}`);


}
export const draw_right_bottom_left_last = (arrow: SVGPathElement, coord: Coord, element_property: ElementProperty, size: Size, drawn_split: Array<string>) => {
    const svg = document.querySelector(`#svg_board`) as SVGElement;
    const { s_x, s_y, s_width, s_height,t_x ,t_width} = element_property;
    const { height } = size;

    let vertival: number = (coord.y + +height / 2) - (s_y + s_height / 2);

    let horizontal: number = ((coord.x) - (s_x + s_width + 32));

    let h1: number =s_x >= t_x ? (12+25) : ((coord.x+t_width+12+25+12) - (s_x+s_width+12))
    let h2: number =s_x >= t_x ? Math.abs((coord.x+t_width+21) - (s_x+s_width+12+25+12)) : (24+4)

    drawn_split[1] = `h${h1}`
    drawn_split[3] = `h-${h2}`


    drawn_split[2] = `v${vertival}`;
    drawn_split.join(',');
    if (arrow.dataset.bool) {
        const contact = arrow.dataset.contact;
        const text = svg.querySelector(`text[id = "${contact}"]`) as SVGTextElement;
        if (vertival <= 0) {
            let _v = Math.abs(vertival);
            if (_v < 40) {
                text?.setAttributeNS(null, 'x', `${+coord.x - 12 - (Math.abs(horizontal / 2) + 20)}`)
                text?.setAttributeNS(null, 'y', `${+coord.y + +height / 2 + Math.abs(vertival) + 20}`)
                text?.setAttributeNS(null, 'transform', `rotate(0 0 0)`)

            } else {
                text?.setAttributeNS(null, 'x', `${+coord.x - 12 - Math.abs(horizontal / 2) - 48}`)
                text?.setAttributeNS(null, 'y', `${+coord.y + +height / 2 + (Math.abs(vertival) / 2 - 15)}`)
                text?.setAttributeNS(null, 'transform', `rotate(90 ${+coord.x - 12 - Math.abs(horizontal / 2) - 48 + 25} ${+coord.y + +height / 2 + (Math.abs(vertival) / 2 - 15 + 25)})`)

            }
        } else {
            let _v = Math.abs(vertival);
            if (_v < 40) {
                text?.setAttributeNS(null, 'x', `${+coord.x - 12 - (Math.abs(horizontal / 2) + 20)}`)
                text?.setAttributeNS(null, 'y', `${+coord.y + +height / 2 - Math.abs(vertival) - 10}`);
                text?.setAttributeNS(null, 'transform', `rotate(0 0 0)`)

            } else {
                text?.setAttributeNS(null, 'x', `${+coord.x - 12 - Math.abs(horizontal / 2) - 48}`)
                text?.setAttributeNS(null, 'y', `${+coord.y + +height / 2 - Math.abs(vertival) / 2 - 15}`)
                text?.setAttributeNS(null, 'transform', `rotate(90 ${+coord.x - 12 - Math.abs(horizontal / 2) - 48 + 25} ${+coord.y + +height / 2 - Math.abs(vertival) / 2 - 15 + 25})`)

            }
        }

    }
    arrow.setAttributeNS(null, 'd', `${drawn_split}`);





}