import {
    StrategyActionsTypes,
    StrategyBoardPosition
} from "../../../types/typesStrategy";


const initialState: StrategyBoardPosition = {
    top: 0,
    left: 0
}

const reducerPosition = (state = initialState, action: StrategyActionsTypes): StrategyBoardPosition => {


    switch (action.type) {

        case "STRATEGY_BOARD_POSITION":
            const { position: { top, left } } = action.payload
            return {
                top,
                left
            };

        default:
            return state
    }
}

export default reducerPosition