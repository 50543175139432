import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/combineReducer";
import { Cell, MatrixWrapper } from "./MatrixHtml.style";

export const MatrixHtml: FC = () => {
  const [matrixData, setMatrixData] = useState<any[]>([]);
  const {
    reducerStrategy: {
      StrategyBoardElements: {
        StrategyMatrixActiveData: { matrix_data },
      },
    },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (matrix_data) {
      const { matrix_cell, horizontal_length, vertical_length } = matrix_data;
      const copyOfMatrixCell = [...matrix_cell];
      const newMatrix = [] as any[];
      for (let i = vertical_length - 1; i >= 0; i--) {
        const sliced = copyOfMatrixCell.slice(
          i * horizontal_length,
          i * horizontal_length + horizontal_length
        );
        sliced.unshift({ status_id: 0, alias: `${sliced[0]?.alias?.[0]}` });

        newMatrix.push(...sliced);
      }

      if (newMatrix.length > 0) {
        const xCellName = newMatrix.slice(
          newMatrix.length - horizontal_length - 1,
          newMatrix.length
        );
        const xCellList = xCellName.map((m: any, i: number) => {
          return i === 0
            ? { status_id: 0, alias: m.alias?.[1] }
            : { status_id: 0, alias: m.alias?.[1] };
        });
        newMatrix.push(...xCellList);
        setMatrixData([...Array.from(new Set(newMatrix))]);
      }
    }
  }, [matrix_data]);

  const PropsMatrixLength = {
    vertical_length: matrix_data?.vertical_length + 1,
    horizontal_length: matrix_data?.horizontal_length + 1,
  };

  return (
    <MatrixWrapper {...PropsMatrixLength}>
      {matrixData?.map((cell: any, i: number) => {
        return (
          <Cell key={i} status_id={cell.status_id}>
            {cell.alias}
          </Cell>
        );
      })}
    </MatrixWrapper>
  );
};
