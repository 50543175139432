import { arrow_bottom } from "../contact_chaining/nearby_side/bottom/bottom";
import { arrow_bottom_left } from "../contact_chaining/nearby_side/bottom/bottome_left";
import { arrow_bottom_left_top } from "../contact_chaining/nearby_side/bottom/bottom_left_top";
import { arrow_bottom_right } from "../contact_chaining/nearby_side/bottom/bottom_right";
import { arrow_bottom_right_top } from "../contact_chaining/nearby_side/bottom/bottom_right_top";
import { arrow_left } from "../contact_chaining/nearby_side/left/left";
import { arrow_left_bottom_right } from "../contact_chaining/nearby_side/left/left_bottom_right";
import { arrow_left_top_right } from "../contact_chaining/nearby_side/left/left_top_right";
import { positions } from "../contact_chaining/nearby_side/positions";
import { arrow_right } from "../contact_chaining/nearby_side/right/right";
import { arrow_right_bottom } from "../contact_chaining/nearby_side/right/right_bottom";
import { arrow_right_bottom_left } from "../contact_chaining/nearby_side/right/right_bottom_left";
import { arrow_right_top } from "../contact_chaining/nearby_side/right/right_top";
import { arrow_right_top_left } from "../contact_chaining/nearby_side/right/right_top_left";
import { arrow_top } from "../contact_chaining/nearby_side/top/top";
import { arrow_top_left } from "../contact_chaining/nearby_side/top/top_left";
import { arrow_top_left_bottom } from "../contact_chaining/nearby_side/top/top_left_bottom";
import { arrow_top_right } from "../contact_chaining/nearby_side/top/top_right";
import { arrow_top_right_bottom } from "../contact_chaining/nearby_side/top/top_right_bottom";
import { Massive } from "../contact_chaining/types";
import { getArrowPropery, getContactId } from "../get_arrow_properties.handler";
import { getAttr } from "../methods/attributes";
import { change_arrow_box_visibility } from "../remove_arrow_box.handler";

export const change_destination = <T extends SVGRectElement>(element: T): void => {
    const svg = document.querySelector('#svg_board')! as SVGElement;
    const {
        dataset: { id },
    } = element;
    const {
        x: self_x,
        y: self_y,
        width: self_width,
        height: self_height
    } = getAttr(element);
    const border: number = 82;
    const inputs = svg.querySelectorAll(`path[data-contact $= "-${id}"]`)! as NodeListOf<SVGPathElement>;
    const outputs = svg.querySelectorAll(`path[data-contact ^= "${id}-"]`)! as NodeListOf<SVGPathElement>;
    // decision_destination(element,inputs,outputs)
    inputs?.forEach((input: SVGPathElement) => {
        const {contact} =  getArrowPropery(input);
        const {prev:prev_id} =  getContactId(contact)
        
        const prev_element = svg.querySelector(`rect[data-id = "${prev_id}"]`)! as SVGRectElement;
        const prev: Massive = { element: prev_element };
        const self: Massive = { element: element }
        const {
            x: prev_x,
            y: prev_y,
            width: prev_width,
            height: prev_height
        } = getAttr(prev_element);
        const {
            first: {
                top: first_top,
                right: first_right,
                bottom: first_bottom,
                left: first_left
            },
            last: {
                top: last_top,
                right: last_right,
                bottom: last_bottom,
                left: last_left
            }
        } = positions(prev, self);


        if (self_x && self_y && prev_x && prev_y && self_width && self_height && prev_width && prev_height) {
            if (prev_y < self_y) {
                if (prev_x < self_x) {

                    if (prev_x + prev_width <= self_x) {
                        
                        if (prev_y + prev_height / 2 < self_y) {
                            if (prev_y + prev_height <= self_y) {
                                if (+(self_x - (prev_x + prev_width)).toFixed(0) <= border) {

                                    if (first_bottom && last_left) {
                                        arrow_bottom_right<Massive>(prev, self, input,inputs);
                                    } else if (first_bottom && last_bottom) {
                                        arrow_bottom_right_top<Massive>(prev, self, input,inputs);
                                    } else if (first_top && last_top) {
                                        arrow_top_right_bottom<Massive>(prev, self, input,inputs);

                                    }
                                } else {
                                    if (first_right && last_left) {
                                        arrow_right<Massive>(prev, self, input,inputs);
                                    } else if (first_bottom && last_left) {
                                        arrow_bottom_right<Massive>(prev, self, input,inputs);
                                    } else if (first_bottom && last_bottom) {
                                        arrow_bottom_right_top<Massive>(prev, self, input,inputs);
                                    } else if (first_top && last_top) {
                                        arrow_top_right_bottom<Massive>(prev, self, input,inputs);


                                    }
                                }
                            } else {
                                if (+(self_x - (prev_x + prev_width)).toFixed(0) <= border) {
                                    if (first_bottom && last_bottom) {
                                        arrow_bottom_right_top<Massive>(prev, self, input,inputs);
                                    } else if (first_top && last_top) {
                                        arrow_top_right_bottom<Massive>(prev, self, input,inputs);

                                    }
                                } else {
                                    if (first_right && last_left) {
                                        arrow_right<Massive>(prev, self, input,inputs);
                                    } else if (first_bottom && last_left) {
                                        arrow_bottom_right<Massive>(prev, self, input,inputs);
                                    } else if (first_bottom && last_bottom) {
                                        arrow_bottom_right_top<Massive>(prev, self, input,inputs);
                                    } else if (first_top && last_top) {
                                        arrow_top_right_bottom<Massive>(prev, self, input,inputs);

                                    }

                                }
                            }
                        } else {
                            if (+(self_x - (prev_x + prev_width)).toFixed(0) <= border) {

                                if (first_top && last_top) {
                                    arrow_top_right_bottom<Massive>(prev, self, input,inputs);

                                } else if (first_bottom && last_bottom) {
                                    arrow_bottom_right_top<Massive>(prev, self, input,inputs);
                                }
                            } else {
                                if (first_right && last_left) {
                                    arrow_right<Massive>(prev, self, input,inputs);
                                } else if (first_top && last_top) {
                                    arrow_top_right_bottom<Massive>(prev, self, input,inputs);
                                    
                                } else if (first_bottom && last_bottom) {
                                    arrow_bottom_right_top<Massive>(prev, self, input,inputs);
                                }
                            }
                        }
                    } else {
                        // if (prev_y + prev_height <= self_y) {
                            if (self_y - (prev_y + prev_height) <= border) {
                                if (first_right && last_right) {
                                    arrow_right_bottom_left<Massive>(prev, self, input,inputs);
                                }else if(first_bottom && last_bottom){
                                    arrow_bottom_right_top<Massive>(prev, self, input,inputs);
                                }else if(first_top && last_top){
                                    arrow_top_right_bottom<Massive>(prev, self, input,inputs);//03

                                }
                                
                            } else {
                                if (first_bottom && last_top) {
                                    arrow_bottom<Massive>(prev, self, input,inputs);
                                }else if(first_top && last_top){
                                    arrow_top_right_bottom<Massive>(prev, self, input,inputs);//05

                                }
                            }
                        // }

                    }
                } else {
                    if (self_x + self_width <= prev_x) {

                        if (prev_y + prev_height / 2 < self_y) {
                            if (prev_y + prev_height <= self_y) {
                                if (+(prev_x - (self_x + self_width)).toFixed(0) <= border) {
                                    if (first_bottom && last_right) {
                                        arrow_bottom_left<Massive>(prev, self, input,inputs);
                                    } else if (first_bottom && last_bottom) {
                                        arrow_bottom_left_top<Massive>(prev, self, input,inputs);
                                    } else if (first_top && last_top) {
                                        arrow_top_left_bottom<Massive>(prev, self, input,inputs);
                                    }
                                } else {
                                    if (first_left && last_right) {
                                        arrow_left<Massive>(prev, self, input,inputs);
                                    } else if (first_bottom && last_right) {
                                        arrow_bottom_left<Massive>(prev, self, input,inputs);
                                    } else if (first_bottom && last_bottom) {
                                        arrow_bottom_left_top<Massive>(prev, self, input,inputs);
                                    } else if (first_top && last_top) {
                                        arrow_top_left_bottom<Massive>(prev, self, input,inputs);
                                    }
                                }
                            } else {
                                if (+(prev_x - (self_x + self_width)).toFixed(0) <= border) {
                                    if (first_bottom && last_bottom) {
                                        arrow_bottom_left_top<Massive>(prev, self, input,inputs);
                                    } else if (first_top && last_top) {
                                        arrow_top_left_bottom<Massive>(prev, self, input,inputs);
                                    }
                                } else {
                                    if (first_left && last_right) {
                                        arrow_left<Massive>(prev, self, input,inputs);
                                    } else if (first_bottom && last_bottom) {
                                        arrow_bottom_left_top<Massive>(prev, self, input,inputs);
                                    } else if (first_bottom && last_right) {
                                        arrow_bottom_left<Massive>(prev, self, input,inputs);
                                    } else if (first_top && last_top) {
                                        arrow_top_left_bottom<Massive>(prev, self, input,inputs);
                                    }

                                }
                            }
                        } else {
                            if (+(prev_x - (self_x + self_width)).toFixed(0) <= border) {

                                if (first_top && last_top) {
                                    arrow_top_left_bottom<Massive>(prev, self, input,inputs);
                                } else if (first_bottom && last_bottom) {
                                    arrow_bottom_left_top<Massive>(prev, self, input,inputs);
                                }
                            } else {
                                if (first_left && last_right) {
                                    arrow_left<Massive>(prev, self, input,inputs);
                                } else if (first_top && last_top) {
                                    arrow_top_left_bottom<Massive>(prev, self, input,inputs);
                                } else if (first_bottom && last_bottom) {
                                    arrow_bottom_left_top<Massive>(prev, self, input,inputs);
                                }
                            }
                        }
                    } else {

                        // if (prev_y + prev_height <= self_y) {
                            if (self_y - (prev_y + prev_height) <= border) {
                                if (first_left && last_left) {
                                    arrow_left_bottom_right<Massive>(prev, self, input,inputs);
                                } else if (first_right && last_right) {
                                    arrow_right_bottom_left<Massive>(prev, self, input,inputs);
                                }else if(first_bottom && last_bottom){
                                    arrow_bottom_left_top<Massive>(prev, self, input,inputs);//01
                                }else if(first_top && last_top){
                                    arrow_top_left_bottom<Massive>(prev, self, input,inputs);//04
                                }
                                
                            } else {
                                if (first_bottom && last_top) {
                                    arrow_bottom<Massive>(prev, self, input,inputs);
                                }
                            }
                        // }
                    }
                }
            } else {
                if (prev_x < self_x) {
                    if (prev_x + prev_width <= self_x) {

                        if (self_y + self_height <= prev_y) {

                            if (+(self_x - (prev_x + prev_width)).toFixed(0) <= border) {
                                if (first_top && last_left) {
                                    arrow_top_right<Massive>(prev, self, input,inputs);
                                } else if (first_top && last_top) {
                                    arrow_top_right_bottom<Massive>(prev, self, input,inputs);

                                } else if (first_bottom && last_bottom) {
                                    arrow_bottom_right_top<Massive>(prev, self, input,inputs);
                                }
                            } else {
                                if (first_right && last_left) {
                                    arrow_right<Massive>(prev, self, input,inputs);
                                } else if (first_top && last_left) {
                                    arrow_top_right<Massive>(prev, self, input,inputs);
                                } else if (first_top && last_top) {
                                    arrow_top_right_bottom<Massive>(prev, self, input,inputs);

                                } else if (first_bottom && last_bottom) {
                                    arrow_bottom_right_top<Massive>(prev, self, input,inputs);

                                }
                            }
                        } else {

                            if (+(self_x - (prev_x + prev_width)).toFixed(0) <= border) {
                                if (first_top && last_top) {
                                    arrow_top_right_bottom<Massive>(prev, self, input,inputs);

                                } else if (first_bottom && last_bottom) {
                                    arrow_bottom_right_top<Massive>(prev, self, input,inputs);
                                }
                            } else {
                                if (first_right && last_left) {
                                    arrow_right<Massive>(prev, self, input,inputs);
                                } else if (first_top && last_left) {
                                    arrow_top_right<Massive>(prev, self, input,inputs);
                                } else if (first_top && last_top) {
                                    arrow_top_right_bottom<Massive>(prev, self, input,inputs);

                                } else if (first_bottom && last_bottom) {
                                    arrow_bottom_right_top<Massive>(prev, self, input,inputs);

                                }

                            }
                        }
                    } else {

                        // if (self_y + self_height <= prev_y) {
                            
                            if (prev_y - (self_y + self_height) <= border) {

                                if (first_left && last_left) {
                                    arrow_left_bottom_right<Massive>(prev, self, input,inputs);
                                } else if (first_right && last_right) {
                                    arrow_right_bottom_left<Massive>(prev, self, input,inputs);
                                }else if(first_top && last_top){
                                    arrow_top_right_bottom<Massive>(prev, self, input,inputs);
                                }
                            } else {

                                if (first_top && last_bottom) {
                                    arrow_top<Massive>(prev, self, input,inputs);
                                }
                            }
                        // }
                    }
                } else {

                    if (self_x + self_width <= prev_x) {

                        if (self_y + self_height <= prev_y) {
                            if (+(prev_x - (self_x + self_width)).toFixed(0) <= border) {
                                if (first_top && last_right) {
                                    arrow_top_left<Massive>(prev, self, input,inputs);
                                } else if (first_top && last_top) {
                                    arrow_top_left_bottom<Massive>(prev, self, input,inputs);

                                } else if (first_bottom && last_bottom) {
                                    arrow_bottom_left_top<Massive>(prev, self, input,inputs);
                                }
                            } else {
                                if (first_left && last_right) {
                                    arrow_left<Massive>(prev, self, input,inputs);
                                } else if (first_top && last_right) {
                                    arrow_top_left<Massive>(prev, self, input,inputs);
                                } else if (first_top && last_top) {
                                    arrow_top_left_bottom<Massive>(prev, self, input,inputs);

                                } else if (first_bottom && last_bottom) {
                                    arrow_bottom_left_top<Massive>(prev, self, input,inputs);

                                }
                            }
                        } else {
                            if (+(prev_x - (self_x + self_width)).toFixed(0) <= border) {
                                if (first_top && last_top) {
                                    arrow_top_left_bottom<Massive>(prev, self, input,inputs);

                                } else if (first_bottom && last_bottom) {
                                    arrow_bottom_left_top<Massive>(prev, self, input,inputs);
                                }
                            } else {
                                if (first_left && last_right) {
                                    arrow_left<Massive>(prev, self, input,inputs);
                                } else if (first_top && last_top) {
                                    arrow_top_left_bottom<Massive>(prev, self, input,inputs);

                                } else if (first_top && last_right) {
                                    arrow_top_left<Massive>(prev, self, input,inputs);
                                } else if (first_bottom && last_bottom) {
                                    arrow_bottom_left_top<Massive>(prev, self, input,inputs);

                                }

                            }
                        }
                    } else {
                        // if (self_y + self_height <= prev_y) {
                            if (prev_y - (self_y + self_height) <= border) {
                                if (first_left && last_left) {
                                    arrow_left_bottom_right<Massive>(prev, self, input,inputs);
                                } else if (first_right && last_right) {
                                    arrow_right_bottom_left<Massive>(prev, self, input,inputs);
                                }else if(first_top && last_top){
                                    arrow_top_left_bottom<Massive>(prev, self, input,inputs);//07
                                }
                            } else {
                                if (first_top && last_bottom) {
                                    arrow_top<Massive>(prev, self, input,inputs);
                                }
                            }
                        // }
                    }
                }
            }
        }

    })


    //outputs
    outputs?.forEach((input: SVGPathElement) => {
        const contact = input?.dataset?.contact!;
        const id_list: string[] = contact?.split('-');
        const [prev_id, next_id] = id_list;

        if(prev_id !== "endpoint"){
            const next_element = svg.querySelector(`rect[data-id = "${next_id}"]`)! as SVGRectElement;
        const self: Massive = { element: element };
        const next: Massive = { element: next_element }
        const {
            x: next_x,
            y: next_y,
            width: next_width,
            height: next_height
        } = getAttr(next_element);
        const {
            first: {
                top: first_top,
                right: first_right,
                bottom: first_bottom,
                left: first_left
            },
            last: {
                top: last_top,
                right: last_right,
                bottom: last_bottom,
                left: last_left
            }
        } = positions(self, next);


        if (self_x && self_y && next_x && next_y && self_width && self_height && next_width && next_height) {
            if (next_y < self_y) {
                if (next_x < self_x) {
                    if (next_x + next_width <= self_x) {
                        if (next_y + next_height / 2 < self_y) {
                            if (next_y + next_height <= self_y) {
                                if (+(self_x - (next_x + next_width)).toFixed(0) <= border) {
                                    if (first_bottom && last_left) {
                                        arrow_top_left<Massive>(self, next, input,inputs);//1
                                    } else if (first_bottom && last_bottom) {
                                        arrow_bottom_left_top<Massive>(self, next, input,inputs);//2
                                    }else if(first_left && last_left){
                                        arrow_left_top_right<Massive>(self, next, input,inputs);//3
                                    }else if (first_top && last_top) {
                                        arrow_top_left_bottom<Massive>(self, next, input,inputs);//3
                                    }
                                } else {

                                    if (first_left && last_right) {
                                        arrow_left<Massive>(self, next, input,inputs);//9
                                    } else if (first_top && last_right) {
                                        arrow_top_left<Massive>(self, next, input,inputs);//10
                                    } else if (first_bottom && last_bottom) {
                                        arrow_bottom_left_top<Massive>(self, next, input,inputs);
                                    } else if (first_top && last_top) {
                                        arrow_top_left_bottom<Massive>(self, next, input,inputs);
                                    }
                                }
                            } else {
                                if (+(self_x - (next_x + next_width)).toFixed(0) <= border) {
                                    if (first_bottom && last_bottom) {
                                        arrow_bottom_left_top<Massive>(self, next, input,inputs);//5
                                    }else if(first_left && last_left){
                                        arrow_left_top_right<Massive>(self, next, input,inputs);//3

                                    } else if (first_top && last_top) {
                                        arrow_top_left_bottom<Massive>(self, next, input,inputs);//4
                                    }
                                } else {
                                    if (first_bottom && last_bottom) {
                                        arrow_bottom_left_top<Massive>(self, next, input,inputs);//8
                                    } else if(first_left && last_left){
                                        arrow_left_top_right<Massive>(self, next, input,inputs);//3

                                    }else if ((!first_bottom || !last_bottom) && first_top && last_top) {
                                        arrow_top_left_bottom<Massive>(self, next, input,inputs);
                                    } else if (first_top && last_right) {
                                        arrow_top_left<Massive>(self, next, input,inputs);//7
                                    } else if (first_left && last_right) {
                                        arrow_left<Massive>(self, next, input,inputs);//6
                                    }

                                }
                            }

                        } else {

                            if (+(self_x - (next_x + next_width)).toFixed(0) <= border) {

                                if (first_top && last_top) {
                                    arrow_top_left_bottom<Massive>(self, next, input,inputs);//10
                                } else if (first_bottom && last_bottom) {
                                    arrow_bottom_left_top<Massive>(self, next, input,inputs);//11
                                }
                            } else {
                                if (first_left && last_right) {
                                    arrow_left<Massive>(self, next, input,inputs);//14
                                } else if (first_top && last_top) {
                                    arrow_top_left_bottom<Massive>(self, next, input,inputs);//12
                                } else if (first_bottom && last_bottom) {
                                    arrow_bottom_left_top<Massive>(self, next, input,inputs);//13
                                }
                            }
                        }

                    } else {

                        if (next_y + next_height <= self_y) {
                            if (self_y - (next_y + next_height) <= border) {

                                if (first_right && last_right) {
                                    arrow_right_top_left<Massive>(self, next, input,inputs);
                                }else if(first_left && last_left){
                                    arrow_left_top_right<Massive>(self, next, input,inputs);
                                }else if(first_bottom && last_bottom){
                                    arrow_bottom_left_top<Massive>(self, next, input,inputs);

                                }
                            } else {
                                if (first_top && last_bottom) {
                                    arrow_top<Massive>(self, next, input,inputs);//14
                                }else if(first_bottom && last_bottom){
                                    arrow_bottom_left_top<Massive>(self, next, input,inputs);

                                }else if(first_right && last_right){
                                    arrow_right_top_left<Massive>(self, next, input,inputs);

                                }else if(first_top && last_top){
                                    arrow_top_left_bottom<Massive>(self, next, input,inputs);

                                }
                            }
                        }else{

                           if(first_left && last_left){
                               arrow_left_top_right<Massive>(self, next, input,inputs);
                           }else if(first_right && last_right){
                               arrow_right_top_left<Massive>(self, next, input,inputs);
                           }else if(first_top && last_top){
                               arrow_top_left_bottom<Massive>(self, next, input,inputs);
                           }
                        }
                    }

                } else {

                    if (self_x + self_width <= next_x) {

                        if (next_y + next_height / 2 < self_y) {
                            if (next_y + next_height <= self_y) {
                                if (+(next_x - (self_x + self_width)).toFixed(0) <= border) {
                                    if (first_top && last_left) {
                                        arrow_top_right<Massive>(self, next, input,inputs);//61
                                    }else if(first_right && last_bottom){
                                        arrow_right_top<Massive>(self, next, input,inputs);//62
                                    }else if(first_top && last_top){
                                        arrow_top_right_bottom<Massive>(self, next, input,inputs);//66

                                    }else if(first_right && last_right){
                                        arrow_right_top_left<Massive>(self, next, input,inputs);//66

                                    }

                                } else {
                                    if (first_right && last_left) {
                                        arrow_right<Massive>(self, next, input,inputs);
                                    }else if (first_top && last_left) {
                                        arrow_top_right<Massive>(self, next, input,inputs);//63
                                    }else if(first_right && last_bottom){
                                        arrow_right_top<Massive>(self, next, input,inputs);//64
                                    }else if(first_top && last_top){
                                        arrow_top_right_bottom<Massive>(self, next, input,inputs);//66

                                    }

                                }

                            } else {
                                
                                if (+(next_x - (self_x + self_width)).toFixed(0) <= border) {
                                    if (first_bottom && last_bottom) {
                                        arrow_bottom_right_top<Massive>(self, next, input,inputs);
                                    } else if (first_top && last_top) {
                                        arrow_top_right_bottom<Massive>(self, next, input,inputs);
                                    }
                                } else {
                                    if (first_right && last_left) {
                                        arrow_right<Massive>(self, next, input,inputs);
                                    } else if (first_bottom && last_bottom) {
                                        arrow_bottom_right_top<Massive>(self, next, input,inputs);
                                    } else if (first_top && last_left) {
                                        arrow_top_right<Massive>(self, next, input,inputs);
                                    } else if (first_top && last_top) {
                                        arrow_top_right_bottom<Massive>(self, next, input,inputs);
                                    }

                                }
                            }

                        } else {
                            if (+(next_x - (self_x + self_width)).toFixed(0) <= border) {

                                if (first_top && last_top) {
                                    arrow_top_right_bottom<Massive>(self, next, input,inputs);
                                } else if (first_bottom && last_bottom) {
                                    arrow_bottom_right_top<Massive>(self, next, input,inputs);
                                }
                            } else {
                                if (first_right && last_left) {
                                    arrow_right<Massive>(self, next, input,inputs);
                                } else if (first_top && last_top) {
                                    arrow_top_right_bottom<Massive>(self, next, input,inputs);
                                } else if (first_bottom && last_bottom) {
                                    arrow_bottom_right_top<Massive>(self, next, input,inputs);
                                }
                            }
                        }

                    } else {

                         if (next_y + next_height <= self_y) {

                            if (self_y - (next_y + next_height) <= border) {
                                if(first_top && last_top){
                                    arrow_top_right_bottom<Massive>(self, next, input,inputs);
                                }else if(first_left && last_left) {
                                    arrow_left_top_right<Massive>(self, next, input,inputs);
                                } else if (first_right && last_right) {
                                    arrow_right_top_left<Massive>(self, next, input,inputs);
                                }
                            } else {

                                if (first_top && last_bottom) {
                                    arrow_top<Massive>(self, next, input,inputs);
                                }else if (first_left && last_left) {
                                    arrow_left_top_right<Massive>(self, next, input,inputs);
                                } else if (first_right && last_right) {
                                    arrow_right_top_left<Massive>(self, next, input,inputs);
                                }
                            }
                        }else{

                            if(self_x <= next_x){
                               if(first_top && last_top){
                                    arrow_top_right_bottom<Massive>(self, next, input,inputs);

                                }else if(first_top && !last_top && last_bottom){
                                    arrow_bottom_right_top<Massive>(self, next, input,inputs);

                                }
                            }

                        }
                    }

                }

            } else {

                if (next_x < self_x) {
                    if (next_x + next_width <= self_x) {

                        if (self_y + self_height <= next_y) {
                            
                            if (+(self_x - (next_x + next_width)).toFixed(0) <= border) {
                                if (first_top && last_top) {
                                    arrow_top_left_bottom<Massive>(self, next, input,inputs);//15

                                } else if (first_bottom && last_bottom) {
                                    arrow_bottom_left_top<Massive>(self, next, input,inputs);//16
                                }

                            } else {
                                if (first_left && last_right) {
                                    arrow_left<Massive>(self, next, input,inputs);//22

                                } else if (first_bottom && last_right) {
                                    arrow_bottom_left<Massive>(self, next, input,inputs);//25

                                } else if (first_top && last_top) {
                                    arrow_top_left_bottom<Massive>(self, next, input,inputs);//23

                                } else if (first_bottom && last_bottom) {
                                    arrow_bottom_left_top<Massive>(self, next, input,inputs);//24

                                }

                            }
                        } else {
                            if (+(self_x - (next_x + next_width)).toFixed(0) <= border) {
                                if (first_top && last_top) {
                                    arrow_top_left_bottom<Massive>(self, next, input,inputs);//17

                                } else if (first_bottom && last_bottom) {
                                    arrow_bottom_left_top<Massive>(self, next, input,inputs);//18
                                }
                            } else {
                                if (first_left && last_right) {
                                    arrow_left<Massive>(self, next, input,inputs);//19
                                } else if (first_top && last_top) {
                                    arrow_top_left_bottom<Massive>(self, next, input,inputs);//20

                                } else if (first_bottom && last_bottom) {
                                    arrow_bottom_left_top<Massive>(self, next, input,inputs);//21

                                }

                            }
                        }
                    } else {

                        // if (self_y + self_height <= next_y) {
                            if (next_y - (self_y + self_height) <= border) {

                                if (first_left && last_left) {
                                    arrow_left_bottom_right<Massive>(self, next, input,inputs);
                                } else if (first_right && last_right) {
                                    arrow_right_bottom_left<Massive>(self, next, input,inputs);
                                }else if(first_top && last_top){
                                    arrow_top_left_bottom<Massive>(self, next, input,inputs);

                                }
                            } else {
                                if (first_bottom && last_top) {
                                    arrow_bottom<Massive>(self, next, input,inputs);//27
                                } else if (first_right && last_right) {
                                    arrow_right_bottom_left<Massive>(self, next, input,inputs);//28
                                }
                            }
                        // }else{

                        // }
                    }

                } else {

                    if (self_x + self_width <= next_x) {
                        if (self_y + self_height <= next_y) {
                            
                            if (+(next_x - (self_x + self_width)).toFixed(0) <= border) {

                                if(next_y - (self_y+self_height)<=border){
                                    if(first_right && last_top){
                                        arrow_right_bottom<Massive>(self, next, input,inputs);//29
                                    }else if(first_right && last_right){
                                        arrow_right_bottom_left<Massive>(self, next, input,inputs);//30
                                    }else if(first_bottom && last_bottom){
                                        arrow_bottom_right_top<Massive>(self, next, input,inputs);//31
                                    }
                                }else{
                                    if(first_bottom && last_top){
                                        arrow_bottom<Massive>(self, next, input,inputs);//32
                                    }else if(first_right && last_top){
                                        arrow_right_bottom<Massive>(self, next, input,inputs);//33
                                    }else if(first_right && last_right){
                                        arrow_right_bottom_left<Massive>(self, next, input,inputs);//34
                                    }else if(first_bottom && last_bottom){
                                        arrow_bottom_right_top<Massive>(self, next, input,inputs);//35
                                    }
                                }
                               
                            } else {
                                if(next_y - (self_y+self_height)<=border){
                                    if(first_right && last_left){
                                        arrow_right<Massive>(self, next, input,inputs);

                                    }else if(first_right && last_top){
                                        arrow_right_bottom<Massive>(self, next, input,inputs);//37
                                    }else if(first_right && last_right){
                                        arrow_right_bottom_left<Massive>(self, next, input,inputs);//38
                                    }else if(first_bottom && last_bottom){
                                        arrow_bottom_right_top<Massive>(self, next, input,inputs);//39
                                    }
                                }else{
                                    if(first_right && last_left){
                                        arrow_right<Massive>(self, next, input,inputs);

                                    }else if(first_bottom && last_top){
                                        arrow_bottom<Massive>(self, next, input,inputs);//41
                                    }else if(first_right && last_top){
                                        arrow_right_bottom<Massive>(self, next, input,inputs);//42
                                    }else if(first_right && last_right){
                                        arrow_right_bottom_left<Massive>(self, next, input,inputs);//43
                                    }else if(first_bottom && last_bottom){
                                        arrow_bottom_right_top<Massive>(self, next, input,inputs);//44
                                    }
                                }
                            }
                        } else {

                            if (+(next_x - (self_x + self_width)).toFixed(0) <= border) {
                                if (first_top && last_top) {
                                    arrow_top_right_bottom<Massive>(self, next, input,inputs);//45

                                } else if (first_bottom && last_bottom) {
                                    arrow_bottom_right_top<Massive>(self, next, input,inputs);//46
                                }
                            } else {
                                

                                if (first_right && last_left) {
                                    arrow_right<Massive>(self, next, input,inputs);
                                } else if (first_top && last_top) {
                                    arrow_top_right_bottom<Massive>(self, next, input,inputs);//48

                                } else  if (first_bottom && last_bottom) {
                                    arrow_bottom_right_top<Massive>(self, next, input,inputs);//49

                                }

                            }
                        }

                    } else {

                        // if (self_y + self_height <= next_y) {
                            if (next_y - (self_y + self_height) <= border) {
                                if(self_width>next_width){
                                    if(self_x+((self_width-next_width)/2)<=next_x){
                                        if (first_right && last_right) {
                                            arrow_right_bottom_left<Massive>(self, next, input,inputs);//51
                                        }else if (first_left && last_left) {
                                            arrow_left_top_right<Massive>(self, next, input,inputs);//50
                                        }else if(first_top && last_top){
                                            arrow_top_right_bottom<Massive>(self, next, input,inputs);
        
                                        } 
            
                                    }else{
                                        if (first_right && last_right) {
                                            arrow_right_bottom_left<Massive>(self, next, input,inputs);//51
                                        }else if (first_left && last_left) {
                                            arrow_left_top_right<Massive>(self, next, input,inputs);//50
                                        }else if(first_top && last_top){
                                            arrow_top_left_bottom<Massive>(self, next, input,inputs);
        
                                        } 
            
                                    }
                                }else if(self_width === next_width){
                                    if(self_x<=next_x){
                                        if (first_right && last_right) {
                                            arrow_right_bottom_left<Massive>(self, next, input,inputs);//51
                                        }else if (first_left && last_left) {
                                            arrow_left_bottom_right<Massive>(self, next, input,inputs);//50
                                        }else if(first_top && last_top){
                                            arrow_top_right_bottom<Massive>(self, next, input,inputs);
        
                                        } 
            
                                    }else{
                                        if (first_right && last_right) {
                                            arrow_right_bottom_left<Massive>(self, next, input,inputs);//51
                                        }else if (first_left && last_left) {
                                            arrow_left_top_right<Massive>(self, next, input,inputs);//50
                                        }else if(first_top && last_top){
                                            arrow_top_left_bottom<Massive>(self, next, input,inputs);
        
                                        } 
            
                                    }
                                }
                                
                            } else {

                                if (first_bottom && last_top) {
                                    arrow_bottom<Massive>(self, next, input,inputs);//52
                                }else if (first_left && last_left) {
                                    arrow_left_top_right<Massive>(self, next, input,inputs);//52
                                } else if (first_right && last_right) {
                                    arrow_right_top_left<Massive>(self, next, input,inputs);//53
                                }
                            }
                        // }
                    }





                }
            }
        }
        }

    })
    change_arrow_box_visibility(element)
}


