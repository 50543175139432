import {
  ScoreCardState,
  ScoreCardActionsTypes,
  SCORECARDDATA,
  SCORECARDCATEGORYDATA,
  SCORECARDCRITERIADATA,
  ACTIVESCORECARDDATA,
  SCORECARDLEVEL,
  SCORECARDACTIVEDELETE,
  SCORE_CARD_LEVEL_DATA_ID,
  SCORECARDATTRIBUTEDATA,
  SCORE_CARD_BLOCKED_MODAL_RESPONSE,
  SCORE_CARD_ACTIVE_ELEMENT,
  SCORE_CARD_ACTIVE_ROW,
  SCORE_CARD_REMOVE_NEW_ROW,
  SCORE_CARD_CHANGE_ROW_EDITABLE_STATUS,
  SCORECARDLEVELTREE,
  ACTIVETREE,
  SCORE_CARD_RECOVERY_DATA,
  SCORE_CARD_ATTRIBUTE_AVAILABILITY, SCORE_CARD_WEIGHT_EDIT_STATUS, SCORE_CARD_REMAINING_WEIGHT, SCORE_CARD_REMAINING_WEIGHT_WARNING, SCORE_CARD_CALCULATE_POINT, SCORE_CARD_REMAINING_POINT
} from "../types/typesScoreCard";


const initialState: ScoreCardState = {

  scoreCardData: { scoreCardData: null },
  activeScoreCardData: { activeScoreCardData: null },
  scoreCardCategoryData: { scoreCardCategoryData: null },
  scoreCardCriteriaData: { scoreCardCriteriaData: null },
  scoreCardAttributeData: { scoreCardAttributeData: null },
  scoreCardLevel: { level: 1 },
  scoreCardActiveDelete: { scoreCardActiveDelete: null },
  scoreCardLevelDataId: { scoreCardId: null, categoryId: null, criteriaId: null, criteriaType: null },  
  scoreCardBlockedModalResponse: { response: null },
  scoreCardActiveElement: { name: null },
  scoreCardActiveRow: { rowIndex: null, rowType: null, rowId: null },
  scoreCardLevelTree: { level: null },
  activeTree: { activeTree: null },
  scoreCardRecoveryData: { recoveryData: null },
  scoreCardAtrributeAvailability: { status: false },
  scoreCardRemainingWeight: { weight: 0 },
  scoreCardRemainingWeightWarning: { weightWarningStatus: false },
  scoreCardRemainingPoint: { point: 0 }
};

export const reducerScoreCard = (state = initialState, action: ScoreCardActionsTypes): ScoreCardState => {
  
  switch (action.type) {


    case SCORECARDDATA:
      return {
        ...state,
        scoreCardData: action.payload.scoreCardData
      };
    case ACTIVESCORECARDDATA:
      return {
        ...state,
        activeScoreCardData: action.payload.activeScoreCardData
      };
    case SCORECARDCATEGORYDATA:
      return {
        ...state,
        scoreCardCategoryData: action.payload.scoreCardCategoryData
      };
    case SCORECARDCRITERIADATA:
      return {
        ...state,
        scoreCardCriteriaData: action.payload.scoreCardCriteriaData
      };
    case SCORECARDATTRIBUTEDATA:
      const { scoreCardAttributeData } = action.payload
      return {
        ...state,
        scoreCardAttributeData: scoreCardAttributeData
      };
    case SCORECARDLEVEL:
      return {
        ...state,
        scoreCardLevel: action.payload.scoreCardLevel
      };
      
    case SCORECARDACTIVEDELETE:
      return {
        ...state,
        scoreCardActiveDelete: action.payload.scoreCardActiveDelete
      };

    case SCORE_CARD_LEVEL_DATA_ID:
      const { scoreCardLevelDataId } = action.payload
      return {
        ...state,
        scoreCardLevelDataId: scoreCardLevelDataId
      };

    case SCORE_CARD_BLOCKED_MODAL_RESPONSE:
      const { scoreCardBlockedModalResponse } = action.payload
      return {
        ...state,
        scoreCardBlockedModalResponse: scoreCardBlockedModalResponse
      }

    case SCORE_CARD_ACTIVE_ELEMENT:
      const { scoreCardActiveElement } = action.payload
      return {
        ...state,
        scoreCardActiveElement: scoreCardActiveElement
      }

    case SCORE_CARD_ACTIVE_ROW:
      const { scoreCardActiveRow } = action.payload
      return {
        ...state,
        scoreCardActiveRow: scoreCardActiveRow
      }

    case SCORE_CARD_REMOVE_NEW_ROW:
      const { level } = action.payload
      let dataWithoutNewRow = []

      if (level === 1) {
        dataWithoutNewRow = state.scoreCardData.scoreCardData.filter((element: any) => element.newRow !== true)

        return {
          ...state,
          scoreCardData: { scoreCardData: dataWithoutNewRow }
        }
      }
      if (level === 2) {
        dataWithoutNewRow = state.scoreCardCategoryData.scoreCardCategoryData.filter((element: any) => element.newRow !== true)

        return {
          ...state,
          scoreCardCategoryData: { scoreCardCategoryData: dataWithoutNewRow }
        }
      }
      if (level === 3) {
        dataWithoutNewRow = state.scoreCardCriteriaData.scoreCardCriteriaData.filter((element: any) => element.newRow !== true)

        return {
          ...state,
          scoreCardCriteriaData: { scoreCardCriteriaData: dataWithoutNewRow }
        }
      }
      if (level === 4) {
        dataWithoutNewRow = state.scoreCardAttributeData.scoreCardAttributeData.filter((element: any) => element.newRow !== true)

        return {
          ...state,
          scoreCardAttributeData: { scoreCardAttributeData: dataWithoutNewRow }
        }
      }
      return state;

    case SCORE_CARD_CHANGE_ROW_EDITABLE_STATUS:
      const { levelOfData, id, status } = action.payload

      if (levelOfData === 1 && id !== null) {

        let specificData = state.scoreCardData.scoreCardData.find((element: any) => element.id === id)
        specificData.editable = status
        specificData.newRow = false

      }
      if (levelOfData === 2 && id !== null) {

        let specificData = state.scoreCardCategoryData.scoreCardCategoryData.find((element: any) => element.id === id)
        specificData.editable = status
        specificData.newRow = false
      }
      if (levelOfData === 3 && id !== null) {

        let specificData = state.scoreCardCriteriaData.scoreCardCriteriaData.find((element: any) => element.id === id)
        specificData.editable = status
        specificData.newRow = false
      }
      if (levelOfData === 4 && id !== null) {

        let specificData = state.scoreCardAttributeData.scoreCardAttributeData.find((element: any) => element.id === id)
        specificData.editable = status
        specificData.newRow = false

      }

      return state;

    case SCORECARDLEVELTREE:
      return {
        ...state,
        scoreCardLevelTree: action.payload.scoreCardLevelTree
      };

    case ACTIVETREE:
      return {
        ...state,
        activeTree: action.payload.activeTree
      };

    case SCORE_CARD_RECOVERY_DATA:
      const { scoreCardRecoveryData } = action.payload
      return {
        ...state,
        scoreCardRecoveryData: scoreCardRecoveryData
      }

    case SCORE_CARD_ATTRIBUTE_AVAILABILITY:
      const { scoreCardAttributeAvailability } = action.payload
      return {
        ...state,
        scoreCardAtrributeAvailability: scoreCardAttributeAvailability
      }

    case SCORE_CARD_WEIGHT_EDIT_STATUS:
      const { levelOfWeightData, weightRowId, weightRowStatus } = action.payload

      if (levelOfWeightData === 2 && weightRowId !== null) {

        let specificData = state.scoreCardCategoryData.scoreCardCategoryData?.find((element: any) => element.id === weightRowId)
        specificData.weightEditStatus = weightRowStatus
      }
      if (levelOfWeightData === 3 && weightRowId !== null) {

        let specificData = state.scoreCardCriteriaData.scoreCardCriteriaData?.find((element: any) => element.id === weightRowId)
        specificData.weightEditStatus = weightRowStatus
      }
      if (levelOfWeightData === 4 && weightRowId !== null) {

        let specificData = state.scoreCardAttributeData.scoreCardAttributeData?.find((element: any) => element.id === weightRowId)
        specificData.weightEditStatus = weightRowStatus

      }

      return state;

    case SCORE_CARD_REMAINING_WEIGHT:
      const { remainingWeightLevel, remainingWeightParentId } = action.payload
      const maxWeight: number = 100;
      let usedWeight: number = 0;
      let remainingWeight: number;

      if (remainingWeightLevel === 2 && remainingWeightParentId !== null) {
        let categoryDataWithSameScoreCardId = state.scoreCardCategoryData.scoreCardCategoryData?.filter(
          (element: any) => element.score_card_id === remainingWeightParentId)

        categoryDataWithSameScoreCardId?.forEach(
          (element: any) => {
            if (element.weight !== "") {
              usedWeight += parseFloat(element.weight)
            }
          })

        remainingWeight = +(maxWeight - usedWeight).toFixed(2);

        return {
          ...state,
          scoreCardRemainingWeight: { weight: remainingWeight }
        }
      }

      if (remainingWeightLevel === 3 && remainingWeightParentId !== null) {
        let categoryDataWithSameScoreCardId = state.scoreCardCriteriaData.scoreCardCriteriaData?.filter(
          (element: any) => element.score_card_category_id === remainingWeightParentId)

        categoryDataWithSameScoreCardId?.forEach(
          (element: any) => {
            if (element.weight !== "") {
              usedWeight += parseFloat(element.weight)
            }
          })

        remainingWeight = +(maxWeight - usedWeight).toFixed(2);
        
        return {
          ...state,
          scoreCardRemainingWeight: { weight: remainingWeight }
        }
      }

      return state;

    case SCORE_CARD_REMAINING_WEIGHT_WARNING:
      const { weightWarningStatus } = action.payload

      return {
        ...state,
        scoreCardRemainingWeightWarning: { weightWarningStatus }
      }

    case SCORE_CARD_CALCULATE_POINT:
      const { levelOfPointData, pointRowId, pointParentId } = action.payload

      if (levelOfPointData === 2 && pointRowId !== null && pointParentId !== null) {
        let parentOfSpecificData = state.scoreCardData?.scoreCardData?.find((element: any) => element.id === pointParentId)
        let specificData = state.scoreCardCategoryData?.scoreCardCategoryData?.find((element: any) => element.id === pointRowId)
        const parentMaxScore = parentOfSpecificData?.max_score
        const copyOfSpecificData = specificData
        const copyOfState = state.scoreCardCategoryData?.scoreCardCategoryData
        const indexOfSpecificData = copyOfState?.indexOf(specificData)

        copyOfSpecificData.point = (+parentMaxScore * +copyOfSpecificData.weight / 100).toFixed(2)

        copyOfState[indexOfSpecificData] = copyOfSpecificData

        return {
          ...state,
          scoreCardCategoryData: { scoreCardCategoryData: copyOfState }
        }

      }

      if (levelOfPointData === 3 && pointRowId !== null && pointParentId !== null) {
        let parentOfSpecificData = state.scoreCardCategoryData?.scoreCardCategoryData?.find((element: any) => element.id === pointParentId)
        let specificData = state.scoreCardCriteriaData?.scoreCardCriteriaData?.find((element: any) => element.id === pointRowId)
        const parentPoint = parentOfSpecificData?.point
        const copyOfSpecificData = specificData
        const copyOfState = state.scoreCardCriteriaData?.scoreCardCriteriaData
        const indexOfSpecificData = copyOfState?.indexOf(specificData)

        copyOfSpecificData.point = (+parentPoint * +copyOfSpecificData.weight / 100).toFixed(2)

        copyOfState[indexOfSpecificData] = copyOfSpecificData

        return {
          ...state,
          scoreCardCriteriaData: { scoreCardCriteriaData: copyOfState }
        }

      }

      if (levelOfPointData === 4 && pointRowId !== null && pointParentId !== null) {
        let parentOfSpecificData = state.scoreCardCriteriaData?.scoreCardCriteriaData?.find((element: any) => element.id === pointParentId)
        let specificData = state.scoreCardAttributeData?.scoreCardAttributeData?.find((element: any) => element.id === pointRowId)
        const parentPoint = parentOfSpecificData?.point
        const copyOfSpecificData = specificData
        const copyOfState = state.scoreCardAttributeData?.scoreCardAttributeData
        const indexOfSpecificData = copyOfState?.indexOf(specificData)

        copyOfSpecificData.point = (+parentPoint * +copyOfSpecificData.weight / 100).toFixed(2)

        copyOfState[indexOfSpecificData] = copyOfSpecificData

        return {
          ...state,
          scoreCardAttributeData: { scoreCardAttributeData: copyOfState }
        }

      }

      return state;

    case SCORE_CARD_REMAINING_POINT:
      const { remainingPointLevel, remainingPointParentId } = action.payload
      let maxParentPoint: number;
      let usedPoint: number = 0;
      let remainingPoint: number;

      if (remainingPointLevel === 2 && remainingPointParentId !== null) {
        const specificParentData = state?.scoreCardData?.scoreCardData?.find(
          (element: any) => element.id === remainingPointParentId
        )

        const categoriesWithSameScoreCardId = state?.scoreCardCategoryData?.scoreCardCategoryData?.filter(
          (element: any) => element.score_card_id === remainingPointParentId
        )

        categoriesWithSameScoreCardId?.forEach(
          (element: any) => {
            usedPoint = usedPoint += parseFloat(element.point)}
        );

        maxParentPoint = specificParentData?.max_score
        remainingPoint = +(maxParentPoint - usedPoint).toFixed(2)

        return {
          ...state,
          scoreCardRemainingPoint: { point: remainingPoint }
        }
      }

      if (remainingPointLevel === 3 && remainingPointParentId !== null) {
        const specificParentData = state?.scoreCardCategoryData?.scoreCardCategoryData?.find(
          (element: any) => element.id === remainingPointParentId
        )

        const categoriesWithSameScoreCardId = state?.scoreCardCriteriaData?.scoreCardCriteriaData?.filter(
          (element: any) => element.score_card_category_id === remainingPointParentId
        )

        categoriesWithSameScoreCardId?.forEach(
          (element: any) => usedPoint = usedPoint += parseFloat(element.point)
        );

        maxParentPoint = specificParentData?.point
        remainingPoint = +(maxParentPoint - usedPoint).toFixed(2)

        return {
          ...state,
          scoreCardRemainingPoint: { point: remainingPoint }
        }
      }

      return state;

    default:
      return state;

  }
}