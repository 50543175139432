export const generateUid = (attribute: string, name: string) => {
  const svg = document.querySelector("#svg_board") as SVGElement;
  const board_elements = svg?.querySelectorAll(
    `rect[${attribute} ^= ${name}]`
  ) as NodeList;

  let data_id = null;
  if (name === "startpoint") {
    data_id = "startpoint";
  } else if (name === "endpoint") {
    data_id = "endpoint";
  } else if (name === "validate") {
    data_id = "validate";
  } else if (name === "datawizard") {
    data_id = "datawizard";
  } else {
    if (board_elements?.length === 0) {
      data_id = `${name}_1`;
    } else {
      board_elements?.forEach((element: any) => {
        let _exists_id: string = element?.getAttribute("data-id");
        let _id: number = +_exists_id.split("_")[1];
        _id++;
        let check = svg.querySelectorAll(
          `g[data-id = "${name}_${_id}"]`
        ) as NodeList;
        if (check.length === 0) {
          data_id = `${name}_${_id}`;
        }
      });
    }
  }

  return data_id;
};
