import React, {
  ClipboardEvent,
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import classes from "./SidePanelDataWizard.module.scss";
import { useDispatch, useSelector } from "react-redux";
import objectPath from "object-path";

import {
  ButtonSizes,
  ButtonTypes,
  DataTypes,
  ElementTypes,
  icons,
  messages,
  ModalNames,
  storageKeys,
} from "../../../../../settings/settings";
import {
  handlerStrategyBoardPanel,
  handlerStrategyElementInput,
  handleStrategyBoardSidePanelContent,
  handleStrategyWizardStatus,
} from "../../../../../store/actions/actionsStrategy";
import Input from "../../../../../components/UI/Input/Input";
import DataViewer from "../../../../../components/UI/DataViewer/DataViewer";
import StrategyEditor, {
  IIFunctionData,
  IIndexedKeys,
} from "../../../../../components/UI/StrategyEditor/StrategyEditor";
import StrategyFunctions from "../../../../../components/UI/StrategyFunctions/StrategyFunctions";
import { checkField } from "../../../../../helpers/checkField";
import { submitElement } from "../../../../../helpers/submitElement";
import {
  closeModal,
  openModal,
  resetModal,
} from "../../../../../store/actions/actionsModal";
import { RootState } from "../../../../../store/combineReducer";
import Modal from "../../../../../components/UI/Modal/Modal";
import DB from "../../../../../layout/DB/Storage";
import WizardList from "../../../../../components/UI/WizardList/WizardList";
import ButtonV2 from "../../../../../components/UI/Button/ButtonV2";
import { hasChange } from "../../../../../helpers/hasChange";
import { useDebounce } from "use-debounce";
import Select from "../../../../../components/UI/Select/Select";
import { removeWizardFromBoard } from "helpers/removeElement";
import { TooltipInfo } from "components/UI/Tooltip/InfoTooltip/InfoTooltip";

interface IDataWizardState {
  parentKey: string;
  required: boolean;
  object_type: string;
  validation: Record<
    string,
    {
      role_condition: string;
      // error_message: string;
      code_message: {
        code: string;
        message: string;
      };
      re_prepare_func: string;
    }
  >;
}

interface IValidating {
  parentKey: boolean;
  role_condition: boolean;
  optionalField: boolean;
  code: boolean;
}

interface IDeleteState {
  parentKey: string;
  validationKey: string | undefined;
  isNested: string;
}

type IFreezeWizardState = Readonly<{
  parentKey: string;
  required: boolean;
  object_type: string;
  validation: Readonly<
    Record<
      string,
      Readonly<{
        role_condition: string;
        // error_message: string;
        code_message: {
          code: string;
          message: string;
        };
        re_prepare_func: string;
      }>
    >
  >;
}>;

enum WizardPanelActions {
  SELECT,
  CLOSE,
  RESET,
  ADD,
  VALIDATE,
  DELETE,
  MANUAL_KEY,
  ERROR,
}

let timeOutInstance: any;
let initialState: IFreezeWizardState = {
  parentKey: "",
  required: true,
  object_type: "",
  validation: {
    validation_0: {
      role_condition: "",
      // error_message: "",
      code_message: {
        code: "",
        message: "",
      },
      re_prepare_func: "",
    },
  },
};
const dataTypes = Object.values(DataTypes)?.map((type: string) => {
  return {
    option: type,
    value: type,
  };
});

let refCodeMessageListData = {};

const SidePanelDataWizard: FC = () => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [isOpenTooltipInfo, setIsOpenTooltipInfo] = useState<boolean>(false);
  const dispatch = useDispatch();
  const {
    reducerModal: { modalState },
  } = useSelector((state: RootState) => state);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [lastActiveEditor, setLastActiveEditor] = useState<string>();
  const [functionDescription, setFunctionDescription] = useState<string>();
  const [functionSyntax, setFunctionSyntax] = useState<string>();
  const [selectedFunctionBody, setSelectedFunctionBody] = useState<
    IIndexedKeys | undefined
  >();
  const [editorFunctionData, setEditorFunctionData] = useState<
    IIFunctionData | undefined
  >();
  const [prevDataWizardData, setPrevDataWizardData] = useState<object>({});
  const [hasWizard, setHasWizard] = useState<boolean>(false);
  const [dataWizardEditorState, setDataWizardEditorState] = useState<any>({
    parentKey: "",
    role_condition: "",
    re_prepare_func: "",
  });

  const [dataWizardDataState, setDataWizardDataState] =
    useState<IDataWizardState>({
      parentKey: "",
      required: true,
      object_type: "",
      validation: {
        validation_0: {
          role_condition: "",
          // error_message: "",
          code_message: { code: "", message: "" },
          re_prepare_func: "",
        },
      },
    });
  const [validating, setValidating] = useState<IValidating>({
    parentKey: false,
    role_condition: false,
    optionalField: false,
    code: false,
  });
  const [activePanelAction, setActivePanelAction] =
    useState<WizardPanelActions | null>(null);
  const [selectedState, setSelectedState] = useState<IDataWizardState>();
  const [deleteState, setDeleteState] = useState<IDeleteState>();
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [hasManualKey, setHasManualKey] = useState<{
    [key: string]: boolean;
  }>({ condtBody: false });
  const [reformattedTreeDataState, setReformattedTreeDataState] =
    useState<any>();
  const [codeMessageState, setCodeMessageState] = useState({
    code: "",
    message: "",
  });
  const [codeMessageList, setCodeMessageList] = useState<
    Array<Record<string, string>>
  >([]);
  const [debouncedHasManualKey] = useDebounce(hasManualKey, 5e2);
  const dataViewerDisabledGroups = useMemo(() => ["variables", "maps"], []);

  const getReformattedSourceData = useCallback(() => {
    const reformattedSourceJSON = sessionStorage.getItem(
      storageKeys.activeStrategySourceData
    );

    if (!reformattedSourceJSON) return;

    setReformattedTreeDataState(JSON.parse(reformattedSourceJSON));
  }, []);
  useLayoutEffect(getReformattedSourceData, [getReformattedSourceData]);

  const getPrevDataWizardData = useCallback(() => {
    const db = new DB(storageKeys.boardElements);

    db.fetch({ type: ElementTypes.DATA_WIZARD })
      .then((result: any) => {
        const { status, data } = result;

        if (status) {
          const copyOFData = { ...data };
          setPrevDataWizardData({ ...copyOFData });
          setHasWizard(true);
        } else {
          console.warn(messages.messageStorageFetchStatusFalse, data);
        }
      })
      .catch((error: any) => {
        console.error(messages.messageStorageFetchError, error);
      });
  }, []);
 
  const showInfo = (e: any) => {
    setIsOpenTooltipInfo(!isOpenTooltipInfo);
   };
  const getCodeMessageList = useCallback(() => {
    const db = new DB(storageKeys.boardElements);

    db.fetch({ type: ElementTypes.MESSAGE_LIST })
      .then((result: any) => {
        const { status, data } = result;

        if (status) {
          refCodeMessageListData = { ...data };
          const {
            data: { code_message_list },
          } = data;
          setCodeMessageList(code_message_list);
        } else {
          console.warn(messages.messageStorageFetchStatusFalse, data);
        }
      })
      .catch((error: any) => {
        console.error(messages.messageStorageFetchError, error);
      });
  }, []);

  const restoreDefaults = useCallback(() => {
    if (editorFunctionData) {
      setSelectedFunctionBody(undefined);
      setEditorFunctionData(undefined);
    }
  }, [editorFunctionData]);

  const handleNodeInputChanges = useCallback(
    (data: string | { valueIndex: string; value: string }) => {
      if (typeof data === "string") return;

      let { valueIndex, value } = data;
      const model = objectPath(reformattedTreeDataState.data);
      const decisionType = typeof model.get(value);
      setDataWizardDataState((state: any) => {
        return {
          ...state,
          object_type:
            model.get(value) !== undefined && model.get(value) !== null
              ? decisionType
              : "",
          parentKey: value,
        };
      });
      setDataWizardEditorState((state: any) => {
        return {
          ...state,
          [valueIndex]: value,
        };
      });
      restoreDefaults();
    },
    [reformattedTreeDataState, restoreDefaults]
  );

  const validateErrorCode = useCallback(
    (value: string) => {
      const hasCreatedErrorCode = codeMessageList.find(({ code }) => {
        return code === value;
      });

      return Boolean(hasCreatedErrorCode);
    },
    [codeMessageList]
  );

  const handleErrorInputChanges = useCallback(
    (value: string, label: string) => {
      setDataWizardDataState((state: any) => {
        return {
          ...state,
          validation: {
            validation_0: {
              ...state.validation.validation_0,
              code_message: {
                code:
                  label === "code"
                    ? value
                    : state.validation.validation_0.code_message.code,
                message:
                  label === "message"
                    ? value
                    : state.validation.validation_0.code_message.message,
              },
            },
          },
        };
      });
      setCodeMessageState((state) => {
        return {
          ...state,
          [label]: value,
        };
      });
    },
    []
  );

  const changeDataWizardEditorState = useCallback(
    (data: string | { valueIndex: string; value: string }) => {
      if (typeof data === "string") return;

      let { valueIndex, value } = data;
      value = value.replace(/[=*]/g, "=").replace("==", "=");

      setDataWizardEditorState((state: any) => {
        return {
          ...state,
          [valueIndex]: value,
        };
      });
      restoreDefaults();
    },
    [restoreDefaults]
  );

  const handlePaste = (e: ClipboardEvent<HTMLTextAreaElement>): void => {
    // e.preventDefault();
    // let value = e.clipboardData.getData("text");
    // e.currentTarget.value = value.replace(/[=*]/g, '=').replace('==','=').replaceAll('!=','<>')
  };

  const changeInputNode = useCallback((value: string) => {
    if (value.includes("root")) {
      value = value.replaceAll("root.", "");
    }
    if (value === "") {
      setDataWizardDataState((state: any) => {
        return {
          ...state,
          object_type: "",
        }
      })
    }
    setDataWizardDataState((state: any) => {
      return {
        ...state,
        parentKey: value,
      };
    });
  }, []);

  const changeRoleCondition = useCallback((value: string) => {
    if (value.includes("root")) {
      value = value.replaceAll("root.", "data.");
    }
    setDataWizardDataState((state: any) => {
      return {
        ...state,
        validation: {
          validation_0: {
            ...state.validation.validation_0,
            role_condition: value,
          },
        },
      };
    });
  }, []);

  const changeRePrepareFunction = useCallback((value: string) => {
    if (value.includes("root")) {
      value = value.replaceAll("root.", "data.");
    }
    setDataWizardDataState((state: any) => {
      return {
        ...state,
        validation: {
          validation_0: {
            ...state.validation.validation_0,
            re_prepare_func: value,
          },
        },
      };
    });
  }, []);

  const handleFunctionsDoubleClick = useCallback(
    (func: IIndexedKeys) => {
      lastActiveEditor && setSelectedFunctionBody(func);
    },
    [lastActiveEditor]
  );

  const select = useCallback(() => {
    if (!selectedState) return;

    setDataWizardDataState(selectedState);
    setDataWizardEditorState({ ...selectedState.validation.validation_0 });
    initialState = {
      ...selectedState,
      validation: {
        validation_0: { ...selectedState.validation.validation_0 },
      },
    };
    dispatch(resetModal());
  }, [selectedState, dispatch]);

  const reset = useCallback(() => {
    setDataWizardDataState({
      parentKey: "",
      required: true,
      object_type: "",
      validation: {
        validation_0: {
          role_condition: "",
          // error_message: "",
          code_message: {
            code: "",
            message: "",
          },
          re_prepare_func: "",
        },
      },
    });
    setDataWizardEditorState({
      role_condition: "",
      re_prepare_func: "",
    });
    setValidating({
      parentKey: false,
      role_condition: false,
      optionalField: false,
      code: false,
    });
    initialState = {
      parentKey: "",
      required: true,
      object_type: "",
      validation: {
        validation_0: {
          role_condition: "",
          // error_message: "",
          code_message: {
            code: "",
            message: "",
          },
          re_prepare_func: "",
        },
      },
    };
    dispatch(resetModal());
    dispatch(handleStrategyWizardStatus({ status: false }));
  }, [dispatch]);

  const del = useCallback(() => {
    if (!deleteState) return;

    const copyData: any = { ...prevDataWizardData };
    const { parentKey, validationKey, isNested }: IDeleteState = deleteState;
    const db = new DB(storageKeys.boardElements);

    if (isNested) {
      delete copyData.data[parentKey].validation[validationKey as string];
    } else {
      delete copyData.data[parentKey];
    }

    db.update({ type: ElementTypes.DATA_WIZARD }, [copyData])
      .then((result: any) => {
        const { status, data } = result;

        if (!status) {
          console.warn(messages.messageStorageUpdateStatusFalse, result);
          return;
        }

        setPrevDataWizardData(data);
        dispatch(resetModal());
      })
      .catch((error: any) => {
        console.error(messages.messageStorageUpdateError, error);
        dispatch(
          openModal({
            modalState: {
              visible: true,
              title: messages.titleOops,
              question: messages.titleWentWrong,
              message: "",
              buttonMessage: messages.titleAgain,
              buttonType: ButtonTypes.DELETE,
              name: ModalNames.DATA_WIZARD_PANEL,
            },
          })
        );
      });
  }, [dispatch, deleteState, prevDataWizardData]);

  const close = useCallback(() => {
    const { current } = wrapperRef;
    dispatch(resetModal());
    if (current) {
      current.classList.add(classes.CloseAnimation);
    }

    timeOutInstance = setTimeout(() => {
      dispatch(handlerStrategyElementInput({ element_input: null }));
      dispatch(handlerStrategyBoardPanel({ element_panel: false }));
      dispatch(handleStrategyBoardSidePanelContent({ content_value: null }));
      prevDataWizardData && removeWizardFromBoard(prevDataWizardData);
    }, 1e3);
  }, [dispatch, prevDataWizardData]);

  const onListSelect = useCallback(
    (state: IDataWizardState) => {
      const copyOfDataState = { ...dataWizardDataState };
      if (
        copyOfDataState.validation.validation_0.role_condition.includes("=") &&
        !copyOfDataState.validation.validation_0.role_condition.includes("==")
      ) {
        copyOfDataState.validation.validation_0.role_condition =
          copyOfDataState.validation.validation_0.role_condition.replaceAll(
            "=",
            "=="
          );
      }
      if (
        copyOfDataState.validation.validation_0.role_condition.includes("<>")
      ) {
        copyOfDataState.validation.validation_0.role_condition =
          copyOfDataState.validation.validation_0.role_condition.replaceAll(
            "<>",
            "!="
          );
      }
      hasChange({
        initial: initialState,
        changeable: copyOfDataState,
      })
        .then(() => {
          setActivePanelAction(WizardPanelActions.SELECT);
          setSelectedState({ ...state });
          dispatch(
            openModal({
              modalState: {
                visible: true,
                title: messages.titleSelectPanel("wizard"),
                question: messages.titleSureSelectPanel("wizard"),
                message: messages.messageSelect,
                buttonMessage: messages.titleBtnSelect,
                buttonType: ButtonTypes.PRIMARY,
                name: ModalNames.DATA_WIZARD_PANEL,
              },
            })
          );
        })
        .catch((error) => {
          error && console.error(error);
          setDataWizardDataState({ ...state });
          setDataWizardEditorState({ ...state.validation.validation_0, ...state.validation.validationKey });
          setCodeMessageState({
            ...state.validation.validation_0.code_message,
          });
          initialState = {
            ...state,
            validation: {
              validation_0: { ...state.validation.validation_0 },
            },
          };
        });
    },
    [dispatch, dataWizardDataState]
  );
  const onListAddition = useCallback(() => {
    hasChange({
      initial: initialState,
      changeable: dataWizardDataState,
    })
      .then(() => {
        setActivePanelAction(WizardPanelActions.ADD);
        dispatch(
          openModal({
            modalState: {
              visible: true,
              title: messages.titleClosePanel,
              question: messages.titleSureAdd("wizard"),
              message: messages.messageAdd,
              buttonMessage: messages.titleBtnAdd,
              buttonType: ButtonTypes.PRIMARY,
              name: ModalNames.DATA_WIZARD_PANEL,
            },
          })
        );
      })
      .catch((error) => {
        error && console.error(error);
        reset();
      });
  }, [dataWizardDataState, dispatch, reset]);

  const onListDelete = useCallback(
    (parentKey, validationKey, isNested) => {
      dispatch(
        openModal({
          modalState: {
            visible: true,
            title: messages.titleDeletePanel("wizard"),
            question: messages.titleSureDelete("wizard"),
            message: "",
            buttonMessage: messages.titleBtnDelete,
            buttonType: ButtonTypes.DELETE,
            name: ModalNames.DATA_WIZARD_PANEL,
          },
        })
      );
      setActivePanelAction(WizardPanelActions.DELETE);
      setDeleteState({ parentKey, validationKey, isNested });
    },
    [dispatch]
  );

  const onReset = useCallback(() => {
    setIsEditMode(false);
    hasChange({
      initial: initialState,
      changeable: dataWizardDataState,
    })
      .then(() => {
        setActivePanelAction(WizardPanelActions.RESET);
        dispatch(
          openModal({
            modalState: {
              visible: true,
              title: messages.titleResetForm,
              question: messages.titleSureResetForm,
              message: messages.messageReset,
              buttonMessage: messages.titleBtnReset,
              buttonType: ButtonTypes.WARNING,
              name: ModalNames.DATA_WIZARD_PANEL,
            },
          })
        );
      })
      .catch((error) => {
        error && console.error(error);
        reset();
      });
  }, [dataWizardDataState, reset, dispatch]);

  const onClose = useCallback(() => {
    setIsOpenTooltipInfo(false);
    hasChange({
      initial: initialState,
      changeable: dataWizardDataState,
    })
      .then(() => {
        setActivePanelAction(WizardPanelActions.CLOSE);
        dispatch(
          openModal({
            modalState: {
              visible: true,
              title: messages.titleClosePanel,
              question: messages.titleSureClosePanel,
              message: messages.messagePanelClosing,
              buttonMessage: messages.titleBtnClose,
              buttonType: ButtonTypes.PRIMARY,
              name: ModalNames.DATA_WIZARD_PANEL,
            },
          })
        );
      })
      .catch((error) => {
        error && console.error(error);
        close();
      });

  }, [dataWizardDataState, dispatch, close]);

  const handleModalAction = useCallback(
    (action: boolean) => {
      const { validatingField } = modalState;

      switch (activePanelAction) {
        case WizardPanelActions.SELECT: {
          if (!action) {
            dispatch(resetModal());
            return;
          }

          select();
          break;
        }
        case WizardPanelActions.ADD:
        case WizardPanelActions.RESET: {
          if (!action) {
            dispatch(resetModal());
            return;
          }

          reset();
          break;
        }
        case WizardPanelActions.VALIDATE: {
          setValidating({
            ...validating,
            [validatingField as string]: true,
          });
          dispatch(closeModal());
          break;
        }
        case WizardPanelActions.DELETE: {
          if (!action) {
            dispatch(resetModal());
            return;
          }
          del();
          break;
        }
        case WizardPanelActions.CLOSE: {
          if (!action) {
            dispatch(resetModal());
            return;
          }
          close();
          break;
        }
        case WizardPanelActions.MANUAL_KEY:
        case WizardPanelActions.ERROR: {
          dispatch(resetModal());
        }
      }
    },
    [
      modalState,
      activePanelAction,
      validating,
      select,
      reset,
      del,
      close,
      dispatch,
    ]
  );

  const setFullScreenState = useCallback(() => {
    const { current } = wrapperRef;

    if (current && fullScreen) {
      current.classList.remove(classes.FullScreen);
      setFullScreen(false);
    } else if (current && !fullScreen) {
      current.classList.add(classes.FullScreen);
      setFullScreen(true);
    }
  }, [fullScreen]);

  const setManualKeyStatus = useCallback(
    (status: { [key: string]: boolean }) => {
      const [key, value] = Object.entries(status)[0];

      setHasManualKey((state) => {
        return {
          ...state,
          [key]: value,
        };
      });
    },
    []
  );

  const setObjectType = useCallback((value: string) => {
    setDataWizardDataState((state: IDataWizardState) => {
      return { ...state, object_type: value };
    });
  }, []);

  useLayoutEffect(getPrevDataWizardData, [getPrevDataWizardData]);

  useLayoutEffect(getCodeMessageList, [getCodeMessageList]);

  useEffect(() => {
    if (selectedFunctionBody && lastActiveEditor) {
      setEditorFunctionData({
        editorIndex: lastActiveEditor,
        data: selectedFunctionBody,
      });
    }
  }, [selectedFunctionBody, lastActiveEditor]);

  useEffect(() => {
    return () => clearTimeout(timeOutInstance);
  }, []);

  const generateNewCodeMessageId = () => {
    const ids: number[] = codeMessageList.map((codeMessage) => {
      return Number(codeMessage.id?.slice(-1));
    });
    let newId = "codemessage_1";

    if (ids.length) {
      const id = Math.max(...ids) + 1;
      newId = `${newId.slice(0, newId.length - 1)}${id}`;
    }

    return newId;
  };

  const handleSubmit = () => {
    if (Object.values(debouncedHasManualKey).some((value) => value)) {
      dispatch(
        openModal({
          modalState: {
            visible: true,
            title: messages.titleAttention,
            question: messages.titleManualKey,
            message: messages.messageManualKey,
            buttonMessage: messages.titleUnderstand,
            buttonType: ButtonTypes.WARNING,
            name: ModalNames.DATA_WIZARD_PANEL,
          },
        })
      );
      setActivePanelAction(WizardPanelActions.MANUAL_KEY);
      return;
    }
    const copyOFDataState = { ...dataWizardDataState };
    if ("validation" in copyOFDataState) {
      let {
        validation: {
          validation_0: { role_condition },
        },
      } = dataWizardDataState;
      if (!role_condition) return;
      role_condition = role_condition.replace("=", "==").replaceAll("<>", "!=");
      copyOFDataState.validation.validation_0.role_condition = role_condition;
    }

    if (
      validateErrorCode(
        copyOFDataState.validation.validation_0.code_message.code
      )
    ) {
      dispatch(
        openModal({
          modalState: {
            visible: true,
            title: messages.titleAttention,
            question: messages.titleDuplicate("message code"),
            message: messages.messageDublicatedMessageCode,
            buttonMessage: messages.titleUnderstand,
            buttonType: ButtonTypes.WARNING,
            name: ModalNames.DATA_WIZARD_PANEL,
            validatingField: "code",
          },
        })
      );
      setActivePanelAction(WizardPanelActions.VALIDATE);
      return;
    }
    checkField({ state: copyOFDataState, types: ElementTypes.DATA_WIZARD })
      .then(() => {
        submitElement(
          !hasWizard
            ? {
                id: "wizard",
                state: copyOFDataState,
                type: ElementTypes.DATA_WIZARD,
                isNew: true,
                isEditMode
              }
            : {
                id: "wizard",
                state: copyOFDataState,
                previousData: prevDataWizardData,
                type: ElementTypes.DATA_WIZARD,
                isNew: false,
                isEditMode
              }
        )
          .then(() => {
            setIsEditMode(false)
            getPrevDataWizardData();
            !hasWizard &&
              dispatch(handleStrategyWizardStatus({ status: true }));
            reset();
            const codeMessageIndex = codeMessageList.findIndex(({ code }) => {
              return code === codeMessageState.code;
            });

            let newList = [];

            if (codeMessageIndex === -1) {
              newList = [
                ...codeMessageList,
                { id: generateNewCodeMessageId(), ...codeMessageState },
              ];           
            } else {
              codeMessageList[codeMessageIndex] = {
                id: codeMessageList[codeMessageIndex].id,
                ...codeMessageState,
              };
              newList = [...codeMessageList];
            }           
            const currentBoardData = JSON.parse(localStorage.getItem(storageKeys.boardElements) || "");

            new DB(storageKeys.boardElements).update(
              { type: ElementTypes.MESSAGE_LIST },
              [
                ...currentBoardData,
                {
                  ...refCodeMessageListData,
                  data: {
                    code_message_list: newList,
                  },
                },
              ]
            );
          })
          .catch((error: any) => {
            console.error(messages.messageStorageUpdateError, error);
            dispatch(
              openModal({
                modalState: {
                  visible: true,
                  title: messages.titleOops,
                  question: messages.titleWentWrong,
                  message: messages.messagePanelSubmittingError,
                  buttonMessage: messages.titleAgain,
                  buttonType: ButtonTypes.DELETE,
                  name: ModalNames.DATA_WIZARD_PANEL,
                },
              })
            );
            setActivePanelAction(WizardPanelActions.ERROR);
          });
      })
      .catch(({ reason }) => {
        dispatch(
          openModal({
            modalState: {
              visible: true,
              title: messages.titleAttention,
              question:
                reason === "optionalField"
                  ? messages.titleOptionalBeFilled
                  : reason === "orField"
                  ? messages.messageWizardPrepareOrMessage
                  : messages.titleCantEmptyField,
              message:
                reason === "orField" ? "" : messages.messagePanelEmptyAttention,
              buttonMessage: messages.titleUnderstand,
              buttonType: ButtonTypes.WARNING,
              validatingField: reason,
              name: ModalNames.DATA_WIZARD_PANEL,
            },
          })
        );
        setActivePanelAction(WizardPanelActions.VALIDATE);
      });
  };

  return (
    <div ref={wrapperRef} className={classes.DataWizardWrapper}>
      <section className={classes.DataWizardHeader}>
        <img
          src={fullScreen ? icons.exitFullScreen : icons.fullScreen}
          alt="screen icon"
          title={fullScreen ? "Exit full screen" : "Full screen"}
          onClick={setFullScreenState}
        />
        <span className={classes.HeaderTitle}>Data Wizard</span>
        <div className={classes.HeaderActions} />
      </section>
      <section className={classes.DataWizardBody}>
        <aside className={classes.DataWizardLeft}>
          <DataViewer disabledGroups={dataViewerDisabledGroups} />
          <span className={classes.SpacerNormal} />
          <StrategyFunctions
            onSingleClick={(description: string, syntax: string) => {
              setFunctionDescription(description);
              setFunctionSyntax(syntax);
            }}
            onDoubleClick={handleFunctionsDoubleClick}
          />
        </aside>
        <div className={classes.DataWizardCenter}>
          <StrategyEditor
            label="Input Data node"
            value={dataWizardDataState?.parentKey
              ?.replaceAll("data.", "")
              .replaceAll("root.", "")}
            valueIndex="parentKey"
            getValueIndex={(id: string) => {
              setLastActiveEditor(id);
            }}
            onChange={handleNodeInputChanges}
            onPaste={handlePaste}
            functionData={editorFunctionData}
            validate={validating.parentKey}
            editorModalName={ModalNames.EDITOR_WIZARD_CONDITION}
            elementType={ElementTypes.DATA_WIZARD}
            getManualKeyStatus={setManualKeyStatus}
            getIndexedValue={changeInputNode}
            getDataType={setObjectType}
            replaceOnDrop
          />
          <span className={classes.SpacerNormal} />
          <Select
            placeHolder="Node type"
            options={dataTypes}
            defaultValue={dataWizardDataState?.object_type}
            disabled
          />
          <span className={classes.SpacerNormal} />
          <StrategyEditor
            label="Condition"
            height="17.27vh"
            value={dataWizardEditorState?.role_condition
              .replaceAll("==", "=")
              .replaceAll("!=", "<>")
              .replaceAll("data.", "")}
            valueIndex="role_condition"
            getValueIndex={(id: string) => {
              setLastActiveEditor(id);
            }}
            onChange={changeDataWizardEditorState}
            onPaste={handlePaste}
            functionData={editorFunctionData}
            validate={validating.role_condition}
            editorModalName={ModalNames.EDITOR_WIZARD_CONDITION}
            elementType={ElementTypes.DATA_WIZARD}
            getManualKeyStatus={setManualKeyStatus}
            getIndexedValue={changeRoleCondition}
            onSingleClick={(description: string, syntax: string) => {
              setFunctionDescription(description);
              setFunctionSyntax(syntax);
            }}
          />
          <span className={classes.SpacerNormal} />
          <StrategyEditor
            label="Replace to"
            height="17.27vh"
            value={dataWizardEditorState?.re_prepare_func?.replaceAll(
              "data.",
              ""
            )}
            valueIndex="re_prepare_func"
            getValueIndex={(id: string) => {
              setLastActiveEditor(id);
            }}
            onChange={changeDataWizardEditorState}
            functionData={editorFunctionData}
            validate={validating.optionalField}
            editorModalName={ModalNames.EDITOR_WIZARD_REPLACE}
            elementType={ElementTypes.DATA_WIZARD}
            getManualKeyStatus={setManualKeyStatus}
            getIndexedValue={changeRePrepareFunction}
            onSingleClick={(description: string, syntax: string) => {
              setFunctionDescription(description);
              setFunctionSyntax(syntax);
            }}
          />
          <span className={classes.SpacerNormal} />
          <div className={classes.CodeMessage}>
            <div className={classes.Code}>
              <Input
                label="Code"
                value={
                  Object.values(dataWizardDataState.validation)[0]?.code_message
                    ?.code
                }
                onChange={(value: string) =>
                  handleErrorInputChanges(value, "code")
                }
                validate={validating.optionalField || validating.code}
              />
            </div>
            <div className={classes.Message}>
              <Input
                label="Message"
                value={
                  Object.values(dataWizardDataState.validation)[0]?.code_message
                    ?.message
                }
                onChange={(value: string) =>
                  handleErrorInputChanges(value, "message")
                }
                validate={validating.optionalField}
              />
            </div>
          </div>
          <span className={classes.SpacerNormal} />
          <span className={classes.DescriptionLabel}>Function description</span>
          {functionDescription && (
            <>
              <span className={classes.SpacerMin} />
              <span className={classes.Description}>{functionDescription}</span>
              <span className={classes.Syntax}>{functionSyntax}</span>
              <span className={classes.SpacerMin} />
            </>
          )}
          <span className={classes.SpacerMax} />
          <div className={classes.Footer}>
            <ButtonV2 size={ButtonSizes.LARGE} onClick={handleSubmit}>
              Submit
            </ButtonV2>
            <ButtonV2
              size={ButtonSizes.LARGE}
              type={ButtonTypes.SECONDARY}
              onClick={onReset}
            >
              Reset
            </ButtonV2>
          </div>
        </div>
        <div className={classes.DataWizardRight}>
          <WizardList
            wizardData={prevDataWizardData}
            onSelect={onListSelect}
            onAddition={onListAddition}
            onDelete={onListDelete}
            onEditMode={setIsEditMode}
          />
        </div>
      </section>
      {isOpenTooltipInfo && <TooltipInfo sidePanelUp />}
      {!fullScreen && (
        <>
        <button className={classes.DataWizardCloseButton} onClick={onClose}>
          <img src={icons.closeBtn} alt="Close icon" />
        </button>
        <svg
            className={classes.HelpIcon}
            viewBox='0 0 8 8'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            onClick={(e) => showInfo(e)}
          >
            <path
              d='M4 6.30469C4.21574 6.30469 4.39062 6.1298 4.39062 5.91406C4.39062 5.69833 4.21574 5.52344 4 5.52344C3.78426 5.52344 3.60938 5.69833 3.60938 5.91406C3.60938 6.1298 3.78426 6.30469 4 6.30469Z'
              fill='white'
              fillOpacity='0.6'
            />      
            <path
              d='M4 2.00781C3.31075 2.00781 2.75 2.56856 2.75 3.25781C2.75 3.43041 2.88991 3.57031 3.0625 3.57031C3.23509 3.57031 3.375 3.43041 3.375 3.25781C3.375 2.91319 3.65538 2.63281 4 2.63281C4.34462 2.63281 4.625 2.91319 4.625 3.25781C4.625 3.60244 4.34462 3.88281 4 3.88281C3.82741 3.88281 3.6875 4.02272 3.6875 4.19531V4.97656C3.6875 5.14916 3.82741 5.28906 4 5.28906C4.17259 5.28906 4.3125 5.14916 4.3125 4.97656V4.46827C4.85103 4.32916 5.25 3.8392 5.25 3.25781C5.25 2.56856 4.68925 2.00781 4 2.00781Z'
              fill='white'
              fillOpacity='0.6'
            />
          </svg> 
        </>
        
      )}
      <Modal
        title={modalState?.title}
        question={modalState?.question}
        message={modalState?.message}
        buttonMessage={modalState?.buttonMessage}
        buttonType={modalState?.buttonType}
        onAction={handleModalAction}
        visible={modalState?.visible}
        name={ModalNames.DATA_WIZARD_PANEL}
      />
    </div>
  );
};

export default SidePanelDataWizard;
