import React from 'react';
import classes from '../Diagram.module.scss';
const Slice = (props) => {
    const {num,radius,angle,value,...rest } = props
    const r = radius // for brevity
    const dx = r * Math.sin(angle)
    const dy = r * (1 - Math.cos(angle))
    
    const topScroll = (e,num)=>{
        if(e.currentTarget.dataset.id !== 'deficit'){
          let container =  document.getElementById(`table_row_${num}`);
        container.style.background='#f3f3f5'
        setTimeout(()=>{
          container.style.background='transparent'

        },1500)
        window.scrollTo({top:container?.getBoundingClientRect().y,behavior:'smooth'})
        }
    }
    const handleHover = (e)=>{
      let element = e.currentTarget;
      if(element.dataset.id !== 'deficit'){
        if(element.dataset.id === 'slice_con'){
          const svg = element.parentNode.parentNode;
          if(svg){
            let findAll = svg.querySelectorAll(`path[data-id = "slice_con"]`);
            if(findAll.length===2){
              findAll.forEach((slice)=>{
                slice.classList.add(classes.activeSlice);
              })
            }
          }
        }else{
          element.classList.add(classes.activeSlice);
        }
      }
    }
    const handleUnHover = (e)=>{
      let element = e.currentTarget;
      if(element.dataset.id !== 'deficit'){

      if(element.dataset.id === 'slice_con'){
        const svg = element.parentNode.parentNode;
        if(svg){
          let findAll = svg.querySelectorAll(`path[data-id = "slice_con"]`);
          if(findAll.length===2){
            findAll.forEach((slice)=>{
              slice.classList.remove(classes.activeSlice);
            })
          }
        }
      }else{
        element.classList.remove(classes.activeSlice);

      }
    }
    }
    return (
      <>
          <path
           onClick={(e)=>topScroll(e,props.num)}
           onMouseOver={handleHover}
           onMouseOut={handleUnHover}
           xlinkTitle='aaa'
           data-id={value?.type === 'parent' || value?.type === 'child' ?'slice_con':(value?.type === 'deficit' ?'deficit':'slice')}
          id='slice'
            {...rest}
            d={`M${r} ${r},V0,a${r} ${r} 0 0 1 ${Math.abs(dx)} ,${dy}z`}
          >
            <title>{value.name ? value.name:'Default Deficit'}</title>
          </path>
        
              
          <path
            style={{transform:props.line?.transformLine,transformOrigin:props.line?.transformOrigin}}
            stroke="none"
            strokeWidth={2}
            d={`M${r} ${r-145}V0`}
            id='line'
          />

      </>
    )
  }
  export default Slice;