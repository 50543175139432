import { CheckOutputStatus, CHECK_OUTPUT_STATUS, StrategyActionsTypes } from "../../../../types/typesStrategy";


const initialState: CheckOutputStatus = {
    status:false,
    showNotification:false
}

const reducer = (state = initialState, action: StrategyActionsTypes): CheckOutputStatus => {

    switch (action.type) {

        case CHECK_OUTPUT_STATUS:
            const {status,showNotification} = action.payload;
            
            return {
                status:status,
                showNotification:showNotification ?? state.showNotification
            }

        default:
            return state

    }
}

export default reducer