import { ADD_DECISION, DELETE_DECISION } from './../types/typesDecision';
import {
  DecisionActionsTypes,
  DECISIONCHECKBOX,
  DecisionCheckbox,
  ActiveDecision,
  ACTIVEDECISION,
  MatrixData,
  MATRIXDATA,
  MatrixDataStatus,
  MATRIXDATASTATUS,
  MatrixSpinner,
  MATRIXSPINNER,
  MatrixActiveAlias,
  MATRIXACTIVEALIAS,
  MatrixListStatus,
  MATRIXLISTSTATUS,
  MatrixActivePoint,
  MATRIXACTIVEPOINT,
  //E
  GETALLDECISIONRANGE,
  HANDLERANGEREFRESH,
  HANDLEDECISIONREFRESH,
  GETALLDECISIONS,
  HANLDERANGEACTIVEELEMENT,
  RangeActiveElement,
  RangeActiveTooltip,
  HANDLERANGEACTIVETOOLTIP,
  RangeActiveRow,
  HANDLERANGEACTIVEROW,
  //END E
} from "../types/typesDecision";


export  const handleDeleteDecicion = (id: number) => {
  return {
    type: DELETE_DECISION, 
    payload: id
  }
};

export const handleAddDecision = (decision: any) => {
  return {
    type: ADD_DECISION,
    payload: decision
  }
}

export const handleDecisionData = (decisionData: any): DecisionActionsTypes => {
  return {
    type: GETALLDECISIONS,
    payload: { decisionData },
  };
};

export function addCheckboxDecision(
  decisionCheckboxList: DecisionCheckbox
): DecisionActionsTypes {
  return {
    type: DECISIONCHECKBOX,
    payload: {
      decisionCheckboxList,
    },
  };
}

export function activeDecision(
  activeDecision: ActiveDecision
): DecisionActionsTypes {
  return {
    type: ACTIVEDECISION,
    payload: { activeDecision: activeDecision },
  };
}

export function matrixData(matrixData: MatrixData): DecisionActionsTypes {
  return {
    type: MATRIXDATA,
    payload: { matrixData: matrixData },
  };
}
export function matrixDataStatus(
  matrixDataStatus: MatrixDataStatus
): DecisionActionsTypes {
  return {
    type: MATRIXDATASTATUS,
    payload: { matrixDataStatus: matrixDataStatus },
  };
}
export function matrixSpinner(
  matrixSpinner: MatrixSpinner
): DecisionActionsTypes {
  return {
    type: MATRIXSPINNER,
    payload: { matrixSpinner: matrixSpinner },
  };
}

export function matrixActiveAlias(
  matrixActiveAlias: MatrixActiveAlias
): DecisionActionsTypes {
  return {
    type: MATRIXACTIVEALIAS,
    payload: { matrixActiveAlias: matrixActiveAlias },
  };
}

export function matrixListStatus(
  matrixListStatus: MatrixListStatus
): DecisionActionsTypes {
  return {
    type: MATRIXLISTSTATUS,
    payload: { matrixListStatus: matrixListStatus },
  };
}

export function matrixActivePoint(
  matrixActivePoint: MatrixActivePoint
): DecisionActionsTypes {
  return {
    type: MATRIXACTIVEPOINT,
    payload: { matrixActivePoint: matrixActivePoint },
  };
}

//E





export const getAllDecisionRange = (
  fetchStatus: string,
  rangeData: any
): DecisionActionsTypes => {
  return {
    type: GETALLDECISIONRANGE,
    payload: { fetchStatus, rangeData },
  };
};

export const handleDecisionReload = (
  decisionReload: boolean
): DecisionActionsTypes => {
  return {
    type: HANDLEDECISIONREFRESH,
    payload: { decisionReload },
  };
};

export const handleRangeReload = (
  rangeReload: boolean
): DecisionActionsTypes => {
  return {
    type: HANDLERANGEREFRESH,
    payload: { rangeReload },
  };
};

export const handleRangeActiveElement = (
  activeElementsProps: RangeActiveElement
): DecisionActionsTypes => {
  return {
    type: HANLDERANGEACTIVEELEMENT,
    payload: { activeElementsProps },
  };
};

export const handleRangeActiveTooltip = (
  tooltipProps: RangeActiveTooltip
): DecisionActionsTypes => {
  return {
    type: HANDLERANGEACTIVETOOLTIP,
    payload: { tooltipProps },
  };
};

export const handleRangeActiveRow = (
  activeRowProps: RangeActiveRow
): DecisionActionsTypes => {
  return {
    type: HANDLERANGEACTIVEROW,
    payload: { activeRowProps },
  };
};
//END E
