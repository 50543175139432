import { downArrowBox, moveArrowBox } from "./arrow_box.handler";
import {find_contact } from "./find_contact.handler";
import { zIndex } from "./create_svg.handler";
import { draw_arrow } from "./draw_arrow.handler";
import { clickHandler } from "./click.handler";
import { change_destination } from "./change_destination_arrow/main";
import { BoardStorage } from "./storage.helper";
import { EActionTypes } from "../../../../../settings/settings";
import { splitBoard } from "helpers/split_board";

function mousePosition(e:MouseEvent){
    let svg:any = document.querySelector('#svg_board') as SVGElement;

    let CTM = svg.getScreenCTM()
    return {
        x: (e.clientX - CTM.e) / CTM.a,
        y: (e.clientY - CTM.f) / CTM.d
    };
}

function circlesIntersect(c1:any, c2:any) {
    const dx = c1.x - c2.x;
    const dy = c1.y - c2.y;
    const d = Math.sqrt(dx * dx + dy * dy);
    return d <= c1.r + c2.r;
}

export const listener = (element:SVGRectElement)=>{
    let svg:any = document.querySelector('#svg_board') as SVGElement;
    function side_replace(side1:string,side2:string){
        let status:boolean = false;

        if(side1 === 'right' && side2 === 'left'){
            status = true;
        }
        if(side2 === 'right' && side1 === 'left'){
            status = true;
        }
        if(side1 === 'top' && side2 === 'bottom'){
            status = true;
        }
        if(side2 === 'top' && side1 === 'bottom'){
            status = true;
        }
        return status;
    }



    const mouseDown = (e:MouseEvent)=>{
        e.preventDefault(); 
        e.stopPropagation();
        // let {x,y} = element?.getBoundingClientRect();
        let x = element.getAttribute('x');
        let y = element.getAttribute('y');

        let ofst = mousePosition(e);
        ofst.x -=  parseFloat(`${x}`);
        ofst.y -=  parseFloat(`${y}`);
        if(element.dataset.type === 'condt'){
            element?.parentNode?.querySelector('#figure2')?.setAttributeNS(null,'stroke','#A668D8');
        }else{
            element.setAttributeNS(null,'stroke','#A668D8');
        }
        downArrowBox(element,'down');
        function moveHandler(move_event:MouseEvent){
                move_event.preventDefault();
                move_event.stopPropagation();
                let {width:svg_width,height:svg_height} = svg.getBoundingClientRect();
                let zoom_percent_span = document.querySelector('#zoom_percent') as HTMLInputElement
                let percent:any = zoom_percent_span?.value?.split('%')?.[0];
                let link:any = element?.getAttribute('data-id');
                zIndex(link)
                element.style.cursor = 'grab';
                let coord = mousePosition(move_event);
                let _x:any = coord.x - ofst.x;
                let _y:any = coord.y - ofst.y
                const data={
                    svg_width,svg_height,percent,x:_x,y:_y,id:element.dataset.id
                } as any
                splitBoard("move",data)

                const width:any  = element.getAttribute('width');
                const height:any  = element.getAttribute('height');

                // if((_x-35>0 && _y-35 >0) && (_x+35< svg_width/+percent*100 - +width && _y+35 < svg_height/+percent*100 - +height)){

                            element?.setAttributeNS(null,"x", _x.toFixed(2));
                            element?.setAttributeNS(null,"y", _y.toFixed(2));



                // }
                const parent = element.parentNode as SVGGElement;
                const text =parent.querySelector('#text') as SVGTextElement;
                // if((_x-35>0 && _y-35 >0) && (_x+35< svg_width/+percent*100 - +width && _y+35 < svg_height/+percent*100 - +height)){

                        text.setAttributeNS(null,'x',`${+_x.toFixed(2)+ +width/2}`)
                        text.setAttributeNS(null,'y',`${+_y.toFixed(2)+ +height/2}`)


                // }
                
                let title_text = parent.querySelector('#title') as SVGTextElement;
                // if((_x-35>0 && _y-35 >0) && (_x+35< svg_width/+percent*100 - +width && _y+35 < svg_height/+percent*100 - +height)){

                    let _xc = element.dataset.type === 'func' ? +_x - 8 : (element.dataset.type === 'scorecard' ? +_x - 18 : (element.dataset.type === 'decision_mapp' ? +_x - 10 : (element.dataset.type === 'datawizard' ? +_x - 12 : (element.dataset.type === 'product' ? +_x:0))));
                    let _yc = element.dataset.type === 'func' ? +_y - 15 : (element.dataset.type === 'scorecard' ? +_y - 11 : (element.dataset.type === 'decision_mapp' ? +_y - 12 : (element.dataset.type === 'datawizard' ? +_y - 15 : (element.dataset.type === 'product'? +_y-11:0))));
                        title_text?.setAttributeNS(null, 'x', `${_xc}`)
                        title_text?.setAttributeNS(null, 'y', `${_yc}`)

                // }

                let textchilds = text.querySelectorAll('tspan');
                if(textchilds.length>0){
                    textchilds?.forEach((tchild:any)=>{
                        // if((_x-35>0 && _y-35 >0) && (_x+35< svg_width/+percent*100 - +width && _y+35 < svg_height/+percent*100 - +height)){

                                tchild?.setAttributeNS(null,'x',`${+_x.toFixed(2)+ +width/2}`)
                                tchild?.setAttributeNS(null,'y',`${+_y.toFixed(2)+ +height/2}`)


                    //    }
                    }) 
                }
                // if((_x-35>0 && _y-35 >0) && (_x+35< svg_width/+percent*100 - +width && _y+35 < svg_height/+percent*100 - +height)){

                    moveArrowBox(element,{x:_x.toFixed(2),y:_y.toFixed(2)});

                    draw_arrow(element,{x:_x,y:_y})

                // }


            
                if(element.getAttribute('data-type') === 'condt'){
                   let figure2 = element.parentNode?.children[1] as SVGRectElement;
                   let figure2_x:any = +(Math.sqrt((70*70)/2) - 70/2).toFixed(2);
                //    if((_x-35>0 && _y-35 >0) && (_x+35< svg_width/+percent*100 - +width && _y+35 < svg_height/+percent*100 - +height)){

                           figure2.setAttributeNS(null,'x',_x -4 + figure2_x);
                           figure2.setAttributeNS(null,'y',_y - 4 + figure2_x);
                           figure2.setAttributeNS(null,'transform',`rotate(45 ${_x + 35} ${_y + 35})`)

                //    }
                }
                change_destination<SVGRectElement>(element)

                let other_elements = svg.querySelectorAll(`g[data-type = "figure"]`) as NodeList;
                
                
                other_elements.forEach((_element:any)=>{
                
                              
                           let data_id:string = _element.getAttribute('data-id');
                           let arrow_box_groups = _element.querySelector(`g[id = "arrow_box_group"]`);
                           let arrow_box_group = arrow_box_groups?.querySelectorAll('g') as NodeList;
                           let selected_element_id  = element.dataset.id;
                           let selected_arrow_box_groups = element.parentNode?.querySelector(`g[id = "arrow_box_group"]`);
                           let selected_arrow_box_group = selected_arrow_box_groups?.querySelectorAll('g') as NodeList;
                
                           arrow_box_group?.forEach((arrow_box:any)=>{

                                let circle = arrow_box.firstChild as SVGRectElement;
                                let data_side:any = circle.dataset.side;
                                let circle_x = circle.x;
                                let circler_y = circle.y;
                                let circle_width = circle.width;
                                let circle_height = circle.height;

                                let element_obj = {
                                    id:data_id,
                                    box:{
                                        side:data_side,
                                        element:circle,
                                        x:circle_x.baseVal.value,
                                        y:circler_y.baseVal.value,
                                        width:circle_width.baseVal.value,
                                        height:circle_height.baseVal.value
                                    }

                                }
                                if(_element !== element.parentNode){
                                    // let selected_circle_top = selected_arrow_box_group[0].firstChild;
                                    // let selected_circle_right = selected_arrow_box_group[1].firstChild;
                                    // let selected_circle_bottom = selected_arrow_box_group[2].firstChild;
                                    // let selected_circle_left = selected_arrow_box_group[3].firstChild;

                                    selected_arrow_box_group?.forEach((selected_arrow_box)=>{
                                        let selected_circle = selected_arrow_box.firstChild as SVGRectElement;
                                        let selected_data_side:any = selected_circle.dataset.side;
                                        let selected_circle_x = selected_circle.x;
                                        let selected_circler_y = selected_circle.y;
                                        let selected_circle_width = selected_circle.width;
                                        let selected_circle_height = selected_circle.height;
                                        let selected_circle_status = selected_circle.dataset.status;

                                        let selected_element_obj = {
                                            id:selected_element_id,
                                            box:{
                                                side:selected_data_side,
                                                element:selected_circle,
                                                x:selected_circle_x.baseVal.value,
                                                y:selected_circler_y.baseVal.value,
                                                width:selected_circle_width.baseVal.value,
                                                height:selected_circle_height.baseVal.value,
                                                display:selected_circle_status
                                             
                                            }
        
                                        }
                                        if(selected_element_obj.box.display !== 'false'){
                                            if(side_replace(selected_element_obj.box.side,element_obj.box.side)){

                                                let result:boolean =circlesIntersect({x:selected_element_obj.box.x,y:selected_element_obj.box.y,r:16},{x:element_obj.box.x,y:element_obj.box.y,r:16})
                                                if(result){
                                                    element_obj.box.element.setAttributeNS(null,'stroke','#A668D8');
                                                    element_obj.box.element.setAttributeNS(null,'data-target',`${selected_element_id}`);

                                                
                                                }else{
                                                    element_obj.box.element.setAttributeNS(null,'stroke','none')                                                
                                                    element_obj.box.element.removeAttributeNS(null,'data-target');

                                                }


                                            }
                                        }

                                    })

                                    
                                }
                                
                           })

                        


                })
                


        }
        function upHandler(ev:any){
            ev.preventDefault();
            ev.stopPropagation();
            document.removeEventListener('mousemove',moveHandler,false);
            element.style.cursor = 'pointer';
            if(element.dataset.type === 'condt'){
                element?.parentNode?.querySelector('#figure2')?.setAttributeNS(null,'stroke','none');
            }else if(element.dataset.type === 'code_message'){
                element?.setAttributeNS(null,'stroke','#70AC81');

            }else{
                element?.setAttributeNS(null,'stroke','none');
            }
            downArrowBox(element,'up');
            find_contact();
            let group = element?.parentNode as SVGGElement;
            // add_storage(group);
            BoardStorage(group,EActionTypes.UP)

            let use = svg.querySelector('use') as SVGUseElement;
            if(use){
                svg.removeChild(use)
            }
            document.removeEventListener('mouseup',upHandler,false);

        }
        function leaveHandler(el:any){
            el.preventDefault();
            el.stopPropagation();

            document.removeEventListener('mousemove',moveHandler,false);

            element.style.cursor = 'initial';

        }
        document.addEventListener('mousemove',moveHandler);

        document.addEventListener('mouseup',upHandler);

        document.addEventListener('mouseleave',leaveHandler);
     
        document.removeEventListener('mousedown',mouseDown,false);


    }
    element?.addEventListener('mousedown',mouseDown);


    clickHandler(element);




    return {element}
} 



export const text_listener = (txt:SVGTextElement)=>{
    let element = txt.parentNode?.querySelector('#figure') as SVGRectElement;
    const group = element?.parentNode as SVGGElement;

    let svg:any = document.querySelector('#svg_board') as SVGElement;
    function side_replace(side1:string,side2:string){
        let status:boolean = false;

        if(side1 === 'right' && side2 === 'left'){
            status = true;
        }
        if(side2 === 'right' && side1 === 'left'){
            status = true;
        }
        if(side1 === 'top' && side2 === 'bottom'){
            status = true;
        }
        if(side2 === 'top' && side1 === 'bottom'){
            status = true;
        }
        return status;
    }



    const mouseDown = (e:MouseEvent)=>{

        e.preventDefault(); 
        e.stopPropagation();
        // let {x,y} = element?.getBoundingClientRect();
        let x = element.getAttribute('x');
        let y = element.getAttribute('y');

        let ofst = mousePosition(e);
        ofst.x -=  parseFloat(`${x}`);
        ofst.y -=  parseFloat(`${y}`);
        if(element.dataset.type === 'condt'){
            element?.parentNode?.querySelector('#figure2')?.setAttributeNS(null,'stroke','#A668D8');
        }else{
            element.setAttributeNS(null,'stroke','#A668D8');
        }
        downArrowBox(element,'down');
        function moveHandler(move_event:MouseEvent){
                move_event.preventDefault();
                move_event.stopPropagation();


                // let {width:svg_width,height:svg_height} = svg.getBoundingClientRect();
                // let zoom_percent_span = document.querySelector('#zoom_percent') as HTMLSpanElement
                // let percent:any = zoom_percent_span?.textContent?.split('%')?.[0];



                let link:any = element?.getAttribute('data-id');
                zIndex(link)
                element.style.cursor = 'grab';
                let coord = mousePosition(move_event);
                let _x:any = coord.x - ofst.x;
                let _y:any = coord.y - ofst.y
               
                const width:any  = element.getAttribute('width');
                const height:any  = element.getAttribute('height');

                let tooltip = group.querySelector('#fo_label_tooltip') as SVGForeignObjectElement;
            
                if(tooltip){
                    // tooltip.setAttributeNS(null,'x',`${_x}`)
                    // tooltip.setAttributeNS(null,'y',`${_y+height/2+1}`)
                    group.removeChild(tooltip);
                }

                // if((_x-35>0 && _y-35 >0) && (_x+35< svg_width/+percent*100 - +width && _y+35 < svg_height/+percent*100 - +height)){

                            element?.setAttributeNS(null,"x", _x.toFixed(2));
                            element?.setAttributeNS(null,"y", _y.toFixed(2));



                // }
                const parent = element.parentNode as SVGGElement;
                const text =parent.querySelector('#text') as SVGTextElement;
                // if((_x-35>0 && _y-35 >0) && (_x+35< svg_width/+percent*100 - +width && _y+35 < svg_height/+percent*100 - +height)){

                        text.setAttributeNS(null,'x',`${+_x.toFixed(2)+ +width/2}`)
                        text.setAttributeNS(null,'y',`${+_y.toFixed(2)+ +height/2}`)


                // }
                
                let title_text = parent.querySelector('#title') as SVGTextElement;
                // if((_x-35>0 && _y-35 >0) && (_x+35< svg_width/+percent*100 - +width && _y+35 < svg_height/+percent*100 - +height)){

                        let _xc = element.dataset.type === 'func' ? +_x - 8 : (element.dataset.type === 'scorecard' ? +_x - 18 : (element.dataset.type === 'decision_mapp' ? +_x - 10 : (element.dataset.type === 'datawizard' ? +_x - 12 : (element.dataset.type === 'product' ? +_x:0))));
                        let _yc = element.dataset.type === 'func' ? +_y - 15 : (element.dataset.type === 'scorecard' ? +_y - 11 : (element.dataset.type === 'decision_mapp' ? +_y - 12 : (element.dataset.type === 'datawizard' ? +_y - 15 : (element.dataset.type === 'product'? +_y-11:0))));
                        title_text?.setAttributeNS(null, 'x', `${_xc}`)
                        title_text?.setAttributeNS(null, 'y', `${_yc}`)

                // }

                let textchilds = text.querySelectorAll('tspan');
                if(textchilds.length>0){
                    textchilds?.forEach((tchild:any)=>{
                        // if((_x-35>0 && _y-35 >0) && (_x+35< svg_width/+percent*100 - +width && _y+35 < svg_height/+percent*100 - +height)){

                                tchild?.setAttributeNS(null,'x',`${+_x.toFixed(2)+ +width/2}`)
                                tchild?.setAttributeNS(null,'y',`${+_y.toFixed(2)+ +height/2}`)


                    //    }
                    }) 
                }
                // if((_x-35>0 && _y-35 >0) && (_x+35< svg_width/+percent*100 - +width && _y+35 < svg_height/+percent*100 - +height)){

                    moveArrowBox(element,{x:_x.toFixed(2),y:_y.toFixed(2)});

                    draw_arrow(element,{x:_x,y:_y})

                // }


            
                if(element.getAttribute('data-type') === 'condt'){
                   let figure2 = element.parentNode?.children[1] as SVGRectElement;
                   let figure2_x:any = +(Math.sqrt((70*70)/2) - 70/2).toFixed(2);
                //    if((_x-35>0 && _y-35 >0) && (_x+35< svg_width/+percent*100 - +width && _y+35 < svg_height/+percent*100 - +height)){

                           figure2.setAttributeNS(null,'x',_x -4 + figure2_x);
                           figure2.setAttributeNS(null,'y',_y - 4 + figure2_x);
                           figure2.setAttributeNS(null,'transform',`rotate(45 ${_x + 35} ${_y + 35})`)

                //    }
                }


                let other_elements = svg.querySelectorAll(`g[data-type = "figure"]`) as NodeList;
                
                
                other_elements.forEach((_element:any)=>{
                
                              
                           let data_id:string = _element.getAttribute('data-id');
                           let arrow_box_groups = _element.querySelector(`g[id = "arrow_box_group"]`);
                           let arrow_box_group = arrow_box_groups?.querySelectorAll('g') as NodeList;
                           let selected_element_id  = element.dataset.id;
                           let selected_arrow_box_groups = element.parentNode?.querySelector(`g[id = "arrow_box_group"]`);
                           let selected_arrow_box_group = selected_arrow_box_groups?.querySelectorAll('g') as NodeList;
                
                           arrow_box_group?.forEach((arrow_box:any)=>{

                                let circle = arrow_box.firstChild as SVGRectElement;
                                let data_side:any = circle.dataset.side;
                                let circle_x = circle.x;
                                let circler_y = circle.y;
                                let circle_width = circle.width;
                                let circle_height = circle.height;

                                let element_obj = {
                                    id:data_id,
                                    box:{
                                        side:data_side,
                                        element:circle,
                                        x:circle_x.baseVal.value,
                                        y:circler_y.baseVal.value,
                                        width:circle_width.baseVal.value,
                                        height:circle_height.baseVal.value
                                    }

                                }
                                if(_element !== element.parentNode){
                                    // let selected_circle_top = selected_arrow_box_group[0].firstChild;
                                    // let selected_circle_right = selected_arrow_box_group[1].firstChild;
                                    // let selected_circle_bottom = selected_arrow_box_group[2].firstChild;
                                    // let selected_circle_left = selected_arrow_box_group[3].firstChild;

                                    selected_arrow_box_group?.forEach((selected_arrow_box)=>{
                                        let selected_circle = selected_arrow_box.firstChild as SVGRectElement;
                                        let selected_data_side:any = selected_circle.dataset.side;
                                        let selected_circle_x = selected_circle.x;
                                        let selected_circler_y = selected_circle.y;
                                        let selected_circle_width = selected_circle.width;
                                        let selected_circle_height = selected_circle.height;
                                        let selected_circle_status = selected_circle.dataset.status;

                                        let selected_element_obj = {
                                            id:selected_element_id,
                                            box:{
                                                side:selected_data_side,
                                                element:selected_circle,
                                                x:selected_circle_x.baseVal.value,
                                                y:selected_circler_y.baseVal.value,
                                                width:selected_circle_width.baseVal.value,
                                                height:selected_circle_height.baseVal.value,
                                                display:selected_circle_status
                                             
                                            }
        
                                        }
                                        if(selected_element_obj.box.display !== 'false'){
                                            if(side_replace(selected_element_obj.box.side,element_obj.box.side)){

                                                let result:boolean =circlesIntersect({x:selected_element_obj.box.x,y:selected_element_obj.box.y,r:16},{x:element_obj.box.x,y:element_obj.box.y,r:16})
                                                if(result){
                                                    element_obj.box.element.setAttributeNS(null,'stroke','#A668D8');
                                                    element_obj.box.element.setAttributeNS(null,'data-target',`${selected_element_id}`);

                                                
                                                }else{
                                                    element_obj.box.element.setAttributeNS(null,'stroke','none')                                                
                                                    element_obj.box.element.removeAttributeNS(null,'data-target');

                                                }


                                            }
                                        }

                                    })

                                    
                                }
                                
                           })   
                })
        }
        function upHandler(ev:any){
            ev.preventDefault();
            ev.stopPropagation();
            document.removeEventListener('mousemove',moveHandler,false);
            element.style.cursor = 'pointer';
            if(element.dataset.type === 'condt'){
                element?.parentNode?.querySelector('#figure2')?.setAttributeNS(null,'stroke','none');
            }else{
                element.setAttributeNS(null,'stroke','none');
            }
            downArrowBox(element,'up');
            find_contact();
            let group = element?.parentNode as SVGGElement;
            // add_storage(group);
            BoardStorage(group,EActionTypes.UP)
            let use = svg.querySelector('use') as SVGUseElement;
            if(use){
                svg.removeChild(use)
            }
            document.removeEventListener('mouseup',upHandler,false);

        }
        function leaveHandler(el:any){
            el.preventDefault();
            el.stopPropagation();

            document.removeEventListener('mousemove',moveHandler,false);

            element.style.cursor = 'initial';

        }
        document.addEventListener('mousemove',moveHandler);

        document.addEventListener('mouseup',upHandler);

        document.addEventListener('mouseleave',leaveHandler);
     
        document.removeEventListener('mousedown',mouseDown,false);


    }
    txt?.addEventListener('mousedown',mouseDown);


    
} 

