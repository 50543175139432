import {
  STRATEGY_ADD_SELECTED,
  STRATEGY_ADD_ALL_SELECTED,
  STRATEGY_REMOVE_ALL_SELECTED,
  STRATEGY_REMOVE_SELECTED,
  StrategyActionsTypes,
  StrategyTableSelectedList,
} from "../../../types/typesStrategy";

const initialState: StrategyTableSelectedList = {
  data: [],
};

const StrategyTableSelectedListReducer = (
  state: StrategyTableSelectedList = initialState,
  action: StrategyActionsTypes
): StrategyTableSelectedList => {
  switch (action.type) {
    case STRATEGY_ADD_SELECTED: {
      return {
        data: [...state.data, action.payload],
      };
    }
    case STRATEGY_ADD_ALL_SELECTED: {
      return action.payload;
    }
    case STRATEGY_REMOVE_SELECTED: {
      return {
        data: state.data.filter(({ id }) => id !== action.payload.id),
      };
    }
    case STRATEGY_REMOVE_ALL_SELECTED: {
      return {
        data: [],
      };
    }
    default: {
      return state;
    }
  }
};

export default StrategyTableSelectedListReducer;
