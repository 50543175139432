import { getAttr } from "../../methods/attributes";
import { Coord, Massive } from "../types";
import { arrow_bottom } from "./bottom/bottom";
import { arrow_bottom_left } from "./bottom/bottome_left";
import { arrow_left } from "./left/left";
import { arrow_left_bottom } from "./left/left_bottom";
import { arrow_left_top } from "./left/left_top";
import { positions } from "./positions";
import { arrow_right } from "./right/right";
import { arrow_right_bottom } from "./right/right_bottom";
import { arrow_right_bottom_left } from "./right/right_bottom_left";
import { arrow_right_top } from "./right/right_top";
import { arrow_right_top_left } from "./right/right_top_left";
import { arrow_top } from "./top/top";
import {arrow_left_bottom_right} from './left/left_bottom_right';
import { arrow_left_top_right } from "./left/left_top_right";
import { arrow_bottom_right } from "./bottom/bottom_right";
import { arrow_top_right } from "./top/top_right";
import { arrow_top_left } from "./top/top_left";

export const nearby_side = (arrow: SVGPathElement, arrow_side: any, coord: Coord, first: Massive, last: Massive) => {
    const box_margin: number = 90;
    const { x: first_x, y: first_y, width: first_width} = getAttr(first?.element);
    const { x: last_x, y: last_y, width: last_width } = getAttr(last?.element);

    const {
        first: {
            top: first_top,
            right: first_right,
            bottom: first_bottom,
            left: first_left
        },
        last: {
            top: last_top,
            right: last_right,
            bottom: last_bottom,
            left: last_left
        }
    } = positions(first, last);

    if(first_x && first_y && first_width && last_x && last_y && last_width){
        if(first_y <= last_y){
            if(first_x <= last_x){
                if((first_x+first_width) <= last_x){
                    if(last_x - (first_x + first_width) >= box_margin){
    
                        if(first_right && last_left){
                            arrow_right<Massive>(first,last,arrow);
                        }else if((!first_right || !last_left) && first_bottom && last_top){
                            arrow_bottom<Massive>(first,last,arrow);
    
                        }else if(!last_left && first_right && last_top){
                            arrow_right_bottom<Massive>(first,last,arrow);
                        }else if(!first_right && first_bottom && last_left){
                            arrow_bottom_right<Massive>(first,last,arrow)
                        }
    
                    }else{
                        if(first_right && last_top){
                            arrow_right_bottom<Massive>(first,last,arrow);
                        }else if(!last_top && first_bottom && last_left){
                            // arrow_bottom_right<Massive>(first,last,arrow);
                        }else if(!first_bottom  && first_right && last_right){
                            arrow_right_bottom_left<Massive>(first,last,arrow)
                        }else if(first_bottom && last_top){
                            arrow_bottom<Massive>(first,last,arrow);
                        }
                    }
                }else if((first_x+first_width) > last_x){
                    if((!last_top || !first_bottom ) && first_right && last_right){
                        arrow_right_bottom_left<Massive>(first,last,arrow)
                    }else if(first_bottom && last_top){
                        arrow_bottom<Massive>(first,last,arrow);
                    }
                }
                
            }
            if(first_x > last_x){
                if((last_x+last_width) <= first_x){
                    if(first_x - (last_x + last_width) >= box_margin){
    
                        if(first_left && last_right){
                            arrow_left<Massive>(first,last,arrow);
                        }else if(!last_right && first_left && last_top){
                            arrow_left_bottom<Massive>(first,last,arrow);
                        }else if(!first_left && first_bottom && last_right){
                            arrow_bottom_left<Massive>(first,last,arrow)
                        }
    
                    }else{
                        if(first_left && last_top){
                            arrow_left_bottom<Massive>(first,last,arrow);
                        }else if(!last_top && first_bottom && last_right){
                            arrow_bottom_left<Massive>(first,last,arrow);
                        }else if(!first_bottom  && first_left && last_left){
                            arrow_left_bottom_right<Massive>(first,last,arrow)
                        }else if(first_bottom && last_top){
                            arrow_bottom<Massive>(first,last,arrow);
                        }
                    }
                }else if((last_x+last_width) > first_x){
                    if((!last_top || !first_bottom ) && first_left && last_left){
                        arrow_left_bottom_right<Massive>(first,last,arrow)
                    }else if(first_bottom && last_top){
                        arrow_bottom<Massive>(first,last,arrow);
                    }
                }
    
            }
        }else if(first_y > last_y){
            if(first_x <= last_x){
                if((first_x+first_width) <= last_x){
                    if(last_x - (first_x + first_width) >= box_margin){
    
                        if(first_right && last_left){
                            arrow_right<Massive>(first,last,arrow);
                        }else if(!last_left && first_right && last_bottom){
                            arrow_right_top<Massive>(first,last,arrow);
                        }else if(!first_right && first_top && last_left){
                            arrow_top_right<Massive>(first,last,arrow)
                        }else if(!first_right && !last_left && first_top  && last_bottom){
                            arrow_top<Massive>(first,last,arrow);
    
                        }
    
                    }else{
                        if(first_right && last_top){
                            arrow_right_top<Massive>(first,last,arrow);
                        }else if((!first_bottom || !last_top) && first_right && last_right){
                            arrow_right_top_left<Massive>(first,last,arrow)
                        }else if(first_top && last_bottom){
                            arrow_top<Massive>(first,last,arrow);
                        }
                    }
                }else if((first_x+first_width) > last_x){
                    if((!last_bottom || !first_top ) && first_right && last_right){
                        arrow_right_top_left<Massive>(first,last,arrow)
                    }else if((!last_bottom || !first_top ) && first_left && last_left){
                        arrow_left_top_right<Massive>(first,last,arrow)
                    }else if(first_top && last_bottom){
                        arrow_top<Massive>(first,last,arrow);
                    }
                }
                
            }
            if(first_x > last_x){
                if((last_x+last_width) <= first_x){
                    if(first_x - (last_x + last_width) >= box_margin){
    
                        if(first_left && last_right){
                            arrow_left<Massive>(first,last,arrow);
                        }else if(!last_right && first_left && last_bottom){
                            arrow_left_top<Massive>(first,last,arrow);
                        }else if(first_left && first_top && last_bottom){
                            arrow_top<Massive>(first,last,arrow);
    
                        }else if(!first_left && first_top && last_right){
                            arrow_top_left<Massive>(first,last,arrow)
                        }
    
                    }else{
                        if(!first_top && first_left && last_bottom){
                            arrow_left_top<Massive>(first,last,arrow);
                        }else if(first_top && last_bottom){
                            arrow_top<Massive>(first,last,arrow);
                        }else if(!last_bottom && first_top && last_right){
                            arrow_top_left<Massive>(first,last,arrow);
                        }else if((!first_top || !last_bottom) && first_left && last_left){
                            arrow_left_top_right<Massive>(first,last,arrow)
                        }else if(first_top && last_bottom){
                            arrow_top<Massive>(first,last,arrow);
                        }
                    }
                }else if((last_x+last_width) > first_x){
                    if((!last_bottom || !first_top ) && first_right && last_right){
                        arrow_right_top_left<Massive>(first,last,arrow)
                    }else if((!last_bottom || !first_top ) && first_left && last_left){
                        arrow_left_top_right<Massive>(first,last,arrow)
                    }else if(first_top && last_bottom){
                        arrow_top<Massive>(first,last,arrow);
                    }
                }
    
            }
        }
    }


}



