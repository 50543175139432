import {
    StrategyActionsTypes,
    StrategyData
} from "../../../types/typesStrategy";


const initialState: StrategyData = {
    data: []
}

const reducerStrategyData = (state = initialState, action: StrategyActionsTypes): StrategyData => {

    switch (action.type) {

        case "STRATEGY_DATA":
            const { data } = action.payload

            return {
                data
            };

        default:
            return state
    }
}

export default reducerStrategyData