import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { endpoints } from "../../../../api/endpoints";
import {
  EActionTypes,
  elementSize,
  ElementTypes,
  storage_key,
} from "../../../../settings/settings";
import {
  handlerStrategyBoardElements,
  handlerStrategyBoardPanel,
  handlerStrategyBoardType,
  handlerStrategyElementInput,
  handlerStrategyElementOutput,
  handlerStrategyElementOutput2,
  handleStrategyBoardSidePanelContent,
  handleStrategyBoardZoom,
  handleStrategyElementTooltipStatus,
  handleStrategyNewCreatedElementId,
  handleStrategySourceDataStatus,
  handleStrategyValidateStatus,
} from "../../../../store/actions/actionsStrategy";
import { RootState } from "../../../../store/combineReducer";
import { request } from "../../../../helpers/request";
import DB from "../../../DB/Storage";
import classes from "./Board.module.scss";
import RemovePanel from "./components/RemovePanel/RemovePanel";
import ToolsPanel from "./components/ToolsPanel/ToolsPanel";
import { add_arrow } from "./handlers/add_arrow.handler";
import { add_element } from "./handlers/add_element.handler";
import { add_storage } from "./handlers/add_storage.handler";
import { contact_chaining } from "./handlers/contact_chaining/contact_chaining.handler";
import { createElement, zIndex } from "./handlers/create_svg.handler";
import { createEndpoint } from "./handlers/endpoint/endpoint";
import {
  call_arrow_box_source,
  call_arrow_box_target,
  replace_side,
  replace_source_side,
} from "./handlers/remove_arrow_box.handler";
import { remove_arrow_db, remove_db } from "./handlers/remove_element.handler";
import { BoardStorage } from "./handlers/storage.helper";
import { getArrowPropery } from "./handlers/get_arrow_properties.handler";
import { removeElementFromOutput } from "helpers/removeElement";
import { splitBoard } from "helpers/split_board";

const Board = () => {
  const [toolsPanel, setToolsPanel] = useState<any>({ x: 0, y: 0, side: null });
  const [removePanel, setRemovePanel] = useState<any>({ x: 0, y: 0 });
  const [removeArrowPanel, setRemoveArrowPanel] = useState<any>({ x: 0, y: 0 });

  const [selected, setSelected] = useState<any>(null);
  const [clickStatus, setClickStatus] = useState<boolean>(false);

  const [activeSourceElement, setActiveSourceElement] = useState<any>(null);
  const dispatch = useDispatch();
  const {
    reducerStrategy: {
      StrategyBoardElements: {
        ElementType: { element_type },
        ElementPanel: { element_panel },
        ElementInput: { element_input },
        StrategyBoardData: { data: strategy_data },
        NewCreatedElement: { new_created_element },
        BoardElements: { board_elements },
        StrategyBoardValidateStatus: { status: validate_status },
        StrategyBoardWizardStatus: { status: wizard_status },
        StrategyElementTooltipStatus: { status: element_tooltip_status },
      },
      StrategyBoardSidePanel: {
        Content: { content_value },
      },
      StrategyBoardZoom: { minZoom, maxZoom, zoom },
      SourceDataAvailability,
    },
  } = useSelector((state: RootState) => state);

  const {
    strategy_designer: { strategy_source_data },
  } = endpoints;

  useEffect(() => {
    const svg = document.querySelector("#svg_board") as SVGElement;
    svg.addEventListener("click", closeActiveTool);
    function closeActiveTool(e: MouseEvent) {
      if (element_input && !new_created_element) {
        setRemovePanel({ x: 0, y: 0 });
        setRemoveArrowPanel({ x: 0, y: 0 });
        setToolsPanel({ x: 0, y: 0, side: null });
        dispatch(handlerStrategyBoardPanel({ element_panel: false }));
        dispatch(handleStrategyBoardSidePanelContent({ content_value: null }));
      }
    }

    return () => {
      svg.removeEventListener("click", closeActiveTool, false);
    };
  }, [dispatch, element_input, new_created_element]);


  const moveHandler = (element: any) => {
    const child = element.querySelector("#figure") as SVGRectElement;
 
    const down = (e: any) => {

      function move(e_move: any) {         
        setRemovePanel({ x: 0, y: 0 });
        setRemoveArrowPanel({ x: 0, y: 0 });
        setToolsPanel({ x: 0, y: 0, side: null });
      }
      
      function up(e_up: any) {
        e_up.preventDefault();
        e_up.stopPropagation();

        document.removeEventListener("mousemove", move, false);
        document.removeEventListener("mouseup", up, false);
      }
      function leave(e_leave: any) {
        e_leave.preventDefault();
        e_leave.stopPropagation();

        document.removeEventListener("mousemove", move, false);
      }
      document.addEventListener("mousemove", move);
      document.addEventListener("mouseup", up);
      document.addEventListener("mouseleave", leave);
      document.removeEventListener("mousedown", down, false);
    };

    child.addEventListener("mousedown", down);
  };

  const clickBox = useCallback((element: any) => {
    let box_group = element?.querySelector("#arrow_box_group") as SVGGElement;

    let circle = box_group?.querySelectorAll("#circle") as NodeList;
    circle?.forEach((item) => {
      item?.addEventListener("click", tools_panel);
    });
  }, []);

  const showRemoveTool = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.target.id === "arrow") {
        const figure = e.currentTarget;
        setSelected(figure?.dataset.contact);
        dispatch(handlerStrategyElementOutput({ element_output: null }));
        dispatch(handlerStrategyElementOutput2({ element_output2: null }));
        dispatch(handlerStrategyBoardPanel({ element_panel: false }));
        dispatch(handlerStrategyElementInput({ element_input: null }));
        if(figure.dataset.side?.split("-").length>1){
          

        }
        if (figure.dataset.side === "right" ||
         figure.dataset.side === "left" ||
          figure.dataset.side === "right-bottom-left" ||
           figure.dataset.side === "left-bottom-right" ||
            figure.dataset.side === "right-top-left" ||
             figure.dataset.side === "right-top-left") {
          let d: string = figure.getAttribute("d");
          let [m, h1, v] = d?.split(",");
          let mx = +m?.split(" ")?.[0]?.split("M")?.[1];
          let my = +m?.split(" ")?.[1];
          let h = +h1?.split("h")?.[1];

          let _split = v?.split("v");
          if (_split?.[1]?.split("-")?.length === 2) {
            let _v = Math.abs(+_split?.[1]?.split("-")?.[1]);
            let coord = { x: mx + h - 20.35, y: my - _v / 2 };
            setRemoveArrowPanel({ x: coord.x, y: coord.y });
            setToolsPanel({ x: 0, y: 0, side: null });
          } else {
            let _v = Math.abs(+_split?.[1]?.split("-")?.[0]);
            let coord = { x: 0, y: 0 };
            if (_v === 0) {
              coord.y = my + _v / 2 + 11;
              coord.x = mx + h - 20.35;
            } else {
              coord.y = my + _v / 2;
              coord.x = mx + h - 20.35;
            }
            setRemoveArrowPanel({ x: coord.x, y: coord.y });
            setRemovePanel({ x: 0, y: 0 });
            setToolsPanel({ x: 0, y: 0, side: null });
          }
        }
        if (figure.dataset.side === "top" ||
         figure.dataset.side === "bottom" || 
         figure.dataset.side === "top-left-bottom" ||
          figure.dataset.side === "top-right-bottom" || 
          figure.dataset.side === "bottom-left-top" || 
          figure.dataset.side === "bottom-right-top" || 
          figure.dataset.side === "top-left" ||
           figure.dataset.side === "top-right" || 
           figure.dataset.side === "bottom-left"  || 
           figure.dataset.side === "bottom-right"  ) {
          let d: string = figure.getAttribute("d");
          let [m, v1, h] = d?.split(",");
          let mx = +m?.split(" ")?.[0]?.split("M")?.[1];
          let my = +m?.split(" ")?.[1];
          let v = +v1?.split("v")?.[1];

          let _split = h?.split("h");
          if (_split?.[1]?.split("-")?.length === 2) {
            let _h = Math.abs(+_split?.[1]?.split("-")?.[1]);
            let coord = { x: mx - _h / 2 - 20.35, y: my + v + 11 };
            setRemoveArrowPanel({ x: coord.x, y: coord.y });
            setToolsPanel({ x: 0, y: 0, side: null });
          } else {
            let _h = Math.abs(+_split?.[1]);

            let coord = { x: 0, y: 0 };
            if (_h === 0) {
              coord.y = my + v;
              coord.x = mx + _h / 2 - 20.35;
            } else {
              coord.y = my + v + 11;
              coord.x = mx + _h / 2 - 20.35;
            }
            setRemoveArrowPanel({ x: coord.x, y: coord.y });
            setRemovePanel({ x: 0, y: 0 });
            setToolsPanel({ x: 0, y: 0, side: null });
          }
        }
      } else {
        const figure = e.currentTarget.querySelector(
          "#figure"
        ) as SVGRectElement;

        setSelected(figure?.dataset.id);
        if (figure?.dataset.type === "code_message") {
          dispatch(handlerStrategyElementOutput({ element_output: null }));
          dispatch(handlerStrategyElementOutput2({ element_output2: null }));
          dispatch(handlerStrategyBoardPanel({ element_panel: false }));
          dispatch(
            handlerStrategyElementInput({ element_input: figure.dataset.id })
          );
          dispatch(
            handleStrategyBoardSidePanelContent({
              content_value: "code_message",
            })
          );
        } else {
          dispatch(handlerStrategyElementOutput({ element_output: null }));
          dispatch(handlerStrategyElementOutput2({ element_output2: null }));
          dispatch(handlerStrategyBoardPanel({ element_panel: false }));
          dispatch(
            handlerStrategyElementInput({ element_input: figure.dataset.id })
          );

          dispatch(
            handleStrategyBoardSidePanelContent({
              content_value: `${figure.dataset.id?.split("_")[0]}`,
            })
          );
        }
        const {
          x: {
            baseVal: { value: x },
          },
          y: {
            baseVal: { value: y },
          },
          width: {
            baseVal: { value: width },
          },
          height: {
            baseVal: { value: height },
          },
        } = figure;
        setRemovePanel({ x: x + width / 2 - 20.35, y: y + height + 10 });
        setRemoveArrowPanel({ x: 0, y: 0 });
        setToolsPanel({ x: 0, y: 0, side: null });
      }
    },
    [dispatch]
  );
  const clickDouble = useCallback(
    (element: any, type: string = "") => {

      element.addEventListener("dblclick", (e: any) => {
        setClickStatus(true);

        let decision = element.querySelector(
          `rect[data-type = "decision_mapp"]`
        ) as SVGRectElement;
        if (decision) {
          dispatch(handleStrategyElementTooltipStatus({ status: "edit" }));
          dispatch(
            handleStrategyBoardSidePanelContent({ content_value: type })
          );
        } else if (element.dataset.id === "validate") {
          dispatch(
            handleStrategyBoardSidePanelContent({
              content_value: "input_data_create_update",
            })
          );
          setToolsPanel({ x: 0, y: 0, side: null });
        } else if (element.dataset.id === "datawizard") {
          setToolsPanel({ x: 0, y: 0, side: null });
          dispatch(
            handleStrategyBoardSidePanelContent({
              content_value: "data_wizard",
            })
          );
          dispatch(
            handlerStrategyElementInput({ element_input: "datawizard" })
          );
        } else {
          showRemoveTool(e);
        }
      });
    },
    [dispatch, showRemoveTool]
  );

  const clickSingle = useCallback(
    (element: any) => {
      element.addEventListener("click", (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setClickStatus(true);
        let elem = element.querySelector("#figure") as SVGRectElement;

        const svg = document.querySelector("#svg_board") as SVGElement;
        const figure = elem;
        setSelected(figure.dataset.id);
        let output1: any = null;
        let output2: any = null;

        let find_arrows = svg.querySelectorAll(
          `path[data-contact ^= "${figure.dataset.id}-"]`
        ) as NodeList;
        if (find_arrows.length > 0) {
          find_arrows?.forEach((arrow: any) => {
            let data_bool: string = arrow.dataset.bool;
            if (data_bool) {
              if (data_bool === "yes") {
                let output_id: string = arrow.dataset.contact?.split("-")?.[1];
                output1 = output_id;
              }
              if (data_bool === "no") {
                let output_id: string = arrow.dataset.contact?.split("-")?.[1];
                output2 = output_id;
              }
            } else {
              let output_id: string = arrow.dataset.contact?.split("-")?.[1];
              output1 = output_id;
            }
          });        
        }
        dispatch(handlerStrategyElementOutput({ element_output: output1 }));
        dispatch(handlerStrategyElementOutput2({ element_output2: output2 }));
        dispatch(handlerStrategyBoardPanel({ element_panel: true }));
        dispatch(
          handlerStrategyElementInput({ element_input: figure.dataset.id })
        );

        const {
          x: {
            baseVal: { value: x },
          },
          y: {
            baseVal: { value: y },
          },
          width: {
            baseVal: { value: width },
          },
          height: {
            baseVal: { value: height },
          },
        } = figure;

        setRemovePanel({ x: x + width / 2 - 20.35, y: y + height + 10 });
        setRemoveArrowPanel({ x: 0, y: 0 });

        setToolsPanel({ x: 0, y: 0, side: null });
      });
    },
    [dispatch]
  );

  const callStart = useCallback(() => {
    const { start } = elementSize;
    const svg = document.querySelector("#svg_board") as SVGElement;
    const db = new DB(storage_key);

    db.fetch({ type: "startpoint" }).then((res: any) => {
      if (res.status && res.data) {
        const { style } = res.data;
        const [left, top] = style?.split(",");
        const data = {
          ...start,
          x: left?.split(":")?.[1]?.split("px")?.[0],
          y: top?.split(":")?.[1]?.split("px")?.[0],
        };

        const start_element = createElement("startpoint", data);
        moveHandler(start_element);

        clickBox(start_element);
        const id = start_element.dataset.id;
        dispatch(handlerStrategyBoardType({ element_type: null }));
        dispatch(handlerStrategyBoardElements({ board_elements: id }));
        svg?.appendChild(start_element);
        splitBoard("start",null)

      }
    });
  }, [clickBox, dispatch]);

  const callEndpoint = useCallback(() => {
    const { end } = elementSize;
    const svg = document.querySelector("#svg_board") as SVGElement;
    const db = new DB(storage_key);
    db.fetch({ type: "endpoint" }).then((res: any) => {
      if (res.status && res.data) {
        if (res.data.data.zoom) {
          dispatch(
            handleStrategyBoardZoom({
              minZoom,
              maxZoom,
              zoom: res.data.data.zoom,
            })
          );
        }
        const { style } = res.data;
        const [left, top] = style?.split(",");
        const data = {
          ...end,
          x: +left?.split(":")?.[1]?.split("px")?.[0],
          y: +top?.split(":")?.[1]?.split("px")?.[0],
        };

        const end_element = createEndpoint(data);
        moveHandler(end_element);

        clickBox(end_element);
        const id = end_element.dataset.id;
        dispatch(handlerStrategyBoardType({ element_type: null }));
        dispatch(handlerStrategyBoardElements({ board_elements: id }));
        svg?.appendChild(end_element);
        splitBoard("start",null)

        const output_format = end_element.querySelector(
          'rect[data-type = "output_format"]'
        ) as SVGRectElement;
        output_format.addEventListener("dblclick", () => {
          dispatch(
            handleStrategyBoardSidePanelContent({
              content_value: "output_format",
            })
          );
          dispatch(
            handlerStrategyElementInput({ element_input: "output_format" })
          );
        });
      }
    });
  }, [clickBox, dispatch, maxZoom, minZoom]);

  const callFunction = useCallback(() => {
    const { function: func } = elementSize;
    const svg = document.querySelector("#svg_board") as SVGElement;
    const db = new DB(storage_key);
    db.fetch({ type: "func" }).then((res: any) => {
      if (res.status && res.data) {
        if (Array.isArray(res.data)) {
          const functions = [...res.data];
          functions?.forEach((_function: any) => {
            const { sourceId, style } = _function;
            const [left, top] = style?.split(",");
            const data = {
              ...func,
              x: left?.split(":")?.[1]?.split("px")?.[0],
              y: top?.split(":")?.[1]?.split("px")?.[0],
              "data-id": sourceId,
            };
            const function_element = createElement("function", data, "current");

            clickBox(function_element);
            clickDouble(function_element);
            clickSingle(function_element);

            const id = function_element.dataset.id;
            dispatch(handlerStrategyBoardType({ element_type: null }));
            dispatch(handlerStrategyBoardElements({ board_elements: id }));
            moveHandler(function_element);

            svg?.appendChild(function_element);
            splitBoard("start",null)

          });
        } else {
          const { sourceId, style } = res.data;
          const [left, top] = style?.split(",");
          const data = {
            ...func,
            x: left?.split(":")?.[1]?.split("px")?.[0],
            y: top?.split(":")?.[1]?.split("px")?.[0],
            "data-id": sourceId,
          };
          const function_element = createElement("function", data, "current");

          clickBox(function_element);
          clickDouble(function_element);
          clickSingle(function_element);

          const id = function_element.dataset.id;
          dispatch(handlerStrategyBoardType({ element_type: null }));
          dispatch(handlerStrategyBoardElements({ board_elements: id }));
          moveHandler(function_element);

          svg?.appendChild(function_element);
          splitBoard("start",null)

        }
      }
    });
  }, [clickBox, clickDouble, clickSingle, dispatch]);
  const callProduct = useCallback(() => {
    const { product } = elementSize;
    const svg = document.querySelector("#svg_board") as SVGElement;
    const db = new DB(storage_key);
    db.fetch({ type: "product" }).then((res: any) => {
      if (res.status && res.data) {
        if (Array.isArray(res.data)) {
          const products = [...res.data];
          products?.forEach((_product: any) => {
            const { sourceId, style } = _product;
            const [left, top] = style?.split(",");
            const data = {
              ...product,
              x: left?.split(":")?.[1]?.split("px")?.[0],
              y: top?.split(":")?.[1]?.split("px")?.[0],
              "data-id": sourceId,
            };
            const product_element = createElement("product", data, "current");

            clickBox(product_element);
            clickDouble(product_element);
            clickSingle(product_element);

            const id = product_element.dataset.id;
            dispatch(handlerStrategyBoardType({ element_type: null }));
            dispatch(handlerStrategyBoardElements({ board_elements: id }));
            moveHandler(product_element);

            svg?.appendChild(product_element);
            splitBoard("start",null)

          });
        } else {
          const { sourceId, style } = res.data;
          const [left, top] = style?.split(",");
          const data = {
            ...product,
            x: left?.split(":")?.[1]?.split("px")?.[0],
            y: top?.split(":")?.[1]?.split("px")?.[0],
            "data-id": sourceId,
          };
          const product_element = createElement("product", data, "current");

          clickBox(product_element);
          clickDouble(product_element);
          clickSingle(product_element);

          const id = product_element.dataset.id;
          dispatch(handlerStrategyBoardType({ element_type: null }));
          dispatch(handlerStrategyBoardElements({ board_elements: id }));
          moveHandler(product_element);

          svg?.appendChild(product_element);
          splitBoard("start",null)

        }
      }
    });
  }, [clickBox, clickDouble, clickSingle, dispatch]);
  const callMapping = useCallback(() => {
    const { mapping: map } = elementSize;
    const svg = document.querySelector("#svg_board") as SVGElement;
    const db = new DB(storage_key);
    db.fetch({ type: "scorecard" }).then((res: any) => {
      if (res.status && res.data) {
        if (Array.isArray(res.data)) {
          const mapps = [...res.data];
          mapps?.forEach((_map: any) => {
            const { sourceId, style } = _map;
            const [left, top] = style?.split(",");
            const data = {
              ...map,
              x: left?.split(":")?.[1]?.split("px")?.[0],
              y: top?.split(":")?.[1]?.split("px")?.[0],
              "data-id": sourceId,
            };
            const map_element = createElement("scorecard", data, "current");

            clickBox(map_element);
            clickDouble(map_element);
            clickSingle(map_element);

            const id = map_element.dataset.id;
            dispatch(handlerStrategyBoardType({ element_type: null }));
            dispatch(handlerStrategyBoardElements({ board_elements: id }));
            moveHandler(map_element);

            svg?.appendChild(map_element);
            splitBoard("start",null)

          });
        } else {
          const { sourceId, style } = res.data;
          const [left, top] = style?.split(",");
          const data = {
            ...map,
            x: left?.split(":")?.[1]?.split("px")?.[0],
            y: top?.split(":")?.[1]?.split("px")?.[0],
            "data-id": sourceId,
          };
          const map_element = createElement("scorecard", data, "current");

          clickBox(map_element);
          clickDouble(map_element);
          clickSingle(map_element);

          const id = map_element.dataset.id;
          dispatch(handlerStrategyBoardType({ element_type: null }));
          dispatch(handlerStrategyBoardElements({ board_elements: id }));
          moveHandler(map_element);

          svg?.appendChild(map_element);
          splitBoard("start",null)

        }
      }
    });
  }, [clickBox, clickDouble, clickSingle, dispatch]);

  const callDecision = useCallback(() => {
    const { decision } = elementSize;
    const svg = document.querySelector("#svg_board") as SVGElement;
    const db = new DB(storage_key);
    db.fetch({ type: "decision" }).then((res: any) => {
      if (res.status && res.data) {
        if (Array.isArray(res.data)) {
          const decisions = [...res.data];
          decisions?.forEach((_decision: any) => {
            const {
              sourceId,
              style,
              data: { data_type },
            } = _decision;
            const [left, top] = style?.split(",");
            const data = {
              ...decision,
              x: left?.split(":")?.[1]?.split("px")?.[0],
              y: top?.split(":")?.[1]?.split("px")?.[0],
              "data-id": sourceId,
            };
            const decision_element = createElement(
              "decision",
              data,
              "current",
              data_type
            );

            clickBox(decision_element);
            clickDouble(decision_element, data_type);
            clickSingle(decision_element);

            const id = decision_element.dataset.id;
            dispatch(handlerStrategyBoardType({ element_type: null }));
            dispatch(handlerStrategyBoardElements({ board_elements: id }));
            moveHandler(decision_element);

            svg?.appendChild(decision_element);
            splitBoard("start",null)

          });
        } else {
          const {
            sourceId,
            style,
            data: { data_type },
          } = res.data;
          const [left, top] = style?.split(",");
          const data = {
            ...decision,
            x: left?.split(":")?.[1]?.split("px")?.[0],
            y: top?.split(":")?.[1]?.split("px")?.[0],
            "data-id": sourceId,
          };
          const decision_element = createElement(
            "decision",
            data,
            "current",
            data_type
          );

          clickBox(decision_element);
          clickDouble(decision_element, data_type);
          clickSingle(decision_element);

          const id = decision_element.dataset.id;
          dispatch(handlerStrategyBoardType({ element_type: null }));
          dispatch(handlerStrategyBoardElements({ board_elements: id }));
          moveHandler(decision_element);

          svg?.appendChild(decision_element);
          splitBoard("start",null)

        }
      }
    });
  }, [clickBox, clickDouble, clickSingle, dispatch]);
  const callCondition = useCallback(() => {
    const { condition: condt } = elementSize;
    const svg = document.querySelector("#svg_board") as SVGElement;
    const db = new DB(storage_key);
    db.fetch({ type: "condt" }).then((res: any) => {
      if (res.status && res.data) {
        if (Array.isArray(res.data)) {
          const filter = res.data?.filter((f: any) => f.branchLabel === "YES");

          filter?.forEach((res_data: any) => {
            const { sourceId, style } = res_data;
            const [left, top] = style?.split(",");
            const data = {
              ...condt,
              x: left?.split(":")?.[1]?.split("px")?.[0],
              y: top?.split(":")?.[1]?.split("px")?.[0],
              "data-id": sourceId,
            };
            const condition_element = createElement(
              "condition",
              data,
              "current"
            );
            const figure1 = condition_element.querySelector(
              "#figure"
            ) as SVGRectElement;
            let {
              x: {
                baseVal: { value: _x },
              },
              y: {
                baseVal: { value: _y },
              },
              width: {
                baseVal: { value: t_width },
              },
              height: {
                baseVal: { value: t_height },
              },
            } = figure1;

            const figure2 = condition_element.querySelector(
              "#figure2"
            ) as SVGRectElement;
            let cond_x: any = figure1.getAttribute("x");
            let cond_y: any = figure1.getAttribute("y");
            let figure2_x: any = +(Math.sqrt((70 * 70) / 2) - 70 / 2).toFixed(
              2
            );
            figure2.setAttributeNS(null, "x", +cond_x - 4 + figure2_x);
            figure2.setAttributeNS(null, "y", +cond_y - 4 + figure2_x);
            figure2.setAttributeNS(
              null,
              "transform",
              `rotate(45 ${_x + t_width / 2} ${_y + t_height / 2})`
            );

            // condition_element.setAttributeNS(null,'data-id',sourceId);
            // condition_element.setAttributeNS(null,'id',sourceId);
            clickBox(condition_element);
            clickDouble(condition_element);
            clickSingle(condition_element);
            moveHandler(condition_element);

            const id = condition_element.dataset.id;
            dispatch(handlerStrategyBoardType({ element_type: null }));
            dispatch(handlerStrategyBoardElements({ board_elements: id }));
            svg?.appendChild(condition_element);
            splitBoard("start",null)

          });
        }
      }
    });
  }, [clickBox, clickDouble, clickSingle, dispatch]);
  const callMessage = useCallback(() => {
    // const {code_message} = elementSize;
    const svg = document.querySelector("#svg_board") as SVGElement;
    const db = new DB(storage_key);

    db.fetch({ type: "code_message" }).then((res: any) => {
      if (res.status && res.data) {
        if (Array.isArray(res.data)) {
          let mass: Array<any> = [...res.data];
          mass?.forEach((message: any) => {
            const { style, sourceId } = message;
            const [left, top] = style?.split(",");
            const data = {
              ...elementSize.code_message,
              x: left?.split(":")?.[1]?.split("px")?.[0],
              y: top?.split(":")?.[1]?.split("px")?.[0],
              "data-id": sourceId,
            };

            const message_element = createElement("codemessage", data);
            clickBox(message_element);
            clickDouble(message_element);
            clickSingle(message_element);

            moveHandler(message_element);
            const id = message_element.dataset.id;
            dispatch(handlerStrategyBoardType({ element_type: null }));
            dispatch(handlerStrategyBoardElements({ board_elements: id }));
            svg?.appendChild(message_element);
            splitBoard("start",null)

          });
        } else {
          const { style, sourceId } = res.data;
          const [left, top] = style?.split(",");
          const data = {
            ...elementSize.code_message,
            x: left?.split(":")?.[1]?.split("px")?.[0],
            y: top?.split(":")?.[1]?.split("px")?.[0],
            "data-id": sourceId,
          };

          const message_element = createElement("codemessage", data);
          clickBox(message_element);
          clickDouble(message_element);
          moveHandler(message_element);
          clickSingle(message_element);

          const id = message_element.dataset.id;
          dispatch(handlerStrategyBoardType({ element_type: null }));
          dispatch(handlerStrategyBoardElements({ board_elements: id }));
          svg?.appendChild(message_element);
          splitBoard("start",null)

        }
      }
    });
  }, [clickBox, clickDouble, clickSingle, dispatch]);

  const callArrow = useCallback(() => {
    const db = new DB(storage_key);
    const svg = document.querySelector("#svg_board") as SVGElement;

    db.fetchAll().then((res: any) => {
      if (res.status && res.data) {
        const elements: Array<any> = [...res.data];
        elements?.forEach((element: any, i: number) => {
          // element?.targetId
          if (element?.sourceId) {
            const { targetId, sourceId, overlays, type, branchLabel } = element;
            let target_id = targetId ? targetId : "endpoint";
            add_arrow(target_id, sourceId, overlays, type, branchLabel);
            let arrow = svg.querySelector(
              `path[data-contact = "${sourceId}-${target_id}"]`
            );
            if (arrow) {
              clickDouble(arrow);
              arrow.addEventListener("click", (e) => {
                setRemovePanel({ x: 0, y: 0 });
                setRemoveArrowPanel({ x: 0, y: 0 });
                setToolsPanel({ x: 0, y: 0, side: null });
                dispatch(handlerStrategyBoardPanel({ element_panel: false }));
                dispatch(
                  handleStrategyBoardSidePanelContent({ content_value: null })
                );
              });
              arrow.addEventListener("mousedown", (e) => {
                setRemovePanel({ x: 0, y: 0 });
                setRemoveArrowPanel({ x: 0, y: 0 });
                setToolsPanel({ x: 0, y: 0, side: null });
                dispatch(handlerStrategyBoardPanel({ element_panel: false }));
                dispatch(
                  handleStrategyBoardSidePanelContent({ content_value: null })
                );
              });
            }
          }
        });
      }
    });
  }, [clickDouble, dispatch]);

  useEffect(() => {
    const svg = document.querySelector("#svg_board") as SVGElement;

    const check_db = localStorage.getItem(storage_key);
    const { start, end } = elementSize;
    if (check_db) {
      if (strategy_data && strategy_data.length > 0) {
        svg.innerHTML = "";
      }
      callStart();
      callEndpoint();
      callFunction();
      callProduct();
      callCondition();
      callMapping();
      callDecision();
      callMessage();
      callArrow();
    } else {
      if (strategy_data?.length === 0) {
        const start_data = {
          ...start,
          x: 200,
          y: 200,
        };
        const start_element = createElement("startpoint", start_data);
        clickBox(start_element);
        moveHandler(start_element);

        const end_data = {
          ...end,
          x: window.innerWidth - 350,
          y: window.innerHeight - 250,
        };
        const end_element = createEndpoint(end_data);

        moveHandler(end_element);
        clickBox(end_element);

        if (start_element) {
          svg?.appendChild(start_element);
        }
        if (end_element) {
          svg?.appendChild(end_element);
        }
        let overlays = [
          {
            side: "right",
            contact: "startpoint-endpoint",
            coord: `M${start_data.x + start_data.width + 12} ${
              start_data.y + start_data.height / 2
            },h${
              (end_data.x - 21 - (start_data.x + start_data.width + 12)) / 2
            },v${
              end_data.y +
              end_data.height / 2 -
              (start_data.y + start_data.height / 2)
            },h${
              (end_data.x - 21 - (start_data.x + start_data.width + 12)) / 2
            },l0 -5 ,l8 5 ,l-8 5 ,l0 -5`,
          },
        ];
        clickDouble(
          add_arrow("endpoint", "startpoint", overlays, "startpoint", "")
        );

        if (start_element) {
          add_storage(start_element);
        }
        if (end_element) {
          add_storage(end_element);
        }
        const output_format = svg.querySelector(
          'rect[data-type = "output_format"]'
        ) as SVGRectElement;
        const parent = output_format?.parentNode as SVGGElement;
        if (parent) {
          add_storage(parent);
        }

        if (output_format) {
          output_format.addEventListener("dblclick", () => {
            dispatch(
              handleStrategyBoardSidePanelContent({
                content_value: "output_format",
              })
            );
            dispatch(
              handlerStrategyElementInput({ element_input: "output_format" })
            );
          });
        }
        zIndex();
      }
    }

  }, [
    callArrow,
    callCondition,
    callDecision,
    callEndpoint,
    callFunction,
    callMapping,
    callMessage,
    callProduct,
    callStart,
    clickBox,
    clickDouble,
    dispatch,
    strategy_data,
  ]);

  useEffect(() => {
    const svg = document.querySelector("#svg_board") as SVGElement;   
    const check_db = localStorage.getItem(storage_key);

    const activeStrategy = sessionStorage.getItem("active_strategy");
    const { status } = SourceDataAvailability;

    new DB(storage_key).fetch({ type: "validate" }).then((_res: any) => {
      if (_res.status && _res.data) {
        const db = new DB(storage_key);
        db.fetch({ type: "validate" }).then((res: any) => {
          if (res.status && res.data) {
            const [left, top] = res.data?.style?.split(",");

            const data = {
              ...elementSize.validate,
              x: +left?.split(":")?.[1]?.split("px")?.[0],
              y: +top?.split(":")?.[1]?.split("px")?.[0],
            };

            const validate = createElement("validate", data) as SVGGElement;
            if (!svg.querySelector('rect[data-id = "validate"]') && validate) {
              svg.appendChild(validate);

              const start_element = svg.querySelector(
                `g[data-id = "startpoint"]`
              ) as SVGRectElement;
              const arrow = svg.querySelector(
                `path[data-contact ^= "startpoint"]`
              ) as SVGPathElement;

              arrow?.setAttributeNS(null, "pointer-events", "none");

              const side = arrow?.dataset?.side;
              if (start_element && side) {
                add_element(start_element, validate, side);
              }
              dispatch(handleStrategyValidateStatus({ status: true }));
              const _box = start_element?.querySelector(
                "#arrow_box_group"
              ) as SVGGElement;
              const _circles = _box?.querySelectorAll("g") as NodeList;
              _circles?.forEach((g: any) => {
                g.style.display = "none";
              });
              const box = validate.querySelector(
                "#arrow_box_group"
              ) as SVGGElement;
              const circles = box?.querySelectorAll(
                "g"
              ) as NodeListOf<SVGGElement>;
              const validate_output = svg.querySelector(
                `path[data-contact ^= "validate"]`
              )! as SVGPathElement;
              // const wizard_output = svg.querySelector(`path[data-contact ^= "datawizard"]`)! as SVGPathElement;

              if (validate_output) {
                const { side } = getArrowPropery(validate_output);
                circles?.forEach((circle: SVGGElement) => {
                  if (
                    circle.id !== `${replace_source_side(side)}_arrow_box_group`
                  ) {
                    circle.style.display = "none";
                  }
                });
              } else {
                if (side) {
                  const getBox = box.querySelector(
                    `g[id = "${replace_side(side)}_arrow_box_group"]`
                  )! as SVGGElement;
                  if (getBox) {
                    getBox.style.display = "none";
                  }
                }
              }

              clickBox(validate);
              clickDouble(validate);
            }
          } else {
            const data = {
              ...elementSize.validate,
              x: 0,
              y: 0,
            };
            const validate = createElement("validate", data);

            if (!svg.querySelector('rect[data-id = "validate"]') && validate) {
              svg.appendChild(validate);

              const start_element = svg.querySelector(
                `g[data-id = "startpoint"]`
              ) as SVGRectElement;
              const _box = start_element?.querySelector(
                "#arrow_box_group"
              ) as SVGGElement;
              const _circles = _box?.querySelectorAll("g") as NodeList;
              _circles?.forEach((g: any) => {
                g.style.display = "none";
              });
              const arrow = svg.querySelector(
                `path[data-contact ^= "startpoint"]`
              ) as SVGPathElement;
              arrow?.setAttributeNS(null, "pointer-events", "none");

              const side = arrow.dataset.side;
              if (start_element && side) {
                add_element(start_element, validate, side);
              }
              add_storage(validate);
              dispatch(handleStrategyValidateStatus({ status: true }));

              clickBox(validate);

              clickDouble(validate);
            }
          }
        });
        dispatch(handleStrategySourceDataStatus({ status: false }));
      } else {
        if (activeStrategy && status) {
          const { id } = JSON.parse(activeStrategy);

          request
            .get(strategy_source_data.get(id))
            .then((res) => {
              const { success, response } = res;

              if (
                success &&
                response.length > 0 &&
                Object.entries(response[0]?.source_data)?.length > 0
              ) {
                if (check_db) {
                  const db = new DB(storage_key);
                  db.fetch({ type: "validate" }).then((res: any) => {
                    if (res.status && res.data) {
                      const [left, top] = res.data?.style?.split(",");

                      const data = {
                        ...elementSize.validate,
                        x: +left?.split(":")?.[1]?.split("px")?.[0],
                        y: +top?.split(":")?.[1]?.split("px")?.[0],
                      };
                      const validate = createElement("validate", data);
                      if (
                        !svg.querySelector('rect[data-id = "validate"]') &&
                        validate
                      ) {
                        svg.appendChild(validate);
                        dispatch(
                          handleStrategySourceDataStatus({ status: false })
                        );

                        const start_element = svg.querySelector(
                          `g[data-id = "startpoint"]`
                        ) as SVGRectElement;
                        const _box = start_element?.querySelector(
                          "#arrow_box_group"
                        ) as SVGGElement;
                        const _circles = _box?.querySelectorAll(
                          "g"
                        ) as NodeList;
                        _circles?.forEach((g: any) => {
                          g.style.display = "none";
                        });
                        const arrow = svg.querySelector(
                          `path[data-contact ^= "startpoint"]`
                        ) as SVGPathElement;
                        arrow?.setAttributeNS(null, "pointer-events", "none");

                        const side = arrow.dataset.side;
                        if (start_element && side) {
                          add_element(start_element, validate, side);
                        }
                        const box = validate?.querySelector(
                          "#arrow_box_group"
                        ) as SVGGElement;
                        const circles = box?.querySelectorAll(
                          "g"
                        ) as NodeListOf<SVGGElement>;
                        circles?.forEach((g: any) => {
                          if (g.id !== `${side}_arrow_box_group`) {
                            g.style.display = "none";
                          }
                        });

                        dispatch(
                          handleStrategyValidateStatus({ status: true })
                        );

                        clickBox(validate);
                        clickDouble(validate);
                      }
                    } else {
                      const data = {
                        ...elementSize.validate,
                        x: 0,
                        y: 0,
                      };
                      const validate = createElement("validate", data);

                      if (
                        !svg.querySelector('rect[data-id = "validate"]') &&
                        validate
                      ) {
                        svg.appendChild(validate);
                        dispatch(
                          handleStrategySourceDataStatus({ status: false })
                        );

                        const start_element = svg.querySelector(
                          `g[data-id = "startpoint"]`
                        ) as SVGRectElement;
                        const _box = start_element?.querySelector(
                          "#arrow_box_group"
                        ) as SVGGElement;
                        const _circles = _box?.querySelectorAll(
                          "g"
                        ) as NodeListOf<SVGGElement>;
                        _circles?.forEach((g: any) => {
                          g.style.display = "none";
                        });
                        const arrow = svg.querySelector(
                          `path[data-contact ^= "startpoint"]`
                        ) as SVGPathElement;
                        arrow?.setAttributeNS(null, "pointer-events", "none");
                        const side = arrow?.dataset?.side;
                        if (start_element && side) {
                          add_element(start_element, validate, side);
                          const box = validate?.querySelector(
                            "#arrow_box_group"
                          ) as SVGGElement;
                          const circles = box?.querySelectorAll(
                            "g"
                          ) as NodeListOf<SVGGElement>;
                          if (side) {
                            circles?.forEach((circle: SVGGElement) => {
                              if (
                                circle.id !==
                                `${replace_source_side(side)}_arrow_box_group`
                              ) {
                                circle.style.display = "none";
                              }
                            });
                            const activeSide = box.querySelector(
                              `g[id = "${replace_source_side(
                                side
                              )}_arrow_box_group"]`
                            ) as SVGGElement;
                            if (activeSide) {
                              activeSide.style.display = "block";
                            }
                          }
                        } else if (start_element && !side) {
                          add_element(start_element, validate, "right");
                          const box = validate?.querySelector(
                            "#arrow_box_group"
                          ) as SVGGElement;
                          const circles = box?.querySelectorAll(
                            "g"
                          ) as NodeListOf<SVGGElement>;
                          if (side) {
                            circles?.forEach((circle: SVGGElement) => {
                              if (
                                circle.id !==
                                `${replace_source_side(side)}_arrow_box_group`
                              ) {
                                circle.style.display = "none";
                              }
                            });
                          }
                        }

                        BoardStorage(validate, EActionTypes.ADD);
                        dispatch(
                          handleStrategyValidateStatus({ status: true })
                        );

                        clickBox(validate);
                        clickDouble(validate);
                      }
                    }
                  });
                }
              }
              if (!success) {
                console.warn(response.message);
              }
            })
            .catch((err) => {
              console.error("Get Specific Strategy Source Data Error : ", err);
            });
          dispatch(handleStrategySourceDataStatus({ status: false }));
        }
      }
    });
  }, [
    SourceDataAvailability,
    activeSourceElement,
    clickBox,
    clickDouble,
    clickSingle,
    dispatch,
    strategy_source_data,
    validate_status,
  ]);

  useEffect(() => {
    if (validate_status && wizard_status) {
      const svg = document.querySelector("#svg_board") as SVGElement;

      new DB(storage_key).fetch({ type: "datawizard" }).then((_res: any) => {
        if (_res.status && _res.data) {
          const data = {
            ...elementSize.wizard,

            x: 0,
            y: 0,
          };

          const wizard = createElement("datawizard", data);
          clickSingle(wizard);

          if (!svg.querySelector('rect[data-id = "datawizard"]') && wizard) {
            svg.appendChild(wizard);

            const validate = svg.querySelector(
              `g[data-id = "validate"]`
            ) as SVGRectElement;
            const box_validate = validate?.querySelector(
              "#arrow_box_group"
            ) as SVGGElement;
            const circles_validate = box_validate?.querySelectorAll(
              "g"
            ) as NodeList;
            circles_validate?.forEach((g: any) => {
              g.style.display = "none";
            });
            const arrow = svg.querySelector(
              `path[data-contact ^= "validate"]`
            ) as SVGPathElement;
            const side = arrow?.dataset?.side;

            if (validate && side) {
              add_element(validate, wizard, side);
            } else if (validate && !side) {
              add_element(validate, wizard, "right");
            }
            // add_storage(wizard);
            BoardStorage(wizard, EActionTypes.ADD);
            const box = wizard.querySelector("#arrow_box_group") as SVGGElement;

            const circles = box?.querySelectorAll(
              "g"
            ) as NodeListOf<SVGGElement>;
            if (side) {
              circles?.forEach((circle: SVGGElement) => {
                if (
                  circle.id !== `${replace_source_side(side)}_arrow_box_group`
                ) {
                  circle.style.display = "none";
                }
              });
              const activeSide = box.querySelector(
                `g[id = "${replace_source_side(side)}_arrow_box_group"]`
              ) as SVGGElement;
              if (activeSide) {
                activeSide.style.display = "block";
              }
            }

            // circles?.forEach((g: any) => {
            //   if (g.id !== `${side}_arrow_box_group`) {
            //     g.style.display = "none";
            //   }
            // });
            clickBox(wizard);
            clickDouble(wizard);
          }
        }
      });
    } else if (validate_status && !wizard_status) {
      const svg = document.querySelector("#svg_board") as SVGElement;
      new DB(storage_key).fetch({ type: "datawizard" }).then((_res: any) => {
        if (_res.status && _res.data) {
          if (!_res.data.style) return;
          const [left, top] = _res.data?.style?.split(",");

          const data = {
            ...elementSize.wizard,
            x: +left?.split(":")?.[1]?.split("px")?.[0],
            y: +top?.split(":")?.[1]?.split("px")?.[0],
          };
          const wizard = createElement("datawizard", data);
          clickSingle(wizard);

          if (!svg.querySelector('rect[data-id = "datawizard"]') && wizard) {
            svg.appendChild(wizard);
            const validate = svg.querySelector(
              `g[data-id = "validate"]`
            ) as SVGRectElement;
            const box_validate = validate?.querySelector(
              "#arrow_box_group"
            ) as SVGGElement;
            const circles_validate = box_validate?.querySelectorAll(
              "g"
            ) as NodeList;
            circles_validate?.forEach((g: any) => {
              g.style.display = "none";
            });
            const arrow = svg.querySelector(
              `path[data-contact ^= "validate"]`
            ) as SVGPathElement;
            arrow?.setAttributeNS(null, "pointer-events", "none");
            const side = arrow?.dataset?.side;
            if (validate && side) {
              add_element(validate, wizard, side);
            }
            const box = wizard.querySelector("#arrow_box_group") as SVGGElement;
            const wizard_output = svg.querySelector(
              `path[data-contact ^= "datawizard"]`
            )! as SVGPathElement;

            const circles = box?.querySelectorAll(
              "g"
            ) as NodeListOf<SVGGElement>;
            if (wizard_output) {
              const { side } = getArrowPropery(wizard_output);
              circles?.forEach((circle: SVGGElement) => {
                if (
                  circle.id !== `${replace_source_side(side)}_arrow_box_group`
                ) {
                  circle.style.display = "none";
                }
              });
            } else {
              if (side) {
                const getBox = box.querySelector(
                  `g[id = "${replace_side(side)}_arrow_box_group"]`
                )! as SVGGElement;
                if (getBox) {
                  getBox.style.display = "none";
                }
              }
            }
            clickBox(wizard);
            clickDouble(wizard);
          }
        }
      });
    }
  }, [clickBox, clickDouble, clickSingle, validate_status, wizard_status]);

  useEffect(() => {
    if (!element_panel) {
      setRemovePanel({ x: 0, y: 0 });
      setRemoveArrowPanel({ x: 0, y: 0 });

      setToolsPanel({ x: 0, y: 0, side: null });
    }
  }, [element_panel]);

  useEffect(() => {
    if (!element_input) {
      setRemovePanel({ x: 0, y: 0 });
      setRemoveArrowPanel({ x: 0, y: 0 });
      setToolsPanel({ x: 0, y: 0, side: null });
    }
  }, [element_input]);

  const add_store = useCallback(

    (element: SVGGElement) => {
      const data_id = element.dataset.id;
      if (data_id) {

        dispatch(handlerStrategyBoardElements({ board_elements: data_id }));
        dispatch(
          handleStrategyNewCreatedElementId({ new_created_element: data_id })
        );
      }
    },
    [dispatch]
  );

  useEffect(() => {
    const svg: any = document.querySelector("#svg_board") as SVGElement;
    let { x, y } = svg.getBoundingClientRect();

    let percent = +(+zoom?.toFixed(2) * 100).toFixed(0);

    if (typeof element_type === "string") {
      if (element_type === "function") {
        const function_data = {
          ...elementSize.function,

          x:
            x < 0
              ? (Math.abs(x) / percent) * 100 +
                ((window.innerWidth / percent) * 100) / 2 -
                elementSize.function.width / 2
              : ((window.innerWidth / percent) * 100 -
                  (Math.abs(x) / percent) * 100) /
                  2 -
                elementSize.function.width / 2,
          y:
            y < 0
              ? (Math.abs(y) / percent) * 100 +
                ((window.innerHeight / percent) * 100) / 2 -
                elementSize.function.height / 2
              : ((window.innerHeight / percent) * 100 -
                  (Math.abs(y) / percent) * 100) /
                  2 -
                elementSize.function.height / 2,
        };

        const function_element = createElement("function", function_data);

        if (function_element) {
          clickBox(function_element);
          clickDouble(function_element);
          clickSingle(function_element);
          moveHandler(function_element);
          svg?.appendChild(function_element);
          add_store(function_element);

          setToolsPanel({ x: 0, y: 0, side: null });
        }
      }
      if (element_type === "product") {
        const product_data = {
          ...elementSize.product,

          x:
            x < 0
              ? (Math.abs(x) / percent) * 100 +
                ((window.innerWidth / percent) * 100) / 2 -
                elementSize.product.width / 2
              : ((window.innerWidth / percent) * 100 -
                  (Math.abs(x) / percent) * 100) /
                  2 -
                elementSize.product.width / 2,
          y:
            y < 0
              ? (Math.abs(y) / percent) * 100 +
                ((window.innerHeight / percent) * 100) / 2 -
                elementSize.product.height / 2
              : ((window.innerHeight / percent) * 100 -
                  (Math.abs(y) / percent) * 100) /
                  2 -
                elementSize.function.height / 2,
        };

        const product_element = createElement("product", product_data);

        if (product_element) {
          clickBox(product_element);
          clickDouble(product_element);
          clickSingle(product_element);
          moveHandler(product_element);
  
          svg?.appendChild(product_element);
          add_store(product_element);

          BoardStorage(product_element, EActionTypes.ADD);

          setToolsPanel({ x: 0, y: 0, side: null });
        }
      }
      if (element_type === "scorecard") {
        const mapping_data = {
          ...elementSize.mapping,
          x:
            x < 0
              ? (Math.abs(x) / percent) * 100 +
                ((window.innerWidth / percent) * 100) / 2 -
                elementSize.mapping.width / 2
              : ((window.innerWidth / percent) * 100 -
                  (Math.abs(x) / percent) * 100) /
                  2 -
                elementSize.mapping.width / 2,
          y:
            y < 0
              ? (Math.abs(y) / percent) * 100 +
                ((window.innerHeight / percent) * 100) / 2 -
                elementSize.mapping.height / 2
              : ((window.innerHeight / percent) * 100 -
                  (Math.abs(y) / percent) * 100) /
                  2 -
                elementSize.mapping.height / 2,
        };

        const mapping_element = createElement("scorecard", mapping_data);
        if (mapping_element) {
          clickBox(mapping_element);
          clickDouble(mapping_element);
          clickSingle(mapping_element);
          moveHandler(mapping_element);

          svg?.appendChild(mapping_element);
          add_store(mapping_element);
          setToolsPanel({ x: 0, y: 0, side: null });
        }
      }

      if (element_type === "condition") {
        const condition_data = {
          ...elementSize.condition,
          x:
            x < 0
              ? (Math.abs(x) / percent) * 100 +
                ((window.innerWidth / percent) * 100) / 2 -
                elementSize.condition.width / 2
              : ((window.innerWidth / percent) * 100 -
                  (Math.abs(x) / percent) * 100) /
                  2 -
                elementSize.condition.width / 2,
          y:
            y < 0
              ? (Math.abs(y) / percent) * 100 +
                ((window.innerHeight / percent) * 100) / 2 -
                elementSize.condition.height / 2
              : ((window.innerHeight / percent) * 100 -
                  (Math.abs(y) / percent) * 100) /
                  2 -
                elementSize.condition.height / 2,
        };

        const condition_element = createElement("condition", condition_data);

        if (condition_element) {
          clickBox(condition_element);
          clickDouble(condition_element);
          clickSingle(condition_element);
          moveHandler(condition_element);

          svg?.appendChild(condition_element);

          add_store(condition_element);
          setToolsPanel({ x: 0, y: 0, side: null });
        }
      }

      dispatch(handlerStrategyBoardType({ element_type: null }));
    }
  }, [
    add_store,
    clickBox,
    clickDouble,
    clickSingle,
    dispatch,
    element_type,
    zoom,
  ]);

  useEffect(() => {
    const svg: any = document.querySelector("#svg_board") as SVGElement;
    let { x, y } = svg.getBoundingClientRect();

    let percent = +(+zoom?.toFixed(2) * 100).toFixed(0);
    if (
      (content_value === "range" || content_value === "matrix") &&
      element_tooltip_status === "side_panel"
    ) {
      const decision_data = {
        ...elementSize.decision,

        x:
          x < 0
            ? (Math.abs(x) / percent) * 100 +
              ((window.innerWidth / percent) * 100) / 2 -
              elementSize.function.width / 2
            : ((window.innerWidth / percent) * 100 -
                (Math.abs(x) / percent) * 100) /
                2 -
              elementSize.function.width / 2,
        y:
          y < 0
            ? (Math.abs(y) / percent) * 100 +
              ((window.innerHeight / percent) * 100) / 2 -
              elementSize.function.height / 2
            : ((window.innerHeight / percent) * 100 -
                (Math.abs(y) / percent) * 100) /
                2 -
              elementSize.function.height / 2,
      };

      const decision_element = createElement(
        "decision",
        decision_data,
        "",
        content_value
      );

      clickBox(decision_element);
      clickDouble(decision_element, content_value);
      clickSingle(decision_element);
      moveHandler(decision_element);

      svg?.appendChild(decision_element);
      add_store(decision_element);

      setToolsPanel({ x: 0, y: 0, side: null });
    }
  }, [
    add_store,
    clickBox,
    clickDouble,
    clickSingle,
    content_value,
    element_tooltip_status,
    zoom,
  ]);

  useEffect(() => {
    const svg = document.querySelector("#svg_board") as SVGElement;
    const arrows = svg.querySelectorAll(`path[id = "arrow"]`) as NodeList;
    if (arrows?.length > 0) {
      arrows.forEach((arrow: any) => {
        clickDouble(arrow);
      });
    }
  }, [clickDouble]);

  const tools_panel = (e: any) => {
    const {
      x: {
        baseVal: { value: x },
      },
      y: {
        baseVal: { value: y },
      },
      width: {
        baseVal: { value: width },
      },
    } = e.currentTarget;
    const side: string = e.currentTarget.dataset.side;
    const parent = e.currentTarget.closest(
      'g[data-type = "figure"]'
    ) as SVGGElement;
    if (parent && side) {
      setActiveSourceElement(parent);
      setToolsPanel({ x: x, y: y + width + 10, side: side });
      setRemovePanel({ x: 0, y: 0 });
    }
  };

  const removeElementHandler = useCallback(
    (defaultSelected: string = "") => {
      const svg = document.querySelector("#svg_board") as SVGSVGElement;
      if (!selected && !defaultSelected) return;
      const _selected = selected ?? defaultSelected
      if(!svg) return;
      const find_element: any = svg.querySelector(
        `rect[data-id = ${_selected}]`
      ) as SVGRectElement;
      const find_arrows = svg.querySelectorAll(
        `path[data-contact $= ${_selected}]`
      ) as NodeListOf<SVGPathElement>;
      const find_arrows_target = svg.querySelectorAll(
        `path[data-contact ^= ${_selected}]`
      ) as NodeListOf<SVGPathElement>;
      if (find_element) {
        const type = find_element.dataset.type as string;
        if (type === ElementTypes.CONDITION) {
          find_arrows_target?.forEach((arrow: SVGPathElement) => {
            const bool = arrow?.dataset?.bool as string;
            if (bool === "no") {
              const contact = arrow?.dataset?.contact as string;
              const noElement = svg?.querySelector(
                `rect[data-id = ${contact?.split("-")?.[1]}]`
              ) as SVGRectElement;

              if (noElement) {
                remove_db(noElement);
                const noElementParent = noElement.parentNode as SVGGElement;
                svg.removeChild(noElementParent);
              }
            }
          });
        }
        remove_db(find_element);
      }

      if (find_arrows?.length !== 0) {
        find_arrows?.forEach((arrow: any) => {
          const id = arrow.dataset.contact;
          if (id) {
            let find_text = svg.querySelectorAll(
              `text[id = "${id}"]`
            ) as NodeList;
            find_text?.forEach((text: any) => {
              svg.removeChild(text);
            });
          }
          if (arrow) {
            svg.removeChild(arrow);
          }
        });
      }
      if (find_arrows_target?.length !== 0) {
        find_arrows_target?.forEach((arrow: any) => {
          const id = arrow?.dataset?.contact;
          if (id) {
            let find_text = svg.querySelectorAll(
              `text[id = "${id}"]`
            ) as NodeList;
            find_text?.forEach((text: any) => {
              svg.removeChild(text);
            });
          }

          if (arrow) {
            svg.removeChild(arrow);
          }
        });
      }
      if (find_arrows) {
        call_arrow_box_source(find_arrows);
      }
      if (find_arrows_target) {
        call_arrow_box_target(find_arrows_target);
      }

      if (find_element) {
        let elements = [...board_elements]?.filter((f: any) => f !== _selected);
        dispatch(handlerStrategyBoardElements({ board_elements: elements }));
        dispatch(handlerStrategyElementInput({ element_input: null }));
        dispatch(handlerStrategyBoardPanel({ element_panel: false }));
        dispatch(handleStrategyBoardSidePanelContent({ content_value: null }));

        svg.removeChild(find_element.parentNode);
        setRemovePanel({ x: 0, y: 0 });
        contact_chaining(find_element, find_arrows, find_arrows_target);
        removeElementFromOutput(_selected);
      }
    },
    [board_elements, dispatch, selected]
  );

  const removeArrowHandler = (e: any) => {
    const svg = document.querySelector("#svg_board") as SVGElement;
    if (selected) {
      let find_arrows = svg.querySelectorAll(
        `path[data-contact $= "${selected}"]`
      ) as NodeList;
      let find_arrows_target = svg.querySelectorAll(
        `path[data-contact ^= "${selected}"]`
      ) as NodeList;

      remove_arrow_db(selected);

      if (find_arrows?.length > 0) {
        svg.removeChild(find_arrows[0]);
        let find_arrow = find_arrows[0] as SVGPathElement;

        const id = find_arrow?.dataset.contact;
        if (id) {
          let find_text = svg.querySelectorAll(
            `text[id = "${id}"]`
          ) as NodeList;
          find_text?.forEach((text: any) => {
            svg.removeChild(text);
          });
        }

        call_arrow_box_source(find_arrows);
        if (find_arrows_target) {
          call_arrow_box_target(find_arrows_target);
        }
        setRemoveArrowPanel({ x: 0, y: 0 });
      }
    }
  };

  useEffect(() => {
    if(new_created_element || clickStatus) return;
    const db = new DB(storage_key);
    db.fetchAll().then((res: any) => {
      const { status, data } = res;
      if (!status) return;
      const filtered = data?.filter(
        (f: any) =>
          f.type !== "startpoint" &&
          f.type !== "endpoint" &&
          f.type !== "validate" &&
          f.type !== "output" &&
          f.type !== "datawizard" &&

          f.type !== "code_message"
      ) as any[];
      const getEmptyTargetElements = filtered?.filter((f:any)=>{
        return f.type === 'condt' && !f.targetId
      }) as any[];
      getEmptyTargetElements?.forEach((element:any)=>{
        element.overlays = [];
        const {sourceId,branchLabel} = element;
        db.update({sourceId:sourceId,branchLabel:branchLabel},[element])
      })
      const getUndefinedElements = filtered?.filter(
        (f: any) => Object.keys(f.data).length === 0 || (Object.keys(f.data).length > 0 && !f.data?.id)
      ) as any[];
      getUndefinedElements?.forEach((element: any) => {
        const { sourceId } = element;
        if (!sourceId) return;
        removeElementHandler(sourceId);
      });
    });
  }, [removeElementHandler, new_created_element, clickStatus]);

  const clearStore = () => {
    dispatch(handlerStrategyElementInput({element_input:null}))
    dispatch(
      handleStrategyBoardSidePanelContent({ content_value: null })
    );
  }
  const addFunctionHandler = (e: any, side: string) => {
    clearStore()
    const svg: any = document.querySelector("#svg_board") as SVGElement;

    const function_data = {
      ...elementSize.function,
    };

    const function_element = createElement(
      "function",
      function_data
    ) as SVGGElement;

    if (function_element) {
      clickBox(function_element);
      clickDouble(function_element);
      clickSingle(function_element);
      moveHandler(function_element);

      svg?.appendChild(function_element);

      clickDouble(add_element(activeSourceElement, function_element, side));
      add_store(function_element);
      // add_storage(function_element);
      BoardStorage(function_element, EActionTypes.ADD);

      const id = function_element?.dataset.id;
      if (id) {
        dispatch(handlerStrategyBoardElements({ board_elements: id }));
        dispatch(
          handleStrategyNewCreatedElementId({ new_created_element: id })
        );
        dispatch(
          handleStrategyBoardSidePanelContent({ content_value: "function" })
        );
        setToolsPanel({ x: 0, y: 0, side: null });
      }
    }
  };
  const addProductHandler = (e: any, side: string) => {
    clearStore()

    const svg: any = document.querySelector("#svg_board") as SVGElement;

    const product_data = {
      ...elementSize.product,
    };

    const product_element = createElement(
      "product",
      product_data
    ) as SVGGElement;

    if (product_element) {
      clickBox(product_element);
      clickDouble(product_element);
      clickSingle(product_element);
      moveHandler(product_element);

      svg?.appendChild(product_element);

      clickDouble(add_element(activeSourceElement, product_element, side));
      add_store(product_element);
      BoardStorage(product_element, EActionTypes.ADD);

      const id = product_element?.dataset.id;
      if (id) {
        dispatch(handlerStrategyBoardElements({ board_elements: id }));
        dispatch(
          handleStrategyNewCreatedElementId({ new_created_element: id })
        );
        dispatch(
          handleStrategyBoardSidePanelContent({ content_value: "product" })
        );
        setToolsPanel({ x: 0, y: 0, side: null });
      }
    }
  };
  const addMappingHandler = (e: any, side: string) => {
    clearStore()

    const svg: any = document.querySelector("#svg_board") as SVGElement;

    const mapping_data = {
      ...elementSize.mapping,
    };
    const mapping_element = createElement(
      "scorecard",
      mapping_data
    ) as SVGGElement;

    if (mapping_element) {
      clickBox(mapping_element);
      clickDouble(mapping_element);
      clickSingle(mapping_element);
      moveHandler(mapping_element);

      svg?.appendChild(mapping_element);

      clickDouble(add_element(activeSourceElement, mapping_element, side));
      add_store(mapping_element);
      // add_storage(mapping_element);
      BoardStorage(mapping_element, EActionTypes.ADD);

      const id = mapping_element.dataset.id;
      if (id) {
        dispatch(handlerStrategyBoardElements({ board_elements: id }));
        dispatch(
          handleStrategyNewCreatedElementId({ new_created_element: id })
        );
        dispatch(
          handleStrategyBoardSidePanelContent({ content_value: "scorecard" })
        );
        setToolsPanel({ x: 0, y: 0, side: null });
      }
    }
  };
  const addDecisionHandler = (e: any, side: string) => {
    clearStore()

    dispatch(handleStrategyElementTooltipStatus({ status: "tooltip" }));
    const svg: any = document.querySelector("#svg_board") as SVGElement;

    const descision_data = {
      ...elementSize.decision,
    };
    const decision_element = createElement(
      "decision",
      descision_data,
      "",
      "range"
    ) as SVGGElement;

    if (decision_element) {
      clickBox(decision_element);
      clickDouble(decision_element, "range");
      clickSingle(decision_element);
      moveHandler(decision_element);

      svg?.appendChild(decision_element);

      clickDouble(add_element(activeSourceElement, decision_element, side));
      add_store(decision_element);
      // add_storage(decision_element);
      BoardStorage(decision_element, EActionTypes.ADD);

      const id = decision_element.dataset.id;
      if (id) {
        dispatch(handlerStrategyBoardElements({ board_elements: id }));
        dispatch(
          handleStrategyNewCreatedElementId({ new_created_element: id })
        );
        dispatch(
          handleStrategyBoardSidePanelContent({ content_value: "range" })
        );
        setToolsPanel({ x: 0, y: 0, side: null });
      }
    }
  };
  const addDecisionMatrixHandler = (e: any, side: string) => {
    clearStore()

    dispatch(handleStrategyElementTooltipStatus({ status: "tooltip" }));
    const svg: any = document.querySelector("#svg_board") as SVGElement;

    const descision_data = {
      ...elementSize.decision,
    };
    const decision_element = createElement(
      "decision",
      descision_data,
      "",
      "matrix"
    ) as SVGGElement;

    if (decision_element) {
      clickBox(decision_element);
      clickDouble(decision_element, "matrix");
      clickSingle(decision_element);
      moveHandler(decision_element);

      svg?.appendChild(decision_element);

      clickDouble(add_element(activeSourceElement, decision_element, side));
      add_store(decision_element);
      // add_storage(decision_element);
      // BoardStorage(decision_element,EActionTypes.ADD)

      const id = decision_element.dataset.id;
      if (id) {
        dispatch(handlerStrategyBoardElements({ board_elements: id }));
        dispatch(
          handleStrategyNewCreatedElementId({ new_created_element: id })
        );
        dispatch(
          handleStrategyBoardSidePanelContent({ content_value: "matrix" })
        );
        setToolsPanel({ x: 0, y: 0, side: null });
      }
    }
  };
  const addConditionHandler = (e: any, side: string) => {
    clearStore()

    dispatch(handlerStrategyElementInput({ element_input: null }));
    dispatch(handleStrategyBoardSidePanelContent({ content_value: null }));

    const svg: any = document.querySelector("#svg_board") as SVGElement;

    const condition_data = {
      ...elementSize.condition,
    };
    const condition_element = createElement("condition", condition_data);

    if (condition_element) {
      clickBox(condition_element);
      clickDouble(condition_element);
      clickSingle(condition_element);
      moveHandler(condition_element);

      svg?.appendChild(condition_element);

      clickDouble(add_element(activeSourceElement, condition_element, side));
      add_store(condition_element);
      // add_storage(condition_element);
      BoardStorage(condition_element, EActionTypes.ADD);

      const id = condition_element.dataset.id;
      if (id) {
        dispatch(handlerStrategyBoardElements({ board_elements: id }));
        dispatch(
          handleStrategyNewCreatedElementId({ new_created_element: id })
        );
        dispatch(
          handleStrategyBoardSidePanelContent({ content_value: "condition" })
        );

        setToolsPanel({ x: 0, y: 0, side: null });
      }
    }
  };

  const addCodeMessageHandler = (e: any, side: any) => {
    clearStore()

    const svg = document.querySelector("#svg_board") as SVGElement;

    const code_message = {
      ...elementSize.code_message,
    };

    const message_element = createElement(
      "codemessage",
      code_message
    ) as SVGGElement;

    if (message_element) {
      clickBox(message_element);
      clickDouble(message_element);
      moveHandler(message_element);

      svg?.appendChild(message_element);

      clickDouble(add_element(activeSourceElement, message_element, side));

      add_store(message_element);
      // add_storage(message_element);
      // BoardStorage(message_element, EActionTypes.ADD);

      const id = message_element.dataset.id;
      if (id) {
        dispatch(handlerStrategyBoardElements({ board_elements: id }));
        dispatch(
          handleStrategyNewCreatedElementId({ new_created_element: id })
        );
        dispatch(
          handleStrategyBoardSidePanelContent({ content_value: "code_message" })
        );

        setToolsPanel({ x: 0, y: 0, side: null });
      }
    }
  };
  return (
    <Fragment>
      <ToolsPanel
        selectedElement={activeSourceElement?.dataset.id}
        coord={toolsPanel}
        addFunction={addFunctionHandler}
        addCondition={addConditionHandler}
        addCodeMessage={addCodeMessageHandler}
        addMapping={addMappingHandler}
        addDecision={addDecisionHandler}
        addProduct={addProductHandler}
        addDecisionMatrix={addDecisionMatrixHandler}
      />
      <RemovePanel removeHandler={removeElementHandler} coord={removePanel} />
      <RemovePanel
        removeHandler={removeArrowHandler}
        coord={removeArrowPanel}
      />

      <svg
        id="svg_board"
        className={classes.SvgBoard}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="100%"
        height="100%"
      ></svg>
    </Fragment>
  );
};

export default memo(Board);