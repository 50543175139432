import React, { FC, useEffect, useCallback, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./Table.module.scss";
import { icons } from "../../../settings/settings";
import Tooltip from "../../../components/UI/Tooltip/Tooltip";
import {
  activeModal as handleActiveModal,
  handleActiveTooltip,
  touchBlocked,
} from "../../../store/actions/action";
import {
  handleScoreCardCategoryData,
  handleScoreCardLevel,
  handleScoreCardLevelDataId,
  handleScoreCardCriteriaData,
  handleScoreCardBlockedModalResponse,
  handleScoreCardActiveElement,
  handleScoreCardActiveRow,
  removeScoreCardNewRow,
  handleScoreCardRowEditableStatus,
  handleActiveTree,
  handleScoreCardRecoveryData,
  handleScoreCardRowWeigthEditableStatus,
  calculateScoreCardRemainingWeight,
  handleScoreCardRemainingWeightWarning,
  calculateScoreCardPoint,
  calculateScoreCardRemainingPoint,
} from "../../../store/actions/actionsScoreCard";
import { RootState } from "../../../store/combineReducer";
import { request } from "../../../helpers/request";
import { endpoints } from "../../../api/endpoints";
// import Checkbox from "../../../components/UI/Checkbox/CheckboxV2";
import {
  Pen,
  Delete,
  Active,
  Cancel,
  NextArrowRight,
} from "../../../components/UI/Svg/Svg";

const CategoryLevel: FC = () => {
  const [criteriaTrusted] = useState<Array<any>>([{key:'Dynamic',value:false},{key:'Static',value:true}]);
  let copyState: any[] = [];
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const {
    reducer: {
      mainTooltip: { type },
    },
    reducerScoreCard: {
      scoreCardCategoryData: { scoreCardCategoryData },
      scoreCardCriteriaData: { scoreCardCriteriaData },
      scoreCardLevel: { level },
      scoreCardLevelDataId: { scoreCardId },
      scoreCardActiveRow: { rowIndex, rowType, rowId },
      scoreCardActiveElement,
      activeTree: { activeTree },
      scoreCardRecoveryData: { recoveryData },
      scoreCardBlockedModalResponse: { response },
      scoreCardRemainingWeight,
      scoreCardRemainingWeightWarning: { weightWarningStatus },
      scoreCardRemainingPoint,
    },
  } = state;

  const refreshHandlerCallback = useCallback(
    (id: number | null) => {
      const {
        score_card: { category },
      } = endpoints;

      if (id !== null) {
        const dataWithoutActiveScoreCard = scoreCardCategoryData?.filter(
          (element: any) => element.score_card_id !== id
        );

        request
          .get(category.getAll(id))
          .then((result) => {
            const { response } = result;
            const combinedData = dataWithoutActiveScoreCard?.concat(response);
            dispatch(
              handleScoreCardCategoryData({
                scoreCardCategoryData: combinedData,
              })
            );
            dispatch(calculateScoreCardRemainingWeight(level, id));
            dispatch(
              calculateScoreCardRemainingPoint({
                remainingPointLevel: level,
                remainingPointParentId: id,
              })
            );
          })
          .catch((error) => {
            console.log("error " + error);
          });

        dispatch(
          handleScoreCardActiveRow({
            rowIndex: null,
            rowType: null,
            rowId: null,
          })
        );
        dispatch(handleScoreCardActiveElement({ name: null }));
        dispatch(
          handleActiveTooltip({ childId: null, childDataId: null, type: null })
        );
        dispatch(touchBlocked({ status: false }));
        dispatch(handleScoreCardRecoveryData({ recoveryData: null }));
      }
    },
    [dispatch, scoreCardCategoryData, level]
  );

  const applyHandlerCallback = useCallback(
    (id: number | null) => {
      const {
        score_card: { category },
      } = endpoints;

      let newData = scoreCardCategoryData?.find(
        (element: any) => element.newRow === true
      );
      let updatedData = scoreCardCategoryData?.find(
        (element: any) => element.editable === true && element.newRow === false
      );
      let weightUpdatedData = scoreCardCategoryData?.find(
        (element: any) => element.weightEditStatus === true
      );

      if (newData && !newData.name) {
        dispatch(
          handleActiveTooltip({
            childId: `category-name-${rowIndex}`,
            childDataId: null,
            type: "validation",
          })
        );
        dispatch(handleScoreCardBlockedModalResponse({ response: null }));
      }

      if (
        newData &&
        newData.name &&
        type !== "validation" &&
        scoreCardRemainingWeight?.weight >= 0
      ) {
        request
          .post(category.post, newData)
          .then(() => {
            refreshHandlerCallback(id);
          })
          .catch((error) => {
            console.log("error ", error);
          });
      }

      if (updatedData && scoreCardRemainingWeight?.weight >= 0) {
        request
          .patch(category.patch(updatedData.id), updatedData)
          .then(() => {
            refreshHandlerCallback(id);
          })
          .catch((error) => {
            console.log("error ", error);
          });
      }

      if (weightUpdatedData && scoreCardRemainingWeight?.weight >= 0) {
        request
          .patch(category.patch(weightUpdatedData.id), weightUpdatedData)
          .then(() => {
            refreshHandlerCallback(id);
          })
          .catch((error) => {
            console.log("error ", error);
          });
      }
    },
    [
      dispatch,
      rowIndex,
      type,
      refreshHandlerCallback,
      scoreCardCategoryData,
      scoreCardRemainingWeight,
    ]
  );

  const cancelHandlerCallback = useCallback(
    (rowType: string | null, rowId: number | null, level: number) => {
      if (rowType === "new") {
        dispatch(removeScoreCardNewRow(level));
      }

      if (rowType === "edit") {
        let specificData = scoreCardCategoryData?.find(
          (element: any) => element.id === rowId
        );
        let copyState = [...scoreCardCategoryData];
        let indexOfSpecificData = copyState.indexOf(specificData);

        copyState[indexOfSpecificData] = recoveryData;
        dispatch(
          handleScoreCardCategoryData({ scoreCardCategoryData: copyState })
        );
        dispatch(handleScoreCardRowEditableStatus(level, rowId, false));
        dispatch(handleScoreCardRowWeigthEditableStatus(level, rowId, false));
      }

      if (rowType !== null) {
        dispatch(
          handleActiveTooltip({ childId: null, childDataId: null, type: null })
        );
        dispatch(
          handleScoreCardActiveRow({
            rowIndex: null,
            rowType: null,
            rowId: null,
          })
        );
        dispatch(handleScoreCardActiveElement({ name: null }));
        dispatch(handleScoreCardRecoveryData({ recoveryData: null }));
        dispatch(handleScoreCardBlockedModalResponse({ response: null }));
        dispatch(touchBlocked({ status: false }));
        dispatch(calculateScoreCardRemainingWeight(level, scoreCardId));
        dispatch(
          calculateScoreCardRemainingPoint({
            remainingPointLevel: level,
            remainingPointParentId: scoreCardId,
          })
        );
      }
    },
    [dispatch, recoveryData, scoreCardCategoryData, scoreCardId]
  );

  const deleteHandlerCallback = useCallback(
    (index: number | null, id: number | null, elementId: string | null) => {
      const {
        score_card: { category },
      } = endpoints;

      if (scoreCardActiveElement.name === null && type === null) {
        dispatch(handleScoreCardActiveElement({ name: elementId }));
        dispatch(
          handleScoreCardActiveRow({
            rowIndex: index,
            rowType: "delete",
            rowId: id,
          })
        );

        if (response === null) {
          dispatch(handleActiveModal({ activeModal: "delete_score_modal" }));
        }
      }

      if (response === "delete" && id !== null) {
        dispatch(handleScoreCardBlockedModalResponse({ response: null }));
        request
          .delete(category.delete(id))
          .then(() => {
            refreshHandlerCallback(scoreCardId);
          })
          .catch((error) => {
            console.log("error ", error);
          });
      }

      if (scoreCardActiveElement.name !== null && type !== "validation") {
        if (type === null)
          dispatch(
            handleActiveTooltip({
              childId: scoreCardActiveElement.name,
              childDataId: null,
              type: "saveChanges",
            })
          );
        if (type === "saveChanges") {
          dispatch(
            handleActiveTooltip({
              childId: null,
              childDataId: null,
              type: null,
            })
          );
          setTimeout(() => {
            dispatch(
              handleActiveTooltip({
                childId: scoreCardActiveElement.name,
                childDataId: null,
                type: "saveChanges",
              })
            );
          }, 50);
        }
      }
    },
    [
      dispatch,
      refreshHandlerCallback,
      response,
      scoreCardId,
      type,
      scoreCardActiveElement.name,
    ]
  );

  const keyboardHandlerCallback = useCallback(
    (event: any) => {
      const { keyCode } = event;

      if (keyCode === 27) {
        cancelHandlerCallback(rowType, rowId, level);
      }

      if (keyCode === 13) {
        applyHandlerCallback(scoreCardId);
      }
    },
    [
      level,
      rowType,
      rowId,
      applyHandlerCallback,
      cancelHandlerCallback,
      scoreCardId,
    ]
  );

  const mouseClickHandlerCallback = useCallback(
    (event: any) => {
      const {
        target: { localName, id },
      } = event;

      if (localName === "img" && id === "score_blocked_modal_close") {
        dispatch(handleScoreCardActiveElement({ name: null }));
        dispatch(
          handleScoreCardActiveRow({
            rowIndex: null,
            rowType: null,
            rowId: null,
          })
        );
      }
    },
    [dispatch]
  );

  useEffect(() => {
    window.addEventListener("click", mouseClickHandlerCallback);
    window.addEventListener("keydown", keyboardHandlerCallback);

    return () => {
      window.removeEventListener("click", mouseClickHandlerCallback);
      window.removeEventListener("keydown", keyboardHandlerCallback);
    };
  }, [type, dispatch, keyboardHandlerCallback, mouseClickHandlerCallback]);

  useEffect(() => {
    if (response === "save") {
      applyHandlerCallback(scoreCardId);
    }

    if (response === "cancel") {
      cancelHandlerCallback(rowType, rowId, level);
    }

    if (response === "delete") {
      deleteHandlerCallback(rowIndex, rowId, scoreCardActiveElement.name);
    }

    if (response === "cancel_delete") {
      dispatch(handleScoreCardActiveElement({ name: null }));
      dispatch(handleScoreCardBlockedModalResponse({ response: null }));
      dispatch(
        handleScoreCardActiveRow({ rowIndex: null, rowType: null, rowId: null })
      );
      dispatch(
        handleActiveTooltip({ childId: null, childDataId: null, type: null })
      );
    }
  }, [
    dispatch,
    response,
    scoreCardId,
    rowIndex,
    rowId,
    rowType,
    level,
    scoreCardActiveElement.name,
    deleteHandlerCallback,
    applyHandlerCallback,
    cancelHandlerCallback,
  ]);

  useEffect(() => {
  //dispatch(calculateScoreCardRemainingWeight(level, scoreCardId)); 
    dispatch(
      calculateScoreCardRemainingPoint({
        remainingPointLevel: level,
        remainingPointParentId: scoreCardId,
      })
    );
  }, [dispatch, level, scoreCardId]);

  useLayoutEffect(() => {
    if (scoreCardRemainingWeight.weight < 0) {
      dispatch(handleScoreCardRemainingWeightWarning(true));
    }
  }, [scoreCardRemainingWeight.weight, dispatch]);

  useLayoutEffect(() => {
    let timer: NodeJS.Timeout;

    if (weightWarningStatus) {
      timer = setTimeout(() => {
        dispatch(handleScoreCardRemainingWeightWarning(false));
      }, 3000);
    }

    return () => clearTimeout(timer);
  }, [weightWarningStatus, dispatch]);

  const numberTopArrowHandler = (
    id: number,
    level: number,
    inputName: string,
    elementId: string,
    index: number
  ) => {
    if (
      (scoreCardActiveElement.name === null ||
        scoreCardActiveElement.name === elementId ||
        rowIndex === index) &&
      type !== "validation"
    ) {
      if (scoreCardRemainingWeight?.weight > 0) {
        dispatch(handleScoreCardActiveElement({ name: elementId }));
        dispatch(touchBlocked({ status: true }));

        if (inputName === "weight" && rowType === null) {
          dispatch(handleScoreCardRowWeigthEditableStatus(level, id, true));
          dispatch(
            handleScoreCardActiveRow({
              rowIndex: index,
              rowType: "edit",
              rowId: id,
            })
          );

          if (recoveryData === null) {
            let specificData = scoreCardCategoryData.find(
              (element: any) => element.id === id
            );
            dispatch(
              handleScoreCardRecoveryData({ recoveryData: specificData })
            );
          }
        }

        let specificData = scoreCardCategoryData.find(
          (element: any) => element.id === id
        );
        let copyState = [...scoreCardCategoryData];
        let copyOfSpecificData = { ...specificData };
        let indexOfSpecificData = copyState.indexOf(specificData);
        let stepNumber =
          scoreCardRemainingWeight?.weight < 1
            ? scoreCardRemainingWeight?.weight
            : 1;

        copyOfSpecificData.weight = (
          +copyOfSpecificData.weight + stepNumber
        ).toFixed(2);

        copyState[indexOfSpecificData] = copyOfSpecificData;
        dispatch(
          handleScoreCardCategoryData({ scoreCardCategoryData: copyState })
        );
        dispatch(calculateScoreCardRemainingWeight(level, scoreCardId));
        dispatch(
          calculateScoreCardPoint({
            levelOfPointData: level,
            pointRowId: id,
            pointParentId: scoreCardId,
          })
        );
        dispatch(
          calculateScoreCardRemainingPoint({
            remainingPointLevel: level,
            remainingPointParentId: scoreCardId,
          })
        );
        dispatch(
          handleActiveTooltip({ childId: null, childDataId: null, type: null })
        );
      }

      if (scoreCardRemainingWeight?.weight === 0) {
        dispatch(handleScoreCardRemainingWeightWarning(true));
      }
    }

    if (
      scoreCardActiveElement.name !== elementId &&
      type !== "validation" &&
      rowIndex !== index
    ) {
      if (type === null)
        dispatch(
          handleActiveTooltip({
            childId: scoreCardActiveElement.name,
            childDataId: null,
            type: "saveChanges",
          })
        );
      if (type === "saveChanges") {
        dispatch(
          handleActiveTooltip({ childId: null, childDataId: null, type: null })
        );
        setTimeout(() => {
          dispatch(
            handleActiveTooltip({
              childId: scoreCardActiveElement.name,
              childDataId: null,
              type: "saveChanges",
            })
          );
        }, 50);
      }
    }
  };

  const numberBottomArrowHandler = (
    id: number,
    level: number,
    inputName: string,
    elementId: string,
    index: number
  ) => {
    if (
      (scoreCardActiveElement.name === null ||
        scoreCardActiveElement.name === elementId ||
        rowIndex === index) &&
      type !== "validation"
    ) {
      dispatch(handleScoreCardActiveElement({ name: elementId }));
      dispatch(touchBlocked({ status: true }));

      if (inputName === "weight" && rowType === null) {
        dispatch(handleScoreCardRowWeigthEditableStatus(level, id, true));
        dispatch(
          handleScoreCardActiveRow({
            rowIndex: index,
            rowType: "edit",
            rowId: id,
          })
        );

        if (recoveryData === null) {
          let specificData = scoreCardCategoryData.find(
            (element: any) => element.id === id
          );
          dispatch(handleScoreCardRecoveryData({ recoveryData: specificData }));
        }
      }

      let specificData = scoreCardCategoryData.find(
        (element: any) => element.id === id
      );
      let copyState = [...scoreCardCategoryData];
      let copyOfSpecificData = { ...specificData };
      let indexOfSpecificData = copyState.indexOf(specificData);

      copyOfSpecificData.weight =
        +copyOfSpecificData.weight > 1
          ? (+copyOfSpecificData.weight - 1).toFixed(2)
          : "0";

      copyState[indexOfSpecificData] = copyOfSpecificData;
      dispatch(
        handleScoreCardCategoryData({ scoreCardCategoryData: copyState })
      );
      dispatch(calculateScoreCardRemainingWeight(level, scoreCardId));
      dispatch(handleScoreCardRemainingWeightWarning(false));
      dispatch(
        calculateScoreCardPoint({
          levelOfPointData: level,
          pointRowId: id,
          pointParentId: scoreCardId,
        })
      );
      dispatch(
        calculateScoreCardRemainingPoint({
          remainingPointLevel: level,
          remainingPointParentId: scoreCardId,
        })
      );
      dispatch(
        handleActiveTooltip({ childId: null, childDataId: null, type: null })
      );
    }

    if (
      scoreCardActiveElement.name !== elementId &&
      type !== "validation" &&
      rowIndex !== index
    ) {
      if (type === null)
        dispatch(
          handleActiveTooltip({
            childId: scoreCardActiveElement.name,
            childDataId: null,
            type: "saveChanges",
          })
        );
      if (type === "saveChanges") {
        dispatch(
          handleActiveTooltip({ childId: null, childDataId: null, type: null })
        );
        setTimeout(() => {
          dispatch(
            handleActiveTooltip({
              childId: scoreCardActiveElement.name,
              childDataId: null,
              type: "saveChanges",
            })
          );
        }, 50);
      }
    }
  };

  const nextBtnHandler = (id: number | null, criteriaType: number | null) => {
    const {
      score_card: { criteria },
    } = endpoints;
    let activeTreeRef = activeTree;

    if (scoreCardActiveElement.name === null && type === null) {
      dispatch(
        handleScoreCardLevelDataId({
          scoreCardId: scoreCardId,
          categoryId: id,
          criteriaId: null,
          criteriaType,
        })
      );

      if (scoreCardCriteriaData === null) {
        request
          .get(criteria.getAll(id))
          .then((result) => {
            const { response } = result;
            dispatch(
              handleScoreCardCriteriaData({ scoreCardCriteriaData: response })
            );
            dispatch(handleScoreCardLevel({ level: 3 }));
          })
          .catch((error) => {
            console.log("error " + error);
          });
      }

      if (scoreCardCriteriaData !== null) {
        let childData = scoreCardCriteriaData.filter(
          (element: any) => element.score_card_category_id === id
        );

        if (childData.length === 0) {
          request
            .get(criteria.getAll(id))
            .then((result) => {
              const { response } = result;
              let combinedData = scoreCardCriteriaData.concat(response);
              dispatch(
                handleScoreCardCriteriaData({
                  scoreCardCriteriaData: combinedData,
                })
              );
              dispatch(handleScoreCardLevel({ level: 3 }));
            })
            .catch((error) => {
              console.log("error " + error);
            });
        }

        if (childData.length !== 0) {
          dispatch(handleScoreCardLevel({ level: 3 }));
        }
      }

      let category_data = scoreCardCategoryData?.filter((elem: any) => {
        return +elem?.id === id;
      });
      let mass = [...activeTreeRef, category_data[0]];
      dispatch(handleActiveTree({ activeTree: mass }));
    }

    if (scoreCardActiveElement.name !== null && type !== "validation") {
      if (type === null)
        dispatch(
          handleActiveTooltip({
            childId: scoreCardActiveElement.name,
            childDataId: null,
            type: "saveChanges",
          })
        );
      if (type === "saveChanges") {
        dispatch(
          handleActiveTooltip({ childId: null, childDataId: null, type: null })
        );
        setTimeout(() => {
          dispatch(
            handleActiveTooltip({
              childId: scoreCardActiveElement.name,
              childDataId: null,
              type: "saveChanges",
            })
          );
        }, 50);
      }
    }
  };

  const editHandler = (index: number, id: number, level: number) => {
    if (
      scoreCardActiveElement.name === null &&
      type === null &&
      rowType !== null
    ) {
      dispatch(handleScoreCardRowEditableStatus(level, rowId, false));
      if (rowType === "new") dispatch(removeScoreCardNewRow(level));
      dispatch(
        handleScoreCardActiveRow({
          rowIndex: index,
          rowType: "edit",
          rowId: id,
        })
      );
      dispatch(handleScoreCardRowEditableStatus(level, id, true));

      let specificData = scoreCardCategoryData?.find(
        (element: any) => element.id === id
      );

      dispatch(handleScoreCardRecoveryData({ recoveryData: specificData }));
    }

    if (
      scoreCardActiveElement.name === null &&
      type === null &&
      rowType === null
    ) {
      dispatch(handleScoreCardRowEditableStatus(level, id, true));

      let specificData = scoreCardCategoryData?.find(
        (element: any) => element.id === id
      );

      dispatch(handleScoreCardRecoveryData({ recoveryData: specificData }));
      dispatch(
        handleScoreCardActiveRow({
          rowIndex: index,
          rowType: "edit",
          rowId: id,
        })
      );
    }

    if (scoreCardActiveElement.name !== null && type !== "validation") {
      if (type === null)
        dispatch(
          handleActiveTooltip({
            childId: scoreCardActiveElement.name,
            childDataId: null,
            type: "saveChanges",
          })
        );
      if (type === "saveChanges") {
        dispatch(
          handleActiveTooltip({ childId: null, childDataId: null, type: null })
        );
        setTimeout(() => {
          dispatch(
            handleActiveTooltip({
              childId: scoreCardActiveElement.name,
              childDataId: null,
              type: "saveChanges",
            })
          );
        }, 50);
      }
    }
  };

  const nameHandler = (value: string, id: number, elementId: string) => {
    dispatch(handleScoreCardActiveElement({ name: elementId }));
    dispatch(touchBlocked({ status: true }));

    let specificData = scoreCardCategoryData.find(
      (element: any) => element.id === id
    );
    let copyState = [...scoreCardCategoryData];
    let copyOfSpecificData = { ...specificData };
    let indexOfSpecificData = copyState.indexOf(specificData);

    copyOfSpecificData.name = value;

    copyState[indexOfSpecificData] = copyOfSpecificData;
    dispatch(handleScoreCardCategoryData({ scoreCardCategoryData: copyState }));

    if (value && type === "validation") {
      dispatch(
        handleActiveTooltip({ childId: null, childDataId: null, type: null })
      );
    }
    if (!value) {
      dispatch(
        handleActiveTooltip({
          childId: elementId,
          childDataId: null,
          type: "validation",
        })
      );
    }
  };

  const weightHandler = (
    value: string,
    id: number,
    level: number,
    elementId: string,
    index: number
  ) => {
    if (
      (scoreCardActiveElement.name === elementId ||
        (scoreCardActiveElement.name === null || rowIndex === index)) &&
      type !== "validation"
    ) {
      if (rowType === null) {
        dispatch(handleScoreCardRowWeigthEditableStatus(level, id, true));
        dispatch(
          handleScoreCardActiveRow({
            rowIndex: index,
            rowType: "edit",
            rowId: id,
          })
        );
      }
      dispatch(handleScoreCardActiveElement({ name: elementId }));
      dispatch(touchBlocked({ status: true }));

      let specificData = scoreCardCategoryData.find(
        (element: any) => element.id === id
      );

      if (recoveryData === null)
        dispatch(handleScoreCardRecoveryData({ recoveryData: specificData }));

      let copyState = [...scoreCardCategoryData];
      let copyOfSpecificData = { ...specificData };
      let indexOfSpecificData = copyState.indexOf(specificData);

      copyOfSpecificData.weight =
        +value >= 0
          ? value.indexOf(".") > -1
            ? value.substring(0, value.indexOf(".") + 3)
            : value
          : "";

      copyState[indexOfSpecificData] = copyOfSpecificData;
      dispatch(
        handleScoreCardCategoryData({ scoreCardCategoryData: copyState })
      );
      dispatch(calculateScoreCardRemainingWeight(level, scoreCardId));
      dispatch(
        calculateScoreCardPoint({
          levelOfPointData: level,
          pointRowId: id,
          pointParentId: scoreCardId,
        })
      );
      dispatch(
        calculateScoreCardRemainingPoint({
          remainingPointLevel: level,
          remainingPointParentId: scoreCardId,
        })
      );
      dispatch(
        handleActiveTooltip({ childId: null, childDataId: null, type: null })
      );
    }

    if (
      scoreCardActiveElement.name !== elementId &&
      type !== "validation" &&
      rowIndex !== index
    ) {
      if (type === null)
        dispatch(
          handleActiveTooltip({
            childId: scoreCardActiveElement.name,
            childDataId: null,
            type: "saveChanges",
          })
        );
      if (type === "saveChanges") {
        dispatch(
          handleActiveTooltip({ childId: null, childDataId: null, type: null })
        );
        setTimeout(() => {
          dispatch(
            handleActiveTooltip({
              childId: scoreCardActiveElement.name,
              childDataId: null,
              type: "saveChanges",
            })
          );
        }, 50);
      }
    }
  };

  // const trustedWeightHandler = (
  //   value: boolean,
  //   id: number,
  //   elementId: string
  // ) => {
  //   dispatch(handleScoreCardActiveElement({ name: elementId }));
  //   dispatch(touchBlocked({ status: true }));

  //   let specificData = scoreCardCategoryData.find(
  //     (element: any) => element.id === id
  //   );
  //   let copyState = [...scoreCardCategoryData];
  //   let copyOfSpecificData = { ...specificData };
  //   let indexOfSpecificData = copyState.indexOf(specificData);

  //   copyOfSpecificData.is_trusted_weight = value;

  //   copyState[indexOfSpecificData] = copyOfSpecificData;
  //   dispatch(handleScoreCardCategoryData({ scoreCardCategoryData: copyState }));
  // };
  const trustedTopArrowHandler = (id:string, index: number) => {

    const typeName = document.getElementById(id)?.innerHTML;
    const presentIndex = criteriaTrusted.findIndex(
      (element: any) => element.key === typeName
    );
    const previousIndex = presentIndex - 1;

    const firstIndex = 0

    if (presentIndex === firstIndex) {
      return;
    }

    copyState = [...scoreCardCategoryData.filter(
        (element: any) => element.score_card_id === scoreCardId
    )];

    copyState[index].is_trusted_weight = criteriaTrusted[previousIndex]?.value;

    dispatch(handleScoreCardCategoryData({ scoreCardCategoryData: copyState }));
    dispatch(handleScoreCardActiveElement({ name: id }));
    dispatch(touchBlocked({ status: true }));
  };

  const trustedBottomArrowHandler = (id:string,index: number) => {
    
    const typeName = document.getElementById(id)?.innerHTML;
    const presentIndex = criteriaTrusted.findIndex(
      (element: any) => element.key === typeName
    );
    const nextIndex = presentIndex + 1;
    const lastIndex = criteriaTrusted.length - 1;

    if (presentIndex === lastIndex) {
      return;
    }

    copyState = [...scoreCardCategoryData.filter(
      (element: any) => element.score_card_id === scoreCardId
  )];

  copyState[index].is_trusted_weight = criteriaTrusted[nextIndex]?.value;

  dispatch(handleScoreCardCategoryData({ scoreCardCategoryData: copyState }));
  dispatch(handleScoreCardActiveElement({ name: id }));
  dispatch(touchBlocked({ status: true }));
  };
  return (
    <div className={classes.SecondLevel}>
      <div className={classes.Head}>
        <span className={classes.HeadColumn}>category name</span>
        <span className={classes.HeadColumn}>category weight</span>
        <span className={classes.HeadColumn}>category point</span>
        <span className={classes.HeadColumn}>category trusted</span>
        <span className={classes.HeadColumn}></span>
      </div>
      {scoreCardRemainingWeight?.weight > 0 && (
        <div className={classes.RemainingRow}>
          <div className={classes.RemainingColumn}>
            <span>default deficit</span>
          </div>
          <div className={classes.RemainingColumn}>
            <span>{scoreCardRemainingWeight.weight}</span>
          </div>
          <div className={classes.RemainingColumn}>
            <span>{scoreCardRemainingPoint.point}</span>
          </div>
        </div>
      )}
      {weightWarningStatus && (
        <div className={classes.WeightWarningRow}>
          <div className={classes.WeightWarningColumn}>
            <span>Category weight sum must be smaller or equal to 100</span>
          </div>
        </div>
      )}
      {scoreCardCategoryData?.filter(
        (element: any) => element.score_card_id === scoreCardId
      ).length > 0 ? (
        scoreCardCategoryData
          ?.filter((element: any) => element.score_card_id === scoreCardId)
          .map((element: any, index: number) => {
            const {
              id,
              name,
              weight,
              point,
              is_trusted_weight,
              newRow,
              editable,
              weightEditStatus,
            } = element;

            let weightTrimmed =
              weight.indexOf(".") > -1
                ? weight.substring(0, weight.indexOf(".") + 3)
                : weight;
            let pointTrimmed = parseFloat(point).toFixed(2);

            return (
              <div key={id ?? 0} className={classes.Row} id={`table_row_${id}`}>
                <div className={classes.Column}>
                  {editable ? (
                    <Tooltip
                      children={
                        <input
                          id={`category-name-${index}`}
                          type="text"
                          tabIndex={1}
                          defaultValue={name !== null ? name : undefined}
                          onChange={(event) =>
                            nameHandler(
                              event.target.value,
                              id,
                              `category-name-${index}`
                            )
                          }
                          onKeyDown={(e) => {
                            if (!e.key.match(/[a-zA-Z0-9_]/)) {
                              e.preventDefault();
                            }
                          }}
                          onPaste={(e) => {e.preventDefault(); return false;}}
                          onDrop={(e) => {e.preventDefault(); return false;}}
                          autoComplete="off"
                          disabled={!editable}
                        />
                      }
                    />
                  ) : (
                    <span
                      className={classes.NameLink}
                      onDoubleClick={() => nextBtnHandler(id, null)}
                    >
                      {name}
                    </span>
                  )}
                </div>
                <div className={classes.Column}>
                  <Tooltip
                    children={
                      <input
                        type="number"
                        id={`category-weight-${index}`}
                        tabIndex={2}
                        value={weightTrimmed}
                        onChange={(event) =>
                          weightHandler(
                            event.target.value,
                            id,
                            level,
                            `category-weight-${index}`,
                            index
                          )
                        }
                        disabled={type === "validation"}
                      />
                    }
                  />
                  <button
                    className={classes.ArrowTop}
                    onClick={() =>
                      numberTopArrowHandler(
                        id,
                        level,
                        "weight",
                        `category-weight-${index}`,
                        index
                      )
                    }
                    disabled={type === "validation"}
                  >
                    <img src={icons.arrowTop} alt="Arror top icon" />
                  </button>
                  <button
                    className={classes.ArrowBottom}
                    onClick={() =>
                      numberBottomArrowHandler(
                        id,
                        level,
                        "weight",
                        `category-weight-${index}`,
                        index
                      )
                    }
                    disabled={type === "validation"}
                  >
                    <img src={icons.arrowBottom} alt="Arror bottom icon" />
                  </button>
                </div>
                <div className={classes.Column}>
                  <span>{pointTrimmed}</span>
                </div>
                <div className={classes.Column}>
                  {/* <Tooltip
                    children={
                      <Checkbox
                        id={`category-trusted-weight-${index}`}
                        label={"Static"}
                        unCheckedPrefix={"Dynamic"}
                        value={is_trusted_weight}
                        onChange={(event) =>
                          trustedWeightHandler(
                            event,
                            id,
                            `category-trusted-weight-${index}`
                          )
                        }
                        disabled={!editable || type === "validation"}
                      />
                    }
                  /> */}
                  <Tooltip
                    children={
                      <div
                      id={`category-trusted-w-${index}`}
                      className={
                          editable 
                          // &&
                          // !scoreCardAtrributeAvailability.status &&
                          // type !== "validation"
                            ? [classes.CategoryTrusted, classes.Active].join(" ")
                            : classes.CategoryTrusted
                        }
                      >
                        {criteriaTrusted.find((element: any) => {
                          const { value } = element;
                          return  is_trusted_weight === value;
                        })?.key}
                      </div>
                    }
                  />
                  <button
                    className={classes.ArrowTop}
                    onClick={() =>
                      trustedTopArrowHandler(`category-trusted-w-${index}`,index)
                    }
                    disabled={
                      !editable
                      //  ||
                      // type === "validation" ||
                      // scoreCardAtrributeAvailability.status
                    }
                  >
                    <img src={icons.arrowTop} alt="Arrow top icon" />
                  </button>
                  <button
                    className={classes.ArrowBottom}
                    onClick={() =>
                      trustedBottomArrowHandler(`category-trusted-w-${index}`,index)
                    }
                    disabled={
                      !editable 
                      // ||
                      // type === "validation" ||
                      // scoreCardAtrributeAvailability.status
                    }
                  >
                    <img src={icons.arrowBottom} alt="Arrow bottom icon" />
                  </button>
                </div>
                <div className={classes.Actions}>
                  {newRow || editable || weightEditStatus ? (
                    <>
                      <Active
                        id={`score-apply-${index}`}
                        style={
                          scoreCardActiveElement.name === null ||
                          type === "validation" ||
                          scoreCardRemainingWeight.weight < 0
                            ? { opacity: 0, pointerEvents: "none" }
                            : { opacity: 1 }
                        }
                        handler={() => applyHandlerCallback(scoreCardId)}
                      />
                      <Cancel
                        id={`score-cancel-${index}`}
                        handler={() =>
                          cancelHandlerCallback(rowType, id, level)
                        }
                      />
                    </>
                  ) : (
                    <>
                      <Pen handler={() => editHandler(index, id, level)} />
                      <Delete
                        id={`delete-${index}`}
                        handler={() =>
                          deleteHandlerCallback(index, id, `delete-${index}`)
                        }
                      />
                      <NextArrowRight
                        id={`nextBtn-${index}`}
                        handler={() => nextBtnHandler(id, null)}
                      />
                    </>
                  )}
                </div>
              </div>
            );
          })
          // .reverse()
      ) : (
        <span className={classes.TableMessage}>No data to display</span>
      )}
    </div>
  );
};

export default CategoryLevel;
