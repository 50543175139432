import { arrowLoop } from "../findArrowLoop";
import { call_arrow_box_source, call_arrow_box_target } from "../remove_arrow_box.handler";
import { remove_arrow_db } from "../remove_element.handler";
import { decision_correctly_contact } from "./decision_correctly_contact.handler";
import { drag_arrow_destination } from "./drag_arrow.handler";

// type ArrowType={
//     top:string,
//     right:string,
//     bottom:string,
//     left:string
// }

type MousePosition={
    x:number;
    y:number
}


function mousePosition(e: MouseEvent):MousePosition {
    let svg = document.querySelector('#svg_board') as SVGSVGElement;

    let CTM = svg.getScreenCTM() as DOMMatrix

    return {
        x: (e.clientX - CTM.e) / CTM.a,
        y: (e.clientY - CTM.f) / CTM.d
    };
}

const removeArrowHandler = (id:string) => {
    const svg = document.querySelector("#svg_board") as SVGElement;

    if (id) {
      let find_arrows = svg.querySelectorAll(
        `path[data-contact $= "${id}"]`
      ) as NodeList;
      let find_arrows_target = svg.querySelectorAll(
        `path[data-contact ^= "${id}"]`
      ) as NodeList;

      remove_arrow_db(id);

      if (find_arrows?.length > 0) {
        svg.removeChild(find_arrows[0]);
        let find_arrow = find_arrows[0] as SVGPathElement;

        const id = find_arrow?.dataset.contact;
        if (id) {
          let find_text = svg.querySelectorAll(
            `text[id = "${id}"]`
          ) as NodeList;
          find_text?.forEach((text: any) => {
            svg.removeChild(text);
          });
        }

        call_arrow_box_source(find_arrows);
        if (find_arrows_target) {
          call_arrow_box_target(find_arrows_target);
        }
      }
    }
  };

export const drag_contact_destination = (arrow:SVGPathElement):void=>{
    const svg = document.querySelector(`#svg_board`) as SVGSVGElement;
    const side = arrow?.dataset?.side as string;
    const drawn = arrow?.getAttribute('d') as string;
    const split_drawn = drawn?.split(',') as string[];
 

    const zoom_percent_span = document.querySelector('#zoom_percent')! as HTMLSpanElement
    let percent = zoom_percent_span?.textContent?.split('%')?.[0] as string;
    let arrow_over = null as SVGRectElement | null;
    let overStatus = false as boolean;
    let upStatus = false as boolean;
    const mouseDown = (event_down:MouseEvent):void =>{
        overStatus = true;

        const target = event_down.target as SVGPathElement;
        let dragStatus = false as boolean;
        const {x:down_x,y:down_y} = event_down as MouseEvent;
        const {
            left:arrow_left,
            top:arrow_top,
            width:arrow_width,
            height:arrow_height
        } = target.getBoundingClientRect() as ClientRect;

           
        const [,_a1,,_a2] = split_drawn as string[];
        const a1_ = _a1?.split('v')?.[1] ? _a1?.split('v')?.[1] :_a1?.split('h')?.[1] as string;
        const a1 = Math.abs(+a1_) as number;
        const a2_ = _a2?.split('v')?.[1] ? _a2?.split('v')?.[1] :_a2?.split('h')?.[1] as string;
        const a2 = Math.abs(+a2_) as number;

        const mouseMove = (event_move:MouseEvent)=>{
            // event_down.target.style.cursor = 'grab'
            // let {x,y} = e_move;
            let coord = mousePosition(event_move);
            if(dragStatus){
                drag_arrow_destination(coord,target);
            }
            
        }

        const mouseUp = (event_up:MouseEvent)=>{
            document.removeEventListener('mousemove', mouseMove, false);
            document.removeEventListener('mousemove', mouseOver, false);
            overStatus = false
            if(arrow_over){

                if(!upStatus){
                    decision_correctly_contact(arrow_over,target);
                    arrow_over = null;
                }else{
                    const id = arrow?.dataset.contact as string;
                    removeArrowHandler(id)
                }
            }
            document.removeEventListener('mouseup',mouseUp,false);


        }
        const mouseOver = (event_over:any)=>{
            if (event_over.target.tagName === 'rect' && event_over.target.dataset.type !== 'startpoint') {
                arrow_over = event_over.target;
                if(overStatus){
                    const {status} = arrowLoop(arrow_over,arrow);
                    upStatus = status;
                }


            }else{
                arrow.setAttribute('stroke',"#788896");

            }
            if (event_over.target.tagName === 'use') {
                let id = event_over.target.getAttribute('xlink:href')?.split('#')[1];
                if (id !== 'startpoint') {
                    let find = svg.querySelector(`rect[data-id = ${id}]:not(use)`) as SVGRectElement;
                    if(find){
                        arrow_over = find;
                    }
                    
                } else {
                    console.log('alert')
                }

            }

        }
        const changeDragStatus = (status:boolean)=>{
            if(status){
                dragStatus = true;
            }else{
                dragStatus = false;
            }
        }
        switch(side){
            case 'top':
            case 'right-top':
            case 'left-top':
                const top_arrow_end = (down_y - arrow_top)/+percent*100 as number;
                const top_end_status = top_arrow_end <= 10;
                changeDragStatus(top_end_status)
                break;
            case 'right':
            case 'top-right':
            case 'bottom-right':
                const right_arrow_end = ((arrow_left+arrow_width) - down_x)/+percent*100 as number;
                const right_end_status = right_arrow_end <= 10;
                changeDragStatus(right_end_status)

                break;
            case 'bottom':
            case 'right-bottom':
            case 'left-bottom':
                const bottom_arrow_end = ((arrow_top+arrow_height) - down_y)/+percent*100 as number;
                const bottom_end_status = bottom_arrow_end <= 10;
                changeDragStatus(bottom_end_status)

                break;
            
            case 'left':
            case 'top-left':
            case 'bottom-left':
            
                const left_arrow_end = (down_x - arrow_left)/+percent*100 as number;
                const left_end_status = left_arrow_end <= 10;
                changeDragStatus(left_end_status)

                break;
            case 'top-right-bottom':
                if(a1>a2){
                    const top_right_bottom_arrow_end =Math.abs((down_y - arrow_top)/+percent*100) as number;
                    const left_pos = ((arrow_left+arrow_width) - down_x)/+percent*100 as number;
                    const left_pos_status = left_pos <= 12;
                    const top_right_bottom_end_status =left_pos_status && top_right_bottom_arrow_end >= 34;
                    changeDragStatus(top_right_bottom_end_status)

                }else{
                    const top_right_bottom_arrow_end =((arrow_top+arrow_height) - down_y)/+percent*100 as number;
                    const left_pos = ((arrow_left+arrow_width) - down_x)/+percent*100 as number;
                    const left_pos_status = left_pos <= 12;
                    const top_right_bottom_end_status =left_pos_status && top_right_bottom_arrow_end <= 10;
                    changeDragStatus(top_right_bottom_end_status)

                }
            break;
            case 'top-left-bottom':
               
                if(a1>a2){
                    const top_left_bottom_arrow_end =Math.abs((down_y - arrow_top)/+percent*100) as number;
                    const left_pos = (down_x-arrow_left)/+percent*100 as number;
                    const left_pos_status = left_pos <= 12;
                    const top_left_bottom_end_status =left_pos_status && top_left_bottom_arrow_end >= 34;
                    changeDragStatus(top_left_bottom_end_status)

                }else{
                    const top_left_bottom_arrow_end =((arrow_top+arrow_height) - down_y)/+percent*100 as number;
                    const left_pos = (down_x-arrow_left)/+percent*100 as number;
                    const left_pos_status = left_pos <= 12;
                    const top_left_bottom_end_status =left_pos_status && top_left_bottom_arrow_end <= 10;
                    changeDragStatus(top_left_bottom_end_status)

                }
            break;         
            case 'bottom-left-top':
               
                if(a1>a2){
                    const top_left_bottom_arrow_end =Math.abs((arrow_top+arrow_height-down_y)/+percent*100) as number;
                    const left_pos = (down_x-arrow_left)/+percent*100 as number;
                    const left_pos_status = left_pos <= 12;
                    const top_left_bottom_end_status =left_pos_status && top_left_bottom_arrow_end >= 34;
                    changeDragStatus(top_left_bottom_end_status)

                }else{
                    const top_left_bottom_arrow_end =((arrow_top+arrow_height) - down_y)/+percent*100 as number;
                    const left_pos = (down_x-arrow_left)/+percent*100 as number;
                    const left_pos_status = left_pos <= 12;
                    const top_left_bottom_end_status =left_pos_status && top_left_bottom_arrow_end >= 93;
                    changeDragStatus(top_left_bottom_end_status)

                }
            break;
            case 'bottom-right-top':
               
                if(a1>a2){
                    const top_left_bottom_arrow_end =Math.abs((arrow_top+arrow_height-down_y)/+percent*100) as number;
                    const left_pos = (down_x-arrow_left)/+percent*100 as number;
                    const left_pos_status = left_pos <= 12;
                    const top_left_bottom_end_status =left_pos_status && top_left_bottom_arrow_end >= 34;
                    changeDragStatus(top_left_bottom_end_status)

                }else{
                    const top_left_bottom_arrow_end =Math.abs((arrow_top+arrow_height - down_y)/+percent*100) as number;
                    const left_pos = (down_x-arrow_left)/+percent*100 as number;
                    const left_pos_status = left_pos <= 12;
                    const top_left_bottom_end_status =left_pos_status && top_left_bottom_arrow_end >= 64;
                    changeDragStatus(top_left_bottom_end_status)

                }
            break;
            case 'right-bottom-left':

                if(a1>a2){
                    const top_left_bottom_arrow_end =Math.abs((down_x - arrow_left)/+percent*100) as number;
                    const left_pos = Math.abs((arrow_top+arrow_height-down_y)/+percent*100) as number;
                    const left_pos_status = left_pos <= 12;
                    const top_left_bottom_end_status =left_pos_status && top_left_bottom_arrow_end <= 50;
                    changeDragStatus(top_left_bottom_end_status)

                }else{
                    const top_left_bottom_arrow_end =Math.abs((down_x - arrow_left)/+percent*100) as number;
                  
                    const top_left_bottom_end_status =top_left_bottom_arrow_end <= 10;
                    changeDragStatus(top_left_bottom_end_status)

                }
            break;         
            case 'right-top-left':

                if(a1>a2){
                    const top_left_bottom_arrow_end =Math.abs((down_x - arrow_left)/+percent*100) as number;
                    const left_pos = Math.abs((down_y-arrow_top)/+percent*100) as number;
                    const left_pos_status = left_pos <= 12;
                    const top_left_bottom_end_status =left_pos_status && top_left_bottom_arrow_end <= 34;
                    changeDragStatus(top_left_bottom_end_status)

                }else{
                    const top_left_bottom_arrow_end =Math.abs((down_x - arrow_left)/+percent*100) as number;
                  
                    const top_left_bottom_end_status =top_left_bottom_arrow_end <= 10;
                    changeDragStatus(top_left_bottom_end_status)

                }
            break;  
            case 'left-top-right':

                if(a1>a2){
                    const top_left_bottom_arrow_end =Math.abs((down_x - arrow_left)/+percent*100) as number;
                    const left_pos = Math.abs((down_y-arrow_top)/+percent*100) as number;
                    const left_pos_status = left_pos <= 12;
                    const top_left_bottom_end_status =left_pos_status && top_left_bottom_arrow_end >= 34;
                    changeDragStatus(top_left_bottom_end_status)

                }else{
                    const top_left_bottom_arrow_end =((arrow_left+arrow_width) - down_x)/+percent*100 as number;
                  
                    const top_left_bottom_end_status =top_left_bottom_arrow_end <= 10;
                    changeDragStatus(top_left_bottom_end_status)

                }
            break;
            case 'left-bottom-right':

                if(a1>a2){
                    const top_left_bottom_arrow_end =Math.abs((down_x - arrow_left)/+percent*100) as number;
                    const left_pos = Math.abs((down_y-(arrow_top+arrow_height))/+percent*100) as number;
                    const left_pos_status = left_pos <= 12;
                    const top_left_bottom_end_status =left_pos_status && top_left_bottom_arrow_end >= 34;
                    changeDragStatus(top_left_bottom_end_status)

                }else{
                    const top_left_bottom_arrow_end =((arrow_left+arrow_width) - down_x)/+percent*100 as number;
                  
                    const top_left_bottom_end_status =top_left_bottom_arrow_end <= 10;
                    changeDragStatus(top_left_bottom_end_status)

                }
            break;      
          
            default:
                break;
        }
        document.addEventListener('mousemove',mouseMove)
        document.addEventListener('mouseup',mouseUp)
        document.addEventListener('mouseover',mouseOver)
        
    }
  
    arrow.addEventListener('mousedown',mouseDown)
}

