import { StrategyActionsTypes, StrategyElementTooltipStatus, STRATEGY_ELEMENT_TOOLTIP_STATUS } from "../../../../types/typesStrategy";


const initialState: StrategyElementTooltipStatus = {
    status:null
}

const reducer = (state = initialState, action: StrategyActionsTypes): StrategyElementTooltipStatus => {

    switch (action.type) {

        case STRATEGY_ELEMENT_TOOLTIP_STATUS:
            const {status} = action.payload;
            
            return {
                status:status
            }

        default:
            return state

    }
}

export default reducer