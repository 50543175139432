import React, {
    FC,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
  } from "react";
  import { useDebounce } from 'use-debounce/lib';
  import DB from '../../../layout/DB/Storage';
  import {
    EditorKeySources,
    messages,
    storageKeys,
  } from '../../../settings/settings';
  import classes from './DataViewer.module.scss';
  import scssVariables from '../../../assets/scss/variables.module.scss';
  import { TooltipInfo } from '../Tooltip/InfoTooltip/InfoTooltip';

  interface IDataViewer {
    fullHeight?: boolean;
    disabledGroups?: string[];
    presentVariableId?: string;
  }

  interface IGroupState {
    name: EditorKeySources;
    extended: boolean;
  }

  const DataViewer: FC<IDataViewer> = ({
    fullHeight = false,
    disabledGroups,
    presentVariableId,
  }) => {
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const bodyRef = useRef<HTMLDivElement | null>(null);

    const [groupState, setGroupState] = useState<Array<IGroupState>>([]);
    const [reformattedTreeDataState, setReformattedTreeDataState] = useState<
      Array<object>
    >([]);
    const [reformattedVariablesState, setReformattedVariablesState] = useState<
      Array<string>
    >([]);
    const [reformattedMapsState, setReformattedMapsState] = useState<
      Array<string>
    >([]);
    const [reformattedDecisionState, setReformattedDecisionState] = useState<
      Array<object>
    >([]);
    const [reformattedScoreCardState, setReformattedScoreCardState] = useState<
      Array<object>
    >([]);
    const [reformattedProductState, setReformattedProductState] = useState<
      Array<string>
    >([]);
    const [searched, setSearched] = useState<string>('');
    const [previousSearch, setPreviousSearch] = useState<string>();
    const [searchResult, setSearchResult] = useState<Array<object>>([]);
    let [searchString] = useDebounce(searched, 1000);
    const [isOpenTooltipInfo, setIsOpenTooltipInfo] = useState(false);

    const getReformattedSourceData = useCallback(() => {
      const reformattedSourceJSON = sessionStorage.getItem(
        storageKeys.reformattedSourceData
      );

      if (!reformattedSourceJSON) return;

      setReformattedTreeDataState(JSON.parse(reformattedSourceJSON));
    }, []);

    const updateTreeData = useCallback((data: Array<object>) => {
      setReformattedTreeDataState(data);
      sessionStorage.setItem(
        storageKeys.reformattedSourceData,
        JSON.stringify(data)
      );
    }, []);

    const generateInitialGroups = useCallback(() => {
      let initialGroupState: Array<IGroupState> = [
        { name: EditorKeySources.INPUT, extended: false },
        { name: EditorKeySources.VARIABLE, extended: false },
        { name: EditorKeySources.MAP, extended: false },
        { name: EditorKeySources.DECISIONS, extended: false },
        { name: EditorKeySources.PRODUCTS, extended: false },
      ];

      if (disabledGroups) {
        let newGroupState = initialGroupState.filter((group: IGroupState) => {
          const { name } = group;

          return !disabledGroups.includes(name);
        });

        setGroupState([...newGroupState]);
      } else {
        setGroupState([...initialGroupState]);
      }
    }, [disabledGroups]);

    const reformatVariablesData = useCallback(
      (elementsArray: Array<object>) => {
        if (disabledGroups?.includes('variables')) return;

        const functionArrays: Array<string> = elementsArray
          ?.filter((element: any) => {
            const { type } = element;

            return type === 'func';
          })
          .filter((element: any) => {
            const {
              data: { id },
            } = element;

            if (!presentVariableId) {
              return element;
            }

            return presentVariableId !== id;
          })
          .map((element: any) => {
            const {
              data: { resultVar },
            } = element;

            return resultVar;
          });

        setReformattedVariablesState(functionArrays);
      },
      [disabledGroups, presentVariableId]
    );

    const reformatMapsData = useCallback(
      (elementsArray: Array<object>) => {
        if (disabledGroups?.includes('scorecard')) return;

        const mapsArrays: Array<string> = elementsArray
          ?.filter((element: any) => {
            const { type } = element;

            return type === 'scorecard';
          })
          .filter((element: any) => {
            const {
              data: { id },
            } = element;
            if (!presentVariableId) {
              return element;
            }

            return presentVariableId !== id;
          })
          .map((element: any) => {
            const {
              data: { label },
            } = element;

            return label;
          });
        setReformattedMapsState(mapsArrays);
      },
      [disabledGroups, presentVariableId]
    );

    const reformatDecisionData = useCallback(
      (elementsArray: Array<object>) => {
        if (disabledGroups?.includes('decision')) return;

        const decisionArrays: Array<object> = elementsArray
          ?.filter((element: any) => {
            const { type } = element;

            return type === 'decision';
          })
          .filter((element: any) => {
            const {
              data: { id },
            } = element;
            if (!presentVariableId) {
              return element;
            }

            return presentVariableId !== id;
          })
          .map((element: any) => {
            const {
              data: { id, label },
            } = element;

            let obj: any = {
              childrenKeys: ['label', 'alias', 'status'],
              extendable: true,
              extended: false,
              id: id,
              key: label,
              parent: label,
              type: 'object',
              value: [
                {
                  childrenKeys: null,
                  extendable: false,
                  extended: false,
                  id: id,
                  key: 'label',
                  parent: label,
                  type: 'string',
                  value: `${label}.label`,
                },
                {
                  childrenKeys: null,
                  extendable: false,
                  extended: false,
                  id: id,
                  key: 'alias',
                  parent: label,
                  type: 'string',
                  value: `${label}.alias`,
                },
                {
                  childrenKeys: null,
                  extendable: false,
                  extended: false,
                  id: id,
                  key: 'status',
                  parent: label,
                  type: 'string',
                  value: `${label}.status`,
                },
              ],
            };

            return obj;
          });

        setReformattedDecisionState(decisionArrays);
      },
      [disabledGroups, presentVariableId]
    );

    const reformatScoreCardData = useCallback(
      (elementsArray: Array<object>) => {
        if (disabledGroups?.includes('scorecard')) return;

        const decisionArrays: Array<object> = elementsArray
          ?.filter((element: any) => {
            const { type } = element;

            return type === 'scorecard';
          })
          .map((element: any) => {
            const {
              data: { id, label },
            } = element;

            let obj: any = {
              childrenKeys: ['point', 'log'],
              extendable: true,
              extended: false,
              id: id,
              key: label,
              parent: label,
              type: 'object',
              value: [
                {
                  childrenKeys: null,
                  extendable: false,
                  extended: false,
                  id: id,
                  key: 'point',
                  parent: label,
                  type: 'string',
                  value: `${label}.point`,
                },
                {
                  childrenKeys: null,
                  extendable: false,
                  extended: false,
                  id: id,
                  key: 'log',
                  parent: label,
                  type: 'string',
                  value: `${label}.log`,
                },
              ],
            };

            return obj;
          });
        setReformattedScoreCardState(decisionArrays);
      },
      [disabledGroups]
    );
    const reformatProductData = useCallback(
      (elementsArray: Array<object>) => {
        if (disabledGroups?.includes('product')) return;

        const productArrays: Array<string> = elementsArray
          ?.filter((element: any) => {
            const { type } = element;

            return type === 'product';
          })
          .filter((element: any) => {
            const {
              data: { id },
            } = element;

            if (!presentVariableId) {
              return element;
            }

            return presentVariableId !== id;
          })
          .map((element: any) => {
            const {
              data: { label },
            } = element;

            return label;
          });
        setReformattedProductState(productArrays);
      },
      [disabledGroups, presentVariableId]
    );
    const fetchElementsData = useCallback(() => {
      const db = new DB(storageKeys.boardElements);

      db.fetchAll()
        .then((result: any) => {
          const { status, data } = result;
          if (status) {
            reformatVariablesData(data);
            reformatMapsData(data);
            reformatDecisionData(data);
            reformatScoreCardData(data);
            reformatProductData(data);
          } else {
            console.warn(messages.messageStorageFetchStatusFalse);
          }
        })
        .catch((error) => {
          console.error(messages.messageStorageFetchError, error);
        });
    }, [
      reformatVariablesData,
      reformatMapsData,
      reformatDecisionData,
      reformatScoreCardData,
      reformatProductData,
    ]);

    const generateSearchResult = useCallback(
      (
        searchString: string | undefined,
        previousSearchString: string | undefined,
        treeData: Array<any> | undefined | null,
        variablesData: Array<string> | undefined | null,
        mapsData: Array<string> | undefined | null,
        decisionData: Array<object> | undefined | null,
        productData: Array<string> | undefined | null,
        searchResult: Array<object>
      ) => {
        function findMatchedTreeKey(
          treeData: Array<any>,
          keySearchRegex: RegExp,
          valueSearchRegex: RegExp,
          initialParent: string = 'root'
        ) {
          let result: Array<object> = [];

          for (let index = 0; index < treeData.length; index++) {
            const { key, value, extendable } = treeData[index];

            const keyFound = keySearchRegex.test(key);
            const valueFound = valueSearchRegex.test(value);

            if (keyFound || valueFound) {
              result.push({
                name: key ? key : `[${index}]`,
                value: !extendable && value,
                parent: initialParent,
                type: 'input',
              });
            }

            if (extendable) {
              result.push(
                ...findMatchedTreeKey(
                  value,
                  keySearchRegex,
                  valueSearchRegex,
                  key ? `${initialParent}.${key}` : `${initialParent}[${index}]`
                )
              );
            }
          }
          return result;
        }

        function findMatchedVariables(
          variablesData: Array<any>,
          keySearchRegex: RegExp
        ) {
          let result: Array<object> = [];

          variablesData.forEach((variable: string) => {
            const found = keySearchRegex.test(variable);

            if (found) {
              result.push({
                name: variable,
                type: 'variable',
              });
            }
          });

          return result;
        }
        function findMatchedMaps(mapsData: Array<any>, keySearchRegex: RegExp) {
          let result: Array<object> = [];

          mapsData.forEach((map: string) => {
            const found = keySearchRegex.test(map);

            if (found) {
              result.push(
                {
                  name: map,
                  type: 'scorecard',
                },
                {
                  name: `${map}.point`,
                  type: 'scorecard',
                },
                {
                  name: `${map}.log`,
                  type: 'scorecard',
                }
              );
            }
          });

          return result;
        }
        function findMatchedDecisions(
          decisionsData: Array<any>,
          keySearchRegex: RegExp
        ) {
          let result: Array<object> = [];

          decisionsData.forEach((decision: string) => {
            if (typeof decision === 'object') {
              const { key } = decision;
              const found = keySearchRegex.test(key);
              if (found) {
                result.push(
                  {
                    name: key,
                    type: 'decision',
                  },
                  {
                    name: `${key}.label`,
                    type: 'decision',
                  },
                  {
                    name: `${key}.alias`,
                    type: 'decision',
                  },
                  {
                    name: `${key}.status`,
                    type: 'decision',
                  }
                );
              }
            } else if (typeof decision === 'string') {
              const found = keySearchRegex.test(decision);
              if (found) {
                result.push({
                  name: decision,
                  type: 'decision',
                });
              }
            }
          });

          return result;
        }
        function findMatchedProducts(
          productsData: Array<any>,
          keySearchRegex: RegExp
        ) {
          let result: Array<object> = [];

          productsData.forEach((product: string) => {
            const found = keySearchRegex.test(product);

            if (found) {
              result.push({
                name: product,
                type: 'product',
              });
            }
          });

          return result;
        }
        if (
          searchString &&
          treeData &&
          variablesData &&
          mapsData &&
          decisionData &&
          productData &&
          searchString !== previousSearchString
        ) {
          let result: Array<any> = [];
          const keySearchRegex = new RegExp(`^${searchString}`, 'i');
          const valueSearchRegex = new RegExp(`${searchString}`, 'i');

          result = result.concat(
            findMatchedTreeKey(treeData, keySearchRegex, valueSearchRegex),
            findMatchedVariables(variablesData, keySearchRegex),
            findMatchedMaps(mapsData, keySearchRegex),
            findMatchedDecisions(decisionData, keySearchRegex),
            findMatchedProducts(productData, keySearchRegex)
          );

          setPreviousSearch(searchString);
          setSearchResult(result);
        } else if (!searchString && searchResult.length) {
          setSearchResult([]);
          setPreviousSearch(undefined);
        }
      },
      []
    );
    const checkHeightProp = useCallback(() => {
      const wrapperElement = wrapperRef.current;
      const bodyElement = bodyRef.current;

      if (fullHeight && wrapperElement && bodyElement) {
        wrapperElement.classList.add(classes.FullWrapperHeight);
        bodyElement.classList.add(classes.FullBodyHeight);
      } else if (
        !fullHeight &&
        wrapperElement &&
        bodyElement &&
        wrapperElement.classList.contains(classes.FullWrapperHeight) &&
        bodyElement.classList.contains(classes.FullBodyHeight)
      ) {
        wrapperElement.classList.remove(classes.FullWrapperHeight);
        bodyElement.classList.remove(classes.FullBodyHeight);
      }
    }, [fullHeight]);

    const clearSearch = useCallback(() => {
      setSearched('');
    }, []);

    useLayoutEffect(getReformattedSourceData, [getReformattedSourceData]);

    useLayoutEffect(fetchElementsData, [fetchElementsData]);

    useLayoutEffect(generateInitialGroups, [generateInitialGroups]);

    useLayoutEffect(checkHeightProp, [checkHeightProp]);

    useEffect(() => {
      generateSearchResult(
        searchString,
        previousSearch,
        reformattedTreeDataState,
        reformattedVariablesState,
        reformattedMapsState,
        reformattedDecisionState,
        reformattedProductState,
        searchResult
      );
    }, [
      generateSearchResult,
      searchString,
      previousSearch,
      reformattedTreeDataState,
      reformattedVariablesState,
      searchResult,
      reformattedDecisionState,
      reformattedMapsState,
      reformattedProductState,
    ]);

    const keyRow = (
      id: string,
      key: string,
      parentPath: string,
      data_type: string = ''
    ) => {
      const data = parentPath.length > 0 ? `${parentPath}.${key}` : key;
      // const isList = parentPath.match(/(?<=\[).*?(?=\])/g) ? true :false; //list icindekiler drag olunmayacaq olarsa aktivlesdir
      return (
        <span
          onDragStart={(event: any) => {
            event.dataTransfer.setData(
              'dragging_source',
              JSON.stringify({ source: EditorKeySources.INPUT, key: data })
            );
          }}
          onClick={() => {
            updateTreeData(handleExtendStatus(reformattedTreeDataState, id));
          }}
          // draggable={data_type === 'array' || isList ? false:true} //list icindekiler drag olunmayacaq olarsa aktivlesdir
          draggable
        >
          {key}
        </span>
      );
    };

    const valueRow = (
      value: string,
      drag: boolean,
      key?: string,
      parentPath?: string
    ) => {
      if (!drag) {
        return <span>{value}</span>;
      }

      const data =
        parentPath && parentPath.length > 0 ? `${parentPath}.${key}` : key;

      return (
        <span
          onDragStart={(event: any) => {
            event.dataTransfer.setData(
              'dragging_source',
              JSON.stringify({ source: EditorKeySources.INPUT, key: data })
            );
          }}
          draggable
        >
          {value}
        </span>
      );
    };

    const arrayRow = (value: Array<any>, extendStatus: boolean) => {
      if (!extendStatus) {
        return <span>[{value.length}]</span>;
      }

      return <>{generateArrayRows(value)}</>;
    };

    const objectRow = (
      value: Array<any>,
      parentPath: string,
      extendStatus: boolean,
      valueIndex: number,
      arrayChild: boolean
    ) => {
      if (extendStatus) {
        return <>{generateObjectRows(value)}</>;
      }
      if (!extendStatus && arrayChild) {
        const data = `${parentPath}[${valueIndex}]`;

        return (
          <span
            draggable={!arrayChild}
            onDragStart={(event: any) => {
              event.dataTransfer.setData(
                'dragging_source',
                JSON.stringify({ source: EditorKeySources.INPUT, key: data })
              );
            }}
          >
            &#123;{value.length}&#125;
          </span>
        );
      }
      if (!extendStatus && !arrayChild) {
        return <span>&#123;{value.length}&#125;</span>;
      }
    };

    const handleGroupExtendStatus = (groupName: string, groupIndex: number) => {
      const copyOfState = [...groupState];
      const specificElement: any = copyOfState?.find((group: any) => {
        const { name } = group;

        return name === groupName;
      });

      if (specificElement) {
        specificElement.extended = !specificElement.extended;
      }

      copyOfState[groupIndex] = { ...specificElement };
      setGroupState([...copyOfState]);
    };

    const handleExtendStatus = (dataArray: Array<any>, uniqueId: string) => {
      const copyOfData = [...dataArray];
      let specificRowIndex: number;

      const specificRow: any = copyOfData.find((element: any) => {
        const { id, extendable } = element;
        return id === uniqueId && extendable;
      });

      if (specificRow) {
        specificRowIndex = copyOfData.indexOf(specificRow);
        specificRow.extended = !specificRow.extended;
        copyOfData[specificRowIndex] = specificRow;
      } else {
        const availableChildren = copyOfData.filter((element: any) => {
          const { extendable } = element;
          return extendable === true;
        });

        availableChildren.forEach((element: any) => {
          const { value } = element;
          specificRowIndex = copyOfData.indexOf(element);
          copyOfData[specificRowIndex].value = handleExtendStatus(
            value,
            uniqueId
          );
        });
      }

      return copyOfData;
    };

    const generateObjectRows = (objectData: Array<object>) => {
      return objectData.map((fieldObjects: any, index: number) => {
        const { id, key, value, parent, type, extendable, extended } =
          fieldObjects;

        return (
          <div
            key={index}
            className={classes.DataRow}
            title={`${key} : ${
              type !== 'array' && type !== 'object' ? value : type
            }`}
          >
            <svg
              className={`${extended ? classes.ActiveArrow : ''}`}
              width='6'
              height='9'
              viewBox='0 0 6 9'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              onClick={() => {
                updateTreeData(
                  handleExtendStatus(reformattedTreeDataState, id)
                );
              }}
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.22392 5.33569C5.53718 5.05723 5.53718 4.56777 5.22392 4.28931L1.35255 0.848104C0.901123 0.446832 0.1875 0.767297 0.1875 1.37129L0.1875 8.25371C0.1875 8.8577 0.901124 9.17817 1.35255 8.7769L5.22392 5.33569Z'
                fill={
                  extendable
                    ? scssVariables.colorIconBlue
                    : scssVariables.colorIconGray
                }
              />
            </svg>
            {keyRow(id, key, parent, type)}
            {' : '}
            {type === 'array'
              ? arrayRow(value, extended)
              : type === 'object'
              ? objectRow(value, parent, extended, index, false)
              : valueRow(value, true, key, parent)}
          </div>
        );
      });
    };

    const generateArrayRows = (arrayData: Array<any>) => {
      return arrayData.map((fieldObjects: any, index: number) => {
        const { id, value, parent, type, extendable, extended } = fieldObjects;
        return (
          <div key={index} className={classes.DataRow}>
            <svg
              className={`${extended ? classes.ActiveArrow : ''}`}
              width='6'
              height='9'
              viewBox='0 0 6 9'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              onClick={() => {
                updateTreeData(
                  handleExtendStatus(reformattedTreeDataState, id)
                );
              }}
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.22392 5.33569C5.53718 5.05723 5.53718 4.56777 5.22392 4.28931L1.35255 0.848104C0.901123 0.446832 0.1875 0.767297 0.1875 1.37129L0.1875 8.25371C0.1875 8.8577 0.901124 9.17817 1.35255 8.7769L5.22392 5.33569Z'
                fill={
                  extendable
                    ? scssVariables.colorIconBlue
                    : scssVariables.colorIconGray
                }
              />
            </svg>
            {type === 'array'
              ? arrayRow(value, extended)
              : type === 'object'
              ? objectRow(value, parent, extended, index, true)
              : valueRow(value, true, 'key', parent)}
          </div>
        );
      });
    };

    const generateVariableRows = (variableData: Array<string>) => {
      return variableData?.map((variable: string, index: number) => {
        return (
          <div
            key={index}
            className={classes.Row}
            draggable
            onDragStart={(event: any) => {
              const { target } = event;
              const targetElement = target as HTMLDivElement;

              targetElement?.classList.add(classes.Drag);
              event.dataTransfer.setData(
                'dragging_variable',
                JSON.stringify({
                  source: EditorKeySources.VARIABLE,
                  key: variable,
                })
              );
            }}
            onDragEnd={(event: any) => {
              const { target } = event;
              const targetElement = target as HTMLDivElement;

              targetElement?.classList.remove(classes.Drag);
            }}
          >
            {valueRow(variable, false)}
          </div>
        );
      });
    };

    // const generateMapsRows = (maps: Array<string>) => {
    //   return maps?.map((map: string, index: number) => {
    //     return (
    //       <div
    //         key={index}
    //         className={classes.Row}
    //         draggable
    //         onDragStart={(event: any) => {
    //           const { target } = event;
    //           const targetElement = target as HTMLDivElement;

    //           targetElement?.classList.add(classes.Dragging);
    //           event.dataTransfer.setData(
    //             "dragging_map",
    //             JSON.stringify({ source: EditorKeySources.MAP, key: map })
    //           );
    //         }}
    //         onDragEnd={(event: any) => {
    //           const { target } = event;
    //           const targetElement = target as HTMLDivElement;

    //           targetElement?.classList.remove(classes.Dragging);
    //         }}
    //       >
    //         {valueRow(map, false)}
    //       </div>
    //     );
    //   });
    // };

    const generateDecisionRows = (objectData: Array<object>) => {
      return objectData.map((fieldObjects: any, index: number) => {
        const { id, key, value, parent, type, extendable, extended } =
          fieldObjects;

        return (
          <div
            key={index}
            className={classes.DataRow}
            title={`${key} : ${
              type !== 'array' && type !== 'object' ? value : type
            }`}
          >
            <svg
              className={`${extended ? classes.ActiveArrow : ''}`}
              width='6'
              height='9'
              viewBox='0 0 6 9'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              onClick={() =>
                setReformattedDecisionState(
                  handleExtendStatus(reformattedDecisionState, id)
                )
              }
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.22392 5.33569C5.53718 5.05723 5.53718 4.56777 5.22392 4.28931L1.35255 0.848104C0.901123 0.446832 0.1875 0.767297 0.1875 1.37129L0.1875 8.25371C0.1875 8.8577 0.901124 9.17817 1.35255 8.7769L5.22392 5.33569Z'
                fill={
                  extendable
                    ? scssVariables.colorIconBlue
                    : scssVariables.colorIconGray
                }
              />
            </svg>
            {keyRow(id, key, parent, fieldObjects)}
            {' : '}
            {type === 'array'
              ? arrayRow(value, extended)
              : type === 'object'
              ? objectRow(value, parent, extended, index, true)
              : valueRow(value, type)}
          </div>
        );
      });
    };

    const generateScoreCardRows = (objectData: Array<object>) => {
      return objectData.map((fieldObjects: any, index: number) => {
        const { id, key, value, parent, type, extendable, extended } =
          fieldObjects;

        return (
          <div
            key={index}
            className={classes.DataRow}
            title={`${key} : ${
              type !== 'array' && type !== 'object' ? value : type
            }`}
          >
            <svg
              className={`${extended ? classes.ActiveArrow : ''}`}
              width='6'
              height='9'
              viewBox='0 0 6 9'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              onClick={() =>
                setReformattedScoreCardState(
                  handleExtendStatus(reformattedScoreCardState, id)
                )
              }
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.22392 5.33569C5.53718 5.05723 5.53718 4.56777 5.22392 4.28931L1.35255 0.848104C0.901123 0.446832 0.1875 0.767297 0.1875 1.37129L0.1875 8.25371C0.1875 8.8577 0.901124 9.17817 1.35255 8.7769L5.22392 5.33569Z'
                fill={
                  extendable
                    ? scssVariables.colorIconBlue
                    : scssVariables.colorIconGray
                }
              />
            </svg>
            {keyRow(id, key, parent, fieldObjects)}
            {' : '}
            {type === 'array'
              ? arrayRow(value, extended)
              : type === 'object'
              ? objectRow(value, parent, extended, index, true)
              : valueRow(value, type)}
          </div>
        );
      });
    };

    const generateProductRows = (productData: Array<string>) => {
      return productData?.map((decision: string, index: number) => {
        return (
          <div
            key={index}
            className={classes.Row}
            draggable
            onDragStart={(event: any) => {
              const { target } = event;
              const targetElement = target as HTMLDivElement;

              targetElement?.classList.add(classes.Drag);
              event.dataTransfer.setData(
                'dragging_product',
                JSON.stringify({
                  source: EditorKeySources.PRODUCTS,
                  key: decision,
                })
              );
            }}
            onDragEnd={(event: any) => {
              const { target } = event;
              const targetElement = target as HTMLDivElement;

              targetElement?.classList.remove(classes.Drag);
            }}
          >
            {valueRow(decision, false)}
          </div>
        );
      });
    };
    const generateGroupRows = (groupList: Array<any>) => {
      return groupList.map((group: any, index: number) => {
        const { name, extended } = group;
        const extendable: { [key: string]: JSX.Element[] } = {
          Input: generateObjectRows(reformattedTreeDataState),
          Variable: generateVariableRows(reformattedVariablesState),
          'Score Cards': generateScoreCardRows(reformattedScoreCardState),
          Decisions: generateDecisionRows(reformattedDecisionState),
          Products: generateProductRows(reformattedProductState),
        };
        return (
          <div
            key={index}
            className={`${classes.GroupRow} ${
              extended ? classes.ActiveGroup : ''
            }`}
          >
            <div
              className={classes.GroupHeader}
              onClick={() => handleGroupExtendStatus(name, index)}
            >
              <span className={classes.GroupName}>{name}</span>
              <svg
                viewBox='0 0 7 5'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M3.66996 3.94462L6.69982 0.914706C6.76996 0.844628 6.80859 0.75108 6.80859 0.651331C6.80859 0.551583 6.76996 0.458035 6.69982 0.387957L6.47675 0.164824C6.33139 0.0196303 6.09513 0.0196303 5.95 0.164824L3.4057 2.70912L0.85858 0.162001C0.788446 0.0919228 0.694953 0.0532303 0.595261 0.0532303C0.495457 0.0532303 0.401964 0.0919228 0.331775 0.162001L0.108753 0.385134C0.0386195 0.455267 -1.7643e-05 0.54876 -1.7643e-05 0.648508C-1.7643e-05 0.748257 0.0386195 0.841805 0.108753 0.911883L3.14138 3.94462C3.21174 4.01487 3.30567 4.05345 3.40553 4.05323C3.50578 4.05345 3.59966 4.01487 3.66996 3.94462Z' />
              </svg>
            </div>
            {extended && extendable[name]}
          </div>
        );
      });
    };

    const handleSearchChange = (event: any) => {
      const {
        target: { value },
      } = event;

      setSearched(value);
    };

    const generateSearchResultRows = (searchResult: Array<object>) => {
      return searchResult?.map((result: any, index: number) => {
        const { name, value, parent, type } = result;
        const parentWithoutRoot = type === 'input' && parent.slice(5);

        return (
          <div
            key={index}
            className={classes.Row}
            title={`${type} : ${
              type === 'input' && parentWithoutRoot
                ? `${parentWithoutRoot}.${name} : ${value}`
                : name
            } `}
            draggable
            onDragStart={(event: any) => {
              const { target } = event;
              const targetElement = target as HTMLDivElement;

              targetElement?.classList.add(classes.Drag);

              const decisionType = {
                input: () => {
                  const value = parentWithoutRoot
                    ? `${parentWithoutRoot}.${name}`
                    : name;
                  return {
                    dataKey: 'dragging_source',
                    data: {
                      source: EditorKeySources.INPUT,
                      key: value,
                      dataType: 'string',
                    },
                  };
                },
                variable: () => {
                  return {
                    dataKey: 'dragging_variable',
                    data: { source: EditorKeySources.VARIABLE, key: name },
                  };
                },
                scorecard: () => {
                  return {
                    dataKey: 'dragging_source',
                    data: { source: EditorKeySources.MAP, key: name },
                  };
                },
                decision: () => {
                  return {
                    dataKey: 'dragging_source',
                    data: { source: EditorKeySources.DECISIONS, key: name },
                  };
                },
                product: () => {
                  return {
                    dataKey: 'dragging_source',
                    data: { source: EditorKeySources.PRODUCTS, key: name },
                  };
                },
              } as any;
              const { dataKey, data } = decisionType[type]?.();
              event.dataTransfer.setData(dataKey, JSON.stringify(data));
              // const { target } = event;
              // const targetElement = target as HTMLDivElement;

              // targetElement?.classList.add(classes.Drag);
              // event.dataTransfer.setData(
              //   type === "input" ? "dragging_source" : "dragging_variable",
              //   type === "input" ? `${parentWithoutRoot}.${name}` : name
              // );
            }}
            onDragEnd={(event: any) => {
              const { target } = event;
              const targetElement = target as HTMLDivElement;

              targetElement?.classList.remove(classes.Drag);
            }}
          >
            {value ? (
              <>
                {valueRow(name, false)} {' : '} {valueRow(value, false)}
              </>
            ) : (
              valueRow(name, false)
            )}
          </div>
        );
      });
    };

    const showInfo = () => {
      setIsOpenTooltipInfo(!isOpenTooltipInfo);
    };

    return (
      <div ref={wrapperRef} className={classes.DataViewerWrapper}>
        <div className={classes.ViewerHeader}>
          <svg
            className={classes.DoubleIcon}
            viewBox='0 0 16 17'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M11.6011 10.796L15.4749 14.8249C15.8026 15.1657 15.7917 15.7091 15.4511 16.0369C15.2909 16.1911 15.0797 16.276 14.8571 16.276C14.622 16.276 14.4026 16.1826 14.2394 16.0131L10.3363 11.9537C9.228 12.7326 7.93171 13.1429 6.57143 13.1429C2.948 13.1429 0 10.1949 0 6.57143C0 2.948 2.948 0 6.57143 0C10.1949 0 13.1429 2.948 13.1429 6.57143C13.1429 8.12229 12.5971 9.612 11.6011 10.796ZM11.4286 6.57143C11.4286 3.89314 9.24971 1.71429 6.57143 1.71429C3.89314 1.71429 1.71429 3.89314 1.71429 6.57143C1.71429 9.24971 3.89314 11.4286 6.57143 11.4286C9.24971 11.4286 11.4286 9.24971 11.4286 6.57143Z'
            />
          </svg>
          <input
            className={classes.Title}
            type='text'
            placeholder='Search in Params'
            value={searched}
            onChange={handleSearchChange}
          />
          {searched ? (
            <svg
              className={classes.DoubleIcon}
              viewBox='0 0 12 12'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              onClick={clearSearch}
            >
              <title>Clear</title>
              <path d='M5.88083 4.99709L9.81083 1.06709C10.055 0.82313 10.055 0.427297 9.81083 0.183339C9.56667 -0.060828 9.17125 -0.060828 8.92708 0.183339L4.99708 4.11334L1.06687 0.183339C0.822708 -0.060828 0.427292 -0.060828 0.183125 0.183339C-0.0610417 0.427297 -0.0610417 0.82313 0.183125 1.06709L4.11333 4.99709L0.183125 8.92709C-0.0610417 9.17105 -0.0610417 9.56688 0.183125 9.81084C0.305208 9.93271 0.465208 9.99376 0.625 9.99376C0.784792 9.99376 0.944792 9.93271 1.06687 9.81063L4.99708 5.88063L8.92708 9.81063C9.04917 9.93271 9.20917 9.99376 9.36896 9.99376C9.52875 9.99376 9.68875 9.93271 9.81083 9.81063C10.055 9.56667 10.055 9.17084 9.81083 8.92688L5.88083 4.99709Z' />
            </svg>
          ) : (
          <></>           
          )}
        </div>
        {isOpenTooltipInfo && <TooltipInfo sidePanelBottom />}
        <div ref={bodyRef} className={classes.ViewerBody}>
          {!searchString ? (
            generateGroupRows(groupState)
          ) : searchResult.length ? (
            generateSearchResultRows(searchResult)
          ) : (
            <div className={classes.NoDataRow}>
              No result found for {searchString}
            </div>
          )}
        </div>
      </div>
    );
  };
  
  export default DataViewer;
  