import {
    StrategyActionsTypes,
    StrategyBoardSidePanelInputUploadProcess
} from "../../../../../types/typesStrategy";


const initialState: StrategyBoardSidePanelInputUploadProcess = {
    uploadFiles_process: null

}

const reducerInputUploadProcess = (state = initialState, action: StrategyActionsTypes): StrategyBoardSidePanelInputUploadProcess => {

    switch (action.type) {

        case "STRATEGY_BOARD_SIDE_PANEL_INPUT_UPLOAD_PROCESS":
            const { uploadFiles_process } = action.payload
            return {
                uploadFiles_process
            };

        case "STRATEGY_BOARD_SIDE_PANEL_INPUT_UPLOAD_PROCESS_STATUS":
            const { file_index, upload_status } = action.payload
            const copyOfFiles = state.uploadFiles_process !== null ? [...state.uploadFiles_process] : []
            const copyOfSpecificObject = copyOfFiles[file_index]
            const modifiedSpecificObject = { ...copyOfSpecificObject, status: upload_status }
            copyOfFiles[file_index] = modifiedSpecificObject

            return {
                uploadFiles_process: copyOfFiles
            }

        case "STRATEGY_BOARD_SIDE_PANEL_INPUT_UPLOAD_RESET":
            return {
                uploadFiles_process: null
            }

        default:
            return state
    }
}

export default reducerInputUploadProcess