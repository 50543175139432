import React, { FC, useCallback, useState, DragEvent } from "react";
import classes from "./DecisionMapper.module.scss";
import { IRangeObj } from "../../../components/UI/DecisionManager/DecisionManagerRange";
import {
  ButtonTypes,
  EditorKeySources,
  messages,
  ModalNames,
} from "../../../settings/settings";
import { IValidating } from "../../../layout/StrategyDesigner/StrategyBoard/SidePanel/Decision/Range/Range";
import Modal from "../../../components/UI/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { closeModal, openModal } from "../../../store/actions/actionsModal";

interface IDecisionMapper {
  decisionRanges?: Array<IRangeObj>;
  getMappedVariable?: (variable: string) => void;
  prevMappedVariable?: string;
  validate?: IValidating;
}

const DecisionMapper: FC<IDecisionMapper> = ({
  decisionRanges,
  getMappedVariable,
  prevMappedVariable,
  validate,
}) => {
  const dispatch = useDispatch();
  const {
    reducerModal: { modalState },
  } = useSelector((state: RootState) => state);
  
  const [droppedVariable, setDroppedVariable] = useState<string | undefined>();
  const [dropQueue, setDropQueue] = useState<
    { key: string; passingVariable: string } | undefined
  >();

  const generateDecisionRangesRows = useCallback(() => {
    if (!decisionRanges) {
      return null;
    }

    if (!decisionRanges.length) {
      return <div className={classes.NoDataRow}>{messages.messageNoData}</div>;
    }

    return decisionRanges.map((rangeObj: IRangeObj, index: number) => {
      const { id, label, alias, FROM, TO } = rangeObj;
      return (
        <div key={index} id={`${id}`} className={classes.MapperRow}>
          <span className={classes.MapperColumn} title={label}>
            {label}
          </span>
          <span className={classes.MapperColumn} title={alias}>
            {alias}
          </span>
          <span className={classes.MapperColumn} title={FROM}>
            {FROM}
          </span>
          <span className={classes.MapperColumn} title={TO}>
            {TO}
          </span>
        </div>
      );
    });
  }, [decisionRanges]);

  const onDragStart = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const { currentTarget } = event;

    currentTarget.classList.add(classes.Drop);
  }, []);

  const onDragOver = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const { currentTarget } = event;

    const hasDropClass = currentTarget.classList.contains(classes.Drop);

    if (!hasDropClass) {
      currentTarget.classList.add(classes.Drop);
    }
  }, []);

  const onDragLeave = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const { currentTarget } = event;

    currentTarget.classList.remove(classes.Drop);
  }, []);

  const onDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      const { currentTarget, dataTransfer } = event;

      const draggingSource = dataTransfer.getData("dragging_source");
      const draggingVariable = dataTransfer.getData("dragging_variable");
      const draggingMap = dataTransfer.getData("dragging_map");
      const draggingDataJSON =
        draggingSource || draggingVariable || draggingMap;
      const { source, key } = JSON.parse(draggingDataJSON);

      const passingVariable =
        source === EditorKeySources.MAP ? `${key}.point` : key;

      currentTarget.classList.remove(classes.Drop);

      if (!prevMappedVariable && !droppedVariable) {
        setDroppedVariable(key);
        getMappedVariable && getMappedVariable(passingVariable);
        return;
      }

      setDropQueue({ key, passingVariable });
      dispatch(
        openModal({
          modalState: {
            visible: true,
            title: messages.titleChangePanel("mapping variable"),
            question: messages.titleSureChange("mapping variable"),
            message: "",
            buttonMessage: messages.titleBtnChange,
            buttonType: ButtonTypes.PRIMARY,
            name: ModalNames.DECISION_MAPPER,
          },
        })
      );
    },
    [getMappedVariable, prevMappedVariable, droppedVariable, dispatch]
  );

  const onModalAction = useCallback(
    (action: boolean) => {
      if (action && dropQueue) {
        setDroppedVariable(dropQueue?.key);
        getMappedVariable && getMappedVariable(dropQueue?.passingVariable);
      }

      setDropQueue(undefined);
      dispatch(closeModal());
    },
    [dispatch, dropQueue, getMappedVariable]
  );

  return (
    <div className={classes.Wrapper}>
      <section className={classes.VariableSection}>
        <div className={classes.SectionHeader}>mapping variable</div>
        <div
          className={`${classes.SectionBody} ${
            prevMappedVariable || droppedVariable ? "" : classes.PassiveDragArea
          } ${validate?.map_obj ? classes.Validate : ""}`}
          onDragEnter={onDragStart}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        >
          <span
            className={`${
              prevMappedVariable || droppedVariable
                ? classes.VariableContent
                : classes.DragAreaContent
            }`}
          >
            {prevMappedVariable ?? droppedVariable ?? "Drag variable here"}
          </span>
        </div>
      </section>
      <section className={classes.DecisionRangeSection}>
        <div className={classes.SectionHeader}>mapping ranges</div>
        <div className={classes.SectionSubHeader}>
          <span className={classes.SubHeader}>label</span>
          <span className={classes.SubHeader}>alias</span>
          <span className={classes.SubHeader}>from</span>
          <span className={classes.SubHeader}>to</span>
        </div>
        <div
          className={`${classes.SectionBody} ${
            validate?.decision_id ? classes.Validate : ""
          }`}
        >
          {generateDecisionRangesRows()}
        </div>
      </section>
      <Modal
        title={modalState?.title}
        question={modalState?.question}
        message={modalState?.message}
        buttonMessage={modalState?.buttonMessage}
        buttonType={modalState?.buttonType}
        onAction={onModalAction}
        visible={modalState?.visible}
        name={ModalNames.DECISION_MAPPER}
      />
    </div>
  );
};

export default DecisionMapper;
