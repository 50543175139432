import React, { FC, useCallback, useLayoutEffect, useRef } from "react";
import classes from "./PossibilitiesList.module.scss";
import { ISearchReturn } from "../../../helpers/searchLocalPossibilities";
import { EditorKeySources } from "../../../settings/settings";

interface IPossibilitiesList {
  showOnly?:string;
  possibilities: ISearchReturn | null;
  cursorCoordinates: { top: string; left: string } | null;
  maxLeftCoordinate: number | undefined;
  onSelect: (value: { source: EditorKeySources; key: string }) => void;
}

const PossibilitiesList: FC<IPossibilitiesList> = ({
  showOnly,
  possibilities,
  cursorCoordinates,
  maxLeftCoordinate,
  onSelect,
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const applyCoordinates = useCallback(() => {
    const { current } = wrapperRef;
    let coordinates = { top: "0", left: "0" };

    if (!cursorCoordinates || !maxLeftCoordinate || !current) return;

    const { left } = cursorCoordinates;
    const unit = "px";
    const leftAsNumber = Number(left.substr(0, left.length - unit.length));
    const wrapperWidth = current.offsetWidth;
    const maxLeftDiff = Math.abs(maxLeftCoordinate - leftAsNumber);

    coordinates = cursorCoordinates;

    if (wrapperWidth > maxLeftDiff) {
      coordinates.left = `${maxLeftCoordinate - wrapperWidth}px`;
    }

    current.style.top = coordinates.top;
    current.style.left = coordinates.left;
  }, [cursorCoordinates, maxLeftCoordinate]);

  const generatePossibilitiesRows = useCallback(
    (possibilities: ISearchReturn | null) => {
      if (!possibilities || typeof possibilities === "string") return;
      const decisionPossiblityList = showOnly === "parentKey" ? possibilities.filter(f=>f.source === "Input") :possibilities
      return decisionPossiblityList.map((possibility, index) => {
        const { source, keys } = possibility;
        
        return (
          <div key={index} className={classes.ListGroup}>
            <div className={classes.ListGroupHeader}>
              <span className={classes.ListGroupName}>{source}</span>
            </div>
            {keys?.map((key, index) => {
              return (
                <div
                  key={index}
                  data-name={"possibility-row"}
                  title={key}
                  className={classes.ListRow}
                  onClick={() =>
                    onSelect({
                      source,
                      key,
                    })
                  }
                >
                  {key}
                </div>
              );
            })}
          </div>
        );
      });
    },
    [onSelect, showOnly]
  );

  useLayoutEffect(applyCoordinates, [applyCoordinates]);

  return (
    <div ref={wrapperRef} className={classes.ListWrapper}>
      {generatePossibilitiesRows(possibilities)}
    </div>
  );
};

export default PossibilitiesList;
