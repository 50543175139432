import { combineReducers } from "redux";
import StrategyDataReducer from "./StrategyTableData/reducer";
import StrategyStatusReducer from "./StrategyStatus/reducer";
import StrategyVersionReducer from "./StrategyVersion/reducer";
import BoardPositionReducer from "./StategyBoardPosition/reducer";
import BoardZoomReducer from "./StategyBoardZoom/reducer";
import StrategyApprovedByStatus from "./StrategyApprovedByStatus/reducer";
import StrategyApproveReducer from "./StrategyApproveFields/reducer";
import BoardSidePanel from "./StrategyBoardSidePanel/reducer";
import ActiveStrategyReducer from "./ActiveStrategy/reducer";
import StrategyFunctionReducer from "./StrategyFunction/reducer";
import SourceDataAvailabilityReducer from "./SourceDataAvailability/reducer";
import SourceDataTypesReducer from "./SourceDataTypes/reducer";

import BoardElements from "./StrategyBoardElement/reducer";
import StrategyFetchStatusReducer from "./StrategyFetchStatus/reducer";
import StrategyTableSelectedListReducer from "./TableSelectedList/reducer";
import strategyOutputFormatData from './StrategyBoardElement/OutputFormat/reducer';
import strategyOutputFormatItemStatus from './StrategyBoardElement/OutputFormat/ItemStatus/reducer';
import strategyOutputFormatMessage from './StrategyBoardElement/OutputFormat/Message/reducer';
import strategyElementTooltipStatus from './StrategyBoardElement/ElementTooltipStatus/reducer';
import changeableStatus from './ChangeableStatus/reducer';
export default combineReducers({
  StrategyData: StrategyDataReducer,
  StrategyStatus: StrategyStatusReducer,
  StrategyVersion: StrategyVersionReducer,
  ActiveStrategy: ActiveStrategyReducer,
  StrategyTableSelectedList: StrategyTableSelectedListReducer,
  StrategyFunction: StrategyFunctionReducer,
  StrategyBoardPosition: BoardPositionReducer,
  StrategyBoardZoom: BoardZoomReducer,
  StrategyApprovedByStatus,
  StrategyApproveReducer,
  StrategyBoardSidePanel: BoardSidePanel,
  StrategyBoardElements: BoardElements,
  SourceDataAvailability: SourceDataAvailabilityReducer,
  SourceDataTypes: SourceDataTypesReducer,
  StrategyFetchStatus: StrategyFetchStatusReducer,
  StrategyOutputFormatData:strategyOutputFormatData,
  StrategyOutputFormatItemStatus:strategyOutputFormatItemStatus,
  StrategyOutputFormatMessage:strategyOutputFormatMessage,
  StrategyElementTooltipStatus:strategyElementTooltipStatus,
  ChangeableStatus:changeableStatus
});
