import {
    StrategyActionsTypes,
    StrategyBoardZoom
} from "../../../types/typesStrategy";


const initialState: StrategyBoardZoom = {
    minZoom: 0.1,
    maxZoom: 3,
    zoom: 1,
    zoomPercent: "100%"
}

const reducerZoom = (state = initialState, action: StrategyActionsTypes): StrategyBoardZoom => {


    switch (action.type) {

        case "STRATEGY_BOARD_ZOOM":
            const { minZoom, maxZoom, zoom } = action.payload
            let zoomPercent = (zoom * 100).toFixed(0) + "%"
            return {

                minZoom,
                maxZoom,
                zoom,
                zoomPercent
            };

        default:
            return state
    }
}

export default reducerZoom