import { StrategyActionsTypes, StrategyOutputFormatItemStatus, STRATEGY_OUTPUT_FORMAT_ITEM_STATUS } from "../../../../../../store/types/typesStrategy";



const initialState: StrategyOutputFormatItemStatus = {
    status:'',
    item:{
        id:'',
        type:'',
        key:'',
        value:'',
        uuid:''
    }
    
}

const reducer = (state = initialState, action: StrategyActionsTypes): StrategyOutputFormatItemStatus => {

    switch (action.type) {

        case STRATEGY_OUTPUT_FORMAT_ITEM_STATUS:
            const {status,item} = action.payload;
            
            return {
                status:status,
                item:item ? item :state.item
            }
      
        default:
            return state

    }
}

export default reducer