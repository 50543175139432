import { storageKeys } from "settings/settings";

export const updateOutputItem = (
  manipulatedData: any,
  type: string,
  previousData: any
) => { 
  if (!manipulatedData) return;
  const {label} = manipulatedData;
  const { label: previousLabel, sourceId } = previousData;
  const boardElementsJSON = localStorage.getItem(storageKeys.boardElements);
  if (!boardElementsJSON) return;
  const boardElementsArray = [...JSON.parse(boardElementsJSON)];
  let currentElement = boardElementsArray.find(
    (item) => item.sourceId === "output"
  );
  if (!currentElement) return;
  const indexOutput = boardElementsArray.indexOf(currentElement);
  let { data, overlays } = currentElement;
  const decisionType = {
    function: () => {
      let getFunctionElement = overlays?.find(
        (item: any) => item["id"] === sourceId
      );
      if (!getFunctionElement) return;
      const index = overlays.indexOf(getFunctionElement) as number;
      if (index < 0) return;
      const { key, value } = getFunctionElement;
      if (key === value) {
        getFunctionElement["key"] = label;
      }
      getFunctionElement["value"] = label;
      
      overlays[index] = getFunctionElement;
      const {outputValue} = data;
      const splittedList = outputValue.replaceAll(/[{}]/g, "").split(",") as string[];
      const val = `${key}:${value}`;
      const indexVal = splittedList.indexOf(val) as number;
      if(indexVal>=0){
        splittedList[indexVal] = key === value ? `${label}:${label}` : `${key}:${label}`
        
      }
      const newOutputValue = `{${splittedList.join(',')}}`;
      data["outputValue"] = newOutputValue
      boardElementsArray[indexOutput] = currentElement;
    },
    scorecard: () => {
      let getScoreElements = overlays?.filter(
        (item: any) => item["id"].includes("variable-") && item["parent"] === previousLabel
      );
      if(getScoreElements.length === 0) return;
      getScoreElements?.forEach((item:any)=>{
        const index = overlays.indexOf(item) as number;
        if(index <0 ) return;
        overlays[index] = {
          ...item,
          key:item["key"]?.replace(previousLabel,label),
          parent:label,
          staticKey:item["staticKey"]?.replace(previousLabel,label),
          ...(item.dataType === "string" && {staticValue:item.staticValue?.replace(previousLabel,label)}),
          value:item["value"]?.replace(previousLabel,label),
        }
        const {key,value} = item;
        const {outputValue} = data;
        const splittedList = outputValue.replaceAll(/[{}]/g, "").split(",") as string[];
        const val = `${key}:${value}`;
        const indexVal = splittedList.indexOf(val) as number;
        if(indexVal>=0){
          splittedList[indexVal] = key === value ? `${label}:${label}` : `${item["key"]?.replace(previousLabel,label)}:${item["value"]?.replace(previousLabel,label)}`
        }
        const newOutputValue = `{${splittedList.join(',')}}`;
        data["outputValue"] = newOutputValue
      })
      
      
      boardElementsArray[indexOutput] = currentElement;
    },
    decision: () => {
      let getScoreElements = overlays?.filter(
        (item: any) => item["id"].includes("variable-") && item["parent"] === previousLabel
      );
      if(getScoreElements.length === 0) return;
      getScoreElements?.forEach((item:any)=>{
        const index = overlays.indexOf(item) as number;
        if(index <0 ) return;
        overlays[index] = {
          ...item,
          key:item["key"]?.replace(previousLabel,label),
          parent:label,
          staticKey:item["staticKey"]?.replace(previousLabel,label),
          ...(item.dataType === "string" && {staticValue:item.staticValue?.replace(previousLabel,label)}),
          value:item["value"]?.replace(previousLabel,label),
        }
        const {key,value} = item;
        const {outputValue} = data;
        const splittedList = outputValue.replaceAll(/[{}]/g, "").split(",") as string[];
        const val = `${key}:${value}`;
        const indexVal = splittedList.indexOf(val) as number;
        if(indexVal>=0){
          splittedList[indexVal] = key === value ? `${label}:${label}` : `${item["key"]?.replace(previousLabel,label)}:${item["value"]?.replace(previousLabel,label)}`
        }
        const newOutputValue = `{${splittedList.join(',')}}`;
        data["outputValue"] = newOutputValue
      })
      boardElementsArray[indexOutput] = currentElement;
    },
    product: () => {
      const {id} = previousData

      let getFunctionElement = overlays?.find(
        (item: any) => item["id"] === id
      );
      if (!getFunctionElement) return;
      const index = overlays.indexOf(getFunctionElement) as number;
      if (index < 0) return;
      const { key, value } = getFunctionElement;
      if (key === value) {
        getFunctionElement["key"] = label;
      }
      getFunctionElement["value"] = label;
      
      overlays[index] = getFunctionElement;
      const {outputValue} = data;
      const splittedList = outputValue.replaceAll(/[{}]/g, "").split(",") as string[];
      const val = `${key}:${value}`;
      const indexVal = splittedList.indexOf(val) as number;
      if(indexVal>=0){
        splittedList[indexVal] = key === value ? `${label}:${label}` : `${key}:${label}`
        
      }
      const newOutputValue = `{${splittedList.join(',')}}`;
      data["outputValue"] = newOutputValue
      boardElementsArray[indexOutput] = currentElement;
    },
  } as any;
  decisionType[type]?.();
 
    localStorage.setItem(
    storageKeys.boardElements,
    JSON.stringify(boardElementsArray)
  );
};
