import React, { Fragment } from "react";
import classes from "./Matrix.module.scss";
import MatrixSvg from "./MatrixSvg/MatrixSvg";
import { Redirect } from "react-router-dom";
import Button from "../../../../components/UI/Button/Button";
import { matrix, icons, matrix_status } from "../../../../settings/settings";
import { useDispatch, useSelector } from "react-redux";
// import { RootState } from '../../../../store/combineReducer';
import {
  matrixData,
  matrixDataStatus,
  activeDecision,
  matrixSpinner,
  matrixActiveAlias,
  matrixListStatus,
} from "../../../../store/actions/actionsDecision";
import Backdrop from "../../../../components/UI/Backdrop/Backdrop";
import { request } from "../../../../helpers/request";
import { endpoints } from "../../../../api/endpoints";
import { RootState } from "../../../../store/combineReducer";
import Table from "../../../../components/UI/Table/Table";
import {
  activeModal,
  touchBlocked,
  cancelHandler,
  saveHandler,
} from "../../../../store/actions/action";

interface typeProps {
  id: number;
  name: string;
  color: string;
  width: string;
  height: string;
  backgroundColor: string;
  borderRadius: string;
}

const Matrix = (props: any) => {
  const [matrixSize, setMatrixSize] = React.useState({
    coords: { x: 5, y: 5 },
  });
  const [vStep, setVstep] = React.useState(0);
  const [hStep, setHstep] = React.useState(0);
  const [settingsTooltip, setSettingsTooltip] = React.useState<any>({
    coords: {},
  });
  const [tooltipType, setTooltipType] = React.useState(false);

  const [settingsTooltipStatus, setSettingsTooltipStatus] =
    React.useState(false);
  // const [activeCell,setActiveCell] =  React.useState<any>();

  const [activeType, setActiveType] = React.useState<typeProps[]>([
    matrix_status[1],
  ]);
  const [typeCounter, setTypeCounter] = React.useState(0);

  const [activeStatus, setActiveStatus] = React.useState<typeProps[]>([
    matrix_status[1],
  ]);
  const [statusCounter, setStatusCounter] = React.useState(0);

  const [activeLabel, setActiveLabel] = React.useState<any | null>(null);
  const [activeAlias, setActiveAlias] = React.useState<any | null>(null);

  const [activeCell, setActiveCell] = React.useState<any | null>(null);

  const [matrixPoints, setMatrixPoints] = React.useState<any>({
    points: {
      horizontal: { min: 0, max: 0 },
      vertical: { min: 0, max: 0 },
    },
  });

  const [editAlias, setEditAlias] = React.useState<any | null>(null);
  const [editStatus, setEditStatus] = React.useState<any | null>(null);

  const [switchStatus, setSwitchStatus] = React.useState(false);
  const [matrixCell, setMatrixCell] = React.useState<any | null>(null);

  let [valueY, setValueY] = React.useState<number>(matrixSize.coords.y);
  let [valueX, setValueX] = React.useState<number>(matrixSize.coords.x);

  const dispatch = useDispatch();
  const store = useSelector((state: RootState) => {
    return state;
  });

  const settingsTooltipRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (store.reducer.cancelHandler.status) {
      dispatch(touchBlocked({ status: false }));
      dispatch(cancelHandler({ status: false }));
      if (store.reducerDecision.matrixListStatus.matrixListStatus) {
        let statusColumn = document.querySelectorAll("#status");

        statusColumn?.forEach((column: any) => {
          if (
            column ===
            store.reducerDecision.matrixListStatus.matrixListStatus?.event
              ?.parentNode
          ) {
            let status_id =
              store.reducerDecision.matrixListStatus.matrixListStatus
                ?.status_id;
            let parentElement = column;
            let editTools = column?.children[1];
            let statusSpan = column?.children[0].children[0];
            dispatch(matrixListStatus({ matrixListStatus: null }));
            dispatch(touchBlocked({ status: false }));
            editTools.style.display = "none";
            let editBtn = editTools?.children[0];
            let saveBtn = editTools?.children[1];
            let cancelBtn = editTools?.children[2];
            editBtn.style.display = "block";
            saveBtn.style.display = "none";
            cancelBtn.style.display = "none";
            setEditStatus(null);

            parentElement.children[0].classList.remove(classes.StatusActive);
            parentElement.children[0].children[1].style.display = "none";

            parentElement.children[0].children[0].style.display = "block";
            parentElement.children[0].children[2].style.display = "none";
            parentElement.children[2].style.display = "none";

            statusSpan.textContent = matrix_status[status_id - 1]?.name;
            setStatusCounter(0);
          }
        });
      } else if (store.reducerDecision.matrixActiveAlias.matrixActiveAlias) {
        let aliasColumn = document.querySelectorAll("#alias");

        aliasColumn?.forEach((column: any) => {
          if (
            column ===
            store.reducerDecision.matrixActiveAlias.matrixActiveAlias?.target
          ) {
            let oldAlias = activeCell?.alias;
            column.children[0].value = oldAlias;
            dispatch(matrixActiveAlias({ matrixActiveAlias: null }));
            column.children[1].style.display = "none";
            let eBtn = column.children[1]?.children[0];
            let sBtn = column.children[1]?.children[1];
            let cBtn = column.children[1]?.children[2];

            eBtn.style.display = "block";
            sBtn.style.display = "none";
            cBtn.style.display = "none";
            column.children[0].style.background = "transparent";
            column.children[0]?.setAttribute("disabled", null);
            column.children[1].style.display = "none";
            setEditAlias(null);

            column.children[2].style.display = "none";
          }
        });
      }
    }
  }, [
    activeCell,
    dispatch,
    store.reducer.cancelHandler.status,
    store.reducerDecision.matrixActiveAlias.matrixActiveAlias,
    store.reducerDecision.matrixListStatus.matrixListStatus,
  ]);
  React.useEffect(() => {
    if (store.reducer.saveHandler.status) {
      dispatch(touchBlocked({ status: false }));
      dispatch(cancelHandler({ status: false }));

      if (store.reducerDecision.matrixListStatus.matrixListStatus) {
        let statusColumn = document.querySelectorAll("#status");

        statusColumn?.forEach((column: any) => {
          if (
            column ===
            store.reducerDecision.matrixListStatus.matrixListStatus?.event
              ?.parentNode
          ) {
            let status_id =
              store.reducerDecision.matrixListStatus.matrixListStatus
                ?.status_id;
            let parentElement = column;
            let editTools = column?.children[1];
            let statusSpan = column?.children[0].children[0];
            dispatch(matrixListStatus({ matrixListStatus: null }));
            dispatch(touchBlocked({ status: false }));
            editTools.style.display = "none";
            let editBtn = editTools?.children[0];
            let saveBtn = editTools?.children[1];
            let cancelBtn = editTools?.children[2];
            editBtn.style.display = "block";
            saveBtn.style.display = "none";
            cancelBtn.style.display = "none";
            setEditStatus(null);
            statusSpan.textContent = matrix_status[status_id - 1]?.name;
            parentElement.children[0].classList.remove(classes.StatusActive);
            parentElement.children[0].children[1].style.display = "none";
            parentElement.children[0].children[0].style.display = "block";
            parentElement.children[0].children[2].style.display = "none";
            parentElement.children[2].style.display = "none";

            setStatusCounter(0);
          }
        });

        const { id, label, alias } = activeCell;
        let status_id = activeStatus[0]?.id;

        let cellData: any = {
          id: id,
          label: label,
          alias: alias,
          status_id: status_id,
        };

        request
          .patch(
            endpoints.decision_designer.decision_matrix.cell.patch(id),
            cellData
          )
          .then((res) => {
            if (res.success && res.response) {
              let matrix_cell = res.response;
              let oldMatrixCell = [...matrixCell];
              let absMassive = oldMatrixCell.filter((cell: any) => {
                return cell.id === matrix_cell.id;
              });
              let index = oldMatrixCell.indexOf(absMassive[0]);
              oldMatrixCell[index] = {
                ...oldMatrixCell[index],
                ...res.response,
              };
              setMatrixCell(oldMatrixCell);
              let oldMatrixData = {
                ...store.reducerDecision.matrixData.matrixData,
              };
              oldMatrixData.matrix_cell = oldMatrixCell;

              dispatch(matrixData({ matrixData: oldMatrixData }));
            }
          })
          .catch((err) => {
            console.log("error:" + err);
          });
      } else if (store.reducerDecision.matrixActiveAlias.matrixActiveAlias) {
        let statusColumn = document.querySelectorAll("#alias");

        statusColumn?.forEach((column: any) => {
          if (
            column ===
            store.reducerDecision.matrixActiveAlias.matrixActiveAlias?.target
          ) {
            dispatch(matrixActiveAlias({ matrixActiveAlias: null }));
            column.children[1].style.display = "none";
            let eBtn = column.children[1]?.children[0];
            let sBtn = column.children[1]?.children[1];
            let cBtn = column.children[1]?.children[2];

            eBtn.style.display = "block";
            sBtn.style.display = "none";
            cBtn.style.display = "none";
            column.children[0].style.background = "transparent";
            column.children[0]?.setAttribute("disabled", null);
            column.children[1].style.display = "none";
            setEditAlias(null);

            column.children[2].style.display = "none";
            let tooltip = column.children[2];

            const { id, label, status_id } = activeCell;

            let cellData: any = {
              id: id,
              label: label,
              alias:
                store.reducerDecision.matrixActiveAlias.matrixActiveAlias
                  ?.value,

              status_id: status_id,
            };

            request
              .patch(
                endpoints.decision_designer.decision_matrix.cell.patch(id),
                cellData
              )
              .then((res) => {
                if (res.success && res.response) {
                  let matrix_cell = res.response;
                  matrix_cell.label = label;
                  let oldMatrixCell = [...matrixCell];
                  let absMassive = oldMatrixCell.filter((cell: any) => {
                    return cell.id === matrix_cell.id;
                  });
                  let index = oldMatrixCell.indexOf(absMassive[0]);
                  oldMatrixCell[index] = {
                    ...oldMatrixCell[index],
                    ...res.response,
                  };
                  setMatrixCell(oldMatrixCell);
                  let oldMatrixData = {
                    ...store.reducerDecision.matrixData.matrixData,
                  };
                  oldMatrixData.matrix_cell = oldMatrixCell;

                  dispatch(matrixData({ matrixData: oldMatrixData }));
                  setSettingsTooltipStatus(false);
                  tooltip.style.display = "none";
                }
              })
              .catch((err) => {
                console.log("error:" + err);
              });
          }
        });
      }
      dispatch(saveHandler({ status: false }));
    }
  }, [
    activeCell,
    activeStatus,
    activeType,
    dispatch,
    matrixCell,
    store.reducer.saveHandler.status,
    store.reducerDecision.matrixActiveAlias.matrixActiveAlias,
    store.reducerDecision.matrixData.matrixData,
    store.reducerDecision.matrixListStatus.matrixListStatus,
  ]);
  React.useEffect(() => {
    if (store.reducerDecision.matrixDataStatus.status) {
      const {
        horizontal_steps,
        vertical_steps,
        horizontal_length,
        vertical_length,
      } = store.reducerDecision.matrixData.matrixData;
      setHstep(horizontal_steps);
      setVstep(vertical_steps);
      setMatrixSize({
        coords: {
          x: horizontal_length,
          y: vertical_length,
        },
      });
      dispatch(activeDecision({ activeDecision: +props.decision_id }));
    } else {
      dispatch(matrixSpinner({ status: true }));
      dispatch(activeDecision({ activeDecision: +props.decision_id }));
      request
        .get(
          endpoints.decision_designer.decision_matrix.get(+props.decision_id)
        )
        .then((res) => {
          if (res.success && res.response.length > 0) {
            const {
              horizontal_steps,
              vertical_steps,
              horizontal_length,
              vertical_length,
              matrix_cell,
            } = res.response[0];
            setHstep(horizontal_steps);
            setVstep(vertical_steps);
            setMatrixSize({
              coords: {
                x: horizontal_length,
                y: vertical_length,
              },
            });
            dispatch(matrixData({ matrixData: res.response[0] }));
            dispatch(matrixDataStatus({ status: true }));
            dispatch(matrixSpinner({ status: false }));
            setMatrixCell(matrix_cell);
          } else {
            dispatch(matrixSpinner({ status: false }));
            setHstep(0);
            setVstep(0);
            setMatrixSize({
              coords: {
                x: 5,
                y: 5,
              },
            });
          }
        })
        .catch((err) => {
          console.error("error:" + err);
        });
    }
  }, [
    dispatch,
    props.decision_id,
    store.reducerDecision.matrixData.matrixData,
    store.reducerDecision.matrixDataStatus.status,
  ]);

  React.useEffect(() => {
    function sorter(a: number, b: number) {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    }
    const matrixCell = store.reducerDecision.matrixData.matrixData
      ?.matrix_cell as any[];
    const groupedCellPoint = matrixCell?.map((cell: any) => {
      return {
        v: [+cell.vertical_start_point, +cell.vertical_end_point].sort(),
        h: [+cell.horizontal_start_point, +cell.horizontal_end_point].sort(),
      };
    });
    const verticalPoints = [] as any[];
    const horizontalPoints = [] as any[];

    if (groupedCellPoint?.length > 0) {
      for (let i of groupedCellPoint) {
        for (let j of Object.values(i.v)) {
          verticalPoints.push(j);
        }
        for (let j of Object.values(i.h)) {
          horizontalPoints.push(j);
        }
      }
    }
    const sortedVerticalPoints = Array.from(new Set(verticalPoints)).sort(
      sorter
    );
    const sortedHorizontalPoints = Array.from(new Set(horizontalPoints)).sort(
      sorter
    );

    let counterY: any = document.querySelectorAll(
      "#counterY"
    ) as NodeListOf<HTMLDivElement>;
    let counterX = document.querySelectorAll(
      "#counterX"
    ) as NodeListOf<HTMLDivElement>;

    for (const point in sortedVerticalPoints) {
      const inputEL = counterY[point]?.querySelector(
        "input"
      ) as HTMLInputElement;
      if (inputEL) {
        inputEL.value = sortedVerticalPoints[point];
      }
    }
    for (const point in sortedHorizontalPoints) {
      const inputEL = counterX[point]?.querySelector(
        "input"
      ) as HTMLInputElement;
      if (inputEL) {
        inputEL.value = sortedHorizontalPoints[point];
      }
    }
  }, [store.reducerDecision.matrixData.matrixData, switchStatus]);

  React.useEffect(() => {
    let counterY: any = document.querySelectorAll("#counterY");

    [...counterY].forEach((el: any, i: number) => {
      let inputEL = el.children[2]
        ? el.children[2].children[0]
        : el.children[0].children[0];

      if (props.step === 2) {
        // if(el !== counterY[1]){
        //     inputEL.value = 0;

        //     inputEL.addEventListener('focusout',(e:any)=>{

        //             let currentInput = [...counterY][i]?.children[2]?.children[0];
        //             let prevInput = [...counterY][i-1]?.children[2]?.children[0];
        //             let nextInput = [...counterY][i+1]?.children[2]?.children[0];

        //                     if(prevInput && +currentInput?.value <= +prevInput.value){
        //                         currentInput.value = +prevInput?.value+1
        //                     }else if(nextInput && +currentInput.value >= +nextInput.value){
        //                         currentInput.value = +nextInput?.value-1

        //                     }

        //     })
        //     inputEL.addEventListener('keyup',(e:any)=>{
        //             [...counterY][i].style.border='0'
        //             if((+[...counterY][i]?.children[2]?.children[0]?.value > +[...counterY][i-1]?.children[2]?.children[0]?.value)
        //             && (+[...counterY][i]?.children[2]?.children[0]?.value < +[...counterY][i+1]?.children[2]?.children[0]?.value)){

        //                 return true

        //             }else if((+[...counterY][i]?.children[2]?.children[0]?.value <= +[...counterY][i-1]?.children[2]?.children[0]?.value) && el !== [...counterY][counterY.length-1]){

        //             }else if([...counterY][i+1] && (+[...counterY][i]?.children[2]?.children[0]?.value >= +[...counterY][i+1]?.children[2]?.children[0]?.value)){
        //                 if((+[...counterY][i]?.children[2]?.children[0]?.value.toString().length >= +[...counterY][i+1]?.children[2]?.children[0]?.value.toString().length)){
        //                     inputEL.value = Math.abs(+[...counterY][i+1]?.children[2]?.children[0]?.value-1)

        //                 }
        //             }else if([...counterY][i+1] && (+[...counterY][i]?.children[2]?.children[0]?.value <= +[...counterY][i-1]?.children[2]?.children[0]?.value) && el === [...counterY][counterY.length-1]){

        //                 if((+[...counterY][i]?.children[2]?.children[0]?.value.toString().length === +[...counterY][i-1]?.children[2]?.children[0]?.value.toString().length)){
        //                     inputEL.value = Math.abs(+[...counterY][i-1]?.children[2]?.children[0]?.value+1)

        //                 }

        //         }

        //     })

        // }else if(el === counterY[1]){
        //     inputEL.addEventListener('keyup',(e:any)=>{
        //         let val =e.currentTarget.value;
        //         setVstep(val)

        //     })
        // }
        inputEL.value = 0;
      } else if (props.step === "final") {
        inputEL.setAttribute("disabled", null);
      }

      // inputEL.value = i*vStep
      inputEL.parentNode.parentNode.style.border = "0";
    });

    let counterX: any = document.querySelectorAll("#counterX");

    [...counterX].forEach((el: any, i: number) => {
      let inputEL = el.children[2]
        ? el.children[2].children[0]
        : el.children[0].children[0];

      if (props.step === 2) {
        // if(el !== counterX[1]){
        //     inputEL.value = 0;

        //     inputEL.addEventListener('focusout',(e:any)=>{

        //         let currentInput = [...counterX][i]?.children[2]?.children[0];
        //         let prevInput = [...counterX][i-1]?.children[2]?.children[0];
        //         let nextInput = [...counterX][i+1]?.children[2]?.children[0];

        //                 if(prevInput && +currentInput?.value <= +prevInput.value){
        //                     currentInput.value = +prevInput?.value+1
        //                 }else if(nextInput && +currentInput.value >= +nextInput.value){
        //                     currentInput.value = +nextInput?.value-1

        //                 }

        // })
        //     inputEL.addEventListener('keyup',(e:any)=>{

        //             if((+[...counterX][i]?.children[2]?.children[0]?.value > +[...counterX][i-1]?.children[2]?.children[0]?.value)
        //             && (+[...counterX][i]?.children[2]?.children[0]?.value < +[...counterX][i+1]?.children[2]?.children[0]?.value)){

        //                 return true

        //             }else if((+[...counterX][i]?.children[2]?.children[0]?.value <= +[...counterX][i-1]?.children[2]?.children[0]?.value) && el !== [...counterX][counterX.length-1]){

        //             }else if([...counterX][i+1] && (+[...counterX][i]?.children[2]?.children[0]?.value >= +[...counterX][i+1]?.children[2]?.children[0]?.value)){
        //                 if((+[...counterX][i]?.children[2]?.children[0]?.value.toString().length >= +[...counterX][i+1]?.children[2]?.children[0]?.value.toString().length)){
        //                     inputEL.value = +[...counterX][i+1]?.children[2]?.children[0]?.value-1

        //                 }
        //             }else if([...counterX][i+1] && (+[...counterX][i]?.children[2]?.children[0]?.value <= +[...counterX][i-1]?.children[2]?.children[0]?.value) && el === [...counterX][counterY.length-1]){

        //                 if((+[...counterX][i]?.children[2]?.children[0]?.value.toString().length === +[...counterX][i-1]?.children[2]?.children[0]?.value.toString().length)){
        //                     inputEL.value = +[...counterX][i-1]?.children[2]?.children[0]?.value+1

        //                 }

        //         }

        //     })

        // }else if(el === counterX[1]){
        //     inputEL.addEventListener('keyup',(e:any)=>{
        //         let val =e.currentTarget.value;
        //         setHstep(val)
        //     })
        // }
        inputEL.value = 0;
      } else if (props.step === "final") {
        inputEL.setAttribute("disabled", null);
      }
      // inputEL.value = i*hStep

      inputEL.parentNode.parentNode.style.border = "0";
    });
  });

  const matrixSizeHandler = (coord: string) => {
    if (coord === "y") {
      if (valueY >= 25) {
        return valueY = 25;
      }
      setValueY(Number(valueY) + 1);
      setMatrixSize((state) => {
        return {
          coords: {
            x: state.coords.x,
            y: state.coords.y + 1,
          },
        };
      });
    }

    else if (coord === "x") {
      if (valueX >= 25) {
        return valueX = 25;
      }
      setValueX(Number(valueX) + 1);
      setMatrixSize((state) => {
        return {
          coords: {
            x: state.coords.x + 1,
            y: state.coords.y,
          },
        };
      });
    }
  };

  const matrixSizeHandlerI = (coord: string) => {
    if (coord === "y") {
      if (valueY <= 3) {
        return valueY = 3;
      }
      setValueY(Number(valueY) - 1);
      setMatrixSize((state) => {
        return {
          coords: {
            x: state.coords.x,
            y: state.coords.y > 3 ? state.coords.y - 1 : 3,
          },
        };
      });
    }
    
    else if (coord === "x") {
      if (valueX <= 3) {
        return valueX = 3;
      }
      setValueX(Number(valueX) - 1);
      setMatrixSize((state) => {
        return {
          coords: {
            x: state.coords.x > 3 ? state.coords.x - 1 : 3,
            y: state.coords.y,
          },
        };
      });
    }
  };

  const settingsTooltipHandler = (
    e: any,
    x: any,
    y: any,
    label: string,
    coordX: number,
    coordY: number,
    cellID: number
  ) => {
    if (props.step && props.step === "final") {
      let activeNumber = coordY;
      let diffPos = matrixSize.coords.y - activeNumber;

      if (activeNumber >= diffPos) {
        setTooltipType(true);
      } else {
        setTooltipType(false);
      }

      setSettingsTooltipStatus(true);
      setSettingsTooltip({
        coords: {
          x: x - 375 + 90,
          y: activeNumber >= diffPos ? y - 88 - 392 : y,
        },
      });

      let tooltip = document.querySelector(
        "#settingsTooltip"
      ) as HTMLDivElement;
      tooltip.style.display = "block";

      tooltip.children[0].textContent = label;
      setActiveLabel(label);
      let alias = matrixCell?.filter((cell: any) => {
        return cell.id === cellID;
      });
      setActiveAlias(alias[0]?.alias);
      let counterX: any = document.querySelector("#horizontalPoints");
      let counterXmassive = [...counterX.children];
      let counterY: any = document.querySelector("#verticalPoints");
      let counterYmassive = [...counterY.children];

      let minPointX = counterXmassive[coordX]?.children[0]?.children[0]?.value;
      let maxPointX =
        counterXmassive[coordX + 1]?.children[0]?.children[0]?.value;
      let minPointY = counterYmassive[coordY]?.children[0]?.children[0]?.value;
      let maxPointY =
        counterYmassive[coordY + 1]?.children[0]?.children[0]?.value;

      setMatrixPoints({
        points: {
          horizontal: {
            min: minPointX,
            max: maxPointX,
          },
          vertical: {
            min: minPointY,
            max: maxPointY,
          },
        },
      });

      const storeData =
        store.reducerDecision.matrixData.matrixData?.matrix_cell;
      const activeCellMass = storeData.filter((cell: any) => {
        return cell.label === label;
      });
      setActiveCell({ ...activeCellMass[0] });
      setActiveType([matrix_status[activeCellMass[0]?.status_id - 1]]);
    } else {
      setSettingsTooltipStatus(false);
    }
  };

  const arrowTopHandler = (typeName: string) => {
    if (typeName === "type") {
      let newArr = [...matrix_status];
      if (typeCounter >= 0 && typeCounter < newArr.length - 1) {
        setTypeCounter(typeCounter + 1);
        setActiveType([newArr[typeCounter + 1]]);
        // let is_range = newArr[typeCounter+1]?.name === 'Range' ? true : false;
      }
    }
  };
  const arrowBottomHandler = (typeName: string) => {
    if (typeName === "type") {
      let newArr = [...matrix_status];
      if (typeCounter > 0 && typeCounter < newArr.length) {
        setTypeCounter(typeCounter - 1);
        setActiveType([newArr[typeCounter - 1]]);
        // let is_range = newArr[typeCounter-1]?.name === 'Range' ? true : false
      } else if (typeCounter === 0 && typeCounter < newArr.length) {
        setTypeCounter(typeCounter);
        setActiveType([newArr[typeCounter]]);
        // let is_range = newArr[typeCounter]?.name === 'Range' ? true : false
        // let is_range = newArr[typeCounter+1]?.name === 'Range' ? true : false;
      }
    }
  };

  const closeTooltipHandler = (e: any) => {
    setSettingsTooltipStatus(false);
    e.currentTarget.parentNode.style.display = "none";
  };

  const matrixApplyHandler = (e: any) => {
    let tooltip = e.currentTarget.parentNode.parentNode.parentNode;
    const {
      id,
      label,
      horizontal_start_point,
      horizontal_end_point,
      vertical_start_point,
      vertical_end_point,
      decision_matrix_id,
    } = activeCell;

    let data: any = {
      id: id,
      label: label,
      alias: activeAlias,
      horizontal_start_point: horizontal_start_point,
      horizontal_end_point: horizontal_end_point,
      vertical_start_point: vertical_start_point,
      vertical_end_point: vertical_end_point,
      decision_matrix_id: decision_matrix_id,
      status_id: activeType[0]?.id,
    };

    request
      .patch(endpoints.decision_designer.decision_matrix.cell.patch(id), data)
      .then((res) => {
        if (res.success && res.response) {
          let matrix_cell = res.response;
          matrix_cell.label = label;
          let oldMatrixCell = [...matrixCell];
          let absMassive = oldMatrixCell.filter((cell: any) => {
            return cell.id === matrix_cell.id;
          });
          let index = oldMatrixCell.indexOf(absMassive[0]);
          oldMatrixCell[index] = res.response;
          setMatrixCell(oldMatrixCell);
          setSettingsTooltipStatus(false);
          tooltip.style.display = "none";
        }
      })
      .catch((err) => {
        console.log("error:" + err);
      });
  };

  const prevHandler = () => {};

  const nextHandler = () => {
    // dispatch(matrixData({matrixData:matrixSize}))
  };

  const checkInputs = () => {
    const counterY = document.querySelectorAll(
      "#counterY"
    ) as NodeListOf<HTMLDivElement>;
    const counterX = document.querySelectorAll(
      "#counterX"
    ) as NodeListOf<HTMLDivElement>;
    let result = true as boolean;
    for (let i = 0; i < counterY.length; i++) {
      for (let j = i + 1; j <= counterY.length; j++) {
        if (j - i === 1) {
          const inputYPrev = counterY[i]?.querySelector(
            "input"
          ) as HTMLInputElement;
          const inputYNext = counterY[j]?.querySelector(
            "input"
          ) as HTMLInputElement;
          if (inputYPrev && inputYNext) {
            const firstInput = counterY[0]?.querySelector(
              "input"
            ) as HTMLInputElement;
            if (!firstInput?.value) {
              firstInput.value = "0";
            }
            if (+inputYNext.value <= +inputYPrev.value) {
              result = false;
              counterY[j].style.border = "1px solid red";
            }
          }
        }
      }
    }
    for (let i = 0; i < counterX.length; i++) {
      for (let j = i + 1; j <= counterX.length; j++) {
        if (j - i === 1) {
          const inputXPrev = counterX[i]?.querySelector(
            "input"
          ) as HTMLInputElement;
          const inputXNext = counterX[j]?.querySelector(
            "input"
          ) as HTMLInputElement;
          if (inputXPrev && inputXNext) {
            const firstInput = counterX[0]?.querySelector(
              "input"
            ) as HTMLInputElement;
            if (!firstInput?.value) {
              firstInput.value = "0";
            }
            if (+inputXNext.value <= +inputXPrev.value) {
              result = false;

              counterX[j].style.border = "1px solid red";
            }
          }
        }
      }
    }
    return { result };
  };
  const finishHandler = () => {
    const cells: any = document.querySelectorAll("#cell");

    const cellMassive = [...cells];

    let counterX: any = document.querySelector("#horizontalPoints");
    let counterXmassive = [...counterX.children];
    let counterY: any = document.querySelector("#verticalPoints");
    let counterYmassive = [...counterY.children];

    counterYmassive.forEach((yMassive: any, i: number) => {
      let input = yMassive.children[2]?.children[0];
      yMassive.style.border = "none";
      if (yMassive !== counterYmassive[0]) {
        if (Number(input.value) === 0) {
          yMassive.style.border = "1px solid red";
        }
      }
    });
    counterXmassive.forEach((xMassive: any, i: number) => {
      let input = xMassive.children[2]?.children[0];
      xMassive.style.border = "none";

      if (xMassive !== counterXmassive[0]) {
        if (Number(input.value) === 0) {
          xMassive.style.border = "1px solid red";
        }
      }
    });

    let data: any = {
      decision_id: +props.decision_id,
      horizontal_min_point:
        +counterXmassive[0]?.children[2]?.children[0]?.value,
      horizontal_max_point:
        +counterXmassive[counterXmassive.length - 1]?.children[2]?.children[0]
          ?.value,
      vertical_min_point: +counterYmassive[0]?.children[2]?.children[0]?.value,
      vertical_max_point:
        +counterYmassive[counterYmassive.length - 1]?.children[2]?.children[0]
          ?.value,
      horizontal_steps: +hStep,
      vertical_steps: +vStep,
      horizontal_length: matrixSize.coords.x,
      vertical_length: matrixSize.coords.y,
      matrix_cell: [],
    };

    cellMassive.forEach((el: any) => {
      let rect = el.querySelector("rect");
      let rectColor = rect?.getAttribute("fill");
      let status_id = matrix_status.filter(
        (status: any) => status.color === rectColor
      )[0]?.id;

      let dataName = el.getAttribute("data-name");
      let dataNameSplit = dataName.split("|");
      let label = dataNameSplit[0].split(":")[1];
      let coordX = +dataNameSplit[1].split(":")[1];
      let coordY = +dataNameSplit[2].split(":")[1];

      let xmin = counterXmassive[coordX]?.children[2]?.children[0]?.value;
      let xmax = counterXmassive[coordX + 1]?.children[2]?.children[0]?.value;
      let ymin = counterYmassive[coordY]?.children[2]?.children[0]?.value;
      let ymax = counterYmassive[coordY + 1]?.children[2]?.children[0]?.value;

      data.matrix_cell.push({
        label: label,
        alias: label,
        horizontal_start_point: +xmin,
        horizontal_end_point: +xmax,
        vertical_start_point: +ymin,
        vertical_end_point: +ymax,
        status_id: status_id,
      });
    });
    if (data) {
      let { result } = checkInputs();
      if (!result) return;
      request
        .post(endpoints.decision_designer.decision_matrix.post, data)
        .then((res) => {
          if (res.success && res.success === true) {
            window.location.search = `?id=${props.decision_id}&type=${props.type_id}&status=final`;
          }
        });
    }
  };

  const aliasChangeHandler = (e: any) => {
    let parentAliases = e.target.parentNode?.parentNode?.parentNode;
    let aliases = parentAliases?.querySelectorAll("#alias");
    let activeAlias = e.target.parentNode;
    activeAlias.children[0]?.removeAttribute("disabled");
    aliases.forEach((alias: any) => {
      if (alias !== activeAlias) {
        alias.children[0]?.setAttribute("disabled", null);
      } else {
        alias.children[1].children[1].style.display = "block";
      }
    });
    dispatch(
      matrixActiveAlias({
        matrixActiveAlias: {
          target: e.target.parentNode,
          value: e.target.value,
        },
      })
    );
    dispatch(touchBlocked({ status: true }));
  };

  const editAliasHandler = (e: any, data: any) => {
    const storeAlias =
      store.reducerDecision.matrixActiveAlias.matrixActiveAlias;
    const storeStatus = store.reducerDecision.matrixListStatus.matrixListStatus;

    const aliases = document.querySelectorAll("#alias");
    const statusList = document.querySelectorAll("#status");

    let editTools = e.currentTarget.parentNode.parentNode;

    if (!storeAlias?.target && !storeStatus?.event) {
      setEditAlias(editTools);
      setActiveCell(data);

      aliases?.forEach((alias: any) => {
        let eBtn = alias?.children[1]?.children[0];
        let sBtn = alias?.children[1]?.children[1];
        let cBtn = alias?.children[1]?.children[2];

        let editBtn: HTMLSpanElement = editTools?.children[0];

        if (eBtn === editBtn) {
          eBtn.style.display = "none";
          sBtn.style.display = "none";
          cBtn.style.display = "block";
          alias.children[0].style.background = "#F5F6FA";
          alias.children[0]?.removeAttribute("disabled");
          alias.children[0]?.focus();
          alias.children[1].style.display = "flex";
        } else {
          eBtn.style.display = "block";
          sBtn.style.display = "none";
          cBtn.style.display = "none";
          alias.children[0].style.background = "transparent";
          alias.children[0]?.setAttribute("disabled", null);

          alias.children[1].style.display = "none";
        }
      });
    } else {
      if (storeAlias?.target && !storeStatus?.event) {
        setActiveCell(data);

        aliases?.forEach((alias: any) => {
          let tooltip = alias?.children[2];
          if (alias === storeAlias?.target) {
            tooltip.style.display = "block";
          }
        });
      } else if (!storeAlias?.target && storeStatus?.event) {
        statusList?.forEach((list: any) => {
          let tooltip = list?.children[2];
          if (list === storeStatus?.event?.parentNode) {
            tooltip.style.display = "block";
          }
        });
      }
    }
  };

  const cancelAliasHandler = (e: any, data: any) => {
    let column = e.currentTarget.parentNode?.parentNode?.parentNode;
    let oldAlias = data.alias;
    column.children[0].value = oldAlias;
    dispatch(matrixActiveAlias({ matrixActiveAlias: null }));
    column.children[1].style.display = "none";
    let eBtn = column.children[1]?.children[0];
    let sBtn = column.children[1]?.children[1];
    let cBtn = column.children[1]?.children[2];

    eBtn.style.display = "block";
    sBtn.style.display = "none";
    cBtn.style.display = "none";
    column.children[0].style.background = "transparent";
    column.children[0]?.setAttribute("disabled", null);
    column.children[1].style.display = "none";
    setEditAlias(null);

    column.children[2].style.display = "none";
  };

  const saveAliasHandler = (e: any, data: any) => {
    let column = e.currentTarget.parentNode?.parentNode?.parentNode;
    // let id = data.id;
    // let oldAlias = data.alias;
    // column.children[0].value = oldAlias;
    dispatch(matrixActiveAlias({ matrixActiveAlias: null }));
    column.children[1].style.display = "none";
    let eBtn = column.children[1]?.children[0];
    let sBtn = column.children[1]?.children[1];
    let cBtn = column.children[1]?.children[2];

    eBtn.style.display = "block";
    sBtn.style.display = "none";
    cBtn.style.display = "none";
    column.children[0].style.background = "transparent";
    column.children[0]?.setAttribute("disabled", null);
    column.children[1].style.display = "none";
    setEditAlias(null);

    column.children[2].style.display = "none";
    let tooltip = column.children[2];

    const { id, label, status_id } = activeCell;

    let cellData: any = {
      id: id,
      label: label,
      alias: store.reducerDecision.matrixActiveAlias.matrixActiveAlias?.value,
      // horizontal_start_point:horizontal_start_point,
      // horizontal_end_point:horizontal_end_point,
      // vertical_start_point:vertical_start_point,
      // vertical_end_point:vertical_end_point,
      // decision_matrix_id:decision_matrix_id,
      status_id: status_id,
    };

    request
      .patch(
        endpoints.decision_designer.decision_matrix.cell.patch(id),
        cellData
      )
      .then((res) => {
        if (res.success && res.response) {
          let matrix_cell = res.response;
          matrix_cell.label = label;
          let oldMatrixCell = [...matrixCell];
          let absMassive = oldMatrixCell.filter((cell: any) => {
            return cell.id === matrix_cell.id;
          });
          let index = oldMatrixCell.indexOf(absMassive[0]);
          oldMatrixCell[index] = { ...oldMatrixCell[index], ...res.response };
          setMatrixCell(oldMatrixCell);
          let oldMatrixData = {
            ...store.reducerDecision.matrixData.matrixData,
          };
          oldMatrixData.matrix_cell = oldMatrixCell;

          dispatch(matrixData({ matrixData: oldMatrixData }));
          setSettingsTooltipStatus(false);
          tooltip.style.display = "none";
        }
      })
      .catch((err) => {
        console.log("error:" + err);
      });
  };

  const aliasOverHandle = (e: any) => {
    let parentAliases = e.currentTarget.parentNode?.parentNode?.parentNode;
    let aliases = parentAliases?.querySelectorAll("#alias");
    let storeAlias = store.reducerDecision.matrixActiveAlias.matrixActiveAlias;

    if (!storeAlias?.target) {
      e.currentTarget.children[0].style.background = "#F5F6FA";

      e.currentTarget.children[0]?.classList.add(classes.Over);
      e.currentTarget.children[0]?.classList.add(classes.CursorOver);
      e.currentTarget.children[0]?.classList.remove(classes.CursorText);
      e.currentTarget.children[0]?.classList.remove(classes.Blocked);
      e.currentTarget.children[1].style.display = "flex";
    } else {
      aliases.forEach((alias: any) => {
        if (alias !== storeAlias?.target) {
          alias.children[0]?.classList.remove(classes.Over);
          alias.children[0]?.classList.remove(classes.CursorOver);
          alias.children[0]?.classList.remove(classes.CursorText);
          alias.children[0]?.classList.add(classes.Blocked);
        } else {
          alias.children[0]?.classList.remove(classes.CursorOver);
          alias.children[0]?.classList.remove(classes.Blocked);
          alias.children[0]?.classList.add(classes.Over);
          alias.children[0]?.classList.add(classes.CursorText);
          e.currentTarget.children[0].style.background = "#F5F6FA";
          e.currentTarget.children[1].style.display = "flex";
        }
      });
    }
  };

  const aliasLeaveHandler = (e: any) => {
    let parentAliases = e.currentTarget.parentNode?.parentNode?.parentNode;
    let aliases = parentAliases?.querySelectorAll("#alias");
    let storeAlias = store.reducerDecision.matrixActiveAlias.matrixActiveAlias;

    if (!storeAlias?.target) {
      e.currentTarget.children[0]?.classList.remove(classes.Over);
      if (e.currentTarget.children[1] !== editAlias) {
        e.currentTarget.children[1].style.display = "none";
        e.currentTarget.children[0].style.background = "transparent";
      }
    } else {
      aliases.forEach((alias: any) => {
        if (alias !== storeAlias?.target) {
          alias.children[0]?.classList.remove(classes.Over);
          alias.children[0]?.classList.remove(classes.CursorOver);
          alias.children[0]?.classList.remove(classes.CursorText);
          alias.children[0]?.classList.remove(classes.Blocked);
          if (e.currentTarget.children[1] !== editAlias) {
            e.currentTarget.children[1].style.display = "none";
            e.currentTarget.children[0].style.background = "transparent";
          }
        }
      });
    }
  };

  const statusTopHandler = (e: any, data: any) => {
    let newArr = [...matrix_status];
    let statusSpan = e.currentTarget.parentNode.parentNode.children[0];

    if (statusCounter >= 0 && statusCounter < newArr.length - 1) {
      setStatusCounter(statusCounter + 1);
      setActiveStatus([newArr[statusCounter + 1]]);
      dispatch(
        matrixListStatus({
          matrixListStatus: {
            event: e.currentTarget.parentNode.parentNode,
            status_id: data.status_id,
            id: data.id,
          },
        })
      );
      dispatch(touchBlocked({ status: true }));

      let editTools =
        e.currentTarget.parentNode.parentNode.parentNode.children[1];
      let sBtn = editTools.children[1];
      sBtn.style.display = "block";
    } else if (statusCounter === newArr.length) {
      setStatusCounter(statusCounter);
      setActiveStatus([newArr[statusCounter]]);
    }
    statusSpan.textContent = activeStatus[0]?.name;
  };

  const statusBottomHandler = (e: any, data: any) => {
    let newArr = [...matrix_status];
    let statusSpan = e.currentTarget.parentNode.parentNode.children[0];
    if (statusCounter > 0 && statusCounter < newArr.length) {
      setStatusCounter(statusCounter - 1);
      setActiveStatus([newArr[statusCounter - 1]]);

      dispatch(
        matrixListStatus({
          matrixListStatus: {
            event: e.currentTarget.parentNode.parentNode,
            status_id: data.status_id,
            id: data.id,
          },
        })
      );
      dispatch(touchBlocked({ status: true }));

      let editTools =
        e.currentTarget.parentNode.parentNode.parentNode.children[1];
      let sBtn = editTools.children[1];
      sBtn.style.display = "block";
    } else if (statusCounter === 0) {
      setStatusCounter(statusCounter);
      setActiveStatus([newArr[statusCounter]]);
    }
    statusSpan.textContent = activeStatus[0]?.name;
  };

  const saveStatusHandler = (e: any, data: any) => {
    let parentElement = e.currentTarget.parentNode?.parentNode?.parentNode;
    let editTools = e.currentTarget.parentNode.parentNode;

    let editBtn = editTools?.children[0];
    let saveBtn = editTools?.children[1];
    let cancelBtn = editTools?.children[2];

    parentElement.children[0].classList.remove(classes.StatusActive);
    parentElement.children[0].children[1].style.display = "none";

    parentElement.children[0].children[0].style.display = "block";
    parentElement.children[0].children[2].style.display = "none";

    parentElement.children[2].style.display = "none";

    let status_id = activeStatus[0]?.id;
    const { id, label, alias } = activeCell;
    let cellData: any = {
      id: id,
      label: label,
      alias: alias,
      status_id: status_id,
    };

    request
      .patch(
        endpoints.decision_designer.decision_matrix.cell.patch(id),
        cellData
      )
      .then((res) => {
        if (res.success && res.response) {
          let matrix_cell = res.response;
          let oldMatrixCell = [...matrixCell];
          let absMassive = oldMatrixCell.filter((cell: any) => {
            return cell.id === matrix_cell.id;
          });
          let index = oldMatrixCell.indexOf(absMassive[0]);
          oldMatrixCell[index] = { ...oldMatrixCell[index], ...res.response };
          setMatrixCell(oldMatrixCell);
          let oldMatrixData = {
            ...store.reducerDecision.matrixData.matrixData,
          };
          oldMatrixData.matrix_cell = oldMatrixCell;
          // statusSpan.textContent =matrix_status[matrix_cell?.status_id]?.name;

          dispatch(matrixData({ matrixData: oldMatrixData }));

          dispatch(matrixListStatus({ matrixListStatus: null }));
          dispatch(touchBlocked({ status: false }));
          editTools.style.display = "none";
          editBtn.style.display = "block";
          saveBtn.style.display = "none";
          cancelBtn.style.display = "none";
          setEditStatus(null);
          setStatusCounter(0);
        }
      })
      .catch((err) => {
        console.log("error:" + err);
      });
  };

  const editStatusHandler = (e: any, data: any) => {
    let statusList = document.querySelectorAll("#status");
    let aliasList = document.querySelectorAll("#alias");

    let storeStatus = store.reducerDecision.matrixListStatus.matrixListStatus;
    let storeAlias = store.reducerDecision.matrixActiveAlias.matrixActiveAlias;

    let editTools = e.currentTarget.parentNode.parentNode;

    setActiveCell(data);
    if (!storeStatus?.event && !storeAlias?.target) {
      setEditStatus(editTools);
      let newArr = [...matrix_status];
      if (data.status_id > 0 && statusCounter < newArr.length - 1) {
        let index = data.status_id - 1;
        setStatusCounter(index);
        setActiveStatus([newArr[index]]);
      }

      statusList?.forEach((field: any) => {
        let eBtn = field?.children[1]?.children[0];
        let sBtn = field?.children[1]?.children[1];
        let cBtn = field?.children[1]?.children[2];

        let editBtn: HTMLSpanElement = editTools?.children[0];

        if (eBtn === editBtn) {
          eBtn.style.display = "none";
          sBtn.style.display = "none";
          cBtn.style.display = "block";
          //  field.children[0].style.background = '#F5F6FA';
          //  field.children[0]?.removeAttribute('disabled');
          //  field.children[0]?.focus();
          field.children[1].style.display = "flex";
          field.children[0].classList.add(classes.StatusActive);
          field.children[0].children[1].style.display = "block";
          field.children[0].children[0].style.display = "none";
          field.children[0].children[2].style.display = "block";
        } else {
          eBtn.style.display = "block";
          sBtn.style.display = "none";
          cBtn.style.display = "none";
          //  field.children[0].style.background = 'transparent';
          //  field.children[0]?.setAttribute('disabled',null);

          field.children[1].style.display = "none";
          field.children[0].classList.remove(classes.StatusActive);
          field.children[0].children[1].style.display = "none";

          field.children[0].children[0].style.display = "block";
          field.children[0].children[2].style.display = "none";
        }
      });
    } else {
      aliasList?.forEach((alias: any) => {
        let tooltip = alias?.children[2];
        if (alias === storeAlias?.target) {
          tooltip.style.display = "block";
        }
      });
    }
  };

  const cancelStatusHandler = (e: any, el: any) => {
    let parentElement = e.currentTarget.parentNode?.parentNode?.parentNode;
    let editTools = e.currentTarget.parentNode.parentNode;
    let statusSpan =
      e.currentTarget.parentNode.parentNode.parentNode.children[0].children[0];
    dispatch(matrixListStatus({ matrixListStatus: null }));
    dispatch(touchBlocked({ status: false }));
    editTools.style.display = "none";
    let editBtn = editTools?.children[0];
    let saveBtn = editTools?.children[1];
    let cancelBtn = editTools?.children[2];
    editBtn.style.display = "block";
    saveBtn.style.display = "none";
    cancelBtn.style.display = "none";
    setEditStatus(null);

    parentElement.children[0].classList.remove(classes.StatusActive);
    parentElement.children[0].children[1].style.display = "none";

    parentElement.children[0].children[0].style.display = "block";
    parentElement.children[0].children[2].style.display = "none";
    statusSpan.textContent = matrix_status[el.status_id - 1]?.name;
    setStatusCounter(0);
    parentElement.children[2].style.display = "none";
  };

  const statusOverHandler = (e: any) => {
    let parentElement = e.currentTarget.parentNode;
    let statusList = parentElement?.querySelectorAll("#status");
    let storeStatus = store.reducerDecision.matrixListStatus.matrixListStatus;

    if (!storeStatus?.target) {
      // e.currentTarget.children[0].style.background='#F5F6FA';

      // e.currentTarget.children[0]?.classList.add(classes.Over);
      // e.currentTarget.children[0]?.classList.add(classes.CursorOver);
      // e.currentTarget.children[0]?.classList.remove(classes.CursorText);
      // e.currentTarget.children[0]?.classList.remove(classes.Blocked);
      e.currentTarget.children[1].style.display = "flex";
    } else {
      statusList.forEach((field: any) => {
        if (field !== storeStatus?.event) {
          // field.children[0]?.classList.remove(classes.Over);
          // field.children[0]?.classList.remove(classes.CursorOver);
          // field.children[0]?.classList.remove(classes.CursorText);
          // field.children[0]?.classList.add(classes.Blocked);
        } else {
          // field.children[0]?.classList.remove(classes.CursorOver);
          // field.children[0]?.classList.remove(classes.Blocked);
          // field.children[0]?.classList.add(classes.Over);
          // field.children[0]?.classList.add(classes.CursorText);
          // e.currentTarget.children[0].style.background='#F5F6FA';
          e.currentTarget.children[1].style.display = "flex";
        }
      });
    }
  };

  const statusLeaveHandler = (e: any) => {
    let parentElement = e.currentTarget.parentNode;
    let statusList = parentElement?.querySelectorAll("#status");
    let storeStatus = store.reducerDecision.matrixListStatus.matrixListStatus;

    if (!storeStatus?.event) {
      // e.currentTarget.children[0]?.classList.remove(classes.Over);
      if (e.currentTarget.children[1] !== editStatus) {
        e.currentTarget.children[1].style.display = "none";
        // e.currentTarget.children[0].style.background='transparent';
      }
    } else {
      statusList.forEach((field: any) => {
        if (field !== storeStatus?.event) {
          // field.children[0]?.classList.remove(classes.Over);
          // field.children[0]?.classList.remove(classes.CursorOver);
          // field.children[0]?.classList.remove(classes.CursorText);
          // field.children[0]?.classList.remove(classes.Blocked);
          if (e.currentTarget.children[1] !== editStatus) {
            e.currentTarget.children[1].style.display = "none";
            // e.currentTarget.children[0].style.background='transparent';
          }
        }
      });
    }
  };

  const switchChangeHandler = (e: any) => {
    if (store.reducer.touchBlocked.status) {
      dispatch(activeModal({ activeModal: "matrix_blocked_modal" }));
    } else {
      setSwitchStatus(!switchStatus);
    }
  };

  const matrixPointsHandler = (e: any, type: string, coord: string) => {
    if (props.step === 2) {
      let input = e.currentTarget.parentNode?.children[2]?.children[0];

      if (
        e.currentTarget.parentNode.parentNode.children[1] ===
        e.currentTarget.parentNode
      ) {
        if (type === "increment") {
          input.value++;
          if (coord === "y") setVstep(input.value);
          if (coord === "x") setHstep(input.value);
        } else if (type === "decrement") {
          if (input.value <= 0) return 0;
          input.value--;
          if (coord === "y") setVstep(input.value);
          if (coord === "x") setHstep(input.value);
        }
      } else {
        if (type === "increment") {
          input.value++;
        } else if (type === "decrement") {
          if (input.value <= 0) return 0;
          input.value--;
        }
      }
    }
  };

  // let matrix:any = props.step === 1 ? <MatrixSvg /> :(props.step === 2 ? <MatrixSvg/> : <Redirect to='/decision-designer'/>);

  const onInputChange = (e: any, coords: string) => {
    const inputValue = e.target.value;
    const maxNumber = inputValue > 25 ? 25 : inputValue;
    if (coords === "y") {
      setValueY(maxNumber);
    }
    else if (coords === "x") {
      setValueX(maxNumber)
    }
  }

  const onInputKeyUp = (e: any, coords: string) => {
    const inputY: HTMLInputElement | null = document.querySelector("#input_first");
    const inputX: HTMLInputElement | null = document.querySelector("#input_second");

    if (e.keyCode === 13 && e.target.value >= 3) {
      if (coords === "y") {
        inputY?.blur();
        setValueY(e.target.value);
        setMatrixSize((state) => {
          return {
            coords: {
              x: state.coords.x,
              y: parseInt(e.target.value),
            },
          };
        });
      }
      
      else if (coords === "x") {
        inputX?.blur();
        setValueX(e.target.value);
        setMatrixSize((state) => {
          return {
            coords: {
              x: parseInt(e.target.value),
              y: state.coords.y,
            },
          };
        });
      }
    }

    else if (e.keyCode === 13 && e.target.value < 3) {
      if (coords === "y") {
        inputY?.blur();
        setValueY(3);
        setMatrixSize((state) => {
          return {
            coords: {
              x: state.coords.x,
              y: 3,
            },
          };
        });
      }
      
      else if (coords === "x") {
        inputX?.blur();
        setValueX(3);
        setMatrixSize((state) => {
          return {
            coords: {
              x: 3,
              y: state.coords.y,
            },
          };
        });
      }
    }
  }

  const onInputKeyDown = (e: any) => {
    if (!(e.key.match(/[0-9]/)) && !(e.keyCode === 8) && !(e.keyCode === 37) && !(e.keyCode === 39)) {
      e.preventDefault();
    }
  }

  return (
    <Fragment>
      {store.reducerDecision.matrixData.matrixData &&
        +store.reducerDecision.matrixData.matrixData?.decision_id ===
          +props.decision_id && (
          <Redirect
            to={`/decision-designer/decision_matrix?id=${props.decision_id}&type=${props.type_id}&status=final`}
          />
        )}
      {props.step !== "final" &&
        (!store.reducerDecision.matrixData.matrixData ||
          +store.reducerDecision.matrixData.matrixData?.decision_id !==
            +props.decision_id) && (
          <>
            <div
              className={classes.MatrixSvg}
              style={{
                width:
                  matrix.xMatrix(matrixSize.coords.x).length > 5
                    ? 520 +
                      (matrix.xMatrix(matrixSize.coords.x).length - 5) * 70
                    : 520 + "px",
              }}
            >
              {props.step === 1 && (
                <>
                  <div className={classes.IncrementMatrixY}>
                    <div className={classes.Next}>
                      <span>{matrixSize.coords.y + 1}</span>
                    </div>
                    <div className={classes.Counter}>
                      <button
                        className={classes.TopBtn}
                        onClick={() => matrixSizeHandler("y")}
                      >
                        <img src={icons.arrowTop} alt="arrow top" />
                      </button>
                      <button
                        className={classes.BottomBtn}
                        onClick={() => matrixSizeHandlerI("y")}
                      >
                        <img src={icons.arrowBottom} alt="arrow bottom" />
                      </button>

                      {/* <span>{matrixSize.coords.y}</span> */}
                        <input
                        id="input_first"
                        className={classes.Counter}
                        type="number"
                        value={valueY}
                        onChange={(e) => onInputChange(e, "y")}
                        onKeyUp={(e) => onInputKeyUp(e, "y")}
                        onKeyDown={onInputKeyDown}
                        />
                    </div>
                    <div className={classes.Prev}>
                      <span>{matrixSize.coords.y - 1}</span>
                    </div>
                  </div>
                  <div className={classes.IncrementMatrixX}>
                    <div className={classes.Prev}>
                      <span>{matrixSize.coords.x - 1}</span>
                    </div>
                    <div className={classes.Counter}>
                      <button
                        className={classes.TopBtn}
                        onKeyPress={(event) =>
                          event.charCode === 8 ||
                          event.charCode === 0 ||
                          event.charCode === 13
                            ? null
                            : event.charCode >= 48 && event.charCode <= 57
                        }
                        onClick={() => matrixSizeHandler("x")}
                      >
                        <img src={icons.arrowTop} alt="arrow top" />
                      </button>
                      <button
                        className={classes.BottomBtn}
                        onKeyPress={(event) =>
                          event.charCode === 8 ||
                          event.charCode === 0 ||
                          event.charCode === 13
                            ? null
                            : event.charCode >= 48 && event.charCode <= 57
                        }
                        onClick={() => matrixSizeHandlerI("x")}
                      >
                        <img src={icons.arrowBottom} alt="arrow bottom" />
                      </button>

                      {/* <span>{matrixSize.coords.x}</span> */}
                      <input
                        id="input_second"
                        className={classes.Counter}
                        type="number"
                        value={valueX}
                        onChange={(e) => onInputChange(e, "x")}
                        onKeyUp={(e) => onInputKeyUp(e, "x")}
                        onKeyDown={onInputKeyDown}
                        />
                    </div>
                    <div className={classes.Next}>
                      <span>{matrixSize.coords.x + 1}</span>
                    </div>
                  </div>

                  <MatrixSvg
                    handler={settingsTooltipHandler}
                    xyMatrix={matrix}
                    xySize={matrixSize}
                  />
                </>
              )}
              {props.step === 2 && (
                <>
                  <div
                    id="verticalPoints"
                    style={{
                      height:
                        matrix.yMatrix(matrixSize.coords.y).length > 5
                          ? 401 +
                            (matrix.yMatrix(matrixSize.coords.y).length - 5) *
                              65
                          : 401,
                    }}
                    className={classes.VerticalPoints}
                  >
                    {matrix
                      .yMatrix(matrixSize.coords.y + 1)
                      .map((yName: any, i: number) => {
                        return (
                          // <rect id={`y${i+1}`} x='17' y={30+i*64.5} width="9" height="1" fill="#efefef"/>
                          <div
                            key={i}
                            id="counterY"
                            className={classes.Counter}
                            style={{ top: 15 + i * 64.5 + "px" }}
                          >
                            <button
                              className={classes.TopBtn}
                              onClick={(e) =>
                                matrixPointsHandler(e, "increment", "y")
                              }
                            >
                              <img src={icons.arrowTop} alt="arrow top" />
                            </button>
                            <button
                              className={classes.BottomBtn}
                              onClick={(e) =>
                                matrixPointsHandler(e, "decrement", "y")
                              }
                            >
                              <img src={icons.arrowBottom} alt="arrow bottom" />
                            </button>

                            {/* <span contentEditable>{matrixSize.coords.y}</span> */}
                            <span>
                              <input
                                onKeyPress={(event) =>
                                  event.charCode === 8 ||
                                  event.charCode === 0 ||
                                  event.charCode === 13
                                    ? null
                                    : event.charCode >= 48 &&
                                      event.charCode <= 57
                                }
                                tabIndex={i + 1}
                                type="number"
                              />
                            </span>
                          </div>
                        );
                      })}
                  </div>
                  <div
                    id="horizontalPoints"
                    style={{
                      width: matrix.xMatrix(matrixSize.coords.x).length * 80,
                    }}
                    className={classes.HorizontalPoints}
                  >
                    {matrix
                      .xMatrix(matrixSize.coords.x + 1)
                      .map((xName: any, i: number) => {
                        return (
                          // <rect id={`x${i+1}`} x={72+i*64.5} y="398" width="1" height="9" fill="#efefef"/>
                          <div
                            key={i}
                            id="counterX"
                            className={classes.Counter}
                            style={{ left: 25 + i * 64.5 + "px" }}
                          >
                            <button
                              className={classes.TopBtn}
                              onClick={(e) =>
                                matrixPointsHandler(e, "increment", "x")
                              }
                            >
                              <img src={icons.arrowTop} alt="arrow top" />
                            </button>
                            <button
                              className={classes.BottomBtn}
                              onClick={(e) =>
                                matrixPointsHandler(e, "decrement", "x")
                              }
                            >
                              <img src={icons.arrowBottom} alt="arrow bottom" />
                            </button>

                            {/* {/* <span contentEditable>{matrixSize.coords.y}</span> */}
                            <span>
                              <input
                                onKeyPress={(event) =>
                                  event.charCode === 8 ||
                                  event.charCode === 0 ||
                                  event.charCode === 13
                                    ? null
                                    : event.charCode >= 48 &&
                                      event.charCode <= 57
                                }
                                tabIndex={
                                  matrix.yMatrix(matrixSize.coords.y + 1)
                                    .length +
                                  (i + 1)
                                }
                                type="number"
                              />
                            </span>
                          </div>
                        );
                      })
                      .reverse()}
                  </div>

                  <MatrixSvg
                    xyMatrix={matrix}
                    xySize={matrixSize}
                    handler={settingsTooltipHandler}
                  />

                  {settingsTooltipStatus && <Backdrop />}
                  <div
                    id="settingsTooltip"
                    ref={settingsTooltipRef}
                    style={{
                      left: settingsTooltip.coords.x,
                      top: settingsTooltip.coords.y,
                    }}
                    className={
                      tooltipType
                        ? [classes.SettingsTooltip, classes.TopTooltip].join(
                            " "
                          )
                        : [classes.SettingsTooltip, classes.BottomTooltip].join(
                            " "
                          )
                    }
                  >
                    <div id="cellTooltip" className={classes.CellTooltip}></div>

                    <div className={classes.Title}>Group Settings</div>
                    <div className={classes.Form}>
                      <div className={classes.Label}>
                        <label>Label</label>
                        <input
                          disabled
                          type="text"
                          defaultValue={activeLabel}
                        />
                      </div>

                      <div className={classes.Status}>
                        <label>Status</label>
                        <div className={classes.SelectOption}>
                          <div className={classes.ValueFormat}>
                            <span className={classes.Name}>
                              {activeType && activeType[0]?.name}
                            </span>
                            <span
                              style={{
                                backgroundColor:
                                  activeType && activeType[0]?.color,
                              }}
                              className={classes.Color}
                            ></span>
                          </div>
                          <div className={classes.ArrowBtns}>
                            <span
                              className={classes.TopSpan}
                              onClick={() => arrowTopHandler("type")}
                            >
                              <img src={icons.arrowTop} alt="arrow top" />
                            </span>
                            <span
                              className={classes.BottomSpan}
                              onClick={() => arrowBottomHandler("type")}
                            >
                              <img src={icons.arrowBottom} alt="arrow bottom" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={classes.Output}>
                        <label>Output Value</label>
                        <div className={classes.OutputContent}>
                          <table>
                            <thead></thead>
                            <tbody>
                              <tr>
                                <td>Matrix_code:</td>
                                <td>{activeLabel}</td>
                              </tr>
                              <tr>
                                <td>X_scores:</td>
                                <td>
                                  {matrixPoints.points.horizontal.min}-
                                  {matrixPoints.points.horizontal.max}
                                </td>
                              </tr>
                              <tr>
                                <td>Y_scores:</td>
                                <td>
                                  {matrixPoints.points.vertical.min}-
                                  {matrixPoints.points.vertical.max}
                                </td>
                              </tr>
                              <tr>
                                <td>Risk:</td>
                                <td>
                                  {
                                    matrix_status[activeCell?.status_id - 1]
                                      ?.name
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className={classes.Button}>
                        <button onClick={matrixApplyHandler}>Apply</button>
                      </div>
                    </div>
                    <div
                      onClick={closeTooltipHandler}
                      className={classes.CloseBtn}
                    >
                      <img src={icons.closeBtn} alt="close" />
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}

      {props.step === "final" && (
        <>
          <div className={classes.Switcher}>
            <div className={classes.buttonSwitch}>
              <input
                disabled={store.reducer.touchBlocked.status ? true : false}
                onChange={switchChangeHandler}
                type="checkbox"
                id="switch-blue"
                className={classes.Switch}
              />
              <label htmlFor="switch-blue" className={classes.lblOff}>
                <img src={icons.switchList} alt="switch" />
              </label>
              <label htmlFor="switch-blue" className={classes.lblOn}>
                <img src={icons.switchMatrix} alt="switch" />
              </label>
            </div>
          </div>
          {switchStatus && (
            <>
              <Table
                section="decision-designer-matrix"
                cancelAliasHandler={cancelAliasHandler}
                editAliasHandler={editAliasHandler}
                onMouseOverHandler={aliasOverHandle}
                onMouseLeaveHandler={aliasLeaveHandler}
                statusMouseOverHandler={statusOverHandler}
                statusMouseLeaveHandler={statusLeaveHandler}
                editStatusHandler={editStatusHandler}
                cancelStatusHandler={cancelStatusHandler}
                onChangeHandler={aliasChangeHandler}
                saveAliasHandler={saveAliasHandler}
                statusTopHandler={statusTopHandler}
                statusBottomHandler={statusBottomHandler}
                activeStatus={activeStatus}
                saveStatusHandler={saveStatusHandler}
                data={store.reducerDecision.matrixData.matrixData?.matrix_cell}
              />
            </>
          )}
          {!switchStatus && (
            <>
              <div
                className={classes.MatrixSvg}
                style={{
                  width:
                    matrix.xMatrix(matrixSize.coords.x).length > 5
                      ? 520 +
                        (matrix.xMatrix(matrixSize.coords.x).length - 5) * 70
                      : 520 + "px",
                }}
              >
                <div
                  id="verticalPoints"
                  style={{
                    height:
                      matrix.yMatrix(matrixSize.coords.y).length > 5
                        ? 401 +
                          (matrix.yMatrix(matrixSize.coords.y).length - 5) * 65
                        : 401,
                  }}
                  className={classes.VerticalPoints}
                >
                  {matrix
                    .yMatrix(matrixSize.coords.y + 1)
                    .map((yName: any, i: number) => {
                      return (
                        <div
                          key={i}
                          id="counterY"
                          className={classes.Counter}
                          style={{ top: 15 + i * 64.5 + "px" }}
                        >
                          {props.step === 2 && (
                            <>
                              <button className={classes.TopBtn}>
                                <img src={icons.arrowTop} alt="arrow top" />
                              </button>
                              <button className={classes.BottomBtn}>
                                <img
                                  src={icons.arrowBottom}
                                  alt="arrow bottom"
                                />
                              </button>
                            </>
                          )}
                          <span>
                            <input type="number" />
                          </span>
                        </div>
                      );
                    })}
                </div>
                <div
                  id="horizontalPoints"
                  style={{
                    width: matrix.xMatrix(matrixSize.coords.x).length * 80,
                  }}
                  className={classes.HorizontalPoints}
                >
                  {matrix
                    .xMatrix(matrixSize.coords.x + 1)
                    .map((xName: any, i: number) => {
                      return (
                        // <rect id={`x${i+1}`} x={72+i*64.5} y="398" width="1" height="9" fill="#efefef"/>
                        <div
                          key={i}
                          id="counterX"
                          className={classes.Counter}
                          style={{ left: 25 + i * 64.5 + "px" }}
                        >
                          {props.step === 2 && (
                            <>
                              <button
                                className={classes.TopBtn}
                                onClick={() => matrixSizeHandler("y")}
                              >
                                <img src={icons.arrowTop} alt="arrow top" />
                              </button>
                              <button
                                className={classes.BottomBtn}
                                onClick={() => matrixSizeHandlerI("y")}
                              >
                                <img
                                  src={icons.arrowBottom}
                                  alt="arrow bottom"
                                />
                              </button>
                            </>
                          )}
                          <span>
                            <input type="number" />
                          </span>
                        </div>
                      );
                    })
                    .reverse()}
                </div>

                <MatrixSvg
                  step={props.step}
                  data={matrixCell}
                  xyMatrix={matrix}
                  xySize={matrixSize}
                  handler={settingsTooltipHandler}
                />
                {settingsTooltipStatus && <Backdrop />}
                <div
                  id="settingsTooltip"
                  ref={settingsTooltipRef}
                  style={{
                    left: settingsTooltip.coords.x,
                    top: settingsTooltip.coords.y,
                  }}
                  className={
                    tooltipType
                      ? [classes.SettingsTooltip, classes.TopTooltip].join(" ")
                      : [classes.SettingsTooltip, classes.BottomTooltip].join(
                          " "
                        )
                  }
                >
                  <div
                    id="cellTooltip"
                    style={{ backgroundColor: activeType[0]?.color }}
                    className={classes.CellTooltip}
                  ></div>

                  <div className={classes.Title}>Group Settings</div>
                  <div className={classes.Form}>
                    <div className={classes.Label}>
                      <label>Label</label>
                      <input disabled type="text" defaultValue={activeLabel} />
                    </div>
                    <div className={classes.Alias}>
                      <label>Alias</label>
                      <input
                        type="text"
                        onChange={(e) => setActiveAlias(e.currentTarget.value)}
                        defaultValue={activeAlias}
                      />
                    </div>
                    <div className={classes.Status}>
                      <label>Status</label>
                      <div className={classes.SelectOption}>
                        <div className={classes.ValueFormat}>
                          <span className={classes.Name}>
                            {activeType && activeType[0]?.name}
                          </span>
                          <span
                            style={{
                              backgroundColor:
                                activeType && activeType[0]?.color,
                            }}
                            className={classes.Color}
                          ></span>
                        </div>
                        <div className={classes.ArrowBtns}>
                          <span
                            className={classes.TopSpan}
                            onClick={() => arrowTopHandler("type")}
                          >
                            <img src={icons.arrowTop} alt="arrow top" />
                          </span>
                          <span
                            className={classes.BottomSpan}
                            onClick={() => arrowBottomHandler("type")}
                          >
                            <img src={icons.arrowBottom} alt="arrow bottom" />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={classes.Output}>
                      <label>Output Value</label>
                      <div className={classes.OutputContent}>
                        <table>
                          <thead></thead>
                          <tbody>
                            <tr>
                              <td>Matrix_code:</td>
                              <td>{activeLabel}</td>
                            </tr>
                            <tr>
                              <td>X_scores:</td>
                              <td>
                                {matrixPoints.points.horizontal.min}-
                                {matrixPoints.points.horizontal.max}
                              </td>
                            </tr>
                            <tr>
                              <td>Y_scores:</td>
                              <td>
                                {matrixPoints.points.vertical.min}-
                                {matrixPoints.points.vertical.max}
                              </td>
                            </tr>
                            <tr>
                              <td>Risk:</td>
                              <td>
                                {matrix_status[activeCell?.status_id - 1]?.name}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className={classes.Button}>
                      <button onClick={matrixApplyHandler}>Apply</button>
                    </div>
                  </div>
                  <div
                    onClick={closeTooltipHandler}
                    className={classes.CloseBtn}
                  >
                    <img src={icons.closeBtn} alt="close" />
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
      <div className={classes.ButtonGroup}>
        <Button
          type="link"
          onClick={prevHandler}
          to={
            props.step === 2 ? (
              `/decision-designer/decision_matrix?id=${props.decision_id}&type=${props.type_id}&step=1`
            ) : props.step === 1 ? (
              "/decision-designer"
            ) : props.step === "final" ? (
              `/decision-designer`
            ) : (
              <Redirect to="/decision-designer" />
            )
          }
          width="12.74vh"
          height="4.43vh"
          bgColor="transparent"
          color="#2C7CF6"
          borderRadius="0.83vh"
          iconLeft={"backBtn"}
        >
          Previous
        </Button>
        {props.step === 1 && (
          <Button
            type="link"
            onClick={nextHandler}
            to={`/decision-designer/decision_matrix?id=${props.decision_id}&type=${props.type_id}&step=2`}
            width="12.74vh"
            height="4.43vh"
            bgColor="rgba(44, 124, 246, 0.08)"
            borderRadius="0.83vh"
            color="#2C7CF6"
            iconRight={"nextBtn"}
          >
            Next
          </Button>
        )}
        {props.step === 2 && (
          <>
            <Button
              type="button"
              onClick={finishHandler}
              width="12.74vh"
              height="4.43vh"
              bgColor="rgba(44, 124, 246, 0.08)"
              borderRadius="0.83vh"
              color="#2C7CF6"
              iconRight={"nextBtn"}
            >
              Finish
            </Button>
          </>
        )}
      </div>
    </Fragment>
  );
};

export default Matrix;
