import {
  ScoreCardActionsTypes,
  ScoreCardData,
  SCORECARDDATA,
  SCORECARDCATEGORYDATA,
  ScoreCardCategoryData,
  SCORECARDCRITERIADATA,
  ScoreCardCriteriaData,
  ActiveScoreCardData,
  ACTIVESCORECARDDATA,
  ScoreCardLevel,
  SCORECARDLEVEL,
  ScoreCardLevelDataId,
  ScoreCardActiveDelete,
  SCORECARDACTIVEDELETE,
  SCORE_CARD_LEVEL_DATA_ID,
  SCORECARDATTRIBUTEDATA,
  ScoreCardAttributeData,
  ScoreCardBlockedModalResponse,
  SCORE_CARD_BLOCKED_MODAL_RESPONSE,
  ScoreCardActiveElement,
  SCORE_CARD_ACTIVE_ELEMENT,
  SCORE_CARD_ACTIVE_ROW,
  ScoreCardActiveRow,
  SCORE_CARD_REMOVE_NEW_ROW,
  SCORE_CARD_CHANGE_ROW_EDITABLE_STATUS,
  SCORECARDLEVELTREE,
  ScoreCardLevelTree,
  ActiveTree,
  ACTIVETREE,
  ScoreCardRecoveryData,
  SCORE_CARD_RECOVERY_DATA,
  ScoreCardAttributeAvailability,
  SCORE_CARD_ATTRIBUTE_AVAILABILITY, SCORE_CARD_WEIGHT_EDIT_STATUS, SCORE_CARD_REMAINING_WEIGHT, SCORE_CARD_REMAINING_WEIGHT_WARNING, ScoreCardCalculatePoint, SCORE_CARD_CALCULATE_POINT, ScoreCardRemainingPointAction, SCORE_CARD_REMAINING_POINT
} from "../types/typesScoreCard";





export function handleScoreCardData(scoreCardData: ScoreCardData): ScoreCardActionsTypes {
  return {
    type: SCORECARDDATA,
    payload: { scoreCardData: scoreCardData }
  };
}
export function activeScoreCardData(activeScoreCardData: ActiveScoreCardData): ScoreCardActionsTypes {
  return {
    type: ACTIVESCORECARDDATA,
    payload: { activeScoreCardData: activeScoreCardData }
  };
}

export function handleScoreCardCategoryData(scoreCardCategoryData: ScoreCardCategoryData): ScoreCardActionsTypes {
  return {
    type: SCORECARDCATEGORYDATA,
    payload: { scoreCardCategoryData: scoreCardCategoryData }
  };
}

export function handleScoreCardCriteriaData(scoreCardCriteriaData: ScoreCardCriteriaData): ScoreCardActionsTypes {
  return {
    type: SCORECARDCRITERIADATA,
    payload: { scoreCardCriteriaData: scoreCardCriteriaData }
  };
}
export function handleScoreCardAttributeData(scoreCardAttributeData: ScoreCardAttributeData): ScoreCardActionsTypes {
  return {
    type: SCORECARDATTRIBUTEDATA,
    payload: { scoreCardAttributeData: scoreCardAttributeData }
  };
}

export function handleScoreCardLevel(scoreCardLevel: ScoreCardLevel): ScoreCardActionsTypes {
  return {
    type: SCORECARDLEVEL,
    payload: { scoreCardLevel: scoreCardLevel }
  };
}
export const handleScoreCardLevelDataId = (scoreCardLevelDataId: ScoreCardLevelDataId): ScoreCardActionsTypes => {
  return {
    type: SCORE_CARD_LEVEL_DATA_ID,
    payload: { scoreCardLevelDataId }
  }
}

export function scoreCardActiveDelete(scoreCardActiveDelete: ScoreCardActiveDelete): ScoreCardActionsTypes {
  return {
    type: SCORECARDACTIVEDELETE,
    payload: { scoreCardActiveDelete: scoreCardActiveDelete }
  };
}




export const handleScoreCardBlockedModalResponse = (scoreCardBlockedModalResponse: ScoreCardBlockedModalResponse): ScoreCardActionsTypes => {
  return {
    type: SCORE_CARD_BLOCKED_MODAL_RESPONSE,
    payload: { scoreCardBlockedModalResponse }
  }
}

export const handleScoreCardActiveElement = (scoreCardActiveElement: ScoreCardActiveElement): ScoreCardActionsTypes => {
  return {
    type: SCORE_CARD_ACTIVE_ELEMENT,
    payload: { scoreCardActiveElement }
  }
}

export const handleScoreCardActiveRow = (scoreCardActiveRow: ScoreCardActiveRow): ScoreCardActionsTypes => {
  return {
    type: SCORE_CARD_ACTIVE_ROW,
    payload: { scoreCardActiveRow }
  }
}

export const removeScoreCardNewRow = (level: number): ScoreCardActionsTypes => {
  return {
    type: SCORE_CARD_REMOVE_NEW_ROW,
    payload: { level }
  }
}

export const handleScoreCardRowEditableStatus = (level: number, id: number | null, status: boolean): ScoreCardActionsTypes => {
  return {
    type: SCORE_CARD_CHANGE_ROW_EDITABLE_STATUS,
    payload: { levelOfData: level, id, status }
  }
}

export function handleScoreCardLevelTree(scoreCardLevelTree: ScoreCardLevelTree): ScoreCardActionsTypes {
  return {
    type: SCORECARDLEVELTREE,
    payload: { scoreCardLevelTree: scoreCardLevelTree }
  };
}
export function handleActiveTree(activeTree: ActiveTree): ScoreCardActionsTypes {
  return {
    type: ACTIVETREE,
    payload: { activeTree: activeTree }
  }
}

export function handleScoreCardRecoveryData(scoreCardRecoveryData: ScoreCardRecoveryData): ScoreCardActionsTypes {
  return {
    type: SCORE_CARD_RECOVERY_DATA,
    payload: { scoreCardRecoveryData }
  };
}

export function handleScoreCardAttributeAvailability(scoreCardAttributeAvailability: ScoreCardAttributeAvailability): ScoreCardActionsTypes {
  return {
    type: SCORE_CARD_ATTRIBUTE_AVAILABILITY,
    payload: { scoreCardAttributeAvailability }
  };
}

export const handleScoreCardRowWeigthEditableStatus = (level: number, id: number | null, status: boolean): ScoreCardActionsTypes => {
  return {
    type: SCORE_CARD_WEIGHT_EDIT_STATUS,
    payload: { levelOfWeightData: level, weightRowId: id, weightRowStatus: status }
  }
}

export const calculateScoreCardRemainingWeight = (level: number, id: number | null): ScoreCardActionsTypes => {
  return {
    type: SCORE_CARD_REMAINING_WEIGHT,
    payload: {
      remainingWeightLevel: level,
      remainingWeightParentId: id
    }
  }
}

export const handleScoreCardRemainingWeightWarning = (status: boolean): ScoreCardActionsTypes => {
  return {
    type: SCORE_CARD_REMAINING_WEIGHT_WARNING,
    payload: {
      weightWarningStatus: status
    }
  }
}

export const calculateScoreCardPoint = (scoreCardCalculatePoint: ScoreCardCalculatePoint): ScoreCardActionsTypes => {
  return {
    type: SCORE_CARD_CALCULATE_POINT,
    payload: scoreCardCalculatePoint
  }
}

export const calculateScoreCardRemainingPoint = (scoreCardCalculateRemainingPoint: ScoreCardRemainingPointAction): ScoreCardActionsTypes => {
  return {
    type: SCORE_CARD_REMAINING_POINT,
    payload: scoreCardCalculateRemainingPoint
  }
}