import { callTooltip } from "./component/tooltip";

export const label_tooltip = (text:SVGTextElement):void => {


    const overHandler = (e:MouseEvent)=>{

        
        const group = text?.parentNode as SVGGElement;
        const text_length:number | undefined = text?.textContent?.length;
        if(group){
            const figure = group.querySelector('#figure') as SVGRectElement;
            const {tooltip} = callTooltip(figure,text);
            if(group){
                const check_tooltip = group.querySelector('#element_label_tooltip') as SVGForeignObjectElement
                if(!check_tooltip && text_length && text_length > 10){
                    group.appendChild(tooltip);

                }

            }

        }
        

    }
    const leaveHandler = (e:MouseEvent)=>{
        const group = text?.parentNode as SVGGElement;

        if(group){
            const check_tooltip = group.querySelector('#element_label_tooltip') as SVGForeignObjectElement
            if(check_tooltip){
                const tooltip = check_tooltip.parentNode as SVGForeignObjectElement;
                group.removeChild(tooltip);

            }

        }

    }

    text?.addEventListener('mouseover',overHandler);
    text?.addEventListener('mouseleave',leaveHandler);

}