export const domain =
  "https://api.app.scorport.eu" !==
  "REACT_" + String.fromCharCode(131 % 66) + "PP_API_URL"
    ? "https://api.app.scorport.eu"
    : "https://api.dss.dev.scorport.eu";
export const apiVersion = "/api/v1";

export const endpoints = {
  login: `${domain}/token/`,
  register: `${domain}/register/`,
  profile: {
    get: `${domain + apiVersion}/user/profile/`,
    patch: `${domain + apiVersion}/user/profile/`,
    reset_password: `${domain + apiVersion}/user/reset_password/`,
  },
  products: `${domain + apiVersion}/products/`,

  product_designer: {
    products: {
      getAll: `${domain + apiVersion}/products/`,
      get: (id: number) => `${domain + apiVersion}/products/${id}/`,
      patch: (id: number) => `${domain + apiVersion}/products/${id}/`,
      delete: (id: number) => `${domain + apiVersion}/products/${id}/`,
      clone: (id: number) => `${domain + apiVersion}/products/${id}/clone/`,
    },
    groups: {
      getAll: (productId: number) =>
        `${domain + apiVersion}/products/${productId}/groups/`,
      post: `${domain + apiVersion}/products/groups/`,
      patch: (productId: number) =>
        `${domain + apiVersion}/product_groups/${productId}/`,
      delete: (productId: number) =>
        `${domain + apiVersion}/product_groups/${productId}/`,
    },
    attribute: {
      getAll: (productId: number) =>
        `${domain + apiVersion}/products/${productId}/attributes/`,
      post: `${domain + apiVersion}/products/attributes/`,
      patch: (id: number) => `${domain + apiVersion}/product_attributes/${id}/`,
      delete: (id: number) =>
        `${domain + apiVersion}/product_attributes/${id}/`,
    },
    values: {
      getAll: (productId: number) =>
        `${domain + apiVersion}/products/${productId}/values/`,
      post: `${domain + apiVersion}/products/values/`,
      patch: (id: number) => `${domain + apiVersion}/product_values/${id}/`,
    },
  },

  decision_designer: {
    decision: {
      get: `${domain + apiVersion}/decision/`,
      post: `${domain + apiVersion}/decision/`,
      patch: (id: number) => `${domain + apiVersion}/decision/${id}/`,
      delete: (id: number) => `${domain + apiVersion}/decision/${id}/`,
      clone: (id: number) => `${domain + apiVersion}/decision/${id}/clone/`,
    },
    decision_type: {
      api: `${domain + apiVersion}/decision_type/`,
    },
    decision_matrix: {
      post: `${domain + apiVersion}/decision/matrix/`,
      patch: (id: number) => `${domain + apiVersion}/decision_matrix/${id}/`,
      get: (id: number) => `${domain + apiVersion}/decision/${id}/matrix/`,
      cell: {
        get: (id: number) =>
          `${domain + apiVersion}/decision/matrix/cell/${id}/`,
        patch: (id: number) =>
          `${domain + apiVersion}/decision/matrix/cell/${id}/`,
      },
    },
    decision_range: {
      get: (decisionId: number) =>
        `${domain + apiVersion}/decision/${decisionId}/range/`,
      post: `${domain + apiVersion}/decision/range/`,
      patch: (id: number) => `${domain + apiVersion}/decision_range/${id}/`,
      delete: (id: number) => `${domain + apiVersion}/decision_range/${id}/`,
    },
  },

  score_card: {
    score_card: {
      post: `${domain + apiVersion}/scorecard/`,
      patch: (id: number) => `${domain + apiVersion}/scorecard/${id}/`,
      get: () => `${domain + apiVersion}/scorecard/`,
      delete: (id: number) => `${domain + apiVersion}/scorecard/${id}/`,
    },
    category: {
      getAll: (scoreCardId: number | null) =>
        `${domain + apiVersion}/scorecard/${scoreCardId}/category/`,
      get: (categoryId: number) =>
        `${domain + apiVersion}/scorecard_category/${categoryId}/`,
      post: `${domain + apiVersion}/scorecard/category/`,
      patch: (categoryId: number) =>
        `${domain + apiVersion}/scorecard_category/${categoryId}/`,
      delete: (categoryId: number) =>
        `${domain + apiVersion}/scorecard_category/${categoryId}/`,
    },
    criteria: {
      getAllWithScoreId: (scoreCardId: number | null) =>
        `${domain + apiVersion}/scorecard/${scoreCardId}/criteria/`,
      getAll: (categoryId: number | null) =>
        `${domain + apiVersion}/scorecard_category/${categoryId}/criteria/`,
      get: (criteriaId: number) =>
        `${domain + apiVersion}/scorecard_criteria/${criteriaId}/`,
      post: `${domain + apiVersion}/scorecard/criteria/`,
      patch: (criteriaId: number) =>
        `${domain + apiVersion}/scorecard_criteria/${criteriaId}/`,
      delete: (criteriaId: number) =>
        `${domain + apiVersion}/scorecard_criteria/${criteriaId}/`,
    },
    criteria_type: {
      get: `${domain + apiVersion}/scorecard/criteria_type/`,
    },
    attribute: {
      getAll: (criteriaId: number | null) =>
        `${domain + apiVersion}/scorecard_criteria/${criteriaId}/attribute/`,
      get: (attributeId: number) =>
        `${domain + apiVersion}/scorecard_attribute/${attributeId}/`,
      post: `${domain + apiVersion}/scorecard/attribute/`,
      patch: (attributeId: number) =>
        `${domain + apiVersion}/scorecard_attribute/${attributeId}/`,
      delete: (attributeId: number) =>
        `${domain + apiVersion}/scorecard_attribute/${attributeId}/`,
    },
  },

  strategy_designer: {
    strategy: {
      getAll: `${domain + apiVersion}/strategy/`,
      get: (strategyId: number) =>
        `${domain + apiVersion}/strategy/${strategyId}/`,
      post: `${domain + apiVersion}/strategy/`,
      patch: (strategyId: number) =>
        `${domain + apiVersion}/strategy/${strategyId}/`,
      test: (strategyId: number) =>
        `${domain + apiVersion}/strategy/${strategyId}/test/`,
      delete: (strategyId: number) =>
        `${domain + apiVersion}/strategy/${strategyId}/`,
      archive: (strategyId: number) =>
        `${domain + apiVersion}/strategy/${strategyId}/archive`,
      clone: (strategyId: number) =>
        `${domain + apiVersion}/strategy/${strategyId}/clone/`,
    },
    strategy_source_data: {
      getAll: `${domain + apiVersion}/strategy/source_data/`,
      get: (strategyId: number) =>
        `${domain + apiVersion}/strategy/${strategyId}/source_data/`,
      post: `${domain + apiVersion}/strategy/source_data/`,
      patch: (sourceDataId: number) =>
        `${domain + apiVersion}/strategy/source_data/${sourceDataId}/`,
    },
    strategy_source_data_type: {
      getAll: `${domain + apiVersion}/strategy/source_data_type/`,
    },
    strategy_version: {
      getAll: `${domain + apiVersion}/strategy/versions/`,
    },
    strategy_status: {
      getAll: `${domain + apiVersion}/strategy/status/`,
    },
    strategy_download: {
      patch: (strategyId: number) =>
        `${domain + apiVersion}/strategy/${strategyId}/download/`,
    },
    strategy_approve: {
      get: (strategyId: number) =>
        `${domain + apiVersion}/strategy/approve/${strategyId}/`,
      patch: (strategyId: number) =>
        `${domain + apiVersion}/strategy/approve/${strategyId}/`,
    },
  },

  function: {
    function_data: {
      getAll: `${domain + apiVersion}/function/`,
    },
    function_groups: {
      getAll: `${domain + apiVersion}/function/group/`,
    },
  },
};
