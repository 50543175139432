import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import classes from "./SidePanelCondition.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  ButtonTypes,
  editorOperatorList,
  ElementTypes,
  icons,
  messages,
  ModalNames,
  operatorTypes,
  storage_key,
} from "../../../../../settings/settings";
import {
  handlerStrategyElementInput,
  handleStrategyBoardSidePanelContent,
  handleStrategyNewCreatedElementId,
} from "../../../../../store/actions/actionsStrategy";
import DataViewer from "../../../../../components/UI/DataViewer/DataViewer";
import StrategyEditor, {
  IIFunctionData,
  IIndexedKeys,
} from "../../../../../components/UI/StrategyEditor/StrategyEditor";
import StrategyFunctions from "../../../../../components/UI/StrategyFunctions/StrategyFunctions";
import { RootState } from "../../../../../store/combineReducer";
import DB from "../../../../../layout/DB/Storage";
import { remove_db } from "../../FlowChart/handlers/remove_element.handler";
import { hasChange } from "../../../../../helpers/hasChange";
import Modal from "../../../../../components/UI/Modal/Modal";
import {
  call_arrow_box_source,
  call_arrow_box_target,
} from "../../FlowChart/handlers/remove_arrow_box.handler";
import { closeModal, openModal } from "../../../../../store/actions/actionsModal";
import { checkField } from "../../../../../helpers/checkField";
import { submitElement } from "../../../../../helpers/submitElement";
import { useDebounce } from "use-debounce";
import { create_arrow } from "../../FlowChart/handlers/create_arrow";
import { Message } from "components/UI/Messages/Messages";
import { checkLabel } from "helpers/checkLabel";
import { TooltipInfo } from "components/UI/Tooltip/InfoTooltip/InfoTooltip";

interface IConditionState {
  id: string;
  label: string;
  condtBody: string;
}

type FreezeConditionState = Readonly<IConditionState>;

interface IValidating {
  label: boolean;
  condtBody: boolean;
}

enum ConditionPanelActions {
  VALIDATE,
  MANUAL_KEY,
  CLOSE,
  ERROR,
  LOGIC_OPERATOR,
}

let timeOutInstance: any;
let initialState: FreezeConditionState;

const SidePanelCondition: FC = () => {
  const dispatch = useDispatch();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const {
    reducerStrategy: {
      StrategyBoardElements: {
        ElementInput: { element_input },
        NewCreatedElement: { new_created_element },
      },
    },
    reducerModal: { modalState },
  } = useSelector((state: RootState) => state);
  const [isOpenTooltipInfo, setIsOpenTooltipInfo] = useState<boolean>(false);
  const [lastActiveEditor, setLastActiveEditor] = useState<string>();
  const [functionDescription, setFunctionDescription] = useState<string>();
  const [functionSyntax, setFunctionSyntax] = useState<string>();
  const [headerInputStatus, setHeaderInputStatus] = useState<boolean>(false);
  const [selectedFunctionBody, setSelectedFunctionBody] = useState<
    IIndexedKeys | undefined
  >();
  const [editorFunctionData, setEditorFunctionData] = useState<
    IIFunctionData | undefined
  >();
  const [labelState, setLabelState] = useState<any>({
    previous: "",
    present: "",
  });
  const [conditionPreviousData, setConditionPreviousData] = useState<any>({});
  const [conditionEditorState, setConditionEditorState] = useState<any>({
    condtBody: "",
  });
  const [conditionDataState, setConditionDataState] = useState<IConditionState>(
    {
      id: "",
      label: "",
      condtBody: "",
    }
  );
  const [validating, setValidating] = useState<IValidating>({
    label: false,
    condtBody: false,
  });
  const [
    activePanelAction,
    setActivePanelAction,
  ] = useState<ConditionPanelActions>();
  const [hasManualKey, setHasManualKey] = useState<{
    [key: string]: boolean;
  }>({ condtBody: false });
  const [nameTooltip,setNameTooltip] = useState<boolean>(false);


  const [debouncedHasManualKey] = useDebounce(hasManualKey, 5e2);

  const showInfo = (e: any) => {
    setIsOpenTooltipInfo(!isOpenTooltipInfo);
   };
   
  const handleConditionState = useCallback(
    (state: any, keys: Array<string>, values: Array<string>) => {
      let copyOfState = { ...state };

      keys?.forEach((key: string, index: number) => {
        copyOfState[key] = values[index];
      });

      setConditionDataState({ ...copyOfState });
    },
    []
  );

  const getConditionId = useCallback(
    (elementId: string) => {
      const { id } = conditionDataState;

      if (!id) {
        let condtLabel = elementId.charAt(0).toUpperCase() + elementId.slice(1);

        handleConditionState(
          conditionDataState,
          ["id", "label"],
          [elementId, condtLabel]
        );
        setLabelState({ ...labelState, present: condtLabel });
        initialState = {
          id: elementId,
          label: condtLabel,
          condtBody: "",
        };
      }
    },
    [handleConditionState, labelState, conditionDataState]
  );

  const separatePreviousSides = useCallback(
    (previousData: Array<any>) => {
      if (previousData.length > 0) {
        let elementData = previousData.find(
          (data: any) => data["branchLabel"] === "YES"
        );

        if (elementData) {
          const { data } = elementData;
          initialState = { ...data };
          setLabelState({ ...labelState, present: data.label });
          setConditionDataState({ ...data });
          setConditionEditorState({ condtBody: data?.condtBody });
        }
      }
    },
    [labelState]
  );

  const getConditionPreviousData = useCallback(
    (conditionId: string) => {
      const db = new DB(storage_key);
      const { id } = conditionDataState;

      if (!id) {
        db.fetch({ sourceId: conditionId })
          .then((result: any) => {
            const { status, data } = result;

            if (status) {
              separatePreviousSides(data);
              setConditionPreviousData([...data]);
            } else {
              console.warn(messages.messageStorageFetchStatusFalse);
            }
          })
          .catch((error) => {
            console.error(messages.messageStorageFetchError, error);
          });
      }
    },
    [conditionDataState, separatePreviousSides]
  );

  const changeInputStatus = useCallback(
    (status: boolean, labelState: any | object) => {
      const { current } = inputRef;
      setHeaderInputStatus(status);
      if (status) {
        setLabelState({ ...labelState, previous: labelState.present });
        current?.focus();
      } else {
        current?.blur();
      }
    },
    []
  );

  const manipulateInputKeyUp = useCallback(
    (event: any) => {
      const { keyCode } = event;
      const enterCode = 13;
      const escCode = 27;

      if (keyCode === enterCode) {
        changeInputStatus(false, labelState);
        handleConditionState(
          conditionDataState,
          ["label"],
          [labelState.present]
        );
        labelState.present && setValidating({ ...validating, label: false });
      } else if (keyCode === escCode) {
        changeInputStatus(false, labelState);
        setLabelState({ previous: "", present: labelState.previous });
      }
    },
    [
      labelState,
      conditionDataState,
      changeInputStatus,
      handleConditionState,
      validating,
    ]
  );

  const changeLabel = useCallback(
    (event: any) => {
      const { target } = event;
      const value = target?.value.replace(" ", "_");
      setLabelState({ ...labelState, present: value });
      if (validating.label) {
        if (value) {
          target?.parentElement?.classList.remove(classes.Validate);
        } else {
          target.parentElement?.classList.add(classes.Validate);
        }
      }
      setNameTooltip(false)

    },
    [labelState, validating]
  );

  const restoreDefaults = useCallback(() => {
    if (editorFunctionData) {
      setSelectedFunctionBody(undefined);
      setEditorFunctionData(undefined);
    }
  }, [editorFunctionData]);

  const changeConditionEditorBody = useCallback(
    (data: string | { valueIndex: string; value: string }) => {
      if (typeof data === "string") return;

      let { valueIndex, value } = data;

      setConditionEditorState((state: any) => {
        return { ...state, [valueIndex]: value };
      });
      restoreDefaults();
    },
    [restoreDefaults]
  );

  const changeConditionDataBody = useCallback((value: string) => {
    value = value.replaceAll('=','==').replaceAll("<==", "<=")
    .replaceAll(">==", ">=").replaceAll('<>','!=')
    
    setConditionDataState((state: any) => {
      return { ...state, condtBody: value };
    });
  }, []);

  const handleFunctionsDoubleClick = useCallback(
    (func: IIndexedKeys) => {
      if (lastActiveEditor) {
        setSelectedFunctionBody(func);
      }
    },
    [lastActiveEditor]
  );

  const setManualKeyStatus = useCallback(
    (status: { [key: string]: boolean }) => {
      const [key, value] = Object.entries(status)[0];

      setHasManualKey((state) => {
        return {
          ...state,
          [key]: value,
        };
      });
    },
    []
  );

  useLayoutEffect(() => {
    if (new_created_element && !element_input) {
      getConditionId(new_created_element);
    } else if (element_input) {
      getConditionPreviousData(element_input);
    }
  }, [
    new_created_element,
    element_input,
    getConditionId,
    getConditionPreviousData,
  ]);

  useEffect(() => {
    if (selectedFunctionBody && lastActiveEditor) {
      setEditorFunctionData({
        editorIndex: lastActiveEditor,
        data: selectedFunctionBody,
      });
    }
  }, [selectedFunctionBody, lastActiveEditor]);

  useEffect(() => {
    return () => clearTimeout(timeOutInstance);
  }, []);

  const handleInputClick = () => {
    if (!headerInputStatus) {
      changeInputStatus(true, labelState);
    }
  };

  const handleInputActions = (status: boolean) => {
    const data = {
      ...conditionDataState,
      label:labelState?.present
    } as any
    if(!checkLabel(data, ElementTypes.CONDITION)){ 

        changeInputStatus(false, labelState);
        if (status) {
          handleConditionState(conditionDataState, ["label"], [labelState.present]);
          labelState.present && setValidating({ ...validating, label: false });
        } else {
          setLabelState({ previous: undefined, present: labelState.previous });
        }
        setNameTooltip(false)

      }else{
        setNameTooltip(true)

      }
  };

  const closeWrapper = () => {
    const { current } = wrapperRef;

    if (current) {
      current.classList.add(classes.CloseAnimation);

      timeOutInstance = setTimeout(() => {
        dispatch(handleStrategyBoardSidePanelContent({ content_value: null }));
        dispatch(
          handleStrategyNewCreatedElementId({ new_created_element: null })
        );
        dispatch(handlerStrategyElementInput({ element_input: null }));
      }, 1000);
    }
  };

  const removeElement = () => {
    if (new_created_element) {
      const svg = document.querySelector("#svg_board") as SVGSVGElement;
      const find_element: any = svg?.querySelector(
        `rect[data-id = ${new_created_element}]`
      ) as SVGRectElement;

      const find_arrows = svg.querySelectorAll(
        `path[data-contact $= ${new_created_element}]`
      ) as NodeList;
      const find_arrows_target = svg.querySelectorAll(
        `path[data-contact ^= ${new_created_element}]`
      ) as NodeListOf<SVGPathElement>;
      let source: any = null;
      let target: any = null;
      let prev_arrow: any = null;

      if (find_element) {
        remove_db(find_element,find_arrows_target);
        if (find_arrows?.length !== 0) {
          find_arrows.forEach((arrow: any) => {
            const id = arrow.dataset.contact;
            source = svg.querySelector(
              `rect[data-id = ${id?.split("-")?.[0]}]`
            );
            let find_text = svg.querySelectorAll(
              `text[id = "${id}"]`
            ) as NodeList;
            find_text?.forEach((text: any) => {
              svg.removeChild(text);
            });
            svg.removeChild(arrow);
          });
        }
        if (find_arrows_target?.length !== 0) {
          find_arrows_target.forEach((arrow: any) => {
            const id = arrow.dataset.contact;
            target = svg.querySelector(
              `rect[data-id = ${id?.split("-")?.[1]}]`
            );
            prev_arrow = arrow;

            let find_text = svg.querySelectorAll(
              `text[id = "${id}"]`
            ) as NodeList;
            find_text?.forEach((text: any) => {
              svg.removeChild(text);
            });

            svg.removeChild(arrow);
          });
        }

        svg.removeChild(find_element.parentNode);

        if (source && target) {
          create_arrow(source, target, prev_arrow, svg);
        } else {
          call_arrow_box_source(find_arrows);
          call_arrow_box_target(find_arrows_target);
        }
      }
    }
  };

  const checkChanges = () => {
    setIsOpenTooltipInfo(false);
    hasChange({
      initial: initialState,
      changeable: conditionDataState,
    })
      .then(() => {
        dispatch(
          openModal({
            modalState: {
              visible: true,
              title: messages.titleClosePanel,
              question: messages.titleSureClosePanel,
              message: messages.messagePanelClosing,
              buttonMessage: messages.titleBtnClose,
              buttonType: ButtonTypes.PRIMARY,
              name: ModalNames.CONDITION_PANEL,
            },
          })
        );
        setActivePanelAction(ConditionPanelActions.CLOSE);
      })
      .catch((error) => {
        error && console.error(error);
        closeWrapper();
        removeElement();
      });
  };

  const handleModalAction = (action: boolean) => {
    switch (activePanelAction) {
      case ConditionPanelActions.VALIDATE: {
        setValidating((state: IValidating) => {
          return {
            ...state,
            [modalState?.validatingField as string]: true,
          };
        });
        break;
      }
      case ConditionPanelActions.CLOSE: {
        if (!action) return;

        closeWrapper();
        removeElement();
      }
    }

    dispatch(closeModal());
  };

  const checkLogicOperatorAvailability = () => {
    return editorOperatorList
      .filter(({ type }) => {
        return (
          type === operatorTypes.COMPARISON || type === operatorTypes.LOGICAL
        );
      })
      .map(({ dataSymbol }) => {
        return conditionDataState.condtBody.includes(dataSymbol);
      })
      .some((value: boolean) => {
        return value;
      });
  };

  const validateData = () =>
    new Promise((resolve, reject) => {
      if (Object.values(debouncedHasManualKey).some((value) => value)) {
        dispatch(
          openModal({
            modalState: {
              visible: true,
              title: messages.titleAttention,
              question: messages.titleManualKey,
              message: messages.messageManualKey,
              buttonMessage: messages.titleUnderstand,
              buttonType: ButtonTypes.WARNING,
              name: ModalNames.CONDITION_PANEL,
            },
          })
        );
        setActivePanelAction(ConditionPanelActions.MANUAL_KEY);
        return reject;
      }

      if (!checkLogicOperatorAvailability()) {
        dispatch(
          openModal({
            modalState: {
              visible: true,
              title: messages.titleAttention,
              question: messages.titleNotLogicOperator,
              message: "",
              buttonMessage: messages.titleUnderstand,
              buttonType: ButtonTypes.WARNING,
              name: ModalNames.CONDITION_PANEL,
            },
          })
        );
        setActivePanelAction(ConditionPanelActions.LOGIC_OPERATOR);
        return reject;
      }

      checkField({ state: conditionDataState, types: ElementTypes.CONDITION })
        .then(resolve)
        .catch(({ reason }) => {
          dispatch(
            openModal({
              modalState: {
                visible: true,
                title: messages.titleAttention,
                question: messages.titleMustBeFilled,
                message: messages.messagePanelEmptyAttention,
                buttonMessage: messages.titleUnderstand,
                buttonType: ButtonTypes.WARNING,
                validatingField: reason,
                name: ModalNames.CONDITION_PANEL,
              },
            })
          );
          setActivePanelAction(ConditionPanelActions.VALIDATE);
        });
    });

  const handleSubmit = () => {
    validateData().then(() => {
      submitElement(
        new_created_element
          ? {
              id: new_created_element,
              state: conditionDataState,
              type: ElementTypes.CONDITION,
              isNew: true,
            }
          : {
              id: element_input,
              state: conditionDataState,
              previousData: conditionPreviousData,
              type: ElementTypes.CONDITION,
              isNew: false,
            }
      )
        .then(closeWrapper)
        .catch(() => {
          dispatch(
            openModal({
              modalState: {
                visible: true,
                title: messages.titleOops,
                question: messages.titleWentWrong,
                message: messages.messagePanelSubmittingError,
                buttonMessage: messages.titleAgain,
                buttonType: ButtonTypes.DELETE,
                name: ModalNames.CONDITION_PANEL,
              },
            })
          );
          setActivePanelAction(ConditionPanelActions.ERROR);
        });
    });
  };

  return (
    <div ref={wrapperRef} className={classes.ConditionWrapper}>
      <section className={classes.ConditionHeader}>
      {nameTooltip && <Message style={{left:1.15,top:5.6}}/>}

        <div
          className={`${classes.HeaderTitleWrapper} ${
            headerInputStatus ? classes.ActiveTitle : ""
          } ${validating.label ? classes.Validate : ""}`}
        >
          <input
            ref={inputRef}
            type="text"
            className={classes.HeaderTitleInput}
            title={labelState.present}
            value={labelState.present}
            onChange={changeLabel}
            onKeyUp={manipulateInputKeyUp}
            onClick={handleInputClick}
          />
          {headerInputStatus && (
            <>
              <svg
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => handleInputActions(true)}
              >
                <path d="M.115 4.23A.415.415 0 010 3.963c0-.077.038-.193.115-.27l.539-.538a.372.372 0 01.538 0l.039.038 2.115 2.27a.186.186 0 00.27 0L8.768.115h.039a.372.372 0 01.538 0l.539.539a.372.372 0 010 .538L3.73 7.577a.35.35 0 01-.27.115.35.35 0 01-.269-.115l-3-3.23-.077-.116z" />
              </svg>
              <svg
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => handleInputActions(false)}
              >
                <path d="M5.88 4.997l3.93-3.93a.625.625 0 10-.883-.884l-3.93 3.93-3.93-3.93a.625.625 0 10-.884.884l3.93 3.93-3.93 3.93a.625.625 0 10.884.884l3.93-3.93 3.93 3.93a.623.623 0 00.884 0 .625.625 0 000-.884l-3.93-3.93z" />
              </svg>
            </>
          )}
        </div>

        <div className={classes.HeaderActions}>
          {element_input && (
            <>
              <button
                className={classes.HeaderBackButton}
                onClick={() => closeWrapper()}
              >
                <span>Back</span>
                <img src={icons.nextBtnGrey} alt="Arrow right icon" />
              </button>
            </>
          )}
        </div>
      </section>
      <section className={classes.ConditionBody}>
        <aside className={classes.ConditionLeftSide}>
          <DataViewer />
          <span className={classes.SpacerNormal} />
          <StrategyFunctions
            onSingleClick={(description: string, syntax: string) => {
              setFunctionDescription(description);
              setFunctionSyntax(syntax);
            }}
            onDoubleClick={handleFunctionsDoubleClick}
          />
        </aside>
        <div className={classes.ConditionRightSide}>
          <StrategyEditor
            label="If (Condition Case)"
            height="47.91vh"
            value={conditionEditorState?.condtBody.replaceAll('==','=').replaceAll('!=','<>')}
            valueIndex="condtBody"
            getValueIndex={(id: string) => {
              setLastActiveEditor(id);
            }}
            onChange={changeConditionEditorBody}
            functionData={editorFunctionData}
            validate={validating.condtBody}
            editorModalName={ModalNames.EDITOR_CONDITION_BODY}
            elementType={ElementTypes.CONDITION}
            getManualKeyStatus={setManualKeyStatus}
            getIndexedValue={changeConditionDataBody}
            onSingleClick={(description: string, syntax: string) => {
              setFunctionDescription(description);
              setFunctionSyntax(syntax);
            }}
          />
          <span className={classes.SpacerNormal} />
          <span className={classes.DescriptionLabel}>Function description</span>
          {functionDescription ? (
            <>
              <span className={classes.SpacerMin} />
              <span className={classes.Description}>{functionDescription}</span>
              <span className={classes.Syntax}>{functionSyntax}</span>
              <span className={classes.SpacerAll} />
            </>
          ) : (
            <span className={classes.SpacerAll} />
          )}
          <button className={classes.SubmitButton} onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </section>
      {isOpenTooltipInfo && <TooltipInfo sidePanelUp />}
      <button className={classes.ConditionCloseButton} onClick={checkChanges}>
        <img src={icons.closeBtn} alt="Close icon" />
      </button>
      <svg
            className={classes.HelpIcon}
            viewBox='0 0 8 8'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            onClick={(e) => showInfo(e)}
          >
            <path
              d='M4 6.30469C4.21574 6.30469 4.39062 6.1298 4.39062 5.91406C4.39062 5.69833 4.21574 5.52344 4 5.52344C3.78426 5.52344 3.60938 5.69833 3.60938 5.91406C3.60938 6.1298 3.78426 6.30469 4 6.30469Z'
              fill='white'
              fillOpacity='0.6'
            />      
            <path
              d='M4 2.00781C3.31075 2.00781 2.75 2.56856 2.75 3.25781C2.75 3.43041 2.88991 3.57031 3.0625 3.57031C3.23509 3.57031 3.375 3.43041 3.375 3.25781C3.375 2.91319 3.65538 2.63281 4 2.63281C4.34462 2.63281 4.625 2.91319 4.625 3.25781C4.625 3.60244 4.34462 3.88281 4 3.88281C3.82741 3.88281 3.6875 4.02272 3.6875 4.19531V4.97656C3.6875 5.14916 3.82741 5.28906 4 5.28906C4.17259 5.28906 4.3125 5.14916 4.3125 4.97656V4.46827C4.85103 4.32916 5.25 3.8392 5.25 3.25781C5.25 2.56856 4.68925 2.00781 4 2.00781Z'
              fill='white'
              fillOpacity='0.6'
            />
          </svg> 
      <Modal
        title={modalState?.title}
        question={modalState?.question}
        message={modalState?.message}
        buttonMessage={modalState?.buttonMessage}
        buttonType={modalState?.buttonType}
        onAction={handleModalAction}
        visible={modalState?.visible}
        name={ModalNames.CONDITION_PANEL}
      />
    </div>
  );
};

export default SidePanelCondition;
