import { arrow_type } from "../../../../../../../../settings/settings";
import { chaining_storage } from "../../../add_storage.handler";
import { getAttr } from "../../../methods/attributes";
import { remove_arrow_box } from "../../../remove_arrow_box.handler";
import { Massive } from "../../types";


export const arrow_right_top = <T extends Massive>(first: T, last: T, arrow: SVGPathElement,arrows:NodeListOf<SVGPathElement>|null = null):void => {
    const svg = document.querySelector(`#svg_board`) as SVGElement;

    const { x: first_x, y: first_y, width: first_width, height: first_height, id: first_id, type: first_type } = getAttr(first.element);
    const { x: last_x, y: last_y,height:last_height, width: last_width, id: last_id } = getAttr(last.element);
    if(first_x && first_y  && first_height && first_width && first_id && first_type && last_x && last_y  && last_height && last_width && last_id){

    
        let m = `M${first_x+first_width+12} ${first_y+first_height/2}`;
        let h = `h${((last_x + last_width/2) - (first_x+first_width+12))}`;
        let v = `v${(last_y+last_height+21) - (first_y+first_height/2)}`;
        const d = [m,h,v,arrow_type.top].join(',');
        arrow.setAttributeNS(null,'data-side','right-top');
        arrow.setAttributeNS(null,'d',d);
        svg.insertBefore(arrow,svg.firstChild);
        chaining_storage(first.element);

        remove_arrow_box(first_id,last_id,'right-top',first_type,arrows);
    }
}
