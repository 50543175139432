import { ElementTypes } from "../settings/settings";

interface ICheckField {
  state: any;
  types: ElementTypes;
}

export const checkField = ({ state, types }: ICheckField) => {
  return new Promise<void>((resolve, reject) => {
    switch (types) {
      case ElementTypes.FUNCTION: {
        const { resultVar, funcTxt } = state;

        resultVar && funcTxt
          ? resolve()
          : reject({ reason: resultVar ? "funcTxt" : "resultVar" });

        break;
      }
      case ElementTypes.CONDITION: {
        const { label, condtBody } = state;

        label && condtBody
          ? resolve()
          : reject({ reason: label ? "condtBody" : "label" });

        break;
      }
      case ElementTypes.MAP: {
        const { label, mappData } = state;
        const mappDataFilter = ({ paramLabel }: any, index: number) => {
          if (paramLabel) return false;

          emptyIndices.push(index);
          return true;
        };
        let emptyIndices: Array<number> = [];

        if (!label || !mappData?.length) {
          return reject({ reason: label ? "mappData" : "label" });
        } else if (mappData?.filter(mappDataFilter).length) {
          return reject({ reason: "mappData", indices: emptyIndices });
        } else {
          return resolve();
        }
      }
      case ElementTypes.PRODUCT: {
        const { label, mappData } = state;
        const mappDataFilter = ({ product_group }: any, index: number) => {
          if (product_group) return false;

          emptyIndices.push(index);
          return true;
        };
        let emptyIndices: Array<number> = [];

        if (!label || !mappData?.length) {
          return reject({ reason: label ? "mappData" : "label" });
        } else if (mappData?.filter(mappDataFilter).length) {
          return reject({ reason: "mappData", indices: emptyIndices });
        } else {
          return resolve();
        }
      }
      case ElementTypes.DECISION_RANGE: {
        const { label, decision_id, map_obj } = state;

        if (!label) {
          return reject({ reason: "label" });
        }

        if (!map_obj) {
          return reject({ reason: "map_obj" });
        }

        if (!decision_id) {
          return reject({ reason: "decision_id" });
        }

        resolve();
        break;
      }
      case ElementTypes.DECISION_MATRIX: {
        const { label, mappData } = state;
        const rangeDataFilter = ({ decision, product }: any, index: number) => {
          if (decision && product) return false;
          !decision
            ? emptyIndices.push({ index, field: "decision" })
            : !product && emptyIndices.push({ index, field: "product" });
          return true;
        };
        let emptyIndices: Array<object> = [];

        if (!label || !mappData?.length) {
          return reject({ reason: label ? "mappData" : "label" });
        } else if (mappData?.filter(rangeDataFilter).length) {
          return reject({ reason: "mappData", indices: emptyIndices });
        } else {
          return resolve();
        }
      }
      case ElementTypes.DATA_WIZARD: {
        const { parentKey, validation } = state;
        const {
          role_condition,
          re_prepare_func,
          code_message,
        }: any = Object.values(validation)[0];

        if (!parentKey || !role_condition) {
          return reject({ reason: parentKey ? "role_condition" : "parentKey" });
        } else if (!re_prepare_func && (!code_message.code || !code_message.message)) {
          return reject({
            reason: "optionalField",
          });
        } else if(re_prepare_func && (code_message.code || code_message.message)){
          return reject({
            reason: "orField",
          });
        }else {
          return resolve();
        }
      }
    }
  });
};
