import React, { DragEvent, FC, MouseEvent, ChangeEvent } from "react";
import classes from "./Tree.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";
import Container from "../RightTree/TreeList/TreeList";
type ItemType = {
  type: string;
  uuid: string;
  // parent_id: string;
  id: string;
  key: string;
  value: string | object[];
};

interface TreeProps {
  dropItemHandler?: (e: DragEvent<HTMLDivElement>) => void;
  clickHandler?: (e: MouseEvent<HTMLDivElement>) => void;
  dragOverItemHandler?: (e: DragEvent<HTMLDivElement>) => void;
  dragLeaveItemHandler?: (e: DragEvent<HTMLDivElement>) => void;
  dragStartItemHandler: (e: DragEvent<HTMLDivElement>, item: ItemType) => void;
  editItemHandler: (e: MouseEvent<SVGSVGElement>, item: ItemType) => void;
  deleteItemHandler: (e: MouseEvent<SVGSVGElement>, item: ItemType) => void;
  saveItemHandler: (e: MouseEvent<SVGSVGElement>, item: ItemType) => void;
  cancelItemHandler: (e: MouseEvent<SVGSVGElement>, item: ItemType) => void;
  changeInputHandler: (
    e: ChangeEvent<HTMLInputElement>,
    data: ItemType,
    type: string
  ) => void;
  dropHandler?: (e: DragEvent<HTMLDivElement>) => void;
  sortHandler: (e: DragEvent<HTMLDivElement>, item: ItemType) => void;
}
const Tree: FC<TreeProps> = ({
  dropHandler,
  dropItemHandler,
  clickHandler,
  dragOverItemHandler,
  dragLeaveItemHandler,
  dragStartItemHandler,
  editItemHandler,
  deleteItemHandler,
  saveItemHandler,
  cancelItemHandler,
  changeInputHandler,
  sortHandler,
}) => {
  const {
    reducerStrategy: {
      StrategyBoardElements: {
        StrategyOutputFormatData: { data },
      },
    },
  } = useSelector((state: RootState) => state);

  return (
    <aside id="aside" className={classes.Aside}>
      <Container
        data={data}
        changeInputHandler={changeInputHandler}
        dragStartHandler={dragStartItemHandler}
        saveItemHandler={saveItemHandler}
        cancelItemHandler={cancelItemHandler}
        editItemHandler={editItemHandler}
        deleteItemHandler={deleteItemHandler}
        dragOverItemHandler={dragOverItemHandler}
        dragLeaveItemHandler={dragLeaveItemHandler}
        dropItemHandler={dropItemHandler}
        clickHandler={clickHandler}
        sortHandler={sortHandler}
      />
      <div onDrop={dropHandler} id="dropzone" className={classes.Dropzone}>
        Drop item
      </div>
    </aside>
  );
};
export default Tree;
