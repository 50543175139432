import { storage_key } from "../../../../../settings/settings";
import DB from "../../../../DB/Storage";
import { getAttr } from "./methods/attributes";

export const create_arrow = (source_element: SVGRectElement, target_element: SVGRectElement, arrow: SVGPathElement, svg: SVGSVGElement) => {
    if (source_element && target_element && arrow && svg) {
        const { x, width, height, y } = getAttr(source_element);
        const { x: x_t, y: y_t, width: width_t, height: height_t } = getAttr(target_element);

        const source_id = source_element?.dataset?.id as string;
        const target_id = target_element?.dataset?.id as string;
        const side = arrow?.dataset?.side as string;

        const coord = arrow?.getAttribute('d') as string;
        const coord_mass = coord?.split(',') as string[];
        if (x && x_t && width && width_t && height && height_t && y && y_t && side) {

            if (side.startsWith('right')) {
                let [, my] = coord_mass?.[0]?.split(' ');

                if (side.split('-')?.length === 1) {
                    let h = Math.abs((x_t - 32) - (x + width)) / 2
                    let new_m = `M${x + width + 12} ${my}`;
                    coord_mass[0] = new_m;
                    coord_mass[1] = `h${h}`;
                    coord_mass[3] = `h${h}`;
                } else if (side.split('-')?.length === 2) {
                    if (side.endsWith('bottom') || side.endsWith('top')) {
                        let h = Math.abs((x_t + width_t / 2) - (x + width + 12))
                        let new_m = `M${x + width + 12} ${my}`;
                        coord_mass[0] = new_m;
                        coord_mass[1] = `h${h}`;
                    }
                } else if (side.split('-')?.length === 3) {

                    if (side.endsWith('left')) {
                        let new_m = `M${x + width + 12} ${my}`;
                        coord_mass[0] = new_m;

                        if (x < x_t) {
                            let h1 = `h${(x_t + width_t + 21 + 12 + 25) - (x + width + 12)}`;
                            let h2 = `h-${12 + 25}`;
                            coord_mass[1] = h1;
                            coord_mass[3] = h2;
                        } else {
                            let h1 = `h${12 + 25}`;
                            let h2 = `h-${Math.abs((x + width + 12 + 25 + 12) - (x_t + width_t + 21))}`;
                            coord_mass[1] = h1;
                            coord_mass[3] = h2;

                        }
                    }
                }


            } else if (side.startsWith('left')) {
                let [, my] = coord_mass?.[0]?.split(' ');

                if (side.split('-')?.length === 1) {
                    let h = `h-${Math.abs((x - 12) - (x_t + width_t + 9)) / 2 - 4}`;
                    let new_m = `M${x - 12} ${my}`;
                    coord_mass[0] = new_m;
                    coord_mass[1] = h;
                    coord_mass[3] = h;
                } else if (side.split('-')?.length === 2) {
                    if (side.endsWith('bottom') || side.endsWith('top')) {
                        let h = `h${(x_t + width_t / 2) - (x - 12)}`;
                        let new_m = `M${x - 12} ${my}`;
                        coord_mass[0] = new_m;
                        coord_mass[1] = h
                    }
                } else if (side.split('-')?.length === 3) {

                    if (side.endsWith('right')) {
                        let new_m = `M${x - 12} ${my}`;
                        coord_mass[0] = new_m;

                        if (x < x_t) {
                            let h1 = `h-${12 + 25}`;
                            let h2 = `h${Math.abs((x - (12 + 25 + 12)) - (x_t - 21))}`;
                            coord_mass[1] = h1;
                            coord_mass[3] = h2;
                        } else {
                            let h1 = `h-${Math.abs((x_t - (21 + 12 + 25)) - (x - 12))}`;
                            let h2 = `h${12 + 25}`;
                            coord_mass[1] = h1;
                            coord_mass[3] = h2;

                        }
                    }
                }


            } else if (side.startsWith('top')) {

                if (side.split('-')?.length === 1) {
                    let v = `v${((y_t + height_t + 12) - (y - 21)) / 2}`;

                    let new_m = `M${x + width / 2} ${y - 12}`;
                    coord_mass[0] = new_m;
                    coord_mass[1] = v;
                    coord_mass[3] = v;
                } else if (side.split('-')?.length === 2) {
                    if (side.endsWith('left') || side.endsWith('right')) {
                        let v = `v${((y_t + height_t / 2) - (y - 12))}`;
                        let new_m = `M${x + width / 2} ${y - 12}`;
                        coord_mass[0] = new_m;
                        coord_mass[1] = v
                    }
                } else if (side.split('-')?.length === 3) {

                    if (side.endsWith('bottom')) {
                        let new_m = `M${x + width / 2} ${y - 12}`;
                        coord_mass[0] = new_m;


                        if (y > y_t) {
                            let v1 = `v-${Math.abs((y - 12 + 25 + 12) - (y_t - 21))}`;
                            let v2 = `v${12 + 25}`;
                            coord_mass[1] = v1;
                            coord_mass[3] = v2;
                        } else {
                            let v1 = `v-${12 + 25}`;
                            let v2 = `v${Math.abs((y_t - 9) - (y - (12 + 25)))}`;
                            coord_mass[1] = v1;
                            coord_mass[3] = v2;

                        }
                    }
                }


            } else if (side.startsWith('bottom')) {

                if (side.split('-')?.length === 1) {
                    let new_m = `M${x + width / 2} ${y + height + 12}`;
                    let v = `v${Math.abs(((y_t - 12) - (y + height + 21)) / 2)}`;

                    coord_mass[0] = new_m;
                    coord_mass[1] = v;
                    coord_mass[3] = v;
                } else if (side.split('-')?.length === 2) {
                    if (side.endsWith('left') || side.endsWith('right')) {
                        let v = `v${((y_t + height_t / 2) - (y + height + 12))}`;
                        let new_m = `M${x + width / 2} ${y + height + 12}`;
                        coord_mass[0] = new_m;
                        coord_mass[1] = v
                    }
                } else if (side.split('-')?.length === 3) {

                    if (side.endsWith('top')) {
                        let new_m = `M${x + width / 2} ${y + height + 12}`;
                        coord_mass[0] = new_m;


                        if (y > y_t) {

                            let v1 = `v${12 + 25}`;
                            let v2 = `v-${Math.abs((12 + 25) + ((y + height + 12) - (y_t + height_t + 21)))}`;
                            coord_mass[1] = v1;
                            coord_mass[3] = v2;
                        } else {
                            let v1 = `v${(12 + 25) + Math.abs((y_t + height_t + 9) - (y + height))}`;
                            let v2 = `v-${12 + 25}`;
                            coord_mass[1] = v1;
                            coord_mass[3] = v2;

                        }
                    }
                }


            }

        }
        if (arrow) {
            arrow.dataset.contact = `${source_id}-${target_id}` as string;
            arrow.setAttribute('d', coord_mass.join(','));
        }
        let _data = localStorage.getItem(storage_key) as string;
        let ref_data = [...JSON.parse(_data)];
        let findItem = ref_data?.filter((f: any) => f.sourceId === source_id) as any[];
        if (findItem.length === 1) {
            let new_source_element = {
                ...findItem[0],
                targetId: target_id,
                overlays: [
                    {
                        side: side, coord: coord_mass?.join(','), contact: `${source_id}-${target_id}`
                    }
                ]
            }
            new DB(storage_key).update({ sourceId: source_id }, [new_source_element])
        }else if(findItem.length === 2){
            const data_bool = arrow?.dataset?.bool as string;
            const find_item = findItem?.filter((f:any)=>f.branchLabel === data_bool?.toUpperCase()) as any[];
            if(find_item){
                let new_source_element = {
                    ...find_item[0],
                    targetId: target_id,
                    overlays: [
                        {
                            side: side, coord: coord_mass?.join(','), contact: `${source_id}-${target_id}`
                        }
                    ]
                }
                new DB(storage_key).update({ sourceId: source_id,branchLabel:data_bool.toUpperCase()}, [new_source_element])
            }
        }
        svg?.insertBefore(arrow, svg?.firstChild);

    }
}