import React, {CSSProperties, FC} from "react";
import classes from "./Button.module.scss";
import { Link } from "react-router-dom";
import { icons } from "../../../settings/settings";

interface IButton {
  type?: string;
  width?: string;
  height?: string;
  bgColor?: string;
  borderRadius?: string;
  color?: string;
  to?: any;
  iconLeft?: any;
  iconRight?: any;
  position?: CSSProperties['position'];
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
  onClick?: () => void;
  overHandler?: () => void;
}

const Button: FC<IButton> = ({
  type,
  width,
  height,
  bgColor,
  color,
  borderRadius,
  to,
  iconLeft,
  iconRight,
  position,
  left,
  right,
  top,
  bottom,
  onClick,
  overHandler,
  children,
}) => {
  const style = {
    width,
    height,
    backgroundColor: bgColor,
    borderRadius,
    color,
    position,
    left,
    right,
    top,
    bottom,
  } as React.CSSProperties;
  return (
    <>
      {type === "link" && (
        <div
          style={style}
          onClick={onClick}
          className={[classes.Button, classes.ButtonLink].join(" ")}
        >
          <Link style={{ color: color }} to={to}>
          {iconLeft && <img style={{marginRight:"1vh"}} src={icons.backBtn} alt='back'/>}
            {children}
            {iconRight && <img  style={{marginLeft:"1vh"}} src={icons.nextBtn} alt='next'/>}
          </Link>
        </div>
      )}
      {type === "button" && (
        <button style={style} id="finish_button" onMouseOver={overHandler} onClick={onClick} className={classes.Button}>
           {iconLeft && <img style={{marginRight:"1vh"}} src={icons.backBtn} alt='back'/>}
          {children}
          {iconRight && <img  style={{marginLeft:"1vh"}} src={icons.nextBtn} alt='next'/>}
        </button>
      )}
    </>
  );
};

export default Button;
