import { FC, useEffect, useState } from "react";
import { IUserDetails, TNavList, UserProfileNavList } from "./config";
import {
  Container,
  Header,
  LeftContent,
  LeftHide,
  List,
  Navbar,
  NavbarFooter,
  Notf,
  RightContent,
  Section,
  Title,
} from "./UserProfile.style";
import { Route, Switch, useHistory } from "react-router-dom";
import { toSlug } from "helpers/slug.helper";
import { EditProfile } from "./EditProfile/EditProfile";
import { ChangePassword } from "./ChangePassword/ChangePassword";
import { BellIcon, PenIcon, PlanIcon, SecurityIcon } from "components/UI/Svg/Svg";
export const UserProfile: FC = () => {
  const [stateUserDetails, setStateUserDetails] = useState<IUserDetails>({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    zip: "",
    address: "",
    city: "",
    country: "",
    state: "",
    email: "",
    profile_image: "",
    password: "",
    user: "",
  });
  const [stateStatusNotf,setStateStatusNotf] = useState<boolean>(false)
  const sessionUserDetails: string | null =
    sessionStorage.getItem("user_details");
  const history = useHistory();

  useEffect(() => {
    const userData = JSON.parse(sessionUserDetails ?? "") as IUserDetails;
    if (!userData) return;
    setStateUserDetails({
      ...userData,
    });
  }, [sessionUserDetails]);

  const handleActiveLink = (item: TNavList) => {
    history.push({
      pathname: `/profile/${toSlug(item.title)}`,
    });
  };

  const handleNotf = (status:boolean)=>{
    setStateStatusNotf(status)
    setTimeout(()=>setStateStatusNotf(!status),5000);
  }
  return (
    <Container>
      <Header>
        <Title>My Profile</Title>
      </Header>
      <Section>
        <LeftHide>
          <LeftContent>
            <Navbar>
              {UserProfileNavList?.map((item: any) => {
                return (
                  <List
                    key={item.id}
                    active={
                      `/profile/${item.slug}` === history.location.pathname
                        ? true
                        : false
                    }
                    onClick={() => handleActiveLink(item)}
                  > 
                    {item.id === 1 && <PenIcon/>}
                    {item.id === 2 && <BellIcon/>}
                    {item.id === 3 && <PlanIcon/>}
                    {item.id === 4 && <SecurityIcon/>}
                    {item.title}
                  </List>
                );
              })}
            </Navbar>
            <NavbarFooter />
          </LeftContent>
        </LeftHide>
        <RightContent>
          <Switch>
            <Route path={"/profile/edit-profile"}>
              <EditProfile propsUserDetails={stateUserDetails} actionStatus={(status:boolean)=>handleNotf(status)} />
            </Route>

            <Route path={"/profile/change-password"}>
              <ChangePassword stateUserDetails={stateUserDetails} actionStatus={(status:boolean)=>handleNotf(status)} />
            </Route>
          </Switch>
          {stateStatusNotf && <Notf>Saved changes</Notf>}
        </RightContent>
      </Section>
    </Container>
  );
};
