import {storage_key} from "settings/settings";

export const checkLabel = (state:any,type:string) => {
    const {id:_id} = state;
    let label = type === 'func' ? state?.resultVar : state?.label
    const boardElementsJSON = localStorage.getItem(storage_key);
    if (!boardElementsJSON) return true;
    const boardElementsArray = JSON.parse(boardElementsJSON) as Array<any[]>;
    let result:boolean = false;
    boardElementsArray?.forEach((item:any)=>{
        const {data} = item;
        let name = item.type === 'func' ? data.resultVar : data.label
        if(name !== undefined && data.id !== _id){
            if(name === label || label === 'startpoint' || label === 'endpoint' || label === 'output'){
                result = true;
            }

        }
    })

    return result
}