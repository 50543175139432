export function toSlug(txt:string):string{
  return txt
    .trim()
    .toLowerCase()
    .replace(/\s+/g, "-")
    // eslint-disable-next-line no-useless-escape
    .replace(/[^\w\-]+/g, "")
    // eslint-disable-next-line no-useless-escape
    .replace(/\-\-+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
}
