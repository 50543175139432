import React, { FC, memo, useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { decision_status, icons, matrix_status } from "../../../../settings/settings";
import { endpoints } from "../../../../api/endpoints";
import { request } from "../../../../helpers/request";
import classes from "./Table.module.scss";
import {
  handleRangeReload,
  handleRangeActiveElement,
  handleRangeActiveRow,
} from "../../../../store/actions/actionsDecision";
import { RootState } from "../../../../store/combineReducer";
import Tooltip from "../../Tooltip/Tooltip";
import {
  handleActiveTooltip,
  touchBlocked,
} from "../../../../store/actions/action";

interface Props {
  data: any;
  setState?: any;
}

const Table: FC<Props> = ({ data, setState }) => {
  const dispatch = useDispatch();
  const {
    decision_designer: {decision_range },
  } = endpoints;
  const {
    reducer: {
      mainTooltip: { childId, type },
    },
    reducerDecision: {
      decisionRanges,
      rangeActiveElement: { name },
      rangeActiveRow: { rowIndex, newRow },
    },
  } = useSelector((state: RootState) => state);
  const [activeElementId, setActiveElementId] = useState<
    string | null | undefined
  >(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const copyState = [...data];

  const rowHandleChange = (index: number, status: boolean) => {
    if (name !== null && rowIndex !== index) {
      dispatch(
        handleActiveTooltip({
          childId: name,
          childDataId: null,
          type: "saveChanges",
        })
      );
    }
    if (name === null) {
      copyState[index] = { ...copyState[index], editable: status };
      setState([...copyState]);

      dispatch(handleRangeActiveRow({ rowIndex: index, newRow: false }));
    }
  };

  const aliasHandleChange = (event: any, index: number, newRow: boolean) => {
    dispatch(
      handleRangeActiveElement({ name: `alias-${index}`, elementIndex: index })
    );
    dispatch(touchBlocked({ status: true }));
    dispatch(handleRangeActiveRow({ rowIndex: index, newRow }));

    if (newRow) dispatch(handleRangeActiveRow({ rowIndex: index, newRow }));
    if (!newRow)
      dispatch(handleRangeActiveRow({ rowIndex: index, newRow: false }));

    copyState[index] = { ...copyState[index], alias: event.target.value };
    setState([...copyState]);
    if (event.target.value.length >= 0) {
      dispatch(
        handleActiveTooltip({
          childId: `alias-${index}`,
          type: null,
          childDataId: null,
        })
      );
    }
    if (event.target.value.length === 0) {
      dispatch(
        handleActiveTooltip({
          childId: `alias-${index}`,
          type: "validation",
          childDataId: null,
        })
      );
    }
  };

  const fromHandleChange = (event: any, index: number, newRow: boolean) => {
    dispatch(
      handleRangeActiveElement({ name: `from-${index}`, elementIndex: index })
    );
    dispatch(touchBlocked({ status: true }));

    if (newRow) dispatch(handleRangeActiveRow({ rowIndex: index, newRow }));
    if (!newRow)
      dispatch(handleRangeActiveRow({ rowIndex: index, newRow: false }));

    copyState[index] = {
      ...copyState[index],
      FROM: event.target.value.toString(),
    };
    setState([...copyState]);
    if (event.target.value.length >= 0) {
      dispatch(
        handleActiveTooltip({
          childId: `from-${index}`,
          type: null,
          childDataId: null,
        })
      );
    }
    if (event.target.value.length === 0) {
      dispatch(
        handleActiveTooltip({
          childId: `from-${index}`,
          type: "validation",
          childDataId: null,
        })
      );
    }
  };

  const toHandleChange = (event: any, index: number, newRow: boolean) => {
    dispatch(
      handleRangeActiveElement({ name: `to-${index}`, elementIndex: index })
    );
    dispatch(touchBlocked({ status: true }));

    if (newRow) dispatch(handleRangeActiveRow({ rowIndex: index, newRow }));
    if (!newRow)
      dispatch(handleRangeActiveRow({ rowIndex: index, newRow: false }));

    copyState[index] = {
      ...copyState[index],
      TO: event.target.value.toString(),
    };
    setState([...copyState]);

    if (event.target.value.length >= 0) {
      dispatch(
        handleActiveTooltip({
          childId: `to-${index}`,
          type: null,
          childDataId: null,
        })
      );
    }

    if (event.target.value.length === 0) {
      dispatch(
        handleActiveTooltip({
          childId: `to-${index}`,
          type: "validation",
          childDataId: null,
        })
      );
    }
  };

  const selectArrowTopHandler = (index: number, newRow: boolean) => {
    let counter = data[index].status_id;

    dispatch(
      handleRangeActiveElement({ name: `status-${index}`, elementIndex: index })
    );
    dispatch(
      handleActiveTooltip({
        childId: `status-${index}`,
        type: null,
        childDataId: null,
      })
    );
    dispatch(touchBlocked({ status: true }));

    if (newRow) dispatch(handleRangeActiveRow({ rowIndex: index, newRow }));
    if (!newRow)
      dispatch(handleRangeActiveRow({ rowIndex: index, newRow: false }));

    if (counter > 1) {
      copyState[index] = { ...copyState[index], status_id: counter - 1 };
      setState([...copyState]);
    }
  };

  const selectArrowBottomHandler = (index: number, newRow: boolean) => {
    let counter = data[index].status_id;

    dispatch(
      handleRangeActiveElement({ name: `status-${index}`, elementIndex: index })
    );
    dispatch(
      handleActiveTooltip({
        childId: `status-${index}`,
        type: null,
        childDataId: null,
      })
    );
    dispatch(touchBlocked({ status: true }));

    if (newRow) dispatch(handleRangeActiveRow({ rowIndex: index, newRow }));
    if (!newRow)
      dispatch(handleRangeActiveRow({ rowIndex: index, newRow: false }));

    if (counter < 3) {
      copyState[index] = { ...copyState[index], status_id: counter + 1 };
      setState([...copyState]);
    }
  };

  const applyHandler = useCallback(
    (index: number | any, newRow: boolean) => {
      const { id, label, alias, FROM, TO, decision_id, status_id } = data[
        index
      ];

      dispatch(handleRangeActiveRow({ rowIndex: index, newRow: newRow }));

      if ((type === null || type === "saveChanges") && alias.length === 0) {
        dispatch(
          handleRangeActiveElement({
            name: `alias-${index}`,
            elementIndex: index,
          })
        );
        dispatch(
          handleActiveTooltip({
            childId: `alias-${index}`,
            type: "validation",
            childDataId: null,
          })
        );
        let passiveInput = document.getElementById(`alias-${index}`);
        passiveInput?.focus();
      }

      if (
        (type === null || type === "saveChanges") &&
        TO === "0" &&
        alias.length !== 0
      ) {
        dispatch(
          handleRangeActiveElement({ name: `to-${index}`, elementIndex: index })
        );
        dispatch(
          handleActiveTooltip({
            childId: `to-${index}`,
            type: "validation",
            childDataId: null,
          })
        );
        let passiveInput = document.getElementById(`to-${index}`);
        passiveInput?.focus();
      }

      if (
        newRow &&
        (type === null || type === "saveChanges") &&
        alias.length !== 0 &&
        TO !== "0"
      ) {
        request
          .post(decision_range.post, {
            decision_id,
            status_id,
            label,
            alias,
            FROM,
            TO,
          })
          .then((result) => {
            const { response } = result;
            if (response) {
              dispatch(handleRangeReload(true));
              dispatch(
                handleActiveTooltip({
                  childId: null,
                  type: null,
                  childDataId: null,
                })
              );
              dispatch(
                handleRangeActiveElement({ name: null, elementIndex: null })
              );
              dispatch(touchBlocked({ status: false }));
              dispatch(handleRangeActiveRow({ rowIndex: null, newRow: false }));
            }
          })
          .catch((error) => console.log("error " + error));
      }

      if (!newRow && (type === null || type === "saveChanges") && TO !== "0") {
        request
          .patch(decision_range.patch(id), {
            decision_id,
            status_id,
            label,
            alias,
            FROM,
            TO,
          })
          .then((result) => {
            const { response } = result;
            if (response) {
              dispatch(handleRangeReload(true));
              dispatch(
                handleActiveTooltip({
                  childId: null,
                  type: null,
                  childDataId: null,
                })
              );
              dispatch(
                handleRangeActiveElement({ name: null, elementIndex: null })
              );
              dispatch(touchBlocked({ status: false }));
              dispatch(handleRangeActiveRow({ rowIndex: null, newRow: false }));
            }
          })
          .catch((error) => console.log("error " + error));
      }
    },
    [data, decision_range, dispatch, type]
  );

  const cancelHandler = useCallback(
    (index: number | any, newRow: boolean) => {
      let rangesArray;
      const { decision_id } = data[index];

      if (newRow) {
        copyState.pop();
        setState([...copyState]);
      }
      if (!newRow) {
        rangesArray = decisionRanges.filter((range: any) => {
          return decision_id.toString() === range.decisionId;
        });

        copyState[index] = { ...rangesArray[0].decisionRangesData[index] };
        setState([...copyState]);
      }

      dispatch(
        handleActiveTooltip({ childId: null, type: null, childDataId: null })
      );
      dispatch(handleRangeActiveElement({ name: null, elementIndex: null }));
      dispatch(touchBlocked({ status: false }));
      dispatch(handleRangeActiveRow({ rowIndex: null, newRow: false }));
    },
    [copyState, decisionRanges, dispatch, setState, data]
  );

  const deleteHandler = (index: number) => {
    const { id } = data[index];

    if (name !== null && rowIndex !== index) {
      dispatch(
        handleActiveTooltip({
          childId: name,
          childDataId: null,
          type: "saveChanges",
        })
      );
    }
    if (name === null && rowIndex === null) {
      dispatch(
        handleRangeActiveElement({
          name: `delete-${index}`,
          elementIndex: index,
        })
      );
      dispatch(
        handleActiveTooltip({
          childId: `delete-${index}`,
          childDataId: id,
          type: "deleteConfirm",
        })
      );
      dispatch(handleRangeActiveRow({ rowIndex: index, newRow: false }));
    }
  };

  useEffect(() => {
    if (type !== null && type !== "saveChanges" && childId !== null) {
      setActiveElementId(name);
      let activeRowElement = document.getElementById(`${name}`);
      let activeElementRowChildren =
        activeRowElement?.parentNode?.parentNode?.parentNode?.childNodes;
      let tableRows =
        activeRowElement?.parentNode?.parentNode?.parentNode?.parentNode
          ?.childNodes;

      activeElementRowChildren?.forEach((element: any) => {
        let neighborButtonElement = element.firstChild as HTMLElement;
        if (
          neighborButtonElement &&
          neighborButtonElement.nodeName === "BUTTON" &&
          type === "validation"
        ) {
          neighborButtonElement.style.opacity = ".4";
        }
      });

      tableRows?.forEach((element: any) => {
        element.childNodes.forEach((childElement: any) => {
          if (childElement.childNodes && childElement.childNodes[0]) {
            let el = childElement.childNodes[0].firstChild as HTMLElement;
            if (el && el.id && el.id !== name && el.nodeName === "INPUT") {
              el.setAttribute("disabled", "true");
            }

            if (childElement.childNodes[0].nodeName === "BUTTON") {
              let firstButtonElement = childElement
                .childNodes[0] as HTMLElement;
              firstButtonElement.setAttribute("disabled", "true");
            }

            if (
              childElement.childNodes[1] &&
              childElement.childNodes[1].firstChild &&
              childElement.lastChild.firstChild.nodeName === "BUTTON"
            ) {
              let secondButtonElement = childElement.childNodes[1]
                .firstChild as HTMLElement;
              secondButtonElement.setAttribute("disabled", "true");
            }
          }
        });
      });
    }

    if (type === null) {
      let activeRowElement = document.getElementById(`${activeElementId}`);
      let activeElementRowChildren =
        activeRowElement?.parentNode?.parentNode?.parentNode?.childNodes;
      let tableRows =
        activeRowElement?.parentNode?.parentNode?.parentNode?.parentNode
          ?.childNodes;

      if (name !== null) {
        activeElementRowChildren?.forEach((element: any) => {
          let neighborElement = element.childNodes[0].firstChild as HTMLElement;
          if (
            neighborElement &&
            neighborElement.id &&
            neighborElement.id !== childId
          ) {
            neighborElement.removeAttribute("disabled");
          }
        });
      }

      activeElementRowChildren?.forEach((element: any) => {
        let neighborButtonElement = element.firstChild as HTMLElement;
        if (
          neighborButtonElement &&
          neighborButtonElement.nodeName === "BUTTON"
        ) {
          neighborButtonElement.style.opacity = "1";
        }
      });

      tableRows?.forEach((element: any) => {
        element.childNodes.forEach((childElement: any) => {
          if (
            childElement.childNodes &&
            childElement.childNodes[0] &&
            childElement.childNodes[1]
          ) {
            if (childElement.childNodes[0].nodeName === "BUTTON") {
              let firstButtonElement = childElement
                .childNodes[0] as HTMLElement;
              firstButtonElement.removeAttribute("disabled");
            }

            if (
              childElement.childNodes[1].firstChild &&
              childElement.lastChild.firstChild.nodeName === "BUTTON"
            ) {
              let secondButtonElement = childElement.childNodes[1]
                .firstChild as HTMLElement;
              secondButtonElement.removeAttribute("disabled");
            }
          }
        });
      });
    }
  }, [type, name, childId, activeElementId]);

  useEffect(() => {
    if (name === "range_blocked_modal_cancel")
      document.getElementById(`cancel-${rowIndex}`)?.click();

    if (name === "range_blocked_modal_save") {
      if (newRow) document.getElementById(`apply-${rowIndex}`)?.click();

      if (!newRow) document.getElementById(`apply-${rowIndex}`)?.click();
    }
  }, [name, rowIndex, newRow]);

  return (
    <div className={classes.Table}>
      <div className={classes.Head}>
        <div style={{ width: "5vw" }} className={classes.Column}>
          label
        </div>
        <div style={{ width: "30vw" }} className={classes.Column}>
          alias
        </div>
        <div style={{ width: "15vw" }} className={classes.Column}>
          from
        </div>
        <div style={{ width: "15vw" }} className={classes.Column}>
          to
        </div>
        <div style={{ width: "10vw" }} className={classes.Column}>
          status
        </div>
        <div style={{ width: "25vw" }} className={classes.Column}></div>
      </div>
      {data.map((element: any, index: number) => {
        const { label, alias, FROM, TO, status_id, newRow, editable } = element;

        return (
          <div key={index} className={classes.Row}>
            <div className={classes.Col} style={{ width: "5vw" }}>
              {matrix_status.map((element: any, index: number) => {
                const { id, color } = element;

                if (status_id === id)
                  return (
                    <span key={index} style={{ backgroundColor: `${color}` }}>
                      {label}
                    </span>
                  );
                else return null;
              })}
            </div>
            <div className={classes.Col} style={{ width: "30vw" }}>
              <Tooltip
                children={
                  <input
                    id={`alias-${index}`}
                    aria-label={`alias-${index}`}
                    type="text"
                    value={alias}
                    onChange={(event) =>
                      aliasHandleChange(event, index, newRow)
                    }
                    disabled={
                      !editable || (name !== null && rowIndex !== index)
                        ? true
                        : false
                    }
                  />
                }
              />
            </div>
            <div className={classes.Col} style={{ width: "15vw" }}>
              <Tooltip
                children={
                  <input
                    id={`from-${index}`}
                    aria-label={`from-${index}`}
                    type="number"
                    value={FROM && parseFloat(FROM)}
                    onChange={(event) => fromHandleChange(event, index, newRow)}
                    disabled={
                      !editable || (name !== null && rowIndex !== index)
                        ? true
                        : false
                    }
                  />
                }
              />
            </div>
            <div className={classes.Col} style={{ width: "15vw" }}>
              <Tooltip
                children={
                  <input
                    id={`to-${index}`}
                    aria-label={`to-${index}`}
                    type="number"
                    value={TO && parseFloat(TO)}
                    onChange={(event) => toHandleChange(event, index, newRow)}
                    disabled={
                      !editable || (name !== null && rowIndex !== index)
                        ? true
                        : false
                    }
                  />
                }
              />
            </div>
            <div className={classes.Col} style={{ width: "10vw" }}>
              <Tooltip
                children={
                  <div id={`status-${index}`} className={classes.statusWrapper}>
                    {decision_status.map((element: any, index: number) => {
                      const { id, name } = element;

                      if (id === status_id)
                        return (
                          <span
                            key={index}
                            className={classes[name.toLowerCase()]}
                          >
                            {name}
                          </span>
                        );
                      else return null;
                    })}
                    <img
                      className={
                        editable && (type === null || type === "saveChanges")
                          ? name !== null && rowIndex !== index
                            ? classes.deactive
                            : classes.arrowTop
                          : classes.deactive
                      }
                      src={icons.arrowTop}
                      alt="Arrow top icon"
                      onClick={() => selectArrowTopHandler(index, newRow)}
                    />
                    <img
                      className={
                        editable && (type === null || type === "saveChanges")
                          ? name !== null && rowIndex !== index
                            ? classes.deactive
                            : classes.arrowBottom
                          : classes.deactive
                      }
                      src={icons.arrowBottom}
                      alt="Arrow bottom icon"
                      onClick={() => selectArrowBottomHandler(index, newRow)}
                    />
                  </div>
                }
              />
            </div>
            <div className={classes.Col} style={{ width: "25vw" }}>
              {editable ? (
                <>
                  <button
                    id={`apply-${index}`}
                    className={classes.apply}
                    onClick={() => applyHandler(index, newRow)}
                    style={
                      name !== null && rowIndex !== index
                        ? { opacity: ".4" }
                        : { opacity: "1" }
                    }
                    disabled={
                      name !== null && rowIndex !== index ? true : false
                    }
                  >
                    Apply
                  </button>
                  <button
                    id={`cancel-${index}`}
                    className={classes.cancel}
                    onClick={() => cancelHandler(index, newRow)}
                    style={
                      name !== null && rowIndex !== index
                        ? { opacity: ".4" }
                        : { opacity: "1" }
                    }
                    disabled={
                      name !== null && rowIndex !== index ? true : false
                    }
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <>
                  <button
                    className={classes.edit}
                    onClick={() => rowHandleChange(index, true)}
                  >
                    Edit
                  </button>
                  <Tooltip
                    children={
                      <button
                        id={`delete-${index}`}
                        className={classes.delete}
                        onClick={() => deleteHandler(index)}
                      >
                        Delete
                      </button>
                    }
                  />
                </>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default memo(Table);
