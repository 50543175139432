import React, {
  FC,
  useEffect,
  useState,
  useCallback,
  useLayoutEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./Table.module.scss";
import { icons } from "../../../settings/settings";
import Tooltip from "../../../components/UI/Tooltip/Tooltip";
import {
  activeModal as handleActiveModal,
  handleActiveTooltip,
  touchBlocked,
} from "../../../store/actions/action";
import {
  handleScoreCardLevel,
  handleScoreCardLevelDataId,
  handleScoreCardCriteriaData,
  handleScoreCardAttributeData,
  handleScoreCardBlockedModalResponse,
  handleScoreCardActiveElement,
  handleScoreCardActiveRow,
  removeScoreCardNewRow,
  handleScoreCardRowEditableStatus,
  handleScoreCardRecoveryData,
  handleScoreCardAttributeAvailability,
  handleScoreCardRowWeigthEditableStatus,
  calculateScoreCardRemainingWeight,
  handleScoreCardRemainingWeightWarning,
  calculateScoreCardPoint,
  calculateScoreCardRemainingPoint,
} from "../../../store/actions/actionsScoreCard";
import { RootState } from "../../../store/combineReducer";
import { request } from "../../../helpers/request";
import { endpoints } from "../../../api/endpoints";
import {
  Pen,
  Delete,
  Active,
  Cancel,
  NextArrowRight,
} from "../../../components/UI/Svg/Svg";

const CriteriaLevel: FC = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const {
    reducer: {
      mainTooltip: { type },
    },
    reducerScoreCard: {
      scoreCardCriteriaData: { scoreCardCriteriaData },
      scoreCardAttributeData: { scoreCardAttributeData },
      scoreCardLevel: { level },
      scoreCardLevelDataId: { scoreCardId, categoryId },
      scoreCardActiveRow: { rowIndex, rowType, rowId },
      scoreCardActiveElement,
      scoreCardRecoveryData: { recoveryData },
      scoreCardBlockedModalResponse: { response },
      scoreCardAtrributeAvailability,
      scoreCardRemainingWeight,
      scoreCardRemainingWeightWarning: { weightWarningStatus },
      scoreCardRemainingPoint,
    },
  } = state;

  const [criteriaTypes, setCriteriaTypes] = useState<Array<any>>([]);
  const [criteriaTrusted] = useState<Array<any>>([{key:'Dynamic',value:false},{key:'Static',value:true}]);

  let copyState: any[] = [];

  const refreshHandlerCallback = useCallback(
    (id: number | null) => {
      const {
        score_card: { criteria },
      } = endpoints;

      if (id !== null) {
        const dataWithoutActiveCategory = scoreCardCriteriaData?.filter(
          (element: any) => element.score_card_category_id !== id
        );

        request
          .get(criteria.getAll(id))
          .then((result) => {
            const { response } = result;
            const combinedData = dataWithoutActiveCategory?.concat(response);
            dispatch(
              handleScoreCardCriteriaData({
                scoreCardCriteriaData: combinedData,
              })
            );
            dispatch(calculateScoreCardRemainingWeight(level, id));
            dispatch(
              calculateScoreCardRemainingPoint({
                remainingPointLevel: level,
                remainingPointParentId: id,
              })
            );
          })
          .catch((error) => {
            console.log("error " + error);
          });

        dispatch(
          handleScoreCardActiveRow({
            rowIndex: null,
            rowType: null,
            rowId: null,
          })
        );
        dispatch(handleScoreCardActiveElement({ name: null }));
        dispatch(
          handleActiveTooltip({ childId: null, childDataId: null, type: null })
        );
        dispatch(touchBlocked({ status: false }));
        dispatch(handleScoreCardRecoveryData({ recoveryData: null }));
      }
    },
    [dispatch, scoreCardCriteriaData, level]
  );

  const applyHandlerCallback = useCallback(
    (id: number | null) => {
      const {
        score_card: { criteria },
      } = endpoints;

      let newData = scoreCardCriteriaData?.find(
        (element: any) => element.newRow === true
      );
      let updatedData = scoreCardCriteriaData?.find(
        (element: any) => element.editable === true && element.newRow === false
      );
      let weightUpdatedData = scoreCardCriteriaData?.find(
        (element: any) => element.weightEditStatus === true
      );

      if (newData && !newData.name) {
        dispatch(
          handleActiveTooltip({
            childId: `criteria-name-${rowIndex}`,
            childDataId: null,
            type: "validation",
          })
        );
        dispatch(handleScoreCardBlockedModalResponse({ response: null }));
      }

      if (
        newData &&
        newData.name &&
        type !== "validation" &&
        scoreCardRemainingWeight?.weight >= 0
      ) {
        request
          .post(criteria.post, newData)
          .then(() => {
            refreshHandlerCallback(id);
          })
          .catch((error) => {
            console.log("error ", error);
          });
      }

      if (updatedData && scoreCardRemainingWeight?.weight >= 0) {
        request
          .patch(criteria.patch(updatedData.id), updatedData)
          .then(() => {
            refreshHandlerCallback(id);
          })
          .catch((error) => {
            console.log("error ", error);
          });
      }

      if (weightUpdatedData && scoreCardRemainingWeight?.weight >= 0) {
        request
          .patch(criteria.patch(weightUpdatedData.id), weightUpdatedData)
          .then(() => {
            refreshHandlerCallback(id);
          })
          .catch((error) => {
            console.log("error ", error);
          });
      }
    },
    [
      dispatch,
      rowIndex,
      type,
      refreshHandlerCallback,
      scoreCardCriteriaData,
      scoreCardRemainingWeight,
    ]
  );

  const cancelHandlerCallback = useCallback(
    (rowType: string | null, rowId: number | null, level: number) => {
      if (rowType === "new") {
        dispatch(removeScoreCardNewRow(level));
      }

      if (rowType === "edit") {
        let specificData = scoreCardCriteriaData?.find(
          (element: any) => element.id === rowId
        );
        let copyState = [...scoreCardCriteriaData];
        let indexOfSpecificData = copyState.indexOf(specificData);

        copyState[indexOfSpecificData] = recoveryData;
        dispatch(
          handleScoreCardCriteriaData({ scoreCardCriteriaData: copyState })
        );
        dispatch(handleScoreCardAttributeAvailability({ status: false }));
        dispatch(handleScoreCardRowEditableStatus(level, rowId, false));
        dispatch(handleScoreCardRowWeigthEditableStatus(level, rowId, false));
      }

      if (rowType !== null) {
        dispatch(handleScoreCardBlockedModalResponse({ response: null }));
        dispatch(
          handleActiveTooltip({ childId: null, childDataId: null, type: null })
        );
        dispatch(
          handleScoreCardActiveRow({
            rowIndex: null,
            rowType: null,
            rowId: null,
          })
        );
        dispatch(handleScoreCardActiveElement({ name: null }));
        dispatch(handleScoreCardRecoveryData({ recoveryData: null }));
        dispatch(touchBlocked({ status: false }));
        dispatch(calculateScoreCardRemainingWeight(level, categoryId));
        dispatch(
          calculateScoreCardRemainingPoint({
            remainingPointLevel: level,
            remainingPointParentId: categoryId,
          })
        );
      }
    },
    [dispatch, recoveryData, scoreCardCriteriaData, categoryId]
  );

  const deleteHandlerCallback = useCallback(
    (index: number | null, id: number | null, elementId: string | null) => {
      const {
        score_card: { criteria },
      } = endpoints;

      if (scoreCardActiveElement.name === null && type === null) {
        dispatch(handleScoreCardActiveElement({ name: elementId }));
        dispatch(
          handleScoreCardActiveRow({
            rowIndex: index,
            rowType: "delete",
            rowId: id,
          })
        );

        if (response === null) {
          dispatch(handleActiveModal({ activeModal: "delete_score_modal" }));
        }
      }

      if (response === "delete" && id !== null) {
        dispatch(handleScoreCardBlockedModalResponse({ response: null }));
        request
          .delete(criteria.delete(id))
          .then(() => {
            refreshHandlerCallback(categoryId);
          })
          .catch((error) => {
            console.log("error ", error);
          });
      }

      if (scoreCardActiveElement.name !== null && type !== "validation") {
        if (type === null)
          dispatch(
            handleActiveTooltip({
              childId: scoreCardActiveElement.name,
              childDataId: null,
              type: "saveChanges",
            })
          );
        if (type === "saveChanges") {
          dispatch(
            handleActiveTooltip({
              childId: null,
              childDataId: null,
              type: null,
            })
          );
          setTimeout(() => {
            dispatch(
              handleActiveTooltip({
                childId: scoreCardActiveElement.name,
                childDataId: null,
                type: "saveChanges",
              })
            );
          }, 50);
        }
      }
    },
    [
      dispatch,
      refreshHandlerCallback,
      response,
      categoryId,
      type,
      scoreCardActiveElement.name,
    ]
  );

  const keyboardHandlerCallback = useCallback(
    (event: any) => {
      const { keyCode } = event;

      if (keyCode === 27) {
        cancelHandlerCallback(rowType, rowId, level);
      }

      if (keyCode === 13) {
        applyHandlerCallback(categoryId);
      }
    },
    [
      level,
      rowType,
      rowId,
      applyHandlerCallback,
      cancelHandlerCallback,
      categoryId,
    ]
  );

  const mouseClickHandlerCallback = useCallback(
    (event: any) => {
      const {
        target: { localName, id },
      } = event;

      if (localName === "img" && id === "score_blocked_modal_close") {
        dispatch(handleScoreCardActiveElement({ name: null }));
        dispatch(
          handleScoreCardActiveRow({
            rowIndex: null,
            rowType: null,
            rowId: null,
          })
        );
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (criteriaTypes.length === 0) {
      request
        .get(endpoints.score_card.criteria_type.get)
        .then((result) => {
          const { response } = result;
          setCriteriaTypes(response);
        })
        .catch((error) => {
          console.log("error " + error);
        });
    }
  }, [criteriaTypes.length]);

  useEffect(() => {
    window.addEventListener("click", mouseClickHandlerCallback);
    window.addEventListener("keydown", keyboardHandlerCallback);

    return () => {
      window.removeEventListener("click", mouseClickHandlerCallback);
      window.removeEventListener("keydown", keyboardHandlerCallback);
    };
  }, [type, dispatch, keyboardHandlerCallback, mouseClickHandlerCallback]);

  useEffect(() => {
    if (response === "save") {
      applyHandlerCallback(categoryId);
    }

    if (response === "cancel") {
      cancelHandlerCallback(rowType, rowId, level);
    }

    if (response === "delete") {
      deleteHandlerCallback(rowIndex, rowId, scoreCardActiveElement.name);
    }

    if (response === "cancel_delete") {
      dispatch(handleScoreCardActiveElement({ name: null }));
      dispatch(handleScoreCardBlockedModalResponse({ response: null }));
      dispatch(
        handleScoreCardActiveRow({ rowIndex: null, rowType: null, rowId: null })
      );
      dispatch(
        handleActiveTooltip({ childId: null, childDataId: null, type: null })
      );
    }
  }, [
    dispatch,
    response,
    rowIndex,
    rowType,
    rowId,
    level,
    categoryId,
    applyHandlerCallback,
    cancelHandlerCallback,
    deleteHandlerCallback,
    scoreCardActiveElement.name,
  ]);

  useEffect(() => {
    dispatch(calculateScoreCardRemainingWeight(level, categoryId));
    dispatch(
      calculateScoreCardRemainingPoint({
        remainingPointLevel: level,
        remainingPointParentId: categoryId,
      })
    );
  }, [dispatch, level, categoryId]);

  useLayoutEffect(() => {
    if (scoreCardRemainingWeight.weight < 0) {
      dispatch(handleScoreCardRemainingWeightWarning(true));
    }
  }, [scoreCardRemainingWeight.weight, dispatch]);

  useLayoutEffect(() => {
    let timer: NodeJS.Timeout;

    if (weightWarningStatus) {
      timer = setTimeout(() => {
        dispatch(handleScoreCardRemainingWeightWarning(false));
      }, 3000);
    }

    return () => clearTimeout(timer);
  }, [weightWarningStatus, dispatch]);

  const numberTopArrowHandler = (
    id: number,
    level: number,
    inputName: string,
    elementId: string,
    index: number
  ) => {
    if (
      (scoreCardActiveElement.name === null ||
        scoreCardActiveElement.name === elementId ||
        rowIndex === index) &&
      type !== "validation"
    ) {
      if (scoreCardRemainingWeight?.weight > 0) {
        dispatch(handleScoreCardActiveElement({ name: elementId }));
        dispatch(touchBlocked({ status: true }));

        if (inputName === "weight") {
          dispatch(handleScoreCardRowWeigthEditableStatus(level, id, true));
          dispatch(
            handleScoreCardActiveRow({
              rowIndex: index,
              rowType: "edit",
              rowId: id,
            })
          );

          if (recoveryData === null) {
            let specificData = scoreCardCriteriaData.find(
              (element: any) => element.id === id
            );
            dispatch(
              handleScoreCardRecoveryData({ recoveryData: specificData })
            );
          }
        }

        let specificData = scoreCardCriteriaData.find(
          (element: any) => element.id === id
        );
        let copyState = [...scoreCardCriteriaData];
        let copyOfSpecificData = { ...specificData };
        let indexOfSpecificData = copyState.indexOf(specificData);
        let stepNumber =
          scoreCardRemainingWeight?.weight < 1
            ? scoreCardRemainingWeight?.weight
            : 1;

        copyOfSpecificData.weight = `${(
          +copyOfSpecificData.weight + stepNumber
        ).toFixed(2)}`;

        copyState[indexOfSpecificData] = copyOfSpecificData;
        dispatch(
          handleScoreCardCriteriaData({ scoreCardCriteriaData: copyState })
        );
        dispatch(calculateScoreCardRemainingWeight(level, categoryId));
        dispatch(
          calculateScoreCardPoint({
            levelOfPointData: level,
            pointRowId: id,
            pointParentId: categoryId,
          })
        );
        dispatch(
          calculateScoreCardRemainingPoint({
            remainingPointLevel: level,
            remainingPointParentId: categoryId,
          })
        );
      }

      if (scoreCardRemainingWeight?.weight === 0) {
        dispatch(handleScoreCardRemainingWeightWarning(true));
      }

      dispatch(
        handleActiveTooltip({ childId: null, childDataId: null, type: null })
      );
    }

    if (
      scoreCardActiveElement.name !== elementId &&
      type !== "validation" &&
      rowIndex !== index
    ) {
      if (type === null)
        dispatch(
          handleActiveTooltip({
            childId: scoreCardActiveElement.name,
            childDataId: null,
            type: "saveChanges",
          })
        );
      if (type === "saveChanges") {
        dispatch(
          handleActiveTooltip({ childId: null, childDataId: null, type: null })
        );
        setTimeout(() => {
          dispatch(
            handleActiveTooltip({
              childId: scoreCardActiveElement.name,
              childDataId: null,
              type: "saveChanges",
            })
          );
        }, 50);
      }
    }
  };

  const numberBottomArrowHandler = (
    id: number,
    level: number,
    inputName: string,
    elementId: string,
    index: number
  ) => {
    if (
      (scoreCardActiveElement.name === null ||
        scoreCardActiveElement.name === elementId ||
        rowIndex === index) &&
      type !== "validation"
    ) {
      dispatch(handleScoreCardActiveElement({ name: elementId }));
      dispatch(touchBlocked({ status: true }));

      if (inputName === "weight") {
        dispatch(handleScoreCardRowWeigthEditableStatus(level, id, true));
        dispatch(
          handleScoreCardActiveRow({
            rowIndex: index,
            rowType: "edit",
            rowId: id,
          })
        );

        if (recoveryData === null) {
          let specificData = scoreCardCriteriaData.find(
            (element: any) => element.id === id
          );
          dispatch(handleScoreCardRecoveryData({ recoveryData: specificData }));
        }
      }

      let specificData = scoreCardCriteriaData.find(
        (element: any) => element.id === id
      );
      let copyState = [...scoreCardCriteriaData];
      let copyOfSpecificData = { ...specificData };
      let indexOfSpecificData = copyState.indexOf(specificData);

      if (inputName === "weight")
        copyOfSpecificData.weight = `${
          +copyOfSpecificData.weight > 1
            ? (+copyOfSpecificData.weight - 1).toFixed(2)
            : "0"
        }`;

      copyState[indexOfSpecificData] = copyOfSpecificData;
      dispatch(
        handleScoreCardCriteriaData({ scoreCardCriteriaData: copyState })
      );
      dispatch(calculateScoreCardRemainingWeight(level, categoryId));
      dispatch(
        calculateScoreCardPoint({
          levelOfPointData: level,
          pointRowId: id,
          pointParentId: categoryId,
        })
      );
      dispatch(
        calculateScoreCardRemainingPoint({
          remainingPointLevel: level,
          remainingPointParentId: categoryId,
        })
      );
      dispatch(
        handleActiveTooltip({ childId: null, childDataId: null, type: null })
      );
    }

    if (
      scoreCardActiveElement.name !== elementId &&
      type !== "validation" &&
      rowIndex !== index
    ) {
      if (type === null)
        dispatch(
          handleActiveTooltip({
            childId: scoreCardActiveElement.name,
            childDataId: null,
            type: "saveChanges",
          })
        );
      if (type === "saveChanges") {
        dispatch(
          handleActiveTooltip({ childId: null, childDataId: null, type: null })
        );
        setTimeout(() => {
          dispatch(
            handleActiveTooltip({
              childId: scoreCardActiveElement.name,
              childDataId: null,
              type: "saveChanges",
            })
          );
        }, 50);
      }
    }
  };

  const typeTopArrowHandler = (id: string, index: number) => {
    const typeName = document.getElementById(id)?.innerHTML;
    const presentIndex = criteriaTypes.findIndex(
      (element: any) => element.name === typeName
    );
    const previousIndex = presentIndex - 1;
    const firstIndex = 0

    if (presentIndex === firstIndex) {
      return;
    }

    copyState = [...scoreCardCriteriaData.filter(
        (element: any) => element.score_card_category_id === categoryId
    )];

      copyState[index].criteria_type_id = criteriaTypes[previousIndex].id;

    dispatch(handleScoreCardCriteriaData({ scoreCardCriteriaData: copyState }));
    dispatch(handleScoreCardActiveElement({ name: id }));
    dispatch(touchBlocked({ status: true }));
  };

  const typeBottomArrowHandler = (id: string, index: number) => {
    const typeName = document.getElementById(id)?.innerHTML;
    const presentIndex = criteriaTypes.findIndex(
      (element: any) => element.name === typeName
    );
    const nextIndex = presentIndex + 1;
    const lastIndex = criteriaTypes.length - 1;

    if (presentIndex === lastIndex) {
      return;
    }

    copyState = [...scoreCardCriteriaData.filter(
        (element: any) => element.score_card_category_id === categoryId
    )];

    copyState[index].criteria_type_id = criteriaTypes[nextIndex].id;

    dispatch(handleScoreCardCriteriaData({ scoreCardCriteriaData: copyState }));
    dispatch(handleScoreCardActiveElement({ name: id }));
    dispatch(touchBlocked({ status: true }));
  };


  const trustedTopArrowHandler = (id:string, index: number) => {
  
    const typeName = document.getElementById(id)?.innerHTML;
    const presentIndex = criteriaTrusted.findIndex(
      (element: any) => element.key === typeName
    );
    const previousIndex = presentIndex - 1;

    const firstIndex = 0

    if (presentIndex === firstIndex) {
      return;
    }

    copyState = [...scoreCardCriteriaData.filter(
        (element: any) => element.score_card_category_id === categoryId
    )];

    copyState[index].is_trusted_weight = criteriaTrusted[previousIndex]?.value;

    dispatch(handleScoreCardCriteriaData({ scoreCardCriteriaData: copyState }));
    dispatch(handleScoreCardActiveElement({ name: id }));
    dispatch(touchBlocked({ status: true }));
  };

  const trustedBottomArrowHandler = (id:string,index: number) => {
    
    const typeName = document.getElementById(id)?.innerHTML;
    const presentIndex = criteriaTrusted.findIndex(
      (element: any) => element.key === typeName
    );
    const nextIndex = presentIndex + 1;
    const lastIndex = criteriaTrusted.length - 1;

    if (presentIndex === lastIndex) {
      return;
    }

    copyState = [...scoreCardCriteriaData.filter(
        (element: any) => element.score_card_category_id === categoryId
    )];

    copyState[index].is_trusted_weight = criteriaTrusted[nextIndex]?.value;
    dispatch(handleScoreCardCriteriaData({ scoreCardCriteriaData: copyState }));
    dispatch(handleScoreCardActiveElement({ name: id }));
    dispatch(touchBlocked({ status: true }));
  };

  const nextBtnHandler = (id: number | null, criteriaType: number | null) => {
    const {
      score_card: { attribute },
    } = endpoints;

    if (scoreCardActiveElement.name === null && type === null) {
      dispatch(
        handleScoreCardLevelDataId({
          scoreCardId: scoreCardId,
          categoryId: categoryId,
          criteriaId: id,
          criteriaType: criteriaType,
        })
      );

      if (scoreCardAttributeData === null) {
        request
          .get(attribute.getAll(id))
          .then((result) => {
            const { response } = result;
            dispatch(
              handleScoreCardAttributeData({ scoreCardAttributeData: response })
            );
            dispatch(handleScoreCardLevel({ level: 4 }));
          })
          .catch((error) => {
            console.log("error " + error);
          });
      }

      if (scoreCardAttributeData !== null) {
        let childData = scoreCardAttributeData?.filter(
          (element: any) => element.score_card_criteria_id === id
        );

        if (childData.length === 0) {
          request
            .get(attribute.getAll(id))
            .then((result) => {
              const { response } = result;
              let combinedData = scoreCardAttributeData?.concat(response);
              dispatch(
                handleScoreCardAttributeData({
                  scoreCardAttributeData: combinedData,
                })
              );
              dispatch(handleScoreCardLevel({ level: 4 }));
            })
            .catch((error) => {
              console.log("error " + error);
            });
        }

        if (childData.length !== 0) {
          dispatch(handleScoreCardLevel({ level: 4 }));
        }
      }
    }

    if (scoreCardActiveElement.name !== null && type !== "validation") {
      if (type === null)
        dispatch(
          handleActiveTooltip({
            childId: scoreCardActiveElement.name,
            childDataId: null,
            type: "saveChanges",
          })
        );
      if (type === "saveChanges") {
        dispatch(
          handleActiveTooltip({ childId: null, childDataId: null, type: null })
        );
        setTimeout(() => {
          dispatch(
            handleActiveTooltip({
              childId: scoreCardActiveElement.name,
              childDataId: null,
              type: "saveChanges",
            })
          );
        }, 50);
      }
    }
  };

  const editHandler = (index: number, id: number, level: number) => {
    if (
      scoreCardActiveElement.name === null &&
      type === null &&
      rowType !== null
    ) {
      dispatch(handleScoreCardRowEditableStatus(level, rowId, false));
      if (rowType === "new") dispatch(removeScoreCardNewRow(level));
      dispatch(
        handleScoreCardActiveRow({
          rowIndex: index,
          rowType: "edit",
          rowId: id,
        })
      );
      dispatch(handleScoreCardRowEditableStatus(level, id, true));

      let specificData = scoreCardCriteriaData?.find(
        (element: any) => element.id === id
      );

      dispatch(handleScoreCardRecoveryData({ recoveryData: specificData }));
      checkAttributeAvailability(id);
    }

    if (
      scoreCardActiveElement.name === null &&
      type === null &&
      rowType === null
    ) {
      dispatch(handleScoreCardRowEditableStatus(level, id, true));

      let specificData = scoreCardCriteriaData?.find(
        (element: any) => element.id === id
      );

      dispatch(handleScoreCardRecoveryData({ recoveryData: specificData }));
      dispatch(
        handleScoreCardActiveRow({
          rowIndex: index,
          rowType: "edit",
          rowId: id,
        })
      );
      checkAttributeAvailability(id);
    }

    if (scoreCardActiveElement.name !== null && type !== "validation") {
      if (type === null)
        dispatch(
          handleActiveTooltip({
            childId: scoreCardActiveElement.name,
            childDataId: null,
            type: "saveChanges",
          })
        );
      if (type === "saveChanges") {
        dispatch(
          handleActiveTooltip({ childId: null, childDataId: null, type: null })
        );
        setTimeout(() => {
          dispatch(
            handleActiveTooltip({
              childId: scoreCardActiveElement.name,
              childDataId: null,
              type: "saveChanges",
            })
          );
        }, 50);
      }
    }
  };

  const nameHandler = (value: string, id: number, elementId: string) => {
    dispatch(handleScoreCardActiveElement({ name: elementId }));
    dispatch(touchBlocked({ status: true }));

    let specificData = scoreCardCriteriaData.find(
      (element: any) => element.id === id
    );
    let copyState = [...scoreCardCriteriaData];
    let copyOfSpecificData = { ...specificData };
    let indexOfSpecificData = copyState.indexOf(specificData);

    copyOfSpecificData.name = value;

    copyState[indexOfSpecificData] = copyOfSpecificData;
    dispatch(handleScoreCardCriteriaData({ scoreCardCriteriaData: copyState }));

    if (value && type === "validation") {
      dispatch(
        handleActiveTooltip({ childId: null, childDataId: null, type: null })
      );
    }
    if (!value) {
      dispatch(
        handleActiveTooltip({
          childId: elementId,
          childDataId: null,
          type: "validation",
        })
      );
    }
  };

  const weightHandler = (
    value: string,
    id: number,
    level: number,
    elementId: string,
    index: number
  ) => {
    if (
      (scoreCardActiveElement.name === elementId ||
        scoreCardActiveElement.name === null ||
        rowIndex === index) &&
      type !== "validation"
    ) {
      if (rowType === null) {
        dispatch(handleScoreCardRowWeigthEditableStatus(level, id, true));
        dispatch(
          handleScoreCardActiveRow({
            rowIndex: index,
            rowType: "edit",
            rowId: id,
          })
        );
      }
      dispatch(handleScoreCardActiveElement({ name: elementId }));
      dispatch(touchBlocked({ status: true }));

      let specificData = scoreCardCriteriaData.find(
        (element: any) => element.id === id
      );

      if (recoveryData === null)
        dispatch(handleScoreCardRecoveryData({ recoveryData: specificData }));

      let copyState = [...scoreCardCriteriaData];
      let copyOfSpecificData = { ...specificData };
      let indexOfSpecificData = copyState.indexOf(specificData);

      copyOfSpecificData.weight =
        +value >= 0
          ? value.indexOf(".") > -1
            ? value.substring(0, value.indexOf(".") + 3)
            : value
          : "";

      copyState[indexOfSpecificData] = copyOfSpecificData;
      dispatch(
        handleScoreCardCriteriaData({ scoreCardCriteriaData: copyState })
      );
      dispatch(calculateScoreCardRemainingWeight(level, categoryId));
      dispatch(
        calculateScoreCardPoint({
          levelOfPointData: level,
          pointRowId: id,
          pointParentId: categoryId,
        })
      );
      dispatch(
        calculateScoreCardRemainingPoint({
          remainingPointLevel: level,
          remainingPointParentId: categoryId,
        })
      );
      dispatch(
        handleActiveTooltip({ childId: null, childDataId: null, type: null })
      );
    }

    if (
      scoreCardActiveElement.name !== elementId &&
      type !== "validation" &&
      rowIndex !== index
    ) {
      if (type === null)
        dispatch(
          handleActiveTooltip({
            childId: scoreCardActiveElement.name,
            childDataId: null,
            type: "saveChanges",
          })
        );
      if (type === "saveChanges") {
        dispatch(
          handleActiveTooltip({ childId: null, childDataId: null, type: null })
        );
        setTimeout(() => {
          dispatch(
            handleActiveTooltip({
              childId: scoreCardActiveElement.name,
              childDataId: null,
              type: "saveChanges",
            })
          );
        }, 50);
      }
    }
  };

  const checkAttributeAvailability = (id: number) => {
    const {
      score_card: { attribute },
    } = endpoints;

    if (scoreCardAttributeData === null) {
      request
        .get(attribute.getAll(id))
        .then((result) => {
          const { response } = result;
          if (response?.length !== 0)
            dispatch(handleScoreCardAttributeAvailability({ status: true }));
          if (response?.length === 0)
            dispatch(handleScoreCardAttributeAvailability({ status: false }));
          dispatch(
            handleScoreCardAttributeData({ scoreCardAttributeData: response })
          );
        })
        .catch((error) => {
          console.log("error " + error);
        });
    }

    if (scoreCardAttributeData !== null) {
      let childData = scoreCardAttributeData?.filter(
        (element: any) => element.score_card_criteria_id === id
      );

      if (childData.length === 0) {
        request
          .get(attribute.getAll(id))
          .then((result) => {
            const { response } = result;
            if (response?.length !== 0)
              dispatch(handleScoreCardAttributeAvailability({ status: true }));
            if (response?.length === 0)
              dispatch(handleScoreCardAttributeAvailability({ status: false }));
            let combinedData = scoreCardAttributeData?.concat(response);
            dispatch(
              handleScoreCardAttributeData({
                scoreCardAttributeData: combinedData,
              })
            );
          })
          .catch((error) => {
            console.log("error " + error);
          });
      }

      if (childData.length !== 0) {
        dispatch(handleScoreCardAttributeAvailability({ status: true }));
      }
    }
  };

  return (
    <div className={classes.ThirdLevel}>
      <div className={classes.Head}>
        <span className={classes.HeadColumn}>criteria name</span>
        <span className={classes.HeadColumn}>weight</span>
        <span className={classes.HeadColumn}>point</span>
        <span className={classes.HeadColumn}>trusted</span>
        <span className={classes.HeadColumn}>type</span>
        <span className={classes.HeadColumn} />
      </div>
      {scoreCardRemainingWeight?.weight > 0 && (
        <div className={classes.RemainingRow}>
          <div className={classes.RemainingColumn}>
            <span>default deficit</span>
          </div>
          <div className={classes.RemainingColumn}>
            <span>{scoreCardRemainingWeight.weight}</span>
          </div>
          <div className={classes.RemainingColumn}>
            <span>{scoreCardRemainingPoint.point}</span>
          </div>
        </div>
      )}
      {weightWarningStatus && (
        <div className={classes.WeightWarningRow}>
          <div className={classes.WeightWarningColumn}>
            <span>Category weight sum must be smaller or equal to 100</span>
          </div>
        </div>
      )}
      {scoreCardCriteriaData?.filter(
        (element: any) => element.score_card_category_id === categoryId
      ).length > 0 ? (
        scoreCardCriteriaData
          ?.filter(
            (element: any) => element.score_card_category_id === categoryId
          )
          .map((element: any, index: number) => {
            const {
              id,
              name,
              weight,
              point,
              is_trusted_weight,
              criteria_type_id,
              newRow,
              editable,
              weightEditStatus,
            } = element;
            let weightTrimmed =
              weight.indexOf(".") > -1
                ? weight.substring(0, weight.indexOf(".") + 3)
                : weight;
            let pointTrimmed = parseFloat(point).toFixed(2);

            return (
              <div key={id ?? 0} className={classes.Row} id={`table_row_${id}`}>
                <div className={classes.Column}>
                  {editable ? (
                    <Tooltip
                      children={
                        <input
                          id={`criteria-name-${index}`}
                          type="text"
                          tabIndex={1}
                          defaultValue={name}
                          onChange={(event) =>
                            nameHandler(
                              event.target.value,
                              id,
                              `criteria-name-${index}`
                            )
                          }
                          onKeyDown={(e) => {
                            if (!e.key.match(/[a-zA-Z0-9_]/)) {
                              e.preventDefault();
                            }
                          }}
                          onPaste={(e) => {e.preventDefault(); return false;}}
                          onDrop={(e) => {e.preventDefault(); return false;}}
                          autoComplete="off"
                          disabled={!editable}
                        />
                      }
                    />
                  ) : (
                    <span
                      className={classes.NameLink}
                      onDoubleClick={() => nextBtnHandler(id, criteria_type_id)}
                    >
                      {name}
                    </span>
                  )}
                </div>
                <div className={classes.Column}>
                  <Tooltip
                    children={
                      <input
                        type="number"
                        id={`criteria-weight-${index}`}
                        tabIndex={2}
                        value={weightTrimmed}
                        onChange={(event) =>
                          weightHandler(
                            event.target.value,
                            id,
                            level,
                            `criteria-weight-${index}`,
                            index
                          )
                        }
                        disabled={type === "validation"}
                      />
                    }
                  />
                  <button
                    className={classes.ArrowTop}
                    onClick={() =>
                      numberTopArrowHandler(
                        id,
                        level,
                        "weight",
                        `criteria-weight-${index}`,
                        index
                      )
                    }
                    disabled={type === "validation"}
                  >
                    <img src={icons.arrowTop} alt="Arror top icon" />
                  </button>
                  <button
                    className={classes.ArrowBottom}
                    onClick={() =>
                      numberBottomArrowHandler(
                        id,
                        level,
                        "weight",
                        `criteria-weight-${index}`,
                        index
                      )
                    }
                    disabled={type === "validation"}
                  >
                    <img src={icons.arrowBottom} alt="Arrow bottom icon" />
                  </button>
                </div>
                <div className={classes.Column}>
                  <span>{pointTrimmed}</span>
                </div>
                <div className={classes.Column}>
                
                  <Tooltip
                    children={
                      <div
                      id={`criteria-trusted-weight-${index}`}
                      className={
                          editable &&
                          !scoreCardAtrributeAvailability.status &&
                          type !== "validation"
                            ? [classes.CriterialTrusted, classes.Active].join(" ")
                            : classes.CriterialTrusted
                        }
                      >
                        {criteriaTrusted.find((element: any) => {
                          const { value } = element;
                          return  is_trusted_weight === value;
                        })?.key}
                        
                      </div>
                    }
                  />
                  <button
                    className={classes.ArrowTop}
                    onClick={() =>
                      trustedTopArrowHandler(`criteria-trusted-weight-${index}`,index)
                    }
                    disabled={
                      !editable
                      //  ||
                      // type === "validation" ||
                      // scoreCardAtrributeAvailability.status
                    }
                  >
                    <img src={icons.arrowTop} alt="Arrow top icon" />
                  </button>
                  <button
                    className={classes.ArrowBottom}
                    onClick={() =>
                      trustedBottomArrowHandler(`criteria-trusted-weight-${index}`,index)
                    }
                    disabled={
                      !editable 
                      // ||
                      // type === "validation" ||
                      // scoreCardAtrributeAvailability.status
                    }
                  >
                    <img src={icons.arrowBottom} alt="Arrow bottom icon" />
                  </button>
                </div>
                <div className={classes.Column}>
                  <Tooltip
                    children={
                      <div
                        id={`criteriaType-${index}`}
                        className={
                          editable &&
                          !scoreCardAtrributeAvailability.status &&
                          type !== "validation"
                            ? [classes.CriterialType, classes.Active].join(" ")
                            : classes.CriterialType
                        }
                      >
                        {criteriaTypes.find((element: any) => {
                          const { id } = element;

                          return  id === criteria_type_id;
                        })?.name}
                      </div>
                    }
                  />
                  <button
                    className={classes.ArrowTop}
                    onClick={() =>
                      typeTopArrowHandler(`criteriaType-${index}`, index)
                    }
                    disabled={
                      !editable 
                      // ||
                      // type === "validation" ||
                      // scoreCardAtrributeAvailability.status
                    }
                  >
                    <img src={icons.arrowTop} alt="Arrow top icon" />
                  </button>
                  <button
                    className={classes.ArrowBottom}
                    onClick={() =>
                      typeBottomArrowHandler(`criteriaType-${index}`, index)
                    }
                    disabled={
                      !editable
                      //  ||
                      // type === "validation" ||
                      // scoreCardAtrributeAvailability.status
                    }
                  >
                    <img src={icons.arrowBottom} alt="Arrow bottom icon" />
                  </button>
                </div>
                <div className={classes.Actions}>
                  {newRow || editable || weightEditStatus ? (
                    <>
                      <Active
                        id={`score-apply-${index}`}
                        style={
                          scoreCardActiveElement.name === null ||
                          type === "validation" ||
                          scoreCardRemainingWeight.weight < 0
                            ? { opacity: 0, pointerEvents: "none" }
                            : { opacity: 1 }
                        }
                        handler={() => applyHandlerCallback(categoryId)}
                      />
                      <Cancel
                        id={`score-cancel-${index}`}
                        handler={() =>
                          cancelHandlerCallback(rowType, id, level)
                        }
                      />
                    </>
                  ) : (
                    <>
                      <Pen handler={() => editHandler(index, id, level)} />
                      <Delete
                        id={`delete-${index}`}
                        handler={() =>
                          deleteHandlerCallback(index, id, `delete-${index}`)
                        }
                      />
                      <NextArrowRight
                        id={`nextBtn-${index}`}
                        handler={() => nextBtnHandler(id, criteria_type_id)}
                      />
                    </>
                  )}
                </div>
              </div>
            );
          })
          // .reverse()
      ) : (
        <span className={classes.TableMessage}>No data to display</span>
      )}
    </div>
  );
};

export default CriteriaLevel;
