import React, { FC, useCallback, MouseEvent, useState } from "react";
import classes from "./ModalList.module.scss";
import {
  ButtonSizes,
  ButtonTypes,
  icons,
  messages,
  ModalNames,
} from "../../../settings/settings";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/combineReducer";
import Button from "../../../components/UI/Button/ButtonV2";
import Portal from "../../../components/UI/Portal/Portal";
import { closeModal } from "../../../store/actions/actionsModal";

interface IModalList {
  name: ModalNames;
  title: string;
  loading: boolean;
  buttonMessage: string;
  buttonType: ButtonTypes;
  onCancel: () => void;
  onSubmit: (id: number) => void;
  visible: boolean;
  headers: Array<string>;
  data: Array<Record<string, any>>;
}

const ModalList: FC<IModalList> = ({
  name,
  title,
  loading,
  buttonMessage,
  buttonType,
  onCancel,
  onSubmit,
  visible,
  headers,
  data,
}) => {
  const dispatch = useDispatch();
  const {
    reducerModal: { modalState },
  } = useSelector((state: RootState) => state);

  const [selectedId, setSelectedId] = useState<number | undefined>();
  const [selectedRowIndex, setSelectedRowIndex] = useState<
    number | undefined
  >();

  const resetStates = useCallback(() => {
    setSelectedId(undefined);
    setSelectedRowIndex(undefined);
  }, []);

  const selectRow = useCallback(
    (event: MouseEvent<HTMLSpanElement>) => {
      const { currentTarget } = event;

      const selectedObj = data[Number(currentTarget.id)];

      setSelectedRowIndex(Number(currentTarget.id));

      if ("id" in selectedObj) {
        setSelectedId(selectedObj.id);
      }
    },
    [data]
  );

  const passIdAndCloseModal = useCallback(
    (event: MouseEvent<HTMLSpanElement>) => {
      const { currentTarget } = event;

      const selectedObj = data[Number(currentTarget.id)];

      setSelectedRowIndex(Number(currentTarget.id));

      if ("id" in selectedObj) {
        onSubmit(selectedObj.id);
        resetStates();
        dispatch(closeModal());
      }
    },
    [data, onSubmit, dispatch, resetStates]
  );

  const generateHeaders = useCallback(() => {
    if (!headers || !headers.length) return null;

    return headers.map((header: string, index: number) => {
      return (
        <span key={index} className={classes.Header}>
          {header}
        </span>
      );
    });
  }, [headers]);

  const generateElementRows = useCallback(() => {
    if (!data || !data.length) {
      return (
        <span className={classes.NoDataRow}>{messages.messageNoData}</span>
      );
    }

    return data.map((dataObj: Record<string, any>, index: number) => {
      const objValues = Object.entries(dataObj)
        .filter(([property]) => {
          return property !== "id";
        })
        .map(([property, value]) => {
          return value;
        });
      return (
        <div
          key={index}
          id={`${index}`}
          className={`${classes.ModalListRow} ${
            index === selectedRowIndex ? classes.ActiveRow : ""
          }`}
          onClick={selectRow}
          onDoubleClick={passIdAndCloseModal}
        >
          {objValues.map((value: any, index: number) => {
            return (
              <span key={index} className={classes.ModalListColumn}>
                {value}
              </span>
            );
          })}
        </div>
      );
    });
  }, [data, selectRow, selectedRowIndex, passIdAndCloseModal]);

  const fireSubmit = () => {
    if (!selectedId) {
      return;
    }
    resetStates();
    onSubmit(selectedId);
  };

  const fireCancel = () => {
    resetStates();
    onCancel();
  };

  return (
    <>
      {visible && modalState?.name === name ? (
        <Portal>
          <div className={classes.Wrapper}>
            <p className={classes.Title}>{title}</p>
            <div className={classes.ListHeaders}>{generateHeaders()}</div>
            <div className={classes.Body}>{generateElementRows()}</div>
            <Button
              type={buttonType}
              size={ButtonSizes.LARGE}
              onClick={fireSubmit}
            >
              {buttonMessage}
            </Button>
            <button className={classes.CancelButton} onClick={fireCancel}>
              <img src={icons.closeBtn} alt="Close icon" />
            </button>
          </div>
        </Portal>
      ) : null}
    </>
  );
};

export default ModalList;
