import { elementSize } from "../../../../../settings/settings";
import { drag_arrow } from "./drag_arrow.handler";
import { createShadow } from "./shadow.handler";

export const addArrowBox = (group:SVGGElement,element:SVGElement,shadow:SVGElement,side:Array<any>=[])=>{
    let xmlns = "http://www.w3.org/2000/svg";

    let _x:any  = element?.getAttribute('x');
    let _y:any  = element?.getAttribute('y');
    let _width:any  = element?.getAttribute('width');
    let _height:any  = element?.getAttribute('height');
    let _data_type:any  = element?.getAttribute('data-type');
    let _box_group = document.createElementNS(xmlns,'g') as SVGGElement;
    _box_group.setAttributeNS(null,'id','arrow_box_group');

    if(side.length===0){

        
        let box_top:any = createArrowBox('top',{x:_x,y:_y,width:_width,height:_height},_data_type,element.dataset.id);
        let box_right:any = createArrowBox('right',{x:_x,y:_y,width:_width,height:_height},_data_type,element.dataset.id);
        let box_bottom:any = createArrowBox('bottom',{x:_x,y:_y,width:_width,height:_height},_data_type,element.dataset.id);
        let box_left:any = createArrowBox('left',{x:_x,y:_y,width:_width,height:_height},_data_type,element.dataset.id);

        _box_group.appendChild(box_top)
        _box_group.appendChild(box_right)
        _box_group.appendChild(box_bottom)
        _box_group.appendChild(box_left);
        
        group.appendChild(_box_group);
        

    }else{
        side?.forEach((_side:string)=>{
            if(_side === "top"){
                let box_top:any = createArrowBox('top',{x:_x,y:_y,width:_width,height:_height},_data_type,element.dataset.id);

                _box_group.appendChild(box_top)

            }
            if(_side === "right"){
                let box_right:any = createArrowBox('right',{x:_x,y:_y,width:_width,height:_height},_data_type,element.dataset.id);
                _box_group.appendChild(box_right)

            }
            if(_side === "bottom"){
                let box_bottom:any = createArrowBox('bottom',{x:_x,y:_y,width:_width,height:_height},_data_type,element.dataset.id);

                _box_group.appendChild(box_bottom)


            }
            if(_side === "left"){
                let box_left:any = createArrowBox('left',{x:_x,y:_y,width:_width,height:_height},_data_type,element.dataset.id);
                _box_group.appendChild(box_left)

            }
            group.appendChild(_box_group);

        })
    }


    if(element.dataset.type === 'condt'){
        let condition_figure:any = document.createElementNS(xmlns,'rect') as SVGRectElement;
        let cond_x:any = element.getAttribute('x');
        let cond_y:any = element.getAttribute('y');
        condition_figure.setAttributeNS(null,'x',+cond_x -4 + +(Math.sqrt((70*70)/2) - 70/2).toFixed(2));
        condition_figure.setAttributeNS(null,'y',+cond_y - 4 + +(Math.sqrt((70*70)/2) - 70/2).toFixed(2));
        condition_figure.setAttributeNS(null,'transform',`rotate(45 ${+cond_x + 35} ${+cond_y + 35})`)
        for(const [key,value] of Object.entries(elementSize.condition_figure)){
            condition_figure.setAttributeNS(null,key,value);
        }

        group.appendChild(condition_figure);
        group.appendChild(element);
        group.appendChild(shadow);
    }else{
        group.appendChild(element);
        
        group.appendChild(shadow);
    }

    
    return group;

}

const createArrowBox = (side:string,style:any,data_type:string,id:any)=>{
        let xmlns = "http://www.w3.org/2000/svg";
        let {x,y,width,height} = style;
        let {circle,rect_big,rect_small} = _attribute;
        if(side === 'top'){
            let _top_box_group = document.createElementNS(xmlns,'g') as SVGGElement;
            _top_box_group.setAttributeNS(null,'id','top_arrow_box_group');

            let _top_box_circle = document.createElementNS(xmlns,'rect') as SVGRectElement;
            let _top_box_rect_big = document.createElementNS(xmlns,'rect') as SVGRectElement;
            let _top_box_rect_small = document.createElementNS(xmlns,'rect') as SVGRectElement;
            let _main_x:any = (+x + +width/2) - +circle.width/2;
            // let _main_y:any = data_type === 'condt' ? +y - (+circle.height+18) : +y - (+circle.height+9);
            let _main_y:any = +y - (+circle.height+9);

            _top_box_circle.setAttributeNS(null,'x',_main_x);
            _top_box_circle.setAttributeNS(null,'y',_main_y);
            _top_box_circle.setAttributeNS(null,'width',circle.width);
            _top_box_circle.setAttributeNS(null,'height',circle.height);
            _top_box_circle.setAttributeNS(null,'rx',circle.r);
            _top_box_circle.setAttributeNS(null,'ry',circle.r);
            _top_box_circle.setAttributeNS(null,'fill',circle.fill);
            _top_box_circle.setAttributeNS(null,'id','circle');
            _top_box_circle.setAttributeNS(null,'style','filter:url(#box_shadow)')
            _top_box_circle.setAttributeNS(null,'data-side','top');


            _top_box_rect_big.setAttributeNS(null,'x',_main_x+11);
            _top_box_rect_big.setAttributeNS(null,'y',_main_y+6);
            _top_box_rect_big.setAttributeNS(null,'width',rect_big.width);
            _top_box_rect_big.setAttributeNS(null,'height',rect_big.height);
            _top_box_rect_big.setAttributeNS(null,'rx',rect_big.r);
            _top_box_rect_big.setAttributeNS(null,'ry',rect_big.r);
            _top_box_rect_big.setAttributeNS(null,'fill',rect_big.fill);
            _top_box_rect_big.setAttributeNS(null,'id','rect_big');
            _top_box_rect_big.setAttributeNS(null,'pointer-events','none');


            _top_box_rect_small.setAttributeNS(null,'x',_main_x+14);
            _top_box_rect_small.setAttributeNS(null,'y',_main_y+14);
            _top_box_rect_small.setAttributeNS(null,'width',rect_small.top.width);
            _top_box_rect_small.setAttributeNS(null,'height',rect_small.top.height);
            _top_box_rect_small.setAttributeNS(null,'rx',rect_small.r);
            _top_box_rect_small.setAttributeNS(null,'ry',rect_small.r);
            _top_box_rect_small.setAttributeNS(null,'fill',rect_small.fill);
            _top_box_rect_small.setAttributeNS(null,'id','rect_small');
            _top_box_rect_small.setAttributeNS(null,'pointer-events','none');

            _top_box_group.appendChild(_top_box_circle);

            _top_box_group.appendChild(_top_box_rect_small);
            _top_box_group.appendChild(_top_box_rect_big);
            _top_box_group.appendChild(createShadow('box'));
            drag_arrow(id,_top_box_group,side);
            // if(data_type === 'endpoint'){
            //     _top_box_group.style.visibility = 'hidden';
            // }
            return _top_box_group;
            
        }
        if(side === 'right'){
            let _right_box_group = document.createElementNS(xmlns,'g') as SVGGElement;
            _right_box_group.setAttributeNS(null,'id','right_arrow_box_group');


            let _right_box_circle = document.createElementNS(xmlns,'rect') as SVGRectElement;
            let _right_box_rect_big = document.createElementNS(xmlns,'rect') as SVGRectElement;
            let _right_box_rect_small = document.createElementNS(xmlns,'rect') as SVGRectElement;
            // let _main_x:any =data_type === 'condt' ? +x + +width + 18 : +x + +width + 9;
            // let _main_y:any = data_type === 'condt' ? (+y +height/2) - (+circle.height/2) : (+y + +height/2) - +circle.height/2;
            let _main_x:any = +x + +width + 9;
            let _main_y:any =  (+y + +height/2) - +circle.height/2;
            _right_box_circle.setAttributeNS(null,'x',_main_x);
            _right_box_circle.setAttributeNS(null,'y',_main_y);
            _right_box_circle.setAttributeNS(null,'width',circle.width);
            _right_box_circle.setAttributeNS(null,'height',circle.height);
            _right_box_circle.setAttributeNS(null,'rx',circle.r);
            _right_box_circle.setAttributeNS(null,'ry',circle.r);
            _right_box_circle.setAttributeNS(null,'fill',circle.fill);
            _right_box_circle.setAttributeNS(null,'style','filter:url(#box_shadow)')
            _right_box_circle.setAttributeNS(null,'id','circle');
            _right_box_circle.setAttributeNS(null,'data-side','right');


            _right_box_rect_big.setAttributeNS(null,'x',_main_x+16);
            _right_box_rect_big.setAttributeNS(null,'y',_main_y+11);
            _right_box_rect_big.setAttributeNS(null,'width',rect_big.width);
            _right_box_rect_big.setAttributeNS(null,'height',rect_big.height);
            _right_box_rect_big.setAttributeNS(null,'rx',rect_big.r);
            _right_box_rect_big.setAttributeNS(null,'ry',rect_big.r);
            _right_box_rect_big.setAttributeNS(null,'fill',rect_big.fill);
            _right_box_rect_big.setAttributeNS(null,'id','rect_big');
            _right_box_rect_big.setAttributeNS(null,'pointer-events','none');



            _right_box_rect_small.setAttributeNS(null,'x',_main_x+6);
            _right_box_rect_small.setAttributeNS(null,'y',_main_y+14);
            _right_box_rect_small.setAttributeNS(null,'width',rect_small.right.width);
            _right_box_rect_small.setAttributeNS(null,'height',rect_small.right.height);
            _right_box_rect_small.setAttributeNS(null,'rx',rect_small.r);
            _right_box_rect_small.setAttributeNS(null,'ry',rect_small.r);
            _right_box_rect_small.setAttributeNS(null,'fill',rect_small.fill);
            _right_box_rect_small.setAttributeNS(null,'id','rect_small');
            _right_box_rect_small.setAttributeNS(null,'pointer-events','none');

            _right_box_group.appendChild(_right_box_circle);

            _right_box_group.appendChild(_right_box_rect_small);
            _right_box_group.appendChild(_right_box_rect_big);
            _right_box_group.appendChild(createShadow('box'));
            drag_arrow(id,_right_box_group,side);
            // if(data_type === 'endpoint'){
            //     _right_box_group.style.visibility = 'hidden';
            // }
            return _right_box_group;
        }

        if(side === 'bottom'){
            let _bottom_box_group = document.createElementNS(xmlns,'g') as SVGGElement;
            _bottom_box_group.setAttributeNS(null,'id','bottom_arrow_box_group');

            let _bottom_box_circle = document.createElementNS(xmlns,'rect') as SVGRectElement;
            let _bottom_box_rect_big = document.createElementNS(xmlns,'rect') as SVGRectElement;
            let _bottom_box_rect_small = document.createElementNS(xmlns,'rect') as SVGRectElement;
            let _main_x:any = (+x + +width/2) - +circle.width/2;
            // let _main_y:any = data_type === 'condt' ? +y + +height + 18 : +y + +height  + 9;
            let _main_y:any =  +y + +height  + 9;

            _bottom_box_circle.setAttributeNS(null,'x',_main_x);
            _bottom_box_circle.setAttributeNS(null,'y',_main_y);
            _bottom_box_circle.setAttributeNS(null,'width',circle.width);
            _bottom_box_circle.setAttributeNS(null,'height',circle.height);
            _bottom_box_circle.setAttributeNS(null,'rx',circle.r);
            _bottom_box_circle.setAttributeNS(null,'ry',circle.r);
            _bottom_box_circle.setAttributeNS(null,'fill',circle.fill);
            _bottom_box_circle.setAttributeNS(null,'style','filter:url(#box_shadow)')
            _bottom_box_circle.setAttributeNS(null,'id','circle');
            _bottom_box_circle.setAttributeNS(null,'data-side','bottom');


            _bottom_box_rect_big.setAttributeNS(null,'x',_main_x+11);
            _bottom_box_rect_big.setAttributeNS(null,'y',_main_y+16);
            _bottom_box_rect_big.setAttributeNS(null,'width',rect_big.width);
            _bottom_box_rect_big.setAttributeNS(null,'height',rect_big.height);
            _bottom_box_rect_big.setAttributeNS(null,'rx',rect_big.r);
            _bottom_box_rect_big.setAttributeNS(null,'ry',rect_big.r);
            _bottom_box_rect_big.setAttributeNS(null,'fill',rect_big.fill);
            _bottom_box_rect_big.setAttributeNS(null,'id','rect_big');
            _bottom_box_rect_big.setAttributeNS(null,'pointer-events','none');


            _bottom_box_rect_small.setAttributeNS(null,'x',_main_x+14);
            _bottom_box_rect_small.setAttributeNS(null,'y',_main_y+6);
            _bottom_box_rect_small.setAttributeNS(null,'width',rect_small.bottom.width);
            _bottom_box_rect_small.setAttributeNS(null,'height',rect_small.bottom.height);
            _bottom_box_rect_small.setAttributeNS(null,'rx',rect_small.r);
            _bottom_box_rect_small.setAttributeNS(null,'ry',rect_small.r);
            _bottom_box_rect_small.setAttributeNS(null,'fill',rect_small.fill);
            _bottom_box_rect_small.setAttributeNS(null,'id','rect_small');
            _bottom_box_rect_small.setAttributeNS(null,'pointer-events','none');

            _bottom_box_group.appendChild(_bottom_box_circle);

            _bottom_box_group.appendChild(_bottom_box_rect_small);
            _bottom_box_group.appendChild(_bottom_box_rect_big);
            _bottom_box_group.appendChild(createShadow('box'));
            drag_arrow(id,_bottom_box_group,side);
            // if(data_type === 'endpoint'){
            //     _bottom_box_group.style.visibility = 'hidden';
            // }
            return _bottom_box_group;
        }
        if(side === 'left'){
            let _left_box_group = document.createElementNS(xmlns,'g') as SVGGElement;
            _left_box_group.setAttributeNS(null,'id','left_arrow_box_group');


            let _left_box_circle = document.createElementNS(xmlns,'rect') as SVGRectElement;
            let _left_box_rect_big = document.createElementNS(xmlns,'rect') as SVGRectElement;
            let _left_box_rect_small = document.createElementNS(xmlns,'rect') as SVGRectElement;
            // let _main_x:any =data_type === 'condt' ? +x - +circle.width - 18 : +x - +circle.width - 9;
            // let _main_y:any = data_type === 'condt' ? (+y +height/2) - (+circle.height/2) : (+y + +height/2) - +circle.height/2;
            let _main_x:any = +x - +circle.width - 9;
            let _main_y:any =(+y + +height/2) - +circle.height/2;
            _left_box_circle.setAttributeNS(null,'x',_main_x);
            _left_box_circle.setAttributeNS(null,'y',_main_y);
            _left_box_circle.setAttributeNS(null,'width',circle.width);
            _left_box_circle.setAttributeNS(null,'height',circle.height);
            _left_box_circle.setAttributeNS(null,'rx',circle.r);
            _left_box_circle.setAttributeNS(null,'ry',circle.r);
            _left_box_circle.setAttributeNS(null,'fill',circle.fill);
            _left_box_circle.setAttributeNS(null,'style','filter:url(#box_shadow)')
            _left_box_circle.setAttributeNS(null,'id','circle');
            _left_box_circle.setAttributeNS(null,'data-side','left');


            _left_box_rect_big.setAttributeNS(null,'x',_main_x+6);
            _left_box_rect_big.setAttributeNS(null,'y',_main_y+11);
            _left_box_rect_big.setAttributeNS(null,'width',rect_big.width);
            _left_box_rect_big.setAttributeNS(null,'height',rect_big.height);
            _left_box_rect_big.setAttributeNS(null,'rx',rect_big.r);
            _left_box_rect_big.setAttributeNS(null,'ry',rect_big.r);
            _left_box_rect_big.setAttributeNS(null,'fill',rect_big.fill);
            _left_box_rect_big.setAttributeNS(null,'id','rect_big');
            _left_box_rect_big.setAttributeNS(null,'pointer-events','none');

            _left_box_rect_big.setAttributeNS(null,'pointer-events','none');

            _left_box_rect_small.setAttributeNS(null,'x',_main_x+14);
            _left_box_rect_small.setAttributeNS(null,'y',_main_y+14);
            _left_box_rect_small.setAttributeNS(null,'width',rect_small.left.width);
            _left_box_rect_small.setAttributeNS(null,'height',rect_small.left.height);
            _left_box_rect_small.setAttributeNS(null,'rx',rect_small.r);
            _left_box_rect_small.setAttributeNS(null,'ry',rect_small.r);
            _left_box_rect_small.setAttributeNS(null,'fill',rect_small.fill);
            _left_box_rect_small.setAttributeNS(null,'id','rect_small');
            _left_box_rect_small.setAttributeNS(null,'pointer-events','none');
            _left_box_rect_small.setAttributeNS(null,'pointer-events','none');

            _left_box_group.appendChild(_left_box_circle);

            _left_box_group.appendChild(_left_box_rect_small);
            _left_box_group.appendChild(_left_box_rect_big);
            _left_box_group.appendChild(createShadow('box'));
            drag_arrow(id,_left_box_group,side);
            // if(data_type === 'endpoint'){
            //     _left_box_group.style.visibility = 'hidden';
            // }
            return _left_box_group;

        }

}

export const downArrowBox = (element:SVGElement,type:string)=>{
    const svg = document.querySelector('#svg_board') as SVGElement;
   
    let data_id:any =  element?.getAttribute('data-id');
    let find_group = svg?.querySelector(`g[data-id = ${data_id}]`);
    let find_arrow_box_group = find_group?.querySelector(`g[id = "arrow_box_group"]`);
    let find_box_groups = find_arrow_box_group?.querySelectorAll('g') as NodeList;

    find_box_groups?.forEach((group:any)=>{
        let childs = group?.querySelectorAll('rect') as NodeList;
        let group_id:string  = group.id;
        childs?.forEach((child:any)=>{
            let stroke = type === 'down' || type === 'move' ? '#A668D8':'';
            if(group_id === 'top_arrow_box_group'){               
                if(child.id === 'circle'){
                    child.setAttributeNS(null,'stroke',stroke);
                }               
            }
            if(group_id === 'right_arrow_box_group'){             
                if(child.id === 'circle'){                 
                    child.setAttributeNS(null,'stroke',stroke);
                }              
            }
            if(group_id === 'bottom_arrow_box_group'){                  
                if(child.id === 'circle'){
                     child.setAttributeNS(null,'stroke',stroke);
                }              
            }
            if(group_id === 'left_arrow_box_group'){              
                if(child.id === 'circle'){
                    child.setAttributeNS(null,'stroke',stroke);
                }               
            }
        })  

    })
}
export const moveArrowBox = (element:SVGElement,coord:any)=>{
 
        if(element){
                    const svg = document.querySelector('#svg_board') as SVGElement;
                    let _width:any =  element.getAttribute('width');
                    let _height:any =  element.getAttribute('height');
                    let data_id:any =  element.getAttribute('data-id');

                    let {circle} = _attribute;
                    let find_group = svg?.querySelector(`g[data-id = ${data_id}]`);
                    let find_arrow_box_group = find_group?.querySelector(`g[id = "arrow_box_group"]`);
                    let find_box_groups = find_arrow_box_group?.querySelectorAll('g') as NodeList;
                    find_box_groups?.forEach((group:any)=>{
                        let childs = group?.querySelectorAll('rect') as NodeList;
                        let group_id:string  = group.id;

                        if(group_id){
                                if(childs && childs.length>0){
                                        childs?.forEach((child:any)=>{
                                            if(group_id === 'top_arrow_box_group'){
                
                                            
                                                let _main_x:any = (+coord.x + +_width/2) - +circle.width/2;
                                                let _main_y:any =+coord.y - (+circle.height+9);
                                                if(child.id === 'circle'){
                                                    child.setAttributeNS(null,'x',_main_x);
                                                    child.setAttributeNS(null,'y',_main_y);
                
                                
                                                }
                                                if(child.id === 'rect_big'){
                                                    child.setAttributeNS(null,'x',_main_x+11);
                                                    child.setAttributeNS(null,'y',_main_y+6);
                                                }
                                                if(child.id === 'rect_small'){
                                                    child.setAttributeNS(null,'x',_main_x+14);
                                                    child.setAttributeNS(null,'y',_main_y+14);
                                                }
                                            }
                                            if(group_id === 'right_arrow_box_group'){
                                            
                                                let _main_x:any =+coord.x + +_width + 9;
                                                let _main_y:any =(+coord.y + +_height/2) - +circle.height/2;
                                                if(child.id === 'circle'){
                                                    child.setAttributeNS(null,'x',_main_x);
                                                    child.setAttributeNS(null,'y',_main_y);
                
                                                }
                                                if(child.id === 'rect_big'){
                                                    child.setAttributeNS(null,'x',_main_x+16);
                                                    child.setAttributeNS(null,'y',_main_y+11);
                                                }
                                                if(child.id === 'rect_small'){
                                                    child.setAttributeNS(null,'x',_main_x+6);
                                                    child.setAttributeNS(null,'y',_main_y+14);
                                                }
                                            }
                                            if(group_id === 'bottom_arrow_box_group'){
                                                
                                                
                                                let _main_x:any =  (+coord.x + +_width/2) - +circle.width/2;
                                                let _main_y:any =  +coord.y + +_height  + 9;
                                                if(child.id === 'circle'){
                                                    child.setAttributeNS(null,'x',_main_x);
                                                    child.setAttributeNS(null,'y',_main_y);
                                                    child.setAttributeNS(null,'stroke','#A668D8');
                
                                                }
                                                if(child.id === 'rect_big'){
                                                    child.setAttributeNS(null,'x',_main_x+11);
                                                    child.setAttributeNS(null,'y',_main_y+16);
                                                }
                                                if(child.id === 'rect_small'){
                                                    child.setAttributeNS(null,'x',_main_x+14);
                                                    child.setAttributeNS(null,'y',_main_y+6);
                                                }
                                            }
                                            if(group_id === 'left_arrow_box_group'){
                                            
                                                let _main_x:any = +coord.x - +circle.width - 9;
                                                let _main_y:any = (+coord.y + +_height/2) - +circle.height/2;
                                                if(child.id === 'circle'){
                                                    child.setAttributeNS(null,'x',_main_x);
                                                    child.setAttributeNS(null,'y',_main_y);
                                                    child.setAttributeNS(null,'stroke','#A668D8');
                
                                                }
                                                if(child.id === 'rect_big'){
                                                    child.setAttributeNS(null,'x',_main_x+6);
                                                    child.setAttributeNS(null,'y',_main_y+11);
                                                }
                                                if(child.id === 'rect_small'){
                                                    child.setAttributeNS(null,'x',_main_x+14);
                                                    child.setAttributeNS(null,'y',_main_y+14);
                                                }
                                            }
                                        }) 
                                } 
                        }
                    })
        }
}


const _attribute = {
    circle:{width:'32',height:'32',r:'16',fill:"#FFFFFF"},
    rect_big:{width:'10',height:'10',r:'2',fill:'#A668D8'},
    rect_small:{
        fill:'#A668D8',
        r:'1',
        top:{
            width:'4',height:'12'
        },
        right:{
            width:'12',height:'4'
        },
        bottom:{
           width:'4',height:'12'
        },
        left:{
            width:'12',height:'4'
        }
    }
}