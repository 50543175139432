import { getArrowPropery, getContactId } from "./get_arrow_properties.handler"

export const arrowLoop = (overElement:SVGRectElement|null=null,arrow:SVGPathElement) => {
    const board = document.querySelector('#svg_board')! as SVGSVGElement;
    const {contact} = getArrowPropery(arrow);
    const {prev} = getContactId(contact);
    const overId = overElement?.dataset.id;
    let status:boolean = false;
    function prevElements(id:string){
        const prevArrows = board.querySelectorAll(`path[data-contact $= "-${id}"]`) as NodeListOf<SVGPathElement>;
        if(prevArrows && overId){
            prevArrows?.forEach((prevArrow:SVGPathElement)=>{
                const {contact:prevContact} = getArrowPropery(prevArrow);
                const {prev:prevId} = getContactId(prevContact)
                if(overId === prevId){
                    arrow.setAttribute('stroke',"#cf3743");
                    status = true;
                }
                prevElements(prevId)
            })

        }
    }
    prevElements(prev)
   
    return {status}
}