import React, { FC, useEffect, useState, useCallback } from "react";
import classes from "./DataTree.module.scss";
import { icons } from "../../../settings/settings";
import Checkbox from "../Checkbox/CheckboxV2";

interface IDataTree {
  header?: boolean;
  leftHeaderElements?: Array<string>;
  rightHeaderElements?: Array<string>;
  data?: object | string;
  undoAction?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  redoAction?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  bgcolor?: string;
  copy?: boolean;
  responseTime?: number;
  firstClick?: boolean;
}

const DataTree: FC<IDataTree> = ({
  header = false,
  leftHeaderElements,
  rightHeaderElements,
  data,
  undoAction = () => {},
  redoAction = () => {},
  bgcolor,
  copy,
  responseTime,
  firstClick,
}) => {
  const [rowsShowStatus, setRowsShowStatus] = useState<boolean>(true);
  const [copiedStatus, setCopiedStatus] = useState<boolean>(false);
  const [reformattedTreeDataState, setReformattedTreeDataState] = useState<
    Array<object>
  >([]);

  const generateTreeData = useCallback((rawData: object) => {
    function generateUniqueId() {
      const chars =
        "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
      let uniqueId = "";

      for (let index = 0; index < 6; index++) {
        uniqueId += chars[Math.floor(Math.random() * chars.length)];
      }

      return uniqueId;
    }

    function checkType(value: any) {
      const valueType = typeof value;

      switch (valueType) {
        case "string":
          return value.includes("#", 0) ? "color" : "string";
        case "object":
          return !value ? "null" : Array.isArray(value) ? "array" : "object";
        default:
          return valueType;
      }
    }

    function checkTypeColor(valueType: string) {
      switch (valueType) {
        case "string":
          return "#82b92c";

        case "number":
          return "#cf3743";

        case "boolean":
          return "#E8C446";

        case "null":
          return "#2c7cf6";

        default:
          return "#102946";
      }
    }

    function reformatValue(value: any, valueType: string): any {
      switch (valueType) {
        case "string":
          return `"${value}"`;

        case "boolean":
          return String(value);

        case "null":
          return String(value);

        case "array":
          return reformatArrayData(value);

        case "object":
          return reformatObjectData(value);

        default:
          return value;
      }
    }

    function checkExtenedableAvailability(valueType: string) {
      switch (valueType) {
        case "array":
          return true;

        case "object":
          return true;

        default:
          return false;
      }
    }

    function reformatObjectData(data: any): Array<object> {
      let formattedObjectData: Array<object> = [];

      Object.entries(data).forEach((element: Array<any>) => {
        formattedObjectData.push({
          id: generateUniqueId(),
          key: element[0],
          value: reformatValue(element[1], checkType(element[1])),
          color: checkTypeColor(checkType(element[1])),
          type: checkType(element[1]),
          extendable: checkExtenedableAvailability(checkType(element[1])),
          extended: true,
        });
      });

      return formattedObjectData;
    }

    function reformatArrayData(data: Array<any>) {
      let formattedArrayData: Array<object> = [];

      data?.forEach((element: any) => {
        formattedArrayData.push({
          id: generateUniqueId(),
          value: reformatValue(element, checkType(element)),
          color: checkTypeColor(checkType(element)),
          type: checkType(element),
          extendable: checkExtenedableAvailability(checkType(element)),
          extended: true,
        });
      });

      return formattedArrayData;
    }

    setReformattedTreeDataState(reformatObjectData(rawData));
  }, []);

  useEffect(() => {
    if (data && typeof data === "object") {
      generateTreeData(data);
    }

    if (data && typeof data === "string") {
      setReformattedTreeDataState([]);
    }
  }, [data, generateTreeData]);

  const handleRowsShowStatus = () => {
    setRowsShowStatus(!rowsShowStatus);
  };

  const keyRow = (key: string) => {
    return `${key} : `;
  };

  const valueRow = (value: string, color: string) => {
    return <span style={{ color }}>{value}</span>;
  };

  const booleanRow = (value: string, color: string) => {
    return (
      <>
        <Checkbox value={JSON.parse(value)} disabled={true} size="small" />{" "}
        {valueRow(value, color)}
      </>
    );
  };

  const colorRow = (value: string, color: string) => {
    return (
      <>
        <span
          className={classes.ColorIndicator}
          style={{ backgroundColor: value }}
        />{" "}
        {valueRow(value, color)}
      </>
    );
  };

  const arrayRow = (
    value: Array<any>,
    color: string,
    extendStatus: boolean
  ) => {
    if (extendStatus) {
      return <>[{generateArrayRows(value)}]</>;
    }
    if (!extendStatus) {
      return <span style={{ color }}>[{value.length}]</span>;
    }
  };

  const objectRow = (
    value: Array<any>,
    color: string,
    extendStatus: boolean
  ) => {
    if (extendStatus) {
      return <>&#123;{generateObjectRows(value)}&#125;</>;
    }
    if (!extendStatus) {
      return <span style={{ color }}>&#123;{value.length}&#125;</span>;
    }
  };

  const handleExtendStatus = (dataArray: Array<any>, uniqueId: string) => {
    const copyOfData = [...dataArray];
    let specificRowIndex: number;

    const specificRow: any = copyOfData.find((element: any) => {
      const { id } = element;
      return id === uniqueId;
    });

    if (specificRow) {
      specificRowIndex = copyOfData.indexOf(specificRow);
      specificRow.extended = !specificRow.extended;
      copyOfData[specificRowIndex] = specificRow;
    } else {
      const availableChildren = copyOfData.filter((element: any) => {
        const { extendable } = element;
        return extendable === true;
      });

      availableChildren.forEach((element: any) => {
        const { value } = element;
        specificRowIndex = copyOfData.indexOf(element);
        copyOfData[specificRowIndex].value = handleExtendStatus(
          value,
          uniqueId
        );
      });
    }

    return copyOfData;
  };

  const generateObjectRows = (objectData: Array<object>) => {
    return objectData.map((fieldObjects: any, index: number) => {
      const { id, key, value, color, type, extendable, extended } =
        fieldObjects;

      return (
        <div
          key={index}
          className={
            type === "boolean"
              ? [classes.Row, classes.BooleanRow].join(" ")
              : classes.Row
          }
        >
          {extendable && (
            <img
              className={
                extended
                  ? [
                      classes.Triangle,
                      classes.TriangleRow,
                      classes.TriangleActive,
                    ].join(" ")
                  : [classes.Triangle, classes.TriangleRow].join(" ")
              }
              src={icons.triangle}
              alt="Arrow icon"
              onClick={() =>
                setReformattedTreeDataState(
                  handleExtendStatus(reformattedTreeDataState, id)
                )
              }
            />
          )}
          {keyRow(key)}
          {type === "boolean" && booleanRow(value, color)}
          {type === "color" && colorRow(value, color)}
          {type === "array" && arrayRow(value, color, extended)}
          {type === "object" && objectRow(value, color, extended)}
          {type !== "boolean" &&
            type !== "color" &&
            type !== "array" &&
            type !== "object" &&
            valueRow(value, color)}
        </div>
      );
    });
  };

  const generateArrayRows = (arrayData: Array<any>) => {
    return arrayData.map((fieldObjects: any, index: number) => {
      const { id, value, color, type, extendable, extended } = fieldObjects;

      return (
        <div
          key={index}
          className={
            type === "boolean"
              ? [classes.Row, classes.BooleanRow].join(" ")
              : classes.Row
          }
        >
          {extendable && (
            <img
              className={
                extended
                  ? [
                      classes.Triangle,
                      classes.TriangleRow,
                      classes.TriangleActive,
                    ].join(" ")
                  : [classes.Triangle, classes.TriangleRow].join(" ")
              }
              src={icons.triangle}
              alt="Arrow icon"
              onClick={() => {
                setReformattedTreeDataState(
                  handleExtendStatus(reformattedTreeDataState, id)
                );
              }}
            />
          )}
          {type === "boolean" && booleanRow(value, color)}
          {type === "color" && colorRow(value, color)}
          {type === "array" && arrayRow(value, color, extended)}
          {type === "object" && objectRow(value, color, extended)}
          {type !== "boolean" &&
            type !== "color" &&
            type !== "array" &&
            type !== "object" &&
            valueRow(value, color)}
          {" ,"}
        </div>
      );
    });
  };

  const copyData = (_data: any) => {
    setCopiedStatus(true);
    const elem = document.createElement("textarea");
    elem.value = JSON.stringify(_data);
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
    setTimeout(() => {
      setCopiedStatus(false);
    }, 2000);
  };
  return (
    <div
      id={"createDataTree"}
      className={
        bgcolor
          ? [classes.ActiveBackground, classes.DataTreeWrapper].join(" ")
          : classes.DataTreeWrapper
      }
    >
      {header && (
        <>
          <div className={classes.TreeHeader}>
            <div className={classes.LeftHeader}>
              {
                // eslint-disable-next-line array-callback-return
                leftHeaderElements?.map((button: string, index: number) => {
                  switch (button) {
                    case "LeftAlign":
                      return (
                        <button key={index}>
                          <img
                            src={icons.codeEditorLeftAlign}
                            alt="Left align icon"
                          />
                        </button>
                      );

                    case "RightAlign":
                      return (
                        <button key={index}>
                          <img
                            src={icons.codeEditorRightAlign}
                            alt="Right align icon"
                          />
                        </button>
                      );

                    case "FontSize":
                      return (
                        <button key={index}>
                          <img
                            src={icons.codeEditorFontSize}
                            alt="Font size icon"
                          />
                        </button>
                      );

                    case "Filter":
                      return (
                        <button key={index}>
                          <img src={icons.filterBlue} alt="Filter icon" />
                        </button>
                      );

                    case "Settings":
                      return (
                        <button key={index}>
                          <img src={icons.settingsBlue} alt="Settings icon" />
                        </button>
                      );

                    case "Undo":
                      return (
                        <button key={index} onClick={undoAction}>
                          <img
                            src={icons.codeEditorUndo}
                            alt="Undo icon"
                            style={{ width: "2.16vh", height: "2.16vh" }}
                          />
                        </button>
                      );

                    case "Redo":
                      return (
                        <button key={index} onClick={redoAction}>
                          <img
                            src={icons.codeEditorRedo}
                            alt="Redo icon"
                            style={{ width: "2.16vh", height: "2.16vh" }}
                          />
                        </button>
                      );

                    case "Search":
                      return (
                        <div className={classes.EditorSearchBox} key={index}>
                          <input type="text" />
                          <img src={icons.search} alt="Search icon" />
                        </div>
                      );

                    default:
                      break;
                  }
                })
              }
            </div>
            <div className={classes.RightHeader}>
              {
                // eslint-disable-next-line array-callback-return
                rightHeaderElements?.map(
                  // eslint-disable-next-line array-callback-return
                  (button: string, index: number): JSX.Element | undefined => {
                    switch (button) {
                      case "LeftAlign":
                        return (
                          <button key={index}>
                            <img
                              src={icons.codeEditorLeftAlign}
                              alt="Left align icon"
                            />
                          </button>
                        );

                      case "RightAlign":
                        return (
                          <button key={index}>
                            <img
                              src={icons.codeEditorRightAlign}
                              alt="Right align icon"
                            />
                          </button>
                        );

                      case "FontSize":
                        return (
                          <button key={index}>
                            <img
                              src={icons.codeEditorFontSize}
                              alt="Font size icon"
                            />
                          </button>
                        );

                      case "Filter":
                        return (
                          <button key={index}>
                            <img src={icons.filterBlue} alt="Filter icon" />
                          </button>
                        );

                      case "Settings":
                        return (
                          <button key={index}>
                            <img src={icons.settingsBlue} alt="Settings icon" />
                          </button>
                        );

                      case "Undo":
                        return (
                          <button key={index} onClick={() => undoAction}>
                            <img
                              src={icons.codeEditorUndo}
                              alt="Undo icon"
                              style={{ width: "2.16vh", height: "2.16vh" }}
                            />
                          </button>
                        );

                      case "Redo":
                        return (
                          <button key={index} onClick={() => redoAction}>
                            <img
                              src={icons.codeEditorRedo}
                              alt="Redo icon"
                              style={{ width: "2.16vh", height: "2.16vh" }}
                            />
                          </button>
                        );

                      case "Search":
                        return (
                          <div />
                          // <div className={classes.EditorSearchBox} key={index}>
                          //   <input type="text" />
                          //   <img src={icons.search} alt="Search icon" />
                          // </div>
                        );
                      case "Response":
                        return (
                          <div className={classes.Response} key={index}>
                            Response time: {responseTime}ms
                          </div>
                        );
                      default:
                        break;
                    }
                  }
                )
              }
            </div>
            <span className={classes.Seperator} />
          </div>
        </>
      )}
      <div id="TreeBody" className={classes.TreeBody}>
        {data ? (
          typeof data === "object" ? (
            <>
              <div className={[classes.Row, classes.FirstRow].join(" ")}>
                <img
                  className={
                    rowsShowStatus
                      ? [classes.Triangle, classes.TriangleActive].join(" ")
                      : classes.Triangle
                  }
                  src={icons.triangle}
                  alt="Arrow icon"
                  onClick={() => handleRowsShowStatus()}
                />{" "}
                <span>object</span> &#123;
                {Object.keys(data).length}&#125;
              </div>
              {copy && (
                <div className={classes.CopyBtn} onClick={() => copyData(data)}>
                  <svg
                    width="19"
                    height="24"
                    viewBox="0 0 19 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2.15222 4.63849H12.3797C13.3349 4.63849 14.1144 5.41794 14.1189 6.37762V21.0521C14.1189 22.0118 13.3394 22.7912 12.3797 22.7912H2.15222C1.19254 22.7912 0.413086 22.0118 0.413086 21.0521V6.37762C0.413086 5.41794 1.19254 4.63849 2.15222 4.63849ZM12.3746 21.5695C12.6629 21.5695 12.8972 21.3353 12.8972 21.0469V6.37693C12.8972 6.08857 12.6629 5.85429 12.3746 5.85429H2.14704C1.85868 5.85429 1.6244 6.08857 1.6244 6.37693V21.0469C1.6244 21.3353 1.85868 21.5695 2.14704 21.5695H12.3746Z"
                      fill="#2C7CF6"
                    />
                    <path
                      d="M14.1189 6.37762H14.5189L14.5189 6.37574L14.1189 6.37762ZM12.3797 4.23849H2.15222V5.03849H12.3797V4.23849ZM14.5189 6.37574C14.5133 5.19766 13.5569 4.23849 12.3797 4.23849V5.03849C13.113 5.03849 13.7154 5.63823 13.7189 6.3795L14.5189 6.37574ZM14.5189 21.0521V6.37762H13.7189V21.0521H14.5189ZM12.3797 23.1912C13.5603 23.1912 14.5189 22.2327 14.5189 21.0521H13.7189C13.7189 21.7909 13.1185 22.3912 12.3797 22.3912V23.1912ZM2.15222 23.1912H12.3797V22.3912H2.15222V23.1912ZM0.0130859 21.0521C0.0130859 22.2327 0.971627 23.1912 2.15222 23.1912V22.3912C1.41346 22.3912 0.813086 21.7909 0.813086 21.0521H0.0130859ZM0.0130859 6.37762V21.0521H0.813086V6.37762H0.0130859ZM2.15222 4.23849C0.971627 4.23849 0.0130859 5.19703 0.0130859 6.37762H0.813086C0.813086 5.63886 1.41346 5.03849 2.15222 5.03849V4.23849ZM12.4972 21.0469C12.4972 21.1143 12.442 21.1695 12.3746 21.1695V21.9695C12.8838 21.9695 13.2972 21.5562 13.2972 21.0469H12.4972ZM12.4972 6.37693V21.0469H13.2972V6.37693H12.4972ZM12.3746 6.25429C12.442 6.25429 12.4972 6.30949 12.4972 6.37693H13.2972C13.2972 5.86766 12.8838 5.45429 12.3746 5.45429V6.25429ZM2.14704 6.25429H12.3746V5.45429H2.14704V6.25429ZM2.0244 6.37693C2.0244 6.30949 2.0796 6.25429 2.14704 6.25429V5.45429C1.63777 5.45429 1.2244 5.86766 1.2244 6.37693H2.0244ZM2.0244 21.0469V6.37693H1.2244V21.0469H2.0244ZM2.14704 21.1695C2.0796 21.1695 2.0244 21.1143 2.0244 21.0469H1.2244C1.2244 21.5562 1.63777 21.9695 2.14704 21.9695V21.1695ZM12.3746 21.1695H2.14704V21.9695H12.3746V21.1695Z"
                      fill="#2C7CF6"
                    />
                    <path
                      d="M16.2899 0.79126H6.06237C5.1027 0.79126 4.32324 1.57071 4.32324 2.53039C4.32324 2.8683 4.59357 3.13864 4.93149 3.13864C5.2694 3.13864 5.53973 2.8683 5.53973 2.53039C5.53973 2.24204 5.77402 2.00775 6.06237 2.00775H16.2899C16.5783 2.00775 16.8125 2.24204 16.8125 2.53039V17.2049C16.8125 17.4932 16.5783 17.7275 16.2899 17.7275C15.952 17.7275 15.6817 17.9978 15.6817 18.3358C15.6817 18.6737 15.952 18.944 16.2899 18.944C17.2496 18.944 18.029 18.1645 18.029 17.2049V2.53039C18.029 1.57071 17.2496 0.79126 16.2899 0.79126Z"
                      fill="#2C7CF6"
                      stroke="#2C7CF6"
                      strokeWidth="0.8"
                    />
                  </svg>

                  {copiedStatus && (
                    <div className={classes.TooltipWrapper}>
                      {/* {child} */}
                      <div className={classes.TooltipBody}>
                        <span className={classes.TooltipArrow}></span>
                        <span className={classes.TooltipTopMessage}>
                          Response data copied to buffer
                        </span>

                        <button
                          className={classes.TooltipCloseBtn}
                          title="Close"
                        >
                          <img src={icons.closeBtn} alt="Close icon" />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          ) : (
            <span>{data}</span>
          )
        ) : !firstClick ? (
          <span style={{ color: "#B2BDCD" }}>No data to display</span>
        ) : null}
        {rowsShowStatus && generateObjectRows(reformattedTreeDataState)}
      </div>
    </div>
  );
};

export default DataTree;
