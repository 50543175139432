import React, { FC } from 'react';
import { Delete } from '../../../../../../components/UI/Svg/Svg';
import classes from './RemovePanel.module.scss';

interface Props{
    removeHandler:Function,
    coord:{
        x:number,
        y:number
    }
}
const RemovePanel:FC<Props> =  ({coord:{x=0,y=0},removeHandler=(e:any)=>{}}) =>{

    return(
        <div id='delete_element' title="Delete" className={classes.DeleteBtnDiv} style={{left:`${x}px`,top:`${y}px`,display:(x !== 0 || y !== 0) ? 'block':'none'}}>
                            {<Delete fill='#FFFFFF' handler={(e:any)=>removeHandler(e)}/>}
        </div>
    )
}

export default RemovePanel;