import { contact_chaining } from "layout/StrategyDesigner/StrategyBoard/FlowChart/handlers/contact_chaining/contact_chaining.handler";
import { create_arrow } from "layout/StrategyDesigner/StrategyBoard/FlowChart/handlers/create_arrow";
import { call_arrow_box_source, call_arrow_box_target } from "layout/StrategyDesigner/StrategyBoard/FlowChart/handlers/remove_arrow_box.handler";
import { remove_db } from "layout/StrategyDesigner/StrategyBoard/FlowChart/handlers/remove_element.handler";
import { storageKeys } from "settings/settings";

export const removeElement = (new_created_element:string) => {
  if (new_created_element) {
    const svg = document.querySelector("#svg_board") as SVGSVGElement;
    const find_element: any = svg?.querySelector(
      `rect[data-id = ${new_created_element}]`
    ) as SVGRectElement;
    const find_arrows = svg.querySelectorAll(
      `path[data-contact $= ${new_created_element}]`
    ) as NodeList;
    const find_arrows_target = svg.querySelectorAll(
      `path[data-contact ^= ${new_created_element}]`
    ) as NodeList;
    let source: any = null;
    let target: any = null;
    let prev_arrow: any = null;

    if (find_arrows?.length !== 0) {
      find_arrows.forEach((arrow: any) => {
        const id = arrow.dataset.contact;
        source = svg.querySelector(`rect[data-id = ${id?.split("-")?.[0]}]`);
        let find_text = svg.querySelectorAll(
          `text[id = "${id}"]`
        ) as NodeList;
        find_text?.forEach((text: any) => {
          svg.removeChild(text);
        });
        svg.removeChild(arrow);
      });
    }
    if (find_arrows_target?.length !== 0) {
      find_arrows_target.forEach((arrow: any) => {
        const id = arrow.dataset.contact;
        target = svg.querySelector(`rect[data-id = ${id?.split("-")?.[1]}]`);
        prev_arrow = arrow;
        let find_text = svg.querySelectorAll(
          `text[id = "${id}"]`
        ) as NodeList;
        find_text?.forEach((text: any) => {
          svg.removeChild(text);
        });

        svg.removeChild(arrow);
      });
    }

    if (source && target) {
      create_arrow(source, target, prev_arrow, svg);
    } else {
      call_arrow_box_source(find_arrows);
      call_arrow_box_target(find_arrows_target);
    }
    if (find_element) {
      remove_db(find_element);

      svg.removeChild(find_element.parentNode);
    }
  }
};

export const removeElementFromOutput=(id:string)=>{
  const boardElementsJSON = localStorage.getItem(storageKeys.boardElements);
  if (!boardElementsJSON) return;
  const boardElementsArray = [...JSON.parse(boardElementsJSON)];
  let currentElement = boardElementsArray.find(
    (item) => item.sourceId === "output"
  );
  if (!currentElement) return;
  const indexOutput = boardElementsArray.indexOf(currentElement);
  let { data, overlays } = currentElement;
  const elements = overlays.filter((item:any)=>item.sourceId === id) as any;
  if (elements.length ===0 ) return;
  elements?.forEach((element:any)=>{
    const index = overlays.indexOf(element) as number;
    if (index < 0) return;
    const {key,value} = element;
    overlays.splice(index,1);
    const {outputValue} = data;
    const splittedList = outputValue.replaceAll(/[{}]/g, "").split(",") as string[];
    const val = `${key}:${value}`;
    const indexVal = splittedList.indexOf(val) as number;
    if(indexVal>=0){
      splittedList.splice(indexVal,1);
    }
    const newOutputValue = `{${splittedList.join(',')}}`;
    data["outputValue"] = newOutputValue
  })
  
  boardElementsArray[indexOutput] = currentElement;
  localStorage.setItem(
    storageKeys.boardElements,
    JSON.stringify(boardElementsArray)
  );
}

export const removeWizardFromBoard = (wizard:any)=>{
  if(!wizard  || !wizard.data) return;
  if(Object.keys(wizard.data).length >0) return;
  const boardElementsJSON = localStorage.getItem(storageKeys.boardElements);
  if (!boardElementsJSON) return;
  const boardElementsArray = [...JSON.parse(boardElementsJSON)];
  let wizardElement = boardElementsArray.find(
    (item) => item.sourceId === "datawizard"
  );
  if (!wizardElement) return;
  const board = document.querySelector("#svg_board") as SVGSVGElement;
  const findElement = board.querySelector('rect[data-id = "datawizard"]') as any;
  if(!findElement) return;
  remove_db(findElement)
  const find_arrows = board.querySelectorAll(`path[data-contact $= "datawizard"]`) as NodeListOf<SVGPathElement>;
  const find_arrows_target = board.querySelectorAll(`path[data-contact ^= "datawizard"]`) as NodeListOf<SVGPathElement>;
  board.removeChild(findElement.parentNode);
  const [arrowInput] = find_arrows as any;
  const [arrowOutput] = find_arrows_target as any;
  board.removeChild(arrowInput);
  board.removeChild(arrowOutput);
  contact_chaining(findElement, find_arrows, find_arrows_target);
} 