import styled from 'styled-components';

export const Container = styled.div`
position: relative;
margin-bottom:1.32vh;
`;
export const Input = styled.input`
  width:100%;
  height:5.6vh;
  border-radius:1.12vh;
  background: #F5F6FA;
  outline:none;
  border: 0;
  text-indent:2.8vh;
  font-size:1.96vh;
  color:#001237;
  font-weight:500;
  &::placeholder{
    font-size:1.96vh;
    color:#102946;
    opacity:.3;
  }
`;
export const Label = styled.label`
  font-size:1.96vh;
  color:#102946;
  opacity:.3;
`;
export const Message = styled.small`
color: #FF554D;
font-size:1.4vh;
`;

export const IconBox = styled.div`
  position: absolute;
  width:3vh;
  height:5.6vh;
  top: 4.6vh;
  right:1.88vh;
  svg{
    z-index: 10;
  }
`