import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import classes from './StrategyFunctions.module.scss';
import { useDebounce } from 'use-debounce/lib';
import { EditorKeySources, storageKeys } from '../../../settings/settings';
import {
  IReformattedFunctions,
  IReformattedFunctionsGroup,
} from '../../../helpers/reformatStrategyFunctions';
import { IIndexedKeys } from '../StrategyEditor/StrategyEditor';

interface IStrategyFunctions {
  onSingleClick?: (description: string, syntax: string) => void;
  onDoubleClick?: (func: IIndexedKeys) => void;
}

const StrategyFunctions: FC<IStrategyFunctions> = ({
  onSingleClick,
  onDoubleClick,
}) => {
  const [reformattedData, setReformattedData] = useState<
    Array<IReformattedFunctionsGroup>
  >([]);
  const [searched, setSearched] = useState<string>('');
  const [searchResult, setSearchResult] = useState<
    Array<IReformattedFunctions>
  >([]);
  let [searchString] = useDebounce(searched, 1000);

  const getStrategyFunctions = useCallback(() => {
    const strategyFunctionsJSON = sessionStorage.getItem(
      storageKeys.reformattedStrategyFunctions
    );

    if (!strategyFunctionsJSON) return;

    const strategyFunctionsArray = JSON.parse(strategyFunctionsJSON);

    setReformattedData(strategyFunctionsArray);
  }, []);

  const generateSearchResult = useCallback(() => {
    if (!searchString || !reformattedData.length) {
      setSearchResult([]);
      return;
    }

    const result: Array<IReformattedFunctions> = reformattedData
      .map((group: IReformattedFunctionsGroup) => {
        const { functions } = group;

        return functions.filter((func: IReformattedFunctions) => {
          const { name } = func;

          return new RegExp(searchString, 'i').test(name);
        });
      })
      .flat();

    setSearchResult(result);
  }, [searchString, reformattedData]);

  const clearSearch = useCallback(() => {
    setSearched('');
  }, []);

  useLayoutEffect(getStrategyFunctions, [getStrategyFunctions]);

  useEffect(generateSearchResult, [generateSearchResult]);

  const handleRowExtendableStatus = (rowId: number, rowIndex: number) => {
    const copyOfData = [...reformattedData];
    const specificRow: any = reformattedData.find(
      (group: IReformattedFunctionsGroup) => rowId === group.id
    );

    if (!specificRow) return;

    specificRow.extended = !specificRow.extended;
    copyOfData[rowIndex] = specificRow;

    setReformattedData(copyOfData);
  };

  const generateFunctionRow = (groupChildren: Array<IReformattedFunctions>) => {
    if (groupChildren.length > 0) {
      return groupChildren?.map(
        (func: IReformattedFunctions, index: number) => {
          const { name, description, syntax } = func;

          return (
            <span
              key={index}
              className={classes.Row}
              title={description as string}
              onClick={() => {
                if (!onSingleClick) return;

                onSingleClick(description as string, syntax as string);
              }}
              onDoubleClick={() => {
                if (!onDoubleClick) return;

                onDoubleClick({ source: EditorKeySources.FUNCTION, key: name });
              }}
              draggable
              onDragStart={(event: any) => {
                const targetElement = event.target as HTMLSpanElement;
                const key = name;
                targetElement.classList.add(classes.Drag);
                event.dataTransfer.setData(
                  'dragging_function',
                  JSON.stringify({ source: EditorKeySources.FUNCTION, key })
                );
                event.dataTransfer.setData(
                  'function_info',
                  JSON.stringify({ ...func })
                );
              }}
              onDragEnd={(event: any) => {
                const targetElement = event.target as HTMLSpanElement;

                targetElement.classList.remove(classes.Drag);
              }}
            >
              {name}
            </span>
          );
        }
      );
    }
  };

  const generateFunctionGroupRow = (
    reformattedData: Array<IReformattedFunctionsGroup>
  ) => {
    return reformattedData.map(
      (group: IReformattedFunctionsGroup, index: number) => {
        const { id, name, functions, extended } = group;

        return (
          <div
            key={index}
            className={`${classes.GroupRow} ${
              extended ? classes.ActiveGroup : ''
            }`}
          >
            <div
              className={classes.GroupHeader}
              onClick={() => handleRowExtendableStatus(id, index)}
            >
              <span className={classes.GroupName}>{name}</span>
              <svg
                viewBox='0 0 7 5'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M3.66996 3.94462L6.69982 0.914706C6.76996 0.844628 6.80859 0.75108 6.80859 0.651331C6.80859 0.551583 6.76996 0.458035 6.69982 0.387957L6.47675 0.164824C6.33139 0.0196303 6.09513 0.0196303 5.95 0.164824L3.4057 2.70912L0.85858 0.162001C0.788446 0.0919228 0.694953 0.0532303 0.595261 0.0532303C0.495457 0.0532303 0.401964 0.0919228 0.331775 0.162001L0.108753 0.385134C0.0386195 0.455267 -1.7643e-05 0.54876 -1.7643e-05 0.648508C-1.7643e-05 0.748257 0.0386195 0.841805 0.108753 0.911883L3.14138 3.94462C3.21174 4.01487 3.30567 4.05345 3.40553 4.05323C3.50578 4.05345 3.59966 4.01487 3.66996 3.94462Z' />
              </svg>
            </div>
            {extended && generateFunctionRow(functions)}
          </div>
        );
      }
    );
  };

  const handleSearchChange = (event: any) => {
    const {
      target: { value },
    } = event;

    setSearched(value);
  };


  return (
    <div className={classes.FunctionsWrapper}>
      <div className={classes.ViewerHeader}>
        <svg
          className={classes.DoubleIcon}
          viewBox='0 0 16 17'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M11.6011 10.796L15.4749 14.8249C15.8026 15.1657 15.7917 15.7091 15.4511 16.0369C15.2909 16.1911 15.0797 16.276 14.8571 16.276C14.622 16.276 14.4026 16.1826 14.2394 16.0131L10.3363 11.9537C9.228 12.7326 7.93171 13.1429 6.57143 13.1429C2.948 13.1429 0 10.1949 0 6.57143C0 2.948 2.948 0 6.57143 0C10.1949 0 13.1429 2.948 13.1429 6.57143C13.1429 8.12229 12.5971 9.612 11.6011 10.796ZM11.4286 6.57143C11.4286 3.89314 9.24971 1.71429 6.57143 1.71429C3.89314 1.71429 1.71429 3.89314 1.71429 6.57143C1.71429 9.24971 3.89314 11.4286 6.57143 11.4286C9.24971 11.4286 11.4286 9.24971 11.4286 6.57143Z'
          />
        </svg>
        <input
          className={classes.Title}
          type='text'
          placeholder='Search in Functions'
          value={searched}
          onChange={handleSearchChange}
        />
        {searched ? (
          <svg
            className={classes.DoubleIcon}
            viewBox='0 0 12 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            onClick={clearSearch}
          >
            <title>Clear</title>
            <path
              d='M5.88083 4.99709L9.81083 1.06709C10.055 0.82313 10.055 0.427297 9.81083 0.183339C9.56667 -0.060828 9.17125 -0.060828 8.92708 0.183339L4.99708 4.11334L1.06687 0.183339C0.822708 -0.060828 0.427292 -0.060828 0.183125 0.183339C-0.0610417 0.427297 -0.0610417 0.82313 0.183125 1.06709L4.11333 4.99709L0.183125 8.92709C-0.0610417 9.17105 -0.0610417 9.56688 0.183125 9.81084C0.305208 9.93271 0.465208 9.99376 0.625 9.99376C0.784792 9.99376 0.944792 9.93271 1.06687 9.81063L4.99708 5.88063L8.92708 9.81063C9.04917 9.93271 9.20917 9.99376 9.36896 9.99376C9.52875 9.99376 9.68875 9.93271 9.81083 9.81063C10.055 9.56667 10.055 9.17084 9.81083 8.92688L5.88083 4.99709Z'
              fillOpacity='0.7'
            />
          </svg>
        ) : (
          <></>
        )}
      </div>    
      <div className={classes.ViewerBody}>
        {!searchString ? (
          reformattedData.length && generateFunctionGroupRow(reformattedData)
        ) : searchResult.length ? (
          generateFunctionRow(searchResult)
        ) : (
          <div className={classes.NoDataRow}>
            No result found for {searchString}
          </div>
        )}
      </div>
    </div>
  );
};

export default StrategyFunctions;
