export const callTooltip = (figure:SVGRectElement,text:SVGTextElement)=>{
    let xmlns = "http://www.w3.org/2000/svg";
    let xhtml = "http://www.w3.org/1999/xhtml";
  
    const foreignObject  = document.createElementNS(xmlns,'foreignObject') as SVGForeignObjectElement;
    foreignObject.setAttributeNS(null,'id','fo_label_tooltip')

    foreignObject.setAttributeNS(null,'pointer-events','none')

    const x = Number(figure.getAttribute('x'));
    const y = Number(figure.getAttribute('y'));
    const height = Number(figure.getAttribute('height'));
    const width = Number(figure.getAttribute('width'));

    const info:any = text?.dataset.info;

    const tooltip = document.createElementNS(xhtml,'div') as HTMLDivElement;
    tooltip.setAttributeNS(null,'id','element_label_tooltip');

    if(info){
        tooltip.textContent = info;

    }
    const triangle = document.createElementNS(xhtml,'div') as HTMLDivElement;
    triangle.setAttributeNS(null,'id','tooltip_triangle');

    const style = document.createElementNS(xhtml,'style') as HTMLStyleElement;
    style.textContent=`
            #element_label_tooltip{
                width:100%;
                min-height:15px;
                background-color:#082a49d6;
                font-size:10px;
                color:#FFF;
                text-align:left;
                padding:2px;
                border-radius:5px;
                z-index:0;
            }
            #tooltip_triangle{
                width:100%;
                height:10px;
                background-color:transparent;
                font-size:10px;
                color:#FFF;
                text-align:left;
                border-radius:5px;
            }
            #tooltip_triangle:before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                right:0;
                margin:auto;
                width: 0;
                height: 0;
                border-style: solid;
                top:-2px;
                border-color: transparent transparent #082a49d6 transparent;
                border-width: 6px;
                z-index:1;

            }
    `
    const char_width = width/15;
    const tooltip_width = info.length <=15 ? width:info.length*char_width;
    foreignObject.appendChild(style);
    foreignObject.setAttributeNS(null,'x',`${x- (Math.abs(tooltip_width - width)/2)}`);
    foreignObject.setAttributeNS(null,'y',`${y+height + 1}`);
    foreignObject.setAttributeNS(null,'width',`${tooltip_width}`);
    foreignObject.setAttributeNS(null,'height',`10%`);


    foreignObject.appendChild(triangle);
    foreignObject.appendChild(tooltip);

    return {tooltip:foreignObject};
}