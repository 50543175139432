import { StrategyActionsTypes, StrategyBoardElements, STRATEGY_BOARD_ELEMENTS } from "../../../../types/typesStrategy";


const initialState: StrategyBoardElements = {
    board_elements:[],
}

const reducer = (state = initialState, action: StrategyActionsTypes): StrategyBoardElements => {
    switch (action.type) {

        case STRATEGY_BOARD_ELEMENTS:
            const {board_elements} = action.payload;
            let a = state.board_elements?.filter((el:any)=>{
                return (el != null || el !== undefined ) && el !== board_elements
            });
           
            return {
                board_elements:typeof(board_elements) === 'string' ? [...a,board_elements]:[...board_elements],
            }

        default:
            return state

    }
}

export default reducer