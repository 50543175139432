import { storage_key } from "../../../../../settings/settings";
import DB from "../../../../DB/Storage";
import { label_tooltip } from "./label_tooltip.handler";

export const create_text = (element:any) =>{
    let xmlns = "http://www.w3.org/2000/svg";
    let titles:any = {
        'startpoint':'Start',
        'endpoint':'End',
        'condt':'If case',
        'output_format':'Output Formats',
        'system_errors':'System Errors',
        'validate':'Validate',
        'datawizard':'Data Wizard',
        
    }
    let newElement =document.createElementNS(xmlns,'text') as SVGTextElement; 
    let tspan1 =  document.createElementNS(xmlns,'tspan') as SVGTextElement; 
    let tspan2 =  document.createElementNS(xmlns,'tspan') as SVGTextElement; 
    

    let x = +element.getAttribute('x');
    let y = +element.getAttribute('y');
    let width = +element.getAttribute('width');
    let height = +element.getAttribute('height');
    let fill = element.dataset.type === 'startpoint' ||
     element.dataset.type === 'endpoint' || 
     element.dataset.type === 'condt' ||
     element.dataset.type === 'output_format' ||
     element.dataset.type === 'system_errors' ||
     element.dataset.type === 'decision_mapp' ||
     element.dataset.type === 'scorecard' ||
     element.dataset.type === 'product'
     ? '#FFF'
     :'#102946'

    let data:object = {              
        x:x+width/2,
        y:y+height/2,
        fill:fill,
        'font-size':'14px',
        'text-anchor': 'middle',
        'text-rendering':"geometricPrecision",
        "alignment-baseline":"middle",
        // "pointer-events":element.dataset.type === "func" ? "initial":"none",
        "pointer-events":"none",

        'font-family':'ginger',
        id:'text'
    };
   

    new DB(storage_key).fetch({sourceId:element.dataset.id})
    .then((res:any)=>{
        if(res.status && res.data){
            if(Array.isArray(res.data)){
                
            }else{
                if(res.data.type==='func'){
                    let text_label = res.data.data?.label?.length <=12 ? res.data.data?.label:res.data.data?.label?.substr(0,12)+'...'
                    newElement.setAttributeNS(null,'data-info',res.data.data?.label);
                    newElement.textContent =  text_label;
                    if(res.data.data?.label?.length > 12){
                        label_tooltip(newElement);

                    }

                }else if(res.data.type==='scorecard'){
                    let text_label = res.data.data?.label?.length <=12 ? res.data.data?.label:res.data.data?.label?.substr(0,12)+'...'
                    newElement.setAttributeNS(null,'data-info',res.data.data?.label);
                    newElement.textContent =  text_label;
                    if(res.data.data?.label?.length > 12){
                        label_tooltip(newElement);

                    }

                }else if(res.data.type==='decision'){
                    let text_label = res.data.data?.label?.length <=12 ? res.data.data?.label:res.data.data?.label?.substr(0,12)+'...'
                    newElement.setAttributeNS(null,'data-info',res.data.data?.label);
                    newElement.textContent =  text_label;
                    if(res.data.data?.label?.length > 12){
                        label_tooltip(newElement);

                    }

                }else if(res.data.type==='product'){
                    let text_label = res.data.data?.label?.length <=12 ? res.data.data?.label:res.data.data?.label?.substr(0,12)+'...'
                    newElement.setAttributeNS(null,'data-info',res.data.data?.label);
                    newElement.textContent =  text_label;
                    if(res.data.data?.label?.length > 12){
                        label_tooltip(newElement);

                    }

                }else if(res.data.type === 'code_message'){
                    let _x = +element.getAttribute('x');
                    let _y = +element.getAttribute('y');
                    let _width = +element.getAttribute('width');
                    let _height = +element.getAttribute('height');
                    tspan1.setAttributeNS(null,'x',`${_x+_width/2}`)
                    tspan1.setAttributeNS(null,'y',`${_y+_height/2}`)
                    tspan1.setAttributeNS(null,'font-size',`14px`)
                    tspan1.setAttributeNS(null,'fill',`#102946`);
                    tspan2.setAttributeNS(null,'x',`${_x+_width/2}`)
                    tspan2.setAttributeNS(null,'y',`${_y+_height/2}`)
                    tspan2.setAttributeNS(null,'font-size',`14px`)
                    tspan2.setAttributeNS(null,'fill',`#102946`);
                    tspan2.setAttributeNS(null,'dy',`10`);

                    tspan1.textContent = 'Code';
                    tspan2.textContent = 'Message';
                    newElement.appendChild(tspan1)
                    newElement.appendChild(tspan2)
                }
                
            }
        }else{
            if(element.dataset.type !== 'code_message'){
                

                newElement.textContent =  titles[element.dataset.type] ? titles[element.dataset.type] :element.dataset.id

            }else{
                let _x = +element.getAttribute('x');
                let _y = +element.getAttribute('y');
                let _width = +element.getAttribute('width');
                let _height = +element.getAttribute('height');
                tspan1.setAttributeNS(null,'x',`${_x+_width/2}`)
                tspan1.setAttributeNS(null,'y',`${_y+_height/2}`)
                tspan1.setAttributeNS(null,'font-size',`14px`)
                tspan1.setAttributeNS(null,'fill',`#102946`);
                tspan2.setAttributeNS(null,'x',`${_x+_width/2}`)
                tspan2.setAttributeNS(null,'y',`${_y+_height/2}`)
                tspan2.setAttributeNS(null,'font-size',`14px`)
                tspan2.setAttributeNS(null,'fill',`#102946`);
                tspan2.setAttributeNS(null,'dy',`10`);

                tspan1.textContent = 'Code';
                tspan2.textContent = 'Message';
                newElement.appendChild(tspan1)
                newElement.appendChild(tspan2)
            }

        }
    })
    newElement.textContent = element.dataset.type !== 'code_message' ?  titles[element.dataset.type] ? titles[element.dataset.type] :element.dataset.id:null

    for(const [key,value] of Object.entries(data)){
        newElement.setAttributeNS(null,key,value);
    };
    

    

    return newElement;
}



export const title_text = (element:any) =>{
    let xmlns = "http://www.w3.org/2000/svg";

    let titles:any = {
        'func':'Function',
        'scorecard':'Mapping',
        'decision_mapp':'Decision',
        'validate':'Validate',
        'datawizard':'Data Wizard'
       
    }
    let newElement =document.createElementNS(xmlns,'text') as SVGTextElement;
    
    
    

    let x = +element.getAttribute('x');
    let y = +element.getAttribute('y');
    // let width = +element.getAttribute('width');
    // let height = +element.getAttribute('height');
    let fill = '#A5AEBB'

    let data:object = {              
        x:x,
        y:y-13,
        fill:fill,
        'font-size':'12px',
        'text-anchor': 'start',
        "alignment-baseline":"hanging",
        "pointer-events":"none",
        'font-family':'ginger',
        id:'title_text'

    };
   

    newElement.textContent = titles[element.dataset.type] ? titles[element.dataset.type] :null

    for(const [key,value] of Object.entries(data)){
        newElement.setAttributeNS(null,key,value);
    };

    return newElement;
}



export const move_text = (element:any) =>{

    let x = +element.getAttribute('x');
    let y = +element.getAttribute('y');
    let width = +element.getAttribute('width');
    let height = +element.getAttribute('height');
    let text = element.parentNode.querySelector('#text') as SVGTextElement;
    text.setAttributeNS(null,'x',`${x+width/2}`)
    text.setAttributeNS(null,'y',`${y+height/2}`)

    let tooltip = element.parentNode.querySelector('#fo_label_tooltip') as SVGForeignObjectElement;

    if(tooltip){
        tooltip.setAttributeNS(null,'x',`${x}`)
        tooltip.setAttributeNS(null,'y',`${y+height + 1}`)
        element.parentNode.removeChild(tooltip)
    }
    
    let title_text = element.parentNode.querySelector('#title') as SVGTextElement;

    let _xc = element.dataset.type === 'func' ? +x - 8 : (element.dataset.type === 'scorecard' ? +x - 18 : (element.dataset.type === 'decision_mapp' ? +x - 10 : (element.dataset.type === 'datawizard' ? +x - 12 : (element.dataset.type === 'product' ? +x:0))));
    let _yc = element.dataset.type === 'func' ? +y - 15 : (element.dataset.type === 'scorecard' ? +y - 11 : (element.dataset.type === 'decision_mapp' ? +y - 12 : (element.dataset.type === 'datawizard' ? +y - 15 : (element.dataset.type === 'product'? +y-11:0))));
    title_text?.setAttributeNS(null,'x',`${_xc}`)
    title_text?.setAttributeNS(null,'y',`${_yc}`)
    
    let textchilds = text.querySelectorAll('tspan');
    if(textchilds.length>0){
        textchilds?.forEach((tchild:any)=>{
            tchild?.setAttributeNS(null,'x',`${+x+ +width/2}`)
            tchild?.setAttributeNS(null,'y',`${+y+ +height/2}`)
        }) 
    }





}