import React, { FC } from "react";
import "./customTheme.css";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/javascript/javascript.js";
import { Controlled as ControlledEditor } from "react-codemirror2";
import classes from "./editor.module.scss";

interface editorProps {
  language: string;
  lineNumbers: boolean;
  header?: boolean;
  leftHeaderElements?: Array<string>;
  rightHeaderElements?: Array<string>;
  value: any;
  onChange: Function;
}

const Editor: FC<editorProps> = ({
  language,
  lineNumbers,
  value,
  onChange,
}) => {
  const handleChange = (editor: any, data: any, value: any) => {
    if (language !== "") {
      onChange(value);
    }
    if (language === "") {
      onChange("First select type, please");
    }
  };

  return (
    <div id="createEditor" className={classes.EditorWrapper}>
      <ControlledEditor
        onBeforeChange={handleChange}
        value={value}
        options={{
          lineWrapping: true,
          mode: language.toLowerCase(),
          theme: "3024-day",
          lineNumbers,
          tabSize: 2,
        }}
      />
    </div>
  );
};

export default Editor;
