import { ADD_DECISION, DELETE_DECISION } from './../types/typesDecision';
import {
  DecisionState, DecisionActionsTypes,
  DECISIONCHECKBOX, ACTIVEDECISION, MATRIXDATA,
  MATRIXDATASTATUS, MATRIXSPINNER, MATRIXACTIVEALIAS,
  MATRIXLISTSTATUS, MATRIXACTIVEPOINT,
  //E 
  GETALLDECISIONRANGE,
  HANDLERANGEREFRESH,
  HANDLEDECISIONREFRESH,
  GETALLDECISIONS,
  HANLDERANGEACTIVEELEMENT,
  HANDLERANGEACTIVEROW, HANDLERANGEACTIVETOOLTIP
  //END E
} from "../types/typesDecision";


const initialState: DecisionState = {

  decisionCheckbox: [],
  activeDecision: {
    activeDecision: null
  },
  matrixData: { matrixData: null },
  matrixDataStatus: { status: false },
  matrixSpinner: { status: false },
  matrixActiveAlias: { matrixActiveAlias: null },
  matrixListStatus: { matrixListStatus: null },
  matrixActivePoint: { matrixActivePoint: null },

  //E
  decisionState: [],
  decisionRanges: [],
  decisionReload: false,
  rangeReload: false,
  rangeActiveElement: { name: null, elementIndex: null },
  rangeActiveTooltip: { childId: null, childDataId: null, type: null },

  rangeActiveRow: { rowIndex: null, newRow: false }
  //END E


};

export const reducerDecision = (state = initialState, action: DecisionActionsTypes): DecisionState => {
 
  switch (action.type) {
    case ADD_DECISION: 
    return {
      ...state,
       decisionState: [...state.decisionState, action.payload]
    }; 
    case DELETE_DECISION: 
    const filteredArray = [...state.decisionState].filter((
      (elem: any) => elem.id !== action.payload
      ));
      return {
        ...state, 
        decisionState: [...filteredArray]
      };

    case DECISIONCHECKBOX:
      let newState = state.decisionCheckbox.filter((el, i) => {

        return el.id !== action.payload.decisionCheckboxList.id

      })
      return {
        ...state,
        decisionCheckbox: [...newState, action.payload.decisionCheckboxList]
      };
    case ACTIVEDECISION:
      return {
        ...state,
        activeDecision: action.payload.activeDecision
      };
    case MATRIXDATA:
      return {
        ...state,
        matrixData: action.payload.matrixData
      };
    case MATRIXDATASTATUS:
      return {
        ...state,
        matrixDataStatus: action.payload.matrixDataStatus
      };
    case MATRIXSPINNER:
      return {
        ...state,
        matrixSpinner: action.payload.matrixSpinner
      };
    case MATRIXACTIVEALIAS:
      return {
        ...state,
        matrixActiveAlias: action.payload.matrixActiveAlias
      };
    case MATRIXLISTSTATUS:
      return {
        ...state,
        matrixListStatus: action.payload.matrixListStatus
      };
    case MATRIXACTIVEPOINT:
      return {
        ...state,
        matrixActivePoint: action.payload.matrixActivePoint
      };

    //E
    case GETALLDECISIONS:

      const { decisionData } = action.payload
      return {
        ...state,
        decisionState: decisionData
      }
      case HANDLERANGEACTIVETOOLTIP:
                  const { tooltipProps } = action.payload
                  return {
                    ...state,
                    rangeActiveTooltip: tooltipProps
                  }

    case GETALLDECISIONRANGE:

      const { fetchStatus, rangeData: { decisionId, decisionRangesData } } = action.payload
      let copydecisionRangesState: Array<any> = [...state.decisionRanges];

      if (fetchStatus === "empty") {
        copydecisionRangesState = [{ decisionId, decisionRangesData }]
      }

      if (fetchStatus === "new") {
        copydecisionRangesState = [...state.decisionRanges, { decisionId, decisionRangesData }]
      }

      if (fetchStatus === "update") {

        copydecisionRangesState.forEach((range: any, index: number) => {
          if (decisionId === range.decisionId) {
            copydecisionRangesState[index] = { decisionId, decisionRangesData }
          }
        })
      }

      return {
        ...state,
        decisionRanges: [...copydecisionRangesState]
      }

    case HANDLEDECISIONREFRESH:
      const { decisionReload } = action.payload
      return {
        ...state,
        decisionReload: decisionReload
      }

    case HANDLERANGEREFRESH:
      const { rangeReload } = action.payload
      return {
        ...state,
        rangeReload: rangeReload
      }

    case HANLDERANGEACTIVEELEMENT:
      const { activeElementsProps } = action.payload
      return {
        ...state,
        rangeActiveElement: activeElementsProps
      }

    case HANDLERANGEACTIVEROW:
      const { activeRowProps } = action.payload
      return {
        ...state,
        rangeActiveRow: activeRowProps
      }

    //END E
    default:
      return state;
  }
}
