import React, { FC } from "react";
import classes from "./SidePanelInput.module.scss";
import { icons, storageKeys } from "../../../../../settings/settings";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../../store/combineReducer";
import {
  handleStrategyBoardSidePanelContent,
  handleStrategyBoardSidePanelInputStage,
} from "../../../../../store/actions/actionsStrategy";
import InputUpload from "./Upload/inputUpload";

const SidePanelInput: FC = () => {
  const dispatch = useDispatch();
  const {
    reducerStrategy: {
      StrategyBoardSidePanel: {
        CollapseStatus: { collapseStatus_value },
        Input: {
          input_stage: { stage_value },
        },
      },
    },
  } = useSelector((state: RootState) => state);

  const checkSourceData = () => {
    const sourceDataJSON = sessionStorage.getItem(
      storageKeys.activeStrategySourceData
    );

    if (!sourceDataJSON) return true;

    const { data } = JSON.parse(sourceDataJSON);

    return !Boolean(Object.values(data).length);
  };

  const handleInputStage = (stage: string | null) => {
    dispatch(handleStrategyBoardSidePanelInputStage({ stage_value: stage }));

    if (stage === "create")
      dispatch(
        handleStrategyBoardSidePanelContent({
          content_value: "input_data_create_new",
        })
      );
  };

  return (
    <div
      id="SidePanelInputWrapper"
      className={
        stage_value === null
          ? classes.SidePanelInputWrapper
          : classes.SidePanelInputUploadWrapper
      }
      style={collapseStatus_value ? { left: "10.5vh" } : { left: "24.7vh" }}
    >
      {stage_value === null && (
        <>
          {checkSourceData() && (
            <span
              className={classes.SidePanelInputUpload}
              onClick={() => handleInputStage("upload")}
            >
              <img src={icons.sidePanelInputUpload} alt="Upload icon" />
              <span>Upload</span>
            </span>
          )}
          <span
            className={classes.SidePanelInputCreate}
            onClick={() => handleInputStage("create")}
          >
            <img src={icons.sidePanelInputCreateXML} alt="Create XML icon" />
            <span>Create JSON/XML</span>
          </span>
        </>
      )}

      <InputUpload />
    </div>
  );
};

export default SidePanelInput;
