export const completeParentheses = (value: string): string => {
  const matchedOpenParentheses: Array<string> | null = value.match(/[(]/g);
  const matchedCloseParentheses: Array<string> | null = value.match(/[)]/g);
  const openParenthesesCount: number = matchedOpenParentheses
    ? matchedOpenParentheses.length
    : 0;
  const closeParenthesesCount: number = matchedCloseParentheses
    ? matchedCloseParentheses.length
    : 0;

  if (openParenthesesCount > closeParenthesesCount) return ")";

  return "(";
};
