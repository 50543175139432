import { StrategyActionsTypes, StrategyBoardElementOutput2, STRATEGY_BOARD_ELEMENT_OUTPUT2 } from "../../../../types/typesStrategy";


const initialState: StrategyBoardElementOutput2 = {
    element_output2:null
}

const reducer = (state = initialState, action: StrategyActionsTypes): StrategyBoardElementOutput2 => {

    switch (action.type) {

        case STRATEGY_BOARD_ELEMENT_OUTPUT2:
            const {element_output2} = action.payload;
            
            return {
                element_output2
            }

        default:
            return state

    }
}

export default reducer