import { Dispatch, SetStateAction, useState } from "react";
import { endpoints } from "../api/endpoints";
import { request } from "../helpers/request";
import { messages } from "../settings/settings";
import { useDispatch } from "react-redux";

interface IFetchMethod {
  reduxData?: any;
  action: (data: any) => any;
  refresh?: boolean;
  additionalHandler?: (data: any) => void;
}

const useGetFetchData = () => {
  const dispatch = useDispatch();

  return async (
    url: string,
    isLoading: boolean,
    setLoading: Dispatch<SetStateAction<boolean>>,
    {
      reduxData = false,
      additionalHandler,
      refresh = false,
      action,
    }: IFetchMethod
  ) => {
    if ((reduxData && !refresh) || isLoading) return;

    setLoading(true);

    const { success, response } = await request.get(url);
    if (!success) {
      console.warn(messages.messageApiGetStatusFalse, response);
      return;
    }

    additionalHandler?.(response);
    dispatch(action(response));
    setLoading(false);

    return response;
  };
};

const useGetAutomationData = <T = never>(
  url: string | ((someData: T | null) => string)
) => {
  const getData = useGetFetchData();
  const [loading, setLoading] = useState(false);

  function get(fetchArguments: IFetchMethod): Promise<any>;
  function get(fetchArguments: IFetchMethod, someData: T): Promise<any>;
  function get(fetchArguments: IFetchMethod, someData?: T) {
    return getData(
      typeof url === "string" ? url : url(someData!),
      loading,
      setLoading,
      fetchArguments
    );
  }

  return { get, isLoading: loading, setLoading: setLoading };
};

const {
  strategy_designer: {
    strategy: strategiesUrl,
    strategy_status: strategyStatusUrl,
    strategy_version: strategyVersionsUrl,
  },
  score_card: {
    score_card: scorecardUrl,
    category: categoryUrl,
    criteria: criteriaUrl,
  },
  product_designer: { products: productsUrl },
  decision_designer: { decision: decisionUrl },
} = endpoints;

export const useGetAllData = () => {
  // region Strategy
  const getAllStrategies = useGetAutomationData(strategiesUrl.getAll);
  const getAllStrategyStatuses = useGetAutomationData(strategyStatusUrl.getAll);
  const getAllStrategyVersions = useGetAutomationData(
    strategyVersionsUrl.getAll
  );
  // endregion

  // region Scorecard
  const getAllScorecards = useGetAutomationData(scorecardUrl.get());
  const getAllScorecardCategories = useGetAutomationData(categoryUrl.getAll);
  const getAllScorecardCriteria = useGetAutomationData(criteriaUrl.getAll);
  // endregion

  // region Product
  const getAllProducts = useGetAutomationData(productsUrl.getAll);
  // endregion

  // region Decision
  const getAllDecisions = useGetAutomationData(decisionUrl.get);
  // endregion

  return {
    strategy: {
      getAllStrategies,
      getAllStrategyStatuses,
      getAllStrategyVersions,
    },
    scorecard: {
      getAllScorecards,
      getAllScorecardCategories,
      getAllScorecardCriteria,
    },
    product: { getAllProducts },
    decision: { getAllDecisions },
  };
};
