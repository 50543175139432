import React from "react";
import classes from "./Header.module.scss";
import Button from "../../../components/UI/Button/ButtonV2";
import { ButtonSizes } from "../../../settings/settings";

interface IHeader {
  title: string;
  addBtnTitle: string;
  onAdd: () => void;
}

const Header = ({
  title,
  addBtnTitle,
  onAdd,
}: IHeader) => {
  return (
    <header className={classes.Header}>
      <section className={classes.TitleSection}>
        <span className={classes.Title}>{title}</span>
        <div className={classes.BtnGroup}>
          {/* <svg
            className={classes.SearchIcon}
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Search</title>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.6011 10.796L15.4749 14.8249C15.8026 15.1657 15.7917 15.7091 15.4511 16.0369C15.2909 16.1911 15.0797 16.276 14.8571 16.276C14.622 16.276 14.4026 16.1826 14.2394 16.0131L10.3363 11.9537C9.228 12.7326 7.93171 13.1429 6.57143 13.1429C2.948 13.1429 0 10.1949 0 6.57143C0 2.948 2.948 0 6.57143 0C10.1949 0 13.1429 2.948 13.1429 6.57143C13.1429 8.12229 12.5971 9.612 11.6011 10.796ZM11.4286 6.57143C11.4286 3.89314 9.24971 1.71429 6.57143 1.71429C3.89314 1.71429 1.71429 3.89314 1.71429 6.57143C1.71429 9.24971 3.89314 11.4286 6.57143 11.4286C9.24971 11.4286 11.4286 9.24971 11.4286 6.57143Z"
            />
          </svg> */}
          <Button size={ButtonSizes.LARGE} onClick={onAdd}>
            {addBtnTitle}
          </Button>
        </div>
      </section>
    </header>
  );
};

export default Header;
