import {
  MODAL_CLOSE,
  MODAL_OPEN,
  MODAL_RESET,
  ModalActions,
  IModalState,
} from "../../types/typesModal";
import { ButtonTypes } from "../../../settings/settings";

const initialState: IModalState = {
  modalState: {
    visible: false,
    title: "",
    question: "",
    message: "",
    buttonMessage: "",
    buttonType: ButtonTypes.PRIMARY,
    validatingField: "",
    name: null,
    fields : []
  },
};

const reducer = (state = initialState, actions: ModalActions): IModalState => { 
  
  switch (actions?.type) {
    case MODAL_OPEN: {
      const { modalState } = actions?.payload;
      return { modalState };
    }
    case MODAL_CLOSE: {
      return {
        modalState: {
          ...initialState.modalState,
          validatingField: state.modalState.validatingField,
        },
      };
    }
    case MODAL_RESET: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
