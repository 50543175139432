import {  StrategyActionsTypes, StrategyFetchStatus } from "../../../types/typesStrategy";

const initialState:StrategyFetchStatus = {
    status: false
}

const StrategyFetchStatusReducer = (state = initialState, action:StrategyActionsTypes):StrategyFetchStatus => {
    switch (action.type) {
        case "STRATEGY_FETCH_STATUS":
            const {status} = action.payload
            
            return {
                status
            }
    
        default:
            return state;
    }
}

export default StrategyFetchStatusReducer