import React,{ FC } from "react";
import classes from './Message.module.scss';
interface IMessage{
    style?:{
        left:number;
        top:number
    }
}
export const Message:FC<IMessage> = ({style})=>{
    
    return(
        <div id="tooltip_message" style={{left:style?.left ? `${style.left}vh`:0,top:style?.top ? `${style.top}vh`:'6.6vh'}} className={classes.Tooltip}>
            <div className={classes.Message}>
                This name already exists
            </div>
        </div>
    )
}
