import { StrategyActionsTypes, StrategyBoardData, STRATEGY_BOARD_DATA } from "../../../../types/typesStrategy";


const initialState: StrategyBoardData = {
    data: []
}

const reducerStrategyData = (state = initialState, action: StrategyActionsTypes): StrategyBoardData => {

    switch (action.type) {

        case STRATEGY_BOARD_DATA:
            const { data } = action.payload

            return {
                data
            };

        default:
            return state
    }
}

export default reducerStrategyData