import {
  MODAL_CLOSE,
  MODAL_OPEN,
  MODAL_RESET,
  IModalCloseAction,
  IModalOpenAction,
  IModalResetAction,
  IModalState,
} from "../types/typesModal";

export function openModal(data: IModalState): IModalOpenAction {
  return {
    type: MODAL_OPEN,
    payload: data,
  };
}

export function closeModal(): IModalCloseAction {
  return {
    type: MODAL_CLOSE,
  };
}

export function resetModal(): IModalResetAction {
  return {
    type: MODAL_RESET,
  };
}
