import { ActiveStrategy, StrategyActionsTypes } from "../../../types/typesStrategy";

const initialState:ActiveStrategy = {
    data: null
}

const ActiveStrategyReducer = (state = initialState, action:StrategyActionsTypes):ActiveStrategy => {
    switch (action.type) {
        case "ACTIVE_STRATEGY":
            const {data} = action.payload
            
            return {
                data
            }
    
        default:
            return state;
    }
}

export default ActiveStrategyReducer