import { StrategyActionsTypes, StrategyApprovedByStatus } from "store/types/typesStrategy";

export const initialState: StrategyApprovedByStatus = {
  approvedStatus: null,
}

const reducerApprovedBy = (state = initialState, action: StrategyActionsTypes): StrategyApprovedByStatus => {
  switch (action.type) {
    case "STRATEGY_APPROVED_BY_STATUS":
      const {approvedStatus} = action.payload;

      return {
        approvedStatus,
      };
    default:
      return state;
  }
}

export default reducerApprovedBy