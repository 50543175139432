type Coord = {
    x: number,
    y: number
}
type ElementProperty = {
    s_x: number,
    s_y: number,
    s_width: number,
    s_height: number,
    t_x: number,
    t_y: number,
    t_width: number,
    t_height: number
}
type Size = {
    width: string,
    height: string
}
export const draw_top_first = (arrow: SVGPathElement, coord: Coord, element_property: ElementProperty, size: Size, drawn_split: Array<string>) => {
    const svg = document.querySelector(`#svg_board`) as SVGElement;
      const { s_x, s_y, s_width, t_x, t_y, t_width, t_height } = element_property;
    const { width } = size;

    const contact = arrow?.dataset.contact;

    let m = `M${s_x + s_width / 2} ${s_y - 12}`;

    let vertival: number = (t_y + t_height + 32) - (coord.y)

    let horizontal: number = ((coord.x + +width / 2) - (t_x + t_width / 2));
    let h = +width > t_width ? (
        +coord.x + (+width - t_width) / 2 >= t_x
            ?
            `h-${Math.abs(horizontal)}`
            :
            `h${Math.abs(horizontal)}`
    ) : (
        +width === t_width ?
            ((coord.x < t_x) ? `h${Math.abs(horizontal)}` : `h-${Math.abs(horizontal)}`)
            :
            (
                +width < t_width ?
                    (
                        +coord.x - (t_width - +width) / 2 >= t_x ?
                            `h-${Math.abs(horizontal)}`
                            : `h${Math.abs(horizontal)}`
                    )
                    : `h-${Math.abs(horizontal)}`
            )

    )
    drawn_split[0] = m;
    drawn_split[1] = `v-${Math.abs(vertival / 2)}`
    drawn_split[2] = h
    drawn_split[3] = `v-${Math.abs(vertival / 2)}`


    drawn_split.join(',');
    const text = svg.querySelector(`text[id = "${contact}"]`) as SVGTextElement;
    if (horizontal <= 0) {
        let _h = Math.abs(horizontal);
        if (_h < 40) {

            text?.setAttributeNS(null, 'x', `${s_x + s_width / 2 - 72}`)
            text?.setAttributeNS(null, 'y', `${(s_y - 12) - Math.abs(vertival / 2) - 20}`);
            text?.setAttributeNS(null, 'transform', `rotate(90 ${(s_x + s_width / 2 - 72) + 25} ${((s_y - 12) - Math.abs(vertival / 2) - 20) + 25})`)

        } else {

            text?.setAttributeNS(null, 'x', `${s_x + s_width / 2 + _h / 2 - 15}`)
            text?.setAttributeNS(null, 'y', `${(s_y - 12) - Math.abs(vertival / 2) - 10}`);
            text?.setAttributeNS(null, 'transform', `rotate(0 ${(s_x + s_width / 2) + _h / 2 - 15} ${((s_y - 12) - Math.abs(vertival / 2) - 10) + 25})`)

        }
    } else {
        let _h = Math.abs((horizontal));
        if (_h < 40) {

            text?.setAttributeNS(null, 'x', `${s_x + s_width / 2 - 40}`)
            text?.setAttributeNS(null, 'y', `${(s_y - 12) - Math.abs(vertival / 2) - 20}`);
            text?.setAttributeNS(null, 'transform', `rotate(90 ${(s_x + s_width / 2 - 40) + 25} ${((s_y - 12) - Math.abs(vertival / 2) - 20) + 25})`)

        } else {
            text?.setAttributeNS(null, 'x', `${s_x + s_width / 2 - _h / 2 - 15}`)
            text?.setAttributeNS(null, 'y', `${(s_y - 12) - Math.abs(vertival / 2) - 10}`);
            text?.setAttributeNS(null, 'transform', `rotate(0 ${(s_x + s_width / 2) - _h / 2 - 15} ${((s_y - 12) - Math.abs(vertival / 2) - 10) + 25})`)

        }
    }
    arrow.setAttributeNS(null, 'd', `${drawn_split}`);

}
export const draw_top_last = (arrow: SVGPathElement, coord: Coord, element_property: ElementProperty, size: Size, drawn_split: Array<string>) => {
    const svg = document.querySelector(`#svg_board`) as SVGElement;
    const { s_x, s_y, s_width, s_height } = element_property;
    const { width, height } = size;
    const contact = arrow?.dataset.contact;

    let m = `M${+s_x + +s_width / 2} ${+s_y - 12}`;

    let vertival: number = (s_y - 32) - (coord.y + +height / 2 + 36)

    let horizontal: number = ((coord.x + +width / 2) - (s_x + s_width / 2));
    let h = +width > s_width ? (
        +coord.x + (+width - s_width) / 2 >= s_x
            ?
            `h${Math.abs(horizontal)}`
            :
            `h-${Math.abs(horizontal)}`
    ) : (
        +width === s_width ?
            ((coord.x < s_x) ? `h-${Math.abs(horizontal)}` : `h${Math.abs(horizontal)}`)
            :
            (
                +width < s_width ?
                    (
                        +coord.x - (s_width - +width) / 2 >= s_x ?
                            `h${Math.abs(horizontal)}`
                            : `h-${Math.abs(horizontal)}`
                    )
                    : `h-${Math.abs(horizontal)}`
            )

    )
    drawn_split[0] = m;
    drawn_split[1] = `v-${vertival / 2}`
    drawn_split[2] = h
    drawn_split[3] = `v-${vertival / 2}`


    drawn_split.join(',');

    const text = svg.querySelector(`text[id = "${contact}"]`) as SVGTextElement;
    if (horizontal <= 0) {
        let _h = Math.abs(horizontal);
        if (_h < 40) {

            text?.setAttributeNS(null, 'x', `${s_x + s_width / 2 - 40}`)
            text?.setAttributeNS(null, 'y', `${(s_y - 12) - Math.abs(vertival / 2) - 20}`);
            text?.setAttributeNS(null, 'transform', `rotate(90 ${(s_x + s_width / 2 - 40) + 25} ${((s_y - 12) - Math.abs(vertival / 2) - 20) + 25})`)

        } else {

            text?.setAttributeNS(null, 'x', `${s_x + s_width / 2 - _h / 2 - 15}`)
            text?.setAttributeNS(null, 'y', `${(s_y - 12) - Math.abs(vertival / 2) - 10}`);
            text?.setAttributeNS(null, 'transform', `rotate(0 ${(s_x + s_width / 2) - _h / 2 - 15} ${((s_y - 12) - Math.abs(vertival / 2) - 10) + 25})`)

        }
    } else {
        let _h = Math.abs((horizontal));
        if (_h < 40) {

            text?.setAttributeNS(null, 'x', `${s_x + s_width / 2 - 72}`)
            text?.setAttributeNS(null, 'y', `${s_y + s_height / 2 - 12 - Math.abs(vertival / 2) - 48}`);
            text?.setAttributeNS(null, 'transform', `rotate(90 ${(s_x + s_width / 2 - 72) + 25} ${s_y + s_height / 2 - 12 - Math.abs(vertival / 2) - 48 + 25})`)

        } else {
            text?.setAttributeNS(null, 'x', `${s_x + s_width / 2 + _h / 2 - 15}`)
            text?.setAttributeNS(null, 'y', `${s_y - 12 - Math.abs(vertival / 2) - 10}`);
            text?.setAttributeNS(null, 'transform', `rotate(0 ${s_x + s_width / 2 + _h / 2 - 15 + 25} ${s_y - 12 - Math.abs(vertival / 2) - 10 + 25})`)

        }
    }
    arrow.setAttributeNS(null, 'd', `${drawn_split}`);

}