import classes from "./Graph.module.scss";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/combineReducer";


const Graph:React.FC = ()=>{
    const [svgHeight,setSvgHeight] = React.useState(0);

    const svgRef:any = React.useRef();
    const coordXref:any = React.useRef();


    const store = useSelector((state: RootState) =>{
        return state;
    });

    React.useEffect(()=>{
        setSvgHeight(svgRef.current?.clientHeight)
    },[store.reducerScoreCard.scoreCardCategoryData.scoreCardCategoryData])


    const getPositivePoint = (level:any,field:any)=> {
        const {scoreCardId,categoryId,criteriaId} = store.reducerScoreCard.scoreCardLevelDataId;

        let data = level === 2 ? store.reducerScoreCard.scoreCardCategoryData.scoreCardCategoryData :
            (level === 3 ? store.reducerScoreCard.scoreCardCriteriaData.scoreCardCriteriaData : 
            (level === 4 ? store.reducerScoreCard.scoreCardAttributeData.scoreCardAttributeData : []))
        return level === 2 ? data?.filter((el:any)=>el?.point && +el?.point>0 && el?.score_card_id === scoreCardId) :
        (
            level === 3 ?  data?.filter((el:any)=>el?.point && +el?.point>0 && el?.score_card_category_id === categoryId):
            (level === 4 ?  data?.filter((el:any)=>el?.point && +el?.point>0 && el?.score_card_criteria_id === criteriaId):null)
        )
    }
    const getNegativePoint = (level:any,field:any)=> {
        const {scoreCardId,categoryId,criteriaId} = store.reducerScoreCard.scoreCardLevelDataId;
        let data = level === 2 ? store.reducerScoreCard.scoreCardCategoryData.scoreCardCategoryData :(level === 3 ? store.reducerScoreCard.scoreCardCriteriaData.scoreCardCriteriaData : (level === 4 ? store.reducerScoreCard.scoreCardAttributeData.scoreCardAttributeData : []))
        return level === 2 ? data?.filter((el:any)=>el?.point && +el?.point<0 && el?.score_card_id === scoreCardId) :
         (
             level === 3 ?  data?.filter((el:any)=>el?.point && +el?.point<0 && el?.score_card_category_id === categoryId):
             (level === 4 ?  data?.filter((el:any)=>el?.point && +el?.point<0 && el?.score_card_criteria_id === criteriaId):null)
         )
    }
   
    const getOnlyPositive = (level:any,field:any)=>{
        const {scoreCardId,categoryId,criteriaId} = store.reducerScoreCard.scoreCardLevelDataId;
        let data = +level === 2 ? store.reducerScoreCard.scoreCardCategoryData.scoreCardCategoryData :(+level === 3 ? store.reducerScoreCard.scoreCardCriteriaData.scoreCardCriteriaData : (+level === 4 ? store.reducerScoreCard.scoreCardAttributeData.scoreCardAttributeData : null))
        return data && data[0]?.hasOwnProperty('point') && (level === 2  ?
            +data.reduce((max:any, p:any) =>p[field] &&  Math.abs(+p[field]) > max && +p.score_card_id === scoreCardId ? Math.abs(+p[field]) : max, Math.abs(+data[0][field]))
             :
             (level === 3 ?
                +data.reduce((max:any, p:any) =>p[field] &&  Math.abs(+p[field]) > max && +p.score_card_category_id === categoryId ? Math.abs(+p[field]) : max, Math.abs(+data[0][field]))
             :
            (level === 4 ?
                +data.reduce((max:any, p:any) =>p[field] && Math.abs(+p[field]) > max && +p.score_card_criteria_id === criteriaId  ? Math.abs(+p[field]) : max, Math.abs(+data[0][field]))
             : 0)))
    }
    
    
    let level = +store.reducerScoreCard.scoreCardLevel.level;
    let storeActiveID = store.reducerScoreCard.scoreCardLevelDataId;
    let parentID = level === 2 ? storeActiveID.scoreCardId :(level === 3 ? storeActiveID.categoryId :storeActiveID.criteriaId);
    const data = +level === 2 ? store.reducerScoreCard.scoreCardCategoryData.scoreCardCategoryData :(+level === 3 ? store.reducerScoreCard.scoreCardCriteriaData.scoreCardCriteriaData : (+level === 4 ? store.reducerScoreCard.scoreCardAttributeData.scoreCardAttributeData : []))
    
    const maxPoint =  getOnlyPositive(level,'point');
    const ceiledPoint =  maxPoint 
    let coordPoint = ceiledPoint ? (ceiledPoint%3 === 0 ? +ceiledPoint/3 : +(+((ceiledPoint/3)%10).toFixed(0) <5 ? +(ceiledPoint/3).toExponential(0)+5 :(+((ceiledPoint/3)%10).toFixed(0) === 5 ? ceiledPoint/3 :+(ceiledPoint/3).toExponential(0)))):null;
 
    const topScroll = (e:any,num:any)=>{
        let container:any =  document.getElementById(`table_row_${num}`);
        container.style.background='#f3f3f5'
        setTimeout(()=>{
          container.style.background='transparent'

        },1500)
        window.scrollTo({top:container?.getBoundingClientRect().y,behavior:'smooth'})
    }
    return(
        <svg className={classes.Graph} style={{float:'left'}} ref={svgRef} width={data?.length > 0 ? data?.length*4+'vw' :'100%'} height='100%' viewBox={`0 0 ${data?.length > 0 ? data?.length*4+'vw' :'100%'} 100%`} xmlns="http://www.w3.org/2000/svg">
            <g id="coordY">
                <rect x='35' y='0' width='.2vh' height='100%' fill='#F3F3F5'/>
            </g>
            <g id="coordX">
                <rect ref={coordXref} x='35' y={`${svgHeight/2}px`} width='100%' height='.2vh' fill='#F3F3F5'/>
                <text opacity='.4' fontSize='1.8vh' x='12' y={svgHeight/2+4}>0</text>

            </g>
            {coordPoint && getPositivePoint(level,'point').length>1 &&
                [coordPoint,coordPoint*2].map((line:any,i:number)=>{
                
                    
                    
                    return(
                    
                        <g key={i} id="coordX">
                            <rect x='35' y={`calc(${svgHeight/2}px - ${35*(i+1)*0.13}vh)`} width='100%' height='.2vh' fill='#F3F3F5'/>
                            <text opacity='.4' fontSize='1.4vh' x='0' y={svgHeight/2 - (35*(i+1)-5)}>{line.toFixed(2)}</text>
                        </g>
                    
                    )

                    
            })


            }
            {coordPoint && getNegativePoint(level,'point').length>1  && 
                [coordPoint,coordPoint*2].map((line:any,i:number)=>{
                
                    
                    
                    return(
                    
                        <g key={i} id="coordX">
                            <rect x='28' y={`calc(${svgHeight/2}px + ${35*(i+1)*0.13}vh)`} width='100%' height='.2vh' fill='#F3F3F5'/>
                            <text opacity='.4' fontSize='1.4vh' x='0' y={svgHeight/2 + (35*(i+1)+4)}>{-line}</text>
                        </g>
                    
                    )

                    
            })


            }

            {+store.reducerScoreCard.scoreCardLevel.level === 2 && store.reducerScoreCard.scoreCardCategoryData.scoreCardCategoryData?.filter((data:any)=>+data?.score_card_id === +store.reducerScoreCard.activeTree.activeTree[0]?.id)?.sort((a:any,b:any)=>+a?.point - +b?.point)?.map((bar:any,i:number)=>{
                
                return(
                    <g key={i} id="bar">
                            {bar?.point>=0 &&
                                <>
                                {/* <rect x={(i+1)*32} y={`calc(${svgHeight/2} - ${(bar?.point + 20)})`} width={20} height={20} fill='transparent'  rx='4'/> */}
                                <text fontSize='1.5vh' opacity='.6'  x={(i+1)*32+10} y={svgHeight/2 - (((Math.abs(bar?.point)/maxPoint)*100+5))}>{Number(bar?.point)}</text>
                                <rect onClick={(e)=>topScroll(e,bar.id)} x={(i+1)*32+10} y={`calc(${svgHeight/2}px - ${((bar?.point/maxPoint)*100)}px)`} width='20' height={((bar?.point/maxPoint)*100)} fill='#50C7EE'  rx='4' />
                                <text opacity='.6' fontSize='1.6vh' alignmentBaseline="middle" dominantBaseline="middle"  textAnchor="end"  transform={`translate(10 0) rotate(-90 ${(i+1)*32+10} ${svgHeight/2+5})`}   x={(i+1)*32+10} y={svgHeight/2+5} >{bar.name}</text>
                                </>
                            }
                            {bar?.point<0 &&
                                <>
                                {/* <rect x={(i+1)*32} y={`calc(${svgHeight/2}px + ${(Math.abs(bar?.point) + 20)}px)`} width={20} height={20} fill='transparent'  rx='4'/> */}
                                <text fontSize='1.5vh' opacity='.6'  x={(i+1)*32-2+10} y={svgHeight/2 + ((Math.abs(bar?.point)/maxPoint)*100+15)}>
                                   {Number(bar?.point)}
                                </text>
                                <rect onClick={(e)=>topScroll(e,bar.id)} x={(i+1)*32+10} y={`calc(${svgHeight/2}px)`} width='20' height={((Math.abs(bar?.point)/maxPoint)*100)} fill='#ED7D97'  rx='4' />
                                <text opacity='.6' fontSize='1.6vh'  alignmentBaseline="middle" dominantBaseline="middle"  textAnchor="end"  transform={`translate(10 0) rotate(90 ${(i+1)*32+10} ${svgHeight/2-5})`}   x={(i+1)*32+10} y={svgHeight/2-5} >{bar.name}</text>
                                </>
                            }
                    </g>
                )
            })}
            {+level === 3 && store.reducerScoreCard.scoreCardCriteriaData.scoreCardCriteriaData?.filter((data:any)=>+data?.score_card_category_id === parentID )?.sort((a:any,b:any)=>+a?.point - +b?.point)?.map((bar:any,i:number)=>{

                return(
                    <g key={i} id="bar">
                            {bar?.point>=0 &&
                                <>
                                {/* <rect x={(i+1)*32} y={`calc(${svgHeight/2} - ${(bar?.point + 20)})`} width={20} height={20} fill='transparent'  rx='4'/> */}
                                <text fontSize='1.5vh' opacity='.6'  x={(i+1)*32+10} y={svgHeight/2 - (((Math.abs(bar?.point)/maxPoint)*100+5))}>{Number(bar?.point)}</text>
                                <rect onClick={(e)=>topScroll(e,bar.id)} x={(i+1)*32+10} y={`calc(${svgHeight/2}px - ${((bar?.point/maxPoint)*100)}px)`} width='20' height={((bar?.point/maxPoint)*100)} fill='#50C7EE'  rx='4' />
                                <text opacity='.6' fontSize='1.6vh' alignmentBaseline="middle" dominantBaseline="middle"  textAnchor="end"  transform={`translate(10 0) rotate(-90 ${(i+1)*32+10} ${svgHeight/2+5})`}   x={(i+1)*32+10} y={svgHeight/2+5} >{bar.name}</text>
                                </>
                            }
                            {bar?.point<0 &&
                                <>
                                {/* <rect x={(i+1)*32} y={`calc(${svgHeight/2}px + ${(Math.abs(bar?.point) + 20)}px)`} width={20} height={20} fill='transparent'  rx='4'/> */}
                                <text fontSize='1.5vh' opacity='.6'  x={(i+1)*32-2+10} y={svgHeight/2 + ((Math.abs(bar?.point)/maxPoint)*100+15)}>
                                   {Number(bar?.point)}
                                </text>
                                <rect onClick={(e)=>topScroll(e,bar.id)} x={(i+1)*32+10} y={`calc(${svgHeight/2}px)`} width='20' height={((Math.abs(bar?.point)/maxPoint)*100)} fill='#ED7D97'  rx='4' />
                                <text opacity='.6' fontSize='1.6vh'  alignmentBaseline="middle" dominantBaseline="middle"  textAnchor="end"  transform={`translate(10 0) rotate(90 ${(i+1)*32+10} ${svgHeight/2-5})`}   x={(i+1)*32+10} y={svgHeight/2-5} >{bar.name}</text>
                                </>
                            }
                    </g>
                )
            })}
            {+level === 4 && store.reducerScoreCard.scoreCardAttributeData.scoreCardAttributeData?.filter((data:any)=>+data?.score_card_criteria_id === parentID )?.sort((a:any,b:any)=>+a?.point - +b?.point)?.map((bar:any,i:number)=>{

                return(
                    <g key={i} id="bar">
                            {bar?.point>=0 &&
                                <>
                                {/* <rect x={(i+1)*32} y={`calc(${svgHeight/2} - ${(bar?.point + 20)})`} width={20} height={20} fill='transparent'  rx='4'/> */}
                                <text fontSize='1.5vh' opacity='.6'  x={(i+1)*32+10} y={svgHeight/2 - (((Math.abs(bar?.point)/maxPoint)*100+5))}>{Number(bar?.weight)}</text>
                                <rect onClick={(e)=>topScroll(e,bar.id)} x={(i+1)*32+10} y={`calc(${svgHeight/2}px - ${((bar?.point/maxPoint)*100)}px)`} width='20' height={((bar?.point/maxPoint)*100)} fill='#50C7EE'  rx='4' />
                                <text opacity='.6' fontSize='1.6vh' alignmentBaseline="middle" dominantBaseline="middle"  textAnchor="end"  transform={`translate(10 0) rotate(-90 ${(i+1)*32+10} ${svgHeight/2+5})`}   x={(i+1)*32+10} y={svgHeight/2+5} >{bar.label}</text>
                                </>
                            }
                            {bar?.point<0 &&
                                <>
                                {/* <rect x={(i+1)*32} y={`calc(${svgHeight/2}px + ${(Math.abs(bar?.point) + 20)}px)`} width={20} height={20} fill='transparent'  rx='4'/> */}
                                <text fontSize='1.5vh' opacity='.6'  x={(i+1)*32-2+10} y={svgHeight/2 + ((Math.abs(bar?.point)/maxPoint)*100+15)}>
                                   {Number(bar?.weight)}
                                </text>
                                <rect onClick={(e)=>topScroll(e,bar.id)} x={(i+1)*32+10} y={`calc(${svgHeight/2}px)`} width='20' height={((Math.abs(bar?.point)/maxPoint)*100)} fill='#ED7D97'  rx='4' />
                                <text opacity='.6' fontSize='1.6vh'  alignmentBaseline="middle" dominantBaseline="middle"  textAnchor="end"  transform={`translate(10 0) rotate(90 ${(i+1)*32+10} ${svgHeight/2-5})`}   x={(i+1)*32+10} y={svgHeight/2-5} >{bar.label}</text>
                                </>
                            }
                    </g>
                )
            })}
        </svg>

    )
}

export default Graph;