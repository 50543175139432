import { EyeHideIcon, EyeIcon } from "components/UI/Svg/Svg";
import { toSlug } from "helpers/slug.helper";
import { ChangeEvent, FC, useState } from "react";
import { Container, IconBox, Input, Label, Message } from "./Input.style";

interface IFormInput {
  type?: string;
  label: string;
  message?: string;
  value?: string;
  isHide?: boolean;
  disable?:boolean;
  onChange?:(e:ChangeEvent<HTMLInputElement>)=>void;
}
export const FormInput: FC<IFormInput> = ({
  type,
  label,
  value,
  message,
  isHide,
  disable,
  onChange
}) => {
  const [stateIsHide,setIsHide] = useState<boolean>(false);
  return (
    <Container>
      <Label>{label}</Label>
      <Input disabled={disable} autoComplete={type === "password" ? "off":toSlug(label)} type={type && !stateIsHide ? type:"text"} defaultValue={value}  placeholder={label} onChange={onChange} />
      {isHide && (
        <IconBox>
          {stateIsHide && <EyeIcon handler={()=>setIsHide(!stateIsHide)}/>}
          {!stateIsHide && <EyeHideIcon handler={()=>setIsHide(!stateIsHide)}/>}
        </IconBox>
      )}
      {message && <Message>{message}</Message>}
    </Container>
  );
};
