import { StrategyActionsTypes, StrategyStatus } from "../../../types/typesStrategy";

const initialState: StrategyStatus = {
    data: []
}

const StrategyStatusReducer = (state = initialState, action: StrategyActionsTypes): StrategyStatus => {
    switch (action.type) {
        case "STRATEGY_STATUS":
            const {data} = action.payload

            return {
                data
            }
    
        default:
            return state
    }
}

export default StrategyStatusReducer;