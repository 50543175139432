import React, {FC, useRef, memo, useEffect, useState} from 'react';
import {icons } from '../../../../../../settings/settings';

import classes from './ToolsPanel.module.scss';

interface Props{
    selectedElement:string;
    coord:{
        x:number,
        y:number,
        side:string
    },
    addFunction:Function;
    addCondition:Function;
    addCodeMessage:Function,
    addMapping:Function,
    addDecision:Function,
    addProduct:Function,
    addDecisionMatrix:Function
}
const ToolsPanel:FC<Props> = ({selectedElement,coord:{x=0,y=0,side},addFunction=(e:any)=>{},addCondition = (e:any)=>{},addCodeMessage=(e:any)=>{},addMapping=(e:any)=>{},addDecision=(e:any)=>{},addProduct=(e:any)=>{},addDecisionMatrix=(e:any)=>{}})=>{
    const [isActive,setIsActive] = useState<boolean>(true);
    const refTooltip = useRef<HTMLDivElement>(null);
    refTooltip.current?.classList.remove(classes.ToggleTooltip)

    useEffect(()=>{
        if(selectedElement){
            if(selectedElement.includes("condition")){
                const board = document.querySelector("#svg_board") as SVGSVGElement;
                const outputs = board.querySelectorAll(`path[data-contact ^= "${selectedElement}-"]`) as NodeListOf<SVGPathElement>;
                if(outputs.length > 1){
                    setIsActive(false)
                }else if(outputs.length === 1){
                    const output = outputs[0] as SVGPathElement; 
                    if(side !== output.dataset.side){
                        setIsActive(true);
                    }else{
                        setIsActive(false)
                    }
                }else{
                    setIsActive(true);
                }
                
                
            }else{
                setIsActive(false)
            }
        }
    },[selectedElement, side])
    const handleTooltip = ()=>{
            refTooltip.current?.classList.toggle(classes.ToggleTooltip)
        
    }
    return(
        <div className={classes.ToolsPanel} id='tools_panel'  style={{left:`${x}px`,top:`${y}px`,display:(x !== 0 || y !== 0) ? 'block':'none'}}>
            <div title="Function"  onClick= {(e)=>addFunction(e,side)} className={[classes.Te,classes.Circle].join(' ')}>
                
                <img src={icons.sidePanelFunction} alt='function'/>
            
            </div>
            <div title="Condition" onClick= {(e)=>addCondition(e,side)} className={[classes.Te,classes.Rectangle].join(' ')}>
                
                <img src={icons.sidePanelArrows} alt='condition'/>

            </div>   
            <div title ="Mapping" onClick= {(e)=>addMapping(e,side)}  className={[classes.Te,classes.Mapping].join(' ')}>

                <img src={icons.sidePanelMapping} alt='mapping'/>

            </div>
            <div title="Decision"  onClick={handleTooltip} className={[classes.Te,classes.Decision].join(' ')}>

                <img src={icons.sidePanelVector} alt='decision'/>
                <div ref = {refTooltip}  className={classes.DecisionTooltip}>
                    <span onClick= {(e)=>addDecision(e,side)} title='Range'><img src={icons.sidePanelDecisionRange} alt='range' /></span>
                    <span onClick= {(e)=>addDecisionMatrix(e,side)}  title='Matrix'><img src={icons.sidePanelDecisionMatrix} alt='range' /></span>
                </div>
            </div>
            <div title ="Product" onClick= {(e)=>addProduct(e,side)}  className={[classes.Te,classes.Product].join(' ')}>

                <img src={icons.sidePanelProduct} alt='product'/>

            </div>
            <div title="Code and Message" style={isActive ? {pointerEvents:"visible"} : {pointerEvents:"none",opacity:".5"}} onClick= {(e)=>addCodeMessage(e,side)}  className={[classes.Te,classes.CodeMessage].join(' ')}>

                <img src={icons.sidePanelMessage} alt='code and message'/>

            </div>
    </div>
    )
}

export default memo(ToolsPanel);