import { StrategyActionsTypes, StrategyBoardNewCreatedElement } from "../../../../types/typesStrategy"

const initialState = {
    new_created_element: null
}

const newCreatedElementReducer = (state = initialState, actions: StrategyActionsTypes): StrategyBoardNewCreatedElement => {
    switch (actions.type) {
        case "STRATEGY_BOARD_NEW_CREATED_ELEMENT":
            const {new_created_element} = actions.payload
            return {
                new_created_element
            }
    
        default:
            return state
    }
}

export default newCreatedElementReducer