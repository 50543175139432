interface  Attr{
    x:number,
    y:number,
    width:number,
    height:number,
    id:string,
    type:string
}

export const getAttr = (selector:SVGElement):Partial<Attr> =>{
    let property:Partial<Attr> ={};
    if(selector){
        let width:number = Number(selector.getAttribute('width'));
        let height:number = Number(selector.getAttribute('height'));
        let x:number = Number(selector.getAttribute('x'));
        let y:number = Number(selector.getAttribute('y'));
        let id:any = selector.getAttribute('data-id');
        let type:any = selector.getAttribute('data-type');

        property.x = x ?? x;
        property.y =y;
        property.width = width;
        property.height = height;
        property.id = id;
        property.type = type;
        
    }
    return property as Partial<Attr>

}