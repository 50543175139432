import {
    StrategyActionsTypes,
    StrategyBoardSidePanelInputUpload
} from "../../../../../types/typesStrategy";


const initialState: StrategyBoardSidePanelInputUpload = {
    uploadFiles_acceptable: null,
    uploadFiles_ignored: null

}

const reducerInputUpload = (state = initialState, action: StrategyActionsTypes): StrategyBoardSidePanelInputUpload => {

    switch (action.type) {

        case "STRATEGY_BOARD_SIDE_PANEL_INPUT_UPLOAD":
            const { uploadFiles_acceptable, uploadFiles_ignored } = action.payload
            const acceptableFiles = uploadFiles_acceptable?.length ? uploadFiles_acceptable : null
            const ignoredFiles = uploadFiles_ignored?.length ? uploadFiles_ignored : null
            return {
                uploadFiles_acceptable: acceptableFiles,
                uploadFiles_ignored: ignoredFiles
            };

        case "STRATEGY_BOARD_SIDE_PANEL_INPUT_REMOVE_QUEUE_FILE":
            const { accept_status, file_name } = action.payload
            let acceptableListCopy: Array<string> | null = state.uploadFiles_acceptable
            let ignoredListCopy: Array<string> | null = state.uploadFiles_ignored

            if (accept_status === "accepted" && acceptableListCopy !== null) {
                acceptableListCopy = acceptableListCopy.filter((fileName: string) => {
                    return !file_name.includes(fileName)
                })
            }

            if (accept_status === "ignored" && ignoredListCopy !== null) {
                ignoredListCopy = ignoredListCopy.filter((fileName: string) => {
                    return !file_name.includes(fileName)
                })
            }

            return {
                uploadFiles_acceptable: acceptableListCopy,
                uploadFiles_ignored: ignoredListCopy
            }

        case "STRATEGY_BOARD_SIDE_PANEL_INPUT_UPLOAD_RESET":
            return {
                uploadFiles_acceptable: null,
                uploadFiles_ignored: null
            }

        default:
            return state
    }
}

export default reducerInputUpload