import { RequiredActions, RequiredDecisionMatrix, REQUIRED_DECISION_MATRIX } from "../../../../../store/types/required.types";


const initialState: RequiredDecisionMatrix = {
    field:{
        x:false,
        y:false,
        matrix:false
    }
}

const reducer = (state = initialState, action: RequiredActions): RequiredDecisionMatrix => {

    switch (action.type) {

        case REQUIRED_DECISION_MATRIX:
            const {field:{x,y,matrix}} = action.payload;
            
            return {
                field:{
                    x:x ? x :state.field.x,
                    y:y ? y :state.field.y,
                    matrix:matrix ? matrix :state.field.matrix
                }
            }

        default:
            return state

    }
}

export default reducer