import { arrow_type } from '../../../../../../settings/settings';
import {create_arrow_top,create_arrow_right,create_arrow_bottom,create_arrow_left} from './export';
import { getAttr } from "../methods/attributes";

export const create_arrow = (target_element:SVGRectElement,source_element:SVGRectElement,side:any,drop:boolean = false)=>{

    let xmlns:string = "http://www.w3.org/2000/svg";
    const board = document.querySelector(`#svg_board`) as SVGElement;

    const {id:t_id} = getAttr(target_element)
    const {id:s_id} = getAttr(source_element);

    const checkHasOutput = board?.querySelector(`path[data-contact ^= "${t_id}-"]`) as SVGPathElement;
        if(s_id && t_id && !checkHasOutput){
            let arrow = document.createElementNS(xmlns,'path') as SVGPathElement;
            arrow.setAttributeNS(null,'stroke','#788896');
            arrow.setAttributeNS(null,'stroke-width','5.65');
            arrow.setAttributeNS(null,'fill','none');
            arrow.setAttributeNS(null,'stroke-linejoin','round');
            arrow.setAttributeNS(null,'stroke-linecap','round');
            arrow.setAttributeNS(null,'id','arrow');
            arrow.setAttributeNS(null,'data-contact',`${s_id}-${t_id}`);
            if(side){
                switch(side){
                    case 'top':
                        create_arrow_top(source_element,target_element,arrow_type.top,arrow,side);  
                        break;
                    case 'right':
                        create_arrow_right(source_element,target_element,arrow_type.right,arrow,side);
                        break;
                    case 'bottom':
                        create_arrow_bottom(source_element,target_element,arrow_type.bottom,arrow,side);
                        break;
                    case 'left':
                        create_arrow_left(source_element,target_element,arrow_type.left,arrow,side)
                        break;
                    default:
                        return arrow;
                        
                }
            }
            return arrow;

        }
}

