import React, {FC, useEffect} from "react";
import "./App.css";
import Main from './layout/Main/Main';
import Login from './layout/Login/Login';
import StrategyBoard from './layout/StrategyDesigner/StrategyBoard/StrategyBoard'
import { Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeAllStrategyTableSelectedList } from "./store/actions/actionsStrategy";
import { RootState } from "./store";



const App: FC = () => {
  
  const dispatch = useDispatch();
  const { 
    reducer: { pathname },
  } = useSelector((state:RootState) => state)

  const sessionCredentials: string | null = sessionStorage.getItem('credentials');
  const sessionUserDetails: string | null = sessionStorage.getItem('user_details');
  const localCredentials: string | null = localStorage.getItem('credentials');
  const localUserDetails: string | null = localStorage.getItem('user_details')

  useEffect(() => {
    dispatch(removeAllStrategyTableSelectedList())
  }, [pathname, dispatch])

  if ((sessionCredentials && sessionUserDetails) || (localCredentials && localUserDetails)) {
    return (
      <>
      <Switch>
        <Route path={"/strategy-designer/strategy"} exact component={StrategyBoard}/>
        <Route component={Main}/> 
      </Switch>
      </>
    )
  } else {
    localStorage.clear();
    return <Login/>    
  }
};

export default App;