import { EditorKeySources, messages, storageKeys } from "../settings/settings";
import {
  IReformattedFunctions,
  IReformattedFunctionsGroup,
} from "./reformatStrategyFunctions";

interface ISearchLocalPossibilities {
  searchValue: string;
  iterateKey?: string | undefined;
  label?: string;
}

interface IPossibilitiesObj {
  source: EditorKeySources;
  keys: Array<string>;
}

export type ISearchReturn = Array<IPossibilitiesObj> | string;

const searchDeeply = (sourceArray: Array<any>): Array<string> => {
  const result: any = [];

  sourceArray.forEach((sourceObj: any) => {
    const { parent, key, childrenKeys, value, type } = sourceObj;
    if (!childrenKeys) return;

    for (const childKey of childrenKeys) {
      const path = `${key}.${childKey}`;
      const reformatArrayPath = type === "array" ? `${key}[${childKey}]` : path;
      const reformatArrayParentPath = Number.isInteger(key)
        ? `[${key}].${childKey}`
        : reformatArrayPath;
      const reformatParentPath = parent
        ? Number.isInteger(key)
          ? `${parent}${reformatArrayParentPath}`
          : `${parent}.${reformatArrayParentPath}`
        : reformatArrayParentPath;

      result.push(reformatParentPath);
    }

    result.push([...searchDeeply(value)]);
  });

  return result.flat();
};

const getSourceDataKeys = () => {
  const reformattedSourceJSON = sessionStorage.getItem(
    storageKeys.reformattedSourceData
  );

  if (!reformattedSourceJSON) return [];

  const reformattedSourceArray = JSON.parse(reformattedSourceJSON);

  return searchDeeply(reformattedSourceArray);
};

const getLocalVariables = () => {
  const boardElementsJSON = localStorage.getItem(storageKeys.boardElements);
  if (!boardElementsJSON) return [];
  const boardElementsArray = JSON.parse(boardElementsJSON);
  let variables: Array<string> = [];
  boardElementsArray.forEach((element: any) => {
    const {
      type,
      data: { label },
    } = element;

    if (type !== "func") return;

    variables.push(label);
  });
  return variables;
};
const getLocalVariableKeys = (funcLabel: string,iterateKey:string) => {

  if(funcLabel === "ITER" || funcLabel === "ITERATE"){
    funcLabel = "SELF"
  }

  const boardElementsJSON = localStorage.getItem(storageKeys.boardElements);
  if (!boardElementsJSON) return [];
  const boardElementsArray = [...JSON.parse(boardElementsJSON)];
  let variables: Array<string> = [];

  const splitLabel = [...funcLabel.split(".")];
  const [name] = splitLabel;
  splitLabel.shift()
  const [afterDot] = splitLabel;

  const getItemsByLabel = boardElementsArray.filter((item:any)=>item.type === 'func')
  ?.map((item: any) => {
    return { label: item.data.label, overlays: item.overlays };
  });
  const copyOfGetElementsByLabel = [
    ...getItemsByLabel?.filter((item: any) => item.label),
  ];
  copyOfGetElementsByLabel
    ?.map((m: any) => {
      return { label:m.label,search: m.label.toLowerCase(), keys: m.overlays[0]?.keys };
    })
    ?.filter((item: any) => {
      return item.keys;
    })
    ?.filter((item: any) => {
      return item.search.includes(name.toLowerCase()) || (item.search === iterateKey?.toLowerCase())
    })
    ?.forEach((item: any) => {
        let {
          label,
          keys
        } = item;
        if(keys.length === 0) return;
        if(afterDot){
           const keyList = keys?.filter((key:string)=>key.includes(afterDot));
           if(!keyList) return;
           label = label === iterateKey ? "SELF":label
           keyList?.forEach((key:string)=>{
            variables.push(`${label}.${key}`);
           })

        }else{
          label = label === iterateKey ? "SELF":label
          keys?.forEach((key:string)=>{
            variables.push(`${label}.${key}`);
          })
        }
        
      });

  return variables;
};

const getLocalMaps = () => {
  const boardElementsJSON = localStorage.getItem(storageKeys.boardElements);
  if (!boardElementsJSON) return [];
  const boardElementsArray = JSON.parse(boardElementsJSON);
  let maps: Array<string> = [];

  boardElementsArray.forEach((element: any) => {
    const {
      type,
      data: { label },
    } = element;

    if (type !== "scorecard") return;

    // maps.push(label);
    maps.push(`${label}.point`, `${label}.log`);
  });
  return maps;
};
const getLocalDecisions = () => {
  const boardElementsJSON = localStorage.getItem(storageKeys.boardElements);
  if (!boardElementsJSON) return [];
  const boardElementsArray = JSON.parse(boardElementsJSON);
  let decisions: Array<string> = [];

  boardElementsArray.forEach((element: any) => {
    const {
      type,
      data: { label },
    } = element;

    if (type !== "decision") return;

    // decisions.push(label);
    decisions.push(`${label}.label`, `${label}.alias`);
  });
  return decisions;
};
const getIterateChildren = (
  iterateKey: string | undefined,
  dataKeys: Array<string>
) => {
  if (!iterateKey) return [];
  return dataKeys.filter((key) => {
    return key !== iterateKey && new RegExp(iterateKey, "g").test(key);
  })
};

const getFunctions = () => {
  const reformattedFunctionsJSON = sessionStorage.getItem(
    storageKeys.reformattedStrategyFunctions
  );

  if (!reformattedFunctionsJSON) return [];

  const functionGroupsArray: Array<IReformattedFunctionsGroup> = JSON.parse(
    reformattedFunctionsJSON
  );

  return functionGroupsArray
    .map(({ functions }: IReformattedFunctionsGroup) => {
      return functions;
    })
    .flat()
    .map(({ name }: IReformattedFunctions) => {
      return name;
    });
};

export const searchLocalPossibilities = ({
  searchValue,
  iterateKey,
  label,
}: ISearchLocalPossibilities) =>{
  if("ITERATE".includes(searchValue)){
    searchValue = "SELF"
  }
  return new Promise((resolve, reject) => {
    const searchRegExp = new RegExp(`${searchValue}`, "i");
    const sourceDataKeys = getSourceDataKeys();
    const localVariables = getLocalVariables();
    const localVariableKeys = getLocalVariableKeys(searchValue,iterateKey ?? '');
    const localMaps = getLocalMaps();
    const localDecisions = getLocalDecisions();

    const iterateChildren = getIterateChildren(iterateKey, sourceDataKeys);
    const functions = getFunctions();
    let searchResult: Array<IPossibilitiesObj> = [];
    const matchedSourceKeys: IPossibilitiesObj = {
      source: EditorKeySources.INPUT,
      // keys: sourceDataKeys.filter((key) => searchRegExp.test(key)),
      keys:
        label === "Iterate Element"
          ? sourceDataKeys
              .filter((key) => /[[0-9]*?]/.test(key))
              .map((key) => key.split("[")?.[0])
              .filter((key) => searchRegExp.test(key))
              .filter((key, index, self) => self.indexOf(key) === index)
          : sourceDataKeys
              .filter((key) => !/[[0-9]*?]/.test(key))
              .filter((key) => searchRegExp.test(key)),
    };

    const matchedVariables: IPossibilitiesObj = {
      source: EditorKeySources.VARIABLE,
      keys: localVariables.filter((variable) => searchRegExp.test(variable)),
    };
    const matchedVariablesKeys: IPossibilitiesObj = {
      source: EditorKeySources.VARIABLE_KEYS,
      keys: localVariableKeys,
    };

    const matchedMaps: IPossibilitiesObj = {
      source: EditorKeySources.MAP,
      keys: localMaps.filter((map) => searchRegExp.test(map)),
    };

    const matchedDecisions: IPossibilitiesObj = {
      source: EditorKeySources.DECISIONS,
      keys: localDecisions.filter((map) => searchRegExp.test(map)),
    };

    const matchedIterateChildren: IPossibilitiesObj = {
      source: EditorKeySources.SELF,
      keys: iterateChildren
        .filter(
          (iterateChild) =>
            iterateKey &&
            searchValue.includes("SELF") &&
            new RegExp(
              `${searchValue.replaceAll(/\b(?:SELF|SELF.)\b/gi, "")}`,
              "i"
            ).test(iterateChild)
        )
        .map((iterateChild) => {
          return iterateChild
            .replace(/[[0-9]*?]/g, "")
            .replace(new RegExp(`${iterateKey}(?:\\[(\\d+)])?\\.`), "");
        })
        .filter((iterateChild: string, index: number, self: Array<string>) => {
          return self.indexOf(iterateChild) === index;
        })
        .filter((iteratedChild)=>iteratedChild !== iterateKey)
        .map((iteratedChild) => {
          return `SELF.${iteratedChild}`
        }),
    };

    const matchedFunctions: IPossibilitiesObj = {
      source: EditorKeySources.FUNCTION,
      keys: functions.filter((func: string) => searchRegExp.test(func)),
    };

    if (
      !matchedVariables.keys.length &&
      !matchedMaps.keys.length &&
      !matchedDecisions.keys.length &&
      !matchedSourceKeys.keys.length &&
      !matchedIterateChildren.keys.length &&
      !matchedFunctions.keys.length &&
      !matchedVariablesKeys.keys.length
    ) {
      return reject(messages.messageSearchNotFound);
    }

    [
      matchedVariables,
      matchedVariablesKeys,
      matchedMaps,
      matchedDecisions,
      matchedSourceKeys,
      matchedIterateChildren,
      matchedFunctions,
    ].forEach((groups) => {
      const { source, keys } = groups;
      if (!keys.length) return;
      searchResult.push({
        source,
        keys,
      });
    });

    resolve(searchResult);
  });
}
