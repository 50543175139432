import { generate_arrow } from "./generate_arrow";
import { Massive } from "./types";



export const contact_chaining = (element:SVGRectElement,input_arrows:NodeListOf<Element>,output_arrows:NodeListOf<Element>) => {
    const svg = document.querySelector(`#svg_board`) as SVGElement;
    if(input_arrows?.length>0 && output_arrows?.length>0){
        
        let first:Massive[]=[];
        let last:Massive[]=[];
        input_arrows.forEach((arrow:Element)=>{

            const contact:string | null = arrow.getAttribute('data-contact');
            const input_arrow_first_id = contact?.split('-')?.[0];
            const first_element = svg.querySelector(`rect[data-id = "${input_arrow_first_id}"]`) as SVGRectElement;
            const side:string|null = arrow.getAttribute('data-side');
            const bool = arrow?.getAttribute('data-bool') as string;
            if(first_element && side){
               first.push({element:first_element,side:side,bool:bool});
            }

        });
        
        output_arrows.forEach((arrow:Element)=>{
            const contact:string | null = arrow.getAttribute('data-contact');
            const bool = arrow?.getAttribute('data-bool') as string;
            if(bool){
                if(bool === 'yes'){
                    const output_arrow_last_id = contact?.split('-')?.[1];
                    const last_element = svg.querySelector(`rect[data-id = "${output_arrow_last_id}"]`) as SVGRectElement;
                    const side:string|null = arrow.getAttribute('data-side');
    
                    if(last_element && side){
                        last.push({element:last_element,side:side});
                    }
                }
            }else{
                const output_arrow_last_id = contact?.split('-')?.[1];
                const last_element = svg.querySelector(`rect[data-id = "${output_arrow_last_id}"]`) as SVGRectElement;
                const side:string|null = arrow.getAttribute('data-side');

                if(last_element && side){
                    last.push({element:last_element,side:side});
                }
            }
            

        })
        
        if(first && last){
            const [last_obj] = last;
            first.forEach((first_obj:Massive)=>{
                generate_arrow(element,first_obj,last_obj);
            })
        }


    }else if(input_arrows?.length>0 && output_arrows?.length === 0){
        
    }
}