import React, { FC, useCallback, useLayoutEffect, useRef } from "react";
import { STRATEGY_OUTPUT_FORMAT_MESSAGE } from "store/types/typesStrategy";
import { icons } from "../../../settings/settings";
import classes from "./TooltipV2.module.scss";

interface ITooltip {
  visible?: boolean;
  onVisible?: Function;
  topMessage?: string;
  bottomMessage?: string;
  data?: any;
  backgroundColor?: string;
  textColor?: string;
  child?: any;
  margin?: string;
}

const TooltipV2: FC<ITooltip> = ({
  visible,
  onVisible = () => {},
  topMessage,
  bottomMessage,
  data,
  backgroundColor,
  textColor,
  child,
  margin,
}) => {
  const bodyRef = useRef<HTMLDivElement | null>(null);
  const arrowRef = useRef<HTMLSpanElement | null>(null);
  const messageRef = useRef<HTMLSpanElement | null>(null);

  const handleVisible = useCallback(
    (visibleStatus: boolean) => {
      if (visibleStatus) {
        bodyRef?.current?.classList.add(classes.Visible);
      }

      if (!visibleStatus) {
        bodyRef?.current?.classList?.remove(classes.Visible);
      }
    },
    [bodyRef]
  );

  const handleColor = useCallback(
    (bgColor: string | undefined, txtColor: string | undefined) => {
      if (bgColor && bodyRef.current && arrowRef.current) {
        bodyRef.current.style.backgroundColor = bgColor;
        arrowRef.current.style.borderColor = `transparent transparent ${bgColor} transparent`;
      }

      if (txtColor && bodyRef.current) {
        bodyRef.current.style.color = txtColor;
      }
    },
    [bodyRef]
  );

  const checkType = (data: any): string => {
    const type = typeof data;
    if (type === "object") {
      if (Array.isArray(data)) {
        return "array";
      } else if (data === null) {
        return "null";
      } else {
        return "object";
      }
    } else {
      return type;
    }
  };

  const showData = useCallback((data: any) => {
    function showArray(data: Array<any>) {
      return data?.slice(0, 5).map((value, index, array) => {
        const type = checkType(value);
        const isLastIndex = index === array.length - 1;

        const types: any = {
          object: `{ Object }`,
          array: `[ Array ]`,
        };

        return (
          <b key={index}>
            {types[type] || value}
            {isLastIndex ? "" : ", "}
          </b>
        );
      });
    }

    function createTable(data: object) {
      const entries = Object.entries(data);

      return (
        <table>
          <tbody>
            <tr>
              <th>Key</th>
              <th>Value</th>
            </tr>

            {entries?.slice(0, 5).map((entry: Array<any>, index: number) => {
              return (
                <tr key={index}>
                  <td title={entry[0]}>{entry[0]}</td>
                  <td
                    title={
                      checkType(entry[1]) === "object"
                        ? "{ Object }"
                        : checkType(entry[1]) === "array"
                        ? "[ Array ]"
                        : entry[1].toString()
                    }
                  >
                    {checkType(entry[1]) === "object"
                      ? "{ Object }"
                      : checkType(entry[1]) === "array"
                      ? "[ Array ]"
                      : entry[1].toString()}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }

    const type = checkType(data);
    return (
      <div className={classes.TooltipData}>
        {type === "object"
          ? createTable(data)
          : type === "array"
          ? showArray(data)
          : data?.toString()}
      </div>
    );
  }, []);

  useLayoutEffect(() => {
    if (typeof visible === "boolean") {
      handleVisible(visible);
    }
  }, [visible, handleVisible]);

  useLayoutEffect(() => {
    if ((backgroundColor && textColor) || backgroundColor || textColor) {
      handleColor(backgroundColor, textColor);
    }
  }, [backgroundColor, textColor, handleColor]);

  return (
    <div className={classes.TooltipWrapper}>
      {child}
      <div
        ref={bodyRef}
        className={classes.TooltipBody}
        style={margin ? { marginTop: margin } : {}}
      >
        <span ref={arrowRef} className={classes.TooltipArrow}></span>
        <span ref={messageRef} className={classes.TooltipTopMessage}>
          {topMessage}
        </span>
        {showData(data)}
        {(checkType(data) === "array" || checkType(data) === "object") && (
          <em ref={messageRef} className={classes.TooltipBottomMessage}>
            {bottomMessage}
          </em>
        )}
        <button
          className={classes.TooltipCloseBtn}
          onClick={() => onVisible(false)}
          title="Close"
        >
          <img src={icons.closeBtn} alt="Close icon" />
        </button>
      </div>
    </div>
  );
};

export default TooltipV2;
