
import { moveArrowBox } from '../arrow_box.handler';
import { condition_arrow } from '../condition_arrow.handler';
import { drag_contact } from '../drag_contact.handler';
import { move_text } from '../element_text.handler';
import { getAttr } from '../methods/attributes';
import { move_auto } from '../move_auto/move_auto.handler';
import { remove_arrow_box } from '../remove_arrow_box.handler';
export const create_arrow_top = (source_element:SVGRectElement,target_element:SVGRectElement,arrow_type:string,arrow:SVGPathElement,side:string)=>{
                        const svg = document.querySelector(`#svg_board`) as SVGElement;
   
                        const {width:t_width,height:t_height,id:t_id} = getAttr(target_element)
                        const {x:s_x,y:s_y,width:s_width,height:s_height,id:s_id} = getAttr(source_element)

                        if(t_width && t_height && s_width && s_height && s_x && s_y && t_id && s_id){

                                    let m_t:string = `M${s_x+s_width/2} ${s_y - 12},v-50,h0,v-50,${arrow_type}`;
                
                                    arrow.setAttributeNS(null,'d',m_t);
                                    arrow.setAttributeNS(null,'data-side',side);
                                    drag_contact(arrow);
                    
                                    if(source_element.dataset.type === 'condt'){
                                        
                                            condition_arrow(source_element,arrow);
                                    }
                                    let find_t = svg.querySelector(`path[data-contact = ${s_id}-${t_id}]`);
                                    let find_t2 = svg.querySelector(`path[data-contact = ${t_id}-${s_id}]`)
                    
                                    if(!find_t && !find_t2){
                                        let _x:number = (s_x+s_width/2) - t_width/2;
                                        target_element.setAttributeNS(null,'x',`${_x}`);
                    
                                        target_element.setAttributeNS(null,'y',`${s_y-(108 + 24) - t_height}`);
                                        move_text(target_element)
                                        let check_contacts_top = svg.querySelectorAll(`path[data-contact ^= "${s_id}-"]`) as NodeListOf<SVGPathElement>;
                                        
                                        check_contacts_top.forEach((check_contact_top:any)=>{
                                            if(source_element?.dataset?.type === 'condt'){
                                               
                                                if(check_contact_top?.dataset?.side === side){
                                                    let next_id:any = check_contact_top.getAttribute('data-contact')?.split('-')?.[1];
                                                    check_contact_top.setAttributeNS(null,'data-contact',`${t_id}-${next_id}`);
                                                    remove_arrow_box(t_id,next_id,side,target_element.dataset.type);                                                   
                                            
                                                }  
                                            }else{
                                                let next_id:any = check_contact_top.getAttribute('data-contact')?.split('-')?.[1];
                                                check_contact_top.setAttributeNS(null,'data-contact',`${t_id}-${next_id}`);
                                                remove_arrow_box(t_id,next_id,side,target_element.dataset.type);  
                                            }
                                            
                                        })
                    
                                    
                                        move_auto(target_element,target_element);
                                        if(target_element.dataset.type === 'condt'){
                                            let figure2 = target_element.parentNode?.children[1] as SVGRectElement;
                                            let cond_x:any = target_element.getAttribute('x');
                                            let cond_y:any = target_element.getAttribute('y');
                                            let figure2_x:any = +(Math.sqrt((70*70)/2) - 70/2).toFixed(2);
                                            figure2.setAttributeNS(null,'x',+cond_x -4 +figure2_x);
                                            figure2.setAttributeNS(null,'y',+cond_y - 4 +figure2_x);
                                            figure2.setAttributeNS(null,'transform',`rotate(45 ${s_x+s_width/2 - t_width/2 + 35} ${s_y- (108+24) - t_height  + 35})`)
                                        }
                                        moveArrowBox(target_element,{x:s_x+s_width/2 - t_width/2,y:s_y-(108+24) - t_height});
                                        svg.insertBefore(arrow,svg.firstChild);
                    
                                        remove_arrow_box(s_id,t_id,side,source_element.dataset.type);
                    
                                    }
                }
}