import {
  State,
  ActionsTypes,
  LOGIN,
  PATHNAME,
  MODAL,
  PRODUCTS,
  SUBMITDATA,
  CHECKBOX,
  REFRESH,
  SELECTALL,
  TOOLTIP,
  TOUCHBLOCKED,
  ACTIVEEVENT,
  CANCELHANDLER,
  SAVEHANDLER,
  ACTIVEATTREVENT,
  ACTIVEATTRIBUTE,
  ATTRIBUTEBLOCKED,
  ACTIVEPRODUCT,
  ACTIVEROWARCHIVE,
  CHECKEDSTATUS,
  CONFIRMTOOLTIP,
  EMPTYVALUEBLOCKED,
  SHOWEMPTYFIELD,
  EMPTYFIELDSTATUS,
  ACTIVESECTION,
  MAINTOOLTIP

} from "../types/types";


const initialState: State = {
  user: {
    username: '',
    mail: ''
  },
  pathname: {},
  activeModal: {},
  products: {
    name: '',
    description: ''
  },
  submitData: {},
  checkboxList: [],
  refresh: {},
  selectAll: {},
  tooltip: {},
  mainTooltip: { childId: null, childDataId: null, type: null },
  touchBlocked: {
    status: false
  },
  activeEvent: {
    activeEvent: null
  },
  cancelHandler: {
    status: false
  },
  saveHandler: {
    status: false
  },
  activeAttrEvent: {
    activeEvent: null
  },
  activeAttribute: {
    activeAttribute: null
  },
  attributeBlocked: {
    status: false
  },
  activeProduct: {
    activeProduct: null
  },
  activeRowArchive: {
    activeRowArchive: null
  },
  checkedStatus: {
    status: null
  },
  confirmTooltip: {
    confirmTooltip: null
  },
  emptyValueBlocked: {
    status: false
  },
  showEmptyField: [],
  emptyFieldStatus: {
    status: false
  },
  activeSection:{activeSection:null}

};

export const reducer = (state = initialState, action: ActionsTypes): State => {
  
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        user: action.payload.user
      };

    case PATHNAME:
      return {
        ...state,
        pathname: action.payload.pathname
      };

    case MODAL:
      return {
        ...state,
        activeModal: action.payload.activeModal
      };
    case PRODUCTS:
      return {
        ...state,
        products: action.payload.products
      };
    case SUBMITDATA:
      return {
        ...state,
        submitData: action.payload.submitData
      };
    case CHECKBOX:
      let newState = state.checkboxList.filter((el, i) => {

        return el.id !== action.payload.checkboxList.id

      })
      return {
        ...state,
        checkboxList: [...newState, action.payload.checkboxList]
      };

    case REFRESH:
      return {
        ...state,
        refresh: action.payload.refresh
      };
    case SELECTALL:
      return {
        ...state,
        selectAll: action.payload.selectAll
      };
    case TOOLTIP:
      return {
        ...state,
        tooltip: action.payload.value
      };
      case MAINTOOLTIP:
      const { tooltipProps } = action.payload
      return {
        ...state,
        mainTooltip: tooltipProps
      };
    case TOUCHBLOCKED:
      return {
        ...state,
        touchBlocked: action.payload.touchBlocked
      };
    case ATTRIBUTEBLOCKED:
      return {
        ...state,
        attributeBlocked: action.payload.attributeBlocked
      };
    case ACTIVEEVENT:
      return {
        ...state,
        activeEvent: action.payload.activeEvent
      };
    case CANCELHANDLER:
      return {
        ...state,
        cancelHandler: action.payload.cancelHandler
      };
    case SAVEHANDLER:
      return {
        ...state,
        saveHandler: action.payload.saveHandler
      };
    case ACTIVEATTREVENT:
      return {
        ...state,
        activeAttrEvent: action.payload.activeEvent
      };
    case ACTIVEATTRIBUTE:
      return {
        ...state,
        activeAttribute: action.payload.activeAttribute
      };
    case ACTIVEPRODUCT:
      return {
        ...state,
        activeProduct: action.payload.activeProduct
      };
    case ACTIVEROWARCHIVE:
      return {
        ...state,
        activeRowArchive: action.payload.activeRowArchive
      };
    case CHECKEDSTATUS:
      return {
        ...state,
        checkedStatus: action.payload.checkedStatus,
          checkboxList: []
      };
    case CONFIRMTOOLTIP:
      return {
        ...state,
        confirmTooltip: action.payload.confirmTooltip
      };
    case EMPTYVALUEBLOCKED:
      return {
        ...state,
        emptyValueBlocked: action.payload.emptyValueBlocked
      };
    case SHOWEMPTYFIELD:
      let fields = state.showEmptyField.filter((el, i) => {

        return el.fields !== action.payload.showEmptyField?.fields

      })
      
      return {
        ...state,
        showEmptyField: [...fields,action.payload.showEmptyField]
      };
    case EMPTYFIELDSTATUS:
      return {
        ...state,
        emptyFieldStatus: action.payload.emptyFieldStatus
      };
      case ACTIVESECTION:
      return {
        ...state,
        activeSection: action.payload.activeSection
      };
    default:
      return state;
  }
}