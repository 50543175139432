export const condition_arrow = (source_element:SVGRectElement,arrow:SVGPathElement)=>{
    const svg = document.querySelector('#svg_board') as SVGElement;

    const data_id = source_element?.dataset.id;
    const outputs = svg.querySelectorAll(`path[data-contact ^= ${data_id}]`) as NodeList;
    if(outputs.length > 0){

        outputs.forEach((output:any)=>{
            if(output.dataset.side === arrow.dataset.side){
                if(output.dataset.bool === 'yes'){
                    arrow.setAttributeNS(null,'data-bool','yes')
                }else if(output.dataset.bool !== 'yes'){
                    arrow.setAttributeNS(null,'data-bool','no')

                }
            }else{
                if(output.dataset.bool === 'yes'){
                  
                    arrow.setAttributeNS(null,'data-bool','no')

                }else if(output.dataset.bool !== 'yes'){
                    arrow.setAttributeNS(null,'data-bool','yes')

                }
            }
        })

    }else{

        arrow.setAttributeNS(null,'data-bool','yes')


    }
    condition_label(arrow);
}

export const condition_label = (arrow:SVGPathElement)=>{
    let xmlns = "http://www.w3.org/2000/svg";
    const svg = document.querySelector('#svg_board') as SVGElement;
    const {side,bool} = arrow.dataset;
    let text_yes = document.createElementNS(xmlns,'text') as SVGTextElement;
    let text_no = document.createElementNS(xmlns,'text') as SVGTextElement;

    let draw = arrow.getAttribute('d');
    let contact:any = arrow.getAttribute('data-contact');
    let draw_split = draw?.split(',');

    let dh = draw_split?.[1]?.split('h')?.[1];
    let d_v = draw_split?.[2]?.split('v')?.[1];

    let dv = draw_split?.[1]?.split('v')?.[1];
    let d_h = draw_split?.[2]?.split('h')?.[1];


    let h:any = dh;
    let v:any = dv;
    let hv:any =d_v;
    let vh:any = d_h;
    const source_element = svg?.querySelector(`rect[data-id = "${contact?.split('-')?.[0]}"]`) as SVGRectElement;
    const s_x:any = source_element?.getAttribute('x');
    const s_y:any = source_element?.getAttribute('y');
    const s_width:any = source_element?.getAttribute('width');
    const s_height:any = source_element?.getAttribute('height')

    if(bool?.toLowerCase() === 'yes'){
        let condt_id:any = contact?.split('-')?.[0];
        if(svg?.querySelector(`text[id = "${contact}"]`)){
            text_yes = svg.querySelector(`text[id = "${contact}"]`) as SVGTextElement;
        }else{

            text_yes.setAttributeNS(null,'id',contact);
            text_yes.textContent ='"Yes"';
            text_yes.setAttributeNS(null,'data-bool','yes')
            text_yes.setAttributeNS(null,'data-condition',`${condt_id}`)

            text_yes?.setAttributeNS(null,'fill','#A5AEBB');
            text_yes?.setAttributeNS(null,'width',`50`);
            text_yes?.setAttributeNS(null,'height',`50`);
        }

        if(side && side === 'top'){

            if(+vh>=0){
                let _h = Math.abs(+vh);
                if(_h<40){

                    text_yes?.setAttributeNS(null,'x',`${+s_x + +s_width/2 - 72}`)
                    text_yes?.setAttributeNS(null,'y',`${(+s_y - 12) - Math.abs(+v) - 20}`);
                    text_yes?.setAttributeNS(null,'transform',`rotate(90 ${(+s_x + +s_width/2 - 72) + 25} ${((+s_y - 12) - Math.abs(+v) -20) + 25})`)

                }else{

                    text_yes?.setAttributeNS(null,'x',`${+s_x + +s_width/2 + _h/2 - 15}`)
                    text_yes?.setAttributeNS(null,'y',`${(+s_y - 12) - Math.abs(+v) - 10}`);
                    text_yes?.setAttributeNS(null,'transform',`rotate(0 ${(+s_x + +s_width/2) + _h/2 - 15} ${((+s_y - 12) - Math.abs(+v) -10) + 25})`)

                }
            }else{
                let _h = Math.abs((+vh));
                if(_h <40){

                    text_yes?.setAttributeNS(null,'x',`${+s_x + +s_width/2 - 40}`)
                    text_yes?.setAttributeNS(null,'y',`${(+s_y - 12) - Math.abs(+v) - 20}`);
                    text_yes?.setAttributeNS(null,'transform',`rotate(90 ${(+s_x + +s_width/2 - 40) + 25} ${((+s_y - 12) - Math.abs(+v) -20) + 25})`)

                }else{
                    text_yes?.setAttributeNS(null,'x',`${+s_x + +s_width/2 - _h/2 -15 }`)
                    text_yes?.setAttributeNS(null,'y',`${(+s_y - 12) - Math.abs(+v) - 10}`);
                    text_yes?.setAttributeNS(null,'transform',`rotate(0 ${(+s_x + +s_width/2) - _h/2 -15} ${((+s_y - 12) - Math.abs(+v) -10) + 25})`)

                }
            }
        }else if(side && side === 'right'){


                if(+hv>=0){
                    let _v = Math.abs(+hv);
                    if(s_x && s_width && h){
                        if(_v<40){
                            text_yes?.setAttributeNS(null,'x',`${+s_x + +s_width + 12 +(Math.abs(+h)-10)}`)
                            text_yes?.setAttributeNS(null,'y',`${+s_y + +s_height/2 -10}`)
                            text_yes?.setAttributeNS(null,'transform',`rotate(0 0 0)`)
    
                        }else{
                            text_yes?.setAttributeNS(null,'x',`${+s_x + +s_width + 12 +Math.abs(+h)+10}`)
                            text_yes?.setAttributeNS(null,'y',`${+s_y + +s_height/2  +  (Math.abs(+hv)/2 - 15)}`)
                            text_yes?.setAttributeNS(null,'transform',`rotate(90 ${+s_x + +s_width + 12  +(Math.abs(+h) +10)} ${+s_y + +s_height/2  + (Math.abs(+hv)/2 - 15)})`)
    
                        }
                    }
                }else{
                    let _v = Math.abs(+hv);
                    if(s_x && s_width && h){

                        if(_v<40){
                            text_yes?.setAttributeNS(null,'x',`${+s_x + +s_width + 12 +(Math.abs(+h)-10)}`)
                            text_yes?.setAttributeNS(null,'y',`${+s_y + +s_height/2 + 20}`);
                            text_yes?.setAttributeNS(null,'transform',`rotate(0 0 0)`)

                        }else{
                            text_yes?.setAttributeNS(null,'x',`${+s_x + +s_width + 12 +Math.abs(+h)+10}`)
                            text_yes?.setAttributeNS(null,'y',`${+s_y + +s_height/2  -  (Math.abs(+hv)/2 + 15)}`)
                            text_yes?.setAttributeNS(null,'transform',`rotate(90 ${+s_x + +s_width + 12  +(Math.abs(+h) +10)} ${+s_y + +s_height/2  - (Math.abs(+hv)/2 + 15)})`)

                        }
                    }
                }




        }else if(side && side === 'bottom'){

            if(+vh>=0){
                let _h = Math.abs(+vh);
                if(_h<40){
                    text_yes?.setAttributeNS(null,'x',`${+s_x + +s_width/2 - 72}`)
                    text_yes?.setAttributeNS(null,'y',`${+s_y + +s_height + 12 + Math.abs(+v) -10}`);
                    text_yes?.setAttributeNS(null,'transform',`rotate(90 ${+s_x + +s_width/2  - 72 + 25} ${+s_y + +s_height + 12 + Math.abs(+v) -10 + 25})`)

                }else{
                    text_yes?.setAttributeNS(null,'x',`${+s_x + +s_width/2 + Math.abs(+vh/2) - 15}`)
                    text_yes?.setAttributeNS(null,'y',`${+s_y + +s_height + 12 + Math.abs(+v) -10}`);
                    text_yes?.setAttributeNS(null,'transform',`rotate(0 ${+s_x + +s_width/2 + Math.abs(+vh/2) - 15 + 25} ${+s_y + +s_height + 12 + Math.abs(+v) -10 + 25})`)

                }
            }else{
                let _h = Math.abs(+vh);
                if(_h<40){
                    text_yes?.setAttributeNS(null,'x',`${+s_x + +s_width/2 - 40}`)
                    text_yes?.setAttributeNS(null,'y',`${+s_y + +s_height + 12 + Math.abs(+v) -10}`);
                    text_yes?.setAttributeNS(null,'transform',`rotate(90 ${+s_x + +s_width/2 - 40 + 25} ${+s_y + +s_height + 12 + Math.abs(+v) -10 + 25})`)

                }else{
                    text_yes?.setAttributeNS(null,'x',`${+s_x + +s_width/2 - _h/2 - 15}`)
                    text_yes?.setAttributeNS(null,'y',`${+s_y + +s_height + 12 + Math.abs(+v) -10}`);
                    text_yes?.setAttributeNS(null,'transform',`rotate(0 ${+s_x + +s_width/2 - _h/2 - 15} ${+s_y + +s_height + 12 + Math.abs(+v) -10 + 25})`)

                }
            }
        }else if(side && side === 'left'){


            if(+hv>=0){
                let _v = Math.abs(+hv);
                if(_v<40){
                    text_yes?.setAttributeNS(null,'x',`${+s_x -12-Math.abs(+h)  - 20}`)
                    text_yes?.setAttributeNS(null,'y',`${+s_y + +s_height/2 -10}`);
                    text_yes?.setAttributeNS(null,'transform',`rotate(0 0 0)`)

                }else{
                    text_yes?.setAttributeNS(null,'x',`${+s_x -12-Math.abs(+h)  - 72}`)
                    text_yes?.setAttributeNS(null,'y',`${+s_y + +s_height/2 + Math.abs(_v)/2 -10}`);
                    text_yes?.setAttributeNS(null,'transform',`rotate(90 ${+s_x -12-Math.abs(+h)  - 72 + 25} ${+s_y + +s_height/2 + Math.abs(_v)/2 -10 + 25})`)

                }
            }else{

                let _v = Math.abs(+hv);
                if(_v<40){
                    text_yes?.setAttributeNS(null,'x',`${+s_x -12-Math.abs(+h)  - 20}`)
                    text_yes?.setAttributeNS(null,'y',`${+s_y + +s_height/2 +20}`);
                    text_yes?.setAttributeNS(null,'transform',`rotate(0 0 0)`)

                }else{
                    text_yes?.setAttributeNS(null,'x',`${+s_x -12-Math.abs(+h)  - 72}`)
                    text_yes?.setAttributeNS(null,'y',`${+s_y + +s_height/2 - Math.abs(_v)/2 - 15}`);
                    text_yes?.setAttributeNS(null,'transform',`rotate(90 ${+s_x -12-Math.abs(+h)  - 72 + 25} ${+s_y + +s_height/2 - Math.abs(_v)/2 - 15 + 25})`)

                }
            }

        }
        let find_dublicate = svg?.querySelector(`[data-bool="yes"][data-condition = "${condt_id}"]`) as SVGTextElement;
        if(find_dublicate){
            svg.removeChild(find_dublicate);
        }
        if(arrow){
            svg?.insertBefore(text_yes,svg.firstChild);

        }
    }else if(bool?.toLowerCase() === 'no'){
        let condt_id:any = contact?.split('-')?.[0];


        if(svg?.querySelector(`text[id = "${contact}"]`)){
            text_no = svg.querySelector(`text[id = "${contact}"]`) as SVGTextElement;
        }else{
            text_no.setAttributeNS(null,'id',contact);
            text_no.textContent = '"No"';
            text_no.setAttributeNS(null,'data-bool','no')
            text_no.setAttributeNS(null,'data-condition',`${condt_id}`)

            text_no?.setAttributeNS(null,'fill','#A5AEBB');
            text_no?.setAttributeNS(null,'width',`50`);
            text_no?.setAttributeNS(null,'height',`50`);
        }

        if(side === 'top'){

            if(+vh>=0){
                let _h = Math.abs(+vh);
                if(_h<40){

                    text_no?.setAttributeNS(null,'x',`${+s_x + +s_width/2 - 72}`)
                    text_no?.setAttributeNS(null,'y',`${(+s_y - 12) - Math.abs(+v) - 20}`);
                    text_no?.setAttributeNS(null,'transform',`rotate(90 ${(+s_x + +s_width/2 - 72) + 25} ${((+s_y - 12) - Math.abs(+v) -20) + 25})`)

                }else{

                    text_no?.setAttributeNS(null,'x',`${+s_x + +s_width/2 + _h/2 - 15}`)
                    text_no?.setAttributeNS(null,'y',`${(+s_y - 12) - Math.abs(+v) - 10}`);
                    text_no?.setAttributeNS(null,'transform',`rotate(0 ${(+s_x + +s_width/2) + _h/2 - 15} ${((+s_y - 12) - Math.abs(+v) -10) + 25})`)

                }
            }else{
                let _h = Math.abs((+vh));
                if(_h <40){

                    text_no?.setAttributeNS(null,'x',`${+s_x + +s_width/2 - 40}`)
                    text_no?.setAttributeNS(null,'y',`${(+s_y - 12) - Math.abs(+v) - 20}`);
                    text_no?.setAttributeNS(null,'transform',`rotate(90 ${(+s_x + +s_width/2 - 40) + 25} ${((+s_y - 12) - Math.abs(+v) -20) + 25})`)

                }else{
                    text_no?.setAttributeNS(null,'x',`${+s_x + +s_width/2 - _h/2 -15 }`)
                    text_no?.setAttributeNS(null,'y',`${(+s_y - 12) - Math.abs(+v) - 10}`);
                    text_no?.setAttributeNS(null,'transform',`rotate(0 ${(+s_x + +s_width/2) - _h/2 -15} ${((+s_y - 12) - Math.abs(+v) -10) + 25})`)

                }
            }
        }else if(side === 'right'){

            if(+hv>=0){
                let _v = Math.abs(+hv);
                if(_v<40){
                    text_no?.setAttributeNS(null,'x',`${+s_x + +s_width + 12 +(Math.abs(+h)-10)}`)
                    text_no?.setAttributeNS(null,'y',`${+s_y + +s_height/2 -10}`)
                    text_no?.setAttributeNS(null,'transform',`rotate(0 0 0)`)

                }else{
                    text_no?.setAttributeNS(null,'x',`${+s_x + +s_width + 12 +Math.abs(+h)+10}`)
                    text_no?.setAttributeNS(null,'y',`${+s_y + +s_height/2  +  (Math.abs(+hv)/2 - 15)}`)
                    text_no?.setAttributeNS(null,'transform',`rotate(90 ${+s_x + +s_width + 12  +(Math.abs(+h) +10)} ${+s_y + +s_height/2  + (Math.abs(+hv)/2 - 15)})`)

                }
            }else{
                let _v = Math.abs(+hv);
                if(_v<40){
                    text_no?.setAttributeNS(null,'x',`${+s_x + +s_width + 12 +(Math.abs(+h)-10)}`)
                    text_no?.setAttributeNS(null,'y',`${+s_y + +s_height/2 + 20}`);
                    text_no?.setAttributeNS(null,'transform',`rotate(0 0 0)`)

                }else{
                    text_no?.setAttributeNS(null,'x',`${+s_x + +s_width + 12 +Math.abs(+h)+10}`)
                    text_no?.setAttributeNS(null,'y',`${+s_y + +s_height/2  -  (Math.abs(+hv)/2 + 15)}`)
                    text_no?.setAttributeNS(null,'transform',`rotate(90 ${+s_x + +s_width + 12  +(Math.abs(+h) +10)} ${+s_y + +s_height/2  - (Math.abs(+hv)/2 + 15)})`)

                }
            }
        }else if(side === 'bottom'){

            if(+vh>=0){
                let _h = Math.abs(+vh);
                if(_h<40){
                    text_no?.setAttributeNS(null,'x',`${+s_x + +s_width/2 - 72}`)
                    text_no?.setAttributeNS(null,'y',`${+s_y + +s_height + 12 + Math.abs(+v) -10}`);
                    text_no?.setAttributeNS(null,'transform',`rotate(90 ${+s_x + +s_width/2  - 72 + 25} ${+s_y + +s_height + 12 + Math.abs(+v) -10 + 25})`)

                }else{
                    text_no?.setAttributeNS(null,'x',`${+s_x + +s_width/2 + Math.abs(+vh/2) - 15}`)
                    text_no?.setAttributeNS(null,'y',`${+s_y + +s_height + 12 + Math.abs(+v) -10}`);
                    text_no?.setAttributeNS(null,'transform',`rotate(0 ${+s_x + +s_width/2 + Math.abs(+vh/2) - 15 + 25} ${+s_y + +s_height + 12 + Math.abs(+v) -10 + 25})`)

                }
            }else{
                let _h = Math.abs(+vh);
                if(_h<40){
                    text_no?.setAttributeNS(null,'x',`${+s_x + +s_width/2 - 40}`)
                    text_no?.setAttributeNS(null,'y',`${+s_y + +s_height + 12 + Math.abs(+v) -10}`);
                    text_no?.setAttributeNS(null,'transform',`rotate(90 ${+s_x + +s_width/2 - 40 + 25} ${+s_y + +s_height + 12 + Math.abs(+v) -10 + 25})`)

                }else{
                    text_no?.setAttributeNS(null,'x',`${+s_x + +s_width/2 - _h/2 - 15}`)
                    text_no?.setAttributeNS(null,'y',`${+s_y + +s_height + 12 + Math.abs(+v) -10}`);
                    text_no?.setAttributeNS(null,'transform',`rotate(0 ${+s_x + +s_width/2 - _h/2 - 15} ${+s_y + +s_height + 12 + Math.abs(+v) -10 + 25})`)

                }
            }
        }else if(side === 'left'){

            if(+hv>=0){
                let _v = Math.abs(+hv);
                if(_v<40){
                    text_no?.setAttributeNS(null,'x',`${+s_x -12-Math.abs(+h)  - 20}`)
                    text_no?.setAttributeNS(null,'y',`${+s_y + +s_height/2 -10}`);
                    text_no?.setAttributeNS(null,'transform',`rotate(0 0 0)`)

                }else{
                    text_no?.setAttributeNS(null,'x',`${+s_x -12-Math.abs(+h)  - 72}`)
                    text_no?.setAttributeNS(null,'y',`${+s_y + +s_height/2 + Math.abs(_v)/2 -10}`);
                    text_no?.setAttributeNS(null,'transform',`rotate(90 ${+s_x -12-Math.abs(+h)  - 72 + 25} ${+s_y + +s_height/2 + Math.abs(_v)/2 -10 + 25})`)

                }
            }else{

                let _v = Math.abs(+hv);
                if(_v<40){
                    text_no?.setAttributeNS(null,'x',`${+s_x -12-Math.abs(+h)  - 20}`)
                    text_no?.setAttributeNS(null,'y',`${+s_y + +s_height/2 +20}`);
                    text_no?.setAttributeNS(null,'transform',`rotate(0 0 0)`)

                }else{
                    text_no?.setAttributeNS(null,'x',`${+s_x -12-Math.abs(+h)  - 72}`)
                    text_no?.setAttributeNS(null,'y',`${+s_y + +s_height/2 - Math.abs(_v)/2 - 15}`);
                    text_no?.setAttributeNS(null,'transform',`rotate(90 ${+s_x -12-Math.abs(+h)  - 72 + 25} ${+s_y + +s_height/2 - Math.abs(_v)/2 - 15 + 25})`)

                }
            }
        }

        let find_duplicate = svg?.querySelector(`[data-bool="no"][data-condition = "${condt_id}"]`) as SVGTextElement;
        if(find_duplicate){
            svg.removeChild(find_duplicate);
        }
        if(arrow){
            svg?.insertBefore(text_no,svg.firstChild);
        }

    }

}

export const check_condition = (element: SVGElement):object=>{
    const svg = document.querySelector('#svg_board') as SVGElement;
    const id = element?.dataset.id;
    const input = svg.querySelector(`path[data-contact $= "${id}"]`) as SVGPathElement;
    const side = input?.dataset.side;
    const data_bool = input?.dataset.bool;
    const d =input?.getAttribute('d');
    const split_d = d?.split(',');
    const h_or_v = split_d?.[1];
    const hv:any = h_or_v?.split('h')?.[1] !== undefined ?  h_or_v?.split('h')?.[1] :h_or_v?.split('v')?.[1];
    if(data_bool){
        return {status:true,side:side,hv:+hv};
    }else{
        return {status:false,side:side};
    }
}
