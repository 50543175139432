import styled, { css } from 'styled-components';

export const Container = styled.div`
position: relative;
margin-bottom:1.32vh;
`;
interface IButton{
  action:string;
}
export const Button = styled.button<IButton>`
  width:100%;
  height:5.6vh;
  border-radius:1.12vh;
  outline:none;
  border: 0;
  font-size:2.52vh;
  font-weight:500;
  cursor: pointer;
  ${(props) => {
       switch(props.action){
         case "save":{
            return css`
            background: #002D57;
            color: #FFF;
          `;
         }
         case "cancel":{
          return css`
            background: #F5F6FA;
            border:.14vh solid #002D57;
            color: #002D57;
        `;
         }
       }
  }}
`;
