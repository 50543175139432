import { FC, MouseEvent } from "react"
import { Button, Container } from "./Button.style"

interface IFormButton{
  label:string;
  action:string;
  disabled?:boolean;
  onCLick?:(e:MouseEvent<HTMLButtonElement>)=>void;
}

export const FormButton:FC<IFormButton> = ({label,action,disabled,onCLick}) => {
  return(
    <Container>
        <Button action={action} disabled={disabled} style={{opacity:disabled ? ".8":"1"}} onClick={onCLick}>{label}</Button>
    </Container>
  )
}