import React, { FC } from 'react'
import { icons } from '../../../../../settings/settings';
import classes from './SidePaneTooltip.module.scss'

const SidePanelTooltip: FC = () => {
    return (
        <div id="side-panel-tooltip" className={classes.SidePanelTooltip}>
            <div className={classes.SidePanelTooltipName}></div>
            <img src={icons.information} alt="Information icon" />
            <span>&#124;</span>
        </div>
    )
}

export default SidePanelTooltip;