import React, { FC, useEffect } from "react";
import classes from "./ProductDesigner.module.scss";
import Table from "../../components/UI/Table/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  activeModal,
  activeSection,
  addCheckbox,
  checkedStatus,
  refreshFetch,
  selectAll,
} from "../../store/actions/action";
import { RootState } from "../../store/combineReducer";
import { Route, Switch } from "react-router-dom";
import GroupTable from "./GroupTable/GroupTable";
import { endpoints } from "../../api/endpoints";
import { request } from "../../helpers/request";
import { ModalLoader } from "components/UI/Modal/ModalLoader";
import { useGetAllData } from "../../hooks/useGetAllData";
import { handleProductData } from "../../store/actions/actionsProduct";

const ProductsDesigner: FC = () => {
  const [loader, setLoader] = React.useState<boolean>(false);

  const dispatch = useDispatch();
  const store = useSelector((state: RootState) => state);
  const counter = store?.reducer.checkboxList.filter((list) => list.checked);
  const {
    product: { getAllProducts },
  } = useGetAllData();


 
  useEffect(() => {
    dispatch(activeSection({ activeSection: window.location.pathname }));
    dispatch(checkedStatus({ status: true }));
  }, [dispatch]);

  useEffect(() => {
    getAllProducts.get({
      reduxData: store?.reducerProduct.products.length,
      action: (data) => handleProductData(data),
    }); 
  }, [getAllProducts, store?.reducerProduct.products.length]);

  const cloneHandler = () => {
    setLoader(true);

    counter?.forEach(({ id }: any) => {
      request
        .post(endpoints.product_designer.products.clone(id), {})
        .then((res) => {
          const { success } = res;
          if (!success) return;
          dispatch(refreshFetch({ refresh: true }));
          setLoader(false);
        });
    });
  };

  const archiveHandler = async () => {
    setLoader(true);

    let ids = store.reducer.checkboxList.filter(
      (list) => list.checked === true
    );

    //    let distinct = (value:any,index:any,self:any)=>{
    //        return self.indexOf(value) === index;
    //    }
    //    let distinctMassive = newMass.filter(distinct)
    let productsMassiveRef = store.reducerProduct.products;

    let checkbox: any = document.querySelectorAll('[data-name="checkbox"]');

    ids.length > 0 &&
      ids.forEach((el: any, i: number) => {
        let checkedMassive = productsMassiveRef.filter(
          (pm: any) => pm.id === el.id
        );
        let index = productsMassiveRef.indexOf(checkedMassive[0]);
        productsMassiveRef.splice(index, 1);
        request.delete(`${endpoints.products}${el.id}/`).then((res) => {
          if (res.ok) {
            dispatch(handleProductData(productsMassiveRef));
            dispatch(checkedStatus({ status: true }));
            checkbox.forEach((elem: any) => {
              elem.checked = false;
            });
            if (store.reducer.checkboxList.length > 0) {
              let mass = [...store.reducer.checkboxList];
              mass.forEach((elem: any, i: number) => {
                if (elem.id === el.id) {
                  let index = mass.indexOf(elem);
                  mass.splice(index, 1);
                  dispatch(
                    addCheckbox({
                      id: mass[index]?.id,
                      checked: mass[index]?.checked,
                    })
                  );
                  setLoader(false);
                }
              });
            }
          }
        });
      });
    // const archiveProduct =await request(api.products+'','delete');
  };

  const selectAllHandler = () => {
    dispatch(selectAll({ selectAll: true }));
    // setUnSelectStatus(true);

    let checkbox: any = document.querySelectorAll('[data-name="checkbox"]');
    // const ids:any = [];
    checkbox.forEach((el: any) => {
      el.checked = true;
      dispatch(
        addCheckbox({
          id: +el.id,
          checked: el.checked,
        })
      );
      // ids.push(el.id.split('checkbox_')[1])
      // request(api.products+el.id.split('checkbox_')[1]+'/','delete');
    });
  };

  const unSelectAllHandler = () => {
    dispatch(selectAll({ selectAll: false }));

    // setUnSelectStatus(false);

    let checkbox: any = document.querySelectorAll('[data-name="checkbox"]');
    dispatch(checkedStatus({ status: true }));

    checkbox.forEach((el: any) => {
      el.checked = false;
    });
  };

  return (
    <div className={classes.ProductsDesigner}>
      {loader && <ModalLoader messageTitle="Please wait..."></ModalLoader>}

      <div className={classes.Main}>
        <Switch>
          <Route path="/products-designer/:string" component={GroupTable} />
          <Route
            path="/products-designer"
            render={() => (
              <>
                <div className={classes.Header}>
                  <div className={classes.Title}>Products Designer</div>
                  <div className={classes.SearchProduct}>
                    {/* <div className={classes.Search}>
                                            <button>
                                            <img src={icons.search} alt='search'/>
                                            </button>
                                        </div> */}
                    <div className={classes.AddProduct}>
                      <button
                        onClick={() =>
                          dispatch(
                            activeModal({
                              activeModal: "add_product",
                            })
                          )
                        }
                      >
                        Add product manager
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className={classes.Actions}
                  style={{
                    display: counter.length > 0 ? "flex" : "none",
                  }}
                >
                  <div className={classes.Selected}>
                    <div className={classes.SelectedItems}>
                      {counter.length === 1 ? (
                        <div>{counter.length} item selected</div>
                      ) : counter.length > 1 ? (
                        <div>{counter.length} items selected</div>
                      ) : null}
                    </div>
                    {/* <div className={classes.VLine}></div> */}
                    <div
                      className={
                        counter.length === store.reducerProduct.products.length
                          ? [classes.SelectAll, classes.Sa].join(" ")
                          : classes.SelectAll
                      }
                    >
                      <button onClick={selectAllHandler}>Select All</button>
                    </div>
                    {/* <div
                                style={{
                                  visibility:
                                    counter.length > 0 ? "visible" : "hidden",
                                }}
                                className={classes.VLine}
                              ></div> */}

                    {/* <div
                                style={{
                                  visibility:
                                    counter.length > 0 ? "visible" : "hidden",
                                }}
                                className={classes.UnSelectAll}
                              >
                                <button onClick={unSelectAllHandler}>
                                  Unselect All
                                </button>
                              </div> */}
                  </div>
                  <div className={classes.Action}>
                    <button onClick={cloneHandler} className={classes.Clone}>
                      Clone
                    </button>
                    <button
                      onClick={archiveHandler}
                      className={classes.Archive}
                    >
                      Archive
                    </button>
                    <button
                      className={classes.Cancel}
                      onClick={unSelectAllHandler}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <Table
                  saveStatusHandler={() => void 0}
                  statusTopHandler={() => void 0}
                  statusBottomHandler={() => void 0}
                  cancelStatusHandler={() => void 0}
                  editStatusHandler={() => void 0}
                  editAliasHandler={() => void 0}
                  saveAliasHandler={() => void 0}
                  cancelAliasHandler={() => void 0}
                  section="products-designer"
                  data={store.reducerProduct.products}
                  changeLoader={(status: boolean) => setLoader(status)}
                />
              </>
            )}
          />
        </Switch>
      </div>
    </div>
  );
};

export default ProductsDesigner;
