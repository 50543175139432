import { splitBoard } from "helpers/split_board";
import { add_storage } from "../add_storage.handler";
// import { change_destination } from "../change_destination_arrow/main";
import { zIndex } from "../create_svg.handler";
// import { draw_arrow } from "../draw_arrow.handler";
import { move_text } from "../element_text.handler";
import { dragEndpoint } from "./endpoint";
import { dragOutput } from "./output_formats";
import { dragErrors } from "./system_errors";

export const listener = (newElement:SVGRectElement)=>{
    function mousePosition(e:MouseEvent){
        let svg:any = document.querySelector('#svg_board') as SVGElement;
    
        let CTM = svg.getScreenCTM()
        return {
            x: (e.clientX - CTM.e) / CTM.a,
            y: (e.clientY - CTM.f) / CTM.d
        };
    }
    

    const mouseDown = (e:MouseEvent)=>{

        e.preventDefault(); 
        e.stopPropagation();
      
        // let {x,y} = newElement?.getBoundingClientRect();
        let x = newElement.getAttribute('x');
        let y = newElement.getAttribute('y');
        let ofst = mousePosition(e);
        ofst.x -=  parseFloat(`${x}`);
        ofst.y -=  parseFloat(`${y}`);
        const target:any = e.target;
        
        function moveHandler(move_event:MouseEvent){
                let svg:any = document.querySelector('#svg_board') as SVGElement;

                let {width:svg_width,height:svg_height} = svg.getBoundingClientRect();
                let zoom_percent_span = document.querySelector('#zoom_percent') as HTMLSpanElement
                let percent:any = zoom_percent_span?.textContent?.split('%')?.[0];

                move_event.preventDefault();
                move_event.stopPropagation();
                let link:any = newElement?.getAttribute('data-id');
                zIndex(link)
                newElement.style.cursor = 'grab';
                let coord = mousePosition(move_event);
                let _x:any = coord.x - ofst.x;
                let _y:any = coord.y - ofst.y
                const data={
                    svg_width,svg_height,percent,x:_x,y:_y,id:newElement.dataset.id
                } as any
                splitBoard("move",data)
                // const width:any  = newElement.getAttribute('width');
                // const height:any  = newElement.getAttribute('height');
                // if((_x-35>0 && _y-35 >0) && (_x+35< svg_width/+percent*100 - +width && _y+35 < svg_height/+percent*100 - +height)){
                    newElement?.setAttributeNS(null,"x", _x.toFixed(2));
                    newElement?.setAttributeNS(null,"y", _y.toFixed(2));
                // }
                move_text(newElement)


                if(target.dataset.type === 'endpoint'){
                    // if((_x-35>0 && _y-35 >0) && (_x+35< svg_width/+percent*100 - +width && _y+35 < svg_height/+percent*100 - +height)){
                        dragOutput({x:_x.toFixed(2),y:_y.toFixed(2)})
                        dragErrors({x:_x.toFixed(2),y:_y.toFixed(2)})
                        // draw_arrow(newElement,{x:_x,y:_y})
                        dragEndpoint({x:_x.toFixed(2),y:_y.toFixed(2)},target)
                        // change_destination<SVGRectElement>(target)

                //    }

                }
                if(target.dataset.type === 'output_format'){
                    // if((_x-35>0 && _y-35 >0) && (_x+35< svg_width/+percent*100 - +width && _y+35 < svg_height/+percent*100 - +height)){
                        dragEndpoint({x:_x.toFixed(2),y:_y.toFixed(2)},target);
                        dragErrors({x:_x.toFixed(2),y:_y.toFixed(2)},target)
                    // }

                }
                if(target.dataset.type === 'system_errors'){
                    // if((_x-35>0 && _y-35 >0) && (_x+35< svg_width/+percent*100 - +width && _y+35 < svg_height/+percent*100 - +height)){
                        dragEndpoint({x:_x.toFixed(2),y:_y.toFixed(2)},target);
                        dragOutput({x:_x.toFixed(2),y:_y.toFixed(2)},target)
                    // }

                }

        }
        function upHandler(ev:any){
            ev.preventDefault();
            ev.stopPropagation();
            document.removeEventListener('mousemove',moveHandler,false);
           

           if(newElement.dataset.type === 'endpoint'){
            const element = newElement.parentNode as SVGGElement;
            add_storage(element);
           }
           if(newElement.dataset.type === 'output_format'){
            const element = newElement.parentNode?.parentNode as SVGGElement;
            add_storage(element);
           }
           if(newElement.dataset.type === 'system_errors'){
            const element = newElement.parentNode?.parentNode as SVGGElement;
            add_storage(element);
           }
            document.removeEventListener('mouseup',upHandler,false);

        }
        function leaveHandler(el:any){
            el.preventDefault();
            el.stopPropagation();

            document.removeEventListener('mousemove',moveHandler,false);

            newElement.style.cursor = 'initial';

        }
        document.addEventListener('mousemove',moveHandler);

        document.addEventListener('mouseup',upHandler);

        document.addEventListener('mouseleave',leaveHandler);
     
        document.removeEventListener('mousedown',mouseDown,false);

    }
  
    newElement?.addEventListener('mousedown',mouseDown);

    return newElement
}