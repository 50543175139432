import React, { Fragment } from 'react';
import classes from './Diagram.module.scss';

import {useSelector} from 'react-redux';
import { RootState } from '../../../../store/combineReducer';
import Slice from './Slice/Slice';

const Pie = (props:any) => {

  const [path,setPath] = React.useState<any>([]);
  const [lineCoord,setLineCoord] = React.useState<any>([]);

  const store = useSelector((state:RootState) =>{
    return state;
  });

  const {scoreCardCategoryData,scoreCardCriteriaData,scoreCardAttributeData,scoreCardLevel} = store.reducerScoreCard;



React.useEffect(()=>{
  let line = document.querySelectorAll('.line_');
  let mass:any=[];
  line?.forEach((el:any,i:number)=>{
    mass.push({[el?.id]:el?.getBoundingClientRect()});
  })

  setLineCoord([...mass]);
  

},[scoreCardCategoryData,scoreCardCriteriaData,scoreCardAttributeData,scoreCardLevel])


const getLineCoord = (key:any)=>{
    let lc = lineCoord?.filter((el:any)=>el[key])[0]
    return lc ? lc[key] :0;
}
  const getData = (level:any)=> {
    const {scoreCardId,categoryId,criteriaId} = store.reducerScoreCard.scoreCardLevelDataId;

    let data = level === 2 ? store.reducerScoreCard.scoreCardCategoryData.scoreCardCategoryData :
        (level === 3 ? store.reducerScoreCard.scoreCardCriteriaData.scoreCardCriteriaData : 
        (level === 4 ? store.reducerScoreCard.scoreCardAttributeData.scoreCardAttributeData : []))
    return level === 2 ? 
    data?.filter((el:any)=>+el?.score_card_id === scoreCardId):
    (
      level === 3 ? data?.filter((el:any)=>+el?.score_card_category_id === categoryId)
      :
      data?.filter((el:any)=>+el?.score_card_criteria_id === criteriaId)
    )
}

    const radius  = 150
    const new_data = getData(store.reducerScoreCard.scoreCardLevel.level);
    let data = [] as any[];
    new_data?.forEach((item:any)=>{
      if(item.weight>50){
        data.push({...item,weight_2:50,type:'parent'},{...item,weight:item.weight-50,type:'child'})
      }else{
        data.push(item)
      }
    })
    let filtered_data = data.filter(f=>f?.type !== 'child' && f?.type !== 'deficit');

    React.useEffect(()=>{
      const slice:any = document.querySelectorAll('#line');
      const pie:any = document.querySelector('#pie');
      let pieCoord = pie.getBoundingClientRect();
      let massive:any=[]
      slice?.forEach((el:any,i:number)=>{
        let coord = el?.getBoundingClientRect();
        massive.push({slice:coord,svg:pieCoord,type:data[i]?.type})
          
    
      })
      setPath([...massive])
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[scoreCardCategoryData,scoreCardCriteriaData,scoreCardAttributeData,scoreCardLevel])
    
    const diameter = 2 * radius
    
    let rotate = 0.5 * Math.PI;
    const total = data.filter(f=>f?.type !== 'child')?.reduce((a:any, b:any) => a +  Number(b?.weight), 0);
    if(total < 100){
      if(100-total<=50){
        data.push({weight:100 - total,type:'deficit'})

      }else{
        data.push({weight:50,type:'deficit'},{weight:total-50,type:'deficit'})

      }
    }
    let rad = 6.2831853072;

    const angleMid = (massive:any)=>{
      let rotate = 0.5*Math.PI;
      let a:any = [];
      let b:any=[];
      let numberMid:any=[];
      let gap  = total<100 ?  100 - total:0;
      let gapRadian = (gap*6.28)/100;
      massive?.forEach((el:any,i:number)=>{
         let weight = +el?.weight;
         const angleLine = 2 * Math.PI * weight / 100;
         a?.push(rotate);
         b?.push(rotate);

         rotate+= angleLine;

      })
      
      massive?.forEach((el:any,i:number)=>{
        numberMid.push(
     
          
    
            a[0] && a[1] ? 
              (a[0]+a[1])/2 :
              (
                a[0] > rad ? 
                a[0] + (b[0] - (a[0]-rad + gapRadian))/2
                 :
                (
                 a[0] < rad && (a[0]+gapRadian) >= rad ?
                  (a[0] + (a[0] + (b[0] - (a[0]+gapRadian - rad))))/2
                  :
                  (
                    (a[0] + (a[0] + (b[0] - (a[0]+gapRadian - rad))))/2

                  
                  )
                )
              )
        )

        a.shift();
      })
      

      return numberMid;
    }




    return (
      <svg
        className={classes.Pie}
        width={(diameter*2)}
        height={(diameter*2)}
        viewBox={`0 0 ${diameter*2} ${diameter*2}`}
        id='pie'
      >
        
        {
        data.map((value:any, i:number) => {
          let angle = 2 * Math.PI * (
            (value.type==='parent' ?+value?.weight_2 :  +value?.weight)
          /100)
          const fill = value.type === 'child' || value.type === 'parent' ? `hsla(${70 * 100}, 100%, 50%,.7)`:(value?.type === 'deficit' ?`hsla(300, 100%, 78%, 0.2)` :`hsla(${70 * i}, 100%, 50%,.7)`)
          const transform = `rotate(${rotate}rad)`
          const transformLine = `rotate(${angleMid(data)[i]}rad)`
          const transformLine2 = `rotate(${angleMid(data)[i]-0.5}rad)`


          const transformOrigin = `${radius}px ${radius}px`
          const transformOrigin2 = `${radius*0.13}vh ${radius*0.13}vh`


          rotate += angle

          return (
            <Fragment key={i}>
              <g  transform={`translate(${(120)} ${(120)})`}>

                  <Slice
                    angle={angle}
                    radius={radius}
                    style={{ fill, transform, transformOrigin }}
                    line={{transformLine,transformOrigin}}
                    line2={{transformLine2,transformOrigin2}}
                    num={value?.id}
                    value={value}
                  />
              </g>
            </Fragment>
          )
        })}
        
          <g transform={`translate(${(120)} ${(120)})`}>
            <circle r={radius/3} cx={diameter/2} cy={diameter/2} fill="#FFF"
            stroke="#FFF"
            strokeWidth="100"
            />
            <circle r={90} cx={diameter/2} cy={diameter/2} fill="#FFF"
            stroke="#B2BDCD"
            strokeWidth={1}
            />
          </g>
          <path 
              className='line_'
              stroke='none'
              strokeWidth='.8'
              fill='none'
              id='line_rt'
              d={`M${0} ${80}  l30 -30 h100`} 
          />
          <path 
                className='line_'

            stroke='none'
            strokeWidth='.8'
            fill='none'
            id='line_lt'
            d={`M${0} ${80} h100 l30 30`} 
          />
          <path 
                className='line_'

            stroke='none'
            strokeWidth='.8'
            fill='none'
            id='line_lb'
            d={`M${0} ${80} h100 l30 -30`} 
          /> 
          <path 
                className='line_'

            stroke='none'
            strokeWidth='.8'
            fill='none'
            id='line_rb'
            d={`M${0} ${80} l30 30  h100`} 
          />
        
          {
            
              path.filter((f:any)=>f?.type !== 'child' && f?.type !== 'deficit' )?.map((element:any,i:number)=>{
              let angle = angleMid(data);
              let dcoord = angle[i];
              
              let translateCoord = 
                  dcoord >=1.50 && dcoord <=3.14 ?
                   
                  (path[i]?.slice?.x - getLineCoord('line_rb')?.x) + " " + (path[i]?.slice?.y - getLineCoord('line_rb')?.y) :
                  (dcoord > 3.14 && dcoord <= 5.30) ?
                  (path[i]?.slice?.x - getLineCoord('line_lb')?.x - getLineCoord('line_lb')?.width) + " " + (path[i]?.slice?.y - getLineCoord('line_lb')?.y) :
                  (dcoord > 5.30 && dcoord <= 6.28) ?
                  (path[i]?.slice?.x - getLineCoord('line_lt')?.x - getLineCoord('line_lt')?.width) + " " + (path[i]?.slice?.y - getLineCoord('line_lt')?.y) :
                  (dcoord < 1.50 || dcoord > 6.28) ?
                  (path[i]?.slice?.x - getLineCoord('line_rt')?.x) + " " + (path[i]?.slice?.y - getLineCoord('line_rt')?.y) 
                  :0;

              let pathD = 
                dcoord >=1.50 && dcoord <=3.14 ?
                `M${0} ${80} l30 30  h100`:
                (dcoord > 3.14 && dcoord <= 5.30) ?
                `M${0} ${80} h100 l30 -30`:
                (dcoord > 5.30 && dcoord <= 6.28) ?
                `M${0} ${50} h100 l30 30` :
                (dcoord < 1.50 || dcoord > 6.28) ?
                `M${0} ${50}  l30 -30 h100`:'';

              let offsetTitle = 
                dcoord >=1.50 && dcoord <=3.14 ?
                '30%':
                (dcoord > 3.14 && dcoord <= 5.30) ?
                '10%':
                (dcoord > 5.30 && dcoord <= 6.28) ?
                '5%':
                (dcoord < 1.50 || dcoord > 6.28) ?
                '30%' : '0';

              let offsetWeight = 
                dcoord >=1.50 && dcoord <=3.14 ?
                '50%':
                (dcoord > 3.14 && dcoord <= 5.30) ?
                '25%':
                (dcoord > 5.30 && dcoord <= 6.28) ?
                '25%':
                (dcoord < 1.50 || dcoord > 6.28) ?
                '50%' : '0';

              const fill = `hsl(${70 * i}, 100%, 50%,.5)`
              return(
                  <g key={i}  id="line_info" transform={`translate(${translateCoord})`}>
                        <path 
    
                            stroke={fill}
                            strokeWidth={1}
                            fill='none'
                            id={`line_info_${i}`}
                            d={pathD} 
                        />
                        <text style={{fontSize:'1.45vh',fontFamily:'ginger'}} transform={`translate(0 -3)`}>
                            <textPath startOffset={offsetWeight} xlinkHref={`#line_info_${i}`}>
                                {Number(filtered_data[i]?.weight)?.toFixed(0)+' %'}
                            </textPath>
                        </text>
                        <text style={{fontSize:'1.72vh',fontFamily:'ginger'}} transform={`translate(0 15)`}>
                            <textPath startOffset={offsetTitle} xlinkHref={`#line_info_${i}`}>
                                {filtered_data[i]?.name?.length > 10 ? data[i]?.name?.substring(0,10)+'...' : filtered_data[i]?.name}
                            </textPath>
                        </text>
                        
                  </g>
                )
              })


          }
       
                
              )
             
      </svg>
    )
  }

 
export default Pie;
