import { draw_right_top_left_first, draw_right_top_left_last } from "./draw_arrow/draw_right_top_left";
import { draw_bottom_first, draw_bottom_last } from "./draw_arrow/draw_bottom";
import { draw_bottom_left_first, draw_bottom_left_last } from "./draw_arrow/draw_bottom_left";
import { draw_bottom_right_first, draw_bottom_right_last } from "./draw_arrow/draw_bottom_right";
import { draw_left_first, draw_left_last } from "./draw_arrow/draw_left";
import { draw_left_bottom_first, draw_left_bottom_last } from "./draw_arrow/draw_left_bottom";
import { draw_left_top_first, draw_left_top_last } from "./draw_arrow/draw_left_top";
import { draw_right_first, draw_right_last } from "./draw_arrow/draw_right";
import { draw_right_bottom_first, draw_right_bottom_last } from "./draw_arrow/draw_right_bottom";
import { draw_right_bottom_left_first, draw_right_bottom_left_last } from "./draw_arrow/draw_right_bottom_left";
import { draw_right_top_first, draw_right_top_last } from "./draw_arrow/draw_right_top";
import { draw_top_first, draw_top_last } from "./draw_arrow/draw_top";
import { draw_top_left_first, draw_top_left_last } from "./draw_arrow/draw_top_left";
import { draw_top_right_first, draw_top_right_last } from "./draw_arrow/draw_top_right";
import { draw_left_bottom_right_first, draw_left_bottom_right_last } from "./draw_arrow/draw_left_bottom_right";
import { draw_left_top_right_first, draw_left_top_right_last } from "./draw_arrow/draw_left_top_right";

type Coord = {
    x: number,
    y: number
}
type ElementProperty = {
    s_x: number,
    s_y: number,
    s_width: number,
    s_height: number,
    t_x: number,
    t_y: number,
    t_width: number,
    t_height: number
}
type Size = {
    width: string,
    height: string
}

export const draw_arrow = (element: SVGElement, coord: Coord) => {
    const svg = document.querySelector(`#svg_board`) as SVGElement;

    let data_id: any = element.dataset.id;
    let width: any = element.getAttribute('width');
    let height: any = element.getAttribute('height');


    let find_arrows = svg.querySelectorAll(`path[data-contact *= ${data_id}]`) as NodeList;
    if (find_arrows.length > 0) {
        find_arrows.forEach((arrow: any) => {
            let contact: string = arrow.getAttribute('data-contact');
            let contact_split: Array<string> = contact.split('-');
            let index = contact_split.indexOf(data_id);
            if (contact_split.length > 0) {
                if (contact_split[1] && index === 0) {
                    let target_element = svg.querySelector(`rect[data-id = ${contact_split[1]}]`) as SVGRectElement;
                    let source_element = svg.querySelector(`rect[data-id = ${contact_split[0]}]`) as SVGRectElement;

                    const {
                        x: {
                            baseVal: {
                                value: t_x
                            }
                        },
                        y: {
                            baseVal: {
                                value: t_y
                            }
                        },
                        width: {
                            baseVal: {
                                value: t_width
                            }
                        },
                        height: {
                            baseVal: {
                                value: t_height
                            }
                        },

                    } = target_element;
                    const {
                        x: {
                            baseVal: {
                                value: s_x
                            }
                        },
                        y: {
                            baseVal: {
                                value: s_y
                            }
                        },
                        width: {
                            baseVal: {
                                value: s_width
                            }
                        },
                        height: {
                            baseVal: {
                                value: s_height
                            }
                        }

                    } = source_element;

                    const element_property: ElementProperty = {
                        s_x: s_x,
                        s_y: s_y,
                        s_width: s_width,
                        s_height: s_height,
                        t_x: t_x,
                        t_y: t_y,
                        t_width: t_width,
                        t_height: t_height
                    }
                    const size: Size = {
                        width: width,
                        height: height
                    }

                    let drawn: string = arrow.getAttribute('d');
                    let arrow_side: string = arrow.getAttribute('data-side');

                    let drawn_split: Array<string> = drawn.split(',');

                    switch(arrow_side){
                        case 'top':
                                draw_top_first(arrow, coord, element_property, size, drawn_split)
                            break;
                        case 'right':
                                draw_right_first(arrow, coord, element_property, size, drawn_split)
                            break;
                        case 'bottom':
                                draw_bottom_first(arrow, coord, element_property, size, drawn_split)
                            break;
                        case 'left':
                                draw_left_first(arrow, coord, element_property, size, drawn_split)
                            break;
                        case 'top-right':
                                draw_top_right_first(arrow, coord, element_property, size, drawn_split)
                            break;
                        case 'top-left':
                                draw_top_left_first(arrow, coord, element_property, size, drawn_split)
                            break;
                        case 'bottom-right':
                                draw_bottom_right_first(arrow, coord, element_property, size, drawn_split)
                            break;
                        case 'bottom-left':
                                draw_bottom_left_first(arrow, coord, element_property, size, drawn_split)
                            break;
                        case 'right-top':
                                draw_right_top_first(arrow, coord, element_property, size, drawn_split)
                            break;
                        case 'right-bottom':
                                draw_right_bottom_first(arrow, coord, element_property, size, drawn_split)
                            break;
                        case 'left-top':
                                draw_left_top_first(arrow, coord, element_property, size, drawn_split)
                            break;
                        case 'left-bottom':
                                draw_left_bottom_first(arrow, coord, element_property, size, drawn_split)
                            break;
                     
                        case 'right-bottom-left':
                                draw_right_bottom_left_first(arrow, coord, element_property, size, drawn_split)
                            break;
                        case 'right-top-left':
                                draw_right_top_left_first(arrow, coord, element_property, size, drawn_split)
                            break;
                        case 'left-bottom-right':
                                draw_left_bottom_right_first(arrow, coord, element_property, size, drawn_split)
                            break;
                        case 'left-top-right':
                                draw_left_top_right_first(arrow, coord, element_property, size, drawn_split)
                            break;
                        default:
                            break;
                    }


                }
                if (contact_split[0] && index === 1) {
                    let source_element = svg.querySelector(`rect[data-id = ${contact_split[0]}]`) as SVGRectElement;
                    let target_element = svg.querySelector(`rect[data-id = ${contact_split[1]}]`) as SVGRectElement;
                    const {
                        x: {
                            baseVal: {
                                value: s_x
                            }
                        },
                        y: {
                            baseVal: {
                                value: s_y
                            }
                        },
                        width: {
                            baseVal: {
                                value: s_width
                            }
                        },
                        height: {
                            baseVal: {
                                value: s_height
                            }
                        }
                    } = source_element;

                    const {
                        x: {
                            baseVal: {
                                value: t_x
                            }
                        },
                        y: {
                            baseVal: {
                                value: t_y
                            }
                        },
                        width: {
                            baseVal: {
                                value: t_width
                            }
                        },
                        height: {
                            baseVal: {
                                value: t_height
                            }
                        }
                    } = target_element;

                    const element_property: ElementProperty = {
                        s_x: s_x,
                        s_y: s_y,
                        s_width: s_width,
                        s_height: s_height,
                        t_x: t_x,
                        t_y: t_y,
                        t_width: t_width,
                        t_height: t_height
                    }
                    const size: Size = {
                        width: width,
                        height: height
                    }
                    let drawn: string = arrow.getAttribute('d');
                    let arrow_side: string = arrow.getAttribute('data-side');

                    let drawn_split: Array<string> = drawn.split(',');



                    switch(arrow_side){
                        case 'top':
                                draw_top_last(arrow, coord, element_property, size, drawn_split)
                            break;
                        case 'right':
                                draw_right_last(arrow, coord, element_property, size, drawn_split)
                            break;
                        case 'bottom':
                                draw_bottom_last(arrow, coord, element_property, size, drawn_split)
                            break;
                        case 'left':
                                draw_left_last(arrow, coord, element_property, size, drawn_split)
                            break;
                        case 'top-right':
                                draw_top_right_last(arrow, coord, element_property, size, drawn_split)
                            break;
                        case 'top-left':
                                draw_top_left_last(arrow, coord, element_property, size, drawn_split)
                            break;
                        case 'bottom-right':
                                draw_bottom_right_last(arrow, coord, element_property, size, drawn_split)
                            break;
                        case 'bottom-left':
                                draw_bottom_left_last(arrow, coord, element_property, size, drawn_split)
                            break;
                        case 'right-top':
                                draw_right_top_last(arrow, coord, element_property, size, drawn_split)
                            break;
                        case 'right-bottom':
                                draw_right_bottom_last(arrow, coord, element_property, size, drawn_split)
                            break;
                        case 'left-top':
                                draw_left_top_last(arrow, coord, element_property, size, drawn_split)
                            break;
                        case 'left-bottom':
                                draw_left_bottom_last(arrow, coord, element_property, size, drawn_split)
                            break;

                    
                        case 'right-bottom-left':
                                draw_right_bottom_left_last(arrow, coord, element_property, size, drawn_split)
                            break;
                        case 'right-top-left':
                                draw_right_top_left_last(arrow, coord, element_property, size, drawn_split)
                            break;
                        case 'left-bottom-right':
                            draw_left_bottom_right_last(arrow, coord, element_property, size, drawn_split)
                            break;
                        case 'left-top-right':
                            draw_left_top_right_last(arrow, coord, element_property, size, drawn_split)
                            break;
                        default:
                            break;
                    }
                
                }
            }
        })
    }
}

