import React, {useMemo} from "react";
import classes from "./Main.module.scss";
import { logoWhite as logo } from "../../assets/index";
import { Link, Switch, Route } from "react-router-dom";
import {sections, decision_type, storageKeys} from "../../settings/settings";
import {
  Dashboard,
  StrategyDesigner,
  ProductsDesigner,
  DecisionDesigner,
  ScoreCard,
} from "../index";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/combineReducer";
import {
  activeModal,
  refreshFetch,
  touchBlocked,
  saveHandler,
  cancelHandler,
  handleActiveTooltip, setPath,
} from "../../store/actions/action";
import { request } from "../../helpers/request";
import { endpoints } from "../../api/endpoints";
import Backdrop from "../../components/UI/Backdrop/Backdrop";
import ModalForm from "../../components/UI/Modal/ModalForm";
import { isNumber } from "util";
import { icons } from "../../settings/settings";
import UserAvatar from "../../assets/svg/user.svg";
import Spinner from "../../components/UI/Spinner/Spinner";
import {
  handleRangeActiveRow,
  handleRangeActiveElement,
  handleAddDecision,
} from "../../store/actions/actionsDecision";
import {
  handleScoreCardData,
  scoreCardActiveDelete,
  activeScoreCardData,
  handleScoreCardBlockedModalResponse,
} from "../../store/actions/actionsScoreCard";
import { UserProfile } from "layout/UserProfile/UserProfile";
import { handleAddProduct } from "store/actions/actionsProduct";

const Main: React.FC = (props) => {
  type linkType = {
    link: any;
  };
  const [activeLink, setActiveLink] = React.useState<linkType>({ link: "" });
  const [message, setMessage] = React.useState("");
  
  const store = useSelector((state: RootState) => {
    return state;
  });
  const {products} = useSelector((state: RootState) => state.reducerProduct);
  const {decisionState} = useSelector((state: RootState) => state.reducerDecision);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setActiveLink({ link: store.reducer.pathname.pathname });
  }, [store.reducer.pathname]);

  const activeLinkHandler = (value: string) => {
    setActiveLink({ link: value });
    dispatch(setPath({ pathname: value }));
  };

  const submitHandler = async (data: any, method: string) => {
    const modalName = store.reducer.activeModal.activeModal;

    let user_details: any = sessionStorage.getItem("user_details");

    if (method?.toLowerCase() === "post") {
      if (modalName === "add_product") {
        const formData: any = {};
        formData["user_id"] = JSON.parse(user_details).user;
        Object.keys(data).forEach((obj: string) => {
          formData[obj] = data[obj].value;
        });
        await request.post(endpoints.products, formData).then((response) => {
          const resData = response.response;
          const { id } = response.response;
          dispatch(handleAddProduct(resData)); 
          
          if (isNumber(id)) {
            setMessage("Success");
            dispatch(activeModal({ activeModal: null }));
            dispatch(refreshFetch({ refresh: true }));
          }
        });
      } else if (modalName === "add_decision") {
        const formData: any = {};
        formData["user_id"] = JSON.parse(user_details).user;
        Object.keys(data).forEach((obj: string) => {
          formData[obj] = data[obj].value;
        });

        await request
          .post(endpoints.decision_designer.decision.post, formData)
          .then((response) => {
            const resData = response.response;
            const { id } = response.response;
            dispatch(handleAddDecision(resData));  

            if (isNumber(id)) {
              setMessage("Success");
              dispatch(activeModal({ activeModal: null }));
              dispatch(refreshFetch({ refresh: true }));
            }
          });
      } else if (modalName === "add_score_modal") {
        const formData: any = {};
        formData["user_id"] = JSON.parse(user_details).user;
        Object.keys(data).forEach((obj: string) => {
          formData[obj] = data[obj].value;
          formData.type_id = data.type_id;
        });

        await request
          .post(endpoints.score_card.score_card.post, formData)
          .then((response) => {
            const { id } = response.response;
            if (isNumber(id)) {
              setMessage("Success");
              dispatch(activeModal({ activeModal: null }));
              // dispatch(
              //     refreshFetch({refresh:true})
              // )
              let storeScoreCardData = store.reducerScoreCard.scoreCardData
                .scoreCardData
                ? [...store.reducerScoreCard.scoreCardData.scoreCardData]
                : [];
              storeScoreCardData.push({ ...response.response });
              dispatch(
                handleScoreCardData({ scoreCardData: storeScoreCardData })
              );
            }
          });
      }
    } else if (method?.toLowerCase() === "patch") {
      if (modalName === "edit_product") {
        let storeData = store.reducer.activeProduct.activeProduct;

        const formData: any = {};
        formData.id = storeData.id;
        formData.user_id = storeData.user_id;
        Object.keys(data).forEach((obj: string) => {
          formData[obj] = data[obj].value;
        });
        await request
          .patch(`${endpoints.products}${storeData?.id}/`, formData)
          .then((response) => {
            const { id } = response.response;
            if (isNumber(id)) {
              setMessage("Success");
              dispatch(activeModal({ activeModal: null }));
              dispatch(refreshFetch({ refresh: true }));
            }
          });
      } else if (modalName === "edit_decision") {
        let storeData = store.reducerDecision.activeDecision.activeDecision;
        const formData: any = {};
        let id = storeData.id;

        Object.keys(data).forEach((obj: string) => {
          formData[obj] = data[obj].value;
          // formData.type_id = data.type_id
        });
        formData.user_id = storeData.user_id;

        await request
          .patch(endpoints.decision_designer.decision.patch(id), formData)
          .then((response) => {
            const { id } = response.response;
            if (isNumber(id)) {
              setMessage("Success");
              dispatch(activeModal({ activeModal: null }));
              dispatch(refreshFetch({ refresh: true }));
            }
          });
      } else if (modalName === "edit_score_modal") {
        let storeData =
          store.reducerScoreCard.activeScoreCardData.activeScoreCardData;
        let score_id = storeData?.id;
        const formData: any = {};
        formData["user_id"] = JSON.parse(user_details).user;
        Object.keys(data).forEach((obj: string) => {
          formData[obj] = data[obj].value;
        });
        await request
          .patch(endpoints.score_card.score_card.patch(score_id), formData)
          .then((response) => {
            const { id } = response.response;
            if (isNumber(id)) {
              setMessage("Success");
              dispatch(activeModal({ activeModal: null }));
              dispatch(refreshFetch({ refresh: true }));
              let storeScoreCardData = [
                ...store.reducerScoreCard.scoreCardData.scoreCardData,
              ];
              let findElement = storeScoreCardData?.filter(
                (elem: any, i: number) => {
                  return +elem.id === +id;
                }
              );
              let index = storeScoreCardData.indexOf(findElement[0]);
              storeScoreCardData[index] = response.response;
              // storeScoreCardData.push({...response.response})
              dispatch(
                handleScoreCardData({ scoreCardData: storeScoreCardData })
              );
            }
          });
      } else if (modalName === "edit_strategy_modal") {
        const formData: any = {};
        formData["user"] = JSON.parse(user_details).user;
        formData["strategy_data"] =
          store.reducerStrategy.ActiveStrategy.data?.strategy_data;
        Object.keys(data).forEach((obj: string) => {
          formData[obj] = data[obj].value;
        });

        await request
          .patch(
            endpoints.strategy_designer.strategy.patch(
              store.reducerStrategy.ActiveStrategy.data?.id
            ),
            formData
          )
          .then((response) => {
            const { success } = response;
            if (success) {
              dispatch(activeModal({ activeModal: null }));
              dispatch(refreshFetch({ refresh: true }));
            }
          })
          .catch((error) => {
            console.log("PATCH Strategy Data Error :", error);
          });
      }
    }
  };

  const closeModal = (e: any) => {
    dispatch(activeModal({ activeModal: null }));
  };

  const closeEmptyBlockedModal = (e: any) => {
    dispatch(activeModal({ activeModal: null }));
  };
  const blockedLinkHandler = (e: any) => {
    if (
      store.reducer.touchBlocked.status &&
      store.reducer.activeEvent.activeEvent &&
      !store.reducer.activeAttribute.activeAttribute &&
      !store.reducer.attributeBlocked.status
    ) {
      dispatch(activeModal({ activeModal: "blocked_modal" }));
    }
    if (
      store.reducer.emptyValueBlocked.status &&
      !store.reducer.activeEvent.activeEvent
    ) {
      dispatch(activeModal({ activeModal: "empty_blocked_modal" }));
    }

    if (
      !store.reducer.activeAttribute.activeAttribute &&
      store.reducer.touchBlocked.status &&
      store.reducer.attributeBlocked.status
    ) {
      dispatch(activeModal({ activeModal: "attribute_blocked_modal" }));
    }
    if (store.reducer.activeAttribute.activeAttribute) {
      dispatch(activeModal({ activeModal: "attribute_blocked_modal" }));
    }

    if (store.reducerDecision.matrixListStatus.matrixListStatus) {
      dispatch(activeModal({ activeModal: "matrix_blocked_modal" }));
    }
    if (store.reducerDecision.matrixActiveAlias.matrixActiveAlias) {
      dispatch(activeModal({ activeModal: "matrix_blocked_modal" }));
    }

    //E
    if (
      store.reducerDecision.rangeActiveElement.name !== null &&
      store.reducer.touchBlocked.status
    ) {
      dispatch(activeModal({ activeModal: "range_blocked_modal" }));
    }

    if (
      store.reducerDecision.rangeActiveElement.name === null &&
      !store.reducer.touchBlocked.status
    ) {
      dispatch(handleRangeActiveRow({ rowIndex: null, newRow: false }));
    }

    if (
      store.reducerDecision.rangeActiveElement.name !== null &&
      !store.reducer.touchBlocked.status
    ) {
      dispatch(handleRangeActiveElement({ name: null, elementIndex: null }));
      dispatch(
        handleActiveTooltip({ childId: null, childDataId: null, type: null })
      );
      dispatch(handleRangeActiveRow({ rowIndex: null, newRow: false }));
    }

    if (
      store.reducerScoreCard.scoreCardActiveElement.name !== null &&
      store.reducer.touchBlocked.status
    ) {
      dispatch(activeModal({ activeModal: "score_blocked_modal" }));
    }

    if (
      store.reducerScoreCard.scoreCardActiveElement.name === null &&
      !store.reducer.touchBlocked.status
    ) {
      dispatch(handleScoreCardBlockedModalResponse({ response: "cancel" }));
    }

    if (store.reducer.mainTooltip.type === "moreMenu") {
      dispatch(
        handleActiveTooltip({ childId: null, childDataId: null, type: null })
      );
    }
    //END E
  };

  const cancelBlockedHandler = () => {
    dispatch(activeModal({ activeModal: null }));
    dispatch(touchBlocked({ status: false }));
    dispatch(cancelHandler({ status: true }));

    dispatch(handleRangeActiveElement({ name: "range_blocked_modal_cancel" }));
    dispatch(
      handleActiveTooltip({ childId: null, childDataId: null, type: null })
    );

    if (store.reducerScoreCard.scoreCardActiveElement.name !== null)
      dispatch(handleScoreCardBlockedModalResponse({ response: "cancel" }));
  };
  const saveBlockedHandler = () => {
    dispatch(activeModal({ activeModal: null }));
    dispatch(touchBlocked({ status: false }));
    dispatch(saveHandler({ status: true }));
    dispatch(handleRangeActiveElement({ name: "range_blocked_modal_save" }));

    if (store.reducerScoreCard.scoreCardActiveElement.name !== null)
      dispatch(handleScoreCardBlockedModalResponse({ response: "save" }));
  };

  const yesDeleteBlockedHandler = () => {
    dispatch(activeModal({ activeModal: null }));
    dispatch(touchBlocked({ status: false }));

    if (store.reducerScoreCard.scoreCardActiveElement.name === null)
      dispatch(scoreCardActiveDelete({ scoreCardActiveDelete: true }));
    if (store.reducerScoreCard.scoreCardActiveElement.name !== null)
      dispatch(handleScoreCardBlockedModalResponse({ response: "delete" }));
  };
  const cancelDeleteBlockedHandler = () => {
    dispatch(activeModal({ activeModal: null }));
    dispatch(touchBlocked({ status: false }));

    dispatch(scoreCardActiveDelete({ scoreCardActiveDelete: false }));
    dispatch(activeScoreCardData({ activeScoreCardData: null }));
    if (store.reducerScoreCard.scoreCardActiveElement.name !== null)
      dispatch(
        handleScoreCardBlockedModalResponse({ response: "cancel_delete" })
      );
  };
  const continueBlockedHandler = () => {
    dispatch(activeModal({ activeModal: null }));
  };

  const getUserEmail = () => {
    const userDetailsJSON = sessionStorage.getItem(storageKeys.userDetails);

    if (!userDetailsJSON) {
      return;
    }

    const userDetailsObj = JSON.parse(userDetailsJSON);

    return userDetailsObj?.email ?? "User";
  };

  const userEmail = useMemo(getUserEmail, []);

  const matrixSpinner =
    store.reducerDecision.matrixSpinner.status === true ? (
      <Backdrop color="light" position="absolute">
        <Spinner />
      </Backdrop>
    ) : null;

  return (
    <div className={classes.Dashboard}>
      {store.reducer.activeModal.activeModal === "empty_blocked_modal" && (
        <Backdrop>
          <div className={classes.BlockedModal}>
            <div className={classes.Title}>Values ​​field is required</div>
            <div className={classes.InputArea}></div>
            <div
              style={{ justifyContent: "center" }}
              className={classes.Footer}
            >
              <button
                tabIndex={1}
                onClick={closeEmptyBlockedModal}
                className={[classes.Save, classes.Btn].join(" ")}
              >
                OK
              </button>
            </div>
          </div>
        </Backdrop>
      )}
      {store.reducer.activeModal.activeModal === "blocked_modal" && (
        <Backdrop>
          <div className={classes.BlockedModal}>
            <div onClick={closeModal} className={classes.CloseBtn}>
              <img src={icons.closeBtn} alt="close" />
            </div>
            <div className={classes.Title}>Save change</div>
            <div className={classes.InputArea}></div>
            <div className={classes.Footer}>
              <button
                tabIndex={1}
                onClick={cancelBlockedHandler}
                className={[classes.Cancel, classes.Btn].join(" ")}
              >
                Cancel
              </button>
              <button
                tabIndex={2}
                onClick={saveBlockedHandler}
                className={[classes.Save, classes.Btn].join(" ")}
              >
                Save
              </button>
            </div>
          </div>
        </Backdrop>
      )}
      {store.reducer.activeModal.activeModal === "attribute_blocked_modal" && (
        <Backdrop>
          <div className={classes.BlockedModal}>
            <div onClick={closeModal} className={classes.CloseBtn}>
              <img src={icons.closeBtn} alt="close" />
            </div>
            <div className={classes.Title}>Cancel or continue editing</div>
            <div className={classes.InputArea}></div>
            <div className={classes.Footer}>
              <button
                tabIndex={1}
                onClick={cancelBlockedHandler}
                className={[classes.Cancel, classes.Btn].join(" ")}
              >
                Cancel
              </button>
              <button
                tabIndex={2}
                onClick={continueBlockedHandler}
                className={[classes.Save, classes.Btn].join(" ")}
              >
                Continue
              </button>
            </div>
          </div>
        </Backdrop>
      )}
      {store.reducer.activeModal.activeModal === "add_product" && (
        <Backdrop>
          <ModalForm
            type="post"
            title="Product Manager Add Form"
            tab="3"
            inputs={[
              {
                tag: "input",
                title: "name",
                id: "name",
                type: "text",
                index: 1,
              },
              {
                tag: "input",
                title: "description",
                id: "description",
                type: "text",
                index: 2,
              },
            ]}
            handler={submitHandler}
            message={message}
            buttonName="Add"
          />
        </Backdrop>
      )}
      {store.reducer.activeModal.activeModal === "edit_product" && (
        <Backdrop>
          <ModalForm
            type="patch"
            title="Edit product"
            tab="2"
            inputs={[
              {
                tag: "input",
                title: "name",
                type: "text",
                id: "name",
                value: store.reducer.activeProduct.activeProduct?.name,
                index: 1,
              },
              {
                tag: "input",
                title: "description",
                type: "text",
                id: "description",
                value: store.reducer.activeProduct.activeProduct?.description,
                index: 2,
              },
            ]}
            handler={submitHandler}
            message={message}
            buttonName="Save"
          />
        </Backdrop>
      )}

      {store.reducer.activeModal.activeModal === "add_decision" && (
        <Backdrop>
          <ModalForm
            type="post"
            title="Add Decision"
            tab="3"
            inputs={[
              {
                tag: "input",
                title: "name",
                type: "text",
                id: "name",
                index: 1,
              },
              {
                tag: "select",
                title: "type",
                type: "text",
                id: "type_id",
                massive: [...decision_type],
                index: 2,
              },
              {
                tag: "input",
                title: "description",
                type: "text",
                id: "description",
                index: 3,
              },
            ]}
            handler={submitHandler}
            message={message}
            buttonName="Add"
          />
        </Backdrop>
      )}
      {store.reducer.activeModal.activeModal === "edit_decision" && (
        <Backdrop>
          <ModalForm
            type="patch"
            title="Edit decision"
            tab="3"
            inputs={[
              {
                tag: "input",
                title: "name",
                type: "text",
                id: "name",
                value:
                  store.reducerDecision.activeDecision.activeDecision?.name,
                index: 1,
              },
              {
                tag: "select",
                title: "type",
                type: "text",
                id: "type_id",
                value:
                  store.reducerDecision.activeDecision.activeDecision?.type_id,
                massive: [...decision_type],
                index: 2,
              },
              {
                tag: "input",
                title: "description",
                type: "text",
                id: "description",
                value:
                  store.reducerDecision.activeDecision.activeDecision
                    ?.description,
                index: 3,
              },
            ]}
            handler={submitHandler}
            message={message}
            buttonName="Save"
          />
        </Backdrop>
      )}
      {store.reducer.activeModal.activeModal === "matrix_blocked_modal" && (
        <Backdrop>
          <div className={classes.BlockedModal}>
            <div onClick={closeModal} className={classes.CloseBtn}>
              <img src={icons.closeBtn} alt="close" />
            </div>
            <div className={classes.Title}>Save change</div>
            <div className={classes.InputArea}></div>
            <div className={classes.Footer}>
              <button
                tabIndex={1}
                onClick={cancelBlockedHandler}
                className={[classes.Cancel, classes.Btn].join(" ")}
              >
                Cancel
              </button>
              <button
                tabIndex={2}
                onClick={saveBlockedHandler}
                className={[classes.Save, classes.Btn].join(" ")}
              >
                Save
              </button>
            </div>
          </div>
        </Backdrop>
      )}
      {
        //E
        store.reducer.activeModal.activeModal === "range_blocked_modal" && (
          <Backdrop>
            <div className={classes.BlockedModal}>
              <div onClick={closeModal} className={classes.CloseBtn}>
                <img src={icons.closeBtn} alt="close" />
              </div>
              <div className={classes.Title}>Save changes?</div>
              <div className={classes.InputArea}></div>
              <div className={classes.Footer}>
                <button
                  tabIndex={1}
                  onClick={cancelBlockedHandler}
                  className={[classes.Cancel, classes.Btn].join(" ")}
                >
                  Cancel
                </button>
                <button
                  tabIndex={2}
                  onClick={saveBlockedHandler}
                  className={[classes.Save, classes.Btn].join(" ")}
                >
                  Save
                </button>
              </div>
            </div>
          </Backdrop>
        )
        //END E
      }

      {store.reducer.activeModal.activeModal === "add_score_modal" && (
        <Backdrop>
          <ModalForm
            type="POST"
            title="Add Score"
            tab="4"
            inputs={[
              {
                tag: "input",
                title: "name",
                type: "text",
                id: "name",
                value: null,
                index: 1,
              },
              {
                tag: "input",
                title: "description",
                type: "text",
                id: "description",
                value: null,
                index: 2,
              },
              {
                left: {
                  tag: "input",
                  title: "Min Score",
                  type: "number",
                  id: "min_score",
                  value: null,
                  index: 3,
                },
                right: {
                  tag: "input",
                  title: "Max Score",
                  type: "number",
                  id: "max_score",
                  value: null,
                  index: 4,
                },
              },
            ]}
            handler={submitHandler}
            message={message}
            buttonName="Add"
          />
          {/* <ModalForm
                    type='POST'
                    title='Add Score'
                    inputs={[
                        {tag:'input',title:'name',type:'text',id:'name',value:null},
                        {tag:'input',title:'is trusted weight',id:'is_trusted_weight',type:'checkbox',value:null},
                        {tag:'input',title:'weight',type:'number',id:'weight',value:null},
                        {tag:'select',title:'Criterial Type',type:'text',id:'criterial_type',massive:[
                            {id:1,name:'Range',description:'Range Description'},
                            {id:2,name:'Variable',description:'Variable Description'}

                        ]}
                        


                    ]}
                    handler={submitHandler}
                    message={message}
                    buttonName='Add'
                /> */}
        </Backdrop>
      )}
      {store.reducer.activeModal.activeModal === "edit_score_modal" && (
        <Backdrop>
          <ModalForm
            type="PATCH"
            title="Edit Score"
            tab="4"
            inputs={[
              {
                tag: "input",
                title: "name",
                type: "text",
                id: "name",
                value:
                  store.reducerScoreCard.activeScoreCardData.activeScoreCardData
                    ?.name,
                index: 1,
              },
              {
                tag: "input",
                title: "description",
                type: "text",
                id: "description",
                value:
                  store.reducerScoreCard.activeScoreCardData.activeScoreCardData
                    ?.description,
                index: 2,
              },
              {
                left: {
                  tag: "input",
                  title: "Min Score",
                  type: "number",
                  id: "min_score",
                  value:
                    store.reducerScoreCard.activeScoreCardData
                      .activeScoreCardData?.min_score,
                  index: 3,
                },
                right: {
                  tag: "input",
                  title: "Max Score",
                  type: "number",
                  id: "max_score",
                  value:
                    store.reducerScoreCard.activeScoreCardData
                      .activeScoreCardData?.max_score,
                  index: 4,
                },
              },
            ]}
            handler={submitHandler}
            message={message}
            buttonName="Save"
          />
        </Backdrop>
      )}
      {store.reducer.activeModal.activeModal === "delete_score_modal" && (
        <Backdrop>
          <div className={classes.BlockedModal}>
            <div onClick={closeModal} className={classes.CloseBtn}>
              <img
                src={icons.closeBtn}
                alt="close"
                id="score_blocked_modal_close"
              />
            </div>
            <div className={classes.Title}>Delete score card</div>
            <div className={classes.InputArea}>
              {/* [SCORE CARD NAME HERE] dinamik olacaq */}
              Are you sure you want to delete [SCORE CARD NAME HERE] score card?
            </div>
            <div className={classes.Footer}>
              <button
                tabIndex={1}
                onClick={cancelDeleteBlockedHandler}
                className={[classes.Cancel, classes.Btn].join(" ")}
              >
                Cancel
              </button>
              <button
                tabIndex={2}
                onClick={yesDeleteBlockedHandler}
                className={[classes.Save, classes.Btn].join(" ")}
              >
                Yes
              </button>
            </div>
          </div>
        </Backdrop>
      )}
      {store.reducer.activeModal.activeModal === "score_blocked_modal" && (
        <Backdrop>
          <div className={classes.BlockedModal}>
            <div onClick={closeModal} className={classes.CloseBtn}>
              <img src={icons.closeBtn} alt="close" />
            </div>
            <div className={classes.Title}>Save changes?</div>
            <div className={classes.InputArea}>
              Do you want to save changes? If not, then press "Cancel" button
            </div>
            <div className={classes.Footer}>
              <button
                tabIndex={1}
                onClick={cancelBlockedHandler}
                className={[classes.Cancel, classes.Btn].join(" ")}
              >
                Cancel
              </button>
              <button
                tabIndex={2}
                onClick={saveBlockedHandler}
                className={[classes.Save, classes.Btn].join(" ")}
              >
                Save
              </button>
            </div>
          </div>
        </Backdrop>
      )}
      {store.reducer.activeModal.activeModal === "edit_strategy_modal" && (
        <Backdrop>
          <ModalForm
            type="PATCH"
            title="Edit Strategy"
            tab="4"
            inputs={[
              {
                tag: "input",
                title: "name",
                type: "text",
                id: "name",
                value: store.reducerStrategy.ActiveStrategy.data?.name,
                index: 1,
              },
              {
                tag: "input",
                title: "description",
                type: "text",
                id: "description",
                value: store.reducerStrategy.ActiveStrategy.data?.description,
                index: 2,
              },
              {
                tag: "select",
                title: "version",
                type: "text",
                id: "version",
                value: store.reducerStrategy.ActiveStrategy.data?.version,
                massive: [...store.reducerStrategy.StrategyVersion.data],
                index: 3,
              },
              {
                tag: "select",
                title: "status",
                type: "text",
                id: "status",
                value: store.reducerStrategy.ActiveStrategy.data?.status,
                massive: [...store.reducerStrategy.StrategyStatus.data],
                index: 4,
              },
            ]}
            handler={submitHandler}
            message={message}
            buttonName="Save"
          />
        </Backdrop>
      )}
      <div className={classes.Menu}>
        <div className={classes.Logo}>
          <img src={logo} alt="logo" />
        </div>
        <div className={classes.Link}>
          {sections.map((link, index) => (
            <div
              key={index}
              onClick={blockedLinkHandler}
              className={
                store.reducer.touchBlocked.status === true ||
                store.reducer.emptyValueBlocked.status === true
                  ? [classes.LinkParent, classes.LinkDis].join(" ")
                  : classes.LinkParent
              }
            >
              <Link
                key={index}
                to={link.to}
                onClick={
                  () => {
                    activeLinkHandler(link.to);
                  }                  
                }
                className={
                  window.location.pathname === link.to || activeLink === link.to
                    ? classes.Active
                    : ""
                }
              >
                <img src={link.icon} alt={link.title} />
                <span>{link.title}</span>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.Avatar}>
        <div className={classes.UserPhoto}>
          <img src={UserAvatar} alt="user" />
        </div>
        <div className={classes.UserDetail}>
          <span className={classes.Mail} title={userEmail}>
            {userEmail}
          </span>
          <span
            onClick={() => {
              sessionStorage.clear();
              localStorage.clear();
              window.location.pathname = "/login";
            }}
            className={classes.Settings}
          >
            Logout
          </span>
        </div>
      </div>
      <div className={classes.Section}>
        {matrixSpinner}

        <Switch>
          <Route
            path={["/", "/dashboard"]}
            exact
            component={() => (
              <Dashboard
                sections={sections.filter((el, i) => el.position === 1)}
              />
            )}
          />
          <Route path="/strategy-designer" component={StrategyDesigner} />
          <Route path="/products-designer" component={ProductsDesigner} />
          <Route path="/decision-designer" component={DecisionDesigner} />
          <Route path="/score-card" component={ScoreCard} />
          <Route path="/profile/:slug" component={UserProfile} />

        </Switch>
      </div>
    </div>
  );
};

export default Main;
