import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import classes from "./Range.module.scss";
import {
  ApiDebugModes,
  ButtonSizes,
  ButtonTypes,
  ElementTypes,
  icons,
  messages,
  ModalNames,
  storage_key,
} from "../../../../../../settings/settings";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../store/combineReducer";
import {
  handlerStrategyElementInput,
  handleStrategyBoardSidePanelContent,
  handleStrategyNewCreatedElementId,
} from "../../../../../../store/actions/actionsStrategy";
import DB from "../../../../../../layout/DB/Storage";
import TooltipV2 from "../../../../../../components/UI/Tooltip/TooltipV2";
import DecisionManagerRange, {
  IGetRanges,
  IRangeObj,
} from "../../../../../../components/UI/DecisionManager/DecisionManagerRange";
import DecisionMapper from "../../../../../../components/UI/DecisionMapper/DecisionMapper";
import { executeStrategy } from "../../../../../../helpers/executeStrategy";
import { remove_db } from "../../../FlowChart/handlers/remove_element.handler";
import { hasChange } from "../../../../../../helpers/hasChange";
import Modal from "../../../../../../components/UI/Modal/Modal";
import {
  call_arrow_box_source,
  call_arrow_box_target,
} from "../../../FlowChart/handlers/remove_arrow_box.handler";
import { closeModal, openModal } from "../../../../../../store/actions/actionsModal";
import { checkField } from "../../../../../../helpers/checkField";
import { submitElement } from "../../../../../../helpers/submitElement";
import { create_arrow } from "../../../../../../layout/StrategyDesigner/StrategyBoard/FlowChart/handlers/create_arrow";
import Button from "../../../../../../components/UI/Button/ButtonV2";
import DataViewer from "../../../../../../components/UI/DataViewer/DataViewer";
import { request } from "../../../../../../helpers/request";
import { endpoints } from "../../../../../../api/endpoints";
import { Message } from "components/UI/Messages/Messages";
import { checkLabel } from "helpers/checkLabel";
import { TooltipInfo } from "components/UI/Tooltip/InfoTooltip/InfoTooltip";

export interface IValidating {
  label: boolean;
  decision_id: boolean;
  map_obj: boolean;
}

enum RangePanelActions {
  VALIDATE,
  CLOSE,
}

let timeOutInstance: any;
let initialState: any;

const getRanges = ({ endpoint }: IGetRanges) =>
  new Promise((resolve) => {
    request
    .get(endpoint)
      .then((res) => {
        const { success, response } = res;

        if (success) {
          resolve(response);
        } else {
          console.warn(messages.messageApiGetStatusFalse, res);
        }
      })
      .catch((error) => {
        console.error(messages.messageApiGetError, error);
      });
    });
    
    const Range: FC = () => {
    const [isOpenTooltipInfo, setIsOpenTooltipInfo] = useState<boolean>(false);
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const dispatch = useDispatch();
    const {
    reducerStrategy: {
      StrategyBoardElements: {
        NewCreatedElement: { new_created_element },
        ElementInput: { element_input },
      },
    },
    reducerModal: { modalState },
  } = useSelector((state: RootState) => state);
  const {
    decision_designer: { decision_range },
  } = endpoints;
  const [mappingState, setMappingState] = useState<any>({
    id: "",
    label: "",
    data_type: "range",
    decision_id: "",
    map_obj: "",
  });
  const [prevMappingState, setPrevMappingState] = useState<any>();
  const [headerInputStatus, setHeaderInputStatus] = useState<boolean>(false);
  const [labelState, setLabelState] = useState<any>({
    previous: "",
    present: "",
  });
  // noinspection DuplicatedCode
  const [tooltipState, setTooltipState] = useState<any>({
    visible: false,
    data: undefined,
    bgColor: undefined,
    txtColor: undefined,
    topMessage: undefined,
    bottomMessage:
      "This notification may show reduced result. For full result please choose Debug mode",
  });
  const [validating, setValidating] = useState<IValidating>({
    label: false,
    decision_id: false,
    map_obj: false,
  });
  const [rangesData, setRangesData] = useState<Array<IRangeObj> | undefined>();
  const [
    activePanelAction,
    setActivePanelAction,
  ] = useState<RangePanelActions>();
  const [nameTooltip,setNameTooltip] = useState<boolean>(false);

  const handleMappingState = useCallback(
    (keys: Array<string>, values: Array<string | number | Array<object>>) => {
      let newState = { ...mappingState };

      keys?.forEach((key: string, index: number) => {
        newState[key] = values[index];
      });

      setMappingState({ ...newState });
    },
    [mappingState]
  );
 
  const showInfo = (e: any) => {
    setIsOpenTooltipInfo(!isOpenTooltipInfo);
   };
   
  const getMappingId = useCallback(
    (elementId: string) => {
      const { id } = mappingState;

      if (!id) {
        handleMappingState(["id", "label"], [elementId, elementId]);
        setLabelState({ ...labelState, present: elementId });
        initialState = Object.freeze({
          id: elementId,
          label: elementId,
          data_type: "range",
          decision_id: "",
          map_obj: "",
        });
      }
    },
    [mappingState, handleMappingState, labelState]
  );

  const getPrevMappingData = useCallback(
    (elementId: string) => {
      const db = new DB(storage_key);
      const { id } = mappingState;

      if (!id) {
        db.fetch({ sourceId: elementId })
          .then((result: any) => {
            const {
              status,
              data: { data },
            } = result;

            if (status) {
              initialState = Object.freeze({ ...data });
              setPrevMappingState({ ...data });
              setMappingState({ ...data });
              setLabelState({ ...labelState, present: data?.label });

              data?.decision_id &&
                getRanges({ endpoint: decision_range.get(data.decision_id) })
                  .then((data: any) => {
                    setRangesData(
                      data.map((rangeObj: any) => {
                        return {
                          id: rangeObj?.id,
                          label: rangeObj?.label,
                          alias: rangeObj?.alias,
                          FROM: rangeObj?.FROM,
                          TO: rangeObj?.TO,
                        };
                      })
                    );
                  })
                  .catch(() => {});
            } else {
              console.warn(messages.messageStorageFetchStatusFalse);
            }
          })
          .catch((error) => {
            console.error(messages.messageStorageFetchError, error);
          });
      }
    },
    [mappingState, labelState, decision_range]
  );

  const changeLabel = useCallback(
    (event: any) => {
      const { target } = event;
      const value = target?.value.replace(" ", "_");
      setNameTooltip(false)
      setLabelState({ ...labelState, present: value });

      if (!validating.label) return;

      if (value) {
        target?.parentElement?.classList.remove(classes.Validate);
      } else {
        target.parentElement?.classList.add(classes.Validate);
      }

    },
    [labelState, validating]
  );

  const changeInputStatus = useCallback(
    (status: boolean, labelState: any | object) => {
      const { current } = inputRef;
      setHeaderInputStatus(status);
      if (status) {
        setLabelState({ ...labelState, previous: labelState.present });
        current?.focus();
      } else {
        current?.blur();
      }
    },
    []
  );

  const manipulateInputKeyUp = useCallback(
    (event: any) => {
      const { keyCode } = event;
      const enterCode = 13;
      const escCode = 27;

      if (keyCode === enterCode) {
        changeInputStatus(false, labelState);
        handleMappingState(["label"], [labelState.present]);
        labelState.present && setValidating({ ...validating, label: false });
      } else if (keyCode === escCode) {
        changeInputStatus(false, labelState);
        setLabelState({ previous: undefined, present: labelState.previous });
      }
    },
    [labelState, changeInputStatus, handleMappingState, validating]
  );

  const setDecisionId = useCallback(
    (id: string | undefined) => {
      if (!id) return;

      handleMappingState(["decision_id"], [id]);
    },
    [handleMappingState]
  );

  const setMapObj = useCallback(
    (variable: string) => {
      handleMappingState(["map_obj"], [variable]);
    },
    [handleMappingState]
  );

  useLayoutEffect(() => {
    if (new_created_element && !element_input) {
      getMappingId(new_created_element);
    } else if (element_input) {
      getPrevMappingData(element_input);
    }
  }, [element_input, new_created_element, getMappingId, getPrevMappingData]);

  useEffect(() => {
    return () => clearTimeout(timeOutInstance);
  }, []);

  const handleInputClick = () => {
    if (!headerInputStatus) {
      changeInputStatus(true, labelState);
    }
  };

  const handleInputActions = (status: boolean) => {

    const data = {
      ...mappingState,
      label:labelState?.present
    } as any
    if(!checkLabel(data, ElementTypes.DECISION_RANGE)){
      changeInputStatus(false, labelState);
      if (status) {
        handleMappingState(["label"], [labelState.present]);
        labelState.present && setValidating({ ...validating, label: false });
      } else {
        setLabelState({ previous: undefined, present: labelState.previous });
      }
      setNameTooltip(false)
     }else{
      setNameTooltip(true)

     }

    
  };

  const closeWrapper = () => {
    const { current } = wrapperRef;

    if (current) {
      current.classList.add(classes.CloseAnimation);
      timeOutInstance = setTimeout(() => {
        dispatch(handleStrategyBoardSidePanelContent({ content_value: null }));
        dispatch(
          handleStrategyNewCreatedElementId({ new_created_element: null })
        );
        dispatch(handlerStrategyElementInput({ element_input: null }));
      }, 1000);
    }
  };

  const removeElement = () => {
    if (new_created_element) {
      const svg = document.querySelector("#svg_board") as SVGSVGElement;
      const find_element: any = svg?.querySelector(
        `rect[data-id = ${new_created_element}]`
      ) as SVGRectElement;

      //
      const find_arrows = svg.querySelectorAll(
        `path[data-contact $= ${new_created_element}]`
      ) as NodeList;
      const find_arrows_target = svg.querySelectorAll(
        `path[data-contact ^= ${new_created_element}]`
      ) as NodeList;
      let source: any = null;
      let target: any = null;
      let prev_arrow: any = null;
      if (find_arrows?.length !== 0) {
        find_arrows.forEach((arrow: any) => {
          const id = arrow.dataset.contact;
          source = svg.querySelector(`rect[data-id = ${id?.split("-")?.[0]}]`);

          let find_text = svg.querySelectorAll(
            `text[id = "${id}"]`
          ) as NodeList;
          find_text?.forEach((text: any) => {
            svg.removeChild(text);
          });
          svg.removeChild(arrow);
        });
      }
      if (find_arrows_target?.length !== 0) {
        find_arrows_target.forEach((arrow: any) => {
          const id = arrow.dataset.contact;
          target = svg.querySelector(`rect[data-id = ${id?.split("-")?.[1]}]`);
          prev_arrow = arrow;
          let find_text = svg.querySelectorAll(
            `text[id = "${id}"]`
          ) as NodeList;
          find_text?.forEach((text: any) => {
            svg.removeChild(text);
          });

          svg.removeChild(arrow);
        });
      }
      if (source && target) {
        create_arrow(source, target, prev_arrow, svg);
      } else {
        call_arrow_box_source(find_arrows);
        call_arrow_box_target(find_arrows_target);
      }
      if (find_element) {
        remove_db(find_element);
        svg.removeChild(find_element.parentNode);
      }
     
    }
  };

  const checkChanges = () => {
    setIsOpenTooltipInfo(false);
    hasChange({
      initial: initialState,
      changeable: mappingState,
    })
      .then(() => {
        dispatch(
          openModal({
            modalState: {
              visible: true,
              title: messages.titleClosePanel,
              question: messages.titleSureClosePanel,
              message: messages.messagePanelClosing,
              buttonMessage: messages.titleBtnClose,
              buttonType: ButtonTypes.PRIMARY,
              name: ModalNames.RANGE_PANEL,
            },
          })
        );
        setActivePanelAction(RangePanelActions.CLOSE);
      })
      .catch((error) => {
        error && console.error(error);
        closeWrapper();
        removeElement();
      });
  };

  const handleModalAction = (action: boolean) => {
    switch (activePanelAction) {
      case RangePanelActions.VALIDATE: {
        setValidating({
          label: false,
          map_obj: false,
          decision_id: false,
          [modalState.validatingField as string]: true,
        });
        break;
      }
      case RangePanelActions.CLOSE: {
        if (!action) {
          return;
        }

        closeWrapper();
        removeElement();
      }
    }

    dispatch(closeModal());
  };

  const validateData = () =>
    new Promise((resolve, reject) => {
      checkField({ state: mappingState, types: ElementTypes.DECISION_RANGE })
        .then(resolve)
        .catch(({ reason }) => {
          dispatch(
            openModal({
              modalState: {
                visible: true,
                title: messages.titleAttention,
                question: messages.titleMustBeFilled,
                message: messages.messagePanelEmptyAttention,
                buttonMessage: messages.titleUnderstand,
                buttonType: ButtonTypes.WARNING,
                validatingField: reason,
                name: ModalNames.RANGE_PANEL,
              },
            })
          );
          reject(messages.messageEmptyField(reason));
        });
      setActivePanelAction(RangePanelActions.VALIDATE);
    });

  const handleExecution = () => {
    validateData()
      .then(() => {
        executeStrategy({
          state: tooltipState,
          setState: setTooltipState,
          activeElementId: element_input,
          debugMode: ApiDebugModes.BREAKPOINT,
          elementPresentData: mappingState,
          elementPreviousData: prevMappingState,
          elementType: ElementTypes.DECISION_RANGE,
        });
      })
      .catch((error) => {
        error && console.error(error);
      });
  };

  const handleSubmit = () => {
    validateData()
      .then(() => {
        submitElement(
          new_created_element
            ? {
                id: new_created_element,
                state: mappingState,
                type: ElementTypes.DECISION_RANGE,
                isNew: true,
              }
            : {
                id: element_input,
                state: mappingState,
                previousData: prevMappingState,
                type: ElementTypes.DECISION_RANGE,
                isNew: false,
              }
        )
          .then(closeWrapper)
          .catch(() => {
            dispatch(
              openModal({
                modalState: {
                  visible: true,
                  title: messages.titleOops,
                  question: messages.titleWentWrong,
                  message: messages.messagePanelSubmittingError,
                  buttonMessage: messages.titleAgain,
                  buttonType: ButtonTypes.DELETE,
                  name: ModalNames.RANGE_PANEL,
                },
              })
            );
          });
      })
      .catch((error) => {
        error && console.error(error);
      });
  };

  return (
    <div ref={wrapperRef} className={classes.RangeWrapper}>
      <section className={classes.RangeHeader}>
      {nameTooltip && <Message style={{left:1.15,top:5.6}}/>}

        <div
          className={`${classes.HeaderTitleWrapper} ${
            headerInputStatus ? classes.ActiveTitle : ""
          } ${validating.label ? classes.Validate : ""}`}
        >
          <input
            ref={inputRef}
            type="text"
            className={classes.HeaderTitleInput}
            title={labelState.present}
            value={labelState.present}
            onChange={changeLabel}
            onKeyUp={manipulateInputKeyUp}
            onClick={handleInputClick}
          />
          {headerInputStatus && (
            <>
              <svg
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => handleInputActions(true)}
              >
                <path d="M.115 4.23A.415.415 0 010 3.963c0-.077.038-.193.115-.27l.539-.538a.372.372 0 01.538 0l.039.038 2.115 2.27a.186.186 0 00.27 0L8.768.115h.039a.372.372 0 01.538 0l.539.539a.372.372 0 010 .538L3.73 7.577a.35.35 0 01-.27.115.35.35 0 01-.269-.115l-3-3.23-.077-.116z" />
              </svg>
              <svg
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => handleInputActions(false)}
              >
                <path d="M5.88 4.997l3.93-3.93a.625.625 0 10-.883-.884l-3.93 3.93-3.93-3.93a.625.625 0 10-.884.884l3.93 3.93-3.93 3.93a.625.625 0 10.884.884l3.93-3.93 3.93 3.93a.623.623 0 00.884 0 .625.625 0 000-.884l-3.93-3.93z" />
              </svg>
            </>
          )}
        </div>
        <div className={classes.HeaderActions}>
          {element_input && (
            <TooltipV2
              visible={tooltipState?.visible}
              onVisible={(value: boolean) =>
                setTooltipState({
                  ...tooltipState,
                  visible: value,
                  data: undefined,
                })
              }
              data={tooltipState?.data}
              topMessage={tooltipState?.topMessage}
              bottomMessage={tooltipState?.bottomMessage}
              backgroundColor={tooltipState?.bgColor}
              textColor={tooltipState?.txtColor}
              child={
                <img
                  className={classes.HeaderPlayAction}
                  src={icons.strategyPlay}
                  alt="Play icon"
                  onClick={handleExecution}
                />
              }
            />
          )}
        </div>
      </section>
      <section className={classes.RangeBody}>
        <aside className={classes.RangeLeftSide}>
          <DataViewer fullHeight={mappingState?.decision_id} presentVariableId={mappingState.id} />
          {!mappingState?.decision_id && (
            <DecisionManagerRange
              getId={setDecisionId}
              getRangesData={setRangesData}
            />
          )}
        </aside>
        <div className={classes.RangeRightSide}>
          <DecisionMapper
            validate={validating}
            decisionRanges={rangesData}
            getMappedVariable={setMapObj}
            prevMappedVariable={mappingState?.map_obj}
          />
          <Button size={ButtonSizes.LARGE} onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </section>
      {isOpenTooltipInfo && <TooltipInfo sidePanelUp />}
      <button className={classes.RangeCloseButton} onClick={checkChanges}>
        <img src={icons.closeBtn} alt="Close icon" />
      </button>
      <svg
            className={classes.HelpIcon}
            viewBox='0 0 8 8'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            onClick={(e) => showInfo(e)}
          >
            <path
              d='M4 6.30469C4.21574 6.30469 4.39062 6.1298 4.39062 5.91406C4.39062 5.69833 4.21574 5.52344 4 5.52344C3.78426 5.52344 3.60938 5.69833 3.60938 5.91406C3.60938 6.1298 3.78426 6.30469 4 6.30469Z'
              fill='white'
              fillOpacity='0.6'
            />      
            <path
              d='M4 2.00781C3.31075 2.00781 2.75 2.56856 2.75 3.25781C2.75 3.43041 2.88991 3.57031 3.0625 3.57031C3.23509 3.57031 3.375 3.43041 3.375 3.25781C3.375 2.91319 3.65538 2.63281 4 2.63281C4.34462 2.63281 4.625 2.91319 4.625 3.25781C4.625 3.60244 4.34462 3.88281 4 3.88281C3.82741 3.88281 3.6875 4.02272 3.6875 4.19531V4.97656C3.6875 5.14916 3.82741 5.28906 4 5.28906C4.17259 5.28906 4.3125 5.14916 4.3125 4.97656V4.46827C4.85103 4.32916 5.25 3.8392 5.25 3.25781C5.25 2.56856 4.68925 2.00781 4 2.00781Z'
              fill='white'
              fillOpacity='0.6'
            />
          </svg> 
      <Modal
        title={modalState?.title}
        question={modalState?.question}
        message={modalState?.message}
        buttonMessage={modalState?.buttonMessage}
        buttonType={modalState?.buttonType}
        onAction={handleModalAction}
        visible={modalState?.visible}
        name={ModalNames.RANGE_PANEL}
      />
    </div>
  );
};

export default Range;
