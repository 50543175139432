import { ElementTypes } from "../../../../../settings/settings";

export const getArrowPropery = (arrow:SVGPathElement)=>{
    const contact = arrow?.dataset?.contact as string;
    const side = arrow?.dataset?.side as string;
    const coord = arrow?.getAttribute('d') as string;
    const bool = arrow?.dataset?.bool as string;

    return {contact,side,coord,bool}
}
export const getContactId = (contact:string)=>{
    const contact_split = contact?.split('-') as string[];
    return {prev:contact_split?.[0],next:contact_split?.[1]}
}

export const getType = (type:string)=>{
    let typeResult:ElementTypes|null=null
    switch(type){
        case ElementTypes.CONDITION:
            typeResult=ElementTypes.CONDITION
            break;
        case ElementTypes.FUNCTION:
            typeResult=ElementTypes.FUNCTION
            break;
        case ElementTypes.MAP:
            typeResult=ElementTypes.MAP
            break;
        case ElementTypes.DECISION:
            typeResult=ElementTypes.DECISION
            break;
        case ElementTypes.VALIDATE:
            typeResult=ElementTypes.VALIDATE
            break;
        case ElementTypes.DATA_WIZARD:
            typeResult=ElementTypes.DATA_WIZARD
            break;
        case ElementTypes.STARTPOINT:
            typeResult=ElementTypes.STARTPOINT
            break;
    }

    return {typeResult:typeResult}
}

export const idToType = (id:string)=>{
    let result:ElementTypes|null=null;

    if(id.includes('condition')){
        result = ElementTypes.CONDITION
    }else if(id.includes('function')){
        result = ElementTypes.FUNCTION

    }else if(id.includes('scorecard')){
        result = ElementTypes.MAP

    }else if(id.includes('decision')){
        result = ElementTypes.DECISION

    }else if(id.includes('startpoint')){
        result = ElementTypes.STARTPOINT

    }else if(id.includes('validate')){
        result = ElementTypes.VALIDATE

    }else if(id.includes('datawizard')){
        result = ElementTypes.DATA_WIZARD

    }
    return {type:result}
    
}