import React, { FC, useCallback, useState } from "react";
import classes from "./DecisionPropertyMapper.module.scss";
import { IRangeObj } from "../../../components/UI/DecisionManager/DecisionManagerRange";
import {
  messages,
} from "../../../settings/settings";
import { IValidating } from "../../../layout/StrategyDesigner/StrategyBoard/SidePanel/Decision/Range/Range";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
interface IDecisionPropertyMapper {
  decisionRanges?: Array<IRangeObj>;
  getMappedVariable?: (variable: string) => void;
  mappingData: any;
  prevMappedVariable?: string;
  validate?: IValidating;
  generateCriteriaRow: (value: any) => any; 
  setMappingVariable: (value: string) => void;
}

const DecisionPropertyMapper: FC<IDecisionPropertyMapper> = ({
  decisionRanges,
  getMappedVariable,
  prevMappedVariable,
  setMappingVariable,
  generateCriteriaRow,
    validate,
}) => {
  const dispatch = useDispatch();
  const {
    reducerModal: { modalState },
  } = useSelector((state: RootState) => state);

  const [droppedVariable, setDroppedVariable] = useState<string | undefined>();  

  const generateDecisionRangesRows = useCallback(() => {
  
    setMappingVariable(prevMappedVariable ?? droppedVariable ?? "Drag variable here")
    if (!decisionRanges) {
      return null;
    }
   
    if (!decisionRanges.length) {
      return <div className={classes.NoDataRow}>{messages.messageNoData}</div>;
    }
         return  decisionRanges.map((rangeObj: IRangeObj, index: number) => {
           const { id, label, alias, FROM, TO } = rangeObj;
      
      return (
        <div key={index} id={`${id}`} className={classes.MapperRow}>
          <span className={classes.MapperColumn} title={label}>
            <p className={label === 'A' ? classes.Success : label === 'B' ? classes.Warning : classes.Danger}>{label}</p> 
          </span>
          <span className={classes.MapperColumn} title={FROM}>
            {`${Math.round(+FROM)} - `}
          </span>
          <span className={classes.MapperColumn} title={TO}>
            {`${Math.round(+TO)}`}
          </span>
        </div>
      );
    });
  }, [decisionRanges]);

    return (
        <div className={classes.Wrapper}>             
      <div className={classes.MenuTitle} id="menuTitle">mapping ranges</div>
      <section className={classes.DecisionRangeSection}>       
        <div
          className={`${classes.SectionBody} ${
            validate?.decision_id ? classes.Validate : ""
          }`}
        >
          {generateDecisionRangesRows()}
        </div>
      </section>     
    </div>
  );
};

export default DecisionPropertyMapper;
