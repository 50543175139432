import styled, { css, keyframes } from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  padding: 0 4.32vh;
  margin: 0 auto;
  z-index: 1;
`;

export const Header = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 2.88vh; */
  height: 9vh;
  width: 100%;
  position: fixed;
  border-bottom: 0.14vh solid #edf0f1;
  z-index: 1;
  background: #fff;
  top: 0;
`;
export const Title = styled.div`
  position: relative;
  font-family: "ginger", sans-serif;
  font-size: 2.88vh;
`;
export const Section = styled.section`
  min-height: 80vh;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0 4vh;
  z-index: 0;
`;
export const LeftContent = styled.nav`
  position: fixed;
  width: 27.36vh;
  height: 100%;
  top: 9vh;
`;
export const LeftHide = styled.nav`
  position: relative;
  width: 27.36vh;
  min-height: 80vh;
`;
export const Navbar = styled.nav`
  width: 27.36vh;
  height: calc(6.16vh * 4);
`;
export const NavbarFooter = styled.nav`
  width: 27.36vh;
  height: calc(100% - (6.16vh * 4));
  border-right: 0.14vh solid #edf0f1;
  border-top: 0.14vh solid #edf0f1;
`;
interface IList {
  active: boolean;
}
export const List = styled.span<IList>`
  display: flex;
  width: 100%;
  height: 6.16vh;
  align-items: center;
  font-size: 1.96vh;
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  border-right: 0.14vh solid #edf0f1;
  gap: 0 2.45vh;

  &:not(:first-child) {
    border-top: 0.14vh solid #edf0f1;
  }
  ${(props) => {
    switch (props.active) {
      case true: {
        return css`
          color: #001237;
          border: none;
          svg {
            path {
              fill-opacity: 1 !important;
            }
          }
        `;
      }
      default: {
        return css`
          color: rgba(0, 18, 55, 0.5);
          fill-opacity: 0.5 !important;
        `;
      }
    }
  }}
`;

export const RightContent = styled.section`
  position: relative;
  width: calc(100% - 27.36vh);
  min-height: 85vh;
  margin-top: 12vh;
  display: flex;
  justify-content: flex-start;
  gap: 0 5.35vh;
`;

export const Avatar = styled.div`
  position: relative;
  width: 19.6vh;
  height: 19.6vh;
  border-radius: 0.56vh;
  border: 0.04vh solid rgba(16, 41, 70, 0.5);
`;
export const AvatarContainer = styled.div`
  position: relative;
  width: 19.6vh;
  height: 19.6vh;
  border-radius: 0.56vh;
  overflow: hidden;
`;
export const EditSubMenu = styled.div`
  position: absolute;
  width:18.48vh;
  min-height:5.46vh;
  background-color: #FFF;
  top:19vh;
  right:calc(-18.48vh + 1.7vh);
  box-shadow: 0 0 1.4vh rgba(0, 0, 0, 0.15);
  z-index: 10;
  border-radius: 0.56vh;
`
export const SubMeniList = styled.span`
  display: block;
  position: relative;
  width:100%;
  height:5.46vh;
  font-size: 1.96vh;
  color: rgba(16, 41, 70, 0.5);
  display: flex;
  align-items: center;
  text-indent: 1.95vh;
  cursor: pointer;
  &:hover{
    color: rgba(16, 41, 70, 0.9);

  }
`
export const AvatarEdit = styled.div`
  position: absolute;
  width: 2.52vh;
  height: 2.52vh;
  border-radius: 0.56vh;
  border: 0.04vh solid rgba(16, 41, 70, 0.5);
  right: 0.7vh;
  bottom: 0.7vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  svg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    path {
      fill-opacity: 1 !important;
    }
  }
`;

export const AvatarFileInput = styled.input`
  opacity: 0;
  z-index: 2;
  position: absolute;
`;
export const AvatarImg = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: inherit;
  height: inherit;
  object-fit: cover;
`;



interface IForm {
  width?: number;
}
export const Form = styled.form<IForm>`
  position: relative;
  height: 19.6vh;
  ${(props) => {
    return css`
      width: ${props.width ?? 72.15}vh;
    `;
  }}/* border-radius:.56vh; */
  /* border:0.04vh solid rgba(16, 41, 70, 0.5); */
`;
export const InputGroup = styled.div`
  position: relative;
  width: inherit;
  min-height: 1vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  gap: 0 2.1vh;
  /* border-radius:.56vh; */
`;
export const DroppedContainer = styled.div`
  width: 53.5vh;
  min-height: 0;
  margin-bottom: 2vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
`;
export const DroppedArea = styled.div`
  width: 53.5vh;
  height: 24.22vh;
  background: #f5f6fa;
  border-radius: 1.4vh;
  margin-bottom: 2vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const DroppedContent = styled.div`
  width: 42.5vh;
  height: 14.22vh;
  border: 0.4vh dashed #10294659;
  border-radius: 4vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 2;
`;

export const TextContainer = styled.div`
  width: 35vh;
  z-index: 1;
`;
export const Paragraph = styled.p`
  text-align: center;
  color: #10294659;
  z-index: 1;
`;

export const Switcher = styled.div`
  position: relative;
  width: 33.75vh;
  height: 6vh;
  border-radius: 5.6vh;
  margin-bottom: 2.8vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f9fb;
  box-shadow: inset 0 0 0.28vh rgba(0, 0, 0, 0.1);
`;

interface ISwitcherTitle {
  active?: boolean;
}
export const SwitcherTitle = styled.div<ISwitcherTitle>`
  position: relative;
  width: 15.68vh;
  height: 4.5vh;
  /* border-radius:5.6vh; */
  /* border:0.04vh solid rgba(16, 41, 70, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  border-radius: 5.6vh;
  font-size: 1.68vh;
  ${(props) => {
    switch (props.active) {
      case true: {
        return css`
          color: #fff;
          background-color: #002d57;
        `;
      }
      default: {
        return css`
          color: #002d57;
          background-color: transparent;
        `;
      }
    }
  }}
`;


export const CloseButton = styled.div`
  position: absolute;
  right:-2.2vh;
  top:0;
  width:1.4vh;
  height:1.4vh;
  cursor: pointer;
`

const slide = keyframes`
  from {
    right:-100%;
  }
  to {
    right:5vh;
  }
`;
export const Notf = styled.div`
  position: fixed;
  right:-100%;
  bottom:5vh;
  width:32.91vh;
  height:5.6vh;
  color: #FFF;
  border-radius:1.2vh;
  background-color: #06C37D;
  animation:${slide} .5s linear forwards;
  display: flex;
  align-items: center;
  justify-content: center;

`
