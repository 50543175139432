import { ElementTypes } from "../settings/settings";

export const checkElementConnections = (
  boardElements: Array<Record<string, any>>
) =>
  new Promise<void>((resolve, reject) => {
    for (const element of boardElements) {
      const { type, sourceId, targetId } = element;

      if (type === ElementTypes.MESSAGE_LIST || type === ElementTypes.USED_FUNCTIONS) continue;

      if (!sourceId || !targetId) {
        reject();
        break;
      }
    }

    resolve();
  });
