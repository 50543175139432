import { arrow_type } from "../../../../../../../../settings/settings";
import { chaining_storage } from "../../../add_storage.handler";
import { getAttr } from "../../../methods/attributes";
import { remove_arrow_box } from "../../../remove_arrow_box.handler";
import { Massive } from "../../types";



export const arrow_top_right_bottom = <T extends Massive>(first: T, last: T, arrow: SVGPathElement,arrows:NodeListOf<SVGPathElement>|null = null):void => {
    const svg = document.querySelector(`#svg_board`) as SVGElement;
    
    const { x: first_x, y: first_y, width: first_width, height: first_height, id: first_id, type: first_type } = getAttr(first.element);
    const { x: last_x, y: last_y, width: last_width,height:last_height,id: last_id } = getAttr(last.element);

    if(first_x && first_y && first_width && first_height && first_id && first_type && last_x && last_y && last_width && last_height && last_id){

            let m = `M${first_x + first_width / 2} ${first_y - 12}`;

            let v1:string = '';
            let v2:string = '';
        
            if(first_y >= last_y){
                v1 = `v-${Math.abs((first_y - 12+25+12) - (last_y-21))}`;
                v2 = `v${12+25}`;
        
            }else if(first_y < last_y ){
                v1 = `v-${12+25}`;
                v2 = `v${Math.abs((last_y-9) - (first_y-(12+25)))}`;
        
            }
            let h = `h${Math.abs((last_x+last_width/2) - (first_x + first_width/2))}`;
            // let v = `v${((last_y + last_height/2) - (first_y - 12))}`;
            const d = [m, v1,h,v2,arrow_type.bottom].join(',');
            arrow.setAttributeNS(null, 'data-side', 'top-right-bottom');
            arrow.setAttributeNS(null, 'd', d);
            svg.insertBefore(arrow, svg.firstChild);
            chaining_storage(first.element);

            remove_arrow_box(first_id, last_id, 'top-right-bottom', first_type,arrows);
    }
}
