import React, { FC, useCallback, useState } from "react";
import classes from "./ScoreCard.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/combineReducer";
import Portal from "../Portal/Portal";
import { icons } from "../../../settings/settings";
import { endpoints } from "../../../api/endpoints";
import { request } from "../../../helpers/request";
import { useDispatch } from "react-redux";
import { handleScoreCardData } from "../../../store/actions/actionsScoreCard";

interface IScoreCard {
  onSelect?: Function;
  fullScreen?:boolean;
}

const ScoreCard: FC<IScoreCard> = ({ onSelect = (scoreId: number, scoreName: string) => {} ,fullScreen}) => {

  const dispatch = useDispatch();
  const {
    score_card: { score_card },
  } = endpoints;

  const [isOpenList, setIsOpenList] = useState<boolean>(false);
  const [selectedScoreId, setSelectedScoreId] = useState<number | undefined>();
  const [selectedScoreName, setSelectedScoreName] = useState("")
  const [selectedScoreDescription, setSelectedScoreDescription] = useState("")

  const {
    reducerScoreCard: {
      scoreCardData: { scoreCardData },
    },
  } = useSelector((state: RootState) => state);

  const closeScoreCardList = useCallback(() => {
    setIsOpenList(false);
  }, []);

  const generateScoreCardRows = useCallback(
    (scoreCardData: Array<any>) => {
      function handleActivation(event: any, id: number, name: string, description: string) {
        const { target } = event;

        if (target.classList.contains(classes.ActiveRow)) {
          target.classList.remove(classes.ActiveRow);
          setSelectedScoreId(undefined);
          setSelectedScoreName("")
          setSelectedScoreDescription("")
        } else {
          target.classList.add(classes.ActiveRow);
          setSelectedScoreId(id);
          setSelectedScoreName(name)
          setSelectedScoreDescription(description)
        }
      }

      function selectId(id: number, name: string, description: string) {
        onSelect(id, name, description);
        closeScoreCardList();
      }

      if (scoreCardData?.length) {
        return scoreCardData.map((scoreCard: any, index: number) => {
          const { id, name, min_score, max_score, description } = scoreCard;
          
          return (
            <div
              key={index}
              className={classes.ListRow}
              title={description}
              onClick={(event: any) => handleActivation(event, id, name, description)}
              onDoubleClick={() => selectId(id, name, description)}
            >
              <span>{name}</span>
              <span>{min_score}</span>
              <span>{max_score}</span>
            </div>
          );
        });
      } else {
        return <div className={classes.NoData}>no data to display</div>;
      }
    },
    [closeScoreCardList, onSelect]
  );

  const getScoreCards = () => {
    if (scoreCardData === null) {
      request
        .get(score_card.get())
        .then((res) => {
          const { success, response } = res;

          if (success) {
            dispatch(handleScoreCardData({ scoreCardData: [...response] }));
          } else {
            console.warn("fetching Score Card Succes is false ", res);
          }
        })
        .catch((error) => {
          console.error("Fetching Score Cards Error ", error);
        });
    }
  };

  const openScoreCardList = () => {
    setIsOpenList(true);
    getScoreCards();
  };

  const copyAuthToLocal = () => {
    const sessionCredentials: any = sessionStorage.getItem("credentials");
    const sessionUserDetails: any = sessionStorage.getItem("user_details");

    localStorage.setItem("credentials", sessionCredentials);
    localStorage.setItem("user_details", sessionUserDetails);
  };

  const handleListSubmit = () => {
    if (selectedScoreId) {
      onSelect(selectedScoreId, selectedScoreName, selectedScoreDescription);
      closeScoreCardList();
    }
  };

  return (
    <div className={fullScreen ? [classes.ScoreWrapper,classes.FullScreen].join(' '):classes.ScoreWrapper}>
      <div className={classes.ScoreHeader}>
        <span className={classes.ScoreTitle}>score card</span>      
        <span className={classes.Seperator}></span>
      </div>
      <div className={classes.ScoreBody}>
        <div className={classes.BodyActions}>
          <button className={classes.ActionButton} onClick={openScoreCardList}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
            >
              <g clipPath="url(#folderSVG)">
                <path
                  fill="#FCEBA6"
                  d="M11.98 15H.405a.405.405 0 01-.39-.51l2.628-7.208a.404.404 0 01.39-.299H14.61c.266 0 .39.255.39.51l-2.63 7.209a.404.404 0 01-.39.299z"
                />
                <path
                  fill="#F0D97A"
                  d="M13 4.655h-.586v2.328h1.034v-1.88c0-.247-.2-.448-.448-.448zM1.034 2.845H.448c-.247 0-.448.2-.448.448v11.05c.018 0 .036.002.053.003l.981-2.677V2.845z"
                />
                <path
                  fill="#F4EFDC"
                  d="M2.643 7.282a.404.404 0 01.39-.3h9.381V0H2.586v7.438l.057-.156z"
                />
                <path
                  fill="#CEC9AE"
                  d="M4.397 4.138h6.207a.259.259 0 100-.517H4.397a.259.259 0 100 .517zM4.397 2.328h2.586a.259.259 0 100-.517H4.397a.259.259 0 100 .517zM10.604 5.432H4.397a.259.259 0 100 .517h6.207a.259.259 0 100-.517zM2.586 7.438V.517H1.81v9.048l.776-2.127z"
                />
                <path
                  fill="#D1BF86"
                  d="M1.81 9.565v-8.53h-.775v10.657l.775-2.127z"
                />
              </g>
              <defs>
                <clipPath id="folderSVG">
                  <path fill="#fff" d="M0 0h15v15H0z" />
                </clipPath>
              </defs>
            </svg>
            <span>open</span>
          </button>
          <a
            className={classes.ActionButton}
            href="/score-card"
            target="_blank"
            onClick={copyAuthToLocal}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
            >
              <g clipPath="url(#fileSVG)">
                <path
                  fill="#EDEADA"
                  d="M14.207 4.278L10.345.346H1.793v16.29h12.414V4.279z"
                />
                <path
                  fill="#CEC9AE"
                  d="M4.552 6.806h6.896a.278.278 0 00.276-.281.278.278 0 00-.276-.28H4.552a.278.278 0 00-.276.28c0 .155.123.28.276.28zM4.552 4.559H7.31a.278.278 0 00.276-.281.278.278 0 00-.276-.28H4.552a.278.278 0 00-.276.28c0 .155.123.28.276.28zM11.448 8.491H4.552a.278.278 0 00-.276.281c0 .155.123.281.276.281h6.896a.278.278 0 00.276-.28.278.278 0 00-.276-.282zM11.448 10.738H4.552a.278.278 0 00-.276.281c0 .155.123.281.276.281h6.896a.278.278 0 00.276-.28.278.278 0 00-.276-.282zM11.448 12.985H4.552a.278.278 0 00-.276.281c0 .156.123.281.276.281h6.896a.278.278 0 00.276-.28.278.278 0 00-.276-.282zM10.345.346v3.932h3.862L10.345.346z"
                />
              </g>
              <defs>
                <clipPath id="fileSVG">
                  <path
                    fill="#fff"
                    d="M0 0h16v16.291H0z"
                    transform="translate(0 .346)"
                  />
                </clipPath>
              </defs>
            </svg>
            <span>new</span>
          </a>
        </div>
      </div>
      {isOpenList && (
        <Portal>
          <div className={classes.ScoreListWrapper}>
            <span className={classes.ListTitle}>score card</span>
            <div className={classes.ListHeader}>
              <span>card name</span>
              <span>card min score</span>
              <span>card max score</span>
            </div>
            <div className={classes.ListBody}>
              {generateScoreCardRows(scoreCardData)}
            </div>
            <button className={classes.SubmitButton} onClick={handleListSubmit}>
              Submit
            </button>
            <button
              className={classes.ListCloseButton}
              onClick={() => closeScoreCardList()}
            >
              <img src={icons.closeBtn} alt="Close icon" />
            </button>
          </div>
        </Portal>
      )}
    </div>
  );
};

export default ScoreCard;
