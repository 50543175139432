import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import classes from "./TestPanel.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store/combineReducer";
import {
  ButtonSizes,
  ButtonTypes,
  storageKeys,
} from "../../../../../settings/settings";
import {
  handleStrategyBoardSidePanelCreateInputObject,
} from "../../../../../store/actions/actionsStrategy";
import Editor from "./Editor/editor";
import DataTree from "../../../../../components/UI/Tree/DataTree";
import { parseStringPromise } from "xml2js";
import { ActionCreators } from "redux-undo";
import { testSrategy } from "helpers/testStrategy";
import Button from "components/UI/Button/ButtonV2";

let timeoutInstance: any;

const InputCreate: FC = () => {
  const {
    reducerStrategy: {
      SourceDataTypes,
    },
  } = useSelector((state: RootState) => state);

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const dispatch = useDispatch();
  const [strategyDataTypes, setStrategyDataTypes] = useState<Array<object>>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<string>("");
  const [editorData, setEditorData] = useState<string>("");
  const [testResponse, setTestResponse] = useState<any>(null);
  const [responseTime, setResponseTime] = useState<number>(0);
  const [responseStatus, setResponseStatus] = useState<boolean>(false);
  const [leftSideBtns, setLeftSideBtns] = useState<any>({
    beautify: "Beautify",
    copy: "Copy",
  });
  const [firstClick,setFirstClick] = useState<boolean>(false);
  const reFormatTypeOptions = useCallback((types: Array<object>) => {
    return types?.map((type: any) => {
      const { id, name } = type;
      return {
        value: id,
        option: name,
      };
    });
  }, []);

  const handleTypes = useCallback(
    (typeId: number) => {
      strategyDataTypes?.forEach((types: any) => {
        const { value, option } = types;

        if (value === typeId) {
          if (option === "JSON") setSelectedLanguage("JAVASCRIPT");
          if (option !== "JSON") setSelectedLanguage(option);
        }
      });
    },
    [strategyDataTypes]
  );

  const jsonParser = useCallback((jsonCode: string) => {
    return new Promise<object>((resolve) => {
      const parsedJson = JSON.parse(jsonCode);
      resolve(parsedJson);
    });
  }, []);

  const convertSelectedEditorLanguageDataToObject = useCallback(
    (data: string) => {
      if (selectedLanguage === "JAVASCRIPT") {
        jsonParser(data)
          .then((result) =>
            dispatch(
              handleStrategyBoardSidePanelCreateInputObject({ data: result })
            )
          )
          .catch(() =>
            dispatch(
              handleStrategyBoardSidePanelCreateInputObject({
                data: "Invalid JSON",
              })
            )
          );
      }
      if (selectedLanguage === "XML") {
        parseStringPromise(data, { explicitArray: false })
          .then((result) => {
            const jsonString = JSON.stringify(result);
            jsonParser(jsonString)
              .then((res) =>
                dispatch(
                  handleStrategyBoardSidePanelCreateInputObject({ data: res })
                )
              )
              .catch(() =>
                dispatch(
                  handleStrategyBoardSidePanelCreateInputObject({
                    data: "Invalid converted XML JSON",
                  })
                )
              );
          })
          .catch(() =>
            dispatch(
              handleStrategyBoardSidePanelCreateInputObject({
                data: "Invalid XML",
              })
            )
          );
      }
    },
    [selectedLanguage, dispatch, jsonParser]
  );

  const getAvailableSourceData = useCallback(() => {
    const sourceDataJSON = sessionStorage.getItem(
      storageKeys.activeStrategySourceData
    );

    if (!sourceDataJSON) {
      return;
    }

    const { type, data } = JSON.parse(sourceDataJSON);
    handleTypes(type);
    setEditorData(JSON.stringify(data, null, "\t"));
    convertSelectedEditorLanguageDataToObject(JSON.stringify(data));
  }, [handleTypes, convertSelectedEditorLanguageDataToObject]);

  const resizeElements = useCallback((event: MouseEvent) => {
    const { clientX } = event;
    const wrapper = wrapperRef.current as HTMLElement;
    const codeArea = document.getElementById("codeArea");
    const resizer = document.getElementById("resizerX");
    const editor = document.getElementById("EditorWrapper");
    const dataTree = document.getElementById("createDataTree");

    if (!wrapper || !codeArea || !resizer || !editor || !dataTree) return;

    const resizerWidthPercentage =
      (resizer.offsetWidth / codeArea.offsetWidth) * 100;
    const movementValue = clientX - wrapper.offsetLeft - 37;
    const movementPercentage = (movementValue / codeArea.offsetWidth) * 100;
    const minBorder = 30;
    const maxBorder = 70 - resizerWidthPercentage;

    if (movementPercentage <= minBorder || movementPercentage >= maxBorder) {
      return;
    }

    resizer.style.left = `${movementPercentage + resizerWidthPercentage / 2}%`;
    editor.style.width = `${movementPercentage}%`;
    dataTree.style.width = `${
      100 - movementPercentage - resizerWidthPercentage
    }%`;
  }, []);

  const applyResizeFunctionality = useCallback(() => {
    const codeArea = document.getElementById("codeArea");

    if (!codeArea) return;

    codeArea.addEventListener("mousemove", resizeElements);
  }, [resizeElements]);

  const clearResizeFunctionality = useCallback(() => {
    const codeArea = document.getElementById("codeArea");

    if (!codeArea) return;

    codeArea.removeEventListener("mousemove", resizeElements);
  }, [resizeElements]);

  useLayoutEffect(getAvailableSourceData, [getAvailableSourceData]);

  useLayoutEffect(() => {
    const { data } = SourceDataTypes;
    if (data !== null) {
      setStrategyDataTypes(reFormatTypeOptions(data));
    }
  }, [SourceDataTypes, reFormatTypeOptions]);

  useEffect(() => {
    if (editorData === "First select type, please" && selectedLanguage !== "") {
      setEditorData(" ");
    }
  }, [editorData, selectedLanguage]);


  useEffect(() => {
    document.addEventListener("mouseup", clearResizeFunctionality);

    return () => {
      document.removeEventListener("mouseup", clearResizeFunctionality);
    };
  }, [clearResizeFunctionality]);

  useEffect(() => {
    return () => clearTimeout(timeoutInstance);
  }, []);

  const undo = () => {
    dispatch(ActionCreators.undo());
  };

  const redo = () => {
    dispatch(ActionCreators.redo());
  };

  const createStrategySourceDataModel = (
    sourceData: string,
    dataTypeId: number
  ) => {
    const strategyDetailsJSON = sessionStorage.getItem(
      storageKeys.activeStrategy
    );
    let strategyId = null;
    if (strategyDetailsJSON) {
      const { id } = JSON.parse(strategyDetailsJSON);
      strategyId = id;
    }

    return {
      strategy: strategyId,
      source_data: JSON.parse(sourceData),
      strategy_source_data_type: dataTypeId,
    };
  };

  const saveSourceData = (data: any) => {
    setFirstClick(true);
    const requestBody = createStrategySourceDataModel(data, 1);

    if (!data) {
      return;
    }

    const setInput = (res: any) => {
      setTestResponse(res);
    };
    const setTime = (time: number) => {
      setResponseTime(time);
    };
    setResponseStatus(true);
    testSrategy(setInput, setTime, requestBody);
    setTestResponse(null);
  };
  const copyData = () => {
    const elem = document.createElement("textarea");
    elem.value = editorData;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
    setLeftSideBtns({ ...leftSideBtns, copy: "Copied!" });
    setTimeout(() => {
      setLeftSideBtns({ ...leftSideBtns, copy: "Copy" });
    }, 2000);
  };
  return (
    <div
      ref={wrapperRef}
      id="testWrapper"
      className={classes.InputCreateWrapper}
    >
      <div className={classes.InputCreateHeader}>
        <div className={classes.InputCreateHeaderStack}>
          <span className={classes.InputCreateHeaderTitle}></span>
        </div>
        <div className={classes.InputCreateHeaderTimer}>
          {/* {responseTime>0 && <span className={classes.InputCreateHeaderTitle}>Response time: {responseTime}ms</span>} */}
        </div>
      </div>
      <div id="codeArea" className={classes.InputCreateCodeArea}>
        <div id="EditorWrapper" className={classes.EditorWrapper}>
          <Editor
            language={selectedLanguage}
            lineNumbers={true}
            header={true}
            leftHeaderElements={["LeftAlign", "RightAlign"]}
            value={editorData}
            onChange={setEditorData}
          />

          <div className={classes.Beautify}>
            <Button
              size={ButtonSizes.SMALL}
              type={ButtonTypes.LINK_PRIMARY}
              onClick={() => {
                setEditorData(
                  JSON.stringify(JSON.parse(editorData), null, "\t")
                );
                setLeftSideBtns({ ...leftSideBtns, beautify: "Beautified!" });
                setTimeout(() => {
                  setLeftSideBtns({ ...leftSideBtns, beautify: "Beautify" });
                }, 2000);
              }}
            >
              {leftSideBtns.beautify}
            </Button>
            <Button
              size={ButtonSizes.SMALL}
              type={ButtonTypes.LINK_PRIMARY}
              onClick={copyData}
            >
              {leftSideBtns.copy}
            </Button>
          </div>
          <div className={classes.StartTest}>
            <Button
              size={ButtonSizes.LARGE}
              type={ButtonTypes.PRIMARY}
              onClick={() => {
                saveSourceData(editorData);
              }}
            >
              <>
                <svg
                  width="20"
                  height="19"
                  viewBox="0 0 20 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.6695 9.25224L8.06949 4.75224C7.97739 4.68984 7.85859 4.68294 7.76019 4.73484C7.66209 4.78674 7.60059 4.88874 7.60059 5.00004V14C7.60059 14.1113 7.66209 14.2133 7.76049 14.2652C7.80429 14.2886 7.85259 14.3 7.90059 14.3C7.95969 14.3 8.01879 14.2823 8.06949 14.2478L14.6695 9.74784C14.7514 9.69204 14.8006 9.59934 14.8006 9.50004C14.8006 9.40074 14.7514 9.30804 14.6695 9.25224ZM8.20059 13.4321V5.56794L13.9681 9.50004L8.20059 13.4321Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.8"
                  />
                  <path
                    d="M10 0.5C5.0374 0.5 1 4.5374 1 9.5C1 14.4626 5.0374 18.5 10 18.5C14.9626 18.5 19 14.4626 19 9.5C19 4.5374 14.9626 0.5 10 0.5ZM10 17.9C5.3683 17.9 1.6 14.1317 1.6 9.5C1.6 4.8683 5.3683 1.1 10 1.1C14.6317 1.1 18.4 4.8683 18.4 9.5C18.4 14.1317 14.6317 17.9 10 17.9Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.8"
                  />
                </svg>
                <span>Start Test</span>
              </>
            </Button>
          </div>
        </div>
        <div className={classes.CodeAreaGap} id={"resizerX"}>
          {/* <button
            onClick={() =>{
              setTestResponse(null)
              setResponseStatus(false)
              convertSelectedEditorLanguageDataToObject(editorData)
            }
            }
          >
            <svg
              viewBox="0 0 10 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Convert to object</title>
              <path d="M0.115385 4.23077C0.0384615 4.15385 0 4.03846 0 3.96154C0 3.88462 0.0384615 3.76923 0.115385 3.69231L0.653846 3.15385C0.807692 3 1.03846 3 1.19231 3.15385L1.23077 3.19231L3.34615 5.46154C3.42308 5.53846 3.53846 5.53846 3.61538 5.46154L8.76923 0.115385H8.80769C8.96154 -0.0384615 9.19231 -0.0384615 9.34615 0.115385L9.88462 0.653846C10.0385 0.807692 10.0385 1.03846 9.88462 1.19231L3.73077 7.57692C3.65385 7.65385 3.57692 7.69231 3.46154 7.69231C3.34615 7.69231 3.26923 7.65385 3.19231 7.57692L0.192308 4.34615L0.115385 4.23077Z" />
            </svg>
          </button> */}
          <svg
            className={classes.ThreeDots}
            onMouseDown={applyResizeFunctionality}
          >
            <circle />
            <circle />
            <circle />
            Sorry, your browser does not support inline SVG.
          </svg>
        </div>
        <DataTree
          header={responseStatus}
          // leftHeaderElements={["Undo", "Redo", "Filter"]}
          rightHeaderElements={["Response"]}
          responseTime={responseTime}
          bgcolor="#FFF"
          undoAction={undo}
          redoAction={redo}
          data={testResponse ? testResponse : null}
          copy={true}
          firstClick={firstClick}
        />
      </div>
    </div>
  );
};

export default InputCreate;
