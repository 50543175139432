import { endpoints } from "api/endpoints"
import { FormButton } from "components/UI/Form/Button/Button"
import { FormInput } from "components/UI/Form/Input/Input"
import { request } from "helpers/request"
import { ChangeEvent, FC, useEffect, useRef, useState } from "react"
import { IUserDetails, TPassword } from "../config"
import { Form, InputGroup } from "../UserProfile.style"
interface IChangePassword{
  stateUserDetails:IUserDetails;
  actionStatus:(status:boolean)=>void;

}
export const ChangePassword:FC<IChangePassword> = ({stateUserDetails,actionStatus})=>{
  const [statePassword, setStatePassword] = useState<TPassword>({
    user:"",
    old_password: "",
    new_password: "",
    new_password_repeat: "",
  });
  const [statePasswordMessage, setStatePasswordMessage] = useState({
    new_password: "",
    new_password_repeat: "",
  });
  const [stateButtonDisabled,setStateButtonDisabled] = useState<boolean>(true);
  const refForm = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (!statePassword.old_password)
      setStatePassword({ ...statePassword,user:stateUserDetails.user, old_password: stateUserDetails.password });
  }, [statePassword, stateUserDetails.password, stateUserDetails.user]);

  useEffect(()=>{
    if(statePassword.new_password !== "" && statePassword.new_password_repeat !== ""){
      if(statePasswordMessage.new_password === "" && statePasswordMessage.new_password_repeat === ""){
        setStateButtonDisabled(false);
      }else{
        setStateButtonDisabled(true);
      }
    }else{
      setStateButtonDisabled(true);
    }
  },[statePassword.new_password, statePassword.new_password_repeat, statePasswordMessage.new_password, statePasswordMessage.new_password_repeat])
  const handleFillForm = (e:ChangeEvent<HTMLInputElement>,key:string) => {
    const {value} = e.target;
    const passwordFormat = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,32}$/g;
    const messages = {
      empty:"Password is empty",
      incorrect:"Password is incorrect",
      notmatch:"New password and confirm password do not match"
    }
    const setState = (field:any=null) => {
        setStatePasswordMessage({...statePasswordMessage,...field});
    }
    setStatePassword(state=>{
      return{
        ...state,
        [key]:value
      }
    })
    const {new_password} = statePassword as TPassword;
    if(key === "new_password"){
      if(!value){
          setState({[key]:messages.empty})
      }else{
        if(!value.match(passwordFormat)){
          setState({[key]:messages.incorrect})
        }else{
          setState({[key]:""});
        }
      }
    }
    if(key === "new_password_repeat"){
      if(!value){
          setState({[key]:messages.empty})
      }else{
        if(new_password !== value){
          setState({[key]:messages.notmatch})
        }else{
          setState({[key]:""});
        }
      }
    }
  }

  const handleCancel = () => {
    setStatePassword({...statePassword,new_password:"",new_password_repeat:""})
    setStatePasswordMessage({...statePassword,new_password:"",new_password_repeat:""})
    refForm?.current?.reset()

  }
  
  const handleSubmit = () => {
    if(stateButtonDisabled) return;
    request.patch(endpoints.profile.reset_password,statePassword)
    .then(res=>{

      if(res){
        const sessionUserDetails: string | null = sessionStorage.getItem("user_details");
        const userData = JSON.parse(sessionUserDetails ?? "") as IUserDetails;
        if (!userData) return;
        const newUserData = {
          ...userData,
          password:statePassword.new_password
        }
        sessionStorage.setItem("user_details",JSON.stringify(newUserData))
        actionStatus(true)

      }
    })    
  }
  return(
    <Form ref={refForm} onSubmit={(e)=>e.preventDefault()} width={39.21}>

    <FormInput
      type="password"
      label="Old password"
      value={stateUserDetails.password}
      isHide
    />
    <FormInput
      type="password"
      label="New password"
      isHide
      value={statePassword.new_password}
      message={statePasswordMessage.new_password}
      onChange={(e)=>handleFillForm(e,"new_password")}
    />
    <FormInput
      type="password"
      label="Confirm password"
      isHide
      value={statePassword.new_password_repeat}
      message={statePasswordMessage.new_password_repeat}
      onChange={(e)=>handleFillForm(e,"new_password_repeat")}

    />
    <InputGroup>
      <FormButton label="Cancel" action="cancel"  onCLick={handleCancel}/>
      <FormButton
        label="Save"
        action="save"
        onCLick={handleSubmit}
        disabled={stateButtonDisabled}
      />
    </InputGroup>
  </Form>
  )
}