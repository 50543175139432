import React, {
  FC,
  useLayoutEffect,
  useState,
  useRef,
  useCallback,
  useEffect,
} from "react";
import classes from "./Input.module.scss";
import { v1 as uuidv1 } from "uuid";
import { Message } from "../Messages/Messages";

interface IInput {
  label?: string;
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  background?: string;
  ref?: Function;
  tabIndex?: number;
  validate?: boolean;
  droppable?: boolean;
  getDataType?: (dataType: string) => void;
  nameTooltip?:boolean;
}

const Input: FC<IInput> = ({
  label,
  value,
  onChange,
  disabled,
  background,
  tabIndex,
  validate = false,
  droppable = false,
  getDataType,
  nameTooltip
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const labelRef = useRef<HTMLLabelElement | null>(null);
  const [uniqueId, setUniqueId] = useState<string>("");
  const validateComponent = useCallback(() => {
    const { current } = wrapperRef;
    
    if (!validate && !nameTooltip) {
      current?.classList.remove(classes.Validate);
      return;
    }

    current?.classList.add(classes.Validate);
  }, [validate, nameTooltip]);

  const handleChange = useCallback(
    (event: any) => {
      const {
        currentTarget: { value },
      } = event;
      onChange && onChange(value);
      validateComponent();
    },
    [onChange, validateComponent]
  );

  const onFocus = useCallback(() => {
    const { current } = labelRef;
    current?.classList.add(classes.ActiveLabel);
    
  }, []);

  const onBlur = useCallback(() => {
    const inputElement = inputRef.current;
    const labelElement = labelRef.current;
    if (!inputElement && !labelElement) return;
    if (inputElement?.value) return;
    labelElement?.classList.remove(classes.ActiveLabel);
  }, []);

  const dragEnter = useCallback((event: any) => {
    const { currentTarget } = event;

    currentTarget?.classList.add(classes.Drop);
  }, []);

  const dragOver = useCallback((event: any) => {
    event.preventDefault();
    const { currentTarget } = event;
    const hasClass = currentTarget?.classList.contains(classes.Drop);

    if (!hasClass) {
      currentTarget?.classList.add(classes.Drop);
    }
  }, []);

  const dragLeave = useCallback((event: any) => {
    const { currentTarget } = event;
    currentTarget?.classList.remove(classes.Drop);
  }, []);

  const drop = useCallback(
    (event: any) => {
      event.preventDefault();
      const { currentTarget, dataTransfer } = event;
      const draggingData = dataTransfer.getData("dragging_source");
      currentTarget?.classList.remove(classes.Drop);

      if (!draggingData) return;

      const { key, dataType } = JSON.parse(draggingData);

      onChange && onChange(key);
      getDataType && getDataType(dataType);
      validateComponent();
    },
    [onChange, getDataType, validateComponent]
  );

  const assignDragMethods = useCallback(() => {
    const { current } = wrapperRef;

    if (!droppable || !current) return;

    current.ondragenter = dragEnter;
    current.ondragover = dragOver;
    current.ondragleave = dragLeave;
    current.ondrop = drop;
  }, [droppable, dragEnter, dragOver, dragLeave, drop]);

  useLayoutEffect(() => {
    if (!uniqueId) {
      setUniqueId(uuidv1());
    }
  }, [uniqueId]);

  useLayoutEffect(assignDragMethods, [droppable, assignDragMethods]);

  useEffect(validateComponent, [validateComponent]);

  useEffect(() => {
    value ? onFocus() : onBlur();
  }, [value, onFocus, onBlur]);

  return (
    <div
      ref={wrapperRef}
      className={classes.Wrapper}
      style={{ backgroundColor: background}}
    >
      <input
        type="text"
        id={uniqueId}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        ref={inputRef}
        tabIndex={tabIndex}
        onFocus={onFocus}
        onBlur={onBlur}

      />
      {
        nameTooltip && <Message/>
      }
      <label ref={labelRef} htmlFor={uniqueId}>
        {label}
      </label>
    </div>
  );
};

export default Input;
