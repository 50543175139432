class DB {
    private key: string = '';
    private store: any | null = null
    constructor(key_name: string) {
        this.key = key_name;
        this.store = localStorage.getItem(this.key);
    }

    add(data: Array<any>) {
        let storage_mass: any = JSON.parse(this.store);
        if (this.store) {
            if (data) {

                let mass: any = [];

                let sourceId = data?.[0]?.['sourceId'];
                storage_mass?.filter((f: any) => f['sourceId'] !== sourceId)?.forEach((value: any) => {

                    mass.push(value);
                })


                let new_mass: any = [...mass, ...data];
                localStorage.setItem(this.key, JSON.stringify(new_mass));
            }

        } else {
            if (data) {
                localStorage.setItem(this.key, JSON.stringify(data));
            }
        }
        return new Promise((resolve, reject) => {
            if (data) {
                resolve(data)
            } else {
                reject(new Error('Data is null'))
            }
        });
    }

    update(condition: any, data: Array<any>) {
        let result: any = {};

        let obj_length: number = Object.entries(condition).length;
        if (obj_length === 1 && data.length === 1) {
            let _key = Object.keys(condition)[0];
            let _value = Object.values(condition)[0];
            let _find = JSON.parse(this.store)?.filter((f: any) => f[_key] === _value);


            if (_find.length > 0) {
                _find.forEach((obj: any) => {
                    for (const [key, value] of Object.entries(data[0])) {
                        obj[key] = value;
                        result['status'] = true;

                    }
                    result['data'] = obj;
                    let mass_ref = [...JSON.parse(this.store)];
                    let filtered = mass_ref.filter((f: any) => f[_key] === _value);
                    let index = mass_ref.indexOf(filtered[0]);
                    mass_ref[index] = obj;
                    localStorage.setItem(this.key, JSON.stringify(mass_ref));
                })

            } else {
                localStorage.setItem(this.key, JSON.stringify(data));

            }
        } else if (obj_length === 2 && data.length === 1) {
            const { sourceId, branchLabel } = condition;
            if (data.length === 1) {
                let _find: Array<any> = JSON.parse(this.store)?.filter((f: any) => f['sourceId'] === sourceId && f['branchLabel'] === branchLabel);
                if (_find.length > 0) {
                    let [data_obj]: any = [...data];
                    for (const [key, value] of Object.entries(data_obj)) {
                        data_obj[key] = value;
                        result['status'] = true;


                    }

                    result['data'] = data_obj
                    let mass_ref = [...JSON.parse(this.store)];
                    const [filtered] = mass_ref.filter((f: any) => f.sourceId === sourceId && f.branchLabel === branchLabel);
                    let index = mass_ref.indexOf(filtered);
                    mass_ref[index] = data_obj;

                    localStorage.setItem(this.key, JSON.stringify(mass_ref));
                }
            }

        } else if (obj_length === 1 && data.length === 2) {
            const { sourceId } = condition;
            let _find_yes: Array<any> = JSON.parse(this.store)?.filter((f: any) => f['sourceId'] === sourceId && f['branchLabel'] === "YES");
            let _find_no: Array<any> = JSON.parse(this.store)?.filter((f: any) => f['sourceId'] === sourceId && f['branchLabel'] === "NO");

            if (_find_yes.length > 0 && _find_no.length > 0) {
                let [data_obj1, data_obj2]: any = [...data];
                for (const [key, value] of Object.entries(data_obj1)) {
                    data_obj1[key] = value;
                    result['status'] = true;
                }
                for (const [key, value] of Object.entries(data_obj2)) {
                    data_obj2[key] = value;
                    result['status'] = true;
                }

                result['data'] = [data_obj1, data_obj2];
                let mass_ref = [...JSON.parse(this.store)];
                const filtered = mass_ref.filter((f: any) => f.sourceId === sourceId);
                let index1 = mass_ref.indexOf(filtered[0]);
                let index2 = mass_ref.indexOf(filtered[1]);
                mass_ref[index1] = data_obj1;
                mass_ref[index2] = data_obj2;

                localStorage.setItem(this.key, JSON.stringify(mass_ref));
            }


        }


        return new Promise((resolve, reject) => {
            if (Object.keys(result)[0] === 'status' && Object.values(result)[0] === true) {
                resolve(result)
            } else {
                // reject(result)
            }
        });
    }



    remove(condition: any) {
        let _key = Object.keys(condition)[0];
        let _value = Object.values(condition)[0];
        let _mass: any = [...JSON.parse(this.store)];
        let _find = _mass.filter((f: any) => f[_key] === _value);
        let result: any = {};
        if (_find.length > 0) {
            let index = _mass.indexOf(_find[0]);

            _mass.splice(index, 1);
            result['status'] = true;
            result['data'] = { ..._find[0] }
            localStorage.setItem(this.key, JSON.stringify(_mass));

        } else {
            result['status'] = false;
        }


        return new Promise((resolve, reject) => {
            if (condition && result) {
                resolve(result)
            } else {
                reject(new Error('Action is failed'))
            }
        });
    }
    fetch(condition: any) {
        let _key = Object.keys(condition)[0];
        let _value = Object.values(condition)[0];
        let _mass: any = this.store && [...JSON.parse(this.store)];
        let _find = _mass?.filter((f: any) => f[_key] === _value);
        let result: any = {};
        if (_find?.length > 0) {

            result['status'] = true;
            if (_find.length === 1) {
                result['data'] = { ..._find[0] }
            }
            if (_find.length > 1) {
                result['data'] = [..._find]

            }


        } else {
            result['status'] = false;
        }


        return new Promise((resolve, reject) => {
            if (condition && result) {
                resolve(result)
            } else {
                reject(new Error('Action is failed'))
            }
        });
    }
    fetchAll() {
        let result: any = {};
        if (this.store) {
            let _mass: any = [...JSON.parse(this.store)];
            if (this.key) {

                result['status'] = true;
                result['data'] = [..._mass];


            } else {
                result['status'] = false;
            }

        }
        return new Promise((resolve, reject) => {
            if (this.key && result) {
                resolve(result)
            } else {
                reject(new Error('Action is failed'))
            }
        });
    }

}

export default DB;