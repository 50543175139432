import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import classes from './Select.module.scss';

interface SelectProps{
    options?:any,
    defaultValue?:any,
    style?:any,
    input?:any,
    handler:(value:any)=>void
}
const Select:FC<SelectProps> = ({options,defaultValue,style,input,handler})=>{
    const [optionStatus,setOptionStatus] = useState<boolean>(false);
    const [selectedOption,setSelectedOption] = useState<any>(null);

    useLayoutEffect(()=>{
            setSelectedOption('element not found')
    },[defaultValue, options])

    useEffect(()=>{
        window.addEventListener('click',()=>setOptionStatus(false))
        return ()=>{ 
            window.removeEventListener('click',()=>setOptionStatus(true))
        }
    },[optionStatus])
    
    const selectHandler = (e:any)=>{
        e.stopPropagation();
        setOptionStatus(!optionStatus);
    }
    const selectOptionHandler = (option:any)=>{
        setSelectedOption(option);
        handler(option)
    }
    return(
        <div onClick={selectHandler} style={style}  className={optionStatus ? [classes.Select,classes.ActiveSelect].join(' '):classes.Select}>
            <span className={classes.Selected}>{defaultValue ? defaultValue : selectedOption}</span>
            {optionStatus && 
            <>
                <div style={{top:style?.height}} className={classes.Options}>
                    {options?.filter((f:any)=>f !== input)?.map((option:any,i:number)=>(
                        <div key={i}  onClick={()=>selectOptionHandler(option)} className={selectedOption === option || defaultValue === option ? [classes.Option,classes.ActiveOption].join(' '):classes.Option}>{option}</div>
                    ))}
                </div>
            </>
            }
        </div>
    )
}

export default Select;