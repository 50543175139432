export const STRATEGY_DATA = "STRATEGY_DATA";
export const STRATEGY_STATUS = "STRATEGY_STATUS";
export const STRATEGY_VERSION = "STRATEGY_VERSION";
export const ACTIVE_STRATEGY = "ACTIVE_STRATEGY";
export const STRATEGY_BOARD_ZOOM = "STRATEGY_BOARD_ZOOM";
export const STRATEGY_APPROVE_FIELDS = "STRATEGY_APPROVE_FIELDS";
export const STRATEGY_APPROVED_BY_STATUS = "STRATEGY_APPROVED_BY_STATUS";
export const STRATEGY_BOARD_POSITION = "STRATEGY_BOARD_POSITION";
export const STRATEGY_BOARD_SIDE_PANEL_COLLAPSE_STATUS =
  "STRATEGY_BOARD_SIDE_PANEL_COLLAPSE_STATUS";
export const STRATEGY_BOARD_SIDE_PANEL_CONTENT =
  "STRATEGY_BOARD_SIDE_PANEL_CONTENT";
export const STRATEGY_BOARD_SIDE_PANEL_INPUT_STAGE =
  "STRATEGY_BOARD_SIDE_PANEL_INPUT_STAGE";
export const STRATEGY_BOARD_SIDE_PANEL_INPUT_UPLOAD =
  "STRATEGY_BOARD_SIDE_PANEL_INPUT_UPLOAD";
export const STRATEGY_BOARD_SIDE_PANEL_INPUT_REMOVE_QUEUE_FILE =
  "STRATEGY_BOARD_SIDE_PANEL_INPUT_REMOVE_QUEUE_FILE";
export const STRATEGY_BOARD_SIDE_PANEL_INPUT_UPLOAD_PROCESS =
  "STRATEGY_BOARD_SIDE_PANEL_INPUT_UPLOAD_PROCESS";
export const STRATEGY_BOARD_SIDE_PANEL_INPUT_UPLOAD_PROCESS_STATUS =
  "STRATEGY_BOARD_SIDE_PANEL_INPUT_UPLOAD_PROCESS_STATUS";
export const STRATEGY_BOARD_SIDE_PANEL_INPUT_UPLOAD_RESET =
  "STRATEGY_BOARD_SIDE_PANEL_INPUT_UPLOAD_RESET";
export const STRATEGY_BOARD_SIDE_PANEL_INPUT_CREATE_OBJECT =
  "STRATEGY_BOARD_SIDE_PANEL_INPUT_CREATE_OBJECT";
export const STRATEGY_SOURCE_DATA_AVAILABILITY =
  "STRATEGY_SOURCE_DATA_AVAILABILITY";
export const STRATEGY_SOURCE_DATA_TYPES = "STRATEGY_SOURCE_DATA_TYPES";
export const STRATEGY_FETCH_STATUS = "STRATEGY_FETCH_STATUS";

export const STRATEGY_BOARD_ELEMENTS = "STRATEGY_BOARD_ELEMENTS";
export const STRATEGY_BOARD_PANEL = "STRATEGY_BOARD_PANEL";
export const STRATEGY_BOARD_TYPE = "STRATEGY_BOARD_TYPE";
export const STRATEGY_BOARD_ELEMENT_INPUT = "STRATEGY_BOARD_ELEMENT_INPUT";
export const STRATEGY_BOARD_ELEMENT_OUTPUT = "STRATEGY_BOARD_ELEMENT_OUTPUT";
export const STRATEGY_BOARD_ELEMENT_OUTPUT2 = "STRATEGY_BOARD_ELEMENT_OUTPUT2";
export const STRATEGY_BOARD_DATA = "STRATEGY_BOARD_DATA";

export const STRATEGY_BOARD_NEW_CREATED_ELEMENT =
  "STRATEGY_BOARD_NEW_CREATED_ELEMENT";

export const STRATEGY_FUNCTION_GROUPS = "STRATEGY_FUNCTION_GROUPS";
export const STRATEGY_FUNCTIONS = "STRATEGY_FUNCTIONS";

export const STRATEGY_BOARD_WIZARD_STATUS = "STRATEGY_BOARD_WIZARD_STATUS";
export const STRATEGY_BOARD_VALIDATE_STATUS = "STRATEGY_BOARD_VALIDATE_STATUS";

export const STRATEGY_SOURCE_DATA_STATUS = "STRATEGY_SOURCE_DATA_STATUS";
export const STRATEGY_ADD_SELECTED = "STRATEGY_ADD_SELECTED";
export const STRATEGY_ADD_ALL_SELECTED = "STRATEGY_ADD_ALL_SELECTED";
export const STRATEGY_REMOVE_SELECTED = "STRATEGY_REMOVE_SELECTED";
export const STRATEGY_REMOVE_ALL_SELECTED = "STRATEGY_REMOVE_ALL_SELECTED";

export const STRATEGY_OUTPUT_FORMAT_DATA = "STRATEGY_OUTPUT_FORMAT_DATA";
export const STRATEGY_OUTPUT_FORMAT_ITEM_STATUS = "STRATEGY_OUTPUT_FORMAT_ITEM_STATUS";
export const STRATEGY_OUTPUT_FORMAT_MESSAGE = "STRATEGY_OUTPUT_FORMAT_MESSAGE";
export const STRATEGY_ELEMENT_TOOLTIP_STATUS = "STRATEGY_ELEMENT_TOOLTIP_STATUS";
export const STRATEGY_MARTIX_ACTIVE_DATA = "STRATEGY_MARTIX_ACTIVE_DATA";
export const STRATEGY_DECISION_MATRIX_COORD_DATA = "STRATEGY_DECISION_MATRIX_COORD_DATA";
export const CHANGEABLE_STATUS = "CHANGEABLE_STATUS";
export const CHECK_OUTPUT_STATUS = "CHECK_OUTPUT_STATUS";


export interface StrategyBoardElements {
  board_elements?: any | null;
}

export interface StrategyBoardType {
  element_type?: any | null;
}

export interface StrategyBoardPanel {
  element_panel?: boolean | false;
}

export interface StrategyBoardElementInput {
  element_input?: any | null;
}
export interface StrategyBoardElementOutput {
  element_output?: any | null;
}
export interface StrategyBoardElementOutput2 {
  element_output2?: any | null;
}
export interface StrategyBoardNewCreatedElement {
  new_created_element?: string | null;
}

export interface StrategyBoardData {
  data: Array<any>;
}

export interface StrategyData {
  data: Array<any>;
}

export interface StrategyStatus {
  data: Array<Record<string, string | number>>;
}

export interface StrategyVersion {
  data: Array<Record<string, string | number>>;
}

export interface ActiveStrategy {
  data: null | any;
}

export interface StrategyBoardZoom {
  minZoom: number;
  maxZoom: number;
  zoom: number;
  zoomPercent?: string;
}

export interface StrategyApprovedByStatus {
  approvedStatus: string | null;
}

export interface StrategyApproveFields {
  strategy_name: string;
  app_name: string;
  approved_by: string | null;
  auth_key: string;
  auth_token: string;
  build: string;
  destination_port: string;
  id: number;
  strategy: number;
  version: string;
}

export interface StrategyBoardPosition {
  top: number;
  left: number;
}

export interface StrategyBoardSidePanelCollapseStatus {
  collapseStatus_value: boolean;
}

export interface StrategyBoardSidePanelContent {
  content_value: string | null;
}

export interface StrategyBoardSidePanelInputStage {
  stage_value: string | null;
}

export interface StrategyBoardSidePanelInputUpload {
  uploadFiles_acceptable: Array<string> | null;
  uploadFiles_ignored: Array<string> | null;
}

export interface StrategyBoardSidePanelInputRemoveQueueFile {
  accept_status: string;
  file_name: string;
}

export interface StrategyBoardSidePanelInputUploadProcess {
  uploadFiles_process: Array<object> | null;
}

export interface StrategyBoardSidePanelInputUploadProcessStatus {
  file_index: number;
  upload_status: string;
}

export interface StrategyBoardSidePanelInputCreateObject {
  data: string | object;
}

export interface StrategyBoardSidePanelState {
  CollapseStatus: StrategyBoardSidePanelCollapseStatus;
  Content: StrategyBoardSidePanelContent;
  Input: {
    input_stage: StrategyBoardSidePanelInputStage;
    input_uploadFiles: StrategyBoardSidePanelInputUpload;
    input_uploadProcess: StrategyBoardSidePanelInputUploadProcess;
    input_createObject: StrategyBoardSidePanelInputCreateObject;
  };
}

export interface StrategyFunctionData {
  function_data: Array<object>;
}

export interface StrategyFunctionGroups {
  function_groups: Array<object>;
}

export interface StrategyFunction {
  data: StrategyFunctionData;
  groups: StrategyFunctionGroups;
}

export interface SourceDataAvailability {
  status: boolean;
}

export interface SourceDataTypes {
  data: Array<object> | null;
}

export interface StrategyFetchStatus {
  status: boolean;
}

export interface StrategyBoardWizardStatus {
  status?: boolean | false;
}
export interface StrategyBoardValidateStatus {
  status?: boolean | false;
}
export interface StrategySourceDataStatus {
  status?: boolean | false;
}

export interface StrategyTableSelectedObj {
  id: number;
}

export interface StrategyTableSelectedList {
  data: Array<StrategyTableSelectedObj>;
}

export interface StrategyOutputFormatData {
  data: Array<any> | [];
}
type ItemType = {
  type:string;
  uuid: string;
  id: string;
  key: string;
  value: string | object[];
}
export interface StrategyOutputFormatItemStatus{
  status:string;
  item?:ItemType;
}
export interface StrategyElementTooltipStatus{
  status:'tooltip'|'side_panel'|'edit'|null;

}


export interface StrategyOutputFormatMessage{
  message:'UNFINISHED_SAVE' | 'EMPTY' | 'NULL'
}
export interface StrategyMatrixActiveData{
  matrix_data:any
}

export interface StrategyDecisionMatrixCoordData{
  x?:string;
  y?:string;
}
export interface ChangeableStatus{
  status:boolean
}
export interface CheckOutputStatus{
  status:boolean;
  showNotification?:boolean;
}
export interface StrategyState {
  StrategyData: StrategyData;
  StrategyStatus: StrategyStatus;
  StrategyVersion: StrategyVersion;
  ActiveStrategy: ActiveStrategy;
  StrategyTableSelectedList: StrategyTableSelectedList;
  StrategyFunction: StrategyFunction;
  StrategyBoardZoom: StrategyBoardZoom;
  StrategyApprovedByStatus: StrategyApprovedByStatus;
  StrategyApproveFields: StrategyApproveFields;
  StrategyBoardPosition: StrategyBoardPosition;
  StrategyBoardSidePanel: StrategyBoardSidePanelState;
  StrategyBoardPanel: StrategyBoardPanel;
  StrategyBoardType: StrategyBoardType;
  StrategyBoardElements: StrategyBoardElements;
  StrategyBoardElementInput: StrategyBoardElementInput;
  StrategyBoardElementOutput: StrategyBoardElementOutput;
  StrategyBoardElementOutput2: StrategyBoardElementOutput2;
  StrategyBoardData: StrategyBoardData;
  SourceDataAvailability: SourceDataAvailability;
  SourceDataTypes: SourceDataTypes;
  StrategyFetchStatus: StrategyFetchStatus;
  StrategyBoardNewCreatedElement: StrategyBoardNewCreatedElement;
  StrategyBoardWizardStatus: StrategyBoardWizardStatus;
  StrategyBoardValidateStatus: StrategyBoardValidateStatus;
  StrategySourceDataStatus: StrategySourceDataStatus;
  StrategyOutputFormatData:StrategyOutputFormatData;
  StrategyOutputFormatItemStatus:StrategyOutputFormatItemStatus;
  StrategyOutputFormatMessage:StrategyOutputFormatMessage;
  StrategyElementTooltipStatus:StrategyElementTooltipStatus;
  StrategyMatrixActiveData:StrategyMatrixActiveData;
  StrategyDecisionMatrixCoordData:StrategyDecisionMatrixCoordData;
  ChangeableStatus:ChangeableStatus;
  CheckOutputStatus:CheckOutputStatus;
}

interface strategyData {
  type: typeof STRATEGY_DATA;
  payload: StrategyData;
}

interface strategyStatus {
  type: typeof STRATEGY_STATUS;
  payload: StrategyStatus;
}

interface strategyVersion {
  type: typeof STRATEGY_VERSION;
  payload: StrategyVersion;
}

interface activeStrategy {
  type: typeof ACTIVE_STRATEGY;
  payload: ActiveStrategy;
}

interface strategyBoardZoom {
  type: typeof STRATEGY_BOARD_ZOOM;
  payload: StrategyBoardZoom;
}

interface strategyApprovedByStatus {
  type: typeof STRATEGY_APPROVED_BY_STATUS;
  payload: StrategyApprovedByStatus;
}

interface strategyApproveFields {
  type: typeof STRATEGY_APPROVE_FIELDS;
  payload: StrategyApproveFields;
}

interface strategyBoardPosition {
  type: typeof STRATEGY_BOARD_POSITION;
  payload: { position: StrategyBoardPosition };
}

interface strategyBoardSidePanelCollapseStatus {
  type: typeof STRATEGY_BOARD_SIDE_PANEL_COLLAPSE_STATUS;
  payload: StrategyBoardSidePanelCollapseStatus;
}

interface strategyBoardSidePanelContent {
  type: typeof STRATEGY_BOARD_SIDE_PANEL_CONTENT;
  payload: StrategyBoardSidePanelContent;
}

interface strategyBoardSidePanelInputStage {
  type: typeof STRATEGY_BOARD_SIDE_PANEL_INPUT_STAGE;
  payload: StrategyBoardSidePanelInputStage;
}

interface strategyBoardSidePanelInputUpload {
  type: typeof STRATEGY_BOARD_SIDE_PANEL_INPUT_UPLOAD;
  payload: StrategyBoardSidePanelInputUpload;
}

interface strategyBoardSidePanelInputRemoveQueueFile {
  type: typeof STRATEGY_BOARD_SIDE_PANEL_INPUT_REMOVE_QUEUE_FILE;
  payload: StrategyBoardSidePanelInputRemoveQueueFile;
}

interface strategyBoardSidePanelInputUploadProcess {
  type: typeof STRATEGY_BOARD_SIDE_PANEL_INPUT_UPLOAD_PROCESS;
  payload: StrategyBoardSidePanelInputUploadProcess;
}

interface strategyBoardSidePanelInputUploadProcessStatus {
  type: typeof STRATEGY_BOARD_SIDE_PANEL_INPUT_UPLOAD_PROCESS_STATUS;
  payload: StrategyBoardSidePanelInputUploadProcessStatus;
}

interface strategyBoardSidePanelInputUploadReset {
  type: typeof STRATEGY_BOARD_SIDE_PANEL_INPUT_UPLOAD_RESET;
}

interface strategyBoardSidePanelInputCreateObject {
  type: typeof STRATEGY_BOARD_SIDE_PANEL_INPUT_CREATE_OBJECT;
  payload: StrategyBoardSidePanelInputCreateObject;
}

interface strategyBoardPanel {
  type: typeof STRATEGY_BOARD_PANEL;
  payload: StrategyBoardPanel;
}
interface strategyBoardType {
  type: typeof STRATEGY_BOARD_TYPE;
  payload: StrategyBoardType;
}

interface strategyBoardElements {
  type: typeof STRATEGY_BOARD_ELEMENTS;
  payload: StrategyBoardElements;
}

interface strategyBoardElementInput {
  type: typeof STRATEGY_BOARD_ELEMENT_INPUT;
  payload: StrategyBoardElementInput;
}
interface strategyBoardElementOutput {
  type: typeof STRATEGY_BOARD_ELEMENT_OUTPUT;
  payload: StrategyBoardElementOutput;
}
interface strategyBoardElementOutput2 {
  type: typeof STRATEGY_BOARD_ELEMENT_OUTPUT2;
  payload: StrategyBoardElementOutput2;
}
interface strategyBoardData {
  type: typeof STRATEGY_BOARD_DATA;
  payload: StrategyBoardData;
}

interface strategyFunctionGroups {
  type: typeof STRATEGY_FUNCTION_GROUPS;
  payload: StrategyFunctionGroups;
}

interface strategyFunctionData {
  type: typeof STRATEGY_FUNCTIONS;
  payload: StrategyFunctionData;
}

interface strategyNewCreatedElement {
  type: typeof STRATEGY_BOARD_NEW_CREATED_ELEMENT;
  payload: StrategyBoardNewCreatedElement;
}

interface sourceDataAvailability {
  type: typeof STRATEGY_SOURCE_DATA_AVAILABILITY;
  payload: SourceDataAvailability;
}

interface sourceDataTypes {
  type: typeof STRATEGY_SOURCE_DATA_TYPES;
  payload: SourceDataTypes;
}

interface strategyFetchStatus {
  type: typeof STRATEGY_FETCH_STATUS;
  payload: StrategyFetchStatus;
}
interface strategyWizardStatus {
  type: typeof STRATEGY_BOARD_WIZARD_STATUS;
  payload: StrategyBoardWizardStatus;
}
interface strategyValidateStatus {
  type: typeof STRATEGY_BOARD_VALIDATE_STATUS;
  payload: StrategyBoardValidateStatus;
}
interface strategySourceDataStatus {
  type: typeof STRATEGY_SOURCE_DATA_STATUS;
  payload: StrategySourceDataStatus;
}

interface strategyTableSelectedListAdd {
  type: typeof STRATEGY_ADD_SELECTED;
  payload: StrategyTableSelectedObj;
}

interface strategyTableSelectedListAddAll {
  type: typeof STRATEGY_ADD_ALL_SELECTED;
  payload: StrategyTableSelectedList;
}

interface strategyTableSelectedListRemove {
  type: typeof STRATEGY_REMOVE_SELECTED;
  payload: StrategyTableSelectedObj;
}

interface strategyTableSelectedListRemoveAll {
  type: typeof STRATEGY_REMOVE_ALL_SELECTED;
}
interface strategyOutputFormatData {
  type: typeof STRATEGY_OUTPUT_FORMAT_DATA;
  payload: StrategyOutputFormatData;
}
interface strategyOutputFormatItemStatus {
  type: typeof STRATEGY_OUTPUT_FORMAT_ITEM_STATUS;
  payload: StrategyOutputFormatItemStatus;
}
interface strategyOutputFormatMessages {
  type: typeof STRATEGY_OUTPUT_FORMAT_MESSAGE;
  payload: StrategyOutputFormatMessage;
}
interface strategyElementTooltipStatus {
  type: typeof STRATEGY_ELEMENT_TOOLTIP_STATUS;
  payload: StrategyElementTooltipStatus;
}
interface strategyMatrixActiveData {
  type: typeof STRATEGY_MARTIX_ACTIVE_DATA;
  payload: StrategyMatrixActiveData;
}
interface strategyDecisionMatrixCoordData {
  type: typeof STRATEGY_DECISION_MATRIX_COORD_DATA;
  payload: StrategyDecisionMatrixCoordData;
}
interface changeableStatus {
  type: typeof CHANGEABLE_STATUS;
  payload: ChangeableStatus;
}
interface checkOutputStatus {
  type: typeof CHECK_OUTPUT_STATUS;
  payload: CheckOutputStatus;
}
export type StrategyActionsTypes =
  | strategyData
  | strategyStatus
  | strategyVersion
  | activeStrategy
  | strategyBoardZoom
  | strategyApprovedByStatus
  | strategyApproveFields
  | strategyBoardPosition
  | strategyBoardSidePanelCollapseStatus
  | strategyBoardSidePanelContent
  | strategyBoardSidePanelInputStage
  | strategyBoardSidePanelInputUpload
  | strategyBoardSidePanelInputRemoveQueueFile
  | strategyBoardSidePanelInputUploadProcess
  | strategyBoardSidePanelInputUploadProcessStatus
  | strategyBoardSidePanelInputUploadReset
  | strategyBoardPanel
  | strategyBoardType
  | strategyBoardElements
  | strategyBoardElementInput
  | strategyBoardElementOutput
  | strategyBoardElementOutput2
  | strategyBoardData
  | strategyBoardSidePanelInputCreateObject
  | strategyFunctionGroups
  | strategyFunctionData
  | strategyNewCreatedElement
  | sourceDataAvailability
  | sourceDataTypes
  | strategyFetchStatus
  | strategyWizardStatus
  | strategyValidateStatus
  | strategySourceDataStatus
  | strategyTableSelectedListAdd
  | strategyTableSelectedListAddAll
  | strategyTableSelectedListRemove
  | strategyTableSelectedListRemoveAll
  | strategyOutputFormatData
  | strategyOutputFormatItemStatus
  | strategyOutputFormatMessages
  | strategyElementTooltipStatus
  | strategyMatrixActiveData
  | strategyDecisionMatrixCoordData
  | changeableStatus
  | checkOutputStatus;
