import { combineReducers } from 'redux'
import reducerBoardElements from './BoardElements/reducer'
import reducerBoardType from './BoardType/reducer'
import reducerPanel from './ElmentPanel/reducer'
import reducerInput from './ElementInput/reducer'
import reducerOutput from './ElementOutput/reducer'
import reducerOutput2 from './ElementOutput2/reducer'

import strategyBoardData from './StrategyFetchData/reducer';
import newCreatedElementReducer from './NewCreatedElement/reducer'
import strategyBoardWizardStatus from './DataWizard/reducer';
import strategyBoardValidateStatus from './Validate/reducer';
import strategySourceDataStatus from './SourceData/reducer';
import strategyOutputFormatData from './OutputFormat/reducer';
import strategyOutputFormatItemStatus from './OutputFormat/ItemStatus/reducer';
import strategyOutputFormatMessage from './OutputFormat/Message/reducer';
import strategyElementTooltipStatus from './ElementTooltipStatus/reducer';
import strategyMatrixActiveData from './StrategyMatrixData/reducer';
import strategyDecisionMatrixCoordData from './MatrixCoordData/reducer';
import checkOutputStatus from './CheckOutputStatus/reducer';
export default combineReducers({
    BoardElements:reducerBoardElements,
    ElementType:reducerBoardType,
    ElementPanel:reducerPanel,
    ElementInput:reducerInput,
    ElementOutput:reducerOutput,
    ElementOutput2:reducerOutput2,
    StrategyBoardData:strategyBoardData,
    NewCreatedElement: newCreatedElementReducer,
    StrategyBoardWizardStatus:strategyBoardWizardStatus,
    StrategyBoardValidateStatus:strategyBoardValidateStatus,
    SourceDataStatus:strategySourceDataStatus,
    StrategyOutputFormatData:strategyOutputFormatData,
    StrategyOutputFormatItemStatus:strategyOutputFormatItemStatus,
    StrategyOutputFormatMessage:strategyOutputFormatMessage,
    StrategyElementTooltipStatus:strategyElementTooltipStatus,
    StrategyMatrixActiveData:strategyMatrixActiveData,
    StrategyDecisionMatrixCoordData:strategyDecisionMatrixCoordData,
    CheckOutputStatus:checkOutputStatus

})