import { StrategyActionsTypes, StrategyOutputFormatData, STRATEGY_OUTPUT_FORMAT_DATA } from "../../../../types/typesStrategy";


const initialState: StrategyOutputFormatData = {
    data:[],
    
}

const reducer = (state = initialState, action: StrategyActionsTypes): StrategyOutputFormatData  => {

    switch (action.type) {

        case STRATEGY_OUTPUT_FORMAT_DATA:
            const {data} = action.payload;
            
            return {
                data:data,
            }
      
        default:
            return state

    }
}

export default reducer