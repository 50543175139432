export type TNavList={
  id:number;
  title:string;
  slug:string;
}
export const UserProfileNavList:TNavList[] = [
  {id:1,title:"Edit Profile",slug:"edit-profile"},
  {id:2,title:"Notifications",slug:"notifications"},
  {id:3,title:"Choose Plan",slug:"choose-plan"},
  {id:4,title:"Change Password",slug:"change-password"}
]

export const SwitcherList = [
  {id:1,title:"New Upload"},
  {id:2,title:"Delete Image"},
]
export interface IUserDetails{
  firstName:string;
  lastName:string;
  phoneNumber:string;
  zip:string;
  address:string;
  city:string;
  country:string;
  state:string;
  email:string;
  profile_image:string | null;
  password:string;
  user:number|string;
}

export type TPassword = {
  user:number|string;
  old_password:string;
  new_password:string;
  new_password_repeat:string;
}