import { elementSize } from "../../../../../../settings/settings";
import { create_text, move_text } from "../element_text.handler";
import { listener } from "./listener";
const arrow_type ={
    top:'l-4 0 ,l4 -8 ,l4 8 ,l-4 0',
    right:'l0 -5 ,l8 5 ,l-8 5 ,l0 -5',
    bottom:'l4 0 ,l-4 8 ,l-4 -8 ,l4 0',
    left:'l0 -5,l-8 5 ,l8 5,l0 -5',
}
export const createErrors = (end_data:any) =>{
    
    let xmlns = "http://www.w3.org/2000/svg";
    const svg = document.querySelector(`#svg_board`) as SVGElement;

    const group = document.createElementNS(xmlns,'g') as SVGGElement;
    group.setAttributeNS(null,'data-id','system_errors');
    group.setAttributeNS(null,'id','system_errors');
    group.setAttributeNS(null,'data-type','figure');
    const {x,y,width,height} = end_data;
    const data:object = {
         stroke:'#CCC',
        width:elementSize.system_error.width,
        height:elementSize.system_error.height,
        x:x-Math.abs(width-elementSize.system_error.width)/2,
        y:y+70+58+12,
        rx:4,
        fill:'#D3465C',
        id:'figure',
        'data-id':'system_errors',
        style:"filter:url(#figure_shadow)"
    };
    const newElement = document.createElementNS(xmlns,'rect') as SVGRectElement;
    newElement.setAttributeNS(null,'data-id','system_errors');
    newElement.setAttributeNS(null,'data-type','system_errors');

    for(const [key,value] of Object.entries(data)){
        newElement.setAttributeNS(null,key,value);
    };

    let arrow = document.createElementNS(xmlns,'path') as SVGPathElement;
    arrow.setAttributeNS(null,'stroke','#788896');
    arrow.setAttributeNS(null,'stroke-width','5.65');
    arrow.setAttributeNS(null,'fill','none');
    arrow.setAttributeNS(null,'stroke-linejoin','round');
    arrow.setAttributeNS(null,'stroke-linecap','round');
    arrow.setAttributeNS(null,'data-contact','endpoint-system_errors');
    let drawn:string = `M${x+width/2} ${y+height+12},v20,h0,v20,${arrow_type.bottom}`;
    arrow.setAttributeNS(null,'d',drawn);
    arrow.setAttributeNS(null,'data-side','bottom');

    const errors = listener(newElement);
    group.appendChild(errors);
    let text = create_text(errors);
    group.appendChild(text)
    svg?.appendChild(arrow);
    return group;
}

export const dragErrors = (coord:any,target:any=null)=>{
    const svg = document.querySelector(`#svg_board`) as SVGElement;
    const errors = svg.querySelector('rect[data-id = "system_errors"]') as SVGRectElement;
    const arrow = svg.querySelector('path[data-contact = "endpoint-system_errors"]') as SVGPathElement;

    if(target?.dataset.type === "output_format"){
        let drawn:string = `M${+coord.x  -105} ${+coord.y+70+12},v20,h0,v20,${arrow_type.bottom}`;
        arrow.setAttributeNS(null,'d',drawn);
        errors.setAttributeNS(null,'x',`${+coord.x-140 - Math.abs(70-elementSize.system_error.width)/2}`);
        errors.setAttributeNS(null,'y',`${+coord.y+70+58+12}`);
        move_text(errors)

    }
    if(!target){
        let drawn:string = `M${+coord.x + 35} ${+coord.y+70+12},v20,h0,v20,${arrow_type.bottom}`;
        arrow.setAttributeNS(null,'d',drawn);
        errors.setAttributeNS(null,'x',`${+coord.x - Math.abs(70-elementSize.system_error.width)/2}`);
        errors.setAttributeNS(null,'y',`${+coord.y+70+58+12}`);
        move_text(errors)

    }


}