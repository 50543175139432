import { StrategyActionsTypes, StrategyDecisionMatrixCoordData, STRATEGY_DECISION_MATRIX_COORD_DATA } from "../../../../types/typesStrategy";


const initialState: StrategyDecisionMatrixCoordData = {
    x:'',
    y:''
}

const reducer = (state = initialState, action: StrategyActionsTypes): StrategyDecisionMatrixCoordData => {

    switch (action.type) {

        case STRATEGY_DECISION_MATRIX_COORD_DATA:
            const { x,y } = action.payload
            return {
                x:x?x:state.x,
                y:y?y:state.y
            };

        default:
            return state
    }
}

export default reducer