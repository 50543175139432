import React, {
  CSSProperties,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import classes from "./Panel.module.scss";

import { useDispatch, useSelector } from "react-redux";
import Select from "../../../../../components/UI/Select/CustomSelect/Select";
import { Settings } from "../../../../../components/UI/Svg/Svg";
import { ApiDebugModes, color, icons, matrix_status, messages, storage_key } from "../../../../../settings/settings";
import {
  handlerStrategyBoardPanel,
  handlerStrategyElementInput,
  handlerStrategyElementOutput,
  handlerStrategyElementOutput2,
  handleStrategyBoardSidePanelContent,
} from "../../../../../store/actions/actionsStrategy";
import { RootState } from "../../../../../store/combineReducer";
import DB from "../../../../DB/Storage";
import TooltipV2 from "../../../../../components/UI/Tooltip/TooltipV2";
import { save_contact } from "../handlers/save_contact_changes.handler";
import { executeStrategy } from "../../../../../helpers/executeStrategy";
import Checkbox from '../../../../../components/UI/Checkbox/CheckboxV3';
import { IGetRanges, IRangeObj } from "components/UI/DecisionManager/DecisionManagerRange";
import { request } from "helpers/request";
import { endpoints } from "api/endpoints";
import DecisionPropertyMatrixMapper from "components/UI/DecisionMapper/Matrix/DecisionPropertyMatrixMapper";
import DecisionPropertyMapper from "components/UI/DecisionMapper/DecisionPropertyMapper";

const Panel: React.FC = () => {
  const [enabledBtn, setEnabledBtn] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>(null);
  const [rangesData, setRangesData] = useState<Array<IRangeObj> | undefined>();
  const [prevMappingState, setPrevMappingState] = useState<any>();
  const [outputScoreId, setOutputScoreId] = useState<any>('');
  const [outputValue, setOutputValue] = useState<string>('');
  const [mappingVariable, setMappingVariable] = useState<string>('');
  const [storageData, setStoragedata] = useState<any>('');
  const [mappingState, setMappingState] = useState<any>({
    id: "",
    label: "",
    map_type: "",
    scorecard_id: "",
    mappData: [],
    decision_id: "",
  });



  let decisionRefs = useRef<any>([]);
  const {
    reducerStrategy: {
      StrategyBoardElements: {
        BoardElements: { board_elements },
        ElementOutput: { element_output },
        ElementOutput2: { element_output2 },
        ElementInput: { element_input },
      },
      StrategyBoardSidePanel: {
        Content: { content_value },
      },
    },
  } = useSelector((state: RootState) => state);

  const {
    decision_designer: { decision_range },
  } = endpoints;

  useEffect(() => {
    setPanelConfigByType(formData);
    setMappingState({
      id: formData?.data?.id,
      label: formData?.data?.label,
      map_type: formData?.data?.map_type,
      scorecard_id: formData?.data?.scorecard_id,
      mappData: formData?.data?.mappData,
    })
  }, [formData])

  const dispatch = useDispatch();

  const [tooltipState, setTooltipState] = useState<any>({
    visible: false,
    data: undefined,
    bgColor: undefined,
    txtColor: undefined,
    topMessage: undefined,
    bottomMessage:
      "This notification may show reduced result. For full result please choose Debug mode.",
  });

  const [labelState, setLabelState] = useState<any>({
    previous: "",
    present: "",
  });

  let initialState: any;
  const db = new DB(storage_key);

  useEffect(() => {
    db.fetchAll()
      .then(data => setStoragedata(data))
  }, [element_input])

  const getRanges = ({ endpoint }: IGetRanges) =>
    new Promise((resolve) => {
      request
        .get(endpoint)
        .then((res) => {
          const { success, response } = res;

          if (success) {
            resolve(response);
          } else {
            console.warn(messages.messageApiGetStatusFalse, res);
          }
        })
        .catch((error) => {
          console.error(messages.messageApiGetError, error);
        });
    });




  useLayoutEffect(() => {
    let form = document.querySelector("#form") as HTMLDivElement;
    let inputs: any = form.querySelectorAll("input");


    db.fetch({ sourceId: element_input })
      .then((res: any) => {
        setFormData(res.data);
        setOutputScoreId(res?.data.targetId)
        inputs.forEach((input: any) => {
          if (input.value || input.value !== "") {
            input?.parentNode?.classList.add(classes.LabelActive);
          }
        });
        return res;
      })
      .then((result: any) => {
        const {
          status,
          data: { data },
        } = result;

        if (status) {
          initialState = Object.freeze({ ...data });
          setPrevMappingState({ ...data });
          setMappingState({ ...data });
          setLabelState({ ...labelState, present: data?.label });

          data?.decision_id &&
            getRanges({ endpoint: decision_range.get(data.decision_id) })
              .then((data: any) => {
                setRangesData(
                  data.map((rangeObj: any) => {
                    return {
                      id: rangeObj?.id,
                      label: rangeObj?.label,
                      alias: rangeObj?.alias,
                      FROM: rangeObj?.FROM,
                      TO: rangeObj?.TO,
                    };
                  })
                );
              })
              .catch(() => { });
        } else {
          console.warn(messages.messageStorageFetchStatusFalse);
        }
      })
      .catch((error) => {
        console.error(messages.messageStorageFetchError, error);
      });
  }, [content_value, element_input, element_output]);

  useEffect(() => {
    db.fetch({ sourceId: outputScoreId })
      .then((res: any) => {
        setOutputValue(res?.data?.data?.label);
      })
  }, [outputScoreId])

  useEffect(() => {
    const settings_btn = document.querySelector(
      "#panel_settings_icon"
    ) as SVGAElement;
    settings_btn.addEventListener("mouseover", (e: any) => {
      setEnabledBtn(true);
    });
    settings_btn.addEventListener("mouseleave", (e: any) => {
      setEnabledBtn(false);
    });
    return () => {
      settings_btn.removeEventListener("mouseover", (e: any) =>
        setEnabledBtn(false)
      );
      settings_btn.removeEventListener("mouseleave", (e: any) =>
        setEnabledBtn(true)
      );
    };
  }, [enabledBtn]);
  const focusInputHandler = (e: any): void => {
    let parent = e.target.parentNode;
    parent.classList.add(classes.LabelActive);
    let form = document.querySelector("#form") as HTMLDivElement;
    let inputs: any = form.querySelectorAll("input");
    inputs.forEach((input: any) => {
      if (input !== e.target) {
        if (!input.value) {
          input?.parentNode?.classList.remove(classes.LabelActive);
        }
      }
    });
  };

  const selectHandler = (option: any) => {
    dispatch(handlerStrategyElementOutput({ element_output: option }));
  };

  const closeElementPanelHandler = () => {
    dispatch(handlerStrategyBoardPanel({ element_panel: false }));
    dispatch(handlerStrategyElementInput({ element_input: null }));
    dispatch(handlerStrategyElementOutput2({ element_output2: null }));
  };

  const addDbHandler = async () => {
    if (element_input) {
      let svg = document.querySelector("#svg_board") as SVGAElement;
      let first_elements = svg.querySelectorAll(
        `path[data-contact $= "-${element_input}"]`
      ) as NodeList;
      if (first_elements.length > 0) {
        first_elements.forEach((elem: any) => {
          let elem_name: string = elem
            ?.getAttribute("data-contact")
            ?.split("-")[0];
          if (elem_name) {
            // _target_id = elem_name;
          }
        });
      }

      let element = svg.querySelector(
        `#figure[data-id = ${element_input}]`
      ) as SVGAElement;
      let output_arrows = svg.querySelectorAll(
        `path[data-contact ^= "${element_input}-"]`
      ) as NodeList;
      let input_arrows = svg.querySelectorAll(
        `path[data-contact $= "-${element_input}"]`
      ) as NodeList;

      let left: any = element.getAttribute("x");
      let top: any = element.getAttribute("y");

      save_contact(
        element_input,
        element_output,
        element_output2,
        input_arrows,
        output_arrows,
        { left: left, top: top }
      );
    }
    closeElementPanelHandler();
  };

  const select_style = {
    width: "100%",
    minHeight: "5.3vh",
    fontSize: "1.72vh",
    color: " #102946",
    wordBreak: "break-all",
    display: "block"
  } as CSSProperties;

  const generateCriteriaRow = (mapped: any) => {
    if (mapped?.length > 0) {
      return mapped.map((mapElement: any, index: number) => {
        const { decision_label, status_id, type } = mapElement;
        return decision_label !== null ? (
          <div
            ref={(element) => {
              decisionRefs.current[index] = element;
            }}
            key={index}
            id={`${index}`}
            className={classes.Row}
          >
            {type === "range" &&
              color.map((element: any, index: number) => {
                const { id, color } = element;
                if (decision_label.charAt(0) === id)
                  return (
                    <span
                      key={index}
                      className={classes.Cell}
                      style={{ backgroundColor: `${color}` }}
                    >
                      {decision_label}
                    </span>
                  );
                else return null;
              })}
            {type === "matrix" &&
              matrix_status.map((element: any, index: number) => {
                const { id, color } = element;

                if (status_id === id)
                  return (
                    <span
                      key={index}
                      className={classes.Cell}
                      style={{ backgroundColor: `${color}` }}
                    >
                      {decision_label}
                    </span>
                  );
                else return null;
              })}
          </div>
        ) : (
          <div key={index} className={classes.Row}>
            No data to display
          </div>
        );
      });
    } else {
      return null;
    }
  }

  const generateItemsRow = (mapped: any) => {
    if (mapped?.length > 0) {
      return mapped.map((mapElement: any, index: number) => {
        const { product_group } = mapElement;
        return (
          product_group !== null && (
            <div
              key={index}
              id={`${index}`}
              className={classes.Row}
              title={
                product_group === ""
                  ? "Must be filled with item"
                  : product_group
              }
            >
              {product_group}
            </div>
          )
        );
      });
    } else {
      return null;
    }
  }

  const setIcon = (type: string) => {
    const imageElem = document.querySelector('#propertyIcon')! as HTMLElement;
    if (type === 'product') {
      imageElem?.setAttribute('src', `${icons.sidePanelProductProperty}`);
    } else if (type === 'condt') {
      imageElem?.setAttribute('src', `${icons.sidePanelConditionArrows}`);
    } else if (type === 'decision') {
      imageElem?.setAttribute('src', `${icons.sidePanelVectorDesicion}`);
    } else if (type === 'scorecard') {
      imageElem?.setAttribute('src', `${icons.sidePanelMappingScoreCard}`);
    } else if (type === 'func') {
      imageElem?.setAttribute('src', `${icons.sidePanelfunctionPropery}`);
    } else if (type === 'datawizard') {
      imageElem?.setAttribute('src', `${icons.sidePanelPropertyCheckbox}`);
    }
    else return undefined;
  }

  const getType = (data: any) => {
    if (Array.isArray(data)) {
      return data[0]?.type
    }
    return data?.type
  }

  const setPanelConfigByType = (formData: any) => {
    let type = getType(formData);
    setIcon(type);
    let menuTitleElem = document.querySelector('#menuTitle')! as HTMLElement;
    let variableFormInput = document.querySelector('#variableFormInput')! as HTMLElement;
    let bodyFormInput = document.querySelector('#bodyFormInput')! as HTMLElement;
    let decisionList = document.querySelector('#decisionList')! as HTMLElement;
    let productsList = document.querySelector('#productsList')! as HTMLElement;
    let menuTitleList = document.querySelector('#menuTitleList')! as HTMLElement;
    let menuList = document.querySelector('#menuList')! as HTMLElement;
    let scoreCardList = document.querySelector('#scoreCardList')! as HTMLElement;
    let checkboxWrapper = document.querySelector('#checkboxWrapper')! as HTMLElement;
    let decisionMappingState = document.querySelector('#decisionMappingState')! as HTMLElement;
    let panelMenu = document.querySelector('#panelMenu')! as HTMLElement;
    let mappingVariable = document.querySelector('#mappingVariable')! as HTMLElement;
    let inputNameValue = document.querySelector('#input_name')! as HTMLInputElement;

    decisionList.style.display = 'none';
    productsList.style.display = 'none';
    variableFormInput.style.display = 'none';
    bodyFormInput.style.display = 'none';
    menuList.style.display = 'none';
    scoreCardList.style.display = 'none';
    scoreCardList.style.display = 'none';
    checkboxWrapper.style.display = 'none';
    if (mappingVariable) {
      mappingVariable.style.display = 'none';
    }

    decisionMappingState.style.display = 'none';
    menuTitleElem.textContent = null;
    panelMenu.style.display = 'block';

    if (type === 'product') {
      menuTitleElem.textContent = 'PRODUCT MAP';
      decisionList.style.display = 'block';
      productsList.style.display = 'block';
    } else if (type === 'decision') {
      menuTitleElem.textContent = formData.data.data_type === 'matrix' ? 'DECISION MATRIX' : null;
      decisionMappingState.style.display = 'block';
      panelMenu.style.display = 'none';
      if (mappingVariable) {
        mappingVariable.style.display = formData.data.data_type === 'matrix' ? 'none' : 'block';
      }

    } else if (type === 'scorecard') {
      menuTitleElem.textContent = 'SCORECARD MAP';
      scoreCardList.style.display = 'block';
    } else if (type === 'func') {
      checkboxWrapper.style.display = 'block';
      variableFormInput.style.display = 'block';
      menuList.style.display = 'block';
      menuTitleList.textContent = `Function body`;
    } else if (type === 'condt') {
      menuList.style.display = 'block';
      inputNameValue.value = formData.length > 0 ? formData[0]?.data?.label : null;
      menuTitleList.textContent = `If (Conditional case)`;
    } else {
      return null;
    };
  }


  const getInputData = (formData: any) => {
    if (formData?.length > 0) {
      return formData[0].sourceId;
    } else if (formData?.sourceId === 'datawizard') {
      return formData.sourceId;
    }
    return formData?.data?.label;
  }
  const getOutputYesAndNoData = (storageData: any, element_input: any): any => {
    let contactElem: string[] = [];
    let obj = {}
    if (formData?.length > 0) {
      formData.map((item: any) => {
        let result = item?.overlays[0]?.contact?.split('-')[1];
        contactElem.push(result);
        obj = {
          'outputYes': storageData?.data?.find((item: any) => item.sourceId === contactElem[0])?.data?.label,
          'outputNo': storageData?.data?.find((item: any) => item.sourceId === contactElem[1])?.data?.label,
          'defaultYes': contactElem[0],
          'defaultNo': contactElem[1]
        }
      })
    }
    return obj;
  }


  const generateConditionBody = (data: any) => {
    let conditionData = data && data[0]?.data.condtBody;
    let conditionRow = conditionData?.slice(10);
    return (
      <>
        {conditionRow}
      </>
    )
  }

  const generateFunctionBody = (data: any) => {
    let functionData = data && data?.data?.funcTxt;
    let functionDetails: string[] = functionData?.split(',');
    let functionRow = functionDetails?.map(str => str?.replace('root.', '')).join(',');
    return functionRow?.startsWith('ITERATE') ? 'SELF' : functionRow;
  }

  const getIterateBodyData = (data: any) => {
    let iterateData = data && data.data.arrayInput.input;
    return iterateData.startsWith('root') ? iterateData.slice(5) : iterateData;
  }

  const generateScordcardItemsRow = (mapState: any) => {
    if (mapState) {
      return mapState.mappData?.map((item: any, index: number) => {
        let { paramLabel } = item;
        paramLabel = paramLabel?.startsWith('$UserData') ? paramLabel.slice(10) : paramLabel;
        return (
          <div className={classes.ScoreCardListRowWrapper} key={index}>
            <div className={classes.ConnectionIcon}>
              <img src={icons.connectionIcon} alt="connection icon" />
            </div>
            <div className={classes.ScoreCardListRow}>
              <p className={classes.ScordCardListTitle}>{item.scLabel}</p>
              <p className={classes.ScordCardListTitle}>{paramLabel}</p>
            </div>
          </div>
        )
      })
    } else {
      <></>
    }
  }

  const onClickMode = () => {
    if (element_input === "datawizard") {
      dispatch(
        handleStrategyBoardSidePanelContent({
          content_value: "data_wizard",
        })
      )
    }
    else {
      dispatch(
        handleStrategyBoardSidePanelContent({
          content_value: element_input?.split("_")[0],
        })
      )
    }
  }

  const setMappedVariableData = (value: any) => {
    setTimeout(() => { setMappingVariable(value) }, 0)
  }
  return (
    <div className={classes.Panel}>
      <div className={classes.Header}>
        <div onClick={closeElementPanelHandler} className={classes.CloseBtn}>
          <img className={classes.PlayIcon} src={icons.closeBtn} alt="icon" />
        </div>

        <div className={classes.ProductProperyIconWrapper}>

          <img src={``} id='propertyIcon' className={classes.ProductProperyIcon} alt="icon" />
          <div className={classes.LeftSide}>PROPERTIES</div>
          <div id="right_side" className={classes.RightSide}>
            <TooltipV2
              visible={tooltipState?.visible}
              onVisible={(value: boolean) =>
                setTooltipState({
                  ...tooltipState,
                  visible: value,
                  data: undefined,
                })
              }
              data={tooltipState?.data}
              topMessage={tooltipState?.topMessage}
              bottomMessage={tooltipState?.bottomMessage}
              backgroundColor={tooltipState?.bgColor}
              textColor={tooltipState?.txtColor}
              child={formData?.sourceId === 'datawizard'
                ? <></>
                :
                <img
                  className={classes.PlayIcon}
                  src={icons.strategyPlay}
                  alt="icon"
                  onClick={() =>
                    executeStrategy({
                      state: tooltipState,
                      setState: setTooltipState,
                      activeElementId: element_input,
                      debugMode: ApiDebugModes.BREAKPOINT
                    })
                  }
                />
              }
            />
            <span className={classes.SpacerNormalHorizontal}></span>
            <span
              id="settings"
              onClick={() => onClickMode()}
            >
              <Settings active={enabledBtn} id="panel_settings_icon" />
            </span>
          </div>
        </div>
      </div>
      <div id="form" className={classes.FormBody}>
        <div className={classes.FormInput}>
          <label htmlFor="input_name" className={classes.label}>Name</label>
          <input
            disabled
            onFocus={focusInputHandler}
            type="text"
            id="input_name"
            value={formData?.data?.label || element_input}

          />
        </div>
        {formData?.data?.data_type === 'matrix'
          ? null
          :
          <div className={classes.FormInput} id='mappingVariable'>
            <span className={classes.label}>Mapping Variable</span>
            <input
              disabled
              onFocus={focusInputHandler}
              type="text"
              id="input_name"
              defaultValue={mappingVariable}
            />
          </div>
        }
        <div className={classes.MenuTitle} id="menuTitle"></div>
        <div className={classes.FormInput} id="variableFormInput">
          <label htmlFor="input_variable1">Variable</label>
          <input
            disabled
            onFocus={focusInputHandler}
            type="text"
            id="input_variable1"
            defaultValue={formData?.data?.resultVar}
          />
        </div>
        <div className={classes.FormInput} id="bodyFormInput">
          <label htmlFor="input_variable2">Body</label>
          <input
            disabled
            onFocus={focusInputHandler}
            type="text"
            id="input_variable2"
            defaultValue=""
          />
        </div>
        <div className={classes.PanelMenu} id="panelMenu">
          <div className={classes.ProductsListWrapper}>
            <div className={classes.DecisionsList} id="decisionList">
              {generateCriteriaRow(formData?.data?.mappData)}
            </div>
            <div className={classes.ProductsList} id="productsList">
              {generateItemsRow(formData?.data?.mappData)}
            </div>
          </div>
          <div className={classes.ScoreCardList} id="scoreCardList">
            {generateScordcardItemsRow(mappingState)}
          </div>
          <div className={classes.MenuList} id="menuList">
            <p className={classes.MenuListTitle} id="menuTitleList"></p>
            <div className={classes.MenuListContent}>
              {generateConditionBody(formData)}
              {generateFunctionBody(formData)}
            </div>
          </div>
        </div>
        {mappingState.data_type === 'matrix'
          ?
          <div className={classes.DecisionMatrixList} id="decisionMappingState">
            <DecisionPropertyMatrixMapper data={formData?.data} />
          </div>
          :
          <div className={classes.DecisionRangeList} id="decisionMappingState">
            <DecisionPropertyMapper
              setMappingVariable={setMappedVariableData}
              generateCriteriaRow={() => generateCriteriaRow(formData?.data)}
              mappingData={formData?.data}
              validate={{
                decision_id: false,
                label: false,
                map_obj: false
              }}
              decisionRanges={rangesData}
              getMappedVariable={() => { }}
              prevMappedVariable={mappingState?.map_obj}
            />
          </div>
        }
        <div id="checkboxWrapper" className={classes.CheckboxWrapper}>
          <Checkbox
            section={"Iterate"}
            isIterate={formData?.data?.funcTxt?.includes('ITERATE')}
          />
        </div>
        {formData?.data?.funcTxt?.includes('ITERATE')
          ? <div className={classes.IterateBody}>
            <p className={classes.IterateTitle} id="menuTitleList">Iterate element</p>
            {getIterateBodyData(formData)}
          </div>
          : <></>}
        <div className={classes.FormInput}>
          <span className={classes.label}>{formData?.data?.data_type === 'range' ? 'Source' : 'Input'}</span>
          <input
            onFocus={focusInputHandler}
            defaultValue={getInputData(formData)}
            disabled
          />
        </div>
        {element_input?.split("_")[0] !== "condition" && (
          <div className={classes.FormInput}>
            <span className={classes.label}>{formData?.data?.data_type === 'range' ? 'Target' : 'Output'}</span>
            <Select
              options={board_elements}
              input={element_input}
              handler={selectHandler}
              defaultValue={outputValue?.split(',').map(str => str?.replace('root.', '')).join(',') || element_output}
              style={{ ...select_style }}
            />
          </div>
        )}
        {element_input?.split("_")[0] === "condition" && (
          <>
            <div className={classes.FormInput}>
              <span className={classes.label}>Output( Yes )</span>

              <input
                onFocus={focusInputHandler}
                defaultValue={getOutputYesAndNoData(storageData, element_input).outputYes || getOutputYesAndNoData(storageData, element_input).defaultYes}
                type="text"
                disabled
              />
            </div>
            <div className={classes.FormInput}>
              <span className={classes.label}>Output( No )</span>
              <input
                onFocus={focusInputHandler}
                defaultValue={getOutputYesAndNoData(storageData, element_input).outputNo || getOutputYesAndNoData(storageData, element_input).defaultNo}
                type="text"
                disabled
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default Panel;
