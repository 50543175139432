import React, { FC, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./Table.module.scss";
import { icons } from "../../../settings/settings";
import Tooltip from "../../../components/UI/Tooltip/Tooltip";
import {
  activeModal as handleActiveModal,
  handleActiveTooltip,
  touchBlocked,
} from "../../../store/actions/action";
import {
  handleScoreCardCategoryData,
  handleScoreCardLevel,
  handleScoreCardLevelDataId,
  handleScoreCardBlockedModalResponse,
  handleScoreCardActiveElement,
  handleScoreCardActiveRow,
  removeScoreCardNewRow,
  handleScoreCardData,
  handleScoreCardRowEditableStatus,
  handleActiveTree,
  handleScoreCardRecoveryData,
} from "../../../store/actions/actionsScoreCard";
import { RootState } from "../../../store/combineReducer";
import { request } from "../../../helpers/request";
import { endpoints } from "../../../api/endpoints";
// import { NextArrowRight } from "../../../components/UI/Svg/Svg";

const ScoreCardLevel: FC = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const {
    reducer: {
      mainTooltip: { childId, type },
    },
    reducerScoreCard: {
      scoreCardData: { scoreCardData },
      scoreCardCategoryData: { scoreCardCategoryData },
      scoreCardLevel: { level },
      scoreCardActiveRow: { rowIndex, rowType, rowId },
      scoreCardActiveElement,
      scoreCardRecoveryData: { recoveryData },
      scoreCardBlockedModalResponse: { response },
    },
  } = state;

  const refreshHandlerCallback = useCallback(() => {
    const {
      score_card: { score_card },
    } = endpoints;

    request
      .get(score_card.get())
      .then((result) => {
        const { response } = result;
        dispatch(handleScoreCardData({ scoreCardData: response }));
      })
      .catch((error) => {
        console.log("error " + error);
      });

    dispatch(
      handleScoreCardActiveRow({ rowIndex: null, rowType: null, rowId: null })
    );
    dispatch(handleScoreCardActiveElement({ name: null }));
    dispatch(
      handleActiveTooltip({ childId: null, childDataId: null, type: null })
    );
    dispatch(touchBlocked({ status: false }));
  }, [dispatch]);

  const applyHandlerCallback = useCallback(() => {
    const {
      score_card: { score_card },
    } = endpoints;

    let newData = scoreCardData?.find(
      (element: any) => element.newRow === true
    );
    let updatedData = scoreCardData?.find(
      (element: any) => element.editable === true && element.newRow === false
    );

    if (newData && !newData.name) {
      dispatch(
        handleActiveTooltip({
          childId: `card-name-input-${rowIndex}`,
          childDataId: null,
          type: "validation",
        })
      );
      dispatch(handleScoreCardBlockedModalResponse({ response: null }));
    }

    if (newData && newData.name && type !== "validation") {
      dispatch(
        handleActiveTooltip({ childId: null, childDataId: null, type: null })
      );

      request
        .post(score_card.post, newData)
        .then(() => {
          refreshHandlerCallback();
        })
        .catch((error) => {
          console.log("error ", error);
        });
    }

    if (updatedData) {
      request
        .patch(score_card.patch(updatedData.id), updatedData)
        .then(() => {
          refreshHandlerCallback();
        })
        .catch((error) => {
          console.log("error ", error);
        });
    }
  }, [dispatch, rowIndex, type, refreshHandlerCallback, scoreCardData]);

  const cancelHandlerCallback = useCallback(
    (rowType: string | null, rowId: number | null, level: number) => {
      if (rowType === "new") {
        dispatch(removeScoreCardNewRow(level));
      }

      if (rowType === "edit") {
        let specificData = scoreCardData?.find(
          (element: any) => element.id === rowId
        );
        let copyState = [...scoreCardData];
        let indexOfSpecificData = copyState.indexOf(specificData);

        copyState[indexOfSpecificData] = recoveryData;
        dispatch(handleScoreCardData({ scoreCardData: copyState }));
        dispatch(handleScoreCardRowEditableStatus(level, rowId, false));
      }

      if (rowType !== null) {
        dispatch(
          handleActiveTooltip({ childId: null, childDataId: null, type: null })
        );
        dispatch(
          handleScoreCardActiveRow({
            rowIndex: null,
            rowType: null,
            rowId: null,
          })
        );
        dispatch(handleScoreCardActiveElement({ name: null }));
        dispatch(handleScoreCardRecoveryData({ recoveryData: null }));
        dispatch(handleScoreCardBlockedModalResponse({ response: null }));
        dispatch(touchBlocked({ status: false }));
      }
    },
    [dispatch, recoveryData, scoreCardData]
  );

  const keyboardHandlerCallback = useCallback(
    (event: any) => {
      const { keyCode } = event;

      if (keyCode === 27) {
        cancelHandlerCallback(rowType, rowId, level);
      }

      if (keyCode === 13) {
        applyHandlerCallback();
      }
    },
    [level, rowType, rowId, applyHandlerCallback, cancelHandlerCallback]
  );

  const mouseClickHandlerCallback = useCallback(
    (event: any) => {
      const {
        target: { innerText, localName, id },
      } = event;
      if (
        type === "moreMenu" &&
        innerText !== "Delete" &&
        localName !== "button"
      ) {
        dispatch(
          handleActiveTooltip({ childId: null, childDataId: null, type: null })
        );
      }

      if (localName === "img" && id === "score_blocked_modal_close") {
        dispatch(handleScoreCardActiveElement({ name: null }));
        dispatch(
          handleScoreCardActiveRow({
            rowIndex: null,
            rowType: null,
            rowId: null,
          })
        );
      }
    },
    [dispatch, type]
  );

  useEffect(() => {
    window.addEventListener("click", mouseClickHandlerCallback);
    window.addEventListener("keydown", keyboardHandlerCallback);

    return () => {
      window.removeEventListener("click", mouseClickHandlerCallback);
      window.removeEventListener("keydown", keyboardHandlerCallback);
    };
  }, [type, dispatch, keyboardHandlerCallback, mouseClickHandlerCallback]);

  useEffect(() => {
    if (response === "save") {
      document.getElementById(`score-apply-${rowIndex}`)?.click();
    }

    if (response === "cancel") {
      document.getElementById(`score-cancel-${rowIndex}`)?.click();
    }

    if (response === "delete") {
      document.getElementById(`delete-${rowIndex}`)?.click();
    }

    if (response === "cancel_delete") {
      dispatch(handleScoreCardActiveElement({ name: null }));
      dispatch(handleScoreCardBlockedModalResponse({ response: null }));
      dispatch(
        handleScoreCardActiveRow({ rowIndex: null, rowType: null, rowId: null })
      );
      dispatch(
        handleActiveTooltip({ childId: null, childDataId: null, type: null })
      );
    }
  }, [dispatch, response, rowIndex]);

  const numberTopArrowHandler = (
    id: number,
    inputName: string,
    elementId: string
  ) => {
    dispatch(handleScoreCardActiveElement({ name: elementId }));
    dispatch(touchBlocked({ status: true }));

    let specificData = scoreCardData.find((element: any) => element.id === id);
    let copyState = [...scoreCardData];
    let copyOfSpecificData = { ...specificData };
    let indexOfSpecificData = copyState.indexOf(specificData);

    if (inputName === "min-score")
      copyOfSpecificData.min_score = `${+copyOfSpecificData.min_score + 1}`;
    if (inputName === "max-score")
      copyOfSpecificData.max_score = `${+copyOfSpecificData.max_score + 1}`;

    copyState[indexOfSpecificData] = copyOfSpecificData;
    dispatch(handleScoreCardData({ scoreCardData: copyState }));
  };

  const numberBottomArrowHandler = (
    id: number,
    inputName: string,
    elementId: string
  ) => {
    dispatch(handleScoreCardActiveElement({ name: elementId }));
    dispatch(touchBlocked({ status: true }));

    let specificData = scoreCardData.find((element: any) => element.id === id);
    let copyState = [...scoreCardData];
    let copyOfSpecificData = { ...specificData };
    let indexOfSpecificData = copyState.indexOf(specificData);

    if (inputName === "min-score")
      copyOfSpecificData.min_score = `${
        +copyOfSpecificData.min_score > 1
          ? +copyOfSpecificData.min_score - 1
          : "0"
      }`;
    if (inputName === "max-score")
      copyOfSpecificData.max_score = `${
        +copyOfSpecificData.max_score > 1
          ? +copyOfSpecificData.max_score - 1
          : "0"
      }`;

    copyState[indexOfSpecificData] = copyOfSpecificData;
    dispatch(handleScoreCardData({ scoreCardData: copyState }));
  };

  const nextBtnHandler = (id: number | null, criteriaType: number | null) => {
    const {
      score_card: { category },
    } = endpoints;

    if (scoreCardActiveElement.name === null && type === null) {
      dispatch(
        handleScoreCardLevelDataId({
          scoreCardId: id,
          categoryId: null,
          criteriaId: null,
          criteriaType,
        })
      );
      if (scoreCardCategoryData === null && id !== null) {
        request
          .get(category.getAll(id))
          .then((result) => {
            const { response } = result;

            dispatch(
              handleScoreCardCategoryData({ scoreCardCategoryData: response })
            );

            dispatch(handleScoreCardLevel({ level: 2 }));
          })
          .catch((error) => {
            console.log("error " + error);
          });
      }

      if (scoreCardCategoryData !== null && id !== null) {
        let childData = scoreCardCategoryData.filter(
          (element: any) => element.score_card_id === id
        );
        if (childData.length === 0) {
          request
            .get(category.getAll(id))
            .then((result) => {
              const { response } = result;
              let combinedData = scoreCardCategoryData.concat(response);
              dispatch(
                handleScoreCardCategoryData({
                  scoreCardCategoryData: combinedData,
                })
              );
              dispatch(handleScoreCardLevel({ level: 2 }));
            })
            .catch((error) => {
              console.log("error " + error);
            });
        }  
        if (childData.length !== 0) {
          dispatch(handleScoreCardLevel({ level: 2 }));
        }
      }

      let score_card_data = scoreCardData?.filter((elem: any) => {
        return +elem?.id === id;
      });

      dispatch(handleActiveTree({ activeTree: score_card_data }));
    }

    if (scoreCardActiveElement.name !== null && type !== "validation") {
      if (type === null)
        dispatch(
          handleActiveTooltip({
            childId: scoreCardActiveElement.name,
            childDataId: null,
            type: "saveChanges",
          })
        );
      if (type === "saveChanges") {
        dispatch(
          handleActiveTooltip({ childId: null, childDataId: null, type: null })
        );
        setTimeout(() => {
          dispatch(
            handleActiveTooltip({
              childId: scoreCardActiveElement.name,
              childDataId: null,
              type: "saveChanges",
            })
          );
        }, 50);
      }
    }
  };

  const moreHandler = (event: any, child: string) => {
    event.stopPropagation();

    if (type === null && scoreCardActiveElement.name === null) {
      dispatch(
        handleActiveTooltip({
          childId: child,
          childDataId: null,
          type: "moreMenu",
        })
      );
    }

    if (type === "moreMenu" && childId === child) {
      dispatch(
        handleActiveTooltip({ childId: null, childDataId: null, type: null })
      );
    }

    if (type === "moreMenu" && childId !== child) {
      dispatch(
        handleActiveTooltip({ childId: null, childDataId: null, type: null })
      );
      setTimeout(() => {
        dispatch(
          handleActiveTooltip({
            childId: child,
            childDataId: null,
            type: "moreMenu",
          })
        );
      }, 50);
    }

    if (scoreCardActiveElement.name !== null && type !== "validation") {
      if (type === null)
        dispatch(
          handleActiveTooltip({
            childId: scoreCardActiveElement.name,
            childDataId: null,
            type: "saveChanges",
          })
        );
      if (type === "saveChanges") {
        dispatch(
          handleActiveTooltip({ childId: null, childDataId: null, type: null })
        );
        setTimeout(() => {
          dispatch(
            handleActiveTooltip({
              childId: scoreCardActiveElement.name,
              childDataId: null,
              type: "saveChanges",
            })
          );
        }, 50);
      }
    }
  };

  const editHandler = (index: number, id: number, level: number) => {
    if (
      scoreCardActiveElement.name === null &&
      type === "moreMenu" &&
      rowType !== null
    ) {
      dispatch(handleScoreCardRowEditableStatus(level, rowId, false));
      if (rowType === "new") dispatch(removeScoreCardNewRow(level));
      dispatch(
        handleScoreCardActiveRow({
          rowIndex: index,
          rowType: "edit",
          rowId: id,
        })
      );
      dispatch(handleScoreCardRowEditableStatus(level, id, true));

      let specificData = scoreCardData?.find(
        (element: any) => element.id === id
      );

      dispatch(handleScoreCardRecoveryData({ recoveryData: specificData }));
    }

    if (
      scoreCardActiveElement.name === null &&
      type === "moreMenu" &&
      rowType === null
    ) {
      dispatch(handleScoreCardRowEditableStatus(level, id, true));

      let specificData = scoreCardData?.find(
        (element: any) => element.id === id
      );

      dispatch(handleScoreCardRecoveryData({ recoveryData: specificData }));
      dispatch(
        handleScoreCardActiveRow({
          rowIndex: index,
          rowType: "edit",
          rowId: id,
        })
      );
    }
  };

  const deleteHandler = (index: number, id: number, elementId: string) => {
    let tooltipElement = document.getElementById(`${childId}`)?.parentNode
      ?.lastChild as HTMLElement;

    tooltipElement.style.display = "none";
    const {
      score_card: { score_card },
    } = endpoints;
    dispatch(handleScoreCardActiveElement({ name: elementId }));
    dispatch(
      handleScoreCardActiveRow({
        rowIndex: index,
        rowType: "delete",
        rowId: id,
      })
    );

    if (response === null) {
      dispatch(handleActiveModal({ activeModal: "delete_score_modal" }));
    }
    if (response === "delete") {
      dispatch(handleScoreCardBlockedModalResponse({ response: null }));
      request
        .delete(score_card.delete(id))
        .then(() => {
          refreshHandlerCallback();
        })
        .catch((error) => {
          console.log("error ", error);
        });
    }
  };

  const nameHandler = (value: string, id: number, elementId: string) => {
    dispatch(handleScoreCardActiveElement({ name: elementId }));
    dispatch(touchBlocked({ status: true }));

    let specificData = scoreCardData.find((element: any) => element.id === id);
    let copyState = [...scoreCardData];
    let copyOfSpecificData = { ...specificData };
    let indexOfSpecificData = copyState.indexOf(specificData);

    copyOfSpecificData.name = value;

    copyState[indexOfSpecificData] = copyOfSpecificData;
    dispatch(handleScoreCardData({ scoreCardData: copyState }));

    if (value && type === "validation") {
      dispatch(
        handleActiveTooltip({ childId: null, childDataId: null, type: null })
      );
    }
    if (!value) {
      dispatch(
        handleActiveTooltip({
          childId: elementId,
          childDataId: null,
          type: "validation",
        })
      );
    }
  };

  const minScoreHandler = (value: string, id: number, elementId: string) => {
    dispatch(handleScoreCardActiveElement({ name: elementId }));
    dispatch(touchBlocked({ status: true }));

    let specificData = scoreCardData.find((element: any) => element.id === id);
    let copyState = [...scoreCardData];
    let copyOfSpecificData = { ...specificData };
    let indexOfSpecificData = copyState.indexOf(specificData);

    copyOfSpecificData.min_score =
      +value >= 0
        ? value.indexOf(".") > -1
          ? value.substring(0, value.indexOf(".") + 3)
          : value
        : "";

    copyState[indexOfSpecificData] = copyOfSpecificData;
    dispatch(handleScoreCardData({ scoreCardData: copyState }));
  };

  const maxScoreHandler = (value: string, id: number, elementId: string) => {
    dispatch(handleScoreCardActiveElement({ name: elementId }));
    dispatch(touchBlocked({ status: true }));

    let specificData = scoreCardData.find((element: any) => element.id === id);
    let copyState = [...scoreCardData];
    let copyOfSpecificData = { ...specificData };
    let indexOfSpecificData = copyState.indexOf(specificData);

    copyOfSpecificData.max_score =
      +value >= 0
        ? value.indexOf(".") > -1
          ? value.substring(0, value.indexOf(".") + 3)
          : value
        : "";

    copyState[indexOfSpecificData] = copyOfSpecificData;
    dispatch(handleScoreCardData({ scoreCardData: copyState }));
  };

  const descriptionHandler = (value: string, id: number, elementId: string) => {
    dispatch(handleScoreCardActiveElement({ name: elementId }));
    dispatch(touchBlocked({ status: true }));

    let specificData = scoreCardData.find((element: any) => element.id === id);
    let copyState = [...scoreCardData];
    let copyOfSpecificData = { ...specificData };
    let indexOfSpecificData = copyState.indexOf(specificData);

    copyOfSpecificData.description = value;
    copyState[indexOfSpecificData] = copyOfSpecificData;
    dispatch(handleScoreCardData({ scoreCardData: copyState }));
  };

  return (
    <div className={classes.FirstLevel}>
      <div className={classes.Head}>
        <span className={classes.HeadColumn}>Card name</span>
        <span className={classes.HeadColumn}>Card min score</span>
        <span className={classes.HeadColumn}>Card max score</span>
        <span className={classes.HeadColumn}>Card description</span>
        <span className={classes.HeadColumn}></span>
      </div>
      {scoreCardData && scoreCardData.length > 0 ? (
        scoreCardData
        .map((element: any, index: number) => {
          const {
            id,
            name,
              description,
              min_score,
              max_score,
              editable,
              newRow,
            } = element;          
            let minScoreTrimmed =
            min_score.indexOf(".") > -1
                ? min_score.substring(0, min_score.indexOf(".") + 3)
                : min_score;
            let maxScoreTrimmed =
              max_score.indexOf(".") > -1
                ? max_score.substring(0, max_score.indexOf(".") + 3)
                : max_score;

                return (
                  <div className={classes.Row} key={id ?? 0} id={`table_row_${id}`}>
                  <div className={classes.Column}>
                  {editable ? (
                    <Tooltip
                      children={
                        <input
                          id={`card-name-input-${index}`}
                          type="text"
                          tabIndex={1}
                          defaultValue={name}
                          onChange={(event) =>
                            nameHandler(
                              event.target.value,
                              id,
                              `card-name-input-${index}`
                            )
                          }
                          onKeyDown={(e) => {
                            if (!e.key.match(/[a-zA-Z0-9_]/)) {
                              e.preventDefault();
                            }
                          }}
                          onPaste={(e) => {e.preventDefault(); return false;}}
                          onDrop={(e) => {e.preventDefault(); return false;}}
                          autoComplete="off"
                          disabled={!editable}
                        />
                      }
                    />
                  ) : (
                    <span
                      className={classes.NameLink}
                      onDoubleClick={() => nextBtnHandler(id, null)}
                    >
                      {name}
                    </span>
                  )}
                </div>
                <div className={classes.Column}>
                  <Tooltip
                    children={
                      <input
                        type="number"
                        tabIndex={2}
                        id={`min-score-${index}`}
                        value={minScoreTrimmed}
                        onChange={(event) =>
                          minScoreHandler(
                            event.target.value,
                            id,
                            `min-score-${index}`
                          )
                        }
                        disabled={!editable || type === "validation"}
                      />
                    }
                  />
                  <button
                    className={classes.ArrowTop}
                    onClick={() =>
                      numberTopArrowHandler(
                        id,
                        "min-score",
                        `min-score-${index}`
                      )
                    }
                    disabled={!editable || type === "validation"}
                  >
                    <img src={icons.arrowTop} alt="Arror top icon" />
                  </button>
                  <button
                    className={classes.ArrowBottom}
                    onClick={() =>
                      numberBottomArrowHandler(
                        id,
                        "min-score",
                        `min-score-${index}`
                      )
                    }
                    disabled={!editable || type === "validation"}
                  >
                    <img src={icons.arrowBottom} alt="Arror bottom icon" />
                  </button>
                </div>
                <div className={classes.Column}>
                  <Tooltip
                    children={
                      <input
                        type="number"
                        id={`max-score-${index}`}
                        tabIndex={3}
                        value={maxScoreTrimmed}
                        onChange={(event) =>
                          maxScoreHandler(
                            event.target.value,
                            id,
                            `max-score-${index}`
                          )
                        }
                        disabled={!editable || type === "validation"}
                      />
                    }
                  />
                  <button
                    className={classes.ArrowTop}
                    onClick={() =>
                      numberTopArrowHandler(
                        id,
                        "max-score",
                        `max-score-${index}`
                      )
                    }
                    disabled={!editable || type === "validation"}
                  >
                    <img src={icons.arrowTop} alt="Arror top icon" />
                  </button>
                  <button
                    className={classes.ArrowBottom}
                    onClick={() =>
                      numberBottomArrowHandler(
                        id,
                        "max-score",
                        `max-score-${index}`
                      )
                    }
                    disabled={!editable || type === "validation"}
                  >
                    <img src={icons.arrowBottom} alt="Arror bottom icon" />
                  </button>
                </div>
                <div className={classes.Column}>
                  <Tooltip
                    children={
                      <input
                        id={`card-description-${index}`}
                        type="text"
                        tabIndex={4}
                        value={description !== null ? description : ""}
                        onChange={(event) =>
                          descriptionHandler(
                            event.target.value,
                            id,
                            `card-description-${index}`
                          )
                        }
                        disabled={!editable || type === "validation"}
                      />
                    }
                  />
                </div>
                <div className={classes.Actions}>
                  {(newRow && !newRow) || !editable ? (
                    <>
                      <Tooltip
                        children={
                          <button
                            id={`moreMenu-${index}`}
                            className={classes.MoreBtn}
                            onClick={(event) =>
                              moreHandler(event, `moreMenu-${index}`)
                            }
                          >
                            <svg
                              width="18"
                              height="16"
                              viewBox="0 0 14 3"
                              fill="none"
                            >
                              <g>
                                <path
                                  d="M5.20833 1.4629C5.20833 2.27084 5.86125 2.92581 6.66667 2.92581C7.47208 2.92581 8.125 2.27084 8.125 1.4629C8.125 0.654965 7.47208 0 6.66667 0C5.86125 0 5.20833 0.654965 5.20833 1.4629Z"
                                  fill="#001237"
                                />
                                <path
                                  d="M-4.07696e-05 1.4629C-4.07696e-05 2.27084 0.652877 2.92581 1.45829 2.92581C2.26371 2.92581 2.91663 2.27084 2.91663 1.4629C2.91663 0.654965 2.26371 0 1.45829 0C0.652877 0 -4.07696e-05 0.654965 -4.07696e-05 1.4629Z"
                                  fill="#001237"
                                />
                                <path
                                  d="M10.4167 1.4629C10.4167 2.27084 11.0696 2.92581 11.875 2.92581C12.6805 2.92581 13.3334 2.27084 13.3334 1.4629C13.3334 0.654965 12.6805 0 11.875 0C11.0696 0 10.4167 0.654965 10.4167 1.4629Z"
                                  fill="#001237"
                                />
                              </g>
                            </svg>
                          </button>
                        }
                        fields={
                          <>
                            <button
                              onClick={() => editHandler(index, id, level)}
                            >
                              Edit
                            </button>
                            <button>Archive</button>
                            <button
                              id={`delete-${index}`}
                              onClick={() =>
                                deleteHandler(index, id, `delete-${index}`)
                              }
                            >
                              Delete
                            </button>
                          </>
                        }
                      />

                      <svg
                        onClick={() => nextBtnHandler(id, null)}
                        id={`nextBtn-${index}`}
                        className={classes.NextBtn}
                        viewBox="0 -3 7 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M6.80994 5.49261L1.50758 0.190349C1.38495 0.0676151 1.22124 0 1.04668 0C0.872118 0 0.708408 0.0676151 0.585771 0.190349L0.195289 0.580734C-0.0587999 0.835114 -0.0587999 1.24855 0.195289 1.50255L4.64781 5.95506L0.190349 10.4125C0.0677119 10.5353 0 10.6989 0 10.8733C0 11.048 0.0677119 11.2116 0.190349 11.3344L0.580831 11.7247C0.703565 11.8475 0.867177 11.9151 1.04174 11.9151C1.2163 11.9151 1.38001 11.8475 1.50264 11.7247L6.80994 6.41762C6.93287 6.2945 7.00039 6.13011 7 5.95536C7.00039 5.77992 6.93287 5.61563 6.80994 5.49261Z" />
                      </svg>
                    </>
                  ) : (
                    <div className={classes.ActionButtonsWrapper}>
                      <button
                        id={`score-apply-${index}`}
                        style={
                          scoreCardActiveElement.name === null ||
                          type === "validation"
                            ? { opacity: 0, pointerEvents: "none" }
                            : { opacity: 1 }
                        }
                        onClick={() => applyHandlerCallback()}
                      >
                        <svg
                          width="17"
                          height="20"
                          viewBox="0 0 10 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.115385 4.23077C0.0384615 4.15385 0 4.03846 0 3.96154C0 3.88462 0.0384615 3.76923 0.115385 3.69231L0.653846 3.15385C0.807692 3 1.03846 3 1.19231 3.15385L1.23077 3.19231L3.34615 5.46154C3.42308 5.53846 3.53846 5.53846 3.61538 5.46154L8.76923 0.115385H8.80769C8.96154 -0.0384615 9.19231 -0.0384615 9.34615 0.115385L9.88462 0.653846C10.0385 0.807692 10.0385 1.03846 9.88462 1.19231L3.73077 7.57692C3.65385 7.65385 3.57692 7.69231 3.46154 7.69231C3.34615 7.69231 3.26923 7.65385 3.19231 7.57692L0.192308 4.34615L0.115385 4.23077Z"
                            fill="#B7BFC7"
                          />
                        </svg>
                      </button>

                      <button
                        id={`score-cancel-${index}`}
                        onClick={() =>
                          cancelHandlerCallback(rowType, id, level)
                        }
                      >
                        <svg
                          width="13"
                          height="20"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.88083 4.99709L9.81083 1.06709C10.055 0.82313 10.055 0.427297 9.81083 0.183339C9.56667 -0.060828 9.17125 -0.060828 8.92708 0.183339L4.99708 4.11334L1.06687 0.183339C0.822708 -0.060828 0.427292 -0.060828 0.183125 0.183339C-0.0610417 0.427297 -0.0610417 0.82313 0.183125 1.06709L4.11333 4.99709L0.183125 8.92709C-0.0610417 9.17105 -0.0610417 9.56688 0.183125 9.81084C0.305208 9.93271 0.465208 9.99376 0.625 9.99376C0.784792 9.99376 0.944792 9.93271 1.06687 9.81063L4.99708 5.88063L8.92708 9.81063C9.04917 9.93271 9.20917 9.99376 9.36896 9.99376C9.52875 9.99376 9.68875 9.93271 9.81083 9.81063C10.055 9.56667 10.055 9.17084 9.81083 8.92688L5.88083 4.99709Z"
                            fill="#B2BDCD"
                          />
                        </svg>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            );
          })
          // .reverse()
      ) : (
        <span className={classes.TableMessage}>No data to display</span>
      )}
    </div>
  );
};;

export default ScoreCardLevel;
