import { exists_side } from "./decision_direction/decision_direction";
import { getArrowPropery } from "./get_arrow_properties.handler";
import { getAttr } from "./methods/attributes";

export const replace_side = (side: string) => {
    let arrow_side: string =
        side === 'right' ? 'left'
            : (side === 'top' ? 'bottom'
                : (side === 'bottom' ? 'top'
                    : (side === 'left' ? 'right'
                        : (side === 'right-top' ? 'bottom'
                            : (side === 'right-bottom' ? 'top'
                                : (side === 'top-right' ? 'left'
                                    : (side === 'top-left' ? 'right'
                                        : (side === 'left-top' ? 'bottom'
                                            : (side === 'left-bottom' ? 'top'
                                                : (side === 'bottom-right' ? 'left'
                                                    : (side === 'bottom-left' ? 'right'
                                                        : (side === 'right-bottom-bottom' ? 'top'
                                                            : (side === 'right-bottom-left' ? 'right'
                                                                : (side === 'right-top-left' ? 'right'
                                                                    : (side === 'left-bottom-right' ? 'left'
                                                                        : (side === 'left-top-right' ? 'left'
                                                                            : (side === 'top-right-bottom' ? 'top'
                                                                                : (side === 'bottom-right-top' ? 'bottom'
                                                                                    : (side === 'bottom-left-top' ? 'bottom'
                                                                                        : (side === 'top-left-bottom' ? 'top'
                                                                                            : side)))))))))))
                                            )
                                        )
                                    )))))));
    return arrow_side;
}


export const replace_source_side = (side:string)=>{
    
    let source_side = (
            (side === 'top' ? 'top'
                : (side === 'right' ? 'right'

                    : (side === 'bottom' ? 'bottom'
                        : (side === 'left' ? 'left'

                            : (side === 'right-top' ? 'right'
                                : (side === 'right-bottom' ? 'right'
                                    : (side === 'top-right' ? 'top'
                                        : (side === 'top-left' ? 'top'
                                            : (side === 'left-top' ? 'left'
                                                : (side === 'left-bottom' ? 'left'
                                                    : (side === 'bottom-right' ? 'bottom'
                                                        : (side === 'bottom-left' ? 'bottom'
                                                            : (side === 'right-bottom-bottom' ? 'right'
                                                                : (side === 'right-bottom-left' ? 'right'
                                                                    : (side === 'right-top-left' ? 'right'
                                                                        : (side === 'left-bottom-right' ? 'left'
                                                                            : (side === 'left-top-right' ? 'left'
                                                                                : (side === 'top-right-bottom' ? 'top'
                                                                                    : (side === 'top-left-bottom' ? 'top'
                                                                                        : (side === 'bottom-left-top' ? 'bottom' :
                                                                                            (side === 'bottom-right-top' ? 'bottom' : side))))))))))))
                                            )
                                        )
                                    ))))))))
        return source_side;
}
const getArrayList = (massive: NodeListOf<SVGPathElement>) => {
    let result: Array<string> = [];
    massive?.forEach((input: SVGPathElement) => {
        const arrow_side: any = input?.dataset?.side;
        result.push(replace_side(arrow_side));

    })
    return result;
}

const getHideSides = (massive:string[],type:string="multiple")=>{
    const constant = ['top','right','bottom','left'] as string[];

    function getArraysIntersection(mass1:string[],mass2:string[]){
        return  mass1.filter(function(n) { return type === "multiple" ? mass2.indexOf(n) === -1:mass2.indexOf(n) !== -1;});
    }
    return getArraysIntersection(constant, massive);
}

export const remove_arrow_box = (source_id: any, target_id: any, side: string, type: any,arrows:NodeListOf<SVGPathElement>|null=null) => {
    const svg_board = document.querySelector('#svg_board')! as SVGSVGElement;
    const output = svg_board?.querySelector(`path[data-contact ^= "${source_id}-"]`)! as SVGPathElement;
    const input = svg_board?.querySelector(`path[data-contact $= "-${target_id}"]`)! as SVGPathElement;
    const wizard = svg_board?.querySelector(`rect[data-id = "datawizard"]`)! as SVGRectElement;
    const validate = svg_board?.querySelector(`rect[data-id = "validate"]`)! as SVGRectElement;
    if(output){
        
    
        const sourceElement = svg_board.querySelector(`rect[data-id = "${source_id}"]`)! as SVGRectElement;
        const sourceParent = sourceElement?.parentNode as SVGGElement;
        const arrowBoxList = sourceParent?.querySelectorAll(`g[id $= "_arrow_box_group"]`)! as NodeListOf<SVGGElement>;
        const {type,id} = getAttr(sourceElement)
        if(type === "condt"){

            const outputArrows = svg_board.querySelectorAll(`path[data-contact ^= "${id}-"]`) as NodeListOf<SVGPathElement>;
            if(outputArrows.length === 2){
                const output_sides = [] as string[] 
                outputArrows?.forEach((output_arrow:SVGPathElement)=>{
                    const {side:output_side} = getArrowPropery(output_arrow);
                     output_sides.push(replace_source_side(output_side));
                     arrowBoxList?.forEach((box:SVGGElement)=>{
                         if(`${replace_source_side(output_side)}_arrow_box_group` === box.id){
                            
                            box.style.display = "block"
                         }
                     })

                })

                getHideSides(output_sides,"multiple").forEach((_side:string)=>{
                    const arrowBox = sourceParent?.querySelector(`g[id = "${_side}_arrow_box_group"]`)! as SVGGElement;
                    if(arrowBox){
                        arrowBox.style.display="none"
                    }
    
                })
               

            }else if(outputArrows.length === 1){
           
                arrowBoxList?.forEach((box:SVGGElement)=>{
                
                    box.style.display = "block"
    
                
                })

                outputArrows?.forEach((output_arrow:SVGPathElement)=>{
                    const {side} = getArrowPropery(output_arrow);
                     arrowBoxList?.forEach((box:SVGGElement)=>{
                         if(box.id === `${replace_side(side)}_arrow_box_group`){
                             box.style.display='none'
                         }
                     })

                })
               
            }
           
        }else{
            
            if((wizard && (id === "startpoint" || id === "validate")) || (!wizard && validate &&(id === "startpoint"))){
                arrowBoxList?.forEach((box:SVGGElement)=>{
                
                        box.style.display = "none"
        
                    
                })
            }else{
                arrowBoxList?.forEach((box:SVGGElement)=>{
                
                    if(box.id === `${replace_source_side(side)}_arrow_box_group`){
                        box.style.display = "block"
                    }else{
                        box.style.display = "none"
        
                    }
                })
            }
            
        }
        
    }
    if(input){
        const targetElement = svg_board.querySelector(`rect[data-id = "${target_id}"]`)! as SVGRectElement;
        const targetParent = targetElement?.parentNode as SVGGElement;
        const checkOutput = svg_board.querySelector(`path[data-contact ^= "${target_id}"]`)! as SVGPathElement;
        if(!checkOutput){

            let sides = [] as string[];
            arrows?.forEach((arrow:SVGPathElement)=>{
                const {side:_side} = getArrowPropery(arrow);
                sides.push(replace_side(_side));
            })
            sides?.forEach((_side:string)=>{
                const arrowBox = targetParent?.querySelector(`g[id = "${_side}_arrow_box_group"]`)! as SVGGElement;
                if(arrowBox){
                    arrowBox.style.display="none"
                }


            })
            if(arrows){
                getHideSides(getArrayList(arrows)).forEach((_side:string)=>{
                    const arrowBox = targetParent?.querySelector(`g[id = "${_side}_arrow_box_group"]`)! as SVGGElement;
                    if(arrowBox){
                        arrowBox.style.display = "block"
                    }
    
                })
            }
            if(output){
                exists_side(targetElement)?.forEach((_side:string)=>{
                    const arrowBox = targetParent?.querySelector(`g[id = "${_side}_arrow_box_group"]`)! as SVGGElement;

                    if(arrowBox){
                        arrowBox.style.display="none"
                    }
    
                })
            }


           

        }
        
       
    }
    
}

export const call_arrow_box_source = (arrows: NodeList) => {
    const svg = document.querySelector(`#svg_board`) as SVGElement;


    if (arrows.length !== 0) {
        arrows.forEach((arrow: any) => {
            const source_element_id = arrow.dataset.contact?.split('-')?.[0];
            const source_element = svg.querySelector(`rect[data-id = ${source_element_id}]`) as SVGRectElement;

            const find_arrows_input = svg.querySelectorAll(`path[data-contact $= ${source_element_id}]`) as NodeList;
            if (find_arrows_input.length !== 0) {
                find_arrows_input.forEach((_arrow: any) => {
                    let arrow_box_groups = source_element.parentNode?.querySelector('#arrow_box_group') as SVGGElement;
                    let box_childs_group = arrow_box_groups?.querySelectorAll(`g`);
                    box_childs_group?.forEach((child: any) => {


                        child.style.display = 'block'

                    })


                })
            }
            if (find_arrows_input.length !== 0) {
                find_arrows_input.forEach((_arrow: any) => {
                    let arrow_box_groups = source_element.parentNode?.querySelector('#arrow_box_group') as SVGGElement;
                    let box_childs_group = arrow_box_groups?.querySelectorAll(`g`);
                    box_childs_group?.forEach((child: any) => {

                        let arrow_side: string = _arrow.dataset.side === 'right' ? 'left' : (_arrow.dataset.side === 'top' ? 'bottom' : (_arrow.dataset.side === 'bottom' ? 'top' : (_arrow.dataset.side === 'left' ? 'right' : _arrow.dataset.side)));
                        if (child.id === `${arrow_side}_arrow_box_group`) {
                            child.style.display = 'none';
                        }

                    })


                })
            }


        })
    }
    if (arrows.length !== 0) {
        arrows.forEach((arrow: any) => {

            if (!svg.contains(arrow)) {
                const source_element_id = arrow.dataset.contact?.split('-')?.[0];
                const source_element = svg.querySelector(`rect[data-id = ${source_element_id}]`) as SVGRectElement;

                const find_arrows_input = svg.querySelectorAll(`path[data-contact $= ${source_element_id}]`) as NodeList;

                if (find_arrows_input.length === 0) {
                    let arrow_box_groups = source_element.parentNode?.querySelector('#arrow_box_group') as SVGGElement;
                    let box_childs_group = arrow_box_groups?.querySelectorAll(`g`);
                    box_childs_group?.forEach((child: any) => {

                        child.style.display = 'block'

                    })

                }
            }



        })
    }

}


export const call_arrow_box_target = (arrows: NodeList) => {
    const svg = document.querySelector(`#svg_board`) as SVGElement;

    if (arrows.length !== 0) {
        arrows.forEach((arrow: any) => {
            const target_element_id = arrow.dataset.contact?.split('-')?.[1];
            const target_element = svg.querySelector(`rect[data-id = ${target_element_id}]`) as SVGRectElement;

            const find_arrows_input = svg.querySelectorAll(`path[data-contact ^= ${target_element_id}]`) as NodeListOf<SVGPathElement>;
            if (find_arrows_input.length !== 0) {
                find_arrows_input.forEach((_arrow: any) => {
                    let arrow_box_groups = target_element?.parentNode?.querySelector('#arrow_box_group') as SVGGElement;
                    let box_childs_group = arrow_box_groups?.querySelectorAll(`g`);
                    box_childs_group?.forEach((child: any) => {

                        let arrow_side: string = _arrow.dataset.side === 'right' ? 'left' : (_arrow.dataset.side === 'top' ? 'bottom' : (_arrow.dataset.side === 'bottom' ? 'top' : (_arrow.dataset.side === 'left' ? 'right' : _arrow.dataset.side)));
                        if (child.id === `${arrow_side}_arrow_box_group`) {
                            child.style.display = 'none'
                        }

                    })


                })
            }




        })
    }
    if (arrows.length !== 0) {

        arrows?.forEach((arrow: any) => {
            if (!svg.contains(arrow)) {

                const target_element = svg.querySelector(`rect[data-id = ${arrow.dataset.contact?.split('-')?.[1]}]`) as SVGRectElement;

                const find_arrows_input = svg.querySelectorAll(`path[data-contact ^= ${arrow.dataset.contact?.split('-')?.[1]}]`) as NodeList;

                if (find_arrows_input.length === 0) {
                    let arrow_box_groups = target_element?.parentNode?.querySelector('#arrow_box_group') as SVGGElement;
                    let box_childs_group = arrow_box_groups?.querySelectorAll(`g`) as NodeListOf<SVGGElement>;
                    const data_id = target_element?.dataset?.id;
                    const self_inputs = svg.querySelectorAll(`path[data-contact $= "${data_id}"]`) as NodeListOf<SVGPathElement>;
                    if (self_inputs.length === 0) {
                        box_childs_group?.forEach((child: SVGGElement) => {

                            child.style.display = 'block'

                        })
                    } else {
                        const inputs_list: string[] = getArrayList(self_inputs);

                        box_childs_group?.forEach((child: SVGGElement) => {

                            const id = child.id;
                            const id_side = id?.split('_')?.[0];
                            if (!inputs_list.includes(id_side)) {
                                child.style.display = 'block'
                            }

                        })

                    }

                }
            }
        })
    }

}

export const change_arrow_box_visibility = <T extends SVGRectElement>(element: T): void => {
    const svg = document.querySelector(`#svg_board`) as SVGElement;
    const id = element?.dataset?.id;
    const inputs = svg.querySelectorAll(`path[data-contact $= "${id}"]`)! as NodeListOf<SVGPathElement>;
    const outputs = svg.querySelectorAll(`path[data-contact ^= "${id}"]`)! as NodeListOf<SVGPathElement>;
    const wizard = svg.querySelector(`rect[data-id = "datawizard"]`)! as SVGRectElement;
    const validate = svg.querySelector(`rect[data-id = "validate"]`)! as SVGRectElement;
    inputs?.forEach((input: SVGPathElement) => {
        const side = input?.dataset?.side;
        const split_side = side?.split('-') as string[];
        const [first_side] = split_side;
        const contact = input?.dataset?.contact as string;
        const split_contact = contact?.split('-') as string[];
        const [prev_id] = split_contact;
        const prev_element = svg.querySelector(`rect[data-id = "${prev_id}"]`)! as SVGRectElement;

        const prev_element_inputs = svg.querySelectorAll(`path[data-contact $= "${prev_id}"]`)! as NodeListOf<SVGPathElement>;
        let prev_inputs: any[] = []
        prev_element_inputs?.forEach((prev_element_input: SVGPathElement) => {
            let side_name = prev_element_input?.dataset.side as string;
            prev_inputs.push(replace_side(side_name))
        })
        let arrow_box_groups = prev_element?.parentNode?.querySelector('#arrow_box_group') as SVGGElement;
        let box_childs_group = arrow_box_groups?.querySelectorAll(`g`)! as NodeListOf<SVGGElement>;
        if(id === "validate" || id === "datawizard"){
            box_childs_group?.forEach((child: any) => {

                let group_id_key = child?.id?.split('_')?.[0] as string;
    
                if (group_id_key === first_side) {
                    child.style.display = 'none'
                    child.querySelector('#circle')?.removeAttribute('data-status')
    
                }
    
            })
        
        }else{
            box_childs_group?.forEach((child: any) => {

                let group_id_key = child?.id?.split('_')?.[0] as string;
    
                if (group_id_key === first_side) {
                    child.style.display = 'block'
                    child.querySelector('#circle')?.removeAttribute('data-status')
    
                }
    
            })
        }
        



    });
    outputs?.forEach((input: SVGPathElement) => {
   
        const contact = input?.dataset?.contact as string;
        const split_contact = contact?.split('-') as string[];
        const [, next_id] = split_contact;
        const next_element = svg.querySelector(`rect[data-id = "${next_id}"]`)! as SVGRectElement;

        const next_element_outputs = svg.querySelectorAll(`path[data-contact ^= "${next_id}"]`)! as NodeListOf<SVGPathElement>;
        const next_element_inputs = svg.querySelectorAll(`path[data-contact $= "${next_id}"]`)! as NodeListOf<SVGPathElement>;

        let next_outputs: any[] = [];
        let next_inputs: any[] = [];
        next_element_outputs?.forEach((next_element_output: SVGPathElement) => {
            let side_name = next_element_output?.dataset.side as string;
            next_outputs.push(side_name)
        });
        next_element_inputs?.forEach((next_element_input: SVGPathElement) => {
            let side_name = next_element_input?.dataset.side as string;
            next_inputs.push(replace_side(side_name))
        })
        
        if (next_element_outputs.length > 0) {

            let arrow_box_groups = next_element?.parentNode?.querySelector('#arrow_box_group') as SVGGElement;
            let box_childs_group = arrow_box_groups?.querySelectorAll(`g`)! as NodeListOf<SVGGElement>;
            if((wizard && (id === "startpoint"))){
                box_childs_group?.forEach((box:SVGGElement)=>{
                        box.style.display = "none"
        
                    
                })
            }else if((!wizard && validate &&(id === "startpoint"))){
                // console.log(box_childs_group)

            }else{
                box_childs_group?.forEach((child: any) => {

                    let group_id_key = child?.id?.split('_')?.[0] as string;
                    if (group_id_key === next_outputs[0]?.split('-')?.[0]) {
    
                        child.style.display = 'block'
                        child.querySelector('#circle')?.removeAttribute('data-status')
    
    
                    }
    
                })
            }
            
        } else {
            if (next_inputs?.length > 0) {
                let arrow_box_groups = next_element?.parentNode?.querySelector('#arrow_box_group') as SVGGElement;
                let box_childs_group = arrow_box_groups?.querySelectorAll(`g`)! as NodeListOf<SVGGElement>;
                box_childs_group?.forEach((child: any) => {

                    let group_id_key = child?.id?.split('_')?.[0] as string;
                    if (!next_inputs.includes(group_id_key)) {
                        
                        child.style.display = 'block'
                        child.querySelector('#circle')?.removeAttribute('data-status')

                    }

                })
            }
        }


    })

}