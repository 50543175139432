
export const SCORECARDDATA = "SCORECARDDATA";
export const SCORECARDCATEGORYDATA = "SCORECARDCATEGORYDATA";
export const SCORECARDCRITERIADATA = "SCORECARDCRITERIADATA";
export const SCORECARDATTRIBUTEDATA = "SCORECARDATTRIBUTEDATA";
export const ACTIVESCORECARDDATA = "ACTIVESCORECARDDATA";
export const SCORECARDLEVEL = "SCORECARDLEVEL";
export const SCORECARDACTIVEDELETE = "SCORECARDACTIVEDELETE";
export const SCORECARDLEVELTREE = "SCORECARDLEVELTREE";
export const ACTIVETREE = "ACTIVETREE";


export const SCORE_CARD_LEVEL_DATA_ID = "SCORE_CARD_LEVEL_DATA_ID";
export const SCORE_CARD_BLOCKED_MODAL_RESPONSE = "SCORE_CARD_BLOCKED_MODAL_RESPONSE";
export const SCORE_CARD_ACTIVE_ELEMENT = "SCORE_CARD_ACTIVE_ELEMENT";
export const SCORE_CARD_ACTIVE_ROW = "SCORE_CARD_ACTIVE_ROW";
export const SCORE_CARD_REMOVE_NEW_ROW = "SCORE_CARD_REMOVE_NEW_ROW";
export const SCORE_CARD_CHANGE_ROW_EDITABLE_STATUS = "SCORE_CARD_CHANGE_ROW_EDITABLE_STATUS";
export const SCORE_CARD_RECOVERY_DATA = "SCORE_CARD_RECOVERY_DATA";
export const SCORE_CARD_ATTRIBUTE_AVAILABILITY = "SCORE_CARD_ATTRIBUTE_AVAILABILITY";
export const SCORE_CARD_WEIGHT_EDIT_STATUS = "SCORE_CARD_WEIGHT_EDIT_STATUS";
export const SCORE_CARD_REMAINING_WEIGHT = "SCORE_CARD_REMAINING_WEIGHT";
export const SCORE_CARD_REMAINING_WEIGHT_WARNING = "SCORE_CARD_REMAINING_WEIGHT_WARNING";
export const SCORE_CARD_CALCULATE_POINT = "SCORE_CARD_CALCULATE_POINT";
export const SCORE_CARD_REMAINING_POINT = "SCORE_CARD_REMAINING_POINT";


export interface ScoreCardData {
  scoreCardData: any | null
}

export interface ActiveScoreCardData {
  activeScoreCardData: any | null
}

export interface ScoreCardCategoryData {
  scoreCardCategoryData: any | null
}

export interface ScoreCardCriteriaData {
  scoreCardCriteriaData: any | null
}

export interface ScoreCardAttributeData {
  scoreCardAttributeData: any | null
}

export interface ScoreCardLevel {
  level: number
}

export interface ScoreCardLevelDataId {
  scoreCardId: number | null,
  categoryId: number | null,
  criteriaId: number | null,
  criteriaType: number | null
}

export interface ScoreCardActiveDelete {
  scoreCardActiveDelete: any | null | boolean
}

export interface ScoreCardBlockedModalResponse {
  response: string | null
}

export interface ScoreCardActiveElement {
  name: string | null
}

export interface ScoreCardActiveRow {
  rowIndex: number | null,
  rowType: string | null,
  rowId: number | null
}

export interface ScoreCardLevelTree {
  level: number | null
}
export interface ActiveTree {
  activeTree: any | null
}

export interface ScoreCardRecoveryData {
  recoveryData: object | null
}

export interface ScoreCardAttributeAvailability {
  status: boolean
}

export interface ScoreCardWeightEditStatus {
  levelOfWeightData: number,
  weightRowId: number | null,
  weightRowStatus: boolean
}

export interface ScoreCardRemainingWeightStore {
  weight: number
}

export interface ScoreCardRemainingWeightAction {
  remainingWeightLevel: number,
  remainingWeightParentId: number | null
}

export interface ScoreCardRemainingWeightWarning {
  weightWarningStatus: boolean
}

export interface ScoreCardCalculatePoint {
  levelOfPointData: number,
  pointRowId: number | null;
  pointParentId: number | null
}

export interface ScoreCardRemainingPointStore {
  point: number
}

export interface ScoreCardRemainingPointAction {
  remainingPointLevel: number,
  remainingPointParentId: number | null
}

export interface ScoreCardState {
  scoreCardData: ScoreCardData,
  activeScoreCardData: ActiveScoreCardData,
  scoreCardCategoryData: ScoreCardCategoryData,
  scoreCardCriteriaData: ScoreCardCriteriaData,
  scoreCardAttributeData: ScoreCardAttributeData,
  scoreCardLevel: ScoreCardLevel,
  scoreCardLevelDataId: ScoreCardLevelDataId,
  scoreCardActiveDelete: ScoreCardActiveDelete,
  scoreCardBlockedModalResponse: ScoreCardBlockedModalResponse,
  scoreCardActiveElement: ScoreCardActiveElement,
  scoreCardActiveRow: ScoreCardActiveRow,
  scoreCardLevelTree: ScoreCardLevelTree,
  activeTree: ActiveTree,
  scoreCardRecoveryData: ScoreCardRecoveryData
  scoreCardAtrributeAvailability: ScoreCardAttributeAvailability,
  scoreCardRemainingWeight: ScoreCardRemainingWeightStore,
  scoreCardRemainingWeightWarning: ScoreCardRemainingWeightWarning,
  scoreCardRemainingPoint: ScoreCardRemainingPointStore
}


interface scoreCardData {
  type: typeof SCORECARDDATA;
  payload: {
    scoreCardData: ScoreCardData
  };
}
interface activeScoreCardData {
  type: typeof ACTIVESCORECARDDATA;
  payload: {
    activeScoreCardData: ActiveScoreCardData
  };
}
interface scoreCardCategoryData {
  type: typeof SCORECARDCATEGORYDATA;
  payload: {
    scoreCardCategoryData: ScoreCardCategoryData
  };
}
interface scoreCardCriteriaData {
  type: typeof SCORECARDCRITERIADATA;
  payload: {
    scoreCardCriteriaData: ScoreCardCriteriaData
  };
}

interface scoreCardAttributeData {
  type: typeof SCORECARDATTRIBUTEDATA;
  payload: {
    scoreCardAttributeData: ScoreCardAttributeData
  };
}
interface scoreCardLevel {
  type: typeof SCORECARDLEVEL;
  payload: {
    scoreCardLevel: ScoreCardLevel
  };
}
interface scoreCardLevelDataId {
  type: typeof SCORE_CARD_LEVEL_DATA_ID;
  payload: { scoreCardLevelDataId: ScoreCardLevelDataId };
}
interface scoreCardActiveDelete {
  type: typeof SCORECARDACTIVEDELETE;
  payload: { scoreCardActiveDelete: ScoreCardActiveDelete };
}

interface scoreCardBlockedModalResponse {
  type: typeof SCORE_CARD_BLOCKED_MODAL_RESPONSE;
  payload: { scoreCardBlockedModalResponse: ScoreCardBlockedModalResponse };
}

interface scoreCardActiveElement {
  type: typeof SCORE_CARD_ACTIVE_ELEMENT;
  payload: { scoreCardActiveElement: ScoreCardActiveElement };
}

interface scoreCardActiveRow {
  type: typeof SCORE_CARD_ACTIVE_ROW;
  payload: { scoreCardActiveRow: ScoreCardActiveRow };
}

interface scoreCardRemoveNewRow {
  type: typeof SCORE_CARD_REMOVE_NEW_ROW;
  payload: { level: number }
}

interface scoreCardChangeRowEditableStatus {
  type: typeof SCORE_CARD_CHANGE_ROW_EDITABLE_STATUS;
  payload: {
    levelOfData: number,
    id: number | null,
    status: boolean
  }
}

interface scoreCardLevelTree {
  type: typeof SCORECARDLEVELTREE;
  payload: {
    scoreCardLevelTree: ScoreCardLevelTree
  };
}

interface activeTree {
  type: typeof ACTIVETREE;
  payload: {
    activeTree: ActiveTree
  };
}

interface scoreCardRecoveryData {
  type: typeof SCORE_CARD_RECOVERY_DATA;
  payload: {
    scoreCardRecoveryData: ScoreCardRecoveryData
  };
}

interface scoreCardAttributeAvailability {
  type: typeof SCORE_CARD_ATTRIBUTE_AVAILABILITY;
  payload: {
    scoreCardAttributeAvailability: ScoreCardAttributeAvailability
  };
}

interface scoreCardWeightEditStatus {
  type: typeof SCORE_CARD_WEIGHT_EDIT_STATUS;
  payload: ScoreCardWeightEditStatus

}

interface scoreCardRemainingWeight {
  type: typeof SCORE_CARD_REMAINING_WEIGHT,
  payload: ScoreCardRemainingWeightAction
}

interface scoreCardRemainingWeightWarning {
  type: typeof SCORE_CARD_REMAINING_WEIGHT_WARNING,
  payload: ScoreCardRemainingWeightWarning
}

interface scoreCardCalculatePoint {
  type: typeof SCORE_CARD_CALCULATE_POINT,
  payload: ScoreCardCalculatePoint
}

interface scoreCardRemainingPoint {
  type: typeof SCORE_CARD_REMAINING_POINT,
  payload: ScoreCardRemainingPointAction
}

export type ScoreCardActionsTypes =
  scoreCardData |
  activeScoreCardData |
  scoreCardCategoryData |
  scoreCardCriteriaData |
  scoreCardLevel |
  scoreCardLevelDataId |
  scoreCardActiveDelete |
  scoreCardAttributeData |
  scoreCardBlockedModalResponse |
  scoreCardActiveElement |
  scoreCardActiveRow |
  scoreCardRemoveNewRow |
  scoreCardChangeRowEditableStatus |
  scoreCardLevelTree |
  scoreCardRecoveryData |
  activeTree |
  scoreCardAttributeAvailability |
  scoreCardWeightEditStatus |
  scoreCardRemainingWeight |
  scoreCardRemainingWeightWarning |
  scoreCardCalculatePoint | 
  scoreCardRemainingPoint