import { StrategyActionsTypes, StrategySourceDataStatus, STRATEGY_SOURCE_DATA_STATUS } from "../../../../types/typesStrategy";


const initialState: StrategySourceDataStatus = {
    status:false
}

const reducer = (state = initialState, action: StrategyActionsTypes): StrategySourceDataStatus => {

    switch (action.type) {

        case STRATEGY_SOURCE_DATA_STATUS:
            const {status} = action.payload;
            
            return {
                status
            }

        default:
            return state

    }
}

export default reducer