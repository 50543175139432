import { reducer } from "./reducers/reducer";
import { reducerDecision } from "./reducers/reducerDecision";
import { reducerScoreCard } from "./reducers/reducerScoreCard";
import { reducerProduct } from "./reducers/reducerProduct";
import reducerStrategy from "./reducers/reducerStrategy/reducer";
import reducerModal from "./reducers/reducerModal/reducer";
import reducerRequired from "./reducers/reducerRequired/reducer";

import { combineReducers } from "redux";

export const rootReducer = combineReducers({
  reducer,
  reducerDecision,
  reducerScoreCard,
  reducerProduct,
  reducerStrategy,
  reducerModal,
  reducerRequired,
});
export type RootState = ReturnType<typeof rootReducer>;
