interface IGetTextCursorCoordinates {
  element: HTMLTextAreaElement;
  cursorPosition: number;
}

export const getTextCursorCoordinates = ({
  element,
  cursorPosition,
}: IGetTextCursorCoordinates) => {
  const editor = element;
  const editorWrapper = editor.parentElement as HTMLDivElement;
  const editorValue = editor.value;
  const newLines = editorValue.split("\n");

  function generateRangeData(linesArray: Array<any>) {
    return linesArray.reduce(
      (total: any, current: string) => {
        const length = current.length;

        const obj = {
          line: total.line,
          range: {
            start: total.cursor,
            end: total.cursor + length,
          },
        };

        const result = [...total.result, obj];

        return {
          cursor: total.cursor + length + 1,
          line: total.line + 1,
          result,
        };
      },
      { cursor: 0, line: 1, result: [] }
    ).result;
  }

  function checkLine(rangeArray: Array<any>, cursorPosition: number) {
    for (let i = 0; i < rangeArray.length; i++) {
      const { line, range } = rangeArray[i];

      if (range.start <= cursorPosition && range.end >= cursorPosition) {
        return line;
      }
    }
  }

  function calculateCursorTop(
    editorElement: HTMLTextAreaElement,
    cursorLine: number
  ) {
    const editorScrollTop = editorElement.scrollTop;
    const editorLineHeightWithPx = getComputedStyle(editorElement).lineHeight;
    const editorLineHeightWithoutPx = parseFloat(
      editorLineHeightWithPx.substring(0, editorLineHeightWithPx.length - 2)
    );
    const totalLineHeight = editorLineHeightWithoutPx * cursorLine;
    return totalLineHeight - editorScrollTop + "px";
  }

  function calculateCursorLeft(
    cursorPosition: number,
    cursorLine: number,
    rangeData: Array<any>,
    linesArray: Array<string>,
    editorWrapperElement: HTMLDivElement,
    editorElement: HTMLTextAreaElement
  ) {
    const wrapper = editorWrapperElement;
    const dummySpan = document.createElement("SPAN") as HTMLSpanElement;
    const propsBody = document.createElement("DIV") as HTMLDivElement;
    const editorProps = getComputedStyle(editorElement);
    let trimCount = 0;

    wrapper.appendChild(dummySpan);
    wrapper.appendChild(propsBody);

    dummySpan.style.fontFamily = editorProps.fontFamily;
    dummySpan.style.fontSize = editorProps.fontSize;
    dummySpan.style.fontWeight = editorProps.fontWeight;
    dummySpan.style.lineHeight = editorProps.lineHeight;
    dummySpan.style.letterSpacing = editorProps.letterSpacing;
    dummySpan.style.whiteSpace = "no-wrap";

    propsBody.style.width = "15vh";

    const specificLineString = linesArray.find((string, index) => {
      let str = "";
      if (index === cursorLine - 1) {
        str = string;
      }

      return str;
    });

    rangeData.forEach(function (element) {
      const { line, range } = element;

      if (line === cursorLine) {
        trimCount = cursorPosition - range.start;
      }
    });

    const trimmedString = specificLineString?.substring(0, trimCount);

    if (trimmedString) {
      dummySpan.innerHTML = trimmedString;
    }

    let trimmedStringWidth = "0px";
    const spanWidth = dummySpan.offsetWidth;
    const editorWidth = editor.offsetWidth;
    const diffWidth = editorWidth - spanWidth;
    const propsBodyWidthString = getComputedStyle(propsBody)?.width;
    const propsBodyWidthNumber = parseFloat(
      propsBodyWidthString.substring(0, propsBodyWidthString.length - 2)
    );

    if (diffWidth < propsBodyWidthNumber) {
      trimmedStringWidth = editorWidth - propsBodyWidthNumber + "px";
    }

    if (diffWidth > propsBodyWidthNumber) {
      trimmedStringWidth = spanWidth + "px";
    }

    dummySpan.remove();
    propsBody.remove();

    return trimmedStringWidth;
  }

  const rangeArray = generateRangeData(newLines);
  const cursorLine = checkLine(rangeArray, cursorPosition);
  const top = calculateCursorTop(editor, cursorLine);
  const left = calculateCursorLeft(
    cursorPosition,
    cursorLine,
    rangeArray,
    newLines,
    editorWrapper,
    editor
  );

  return {
    top,
    left,
  };
};
