import React, {
  ClipboardEvent,
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import classes from "./SidePanelFunction.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  ApiDebugModes,
  ButtonSizes,
  ButtonTypes,
  ElementTypes,
  icons,
  messages,
  ModalNames,
  storage_key,
} from "../../../../../settings/settings";
import {
  handlerStrategyElementInput,
  handleStrategyBoardSidePanelContent,
  handleStrategyNewCreatedElementId,
} from "../../../../../store/actions/actionsStrategy";
import Input from "../../../../../components/UI/Input/Input";
import DataViewer from "../../../../../components/UI/DataViewer/DataViewer";
import StrategyEditor, {
  IIFunctionData,
  IIndexedKeys,
} from "../../../../../components/UI/StrategyEditor/StrategyEditor";
import StrategyFunctions from "../../../../../components/UI/StrategyFunctions/StrategyFunctions";
import { RootState } from "../../../../../store/combineReducer";
import DB from "../../../../../layout/DB/Storage";
import TooltipV2 from "../../../../../components/UI/Tooltip/TooltipV2";
import { executeStrategy } from "../../../../../helpers/executeStrategy";
import { hasChange } from "../../../../../helpers/hasChange";
import Modal from "../../../../../components/UI/Modal/Modal";
import { checkField } from "../../../../../helpers/checkField";
import { submitElement } from "../../../../../helpers/submitElement";
import {
  closeModal,
  openModal,
} from "../../../../../store/actions/actionsModal";
import { useDebounce } from "use-debounce/lib";
import Button from "../../../../../components/UI/Button/ButtonV2";
import { checkLabel } from "helpers/checkLabel";
import { removeElement } from "helpers/removeElement";
import { TooltipInfo } from "components/UI/Tooltip/InfoTooltip/InfoTooltip";

interface IValidating {
  resultVar: boolean;
  funcTxt: boolean;
}

enum FunctionPanelActions {
  VALIDATE,
  MANUAL_KEY,
  CLOSE,
  ERROR,
}

let timeOutInstance: any;
let initialState: any;

const SidePanelFunction: FC = () => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const {
    reducerStrategy: {
      StrategyBoardElements: {
        ElementInput: { element_input },
        NewCreatedElement: { new_created_element },
      },
    },
    reducerModal: { modalState },
  } = useSelector((state: RootState) => state);

  const [lastActiveEditor, setLastActiveEditor] = useState<string>();
  const [functionDescription, setFunctionDescription] = useState<string>();
  const [functionSyntax, setFunctionSyntax] = useState<string>();
  const [selectedFunctionBody, setSelectedFunctionBody] = useState<
    IIndexedKeys | undefined
  >();
  const [editorFunctionData, setEditorFunctionData] = useState<
    IIFunctionData | undefined
  >();
  const [functionPreviousData, setFunctionPreviousData] = useState<any>({});
  const [functionEditorState, setFunctionEditorState] = useState<any>({
    funcTxt: "",
    arrayInput: {
      input: "",
      condt: "",
    },
  });
  const [functionDataState, setFunctionDataState] = useState<any>({
    id: "",
    label: "",
    resultVar: "",
    func: "",
    funcTxt: "",
    arrayInput: {
      input: "",
      condt: "",
    },
  });
  const [tooltipState, setTooltipState] = useState<any>({
    visible: false,
    data: undefined,
    bgColor: undefined,
    txtColor: undefined,
    topMessage: undefined,
    bottomMessage:
      "This notification may show reduced result. For full result please choose Debug mode.",
  });

  const [validating, setValidating] = useState<IValidating>({
    resultVar: false,
    funcTxt: false,
  });
  const [activePanelAction, setActivePanelAction] =
    useState<FunctionPanelActions>();
  const [hasManualKey, setHasManualKey] = useState<{
    [key: string]: boolean;
  }>({ funcTxt: false });
  const [showIterate, setShowIterate] = useState<boolean>(false);
  const [nameTooltip, setNameTooltip] = useState<boolean>(false);
  const [debouncedHasManualKey] = useDebounce(hasManualKey, 5e2);
  const [isOpenTooltipInfo, setIsOpenTooltipInfo] = useState<boolean>(false);

   const showInfo = (e: any) => {
    setIsOpenTooltipInfo(!isOpenTooltipInfo);
  };
  const handleFunctionState = useCallback(
    (
      state: any,
      innerObject: boolean,
      keys: Array<string>,
      values: Array<string>
    ) => {
      let copyOfState = { ...state };

      if (innerObject) {
        keys?.forEach((key: string, index: number) => {
          copyOfState["arrayInput"][key] = values[index];
        });
      } else {
        keys?.forEach((key: string, index: number) => {
          copyOfState[key] = values[index];
        });
      }
      setFunctionDataState({ ...copyOfState });
    },
    []
  );

  const getFunctionId = useCallback(
    (elementId: string) => {
      const { id } = functionDataState;
      if (id === "") {
        let funcLabel =
          "Function " + elementId.substring(elementId.lastIndexOf("_") + 1);

        handleFunctionState(
          functionDataState,
          false,
          ["id", "label"],
          [elementId, funcLabel]
        );
        initialState = Object.freeze({
          id: elementId,
          label: funcLabel,
          resultVar: "",
          func: "",
          funcTxt: "",
          arrayInput: {
            input: "",
            condt: "",
          },
        });
      }
    },
    [handleFunctionState, functionDataState]
  );

  const getFunctionPreviousData = useCallback(
    (functionId: string) => {
      const db = new DB(storage_key);
      const { id } = functionDataState;

      if (id === "") {
        db.fetch({ sourceId: functionId })
          .then((result: any) => {
            const { status, data } = result;

            if (status) {
              let previousData = { ...data.data };

              initialState = Object.freeze({
                ...previousData,
                arrayInput: Object.freeze({ ...previousData.arrayInput }),
              });
              setFunctionDataState({ ...previousData });
              setFunctionEditorState({ ...previousData });
              setFunctionPreviousData({ ...data });
              previousData?.arrayInput?.input && setShowIterate(true);
            } else {
              console.warn(
                "Previous function data fetch from local storage not found"
              );
            }
          })
          .catch((error) => {
            console.error(
              "Previous function data fetch from local storage error :",
              error
            );
          });
      }
    },
    [functionDataState]
  );

  const restoreDefaults = useCallback(() => {
    if (editorFunctionData) {
      setSelectedFunctionBody(undefined);
      setEditorFunctionData(undefined);
    }
  }, [editorFunctionData]);

  const handleInputChanges = useCallback(
    (value: any) => {
      handleFunctionState(
        functionDataState,
        false,
        ["resultVar"],
        [value.replace(/^\d|\W/g, "_")]
      );
      setNameTooltip(false);
    },
    [handleFunctionState, functionDataState]
  );

  const changeFunctionEditorBody = useCallback(
    (data: string | { valueIndex: string; value: string }) => {
      if (typeof data === "string") return;

      const { valueIndex, value } = data;
      setFunctionEditorState((state: any) => {
        return { ...state, [valueIndex]: value };
      });
      restoreDefaults();
    },
    [restoreDefaults]
  );

  const changeFunctionDataBody = useCallback(
    (value: string) => {
      setFunctionDataState((state: any) => {
        const iterateKey = functionDataState?.arrayInput?.input?.replace(
          "root.",
          ""
        ) as string;
        let copyOfValue = value.includes("SELF.")
          ? value.replaceAll("SELF.", "ITERATE.")
          : value;
        if (iterateKey && copyOfValue.includes("SELF")) {
          copyOfValue = copyOfValue.replaceAll("SELF", `ITERATE`);
        }
        copyOfValue = copyOfValue
          .replaceAll("=", "==")
          .replaceAll("<==", "<=")
          .replaceAll(">==", ">=");
        if (copyOfValue.includes("<>")) {
          copyOfValue = copyOfValue.replaceAll("<>", "!=");
        }
        return { ...state, funcTxt: copyOfValue };
      });
    },
    [functionDataState?.arrayInput?.input]
  );

  const changeIterateEditorElement = useCallback(
    (data: string | { valueIndex: string; value: string }) => {
      if (typeof data === "string") return;

      const { valueIndex, value } = data;
      setFunctionEditorState((state: any) => {
        return {
          ...state,
          arrayInput: { ...state.arrayInput, [valueIndex]: value },
        };
      });
      restoreDefaults();
    },
    [restoreDefaults]
  );

  const changeIterateDataElement = useCallback((value: string) => {
    setFunctionDataState((state: any) => {
      return {
        ...state,
        arrayInput: { ...state.arrayInput, input: value },
      };
    });
  }, []);

  const changeIterateEditorCondition = useCallback(
    (data: string | { valueIndex: string; value: string }) => {
      if (typeof data === "string") return;

      let { valueIndex, value } = data;
      value = value.replace(/[=*]/g, "=").replace("==", "=");

      setFunctionEditorState((state: any) => {
        return {
          ...state,
          arrayInput: { ...state.arrayInput, [valueIndex]: value },
        };
      });
      restoreDefaults();
    },
    [restoreDefaults]
  );

  const changeIterateDataCondition = useCallback(
    (value: string) => {
      const iterateKey = functionDataState?.arrayInput?.input?.replace(
        "root.",
        ""
      ) as string;
      let copyOfValue = value.includes("SELF.")
        ? value.replaceAll("SELF.", "ITERATE.")
        : value;
      if (iterateKey && copyOfValue.includes("SELF")) {
        copyOfValue = copyOfValue.replaceAll("SELF", `ITERATE`);
      }
      copyOfValue = copyOfValue
        .replaceAll("=", "==")
        .replaceAll("<==", "<=")
        .replaceAll(">==", ">=");
      if (copyOfValue.includes("<>")) {
        copyOfValue = copyOfValue.replaceAll("<>", "!=");
      }
      setFunctionDataState((state: any) => {
        return {
          ...state,
          arrayInput: { ...state.arrayInput, condt: copyOfValue },
        };
      });
    },
    [functionDataState?.arrayInput?.input]
  );

  const handlePaste = (e: ClipboardEvent<HTMLTextAreaElement>): void => {
    // e.preventDefault();
    // let value = e.clipboardData.getData("text");
    // e.currentTarget.value = value.replace(/[=*]/g, "=").replace("==", "=");
  };
  const handleFunctionsDoubleClick = useCallback(
    (func: IIndexedKeys) => {
      if (lastActiveEditor) {
        setSelectedFunctionBody(func);
      }
    },
    [lastActiveEditor]
  );

  const setManualKeyStatus = useCallback(
    (status: { [key: string]: boolean }) => {
      const [key, value] = Object.entries(status)[0];

      setHasManualKey((state) => {
        return {
          ...state,
          [key]: value,
        };
      });
    },
    []
  );

  useLayoutEffect(() => {
    if (new_created_element && !element_input) {
      getFunctionId(new_created_element);
    } else if (element_input) {
      getFunctionPreviousData(element_input);
    }
  }, [
    new_created_element,
    getFunctionId,
    element_input,
    getFunctionPreviousData,
  ]);

  useEffect(() => {
    if (selectedFunctionBody && lastActiveEditor) {
      setEditorFunctionData({
        editorIndex: lastActiveEditor,
        data: selectedFunctionBody,
      });
    }
  }, [selectedFunctionBody, lastActiveEditor]);

  useEffect(() => {
    return () => clearTimeout(timeOutInstance);
  }, []);

  const closeWrapper = () => {
    const { current } = wrapperRef;

    if (current) {
      current.classList.add(classes.CloseAnimation);
    }

    timeOutInstance = setTimeout(() => {
      dispatch(handleStrategyBoardSidePanelContent({ content_value: null }));
      dispatch(
        handleStrategyNewCreatedElementId({ new_created_element: null })
      );
      dispatch(handlerStrategyElementInput({ element_input: null }));
    }, 1000);
  };

  const checkChanges = () => {
    setIsOpenTooltipInfo(false);
    hasChange({
      initial: initialState,
      changeable: functionDataState,
    })
      .then(() => {
        dispatch(
          openModal({
            modalState: {
              visible: true,
              title: messages.titleClosePanel,
              question: messages.titleSureClosePanel,
              message: messages.messagePanelClosing,
              buttonMessage: messages.titleBtnClose,
              buttonType: ButtonTypes.PRIMARY,
              name: ModalNames.FUNCTION_PANEL,
            },
          })
        );
        setActivePanelAction(FunctionPanelActions.CLOSE);
      })
      .catch((error) => {
        error && console.error(error);
        closeWrapper();
        removeElement(new_created_element);
      });
  };

  const handleModalAction = (action: boolean) => {
    switch (activePanelAction) {
      case FunctionPanelActions.VALIDATE: {
        setValidating((state: IValidating) => {
          return {
            ...state,
            [modalState?.validatingField as string]: true,
          };
        });
        break;
      }
      case FunctionPanelActions.CLOSE: {
        if (!action) {
          dispatch(closeModal());
          return;
        }

        closeWrapper();
        removeElement(new_created_element);
      }
    }

    dispatch(closeModal());
  };

  const validateData = () =>
    new Promise((resolve, reject) => {
      if (Object.values(debouncedHasManualKey).some((value) => value)) {
        dispatch(
          openModal({
            modalState: {
              visible: true,
              title: messages.titleAttention,
              question: messages.titleManualKey,
              message: messages.messageManualKey,
              buttonMessage: messages.titleUnderstand,
              buttonType: ButtonTypes.WARNING,
              name: ModalNames.FUNCTION_PANEL,
            },
          })
        );
        setActivePanelAction(FunctionPanelActions.MANUAL_KEY);
        return reject();
      }

      checkField({ state: functionDataState, types: ElementTypes.FUNCTION })
        .then(resolve)
        .catch(({ reason }) => {
          dispatch(
            openModal({
              modalState: {
                visible: true,
                title: messages.titleAttention,
                question: messages.titleMustBeFilled,
                message: messages.messagePanelEmptyAttention,
                buttonMessage: messages.titleUnderstand,
                buttonType: ButtonTypes.WARNING,
                validatingField: reason,
                name: ModalNames.FUNCTION_PANEL,
              },
            })
          );
          setActivePanelAction(FunctionPanelActions.VALIDATE);
          return reject();
        });
    });

  const handleExecution = () => {
    validateData().then(() => {
      executeStrategy({
        state: tooltipState,
        setState: setTooltipState,
        activeElementId: element_input,
        debugMode: ApiDebugModes.BREAKPOINT,
        elementPresentData: functionDataState,
        elementPreviousData: functionPreviousData,
        elementType: ElementTypes.FUNCTION,
      });
    });
  };

  const replaceFunction = useCallback(
    (str: string, oldStr: string, newStr: string) => {
      let replaceStr = str;
      const regex = new RegExp(`(?:\\W|^)(${oldStr})(?:\\W|$)`);

      while (replaceStr.match(regex)) {
        replaceStr = replaceStr.replace(regex, (data, $1) =>
          data.replace($1, newStr)
        );
      }

      return replaceStr;
    },
    []
  );

  const handlers: Record<
    string,
    (item: any, oldVarName: string, newVarName: string) => void
  > = useMemo(
    () => ({
      output() {},
      startpoint() {},
      endpoint() {},
      code_message() {},
      code_message_list() {},
      product() {},
      func(item, oldVarName, newVarName) {
        item.data.funcTxt = replaceFunction(
          item.data.funcTxt,
          oldVarName,
          newVarName
        );
        item.data.func = `${item.data.label}=${item.data.funcTxt}`;
        new DB(storage_key).update({ sourceId: item.sourceId }, [item]);
      },
      condt(item, oldVarName, newVarName) {
        item.data.condtBody = replaceFunction(
          item.data.condtBody,
          oldVarName,
          newVarName
        );
        new DB(storage_key).update(
          { sourceId: item.sourceId, branchLabel: item.branchLabel },
          [item]
        );
      },
      scorecard(item, oldVarName, newVarName) {
        item.data.mappData = item.data.mappData.map((d: any) => {
          d.paramLabel = replaceFunction(d.paramLabel, oldVarName, newVarName);
          return d;
        });
        new DB(storage_key).update({ sourceId: item.sourceId }, [item]);
      },
      decision(item, oldVarName, newVarName) {
        if (typeof item.data.map_obj === "string") {
          item.data.map_obj = replaceFunction(
            item.data.map_obj,
            oldVarName,
            newVarName
          );
        } else {
          item.data.map_obj.x = replaceFunction(
            item.data.map_obj.x,
            oldVarName,
            newVarName
          );
          item.data.map_obj.y = replaceFunction(
            item.data.map_obj.y,
            oldVarName,
            newVarName
          );
        }
        new DB(storage_key).update({ sourceId: item.sourceId }, [item]);
      },
    }),
    [replaceFunction]
  );

  const handleRenameVariableName = useCallback(
    (list: any[], oldVarName: string, newVarName: string) => {
      list.forEach(
        (item: any) =>
          newVarName &&
          oldVarName !== newVarName &&
          item.data &&
          Object.keys(item.data).length > 0 &&
          item.data.label !== oldVarName &&
          handlers[item.type](item, oldVarName, newVarName)
      );
    },
    [handlers]
  );

  const handleSubmit = () => {
    if (!checkLabel(functionDataState, ElementTypes.FUNCTION)) {
      validateData().then(() => {
        new DB(storage_key).fetchAll().then((res: any) => {
          if (res.status) {
            handleRenameVariableName(
              res.data,
              functionDataState.label,
              functionDataState.resultVar
            );
          }
        });

        submitElement(
          new_created_element
            ? {
                id: new_created_element,
                state: functionDataState,
                type: ElementTypes.FUNCTION,
                isNew: true,
              }
            : {
                id: element_input,
                state: functionDataState,
                previousData: functionPreviousData,
                type: ElementTypes.FUNCTION,
                isNew: false,
              }
        )
          .then(closeWrapper)
          .catch(() => {
            dispatch(
              openModal({
                modalState: {
                  visible: true,
                  title: messages.titleOops,
                  question: messages.titleWentWrong,
                  message: messages.messagePanelSubmittingError,
                  buttonMessage: messages.titleAgain,
                  buttonType: ButtonTypes.DELETE,
                  name: ModalNames.FUNCTION_PANEL,
                },
              })
            );
            setActivePanelAction(FunctionPanelActions.ERROR);
          });
      });
      setNameTooltip(false);
    } else {
      setNameTooltip(true);
    }
  };

  return (
    <div ref={wrapperRef} className={classes.FunctionWrapper}>
      <section className={classes.FunctionHeader}>
        <span className={classes.HeaderTitle}>{functionDataState?.label}</span>
        <div className={classes.HeaderActions}>
          {element_input && (
            <>
              <TooltipV2
                visible={tooltipState?.visible}
                onVisible={(value: boolean) =>
                  setTooltipState({
                    ...tooltipState,
                    visible: value,
                    data: undefined,
                  })
                }
                data={tooltipState?.data}
                topMessage={tooltipState?.topMessage}
                bottomMessage={tooltipState?.bottomMessage}
                backgroundColor={tooltipState?.bgColor}
                textColor={tooltipState?.txtColor}
                child={
                  <img
                    className={classes.HeaderPlayAction}
                    src={icons.strategyPlay}
                    alt="Play icon"
                    onClick={handleExecution}
                  />
                }
              />
              <span className={classes.SpacerNormalHorizontal} />
              <button
                className={classes.HeaderBackButton}
                onClick={closeWrapper}
              >
                <span>Back</span>
                <img src={icons.nextBtnGrey} alt="Arrow right icon" />
              </button>
            </>
          )}
        </div>
      </section>
      <section className={classes.FunctionBody}>
        <aside className={classes.FunctionLeftSide}>
          <DataViewer presentVariableId={functionDataState?.id} />
          <StrategyFunctions
            onSingleClick={(description: string, syntax: string) => {
              setFunctionDescription(description);
              setFunctionSyntax(syntax);
            }}
            onDoubleClick={handleFunctionsDoubleClick}
          />
        </aside>
        <div className={classes.FunctionRightSide}>
          <Input
            label="Function Name"
            value={functionDataState?.resultVar}
            onChange={handleInputChanges}
            tabIndex={1}
            validate={validating.resultVar}
            nameTooltip={nameTooltip}
          />
          <StrategyEditor
            label="Function Body"
            height="17.27vh"
            value={functionEditorState?.funcTxt
              ?.replaceAll("==", "=")
              .replaceAll("!=", "<>")}
            valueIndex="funcTxt"
            getValueIndex={(id: string) => {
              setLastActiveEditor(id);
            }}
            onChange={changeFunctionEditorBody}
            functionData={editorFunctionData}
            tabIndex={2}
            validate={validating.funcTxt}
            iterateKey={functionDataState?.arrayInput?.input}
            editorModalName={ModalNames.EDITOR_FUNCTION_BODY}
            elementType={ElementTypes.FUNCTION}
            getManualKeyStatus={setManualKeyStatus}
            getIndexedValue={changeFunctionDataBody}
            onSingleClick={(description: string, syntax: string) => {
              setFunctionDescription(description);
              setFunctionSyntax(syntax);
            }}
          />
          <div className={classes.IterateToggleRow}>
            <div
              className={classes.IterateToggle}
              onClick={() => setShowIterate(!showIterate)}
            >
              <svg
                className={showIterate ? classes.Rotate : ""}
                viewBox="0 0 7 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M3.66996 3.94462L6.69982 0.914706C6.76996 0.844628 6.80859 0.75108 6.80859 0.651331C6.80859 0.551583 6.76996 0.458035 6.69982 0.387957L6.47675 0.164824C6.33139 0.0196303 6.09513 0.0196303 5.95 0.164824L3.4057 2.70912L0.85858 0.162001C0.788446 0.0919228 0.694953 0.0532303 0.595261 0.0532303C0.495457 0.0532303 0.401964 0.0919228 0.331775 0.162001L0.108753 0.385134C0.0386195 0.455267 -1.7643e-05 0.54876 -1.7643e-05 0.648508C-1.7643e-05 0.748257 0.0386195 0.841805 0.108753 0.911883L3.14138 3.94462C3.21174 4.01487 3.30567 4.05345 3.40553 4.05323C3.50578 4.05345 3.59966 4.01487 3.66996 3.94462Z" />
              </svg>
              <span>{showIterate ? "Hide" : "Show"} iterate section</span>
            </div>
          </div>
          <section
            className={`${classes.Iterate} ${
              showIterate ? "" : classes.Collapsed
            }`}
          >
            <StrategyEditor
              label="Iterate Element"
              height="17.27vh"
              value={functionEditorState?.arrayInput?.input
                .replaceAll("==", "=")
                .replaceAll("!=", "<>")}
              valueIndex="input"
              getValueIndex={(id: string) => {
                setLastActiveEditor(id);
              }}
              onChange={changeIterateEditorElement}
              functionData={editorFunctionData}
              tabIndex={3}
              editorModalName={ModalNames.EDITOR_ITERATE_ELEMENT}
              elementType={ElementTypes.FUNCTION}
              getManualKeyStatus={setManualKeyStatus}
              getIndexedValue={changeIterateDataElement}
              onSingleClick={(description: string, syntax: string) => {
                setFunctionDescription(description);
                setFunctionSyntax(syntax);
              }}
            />
            <StrategyEditor
              label="Iterate Condition"
              height="17.27vh"
              value={functionEditorState?.arrayInput?.condt
                .replaceAll("==", "=")
                ?.replaceAll("!=", "<>")}
              valueIndex="condt"
              getValueIndex={(id: string) => {
                setLastActiveEditor(id);
              }}
              onChange={changeIterateEditorCondition}
              onPaste={handlePaste}
              functionData={editorFunctionData}
              tabIndex={4}
              iterateKey={functionDataState?.arrayInput?.input}
              editorModalName={ModalNames.EDITOR_ITERATE_CONDITION}
              elementType={ElementTypes.FUNCTION}
              getManualKeyStatus={setManualKeyStatus}
              getIndexedValue={changeIterateDataCondition}
              onSingleClick={(description: string, syntax: string) => {
                setFunctionDescription(description);
                setFunctionSyntax(syntax);
              }}
            />
          </section>
          <span className={classes.DescriptionLabel}>Function description</span>

          {functionDescription
            ?.split(/[\n\r]/)
            .flat()
            .filter(Boolean)
            .map((description) => (
              <span className={classes.Description}>{description}</span>
            ))}
          <span className={classes.Syntax}>{functionSyntax}</span>
          <Button size={ButtonSizes.LARGE} onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </section>
        {isOpenTooltipInfo && <TooltipInfo sidePanelUp />}
      <button className={classes.FunctionCloseButton} onClick={checkChanges}>
        <img src={icons.closeBtn} alt="Close icon" />
      </button>
      <svg
            className={classes.HelpIcon}
            viewBox='0 0 8 8'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            onClick={(e) => showInfo(e)}
          >
            <path
              d='M4 6.30469C4.21574 6.30469 4.39062 6.1298 4.39062 5.91406C4.39062 5.69833 4.21574 5.52344 4 5.52344C3.78426 5.52344 3.60938 5.69833 3.60938 5.91406C3.60938 6.1298 3.78426 6.30469 4 6.30469Z'
              fill='white'
              fillOpacity='0.6'
            />      
            <path
              d='M4 2.00781C3.31075 2.00781 2.75 2.56856 2.75 3.25781C2.75 3.43041 2.88991 3.57031 3.0625 3.57031C3.23509 3.57031 3.375 3.43041 3.375 3.25781C3.375 2.91319 3.65538 2.63281 4 2.63281C4.34462 2.63281 4.625 2.91319 4.625 3.25781C4.625 3.60244 4.34462 3.88281 4 3.88281C3.82741 3.88281 3.6875 4.02272 3.6875 4.19531V4.97656C3.6875 5.14916 3.82741 5.28906 4 5.28906C4.17259 5.28906 4.3125 5.14916 4.3125 4.97656V4.46827C4.85103 4.32916 5.25 3.8392 5.25 3.25781C5.25 2.56856 4.68925 2.00781 4 2.00781Z'
              fill='white'
              fillOpacity='0.6'
            />
          </svg> 
      <Modal
        title={modalState?.title}
        question={modalState?.question}
        message={modalState?.message}
        buttonMessage={modalState?.buttonMessage}
        buttonType={modalState?.buttonType}
        onAction={handleModalAction}
        visible={modalState?.visible}
        name={ModalNames.FUNCTION_PANEL}
      />
    </div>
  );
};

export default SidePanelFunction;
