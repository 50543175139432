import { remove_arrow_box } from "./remove_arrow_box.handler";

import {condition_label} from "./condition_arrow.handler";
import { drag_contact_destination } from "./drag_arrow_destination/coord_arrow.handler";
export const add_arrow = (targetId:any,sourceId:any,overlays:Array<any>=[],type:any,branchLabel:any=null) =>{

    if(targetId && sourceId){
        const {coord,contact,side} =overlays.length>0 && overlays[0];
        const svg = document.querySelector(`#svg_board`) as SVGElement;
        let xmlns:string = "http://www.w3.org/2000/svg";
        let arrow = document.createElementNS(xmlns,'path') as SVGPathElement;
        arrow.setAttributeNS(null,'stroke','#788896');
        arrow.setAttributeNS(null,'stroke-width','5.65');
        arrow.setAttributeNS(null,'fill','none');
        arrow.setAttributeNS(null,'stroke-linejoin','round');
        arrow.setAttributeNS(null,'stroke-linecap','round');
        arrow.setAttributeNS(null,'data-contact',`${contact}`);
        arrow.setAttributeNS(null,'data-side',`${side}`);
        if(coord){
            arrow.setAttributeNS(null,'d',`${coord}`);
        }
        arrow.setAttributeNS(null,'id',`arrow`);

        drag_contact_destination(arrow)


        if(branchLabel === "YES"){
            arrow.setAttributeNS(null,'data-bool','yes');


        }
        if(branchLabel === "NO"){
            arrow.setAttributeNS(null,'data-bool','no');

        }
        let check = svg?.querySelectorAll(`path[data-contact = ${contact}]`) as NodeList;
        condition_label(arrow);

        if(check?.length ===0){
            svg.insertBefore(arrow,svg.firstChild);

            
        }

        remove_arrow_box(contact?.split('-')[0],contact?.split('-')[1],side,type);
        return arrow

    }



}