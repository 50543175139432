
export const REQUIRED_DECISION_MATRIX = "REQUIRED_DECISION_MATRIX";

export interface RequiredDecisionMatrix {
    field:{
        x?:boolean;
        y?:boolean;
        matrix?:boolean
    }
}

export interface RequiredState {
  RequiredDecisionMatrix: RequiredDecisionMatrix;
}

export interface decisionMatrix {
  type: typeof REQUIRED_DECISION_MATRIX;
  payload: RequiredDecisionMatrix;
}



export type RequiredActions = decisionMatrix;
