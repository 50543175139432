import { combineReducers } from 'redux'
import reducerPanelCollapseStatus from './SidePanelCollapseStatus/reducer'
import reducerPanelContent from './SidePanelContent/reducer'
import reducerPanelInput from './SidePanelInput/reducer'


export default combineReducers({
    CollapseStatus: reducerPanelCollapseStatus,
    Content: reducerPanelContent,
    Input: reducerPanelInput
})