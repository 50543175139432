import React, { FC, useCallback, useEffect, useLayoutEffect, useState } from 'react'
import classes from './ButtonSlider.module.scss'

interface IButtonSlider {
    buttons?: Array<any>,
    active?: number,
    redirectId?:number
}

const ButtonSlider: FC<IButtonSlider> = ({ buttons, active,redirectId}) => {

    const [activeButton, setActiveButton] = useState<number>(0)

    const mouseClickHandlerCallback = useCallback(
        (event: any) => {

          
            const { target: { id, name } } = event;
                if (name === "button-slider" && +id === redirectId) setActiveButton(parseInt(id))

            
        },
        [redirectId],
    )

    useLayoutEffect(() => {
        if (active) setActiveButton(active)
    }, [active])

  
    useEffect(() => {

        window.addEventListener('click', mouseClickHandlerCallback)

        return () => window.removeEventListener('click', mouseClickHandlerCallback)

    }, [mouseClickHandlerCallback])

    return (
        <div className={classes.ButtonSliderWrapper}>
            {
                buttons?.map((element: any, index: number) => {
                    const { name, icon, event } = element

                    return (
                        <button
                            name="button-slider"
                            id={(index || index === 0) ? index.toString() : ""}
                            className={activeButton === index ? classes.ActiveButton : ""}
                            key={index}
                            onClick={event}
                        >
                            {icon}{name}
                        </button>
                    )
                })
            }
        </div>
    )
}

export default ButtonSlider