import { elementSize } from "../../../../../../settings/settings";
import { create_text, move_text } from "../element_text.handler";
import { listener } from "./listener";
const arrow_type ={
    top:'l-4 0 ,l4 -8 ,l4 8 ,l-4 0',
    right:'l0 -5 ,l8 5 ,l-8 5 ,l0 -5',
    bottom:'l4 0 ,l-4 8 ,l-4 -8 ,l4 0',
    left:'l0 -5,l-8 5 ,l8 5,l0 -5',
}
export const createOutput = (end_data:any) =>{
    
    let xmlns = "http://www.w3.org/2000/svg";
    const svg = document.querySelector(`#svg_board`) as SVGElement;

    const group = document.createElementNS(xmlns,'g') as SVGGElement;
    group.setAttributeNS(null,'data-id','output_format');
    group.setAttributeNS(null,'id','output_format');
    group.setAttributeNS(null,'data-type','figure');
    const {x,y,width,height} = end_data;
    const data:object = {
         stroke:'#CCC',
        width:elementSize.output_format.width,
        height:elementSize.output_format.height,
        x:x+width+58+12,
        y:y,
        rx:4,
        fill:'#2C87D9',
        id:'figure',
        'data-id':'output_format',
        style:"filter:url(#figure_shadow)"
    };
    const newElement = document.createElementNS(xmlns,'rect') as SVGRectElement;
    newElement.setAttributeNS(null,'data-id','output_format');
    newElement.setAttributeNS(null,'data-type','output_format');

    for(const [key,value] of Object.entries(data)){
        newElement.setAttributeNS(null,key,value);
    };

    let arrow = document.createElementNS(xmlns,'path') as SVGPathElement;
    arrow.setAttributeNS(null,'stroke','#788896');
    arrow.setAttributeNS(null,'stroke-width','5.65');
    arrow.setAttributeNS(null,'fill','none');
    arrow.setAttributeNS(null,'stroke-linejoin','round');
    arrow.setAttributeNS(null,'stroke-linecap','round');
    arrow.setAttributeNS(null,'data-contact','endpoint-output_format');
    let drawn:string = `M${x+width+12} ${y+height/2},h20,v0,h20,${arrow_type.right}`;
    arrow.setAttributeNS(null,'d',drawn);
    arrow.setAttributeNS(null,'data-side','right');

    const output = listener(newElement);
    let text_output = create_text(output);
    group.appendChild(output);
    group.appendChild(text_output)

    svg?.appendChild(arrow);
    return group;
}

export const dragOutput = (coord:any,target:any=null)=>{
    const svg = document.querySelector(`#svg_board`) as SVGElement;
    const output = svg.querySelector('rect[data-id = "output_format"]') as SVGRectElement;
    const arrow = svg.querySelector('path[data-contact = "endpoint-output_format"]') as SVGPathElement;
    if(target?.dataset.type === "system_errors"){
        let drawn:string = `M${+coord.x + Math.abs(elementSize.system_error.width - 70)/2 + 70+12} ${+coord.y - 105},h20,v0,h20,${arrow_type.right}`;
        arrow.setAttributeNS(null,'d',drawn);
        output.setAttributeNS(null,'x',`${+coord.x + Math.abs(elementSize.system_error.width - 70)/2 + 70+12 + 58}`);
        output.setAttributeNS(null,'y',`${+coord.y - 140}`);
        move_text(output)

    }
    if(!target){
        let drawn:string = `M${+coord.x+70+12} ${+coord.y+35},h20,v0,h20,${arrow_type.right}`;
        arrow.setAttributeNS(null,'d',drawn);
        output.setAttributeNS(null,'x',`${+coord.x+70+58+12}`);
        output.setAttributeNS(null,'y',`${coord.y}`);
        move_text(output)
        

    }


}