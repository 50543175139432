import { matrix_status } from "settings/settings";
import styled, { css } from "styled-components/macro";
type IMatrixWrapper = {
  vertical_length: number;
  horizontal_length: number;
};
export const MatrixWrapper = styled.div<IMatrixWrapper>`
  position: relative;
  width:43vh;
  height:30vh;
//   overflow: hidden;
  display: grid;
  grid-auto-rows: calc(30vh / ${(props: IMatrixWrapper) => props.horizontal_length>=props.vertical_length ? props.horizontal_length : props.vertical_length} - 0.5vh);
  gap: 0.5vh;
  font-size:10px;
  align-content: end;
//   margin-left: -15x;
  margin-top: 30px;

  ${css`
    grid-template-columns: repeat(
      ${(props: IMatrixWrapper) => props.horizontal_length},
      calc(30vh / ${(props: IMatrixWrapper) => props.horizontal_length>=props.vertical_length ? props.horizontal_length : props.vertical_length} - 0.5vh)
    );
  `}
`;

export const Cell = styled.div<{status_id?:any}>`
  position: relative;
  /* width: 100%; */
  /* height:auto; */

  overflow: hidden;
  border-radius: 0.5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  font-size:1.5em;
  ${css`
      background-color:${(props:any) =>props.status_id !== 0 ? matrix_status[props.status_id-1].color:'transparent'};
      color:${(props:any) =>props.status_id !== 0 ? 'rgba(255, 255, 255, 1)':'#B2BDCD'}
  `}
`;
