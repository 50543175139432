interface IPanZoom {
  element: HTMLElement | null;
  minScale: number;
  maxScale: number;
  scaleSensitivity: number;
  transformation?: object;
}

interface IGetMatrix {
  scale: number;
  translateX: number;
  translateY: number;
}

interface IPan {
  state: any;
  originX: number;
  originY: number;
}

interface IPanBy {
  originX: number;
  originY: number;
}

interface IPanTo {
  originX: number;
  originY: number;
  scale: number;
}

interface IGetScale {
  scale: number;
  minScale: number;
  maxScale: number;
  scaleSensitivity: number;
  deltaScale: number;
}

interface IHasPositionChanged {
  pos: number;
  prevPos: number;
}

interface IValueInRange {
  minScale: number;
  maxScale: number;
  scale: number;
}

interface IGetTranslate {
  minScale: number;
  maxScale: number;
  scale: number;
}

interface IGetTranslateInner {
  pos: number;
  prevPos: number;
  translate: number;
}

interface IZoom {
  x: number;
  y: number;
  deltaScale: number;
}

const panZoom = ({
  element,
  minScale,
  maxScale,
  scaleSensitivity = 10,
  transformation,
}: IPanZoom) => {
  const state = {
    element,
    minScale,
    maxScale,
    scaleSensitivity,
    transformation: transformation
      ? { ...transformation }
      : {
          originX: 0,
          originY: 0,
          translateX: 0,
          translateY: 0,
          scale: 1,
        },
  };
  return Object.assign({}, canZoom(state), canPan(state), getData(state));
};

const getMatrix = ({ scale, translateX, translateY }: IGetMatrix) => {
  return `matrix(${scale}, 0, 0, ${scale}, ${translateX}, ${translateY})`;
};

const pan = ({ state, originX, originY }: IPan) => {
  state.transformation.translateX += originX;
  state.transformation.translateY += originY;
  state.element.style.transform = getMatrix({
    scale: state.transformation.scale,
    translateX: state.transformation.translateX,
    translateY: state.transformation.translateY,
  });
};

const canPan = (state: any) => ({
  panBy: ({ originX, originY }: IPanBy) => pan({ state, originX, originY }),
  panTo: ({ originX, originY, scale }: IPanTo) => {
    state.transformation.scale = scale;
    pan({
      state,
      originX: originX - state.transformation.translateX,
      originY: originY - state.transformation.translateY,
    });
  },
});

const getScale = ({
  scale,
  minScale,
  maxScale,
  scaleSensitivity,
  deltaScale,
}: IGetScale) => {
  let newScale = scale + deltaScale / (scaleSensitivity / scale);
  newScale = Math.max(minScale, Math.min(newScale, maxScale));
  return [scale, newScale];
};

const hasPositionChanged = ({ pos, prevPos }: IHasPositionChanged) =>
  pos !== prevPos;

const valueInRange = ({ minScale, maxScale, scale }: IValueInRange) =>
  scale <= maxScale && scale >= minScale;

const getTranslate = ({ minScale, maxScale, scale }: IGetTranslate) => ({
  pos,
  prevPos,
  translate,
}: IGetTranslateInner) =>
  valueInRange({ minScale, maxScale, scale }) &&
  hasPositionChanged({ pos, prevPos })
    ? translate + (pos - prevPos * scale) * (1 - 1 / scale)
    : translate;

const canZoom = (state: any) => ({
  zoom: ({ x, y, deltaScale }: IZoom) => {
    const { left, top } = state.element.getBoundingClientRect();
    const { minScale, maxScale, scaleSensitivity } = state;
    const [scale, newScale] = getScale({
      scale: state.transformation.scale,
      deltaScale,
      minScale,
      maxScale,
      scaleSensitivity,
    });
    const originX = x - left;
    const originY = y - top;
    const newOriginX = originX / scale;
    const newOriginY = originY / scale;
    const translate = getTranslate({ scale, minScale, maxScale });
    const translateX = translate({
      pos: originX,
      prevPos: state.transformation.originX,
      translate: state.transformation.translateX,
    });
    const translateY = translate({
      pos: originY,
      prevPos: state.transformation.originY,
      translate: state.transformation.translateY,
    });

    state.element.style.transformOrigin = `${newOriginX}px ${newOriginY}px`;
    state.element.style.transform = getMatrix({
      scale: newScale,
      translateX,
      translateY,
    });
    state.transformation = {
      originX: newOriginX,
      originY: newOriginY,
      translateX,
      translateY,
      scale: newScale,
    };

    return newScale;
  },
});

const getData = (state: any) => ({
  getTransformation: () => {
    const { transformation } = state;
    return transformation;
  },
});

export default panZoom;
