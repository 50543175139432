import {
    StrategyActionsTypes,
    StrategyBoardSidePanelContent
} from "../../../../types/typesStrategy";


const initialState: StrategyBoardSidePanelContent = {
    content_value:null,
}

const reducerPanelContent = (state = initialState, action: StrategyActionsTypes): StrategyBoardSidePanelContent => {
    switch (action.type) {

        case "STRATEGY_BOARD_SIDE_PANEL_CONTENT":
            const { content_value } = action.payload
            return {
                ...state,
                content_value
            };

        default:
            return state
    }
}

export default reducerPanelContent