import { Link } from 'react-router-dom';
import classes from './InfoTooltip.module.scss'

export const TooltipInfo = (props) => {
  return (
    <div className={props.sidePanelUp ? classes.TooltipInfo : props.sidePanelBottom ? [classes.TooltipInfo, classes.TooltipInfoAlternative].join(" ") : null}>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry.
      </p>
      <Link to={''} className={classes.TooltipInfoLink}>
        Lorem Ipsum: <span>lorem ipsum</span>
      </Link>
    </div>
  )
}