import { storage_key } from "../../../../../settings/settings";
import DB from "../../../../DB/Storage";
import { getArrowPropery, getContactId } from "./get_arrow_properties.handler";

export const remove_db = (element: SVGRectElement,arrows:NodeListOf<SVGPathElement>|null = null) => {
    const svg = document.querySelector('#svg_board') as SVGElement;

    const db = new DB(storage_key);

    const id = element.dataset.id;
    const arrow_input = svg.querySelector(`path[data-contact $= ${id}]`) as SVGPathElement;
    const data_bool  = arrow_input?.dataset.bool;
    const data_type = element.dataset.type

    const source_id = arrow_input?.dataset.contact?.split('-')?.[0];
    const validate = svg.querySelector(`rect[data-type = "validate"]`) as SVGRectElement;
    const wizard = svg.querySelector(`rect[data-type = "datawizard"]`) as SVGRectElement;
    db.fetch({ sourceId: id }).then((res: any) => {
        if (res.status && res.data) {

            if (Array.isArray(res.data)) {

                res.data.forEach((_data: any) => {

                    new DB(storage_key).remove({ sourceId: _data.sourceId });

                });

                new DB(storage_key).fetch({ targetId: id }).then((_res: any) => {
                    if (_res.status && _res.data) {

                        if (Array.isArray(_res.data)) {

                        } else {

                            new DB(storage_key).add([{
                                ..._res.data,
                                targetId: "",
                                overlays: [],
                                anchors: []
                            }])

                        }
                    }
                })
            } else {

                if(data_type === 'code_message'){
                    new DB(storage_key).fetch({type:data_type})
                    .then((res:any)=>{
                        if(res.status && res.data){
                            if(Array.isArray(res.data) && res.data.length>1){
                               
                                new DB(storage_key).fetch({sourceId:id})
                                .then((res_removed:any)=>{
                                    if(res_removed.status && res_removed.data){

                                        const {data:{code_message:{code}}} = res_removed.data
                                        new DB(storage_key).fetch({type:'code_message_list'})
                                        .then((res_msg_list:any)=>{
                                            if(res_msg_list.status && res_msg_list.data){
                                                const {data:{code_message_list}} = res_msg_list.data;
                                                const ref_msg_list = [...code_message_list]?.filter((f:any)=>f.code !== code)

                                                let new_msg_list = {
                                                    ...res_msg_list.data,
                                                    data:{
                                                        label: "code_message_list",
                                                        code_message_list: [...ref_msg_list],
        
                                                    }
                                                }
                                               new DB(storage_key).remove({ sourceId: id });
                                               new DB(storage_key).update({type:'code_message_list'},[new_msg_list])
                                            }
                                        })
                                    }
                                })
                                
                            }else{
                                new DB(storage_key).remove({ sourceId: id });
                                new DB(storage_key).remove({ type: 'code_message_list' });

                            }
                        }
                    })
                }else{
                    new DB(storage_key).remove({ sourceId: id });

                }
                
                new DB(storage_key).fetch({ targetId: id }).then((_res: any) => {
                    if (_res.status && _res.data) {


                        if (Array.isArray(_res.data)) {
                            
                        } else {
                           
                            if (data_bool) {
                                if (data_bool?.toUpperCase() === "YES") {

                                    new DB(storage_key).update({ sourceId: source_id, branchLabel: 'YES' }, [{
                                        ..._res.data,
                                        targetId: "",
                                        overlays: [],
                                        anchors: []
                                    }]);

                                }
                                if (data_bool?.toUpperCase() === "NO") {
                                    new DB(storage_key).update({ sourceId: source_id, branchLabel: 'NO' }, [{
                                        ..._res.data,
                                        targetId: "",
                                        overlays: [],
                                        anchors: []
                                    }])

                                }
                            } else {
                            
                                const {type} = _res.data;
                                let newData = [] as any[];
                                if(validate && !wizard && type === "startpoint"){
                                    const _arrow = svg.querySelector(`path[data-contact ^= "validate"]`) as SVGPathElement;
                                    const {contact} = getArrowPropery(_arrow);
                                    const {next} = getContactId(contact);

                                    newData.push({
                                        ..._res.data,
                                        targetId:next
                                    })
                                }else if(validate && wizard && type === "startpoint"){
                                    const _arrow = svg.querySelector(`path[data-contact ^= "datawizard"]`) as SVGPathElement;
                                    const {contact} = getArrowPropery(_arrow);
                                    const {next} = getContactId(contact);
                                    newData.push({
                                        ..._res.data,
                                        targetId:next
                                    })

                                }else{
                                    newData.push({
                                        ..._res.data,
                                        targetId: "",
                                        overlays: [],
                                        anchors: []
                                    })
                                }

                                new DB(storage_key).add(newData)

                            }
                        }


                    }
                    
                })
            }

        }else{

            if(arrows){
                new DB(storage_key).fetch({type:'startpoint'})
                .then((res:any)=>{
                    const {status,data} = res;

                    if(status && data){
                        arrows?.forEach((arrow:SVGPathElement)=>{
                            const {contact} = getArrowPropery(arrow);
                            const {prev,next} = getContactId(contact);
                            let newData = [] as any[];
                               
                                if(validate && !wizard && prev === "validate"){

                                    newData.push({
                                        ...data,
                                        targetId:next
                                    })
        
                                }else if(validate && wizard && prev === "datawizard"){
                                    newData.push({
                                        ...data,
                                        targetId:next
                                    })
        
                                }
                                if(newData.length>0){

                                    new DB(storage_key).add(newData);
                                }
                        })
                    }

                })
               
            }

        }
    })

}

export const remove_arrow_db = (contact: string) => {
    let source_id = contact?.split('-')?.[0];
    new DB(storage_key).fetch({ sourceId: source_id }).then((res: any) => {
        if (res.status && res.data) {
            let _obj: Array<any> = [];
            if (Array.isArray(res.data)) {
                let _mass = [...res.data];
                let f = _mass.filter((_f: any) => _f?.overlays?.[0]?.contact === contact);
                let index = _mass.indexOf(f?.[0]);

                _mass[index] = {
                    ...f?.[0],
                    anchors: [],
                    overlays: [],
                    targetId: ""
                }
                _obj.push(..._mass)
            } else {

                _obj.push({
                    ...res.data,
                    anchors: [],
                    overlays: [],
                    targetId: ""
                })
            }
            new DB(storage_key).update({ sourceId: source_id }, _obj)
        }
    })
}