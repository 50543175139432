import React, { FC, ReactChild, ReactFragment, ReactPortal } from "react";
import classes from "./Portal.module.scss";
import { createPortal } from "react-dom";

interface IPortal {
  children?:
    | Element
    | boolean
    | ReactChild
    | ReactFragment
    | ReactPortal
    | null
    | undefined;
}

const Portal: FC<IPortal> = ({ children }) => {
  return createPortal(
    <div className={classes.PortalOverlay}>{children}</div>,
    document.getElementById("portal-root") as HTMLDivElement
  );
};

export default Portal;
