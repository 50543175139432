import { combineReducers } from 'redux'
import undoable from 'redux-undo'
import reducerPanelInputStage from './InputStage/reducer'
import reducerPanelInputUpload from './InputUpload/reducer'
import reducerInputUploadProcess from './InputUploadProcess/reducer'
import reducerInputCreateObject from './InputCreateObject/reducer'

export default combineReducers({
    input_stage: reducerPanelInputStage,
    input_uploadFiles: reducerPanelInputUpload,
    input_uploadProcess: reducerInputUploadProcess,
    input_createObject: undoable( reducerInputCreateObject)
})