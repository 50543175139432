import React, { FC, useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./Table.module.scss";
import { icons } from "../../../settings/settings";
import Tooltip from "../../../components/UI/Tooltip/Tooltip";
import {
  activeModal as handleActiveModal,
  handleActiveTooltip,
  touchBlocked,
} from "../../../store/actions/action";
import {
  handleScoreCardAttributeData,
  handleScoreCardBlockedModalResponse,
  handleScoreCardActiveElement,
  handleScoreCardActiveRow,
  removeScoreCardNewRow,
  handleScoreCardRowEditableStatus,
  handleScoreCardRecoveryData,
  handleScoreCardRowWeigthEditableStatus,
  calculateScoreCardPoint,
} from "../../../store/actions/actionsScoreCard";
import { RootState } from "../../../store/combineReducer";
import { request } from "../../../helpers/request";
import { endpoints } from "../../../api/endpoints";
import Checkbox from "../../../components/UI/Checkbox/CheckboxV2";
import { Pen, Delete, Active, Cancel } from "../../../components/UI/Svg/Svg";

const AttributeLevel: FC = () => {
  const [criteriaTrusted] = useState<Array<any>>([{key:'Dynamic',value:false},{key:'Static',value:true}]);
  let copyState: any[] = [];

  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const {
    reducer: {
      mainTooltip: { type },
    },
    reducerScoreCard: {
      scoreCardAttributeData: { scoreCardAttributeData },
      scoreCardLevel: { level },
      scoreCardLevelDataId: { criteriaId, criteriaType },
      scoreCardActiveRow: { rowIndex, rowType, rowId },
      scoreCardActiveElement,
      scoreCardRecoveryData: { recoveryData },
      scoreCardBlockedModalResponse: { response },
    },
  } = state;

  const refreshHandlerCallback = useCallback(
    (id: number | null) => {
      const {
        score_card: { attribute },
      } = endpoints;

      if (id !== null) {
        const dataWithoutActiveCriteria = scoreCardAttributeData?.filter(
          (element: any) => element.score_card_criteria_id !== id
        );

        request
          .get(attribute.getAll(id))
          .then((result) => {
            const { response } = result;
            const combinedData = dataWithoutActiveCriteria?.concat(response);
            dispatch(
              handleScoreCardAttributeData({
                scoreCardAttributeData: combinedData,
              })
            );
          })
          .catch((error) => {
            console.log("error " + error);
          });
      }

      dispatch(
        handleScoreCardActiveRow({ rowIndex: null, rowType: null, rowId: null })
      );
      dispatch(handleScoreCardActiveElement({ name: null }));
      dispatch(
        handleActiveTooltip({ childId: null, childDataId: null, type: null })
      );
      dispatch(touchBlocked({ status: false }));
      dispatch(handleScoreCardRecoveryData({ recoveryData: null }));
    },
    [dispatch, scoreCardAttributeData]
  );

  const applyHandlerCallback = useCallback(
    (id: number | null) => {
      const {
        score_card: { attribute },
      } = endpoints;

      let newData = scoreCardAttributeData?.find(
        (element: any) => element.newRow === true
      );
      let updatedData = scoreCardAttributeData?.find(
        (element: any) => element.editable === true && element.newRow === false
      );
      let weightUpdatedData = scoreCardAttributeData?.find(
        (element: any) => element.weightEditStatus === true
      );

      if (newData && !newData.label) {
        dispatch(
          handleActiveTooltip({
            childId: `attribute-label-${rowIndex}`,
            childDataId: null,
            type: "validation",
          })
        );
        dispatch(handleScoreCardBlockedModalResponse({ response: null }));
      }

      if (newData && newData.label && type !== "validation") {
        request
          .post(attribute.post, newData)
          .then(() => {
            refreshHandlerCallback(id);
          })
          .catch((error) => {
            console.log("error ", error);
          });
      }

      if (updatedData) {
        request
          .patch(attribute.patch(updatedData.id), updatedData)
          .then(() => {
            refreshHandlerCallback(id);
          })
          .catch((error) => {
            console.log("error ", error);
          });
      }

      if (weightUpdatedData) {
        request
          .patch(attribute.patch(weightUpdatedData.id), weightUpdatedData)
          .then(() => {
            refreshHandlerCallback(id);
          })
          .catch((error) => {
            console.log("error ", error);
          });
      }
    },
    [dispatch, rowIndex, type, refreshHandlerCallback, scoreCardAttributeData]
  );

  const cancelHandlerCallback = useCallback(
    (rowType: string | null, rowId: number | null, level: number) => {
      if (rowType === "new") {
        dispatch(removeScoreCardNewRow(level));
      }

      if (rowType === "edit") {
        let specificData = scoreCardAttributeData?.find(
          (element: any) => element.id === rowId
        );
        let copyState = [...scoreCardAttributeData];
        let indexOfSpecificData = copyState.indexOf(specificData);

        copyState[indexOfSpecificData] = recoveryData;
        dispatch(
          handleScoreCardAttributeData({ scoreCardAttributeData: copyState })
        );
        dispatch(handleScoreCardRowEditableStatus(level, rowId, false));
        dispatch(handleScoreCardRowWeigthEditableStatus(level, rowId, false));
      }

      if (rowType !== null) {
        dispatch(
          handleActiveTooltip({ childId: null, childDataId: null, type: null })
        );
        dispatch(
          handleScoreCardActiveRow({
            rowIndex: null,
            rowType: null,
            rowId: null,
          })
        );
        dispatch(handleScoreCardActiveElement({ name: null }));
        dispatch(handleScoreCardRecoveryData({ recoveryData: null }));
        dispatch(handleScoreCardBlockedModalResponse({ response: null }));
        dispatch(touchBlocked({ status: false }));
      }
    },
    [dispatch, recoveryData, scoreCardAttributeData]
  );

  const deleteHandlerCallback = useCallback(
    (index: number | null, id: number | null, elementId: string | null) => {
      const {
        score_card: { attribute },
      } = endpoints;

      if (scoreCardActiveElement.name === null && type === null) {
        dispatch(handleScoreCardActiveElement({ name: elementId }));
        dispatch(
          handleScoreCardActiveRow({
            rowIndex: index,
            rowType: "delete",
            rowId: id,
          })
        );

        if (response === null) {
          dispatch(handleActiveModal({ activeModal: "delete_score_modal" }));
        }
      }

      if (response === "delete" && id !== null) {
        dispatch(handleScoreCardBlockedModalResponse({ response: null }));
        request
          .delete(attribute.delete(id))
          .then(() => {
            refreshHandlerCallback(criteriaId);
          })
          .catch((error) => {
            console.log("error ", error);
          });
      }

      if (scoreCardActiveElement.name !== null && type !== "validation") {
        if (type === null)
          dispatch(
            handleActiveTooltip({
              childId: scoreCardActiveElement.name,
              childDataId: null,
              type: "saveChanges",
            })
          );
        if (type === "saveChanges") {
          dispatch(
            handleActiveTooltip({
              childId: null,
              childDataId: null,
              type: null,
            })
          );
          setTimeout(() => {
            dispatch(
              handleActiveTooltip({
                childId: scoreCardActiveElement.name,
                childDataId: null,
                type: "saveChanges",
              })
            );
          }, 50);
        }
      }
    },
    [
      criteriaId,
      dispatch,
      refreshHandlerCallback,
      response,
      type,
      scoreCardActiveElement.name,
    ]
  );

  const keyboardHandlerCallback = useCallback(
    (event: any) => {
      const { keyCode } = event;

      if (keyCode === 27) {
        cancelHandlerCallback(rowType, rowId, level);
      }

      if (keyCode === 13) {
        applyHandlerCallback(criteriaId);
      }
    },
    [
      level,
      rowType,
      rowId,
      applyHandlerCallback,
      cancelHandlerCallback,
      criteriaId,
    ]
  );

  const mouseClickHandlerCallback = useCallback(
    (event: any) => {
      const {
        target: { localName, id },
      } = event;

      if (localName === "img" && id === "score_blocked_modal_close") {
        dispatch(handleScoreCardActiveElement({ name: null }));
        dispatch(
          handleScoreCardActiveRow({
            rowIndex: null,
            rowType: null,
            rowId: null,
          })
        );
      }
    },
    [dispatch]
  );

  useEffect(() => {
    window.addEventListener("click", mouseClickHandlerCallback);
    window.addEventListener("keydown", keyboardHandlerCallback);

    return () => {
      window.removeEventListener("click", mouseClickHandlerCallback);
      window.removeEventListener("keydown", keyboardHandlerCallback);
    };
  }, [type, dispatch, keyboardHandlerCallback, mouseClickHandlerCallback]);

  useEffect(() => {
    if (response === "save") {
      applyHandlerCallback(criteriaId);
    }

    if (response === "cancel") {
      cancelHandlerCallback(rowType, rowId, level);
    }

    if (response === "delete") {
      deleteHandlerCallback(rowIndex, rowId, scoreCardActiveElement.name);
    }

    if (response === "cancel_delete") {
      dispatch(handleScoreCardActiveElement({ name: null }));
      dispatch(handleScoreCardBlockedModalResponse({ response: null }));
      dispatch(
        handleScoreCardActiveRow({ rowIndex: null, rowType: null, rowId: null })
      );
      dispatch(
        handleActiveTooltip({ childId: null, childDataId: null, type: null })
      );
    }
  }, [
    dispatch,
    response,
    rowIndex,
    rowType,
    rowId,
    level,
    criteriaId,
    scoreCardActiveElement.name,
    applyHandlerCallback,
    cancelHandlerCallback,
    deleteHandlerCallback,
  ]);

  const numberTopArrowHandler = (
    id: number,
    level: number,
    inputName: string,
    elementId: string,
    index: number
  ) => {
    if (
      (scoreCardActiveElement.name === null ||
        scoreCardActiveElement.name === elementId ||
        rowIndex === index) &&
      type !== "validation"
    ) {
      dispatch(handleScoreCardActiveElement({ name: elementId }));
      dispatch(touchBlocked({ status: true }));

      if (inputName === "weight") {
        dispatch(handleScoreCardRowWeigthEditableStatus(level, id, true));
        dispatch(
          handleScoreCardActiveRow({
            rowIndex: index,
            rowType: "edit",
            rowId: id,
          })
        );

        if (recoveryData === null) {
          let specificData = scoreCardAttributeData.find(
            (element: any) => element.id === id
          );
          dispatch(handleScoreCardRecoveryData({ recoveryData: specificData }));
        }
      }

      let specificData = scoreCardAttributeData.find(
        (element: any) => element.id === id
      );
      let copyState = [...scoreCardAttributeData];
      let copyOfSpecificData = { ...specificData };
      let indexOfSpecificData = copyState.indexOf(specificData);

      if (inputName === "weight")
        copyOfSpecificData.weight = (+copyOfSpecificData.weight + 1).toFixed(2);
      if (inputName === "less-value")
        copyOfSpecificData.value.less_value = (
          +copyOfSpecificData.value.less_value + 1
        ).toFixed(2);
      if (inputName === "more-value")
        copyOfSpecificData.value.more_value = (
          +copyOfSpecificData.value.more_value + 1
        ).toFixed(2);
      if (inputName === "value")
        copyOfSpecificData.value.value = (
          +copyOfSpecificData.value.value + 1
        ).toFixed(2);

      copyState[indexOfSpecificData] = copyOfSpecificData;
      dispatch(
        handleScoreCardAttributeData({ scoreCardAttributeData: copyState })
      );
      dispatch(
        calculateScoreCardPoint({
          levelOfPointData: level,
          pointRowId: id,
          pointParentId: criteriaId,
        })
      );
      dispatch(
        handleActiveTooltip({ childId: null, childDataId: null, type: null })
      );
    }

    if (
      scoreCardActiveElement.name !== elementId &&
      type !== "validation" &&
      rowIndex !== index
    ) {
      if (type === null)
        dispatch(
          handleActiveTooltip({
            childId: scoreCardActiveElement.name,
            childDataId: null,
            type: "saveChanges",
          })
        );
      if (type === "saveChanges") {
        dispatch(
          handleActiveTooltip({ childId: null, childDataId: null, type: null })
        );
        setTimeout(() => {
          dispatch(
            handleActiveTooltip({
              childId: scoreCardActiveElement.name,
              childDataId: null,
              type: "saveChanges",
            })
          );
        }, 50);
      }
    }
  };

  const numberBottomArrowHandler = (
    id: number,
    level: number,
    inputName: string,
    elementId: string,
    index: number
  ) => {
    if (
      (scoreCardActiveElement.name === null ||
        scoreCardActiveElement.name === elementId ||
        rowIndex === index) &&
      type !== "validation"
    ) {
      dispatch(handleScoreCardActiveElement({ name: elementId }));
      dispatch(touchBlocked({ status: true }));

      if (inputName === "weight") {
        dispatch(handleScoreCardRowWeigthEditableStatus(level, id, true));
        dispatch(
          handleScoreCardActiveRow({
            rowIndex: index,
            rowType: "edit",
            rowId: id,
          })
        );

        if (recoveryData === null) {
          let specificData = scoreCardAttributeData.find(
            (element: any) => element.id === id
          );
          dispatch(handleScoreCardRecoveryData({ recoveryData: specificData }));
        }
      }

      let specificData = scoreCardAttributeData.find(
        (element: any) => element.id === id
      );
      let copyState = [...scoreCardAttributeData];
      let copyOfSpecificData = { ...specificData };
      let indexOfSpecificData = copyState.indexOf(specificData);

      if (inputName === "weight")
        copyOfSpecificData.weight = (+copyOfSpecificData.weight - 1).toFixed(2);

      if (inputName === "less-value")
        copyOfSpecificData.value.less_value = (
          +copyOfSpecificData.value.less_value - 1
        ).toFixed(2);
      if (inputName === "more-value")
        copyOfSpecificData.value.more_value =
          +copyOfSpecificData.value.more_value > 1
            ? (+copyOfSpecificData.value.more_value - 1).toFixed(2)
            : "0";
      if (inputName === "value")
        copyOfSpecificData.value.value =
          +copyOfSpecificData.value.value > 1
            ? (+copyOfSpecificData.value.value - 1).toFixed(2)
            : "0";

      copyState[indexOfSpecificData] = copyOfSpecificData;
      dispatch(
        handleScoreCardAttributeData({ scoreCardAttributeData: copyState })
      );
      dispatch(
        calculateScoreCardPoint({
          levelOfPointData: level,
          pointRowId: id,
          pointParentId: criteriaId,
        })
      );
      dispatch(
        handleActiveTooltip({ childId: null, childDataId: null, type: null })
      );
    }

    if (
      scoreCardActiveElement.name !== elementId &&
      type !== "validation" &&
      rowIndex !== index
    ) {
      if (type === null)
        dispatch(
          handleActiveTooltip({
            childId: scoreCardActiveElement.name,
            childDataId: null,
            type: "saveChanges",
          })
        );
      if (type === "saveChanges") {
        dispatch(
          handleActiveTooltip({ childId: null, childDataId: null, type: null })
        );
        setTimeout(() => {
          dispatch(
            handleActiveTooltip({
              childId: scoreCardActiveElement.name,
              childDataId: null,
              type: "saveChanges",
            })
          );
        }, 50);
      }
    }
  };

  const editHandler = (index: number, id: number, level: number) => {
    if (
      scoreCardActiveElement.name === null &&
      type === null &&
      rowType !== null
    ) {
      dispatch(handleScoreCardRowEditableStatus(level, rowId, false));
      if (rowType === "new") dispatch(removeScoreCardNewRow(level));
      dispatch(
        handleScoreCardActiveRow({
          rowIndex: index,
          rowType: "edit",
          rowId: id,
        })
      );
      dispatch(handleScoreCardRowEditableStatus(level, id, true));

      let specificData = scoreCardAttributeData?.find(
        (element: any) => element.id === id
      );

      dispatch(handleScoreCardRecoveryData({ recoveryData: specificData }));
    }

    if (
      scoreCardActiveElement.name === null &&
      type === null &&
      rowType === null
    ) {
      dispatch(handleScoreCardRowEditableStatus(level, id, true));

      let specificData = scoreCardAttributeData?.find(
        (element: any) => element.id === id
      );

      dispatch(handleScoreCardRecoveryData({ recoveryData: specificData }));
      dispatch(
        handleScoreCardActiveRow({
          rowIndex: index,
          rowType: "edit",
          rowId: id,
        })
      );
    }

    if (scoreCardActiveElement.name !== null && type !== "validation") {
      if (type === null)
        dispatch(
          handleActiveTooltip({
            childId: scoreCardActiveElement.name,
            childDataId: null,
            type: "saveChanges",
          })
        );
      if (type === "saveChanges") {
        dispatch(
          handleActiveTooltip({ childId: null, childDataId: null, type: null })
        );
        setTimeout(() => {
          dispatch(
            handleActiveTooltip({
              childId: scoreCardActiveElement.name,
              childDataId: null,
              type: "saveChanges",
            })
          );
        }, 50);
      }
    }
  };

  const nameHandler = (value: string, id: number, elementId: string) => {
    dispatch(handleScoreCardActiveElement({ name: elementId }));
    dispatch(touchBlocked({ status: true }));

    let specificData = scoreCardAttributeData.find(
      (element: any) => element.id === id
    );
    let copyState = [...scoreCardAttributeData];
    let copyOfSpecificData = { ...specificData };
    let indexOfSpecificData = copyState.indexOf(specificData);

    copyOfSpecificData.label = value;

    copyState[indexOfSpecificData] = copyOfSpecificData;
    dispatch(
      handleScoreCardAttributeData({ scoreCardAttributeData: copyState })
    );

    if (value && type === "validation") {
      dispatch(
        handleActiveTooltip({ childId: null, childDataId: null, type: null })
      );
    }
    if (!value) {
      dispatch(
        handleActiveTooltip({
          childId: elementId,
          childDataId: null,
          type: "validation",
        })
      );
    }
  };

  const weightHandler = (
    value: string,
    id: number,
    level: number,
    elementId: string,
    index: number
  ) => {
    if (
      (scoreCardActiveElement.name === elementId ||
        scoreCardActiveElement.name === null ||
        rowIndex === index) &&
        type !== "validation"
    ) {
      
      if (rowType === null) {
        dispatch(handleScoreCardRowWeigthEditableStatus(level, id, true));
        dispatch(
          handleScoreCardActiveRow({
            rowIndex: index,
            rowType: "edit",
            rowId: id,
          })
        );
      }
      dispatch(handleScoreCardActiveElement({ name: elementId }));
      dispatch(touchBlocked({ status: true }));

      let specificData = scoreCardAttributeData.find(
        (element: any) => element.id === id
      );
      if (recoveryData === null)
        dispatch(handleScoreCardRecoveryData({ recoveryData: specificData }));

      let copyState = [...scoreCardAttributeData];
      let copyOfSpecificData = { ...specificData };
      let indexOfSpecificData = copyState.indexOf(specificData);
      let _value = value.indexOf(".") > -1
      ? value.substring(0, value.indexOf(".") + 3)
      : value

      if(+value>=0){
        if(+value <= 100){
          copyOfSpecificData.weight = _value
        }
      }else{
        if(+value >= -100){
          copyOfSpecificData.weight = _value
        }
      }

      copyState[indexOfSpecificData] = copyOfSpecificData;
      dispatch(
        handleScoreCardAttributeData({ scoreCardAttributeData: copyState })
      );
      dispatch(
        calculateScoreCardPoint({
          levelOfPointData: level,
          pointRowId: id,
          pointParentId: criteriaId,
        })
      );
    }else{
      let specificData = scoreCardAttributeData.find(
        (element: any) => element.id === id
      );
      if (recoveryData === null)
        dispatch(handleScoreCardRecoveryData({ recoveryData: specificData }));

      let copyState = [...scoreCardAttributeData];
      let copyOfSpecificData = { ...specificData };
      let indexOfSpecificData = copyState.indexOf(specificData);
      let _value = value.indexOf(".") > -1
      ? value.substring(0, value.indexOf(".") + 3)
      : value
        if(+value>=0){
          if(+value <= 100){
            copyOfSpecificData.weight = _value
          }
        }else{
          if(+value >= -100){
            copyOfSpecificData.weight = _value
          }
        }
        

      copyState[indexOfSpecificData] = copyOfSpecificData;
      dispatch(
        handleScoreCardAttributeData({ scoreCardAttributeData: copyState })
      );
      dispatch(
        calculateScoreCardPoint({
          levelOfPointData: level,
          pointRowId: id,
          pointParentId: criteriaId,
        })
      );
    }

    // if (
    //   scoreCardActiveElement.name !== elementId &&
    //   type !== "validation" &&
    //   rowIndex !== index 
    // ) {

    //   if (type === null)
    //   console.log(value)
    //     dispatch(
    //       handleActiveTooltip({
    //         childId: scoreCardActiveElement.name,
    //         childDataId: null,
    //         type: "saveChanges",
    //       })
    //     );
    //   if (type === "saveChanges") {
    //     dispatch(
    //       handleActiveTooltip({ childId: null, childDataId: null, type: null })
    //     );
    //     setTimeout(() => {
    //       dispatch(
    //         handleActiveTooltip({
    //           childId: scoreCardActiveElement.name,
    //           childDataId: null,
    //           type: "saveChanges",
    //         })
    //       );
    //     }, 50);
    //   }
    // }
  };

  // const trustedWeightHandler = (
  //   value: boolean,
  //   id: number,
  //   elementId: string
  // ) => {
  //   dispatch(handleScoreCardActiveElement({ name: elementId }));
  //   dispatch(touchBlocked({ status: true }));

  //   let specificData = scoreCardAttributeData.find(
  //     (element: any) => element.id === id
  //   );
  //   let copyState = [...scoreCardAttributeData];
  //   let copyOfSpecificData = { ...specificData };
  //   let indexOfSpecificData = copyState.indexOf(specificData);

  //   copyOfSpecificData.is_trusted_weight = value;

  //   copyState[indexOfSpecificData] = copyOfSpecificData;
  //   dispatch(
  //     handleScoreCardAttributeData({ scoreCardAttributeData: copyState })
  //   );
  // };

  const lessValueHandler = (value: string, id: number, elementId: string) => {
    dispatch(handleScoreCardActiveElement({ name: elementId }));

    let specificData = scoreCardAttributeData.find(
      (element: any) => element.id === id
    );
    let copyState = [...scoreCardAttributeData];
    let copyOfSpecificData = { ...specificData };
    let indexOfSpecificData = copyState.indexOf(specificData);

    copyOfSpecificData.value.less_value =
      value.indexOf(".") > -1
        ? value.substring(0, value.indexOf(".") + 3)
        : value;

    copyState[indexOfSpecificData] = copyOfSpecificData;
    dispatch(
      handleScoreCardAttributeData({ scoreCardAttributeData: copyState })
    );
  };

  const moreValueHandler = (value: string, id: number, elementId: string) => {
    dispatch(handleScoreCardActiveElement({ name: elementId }));

    let specificData = scoreCardAttributeData.find(
      (element: any) => element.id === id
    );
    let copyState = [...scoreCardAttributeData];
    let copyOfSpecificData = { ...specificData };
    let indexOfSpecificData = copyState.indexOf(specificData);

    copyOfSpecificData.value.more_value =
      +value >= 0
        ? value.indexOf(".") > -1
          ? value.substring(0, value.indexOf(".") + 3)
          : value
        : "";

    copyState[indexOfSpecificData] = copyOfSpecificData;
    dispatch(
      handleScoreCardAttributeData({ scoreCardAttributeData: copyState })
    );
  };

  const valueHandler = (value: string, id: number, elementId: string) => {
    dispatch(handleScoreCardActiveElement({ name: elementId }));

    let specificData = scoreCardAttributeData.find(
      (element: any) => element.id === id
    );
    let copyState = [...scoreCardAttributeData];
    let copyOfSpecificData = { ...specificData };
    let indexOfSpecificData = copyState.indexOf(specificData);

    copyOfSpecificData.value.value = value;

    copyState[indexOfSpecificData] = copyOfSpecificData;
    dispatch(
      handleScoreCardAttributeData({ scoreCardAttributeData: copyState })
    );
  };


  const trustedTopArrowHandler = (id:string, index: number) => {

    const typeName = document.getElementById(id)?.innerHTML;
    const presentIndex = criteriaTrusted.findIndex(
      (element: any) => element.key === typeName
    );
    const previousIndex = presentIndex - 1;

    const firstIndex = 0

    if (presentIndex === firstIndex) {
      return;
    }

    copyState = [...scoreCardAttributeData.filter(
        (element: any) => element.score_card_criteria_id === criteriaId
    )];

    copyState[index].is_trusted_weight = criteriaTrusted[previousIndex]?.value;

    dispatch(handleScoreCardAttributeData({ scoreCardAttributeData: copyState }));
    dispatch(handleScoreCardActiveElement({ name: id }));
    dispatch(touchBlocked({ status: true }));
  };

  const trustedBottomArrowHandler = (id:string,index: number) => {
    
    const typeName = document.getElementById(id)?.innerHTML;
    const presentIndex = criteriaTrusted.findIndex(
      (element: any) => element.key === typeName
    );
    const nextIndex = presentIndex + 1;
    const lastIndex = criteriaTrusted.length - 1;

    if (presentIndex === lastIndex) {
      return;
    }

    copyState = [...scoreCardAttributeData.filter(
      (element: any) => element.score_card_criteria_id === criteriaId
  )];

  copyState[index].is_trusted_weight = criteriaTrusted[nextIndex]?.value;

  dispatch(handleScoreCardAttributeData({ scoreCardAttributeData: copyState }));
  dispatch(handleScoreCardActiveElement({ name: id }));
  dispatch(touchBlocked({ status: true }));
  };
  return (
    <div className={classes.LastLevel}>
      <div className={classes.Head}>
        <span className={classes.HeadColumn}>Attribute Name</span>
        {criteriaType === 1 ? (
          <>
            <span className={classes.HeadColumn}>less value</span>
            <span className={classes.HeadColumn}>more value</span>
          </>
        ) : (
          criteriaType === 2 && (
            <span className={classes.HeadColumn}>value</span>
          )
        )}
        <span className={classes.HeadColumn}>Impact (%)</span>
        <span className={classes.HeadColumn}>point</span>
        <span className={classes.HeadColumn}>trusted</span>
        <span className={classes.HeadColumn} />
      </div>
      {scoreCardAttributeData?.filter(
        (element: any) => element.score_card_criteria_id === criteriaId
      ).length > 0 &&
        scoreCardAttributeData
          .filter(
            (element: any) => element.score_card_criteria_id === criteriaId
          )
          .filter((element: any) => element.label === "in all other cases")
          .map((element: any, index: number) => {
            const {
              id,
              label,
              weight,
              point,
              is_trusted_weight,
              weightEditStatus,
            } = element;

            return (
              <div key={index} className={classes.Row} id={`table_row_${id}`}>
                <div className={classes.Column}>
                  <span className={classes.OtherLabel}>{label}</span>
                </div>
                {criteriaType === 1 && (
                  <>
                    <div className={classes.LessValueColumn} />
                    <div className={classes.MoreValueColumn} />
                  </>
                )}
                {criteriaType === 2 && (
                  <div className={classes.AttributeValueColumn} />
                )}
                <div className={classes.AttributeWeightColumn}>
                  <Tooltip
                    children={
                      <input
                        type="number"
                        id={`attribute-weight-${index}`}
                        tabIndex={criteriaType === 1 ? 4 : 3}
                        value={weight}
                        onChange={(event) =>
                          weightHandler(
                            event.target.value,
                            id,
                            level,
                            `attribute-weight-${index}`,
                            index
                          )
                        }
                        disabled={type === "validation"}
                      />
                    }
                  />
                  <button
                    className={classes.ArrowTop}
                    onClick={() =>
                      numberTopArrowHandler(
                        id,
                        level,
                        "weight",
                        `attribute-weight-${index}`,
                        index
                      )
                    }
                    disabled={type === "validation"}
                  >
                    <img src={icons.arrowTop} alt="Arrow top icon" />
                  </button>
                  <button
                    className={classes.ArrowBottom}
                    onClick={() =>
                      numberBottomArrowHandler(
                        id,
                        level,
                        "weight",
                        `attribute-weight-${index}`,
                        index
                      )
                    }
                    disabled={type === "validation"}
                  >
                    <img src={icons.arrowBottom} alt="Arrow bottom icon" />
                  </button>
                </div>
                <div className={classes.AttributePointColumn}>
                  <span>{point}</span>
                </div>
                <div className={classes.AttributeTrustColumn}>
                  <Tooltip
                    children={
                      <Checkbox
                        id={`attribute-trusted-weight-${index}`}
                        label={"Static"}
                        unCheckedPrefix={"Dynamic"}
                        value={is_trusted_weight}
                        disabled
                      />
                    }
                  />
                </div>
                <div className={classes.Actions}>
                  {weightEditStatus && (
                    <>
                      <Active
                        id={`score-apply-${index}`}
                        style={
                          scoreCardActiveElement.name === null ||
                          type === "validation"
                            ? { opacity: 0, pointerEvents: "none" }
                            : { opacity: 1 }
                        }
                        handler={() => applyHandlerCallback(criteriaId)}
                      />
                      <Cancel
                        id={`score-cancel-${index}`}
                        handler={() =>
                          cancelHandlerCallback(rowType, id, level)
                        }
                      />
                    </>
                  )}
                </div>
              </div>
            );
          })}
      {scoreCardAttributeData?.filter(
        (element: any) => element.score_card_criteria_id === criteriaId
      ).length > 0 ? (
        scoreCardAttributeData
          ?.filter(
            (element: any) => element.score_card_criteria_id === criteriaId
          )
          .filter((element: any) => element.label !== "in all other cases")
          .map((element: any, index: number) => {
            const {
              id,
              label,
              weight,
              point,
              is_trusted_weight,
              value: { less_value, more_value, value },
              newRow,
              editable,
              weightEditStatus,
            } = element;
            let weightTrimmed =
              weight.indexOf(".") > -1
                ? weight.substring(0, weight.indexOf(".") + 3)
                : weight;
            let pointTrimmed = parseFloat(point).toFixed(2);
            let lessValueTrimmed =
              less_value && less_value.indexOf(".") > -1
                ? less_value.substring(0, less_value.indexOf(".") + 3)
                : less_value;
            let moreValueTrimmed =
              more_value && more_value.indexOf(".") > -1
                ? more_value.substring(0, more_value.indexOf(".") + 3)
                : more_value;

            return (
              <div key={id ?? 0} className={classes.Row} id={`table_row_${id}`}>
                <div className={classes.Column}>
                  <Tooltip
                    children={
                      <input
                        id={`attribute-label-${index}`}
                        type="text"
                        defaultValue={label}
                        tabIndex={1}
                        onChange={(event) =>
                          nameHandler(
                            event.target.value,
                            id,
                            `attribute-label-${index}`
                          )
                        }
                        onKeyDown={(e) => {
                          if (!e.key.match(/[a-zA-ZəƏöÖğĞüÜçÇşŞ0-9_]/)) {
                            e.preventDefault();
                          }
                        }}
                        onPaste={(e) => {e.preventDefault(); return false;}}
                        onDrop={(e) => {e.preventDefault(); return false;}}
                        autoComplete="off"
                        disabled={!editable}
                      />
                    }
                  />
                </div>
                {criteriaType === 1 && (
                  <>
                    <div className={classes.LessValueColumn}>
                      <Tooltip
                        children={
                          <input
                            type="number"
                            id={`attribute-less-value-${index}`}
                            tabIndex={2}
                            value={lessValueTrimmed}
                            onChange={(event) =>
                              lessValueHandler(
                                event.target.value,
                                id,
                                `attribute-less-value-${index}`
                              )
                            }
                            disabled={!editable || type === "validation"}
                          />
                        }
                      />
                      <button
                        className={classes.ArrowTop}
                        onClick={() =>
                          numberTopArrowHandler(
                            id,
                            level,
                            "less-value",
                            `attribute-less-value-${index}`,
                            index
                          )
                        }
                        disabled={!editable || type === "validation"}
                      >
                        <img src={icons.arrowTop} alt="Arrow top icon" />
                      </button>
                      <button
                        className={classes.ArrowBottom}
                        onClick={() =>
                          numberBottomArrowHandler(
                            id,
                            level,
                            "less-value",
                            `attribute-less-value-${index}`,
                            index
                          )
                        }
                        disabled={!editable || type === "validation"}
                      >
                        <img src={icons.arrowBottom} alt="Arrow bottom icon" />
                      </button>
                    </div>
                    <div className={classes.MoreValueColumn}>
                      <Tooltip
                        children={
                          <input
                            type="number"
                            id={`attribute-more-value-${index}`}
                            tabIndex={3}
                            value={moreValueTrimmed}
                            onChange={(event) =>
                              moreValueHandler(
                                event.target.value,
                                id,
                                `attribute-more-value-${index}`
                              )
                            }
                            disabled={!editable || type === "validation"}
                          />
                        }
                      />
                      <button
                        className={classes.ArrowTop}
                        onClick={() =>
                          numberTopArrowHandler(
                            id,
                            level,
                            "more-value",
                            `attribute-more-value-${index}`,
                            index
                          )
                        }
                        disabled={!editable || type === "validation"}
                      >
                        <img src={icons.arrowTop} alt="Arrow top icon" />
                      </button>
                      <button
                        className={classes.ArrowBottom}
                        onClick={() =>
                          numberBottomArrowHandler(
                            id,
                            level,
                            "more-value",
                            `attribute-more-value-${index}`,
                            index
                          )
                        }
                        disabled={!editable || type === "validation"}
                      >
                        <img src={icons.arrowBottom} alt="Arrow bottom icon" />
                      </button>
                    </div>
                  </>
                )}
                {criteriaType === 2 && (
                  <div className={classes.AttributeValueColumn}>
                    <Tooltip
                      children={
                        <input
                          type="text"
                          id={`attribute-value-${index}`}
                          tabIndex={2}
                          value={value}
                          onChange={(event) =>
                            valueHandler(
                              event.target.value,
                              id,
                              `attribute-value-${index}`
                            )
                          }
                          onKeyDown={(e) => {
                            if (!e.key.match(/[a-zA-ZəƏöÖğĞüÜçÇşŞ0-9_]/)) {
                              e.preventDefault();
                            }
                          }}
                          disabled={!editable || type === "validation"}
                        />
                      }
                    />
                  </div>
                )}
                <div className={classes.AttributeWeightColumn}>
                  <Tooltip
                    children={
                      <input
                        type="number"
                        id={`attribute-weight-${index}`}
                        tabIndex={criteriaType === 1 ? 4 : 3}
                        value={weightTrimmed}
                        onChange={(event) =>
                          weightHandler(
                            event.target.value,
                            id,
                            level,
                            `attribute-weight-${index}`,
                            index
                          )
                        }
                        disabled={type === "validation"}
                      />
                    }
                  />
                  <button
                    className={classes.ArrowTop}
                    onClick={() =>
                      numberTopArrowHandler(
                        id,
                        level,
                        "weight",
                        `attribute-weight-${index}`,
                        index
                      )
                    }
                    disabled={type === "validation"}
                  >
                    <img src={icons.arrowTop} alt="Arrow top icon" />
                  </button>
                  <button
                    className={classes.ArrowBottom}
                    onClick={() =>
                      numberBottomArrowHandler(
                        id,
                        level,
                        "weight",
                        `attribute-weight-${index}`,
                        index
                      )
                    }
                    disabled={type === "validation"}
                  >
                    <img src={icons.arrowBottom} alt="Arrow bottom icon" />
                  </button>
                </div>
                <div className={classes.AttributePointColumn}>
                  <span>{pointTrimmed}</span>
                </div>
                <div className={classes.AttributeTrustColumn}>
                  {/* <Tooltip
                    children={
                      <Checkbox
                        id={`attribute-trusted-weight-${index}`}
                        label={"static"}
                        unCheckedPrefix={"dynamic"}
                        value={is_trusted_weight}
                        onChange={(event) =>
                          trustedWeightHandler(
                            event,
                            id,
                            `attribute-trusted-weight-${index}`
                          )
                        }
                        disabled={!editable || type === "validation"}
                      />
                    }
                  /> */}

                  <Tooltip
                    children={
                      <div
                      id={`attribute-trusted-w-${index}`}
                      className={
                          editable 
                          // &&
                          // !scoreCardAtrributeAvailability.status &&
                          // type !== "validation"
                            ? [classes.AttributeTrusted, classes.Active].join(" ")
                            : classes.AttributeTrusted
                        }
                      >
                        {criteriaTrusted.find((element: any) => {
                          const { value } = element;
                          return  is_trusted_weight === value;
                        })?.key}
                      </div>
                    }
                  />
                  <button
                    className={classes.ArrowTop}
                    onClick={() =>
                      trustedTopArrowHandler(`attribute-trusted-w-${index}`,index)
                    }
                    disabled={
                      !editable
                      //  ||
                      // type === "validation" ||
                      // scoreCardAtrributeAvailability.status
                    }
                  >
                    <img src={icons.arrowTop} alt="Arrow top icon" />
                  </button>
                  <button
                    className={classes.ArrowBottom}
                    onClick={() =>
                      trustedBottomArrowHandler(`attribute-trusted-w-${index}`,index)
                    }
                    disabled={
                      !editable 
                      // ||
                      // type === "validation" ||
                      // scoreCardAtrributeAvailability.status
                    }
                  >
                    <img src={icons.arrowBottom} alt="Arrow bottom icon" />
                  </button>
                </div>
                <div className={classes.Actions}>
                  {newRow || editable || weightEditStatus ? (
                    <>
                      <Active
                        id={`score-apply-${index}`}
                        style={
                          scoreCardActiveElement.name === null ||
                          type === "validation"
                            ? { opacity: 0, pointerEvents: "none" }
                            : { opacity: 1 }
                        }
                        handler={() => applyHandlerCallback(criteriaId)}
                      />
                      <Cancel
                        id={`score-cancel-${index}`}
                        handler={() =>
                          cancelHandlerCallback(rowType, id, level)
                        }
                      />
                    </>
                  ) : (
                    <>
                      <Pen handler={() => editHandler(index, id, level)} />
                      <Delete
                        id={`delete-${index}`}
                        handler={() =>
                          deleteHandlerCallback(index, id, `delete-${index}`)
                        }
                      />
                    </>
                  )}
                </div>
              </div>
            );
          })
          // .reverse()
      ) : (
        <span className={classes.TableMessage}>No data to display</span>
      )}
    </div>
  );
};

export default AttributeLevel;
