import React, { FC, useCallback, useEffect, useState } from "react";
import classes from "./TooltipMenu.module.scss";

export interface IMenu {
  title: string;
  action: (id: number | undefined) => void;
}

interface ITooltipMenu {
  menu: Array<IMenu>;
  dataId?: number;
}

const TooltipMenu: FC<ITooltipMenu> = ({ menu, dataId }) => {
  const menuButtonName = "tooltip-menu-button";
  const [visible, setVisible] = useState<boolean>(false);

  const setMenuVisible = useCallback(() => {
    setVisible((state: boolean) => !state);
  }, []);

  const generateMenu = useCallback(() => {
    if (!menu?.length) return null;

    return menu.map(({ title, action }, index: number) => {
      return (
        <button
          key={index}
          name={menuButtonName}
          onClick={() => action(dataId)}
        >
          {title}
        </button>
      );
    });
  }, [menu, dataId]);

  const manipulateClicks = useCallback(
    (event: MouseEvent) => {
      const { target } = event;

      if (!target) return;

      // @ts-ignore
      if (target?.name !== menuButtonName && visible) {
        setVisible(false);
        return;
      }
    },
    [visible]
  );

  useEffect(() => {
    document.addEventListener("click", manipulateClicks);

    return () => document.removeEventListener("click", manipulateClicks);
  }, [manipulateClicks]);

  return (
    <span
      className={`${classes.TooltipMenu} ${visible ? classes.Active : ""}`}
      onClick={setMenuVisible}
      title="Actions"
    >
      <svg viewBox="0 0 14 3" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.20833 1.4629C5.20833 2.27084 5.86125 2.92581 6.66667 2.92581C7.47208 2.92581 8.125 2.27084 8.125 1.4629C8.125 0.654965 7.47208 0 6.66667 0C5.86125 0 5.20833 0.654965 5.20833 1.4629Z" />
        <path d="M-4.07696e-05 1.4629C-4.07696e-05 2.27084 0.652877 2.92581 1.45829 2.92581C2.26371 2.92581 2.91663 2.27084 2.91663 1.4629C2.91663 0.654965 2.26371 0 1.45829 0C0.652877 0 -4.07696e-05 0.654965 -4.07696e-05 1.4629Z" />
        <path d="M10.4167 1.4629C10.4167 2.27084 11.0696 2.92581 11.875 2.92581C12.6805 2.92581 13.3334 2.27084 13.3334 1.4629C13.3334 0.654965 12.6805 0 11.875 0C11.0696 0 10.4167 0.654965 10.4167 1.4629Z" />
      </svg>
      {visible && <div className={classes.Menu}>{generateMenu()}</div>}
    </span>
  );
};

export default TooltipMenu;
