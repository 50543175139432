import { storageKeys } from "../settings/settings";

export interface IFetchedGroups {
  id: number;
  name: string;
  description: string | null;
  library_file:string;

}

interface IFetchedFunctions {
  id:number;
  name: string;
  description: string | null;
  syntax: string | null;
  group_id: number | null;
}

interface IReformatStrategyFunctions {
  functionGroups: Array<IFetchedGroups>;
  functions: Array<IFetchedFunctions>;
}

export interface IReformattedFunctions {
  name: string;
  description: string | null;
  syntax: string | null;
}

export interface IReformattedFunctionsGroup {
  id: number;
  name: string | null;
  library_file:string;
  functions: Array<IReformattedFunctions>;
  extended: boolean;
}

export const reformatStrategyFunctions = ({
  functionGroups,
  functions,
}: IReformatStrategyFunctions) => {
  const reformattedData: Array<IReformattedFunctionsGroup> = functionGroups.map(
    (group: IFetchedGroups): IReformattedFunctionsGroup => {
      const { id, name,library_file } = group;

      return {
        id,
        name,
        library_file,
        functions: functions
          .filter((func: IFetchedFunctions) => {
            const { group_id } = func;

            return group_id === id;
          })
          ?.map((func: IFetchedFunctions) => {
            const {id, name, syntax, description } = func;

            return {
              id,
              name,
              syntax,
              description,
              
            };
          }),
        extended: false,
      };
    }
  );

  sessionStorage.setItem(
    storageKeys.reformattedStrategyFunctions,
    JSON.stringify(reformattedData)
  );
};
