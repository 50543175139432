import { SourceDataAvailability, StrategyActionsTypes } from "../../../types/typesStrategy";

const initialState: SourceDataAvailability = {
    status: false
}

const SourceDataAvailabilityReducer = (state = initialState, action: StrategyActionsTypes): SourceDataAvailability => {
    switch (action.type) {
        case "STRATEGY_SOURCE_DATA_AVAILABILITY":
            const {status} = action.payload

            return {
                status
            }
    
        default:
            return state
    }
}

export default SourceDataAvailabilityReducer;