// import svg

import home from "../assets/svg/home.svg";
import strategy from "../assets/svg/strategy.svg";
import score from "../assets/svg/score.svg";
import decision from "../assets/svg/decision.svg";
import products from "../assets/svg/products.svg";
import downloads from "../assets/svg/downloads.svg";
import orders from "../assets/svg/orders.svg";
import settings from "../assets/svg/settings.svg";
import search from "../assets/svg/search.svg";
import facebook from "../assets/svg/fb.svg";
import google from "../assets/svg/google.svg";
import arrowTop from "../assets/svg/at.svg";
import arrowBottom from "../assets/svg/ab.svg";
import arrowLeft from "../assets/svg/al.svg";

import deleteBtnEnable from "../assets/svg/delete2.svg";
import deleteBtnDisable from "../assets/svg/delete.svg";
import penEnable from "../assets/svg/penEnable.svg";
import penDisable from "../assets/svg/penDisable.svg";
import applyIcon from "../assets/svg/apply.svg";
import backBtn from "../assets/svg/backBtn.svg";
import nextBtn from "../assets/svg/nextBtn.svg";

import scrollArrow from "../assets/svg/scrollArrow.svg";
import closeBtn from "../assets/svg/close.svg";
import switchlist from "../assets/svg/switchlist.svg";
import switchmatrix from "../assets/svg/switchmatrix.svg";
import Triangle from "../assets/svg/Triangle.svg";
import checkbox from "../assets/svg/checkbox.svg";
import debug from "../assets/svg/debug.svg";

import sidePanelArrows from "../assets/svg/sidePanelArrows.svg";
import sidePanelCheckBox from "../assets/svg/sidePanelcheck-box.svg";
import sidePanelCode from "../assets/svg/sidePanelCode.svg";
import sidePanelCollapse from "../assets/svg/sidePanelCollapse.svg";
import sidePanelFunction from "../assets/svg/sidePanelfunction.svg";
import sidePanelInput from "../assets/svg/sidePanelInput.svg";
import sidePanelInputUpload from "../assets/svg/sidePanelInputUpload.svg";
import sidePanelInputUploadDragDrop from "../assets/svg/sidePanelInputUploadDragDrop.svg";
import sidePanelInputFetch from "../assets/svg/sidePanelInputFetch.svg";
import sidePanelInputCreateXML from "../assets/svg/sidePanelInputCreateXML.svg";
import sidePanelMapping from "../assets/svg/sidePanelMapping.svg";
import sidePanelMessage from "../assets/svg/sidePanelMessage.svg";
import sidePanelVector from "../assets/svg/sidePanelVector.svg";
import sidePanelExtend from "../assets/svg/sidePanelExtend.svg";
import information from "../assets/svg/information.svg";
import sidePanelProduct from "../assets/svg/sidePanelProduct.svg";
import sidePanelProductProperty from "../assets/svg/sidePanelProductProperty.svg";
import sidePanelConditionArrows from "../assets/svg/sidePanelConditionArrows .svg";
import sidePanelMappingScoreCard from "../assets/svg/sidePanelMappingScoreCard.svg";
import sidePanelVectorDesicion from "../assets/svg/sidePanelVectorDesicion.svg";
import sidePanelfunctionPropery from "../assets/svg/sidePanelfunctionPropery.svg";


import undo from "../assets/svg/undo.svg";
import redo from "../assets/svg/redo.svg";
import minus from "../assets/svg/minus.svg";
import plus from "../assets/svg/plus.svg";
import input from "../assets/svg/input.svg";
import information2 from "../assets/svg/information2.svg";
import danger from "../assets/svg/danger.svg";
import uploading from "../assets/svg/uploading.svg";
import applyGreen from "../assets/svg/applyGreen.svg";
import inputBackButton from "../assets/svg/inputBackButton.svg";
import strategyPlay from "../assets/svg/strategyPlay.svg";
import codeEditorFontSize from "../assets/svg/codeEditorFontSize.svg";
import codeEditorLeftAlign from "../assets/svg/codeEditorLeftAlign.svg";
import codeEditorRedo from "../assets/svg/codeEditorRedo.svg";
import codeEditorRightAlign from "../assets/svg/codeEditorRightAlign.svg";
import codeEditorUndo from "../assets/svg/codeEditorUndo.svg";
import filterBlue from "../assets/svg/filterBlue.svg";
import settingsBlue from "../assets/svg/settingsBlue.svg";
import settingsGrey from "../assets/svg/settingsGrey.svg";

import nextBtnGrey from "../assets/svg/nextBtnGrey.svg";
import fullScreen from "../assets/svg/full-screen.svg";
import exitFullScreen from "../assets/svg/exit-full-screen.svg";

import sidePanelDecisionRange from "../assets/svg/sidePanelDecisionRange.svg";
import range from "../assets/svg/elements/range.svg";
import decision_matrix from "../assets/svg/elements/matrix.svg";

import sidePanelDecisionHierarchy from "../assets/svg/sidePanelDecisionHierarchy.svg";
import sidePanelDecisionMatrix from "../assets/svg/sidePanelDecisionMatrix.svg";
import sidePanelDecisionMatrixDisabled from "../assets/svg/sidePanelDecisionMatrixDisabled.svg";
import function_element from "../assets/svg/elements/function.svg";
import mapping_element from "../assets/svg/elements/mapping.svg";
import product_element from "../assets/svg/elements/product.svg";
import decision_element from "../assets/svg/elements/decision.svg";
import data_wizard from "../assets/svg/elements/wizard.svg";
import reloadIcon from "../assets/svg/reload.svg";
import connectionIcon from '../assets/svg/connection.svg';
import sidePanelPropertyCheckbox from '../assets/svg/sidePanelPropertyCheck-box.svg';
export const storage_key = `dss_board_elements_${
  window.location.search?.split("&")?.[1]?.split("=")?.[1]
}`;
export const strategyID = +window.location.search
  ?.split("&")?.[1]
  ?.split("=")?.[1] as number;
// create icons object
export const icons = {
  home: home,
  score: score,
  strategy: strategy,
  _decision: decision,
  products: products,
  downloads: downloads,
  orders: orders,
  settings: settings,
  search: search,
  facebook: facebook,
  google: google,
  arrowTop: arrowTop,
  arrowBottom: arrowBottom,
  deleteEnb: deleteBtnEnable,
  deleteDis: deleteBtnDisable,
  penEnable: penEnable,
  penDisable: penDisable,
  applyIcon: applyIcon,
  backBtn: backBtn,
  scrollArrow: scrollArrow,
  closeBtn: closeBtn,
  nextBtn: nextBtn,
  switchList: switchlist,
  switchMatrix: switchmatrix,
  triangle: Triangle,
  arrowLeft: arrowLeft,
  checkbox: checkbox,
  debug: debug,
  sidePanelArrows: sidePanelArrows,
  sidePanelCheckBox: sidePanelCheckBox,
  sidePanelCode: sidePanelCode,
  sidePanelCollapse: sidePanelCollapse,
  sidePanelFunction: sidePanelFunction,
  sidePanelInput: sidePanelInput,
  sidePanelProductProperty: sidePanelProductProperty,
  sidePanelConditionArrows: sidePanelConditionArrows,
  sidePanelMappingScoreCard: sidePanelMappingScoreCard,
  sidePanelVectorDesicion: sidePanelVectorDesicion,
  sidePanelfunctionPropery: sidePanelfunctionPropery,
  sidePanelPropertyCheckbox: sidePanelPropertyCheckbox,
  sidePanelInputUpload: sidePanelInputUpload,
  sidePanelInputUploadDragDrop: sidePanelInputUploadDragDrop,
  sidePanelInputFetch: sidePanelInputFetch,
  sidePanelInputCreateXML: sidePanelInputCreateXML,
  sidePanelMapping: sidePanelMapping,
  sidePanelMessage: sidePanelMessage,
  sidePanelVector: sidePanelVector,
  sidePanelExtend: sidePanelExtend,
  connectionIcon : connectionIcon,
  information: information,
  undo: undo,
  redo: redo,
  minus: minus,
  plus: plus,
  input: input,
  information2: information2,
  danger: danger,
  uploading: uploading,
  applyGreen: applyGreen,
  inputBackButton: inputBackButton,
  strategyPlay: strategyPlay,
  codeEditorFontSize: codeEditorFontSize,
  codeEditorLeftAlign: codeEditorLeftAlign,
  codeEditorRedo: codeEditorRedo,
  codeEditorRightAlign: codeEditorRightAlign,
  codeEditorUndo: codeEditorUndo,
  filterBlue: filterBlue,
  settingsBlue: settingsBlue,
  settingsGrey: settingsGrey,
  nextBtnGrey: nextBtnGrey,
  fullScreen: fullScreen,
  exitFullScreen: exitFullScreen,
  sidePanelDecisionRange,
  sidePanelDecisionHierarchy,
  sidePanelDecisionMatrix,
  sidePanelDecisionMatrixDisabled,
  range,
  decision_matrix,
  function: function_element,
  mapping: mapping_element,
  decision: decision_element,
  product: product_element,
  dataWizard: data_wizard,
  sidePanelProduct,
  reload: reloadIcon,
};

// main sections
export const sections = [
  {
    title: "Dashboard",
    to: "/dashboard",
    icon: icons.home,
    position: 0,
  },
  {
    title: "Strategy Designer",
    description:
      "Volutpat sit vivamus volutpat dignissim suscipit eleifend id. Sit suspendisse.",
    to: "/strategy-designer",
    icon: icons.strategy,
    position: 1,
    style: {
      width: "257px",
      height: "90px",
      backgroundColor: "#57B8A6",
      borderRadius: "10px",
    },
  },
  {
    title: "Score Card Designer",
    description:
      "Volutpat sit vivamus volutpat dignissim suscipit eleifend id. Sit suspendisse.",
    to: "/score-card",
    icon: icons.score,
    position: 1,
    style: {
      width: "257px",
      height: "90px",
      backgroundColor: "orange",
      borderRadius: "10px",
    },
  },
  {
    title: "Products Designer",
    description:
      "Volutpat sit vivamus volutpat dignissim suscipit eleifend id. Sit suspendisse.",
    to: "/products-designer",
    icon: icons.sidePanelProduct,
    position: 1,
    style: {
      width: "257px",
      height: "90px",
      backgroundColor: "#59A5BA",
      borderRadius: "10px",
    },
  },
  {
    title: "Decision Designer",
    description:
      "Volutpat sit vivamus volutpat dignissim suscipit eleifend id. Sit suspendisse.",
    to: "/decision-designer",
    icon: icons._decision,
    position: 1,
    style: {
      width: "257px",
      height: "90px",
      backgroundColor: "#8F86F8",
      borderRadius: "10px",
    },
  },
  {
    title: "Downloads",
    to: "/downloads",
    icon: icons.downloads,
    position: 0,
  },
  {
    title: "Orders",
    to: "/orders",
    icon: icons.orders,
    position: 0,
  },
  // {
  //   title: "Settings",
  //   to: "/settings",
  //   icon: icons.settings,
  //   position: 0,
  // },
  {
    title: "Profile",
    to: "/profile/edit-profile",
    icon: icons.settings,
    position: 0,
  },
];

//PRODUCT DESIGNER
export const format = [
  { id: 1, name: "Currency", description: "currency" },
  { id: 2, name: "Percent", description: "percent" },
  { id: 3, name: "Number", description: "number" },
  // { id: 4, name: "Float", description: "float" },
  { id: 5, name: "Text", description: "text" },
  { id: 6, name: "Date", description: "date" },
];
export const type = [
  { id: 1, name: "Range" },
  { id: 2, name: "Single" },
];

export const decision_type = [
  { id: 1, name: "Matrix", color: "#E35D85" },
  { id: 2, name: "Range", color: "#FF7849" },
  { id: 3, name: "Tree", color: "#0470D1" },
];

export const matrix_status = [
  {
    id: 1,
    name: "Yes",
    color: "#04D439",
    backgroundColor: "rgba(4, 212, 57, 0.12)",
    width: "54px",
    height: "35px",
    borderRadius: "16px",
  },
  {
    id: 2,
    name: "No",
    color: "#FF4037",
    backgroundColor: "rgba(255, 64, 55, 0.1)",
    width: "54px",
    height: "35px",
    borderRadius: "16px",
  },
  {
    id: 3,
    name: "Maybe",
    color: "#FFDD2D",
    backgroundColor: "rgba(255, 221, 45, 0.13)",
    width: "74px",
    height: "35px",
    borderRadius: "16px",
  },
];

export const alphabet = [
  { code: 65, letter: "A" },
  { code: 66, letter: "B" },
  { code: 67, letter: "C" },
  { code: 68, letter: "D" },
  { code: 69, letter: "E" },
  { code: 70, letter: "F" },
  { code: 71, letter: "G" },
  { code: 72, letter: "H" },
  { code: 73, letter: "I" },
  { code: 74, letter: "J" },
  { code: 75, letter: "K" },
  { code: 76, letter: "L" },
  { code: 77, letter: "M" },
  { code: 78, letter: "N" },
  { code: 79, letter: "O" },
  { code: 80, letter: "P" },
  { code: 81, letter: "Q" },
  { code: 82, letter: "R" },
  { code: 83, letter: "S" },
  { code: 84, letter: "T" },
  { code: 85, letter: "U" },
  { code: 86, letter: "V" },
  { code: 87, letter: "W" },
  { code: 88, letter: "X" },
  { code: 89, letter: "Y" },
  { code: 90, letter: "Z" },
];
export const matrix = {
  xMatrix: (cell: number) => {
    return [...alphabet.slice(0, cell)];
  },
  yMatrix: (cell: number) => {
    return [...alphabet.slice(0, cell)];
  },
};

//E

export const decision_status = [
  { id: 1, name: "Yes" },
  { id: 2, name: "No" },
  { id: 3, name: "Maybe" },
];

export const color = [
  { id: "A", color: "#007FFF" },
  { id: "B", color: "#CD7F32" },
  { id: "C", color: "#00FFFF" },
  { id: "D", color: "#4b8e5c" },
  { id: "E", color: "#3b8272" },
  { id: "F", color: "#71805e" },
  { id: "G", color: "#909090" },
  { id: "H", color: "#cccc33" },
  { id: "I", color: "#4b0082" },
  { id: "J", color: "#17917f" },
  { id: "K", color: "#6c6e68" },
  { id: "L", color: "#713b5e" },
  { id: "M", color: "#ff00ff" },
  { id: "N", color: "#000080" },
  { id: "O", color: "#fc9303" },
  { id: "P", color: "#c09da4" },
  { id: "Q", color: "#51484f" },
  { id: "R", color: "#e71837" },
  { id: "S", color: "#b34b42" },
  { id: "T", color: "#f17d35" },
  { id: "U", color: "#006b5a" },
  { id: "V", color: "#838ba4" },
  { id: "W", color: "#4b414a" },
  { id: "X", color: "#8f9d80" },
  { id: "Y", color: "#fce903" },
  { id: "Z", color: "#9c8855" },
];

//END E
export const strategy_data = {
  type: "",
  data: {},
  style: "",
  sourceId: "",
  targetId: "",
  branchLabel: "",
  anchors: [],
  overlays: [],
  breakPoint: "False",
};
export const code_message_data = {
  type: "code_message",
  sourceId: null,
  targetId: "endpoint",
  style: "",
  data: {
    label: "code_message",
    code_message: {
      code: null,
      message: null,
    },
  },
};
export const code_message_list_data = {
  type: "code_message_list",
  sourceId: "code_message_list_1",
  data: {
    label: "code_message_list",
    code_message_list: [],
  },
};

export const output_format_data = {
  type: "output",
  data: {
    id: "output",
    label: "Output Format",
    resultVar: "output_format_1",
    outputValue: "{}",
  },
  style: "",
  sourceId: "output",
  targetId: "endpoint",
  branchLabel: "",
  anchors: [],
  overlays: [],
};

export const start_point_data = {
  type: "startpoint",
  data: {},
  style: "",
  sourceId: "startpoint",
  targetId: "",
  branchLabel: "",
  anchors: [],
  overlays: [],
};
export const function_data = {
  type: "func",
  data: {},
  style: "",
  sourceId: "",
  targetId: "",
  branchLabel: "",
  anchors: [],
  overlays: [],
};
export const product_data = {
  type: "product",
  data: {},
  style: "",
  sourceId: "",
  targetId: "",
  branchLabel: "",
  anchors: [],
  overlays: [],
};
export const validate_data = {
  type: "validate",
  data: {},
  style: "",
  sourceId: "",
  targetId: "",
  branchLabel: "",
  anchors: [],
  overlays: [],
};

export const mapping_data = {
  type: "scorecard",
  data: {},
  style: "",
  sourceId: "",
  targetId: "",
  branchLabel: "",
  anchors: [],
  overlays: [],
};

export const decision_data = {
  type: "decision",
  breakPoint: "False",
  data: {},
  style: "",
  sourceId: "",
  targetId: "",
  branchLabel: "",
  anchors: [],
  overlays: [],
};
export const condition_data = {
  yes: {
    type: "condt",
    data: {},
    style: "",
    sourceId: "",
    targetId: "",
    branchLabel: "YES",
    anchors: [],
    overlays: [],
  },
  no: {
    type: "condt",
    data: {},
    style: "",
    sourceId: "",
    targetId: "",
    branchLabel: "NO",
    anchors: [],
    overlays: [],
  },
};
export const end_point_data = {
  type: "endpoint",
  data: {},
  style: "",
  sourceId: "endpoint",
  targetId: "endpoint",
};

export const used_functions = {
  type: "used_functions",
  sourceId: "used_functions",
  data: {
    "math.mjs": [],
    "statistical.mjs": [],
    "financial.mjs": [],
    "datetime.mjs": [],
    "local.mjs": [],
  },
};
export const elementSize = {
  start: {
    width: 70,
    height: 70,
    rx: 35,
    ry: 35,
    fill: "#F39759",
    stroke: "none",
    id: "figure",
    style: "filter:url(#figure_shadow)",
  },
  validate: {
    width: 107,
    height: 70,
    rx: 5,
    ry: 5,
    fill: "#FFFFFF",
    stroke: "none",
    "stroke-width": ".1vh",
    id: "figure",
    style: "filter:url(#figure_shadow)",
  },
  wizard: {
    width: 107,
    height: 70,
    rx: 5,
    ry: 5,
    fill: "#FFFFFF",
    stroke: "none",
    "stroke-width": ".1vh",
    id: "figure",
    style: "filter:url(#figure_shadow)",
  },
  function: {
    width: 107,
    height: 70,
    rx: 5,
    ry: 5,
    fill: "#FFFFFF",
    stroke: "none",
    "stroke-width": ".1vh",
    id: "figure",
    style: "filter:url(#figure_shadow)",
  },
  product: {
    width: 107,
    height: 70,
    rx: 5,
    ry: 5,
    fill: "#06C37D",
    stroke: "none",
    "stroke-width": ".1vh",
    id: "figure",
    style: "filter:url(#figure_shadow)",
  },
  condition_figure: {
    width: +Math.sqrt((70 * 70) / 2).toFixed(2),
    height: +Math.sqrt((70 * 70) / 2).toFixed(2),
    rx: 5,
    ry: 5,
    fill: "#7373AA",
    stroke: "none",
    id: "figure2",
    style: "filter:url(#figure_shadow)",
  },
  condition: {
    width: 70,
    height: 70,
    rx: 35,
    ry: 35,
    fill: "transparent",
    stroke: "none",
    id: "figure",
    style: "filter:url(#figure_shadow)",
  },
  end: {
    width: 70,
    height: 70,
    rx: 35,
    ry: 35,
    fill: "#979797",
    stroke: "none",
    id: "figure",
    style: "filter:url(#figure_shadow)",
  },
  code_message: {
    width: 70,
    height: 70,
    rx: 35,
    ry: 35,
    fill: "#FFF",
    stroke: "#70AC81",
    "stroke-width": "2",
    id: "figure",
    style: "filter:url(#figure_shadow)",
  },
  mapping: {
    width: 107,
    height: 70,
    rx: 5,
    ry: 5,
    fill: "#A64B75",
    stroke: "none",
    "stroke-width": ".1vh",
    id: "figure",
    style: "filter:url(#figure_shadow)",
  },
  decision: {
    width: 107,
    height: 70,
    rx: 5,
    ry: 5,
    fill: "#536C8B",
    stroke: "none",
    "stroke-width": ".1vh",
    id: "figure",
    style: "filter:url(#figure_shadow)",
  },
  system_error: {
    width: 127,
    height: 70,
  },
  output_format: {
    width: 157,
    height: 70,
  },
};

export const messages = {
  titleCantEmptyField: "Field can not be empty",
  titleMustBeFilled: "Filed must be filled!",
  titleOptionalBeFilled: "One of the optional fields must be filled!",
  titleClosePanel: "Close panel",
  titleDeletePanel: (item: string) => `Delete ${item}`,
  titleAddPanel: (item: string) => `Add new ${item}`,
  titleEditPanel: (item: string) => `Edit ${item}`,
  titleSelectPanel: (item: string) => `Select ${item}`,
  titleArchivePanel: (item: string) => `Archive ${item}`,
  titleClonePanel: (item: string) => `Clone ${item}`,
  titleChangePanel: (item: string) => `Change ${item}`,
  titleResetForm: "Reset form",
  titleSureAdd: (item: string) => `Are you sure you want to add new ${item}?`,
  titleSureClosePanel: "Are you sure you want to close panel?",
  titleSureSelectPanel: (item: string) =>
    `Are you sure you want to select other ${item}?`,
  titleSureResetForm: "Are you sure you want reset form?",
  titleSureDelete: (item: string = "") =>
    `Are you sure you want to delete ${item}?`,
  titleSureArchive: (item: string = "") =>
    `Are you sure you want to archive ${item}?`,
  titleSureClone: (item: string = "") =>
    `Are you sure you want to clone ${item}?`,
  titleSureChange: (item: string = "") =>
    `Are you sure you want to change ${item}?`,
  titleSureClearEditor: "Are you sure you want to clear editor?",
  titleClearEditor: "Clear editor",
  titleWentWrong: "Something went wrong",
  titleManualKey: "Manually written variables",
  titleNotLogicOperator:
    "Logic or Comparison operators must be used in condition data",
  titleOops: "Oops!",
  titleEmptyData: (item?: string) => `Empty${item ? ` ${item}` : ""} data`,
  titleUncompletedElementConnection: "Uncompleted elements connection",
  titleAgain: "Try again",
  titleAttention: "Attention",
  titleUnderstand: "Understand",
  titleBtnClose: "Yes, Close",
  titleBtnDelete: "Yes, Delete",
  titleBtnReset: "Yes, Reset",
  titleBtnAdd: "Yes, Add",
  titleBtnSelect: "Yes, Select",
  titleBtnClear: "Yes, Clear",
  titleBtnArchive: "Yes, Archive",
  titleBtnClone: "Yes, Clone",
  titleBtnChange: "Yes, Change",
  titleBtnSave: "Save",
  titleBtnApply: "Apply",
  titleDuplicate: (item: string) => `Duplicated ${item}`,
  messageNoData: "There is no data to display",
  messageSearchNotFound: "Nothing found",
  messageUnexpectedError: "Unexpected error happened. Please reload page",
  messagePanelClosing:
    "In this situation you will lose all changes about this element data. If this element is new created,  element will be disappear after closing panel.",
  messagePanelEmptyAttention:
    "Required fields can not be empty during submitting process. Empty fields can be reason of some fails when strategy is running. The required fields will be show red borders after closing attention.",
  messagePanelSubmittingError:
    "This failure happens during submitting element data process. For fixing this problem, reopen the editing panel for the element which going to new or update.",
  messageReset: "In this situation you will lose all changes.",
  messageAdd:
    "If you proceed then form will be reset and you will lose all changes about current data",
  messageSelect:
    "If you proceed then form will be fill new data and you will lose all changes about current data",
  messageUncompletedElementConnection:
    "Please complete connection(s) between unconnected element(s)",
  messageStorageFetchStatusFalse: "Local storage fetch result status is false",
  messageStorageFetchError: "Local storage fetch error",
  messageStorageUpdateStatusFalse:
    "Local storage update result status is false",
  messageStorageUpdateError: "Local storage update error",
  messageDifferentType:
    "Parameters types are different. They must be same type!",
  messageStrategyKeyExecuteSuccessful: (key: string) =>
    `Result of ${key} is successful`,
  messageStrategyExecuteSuccessful: "Result is successful",
  messageWaitForResponse: "Please wait for response...",
  messageCodeMessage: (message?: string) => `${message}`,
  messageEmptyResponse:
    "Your request response is successful but returned data is empty",
  messageWentWrong: (message?: string) => `Something went wrong ${message}`,
  messageManualKey:
    "Manually written variable shows on red background and may not be in strategy data. Please replace that variable with existing variable. If you don't replace you can't proceed submit.",
  messageDuplicatedMappingVariable:
    "Each of the criteria must be mapped with an individual variable. Same variable can't map with multiple criteria.",
  messageDublicatedMessageCode: "Each of the messages must be an individual variable. Please replace the message code with a unique one.",
  messageApiGetError: "Getting data from api error",
  messageApiGetStatusFalse: "Getting data response status is false",
  messageApiPostError: "Posting data to api error",
  messageApiPostStatusFalse: "Posting data response status is false",
  messageApiPatchError: "Patching data to api error",
  messageApiPatchStatusFalse: "Patching data response status is false",
  messageApiDeleteError: "Deleting data from api error",
  messageApiCloneError: "Cloning data from api error",
  messageApiInternalError: "Internal error",
  messageApiResponseError:
    "There is failure between client and server connection. For fixing this problem, check your internet connection and try again.",
  messageWizardPrepareOrMessage:
    "You can fill in only one of the Replace to and Code & Message fields.",
  messageApiResponseFieldMissing: (field: string) =>
    `${field} don't exist on response`,
  messageEmptyField: (field?: string) => `Empty field ${field}`,
};

export const storageKeys = {
  credentials: "credentials",
  userDetails: "user_details",
  activeStrategy: "active_strategy",
  activeStrategySourceData: "active_strategy_source_data",
  boardTransformation: "board_transformation",
  boardElements: storage_key,
  reformattedSourceData: "reformatted_source_data",
  reformattedStrategyFunctions: "reformatted_strategy_functions",
};

export const arrow_type = {
  top: "l-4 0 ,l4 -8 ,l4 8 ,l-4 0",
  right: "l0 -5 ,l8 5 ,l-8 5 ,l0 -5",
  bottom: "l4 0 ,l-4 8 ,l-4 -8 ,l4 0",
  left: "l0 -5,l-8 5 ,l8 5,l0 -5",
};

export const initialStrategy = [
  {
    type: "output",
    data: {
      id: "output",
      label: "Output Format",
      resultVar: "output_format_1",
      outputValue: "{}",
    },
    style: "",
    sourceId: "output",
    targetId: "endpoint",
    branchLabel: "",
    anchors: [],
    overlays: [],
  },
  {
    type: "startpoint",
    data: {},
    style: "left:200px,top:200px",
    sourceId: "startpoint",
    targetId: "endpoint",
    branchLabel: "",
    anchors: ["right"],
    overlays: [
      {
        side: "right",
        contact: "startpoint-endpoint",
        coord: "M282 235,h633.5,v558,h633.5,l0 -5 ,l8 5 ,l-8 5 ,l0 -5",
      },
    ],
  },
  {
    type: "endpoint",
    data: {},
    style: "left:1570px,top:758px",
    sourceId: "endpoint",
    targetId: "endpoint",
  },
];

export enum StrategyPanels {
  INPUT,
  INPUT_CREATE_NEW,
  INPUT_CREATE_UPDATE,
  DATA_WIZARD,
  FUNCTION,
  CONDITION,
  MAPPING,
  DECISION,
  RANGE,
  MATRIX,
  CODE_MESSAGE,
  OUTPUT_FORMAT,
  CLOSE,
}

export enum ElementTypes {
  STARTPOINT = "startpoint",
  ENDPOINT = "endpoint",
  OUTPUT = "output",
  VALIDATE = "validate",
  MESSAGE = "code_message",
  MESSAGE_LIST = "code_message_list",
  FUNCTION = "func",
  CONDITION = "condt",
  MAP = "scorecard",
  DECISION = "decision_mapp",
  DECISION_RANGE = "decision",
  DECISION_MATRIX = "matrix",
  DATA_WIZARD = "datawizard",
  PRODUCT = "product",
  USED_FUNCTIONS = "used_functions",
}

export enum ButtonTypes {
  PRIMARY = "Primary",
  SECONDARY = "Secondary",
  WARNING = "Warning",
  DELETE = "Delete",
  LOADING = "Loading",
  SAVE = "Save",
  DISABLED = "Disabled",
  LINK_PRIMARY = "LinkPrimary",
  LINK_SECONDARY = "LinkSecondary",
}

export enum ButtonSizes {
  SMALL = "Small",
  DEFAULT = "Default",
  LARGE = "Large",
}

export enum ButtonShape {
  DEFAULT = "Rectangle",
  ROUND = "Round",
  NONE = "None",
}

export enum CutOverflowStringDirections {
  LEFT,
  RIGHT,
}

export enum ApiDebugModes {
  DEBUG = "Debug-Mode",
  BREAKPOINT = "Breakpoint-Mode",
  OUTPUT = "Output-Mode",
}

export enum EditorKeySources {
  INPUT = "Input",
  VARIABLE = "Variable",
  VARIABLE_KEYS = "Variable Keys",
  SELF = "SELF",
  MAP = "Score Cards",
  ITERATE = "Iterate",
  MANUAL = "Manual",
  OPERATOR = "Operator",
  FUNCTION = "Function",
  STRING = "String",
  NUMBER = "Number",
  NEWLINE = "New line",
  SPACE = "Space",
  DECISIONS = "Decisions",
  PRODUCTS = "Products",
  NULL = "Null",
}

export enum ModalNames {
  STRATEGY_DESIGNER,
  STRATEGY_DESIGNER_FORM,
  STRATEGY_TABLE,
  STRATEGY_TABLE_FORM,
  DATA_WIZARD_PANEL,
  FUNCTION_PANEL,
  CONDITION_PANEL,
  RANGE_PANEL,
  MATRIX_PANEL,
  EDITOR_FUNCTION_BODY,
  EDITOR_ITERATE_ELEMENT,
  EDITOR_ITERATE_CONDITION,
  EDITOR_CONDITION_BODY,
  EDITOR_WIZARD_CONDITION,
  EDITOR_WIZARD_REPLACE,
  MAPPING_LIST,
  DECISION_MANAGER_RANGE_LIST,
  DECISION_MAPPER,
  OUTPUT_FORMAT_PANEL,
}

export enum ModalFormInlineElementTypes {
  INPUT,
  SELECT,
}

export enum operatorTypes {
  ARITHMETIC,
  COMPARISON,
  LOGICAL,
  ASSIGNMENT,
  SYMBOL,
}

export const editorOperatorList = [
  {
    key: "addition",
    title: "Addition",
    type: operatorTypes.ARITHMETIC,
    symbol: "+",
    dataSymbol: "+",
  },
  {
    key: "subtraction",
    title: "Subtraction",
    type: operatorTypes.ARITHMETIC,
    symbol: "-",
    dataSymbol: "-",
  },
  {
    key: "multiplication",
    title: "Multiplication",
    type: operatorTypes.ARITHMETIC,
    symbol: "*",
    dataSymbol: "*",
  },
  {
    key: "division",
    title: "Division",
    type: operatorTypes.ARITHMETIC,
    symbol: "/",
    dataSymbol: "/",
  },
  {
    key: "remainder",
    title: "Remainder",
    type: operatorTypes.ARITHMETIC,
    symbol: "%",
    dataSymbol: "%",
  },
  {
    key: "exponent",
    title: "Exponent",
    type: operatorTypes.ARITHMETIC,
    symbol: "**",
    dataSymbol: "**",
  },
  {
    key: "assign",
    title: "Assign",
    type: operatorTypes.ASSIGNMENT,
    symbol: "=",
    dataSymbol: "=",
  },
  {
    key: "point",
    title: "Point",
    type: operatorTypes.SYMBOL,
    symbol: ".",
    dataSymbol: ".",
  },
  {
    key: "parentheses",
    title: "Parentheses",
    type: operatorTypes.SYMBOL,
    symbol: "( )",
    dataSymbol: "(",
  },
  {
    key: "equal",
    title: "Equal",
    type: operatorTypes.COMPARISON,
    symbol: "==",
    dataSymbol: "==",
  },
  {
    key: "different",
    title: "Different",
    type: operatorTypes.COMPARISON,
    symbol: "<>",
    dataSymbol: "<>",
  },
  {
    key: "lessThan",
    title: "Less than",
    type: operatorTypes.COMPARISON,
    symbol: "<",
    dataSymbol: "<",
  },
  {
    key: "greaterThan",
    title: "Greater than",
    type: operatorTypes.COMPARISON,
    symbol: ">",
    dataSymbol: ">",
  },
  {
    key: "lessThanEqual",
    title: "Less than or Equal to",
    type: operatorTypes.COMPARISON,
    symbol: "<=",
    dataSymbol: "<=",
  },
  {
    key: "greaterThanEqual",
    title: "Greater than or Equal to",
    type: operatorTypes.COMPARISON,
    symbol: ">=",
    dataSymbol: ">=",
  },
  {
    key: "and",
    title: "And",
    type: operatorTypes.LOGICAL,
    symbol: "AND",
    dataSymbol: "&&",
  },
  {
    key: "or",
    title: "Or",
    type: operatorTypes.LOGICAL,
    symbol: "OR",
    dataSymbol: "||",
  },
];

export const OUTPUT_ELEMENTS = [
  "func",
  "decision",
  "scorecard",
  "product",
] as string[];

export const VARIABLES = ["func"] as string[];
export const MAPPING = ["scorecard"] as string[];

export enum OutputMessages {
  UNFINISHED_SAVE = "UNFINISHED_SAVE",
  EMPTY = "EMPTY",
  NULL = "NULL",
}

export const MessageList = {
  UNFINISHED_SAVE: "Please save or cancel changes!",
  NULL: "Key and value is not empty!",
};

export const strategyCreateWaitMessages = [
  "Creating request sent",
  "Request accepted",
  "Generating strategy",
  "This takes few seconds",
  "Almost done",
];

export enum EActionTypes {
  UP,
  CHAINING,
  ADD,
}

export enum DataTypes {
  NUMBER = "number",
  STRING = "string",
  BOOLEAN = "boolean",
  OBJECT = "object",
  ARRAY = "array",
  NULL = "null",
}
