import React, { FC, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { endpoints } from "../../../../api/endpoints";
import { request } from "../../../../helpers/request";
import {
  getAllDecisionRange,
  handleRangeReload,
  handleRangeActiveRow,
  handleRangeActiveElement,
} from "../../../../store/actions/actionsDecision";
import { icons } from "../../../../settings/settings";
import DecisionTable from "../../../../components/UI/Table/DecisionDesigner/Table";
import classes from "./Range.module.scss";
import { RootState } from "../../../../store/combineReducer";
import {
  activeModal,
  handleActiveTooltip,
} from "../../../../store/actions/action";

type decisionMassive = {
  data: any;
};
const DecisionNameRange: FC<decisionMassive> = ({ data }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const {
    decision_designer: { decision_range },
  } = endpoints;
  const decisionId = Number(search.substring(4, search.lastIndexOf("&")));

  const {
    reducer: {
      touchBlocked,
      mainTooltip: { type },
    },
    reducerDecision: {
      decisionRanges,
      rangeReload,
      rangeActiveElement: { name },
    },
  } = useSelector((state: RootState) => state);

  const [decisionRangesState, setDecisionRangesState] = useState<Array<any>>(
    []
  );
  const [alpahabetState, setAlpahabetState] = useState({
    level: 0,
    firstIndexOfDouble: 1,
    secondCharIndex: 0,
  });

  useEffect(() => {
    if (decisionRanges.length === 0) {
      request
        .get(decision_range.get(decisionId))
        .then((result) => {
          const { response } = result;
          dispatch(
            getAllDecisionRange("empty", {
              decisionId,
              decisionRangesData: response,
            })
          );
          dispatch(handleRangeReload(false));
        })
        .catch((error) => {
          console.log("error " + error);
        });
    }

    if (decisionRanges.length > 0) {
      let checkDecisionId = decisionRanges.some(
        (range: any) => decisionId === range.decisionId
      );

      if (!checkDecisionId) {
        request
          .get(decision_range.get(decisionId))
          .then((result) => {
            const { response } = result;
            dispatch(
              getAllDecisionRange("new", {
                decisionId,
                decisionRangesData: response,
              })
            );
            dispatch(handleRangeReload(false));
          })
          .catch((error) => {
            console.log("error " + error);
          });
      }
    }

    if (rangeReload) {
      request
        .get(decision_range.get(decisionId))
        .then((result) => {
          const { response } = result;
          dispatch(
            getAllDecisionRange("update", {
              decisionId,
              decisionRangesData: response,
            })
          );
          dispatch(handleRangeReload(false));
        })
        .catch((error) => {
          console.log("error " + error);
        });
    }
  }, [dispatch, decision_range, decisionId, decisionRanges, rangeReload]);

  useEffect(() => {
    let rangeDataWithDecisionId: Array<any>;
    let rangeArray: Array<any>;

    if (decisionRanges.length > 0) {
      rangeDataWithDecisionId = decisionRanges.filter((range: any) => {
        return decisionId === range.decisionId;
      });

      if (rangeDataWithDecisionId.length > 0) {
        rangeArray = [...rangeDataWithDecisionId[0].decisionRangesData];
        setDecisionRangesState(rangeArray);
      }
    }
  }, [decisionRanges, decisionId]);

  const incrementAlphabet = (char: string) => {
    const alphabet = " ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let indexOfChar = 0;
    let secondIndexOfChar = 0;
    let nextChar;

    for (let element of alphabet) {
      if (element === char.charAt(0)) indexOfChar = alphabet.indexOf(element);
      if (element === char.charAt(1))
        secondIndexOfChar = alphabet.indexOf(element);
    }

    if (indexOfChar === 25) setAlpahabetState({ ...alpahabetState, level: 1 });
    if (indexOfChar === 26) indexOfChar = 0;
    if (secondIndexOfChar === 25)
      setAlpahabetState({
        ...alpahabetState,
        firstIndexOfDouble: alpahabetState.firstIndexOfDouble + 1,
      });
    if (secondIndexOfChar === 26) secondIndexOfChar = 0;

    if (indexOfChar < 26) nextChar = alphabet[indexOfChar + 1];

    if (alpahabetState.level === 1) {
      nextChar = [
        alphabet[alpahabetState.firstIndexOfDouble],
        alphabet[secondIndexOfChar + 1],
      ].join("");
    }

    return nextChar;
  };

  const addDecisionRangeRow = () => {
    if (name !== null && type === null) {
      dispatch(
        handleActiveTooltip({
          childId: name,
          childDataId: null,
          type: "saveChanges",
        })
      );
    }

    if (name === null && type === null) {
      setDecisionRangesState([
        ...decisionRangesState,
        {
          alias: "",
          label: incrementAlphabet(
            decisionRangesState.length > 0
              ? decisionRangesState[decisionRangesState.length - 1].label
              : " "
          ),
          FROM: "0",
          TO: "0",
          decision_id: decisionId,
          status_id: 2,
          editable: true,
          newRow: true,
        },
      ]);

      dispatch(
        handleRangeActiveRow({
          rowIndex:
            decisionRangesState.length > 0 ? decisionRangesState.length : 0,
          newRow: true,
        })
      );
    }
  };

  const blockedLinkHandler = (e: any) => {
    if (name !== null && touchBlocked.status) {
      dispatch(activeModal({ activeModal: "range_blocked_modal" }));
    }

    if (name === null && !touchBlocked.status) {
      dispatch(handleRangeActiveRow({ rowIndex: null, newRow: false }));
    }

    if (name !== null && !touchBlocked.status) {
      dispatch(handleRangeActiveElement({ name: null, elementIndex: null }));
      dispatch(
        handleActiveTooltip({ childId: null, childDataId: null, type: null })
      );
      dispatch(handleRangeActiveRow({ rowIndex: null, newRow: false }));
    }
  };

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const foundObject = data.find((each: any) => each.id === Number(decisionId));

  return (
    <section>
      <div className={classes.HeaderDecision}>
        <div className={classes.Title}>
          {foundObject && capitalizeFirstLetter(foundObject.name)}
          <div className={classes.Type}>Range</div>
        </div>
        <div className={classes.SearchDecision}>
          <button className={classes.Search}>
            <img src={icons.search} alt="search" />
          </button>

          <button
            className={classes.AddDecision}
            onClick={() => addDecisionRangeRow()}
          >
            Add Row
          </button>
        </div>
      </div>
      <div
        className={
          touchBlocked.status
            ? [classes.BackBtn, classes.BackBtnDisabled].join(" ")
            : classes.BackBtn
        }
        onClick={blockedLinkHandler}
        data-testid="back"
      >
        <Link to="/decision-designer">
          <img src={icons.backBtn} alt="back" /> Back
        </Link>
      </div>
      <DecisionTable
        data={decisionRangesState}
        setState={setDecisionRangesState}
      />
    </section>
  );
};

export default DecisionNameRange;
