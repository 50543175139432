  export const LOGIN = "LOGIN";
  export const PATHNAME = "PATHNAME";
  export const MODAL = "MODAL";
  export const PRODUCTS = "PRODUCTS";
  export const SUBMITDATA = "SUBMITDATA";
  export const CHECKBOX = "CHECKBOX";
  export const REFRESH = "REFRESH";
  export const SELECTALL = "SELECTALL";
  export const TOOLTIP = "TOOLTIP";
  export const MAINTOOLTIP = "MAINTOOLTIP";
  export const TOUCHBLOCKED = "TOUCHBLOCKED";
  export const ACTIVEEVENT = "ACTIVEEVENT";
  export const CANCELHANDLER = "CANCELHANDLER";
  export const SAVEHANDLER = "SAVEHANDLER";
  export const ACTIVEATTREVENT = "ACTIVEATTREVENT";
  export const ACTIVEATTRIBUTE = "ACTIVEATTRIBUTE";
  export const ATTRIBUTEBLOCKED = "ATTRIBUTEBLOCKED";
  export const ACTIVEPRODUCT = "ACTIVEPRODUCT";
  export const ACTIVEROWARCHIVE = "ACTIVEROWARCHIVE";
  export const CHECKEDSTATUS = "CHECKEDSTATUS";
  export const CONFIRMTOOLTIP = "CONFIRMTOOLTIP";
  export const EMPTYVALUEBLOCKED = "EMPTYVALUEBLOCKED";
  export const SHOWEMPTYFIELD = "SHOWEMPTYFIELD";
  export const EMPTYFIELDSTATUS = "EMPTYFIELDSTATUS";
  export const PRODUCTVALUES = "PRODUCTVALUES";
  export const ACTIVESECTION = "ACTIVESECTION";












  export interface User {
    username?:string
    mail?:string
  }

  export interface Path {
    pathname ? : string
  }
  export interface Modal {
    activeModal ? : string | null
  }

  export interface Product {
    name ? : string,
      description ? : string
  }


  export interface Submit {
    data ? : object
  }


  export interface Checkbox {
    id ? : number,
    checked ? : boolean
  }


  export interface Refresh {
    refresh ? : boolean
  }

  export interface SelectAll {
    selectAll ? : boolean
  }

  export interface Tooltip {
    tooltip ? : any
  }

//E
  export interface MainTooltip {
    childId?: string | null;
    childDataId?: number | null;
    type?: string | null;
  }
  //END E

  export interface TouchBlocked {
    status ? : boolean
  }
  export interface ActiveEvent {
    activeEvent ? : any | null
  }
  export interface CancelHandler {
    status ? : boolean
  }
  export interface SaveHandler {
    status ? : boolean
  }
  export interface ActiveAttrEvent {
    activeEvent ? : any | null
  }
  export interface ActiveAttribute {
    activeAttribute ? : any | null
  }
  export interface AttributeBlocked {
    status ? : boolean
  }
  export interface ActiveProduct {
    activeProduct:any | null
  }
  export interface ActiveRowArchive {
    activeRowArchive:any | null
  }
  export interface CheckedStatus {
    status ? : boolean | null
  }
 
  export interface ConfirmTooltip {
    confirmTooltip ? : any | null
  }
  export interface EmptyValueBlocked {
    status ? : boolean
  }
  export interface ShowEmptyField {
    fields ? : any | null
  }
  export interface EmptyFieldStatus {
    status?:boolean
  }
  export interface ActiveSection {
    activeSection ? : string | null
  }

  export interface State {
    user: User,
    pathname: Path,
      activeModal: Modal,
      products: Product,
      submitData: Submit,
      checkboxList: Checkbox[],
      refresh: Refresh,
      selectAll: SelectAll,
      tooltip: Tooltip,
      mainTooltip:MainTooltip
      touchBlocked: TouchBlocked,
      activeEvent: ActiveEvent,
      cancelHandler: CancelHandler,
      saveHandler: SaveHandler,
      activeAttrEvent: ActiveAttrEvent,
      activeAttribute: ActiveAttribute,
      attributeBlocked:AttributeBlocked,
      activeProduct:ActiveProduct,
      activeRowArchive:ActiveRowArchive,
      checkedStatus:CheckedStatus,
      confirmTooltip:ConfirmTooltip,
      emptyValueBlocked:EmptyValueBlocked,
      showEmptyField:ShowEmptyField[],
      emptyFieldStatus:EmptyFieldStatus,
      activeSection:ActiveSection

  }


  interface CreateLoginRequest {
    type: typeof LOGIN;
    payload: {
      user: User
    };
  }



  interface addPathname {
    type: typeof PATHNAME;
    payload: {
      pathname: Path
    };
  }


  interface activeModal {
    type: typeof MODAL;
    payload: {
      activeModal: Modal
    };
  }


  interface addProduct {
    type: typeof PRODUCTS;
    payload: {
      products: Product
    };
  }

  interface submitData {
    type: typeof SUBMITDATA;
    payload: {
      submitData: Submit
    };
  }

  interface checkboxList {
    type: typeof CHECKBOX;
    payload: {
      checkboxList: Checkbox
    };
  }

  interface refreshFetch {
    type: typeof REFRESH;
    payload: {
      refresh: Refresh
    };
  }

  interface selectAll {
    type: typeof SELECTALL;
    payload: {
      selectAll: SelectAll
    };
  }
  interface tooltipActive {
    type: typeof TOOLTIP;
    payload: {
      value: Tooltip
    };
  }
  interface touchBlocked {
    type: typeof TOUCHBLOCKED;
    payload: {
      touchBlocked: TouchBlocked
    };
  }
  interface activeEvent {
    type: typeof ACTIVEEVENT;
    payload: {
      activeEvent: ActiveEvent
    };
  }
  interface cancelHandler {
    type: typeof CANCELHANDLER;
    payload: {
      cancelHandler: CancelHandler
    };
  }
  interface saveHandler {
    type: typeof SAVEHANDLER;
    payload: {
      saveHandler: SaveHandler
    };
  }
  interface activeAttrEvent {
    type: typeof ACTIVEATTREVENT;
    payload: {
      activeEvent: ActiveAttrEvent
    };
  }
  interface activeAttribute {
    type: typeof ACTIVEATTRIBUTE;
    payload: {
      activeAttribute: ActiveAttribute
    };
  }

  interface attributeBlocked {
    type: typeof ATTRIBUTEBLOCKED;
    payload: {
      attributeBlocked: AttributeBlocked
    };
  }
  interface activeProduct {
    type: typeof ACTIVEPRODUCT;
    payload: {
      activeProduct: ActiveProduct
    };
  }
  interface activeRowArchive {
    type: typeof ACTIVEROWARCHIVE;
    payload: {
      activeRowArchive: ActiveRowArchive
    };
  }
  interface checkedStatus {
    type: typeof CHECKEDSTATUS;
    payload: {
      checkedStatus: CheckedStatus
    };
  }
  interface confirmTooltip {
    type: typeof CONFIRMTOOLTIP;
    payload: {
      confirmTooltip: ConfirmTooltip
    };
  }
  interface emptyValueBlocked {
    type: typeof EMPTYVALUEBLOCKED;
    payload: {
      emptyValueBlocked: EmptyValueBlocked
    };
  }
  interface showEmptyField {
    type: typeof SHOWEMPTYFIELD;
    payload: {
      showEmptyField: ShowEmptyField
    };
  }
  interface emptyFieldStatus {
    type: typeof EMPTYFIELDSTATUS;
    payload: {
      emptyFieldStatus: EmptyFieldStatus
    };
  }
  interface activeSection {
    type: typeof ACTIVESECTION;
    payload: {
      activeSection: ActiveSection
    };
  }
  //E
  interface handleActiveTooltip {
    type: typeof MAINTOOLTIP;
    payload: any;
  }
  //END E
  export type ActionsTypes = CreateLoginRequest | addPathname | activeModal | addProduct |
                             submitData | checkboxList | refreshFetch | selectAll | tooltipActive | 
                             touchBlocked | activeEvent | cancelHandler | saveHandler | activeAttrEvent | 
                             activeAttribute | attributeBlocked | activeProduct | activeRowArchive |
                             checkedStatus | confirmTooltip | emptyValueBlocked | showEmptyField | emptyFieldStatus | activeSection | handleActiveTooltip;