import { StrategyActionsTypes, StrategyBoardElementOutput, STRATEGY_BOARD_ELEMENT_OUTPUT } from "../../../../types/typesStrategy";


const initialState: StrategyBoardElementOutput = {
    element_output:null
}

const reducer = (state = initialState, action: StrategyActionsTypes): StrategyBoardElementOutput => {

    switch (action.type) {

        case STRATEGY_BOARD_ELEMENT_OUTPUT:
            const {element_output} = action.payload;
            
            return {
                element_output
            }

        default:
            return state

    }
}

export default reducer