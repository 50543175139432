import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import classes from "./inputCreate.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../store/combineReducer";
import CustomSelect from "../../../../../../components/UI/Select/Select";
import {
  ButtonSizes,
  ButtonTypes,
  icons,
  messages,
  storageKeys,
} from "../../../../../../settings/settings";
import { request } from "../../../../../../helpers/request";
import { endpoints } from "../../../../../../api/endpoints";
import {
  handleStrategyBoardSidePanelContent,
  handleStrategyBoardSidePanelCreateInputObject,
  handleStrategyValidateStatus,
} from "../../../../../../store/actions/actionsStrategy";
import Editor from "./Editor/editor";
import DataTree from "../../../../../../components/UI/Tree/DataTree";
import { parseStringPromise } from "xml2js";
import { ActionCreators } from "redux-undo";
import { reformatSourceData } from "../../../../../../helpers/reformatSourceData";
import Button from "../../../../../../components/UI/Button/ButtonV2";

let timeoutInstance: any;

const InputCreate: FC = () => {
  const {
    reducerStrategy: {
      StrategyBoardSidePanel: {
        Input: { input_createObject },
      },
      SourceDataTypes,
    },
  } = useSelector((state: RootState) => state);
  const {
    strategy_designer: { strategy_source_data },
  } = endpoints;

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const dispatch = useDispatch();
  const [strategyDataTypes, setStrategyDataTypes] = useState<Array<object>>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<string>("");
  const [selectedLanguageId, setSelectedLanguageId] = useState<number>(0);
  const [editorData, setEditorData] = useState<string>("");
  const [sourceData, setSourceData] = useState<string>("");
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [validate, setValidate] = useState<boolean>(false);
  const [isInvalidJson, setIsInvalidJson] = useState<undefined | boolean>(
    undefined
  );

  const reFormatTypeOptions = useCallback((types: Array<object>) => {
    return types?.map((type: any) => {
      const { id, name } = type;
      return {
        value: id,
        option: name,
      };
    });
  }, []);

  const handleTypes = useCallback(
    (typeId: number) => {
      strategyDataTypes?.forEach((types: any) => {
        const { value, option } = types;

        setSelectedLanguageId(typeId);
        if (value === typeId) {
          if (option === "JSON") setSelectedLanguage("JAVASCRIPT");
          if (option !== "JSON") setSelectedLanguage(option);
          setValidate(false);
        }
      });
    },
    [strategyDataTypes]
  );

  const jsonParser = useCallback((jsonCode: string) => {
    return new Promise<object>((resolve) => {
      const parsedJson = JSON.parse(jsonCode);
      resolve(parsedJson);
    });
  }, []);

  const convertSelectedEditorLanguageDataToObject = useCallback(
    (data: string) => {
      if (selectedLanguage === "JAVASCRIPT") {
        jsonParser(data)
          .then((result) =>
            dispatch(
              handleStrategyBoardSidePanelCreateInputObject({ data: result })
            )
          )
          .catch(() =>
            dispatch(
              handleStrategyBoardSidePanelCreateInputObject({
                data: "Invalid JSON",
              })
            )
          );
      }
      if (selectedLanguage === "XML") {
        parseStringPromise(data, { explicitArray: false })
          .then((result) => {
            const jsonString = JSON.stringify(result);
            jsonParser(jsonString)
              .then((res) =>
                dispatch(
                  handleStrategyBoardSidePanelCreateInputObject({ data: res })
                )
              )
              .catch(() =>
                dispatch(
                  handleStrategyBoardSidePanelCreateInputObject({
                    data: "Invalid converted XML JSON",
                  })
                )
              );
          })
          .catch(() =>
            dispatch(
              handleStrategyBoardSidePanelCreateInputObject({
                data: "Invalid XML",
              })
            )
          );
      }
    },
    [selectedLanguage, dispatch, jsonParser]
  );

  const checkJsonFormatAndConvert = (data: any) => {
    if (!data) return;
    const pattern = /[-~<>[/\]{}()*+=?%:;.,!@&\\^$|#\s]/g;
    const incorrectKeys = [] as string[];
    function trimObj(obj: any) {
      if (!Array.isArray(obj) && typeof obj != "object") return obj;
      return Object.keys(obj).reduce(
        (acc: any, key: any) => {
          if (key.match(pattern)) {
            incorrectKeys.push(key);
          }
          acc[key.trim()] =
            typeof obj[key] == "string" ? obj[key].trim() : trimObj(obj[key]);
          return acc;
        },
        Array.isArray(obj) ? [] : {}
      );
    }
    try {
      trimObj(JSON.parse(data));
      if (incorrectKeys.length === 0)
        convertSelectedEditorLanguageDataToObject(editorData);
      const nodeList = document.querySelectorAll(
        "span"
      ) as NodeListOf<HTMLSpanElement>;
      nodeList?.forEach((span: HTMLSpanElement) => {
        incorrectKeys?.forEach((word: string) => {
          if (span.textContent === `"${word}"`) {
            span.innerHTML = `<mark style="background-color: rgba(255, 93, 85, 0.3);
              border-radius: 0.43vh;color:#001237">${span.textContent}</mark>`;
          }
        });
      });
    } catch (error) {
      console.warn("Invalid JSON");
    }
  };

  const getAvailableSourceData = useCallback(() => {
    const sourceDataJSON = sessionStorage.getItem(
      storageKeys.activeStrategySourceData
    );

    if (!sourceDataJSON) {
      return;
    }

    const { type, data } = JSON.parse(sourceDataJSON);
    handleTypes(type);
    setEditorData(JSON.stringify(data, null, "\t"));
    convertSelectedEditorLanguageDataToObject(JSON.stringify(data));
  }, [handleTypes, convertSelectedEditorLanguageDataToObject]);

  const resizeElements = useCallback((event: MouseEvent) => {
    const { clientX } = event;
    const wrapper = wrapperRef.current as HTMLElement;
    const codeArea = document.getElementById("codeArea");
    const resizer = document.getElementById("resizerX");
    const editor = document.getElementById("createEditor");
    const dataTree = document.getElementById("createDataTree");

    if (!wrapper || !codeArea || !resizer || !editor || !dataTree) return;

    const resizerWidthPercentage =
      (resizer.offsetWidth / codeArea.offsetWidth) * 100;
    const movementValue = clientX - wrapper.offsetLeft - 37;
    const movementPercentage = (movementValue / codeArea.offsetWidth) * 100;
    const minBorder = 30;
    const maxBorder = 70 - resizerWidthPercentage;

    if (movementPercentage <= minBorder || movementPercentage >= maxBorder) {
      return;
    }

    resizer.style.left = `${movementPercentage + resizerWidthPercentage / 2}%`;
    editor.style.width = `${movementPercentage}%`;
    dataTree.style.width = `${
      100 - movementPercentage - resizerWidthPercentage
    }%`;
  }, []);

  const applyResizeFunctionality = useCallback(() => {
    const codeArea = document.getElementById("codeArea");

    if (!codeArea) return;

    codeArea.addEventListener("mousemove", resizeElements);
  }, [resizeElements]);

  const clearResizeFunctionality = useCallback(() => {
    const codeArea = document.getElementById("codeArea");

    if (!codeArea) return;

    codeArea.removeEventListener("mousemove", resizeElements);
  }, [resizeElements]);

  const resetInvalidJSONStatus = useCallback(() => {
    if (isInvalidJson === undefined) {
      return;
    }
    const resettingStateTimeout = setTimeout(() => {
      setIsInvalidJson(undefined);
    }, 2e3);

    return () => {
      clearTimeout(resettingStateTimeout);
    };
  }, [isInvalidJson]);

  useLayoutEffect(getAvailableSourceData, [getAvailableSourceData]);

  useLayoutEffect(() => {
    const { data } = SourceDataTypes;
    if (data !== null) {
      setStrategyDataTypes(reFormatTypeOptions(data));
    }
  }, [SourceDataTypes, reFormatTypeOptions]);

  useEffect(() => {
    if (editorData === "First select type, please" && selectedLanguage !== "") {
      setEditorData(" ");
      setValidate(true);
    }
  }, [editorData, selectedLanguage]);

  useEffect(() => {
    if (typeof input_createObject.present.data === "object") {
      setSourceData(JSON.stringify(input_createObject.present.data));
    }
  }, [input_createObject.present.data]);

  useEffect(() => {
    document.addEventListener("mouseup", clearResizeFunctionality);

    return () => {
      document.removeEventListener("mouseup", clearResizeFunctionality);
    };
  }, [clearResizeFunctionality]);

  useEffect(() => {
    return () => clearTimeout(timeoutInstance);
  }, []);

  useEffect(resetInvalidJSONStatus);

  const closeWrapper = () => {
    const { current } = wrapperRef;

    if (current) {
      current.classList.add(classes.CloseAnimation);

      timeoutInstance = setTimeout(() => {
        dispatch(handleStrategyBoardSidePanelContent({ content_value: null }));
        dispatch(handleStrategyBoardSidePanelCreateInputObject({ data: "" }));
        dispatch(ActionCreators.clearHistory());
      }, 1e3);
    }
  };

  const createStrategySourceDataModel = (
    sourceData: string,
    dataTypeId: number
  ) => {
    const strategyDetailsJSON = sessionStorage.getItem(
      storageKeys.activeStrategy
    );
    let strategyId = null;
    if (strategyDetailsJSON) {
      const { id } = JSON.parse(strategyDetailsJSON);
      strategyId = id;
    }

    return {
      strategy: strategyId,
      source_data: JSON.parse(sourceData),
      strategy_source_data_type: dataTypeId,
    };
  };

  const undo = () => {
    dispatch(ActionCreators.undo());
  };

  const redo = () => {
    dispatch(ActionCreators.redo());
  };

  const handleSubmit = () => {
    const sourceDataJSON = sessionStorage.getItem(
      storageKeys.activeStrategySourceData
    );

    if (
      sourceData !== "" &&
      sourceData !== "Invalid JSON" &&
      sourceData !== "Invalid XML"
    ) {
      const requestBody = createStrategySourceDataModel(
        sourceData,
        selectedLanguageId
      );

      if (!sourceDataJSON) {
        return;
      }

      setLoading(true);
      const { id } = JSON.parse(sourceDataJSON);

      request
        .patch(strategy_source_data.patch(id), requestBody)
        .then((res) => {
          const {
            success,
            response: { id, source_data, strategy_source_data_type },
          } = res;
          if (success) {
            sessionStorage.setItem(
              storageKeys.activeStrategySourceData,
              JSON.stringify({
                id,
                type: strategy_source_data_type,
                data: source_data,
              })
            );
            setLoading(false);
            reformatSourceData({ sourceData: source_data });
            dispatch(
              handleStrategyBoardSidePanelCreateInputObject({ data: " " })
            );
            dispatch(handleStrategyValidateStatus({ status: true }));
            dispatch(ActionCreators.clearHistory());
            closeWrapper();
          } else {
            console.warn(messages.messageApiPatchStatusFalse, res);
          }
        })
        .catch((error) => console.error(messages.messageApiPatchError, error));
    }
  };

  const setFullScreenState = () => {
    const { current } = wrapperRef;

    if (current && fullScreen) {
      current.classList.remove(classes.FullScreen);
      setFullScreen(false);
    } else if (current && !fullScreen) {
      current.classList.add(classes.FullScreen);
      setFullScreen(true);
    }
  };

  const onBeautifying = () => {
    setEditorData((state: string) => {
      try {
        const parsedData = JSON.parse(state);
        setIsInvalidJson(false);
        return JSON.stringify(parsedData, null, "\t");
      } catch (error) {
        console.error("Invalid json", `${error}`);
        setIsInvalidJson(true);
        return state;
      }
    });
  };

  return (
    <div ref={wrapperRef} className={classes.InputCreateWrapper}>
      <div className={classes.InputCreateHeader}>
        <div className={classes.InputCreateHeaderStack}>
          <img
            src={fullScreen ? icons.exitFullScreen : icons.fullScreen}
            alt="screen icon"
            title={fullScreen ? "Exit full screen" : "Full screen"}
            onClick={setFullScreenState}
          />
          <span className={classes.InputCreateHeaderTitle}>
            create input object
          </span>
        </div>

        <CustomSelect
          placeHolder="Select type"
          options={strategyDataTypes}
          defaultValue={selectedLanguageId}
          onChange={handleTypes}
          validate={validate}
        />
      </div>
      <div id="codeArea" className={classes.InputCreateCodeArea}>
        <Editor
          language={selectedLanguage}
          lineNumbers={true}
          header={true}
          leftHeaderElements={["Beautifier"]}
          value={editorData}
          hasError={isInvalidJson}
          onChange={setEditorData}
          onBeautify={onBeautifying}
        />
        <div className={classes.CodeAreaGap} id={"resizerX"}>
          <button
            onClick={() => {
              checkJsonFormatAndConvert(editorData);
            }}
          >
            <svg
              viewBox="0 0 10 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Convert to object</title>
              <path d="M0.115385 4.23077C0.0384615 4.15385 0 4.03846 0 3.96154C0 3.88462 0.0384615 3.76923 0.115385 3.69231L0.653846 3.15385C0.807692 3 1.03846 3 1.19231 3.15385L1.23077 3.19231L3.34615 5.46154C3.42308 5.53846 3.53846 5.53846 3.61538 5.46154L8.76923 0.115385H8.80769C8.96154 -0.0384615 9.19231 -0.0384615 9.34615 0.115385L9.88462 0.653846C10.0385 0.807692 10.0385 1.03846 9.88462 1.19231L3.73077 7.57692C3.65385 7.65385 3.57692 7.69231 3.46154 7.69231C3.34615 7.69231 3.26923 7.65385 3.19231 7.57692L0.192308 4.34615L0.115385 4.23077Z" />
            </svg>
          </button>
          <svg
            className={classes.ThreeDots}
            onMouseDown={applyResizeFunctionality}
          >
            <circle />
            <circle />
            <circle />
            Sorry, your browser does not support inline SVG.
          </svg>
        </div>
        <DataTree
          header={true}
          leftHeaderElements={["Undo", "Redo"]}
          undoAction={undo}
          redoAction={redo}
          data={input_createObject.present.data}
        />
      </div>
      <div className={classes.InputCreateFooter}>
        <Button
          size={ButtonSizes.LARGE}
          type={loading ? ButtonTypes.LOADING : ButtonTypes.PRIMARY}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
      {!fullScreen && (
        <button
          className={classes.InputCreateCloseButton}
          onClick={closeWrapper}
        >
          <img src={icons.closeBtn} alt="Close icon" />
        </button>
      )}
    </div>
  );
};

export default InputCreate;
