import { ADD_PRODUCT, DELETE_PRODUCT, GET_ALL_PRODUCTS, ProductActionsTypes } from "../types/typesProduct";

export const handleProductData = (productData: any): ProductActionsTypes => {
  return {
    type: GET_ALL_PRODUCTS,
    payload: { productData },
  };
};

export const handleAddProduct = (productData: any):any => {
  return {
    type: ADD_PRODUCT, 
    payload: productData
  }
};

export const handleDeleteProduct = (id : number): any => {
  return {
    type: DELETE_PRODUCT, 
    payload: id
  }
};


