import classes from "./ModalLoader.module.scss";
import Portal from "../Portal/Portal";
import { FC } from "react";
interface IModalLoader {
  spinner?: boolean;
  messageTitle?:string;
  messageBody?:string
}
export const ModalLoader: FC<IModalLoader> = ({ spinner,messageTitle,messageBody }) => {
  return (
    <Portal>
      <div className={classes.Wrapper}>
        {spinner && (
          <div className={classes.Spinner}>
            <svg
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M5.14087 2.33928C5.78687 2.33928 6.31056 1.81561 6.31056 1.16964C6.31056 0.523665 5.78687 0 5.14087 0C4.49488 0 3.97119 0.523665 3.97119 1.16964C3.97119 1.81561 4.49488 2.33928 5.14087 2.33928Z" />
              <path d="M5.1407 10.0001C5.52826 10.0001 5.84243 9.68593 5.84243 9.29839C5.84243 8.91084 5.52826 8.59668 5.1407 8.59668C4.75314 8.59668 4.43896 8.91084 4.43896 9.29839C4.43896 9.68593 4.75314 10.0001 5.1407 10.0001Z" />
              <path d="M2.26666 3.41263C2.8481 3.41263 3.31945 2.9413 3.31945 2.35988C3.31945 1.77846 2.8481 1.30713 2.26666 1.30713C1.68522 1.30713 1.21387 1.77846 1.21387 2.35988C1.21387 2.9413 1.68522 3.41263 2.26666 3.41263Z" />
              <path d="M8.01502 8.69259C8.33802 8.69259 8.59986 8.43076 8.59986 8.10777C8.59986 7.78478 8.33802 7.52295 8.01502 7.52295C7.69202 7.52295 7.43018 7.78478 7.43018 8.10777C7.43018 8.43076 7.69202 8.69259 8.01502 8.69259Z" />
              <path d="M1.07614 6.1698C1.59282 6.1698 2.01166 5.75097 2.01166 5.23431C2.01166 4.71766 1.59282 4.29883 1.07614 4.29883C0.559471 4.29883 0.140625 4.71766 0.140625 5.23431C0.140625 5.75097 0.559471 6.1698 1.07614 6.1698Z" />
              <path d="M9.20536 5.70171C9.46359 5.70171 9.67293 5.49238 9.67293 5.23415C9.67293 4.97593 9.46359 4.7666 9.20536 4.7666C8.94713 4.7666 8.73779 4.97593 8.73779 5.23415C8.73779 5.49238 8.94713 5.70171 9.20536 5.70171Z" />
              <path d="M1.68819 7.52945C1.36826 7.84938 1.36826 8.36703 1.68819 8.68695C2.00775 9.00687 2.52618 9.00687 2.84573 8.68695C3.16567 8.36703 3.16567 7.84938 2.84573 7.52945C2.52618 7.20915 2.00813 7.20611 1.68819 7.52945Z" />
              <path d="M8.01474 2.71012C8.20841 2.71012 8.36542 2.55312 8.36542 2.35945C8.36542 2.16579 8.20841 2.00879 8.01474 2.00879C7.82107 2.00879 7.66406 2.16579 7.66406 2.35945C7.66406 2.55312 7.82107 2.71012 8.01474 2.71012Z" />
            </svg>
          </div>
        )}
        <div className={classes.Message}>
          <span>{messageTitle}</span>
          <small>{messageBody}</small>
        </div>
      </div>
    </Portal>
  );
};
