import React from "react";
import classNames from "classnames";

import classes from "./Login.module.scss";
import { logo } from "../../assets/index";
import axios from "axios";
import { Link, Switch, Route } from "react-router-dom";
import { endpoints } from "../../api/endpoints";
import { icons } from "../../settings/settings";

const Login: React.FC = () => {
  const [emailLoginStatus, setEmailLoginStatus] = React.useState(false);
  const [passwordLoginStatus, setPasswordLoginStatus] = React.useState(false);
  const [loginMessage, setLoginMessage] = React.useState("");

  const emailLoginRef = React.useRef<HTMLInputElement | null>(null);
  const passwordLoginRef = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    if (emailLoginStatus && passwordLoginStatus) {
      const data = {
        email: emailLoginRef.current?.value,
        password: passwordLoginRef.current?.value,
      };
      axios
        .post(endpoints.login, data)
        .then((response) => {
          if (!response.data.error) {
            sessionStorage.setItem(
              "credentials",
              JSON.stringify(response.data)
            );
            // const token:any = JSON.stringify(response.data);

            const AuthStr = "Bearer " + response.data.access_token || "";
            axios
              .get(endpoints.profile.get, {
                headers: { Authorization: AuthStr },
              })
              .then((res) => {
                res.data.email = data.email;
                sessionStorage.setItem(
                  "user_details",
                  JSON.stringify({
                    ...res.data,
                    password: passwordLoginRef.current?.value,
                  })
                );
                window.location.pathname = "/dashboard";
              })
              .catch((error) => {
                console.log("error " + error);
              });
            setLoginMessage("");
          } else {
            setEmailLoginStatus(false);
            setPasswordLoginStatus(false);
            setLoginMessage("Login or Password is incorrect");
          }
        })
        .catch((error) => {
          console.log("error " + error);
        });
    }
  }, [emailLoginStatus, passwordLoginStatus]);

  const focusIn = (e: any) => {
    e.target.parentNode.childNodes[0]?.classList.add(classes.ActiveLabel);
  };

  const focusOut = (e: any) => {
    if (e.target.parentNode.childNodes[1]?.value === "") {
      e.target.parentNode.childNodes[0]?.classList.remove(classes.ActiveLabel);
    }
  };

  const loginHandler = async () => {
    const data = {
      email: emailLoginRef.current?.value,
      password: passwordLoginRef.current?.value,
    };
    function validateEmail(email: any) {
      const re =
        /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    const passwordFormat =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,32}$/g;
    let list = [emailLoginRef.current, passwordLoginRef.current];
    if (
      emailLoginRef.current?.value === "" ||
      passwordLoginRef.current?.value === ""
    ) {
      list.forEach((el: any) => {
        if (el?.value === "" && el.getAttribute("type") === "email") {
          el.parentNode.children[2].children[0].textContent =
            "This field is required";
          el.parentNode.children[2].style.display = "flex";
          setEmailLoginStatus(false);
        } else if (el?.value === "" && el.getAttribute("type") === "password") {
          el.parentNode.children[2].children[0].textContent =
            "This field is required";
          el.parentNode.children[2].style.display = "flex";
          setPasswordLoginStatus(false);
        } else {
          el.parentNode.children[2].children[0].textContent = "";
          el.parentNode.children[2].style.display = "none";
          setPasswordLoginStatus(true);
          setEmailLoginStatus(true);
        }
      });
    } else if (
      emailLoginRef.current?.value !== "" &&
      passwordLoginRef.current?.value !== ""
    ) {
      list.forEach((el: any) => {
        if (el?.getAttribute("type") === "password") {
          if (el?.value.match(passwordFormat)) {
            el.parentNode.children[2].children[0].textContent = "";
            el.parentNode.children[2].style.display = "none";
            setPasswordLoginStatus(true);
          } else {
            el.parentNode.children[2].children[0].textContent =
              "Password is invalid";
            el.parentNode.children[2].style.display = "flex";
            setPasswordLoginStatus(false);
          }
        } else if (el?.getAttribute("type") === "email") {
          if (validateEmail(data.email)) {
            el.parentNode.children[2].children[0].textContent = "";
            el.parentNode.children[2].style.display = "none";
            setEmailLoginStatus(true);
          } else {
            el.parentNode.children[2].children[0].textContent =
              "Invalid email address";
            el.parentNode.children[2].style.display = "flex";

            setEmailLoginStatus(false);
          }
        }
      });
    }
  };

  const closeTooltip = (e: any) => {
    if (e.currentTarget.parentNode) {
      e.currentTarget.parentNode.style.display = "none";
    }
  };

  React.useEffect(() => {
    const listener = (e: any) => {
      if (e.keyCode === 13) {
        loginHandler();
      }
    };
    const inputEmail = document.querySelector("#email");
    const inputPassword = document.querySelector("#password");
    inputEmail?.addEventListener("keydown", listener);
    inputPassword?.addEventListener("keydown", listener);
  }, []);

  return (
    <div className={classes.Container}>
      <div className={classes.LeftSide}>
        <div className={classes.Header}>
          <div className={classes.Logo}>
            <img src={logo} alt="logo" />
          </div>
        </div>
        <Switch>
          <Route
            path={["/", "/login"]}
            render={() => {
              return (
                <div className={classes.Form}>
                  <div className={classes.Title}>
                    Welcome to the ScorPort Decision Engine Platform
                  </div>
                  <div className={classes.InputArea}>
                    <div
                      onFocus={focusIn}
                      onBlur={focusOut}
                      className={classNames(
                        classes.FormInput,
                        classes.InputEmail
                      )}
                    >
                      <label htmlFor="email">Email address</label>
                      <input
                        ref={emailLoginRef}
                        id="email"
                        type="email"
                        placeholder="Email"
                      />

                      <div className={classes.TooltipTop}>
                        <div className={classes.Message}></div>
                        <div onClick={closeTooltip} className={classes.Close}>
                          <img src={icons.closeBtn} alt="close" />
                        </div>
                      </div>
                    </div>
                    <div
                      onFocus={focusIn}
                      onBlur={focusOut}
                      className={classNames(
                        classes.FormInput,
                        classes.InputPassword
                      )}
                    >
                      <div>
                        <label
                          htmlFor="password"
                          className={
                            passwordLoginRef?.current?.value
                              ? classes.ActiveLabel
                              : ""
                          }
                        >
                          Password
                        </label>
                        <input
                          ref={passwordLoginRef}
                          id="password"
                          type="password"
                          placeholder="Password"
                        />
                        <div className={classes.Tooltip}>
                          <div className={classes.Message}></div>
                          <div onClick={closeTooltip} className={classes.Close}>
                            <img src={icons.closeBtn} alt="close" />
                          </div>
                        </div>
                      </div>
                      <div className={classes.ForgotPassword}>
                        <a href="fp">Forgot Password ?</a>
                      </div>
                    </div>
                    <div className={classes.Checkbox}>
                      <input type="checkbox" id="keepMeLoggedIn" />
                      <span className={classes.Checkmark}></span>
                      <label htmlFor="keepMeLoggedIn">Keep me logged in</label>
                    </div>
                    <div className={classes.SubmitArea}>
                      {loginMessage && (
                        <div className={classes.LoginMessage}>
                          <div className={classes.Tooltip}>
                            <div className={classes.Message}>
                              {loginMessage}
                            </div>
                            <div
                              onClick={closeTooltip}
                              className={classes.Close}
                            >
                              <img src={icons.closeBtn} alt="close" />
                            </div>
                          </div>
                        </div>
                      )}
                      <button onClick={loginHandler}>
                        <svg
                          width="13"
                          height="9"
                          viewBox="0 0 13 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.17706 4.06999L4.04564 0.178091C4.28167 -0.0593636 4.66447 -0.0593636 4.9005 0.178091C5.13658 0.415594 5.13658 0.8006 4.9005 1.0381L2.06382 3.89189H12.3955C12.7293 3.89189 13 4.16417 13 4.49999C13 4.83577 12.7293 5.1081 12.3955 5.1081H2.06382L4.9004 7.96188C5.13648 8.19939 5.13648 8.58439 4.9004 8.8219C4.78241 8.94055 4.62767 9 4.47297 9C4.31828 9 4.16358 8.94055 4.04554 8.8219L0.17706 4.93C-0.05902 4.6925 -0.05902 4.30749 0.17706 4.06999Z"
                            fill="#FFFFFF"
                          />
                        </svg>
                        Log In
                      </button>
                    </div>
                    <div className={classes.SocialLinkArea}>
                      <div className={classes.LeftLine}></div>
                      <div className={classes.Space}>or</div>
                      <div className={classes.RightLine}></div>
                    </div>
                    <div className={classes.SignupLink}>
                      Please contact us on{" "}
                      <Link
                        onClick={() =>
                          (window.location.href = "mailto:sales@scorport.eu")
                        }
                        to="/contact"
                      >
                        sales@scorport.eu
                      </Link>
                    </div>
                  </div>
                </div>
              );
            }}
          />
        </Switch>
        <div className={classes.Footer}>
          <div className={classes.First}>
            <p>
              To request access please write to{" "}
              <Link
                to="/contact"
                onClick={() =>
                  (window.location.href = "mailto:sales@scorport.eu")
                }
              >
                sales@scorport.eu
              </Link>
              , or call{" "}
              <Link
                to="/contact"
                onClick={() => (window.location.href = "tel:+420 776 518 731")}
              >
                +420 776 518 731
              </Link>
              .
            </p>
          </div>
          <div>
            Copyright © 2022 Scorport EU S.R.O. All rights reserved. |{" "}
            <a href="https://scorport.eu/privacy/" target="blank">
              Privacy Policy
            </a>{" "}
            |
          </div>
        </div>
      </div>
      <div className={classes.RightSide}>
        <div className={classes.Layer}>
          <div className={classes.Cover}>
            <h1 className={classes.Title}>DEMO ENVIRONMENT</h1>
            <h1 className={classes.Text}>
              We look forward to demonstrate how our Loan Origination solution
              is a perfect fit for your company’s business growth.
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
