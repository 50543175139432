import React,{ FC } from "react";
import { useSelector } from "react-redux";
import { MessageList, OutputMessages } from "../../../../settings/settings";
import { RootState } from "../../../../store";
import classes from './Tooltip.module.scss';
const Tooltip:FC = ()=>{
    const {
        reducerStrategy:{
            StrategyBoardElements:{
               
                StrategyOutputFormatMessage:{
                    message
                }
            }
        }
      } = useSelector((state: RootState) => state);
    return(
        <div id="tooltip_message" className={classes.Tooltip}>
            <div className={classes.Message}>
                {message === OutputMessages.UNFINISHED_SAVE &&
                    MessageList.UNFINISHED_SAVE
                }
                {message === OutputMessages.NULL &&
                    MessageList.NULL
                }
            </div>
        </div>
    )
}
export default Tooltip;