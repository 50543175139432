
export const splitBoard = (type:any,data:any)=>{
  if(type==="start"){
    const board = document.querySelector("#svg_board") as SVGSVGElement;
    if(!board) return;
    let {width:svg_width,height:svg_height} = board.getBoundingClientRect();
    let zoom_percent_span = document.querySelector('#zoom_percent') as HTMLInputElement
    let percent:any = zoom_percent_span?.value?.split('%')?.[0];
    const elements = board.querySelectorAll("rect[id = figure]") as NodeListOf<SVGRectElement>;
    if(elements.length === 0) return;
    const svgBoard = document.querySelector("#StrategyBoard") as HTMLDivElement;

    elements.forEach((element:any)=>{
      const x = Number(element.getAttribute("x"));
      const y = Number(element.getAttribute("y"));
      const currentWidth = svg_width/+percent*100;
      const currentHeight = svg_height/+percent*100;
      if((currentWidth - x < 220)){
        svgBoard.style.width = "800%";

      }
      if((currentHeight - y < 220)){
        svgBoard.style.height = "800%";

      }

    })

  }else{
    const board = document.querySelector("#svg_board") as SVGSVGElement;
    if(!board) return;
    
    const {x,y,id} = data;
    const currentWidth = window.innerWidth*2.5;
    const currentHeight = window.innerHeight*2.5;
    const svgBoard = document.querySelector("#StrategyBoard") as HTMLDivElement;
    if(!svgBoard) return;
    if((currentWidth-x < 220)){
      svgBoard.style.width = "800%";
    }else{
      svgBoard.style.width = "250%";

    }
    if((currentHeight-y < 220)){
      svgBoard.style.height = "800%";
    }else{
      svgBoard.style.height = "250%";

    }
    const elements = board.querySelectorAll("rect[id = figure]") as NodeListOf<SVGRectElement>;
    elements.forEach((element:any)=>{
      if(element.dataset.id !== id){
        
        const x = Number(element.getAttribute("x"));
        const y = Number(element.getAttribute("y"));
        if(currentWidth-x < 220){
          svgBoard.style.width = "800%";
        }
        if(currentHeight-y < 220){
          svgBoard.style.height = "800%";
        }
        
      }

    })
  }
  
}