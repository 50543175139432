import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import classes from "./StrategyDesigner.module.scss";
import { request } from "../../helpers/request";
import { endpoints } from "../../api/endpoints";
import { useDispatch, useSelector } from "react-redux";
import {
  addAllStrategyTableSelectedList,
  addStrategyTableSelectedList,
  handleActiveStrategy,
  handleStrategyData,
  handleStrategyStatus,
  handleStrategyVersion,
  removeAllStrategyTableSelectedList,
  removeStrategyTableSelectedList,
} from "../../store/actions/actionsStrategy";
import { RootState } from "../../store/combineReducer";
import ModalForm from "../../components/UI/Modal/ModalFormV2";
import {
  ButtonTypes,
  initialStrategy,
  messages,
  ModalFormInlineElementTypes,
  ModalNames,
  storageKeys,
  strategyCreateWaitMessages,
} from "../../settings/settings";
import { openModal, resetModal } from "../../store/actions/actionsModal";
import Header from "../../components/UI/Header/Header";
import Table from "../../components/UI/Table/TableV2";
import Modal from "../../components/UI/Modal/Modal";
import { useGetAllData } from "../../hooks/useGetAllData";

enum StrategyDesignerActions {
  ADD_STRATEGY,
  EDIT_STRATEGY,
  ARCHIVE_STRATEGY,
  ARCHIVE_ALL_STRATEGY,
  DELETE_STRATEGY,
  DELETE_ALL_STRATEGY,
  CLONE_STRATEGY,
  CLONE_ALL_STRATEGY,
}

function* msgGenerator() {
  for (const msg of strategyCreateWaitMessages) {
    yield msg;
  }
}

let timeoutInstance: any;
let firstRender: boolean = true;

const StrategyDesigner: FC = () => {
  const dispatch = useDispatch();
  const messageGenerator = msgGenerator();
  const {
    strategy_designer: { strategy },
  } = endpoints;
  const {
    reducerStrategy: {
      StrategyData,
      StrategyStatus,
      StrategyVersion,
      ActiveStrategy,
      StrategyTableSelectedList,
    },
    reducerModal: { modalState },
  } = useSelector((state: RootState) => state);

  const [activeAction, setActiveAction] = useState<StrategyDesignerActions>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>();
  const {
    strategy: {
      getAllStrategies,
      getAllStrategyStatuses,
      getAllStrategyVersions,
    },
  } = useGetAllData();

  const tableDataLoading =
    getAllStrategies.isLoading ||
    getAllStrategyStatuses.isLoading ||
    getAllStrategyVersions.isLoading;

  const openAddModal = useCallback(() => {
    setActiveAction(StrategyDesignerActions.ADD_STRATEGY);
    dispatch(
      openModal({
        modalState: {
          visible: true,
          title: messages.titleAddPanel("strategy"),
          question: "",
          message: "",
          buttonMessage: messages.titleBtnSave,
          buttonType: ButtonTypes.SAVE,
          name: ModalNames.STRATEGY_DESIGNER_FORM,
          fields: [
            {
              elementType: ModalFormInlineElementTypes.INPUT,
              name: "name",
              label: "Name",
              initialValue: null,
            },
            {
              elementType: ModalFormInlineElementTypes.INPUT,
              name: "description",
              label: "Description",
              initialValue: null,
            },
            {
              elementType: ModalFormInlineElementTypes.SELECT,
              name: "version",
              label: "Version",
              initialValue: StrategyVersion?.data[0]?.id ?? 1,
              selectData: StrategyVersion?.data
                ? StrategyVersion?.data.map((version: any) => {
                    return { id: version.id, name: version.name };
                  })
                : [],
            },
            {
              elementType: ModalFormInlineElementTypes.SELECT,
              name: "status",
              label: "Status",
              initialValue: StrategyStatus?.data[0]?.id ?? 1,
              selectData: StrategyStatus?.data
                ? StrategyStatus.data.map((version: any) => {
                    return { id: version.id, name: version.name };
                  })
                : [],
            },
          ],
        },
      })
    );
  }, [StrategyStatus, StrategyVersion, dispatch]);

  const openEditModal = useCallback(
    (id: number | undefined) => {
      if (!id) return;

      const strategy = StrategyData?.data.find((strategy: any) => {
        return strategy?.id === id;
      });

      setActiveAction(StrategyDesignerActions.EDIT_STRATEGY);
      dispatch(handleActiveStrategy({ data: strategy }));
      dispatch(
        openModal({
          modalState: {
            visible: true,
            title: messages.titleEditPanel("strategy"),
            question: "",
            message: "",
            buttonMessage: messages.titleBtnApply,
            buttonType: ButtonTypes.PRIMARY,
            name: ModalNames.STRATEGY_DESIGNER_FORM,
            fields: [
              {
                elementType: ModalFormInlineElementTypes.INPUT,
                name: "name",
                label: "Name",
                initialValue: strategy?.name,
              },
              {
                elementType: ModalFormInlineElementTypes.INPUT,
                name: "description",
                label: "Description",
                initialValue: strategy?.description,
              },
              {
                elementType: ModalFormInlineElementTypes.SELECT,
                name: "version",
                label: "Version",
                initialValue: strategy?.version,
                selectData: StrategyVersion?.data
                  ? StrategyVersion?.data.map((version: any) => {
                      return { id: version.id, name: version.name };
                    })
                  : [],
              },
              {
                elementType: ModalFormInlineElementTypes.SELECT,
                name: "status",
                label: "Status",
                initialValue: strategy?.status,
                selectData: StrategyStatus?.data
                  ? StrategyStatus.data.map((version: any) => {
                      return { id: version.id, name: version.name };
                    })
                  : [],
              },
            ],
          },
        })
      );
    },
    [StrategyData, StrategyStatus, StrategyVersion, dispatch]
  );
  const openCloneModal = useCallback(
    (id: number | undefined) => {
      if (!id) return;

      const strategy = StrategyData?.data.find((strategy: any) => {
        return strategy?.id === id;
      });

      setActiveAction(StrategyDesignerActions.CLONE_STRATEGY);
      dispatch(handleActiveStrategy({ data: strategy }));
      dispatch(
        openModal({
          modalState: {
            visible: true,
            title: messages.titleClonePanel("strategy"),
            question: messages.titleSureClone(`${strategy?.name} strategy`),
            message: "",
            buttonMessage: messages.titleBtnClone,
            buttonType: ButtonTypes.WARNING,
            name: ModalNames.STRATEGY_DESIGNER,
          },
        })
      );
    },
    [StrategyData, dispatch]
  );

  const openCloneAllModal = useCallback(() => {
    if (StrategyTableSelectedList?.data.length > 1) return;
    setActiveAction(StrategyDesignerActions.CLONE_ALL_STRATEGY);
    dispatch(
      openModal({
        modalState: {
          visible: true,
          title: messages.titleClonePanel("strategy"),
          question: messages.titleSureClone("selected strategies"),
          message: "",
          buttonMessage: messages.titleBtnClone,
          buttonType: ButtonTypes.WARNING,
          name: ModalNames.STRATEGY_DESIGNER,
        },
      })
    );
  }, [StrategyTableSelectedList?.data.length, dispatch]);

  const openArchiveModal = useCallback(
    (id: number | undefined) => {
      if (!id) return;

      const strategy = StrategyData?.data.find((strategy: any) => {
        return strategy?.id === id;
      });

      setActiveAction(StrategyDesignerActions.ARCHIVE_STRATEGY);
      dispatch(handleActiveStrategy({ data: strategy }));
      dispatch(
        openModal({
          modalState: {
            visible: true,
            title: messages.titleArchivePanel("strategy"),
            question: messages.titleSureArchive(`${strategy?.name} strategy`),
            message: "",
            buttonMessage: messages.titleBtnArchive,
            buttonType: ButtonTypes.WARNING,
            name: ModalNames.STRATEGY_DESIGNER,
          },
        })
      );
    },
    [StrategyData, dispatch]
  );

  const openArchiveAllModal = useCallback(() => {
    setActiveAction(StrategyDesignerActions.ARCHIVE_ALL_STRATEGY);
    dispatch(
      openModal({
        modalState: {
          visible: true,
          title: messages.titleArchivePanel("strategy"),
          question: messages.titleSureArchive("selected strategies"),
          message: "",
          buttonMessage: messages.titleBtnArchive,
          buttonType: ButtonTypes.WARNING,
          name: ModalNames.STRATEGY_DESIGNER,
        },
      })
    );
  }, [dispatch]);

  const openDeleteModal = useCallback(
    (id: number | undefined) => {
      if (!id) return;

      const strategy = StrategyData?.data.find((strategy: any) => {
        return strategy?.id === id;
      });

      setActiveAction(StrategyDesignerActions.DELETE_STRATEGY);
      dispatch(handleActiveStrategy({ data: strategy }));
      dispatch(
        openModal({
          modalState: {
            visible: true,
            title: messages.titleDeletePanel("strategy"),
            question: messages.titleSureDelete(`${strategy?.name} strategy`),
            message: "",
            buttonMessage: messages.titleBtnDelete,
            buttonType: ButtonTypes.DELETE,
            name: ModalNames.STRATEGY_DESIGNER,
          },
        })
      );
    },
    [StrategyData, dispatch]
  );

  const openDeleteAllModal = useCallback(() => {
    setActiveAction(StrategyDesignerActions.DELETE_ALL_STRATEGY);
    dispatch(
      openModal({
        modalState: {
          visible: true,
          title: messages.titleDeletePanel("strategy"),
          question: messages.titleSureDelete("selected strategies"),
          message: "",
          buttonMessage: messages.titleBtnDelete,
          buttonType: ButtonTypes.DELETE,
          name: ModalNames.STRATEGY_DESIGNER,
        },
      })
    );
  }, [dispatch]);

  const waitForStatusChange = useCallback(
    
    (strategyId: number | undefined) => {
      if (!strategyId) return;

      const stopStatus = StrategyStatus?.data.find(
        (status: any) => status.name.toLowerCase() === "ready"
      );

      timeoutInstance = setTimeout(() => {
        request
          .get(strategy.get(strategyId))
          .then((res) => {
            const { success, response } = res;

            if (!success) {
              console.warn(messages.messageApiGetStatusFalse, res);
            }

            if (response?.status === stopStatus?.id) {
              setLoading(false);
              dispatch(resetModal());
              getAllStrategies.get({
                reduxData: StrategyData?.data.length,
                action: (data) => handleStrategyData({ data }),
                refresh: true,
              });
              return;
            }

            setLoadingMessage(messageGenerator.next().value as string);
            waitForStatusChange(strategyId);
          })
          .catch((error) => {
            alert('hey')
            console.error(messages.messageApiPostError, error);
            dispatch(
              openModal({
                modalState: {
                  visible: true,
                  title: messages.titleOops,
                  question: messages.titleWentWrong,
                  message: messages.messageApiResponseError,
                  buttonMessage: messages.titleAgain,
                  buttonType: ButtonTypes.DELETE,
                  name: ModalNames.STRATEGY_TABLE,
                },
              })
            );
          });
      }, 7e3);
    },
    [
      getAllStrategies,
      StrategyData,
      StrategyStatus,
      strategy,
      dispatch,
      messageGenerator,
    ]
  );

  const onSubmit = useCallback(
    (data) => {
      const userDetailsJSON = sessionStorage.getItem(storageKeys.userDetails);

      if (!userDetailsJSON) return;

      const userId = JSON.parse(userDetailsJSON)?.user;
      const requestData = {
        user: userId,
        strategy_data:
          activeAction === StrategyDesignerActions.ADD_STRATEGY
            ? initialStrategy
            : ActiveStrategy.data?.strategy_data,
        ...data,
      };
      const startStatus = 1;
      setLoading(true);

      switch (activeAction) {
        case StrategyDesignerActions.ADD_STRATEGY: {
          request
            .post(strategy.post, requestData)
            .then((res) => {
              const { success, response } = res;

              if (!success) {
                console.warn(messages.messageApiPostStatusFalse);
                return;
              }

              if (response?.status === startStatus) {
                waitForStatusChange(response?.id);
                return;
              }

              dispatch(resetModal());
              getAllStrategies.get({
                reduxData: StrategyData?.data.length,
                action: (data) => handleStrategyData({ data }),
                refresh: true,
              });
              setLoading(false);
            })
            .catch((error) => {
              console.error(messages.messageApiPostError, error);
              dispatch(
                openModal({
                  modalState: {
                    visible: true,
                    title: messages.titleOops,
                    question: messages.titleWentWrong,
                    message: messages.messageApiResponseError,
                    buttonMessage: messages.titleAgain,
                    buttonType: ButtonTypes.DELETE,
                    name: ModalNames.STRATEGY_TABLE,
                  },
                })
              );
            });
          break;
        }
        case StrategyDesignerActions.EDIT_STRATEGY: {
          request
            .patch(strategy.patch(ActiveStrategy.data?.id), requestData)
            .then((res) => {
              const { success } = res;

              if (!success) {
                console.warn(messages.messageApiPostStatusFalse);
                return;
              }

              setLoading(false);
              dispatch(resetModal());
              getAllStrategies.get({
                reduxData: StrategyData?.data.length,
                action: (data) => handleStrategyData({ data }),
                refresh: true,
              });
            })
            .catch((error) => {
              console.error(messages.messageApiPostError, error);
              dispatch(
                openModal({
                  modalState: {
                    visible: true,
                    title: messages.titleOops,
                    question: messages.titleWentWrong,
                    message: messages.messageApiResponseError,
                    buttonMessage: messages.titleAgain,
                    buttonType: ButtonTypes.DELETE,
                    name: ModalNames.STRATEGY_TABLE,
                  },
                })
              );
            });
        }
      }
    },
    [
      waitForStatusChange,
      StrategyData,
      strategy,
      dispatch,
      activeAction,
      ActiveStrategy,
      getAllStrategies,
    ]
  );

  const onAction = useCallback(
    (action: boolean) => {
      if (!action) {
        dispatch(resetModal());
        return;
      }

      setLoading(true);
      const startStatus = 2;

      switch (activeAction) {
        case StrategyDesignerActions.CLONE_STRATEGY: {
          request
            .post(strategy.clone(ActiveStrategy?.data?.id), {})
            .then((res) => {
              const { success, response } = res;
              if (!success) {
                console.warn(messages.messageApiPostStatusFalse);
                return;
              }
              if (response?.status === startStatus) {
                waitForStatusChange(response?.id);
                return;
              }
              setLoading(false);
              dispatch(resetModal());
              getAllStrategies.get({
                reduxData: StrategyData?.data.length,
                action: (data) => handleStrategyData({ data }),
                refresh: true,
              });
            })
            .catch((error) => {
              console.error(messages.messageApiCloneError, error);
              dispatch(
                openModal({
                  modalState: {
                    visible: true,
                    title: messages.titleOops,
                    question: messages.titleWentWrong,
                    message: messages.messageApiResponseError,
                    buttonMessage: messages.titleAgain,
                    buttonType: ButtonTypes.DELETE,
                    name: ModalNames.STRATEGY_TABLE,
                  },
                })
              );
            });
          break;
        }
        case StrategyDesignerActions.CLONE_ALL_STRATEGY: {
          request
            .post(strategy.clone(StrategyTableSelectedList?.data[0].id), {})
            .then((res) => {
              const { success, response } = res;
              if (!success) {
                console.warn(messages.messageApiPostStatusFalse);
                return;
              }
              if (response?.status === startStatus) {
                waitForStatusChange(response?.id);
                return;
              }
              setLoading(false);
              dispatch(resetModal());
              getAllStrategies.get({
                reduxData: StrategyData?.data.length,
                action: (data) => handleStrategyData({ data }),
                refresh: true,
              });
            })
            .catch((error) => {
              console.error(messages.messageApiCloneError, error);
              dispatch(
                openModal({
                  modalState: {
                    visible: true,
                    title: messages.titleOops,
                    question: messages.titleWentWrong,
                    message: messages.messageApiResponseError,
                    buttonMessage: messages.titleAgain,
                    buttonType: ButtonTypes.DELETE,
                    name: ModalNames.STRATEGY_TABLE,
                  },
                })
              );
            });
          break;
        }

        case StrategyDesignerActions.ARCHIVE_STRATEGY: {
          request
            .delete(strategy.archive(ActiveStrategy?.data?.id))
            .then(() => {
              setLoading(false);
              dispatch(resetModal());
              getAllStrategies.get({
                reduxData: StrategyData?.data.length,
                action: (data) => handleStrategyData({ data }),
                refresh: true,
              });
            })
            .catch((error) => {
              console.error(messages.messageApiDeleteError, error);
              dispatch(
                openModal({
                  modalState: {
                    visible: true,
                    title: messages.titleOops,
                    question: messages.titleWentWrong,
                    message: messages.messageApiResponseError,
                    buttonMessage: messages.titleAgain,
                    buttonType: ButtonTypes.DELETE,
                    name: ModalNames.STRATEGY_TABLE,
                  },
                })
              );
            });
          break;
        }
        case StrategyDesignerActions.ARCHIVE_ALL_STRATEGY: {
          const recursiveArchive = (id: number, index: number) =>
            new Promise<void>((resolve) => {
              if (index === StrategyTableSelectedList?.data.length) {
                return resolve();
              }

              request
                .delete(strategy.archive(id))
                .then(() => {
                  index++;
                  recursiveArchive(
                    StrategyTableSelectedList?.data[index]?.id,
                    index
                  ).then(resolve);
                })
                .catch((error) => {
                  console.error(messages.messageApiDeleteError, error);
                  dispatch(resetModal());
                  dispatch(
                    openModal({
                      modalState: {
                        visible: true,
                        title: messages.titleOops,
                        question: messages.titleWentWrong,
                        message: messages.messageApiResponseError,
                        buttonMessage: messages.titleAgain,
                        buttonType: ButtonTypes.DELETE,
                        name: ModalNames.STRATEGY_TABLE,
                      },
                    })
                  );
                });
            });

          recursiveArchive(StrategyTableSelectedList?.data[0].id, 0).then(
            () => {
              setLoading(false);
              dispatch(resetModal());
              dispatch(removeAllStrategyTableSelectedList());
              getAllStrategies.get({
                reduxData: StrategyData?.data.length,
                action: (data) => handleStrategyData({ data }),
                refresh: true,
              });
            }
          );
          break;
        }
        case StrategyDesignerActions.DELETE_STRATEGY: {
          request
            .delete(strategy.delete(ActiveStrategy?.data?.id))
            .then(() => {
              setLoading(false);
              dispatch(resetModal());
              getAllStrategies.get({
                reduxData: StrategyData?.data.length,
                action: (data) => handleStrategyData({ data }),
                refresh: true,
              });
            })
            .catch((error) => {
              console.error(messages.messageApiDeleteError, error);
              dispatch(
                openModal({
                  modalState: {
                    visible: true,
                    title: messages.titleOops,
                    question: messages.titleWentWrong,
                    message: messages.messageApiResponseError,
                    buttonMessage: messages.titleAgain,
                    buttonType: ButtonTypes.DELETE,
                    name: ModalNames.STRATEGY_TABLE,
                  },
                })
              );
            });
          break;
        }
        case StrategyDesignerActions.DELETE_ALL_STRATEGY: {
          const recursiveDelete = (id: number, index: number) =>
            new Promise<void>((resolve) => {
              if (index === StrategyTableSelectedList?.data.length) {
                return resolve();
              }

              request
                .delete(strategy.delete(id))
                .then(() => {
                  index++;
                  recursiveDelete(
                    StrategyTableSelectedList?.data[index]?.id,
                    index
                  ).then(resolve);
                })
                .catch((error) => {
                  console.error(messages.messageApiDeleteError, error);
                  dispatch(resetModal());
                  dispatch(
                    openModal({
                      modalState: {
                        visible: true,
                        title: messages.titleOops,
                        question: messages.titleWentWrong,
                        message: messages.messageApiResponseError,
                        buttonMessage: messages.titleAgain,
                        buttonType: ButtonTypes.DELETE,
                        name: ModalNames.STRATEGY_TABLE,
                      },
                    })
                  );
                });
            });

          recursiveDelete(StrategyTableSelectedList?.data[0].id, 0).then(() => {
            setLoading(false);
            dispatch(resetModal());
            dispatch(removeAllStrategyTableSelectedList());
            getAllStrategies.get({
              reduxData: StrategyData?.data.length,
              action: (data) => handleStrategyData({ data }),
              refresh: true,
            });
          });
        }
      }
    },
    [
      ActiveStrategy?.data?.id,
      activeAction,
      dispatch,
      strategy,
      StrategyData,
      waitForStatusChange,
      StrategyTableSelectedList?.data,
      getAllStrategies,
    ]
  );

  const onCancel = useCallback(() => {
    dispatch(resetModal());
  }, [dispatch]);

  const handleTableCheckAction = useCallback(
    (event: boolean, id: number) => {
      if (!event) {
        dispatch(removeStrategyTableSelectedList({ id }));
        return;
      }

      dispatch(addStrategyTableSelectedList({ id }));
    },
    [dispatch]
  );

  const onHeaderCancel = useCallback(() => {
    dispatch(removeAllStrategyTableSelectedList());
  }, [dispatch]);

  const onHeaderSelectAll = useCallback(() => {
    const selectedList = StrategyData.data.map(({ id }) => {
      return { id };
    });

    dispatch(addAllStrategyTableSelectedList({ data: selectedList }));
  }, [StrategyData, dispatch]);

  useLayoutEffect(() => {
    if (!firstRender) return;
    firstRender = false;

    getAllStrategies.get({
      reduxData: StrategyData?.data.length,
      action: (data) => handleStrategyData({ data }),
      refresh: true,
    });

    getAllStrategyStatuses.get({
      reduxData: StrategyStatus?.data.length,
      action: (data) => handleStrategyStatus({ data }),
    });

    getAllStrategyVersions.get({
      reduxData: StrategyVersion?.data.length,
      action: (data) => handleStrategyVersion({ data }),
    });
  }, [
    StrategyData,
    StrategyStatus,
    StrategyVersion,
    getAllStrategies,
    getAllStrategyStatuses,
    getAllStrategyVersions,
  ]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutInstance);
    };
  }, []);

  const copyAuthAndActiveStrategyToLocalStorage = (
    strategyName: string,
    strategyId: string,
    strategyStatus: string,
    strategyVersion: string,
    strategyDesc: string
  ) => {
    const sessionCredentials: any = sessionStorage.getItem(
      storageKeys.credentials
    );
    const sessionUserDetails: any = sessionStorage.getItem(
      storageKeys.userDetails
    );
    const activeStrategy = JSON.stringify({
      name: strategyName,
      id: strategyId,
      status: strategyStatus,
      version: strategyVersion,
      description: strategyDesc,
    });

    localStorage.setItem(storageKeys.credentials, sessionCredentials);
    localStorage.setItem(storageKeys.userDetails, sessionUserDetails);
    localStorage.setItem(storageKeys.activeStrategy, activeStrategy);
    localStorage.removeItem(storageKeys.boardElements);
  };

  const columns = [
    {
    key: "name",
    title: "name",
    width: "20%",
    link: {
    target: "child",
    to: (data: any) =>
    `/strategy-designer/strategy?name=${data.name}&id=${data.id}&status=${data.status}&version=${data.version}`,
    onClick: ({ name, id, status, version, description }: any) =>
    copyAuthAndActiveStrategyToLocalStorage(
    name,
    id,
    status,
    version,
    description
    ),
    },
    },
    {
    key: "status",
    title: "status",
    width: "15%",
    data: StrategyStatus?.data,
    },
    {
    key: "description",
    title: "description",
    width: "30%",
    },
    {
    key: "update_time",
    title: "created at",
    width: "20%",
    isTime: true,
    },
    ];

  const actionsMenu = [
    {
      title: "Edit",
      action: openEditModal,
    },
    {
      title: "Clone",
      action: openCloneModal,
    },
    {
      title: "Archive",
      action: openArchiveModal,
    },
    {
      title: "Delete",
      action: openDeleteModal,
    },
  ];

  return (
    <div className={classes.StrategyDesigner}>
      <div className={classes.Main}>
        <Header
          title="Strategy Designer"
          addBtnTitle="Add Strategy"
          onAdd={openAddModal}
        />
        <Table
          columns={columns}
          data={StrategyData?.data}
          actionsMenu={actionsMenu}
          checkAction={handleTableCheckAction}
          selectedIdList={StrategyTableSelectedList?.data}
          onClone={openCloneAllModal}
          onArchive={openArchiveAllModal}
          onDelete={openDeleteAllModal}
          onCancel={onHeaderCancel}
          onSelectAll={onHeaderSelectAll}
          selectedListLength={StrategyTableSelectedList?.data.length}
          loading={tableDataLoading}
        />
      </div>
      <Modal
        name={ModalNames.STRATEGY_DESIGNER}
        title={modalState?.title}
        buttonMessage={modalState?.buttonMessage}
        buttonType={modalState?.buttonType}
        question={modalState?.question}
        message={modalState?.message}
        onAction={onAction}
        visible={modalState?.visible}
        buttonLoading={loading}
      />
      <ModalForm
        name={ModalNames.STRATEGY_DESIGNER_FORM}
        title={modalState?.title}
        buttonMessage={modalState?.buttonMessage}
        buttonType={modalState?.buttonType}
        elements={modalState?.fields}
        onCancel={onCancel}
        onSubmit={onSubmit}
        visible={modalState?.visible}
        buttonLoading={loading}
        buttonLoadingMessage={loadingMessage}
      />
    </div>
  );
};
export default StrategyDesigner;
