
import { decision_box } from "../decision_side";
import { Massive } from "../types";

type FirstSideType = {
    top: boolean,
    right: boolean,
    bottom: boolean,
    left: boolean,
    [key: string]: boolean
}
type LastSideType = {
    top: boolean,
    right: boolean,
    bottom: boolean,
    left: boolean,
    [key: string]: boolean

}
type SideType = {
    first: FirstSideType,
    last: LastSideType
}
let sideObj: SideType = {
    first: {
        top: true,
        right: true,
        bottom: true,
        left: true
    },
    last: {
        top: true,
        right: true,
        bottom: true,
        left: true
    }
}
export const positions = (first: Massive, last: Massive) => {

 

    const {
        first: {
            inputs: first_inputs,
            // outputs: first_outputs
        },
        last: {
            // inputs: last_inputs,
            outputs: last_outputs
        }
    } = decision_box(first.element, last.element);

   
    if (first_inputs?.length > 0) {
        let mass = [...Object.keys(sideObj.first)] as string[];
        for(const i of first_inputs){
            if(i){
                sideObj.first[i] = false;
            
            let index = mass.indexOf(i) as number;
            delete mass[index];
            }
        }
        for(const j of mass){
            if(j){
                sideObj.first[j] = true;
            }

        }

    } else {                        
        for(const i of Object.keys(sideObj.first)){
            if(i){
                sideObj.first[i] = true;
            }
        }
    }


    if (last_outputs?.length > 0) {
        let mass = [...Object.keys(sideObj.last)] as string[];

        for(const i of last_outputs){
           if(i){
            sideObj.last[i] = false;
            let index = mass.indexOf(i) as number;
            delete mass[index];
           }
        }
        for(const j of mass){
            
            if(j){
                sideObj.last[j] = true;
            }


        }

    } else {                        
        for(const i of Object.keys(sideObj.last)){
            if(i){
                sideObj.last[i] = true;
            }
        }
    }

    return sideObj;


}
