type Coord = {
    x: number,
    y: number
}
type ElementProperty = {
    s_x: number,
    s_y: number,
    s_width: number,
    s_height: number,
    t_x: number,
    t_y: number,
    t_width: number,
    t_height: number
}
type Size = {
    width: string,
    height: string
}
export const draw_bottom_left_first = (arrow: SVGPathElement, coord: Coord, element_property: ElementProperty, size: Size, drawn_split: Array<string>) => {
    const svg = document.querySelector(`#svg_board`) as SVGElement;
    const { s_x, s_y, s_height,s_width, t_x, t_y, t_width, t_height } = element_property;
    const { height } = size;
    const contact = arrow?.dataset.contact;

    let m = `M${s_x+s_width/2} ${s_y + s_height + 12}`;

    let horizontal: number = (+s_x+s_width/2) - (t_x + +t_width + 21);

    let v = (coord.y + +height +12) - (t_y + t_height / 2);

    let vertical = coord.y < t_y ? `v${Math.abs(v)}` : `v-${Math.abs(v)}`;


    drawn_split[0] = m;
    drawn_split[1] = vertical
    drawn_split[2] = `h-${Math.abs(horizontal)}`;


    drawn_split.join(',');

    const text = svg.querySelector(`text[id = "${contact}"]`) as SVGTextElement;
    if (v <= 0) {
        let _v = Math.abs(v);
        if (_v < 40) {
            text?.setAttributeNS(null, 'x', `${(s_x - 12) - Math.abs(horizontal / 2) - 20}`)
            text?.setAttributeNS(null, 'y', `${s_y + s_height / 2 - 10}`);
            text?.setAttributeNS(null, 'transform', `rotate(0 0 0)`)

        } else {
            text?.setAttributeNS(null, 'x', `${(s_x - 12) - Math.abs(horizontal / 2) - 72}`)
            text?.setAttributeNS(null, 'y', `${s_y + s_height / 2 + Math.abs(v) / 2 - 10}`);
            text?.setAttributeNS(null, 'transform', `rotate(90 ${(s_x - 12) - Math.abs(horizontal / 2) - 72 + 25} ${s_y + s_height / 2 + Math.abs(v) / 2 - 10 + 25})`)

        }
    } else {
        let _v = Math.abs(v);
        if (_v < 40) {
            text?.setAttributeNS(null, 'x', `${(s_x - 12) - Math.abs(horizontal / 2) - 20}`)
            text?.setAttributeNS(null, 'y', `${s_y + s_height / 2 + 20}`);
            text?.setAttributeNS(null, 'transform', `rotate(0 0 0)`)

        } else {
            text?.setAttributeNS(null, 'x', `${(s_x - 12) - Math.abs(horizontal / 2) - 72}`)
            text?.setAttributeNS(null, 'y', `${s_y + s_height / 2 - Math.abs(v) / 2 - 15}`);
            text?.setAttributeNS(null, 'transform', `rotate(90 ${(s_x - 12) - Math.abs(horizontal / 2) - 72 + 25} ${s_y + s_height / 2 - Math.abs(v) / 2 - 15 + 25})`)

        }
    }


    arrow.setAttributeNS(null, 'd', `${drawn_split}`);

 
}
export const draw_bottom_left_last = (arrow: SVGPathElement, coord: Coord, element_property: ElementProperty, size: Size, drawn_split: Array<string>) => {
    const svg = document.querySelector(`#svg_board`) as SVGElement;
    const { s_x, s_y, s_width,s_height, t_x, t_y, t_width, t_height } = element_property;
    const contact = arrow?.dataset.contact;

    let horizontal: number = ((t_x + t_width + 21) - (s_x + s_width/2));

    let m = `M${+s_x +s_width/2} ${+s_y + s_height+12}`;

    let v = (t_y + t_height / 2) - (s_y + s_height +12);

    let vertical = coord.y < s_y ? `v-${Math.abs(v)}` : `v${Math.abs(v)}`;


    drawn_split[0] = m;
    drawn_split[1] =vertical
    drawn_split[2] =  `h-${Math.abs(horizontal)}`;


    drawn_split.join(',');

    const text = svg.querySelector(`text[id = "${contact}"]`) as SVGTextElement;
    if (v <= 0) {
        let _v = Math.abs(v);
        if (_v < 40) {
            text?.setAttributeNS(null, 'x', `${(s_x - 12) - Math.abs(horizontal / 2) - 20}`)
            text?.setAttributeNS(null, 'y', `${s_y + s_height / 2 + 20}`);
            text?.setAttributeNS(null, 'transform', `rotate(0 0 0)`)

        } else {
            text?.setAttributeNS(null, 'x', `${(s_x - 12) - Math.abs(horizontal / 2) - 72}`)
            text?.setAttributeNS(null, 'y', `${s_y + s_height / 2 - _v / 2 - 10}`);
            text?.setAttributeNS(null, 'transform', `rotate(90 ${(s_x - 12) - Math.abs(horizontal / 2) - 72 + 25} ${s_y + s_height / 2 - _v / 2 - 10 + 25})`)

        }
    } else {
        let _v = Math.abs(v);
        if (_v < 40) {
            text?.setAttributeNS(null, 'x', `${(s_x - 12) - Math.abs(horizontal / 2) - 20}`)
            text?.setAttributeNS(null, 'y', `${s_y + s_height / 2 - 10}`);
            text?.setAttributeNS(null, 'transform', `rotate(0 0 0)`)

        } else {
            text?.setAttributeNS(null, 'x', `${s_x - 12 - Math.abs(horizontal / 2) - 72}`)
            text?.setAttributeNS(null, 'y', `${s_y + s_height / 2 + _v / 2 - 15}`);
            text?.setAttributeNS(null, 'transform', `rotate(90 ${s_x - 12 - Math.abs(horizontal / 2) - 72 + 25} ${s_y + s_height / 2 + _v / 2 - 15 + 25})`)

        }
    }
    arrow.setAttributeNS(null, 'd', `${drawn_split}`);


    
}