import React, { FC, useCallback, useEffect, useState } from "react";
import classes from "./inputUpload.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../store/combineReducer";
import { icons } from "../../../../../../settings/settings";
import { parseString } from "xml2js";
import { endpoints } from "../../../../../../api/endpoints";
import {
  handleSourceDataAvailability,
  handleStrategyBoardSidePanelContent,
  handleStrategyBoardSidePanelInputStage,
  handleStrategyBoardSidePanelInputUploadFile,
  handleStrategyBoardSidePanelInputUploadProcessFiles,
  handleStrategyBoardSidePanelInputUploadProcessStatus,
  handleStrategyValidateStatus,
  removeStrategyBoardSidePanelInputQueueFile,
  resetStrategyBoardSidePanelInputUpload,
} from "../../../../../../store/actions/actionsStrategy";
import { request } from "../../../../../../helpers/request";

const InputUpload: FC = () => {
  const {
    reducerStrategy: {
      StrategyBoardSidePanel: {
        Input: {
          input_stage: { stage_value },
          input_uploadFiles: { uploadFiles_acceptable, uploadFiles_ignored },
          input_uploadProcess: { uploadFiles_process },
        },
      },
      SourceDataAvailability,
      SourceDataTypes,
    },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const {
    strategy_designer: { strategy_source_data },
  } = endpoints;
  const [strategySourceDataTypes, setStrategySourceDataTypes] = useState<
    Array<object>
  >([]);

  const checkQueueListAvailability = useCallback(
    (
      acceptableLength: number | undefined,
      ignoredLength: number | undefined
    ) => {
      if (acceptableLength === 0 && !ignoredLength) {
        dispatch(
          handleStrategyBoardSidePanelInputStage({ stage_value: "upload" })
        );
      }

      if (!acceptableLength && ignoredLength === 0) {
        dispatch(
          handleStrategyBoardSidePanelInputStage({ stage_value: "upload" })
        );
      }

      if (acceptableLength === 0 && ignoredLength === 0) {
        dispatch(
          handleStrategyBoardSidePanelInputStage({ stage_value: "upload" })
        );
      }
    },
    [dispatch]
  );

  useEffect(() => {
    checkQueueListAvailability(
      uploadFiles_acceptable?.length,
      uploadFiles_ignored?.length
    );
  }, [uploadFiles_acceptable, uploadFiles_ignored, checkQueueListAvailability]);

  useEffect(() => {
    const { data } = SourceDataTypes;
    if (data !== null) {
      setStrategySourceDataTypes(data);
    }
  }, [SourceDataTypes]);

  const handleDragOver = (event: any) => {
    event.preventDefault();
    const dropArea = event.currentTarget as HTMLDivElement;
    const uploadLabel = document.getElementById(
      "input_drag_and_drop_label"
    ) as HTMLLabelElement;

    if (dropArea)
      dropArea.style.border = "0.14vh dashed rgba(44, 124, 246, 0.6)";
    if (uploadLabel) uploadLabel.style.pointerEvents = "none";
  };

  const handleDragLeave = (event: any) => {
    const dropArea = event.currentTarget as HTMLDivElement;
    const uploadLabel = document.getElementById(
      "input_drag_and_drop_label"
    ) as HTMLLabelElement;

    if (dropArea) dropArea.style.border = "0.14vh dashed #3e4c58";
    if (uploadLabel) uploadLabel.style.pointerEvents = "all";
  };

  const handleDragEnd = (event: any) => {
    const dropArea = event.currentTarget as HTMLDivElement;
    const uploadLabel = document.getElementById(
      "input_drag_and_drop_label"
    ) as HTMLLabelElement;

    if (dropArea) dropArea.style.border = "0.14vh dashed #3e4c58";
    if (uploadLabel) uploadLabel.style.pointerEvents = "all";
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    const files = event.dataTransfer.files;

    checkFileTypeAndAddQueue(files);
  };

  const handleFileChange = (event: any) => {
    const files = event?.target?.files;

    checkFileTypeAndAddQueue(files);
  };

  const convertHandler = (
    fileContent: string | ArrayBuffer | null,
    type: string
  ) => {
    const typeActions: any = {
      "application/json": (content: string) =>
        new Promise((resolve) => resolve({ data: content, type: "JSON" })),
      "text/xml": (content: string) =>
        new Promise((resolve, reject) => {
          parseString(content, { explicitArray: false }, (error, result) => {
            if (error) return reject(error);

            resolve({ data: JSON.stringify(result), type: "JSON" });
          });
        }),
    };

    return typeActions[type](fileContent);
  };

  const readFileAsString = (file: File) =>
    new Promise((res, rej) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        res(convertHandler(reader.result, file.type));
      };
      reader.onerror = rej;
    });

  const checkFileTypeAndAddQueue = (fileList: FileList) => {
    const acceptableFileTypes = ["text/xml", "application/json"];
    let loopIndex: number = 0;
    let acceptableFile: boolean;
    const acceptableFileList: Array<any> = [];
    const acceptableFileNameList: Array<string> = [];
    const ignoredFileNameList: Array<string> = [];

    if (fileList.length) {
      for (loopIndex; loopIndex < fileList.length; loopIndex++) {
        acceptableFile = acceptableFileTypes.includes(fileList[loopIndex].type);

        if (acceptableFile) {
          acceptableFileNameList.push(fileList[loopIndex].name);
          readFileAsString(fileList[loopIndex])
            .then((result) => {
              acceptableFileList.push(result);
              if (acceptableFileList.length) {
                sessionStorage.setItem(
                  "upload_queue",
                  JSON.stringify(acceptableFileList)
                );
              }
            })
            .catch((error) => console.error("File reading error :", error));
        }

        if (!acceptableFile) ignoredFileNameList.push(fileList[loopIndex].name);
      }

      dispatch(
        handleStrategyBoardSidePanelInputUploadFile({
          uploadFiles_acceptable: acceptableFileNameList,
          uploadFiles_ignored: ignoredFileNameList,
        })
      );
      dispatch(
        handleStrategyBoardSidePanelInputStage({ stage_value: "upload_queue" })
      );
    }
  };

  const removeFileFromQueue = (acceptStatus: string, fileName: string) => {
    dispatch(
      removeStrategyBoardSidePanelInputQueueFile({
        accept_status: acceptStatus,
        file_name: fileName,
      })
    );
  };

  const convertQueueToArray = (queueString: string | null) => {
    let convertedQueueArray: Array<any> = [];
    let stringToArray: Array<string> = [];
    if (queueString !== null) {
      stringToArray = JSON.parse(queueString);

      stringToArray.forEach((element: any) => {
        convertedQueueArray.push(element);
      });
    }
    return convertedQueueArray;
  };

  const getSourceDataTypeId = (type: string) => {
    let typeId;

    strategySourceDataTypes.forEach((types: any) => {
      if (type === types?.name) typeId = types?.id;
    });

    return typeId;
  };

  const createAndGetStrategyModelsArray = (fileData: Array<any>) => {
    const strategyDetailsJSON = sessionStorage.getItem("active_strategy");
    let strategyId: any = null;
    if (strategyDetailsJSON) {
      const { id } = JSON.parse(strategyDetailsJSON);
      strategyId = id;
    }

    let strategySourceDataModelsArray: Array<any> = [];

    fileData?.forEach((element: any) => {
      let strategySourceDataModel = {
        strategy: strategyId,
        source_data: JSON.parse(element?.data),
        strategy_source_data_type: getSourceDataTypeId(element?.type),
      };

      strategySourceDataModelsArray.push(strategySourceDataModel);
    });

    return strategySourceDataModelsArray;
  };

  const strategyModelUploader = (array: Array<any>, file_index: number = 0) => {
    return new Promise(async (resolve, reject) => {
      if (!array.length) return resolve(false);
      const {status} =  SourceDataAvailability
      const sourceDataJSON = sessionStorage.getItem("active_strategy_source_data")
      let res: any
      
      if(status && sourceDataJSON){
        const {id} = JSON.parse(sourceDataJSON)
        res = await request.patch(
          strategy_source_data.patch(id),
          array.shift()
        )
      }else {
        res = await request.post(
          strategy_source_data.post,
          array.shift()
        );

        dispatch(handleSourceDataAvailability({status:true}))
      }
      
      const {
        success,
        response: { id, source_data, strategy_source_data_type },
      } = res;

      if (!success) return reject(res);

      dispatch(
        handleStrategyBoardSidePanelInputUploadProcessStatus({
          upload_status: "uploaded",
          file_index,
        })
      );
      sessionStorage.setItem(
        "active_strategy_source_data",
        JSON.stringify({ id, type: strategy_source_data_type,data: source_data })
      );
      sessionStorage.removeItem("upload_queue");
      await strategyModelUploader(array, ++file_index);
      return resolve(true);
    });
  };

  const handleUpload = () => {
    let uploadingFileNames: Array<object> = [];
    const sessionQueue = sessionStorage.getItem("upload_queue");
    const uploadingFileData = convertQueueToArray(sessionQueue);
    const uplaodingFileStrategyModels = createAndGetStrategyModelsArray(
      uploadingFileData
    );

    uploadFiles_acceptable?.forEach((fileNames: String) => {
      uploadingFileNames.push({ name: fileNames, status: "waiting" });
    });

    dispatch(
      handleStrategyBoardSidePanelInputUploadProcessFiles({
        uploadFiles_process: uploadingFileNames,
      })
    );
    dispatch(
      handleStrategyBoardSidePanelInputStage({ stage_value: "upload_process" })
    );


    strategyModelUploader(uplaodingFileStrategyModels)
      .then((response) => {
        if (response){
          dispatch(
            handleStrategyBoardSidePanelInputStage({
              stage_value: "upload_complete",
            })
          );
          dispatch(handleStrategyValidateStatus({status:true}));

        }
      })
      .catch((error) =>
        console.error("Strategy Source Data Upload Error :", error)
      );
  };

  const closeSidePanelInput = () => {
    dispatch(handleStrategyBoardSidePanelContent({ content_value: null }));
    dispatch(handleStrategyBoardSidePanelInputStage({ stage_value: null }));
    dispatch(resetStrategyBoardSidePanelInputUpload());
  };

  const backToInputStage = () => {
    dispatch(handleStrategyBoardSidePanelInputStage({ stage_value: null }));
    dispatch(resetStrategyBoardSidePanelInputUpload());
  };

  return (
    <>
      {stage_value === "upload" && (
        <>
          <div
            className={classes.InputUploadDropArea}
            onDragOver={(event) => handleDragOver(event)}
            onDragLeave={(event) => handleDragLeave(event)}
            onDragEnd={(event) => handleDragEnd(event)}
            onDrop={(event) => handleDrop(event)}
          >
            <img src={icons.sidePanelInputUploadDragDrop} alt="Upload icon" />
            <span>
              <label
                id="input_drag_and_drop_label"
                htmlFor="input_drag_and_drop"
              >
                Choose a file
              </label>{" "}
              or drag here
            </span>
            <small>
              Only <span>xml, csv, json</span> file type
            </small>
            <input
              type="file"
              id="input_drag_and_drop"
              onChange={(event) => handleFileChange(event)}
            />
          </div>
          <button className={classes.InputUploadButton}>Upload</button>
        </>
      )}

      {stage_value === "upload_queue" && (
        <>
          <div className={classes.InputUploadQueueArea}>
            {uploadFiles_acceptable?.map(
              (acceptableFileName: string, index: number) => {
                return (
                  <div className={classes.InputUploadQueueElement} key={index}>
                    <span className={classes.InputUploadQueueElementName}>
                      {acceptableFileName}
                    </span>
                    <img
                      className={classes.InputUploadQueueElementRejectIcon}
                      src={icons.closeBtn}
                      alt="Close Icon"
                      onClick={() =>
                        removeFileFromQueue("accepted", acceptableFileName)
                      }
                    />
                  </div>
                );
              }
            )}

            {uploadFiles_ignored?.map(
              (ignoredFileName: string, index: number) => {
                return (
                  <div className={classes.InputUploadQueueElement} key={index}>
                    <img
                      className={classes.InputUploadQueueElementStatusIcon}
                      src={icons.danger}
                      alt="Danger Icon"
                      title="File format not acceptable"
                    />
                    <span className={classes.InputUploadQueueElementName}>
                      {ignoredFileName}
                    </span>
                    <img
                      className={classes.InputUploadQueueElementRejectIcon}
                      src={icons.closeBtn}
                      alt="Close Icon"
                      onClick={() =>
                        removeFileFromQueue("ignored", ignoredFileName)
                      }
                    />
                  </div>
                );
              }
            )}
          </div>
          <button
            className={classes.InputUploadButton}
            onClick={() => handleUpload()}
          >
            Upload
          </button>
        </>
      )}

      {stage_value === "upload_process" && (
        <div className={classes.InputUploadProcessArea}>
          {uploadFiles_process?.map((fileObject: any, index: number) => {
            const { name, status } = fileObject;

            return (
              <div className={classes.InputUploadProcessElement} key={index}>
                <img
                  className={
                    status === "waiting" || status === "uploading"
                      ? [
                          classes.InputUploadProcessElementStatusIcon,
                          classes.InputUploadProcessElementStatusIconAnimate,
                        ].join(" ")
                      : classes.InputUploadProcessElementStatusIcon
                  }
                  src={
                    status === "waiting" || status === "uploading"
                      ? icons.uploading
                      : status === "uploaded"
                      ? icons.applyGreen
                      : icons.danger
                  }
                  alt="Process Icon"
                />
                <div className={classes.InputUploadProcessElementInfo}>
                  <span className={classes.InputUploadProcessElementName}>
                    {name}
                  </span>
                  <span
                    className={classes.InputUploadProcessElementProgressInfo}
                  >
                    {status === "uploaded"
                      ? "100% • 0 seconds left"
                      : "0% • 1 seconds left"}
                  </span>
                </div>
                <span
                  className={classes.InputUploadProcessElementProgressBar}
                  style={
                    status === "uploaded" ? { width: "100%" } : { width: "0%" }
                  }
                ></span>
              </div>
            );
          })}
        </div>
      )}

      {stage_value === "upload_complete" && (
        <>
          <div className={classes.InputUploadCompleteAreaHeader}>
            <button
              className={classes.InputUploadCompleteAreaHeaderButton}
              onClick={() => backToInputStage()}
            >
              <img src={icons.inputBackButton} alt="Back Arrow" /> Back
            </button>
          </div>
          <div className={classes.InputUploadCompleteArea}>
            {uploadFiles_process?.map((fileObject: any, index: number) => {
              const { name, status } = fileObject;

              return (
                <div className={classes.InputUploadCompleteElement} key={index}>
                  <img
                    className={classes.InputUploadCompleteElementStatusIcon}
                    src={
                      status === "uploaded" ? icons.applyGreen : icons.danger
                    }
                    alt="Status Icon"
                  />
                  <div className={classes.InputUploadCompleteElementInfo}>
                    <span className={classes.InputUploadCompleteElementName}>
                      {name}
                    </span>
                    <span
                      className={classes.InputUploadCompleteElementProgressInfo}
                    >
                      Done!
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
          <div className={classes.InputUploadCompleteAreaButtonGroup}>
            <button
              className={classes.CompleteAreaCloseButton}
              onClick={() => closeSidePanelInput()}
            >
              Close
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default InputUpload;
