import React, { FC, useCallback, useEffect, useState } from "react";
import classes from "./ProductManager.module.scss";
import { endpoints } from "../../../api/endpoints";
import { EditorKeySources, icons } from "../../../settings/settings";
import Portal from "../Portal/Portal";
import { request } from "../../../helpers/request";

interface IProductManager {
  setId: Function;
}

let timeoutInstance: any;

const ProductManager: FC<IProductManager> = ({ setId }) => {
  const { product_designer } = endpoints;

  const [portalShow, setPortalShow] = useState<boolean>(false);
  const [products, setProducts] = useState<Array<object>>([]);
  const [selectedProductId, setSelectedProductId] = useState<
    number | undefined
  >();
  const [productId, setProductId] = useState<number | undefined>();
  const [groups, setGroups] = useState<Array<object>>([]);

  const getGroups = useCallback(
    (productId: number) => {
      request
        .get(product_designer.groups.getAll(productId))
        .then((res) => {
          const { success, response } = res;

          if (success) {
            setGroups([...response]);
            if (response.length === 0) {
              timeoutInstance = setTimeout(() => {
                setProductId(undefined);
              }, 1000);
            }
          } else {
            console.warn("Fetching groups success is false ", res);
          }
        })
        .catch((error) => {
          console.error("Fetching groups error ", error);
        });
    },
    [product_designer]
  );

  const closePortal = useCallback(() => {
    setPortalShow(false);
  }, []);

  const generateProductRows = useCallback(
    (products: Array<object>) => {
      function handleActivation(event: any, id: number) {
        const { target } = event;

        if (target.classList.contains(classes.ActiveRow)) {
          target.classList.remove(classes.ActiveRow);
          setSelectedProductId(undefined);
        } else {
          target.classList.add(classes.ActiveRow);
          setSelectedProductId(id);
        }
      }

      function selectId(id: number) {
        setProductId(id);
        setId(String(id));
        getGroups(id);
        closePortal();
      }

      if (products?.length) {
        return products.map((product: any, index: number) => {
          const { id, name, description } = product;

          return (
            <div
              key={index}
              className={classes.PortalRow}
              onClick={(event: any) => handleActivation(event, id)}
              onDoubleClick={() => selectId(id)}
            >
              <span>{name}</span>
              <span title={description}>{description}</span>
            </div>
          );
        });
      } else {
        return <div className={classes.NoData}>no data to display</div>;
      }
    },
    [closePortal, getGroups, setId]
  );

  const generateGroupRows = useCallback((groups: Array<object>) => {
    function startDrag(event: any) {
      const { target, dataTransfer } = event;

      target.classList.add(classes.ActiveRow);
      

      dataTransfer.setData("draggingProduct",
      JSON.stringify({ source: EditorKeySources.PRODUCTS, key: target.innerText})
      );
    }

    function endDrag(event: any) {
      const { target } = event;

      target.classList.remove(classes.ActiveRow);
    }

    if (groups.length > 0) {
      return groups.map((range: any, index: number) => {
        const { name } = range;

        return (
          <div
            key={index}
            className={classes.Row}
            onDragStart={startDrag}
            onDragEnd={endDrag}
            draggable
          >
            {name}
          </div>
        );
      });
    } else {
      return <div className={classes.NoDataRow}>no data to display</div>;
    }
  }, []);

  useEffect(() => {
    return () => clearTimeout(timeoutInstance);
  }, []);

  const getProducts = () => {
    request
      .get(product_designer.products.getAll)
      .then((res) => {
        const { success, response } = res;

        if (success) {
          setProducts([...response]);
        } else {
          console.warn("Fetching products success is false ", res);
        }
      })
      .catch((error) => {
        console.error("Fetching products error ", error);
      });
  };

  const openPortal = () => {
    setPortalShow(true);
    getProducts();
  };

  const handlePortalSubmit = () => {
    if (selectedProductId) {
      setProductId(selectedProductId);
      setId(String(selectedProductId));
      getGroups(selectedProductId);
      closePortal();
    }
  };

  return (
    <div className={classes.ProductManagerWrapper}>
      <div className={classes.ViewerHeader}>
        <span className={classes.Title}>Products</span>
      </div>
      <div className={classes.ViewerBody}>
        {productId ? (
          generateGroupRows(groups)
        ) : (
          <div className={classes.Actions}>
            <button className={classes.ActionButton} onClick={openPortal}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
              >
                <g clipPath="url(#folderSVG)">
                  <path
                    fill="#FCEBA6"
                    d="M11.98 15H.405a.405.405 0 01-.39-.51l2.628-7.208a.404.404 0 01.39-.299H14.61c.266 0 .39.255.39.51l-2.63 7.209a.404.404 0 01-.39.299z"
                  />
                  <path
                    fill="#F0D97A"
                    d="M13 4.655h-.586v2.328h1.034v-1.88c0-.247-.2-.448-.448-.448zM1.034 2.845H.448c-.247 0-.448.2-.448.448v11.05c.018 0 .036.002.053.003l.981-2.677V2.845z"
                  />
                  <path
                    fill="#F4EFDC"
                    d="M2.643 7.282a.404.404 0 01.39-.3h9.381V0H2.586v7.438l.057-.156z"
                  />
                  <path
                    fill="#CEC9AE"
                    d="M4.397 4.138h6.207a.259.259 0 100-.517H4.397a.259.259 0 100 .517zM4.397 2.328h2.586a.259.259 0 100-.517H4.397a.259.259 0 100 .517zM10.604 5.432H4.397a.259.259 0 100 .517h6.207a.259.259 0 100-.517zM2.586 7.438V.517H1.81v9.048l.776-2.127z"
                  />
                  <path
                    fill="#D1BF86"
                    d="M1.81 9.565v-8.53h-.775v10.657l.775-2.127z"
                  />
                </g>
                <defs>
                  <clipPath id="folderSVG">
                    <path fill="#fff" d="M0 0h15v15H0z" />
                  </clipPath>
                </defs>
              </svg>
              <span>open</span>
            </button>
          </div>
        )}
      </div>
      {portalShow && (
        <Portal>
          <div className={classes.PortalWrapper}>
            <span className={classes.PortalTitle}>product</span>
            <div className={classes.PortalHeader}>
              <span>product name</span>
              <span>description</span>
            </div>
            <div className={classes.PortalBody}>
              {generateProductRows(products)}
            </div>
            <button
              className={classes.SubmitButton}
              onClick={handlePortalSubmit}
            >
              Submit
            </button>
            <button
              className={classes.PortalCloseButton}
              onClick={() => closePortal()}
            >
              <img src={icons.closeBtn} alt="Close icon" />
            </button>
          </div>
        </Portal>
      )}
    </div>
  );
};

export default ProductManager;
