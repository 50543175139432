import React, { FC, useCallback } from "react";
import classes from "./Modal.module.scss";
import { ButtonSizes, ButtonTypes, icons, ModalNames } from "../../../settings/settings";
import Portal from "../Portal/Portal";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/combineReducer";
import Button from "../Button/ButtonV2";

interface IModal {
  buttonType?: ButtonTypes;
  title: string;
  question: string;
  message?: string;
  buttonMessage?: string;
  onAction: (action: boolean) => void;
  visible: boolean;
  name: ModalNames;
  buttonLoading?: boolean;
}

const Modal: FC<IModal> = ({
  buttonType = ButtonTypes.PRIMARY,
  title,
  question,
  message,
  buttonMessage = "Yes",
  onAction,
  visible,
  name,
  buttonLoading = false,
}) => {
  const {
    reducerModal: { modalState },
  } = useSelector((state: RootState) => state);

  const fireAccept = useCallback(() => {
    onAction(true);
  }, [onAction]);

  const fireCancel = useCallback(() => {
    onAction(false);
  }, [onAction]);

  return (
    <>
      {visible && modalState?.name === name ? (
        <Portal>
          <div className={classes.Wrapper}>
            <p className={classes.Title}>{title}</p>
            <div className={classes.Body}>
              <p>{question}</p>
              <span>{message}</span>
            </div>
            <Button
              type={buttonLoading ? ButtonTypes.LOADING : buttonType}
              size={ButtonSizes.LARGE}
              onClick={fireAccept}
            >
              {buttonMessage}
            </Button>
            <button className={classes.CancelButton} onClick={fireCancel}>
              <img src={icons.closeBtn} alt="Close icon" />
            </button>
          </div>
        </Portal>
      ) : null}
    </>
  );
};

export default Modal;
