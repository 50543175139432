import React from 'react';
import classes from './Section.module.scss';
import { icons } from '../../../settings/settings';
import Table from '../Table/Table';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/combineReducer';
import Graph from '../Charts/Graph/Graph';
import Diagram from '../Charts/Diagram/Diagram';
import { Active } from '../../../components/UI/Svg/Svg';
import {
    handleScoreCardLevel,
    handleScoreCardLevelDataId,
    handleScoreCardRowEditableStatus,
    handleScoreCardActiveRow, handleScoreCardData,
    handleScoreCardCategoryData,
    handleScoreCardCriteriaData,
    handleScoreCardLevelTree,
    handleScoreCardAttributeData,
    handleActiveTree,
    removeScoreCardNewRow
} from '../../../store/actions/actionsScoreCard';
import { handleActiveTooltip, activeModal } from '../../../store/actions/action';

const Section: React.FC = () => {
  const [tuningStatus, setTuningStatus] = React.useState<boolean>(false);
  const [activeChart, setActiveChart] = React.useState("graph");
  const [scrollStatus, setScrollStatus] = React.useState(false);
  const [leftArrow, setLeftArrow] = React.useState(false);
  const [rightArrow, setRightArrow] = React.useState(false);
  const [title, setTitle] = React.useState<string>();
  const [activeData, setActiveData] = React.useState([]);
  const [tuningMessage, setTuningMessage] = React.useState<string>('Tuning Category');

  const dispatch = useDispatch();
  let user_details: any = sessionStorage.getItem("user_details");
  const {
    reducer: { mainTooltip, touchBlocked },
    reducerScoreCard: {
      scoreCardData: { scoreCardData },
      scoreCardCategoryData: { scoreCardCategoryData },
      scoreCardCriteriaData: { scoreCardCriteriaData },
      scoreCardAttributeData: { scoreCardAttributeData },
      scoreCardLevel: { level },
      scoreCardLevelDataId: {
        scoreCardId,
        categoryId,
        criteriaId,
        criteriaType,
      },
      scoreCardActiveRow: { rowType, rowId },
      activeTree: { activeTree },
      scoreCardActiveElement,
    },
  } = useSelector((state: RootState) => state);
  React.useEffect(() => {
    if (level === 1) {
      setTitle("Score Cards");
    }

    if (level === 2) {
      let score = scoreCardData.find(
        (element: any) => element.id === scoreCardId
      );
      setTitle(score?.name);
      setActiveData(scoreCardCategoryData);
    }

    if (level === 3) {
      let category = scoreCardCategoryData.find(
        (element: any) => element.id === categoryId
      );
      setTitle(category?.name);
      setActiveData(scoreCardCriteriaData);
    }

    if (level === 4) {
      let criteria = scoreCardCriteriaData.find(
        (element: any) => element.id === criteriaId
      );
      setTitle(criteria?.name);
      setActiveData(scoreCardAttributeData);
      setActiveChart("graph");
    }
  }, [
    level,
    scoreCardId,
    scoreCardData,
    categoryId,
    scoreCardCategoryData,
    criteriaId,
    scoreCardCriteriaData,
    scoreCardAttributeData,
  ]);

  React.useEffect(() => {
    let chartArea = document.getElementById("chartArea") as HTMLElement;
    if (chartArea?.scrollWidth > chartArea?.clientWidth) {
      setScrollStatus(true);
      setRightArrow(true);
    } else {
      setScrollStatus(false);
    }
    if (chartArea) {
      chartArea.onscroll = (e) => {
        if (chartArea.scrollLeft === 0) {
          setLeftArrow(false);
          setRightArrow(true);
        } else {
          setLeftArrow(true);
          setRightArrow(true);
        }
        if (
          chartArea?.scrollWidth -
            Math.ceil(chartArea?.scrollLeft) -
            chartArea?.offsetWidth ===
          0
        ) {
          setRightArrow(false);
        } else {
          setRightArrow(true);
        }
      };
    }
  }, [tuningStatus, activeData]);

  const tuningHandler = () => {

    if (scoreCardActiveElement.name === null && mainTooltip.type === null) {
      setTuningStatus(!tuningStatus);
      setTuningMessage(tuningMessage === 'Tuning Category' ? 'Hide' :'Tuning Category')
    }

    if (
      scoreCardActiveElement.name !== null &&
      mainTooltip.type !== "validation"
    ) {
      if (mainTooltip.type === null)
        dispatch(
          handleActiveTooltip({
            childId: scoreCardActiveElement.name,
            childDataId: null,
            type: "saveChanges",
          })
        );
      if (mainTooltip.type === "saveChanges") {
        dispatch(
          handleActiveTooltip({ childId: null, childDataId: null, type: null })
        );
        setTimeout(() => {
          dispatch(
            handleActiveTooltip({
              childId: scoreCardActiveElement.name,
              childDataId: null,
              type: "saveChanges",
            })
          );
        }, 50);
      }
    }
  };

  const activeChartHandler = (e: any, type: string) => {
    setActiveChart(type);
  };

  const backBtnHandler = (level: number) => {
    if (scoreCardActiveElement.name !== null && touchBlocked.status) {
      dispatch(activeModal({ activeModal: "score_blocked_modal" }));
    }

    if (scoreCardActiveElement.name === null && !touchBlocked.status) {
      dispatch(handleScoreCardLevel({ level: level - 1 }));
    
      let activeTreeRef = [...activeTree];
      activeTreeRef.pop();
      dispatch(handleActiveTree({ activeTree: activeTreeRef }));
      if (level === 2) {
        dispatch(
          handleScoreCardLevelDataId({      
            scoreCardId: null,
            categoryId: null,
            criteriaId: null,
            criteriaType: null,
          })
        );
        dispatch(handleScoreCardLevelTree({ level: 2 }));
      }
      if (level === 3) {
        dispatch(
          handleScoreCardLevelDataId({
            scoreCardId,
            categoryId: null,
            criteriaId: null,
            criteriaType: null,
          })
        );
        dispatch(handleScoreCardLevelTree({ level: 3 }));
      }
      if (level === 4) {
        dispatch(
          handleScoreCardLevelDataId({
            scoreCardId,
            categoryId,
            criteriaId: null,
            criteriaType: null,
          })
        );
        dispatch(handleScoreCardLevelTree({ level: 4 }));
      }
      if (rowType === "edit" && rowId !== null) {
        dispatch(handleScoreCardRowEditableStatus(level, rowId, false));
        dispatch(
          handleScoreCardActiveRow({
            rowIndex: null,
            rowType: null,
            rowId: null,
          })
        );
      }

      if (rowType === "new") {
        dispatch(removeScoreCardNewRow(level));
        dispatch(
          handleScoreCardActiveRow({
            rowIndex: null,
            rowType: null,
            rowId: null,
          })
        );
      }
    }
  };

  const newRowHandler = () => {
    if (scoreCardActiveElement.name === null && mainTooltip.type === null) {
      if (level === 1) {
        const user_id = JSON.parse(user_details).user;
        dispatch(
          handleScoreCardData({
            scoreCardData:
              scoreCardData !== null
                ? [
                  {
                    name: undefined,
                    description: undefined,
                    min_score: "0",
                    max_score: "0",
                    user_id,
                    editable: true,
                    newRow: true,
                  },
                    ...scoreCardData,
                    
                  ]
                : [
                    {
                      name: undefined,
                      description: undefined,
                      min_score: "0",
                      max_score: "0",
                      user_id,
                      editable: true,
                      newRow: true,
                    },
                  ],
          })
        );
        dispatch(
          handleScoreCardActiveRow({
            rowIndex: scoreCardData !== null ? scoreCardData.length : 0,
            rowType: "new",
            rowId: null,
          })
        );
      }

      if (level === 2) {
        dispatch(
          handleScoreCardCategoryData({
            scoreCardCategoryData:
              scoreCardCategoryData !== null
                ? [
                  {
                    score_card_id: scoreCardId,
                    name: undefined,
                    weight: "0",
                    is_trusted_weight: false,
                    point: "0",
                    editable: true,
                    newRow: true,
                  },
                    ...scoreCardCategoryData,
                    
                  ]
                : [
                    {
                      score_card_id: scoreCardId,
                      name: undefined,
                      weight: "0",
                      is_trusted_weight: false,
                      point: "0",
                      editable: true,
                      newRow: true,
                    },
                  ],
          })
        );

        const activeScoreCardCategories = scoreCardCategoryData?.filter(
          (element: any) => element.score_card_id === scoreCardId
        );
        const newElementIndex = activeScoreCardCategories?.length;

        dispatch(
          handleScoreCardActiveRow({
            rowIndex: newElementIndex,
            rowType: "new",
            rowId: null,
          })
        );
      }

      if (level === 3) {
        dispatch(
          handleScoreCardCriteriaData({
            scoreCardCriteriaData:
              scoreCardCriteriaData !== null
                ? [
                    {
                      score_card_category_id: categoryId,
                      criteria_type_id: 1,
                      name: undefined,
                      weight: "0",
                      is_trusted_weight: false,
                      point: "0",
                      editable: true,
                      newRow: true,
                    },
                    ...scoreCardCriteriaData,
                    
                  ]
                : [
                    {
                      score_card_category_id: categoryId,
                      criteria_type_id: 1,
                      name: undefined,
                      weight: "0",
                      is_trusted_weight: false,
                      point: "0",
                      editable: true,
                      newRow: true,
                    },
                  ],
          })
        );

        const activeScoreCardCriterias = scoreCardCriteriaData?.filter(
          (element: any) => element.score_card_category_id === categoryId
        );
        const newElementIndex = activeScoreCardCriterias?.length;

        dispatch(
          handleScoreCardActiveRow({
            rowIndex: newElementIndex,
            rowType: "new",
            rowId: null,
          })
        );
      }

      if (level === 4) {
        if (criteriaType === 1) {
          dispatch(
            handleScoreCardAttributeData({
              scoreCardAttributeData:
                scoreCardAttributeData !== null
                  ? [
                      {
                        label: undefined,
                        value: {
                          less_value: "0",
                          more_value: "0",
                        },
                        is_trusted_weight: false,
                        weight: "0",
                        point: "0",
                        score_card_criteria_id: criteriaId,
                        editable: true,
                        newRow: true,
                      },
                      ...scoreCardAttributeData,
                      
                    ]
                  : [
                      {
                        label: undefined,
                        value: {
                          less_value: "0",
                          more_value: "0",
                        },
                        is_trusted_weight: false,
                        weight: "0",
                        point: "0",
                        score_card_criteria_id: criteriaId,
                        editable: true,
                        newRow: true,
                      },
                    ],
            })
          );
        }

        if (criteriaType === 2) {
          dispatch(
            handleScoreCardAttributeData({
              scoreCardAttributeData:
                scoreCardAttributeData !== null
                  ? [
                    {
                      label: "",
                      value: {
                        value: "",
                      },
                      is_trusted_weight: false,
                      weight: "0",
                      point: "0",
                      score_card_criteria_id: criteriaId,
                      editable: true,
                      newRow: true,
                    },
                      ...scoreCardAttributeData,
                     
                    ]
                  : [
                      {
                        label: "",
                        value: {
                          value: "",
                        },
                        is_trusted_weight: false,
                        weight: "0",
                        point: "0",
                        score_card_criteria_id: criteriaId,
                        editable: true,
                        newRow: true,
                      },
                    ],
            })
          );
        }

        const activeScoreCardAtrinutes = scoreCardAttributeData?.filter(
          (element: any) => element.score_card_criteria_id === criteriaId
        );
        const newElementIndex = activeScoreCardAtrinutes?.length;

        dispatch(
          handleScoreCardActiveRow({
            rowIndex: newElementIndex,
            rowType: "new",
            rowId: null,
          })
        );
      }
    }

    if (
      scoreCardActiveElement.name !== null &&
      mainTooltip.type !== "validation"
    ) {
      if (mainTooltip.type === null)
        dispatch(
          handleActiveTooltip({
            childId: scoreCardActiveElement.name,
            childDataId: null,
            type: "saveChanges",
          })
        );
      if (mainTooltip.type === "saveChanges") {
        dispatch(
          handleActiveTooltip({ childId: null, childDataId: null, type: null })
        );
        setTimeout(() => {
          dispatch(
            handleActiveTooltip({
              childId: scoreCardActiveElement.name,
              childDataId: null,
              type: "saveChanges",
            })
          );
        }, 50);
      }
    }
  };

  const chart =
    activeChart === "graph" ? (
      <Graph />
    ) : activeChart === "diagram" ? (
      <Diagram />
    ) : null;
  function sideScroll(
    element: any,
    direction: string,
    speed: number,
    distance: number,
    step: number
  ) {
    let scrollAmount = 0;
    var slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }

  const leftScroll = (e: any) => {
    let container = document.getElementById("chartArea") as HTMLElement;

    sideScroll(container, "left", 25, 100, 250);
  };
  const rightScroll = (e: any) => {
    var container = document.getElementById("chartArea") as HTMLElement;
    sideScroll(container, "right", 25, 100, 250);
  };

  return (
    <div id="section" className={classes.Section}>
      <div className={classes.Header}>
        <div className={classes.Top}>
          <div className={classes.Title}>{title}</div>
          <div className={classes.SearchProduct}>
            {/* <div className={classes.Search}>
                            <button>
                                <img src={icons.search} alt='search' />
                            </button>
                        </div> */}
            {level !== 1 && (
              <>
                <div className={classes.TuningCategory}>
                  <button onClick={tuningHandler}>{tuningMessage}</button>
                </div>
              </>
            )}
            <div className={classes.AddCategory}>
              <button id={"scoreCard-addBtn"} onClick={() => newRowHandler()}>
                {level === 1 && "Add Score"}
                {level === 2 && "Add Category"}
                {level === 3 && "Add Criteria"}
                {level === 4 && "Add Attribute"}
              </button>
            </div>
          </div>
        </div>
        {level > 1 && (
          <div
            className={classes.BackBtn}
            onClick={() => backBtnHandler(level)}
          >
            <span>
              <img src={icons.backBtn} alt="back" />
              {level === 2 && " Back"}
              {level === 3 && "Back to Score"}
              {level === 4 && "Back to Category"}
            </span>
          </div>
        )}
        {level !== 1 && tuningStatus && (
          <>
            <div className={classes.TuningArea}>
              <div className={classes.ChartButtons}>
                <button onClick={(e) => activeChartHandler(e, "graph")}>
                  {activeChart === "graph" && <Active />} Show Graph
                </button>
                <span></span>
                <button
                  disabled={level !== 4 ? false : true}
                  style={{ opacity: level !== 4 ? 1 : 0.5 }}
                  onClick={(e) => activeChartHandler(e, "diagram")}
                >
                  {activeChart === "diagram" && <Active />} Show Diagram
                </button>
              </div>
              <div className={classes.ShowButton}>
                <button>Show All</button>
              </div>
            </div>
          </>
        )}
      </div>
      {level !== 1 && tuningStatus && (
        <>
          <div className={classes.ChartSection}>
            <div
              id="chartArea"
              className={
                activeChart === "graph"
                  ? [classes.ChartArea, classes.GraphStyle].join(" ")
                  : [classes.ChartArea, classes.DiagramStyle].join(" ")
              }
            >
              {chart}
            </div>

            {scrollStatus && (
              <>
                {leftArrow && (
                  <div
                    onClick={leftScroll}
                    id="hScrollBtnL"
                    className={classes.HScrollBtnL}
                  >
                    <img src={icons.scrollArrow} alt="scroll" />
                  </div>
                )}
                {rightArrow && (
                  <div
                    onClick={rightScroll}
                    id="hScrollBtnR"
                    className={classes.HScrollBtnR}
                  >
                    <img src={icons.scrollArrow} alt="scroll" />
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}

      <div className={classes.Table}>
        <Table />
      </div>
    </div>
  );
};

export default Section;