export const showRequiredField = (field:any) =>{
    for(const [key,value] of Object.entries(field)){
        if(value === false){
            if(key === 'x'){
                const getXfield = document.querySelector('#matrix_x')?.children[0]! as HTMLDivElement;
                if(getXfield){
                    getXfield.style.border = '0.14vh dashed red'
                }
            }
            if(key === 'y'){
                const getYfield = document.querySelector('#matrix_y')?.children[0]! as HTMLSpanElement;
                if(getYfield){
                    getYfield.style.border = '0.14vh dashed red'
                }
            }
            if(key === 'matrix'){
                const getMatrixfield = document.querySelector('#matrix_content')! as HTMLDivElement;
                if(getMatrixfield){
                    getMatrixfield.style.border = '0.14vh dashed red'
                }
            }
        }
    }
}