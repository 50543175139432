import React, { Fragment } from "react";
import classes from "./ModalForm.module.scss";
import closeSvg from "../../../assets/svg/close.svg";
import { useDispatch } from "react-redux";
import { activeModal, submitData } from "../../../store/actions/action";
import { icons } from "../../../settings/settings";
import { ArrowTop, ArrowBottom } from "../Svg/Svg";

interface ModalProps {
  inputs?: any;
  title?: string;
  handler: (data: any, method: string) => void;
  message?: string;
  value?: any;
  buttonName?: string;
  type?: string;
  row?: number;
  tab?: any;
}

// interface typeProps{
//     id:number,
//     name:string,
//     color:string
// }
// interface trustedProps{
//     id:number,
//     name:string,
//     color:string
// }
const ModalForm: React.FC<ModalProps> = ({
  inputs,
  title,
  handler,
  message,
  value,
  buttonName,
  type,
  row,
  tab,
}) => {
  const [refs, setRefs] = React.useState({ _inputs: {} });

  const [counter, setCounter] = React.useState(0);

  const dispatch = useDispatch();

  React.useEffect(() => {
    let input: any = document.querySelectorAll(
      "input"
    ) as unknown as HTMLInputElement;
    [...input].forEach((el: any) => {
      let label: any;
      el?.addEventListener("focus", (e: any) => {
        if (e.currentTarget.parentNode?.childNodes[0]?.nodeName === "LABEL") {
          label = e.currentTarget.parentNode?.childNodes[0];
        } else if (
          e.currentTarget.parentNode?.parentNode?.childNodes[0]?.nodeName ===
          "LABEL"
        ) {
          label = e.currentTarget.parentNode?.parentNode?.childNodes[0];
        }
        label?.classList.add(classes.ActiveLabel);
      });
    });
  }, []);
  const labelTop = (e: any) => {
    if (
      e.target.parentNode.childNodes[0] &&
      e.target.parentNode.childNodes[0].nodeName === "LABEL"
    ) {
      e.target.parentNode.childNodes[0].classList.add(classes.ActiveLabel);
    }
  };

  let newObj: any = {};

  const refsHandler = (elem: any, data: any) => {
    if (elem && elem.id) {
      let id =
        elem?.id?.toLowerCase().split(" ")?.length > 1
          ? elem?.id?.toLowerCase().split(" ")?.join("_")
          : elem?.id?.toLowerCase();
      newObj[id] = {
        value:
          data?.type === "checkbox"
            ? elem?.checked
            : data?.tag === "select"
            ? data?.massive?.filter((a: any) => a.name === elem.value)[0]?.id
            : elem?.value,
      };
    }
  };

  const submitHandler = (e: any, method: any) => {   
    setRefs((state) => {
      return {
        ...state,
        _inputs: {
          ...newObj,
        },
      };
    });

    dispatch(
      submitData({
        data: newObj,
      })
    );
    handler(newObj, method);
  };

  const writeInputHandler = (e: any, el: any) => {
    let id =
      e.currentTarget.id?.toLowerCase().split(" ")?.length > 1
        ? e.currentTarget.id?.toLowerCase().split(" ")?.join("_")
        : e.currentTarget.id?.toLowerCase();

    newObj[id] = {
      value:
        el?.type === "checkbox"
          ? e.currentTarget.checked
          : el?.tag === "select"
          ? el?.massive?.filter((a: any) => a.name === e.currentTarget.value)[0]
              ?.id
          : e.currentTarget.value,
    };
  };

  const arrowTopHandler = (e: any, data: any = null) => {
    let elem: any = e.currentTarget.parentNode?.parentNode?.children[0]
      ?.children[0] as HTMLElement;

    let title = data?.id;
    title = title?.toLowerCase().split(" ")?.join("_");
    let newArr = [...data?.massive];
    let len = newArr.length;
    if (counter < len - 1) {
      setCounter(counter + 1);
      elem.value = newArr[counter + 1]?.name;
      newObj[title].value = +newArr[counter + 1]?.id;
      if (newArr[0]?.color) {
        elem.nextSibling.style.backgroundColor = newArr[counter + 1]?.color;
      }
    }
  };

  const arrowBottomHandler = (e: any, data: any = null) => {
    let elem: any = e.currentTarget.parentNode?.parentNode?.children[0]
      ?.children[0] as HTMLElement;

    let title = data?.id;
    title = title.toLowerCase().split(" ")?.join("_");
    let newArr = [...data?.massive];
    if (counter > 0) {
      setCounter(counter - 1);
      elem.value = newArr[counter - 1]?.name;
      newObj[title].value = +newArr[counter - 1]?.id;
      if (newArr[0]?.color) {
        elem.nextSibling.style.backgroundColor = newArr[counter - 1]?.color;
      }
    }
  };

  const numberInputCounterJandler = (e: any, type: string, data: any) => {
    let input = e.currentTarget.parentNode?.parentNode?.children[0];
    let label = e.currentTarget.parentNode?.parentNode?.parentNode?.children[0];
    let title = data?.id;
    title = title.toLowerCase().split(" ")?.join("_");
    if (label && label?.nodeName === "LABEL") {
      label.classList.add(classes.ActiveLabel);
    }
    if (type === "inc") {
      input.value++;

      newObj[title].value = input.value;
    }
    if (type === "dec") {
      if (input.value <= 0) return 0;
      input.value--;
      newObj[title].value = input.value;
    }
  };
  return (
    <div
      style={{
        height:
          inputs.length > 2 ? 34 + (inputs.length - 2) * 8.8 + "vh" : 35 + "vh",
      }}
      className={classes.Modal}
    >
      <div
        onClick={() => dispatch(activeModal({ activeModal: null }))}
        className={classes.CloseBtn}
      >
        <img src={closeSvg} alt="close" />
      </div>
      <div className={classes.Title}>{title}</div>

      {refs &&
        inputs.map((el: any, i: number) => {
          const inline_input = {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "transparent",
          } as React.CSSProperties;

          return (
            <Fragment key={i}>
              {el.tag === "input" &&
                !el.hasOwnProperty("left") &&
                el.type !== "checkbox" && (
                  <>
                    <div className={classes.InputArea}>
                      <div
                        style={
                          el?.left ? inline_input : { position: "relative" }
                        }
                        className={classes.InputField}
                      >
                        <label
                          htmlFor={el.title}
                          className={el.value ? classes.ActiveLabel : ""}
                        >
                          {el.title}
                        </label>
                        <input
                          tabIndex={el?.index ? el.index : i + 1}
                          onChange={(e) => writeInputHandler(e, el)}
                          onKeyDown={(e) => {
                            if (!e.key.match(/[a-zA-Z0-9_]/)) {
                              e.preventDefault();
                            }
                          }}
                          defaultValue={el.value}
                          ref={(elem) => refsHandler(elem, el)}
                          type={el.type}
                          id={el?.id}
                          onPaste={(e) => {e.preventDefault(); return false;}}
                          onDrop={(e) => {e.preventDefault(); return false;}}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </>
                )}
              {(el?.left?.tag === "input" || el?.right?.tag === "input") &&
                el.hasOwnProperty("left") && (
                  <>
                    <div className={classes.InputArea}>
                      <div
                        style={
                          el?.left ? inline_input : { position: "relative" }
                        }
                        className={classes.InputField}
                      >
                        <div className={classes.Left}>
                          <label
                            htmlFor={el?.left?.id}
                            className={
                              el?.left?.value ? classes.ActiveLabel : ""
                            }
                          >
                            {el?.left.title}
                          </label>
                          <div className={classes.Input}>
                            <input
                              tabIndex={el?.left?.index ? el.left.index : i + 1}
                              min="0"
                              onChange={(e) => writeInputHandler(e, el)}
                              defaultValue={el?.left?.value}
                              ref={(elem) => refsHandler(elem, el)}
                              type={el?.left?.type}
                              id={el?.left?.id}
                            />
                            <div className={classes.Spin}>
                              <button
                                onClick={(e) =>
                                  numberInputCounterJandler(e, "inc", el?.left)
                                }
                              >
                                <ArrowTop />
                              </button>
                              <button
                                onClick={(e) =>
                                  numberInputCounterJandler(e, "dec", el?.left)
                                }
                              >
                                <ArrowBottom />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className={classes.Right}>
                          <label
                            htmlFor={el?.right?.id}
                            className={
                              el?.right?.value ? classes.ActiveLabel : ""
                            }
                          >
                            {el?.right.title}
                          </label>
                          <div className={classes.Input}>
                            <input
                              tabIndex={
                                el?.right?.index ? el.right.index : i + 1
                              }
                              min="0"
                              onChange={(e) => writeInputHandler(e, el)}
                              defaultValue={el?.right?.value}
                              ref={(elem) => refsHandler(elem, el)}
                              type={el?.right?.type}
                              id={el?.right?.id}
                            />
                            <div className={classes.Spin}>
                              <button
                                onClick={(e) =>
                                  numberInputCounterJandler(e, "inc", el?.right)
                                }
                              >
                                <ArrowTop />
                              </button>
                              <button
                                onClick={(e) =>
                                  numberInputCounterJandler(e, "dec", el?.right)
                                }
                              >
                                <ArrowBottom />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

              {el.tag === "select" && el.massive && (
                <>
                  <div className={classes.InputArea}>
                    <div
                      style={el?.left ? inline_input : { position: "relative" }}
                      className={classes.InputField}
                    >
                      <label htmlFor={el.title} className={classes.ActiveLabel}>
                        {el.title}
                      </label>
                      <div className={classes.SelectOption}>
                        <div className={classes.ValueFormat}>
                          <input
                            tabIndex={el?.index ? el.index : i + 1}
                            disabled
                            onChange={(e) => writeInputHandler(e, el)}
                            defaultValue={
                              !el?.value
                                ? el?.massive[0]?.name
                                : el?.massive?.filter(
                                    (a: any) => +a.id === +el?.value
                                  )[0]?.name
                            }
                            ref={(elem) => refsHandler(elem, el)}
                            type="text"
                            id={el?.id}
                          />
                          {el?.massive[0]?.color && (
                            <span
                              style={{
                                backgroundColor: !el?.value
                                  ? el?.massive[0]?.color
                                  : el?.massive?.filter(
                                      (a: any) => +a.id === +el?.value
                                    )[0]?.color,
                              }}
                              className={classes.Color}
                            ></span>
                          )}
                        </div>
                        <div className={classes.ArrowBtns}>
                          <span
                            className={classes.TopSpan}
                            onClick={(e) => arrowTopHandler(e, el)}
                          >
                            <img src={icons.arrowTop} alt="arrow top" />
                          </span>
                          <span
                            className={classes.BottomSpan}
                            onClick={(e) => arrowBottomHandler(e, el)}
                          >
                            <img src={icons.arrowBottom} alt="arrow bottom" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {el.tag === "input" &&
                !el.hasOwnProperty("left") &&
                el.type === "checkbox" && (
                  <>
                    <div className={classes.InputArea}>
                      <div
                        onClick={labelTop}
                        className={classes.InputFieldCheckbox}
                      >
                        <input
                          tabIndex={el?.index ? el.index : i + 1}
                          onChange={(e) => writeInputHandler(e, el)}
                          defaultValue={el.value}
                          ref={(elem) => refsHandler(elem, el)}
                          type={el.type}
                          id={el.title}
                        />
                        <label
                          htmlFor={el.title}
                          className={el.value ? classes.ActiveLabel : ""}
                        >
                          {el.title}
                        </label>
                      </div>
                    </div>
                  </>
                )}
            </Fragment>
          );
        })}

      <div className={classes.Footer}>
        <button
          tabIndex={tab ? +tab + 1 : inputs.length + 1}
          onClick={(e) => submitHandler(e, type)}
          className={classes.Add}
        >
          {buttonName}
        </button>
      </div>
    </div>
  );
};

export default ModalForm;
