import { StrategyActionsTypes, StrategyOutputFormatMessage, STRATEGY_OUTPUT_FORMAT_MESSAGE } from "../../../../../../store/types/typesStrategy";



const initialState: StrategyOutputFormatMessage = {
    message:'EMPTY'
    
}

const reducer = (state = initialState, action: StrategyActionsTypes): StrategyOutputFormatMessage => {

    switch (action.type) {

        case STRATEGY_OUTPUT_FORMAT_MESSAGE:
            const {message} = action.payload;
            
            return {
                message:message
            }
      
        default:
            return state

    }
}

export default reducer