import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  DragEvent,
} from "react";
import { useDebounce } from "use-debounce/lib";
import DB from "../../../../../../layout/DB/Storage";
import {
  EditorKeySources,
  messages,
  storageKeys,
  storage_key,
} from "../../../../../../settings/settings";
import classes from "./LeftTree.module.scss";
import scssVariables from "../../../../../../assets/scss/variables.module.scss";
// import { v4 as uuidv4 } from "uuid";
interface IDataViewer {
  fullHeight?: boolean;
  disabledGroups?: string[];
  dragVariable: (e: DragEvent<HTMLDivElement>, variable: string) => void;
  dragMaps: (e: DragEvent<HTMLDivElement>, map: string) => void;
  dragTreeHandler: (
    e: DragEvent<HTMLSpanElement>,
    variable: string,
    fieldObject: any
  ) => void;
}

interface IGroupState {
  name: EditorKeySources;
  extended: boolean;
}

const OutputDataViewer: FC<IDataViewer> = ({
  fullHeight = false,
  disabledGroups,
  dragVariable,
  dragTreeHandler,
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const bodyRef = useRef<HTMLDivElement | null>(null);

  const [groupState, setGroupState] = useState<Array<IGroupState>>([]);
  const [reformattedTreeDataState, setReformattedTreeDataState] = useState<
    Array<object>
  >([]);
  const [reformattedVariablesState, setReformattedVariablesState] = useState<
    Array<string>
  >([]);
  const [reformattedMapsState, setReformattedMapsState] = useState<
    Array<string>
  >([]);
  const [reformattedDecisionState, setReformattedDecisionState] = useState<
    Array<object>
  >([]);
  const [reformattedScoreCardState, setReformattedScoreCardState] = useState<
    Array<object>
  >([]);
  const [reformattedProductState, setReformattedProductState] = useState<
    Array<string>
  >([]);
  const [searched, setSearched] = useState<string>("");
  const [previousSearch, setPreviousSearch] = useState<string>();
  const [searchResult, setSearchResult] = useState<Array<object>>([]);
  const [storage, setStorage] = useState<any[]>([]);
  let [searchString] = useDebounce(searched, 1000);
  useLayoutEffect(() => {
    const storageData = localStorage.getItem(storage_key);
    if (!storageData) return;
    const storageObjData = JSON.parse(storageData);
    setStorage([...storageObjData]);
  }, []);
  const getReformattedSourceData = useCallback(() => {
    const reformattedSourceJSON = sessionStorage.getItem(
      storageKeys.reformattedSourceData
    );

    if (!reformattedSourceJSON) return;
    setReformattedTreeDataState(JSON.parse(reformattedSourceJSON));
  }, []);

  const generateInitialGroups = useCallback(() => {
    let initialGroupState: Array<IGroupState> = [
      { name: EditorKeySources.INPUT, extended: false },
      { name: EditorKeySources.VARIABLE, extended: false },
      { name: EditorKeySources.MAP, extended: false },
      { name: EditorKeySources.DECISIONS, extended: false },
      { name: EditorKeySources.PRODUCTS, extended: false },
    ];

    if (disabledGroups) {
      let newGroupState = initialGroupState.filter((group: IGroupState) => {
        const { name } = group;

        return !disabledGroups.includes(name);
      });

      setGroupState([...newGroupState]);
    } else {
      setGroupState([...initialGroupState]);
    }
  }, [disabledGroups]);

  const reformatVariablesData = useCallback(
    (elementsArray: Array<object>) => {
      if (disabledGroups?.includes("variables")) return;

      const functionArrays: Array<string> = elementsArray
        ?.filter((element: any) => {
          const { type } = element;

          return type === "func";
        })
        .map((element: any) => {
          const {
            data: { resultVar, label },
          } = element;

          return resultVar ? resultVar : label;
        });

      setReformattedVariablesState(functionArrays);
    },
    [disabledGroups]
  );

  const reformatMapsData = useCallback(
    (elementsArray: Array<object>) => {
      if (disabledGroups?.includes("scorecard")) return;

      const mapsArrays: Array<string> = elementsArray
        ?.filter((element: any) => {
          const { type } = element;

          return type === "scorecard";
        })
        .map((element: any) => {
          const {
            data: { label },
          } = element;

          return label;
        });
      setReformattedMapsState(mapsArrays);
    },
    [disabledGroups]
  );
  const reformatDecisionData = useCallback(
    (elementsArray: Array<object>) => {
      if (disabledGroups?.includes("decision")) return;

      const decisionArrays: Array<object> = elementsArray
        ?.filter((element: any) => {
          const { type } = element;

          return type === "decision";
        })
        .map((element: any) => {
          const {
            data: { id, label },
          } = element;
         
          let obj: any = {
            childrenKeys: ["label", "alias","status"],
            extendable: true,
            extended: false,
            id: id,
            key: label,
            parent: label,
            type: "object",
            value: [
              {
                childrenKeys: null,
                extendable: false,
                extended: false,
                id: id,
                key: "label",
                parent: label,
                type: "string",
                value: `${label}.label`,
              },
              {
                childrenKeys: null,
                extendable: false,
                extended: false,
                id: id,
                key: "alias",
                parent: label,
                type: "string",
                value: `${label}.alias`,
              },
              {
                childrenKeys: null,
                extendable: false,
                extended: false,
                id: id,
                key: "status",
                parent: label,
                type: "string",
                value: `${label}.status`,
              },
            ],
          };

          return obj;
        });

      setReformattedDecisionState(decisionArrays);
    },
    [disabledGroups]
  );

  const reformatScoreCardData = useCallback(
    (elementsArray: Array<object>) => {
      if (disabledGroups?.includes("scorecard")) return;

      const decisionArrays: Array<object> = elementsArray
        ?.filter((element: any) => {
          const { type } = element;

          return type === "scorecard";
        })
        .map((element: any) => {
          const {
            data: { id, label },
          } = element;

          let obj: any = {
            childrenKeys: ["point", "log"],
            extendable: true,
            extended: false,
            id: id,
            key: label,
            parent: label,
            type: "object",
            value: [
              {
                childrenKeys: null,
                extendable: false,
                extended: false,
                id: id,
                key: "point",
                parent: label,
                type: "string",
                value: `${label}.point`,
              },
              {
                childrenKeys: null,
                extendable: false,
                extended: false,
                id: id,
                key: "log",
                parent: label,
                type: "string",
                value: `${label}.log`,
              },
            ],
          };

          return obj;
        });
      setReformattedScoreCardState(decisionArrays);
    },
    [disabledGroups]
  );
  const reformatProductData = useCallback(
    (elementsArray: Array<object>) => {
      if (disabledGroups?.includes("product")) return;

      const productArrays: Array<string> = elementsArray
        ?.filter((element: any) => {
          const { type } = element;

          return type === "product";
        })
        .map((element: any) => {
          const {
            data: { label },
          } = element;

          return label;
        });
      setReformattedProductState(productArrays);
    },
    [disabledGroups]
  );
  const fetchElementsData = useCallback(() => {
    const db = new DB(storageKeys.boardElements);

    db.fetchAll()
      .then((result: any) => {
        const { status, data } = result;
        if (status) {
          reformatVariablesData(data);
          reformatMapsData(data);
          reformatDecisionData(data);
          reformatScoreCardData(data);
          reformatProductData(data);
        } else {
          console.warn(messages.messageStorageFetchStatusFalse);
        }
      })
      .catch((error) => {
        console.error(messages.messageStorageFetchError, error);
      });
  }, [
    reformatVariablesData,
    reformatMapsData,
    reformatDecisionData,
    reformatScoreCardData,
    reformatProductData,
  ]);

  const generateSearchResult = useCallback(
    (
      searchString: string | undefined,
      previousSearchString: string | undefined,
      treeData: Array<any> | undefined | null,
      variablesData: Array<string> | undefined | null,
      mapsData: Array<string> | undefined | null,
      decisionData: Array<object> | undefined | null,
      productData: Array<string> | undefined | null,
      searchResult: Array<object>
    ) => {
      function findMatchedTreeKey(
        treeData: Array<any>,
        keySearchRegex: RegExp,
        valueSearchRegex: RegExp,
        initialParent: string = "root"
      ) {
        let result: Array<object> = [];

        for (let index = 0; index < treeData.length; index++) {
          const { key, value, extendable } = treeData[index];

          const keyFound = keySearchRegex.test(key);
          const valueFound = valueSearchRegex.test(value);

          if (keyFound || valueFound) {
            result.push({
              name: key ? key : `[${index}]`,
              value: !extendable && value,
              parent: initialParent,
              type: "input",
            });
          }

          if (extendable) {
            result.push(
              ...findMatchedTreeKey(
                value,
                keySearchRegex,
                valueSearchRegex,
                key ? `${initialParent}.${key}` : `${initialParent}[${index}]`
              )
            );
          }
        }
        return result;
      }

      function findMatchedVariables(
        variablesData: Array<any>,
        keySearchRegex: RegExp
      ) {
        let result: Array<object> = [];

        variablesData.forEach((variable: string) => {
          const found = keySearchRegex.test(variable);

          if (found) {
            result.push({
              name: variable,
              type: "variable",
            });
          }
        });

        return result;
      }
      function findMatchedMaps(mapsData: Array<any>, keySearchRegex: RegExp) {
        let result: Array<object> = [];
        mapsData.forEach((map: string) => {
          if (typeof map === "object") {
            const { key } = map;
            const found = keySearchRegex.test(key);
            if (found) {
              result.push(
                {
                  name: key,
                  type: "scorecard",
                },
                {
                  name: `${key}.point`,
                  type: "scorecard",
                },
                {
                  name: `${key}.log`,
                  type: "scorecard",
                }
              );
            }
          } else if (typeof map === "string") {
            const found = keySearchRegex.test(map);
            if (found) {
              result.push(
                {
                  name: map,
                  type: "scorecard",
                },
                {
                  name: `${map}.point`,
                  type: "scorecard",
                },
                {
                  name: `${map}.log`,
                  type: "scorecard",
                }
              );
            }
          }
       
        });
        return result;
      }
      function findMatchedDecisions(
        decisionsData: Array<any>,
        keySearchRegex: RegExp
      ) {
        let result: Array<object> = [];

        decisionsData.forEach((decision: string) => {
          if (typeof decision === "object") {
            const { key } = decision;
            const found = keySearchRegex.test(key);
            if (found) {
              result.push(
                {
                  name: key,
                  type: "decision",
                },
                {
                  name: `${key}.label`,
                  type: "decision",
                },
                {
                  name: `${key}.alias`,
                  type: "decision",
                }
                ,
                {
                  name: `${key}.status`,
                  type: "decision",
                }
              );
            }
          } else if (typeof decision === "string") {
            const found = keySearchRegex.test(decision);
            if (found) {
              result.push({
                name: decision,
                type: "decision",
              });
            }
          }
        });

        return result;
      }
      function findMatchedProducts(
        productsData: Array<any>,
        keySearchRegex: RegExp
      ) {
        let result: Array<object> = [];

        productsData.forEach((product: string) => {
          const found = keySearchRegex.test(product);

          if (found) {
            result.push({
              name: product,
              type: "product",
            });
          }
        });

        return result;
      }
      if (
        searchString &&
        treeData &&
        variablesData &&
        mapsData &&
        decisionData &&
        productData &&
        searchString !== previousSearchString
      ) {
        let result: Array<any> = [];
        const keySearchRegex = new RegExp(`^${searchString}`, "i");
        const valueSearchRegex = new RegExp(`${searchString}`, "i");

        result = result.concat(
          findMatchedTreeKey(treeData, keySearchRegex, valueSearchRegex),
          findMatchedVariables(variablesData, keySearchRegex),
          findMatchedMaps(mapsData, keySearchRegex),
          findMatchedDecisions(decisionData, keySearchRegex),
          findMatchedProducts(productData, keySearchRegex)
        );

        setPreviousSearch(searchString);
        setSearchResult(result);
      } else if (!searchString && searchResult.length) {
        setSearchResult([]);
        setPreviousSearch(undefined);
      }
    },
    []
  );
  const checkHeightProp = useCallback(() => {
    const wrapperElement = wrapperRef.current;
    const bodyElement = bodyRef.current;

    if (fullHeight && wrapperElement && bodyElement) {
      wrapperElement.classList.add(classes.FullWrapperHeight);
      bodyElement.classList.add(classes.FullBodyHeight);
    } else if (
      !fullHeight &&
      wrapperElement &&
      bodyElement &&
      wrapperElement.classList.contains(classes.FullWrapperHeight) &&
      bodyElement.classList.contains(classes.FullBodyHeight)
    ) {
      wrapperElement.classList.remove(classes.FullWrapperHeight);
      bodyElement.classList.remove(classes.FullBodyHeight);
    }
  }, [fullHeight]);

  const clearSearch = useCallback(() => {
    setSearched("");
  }, []);

  useLayoutEffect(() => {
    getReformattedSourceData();
    generateInitialGroups();
    fetchElementsData();
    checkHeightProp();
  }, [
    getReformattedSourceData,
    fetchElementsData,
    checkHeightProp,
    generateInitialGroups,
  ]);

  useEffect(() => {
    generateSearchResult(
      searchString,
      previousSearch,
      reformattedTreeDataState,
      reformattedVariablesState,
      reformattedMapsState,
      reformattedDecisionState,

      reformattedProductState,
      searchResult
    );
  }, [
    generateSearchResult,
    searchString,
    previousSearch,
    reformattedTreeDataState,
    reformattedVariablesState,
    reformattedMapsState,
    reformattedDecisionState,
    searchResult,
    reformattedProductState,
  ]);

  const keyRow = (
    id: string,
    key: string,
    parentPath: string,
    fieldObject: any,
    type: string = ""
  ) => {
    const data = parentPath?.length > 0 ? `${parentPath}.${key}` : key;
    // const isList = parentPath?.match(/(?<=\[).*?(?=\])/g) ? true :false; //list icindekiler drag olunmayacaq olarsa aktivlesdir
    const _type =
      type === "tree" ? EditorKeySources.INPUT : EditorKeySources.DECISIONS;
    return (
      <span
        onDragStart={(e) => {
          dragTreeHandler(
            e,
            JSON.stringify({ source: _type, key: data }),
            fieldObject
          );
        }}
        onClick={() =>
          type === "decision"
            ? setReformattedDecisionState(
                handleExtendStatus(reformattedDecisionState, id)
              )
            : type === "scorecard"
            ? setReformattedScoreCardState(
                handleExtendStatus(reformattedScoreCardState, id)
              )
            : setReformattedTreeDataState(
                handleExtendStatus(reformattedTreeDataState, id)
              )
        }
        // data_type === 'array' ||
        // draggable={isList ? false:true}
        draggable
      >
        {key}
      </span>
    );
  };

  const valueRow = (value: string) => {
    return <span>{value}</span>;
  };

  const booleanRow = (value: string) => {
    return valueRow(value);
  };

  const colorRow = (value: string) => {
    return valueRow(value);
  };

  const variableRow = (value: string) => {
    return valueRow(value);
  };

  const arrayRow = (
    value: Array<any>,
    extendStatus: boolean,
    type: string = ""
  ) => {
    if (extendStatus) {
      return <>{generateArrayRows(value, type)}</>;
    }
    if (!extendStatus) {
      return <span>[{value?.length}]</span>;
    }
  };

  const objectRow = (
    value: Array<any>,
    parentPath: string,
    extendStatus: boolean,
    valueIndex: number,
    arrayChild: boolean,
    type: string = ""
  ) => {
    if (extendStatus) {
      return <>{generateObjectRows(value)}</>;
    }
    if (!extendStatus && arrayChild) {
      const pathWithoutRoot = parentPath.slice(5);
      const data = `${pathWithoutRoot}[${valueIndex}]`;

      return (
        <span
          // draggable
          onDragStart={(event: any) => {
            event.dataTransfer.setData(
              "dragging_source",
              JSON.stringify({
                source:
                  type === "decision" || type === "scorecard"
                    ? EditorKeySources.DECISIONS
                    : EditorKeySources.INPUT,
                key: data,
              })
            );
          }}
        >
          &#123;{value.length}&#125;
        </span>
      );
    }
    if (!extendStatus && !arrayChild) {
      return <span>&#123;{value.length}&#125;</span>;
    }
  };

  const handleGroupExtendStatus = (groupName: string, groupIndex: number) => {
    const copyOfState = [...groupState];
    const specificElement: any = copyOfState?.find((group: any) => {
      const { name } = group;

      return name === groupName;
    });

    if (specificElement) {
      specificElement.extended = !specificElement.extended;
    }

    copyOfState[groupIndex] = { ...specificElement };
    setGroupState([...copyOfState]);
  };

  const handleExtendStatus = (dataArray: Array<any>, uniqueId: string) => {
    const copyOfData = [...dataArray];
    let specificRowIndex: number;

    const specificRow: any = copyOfData.find((element: any) => {
      const { id, extendable } = element;
      return id === uniqueId && extendable;
    });

    if (specificRow) {
      specificRowIndex = copyOfData.indexOf(specificRow);
      specificRow.extended = !specificRow.extended;
      copyOfData[specificRowIndex] = specificRow;
    } else {
      const availableChildren = copyOfData.filter((element: any) => {
        const { extendable } = element;
        return extendable === true;
      });

      availableChildren.forEach((element: any) => {
        const { value } = element;
        specificRowIndex = copyOfData.indexOf(element);
        copyOfData[specificRowIndex].value = handleExtendStatus(
          value,
          uniqueId
        );
      });
    }

    return copyOfData;
  };

  const generateObjectRows = (objectData: Array<object>) => {
    return objectData.map((fieldObjects: any, index: number) => {
      const { id, key, value, parent, type, extendable, extended } =
        fieldObjects;

      return (
        <div
          key={index}
          className={classes.DataRow}
          title={`${key} : ${
            type !== "array" && type !== "object" ? value : type
          }`}
        >
          <svg
            className={`${extended ? classes.ActiveArrow : ""}`}
            width="6"
            height="9"
            viewBox="0 0 6 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() =>
              setReformattedTreeDataState(
                handleExtendStatus(reformattedTreeDataState, id)
              )
            }
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.22392 5.33569C5.53718 5.05723 5.53718 4.56777 5.22392 4.28931L1.35255 0.848104C0.901123 0.446832 0.1875 0.767297 0.1875 1.37129L0.1875 8.25371C0.1875 8.8577 0.901124 9.17817 1.35255 8.7769L5.22392 5.33569Z"
              fill={
                extendable
                  ? scssVariables.colorIconBlue
                  : scssVariables.colorIconGray
              }
            />
          </svg>
          {keyRow(id, key, parent, fieldObjects, "tree")}
          {" : "}
          {type === "boolean"
            ? booleanRow(value)
            : type === "color"
            ? colorRow(value)
            : type === "array"
            ? arrayRow(value, extended)
            : type === "object"
            ? objectRow(value, parent, extended, index, true)
            : valueRow(value)}
        </div>
      );
    });
  };

  const generateArrayRows = (arrayData: Array<any>, _type: string = "") => {
    return arrayData.map((fieldObjects: any, index: number) => {
      const { id, value, parent, type, extendable, extended } = fieldObjects;
      return (
        <div key={index} className={classes.DataRow}>
          <svg
            className={`${extended ? classes.ActiveArrow : ""}`}
            width="6"
            height="9"
            viewBox="0 0 6 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() =>
              _type === "scorecard"
                ? setReformattedScoreCardState(
                    handleExtendStatus(reformattedScoreCardState, id)
                  )
                : setReformattedTreeDataState(
                    handleExtendStatus(reformattedTreeDataState, id)
                  )
            }
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.22392 5.33569C5.53718 5.05723 5.53718 4.56777 5.22392 4.28931L1.35255 0.848104C0.901123 0.446832 0.1875 0.767297 0.1875 1.37129L0.1875 8.25371C0.1875 8.8577 0.901124 9.17817 1.35255 8.7769L5.22392 5.33569Z"
              fill={
                extendable
                  ? scssVariables.colorIconBlue
                  : scssVariables.colorIconGray
              }
            />
          </svg>
          {type === "boolean"
            ? booleanRow(value)
            : type === "color"
            ? colorRow(value)
            : type === "array"
            ? arrayRow(value, extended)
            : type === "object"
            ? objectRow(value, parent, extended, index, true)
            : valueRow(value)}
        </div>
      );
    });
  };

  const generateVariableRows = (variableData: Array<string>) => {
    return variableData?.map((variable: string, index: number) => {
      return (
        <div
          key={index}
          className={classes.Row}
          draggable
          onDragStart={(e) => dragVariable(e, variable)}
          onDragEnd={(event: any) => {
            const { target } = event;
            const targetElement = target as HTMLDivElement;

            targetElement?.classList.remove(classes.Drag);
          }}
        >
          {variableRow(variable)}
        </div>
      );
    });
  };

  const generateDecisionRows = (objectData: Array<object>) => {
    return objectData.map((fieldObjects: any, index: number) => {
      const { id, key, value, parent, type, extendable, extended } =
        fieldObjects;

      return (
        <div
          key={index}
          className={classes.DataRow}
          title={`${key} : ${
            type !== "array" && type !== "object" ? value : type
          }`}
        >
          <svg
            className={`${extended ? classes.ActiveArrow : ""}`}
            width="6"
            height="9"
            viewBox="0 0 6 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() =>
              setReformattedDecisionState(
                handleExtendStatus(reformattedDecisionState, id)
              )
            }
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.22392 5.33569C5.53718 5.05723 5.53718 4.56777 5.22392 4.28931L1.35255 0.848104C0.901123 0.446832 0.1875 0.767297 0.1875 1.37129L0.1875 8.25371C0.1875 8.8577 0.901124 9.17817 1.35255 8.7769L5.22392 5.33569Z"
              fill={
                extendable
                  ? scssVariables.colorIconBlue
                  : scssVariables.colorIconGray
              }
            />
          </svg>
          {keyRow(id, key, parent, fieldObjects, "decision")}
          {" : "}
          {type === "boolean"
            ? booleanRow(value)
            : type === "color"
            ? colorRow(value)
            : type === "array"
            ? arrayRow(value, extended)
            : type === "object"
            ? objectRow(value, parent, extended, index, true, "decision")
            : valueRow(value)}
        </div>
      );
    });
  };

  const generateScoreCardRows = (objectData: Array<object>) => {
    return objectData.map((fieldObjects: any, index: number) => {
      const { id, key, value, parent, type, extendable, extended } =
        fieldObjects;

      return (
        <div
          key={index}
          className={classes.DataRow}
          title={`${key} : ${
            type !== "array" && type !== "object" ? value : type
          }`}
        >
          <svg
            className={`${extended ? classes.ActiveArrow : ""}`}
            width="6"
            height="9"
            viewBox="0 0 6 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() =>
              setReformattedScoreCardState(
                handleExtendStatus(reformattedScoreCardState, id)
              )
            }
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.22392 5.33569C5.53718 5.05723 5.53718 4.56777 5.22392 4.28931L1.35255 0.848104C0.901123 0.446832 0.1875 0.767297 0.1875 1.37129L0.1875 8.25371C0.1875 8.8577 0.901124 9.17817 1.35255 8.7769L5.22392 5.33569Z"
              fill={
                extendable
                  ? scssVariables.colorIconBlue
                  : scssVariables.colorIconGray
              }
            />
          </svg>
          {keyRow(id, key, parent, fieldObjects, "scorecard")}
          {" : "}
          {type === "boolean"
            ? booleanRow(value)
            : type === "color"
            ? colorRow(value)
            : type === "array"
            ? arrayRow(value, extended, "scorecard")
            : type === "object"
            ? objectRow(value, parent, extended, index, true, "scorecard")
            : valueRow(value)}
        </div>
      );
    });
  };
  const generateProductRows = (productData: Array<string>) => {
    return productData?.map((product: string, index: number) => {
      return (
        <div
          key={index}
          className={classes.Row}
          draggable
          onDragStart={(event: any) => {
            const { target } = event;
            const targetElement = target as HTMLDivElement;

            targetElement?.classList.add(classes.Drag);
            event.dataTransfer.setData(
              "dragging_product",
              JSON.stringify({
                source: EditorKeySources.PRODUCTS,
                key: product,
              })
            );
          }}
          onDragEnd={(event: any) => {
            const { target } = event;
            const targetElement = target as HTMLDivElement;

            targetElement?.classList.remove(classes.Drag);
          }}
        >
          {valueRow(product)}
        </div>
      );
    });
  };
  const generateGroupRows = (groupList: Array<any>) => {
    return groupList.map((group: any, index: number) => {
      const { name, extended } = group;
      const extendable: { [key: string]: JSX.Element[] } = {
        Input: generateObjectRows(reformattedTreeDataState),
        Variable: generateVariableRows(reformattedVariablesState),
        "Score Cards": generateScoreCardRows(reformattedScoreCardState),
        Decisions: generateDecisionRows(reformattedDecisionState),
        Products: generateProductRows(reformattedProductState),
      };
      return (
        <div
          key={index}
          className={`${classes.GroupRow} ${
            extended ? classes.ActiveGroup : ""
          }`}
        >
          <div
            className={classes.GroupHeader}
            onClick={() => handleGroupExtendStatus(name, index)}
          >
            <span className={classes.GroupName}>{name}</span>
            <svg
              viewBox="0 0 7 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M3.66996 3.94462L6.69982 0.914706C6.76996 0.844628 6.80859 0.75108 6.80859 0.651331C6.80859 0.551583 6.76996 0.458035 6.69982 0.387957L6.47675 0.164824C6.33139 0.0196303 6.09513 0.0196303 5.95 0.164824L3.4057 2.70912L0.85858 0.162001C0.788446 0.0919228 0.694953 0.0532303 0.595261 0.0532303C0.495457 0.0532303 0.401964 0.0919228 0.331775 0.162001L0.108753 0.385134C0.0386195 0.455267 -1.7643e-05 0.54876 -1.7643e-05 0.648508C-1.7643e-05 0.748257 0.0386195 0.841805 0.108753 0.911883L3.14138 3.94462C3.21174 4.01487 3.30567 4.05345 3.40553 4.05323C3.50578 4.05345 3.59966 4.01487 3.66996 3.94462Z" />
            </svg>
          </div>
          {extended && extendable[name]}
        </div>
      );
    });
  };

  const handleSearchChange = (event: any) => {
    const {
      target: { value },
    } = event;

    setSearched(value);
  };

  const generateSearchResultRows = (searchResult: Array<object>) => {
    return searchResult?.map((result: any, index: number) => {
      const { name, value, parent, type } = result;
      const parentWithoutRoot = type === "input" && parent.slice(5);
      return (
        <div
          key={index}
          className={classes.Row}
          title={`${type} : ${
            type === "input" && parentWithoutRoot
              ? `${parentWithoutRoot}.${name} : ${value}`
              : name
          } `}
          draggable
          onDragStart={(event: any) => {
            const { target } = event;
            const targetElement = target as HTMLDivElement;
            const [item] = storage.filter(
              (item: any) => item.data.label === name
            );

            let currentObj = null as any;

            function getCurrentObj(data: any) {
              const splittedArr = [...parentWithoutRoot?.split("."), name];
              splittedArr?.forEach((key) => {
                data.forEach((item: any) => {
                  if (item.key === key) {
                    if (
                      item.key !== name &&
                      item.parent !== parentWithoutRoot
                    ) {
                      getCurrentObj(item.value);
                    } else if (
                      item.key === name &&
                      item.parent === parentWithoutRoot
                    ) {
                      currentObj = item;
                    }
                  }
                });
              });
            }

            if (!item) {
              const decisionType = {
                input: () => {
                  getCurrentObj(reformattedTreeDataState);
                  dragTreeHandler(event, JSON.stringify({ a: 5 }), currentObj);
                },
                scorecard: () => {
                  if (!name) return;
                  const [parent, key] = name.split(".") as string[];
                  const [getScoreCardObj] = reformattedScoreCardState?.filter(
                    (scorecard: any) => scorecard.key === parent
                  ) as any;
                  if (!getScoreCardObj) return;
                  const { childrenKeys, value } = getScoreCardObj;
                  const index = childrenKeys.indexOf(key);
                  const data = value[index];
                  if (!data) return;
                  dragTreeHandler(event, JSON.stringify({}), data);
                },
                decision: () => {
                  if (!name) return;
                  const [parent, key] = name.split(".") as string[];
                  const [getDecisionObj] = reformattedDecisionState?.filter(
                    (decision: any) => decision.key === parent
                  ) as any;
                  if (!getDecisionObj) return;
                  const { childrenKeys, value } = getDecisionObj;
                  const index = childrenKeys.indexOf(key);
                  const data = value[index];
                  if (!data) return;
                  dragTreeHandler(event, JSON.stringify({}), data);
                },
              } as any;
              decisionType[type]();
            } else {
              const { id } = item.data;
              targetElement?.classList.add(classes.Drag);
              const decisionType = {
                variable: () => {
                  return [
                    { dataKey: "id", data: id },
                    { dataKey: "label", data: name },
                  ];
                },
                scorecard: () => {
                  return [
                    { dataKey: "id", data: id },
                    { dataKey: "label", data: name },
                  ];
                },
                decision: () => {
                  return [
                    { dataKey: "id", data: id },
                    { dataKey: "label", data: name },
                  ];
                },
                product: () => {
                  return [
                    { dataKey: "id", data: id },
                    { dataKey: "label", data: name },
                  ];
                },
              } as any;
              if (Array.isArray(decisionType[type]?.())) {
                const refArray = [...decisionType[type]?.()];
                refArray.forEach((item: any) => {
                  const { dataKey, data } = item;
                  event.dataTransfer.setData(dataKey, data);
                });
              }
            }
          }}
          onDragEnd={(event: any) => {
            const { target } = event;
            const targetElement = target as HTMLDivElement;

            targetElement?.classList.remove(classes.Drag);
          }}
        >
          {value ? (
            <>
              {valueRow(name)} {" : "} {valueRow(value)}
            </>
          ) : (
            valueRow(name)
          )}
        </div>
      );
    });
  };

  return (
    <div ref={wrapperRef} className={classes.DataViewerWrapper}>
      <div className={classes.ViewerHeader}>
        <svg
          className={classes.DoubleIcon}
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.6011 10.796L15.4749 14.8249C15.8026 15.1657 15.7917 15.7091 15.4511 16.0369C15.2909 16.1911 15.0797 16.276 14.8571 16.276C14.622 16.276 14.4026 16.1826 14.2394 16.0131L10.3363 11.9537C9.228 12.7326 7.93171 13.1429 6.57143 13.1429C2.948 13.1429 0 10.1949 0 6.57143C0 2.948 2.948 0 6.57143 0C10.1949 0 13.1429 2.948 13.1429 6.57143C13.1429 8.12229 12.5971 9.612 11.6011 10.796ZM11.4286 6.57143C11.4286 3.89314 9.24971 1.71429 6.57143 1.71429C3.89314 1.71429 1.71429 3.89314 1.71429 6.57143C1.71429 9.24971 3.89314 11.4286 6.57143 11.4286C9.24971 11.4286 11.4286 9.24971 11.4286 6.57143Z"
          />
        </svg>
        <input
          className={classes.Title}
          type="text"
          placeholder="Search in Params"
          value={searched}
          onChange={handleSearchChange}
        />
        {searched ? (
          <svg
            className={classes.DoubleIcon}
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={clearSearch}
          >
            <title>Clear</title>
            <path d="M5.88083 4.99709L9.81083 1.06709C10.055 0.82313 10.055 0.427297 9.81083 0.183339C9.56667 -0.060828 9.17125 -0.060828 8.92708 0.183339L4.99708 4.11334L1.06687 0.183339C0.822708 -0.060828 0.427292 -0.060828 0.183125 0.183339C-0.0610417 0.427297 -0.0610417 0.82313 0.183125 1.06709L4.11333 4.99709L0.183125 8.92709C-0.0610417 9.17105 -0.0610417 9.56688 0.183125 9.81084C0.305208 9.93271 0.465208 9.99376 0.625 9.99376C0.784792 9.99376 0.944792 9.93271 1.06687 9.81063L4.99708 5.88063L8.92708 9.81063C9.04917 9.93271 9.20917 9.99376 9.36896 9.99376C9.52875 9.99376 9.68875 9.93271 9.81083 9.81063C10.055 9.56667 10.055 9.17084 9.81083 8.92688L5.88083 4.99709Z" />
          </svg>
        ) : (
          <svg
            className={classes.DoubleIcon}
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 6.30469C4.21574 6.30469 4.39062 6.1298 4.39062 5.91406C4.39062 5.69833 4.21574 5.52344 4 5.52344C3.78426 5.52344 3.60938 5.69833 3.60938 5.91406C3.60938 6.1298 3.78426 6.30469 4 6.30469Z"
              fill="white"
              fillOpacity="0.6"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 4C0 1.78902 1.78931 0 4 0C6.21098 0 8 1.78931 8 4C8 6.21098 6.21069 8 4 8C1.78902 8 0 6.21069 0 4ZM0.625 4C0.625 5.86552 2.13473 7.375 4 7.375C5.86552 7.375 7.375 5.86527 7.375 4C7.375 2.13448 5.86527 0.625 4 0.625C2.13448 0.625 0.625 2.13473 0.625 4Z"
              fill="white"
              fillOpacity="0.6"
            />
            <path
              d="M4 2.00781C3.31075 2.00781 2.75 2.56856 2.75 3.25781C2.75 3.43041 2.88991 3.57031 3.0625 3.57031C3.23509 3.57031 3.375 3.43041 3.375 3.25781C3.375 2.91319 3.65538 2.63281 4 2.63281C4.34462 2.63281 4.625 2.91319 4.625 3.25781C4.625 3.60244 4.34462 3.88281 4 3.88281C3.82741 3.88281 3.6875 4.02272 3.6875 4.19531V4.97656C3.6875 5.14916 3.82741 5.28906 4 5.28906C4.17259 5.28906 4.3125 5.14916 4.3125 4.97656V4.46827C4.85103 4.32916 5.25 3.8392 5.25 3.25781C5.25 2.56856 4.68925 2.00781 4 2.00781Z"
              fill="white"
              fillOpacity="0.6"
            />
          </svg>
        )}
      </div>
      <div
        ref={bodyRef}
        className={classes.ViewerBody}
        style={{ height: "52vh" }}
      >
        {!searchString ? (
          generateGroupRows(groupState)
        ) : searchResult.length ? (
          generateSearchResultRows(searchResult)
        ) : (
          <div className={classes.NoDataRow}>
            No result found for {searchString}
          </div>
        )}
      </div>
    </div>
  );
};

export default OutputDataViewer;
