import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import classes from "./StrategyBoardFooter.module.scss";
import { icons } from "../../../../settings/settings";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/combineReducer";
import {
  handleStrategyBoardZoom,
  handleStrategyBoardSidePanelContent,
} from "../../../../store/actions/actionsStrategy";
import { removeElement } from "helpers/removeElement";

const StrategyBoardFooter: FC = () => {
  const dispatch = useDispatch();
  const [formatsString, setFormatsString] = useState<string>();
  const {
    reducerStrategy: {
      StrategyBoardZoom: { minZoom, maxZoom, zoom,zoomPercent },
      StrategyBoardSidePanel: {
        Input: {
          input_uploadFiles: { uploadFiles_acceptable },
        },
      },
      StrategyBoardElements: {
        NewCreatedElement: { new_created_element },
      },
      SourceDataAvailability,
      SourceDataTypes,
    },
  } = useSelector((state: RootState) => state);

  // console.log(useSelector((state: RootState) => state).reducerStrategy.StrategyBoardZoom.zoomPercent);

  const zoomInput: any = useRef();
  let [value, setValue] = useState("");
  // const initZoomPercent = useSelector((state: RootState) => state).reducerStrategy.StrategyBoardZoom.zoomPercent!;
  // console.log(value);
  // console.log(zoomPercent);

  const fileFormatSeperator = useCallback((string: string) => {
    let seperatedFormat = string.slice(string.indexOf(".") + 1);

    return seperatedFormat;
  }, []);

  const seperateFileFormatsAndCombineIntoString = useCallback(
    (array: Array<string> | null) => {
      let formatsCombinedString = "";
      let formatsArray: Array<string> = [];

      if (array !== null) {
        array.forEach((element: any) => {
          formatsArray.push(fileFormatSeperator(element));
        });
      }

      formatsCombinedString = formatsArray.join(", ").toUpperCase();

      setFormatsString(formatsCombinedString);
    },
    [fileFormatSeperator]
  );

  const findSourceDataType = useCallback((sourceTypes: Array<any>) => {
    const sourceDataJSON = sessionStorage.getItem(
      "active_strategy_source_data"
    );

    if (sourceDataJSON) {
      const { type } = JSON.parse(sourceDataJSON);

      const { name } = sourceTypes.find((sourceType: any) => {
        const { id } = sourceType;

        return id === type;
      });

      setFormatsString(name);
    }
  }, []);

  useLayoutEffect(() => {
    zoomPercent && setValue(zoomPercent);
    const { status } = SourceDataAvailability;
    const { data } = SourceDataTypes;
    if (data !== null && status) {
      findSourceDataType(data);
    }
  }, [SourceDataAvailability, SourceDataTypes, findSourceDataType, zoomPercent]);

  useEffect(() => {
    if (uploadFiles_acceptable !== null)
      seperateFileFormatsAndCombineIntoString(uploadFiles_acceptable);
  }, [uploadFiles_acceptable, seperateFileFormatsAndCombineIntoString]);

  const zoomIn = (event: any) => {
    const boardTransformationJSON = sessionStorage.getItem(
      "board_transformation"
    );
    // console.log(boardTransformationJSON)
    const boardElement = document.getElementById(
      "StrategyBoard"
    ) as HTMLDivElement;
    // console.log(boardElement)
    let newZoom = 1;
    if (boardElement && boardTransformationJSON) {
      const { scale, originX, originY, translateX, translateY } = JSON.parse(
        boardTransformationJSON
      );

      newZoom = scale < maxZoom ? scale + 0.1 : maxZoom;
      // console.log(scale)

      boardElement.style.transform = `matrix(${newZoom}, 0, 0, ${newZoom}, ${translateX}, ${translateY})`;
      boardElement.style.transformOrigin = `${originX}px ${originY}px`;

      sessionStorage.setItem(
        "board_transformation",
        JSON.stringify({
          scale: newZoom,
          originX,
          originY,
          translateX,
          translateY,
        })
      );

      dispatch(handleStrategyBoardZoom({ minZoom, maxZoom, zoom: newZoom }));
    } else if (boardElement) {
      const { offsetWidth, offsetHeight } = boardElement;
      // const translateX = -(offsetWidth / 100) * 30;
      // const translateY = -(offsetHeight / 100) * 33;
      const translateX = 0;
      const translateY = 0;
      // default 2 
      const originX = offsetWidth / 8;
      const originY = offsetHeight / 8;

      newZoom = zoom < maxZoom ? zoom + 0.1 : maxZoom;

      boardElement.style.transform = `matrix(${newZoom}, 0, 0, ${newZoom}, ${translateX}, ${translateY})`;
      boardElement.style.transformOrigin = `${originX}px ${originY}px`;

      sessionStorage.setItem(
        "board_transformation",
        JSON.stringify({
          scale: newZoom,
          originX,
          originY,
          translateX,
          translateY,
        })
      );
    }

    dispatch(handleStrategyBoardZoom({ minZoom, maxZoom, zoom: newZoom }));
    // console.log(newZoom);
  };

  const zoomOut = (event: any) => {
    const boardTransformationJSON = sessionStorage.getItem(
      "board_transformation"
    );
    const boardElement = document.getElementById(
      "StrategyBoard"
    ) as HTMLDivElement;
    let newZoom = 1;
    if (boardElement && boardTransformationJSON) {
      const { scale, originX, originY, translateX, translateY } = JSON.parse(
        boardTransformationJSON
      );

      newZoom =
        scale > minZoom && scale - minZoom > 0.1 ? scale - 0.1 : minZoom;

      boardElement.style.transform = `matrix(${newZoom}, 0, 0, ${newZoom}, ${translateX}, ${translateY})`;
      boardElement.style.transformOrigin = `${originX}px ${originY}px`;

      sessionStorage.setItem(
        "board_transformation",
        JSON.stringify({
          scale: newZoom,
          originX,
          originY,
          translateX,
          translateY,
        })
      );

      dispatch(handleStrategyBoardZoom({ minZoom, maxZoom, zoom: newZoom }));
    
    } else if (boardElement) {
      const { offsetWidth, offsetHeight } = boardElement;
      // const translateX = -(offsetWidth / 100) * 30;
      // const translateY = -(offsetHeight / 100) * 33;
      const translateX = 0;
      const translateY = 0;
      const originX = offsetWidth / 8;
      const originY = offsetHeight / 8;

      newZoom = zoom > minZoom && zoom - minZoom > 0.1 ? zoom - 0.1 : minZoom;

      boardElement.style.transform = `matrix(${newZoom}, 0, 0, ${newZoom}, ${translateX}, ${translateY})`;
      boardElement.style.transformOrigin = `${originX}px ${originY}px`;

      sessionStorage.setItem(
        "board_transformation",
        JSON.stringify({
          scale: newZoom,
          originX,
          originY,
          translateX,
          translateY,
        })
      );

      dispatch(handleStrategyBoardZoom({ minZoom, maxZoom, zoom: newZoom }));
    }
  };

  const openCreatInputPanel = () => {
    if(new_created_element){
      removeElement(new_created_element)
    }
    if (formatsString) {
      dispatch(
        handleStrategyBoardSidePanelContent({
          content_value: "input_data_create_update",
        })
      );
    }
  };

  const onInputChange = (e: any) => {
    let inputValue = e.target.value;
    setValue(inputValue > 300 ? 300 : inputValue);
  }

  const onInputKeyUp = (e: any) => {
    const input: HTMLInputElement | null = document.querySelector("#zoom_percent");
    const boardTransformationJSON = sessionStorage.getItem(
      "board_transformation"
    );
    let { originX, originY, translateX, translateY } = JSON.parse(
      boardTransformationJSON!
    );
    let newZoom = 1;

    if (e.keyCode === 13 && e.target.value >= 10) {
      input?.blur();
      setValue(e.target.value + "%");
      newZoom = e.target.value / 100;
      // console.log(newZoom);
      const boardElement = document.getElementById(
        "StrategyBoard"
      ) as HTMLDivElement;

      boardElement.style.transform = `matrix(${newZoom}, 0, 0, ${newZoom}, ${translateX}, ${translateY})`;
      boardElement.style.transformOrigin = `${originX}px ${originY}px`;

      sessionStorage.setItem(
        "board_transformation",
        JSON.stringify({
          scale: newZoom,
          originX,
          originY,
          translateX,
          translateY,
        })
      );
      dispatch(handleStrategyBoardZoom({ minZoom, maxZoom, zoom: newZoom }));
    }
    else if (e.keyCode === 13 && e.target.value < 10) {
      input?.blur();
      setValue("10%");
      newZoom = 0.1;

      sessionStorage.setItem(
        "board_transformation",
        JSON.stringify({
          scale: newZoom,
          originX,
          originY,
          translateX,
          translateY,
        })
      );
      dispatch(handleStrategyBoardZoom({ minZoom, maxZoom, zoom: newZoom }));
    }
  }

  const onInputKeyDown = (e: any) => {
    if (!(e.key.match(/[0-9]/)) && !(e.keyCode === 8) && !(e.keyCode === 37) && !(e.keyCode === 39)) {
      e.preventDefault();
    }
  }

  const onInputClick = (e: any) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace("%", "")
    setValue(inputValue);
  }

  return (
    <section className={classes.StrategyBoardFooterWrapper}>
      <div className={classes.StrategyBoardUndoRedoGroup}>
        <button className={classes.StrategyBoardUndo}>
          <img src={icons.undo} alt="Undo icon" />
        </button>
        <button className={classes.StrategyBoardRedo}>
          <img src={icons.redo} alt="Redo icon" />
        </button>
      </div>

      <div className={classes.StrategyBoardZoomGroup}>
        <button
          className={classes.StrategyBoardZoomOut}
          onClick={(event) => (zoomOut(event))}
        >
          <img src={icons.minus} alt="Minus icon" />
        </button>
        {/* <span id="zoom_percent" className={classes.StrategyBoardZoom}>{zoomPercent}</span> */}
        <input ref={zoomInput} onChange={(e) => onInputChange(e)} onKeyUp={(e) => onInputKeyUp(e)} onKeyDown={(e) => onInputKeyDown(e)} onClick={(e) => onInputClick(e)} id="zoom_percent" className={classes.StrategyBoardZoom} value={value} />
        <button
          className={classes.StrategyBoardZoomIn}
          onClick={(event) => (zoomIn(event))}
        >
          <img src={icons.plus} alt="Plus icon" />
        </button>
      </div>

      <div
        className={
          uploadFiles_acceptable !== null || formatsString
            ? classes.StrategyBoardInputType
            : classes.StrategyBoardInputTypeNull
        }
        onClick={openCreatInputPanel}
      >
        <img src={icons.input} alt="Input icon" />
        <span className={classes.StrategyBoardInputTypeLetter}>
          Input Data : {formatsString}
        </span>
      </div>

      <div className={classes.StrategyBoardInformation}>
        <img src={icons.information2} alt="Information icon" />
      </div>
    </section>
  );
};

export default StrategyBoardFooter;
