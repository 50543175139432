import React, { FC } from "react";
import classes from "./StrategyBoardSidePanel.module.scss";
import { icons, OutputMessages } from "../../../../settings/settings";
import { useDispatch, useSelector } from "react-redux";
import SidePanelTooltip from "./Tooltip/SidePaneTooltip";
import SidePanelInput from "./Input/SidePanelInput";
import {
  handlerStrategyBoardPanel,
  handlerStrategyBoardType,
  handlerStrategyElementInput,
  handlerStrategyElementOutput,
  handlerStrategyElementOutput2,
  handleStrategyBoardSidePanelCollapseStatus,
  handleStrategyBoardSidePanelContent,
  handleStrategyBoardSidePanelInputStage,
  handleStrategyElementTooltipStatus,
  handleStrategyNewCreatedElementId,
  resetStrategyBoardSidePanelInputUpload,
} from "../../../../store/actions/actionsStrategy";
import { RootState } from "../../../../store/combineReducer";
import InputCreate from "./Input/CreateInputObject/inputCreate";
import SidePanelFunction from "./Function/SidePanelFunction";
import SidePanelCondition from "./Condition/SidePanelCondition";
import SidePanelDataWizard from "./DataWizard/SidePanelDataWizard";
import CodeMessage from "../FlowChart/CodeMessage/CodeMessage";
import OutputFormat from "../FlowChart/OutputFormatPanel/Output";
import SidePanelMapping from "./Mapping/SidePanelMapping";
import Range from "./Decision/Range/Range";
import Matrix from "./Decision/Matrix/Matrix";
import SidePanelProduct from "./Product/SidePanelProduct";

const StrategyBoardSidePanel: FC = () => {
  const dispatch = useDispatch();

  const {
    reducerStrategy: {
      StrategyBoardSidePanel: {
        CollapseStatus: { collapseStatus_value },
        Content: { content_value },
      },
      StrategyBoardElements: {
        ElementInput: { element_input },
        NewCreatedElement:{new_created_element}
      },
      StrategyOutputFormatMessage: { message },      
    },
    reducerStrategy: {
      StrategyBoardElements: {
        StrategyMatrixActiveData: { matrix_data },
        }
      }    
  } = useSelector((state: RootState) => state);   

  const handlePanelCollapseStatus = () => {
    dispatch(
      handleStrategyBoardSidePanelCollapseStatus({
        collapseStatus_value: !collapseStatus_value,
      })
    );
  };

  const showListTooltip = (event: any) => {
    const tooltip = document.getElementById("side-panel-tooltip");
    const top =
      event.currentTarget?.offsetTop +
      (event.currentTarget?.getBoundingClientRect().height / 2 - 10);
    const fieldName = event.currentTarget?.lastChild.innerText;
    if (tooltip && collapseStatus_value) {
      tooltip.style.top = `${top}px`;
      tooltip.style.display = "flex";
      tooltip.children[0].innerHTML = fieldName;
    }
  };

  const hideToolTip = () => {
    const tooltip = document.getElementById("side-panel-tooltip");
    if (tooltip) {
      tooltip.style.display = "none";
    }
  };

  const changeContent = (content: string) => {
    if(new_created_element || element_input) return;
    if (message !== OutputMessages.EMPTY) {
      return;
    }

    hideToolTip();
    dispatch(handleStrategyNewCreatedElementId({ new_created_element: null }));
    dispatch(handlerStrategyBoardPanel({ element_panel: false }));
    dispatch(handlerStrategyElementInput({ element_input: null }));
    dispatch(handlerStrategyElementOutput({ element_output: null }));
    dispatch(handlerStrategyElementOutput2({ element_output2: null }));
    if (element_input) {
      dispatch(handleStrategyElementTooltipStatus({ status: "edit" }));
    } else {
      dispatch(handleStrategyElementTooltipStatus({ status: "side_panel" }));
    }

    if (!content_value) {
      dispatch(handleStrategyBoardSidePanelInputStage({ stage_value: null }));
      dispatch(handlerStrategyBoardType({ element_type: content }));
      dispatch(handleStrategyBoardSidePanelContent({ content_value: content }));
      return;
    }

    dispatch(handleStrategyBoardSidePanelContent({ content_value: null }));
    dispatch(handlerStrategyBoardType({ element_type: null }));
    dispatch(resetStrategyBoardSidePanelInputUpload());

    if (content_value !== "input_data" && content_value !== "decision") {
      setTimeout(() => {
        dispatch(handlerStrategyBoardType({ element_type: content }));
        dispatch(
          handleStrategyBoardSidePanelContent({ content_value: content })
        );
      }, 1000);
    }
  };

 const displayDecisionPanel = (data: any) => {
  if(matrix_data) {
    dispatch(
      handleStrategyBoardSidePanelContent({
        content_value: 'matrix',
      })
    );
  } else {
    dispatch(
      handleStrategyBoardSidePanelContent({
        content_value: 'range',
      })
    );
  }
  return null;
}
  return (
    <div className={classes.StrategyBoardSidePanel}>
      <div
        className={
          collapseStatus_value
            ? [classes.SidePanelWrapper, classes.Collapsed].join(" ")
            : [classes.SidePanelWrapper, classes.Extended].join(" ")
        }
      >
        <ul className={classes.SidePanelMenuList}>
          <li
            onClick={() => changeContent("input_data")}
            onMouseEnter={
              content_value !== "input_data"
                ? (event) => showListTooltip(event)
                : () => {}
            }
            onMouseLeave={() => hideToolTip()}
          >
            <img src={icons.sidePanelInput} alt="Side panel input icon" />
            <span className={collapseStatus_value ? classes.Hide : ""}>
              Input data
            </span>
          </li>
          <li
            onMouseEnter={(event) => showListTooltip(event)}
            onMouseLeave={() => hideToolTip()}
            onClick={() => changeContent("data_wizard")}
          >
            <img
              src={icons.sidePanelCheckBox}
              alt="Side panel check-box icon"
            />
            <span className={collapseStatus_value ? classes.Hide : ""}>
              Data Wizard
            </span>
          </li>
          <li
            onMouseEnter={(event) => showListTooltip(event)}
            onMouseLeave={() => hideToolTip()}
            onClick={() => changeContent("function")}
          >
            <img src={icons.sidePanelFunction} alt="Side panel function icon" />
            <span className={collapseStatus_value ? classes.Hide : ""}>
              Function
            </span>
          </li>
          <li
            onMouseEnter={(event) => showListTooltip(event)}
            onMouseLeave={() => hideToolTip()}
            onClick={() => changeContent("condition")}
          >
            <img src={icons.sidePanelArrows} alt="Side panel arrows icon" />
            <span className={collapseStatus_value ? classes.Hide : ""}>
              Condition
            </span>
          </li>
          <li
            onMouseEnter={(event) => showListTooltip(event)}
            onMouseLeave={() => hideToolTip()}
            onClick={() => {
              changeContent("scorecard");
            }}
          >
            <img src={icons.sidePanelMapping} alt="Side panel mapping icon" />
            <span className={collapseStatus_value ? classes.Hide : ""}>
              Score Card
            </span>
          </li>
          <li
            onMouseEnter={
              content_value !== "decision"
                ? (event) => showListTooltip(event)
                : () => {}
            }
            onMouseLeave={() => hideToolTip()}
            onClick={() => changeContent("decision")}
          >
            <img src={icons.sidePanelVector} alt="Side panel vector icon" />
            <span className={collapseStatus_value ? classes.Hide : ""}>
              Decision
            </span>
          </li>
          <li
            onMouseEnter={
              content_value !== "product"
                ? (event) => showListTooltip(event)
                : () => {}
            }
            onMouseLeave={hideToolTip}
            onClick={() => changeContent("product")}
          >
            <img src={icons.sidePanelProduct} alt="Side panel product icon" />
            <span className={collapseStatus_value ? classes.Hide : ""}>
              Product
            </span>
          </li>
          <li
            onMouseEnter={(event) => showListTooltip(event)}
            onMouseLeave={() => hideToolTip()}
            onClick={() => changeContent("code_and_message")}
          >
            <img src={icons.sidePanelMessage} alt="Side panel message icon" />
            <span className={collapseStatus_value ? classes.Hide : ""}>
              Code and Message
            </span>
          </li>
        </ul>
        <hr className={classes.SidePanelSeparate} />
        <button
          className={classes.SidePanelCollapseButton}
          onClick={handlePanelCollapseStatus}
        >
          <img
            src={
              collapseStatus_value
                ? icons.sidePanelExtend
                : icons.sidePanelCollapse
            }
            alt="Side panel collapse icon"
          />
        </button>
       
        <SidePanelTooltip />
        {content_value === "input_data" && <SidePanelInput />}
        {(content_value === "input_data_create_new" ||
          content_value === "input_data_create_update") && <InputCreate />}
        {content_value === "data_wizard" && <SidePanelDataWizard />}
        {content_value === "function" && <SidePanelFunction />}
        {content_value === "condition" && <SidePanelCondition />}
        {content_value === "scorecard" && <SidePanelMapping />}
        {/* {content_value === "decision" && <SidePanelDecision />} */}
        {content_value === "decision" ? displayDecisionPanel(matrix_data) : <></>}
        {content_value === "product" && <SidePanelProduct />}
        {content_value === "range" && <Range />}
        {content_value === "matrix" && <Matrix />}
        {content_value === "code_message" && <CodeMessage />}
        {content_value === "output_format" && <OutputFormat />}
        {/* <OutputFormatPanel/> */}
        {/* <CodeMessage/> */}
      </div>
    </div>
  );
};

export default StrategyBoardSidePanel;
