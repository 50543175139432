import { Product } from "../types/types";
import {
  ADD_PRODUCT,
  DELETE_PRODUCT,
  GET_ALL_PRODUCTS,
  ProductActionsTypes,
  ProductState,
} from "../types/typesProduct";

const initialState = {
  products: [] as Product[],
};

export const reducerProduct = (
  state = initialState,
  action: ProductActionsTypes
  ): ProductState => {
    
    switch (action.type) {
      case GET_ALL_PRODUCTS:
        const { productData } = action.payload;
      return {
        ...state,
        products: productData,
      };
      case ADD_PRODUCT: 
      return {
        ...state, 
        products: [...state.products, action.payload]
      };
      case DELETE_PRODUCT: 
      const filteredArray = [...state.products].filter((
        (elem: any) => elem.id !== action.payload
        ));
        return {
          ...state,
          products: [...filteredArray]
        };
    default:
      return state;
  }
};
