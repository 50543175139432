import { endpoints } from "api/endpoints";
import DB from "layout/DB/Storage";
import { ApiDebugModes, messages, storageKeys } from "settings/settings";
import { checkElementConnections } from "./checkElementConnections";
import { request } from "./request";

export const testSrategy = (
  setInput: (_data: any) => void,
  setTime: (_data: number) => void,
  requestBody: any
) => {
  const db = new DB(storageKeys.boardElements);
  const {
    strategy_designer: { strategy },
  } = endpoints;
  function generateStrategyEngineData() {
    const { source_data } = requestBody;
    if (!source_data) return {};
    return {
      source: {
        ...source_data
      }
    };
  }

  function runEngine(data: object) {
    const strategyDetails = sessionStorage.getItem(storageKeys.activeStrategy);
    if (strategyDetails) {
      const { id } = JSON.parse(strategyDetails);
      const sendData = { ...data } as any;
      let date = new Date();
      let start_time = date.getTime();

      request
        .patch(strategy.test(id), sendData, null, ApiDebugModes.OUTPUT)
        .then((res) => {
          const { success, response } = res;

          if (success) {
            setInput(response);
            setTime(Math.abs(new Date().getTime() - start_time));
          } else {
            console.warn(messages.messageApiPatchStatusFalse, response);
          }
        })
        .catch((error) => {
          console.error(messages.messageApiPatchError, error);
        });
    }
  }

  db.fetchAll()
    .then((res: any) => {
      const { status, data } = res;

      if (status) {
        checkElementConnections(data)
          .then(() => {
            runEngine(generateStrategyEngineData()) as any;
          })
          .catch(() => {
            console.warn(messages.messageUncompletedElementConnection);
          });
      } else {
        console.warn(messages.messageStorageFetchStatusFalse, res);
      }
    })
    .catch((error) => {
      console.error(messages.messageStorageFetchError, error);
    });
};

export const runOutput = (setOutputStatus: (_data: any) => void): any => {
  const db = new DB(storageKeys.boardElements);
  const {
    strategy_designer: { strategy },
  } = endpoints;
  function manipulateData(strategyData: Array<object>) {
    const elementId = "output";

    function filterElements(element: any) {
      return element.sourceId !== elementId;
    }

    const copyOfDataWithoutSpecElement = [...strategyData]
      ?.filter(filterElements)
      ?.map((element: any) => {
        if (element.breakPoint) {
          element.breakPoint = "False";
          return element;
        } else {
          return element;
        }
      });

    let specificElement: any = [...strategyData]?.find((element: any) => {
      return element.sourceId === elementId;
    });

    specificElement.breakPoint = "True";

    copyOfDataWithoutSpecElement.push({ ...specificElement });

    return [...copyOfDataWithoutSpecElement];
  }

  function generateStrategyEngineData(strategyData: Array<object>) {
    const strategyDetails = sessionStorage.getItem(storageKeys.activeStrategy);
    const userDetails = sessionStorage.getItem(storageKeys.userDetails);
    let strategy: any = {};
    let user: any = {};
    if (strategyDetails && userDetails) {
      strategy = JSON.parse(strategyDetails);
      user = JSON.parse(userDetails);
    }

    return {
      user: user?.user,
      name: strategy?.name,
      version: strategy?.version,
      status: 1,
      strategy_data: manipulateData(strategyData),
    };
  }

  function runEngine(data: object) {
    const strategyDetails = sessionStorage.getItem(storageKeys.activeStrategy);
    if (strategyDetails) {
      const { id } = JSON.parse(strategyDetails);
      let { strategy_data } = data as any;
      let filterData = strategy_data?.filter(
        (f: any) => f.type === "product"
      ) as Array<any>;
      let filterCodeMessageData = strategy_data?.filter(
        (f: any) => f.type === "code_message_list"
      ) as Array<any>;
      let fData = filterData?.map((m: any) => {
        const {
          data: { mappData },
        } = m;
        let newMappData = mappData?.map(
          ({ decision_label, product_group }: any) => {
            return {
              decision_label: decision_label,
              product_group: product_group,
            };
          }
        );
        return { ...m, data: { ...m.data, mappData: newMappData } };
      });
      let fCodeMessageData = filterCodeMessageData?.map((m: any) => {
        const {
          data: { code_message_list },
        } = m;
        let newMappData = code_message_list?.map(({ code, message }: any) => {
          return { code: code, message: message };
        });
        return { ...m, data: { ...m.data, code_message_list: newMappData } };
      });
      let newData = strategy_data?.filter(
        (f: any) => f.type !== "product"
      ) as Array<any>;
      for (const item of fData) {
        newData.push(item);
      }
      let newData2 = newData?.filter(
        (f: any) => f.type !== "code_message_list"
      ) as Array<any>;
      for (const item of fCodeMessageData) {
        newData2.push(item);
      }
      const sendData = { ...data, strategy_data: newData2 };
      request
        .patch(strategy.patch(id), sendData, null, ApiDebugModes.OUTPUT)
        .then((res) => {
          const { success, response } = res;

          if (success) {
            if (Object.keys(response).length === 0) {
              setOutputStatus(false);
            } else {
              setOutputStatus(true);
            }
          } else {
            setOutputStatus(false);

            console.warn(messages.messageApiPatchStatusFalse, response);
          }
        })
        .catch((error) => {
          setOutputStatus(false);

          console.error(messages.messageApiPatchError, error);
        });
    }
  }

  db.fetchAll()
    .then((res: any) => {
      const { status, data } = res;

      if (status) {
        checkElementConnections(data)
          .then(() => {
            runEngine(generateStrategyEngineData([...data]));
          })
          .catch(() => {
            console.warn(messages.messageUncompletedElementConnection);
          });
      } else {
        console.warn(messages.messageStorageFetchStatusFalse, res);
      }
    })
    .catch((error) => {
      console.error(messages.messageStorageFetchError, error);
    });
};
