import { StrategyActionsTypes, StrategyBoardValidateStatus, STRATEGY_BOARD_VALIDATE_STATUS } from "../../../../types/typesStrategy";


const initialState: StrategyBoardValidateStatus = {
    status:false
}

const reducer = (state = initialState, action: StrategyActionsTypes): StrategyBoardValidateStatus => {

    switch (action.type) {

        case STRATEGY_BOARD_VALIDATE_STATUS:
            const {status} = action.payload;
            
            return {
                status
            }

        default:
            return state

    }
}

export default reducer