import { FormButton } from "components/UI/Form/Button/Button";
import { FormInput } from "components/UI/Form/Input/Input";
import {  CloseIcon, PenIcon } from "components/UI/Svg/Svg";
import {
  ChangeEvent,
  FC,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Avatar,
  AvatarContainer,
  AvatarEdit,
  AvatarFileInput,
  AvatarImg,
  CloseButton,
  DroppedArea,
  DroppedContainer,
  DroppedContent,
  EditSubMenu,
  Form,
  InputGroup,
  Paragraph,
  SubMeniList,
  TextContainer,
} from "../UserProfile.style";
import Resizer from "react-image-file-resizer";
import { userAvatar } from "assets";
import { IUserDetails } from "../config";
import { request } from "helpers/request";
import { endpoints } from "api/endpoints";

interface IEditProfile {
  propsUserDetails: IUserDetails;
  actionStatus: (status: boolean) => void;
}
export const EditProfile: FC<IEditProfile> = ({
  propsUserDetails,
  actionStatus,
}) => {
  const [stateUserDetails, setStateUserDetails] = useState<IUserDetails>({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    zip: "",
    address: "",
    city: "",
    country: "",
    state: "",
    email: "",
    profile_image: null,
    password: "",
    user: "",
  });

  const [stateImagePreview, setStateImagePreview] = useState<any>(null);
  const [stateDropZoneStatus, setStateDropZoneStatus] =
    useState<boolean>(false);

  useEffect(() => {
    if (propsUserDetails) {
      setStateUserDetails({ ...propsUserDetails });
      const { profile_image } = propsUserDetails;
      setStateImagePreview(profile_image);
    }
  }, [propsUserDetails]);
  const refFileUploader = useRef<HTMLInputElement>(null);
  const refForm = useRef<HTMLFormElement>(null);
  const resizeFile = (file: any) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });
  const handleFileUploadChange = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    let files = event.target.files as any;
    const image = await resizeFile(files[0]) as any;
    if (!image) return;
    setStateImagePreview(image);
    setStateDropZoneStatus(false);
    setStateUserDetails({
      ...stateUserDetails,
      profile_image:image
    })
  };

  const openDropPanel = () => {
    setStateDropZoneStatus(!stateDropZoneStatus);
  };

  const handleSubmit = () => {
    request.patch(endpoints.profile.patch, stateUserDetails).then((res) => {
      if (res) {
        const sessionUserDetails: string | null =
          sessionStorage.getItem("user_details");
        const userData = JSON.parse(sessionUserDetails ?? "") as IUserDetails;
        if (!userData) return;
        const newUserData = {
          ...userData,
          ...res,
          profile_image: stateImagePreview ?? null,
        };
        sessionStorage.setItem("user_details", JSON.stringify(newUserData));
        actionStatus(true);
      }
    });
  };
  const handleDeleteImage = () => {
   
    const sendData = {
      user:stateUserDetails.user,
      profile_image:null
    }
    request.patch(endpoints.profile.patch, sendData).then((res) => {
      if (res) {
        const sessionUserDetails: string | null = sessionStorage.getItem("user_details");
        const userData = JSON.parse(sessionUserDetails ?? "") as IUserDetails;
        if (!userData) return;
        const newUserData = {
          ...userData,
          ...res,
        };
        sessionStorage.setItem("user_details", JSON.stringify(newUserData));
        actionStatus(true);
      }
    });
    setStateImagePreview(null);
    setStateDropZoneStatus(false);
  };
  const handleOpenFilePanel = (e: MouseEvent) => {
    const input = refFileUploader.current as HTMLInputElement;
    if (!input) return;
    input.click();
  };

  const handleEnter = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.style.borderColor = "rgba(44, 124, 246, 0.6)";
  };

  const handleLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.style.borderColor = "#10294659";
  };

  const handleUpload = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.style.borderColor = "#10294659";
    const [file] = e.target.files || e.dataTransfer.files;
    uploadFile(file);
  };

  async function uploadFile(file: any) {
    const image = await resizeFile(file) as any;
    if (!image) return;
    setStateImagePreview(image);
    setStateUserDetails({
      ...stateUserDetails,
      profile_image:image
    })
  }

  function formatPhone(value: string) {
    let numbers = value?.replace(/\D/g, "");
    if (numbers?.length > 12) {
      numbers = numbers.slice(0, -1);
    }
    const char = { 0: "(", 3: ") ", 5: "-", 8: "-" } as any;
    let newValue = "";
    for (var i = 0; i < numbers?.length; i++) {
      newValue += (char[i] || "") + numbers[i];
    }
    return newValue;
  }
  const handleFillForm = (e: ChangeEvent<HTMLInputElement>, key: string) => {
    const { value } = e.target;
    if (key === "phoneNumber" && value) {
      e.target.value = value.replace(/\D/g, "");
      if (value.length >= 12) {
        e.target.value = e.target.value.slice(0, -1);
      }
      e.target.value = formatPhone(value);
    }
    setStateUserDetails((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };
  const handleCancel = () => {
    const sessionUserDetails: string | null =
      sessionStorage.getItem("user_details");
    const userData = JSON.parse(sessionUserDetails ?? "") as IUserDetails;
    if (!userData) return;
    setStateUserDetails({ ...userData });
    refForm?.current?.reset();
  };


  return (
    <>
      <Avatar>
        <AvatarContainer>
          {stateImagePreview && <AvatarImg src={stateImagePreview} alt="user" />}
          {!stateImagePreview && <AvatarImg src={userAvatar} alt="user" />}
          <AvatarEdit onClick={openDropPanel}>
            <PenIcon />
          </AvatarEdit>
        </AvatarContainer>
        {stateDropZoneStatus && (
          <EditSubMenu>
            <SubMeniList onClick={handleOpenFilePanel}>
              Upload a photo
            </SubMeniList>
            {stateImagePreview && (
              <SubMeniList onClick={handleDeleteImage}>
                Delete photo
              </SubMeniList>
            )}
          </EditSubMenu>
        )}
      </Avatar>
      <Form ref={refForm} onSubmit={(e) => e.preventDefault()}>
        {stateDropZoneStatus && (
          <DroppedContainer>
            <CloseButton onClick={openDropPanel}>
              <CloseIcon />
            </CloseButton>

            <DroppedArea>
              <DroppedContent
                onDragEnter={(e) => handleEnter(e)}
                onDragLeave={(e) => handleLeave(e)}
                onDragOver={(e) => handleOver(e)}
                onDrop={handleUpload}
                onClick={handleOpenFilePanel}
              >
                <TextContainer>
                  <Paragraph>Click to browse or</Paragraph>
                  <Paragraph>drag and drop your files</Paragraph>
                </TextContainer>
              </DroppedContent>
            </DroppedArea>
          </DroppedContainer>
        )}
        <AvatarFileInput
          type="file"
          ref={refFileUploader}
          onChange={handleFileUploadChange}
          onClick={(e) => e.stopPropagation()}
        />
        <InputGroup>
          <FormInput
            label="First Name"
            value={stateUserDetails.firstName}
            onChange={(e) => handleFillForm(e, "firstName")}
          />
          <FormInput
            label="Last Name"
            value={stateUserDetails.lastName}
            onChange={(e) => handleFillForm(e, "lastName")}
          />
        </InputGroup>
        <FormInput label="E-mail" disable value={stateUserDetails.email} />
        <FormInput
          label="Phone Number"
          value={formatPhone(stateUserDetails.phoneNumber)}
          onChange={(e) => handleFillForm(e, "phoneNumber")}
        />
        <FormInput
          label="Address"
          value={stateUserDetails.address}
          onChange={(e) => handleFillForm(e, "address")}
        />
        <InputGroup>
          <FormInput
            label="City"
            value={stateUserDetails.city}
            onChange={(e) => handleFillForm(e, "city")}
          />
          <FormInput
            label="State"
            value={stateUserDetails.state}
            onChange={(e) => handleFillForm(e, "state")}
          />
        </InputGroup>
        <InputGroup>
          <FormInput
            label="Zip code"
            value={stateUserDetails.zip}
            onChange={(e) => handleFillForm(e, "zip")}
          />
          <FormInput
            label="Country"
            value={stateUserDetails.country}
            onChange={(e) => handleFillForm(e, "country")}
          />
        </InputGroup>
        <InputGroup>
          <FormButton label="Save" action="save" onCLick={handleSubmit} />
          <FormButton label="Cancel" action="cancel" onCLick={handleCancel} />
        </InputGroup>
      </Form>
    </>
  );
};
