export const GET_ALL_PRODUCTS = "PRODUCTS/GET_ALL_PRODUCTS";
export const  DELETE_PRODUCT = "DELETE_PRODUCT";
export const  ADD_PRODUCT = "ADD_PRODUCT";

export type ProductActionsTypes = getAllDecisions;

interface getAllDecisions {
  type: any;
  payload: any;
}


export interface ProductState {
  products: any[];
}


export interface setProducts {
  type: typeof DELETE_PRODUCT,
  payload: any;
}


export interface addProduct {
  type: typeof  ADD_PRODUCT,
  payload: any;
}
