import { StrategyActionsTypes, StrategyMatrixActiveData, STRATEGY_MARTIX_ACTIVE_DATA } from "../../../../types/typesStrategy";


const initialState: StrategyMatrixActiveData = {
    matrix_data:null
}

const reducer = (state = initialState, action: StrategyActionsTypes): StrategyMatrixActiveData => {
    switch (action.type) {

        case STRATEGY_MARTIX_ACTIVE_DATA:
            const { matrix_data } = action.payload
            const [data] = matrix_data;
            return {
                matrix_data:data
            };

        default:
            return state
    }
}

export default reducer