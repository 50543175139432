import { Massive } from "../contact_chaining/types";
import { getArrowPropery, getContactId } from "../get_arrow_properties.handler"
import { getAttr } from "../methods/attributes";
import { arrow_bottom } from "../contact_chaining/nearby_side/bottom/bottom";
import { arrow_bottom_left } from "../contact_chaining/nearby_side/bottom/bottome_left";
import { arrow_bottom_left_top } from "../contact_chaining/nearby_side/bottom/bottom_left_top";
import { arrow_bottom_right } from "../contact_chaining/nearby_side/bottom/bottom_right";
import { arrow_bottom_right_top } from "../contact_chaining/nearby_side/bottom/bottom_right_top";
import { arrow_left } from "../contact_chaining/nearby_side/left/left";
import { arrow_left_bottom_right } from "../contact_chaining/nearby_side/left/left_bottom_right";
// import { arrow_left_top_right } from "../contact_chaining/nearby_side/left/left_top_right";
import { positions } from "../contact_chaining/nearby_side/positions";
import { arrow_right } from "../contact_chaining/nearby_side/right/right";
// import { arrow_right_bottom } from "../contact_chaining/nearby_side/right/right_bottom";
import { arrow_right_bottom_left } from "../contact_chaining/nearby_side/right/right_bottom_left";
// import { arrow_right_top } from "../contact_chaining/nearby_side/right/right_top";
// import { arrow_right_top_left } from "../contact_chaining/nearby_side/right/right_top_left";
import { arrow_top } from "../contact_chaining/nearby_side/top/top";
import { arrow_top_left } from "../contact_chaining/nearby_side/top/top_left";
import { arrow_top_left_bottom } from "../contact_chaining/nearby_side/top/top_left_bottom";
import { arrow_top_right } from "../contact_chaining/nearby_side/top/top_right";
import { arrow_top_right_bottom } from "../contact_chaining/nearby_side/top/top_right_bottom";
type TypeCoord={
    x:number;
    y:number;
}
export const drag_arrow_destination = (coord:TypeCoord,arrow:SVGPathElement) =>{
    let xmlns = "http://www.w3.org/2000/svg";
    const svgBoard = document.querySelector(`#svg_board`) as SVGSVGElement;
    const border: number = 82;
    const {contact} = getArrowPropery(arrow);
    const {prev} = getContactId(contact);
    const {x,y} = coord;
    const prevElement = svgBoard.querySelector(`rect[data-id = "${prev}"]`) as SVGRectElement;
    const prev_element: Massive = { element: prevElement };
    const newElement = document.createElementNS(xmlns, "rect") as SVGRectElement;
    newElement.setAttribute('x',`${x}`);
    newElement.setAttribute('y',`${y}`);
    newElement.setAttribute('height',`16`);
    newElement.setAttribute('width',`16`);
    newElement.setAttribute('data-id',`shadow`);

    const self: Massive = { element: newElement };
    const {
        x: self_x,
        y: self_y,
        width: self_width,
        height: self_height
    } = getAttr(newElement);
    const {
        x: prev_x,
        y: prev_y,
        width: prev_width,
        height: prev_height
    } = getAttr(prevElement);
    const {
        first: {
            top: first_top,
            right: first_right,
            bottom: first_bottom,
            left: first_left
        },
        last: {
            top: last_top,
            right: last_right,
            bottom: last_bottom,
            left: last_left
        }
    } = positions(prev_element, self);

    if (self_x && self_y && prev_x && prev_y && self_width && self_height && prev_width && prev_height) {
        if (prev_y < self_y) {
            if (prev_x < self_x) {

                if (prev_x + prev_width <= self_x) {
                    if (prev_y + prev_height / 2 < self_y) {
                        if (prev_y + prev_height <= self_y) {
                            if (+(self_x - (prev_x + prev_width)).toFixed(0) <= border) {

                                if (first_bottom && last_left) {
                                    arrow_bottom_right<Massive>(prev_element, self, arrow);
                                } else if (first_bottom && last_bottom) {
                                    arrow_bottom_right_top<Massive>(prev_element, self, arrow);
                                } else if (first_top && last_top) {
                                    arrow_top_right_bottom<Massive>(prev_element, self, arrow);

                                }
                            } else {
                                if (first_right && last_left) {
                                    arrow_right<Massive>(prev_element, self, arrow);
                                } else if (first_bottom && last_left) {
                                    arrow_bottom_right<Massive>(prev_element, self, arrow);
                                } else if (first_bottom && last_bottom) {
                                    arrow_bottom_right_top<Massive>(prev_element, self, arrow);
                                } else if (first_top && last_top) {
                                    arrow_top_right_bottom<Massive>(prev_element, self, arrow);

                                }
                            }
                        } else {
                            if (+(self_x - (prev_x + prev_width)).toFixed(0) <= border) {
                                if (first_bottom && last_bottom) {
                                    arrow_bottom_right_top<Massive>(prev_element, self, arrow);
                                } else if (first_top && last_top) {
                                    arrow_top_right_bottom<Massive>(prev_element, self, arrow);

                                }
                            } else {
                                if (first_right && last_left) {
                                    arrow_right<Massive>(prev_element, self, arrow);
                                } else if (first_bottom && last_left) {
                                    arrow_bottom_right<Massive>(prev_element, self, arrow);
                                } else if (first_bottom && last_bottom) {
                                    arrow_bottom_right_top<Massive>(prev_element, self, arrow);
                                } else if (first_top && last_top) {
                                    arrow_top_right_bottom<Massive>(prev_element, self, arrow);

                                }

                            }
                        }
                    } else {
                        if (+(self_x - (prev_x + prev_width)).toFixed(0) <= border) {

                            if (first_top && last_top) {
                                arrow_top_right_bottom<Massive>(prev_element, self, arrow);

                            } else if (first_bottom && last_bottom) {
                                arrow_bottom_right_top<Massive>(prev_element, self, arrow);
                            }
                        } else {
                            if (first_right && last_left) {
                                arrow_right<Massive>(prev_element, self, arrow);
                            } else if (first_top && last_top) {
                                arrow_top_right_bottom<Massive>(prev_element, self, arrow);
                            } else if (first_bottom && last_bottom) {
                                arrow_bottom_right_top<Massive>(prev_element, self, arrow);
                            }
                        }
                    }
                } else {
                    if (prev_y + prev_height <= self_y) {
                        if (self_y - (prev_y + prev_height) <= border) {
                            if (first_right && last_right) {
                                arrow_right_bottom_left<Massive>(prev_element, self, arrow);
                            }else if(first_bottom && last_bottom){
                                arrow_bottom_right_top<Massive>(prev_element, self, arrow);
                            }else if(first_top && last_top){
                                arrow_top_right_bottom<Massive>(prev_element, self, arrow);//03
                            }
                            
                        } else {
                            if (first_bottom && last_top) {
                                arrow_bottom<Massive>(prev_element, self, arrow);
                            }else if(first_top && last_top){
                                arrow_top_right_bottom<Massive>(prev_element, self, arrow);//05

                            }
                        }
                    }

                }
            } else {
                
                if (self_x + self_width <= prev_x) {
                    
                    if (prev_y + prev_height / 2 < self_y) {
                        if (prev_y + prev_height <= self_y) {
                            if (+(prev_x - (self_x + self_width)).toFixed(0) <= border) {
                                if (first_bottom && last_right) {
                                    arrow_bottom_left<Massive>(prev_element, self, arrow);
                                } else if (first_bottom && last_bottom) {
                                    arrow_bottom_left_top<Massive>(prev_element, self, arrow);
                                } else if (first_top && last_top) {
                                    arrow_top_left_bottom<Massive>(prev_element, self, arrow);
                                }
                            } else {
                                if (first_left && last_right) {
                                    arrow_left<Massive>(prev_element, self, arrow);
                                } else if (first_bottom && last_right) {
                                    arrow_bottom_left<Massive>(prev_element, self, arrow);
                                } else if (first_bottom && last_bottom) {
                                    arrow_bottom_left_top<Massive>(prev_element, self, arrow);
                                } else if (first_top && last_top) {
                                    arrow_top_left_bottom<Massive>(prev_element, self, arrow);
                                }
                            }
                        } else {
                            if (+(prev_x - (self_x + self_width)).toFixed(0) <= border) {
                                if (first_bottom && last_bottom) {
                                    arrow_bottom_left_top<Massive>(prev_element, self, arrow);
                                } else if (first_top && last_top) {
                                    arrow_top_left_bottom<Massive>(prev_element, self, arrow);
                                }
                            } else {
                                if (first_left && last_right) {
                                    arrow_left<Massive>(prev_element, self, arrow);
                                } else if (first_bottom && last_bottom) {
                                    arrow_bottom_left_top<Massive>(prev_element, self, arrow);
                                } else if (first_bottom && last_right) {
                                    arrow_bottom_left<Massive>(prev_element, self, arrow);
                                } else if (first_top && last_top) {
                                    arrow_top_left_bottom<Massive>(prev_element, self, arrow);
                                }

                            }
                        }
                    } else {
                        if (+(prev_x - (self_x + self_width)).toFixed(0) <= border) {

                            if (first_top && last_top) {
                                arrow_top_left_bottom<Massive>(prev_element, self, arrow);
                            } else if (first_bottom && last_bottom) {
                                arrow_bottom_left_top<Massive>(prev_element, self, arrow);
                            }
                        } else {
                            if (first_left && last_right) {
                                arrow_left<Massive>(prev_element, self, arrow);
                            } else if (first_top && last_top) {
                                arrow_top_left_bottom<Massive>(prev_element, self, arrow);
                            } else if (first_bottom && last_bottom) {
                                arrow_bottom_left_top<Massive>(prev_element, self, arrow);
                            }
                        }
                    }
                } else {

                    if (prev_y + prev_height <= self_y) {
                        if (self_y - (prev_y + prev_height) <= border) {
                            if (first_left && last_left) {
                                arrow_left_bottom_right<Massive>(prev_element, self, arrow);
                            } else if (first_right && last_right) {
                                arrow_right_bottom_left<Massive>(prev_element, self, arrow);
                            }else if(first_bottom && last_bottom){
                                arrow_bottom_left_top<Massive>(prev_element, self, arrow);//01
                            }else if(first_top && last_top){
                                arrow_top_left_bottom<Massive>(prev_element, self, arrow);//04
                            }
                            
                           
                        } else {
                            if (first_bottom && last_top) {
                                arrow_bottom<Massive>(prev_element, self, arrow);
                            }
                        }
                    }
                }
            }
        } else {
            if (prev_x < self_x) {
                if (prev_x + prev_width <= self_x) {

                    if (self_y + self_height <= prev_y) {
                        
                        if (+(self_x - (prev_x + prev_width)).toFixed(0) <= border) {
                            if (first_top && last_left) {
                                arrow_top_right<Massive>(prev_element, self, arrow);
                            } else if (first_top && last_top) {
                                arrow_top_right_bottom<Massive>(prev_element, self, arrow);

                            } else if (first_bottom && last_bottom) {
                                arrow_bottom_right_top<Massive>(prev_element, self, arrow);
                            }
                        } else {
                            if (first_right && last_left) {
                                arrow_right<Massive>(prev_element, self, arrow);
                            } else if (first_top && last_left) {
                                arrow_top_right<Massive>(prev_element, self, arrow);
                            } else if (first_top && last_top) {
                                arrow_top_right_bottom<Massive>(prev_element, self, arrow);

                            } else if (first_bottom && last_bottom) {
                                arrow_bottom_right_top<Massive>(prev_element, self, arrow);

                            }
                        }
                    } else {
                        if (+(self_x - (prev_x + prev_width)).toFixed(0) <= border) {
                            if (first_top && last_top) {
                                arrow_top_right_bottom<Massive>(prev_element, self, arrow);

                            } else if (first_bottom && last_bottom) {
                                arrow_bottom_right_top<Massive>(prev_element, self, arrow);
                            }
                        } else {
                            if (first_right && last_left) {
                                arrow_right<Massive>(prev_element, self, arrow);
                            } else if (first_top && last_left) {
                                arrow_top_right<Massive>(prev_element, self, arrow);
                            } else if (first_top && last_top) {
                                arrow_top_right_bottom<Massive>(prev_element, self, arrow);

                            } else if (first_bottom && last_bottom) {
                                arrow_bottom_right_top<Massive>(prev_element, self, arrow);

                            }

                        }
                    }
                } else {
                    
                    if (self_y + self_height <= prev_y) {
                        
                        if (prev_y - (self_y + self_height) <= border) {
                            if (first_left && last_left) {
                                arrow_left_bottom_right<Massive>(prev_element, self, arrow);
                            } else if (first_right && last_right) {
                                arrow_right_bottom_left<Massive>(prev_element, self, arrow);
                            }else if(first_top && last_top){
                                arrow_top_right_bottom<Massive>(prev_element, self, arrow);
                            }
                        } else {
                            if (first_top && last_bottom) {
                                arrow_top<Massive>(prev_element, self, arrow);
                            }
                        }
                    }
                }
            } else {
                if (self_x + self_width <= prev_x) {

                    if (self_y + self_height <= prev_y) {
                        if (+(prev_x - (self_x + self_width)).toFixed(0) <= border) {
                            if (first_top && last_right) {
                                arrow_top_left<Massive>(prev_element, self, arrow);
                            } else if (first_top && last_top) {
                                arrow_top_left_bottom<Massive>(prev_element, self, arrow);

                            } else if (first_bottom && last_bottom) {
                                arrow_bottom_left_top<Massive>(prev_element, self, arrow);
                            }
                        } else {
                            if (first_left && last_right) {
                                arrow_left<Massive>(prev_element, self, arrow);
                            } else if (first_top && last_right) {
                                arrow_top_left<Massive>(prev_element, self, arrow);
                            } else if (first_top && last_top) {
                                arrow_top_left_bottom<Massive>(prev_element, self, arrow);

                            } else if (first_bottom && last_bottom) {
                                arrow_bottom_left_top<Massive>(prev_element, self, arrow);

                            }
                        }
                    } else {
                        if (+(prev_x - (self_x + self_width)).toFixed(0) <= border) {
                            if (first_top && last_top) {
                                arrow_top_left_bottom<Massive>(prev_element, self, arrow);

                            } else if (first_bottom && last_bottom) {
                                arrow_bottom_left_top<Massive>(prev_element, self, arrow);
                            }
                        } else {
                            if (first_left && last_right) {
                                arrow_left<Massive>(prev_element, self, arrow);
                            } else if (first_top && last_top) {
                                arrow_top_left_bottom<Massive>(prev_element, self, arrow);

                            } else if (first_top && last_right) {
                                arrow_top_left<Massive>(prev_element, self, arrow);
                            } else if (first_bottom && last_bottom) {
                                arrow_bottom_left_top<Massive>(prev_element, self, arrow);

                            }

                        }
                    }
                } else {
                    if (self_y + self_height <= prev_y) {
                        if (prev_y - (self_y + self_height) <= border) {
                            if (first_left && last_left) {
                                arrow_left_bottom_right<Massive>(prev_element, self, arrow);
                            } else if (first_right && last_right) {
                                arrow_right_bottom_left<Massive>(prev_element, self, arrow);
                            }else if(first_top && last_top){
                                arrow_top_left_bottom<Massive>(prev_element, self, arrow);//07
                            }
                        } else {
                            if (first_top && last_bottom) {
                                arrow_top<Massive>(prev_element, self, arrow);
                            }
                        }
                    }
                }
            }
        }
    }
}