import React, { FC } from "react";
import "./customTheme.css";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/javascript/javascript.js";
import { Controlled as ControlledEditor } from "react-codemirror2";
import classes from "./editor.module.scss";
import { icons } from "../../../../../../../settings/settings";

interface editorProps {
  language: string;
  lineNumbers: boolean;
  header?: boolean;
  leftHeaderElements?: Array<string>;
  rightHeaderElements?: Array<string>;
  value: any;
  hasError?: boolean;
  onChange: Function;
  onBeautify?: () => void;
}

const Editor: FC<editorProps> = ({
  language,
  lineNumbers,
  header = false,
  leftHeaderElements,
  rightHeaderElements,
  value,
  hasError,
  onChange,
  onBeautify,
}) => {
  const handleChange = (editor: any, data: any, value: any) => {
    if (language !== "") {
      onChange(value);
    }
    if (language === "") {
      onChange("First select type, please");
    }
  };

  return (
    <div id="createEditor" className={classes.EditorWrapper}>
      {header && (
        <section className={classes.EditorHeader}>
          <div className={classes.HeaderLeftSide}>
            {
              // eslint-disable-next-line array-callback-return
              leftHeaderElements?.map((button: string, index: number) => {
                switch (button) {
                  case "LeftAlign":
                    return (
                      <button key={index}>
                        <img
                          src={icons.codeEditorLeftAlign}
                          alt="Left align icon"
                        />
                      </button>
                    );

                  case "Beautifier":
                    return (
                      <button
                        className={`${classes.BeautifierBtn} ${
                          hasError !== undefined
                            ? hasError
                              ? classes["BeautifierBtn--fail"]
                              : classes["BeautifierBtn--success"]
                            : ""
                        }`}
                        key={index}
                        onClick={onBeautify}
                      >
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.58333 0H0.416667C0.186667 0 0 0.186667 0 0.416667C0 0.646667 0.186667 0.833333 0.416667 0.833333H9.58333C9.81333 0.833333 10 0.646667 10 0.416667C10 0.186667 9.81333 0 9.58333 0Z" />
                          <path d="M9.58333 9.16602H0.416667C0.186667 9.16602 0 9.35268 0 9.58268C0 9.81268 0.186667 9.99935 0.416667 9.99935H9.58333C9.81333 9.99935 10 9.81268 10 9.58268C10 9.35268 9.81333 9.16602 9.58333 9.16602Z" />
                          <path d="M9.58333 2.29102H5.41667C5.18667 2.29102 5 2.47768 5 2.70768C5 2.93768 5.18667 3.12435 5.41667 3.12435H9.58333C9.81333 3.12435 10 2.93768 10 2.70768C10 2.47768 9.81333 2.29102 9.58333 2.29102Z" />
                          <path d="M9.58333 4.58398H5.41667C5.18667 4.58398 5 4.77065 5 5.00065C5 5.23065 5.18667 5.41732 5.41667 5.41732H9.58333C9.81333 5.41732 10 5.23065 10 5.00065C10 4.77065 9.81333 4.58398 9.58333 4.58398Z" />
                          <path d="M9.58333 6.875H5.41667C5.18667 6.875 5 7.06167 5 7.29167C5 7.52167 5.18667 7.70833 5.41667 7.70833H9.58333C9.81333 7.70833 10 7.52167 10 7.29167C10 7.06167 9.81333 6.875 9.58333 6.875Z" />
                          <path d="M0 5.00025C0 5.23025 0.186667 5.41692 0.416667 5.41692H2.74417L2.74292 5.41817C2.49271 5.66817 2.28562 5.95567 2.12729 6.27233C2.02437 6.47817 2.10771 6.72838 2.31375 6.8315C2.37354 6.86129 2.43708 6.87525 2.49958 6.87525C2.6525 6.87525 2.79958 6.79109 2.87271 6.64504C2.99083 6.40859 3.14542 6.19421 3.33208 6.00733L4.04458 5.29484C4.20729 5.13213 4.20729 4.86838 4.04458 4.70567L3.33208 3.99317C3.14542 3.8065 2.99083 3.59192 2.87271 3.35546C2.76979 3.15004 2.52021 3.06588 2.31354 3.16921C2.10771 3.27213 2.02417 3.52233 2.12708 3.72817C2.28542 4.04463 2.4925 4.33192 2.74271 4.58233L2.74417 4.58358H0.416667C0.186667 4.58358 0 4.77025 0 5.00025Z" />
                        </svg>
                      </button>
                    );

                  case "FontSize":
                    return (
                      <button key={index}>
                        <img
                          src={icons.codeEditorFontSize}
                          alt="Font size icon"
                        />
                      </button>
                    );

                  case "Filter":
                    return (
                      <button key={index}>
                        <img src={icons.filterBlue} alt="Filter icon" />
                      </button>
                    );

                  case "Settings":
                    return (
                      <button key={index}>
                        <img src={icons.settingsBlue} alt="Settings icon" />
                      </button>
                    );

                  case "Undo":
                    return (
                      <button key={index}>
                        <img
                          src={icons.codeEditorUndo}
                          alt="Undo icon"
                          style={{ width: "2.16vh", height: "2.16vh" }}
                        />
                      </button>
                    );

                  case "Redo":
                    return (
                      <button key={index}>
                        <img
                          src={icons.codeEditorRedo}
                          alt="Redo icon"
                          style={{ width: "2.16vh", height: "2.16vh" }}
                        />
                      </button>
                    );

                  case "Search":
                    return (
                      <div className={classes.EditorSearchBox} key={index}>
                        <input type="text" />
                        <img src={icons.search} alt="Search icon" />
                      </div>
                    );

                  default:
                    break;
                }
              })
            }
          </div>
          <div className={classes.HeaderRightSide}>
            {
              // eslint-disable-next-line array-callback-return
              rightHeaderElements?.map((button: string, index: number) => {
                switch (button) {
                  case "LeftAlign":
                    return (
                      <button key={index}>
                        <img
                          src={icons.codeEditorLeftAlign}
                          alt="Left align icon"
                        />
                      </button>
                    );

                  case "RightAlign":
                    return (
                      <button key={index}>
                        <img
                          src={icons.codeEditorRightAlign}
                          alt="Right align icon"
                        />
                      </button>
                    );

                  case "FontSize":
                    return (
                      <button key={index}>
                        <img
                          src={icons.codeEditorFontSize}
                          alt="Font size icon"
                        />
                      </button>
                    );

                  case "Filter":
                    return (
                      <button key={index}>
                        <img src={icons.filterBlue} alt="Filter icon" />
                      </button>
                    );

                  case "Settings":
                    return (
                      <button key={index}>
                        <img src={icons.settingsBlue} alt="Settings icon" />
                      </button>
                    );

                  case "Undo":
                    return (
                      <button key={index}>
                        <img
                          src={icons.codeEditorUndo}
                          alt="Undo icon"
                          style={{ width: "2.16vh", height: "2.16vh" }}
                        />
                      </button>
                    );

                  case "Redo":
                    return (
                      <button key={index}>
                        <img
                          src={icons.codeEditorRedo}
                          alt="Redo icon"
                          style={{ width: "2.16vh", height: "2.16vh" }}
                        />
                      </button>
                    );

                  case "Search":
                    return (
                      <div className={classes.EditorSearchBox} key={index}>
                        <input type="text" />
                        <img src={icons.search} alt="Search icon" />
                      </div>
                    );

                  default:
                    break;
                }
              })
            }
          </div>
          <span className={classes.EditorSeperator}></span>
        </section>
      )}
      <ControlledEditor
        onBeforeChange={handleChange}
        value={value}
        options={{
          lineWrapping: true,
          mode: language.toLowerCase(),
          theme: "3024-day",
          lineNumbers: lineNumbers,
          tabSize: 1,
        }}
      />
    </div>
  );
};

export default Editor;
