export const DECISIONCHECKBOX = "DECISIONCHECKBOX";
export const ACTIVEDECISION = "ACTIVEDECISION";
export const MATRIXDATA = "MATRIXDATA";
export const MATRIXDATASTATUS = "MATRIXDATASTATUS";
export const MATRIXSPINNER = "MATRIXSPINNER";
export const MATRIXACTIVEALIAS = "MATRIXACTIVEALIAS";
export const MATRIXLISTSTATUS = "MATRIXLISTSTATUS";
export const MATRIXACTIVEPOINT = "MATRIXACTIVEPOINT";



//E

export const GETALLDECISIONS = "GETALLDECISIONS";
export const GETALLDECISIONRANGE = "GETALLDECISIONRANGE";
export const HANDLEDECISIONREFRESH = "HANDLEDECISIONREFRESH";
export const HANDLERANGEREFRESH = "HANDLERANGEREFRESH";
export const HANLDERANGEACTIVEELEMENT = "HANLDERANGEACTIVEELEMENT";
export const HANDLERANGEACTIVETOOLTIP = "HANDLERANGEACTIVETOOLTIP"
export const HANDLERANGEACTIVEROW = "HANDLERANGEACTIVEROW";


export const ADD_DECISION = "ADD_DECISION";
export const DELETE_DECISION = "DELETE_DECISION";

//end E
//E

export interface AddDecision {
  type: typeof ADD_DECISION, 
  payload : any
}

export interface DeleteDecision {
  type: typeof DELETE_DECISION, 
  payload : number
}


export interface DecisionRange {
  id?: number;
  decisionId?: number | string;
  statusId?: number;
  alias?: string;
  label?: string;
  from?: number | string;
  to?: number | string;
}

export interface RangeActiveElement {
  name?: string | null;
  elementIndex?: any | null;
}

export interface RangeActiveTooltip {
  childId?: string | null;
  childDataId?: number | null;
  type?: string | null;
}

export interface RangeActiveRow {
  rowIndex?: number | null;
  newRow: boolean;
}

//end E

export interface DecisionCheckbox {
  id ? : number,
  checked ? : boolean
}

export interface ActiveDecision {
  activeDecision:any | null
}

export interface MatrixData {
  matrixData:any | null
}
export interface MatrixDataStatus {
  status ? : boolean
}
export interface MatrixSpinner {
  status ? : boolean
}

export interface MatrixActiveAlias {
  matrixActiveAlias:any | null
}

export interface MatrixListStatus {
  matrixListStatus:any | null
}
export interface MatrixActivePoint {
  matrixActivePoint:any | null
}

export interface DecisionState {
    decisionCheckbox: DecisionCheckbox[],
    activeDecision:ActiveDecision,
    matrixData:MatrixData,
    matrixDataStatus:MatrixDataStatus,
    matrixSpinner:MatrixSpinner,
    matrixActiveAlias:MatrixActiveAlias,
    matrixListStatus:MatrixListStatus,
    matrixActivePoint:MatrixActivePoint,
    //E
    decisionState: Array<any>,
    decisionRanges: any,
    decisionReload: boolean,
    rangeReload: boolean,
    rangeActiveElement: RangeActiveElement,
    rangeActiveTooltip: RangeActiveTooltip,
    rangeActiveRow: RangeActiveRow,
    //END E

}

interface decisionCheckboxList {
  type: typeof DECISIONCHECKBOX;
  payload: {
    decisionCheckboxList: DecisionCheckbox
  };
}
interface activeDecision {
  type: typeof ACTIVEDECISION;
  payload: {
    activeDecision: ActiveDecision
  };
}
interface matrixData {
  type: typeof MATRIXDATA;
  payload: {
    matrixData: MatrixData
  };
}
interface matrixDataStatus{
  type:typeof MATRIXDATASTATUS,
  payload:{
    matrixDataStatus:MatrixDataStatus
  }
}
interface matrixSpinner{
  type:typeof MATRIXSPINNER,
  payload:{
    matrixSpinner:MatrixSpinner
  }
}
interface matrixActiveAlias{
  type: typeof MATRIXACTIVEALIAS;
  payload: {
    matrixActiveAlias: MatrixActiveAlias
  };
}

interface matrixListStatus{
  type: typeof MATRIXLISTSTATUS;
  payload: {
    matrixListStatus: MatrixListStatus
  };
}

interface matrixActivePoint{
  type: typeof MATRIXACTIVEPOINT;
  payload: {
    matrixActivePoint: MatrixActivePoint
  };
}

//E

interface getAllDecisions {
  type: typeof GETALLDECISIONS;
  payload: any
}

interface getAllDecisionRange {
  type: typeof GETALLDECISIONRANGE;
  payload: any
}

interface handleDecisionReload {
  type: typeof HANDLEDECISIONREFRESH;
  payload: { decisionReload: boolean };
}

interface handleRangeReload {
  type: typeof HANDLERANGEREFRESH;
  payload: { rangeReload: boolean };
}

interface handleRangeActiveElement {
  type: typeof HANLDERANGEACTIVEELEMENT;
  payload: any;
}

interface handleRangeActiveTooltip {
  type: typeof HANDLERANGEACTIVETOOLTIP;
  payload: any;
}

interface handleRangeActiveRow {
  type: typeof HANDLERANGEACTIVEROW;
  payload: any;
}
//END E
export type DecisionActionsTypes =  
decisionCheckboxList 
| activeDecision 
| matrixData 
| matrixDataStatus 
| matrixSpinner 
| matrixActiveAlias 
| matrixListStatus 
| matrixActivePoint  
| AddDecision
| DeleteDecision 
//E
| getAllDecisions
| getAllDecisionRange
| handleDecisionReload
| handleRangeReload
| handleRangeActiveElement
| handleRangeActiveTooltip
| handleRangeActiveRow;
//END E