import { allowedNodeEnvironmentFlags } from "process";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";
import DB from "../layout/DB/Storage";
import { ElementTypes, storageKeys, storage_key } from "../settings/settings";
import { manipulateExecutionData } from "./manipulateExecutionData";
import { updateOutputItem } from "./outputData";

interface ISubmitElement {
  id: string;
  state?: any;
  previousData?: any;
  type: ElementTypes;
  isNew: boolean;
  isEditMode?: boolean
}

function getSpecificConditionSide(side: string, dataArray: Array<any>) {
  return dataArray.find((data: any) => data.branchLabel === side);
}

export const submitElement = ({
  id,
  state,
  previousData,
  type,
  isNew,
  isEditMode
}: ISubmitElement) => {
  return new Promise(async (resolve, reject) => {
    const db = new DB(storage_key);
    const manipulatedData = manipulateExecutionData({
      elementState: state,
      elementType: type,
      elementPreviousData: previousData,
      isEditMode
    });

    const svg = document.querySelector("#svg_board") as SVGAElement;
    const element = svg?.querySelector(
      `rect[data-id = ${id}]`
    ) as SVGRectElement;
    const element_type = element?.dataset?.type;
    const text = element?.parentNode?.querySelector(
      `text[id = "text"]`
    ) as SVGAElement;
    let data: Array<object> = [];
    const iterateKeys = [] as any[];

    const getKeys = (elmentData: any) => {
      const {
        arrayInput: { input },
      } = elmentData;
      if (input) {
        const getIterateKeys = (input: string) => {
          let result = "" as string;
          if (input.includes("root")) return input.replace("root.", "");

          const boardElementsJSON = localStorage.getItem(
            storageKeys.boardElements
          );
          if (!boardElementsJSON) return [];
          const boardElementsArray = [...JSON.parse(boardElementsJSON)];
          boardElementsArray?.forEach((item: any) => {
            if (item.type === "func") {
              const {
                data: { label, arrayInput },
              } = item;
              if (input === label) {
                result = arrayInput?.input;
              }
            }
          });
          return result.replace("root.", "");
        };
        const subKeys = [] as string[];
        const mainKeys = [] as string[];
        const chainedKeys = (key: string, value: any) => {
          Object.keys(value)?.forEach((key_sub) => {
            subKeys.push(`${key}.${key_sub}`);
          });
        };
        const searchByValue = (obj: any, keys: string | string[]) => {
          if (typeof keys === "string") {
            const splittedKeys = keys.split(".");
            const refData = [...Object.entries(obj)];
            refData?.forEach(([key, value]) => {
              if (!splittedKeys.includes(key)) return;
              if (typeof value === "object" && !Array.isArray(value)) {
                const index = splittedKeys.findIndex((f) => f === key);
                splittedKeys.splice(index, 1);
                const joined = splittedKeys.join(".");
                searchByValue(value, joined);
              } else {
                const [item] = obj[key];
                if(!item) return;
                Object.entries(item)?.forEach(([key_sub, value_sub]) => {
                  if (
                    typeof value_sub === "object" &&
                    !Array.isArray(value_sub)
                  ) {
                    chainedKeys(key_sub, value_sub);
                    searchByValue(value_sub, subKeys);
                  }
                });
                mainKeys.push(...Object.keys(item));
              }
            });
          } else if (Array.isArray(keys)) {
            keys?.forEach((key) => {
              const splitted = key.split(".");
              const lastKey = splitted[splitted.length - 1];

              Object.entries(obj)?.forEach(([key_sub, value_sub]) => {
                if (
                  typeof value_sub === "object" &&
                  !Array.isArray(value_sub)
                ) {
                  if (lastKey === key_sub) {
                    chainedKeys(key, value_sub);
                    searchByValue(value_sub, subKeys);
                  }
                }
              });
            });
          }
        };
        const reformattedSourceJSON = sessionStorage.getItem(
          storageKeys.activeStrategySourceData
        );

        if (!reformattedSourceJSON) return [];

        const reformattedSourceArray = JSON.parse(reformattedSourceJSON);
        const { data: jsonData } = reformattedSourceArray;
        const keyChain = getIterateKeys(input);
        searchByValue(jsonData, keyChain);
        iterateKeys.push({ keys: [...mainKeys, ...subKeys] });
      }
    };
       
    if (manipulatedData?.label) {
      text.textContent =
        element_type === "condt" ? "If case" : manipulatedData?.label;
    }
    if (isNew) {
      let anchors: Array<string> = [];
      let overlays: Array<{
        side: string;
        contact: string;
        coord: string;
      }> = [];
      let left: any = element?.getAttribute("x");
      let top: any = element?.getAttribute("y");
      let first_output_arrows = svg.querySelectorAll(
        `path[data-contact ^= ${id}]`
      );
      let last_output_arrows = svg.querySelectorAll(
        `path[data-contact $= ${id}]`
      );

      first_output_arrows?.forEach((elem: any) => {
        let _data_type = elem?.getAttribute("data-side");
        let _data_parent = elem?.getAttribute("data-contact");
        let _data_coord = elem?.getAttribute("d");
        anchors.push(_data_type);
        overlays.push({
          side: _data_type,
          contact: _data_parent,
          coord: _data_coord,
        });
      });

      last_output_arrows?.forEach((elem: any) => {
        anchors = [];
        let _data_type = elem?.getAttribute("data-side");
        let _data_coord = elem?.getAttribute("d");

        let _mass_ = _data_coord?.split(",");
        let _first = _mass_[0];
        let _x = +_first?.split(" ")[0]?.split("M")[1];
        let _y = +_first?.split(" ")[1];
        let m = `M${_x} ${_y}`;
        let _v;

        if (_data_type === "right") {
          _v =
            _mass_[2]?.split("v")?.length === 2
              ? +_mass_[2]?.split("v")[1]
              : +_mass_[2]?.split("v")[1];
          let _v_char = _mass_[2]?.split("v")?.length === 2 ? "v" : "v";
          let v = `${_v_char}${_v}`;

          _mass_[0] = m;
          _mass_[2] = v;
        } else if (_data_type === "left") {
          _v =
            _mass_[6]?.split("v")?.length === 2
              ? +_mass_[6]?.split("v")[1]
              : +_mass_[6]?.split("v")[1];
          let _v_char = _mass_[6]?.split("v")?.length === 2 ? "v" : "v";
          let v = `${_v_char}${_v}`;

          _mass_[0] = m;
          _mass_[2] = v;
        } else {
          _v =
            _mass_[1]?.split("v")?.length === 2
              ? +_mass_[1]?.split("v")[1]
              : +_mass_[1]?.split("v")[1];
          let _v_char = _mass_[1]?.split("v")?.length === 2 ? "v" : "v";
          let v = `${_v_char}${_v}`;

          _mass_[0] = m;
          _mass_[1] = v;
          _mass_[3] = v;
        }
        anchors.push(_data_type);
      });

      const targetId = overlays ? overlays?.[0]?.contact?.split("-")?.[1] : "";
      switch (type) {
        case ElementTypes.CONDITION: {
          data.push(
            {
              type: ElementTypes.CONDITION,
              data: manipulatedData,
              style: `left:${left}px,top:${top}px`,
              sourceId: id,
              targetId,
              branchLabel: "YES",
              anchors: [...anchors],
              overlays,
              breakPoint: "False",
            },
            {
              type: ElementTypes.CONDITION,
              data: manipulatedData,
              style: `left:${left}px,top:${top}px`,
              sourceId: id,
              targetId: "",
              branchLabel: "NO",
              anchors,
              overlays: [],
              breakPoint: "False",
            }
          );
          break;
        }
        case ElementTypes.DATA_WIZARD: {        
          data.push({
            type: type,
            data: manipulatedData,
            style: "",
            anchors: [...anchors],
            overlays,
          });
          break;
        }
        case ElementTypes.DECISION_RANGE:
        case ElementTypes.DECISION_MATRIX: {
          data.push({
            type: ElementTypes.DECISION_RANGE,
            data: manipulatedData,
            style: `left:${left}px,top:${top}px`,
            sourceId: id,
            targetId,
            branchLabel: "",
            anchors,
            overlays,
            breakPoint: "False",
          });
          break;
        }
        case ElementTypes.PRODUCT: {
          data.push({
            type: ElementTypes.PRODUCT,
            data: manipulatedData,
            style: `left:${left}px,top:${top}px`,
            sourceId: id,
            targetId,
            branchLabel: "",
            anchors,
            overlays,
            breakPoint: "False",
          });
          break;
        }
        case ElementTypes.MAP: {
          data.push({
            type: ElementTypes.MAP,
            data: manipulatedData,
            style: `left:${left}px,top:${top}px`,
            sourceId: id,
            targetId,
            branchLabel: "",
            anchors,
            overlays,
            breakPoint: "False",
          });
          break;
        }
        default: {
          getKeys(manipulatedData);

          data.push({
            type: type,
            data: manipulatedData,
            style: `left:${left}px,top:${top}px`,
            sourceId: id,
            targetId,
            branchLabel: "",
            anchors,
            overlays: [
              { ...overlays[0], ...(iterateKeys.length > 0 && iterateKeys[0]) },
            ],
            breakPoint: "False",
          });

          break;
        }
      }
  
      db.add(data).then(resolve).catch(reject);
    } else {
      const decisionType = {
        condt: () => {
          const conditionTrueSide = getSpecificConditionSide(
            "YES",
            previousData
          );
          const conditionFalseSide = getSpecificConditionSide(
            "NO",
            previousData
          );
          data.push(
            {
              ...conditionTrueSide,
              data: manipulatedData,
            },
            {
              ...conditionFalseSide,
              data: manipulatedData,
            }
          );
        },
        scorecard: () => {
          data.push({
            ...data,
            data: manipulatedData,
          });
        },
        func: () => {
          data.push({
            ...data,
            data: manipulatedData,
          });
          getKeys(manipulatedData);
        },
        default: () => {
          data.push({
            ...data,
            data: manipulatedData,
          });
        },
      } as any;      

      decisionType[type] ? decisionType[type]?.() : decisionType["default"]?.();
      await db
      .update(
        type === ElementTypes.DATA_WIZARD ? { type } : { sourceId: id },
        data
        )
        .then(resolve)
        .catch(reject);
    
        const copyOfData = [] as any[];
        await new DB(storage_key)
        .fetch({ sourceId: id })
        .then(async (res: any) => {
          const { status, data } = res;
          if (!status) return;

          let item = data?.overlays?.filter((f: any) =>
            Object.keys(f).includes("keys")
          );

          if (!item || item?.length === 0) return;
          let [key] = item;
          if (key) {
            key = iterateKeys[0];
          } else {
            const coord = [...data.overlays];
            data.overlays = [];
            data.overlays.push({ ...coord[0], ...iterateKeys[0] });
          }
          copyOfData.push(data);
        });
      const getTypes={
        func:"function",
        scorecard:"scorecard",
        product:"product",
      } as any;
      previousData && updateOutputItem(manipulatedData,getTypes[type],previousData)
      if (copyOfData.length === 0) return;
      copyOfData[0].overlays[0] = {
        ...copyOfData[0].overlays[0],
        ...(iterateKeys.length > 0 && iterateKeys[0]),
      };
      new DB(storage_key)
        .update({ sourceId: id }, copyOfData)
        .then(resolve)
        .catch(reject);
      
    }
  });
};
