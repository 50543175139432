import { ButtonTypes, ModalNames } from "../../settings/settings";
import { ModalElements } from "../../components/UI/Modal/ModalFormV2";

export const MODAL_OPEN = "MODAL_OPEN";
export const MODAL_CLOSE = "MODAL_CLOSE";
export const MODAL_RESET = "MODAL_RESET";

export interface IModalData {
  visible: boolean;
  title: string;
  question: string;
  message: string;
  buttonMessage: string;
  buttonType: ButtonTypes;
  validatingField?: string;
  name: ModalNames | null;
  fields?: ModalElements;
}

export interface IModalState {
  modalState: IModalData;
}

export interface IModalOpenAction {
  type: typeof MODAL_OPEN;
  payload: IModalState;
}

export interface IModalCloseAction {
  type: typeof MODAL_CLOSE;
}

export interface IModalResetAction {
  type: typeof MODAL_RESET;
}

export type ModalActions =
  | IModalOpenAction
  | IModalCloseAction
  | IModalResetAction;
