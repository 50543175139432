import { StrategyActionsTypes, StrategyBoardPanel, STRATEGY_BOARD_PANEL } from "../../../../types/typesStrategy";


const initialState: StrategyBoardPanel = {
    element_panel:false
}

const reducer = (state = initialState, action: StrategyActionsTypes): StrategyBoardPanel => {

    switch (action.type) {

        case STRATEGY_BOARD_PANEL:
            const {element_panel} = action.payload;
            
            return {
                element_panel
            }

        default:
            return state

    }
}

export default reducer