import { CutOverflowStringDirections } from "../settings/settings";

interface ICutOverflowString {
  overflowString: string;
  maxLength: number;
  startingDirection?: CutOverflowStringDirections;
  addition?: string;
}

/**
 * This function accepts string and returns modified string if given string length greater than maxLength.
 *
 * @param {string} overflowString Accepting string which will be modified
 *
 * @param {number} maxLength Length of max acceptable string length
 *
 * @param {CutOverflowStringDirections} startingDirection Is optional parameter. Default CutOverflowStringDirections.RIGHT
 *
 * @param {string} addition Is optional parameter. Addition is a string and what you want replace the cut string part
 *
 * @returns {string} Modified version of given string
 */
export const cutOverflowString = ({
  overflowString,
  maxLength,
  startingDirection = CutOverflowStringDirections.RIGHT,
  addition = "",
}: ICutOverflowString): string => {
  if (overflowString.length < maxLength) return overflowString;

  const stringLength: number = overflowString.length;
  const additionLength: number = addition?.length;
  let cutStart: number = 0;
  let cutEnd: number = addition ? maxLength - additionLength : maxLength;

  if (startingDirection === CutOverflowStringDirections.LEFT) {
    cutStart = stringLength - maxLength + additionLength;
    cutEnd = stringLength;

    return `${addition ?? ""}${overflowString.substring(
      cutStart < 0 ? 0 : Math.abs(cutStart),
      Math.abs(cutEnd)
    )}`;
  }

  return `${overflowString.substr(cutStart, Math.abs(cutEnd))}${
    addition ?? ""
  }`;
};
