import {
    ACTIVE_STRATEGY,
    ActiveStrategy,
    SourceDataAvailability,
    SourceDataTypes,
    STRATEGY_ADD_ALL_SELECTED,
    STRATEGY_ADD_SELECTED,
    STRATEGY_BOARD_DATA,
    STRATEGY_BOARD_ELEMENT_INPUT,
    STRATEGY_BOARD_ELEMENT_OUTPUT,
    STRATEGY_BOARD_ELEMENT_OUTPUT2,
    STRATEGY_BOARD_ELEMENTS,
    STRATEGY_BOARD_NEW_CREATED_ELEMENT,
    STRATEGY_BOARD_PANEL,
    STRATEGY_BOARD_POSITION,
    STRATEGY_BOARD_SIDE_PANEL_COLLAPSE_STATUS,
    STRATEGY_BOARD_SIDE_PANEL_CONTENT,
    STRATEGY_BOARD_SIDE_PANEL_INPUT_CREATE_OBJECT,
    STRATEGY_BOARD_SIDE_PANEL_INPUT_REMOVE_QUEUE_FILE,
    STRATEGY_BOARD_SIDE_PANEL_INPUT_STAGE,
    STRATEGY_BOARD_SIDE_PANEL_INPUT_UPLOAD,
    STRATEGY_BOARD_SIDE_PANEL_INPUT_UPLOAD_PROCESS,
    STRATEGY_BOARD_SIDE_PANEL_INPUT_UPLOAD_PROCESS_STATUS,
    STRATEGY_BOARD_SIDE_PANEL_INPUT_UPLOAD_RESET,
    STRATEGY_BOARD_TYPE,
    STRATEGY_BOARD_VALIDATE_STATUS,
    STRATEGY_BOARD_WIZARD_STATUS,
    STRATEGY_BOARD_ZOOM,
    STRATEGY_DATA,
    STRATEGY_FETCH_STATUS,
    STRATEGY_FUNCTION_GROUPS,
    STRATEGY_FUNCTIONS,
    STRATEGY_REMOVE_ALL_SELECTED,
    STRATEGY_REMOVE_SELECTED,
    STRATEGY_SOURCE_DATA_AVAILABILITY,
    STRATEGY_SOURCE_DATA_STATUS,
    STRATEGY_SOURCE_DATA_TYPES,
    STRATEGY_STATUS,
    STRATEGY_VERSION,
    StrategyActionsTypes,
    StrategyBoardData,
    StrategyBoardElementInput,
    StrategyBoardElementOutput,
    StrategyBoardElementOutput2,
    StrategyBoardElements,
    StrategyBoardNewCreatedElement,
    StrategyBoardPanel,
    StrategyBoardPosition,
    StrategyBoardSidePanelCollapseStatus,
    StrategyBoardSidePanelContent,
    StrategyBoardSidePanelInputCreateObject,
    StrategyBoardSidePanelInputRemoveQueueFile,
    StrategyBoardSidePanelInputStage,
    StrategyBoardSidePanelInputUpload,
    StrategyBoardSidePanelInputUploadProcess,
    StrategyBoardSidePanelInputUploadProcessStatus,
    StrategyBoardType,
    StrategyBoardValidateStatus,
    StrategyBoardWizardStatus,
    StrategyBoardZoom,
    StrategyData,
    StrategyFetchStatus,
    StrategyFunctionData,
    StrategyFunctionGroups,
    StrategySourceDataStatus,
    StrategyStatus,
    StrategyTableSelectedList,
    StrategyTableSelectedObj,
    StrategyVersion,
    StrategyOutputFormatData,
    STRATEGY_OUTPUT_FORMAT_DATA,
    StrategyOutputFormatItemStatus,
    STRATEGY_OUTPUT_FORMAT_ITEM_STATUS,
    StrategyOutputFormatMessage,
    STRATEGY_OUTPUT_FORMAT_MESSAGE,
    StrategyElementTooltipStatus,
    STRATEGY_ELEMENT_TOOLTIP_STATUS,
    StrategyMatrixActiveData,
    STRATEGY_MARTIX_ACTIVE_DATA,
    StrategyDecisionMatrixCoordData,
    STRATEGY_DECISION_MATRIX_COORD_DATA,
    ChangeableStatus,
    CHANGEABLE_STATUS,
    CheckOutputStatus,
    CHECK_OUTPUT_STATUS,
    STRATEGY_APPROVE_FIELDS,
    StrategyApproveFields,
    StrategyApprovedByStatus,
    STRATEGY_APPROVED_BY_STATUS
} from "../types/typesStrategy";


export function handleStrategyData(data: StrategyData): StrategyActionsTypes {
    return {
        type: STRATEGY_DATA,
        payload: data
    }
}

export function handleStrategyStatus(data: StrategyStatus): StrategyActionsTypes {
    return {
        type: STRATEGY_STATUS,
        payload: data
    }
}

export function handleStrategyVersion(data: StrategyVersion): StrategyActionsTypes {
    return {
        type: STRATEGY_VERSION,
        payload: data
    }
}

export function handleActiveStrategy(data: ActiveStrategy):StrategyActionsTypes {
    return {
        type: ACTIVE_STRATEGY,
        payload: data
    }
}

export function handleStrategyBoardZoom(zoom: StrategyBoardZoom): StrategyActionsTypes {
    return {
        type: STRATEGY_BOARD_ZOOM,
        payload: zoom
    };
}

export function handleStrategyApprovedByStatus(data: StrategyApprovedByStatus): StrategyActionsTypes {
    return {
        type: STRATEGY_APPROVED_BY_STATUS,
        payload: data
    }
}

export function handleStrategyApproveFields(data: StrategyApproveFields): StrategyActionsTypes {
    return {
        type: STRATEGY_APPROVE_FIELDS,
        payload: data,
    }
}

export function handleStrategyBoardPosition(position: StrategyBoardPosition): StrategyActionsTypes {
    return {
        type: STRATEGY_BOARD_POSITION,
        payload: { position }
    };
}

export function handleStrategyBoardSidePanelCollapseStatus(sidePanelProps: StrategyBoardSidePanelCollapseStatus): StrategyActionsTypes {
    return {
        type: STRATEGY_BOARD_SIDE_PANEL_COLLAPSE_STATUS,
        payload: sidePanelProps
    }
}

export function handleStrategyBoardSidePanelContent(sidePanelProps: StrategyBoardSidePanelContent): StrategyActionsTypes {
    return {
        type: STRATEGY_BOARD_SIDE_PANEL_CONTENT,
        payload: sidePanelProps
    };
}

export function handleStrategyBoardSidePanelInputStage(sidePanelProps: StrategyBoardSidePanelInputStage): StrategyActionsTypes {
    return {
        type: STRATEGY_BOARD_SIDE_PANEL_INPUT_STAGE,
        payload: sidePanelProps
    };
}

export function handleStrategyBoardSidePanelInputUploadFile(sidePanelProps: StrategyBoardSidePanelInputUpload): StrategyActionsTypes {
    return {
        type: STRATEGY_BOARD_SIDE_PANEL_INPUT_UPLOAD,
        payload: sidePanelProps
    };
}

export function removeStrategyBoardSidePanelInputQueueFile(sidePanelProps: StrategyBoardSidePanelInputRemoveQueueFile): StrategyActionsTypes {
    return {
        type: STRATEGY_BOARD_SIDE_PANEL_INPUT_REMOVE_QUEUE_FILE,
        payload: sidePanelProps
    }
}

export function handleStrategyBoardSidePanelInputUploadProcessFiles(sidePanelProps: StrategyBoardSidePanelInputUploadProcess): StrategyActionsTypes {
    return {
        type: STRATEGY_BOARD_SIDE_PANEL_INPUT_UPLOAD_PROCESS,
        payload: sidePanelProps
    }
}

export function handleStrategyBoardSidePanelInputUploadProcessStatus(sidePanelProps: StrategyBoardSidePanelInputUploadProcessStatus): StrategyActionsTypes {
    return {
        type: STRATEGY_BOARD_SIDE_PANEL_INPUT_UPLOAD_PROCESS_STATUS,
        payload: sidePanelProps
    }
}

export function resetStrategyBoardSidePanelInputUpload():StrategyActionsTypes {
    return {
        type: STRATEGY_BOARD_SIDE_PANEL_INPUT_UPLOAD_RESET
    }
}

export function handleStrategyBoardSidePanelCreateInputObject(data : StrategyBoardSidePanelInputCreateObject):StrategyActionsTypes {
    return {
        type: STRATEGY_BOARD_SIDE_PANEL_INPUT_CREATE_OBJECT,
        payload: data
    }
}

export function handlerStrategyBoardPanel(data: StrategyBoardPanel): StrategyActionsTypes {
    return {
        type: STRATEGY_BOARD_PANEL,
        payload: data
    }
}
export function handlerStrategyBoardElements(data: StrategyBoardElements): StrategyActionsTypes {
    return {
        type: STRATEGY_BOARD_ELEMENTS,
        payload: data
    }
}
export function handlerStrategyBoardType(data: StrategyBoardType): StrategyActionsTypes {
    return {
        type: STRATEGY_BOARD_TYPE,
        payload: data
    }
}
export function handlerStrategyElementInput(data: StrategyBoardElementInput): StrategyActionsTypes {
    return {
        type: STRATEGY_BOARD_ELEMENT_INPUT,
        payload: data
    }
}
export function handlerStrategyElementOutput(data: StrategyBoardElementOutput): StrategyActionsTypes {
    return {
        type: STRATEGY_BOARD_ELEMENT_OUTPUT,
        payload: data
    }
}
export function handlerStrategyElementOutput2(data: StrategyBoardElementOutput2): StrategyActionsTypes {
    return {
        type: STRATEGY_BOARD_ELEMENT_OUTPUT2,
        payload: data
    }
}

export function handleStrategyBoardData(data: StrategyBoardData): StrategyActionsTypes {
    return {
        type: STRATEGY_BOARD_DATA,
        payload: data
    }
}

export function handleStrategyFunctionGroups(data: StrategyFunctionGroups):StrategyActionsTypes {
    return {
        type: STRATEGY_FUNCTION_GROUPS,
        payload: data
    }
}

export function handleStrategyFunctionData(data: StrategyFunctionData):StrategyActionsTypes {
    return {
        type: STRATEGY_FUNCTIONS,
        payload: data
    }
}

export function handleStrategyNewCreatedElementId(data: StrategyBoardNewCreatedElement):StrategyActionsTypes{
    return {
        type: STRATEGY_BOARD_NEW_CREATED_ELEMENT,
        payload: data
    }
}

export function handleSourceDataAvailability(data: SourceDataAvailability):StrategyActionsTypes{
    return{
        type: STRATEGY_SOURCE_DATA_AVAILABILITY,
        payload: data
    }
}

export function handleSourceDataTypes(data: SourceDataTypes):StrategyActionsTypes{
    return {
        type: STRATEGY_SOURCE_DATA_TYPES,
        payload: data
    }
}

export function handleStrategyFetchStatus(data: StrategyFetchStatus):StrategyActionsTypes{
    return {
        type: STRATEGY_FETCH_STATUS,
        payload: data
    }
}

export function handleStrategyWizardStatus(data: StrategyBoardWizardStatus):StrategyActionsTypes{
    return {
        type: STRATEGY_BOARD_WIZARD_STATUS,
        payload: data
    }
}

export function handleStrategyValidateStatus(data: StrategyBoardValidateStatus):StrategyActionsTypes{
    return {
        type: STRATEGY_BOARD_VALIDATE_STATUS,
        payload: data
    }
}

export function handleStrategySourceDataStatus(data: StrategySourceDataStatus):StrategyActionsTypes{
    return {
        type: STRATEGY_SOURCE_DATA_STATUS,
        payload: data
    }
}

export function addStrategyTableSelectedList(data:StrategyTableSelectedObj):StrategyActionsTypes{
    return {
        type:STRATEGY_ADD_SELECTED,
        payload:data
    }
}

export function addAllStrategyTableSelectedList(data:StrategyTableSelectedList):StrategyActionsTypes{
    return {
        type:STRATEGY_ADD_ALL_SELECTED,
        payload:data
    }
}

export function removeStrategyTableSelectedList(data:StrategyTableSelectedObj):StrategyActionsTypes{
    return {
        type:STRATEGY_REMOVE_SELECTED,
        payload:data
    }
}

export function removeAllStrategyTableSelectedList():StrategyActionsTypes{
    return {
        type:STRATEGY_REMOVE_ALL_SELECTED,
    }
}
export function handleStrategyOutputFormatData(data: StrategyOutputFormatData):StrategyActionsTypes{
    return {
        type: STRATEGY_OUTPUT_FORMAT_DATA,
        payload: data
    }
}
export function handleStrategyOutputFormatItemStatus(data: StrategyOutputFormatItemStatus):StrategyActionsTypes{
    return {
        type: STRATEGY_OUTPUT_FORMAT_ITEM_STATUS,
        payload: data
    }
}
export function handleStrategyOutputFormatMessage(data: StrategyOutputFormatMessage):StrategyActionsTypes{
    return {
        type: STRATEGY_OUTPUT_FORMAT_MESSAGE,
        payload: data
    }
}

export function handleStrategyElementTooltipStatus(data: StrategyElementTooltipStatus):StrategyActionsTypes{
    return {
        type: STRATEGY_ELEMENT_TOOLTIP_STATUS,
        payload: data
    }
}


export function handleStrategyMatrixActiveData(data: StrategyMatrixActiveData):StrategyActionsTypes{
    return {
        type: STRATEGY_MARTIX_ACTIVE_DATA,
        payload: data
    }
}

export function handleStrategyMatrixCoordData(data: StrategyDecisionMatrixCoordData):StrategyActionsTypes{
    return {
        type: STRATEGY_DECISION_MATRIX_COORD_DATA,
        payload: data
    }
}
export function handleChangeableStatus(data: ChangeableStatus):StrategyActionsTypes{
    return {
        type: CHANGEABLE_STATUS,
        payload: data
    }
}

export function handleCheckOutputStatus(data: CheckOutputStatus):StrategyActionsTypes{
    return {
        type: CHECK_OUTPUT_STATUS,
        payload: data
    }
}