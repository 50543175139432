import React, { FC, useCallback, useLayoutEffect, useState } from "react";
import classes from "./ModalFormV2.module.scss";
import { useSelector } from "react-redux";
import {
  ButtonSizes,
  ButtonTypes,
  icons,
  ModalFormInlineElementTypes,
  ModalNames,
} from "../../../settings/settings";
import { RootState } from "../../../store/combineReducer";
import Portal from "../Portal/Portal";
import Button from "../Button/ButtonV2";
import Input from "../Input/Input";
import InlineSelect, { InlineSelectData } from "../Select/InlineSelect";

interface IModalElement {
  elementType: ModalFormInlineElementTypes;
  name: string;
  label?: string;
  initialValue: string | number | boolean | null;
  selectData?: InlineSelectData;
}

export interface IModalData {
  [key: string]: string | number | boolean;
}

interface ISetData {
  name: string | number;
  value: string | number | boolean | null;
}

export type ModalElements = Array<IModalElement>;

interface IIModalFormV2 {
  elements?: ModalElements;
  name: ModalNames;
  title: string;
  buttonMessage: string;
  buttonType: ButtonTypes;
  buttonLoading?: boolean;
  buttonLoadingMessage?: string;
  onCancel: (action: boolean) => void;
  onSubmit: (data: IModalData) => void;
  visible: boolean;
}

let initialRender = true;

const ModalForm: FC<IIModalFormV2> = ({
  elements,
  name,
  title,
  buttonMessage,
  buttonType,
  buttonLoading = false,
  buttonLoadingMessage,
  onCancel,
  onSubmit,
  visible,
}) => {
  const {
    reducerModal: { modalState },
  } = useSelector((state: RootState) => state);

  const [modalData, setModalData] = useState<IModalData>({});

  const setData = useCallback(({ name, value }: ISetData) => {
    setModalData((state) => {
      return {
        ...state,
        [name as string]: value ?? "",
      };
    });
  }, []);

  const setInitialValues = useCallback(() => {
    if (modalState?.name !== name || !initialRender || !elements) return;

    elements.forEach((element: IModalElement) => {
      const { name, initialValue } = element;

      setData({ name, value: initialValue });
    });

    initialRender = false;
  }, [modalState, name, elements, setData]);

  const generateElementRows = useCallback(() => {
    if (!elements || !elements.length) return null;

    // eslint-disable-next-line array-callback-return
    return elements.map((elementObj: IModalElement, index: number) => {
      const { elementType, name, label, selectData } = elementObj;

      switch (elementType) {
        case ModalFormInlineElementTypes.INPUT: {
          const value = modalData[name] as string;
          return (
            <Input
              key={index}
              label={label}
              value={value ?? ""}
              onChange={(value: string) => setData({ name, value })}
            />
          );
        }

        case ModalFormInlineElementTypes.SELECT: {
          return (
            <InlineSelect
              key={index}
              label={label}
              defaultValueId={modalData && (modalData[name] as number)}
              data={selectData}
              onSelect={(value: number) => setData({ name, value })}
            />
          );
        }
      }
    });
  }, [elements, modalData, setData]);

  const fireCancel = useCallback(() => {
    onCancel(false);
    setModalData({});
    initialRender = true;
  }, [onCancel]);

  const fireSubmit = useCallback(() => {
    if (!modalData) return;

    onSubmit(modalData);
    initialRender = true;
  }, [onSubmit, modalData]);

  useLayoutEffect(setInitialValues, [setInitialValues, visible]);

  return (
    <>
      {visible && modalState?.name === name ? (
        <Portal>
          <div className={classes.Wrapper}>
            <p className={classes.Title}>{title}</p>
            <div className={classes.Body}>
              {generateElementRows()}
              <Button
                type={buttonLoading ? ButtonTypes.LOADING : buttonType}
                size={ButtonSizes.LARGE}
                onClick={fireSubmit}
                loadingMessage={buttonLoadingMessage}
              >
                {buttonMessage}
              </Button>
            </div>
            <button
              className={classes.CancelButton}
              onClick={fireCancel}
              disabled={buttonLoading}
            >
              <img src={icons.closeBtn} alt="Close icon" />
            </button>
          </div>
        </Portal>
      ) : null}
    </>
  );
};

export default ModalForm;
